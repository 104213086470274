import React from 'react';
import cx from 'classnames';

import styles from './Hr.module.scss';

const Hr = ({ light }) => (
  <hr className={cx(styles.hr, { [styles.light]: light })} />
);

export default Hr;
