import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Logout = ({ color, ...rest }) => (
  <svg viewBox="0 0 25 24" {...rest} fill={color}>
    <path d="M2.025 2.5a.6.6 0 0 1 .6-.6h11.762a.6.6 0 0 1 .6.6v3.62a.6.6 0 1 1-1.2 0V3.1H3.225v17.8h10.562v-2.096a.6.6 0 1 1 1.2 0V21.5a.6.6 0 0 1-.6.6H2.625a.6.6 0 0 1-.6-.6v-19Z" />
    <path d="M5.644 12.453a.6.6 0 0 1 .6-.6h15.38a.6.6 0 1 1 0 1.2H6.245a.6.6 0 0 1-.6-.6Z" />
    <path d="M11.192 7.496a.6.6 0 0 1 0 .848l-4.1 4.1 4.1 4.1a.6.6 0 0 1-.848.848L5.82 12.868a.6.6 0 0 1 0-.848l4.524-4.524a.6.6 0 0 1 .848 0Z" />
  </svg>
);

Logout.defaultProps = {
  width: 25,
  height: 24,
  color: colors.primary,
};

Logout.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Logout);
