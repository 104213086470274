import React, { memo } from 'react';
import PropTypes from 'prop-types';

const MessagesColor = (props) => (
  <svg viewBox="0 0 28 25" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.color}
        d="M13.36 10.741h13.418v8.914H24.86l-.638 3.82-4.473-3.82h-6.39z"
      />
      <path
        d="M26.778 10.096c.307 0 .555.248.555.554v8.975a.555.555 0 0 1-.555.553h-1.666v3.934a.556.556 0 0 1-.873.455l-.055-.044-4.842-4.345h-5.898a.555.555 0 0 1-.55-.484l-.005-.07V10.65c0-.306.249-.554.555-.554Zm-.555 1.107H14v7.867l5.556.001c.11 0 .216.032.307.092l.064.05 4.074 3.655L24 19.625c0-.283.212-.516.486-.55l.07-.004h1.667v-7.868ZM22.333 0c.284 0 .517.211.552.484l.004.07v7.292a.555.555 0 0 1-1.107.069l-.004-.07V1.108h-20v12.355h2.778c.283 0 .517.211.55.484l.005.07v3.138l3.494-3.527a.556.556 0 0 1 .321-.16L9 13.462h1.111a.555.555 0 0 1 .07 1.103l-.07.004h-.879l-4.281 4.323a.556.556 0 0 1-.947-.32L4 18.502V14.57H1.222a.555.555 0 0 1-.551-.484l-.004-.07V.555c0-.283.212-.516.486-.55L1.223 0h21.11Z"
        fill="#042238"
      />
    </g>
  </svg>
);

MessagesColor.defaultProps = {
  width: 28,
  height: 25,
  color: '#7FE3FF',
};

MessagesColor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(MessagesColor);
