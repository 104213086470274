export const AIAuditCategoriesEnum = {
  ESSENTIAL_LEASE_INFORMATION: 'Essential Lease Information',
  RENT_LATE_FEES_AND_SECURITY_DEPOSITS: 'Rent, Late Fees and Security Deposits',
  GENERAL_LEASE_PROVISIONS: 'General Lease Provisions',
  MANDATORY_DISCLOSURES: 'Mandatory Disclosures',
  CONTRACTUAL_TERMS: 'Contractual Terms',
  FAIR_HOUSING: 'Fair Housing',
};

export const categoriesOrder = Object.values(AIAuditCategoriesEnum);

export const AuditItemIncludedEnum = {
  YES: 'yes',
  NO: 'no',
  INDEFINITE: 'indefinite',
};

export const AuditToolRatingEnum = {
  UP: 'up',
  DOWN: 'down',
};
