import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RadioGroup from '../../../../../../../components/RadioGroup';
import PoolIcon from '../../../../../../../icons/Pool';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './PoolDisclosureAZ.module.scss';

const PoolDisclosureAZ = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Pool Disclosure" icon={PoolIcon} />
      <span className={styles.label}>Is there a pool on the premises?</span>
      <p className={styles.description}>
        Arizona law requires that you disclose this and provide{' '}
        <a
          className={styles.link}
          href="https://www.azdhs.gov/documents/preparedness/epidemiology-disease-control/environmental-health/residential-pool-safety-notice.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          this educational material
        </a>{' '}
        on pool safety if a pool is on the premises. If yes, we will attach it
        to the lease agreement for you.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.has_pool"
        name="custom_state_data.has_pool"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'has_pool_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'has_pool_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
    </div>
  );
};

PoolDisclosureAZ.propTypes = {
  className: PropTypes.string,
};

export default PoolDisclosureAZ;
