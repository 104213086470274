import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './SectionPeopleOnTheLease.module.scss';

export const SectionPeopleOnTheLeaseAdditionalLandlordSection = ({
  hasAdditionalLandlord,
  children,
}) => {
  return (
    <div
      className={cx(styles.column, styles.section, {
        [styles.midSpacing]: !hasAdditionalLandlord,
      })}
    >
      {children}
    </div>
  );
};

SectionPeopleOnTheLeaseAdditionalLandlordSection.propTypes = {
  hasAdditionalLandlord: PropTypes.bool,
  children: PropTypes.node,
};
