import { get } from 'lodash';

import { COOKIE_NAMES, getCookies, setCookie } from './cookies';

export const setExperimentGbbCookie = (value) => {
  const oneYear = 1000 * 60 * 60 * 24 * 365;

  setCookie(COOKIE_NAMES.EXPERIMENT_GBB, value, oneYear);
};

export const getExperimentGbbCookie = () => {
  const cookies = getCookies();
  const value = get(cookies, COOKIE_NAMES.EXPERIMENT_GBB, null) || null;

  return value;
};
