import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import RadiationIcon from '../../../../../../../icons/Radiation';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './RadonDisclosureCO.module.scss';

const RadonDisclosureCO = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <>
      <div className={cx(styles.container, className)}>
        <SectionTitle title="Radon Disclosure" icon={RadiationIcon} />
        <span className={styles.title}>
          Are you aware of radon concentrations or do you know if any radon
          tests have been performed on the property?
        </span>
        <p className={styles.description}>
          Colorado law requires that you disclose this and provide additional
          detail if relevant. As is required by law, a warning on the dangers of
          radon will appear in the lease agreement. You are also required to
          provide{' '}
          <a
            className={styles.link}
            href="https://www.epa.gov/sites/default/files/2016-12/documents/2016_a_citizens_guide_to_radon.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            this pamphlet
          </a>{' '}
          on advice about radon, we will attach it to the lease agreement for
          you as well.
        </p>
        <Field
          component={RadioGroup}
          id="custom_state_data.radon_hazard"
          name="custom_state_data.radon_hazard"
          radiosContainerClassName={styles.radioButtonsContainer}
          options={[
            {
              id: 'radon_hazard_yes',
              value: true,
              label: 'Yes',
            },
            {
              id: 'radon_hazard_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />
      </div>
      <Condition when="custom_state_data.radon_hazard" is={true}>
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.radon_hazard_explanation"
          name="custom_state_data.radon_hazard_explanation"
          label={
            <div className={styles.labelContainer}>
              <span className={styles.labelTitle}>
                If you are aware of any radon concentrations or if tests have
                been performed on the property, please provide the following
                information:
              </span>
              <ol>
                <li>What radon tests have been conducted?</li>
                <li>
                  What the results were- be sure to include the most current
                  records and reports pertaining to radon concentrations. You
                  may attach them in the “Provisions & Attachments” section.
                </li>
                <li>
                  A description of any radon concentrations deleted, mitigated
                  or remediated;
                </li>
                <li>
                  Information and description of any radon mitigation system
                  installed on the property.
                </li>
              </ol>
            </div>
          }
          labelProps={{
            className: styles.radonLabel,
          }}
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      </Condition>
    </>
  );
};

RadonDisclosureCO.propTypes = {
  className: PropTypes.string,
};

export default RadonDisclosureCO;
