import { Experiments } from '../../constants/experiments';

const checkIfLandlordPaysExperimentIsTest = (obj = []) => {
  const targetExperiments = [
    Experiments.LandlordPaysSpecificStates.name,
    Experiments.LandlordPaysOtherStates.name,
  ];

  if (!obj.some((item) => targetExperiments.includes(item.name))) {
    return null;
  }

  return targetExperiments.some((expName) =>
    obj.some(
      (item) =>
        item.name === expName &&
        (item.value === Experiments.LandlordPaysSpecificStates.variants.test ||
          item.value === Experiments.LandlordPaysOtherStates.variants.test),
    ),
  );
};

export default checkIfLandlordPaysExperimentIsTest;
