import React from 'react';
import cx from 'classnames';

import styles from './common.module.scss';

const Line = ({ size, title, breakpoint, hideSmall, expandSmall }) => {
  return (
    <div
      className={cx(styles.skeletonLine, styles[size], {
        [styles.title]: title,
        [styles.breakpoint]: breakpoint,
        [styles.hideSmall]: hideSmall,
        [styles.expandSmall]: expandSmall,
      })}
    />
  );
};

export default Line;
