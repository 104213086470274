import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const MarketingSlim = (props) => (
  <svg {...props} viewBox="0 0 40 41">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.146 5.155c.54 0 .976.43.976.96v4.803c0 .53-.437.96-.976.96l-8.781-.001v6.483l10 .001a.97.97 0 0 1 .969.84l.008.12-.002 6.242 2.684.002c.485 0 .888.348.963.804l.013.156v6.003c0 .53-.437.96-.976.96h-6.097a.968.968 0 0 1-.976-.96v-6.003c0-.53.437-.96.976-.96l1.463-.002v-5.281h-9.025v5.281l2.684.002c.485 0 .887.348.963.804l.012.156v6.003c0 .53-.436.96-.975.96H16.95a.968.968 0 0 1-.975-.96v-6.003c0-.53.436-.96.975-.96l1.462-.002v-5.281H10.61l-.002 5.281 1.465.002c.485 0 .887.348.963.804l.013.156v6.003c0 .53-.437.96-.976.96H5.976a.968.968 0 0 1-.976-.96v-6.003c0-.53.437-.96.976-.96l2.682-.002v-6.241c0-.49.373-.894.854-.953l.122-.008 8.78-.001v-6.483l-7.56.001a.971.971 0 0 1-.963-.805l-.013-.155V6.115c0-.53.437-.96.976-.96h18.292Zm-7.073 22.33h-4.146v4.082h4.146v-4.082Zm-10.975 0H6.95v4.082h4.147v-4.082Zm21.95 0h-4.146v4.082h4.147v-4.082Zm-4.877-20.41H11.829v2.882h16.342V7.076Z"
      fill={colors.text}
    />
  </svg>
);

MarketingSlim.defaultProps = {
  color: colors.text,
};

MarketingSlim.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(MarketingSlim);
