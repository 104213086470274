import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { parse } from 'query-string';

import { isLoggedIn } from '../core/auth/authService';
import demoAppQuery from '../graphql/queries/demoApp.graphql';
import publicListingQuery from '../graphql/queries/publicListing.graphql';

const useListingFetcher = () => {
  return useCallback((listingId, isDemo) => {
    let idOrSlug = listingId;

    const { source } = parse(location.search);

    if (idOrSlug && !`${idOrSlug}`.match(/^\d+$/g)) {
      idOrSlug = atob(idOrSlug).split(':').pop();
    }
    const { data, loading, error } = useQuery(
      isDemo ? demoAppQuery : publicListingQuery,
      {
        variables: {
          idOrSlug,
          source,
        },
        skip: isDemo && !isLoggedIn(),
      },
    );

    let parsedData = {};

    if (isDemo) {
      parsedData = {
        ...data?.userData?.basicProfile?.data,
        id: 'demo',
      };
    } else {
      parsedData = data?.viewer?.publicListing;
    }

    return { data: parsedData, loading, error };
  });
};

export default useListingFetcher;
