import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import HouseCondition from '../../../../../../../icons/HouseCondition';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './MoldDisclosureDC.module.scss';

const MoldDisclosureDC = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle
        title="Mold Disclosure"
        icon={HouseCondition}
        className={styles.sectionTitle}
      />
      <label className={styles.label}>
        Is there any known history of presence of indoor mold contamination in
        premises or within common areas for past 3 years?{' '}
      </label>
      <p className={styles.description}>
        District of Columbia law requires that you disclose any known history of
        the presence of mold contamination for the past three years within the
        interior or common areas of the property.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.has_mold"
        name="custom_state_data.has_mold"
        className={styles.radioWrapper}
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'has_mold_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'has_mold_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition when="custom_state_data.has_mold" is={true}>
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.has_mold_explanation"
          name="custom_state_data.has_mold_explanation"
          label="Please list any and all known mold presence within the last 3 years in the interior or common area(s):"
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
            className: styles.termsField,
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      </Condition>
    </div>
  );
};

MoldDisclosureDC.propTypes = {
  className: PropTypes.string,
};

export default MoldDisclosureDC;
