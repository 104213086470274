import React from 'react';
import useWindowSize from '@rooks/use-window-size';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import Logo from '../../../components/Logo';
import Modal from '../../../components/Modal';
import { Link, Paragraph } from '../../../components/Typography';
import { smallScreenWidth } from '../../../constants/media-breakpoints';
import openInNewTab from '../../../helpers/openInNewTab';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';

import phone from './assets/phone.svg';
import landLordPhone from './assets/phone-landlord.svg';

import styles from './DownloadAppModal.module.scss';

const DOWNLOAD_APP_MODAL = 'download_app_modal';

const LANDLOR_LOGIN_ANALYTICS_LOCATION =
  'Mobile Download Page - Landlord Login';
const RENTER_LOGIN_ANALYTICS_LOCATION = 'Mobile Download Page - Renter Login';

const RENTER_TITLE = 'Simpler renting – on the go.';
const LANDLORD_TITLE = 'Manage your rentals from anywhere';

const DownloadAppModal = ({ isRenter }) => {
  const { innerWidth, innerHeight } = useWindowSize();
  const isMobile = innerWidth <= smallScreenWidth;
  const [modalVisible, setModalVisible] = useLocalStorage(
    DOWNLOAD_APP_MODAL,
    true,
  );
  const config = useConfig();

  const location = isRenter
    ? RENTER_LOGIN_ANALYTICS_LOCATION
    : LANDLOR_LOGIN_ANALYTICS_LOCATION;

  const deepLink = isRenter
    ? config.RENTER_MOBILE_APP_DOWNLOAD_URL
    : config.LL_MOBILE_APP_DOWNLOAD_URL;

  const onPressClose = () => {
    segmentTracking('dismiss clicked', { location });
    setModalVisible(false);
  };

  const onPressGoToApp = () => {
    segmentTracking('get_the_app clicked', { location });
    openInNewTab(deepLink);
    setModalVisible(false);
  };

  const onPressLogin = () => {
    segmentTracking('log_in_online clicked', { location });
    setModalVisible(false);
  };

  return (
    <Modal
      open={modalVisible && isMobile}
      mobileFullScreen
      className={styles.modalCard}
      showCloseIcon
      onClose={onPressClose}
    >
      <div className={styles.container} style={{ height: innerHeight }}>
        <div className={styles.topSection}>
          <div className={styles.spacer} />
          <Logo color="white" />
          <Paragraph className={styles.topSectionTitle}>
            {isRenter ? RENTER_TITLE : LANDLORD_TITLE}
          </Paragraph>
          <img
            src={isRenter ? phone : landLordPhone}
            className={styles.phone}
          />
        </div>
        <div className={styles.bottomSection}>
          <Button className={styles.goToAppButton} onClick={onPressGoToApp}>
            Go to App
          </Button>
          <Link onClick={onPressLogin}>Log in Online</Link>
        </div>
      </div>
    </Modal>
  );
};

DownloadAppModal.propTypes = {
  isRenter: PropTypes.bool.isRequired,
};

export default DownloadAppModal;
