const CITY_REGEX = /[^A-Za-z'.-\s]/g;

export const validShareAbleCity = (value) => {
  if (!value) {
    return undefined;
  }

  if (value.length < 2 || value.length > 27) {
    return 'Must be 2-27 characters';
  }

  if (CITY_REGEX.test(value)) {
    return "It's not valid city name";
  }

  return undefined;
};

export default validShareAbleCity;
