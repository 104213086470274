import React, { useEffect } from 'react';
import { useLottie } from 'lottie-react';
import PropTypes from 'prop-types';

import IframeAnimation from '../../../components/Animations/iOSIframeAnimation';
import isiOSDevice from '../../../helpers/isIosDevice';

import animationPathJson from './UALAAnimation.animation.json';

const UALAAnimation = ({ style = {}, onComplete }) => {
  const ANIMATION_DURATION = 4000; // 4 seconds
  const iOS = isiOSDevice();

  useEffect(() => {
    if (onComplete && iOS) {
      setTimeout(() => {
        onComplete();
      }, ANIMATION_DURATION);
    }
  }, []);

  if (iOS) {
    return (
      <IframeAnimation src="https://lottie.host/?file=29146e05-7ef8-4e35-8429-c007c8a3e6ac/uVRE0f0eOy.json" />
    );
  }

  const options = {
    path: animationPathJson,
    loop: false,
    autoplay: true,
    onComplete,
  };

  const LoaderData = useLottie(options, style);
  return LoaderData.View;
};

UALAAnimation.propTypes = {
  style: PropTypes.object,
  onComplete: PropTypes.func,
};

export default UALAAnimation;
