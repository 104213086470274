import React, { useCallback, useContext, useState } from 'react';
import ms from 'ms';

import ToastList from './ToastList';

const ToastContext = React.createContext({
  addToast: () => console.error('Not in the ToastContext'),
  removeToast: () => console.error('Not in the ToastContext'),
});

const DEFAULT_TIMEOUT = ms('3s');

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const addToast = useCallback((newToast) => {
    const id = Date.now();
    setToasts((oldToasts) => [
      ...oldToasts,
      {
        timeout: DEFAULT_TIMEOUT,
        ...newToast,
        id,
      },
    ]);
    return id;
  }, []);
  const removeToast = useCallback((id) => {
    setToasts((oldToasts) => oldToasts.filter((t) => t.id !== id));
  }, []);

  return (
    <ToastContext.Provider
      value={{
        addToast,
        removeToast,
      }}
    >
      <>
        <ToastList toasts={toasts} />
        {children}
      </>
    </ToastContext.Provider>
  );
};

export const useShowToast = () => {
  const { addToast } = useContext(ToastContext);
  return useCallback((toast) => {
    return addToast(toast);
  });
};

export const useInfoToast = () => {
  const showToast = useShowToast();
  return useCallback((message, options = {}) => {
    return showToast({
      ...options,
      message,
      type: 'info',
    });
  });
};

export const useWarningToast = () => {
  const showToast = useShowToast();
  return useCallback((message, options = {}) => {
    return showToast({
      ...options,
      message,
      type: 'warning',
    });
  });
};

export const useSuccessToast = () => {
  const showToast = useShowToast();
  return useCallback((message, options = {}) => {
    return showToast({
      ...options,
      message,
      type: 'success',
    });
  });
};

export const useErrorToast = () => {
  const showToast = useShowToast();
  return useCallback((message, options = {}) => {
    return showToast({
      ...options,
      message,
      type: 'error',
    });
  });
};

export const useHideToast = (id) => {
  const { removeToast } = useContext(ToastContext);
  return useCallback(() => {
    removeToast(id);
  });
};
