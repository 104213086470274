import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useCombobox } from 'downshift';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';

import Label from '../Label';
import PlainInput from '../PlainInput';

import styles from './PlainComboBox.module.scss';
const PlainComboBox = ({
  disabled,
  getOptions,
  onSelected,
  itemToString,
  label,
  labelProps,
  placeholder,
  className,
  containerClassName,
  menuClassName,
  highlightClassName,
  style,
  onChange,
  onBlur,
  onFocus,
  value,
  name,
  debounce = 400,
  error,
  'data-qa': dataQa,
}) => {
  const [currentValue, setCurrentValue] = useState(value || '');

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const [options, setOptions] = useState([]);
  const search = useDebouncedCallback(async (query) => {
    let results = [];
    try {
      results = await getOptions(query);
    } catch (e) {
      // ignore error
    }
    setOptions(results);
  }, debounce);

  const items = options || [];
  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items,
    itemToString,
    inputValue: currentValue,
    onSelectedItemChange: ({ selectedItem }) => {
      setCurrentValue(itemToString(selectedItem));
      onSelected(selectedItem);
    },
    onInputValueChange: ({ inputValue, selectedItem }) => {
      if (selectedItem && itemToString(selectedItem) === inputValue) {
        return null;
      }
      onChange(inputValue);
      search(inputValue);
    },
  });
  return (
    <div
      className={cx(styles.wrap, className, {
        [styles.disabled]: disabled,
      })}
      style={style}
    >
      {label && (
        <Label {...labelProps} {...getLabelProps()}>
          {label}
        </Label>
      )}
      <div
        className={cx(styles.container, containerClassName)}
        {...getComboboxProps()}
      >
        <PlainInput
          disabled={disabled}
          name={name}
          autoComplete="off"
          invalid={!!error}
          {...getInputProps({
            onFocus,
            onBlur,
            placeholder,
            onKeyDown: (event) => {
              // There is an issue with downshift not allowing
              // submitting the form when there are no items to select
              // so to fix that we are changing downshift default behaviour
              // https://github.com/downshift-js/downshift#event-handlers
              if (event.key === 'Enter' && items.length === 0) {
                event.nativeEvent.preventDownshiftDefault = true;
              }
            },
            onChange: (e) => {
              setCurrentValue(e.target.value);
              onChange(e);
            },
          })}
          data-qa={dataQa}
        />
      </div>
      <ul
        {...getMenuProps()}
        className={cx(styles.menu, menuClassName, {
          [styles.open]: isOpen && options && options.length > 0,
        })}
      >
        {isOpen && options
          ? options.map((option, index) => (
              <li
                className={cx({
                  [styles.highlight]: highlightedIndex === index,
                  [highlightClassName]: highlightedIndex === index,
                })}
                key={`${option}${index}`}
                {...getItemProps({ item: option, index })}
              >
                {itemToString(option)}
              </li>
            ))
          : null}
      </ul>
      {error ? <div className={styles.error}>{error}</div> : null}
    </div>
  );
};
PlainComboBox.propTypes = {
  disabled: PropTypes.bool,
  getOptions: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  itemToString: PropTypes.func,
  label: PropTypes.string,
  labelProps: PropTypes.object,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  highlightClassName: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  debounce: PropTypes.number,
  error: PropTypes.string,
};
PlainComboBox.defaultProps = {
  itemToString: (item) => (item ? String(item) : ''),
};
export default PlainComboBox;
