import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';

import { useUserProfile } from '../../../../../core/TTgraphql';
import { segmentTracking } from '../../../../../services/utilities';

import BankWrapper from './BankWrapper';
import RentCollection from './RentCollection';

const CollectionWrapper = (props) => {
  const {
    onNext,
    onboarding,
    overrideBackButtonClick,
    bankStep,
    location,
    history,
  } = props;

  const { user } = useUserProfile({}, false);
  const [showBankStep, setShowBankStep] = useState(bankStep);
  const queryParams = parse(location.search, { parseBooleans: true });

  const { manually, ...restQueryParams } = queryParams;

  const isCustomer = user.is_customer;

  useEffect(() => {
    if (manually) {
      overrideBackButtonClick(() => {
        const params = restQueryParams ? '?' + stringify(restQueryParams) : '';
        history.replace(location.pathname + params);
      });
    } else if (showBankStep) {
      overrideBackButtonClick(() => {
        setShowBankStep(false);
      });
    } else {
      overrideBackButtonClick(null);
    }
    return () => {
      overrideBackButtonClick(null);
    };
  }, [showBankStep, manually]);

  const onBankNext = async () => {
    if (onboarding) {
      segmentTracking('bank_step submit', {
        location: 'rp onboarding bank step',
      });
    } else {
      if (isCustomer) {
        segmentTracking('bank_step submit', {
          location: 'rp existing bank step lr',
        });
      } else {
        segmentTracking('bank_step submit', {
          location: 'rp existing bank step hr',
        });
      }
    }
    onNext(showBankStep);
  };

  const onRentCollectionNext = async (data) => {
    const online = data.ONLINE;
    const offline = data.OFFLINE;

    let rent_collection_answer;

    if (online && offline) {
      rent_collection_answer = 'both';
    } else if (online) {
      rent_collection_answer = 'Online';
    } else {
      rent_collection_answer = 'Offline';
    }

    segmentTracking('next clicked', {
      location: onboarding
        ? 'rp onboarding collection'
        : 'rp existing collection',
      rent_collection_answer,
    });
    if (online) {
      setShowBankStep(data);
    } else if (offline) {
      onNext(data);
    }
  };

  if (showBankStep) {
    return <BankWrapper {...props} onNext={onBankNext} hideCancel />;
  }

  return <RentCollection onSubmit={onRentCollectionNext} />;
};

CollectionWrapper.propTypes = {
  onNext: PropTypes.func.isRequired,
  onboarding: PropTypes.bool,
  overrideBackButtonClick: PropTypes.func,
  bankStep: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default CollectionWrapper;
