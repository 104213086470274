import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { lightGreenAlt, primary } from '../../constants/colors';
import GraphColor from '../../icons/GraphColor';
import Location from '../../icons/Location';
import StarEmpty from '../../icons/StarEmpty';
import StarFilled from '../../icons/StarFilled';
import PaymentsNav from '../../icons/streamline/Payments';
import Time from '../../icons/Time';
import VerifiedBadge from '../../icons/VerifiedBadge';
import { useConfig } from '../../providers/ConfigProvider';
import Button from '../Button';

import styles from './LulaPromoCardB.module.scss';

const LulaPromoCardB = ({ city, className, onClick }) => {
  const { PRIVATE_BASE_PATH } = useConfig();

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.cardHeader}>
        <div className={styles.chLeft}>
          <h3>Hire a PRO</h3>
          <p>Our qualified Pros can help.</p>
        </div>
        <div className={styles.chRight}>
          <Button
            className={`${styles.cta} ${styles.ctaD}`}
            to={`${PRIVATE_BASE_PATH}maintenance/lula`}
            onClick={onClick}
          >
            Get it done
          </Button>
          <div className={styles.proPicM}></div>
        </div>
      </div>
      <div className={styles.cardBody}>
        <div className={styles.cbLeft}>
          <div className={styles.proPic}></div>
        </div>
        <div className={styles.cbRight}>
          <div className={styles.verifiedPros}>
            <VerifiedBadge color={primary} />
            Verified Pros
          </div>

          <div className={styles.rating}>
            <StarFilled
              className={styles.star}
              color={lightGreenAlt}
              width={20}
              height={20}
            />
            <StarFilled
              className={styles.star}
              color={lightGreenAlt}
              width={20}
              height={20}
            />
            <StarFilled
              className={styles.star}
              color={lightGreenAlt}
              width={20}
              height={20}
            />
            <StarFilled
              className={styles.star}
              color={lightGreenAlt}
              width={20}
              height={20}
            />
            <div className={styles.combinedStar}>
              <StarEmpty
                className={styles.emptyStar}
                color={lightGreenAlt}
                width={20}
                height={20}
              />
              <StarFilled
                className={styles.star75}
                color={lightGreenAlt}
                width={20}
                height={20}
              />
            </div>
            <div className={styles.stats}>
              4.74 <span>(6K+)</span>
            </div>
          </div>
          <div className={styles.items}>
            <div className={styles.itemRow}>
              <div className={styles.item}>
                <Location width={14} height={14} /> Serves {city}
              </div>
              <div className={styles.item}>
                <Time width={17} height={17} /> 24/7 Troubleshooting
              </div>
            </div>
            <div className={styles.itemRow}>
              <div className={styles.item}>
                <GraphColor transparent width={15} height={15} /> In high demand
              </div>
              <div className={styles.item}>
                <PaymentsNav
                  width={14}
                  height={14}
                  color={primary}
                  strokeWidth={0.5}
                />{' '}
                Fair pricing
              </div>
            </div>
          </div>
          <Button
            className={`${styles.cta} ${styles.ctaM}`}
            to={`${PRIVATE_BASE_PATH}maintenance/lula`}
            onClick={onClick}
          >
            Get it done
          </Button>
        </div>
      </div>
    </div>
  );
};

LulaPromoCardB.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  city: PropTypes.string,
};

export default LulaPromoCardB;
