import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Content from './components/Content';
import Footer from './components/Footer';
import Icon from './components/Icon';

import styles from './Card.module.scss';

const Card = React.forwardRef(
  (
    {
      children,
      noBorder,
      className,
      blue,
      style,
      padded = false,
      ...otherProps
    },
    ref,
  ) => (
    <div
      data-qa={otherProps.dataQa}
      ref={ref}
      className={cx(
        styles.card,
        {
          [styles.blue]: blue,
          [styles.padded]: padded,
          [styles.noBorder]: noBorder,
        },
        className,
      )}
      style={style}
      {...otherProps}
    >
      {children}
    </div>
  ),
);

Card.displayName = 'Card';

Card.propTypes = {
  blue: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  padded: PropTypes.bool,
  media: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.alt,
  }),
  dataQa: PropTypes.string,
  noBorder: PropTypes.bool,
};

Card.Content = Content;
Card.Icon = Icon;
Card.Footer = Footer;

export default Card;
