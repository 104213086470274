import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { getSignupTrackingCookie } from '../helpers/signupTrackingData';
import { isSegmentAllowedRoute } from '../services/utilities';

const SegmentSetup = () => {
  const history = useHistory();

  const registerPageEvent = useCallback((pathname) => {
    if (isSegmentAllowedRoute(pathname)) {
      //grab values from cookie and send them to segment
      const { source, medium, campaign, content, term } =
        getSignupTrackingCookie();
      window.analytics &&
        window.analytics.page(
          null,
          null,
          {
            utm_source: source,
            utm_medium: medium,
            utm_campaign: campaign,
            utm_content: content,
            utm_term: term,
          },
          {
            context: {
              campaign: {
                source,
                medium,
                campaign,
                content,
                term,
                name: campaign,
              },
            },
          },
        );
    }
  }, []);

  useEffect(() => {
    // register first time page loads
    registerPageEvent(location.pathname);

    // register each time page change
    const unlisten = history.listen(() => {
      if (window.analytics) {
        registerPageEvent(location.pathname);
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  return <></>;
};

export default SegmentSetup;
