import React from 'react';
import moment from 'moment';

import getFormattedDateLocalTZ from '../../helpers/format-date-local';
import isDate from '../../validators/isDate';
import { default as UnderlyingDateField } from '../DateField';

import CoreField from './CoreField';

const DATE_FORMAT = 'YYYY-MM-DD';

const DateField = ({ validate = [], name, ...props }) => {
  const sanitizeDate = (date) => {
    if (date === null || date === '') return '';

    const isValidDate = moment(date, DATE_FORMAT, true).isValid();

    return isValidDate ? getFormattedDateLocalTZ(date) : date;
  };

  return (
    <CoreField
      name={name}
      component={UnderlyingDateField}
      type="text"
      validate={[
        (value) => isDate(getFormattedDateLocalTZ(value)),
        ...(Array.isArray(validate) ? validate : [validate]),
      ]}
      placeholder={'MM/DD/YYYY'}
      format={sanitizeDate}
      parse={sanitizeDate}
      {...props}
    />
  );
};

export default DateField;
