import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Leads = (props) => (
  <svg {...props} viewBox="0 0 44 38" fill={props.color}>
    <path d="M28.519 12.773c.63 0 1.14.5 1.14 1.118v8.843c0 2.193-1.597 4.032-3.725 4.525v9.623A1.13 1.13 0 0124.794 38h-5.588c-.63 0-1.14-.5-1.14-1.118V27.26c-2.129-.493-3.725-2.332-3.725-4.525V13.89c0-.618.51-1.118 1.14-1.118H28.52zm-1.141 2.235H16.622v7.726c0 1.32 1.157 2.42 2.584 2.42.63 0 1.141.5 1.141 1.117v9.494h3.306V26.27c0-.617.51-1.118 1.14-1.118 1.427 0 2.585-1.1 2.585-2.42v-7.725zm-17.111-2.235c.63 0 1.14.5 1.14 1.118v5.536c-.002 1.904-.515 3.246-1.825 3.708v7.36a1.13 1.13 0 01-1.14 1.118H2.965c-.63 0-1.14-.5-1.14-1.117v-7.361C.513 22.673 0 21.33 0 19.425v-5.534c0-.618.51-1.118 1.14-1.118h9.127zm32.592 0c.63 0 1.141.5 1.141 1.118v5.536c-.002 1.904-.516 3.246-1.825 3.708v7.36a1.13 1.13 0 01-1.14 1.118h-5.476c-.63 0-1.141-.5-1.141-1.117v-7.361c-1.312-.462-1.825-1.805-1.825-3.71v-5.534c0-.618.51-1.118 1.14-1.118h9.126zM9.126 15.008H2.28v4.418c0 1.31.182 1.65.685 1.65.63 0 1.14.5 1.14 1.117v7.185h3.195v-7.185c0-.617.51-1.117 1.14-1.117.501 0 .683-.342.685-1.65v-4.418zm32.593 0h-6.845v4.418c0 1.31.182 1.65.685 1.65.63 0 1.14.5 1.14 1.117v7.185h3.194v-7.185c0-.617.511-1.117 1.141-1.117.5 0 .683-.342.685-1.65v-4.418zM5.052 3.832c2.07 0 3.748 1.645 3.748 3.672 0 2.028-1.679 3.672-3.748 3.672-2.07 0-3.748-1.644-3.748-3.672 0-2.027 1.679-3.672 3.748-3.672zm32.592 0c2.07 0 3.749 1.645 3.749 3.672 0 2.028-1.68 3.672-3.749 3.672s-3.748-1.644-3.748-3.672c0-2.027 1.68-3.672 3.748-3.672zM21.348 0c3.15 0 5.704 2.501 5.704 5.588s-2.553 5.588-5.704 5.588c-3.15 0-5.704-2.501-5.704-5.588S18.198 0 21.348 0zM5.052 6.067c-.81 0-1.467.644-1.467 1.437s.658 1.437 1.467 1.437c.81 0 1.467-.644 1.467-1.437S5.86 6.067 5.052 6.067zm32.592 0c-.809 0-1.466.644-1.466 1.437s.657 1.437 1.466 1.437c.81 0 1.467-.644 1.467-1.437s-.657-1.437-1.467-1.437zM21.348 2.235c-1.89 0-3.422 1.5-3.422 3.353 0 1.853 1.532 3.353 3.422 3.353 1.89 0 3.422-1.5 3.422-3.353 0-1.852-1.531-3.353-3.422-3.353z" />
  </svg>
);

Leads.defaultProps = {
  width: 44,
  height: 38,
  color: colors.text,
};

Leads.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Leads);
