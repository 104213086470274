import { useCallback, useState } from 'react';

import { getImageURL, uploadFileToS3 } from '../services/cloudFilesService';

const useS3FileUploader = ({ onUploaded, bucket }) => {
  const [uploading, setUploading] = useState(false);

  const getUrlObjectFromS3Params = useCallback(({ url, urls }) => {
    if (!url && !urls) {
      console.warn('s3Params is not provided');
      return;
    }
    return url || urls[0];
  }, []);

  const upload = useCallback(
    (urlsFiles = []) =>
      Promise.all(
        urlsFiles.map(async (queuedFile) => {
          try {
            const urlObject = getUrlObjectFromS3Params(queuedFile.s3Params);

            await uploadFileToS3(urlObject, queuedFile.file).then((r) =>
              r.text(),
            );

            return {
              id: queuedFile.s3Params.id,
              file_url: `${urlObject.endpoint_url}/${encodeURIComponent(
                urlObject.params.key,
              )}`,
              filename: queuedFile.name,
              rotation: 0,
            };
          } catch (errorResponse) {
            console.error('Bad request', errorResponse);
          }
        }),
      ),
    [],
  );

  const onFileAdded = useCallback(async (file) => {
    const content = await getImageURL({
      fileName: file.name,
      type: file.type,
      imageType: bucket,
    });

    return {
      name: file.name,
      file,
      s3Params: JSON.parse(content),
    };
  }, []);

  const handleFileDrop = useCallback(
    async (files = []) => {
      try {
        if (files?.length === 0) return;

        setUploading(true);

        const urls = await Promise.all(Array.from(files)?.map(onFileAdded));
        const uploaded = await upload(urls);

        onUploaded(uploaded);
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        setUploading(false);
      }
    },
    [setUploading, onUploaded],
  );

  return [handleFileDrop, uploading];
};

export default useS3FileUploader;
