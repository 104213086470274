import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import MoldIcon from '../../../../../../../icons/Mold';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './MoldDisclosureVA.module.scss';

const MoldDisclosureVA = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Mold Disclosure" icon={MoldIcon} />
      <label className={styles.label}>
        Are there any visible signs of mold in the interior of the property?
      </label>
      <p className={styles.description}>
        Virginia law requires that you disclose any evidence of mold you can see
        with the naked eye within the interior of the property.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.has_mold"
        name="custom_state_data.has_mold"
        className={styles.radioWrapper}
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'has_mold_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'has_mold_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition when="custom_state_data.has_mold" is={true}>
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.has_mold_explanation"
          name="custom_state_data.has_mold_explanation"
          label="Please list any and all areas where mold is visibly present in the interior of the property (ex: hall bathroom above shower):"
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      </Condition>
    </div>
  );
};

MoldDisclosureVA.propTypes = {
  className: PropTypes.string,
};

export default MoldDisclosureVA;
