import React from 'react';

// create context with no upfront defaultValue
// without having to do undefined check all the time
const createCtx = (name, defaultValue = undefined) => {
  const ctx = React.createContext(defaultValue);

  // adding name makes it easier to debug in react devtools
  ctx.displayName = name;

  const useCtx = () => {
    const c = React.useContext(ctx);

    if (c === undefined) {
      throw new Error(`useCtx must be inside a ${name} Provider with a value`);
    }
    return c;
  };

  return [useCtx, ctx];
};

export default createCtx;
