import React, { useContext, useEffect, useState } from 'react';

import { getTestInitalStates } from './FlowPicker';

export const TestContext = React.createContext({});

export const TestProvider = ({
  children,
  setAdditionalSignupData,
  additionalSignupData,
  selected,
}) => {
  const initialState = getTestInitalStates();
  const [layoutControl, setLayoutControl] = useState({ ...initialState });

  useEffect(() => {
    if (!selected) setLayoutControl({ ...initialState, ...selected });
  }, [selected]);

  return (
    <TestContext.Provider
      value={{
        setAdditionalSignupData,
        additionalSignupData,
        layoutControl,
        setLayoutControl,
      }}
    >
      {children}
    </TestContext.Provider>
  );
};

export const useSetLayoutControll = ({
  hideUserOption,
  hideSignupForm,
  title,
  sideBar,
  sideBarTop,
  hideHR,
}) => {
  const { setLayoutControl } = useContext(TestContext);

  useEffect(() => {
    setLayoutControl({
      hideUserOption,
      hideSignupForm,
      title,
      sideBar,
      sideBarTop,
      hideHR,
    });
  }, [hideSignupForm, hideUserOption, title, sideBar, sideBarTop, hideHR]);

  return setLayoutControl;
};

export const useSetAdditionalSignupData = () => {
  const { setAdditionalSignupData } = useContext(TestContext);
  return (value) => setAdditionalSignupData(value);
};

export const useAdditionalSignupData = () => {
  const { additionalSignupData } = useContext(TestContext);
  return additionalSignupData;
};
