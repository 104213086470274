import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import { SUB_DIVISION_NAMES_KEYS } from '../../../../../../../constants/lease_agreement/sub-division-names';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from './MoveInFeeHelperWA.module.scss';

const moveInFeeDivisionTexts = {
  [SUB_DIVISION_NAMES_KEYS.tacoma]: `Move-in fees cannot exceed 25% of the first month's rent.`,
  [SUB_DIVISION_NAMES_KEYS.seattle]: `Move-in fees cannot exceed 25% of the first month's rent.`,
};

const MoveInFeeHelperWA = ({ className, leaseAgreementSelection }) => {
  const moveInFeeText = moveInFeeDivisionTexts[leaseAgreementSelection];

  return (
    <HelpfulInfoCard
      Icon={ResourcesIcon}
      className={cx(styles.helpfulInfoCard, className)}
      title="Move-In Fees"
      collapsable
    >
      <p className={styles.desc}>{moveInFeeText}</p>
    </HelpfulInfoCard>
  );
};

MoveInFeeHelperWA.propTypes = {
  className: PropTypes.string,
  leaseAgreementSelection: PropTypes.string,
};

export default MoveInFeeHelperWA;
