const SUPPORTED_FILE_TYPES = [
  '.csv',
  '.xlsx',
  '.xls',
  '.jpg',
  '.jpeg',
  '.gif',
  '.bmp',
  '.png',
  '.pdf',
  '.docx',
  '.doc',
  '.mp3',
  '.webm',
  '.mp4',
  '.wexbim',
  '.txt',
  '.zip',
  '.rar',
];

export default SUPPORTED_FILE_TYPES;
