import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import iOS from 'is-ios';
import isSafari from 'is-safari';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { useConfig } from '../../providers/ConfigProvider';
import * as localStorageService from '../../services/localStorageService';
import { gtmDataLayerPush, segmentTracking } from '../../services/utilities';

import IOSPWAPrompt from './IOSPWAPrompt';
import isPWA from './isPWA';
import NativePWAPrompt from './NativePWAPrompt';
import useAddToHomescreenPrompt from './useAddToHomescreenPrompt';

if ('serviceWorker' in navigator) {
  console.log('Registering service worker <<<<<');
  try {
    const registration = await navigator.serviceWorker.register(
      '/service-worker.js',
      {
        scope: '.',
      },
    );
    console.log({ registration });
  } catch (e) {
    console.error('Error registering service worker', e);
  }
}

const isOnboarding = (pathname) => {
  const onboardingPaths = [
    '/onboarding',
    '/renthop',
    '/upload-internalreview-documents',
    '/upload-stripe-verification-documents',
    '/tenant-onboarding',
  ];

  return onboardingPaths.some((path) => pathname.includes(path));
};

const AddAppHomeScreen = ({ location }) => {
  // make sure that this is called as soon as possible
  // so we hijack the propmt event and display our own
  // install prompt instead of default one provided
  // by the browser
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();

  const [iosPrompt] = useState(
    'ios-prompt' in queryString.parse(location.search),
  );
  const [androidPrompt] = useState(
    'android-prompt' in queryString.parse(location.search),
  );

  const config = useConfig();

  const isPremiumShowing = 'showPremium' in queryString.parse(location.search);

  // track pwa usage
  useEffect(() => {
    if (!isPWA()) {
      return;
    }
    if (!localStorageService.getItem('pwa-installed')) {
      segmentTracking('app_installed', {
        location: config.IS_OWNER ? 'Landlord' : 'Renter',
      });
      gtmDataLayerPush({
        event: 'gtmEvent',
        eventCategory: 'pwa',
        eventAction: 'install',
        eventLabel: 'install complete',
      });
      localStorageService.setItem('pwa-installed', true);
    }
    segmentTracking('app_opened', {
      location: config.IS_OWNER ? 'Landlord' : 'Renter',
    });
  }, []);

  /**
   * NOTE: We're disabling these PWA prompts for landlords
   * because we want to drive attention to our new mobile app
   */
  if (config.IS_OWNER) {
    return null;
  }

  /**
   * NOTE: We're disabling these PWA prompts for renters
   * because we want to drive attention to our new mobile app
   */
  if (config.IS_RENTER) {
    return null;
  }

  const onboarding = isOnboarding(location.pathname);
  if (onboarding || isPremiumShowing) {
    return null;
  }

  // Do not render on application flow
  const applicationRoute = /\/applications\/\d+\/\w+(\/\d+)?/.test(
    location.pathname,
  );
  if (applicationRoute) {
    return null;
  }

  if ((iOS && isSafari) || iosPrompt) {
    return <IOSPWAPrompt forced={iosPrompt} />;
  }

  return (
    <NativePWAPrompt
      prompt={prompt}
      forced={androidPrompt}
      promptToInstall={promptToInstall}
    />
  );
};

AddAppHomeScreen.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

export default withRouter(AddAppHomeScreen);
