import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Tenants = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 25" {...rest} fill={color}>
    <path d="M6.31 14.448a.6.6 0 0 1 .6-.6h4.13a.6.6 0 0 1 .6.6v2.479c0 .358-.034.795-.231 1.159a1.265 1.265 0 0 1-.595.56v3.237a.6.6 0 0 1-.6.6H7.736a.6.6 0 0 1-.6-.6v-3.236a1.24 1.24 0 0 1-.599-.563c-.195-.364-.227-.803-.227-1.157v-2.479Zm1.2.6v1.879c0 .323.038.503.085.59a.134.134 0 0 0 .018.027l.005.004s.03.018.118.018a.6.6 0 0 1 .6.6v3.117h1.278v-3.117a.6.6 0 0 1 .6-.6c.085 0 .114-.018.114-.018l.007-.005a.143.143 0 0 0 .019-.028c.047-.087.086-.269.086-.588v-1.879H7.51ZM8.979 10.918a.64.64 0 1 0 0 1.278.64.64 0 0 0 0-1.278Zm-1.84.64a1.84 1.84 0 1 1 3.68 0 1.84 1.84 0 0 1-3.68 0ZM12.37 14.448a.6.6 0 0 1 .6-.6h4.13a.6.6 0 0 1 .6.6v2.479c0 .358-.034.795-.231 1.159a1.265 1.265 0 0 1-.595.56v3.237a.6.6 0 0 1-.6.6h-2.478a.6.6 0 0 1-.6-.6v-3.236a1.24 1.24 0 0 1-.599-.563c-.195-.364-.227-.803-.227-1.157v-2.479Zm1.2.6v1.879c0 .323.038.503.085.59a.132.132 0 0 0 .018.027.028.028 0 0 0 .005.004s.03.018.118.018a.6.6 0 0 1 .6.6v3.117h1.278v-3.117a.6.6 0 0 1 .6-.6c.085 0 .114-.018.114-.018s.004-.002.006-.005a.143.143 0 0 0 .02-.028c.047-.087.086-.269.086-.588v-1.879h-2.93ZM15.039 10.918a.64.64 0 1 0 0 1.278.64.64 0 0 0 0-1.278Zm-1.84.64a1.84 1.84 0 1 1 3.68 0 1.84 1.84 0 0 1-3.68 0ZM4.978 12.196a.6.6 0 0 1 .6.6v9.087a.6.6 0 0 1-1.2 0v-9.087a.6.6 0 0 1 .6-.6ZM19.022 12.196a.6.6 0 0 1 .6.6v9.087a.6.6 0 0 1-1.2 0v-9.087a.6.6 0 0 1 .6-.6Z" />
    <path d="M11.595 3.266a.6.6 0 0 1 .81 0l9.5 8.674a.6.6 0 0 1-.81.886L12 4.522l-9.096 8.304a.6.6 0 0 1-.809-.886l9.5-8.674Z" />
  </svg>
);

Tenants.defaultProps = {
  width: 24,
  height: 25,
  color: colors.primary,
};

Tenants.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Tenants);
