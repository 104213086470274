import React, { memo } from 'react';
import PropTypes from 'prop-types';

const DownloadForms = (props) => (
  <svg {...props} viewBox="0 0 40 40">
    <g fill="none">
      <path
        fill="#7FE3FF"
        d="M1.667 31.667H25V35H1.667zM27.5 0h5.833v6.667h5v10H27.5z"
      />
      <path
        d="M6.667 18.333a.833.833 0 0 1 .15 1.654l-.15.013H3.333c-.856 0-1.56.642-1.655 1.472l-.011.195-.001 9.999H25v-10c0-.854-.643-1.559-1.472-1.655L23.333 20h-2.5a.833.833 0 0 1-.15-1.653l.15-.014h2.5a3.333 3.333 0 0 1 3.325 3.085l.009.249v11.666a3.335 3.335 0 0 1-3.085 3.325l-.249.009-9.167-.001v1.667h4.167a.833.833 0 0 1 .15 1.654l-.15.013h-10a.833.833 0 0 1-.15-1.653l.15-.014H12.5v-1.667H3.333A3.332 3.332 0 0 1 .01 33.583L0 33.333V21.667a3.331 3.331 0 0 1 3.084-3.325l.25-.009h3.333Zm18.333 15H1.667c0 .856.642 1.56 1.472 1.656l.194.011h20c.854 0 1.56-.644 1.656-1.472l.011-.195Zm-2.5-27.5a.833.833 0 0 1 0 1.667 7.497 7.497 0 0 0-7.492 7.147L15 15l-.001.488 1.912-1.91a.834.834 0 0 1 1.063-.097l.115.096c.29.29.322.739.097 1.064l-.097.115-3.343 3.343-.033.03-.006.004.037-.034-.059.051-.028.024a.834.834 0 0 1-.4.155l-.054.004H14.12l-.024-.003a.7.7 0 0 1-.073-.009l-.03-.005a.803.803 0 0 1-.301-.13l-.01-.007a.838.838 0 0 1-.098-.082l-.008-.008-3.333-3.333a.833.833 0 0 1 1.063-1.275l.116.096 1.91 1.911V15c0-5.065 4.101-9.167 9.167-9.167ZM34.167 0c.184 0 .362.061.506.172l.083.072 4.98 4.981a.838.838 0 0 1 .243.42l.008.039.002.017.008.066.003.066v10a.833.833 0 0 1-.729.827l-.104.007H27.5a.833.833 0 0 1-.827-.73l-.006-.104v-15c0-.425.318-.775.728-.827L27.5 0h6.667ZM32.5 1.666h-4.167V15h10V6.666h-5a.834.834 0 0 1-.82-.683l-.013-.15V1.666Zm1.666.345V5h2.989l-2.989-2.989Z"
        fill="#042238"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

DownloadForms.defaultProps = {
  width: 40,
  height: 40,
};

DownloadForms.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(DownloadForms);
