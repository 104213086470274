const validPassword = (value) => {
  const password = value.trim();

  if (!password || password.length < 8) {
    return 'Password should have at least 8 characters';
  }
  return undefined;
};

export default validPassword;
