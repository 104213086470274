import React, { memo } from 'react';

import colors from '../../../constants/colors';

const Radio = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 3a7 7 0 100 14 7 7 0 000-14z"
      fillRule="evenodd"
    />
  </svg>
);

Radio.defaultProps = {
  fill: colors.primary,
};

export default memo(Radio);
