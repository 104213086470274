import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Portfolio = ({ color, ...props }) => (
  <svg {...props} viewBox="0 0 32 32" fill="none">
    <g id="Portfolio">
      <g id="Shape">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.53336 5.5334C2.53336 5.09157 2.89154 4.7334 3.33336 4.7334H11.0435C11.4853 4.7334 11.8435 5.09157 11.8435 5.5334V6.9363H25.3623C25.8042 6.9363 26.1623 7.29447 26.1623 7.7363V9.13919H28.6667C29.1085 9.13919 29.4667 9.49737 29.4667 9.9392V23.1566C29.4667 25.4268 27.6326 27.2609 25.3623 27.2609H6.08699C4.12516 27.2609 2.53336 25.6691 2.53336 23.7073V5.5334ZM9.05546 25.6609H25.3623C26.7489 25.6609 27.8667 24.5432 27.8667 23.1566V10.7392H9.64061V23.7073C9.64061 24.4288 9.4253 25.1003 9.05546 25.6609ZM24.5623 9.13919H8.84061C8.39878 9.13919 8.04061 9.49737 8.04061 9.9392V23.7073C8.04061 24.7855 7.16516 25.6609 6.08699 25.6609C5.00881 25.6609 4.13336 24.7855 4.13336 23.7073V6.3334H10.2435V7.7363C10.2435 8.17812 10.6017 8.5363 11.0435 8.5363H24.5623V9.13919Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9294 15.9334C15.3712 15.9334 15.7294 16.2916 15.7294 16.7334V22.4145H21.796V16.7334C21.796 16.2916 22.1542 15.9334 22.596 15.9334C23.0379 15.9334 23.396 16.2916 23.396 16.7334V23.2145C23.396 23.6563 23.0379 24.0145 22.596 24.0145H14.9294C14.4875 24.0145 14.1294 23.6563 14.1294 23.2145V16.7334C14.1294 16.2916 14.4875 15.9334 14.9294 15.9334Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2484 12.5297C18.5495 12.268 18.9973 12.268 19.2983 12.5297L25.6317 18.037C25.9651 18.3269 26.0003 18.8322 25.7104 19.1656C25.4205 19.499 24.9152 19.5342 24.5818 19.2443L18.7734 14.1936L12.965 19.2443C12.6316 19.5342 12.1263 19.499 11.8364 19.1656C11.5465 18.8322 11.5817 18.3269 11.9151 18.037L18.2484 12.5297Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6267 18.8667C16.6267 18.4249 16.9849 18.0667 17.4267 18.0667H20.0934C20.5352 18.0667 20.8934 18.4249 20.8934 18.8667V22.8667C20.8934 23.3086 20.5352 23.6667 20.0934 23.6667H17.4267C16.9849 23.6667 16.6267 23.3086 16.6267 22.8667V18.8667ZM18.2267 19.6667V22.0667H19.2934V19.6667H18.2267Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

Portfolio.defaultProps = {
  width: 32,
  height: 32,
  color: '#1C4E7C',
};

Portfolio.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Portfolio);
