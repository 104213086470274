import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const CompanyThin = ({ color, ...props }) => (
  <svg {...props} viewBox="0 0 48 48" fill={color}>
    <path d="M42.14 1c.52 0 .995.301 1.228.778l3.816 7.831a1.419 1.419 0 0 1 .149.601l-.001.035v1.959c0 2.809-1.55 5.246-3.819 6.462v22.926c0 .707-.508 1.292-1.17 1.393L42.14 43H5.86c-.69 0-1.26-.521-1.358-1.2l-.015-.208V18.666C2.217 17.45.668 15.013.668 12.204v-1.96l-.001-.034c.004-.148.03-.3.084-.448l.039-.095-.026.06c.016-.04.033-.08.052-.118l3.816-7.83A1.37 1.37 0 0 1 5.86 1h36.28zm-5.529 17.167-.263.294c-1.619 1.701-3.849 2.83-6.13 2.972l-.49.016a8.825 8.825 0 0 1-5.653-2.055L24 19.327l-.075.067a8.834 8.834 0 0 1-5.132 2.04l-.521.015c-2.45 0-4.886-1.165-6.62-2.988l-.265-.294-.335.25c-.887.615-1.806.975-2.835 1.055l-.448.017c-.18 0-.36-.007-.537-.02v20.714H27.4V23.959c0-.732.545-1.334 1.24-1.402l.133-.006h9.547c.758 0 1.373.63 1.373 1.408v16.224h1.072l.002-20.714c-.177.013-.356.02-.537.02-1.214 0-2.267-.369-3.28-1.07l-.34-.252zm.336 7.198h-6.803v14.816h6.803V25.365zm-11.992-2.814c.758 0 1.372.63 1.372 1.408v11.754c0 .778-.614 1.409-1.372 1.409H9.679c-.758 0-1.373-.63-1.373-1.409V23.96c0-.778.615-1.408 1.373-1.408h15.276zm-1.374 2.814H11.05v8.94h12.53v-8.94zm11.064 6.331c.607 0 1.098.505 1.098 1.127 0 .624-.491 1.126-1.098 1.126-.606 0-1.098-.502-1.098-1.126 0-.622.492-1.127 1.098-1.127zM22.627 11.652h-9.668v4.04c1.167 1.67 3.137 2.842 5.067 2.934l.246.006a6.15 6.15 0 0 0 4.356-1.818l-.001-5.162zm12.412 0h-9.667l-.001 5.16a6.156 6.156 0 0 0 4.085 1.814l.272.006c2.007 0 4.097-1.198 5.313-2.94l-.002-4.04zm9.548 0h-6.803v3.864c.804.735 1.509 1.102 2.269 1.151l.178.006c2.406 0 4.356-2 4.356-4.47v-.551zm-34.371 0H3.413v.552c0 1.855 1.101 3.446 2.67 4.122.163.026.316.082.455.163.29.09.593.148.906.172l.325.012c.83 0 1.584-.366 2.447-1.157v-3.864zm1.524-7.837H6.707l-2.446 5.02 6.256-.001 1.223-5.019zm10.887 0h-8.058l-1.223 5.019h9.28V3.815zm10.804 0h-8.06v5.019h9.282L33.43 3.815zm7.86 0H36.26l1.223 5.019 6.254.001-2.446-5.02z" />{' '}
  </svg>
);

CompanyThin.defaultProps = {
  width: 46.67,
  height: 46.67,
  color: colors.text,
};

CompanyThin.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(CompanyThin);
