const normalizeLateFeeAmount = ({
  late_fee_amount,
  is_late_fee_percentage,
}) => {
  if (!late_fee_amount) {
    return null;
  }

  return is_late_fee_percentage
    ? late_fee_amount
    : (late_fee_amount / 100).toFixed(2).toString();
};

export default normalizeLateFeeAmount;
