import Decimal from 'jsdecimal';

import { PREMIUM_SUBSCRIPTION_STRIPE_PRODUCT_ID } from '../constants';

const discountPrice = (
  price,
  promoCode,
  productsList,
  currentUserPremiumPrice,
) => {
  let priceToApplyPercentageDiscount = price;

  if (productsList?.length > 1 && promoCode.applies_to?.products?.length) {
    // if there is more than one product inside productList we need to calculate the correct discounted amount to show in the UI
    // in the promoCode object we should receive applies_to.products to know products this promoCode can be applied to
    // new argument productsList that will contain product ids and product amoounts that are provided from the parent component that instantiates StripeElementsComponent

    const promoCodeProduct = promoCode.applies_to.products[0];
    // in stripe we can assign multiple products to one discount code
    // in practice we'll always assign one product to one discount code so we can rely on taking the first element in the applies_to.products array
    const canApplyPromoCode = !!productsList.find(
      (productId) => productId === promoCodeProduct.id,
    );
    // check if the promoCode can be applied to any prodct inside productsList

    if (canApplyPromoCode && !promoCode.amount_off) {
      // if promoCode can be applied and it's a percentage discount we need to reassign priceToApplyPercentageDiscount with the correct value
      // if the promoCode is amount_off we don't care -> calculation remains the same as it was before

      if (
        currentUserPremiumPrice &&
        promoCodeProduct.id === PREMIUM_SUBSCRIPTION_STRIPE_PRODUCT_ID
        // we're running a premium price test with 4 variations at the moment: $109, $119, $149, $199
        // if the promoCode is for premium subscription we need to apply it to the user's premium price test value instead of default premium price of $99
      ) {
        priceToApplyPercentageDiscount = currentUserPremiumPrice;
      } else {
        priceToApplyPercentageDiscount = Number(promoCodeProduct.amount);
      }
    }
  }

  let amountOff = promoCode.amount_off;
  if (amountOff) {
    amountOff = Decimal(amountOff).div(100).toFloat();
  } else {
    amountOff = Decimal(priceToApplyPercentageDiscount)
      .mul(promoCode.percent_off)
      .div(100)
      .toFloat();
  }

  return Decimal(price).sub(amountOff).toFloat();
};

export default discountPrice;
