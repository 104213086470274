import React, { memo } from 'react';
import PropTypes from 'prop-types';

const SaveTime = (props) => (
  <svg {...props} viewBox="0 0 16 20">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#7FE3FF"
        d="M4.667 8.333h6.666C10.223 9.444 9.111 10 8 10c-1.111 0-2.222-.556-3.333-1.667ZM3 16.667h2.5l2.5-2.5 2.5 2.5H13v2.5H3z"
      />
      <path
        fill="#042238"
        d="M15.083 0a.417.417 0 0 1 .075.827l-.075.006h-1.25v3.75A5.836 5.836 0 0 1 10.168 10a5.835 5.835 0 0 1 3.665 5.417v3.749h1.25a.417.417 0 0 1 .075.827l-.075.007H.917a.417.417 0 0 1-.075-.827l.075-.006 1.249-.001v-3.75A5.835 5.835 0 0 1 5.832 10a5.832 5.832 0 0 1-3.664-5.417L2.166.833H.916A.417.417 0 0 1 .843.007L.917 0h14.166ZM8 14.34l-2.205 2.205a.417.417 0 0 1-.23.116l-.065.006-2.501-.001.001 2.5h10l-.001-2.5H10.5a.417.417 0 0 1-.245-.079l-.05-.042L8 14.339Zm.187-3.92L8 10.417a5 5 0 0 0-5 5l-.001.416h2.33l2.376-2.378a.417.417 0 0 1 .532-.048l.058.048 2.377 2.378H13l.001-.416a5.002 5.002 0 0 0-4.813-4.997ZM4.692 8.334a4.98 4.98 0 0 0 3.12 1.246L8 9.583a4.98 4.98 0 0 0 3.305-1.248l-6.613-.001ZM13 .834H3v3.75c0 1.088.348 2.096.938 2.917h8.123c.59-.822.939-1.83.939-2.918V.833Z"
      />
    </g>
  </svg>
);

SaveTime.defaultProps = {
  width: 16,
  height: 20,
};

SaveTime.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(SaveTime);
