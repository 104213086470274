import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../../constants/colors';
import { segmentTracking } from '../../../services/utilities';
import Button from '../../Button';
import Card from '../../Card';
import SegmentedController from '../../SegmentedController';
import Tag from '../../Tag';

import styles from './ToggleVariant.module.scss';

const PLAN_OPTIONS = [
  {
    label: 'Pro Plan',
    value: 'PRO',
  },
  {
    label: 'Premium Plan',
    value: 'PREMIUM',
  },
];

const ToggleVariant = ({
  className,
  benefits,
  extraParams,
  onGetPremium,
  onGetPro,
  sections,
  onSectionClick,
  defaultPricing,
  premiumPrice,
  proPrice,
  displayMonthlyPrice = false,
  segmentProperties = {},
}) => {
  const [selectedPlan, setSelectedPlan] = useState(
    defaultPricing?.toUpperCase() || 'PRO',
  );

  const isPremium = selectedPlan === 'PREMIUM';

  const price = isPremium ? premiumPrice : proPrice;

  const renderBenefitItems = (benefitItems) =>
    benefitItems.map((item) => {
      const ItemIcon = item.icon;
      const iconProps = {
        color: colors.orangeLight,
        ...(item.iconProps || {}),
      };

      return (
        <div key={item.title} className={styles.benefitItem}>
          {ItemIcon && (
            <div className={styles.itemIconWrapper}>
              <ItemIcon width={27} height={27} {...iconProps} />
            </div>
          )}
          <div className={styles.itemInfo}>
            <h3
              className={cx(styles.itemTitle, {
                [styles.fullHeight]: !item?.description,
              })}
            >
              {item.title}
            </h3>
            {item?.description && (
              <p className={styles.itemDescription}>{item.description}</p>
            )}
            {item.cta && (
              <div className={styles.itemCta}>
                <a
                  href={
                    typeof item.ctaLink === 'function'
                      ? item.ctaLink(extraParams)
                      : item.ctaLink
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  {...(item.onClick
                    ? {
                        onClick: item.onClick,
                      }
                    : {})}
                >
                  {item.cta}
                </a>
              </div>
            )}
          </div>
        </div>
      );
    });

  const onSubscribe = useCallback(() => {
    if (isPremium) {
      onGetPremium();
    } else {
      onGetPro();
    }
  }, [isPremium, onGetPremium, onGetPro]);

  useEffect(() => {
    setSelectedPlan(defaultPricing.toUpperCase());
  }, [defaultPricing]);

  const PlanController = () => (
    <SegmentedController
      containerClassName={styles.segmentedControllerContainer}
      className={styles.segmentedController}
      onChange={(requestedStatus) => {
        segmentTracking(
          `${requestedStatus.toLowerCase()}_plan_toggle_on`,
          segmentProperties,
        );
        setSelectedPlan(requestedStatus);
      }}
      selectedValue={selectedPlan}
      options={PLAN_OPTIONS}
    />
  );

  return (
    <Card className={cx(styles.container, className)}>
      <div className={styles.topRow}>
        <div className={styles.colLeft}>
          <div className={styles.title}>
            {isPremium ? (
              <>
                Premium
                <Tag className={styles.tag} uppercase={false}>
                  Best Value
                </Tag>
              </>
            ) : (
              'Pro'
            )}
          </div>
          <div className={styles.subtitle}>
            <span className={styles.price}>
              ${displayMonthlyPrice ? (price / 12).toFixed(2) : price}
            </span>
            <span className={styles.perYear}>
              {displayMonthlyPrice ? 'per month' : 'per year'}
            </span>
          </div>
          {displayMonthlyPrice && (
            <span className={styles.billedAnnuallyLabel}>Billed annually</span>
          )}
        </div>
        <div className={cx(styles.toggle, styles.mobileOnly)}>
          <PlanController />
        </div>

        <div className={styles.colRight}>
          <Button
            className={styles.getPremiumBtn}
            onClick={onSubscribe}
            data-qa="get-premium-btn"
          >
            {isPremium ? 'Get Premium' : 'Get Pro'}
          </Button>
        </div>
      </div>
      <div className={cx(styles.toggle, styles.desktopOnly)}>
        <PlanController />
      </div>
      <div
        className={cx(styles.itemsCard, {
          [styles.withTabs]: !!sections,
        })}
      >
        {sections && (
          <div className={styles.tabs}>
            {sections.map((tab) => (
              <div
                key={tab.key}
                className={cx(styles.tab, {
                  [styles.active]: tab.key === benefits.key,
                })}
                onClick={() => {
                  segmentTracking(
                    `${tab.title.toLowerCase()}_tab clicked`,
                    segmentProperties,
                  );
                  onSectionClick?.(tab.key);
                }}
              >
                {tab.title}
              </div>
            ))}
          </div>
        )}
        <div className={styles.columns}>
          <div className={styles.itemsCol}>
            {renderBenefitItems(
              benefits.items.filter((i) => isPremium || i.plan !== 'premium'),
            )}
            {!isPremium && benefits.items.some((i) => i.plan === 'premium') && (
              <div className={styles.onlyWithPremium}>
                <p className={styles.onlyWithPremiumLabel}>
                  Only included with{' '}
                  <a
                    onClick={() => {
                      segmentTracking(
                        'premium_plan_link clicked',
                        segmentProperties,
                      );
                      setSelectedPlan('PREMIUM');
                    }}
                  >
                    Premium Plan
                  </a>
                  :
                </p>
                {renderBenefitItems(
                  benefits.items.filter((i) => i.plan === 'premium'),
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

ToggleVariant.defaultProps = {
  className: '',
};

ToggleVariant.propTypes = {
  className: PropTypes.string,
  benefits: PropTypes.object,
  extraParams: PropTypes.object.isRequired,
  onSubscribe: PropTypes.func.isRequired,
  sections: PropTypes.array,
  onSectionClick: PropTypes.func,
  displayMonthlyPrice: PropTypes.bool,
  onGetPremium: PropTypes.func.isRequired,
  onGetPro: PropTypes.func.isRequired,
  defaultPricing: PropTypes.string,
  premiumPrice: PropTypes.number,
  proPrice: PropTypes.number,
  segmentProperties: PropTypes.object,
};

export default ToggleVariant;
