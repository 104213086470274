import React from 'react';
import PropTypes from 'prop-types';

import RentersInsuranceThickIcon from '../../../../icons/RentersInsuranceThick';
import SectionTitle from '../../common/SectionTitle';

import styles from './SectionPetsSmokingOther.module.scss';

export const SectionPetsSmokingOtherInsuranceSection = ({ children }) => (
  <div className={styles.section}>
    <SectionTitle title="Renters Insurance" icon={RentersInsuranceThickIcon} />
    {children}
  </div>
);

SectionPetsSmokingOtherInsuranceSection.propTypes = {
  children: PropTypes.node,
};
