import moment from 'moment';
const serverFormat = 'YYYY-MM-DD';
const clientFormat = 'MM/DD/YYYY';

const convertServerDate = (date) => {
  if (!date) {
    return date;
  }
  const mDate = moment(date, serverFormat, true);
  if (mDate && mDate.isValid()) {
    return mDate.format(clientFormat);
  }
  return date;
};

export default convertServerDate;
