import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';

import styles from './AdditionalTermsHelper.module.scss';

const AdditionalTermsHelper = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <HelpfulInfoCard
      noIcon
      title={'Virginia Landlord Tenant Handbook'}
      className={styles.helpfulInfoCard}
      color="iceCold"
    >
      <p className={styles.description}>
        Virginia requires that the{' '}
        <a
          className={styles.link}
          href="https://www.dhcd.virginia.gov/sites/default/files/Docx/landlord-tenant/landlord-tenant-handbook-final.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Virginia Landlord-Tenant Handbook
        </a>{' '}
        must be presented to the tenant(s) and each party must sign it. We've
        already attached it for you.
      </p>
    </HelpfulInfoCard>
  </div>
);

AdditionalTermsHelper.propTypes = {
  className: PropTypes.string,
};

export default AdditionalTermsHelper;
