import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const AccountNavThin = ({ color, ...props }) => (
  <svg {...props} viewBox="0 0 48 48" fill={color}>
    <path d="M24 .667c12.888 0 23.333 10.426 23.333 23.29a23.191 23.191 0 0 1-6.724 16.356.97.97 0 0 1-.506.27c-4.266 4.295-9.998 6.75-16.113 6.75-6.223 0-12.052-2.544-16.342-6.981l.242.25a.97.97 0 0 1-.476-.265 23.197 23.197 0 0 1-6.747-16.38C.667 11.094 11.112.668 24 .668zm6.835 12.853c-.727-2.9-10.027-2.9-10.753 0a.972.972 0 0 1-1.133.717c-.882-.176-1.26-.047-1.445.279-.212.373-.263 1.075-.136 2.01.068.497.18 1.03.334 1.63.08.308.157.588.293 1.07.134.48.172.638.172.85a.972.972 0 0 1-.973.972c-.31 0-.31 1.938 0 1.938.537 0 .973.435.973.972 0 1.895.467 3.216 1.223 4.097.422.49.82.733 1.028.803a.972.972 0 0 1 .665.922v4.852c0 .404-.25.767-.628.91-.545.205-1.123.418-1.831.676l-1.328.482c-3.85 1.393-5.993 2.238-7.487 3.05 3.841 3.599 8.853 5.639 14.181 5.639 5.341 0 10.362-2.05 14.206-5.663-.71-.38-1.576-.775-2.619-1.201-.678-.278-2.314-.889-3.933-1.486l-.485-.178a695.043 695.043 0 0 0-3.611-1.319.972.972 0 0 1-.631-.91V29.78c0-.419.268-.79.665-.922.209-.07.606-.312 1.028-.803.756-.881 1.223-2.202 1.223-4.097 0-.537.436-.972.973-.972.31 0 .31-1.938 0-1.938a.972.972 0 0 1-.973-.972c0-.3.607-2.202.605-2.195.286-1 .465-1.892.516-2.72.038-.616 0-1.167-.12-1.641zM24 2.61c-11.815 0-21.389 9.556-21.389 21.347 0 5.429 2.04 10.535 5.64 14.442 1.661-1.006 3.894-1.905 8.383-3.529l1.094-.396.23-.084c.437-.159.823-.3 1.18-.433v-3.572a5.221 5.221 0 0 1-1.223-1.064c-.954-1.111-1.556-2.647-1.672-4.642-1.566-.916-1.631-4.093-.194-5.196a53.527 53.527 0 0 1-.23-.845 16.23 16.23 0 0 1-.377-1.85c-.18-1.321-.102-2.402.372-3.235.533-.936 1.472-1.387 2.712-1.326 2.294-4.026 13.05-3.753 14.195.82.171.683.223 1.429.174 2.232-.06.991-.266 2.013-.588 3.136-.077.27-.217.722-.333 1.086 1.413 1.118 1.341 4.267-.217 5.178-.116 1.995-.718 3.53-1.672 4.642-.415.482-.838.829-1.224 1.064v3.564c1.676.61 6.175 2.253 7.452 2.775 1.423.582 2.554 1.117 3.454 1.653 3.588-3.903 5.622-9 5.622-14.42C45.389 12.168 35.815 2.61 24 2.61z" />
  </svg>
);

AccountNavThin.defaultProps = {
  width: 46.67,
  height: 46.67,
  color: colors.greyLight,
};

AccountNavThin.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(AccountNavThin);
