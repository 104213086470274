import React from 'react';

import HelpfulInfoListItem from '../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import { H2, H3, Paragraph } from '../../../../../components/Typography';
import AcademyLogo from '../../../../../icons/AcademyLogo';
import academyBuildingImg from '../../assets/academy-header.svg';

import styles from './Header.module.scss';

const staticItems = [
  {
    title: '120+ minutes',
    description: 'of self paced content',
  },
  {
    title: 'Interactive',
    description: 'examples & assessments',
  },
  {
    title: 'Rated 4.7/5.0',
    description: 'by attendees',
  },
  {
    title: 'All Levels',
    description: 'of experience',
  },
];

const Header = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.headerSection}>
        <div className={styles.content}>
          <AcademyLogo className={styles.logo} width={131} height={29} />
          <H2>TurboTenant Academy</H2>
          <Paragraph weight="semi" size="large">
            Improve your landlord knowledge and take your rental business to the
            next level.
          </Paragraph>
        </div>
        <div className={styles.graphic}>
          <img src={academyBuildingImg} />
        </div>
      </div>
      <div className={styles.staticSection}>
        {staticItems.map((item, index) => (
          <div className={styles.staticItem} key={index}>
            <H3>{item.title}</H3>
            <Paragraph size="large">{item.description}</Paragraph>
          </div>
        ))}
      </div>
      <div className={styles.staticSectionMobile}>
        {staticItems.map((item, index) => (
          <HelpfulInfoListItem
            className={styles.listItem}
            key={index}
          >{`${item.title} ${item.description}`}</HelpfulInfoListItem>
        ))}
      </div>
    </div>
  );
};

export default Header;
