import React from 'react';
import PropTypes from 'prop-types';

import ExportMessagesAnimation from '../Animations/ExportMessagesAnimation';
import Button from '../Button';
import Modal from '../Modal';

import styles from './ExportMessagesLoading.module.scss';

const ExportMessagesLoading = ({ open, onPressCancel }) => {
  return (
    <Modal
      className={styles.exportMessagesLoadingModal}
      open={open}
      containerClassName={styles.container}
      id="exportMessagesLoadingModal"
      autoFocus={false}
      disableOutsideClick
    >
      <div className={styles.animation}>
        <ExportMessagesAnimation
          style={{
            height: 300,
          }}
        />
      </div>

      <div className={styles.content}>
        <h1 className={styles.title}>Exporting...</h1>
        <Button className={styles.btn} secondary onClick={onPressCancel}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

ExportMessagesLoading.propTypes = {
  open: PropTypes.bool.isRequired,
  onPressCancel: PropTypes.func.isRequired,
};

export default ExportMessagesLoading;
