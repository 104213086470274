import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ListingHouseColor = (props) => (
  <svg {...props} viewBox="0 0 40 33">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#7FE3FF"
        d="M29.543 4.977h3.939v4.978h-1.969l-1.97-1.991zM10.832 20.905h4.924v7.964h-4.924zM20.68 20.905h8.863v3.982H20.68z"
      />
      <path
        fill="#042238"
        d="m20.495.166 7.837 5.881V4.23c0-.431.319-.787.73-.84l.104-.006h5a.84.84 0 0 1 .833.846l-.001 6.821 4.662 3.5c.647.484.309 1.526-.495 1.526l-4.167-.001v15.231h4.167c.425 0 .775.323.827.74l.006.107a.84.84 0 0 1-.833.846H.835a.84.84 0 0 1-.833-.846.84.84 0 0 1 .833-.846L5 31.307V16.076H.835c-.804 0-1.142-1.04-.495-1.526L19.505.166a.823.823 0 0 1 .99 0Zm12.837 15.91H6.668v15.23h1.666v-2.538a.84.84 0 0 1 .834-.846H10v-7.615a.84.84 0 0 1 .834-.846h5a.84.84 0 0 1 .833.846l-.001 7.615h.834a.84.84 0 0 1 .833.846v2.538h15v-15.23ZM16.667 29.615H10v1.692h6.666v-1.692ZM15 21.153h-3.333v6.77H15v-6.77Zm14.166-1.692a.84.84 0 0 1 .833.846v5.077a.84.84 0 0 1-.833.846h-8.333a.84.84 0 0 1-.833-.846v-5.077a.84.84 0 0 1 .833-.846h8.333Zm-.833 1.692h-6.666v3.385h6.666v-3.385ZM20 1.898 3.364 14.385l2.455-.001h30.817L20 1.899Zm13.332 3.178H30V7.3L33.332 9.8V5.076Z"
      />
    </g>
  </svg>
);

ListingHouseColor.defaultProps = {
  width: 40,
  height: 33,
};

ListingHouseColor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(ListingHouseColor);
