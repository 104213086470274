import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Property = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 25" {...rest} fill={color}>
    <path d="m12.364 4.173 3.513 2.559v-.466c0-.3.222-.549.513-.595l.1-.008h2.448c.338 0 .612.27.612.603v3.14l2.2 1.603c.475.345.227 1.088-.363 1.088H19.55v7.75l1.837.001a.61.61 0 0 1 .604.506l.008.098a.608.608 0 0 1-.612.603H2.613a.608.608 0 0 1-.612-.604c0-.333.274-.603.612-.603H4.45v-7.751H2.613c-.59 0-.838-.742-.364-1.088l9.387-6.836a.62.62 0 0 1 .728 0Zm5.962 8.032H5.674v7.452h1.224V14.38c0-.475.275-.86.613-.86h2.448c.338 0 .613.385.613.86l-.001 5.277h7.755v-7.452ZM9.347 15.24H8.123v4.013h1.224V15.24Zm7.142-1.937c.338 0 .612.27.612.603v2.413a.608.608 0 0 1-.612.603h-4.08a.608.608 0 0 1-.613-.603v-2.413c0-.333.274-.603.612-.603h4.081Zm-.612 1.206H13.02v1.207h2.857v-1.207ZM12 5.408 4.471 10.89H19.53L12 5.408Zm6.326 1.461H17.1v.754l1.224.892V6.869Z" />
  </svg>
);

Property.defaultProps = {
  width: 24,
  height: 25,
  color: colors.primary,
};

Property.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Property);
