import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import cx from 'classnames';
import PropTypes from 'prop-types';

import DocumentUploadIcon from '../../icons/DocumentUpload';
import getFieldError from '../../validators/get-field-error';
import Spinner from '../Spinner';

import styles from './UploadDropZone.module.scss';

const UploadDropZone = ({
  className,
  id,
  meta,
  input: { onChange, value },
  uploadFiles,
  icon,
  iconProps,
  dropzoneLabel,
  inputProps,
  disabled,
  dropzoneClassName,
  ...otherProps
}) => {
  const [uploadError, setUploadError] = useState(null);
  const error = (meta && getFieldError(meta)) || uploadError;
  const [uploading, setUploading] = useState(false);
  const onFilesChange = async (inputFiles) => {
    const needUpload = [];
    const newFiles = [];
    setUploadError(null);
    inputFiles.forEach((file) => {
      if (file instanceof File) {
        needUpload.push(file);
      } else {
        newFiles.push(file);
      }
    });
    try {
      if (needUpload.length) {
        setUploading(true);
        const uploaded = await uploadFiles(needUpload);
        if (uploaded != null) {
          newFiles.push(...uploaded);
        }
        setUploading(false);
      }
    } catch (e) {
      console.error('Error while uploading file - ', e);
      setUploadError('Document upload failed. Try again.');
      // showErrorMessage('An error has occurred when uploading');
      setUploading(false);
    }
    onChange(newFiles);
  };
  const handleDrop = (acceptedFiles) => {
    if (disabled) return;
    onFilesChange([...(value || []), ...acceptedFiles]);
  };
  let Icon = icon;
  if (!Icon) {
    Icon = DocumentUploadIcon;
  }
  return (
    <div
      className={cx(styles.container, className, {
        [styles.disabled]: disabled,
      })}
    >
      {uploading && (
        <div className={cx(styles.loader)}>
          <Spinner secondary />
          <p>Uploading...</p>
        </div>
      )}
      <Dropzone onDrop={handleDrop} {...otherProps}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              className: cx(
                styles.dropzone,
                {
                  [styles.dropZoneError]: error,
                },
                dropzoneClassName,
              ),
            })}
          >
            <input
              id={id}
              onChange={(e) => {
                handleDrop(e.target.files);
              }}
              {...getInputProps()}
              {...inputProps}
            />
            <Icon {...iconProps} />
            <p className={styles.title}>{dropzoneLabel}</p>
          </div>
        )}
      </Dropzone>
      {error && (
        <span id={`${id}_errormsg`} className={styles.error}>
          {error}
        </span>
      )}
    </div>
  );
};

UploadDropZone.propTypes = {
  className: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  dropzoneLabel: PropTypes.string,
  dropzoneClassName: PropTypes.string,
  accept: PropTypes.string,
  id: PropTypes.string,
  iconProps: PropTypes.object,
  inputProps: PropTypes.object,
  disabled: PropTypes.bool,
};
UploadDropZone.defaultProps = {
  iconProps: {},
  dropzoneLabel: 'Click or drag to upload',
  input: {
    value: [],
    onChange: () => null,
  },
  disabled: false,
};

export default UploadDropZone;
