import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const InsuranceThick = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <path
      d="M5.935 15.758c.535 0 .968.434.968.97v.108H9.16c1.314 0 2.487.447 3.695 1.235l.398.27.774.56.133.09h3.602c.85 0 1.396.53 1.635 1.209l3.476-1.104c1.563-.446 2.817-.037 4.176 1.325a.971.971 0 01-.252 1.554 187.842 187.842 0 00-3.26 1.674l-3.775 2-.679.346c-1.497.75-2.43 1.103-3.384 1.258-1.075.173-2.092.069-3.531-.334l-.37-.107c-.666-.2-3.256-1.09-4.897-1.655l.001.195c0 .535-.433.97-.968.97h-4.3a.969.969 0 01-.968-.97v-8.624c0-.536.433-.97.967-.97zm3.226 3.018H6.903v4.327l6.334 2.114c2.088.669 1.245.267 2.155.12.83-.134 1.744-.51 3.48-1.411l4.183-2.213 1.47-.76-.067-.024a1.616 1.616 0 00-.867-.016l-.16.041-4.234 1.346a1.615 1.615 0 01-1.237.778l-.197.01H11.85a.97.97 0 01-.131-1.931l.131-.01h5.75a.976.976 0 01.01-.026.11.11 0 01-.005-.027l-.002-.054c0-.067.004-.094.027-.104l.016-.004H14c-.365 0-.594-.103-1.004-.386l-.26-.186-.403-.294a13.108 13.108 0 00-.533-.369c-.819-.534-1.573-.846-2.347-.909l-.292-.012zm-4.193-1.078H2.6v6.684h2.367v-6.684zm5.269-1.078a.969.969 0 01-.96-.838l-.008-.132v-5.39a.969.969 0 011.926-.131l.01.131-.001 4.42h1.29v-3.342a.97.97 0 01.855-.963l.113-.007h3.226c.535 0 .968.435.968.97v3.342h1.29v-4.42a.97.97 0 01.837-.96l.13-.01c.49 0 .896.365.96.839l.009.131v5.39a.97.97 0 01-.837.961l-.131.01h-9.677zm5.483-4.312h-1.29v2.372h1.29v-2.372zM20.99.667c.49 0 .894.365.958.838l.009.132v2.695a.969.969 0 01-1.23.934l3.046 2.644c.404.351.448.964.098 1.369a.966.966 0 01-1.25.184l-.115-.086-7.431-6.457-7.43 6.457a.966.966 0 01-1.264.003l-.102-.101a.972.972 0 01-.003-1.267l.1-.102L14.442.904a.966.966 0 011.14-.094l.128.094 4.395 3.82a.965.965 0 01-.074-.26l-.009-.132V2.606l-1.72.001a.969.969 0 01-.959-.839l-.009-.131a.97.97 0 01.837-.961l.131-.01h2.688z"
      fill={props.color}
      fillRule="evenodd"
    />
  </svg>
);

InsuranceThick.defaultProps = {
  width: 38,
  height: 48,
  color: colors.text,
};

InsuranceThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(InsuranceThick);
