import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PDFDocumentViewerModal from '../../../../components/PDFDocumentViewerModal';
import { TTfetch } from '../../../../core/TTfetch';
import { useConfig } from '../../../../providers/ConfigProvider';
import { segmentTracking } from '../../../../services/utilities';

const PreviewPublicLease = ({
  leaseAgreement,
  onClose,
  onAction,
  openLocation,
  title,
  subtitle,
  actionLabel,
  showCloseIcon,
  closeLabel,
}) => {
  const { API_URL } = useConfig();
  const [url, setUrl] = useState(null);

  useEffect(() => {
    segmentTracking('preview_lease_modal loaded', {
      location: openLocation,
    });
  }, []);

  useEffect(() => {
    async function getUrl() {
      const res = await TTfetch(API_URL + '/public/preview-lease-agreement', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(leaseAgreement),
      });

      const response = await res.json();
      setUrl(response.url);
    }
    getUrl();
  }, [leaseAgreement]);

  return (
    <PDFDocumentViewerModal
      open
      loading={!url}
      actionLoading={!url}
      url={url}
      title={title}
      subtitle={subtitle}
      onClose={onClose}
      closeLabel={closeLabel}
      onAction={onAction}
      actionLabel={actionLabel}
      showCloseIcon={showCloseIcon}
    />
  );
};

PreviewPublicLease.defaultProps = {
  title: 'Preview Your Lease',
  subtitle: 'This is what the lease agreement looks like so far.',
  actionLabel: 'Sign Up',
  closeLabel: 'Go back',
  showCloseIcon: true,
};

PreviewPublicLease.propTypes = {
  leaseAgreement: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  openLocation: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actionLabel: PropTypes.string,
  showCloseIcon: PropTypes.bool,
  closeLabel: PropTypes.string,
};

export default PreviewPublicLease;
