import React from 'react';
import PropTypes from 'prop-types';

import { useUserProfile } from '../../core/TTgraphql';

import IncomeInsightsCard from './IncomeInsightsCard';

const INCOME_INSIGHTS_BUNDLES = [13, 14, 6013, 6014];
const REPORT_PULLED_STATUSES = ['RECEIVED', 'NOT_RECEIVED'];

const IncomeInsightsCardContainer = ({ application, ...props }) => {
  const { user } = useUserProfile({ polling: false });
  const credit_check = application?.credit_check;
  const report = credit_check?.report;

  const isPremiumUser = user?.premium_subscription_subscribed;
  const reportStatus = application?.credit_check?.status;

  const isReportRequested = reportStatus === 'REQUESTED';

  const instantReport = ['PRE_ACCEPTED', 'RECEIVED'].includes(reportStatus);

  const isIncomeInsightsBundle = INCOME_INSIGHTS_BUNDLES.includes(
    credit_check?.bundle_id,
  );
  const isReportPulled = REPORT_PULLED_STATUSES.includes(reportStatus);
  const { incomeInsights } = report != null ? JSON.parse(report) : {};

  const shouldShowInsightsCard =
    isPremiumUser &&
    isReportPulled &&
    !!incomeInsights &&
    isIncomeInsightsBundle;

  return (
    <>
      {shouldShowInsightsCard && (
        <IncomeInsightsCard
          application={application}
          instantReport={instantReport}
          isReportRequested={isReportRequested}
          incomeInsights={incomeInsights}
          {...props}
        />
      )}
    </>
  );
};

IncomeInsightsCardContainer.propTypes = {
  application: PropTypes.object,
};

IncomeInsightsCardContainer.defaultProps = {};

export default IncomeInsightsCardContainer;
