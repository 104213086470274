import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';
import { useMutation } from '@apollo/client';
import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import { parse } from 'query-string';

import LoadingScreen from '../../components/LoadingScreen';

import Addresses from './edit/addresses/Addresses';
import AddressesEdit from './edit/addresses/AddressesEdit';
import ManageAttachments from './edit/attachments/ManageAttachments';
import Employments from './edit/employments/Employments';
import EmploymentsEdit from './edit/employments/EmploymentsEdit';
import GeneralDetails from './edit/general-details/GeneralDetails';
import GeneralDetailsEdit from './edit/general-details/GeneralDetailsEdit';
import Info from './edit/info/Info';
import InfoEdit from './edit/info/InfoEdit';
import OtherInfo from './edit/other-info/OtherInfo';
import OtherInfoEdit from './edit/other-info/OtherInfoEdit';
import SelfReportedBg from './edit/self-reported-bg/SelfReportedBg';
import SelfReportedBgEdit from './edit/self-reported-bg/SelfReportedBgEdit';
import SwitchViewEdit from './edit/SwitchViewEdit';
import updateRentalRequestGQL from './graphql/updateRentalRequest.graphql';
import fieldsBySection from './fieldsBySection';

const CoSignerAddress = (props) => <Addresses {...props} isCoSignerAddress />;

const sections = [
  {
    id: 'info',
    view: Info,
    edit: InfoEdit,
  },
  {
    id: 'residences',
    title: 'Addresses',
    view: Addresses,
    edit: AddressesEdit,
    shouldRender: (application) => {
      if (application == null) return false;

      const { applying_as_tenant, residences = [] } = application;

      return applying_as_tenant || residences?.length > 1;
    },
  },
  {
    id: 'mailing-address',
    title: 'Mailing Address',
    view: CoSignerAddress,
    edit: AddressesEdit,
    shouldRender: (application) => {
      if (application == null) return false;
      const { applying_as_tenant, residences = [] } = application;

      return !applying_as_tenant && residences?.length === 1;
    },
  },
  {
    id: 'employments',
    title: 'Income',
    view: Employments,
    edit: EmploymentsEdit,
    mapper: (application) => {
      if (application == null) return false;

      const { income_sources = [], employments = [] } = application;

      const mapMonthlyIncome = ({ monthly_income, ...obj }) => ({
        ...obj,
        monthly_income: monthly_income / 100,
      });

      return {
        ...application,
        employments: employments.map(mapMonthlyIncome),
        income_sources: income_sources.map(mapMonthlyIncome),
      };
    },
  },
  {
    id: 'generalDetails',
    title: 'General Details',
    view: GeneralDetails,
    edit: GeneralDetailsEdit,
    shouldRender: (application) => {
      if (application == null) return false;

      return (
        application?.contacts?.length > 0 ||
        application?.pets?.length > 0 ||
        application?.vehicles?.length > 0
      );
    },
  },
  {
    id: 'selfReported',
    title: 'Self-Reported Background',
    view: SelfReportedBg,
    edit: SelfReportedBgEdit,
  },
  {
    id: 'additionalComments',
    title: 'Additional Comments',
    view: OtherInfo,
    edit: OtherInfoEdit,
    shouldRender: (application) => {
      if (application == null) return false;

      const { applying_as_tenant } = application;

      return (
        !applying_as_tenant &&
        application?.custom_application_question_1 == null &&
        application?.custom_application_question_2 == null &&
        application?.custom_application_question_3 == null &&
        application?.custom_application_question_4 == null
      );
    },
  },
  {
    id: 'otherInfo',
    title: 'Other Information and Comments',
    view: OtherInfo,
    edit: OtherInfoEdit,
    shouldRender: (application) => {
      if (application == null) return false;

      const { applying_as_tenant } = application;

      return (
        applying_as_tenant ||
        application?.custom_application_question_1 != null ||
        application?.custom_application_question_2 != null ||
        application?.custom_application_question_3 != null ||
        application?.custom_application_question_4 != null
      );
    },
  },
];

const ApplicationComponent = ({
  renterEmail,
  loading,
  application,
  partnersApplication,
}) => {
  const [updateRentalRequestMutation, { loading: updateRentalRequestLoading }] =
    useMutation(updateRentalRequestGQL);

  const location = useLocation();

  const updateRentalRequest = (data) => {
    return updateRentalRequestMutation({
      variables: {
        rentalRequest: {
          ...data,
        },
      },
    });
  };

  const quickLinks = {};
  const incomeSection = useRef(null);

  const hasIdentityVerification =
    application?.credit_check?.request_accepted || null;

  const redirectToIncome = !!parse(location.search).redirectToIncome || false;

  const getEditableData = (sectionId) => {
    return pick(application, fieldsBySection[sectionId]);
  };

  useEffect(() => {
    if (application && redirectToIncome) {
      scrollToComponent(incomeSection.current, { align: 'top', offset: -88 });
    }
  }, [application, redirectToIncome]);

  return (
    <LoadingScreen
      loading={loading || updateRentalRequestLoading}
      style={{ width: '100%' }}
    >
      {sections.map(({ id, title, view, edit, mapper, shouldRender }, idx) => {
        if (idx === 0) {
          title = `${application?.first_name} ${application?.last_name}`;
        }

        if (typeof shouldRender === 'function' && !shouldRender(application))
          return null;

        return (
          <SwitchViewEdit
            id={id}
            title={title}
            editableData={
              typeof mapper === 'function'
                ? mapper(getEditableData(id))
                : getEditableData(id)
            }
            viewComponent={view}
            editComponent={edit}
            renterEmail={renterEmail}
            application={application || {}}
            onSave={updateRentalRequest}
            loading={loading || updateRentalRequestLoading}
            showPrintButton={idx === 0}
            location={location}
            hasIdentityVerification={idx === 0 && hasIdentityVerification}
            key={`application-${idx}`}
            innerRef={id === 'employments' ? incomeSection : null}
            partnersApplication={partnersApplication}
          />
        );
      })}
      <ManageAttachments
        id="attachments"
        name="attachments"
        title="Attachments"
        ref={(input) => {
          quickLinks.attachments = input;
        }}
        application={application}
        onSave={updateRentalRequest}
        loading={loading || updateRentalRequestLoading}
        maxFileSize={10 * 1024 * 1024} // 10MB in bytes
        partnersApplication={partnersApplication}
      />
    </LoadingScreen>
  );
};

ApplicationComponent.propTypes = {
  styles: PropTypes.object,
  updateRentalRequest: PropTypes.func,
  renterEmail: PropTypes.string,
  application: PropTypes.object,
  loading: PropTypes.bool,
  location: PropTypes.object,
  partnersApplication: PropTypes.bool,
};

export default ApplicationComponent;
