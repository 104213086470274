import PropTypes from 'prop-types';

const listingPropTypes = PropTypes.shape({
  id: PropTypes.string,
  state: PropTypes.string,
  city: PropTypes.string,
  zip: PropTypes.string,
  address: PropTypes.string,
  unit: PropTypes.string,
  room_name: PropTypes.string,
  has_valid_address: PropTypes.bool,
  rent_amount: PropTypes.number,
  security_deposit_amount: PropTypes.number,
  property_role: PropTypes.oneOf(['ROOM', 'UNIT', 'SINGLE_UNIT', 'MULTI_UNIT']),
  parent_listing_id: PropTypes.string,
  root_listing_id: PropTypes.string,
  nickname: PropTypes.string,
  has_rooms: PropTypes.bool,
  is_multiunit: PropTypes.bool,
  parent_listing: PropTypes.string,
  root_listing: PropTypes.object,
  children_listings: PropTypes.arrayOf(PropTypes.object),
});

export default listingPropTypes;
