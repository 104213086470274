import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import cx from 'classnames';
import PropTypes from 'prop-types';

import FlatButton from '../../../../../components/FlatButton';
import Input from '../../../../../components/Input';
import SelectField from '../../../../../components/SelectField';
import { Tooltip } from '../../../../../components/Tooltip';
import { keyValues } from '../../../../../constants';
import { SUB_DIVISION_NAMES_KEYS } from '../../../../../constants/lease_agreement/sub-division-names';
import { KEYS_LIMIT } from '../../../../../constants/lease_agreement/wizard-limitations';
import getUtilitiesKeyTypes from '../../../../../helpers/getUtilitiesKeyTypes';
import PropertyRolesEnum from '../../../../../helpers/propertyRolesEnum';
import Keys from '../../../../../icons/Keys';
import MinusCircle from '../../../../../icons/MinusCircle';
import PlusCircle from '../../../../../icons/PlusCircle';
import Utilities from '../../../../../icons/Utilities';
import amountGreaterThenZero from '../../../../../validators/amountGreaterThenZero';
import composeValidators from '../../../../../validators/composeValidators';
import conditionalValidation from '../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../validators/fieldRequired';
import isLowerThan from '../../../../../validators/isLowerThan';
import isNumber from '../../../../../validators/isNumber';
import SectionInfoBlock from '../../../common/SectionInfoBlock';
import SectionTitle from '../../../common/SectionTitle';
import MaintenanceNeedsSection from '../MaintenanceNeedsSection';
import SharedUtilitiesFields from '../SharedUtilitiesFields';
import SharedUtilitiesAZ from '../state_specific/AZ/SharedUtilitiesAZ';
import SharedUtilitiesCA from '../state_specific/CA/SharedUtilitiesCA';
import SharedUtilitiesIA from '../state_specific/IA/SharedUtilitiesIA';
import SharedUtilitiesIL from '../state_specific/IL/SharedUtilities';
import SharedUtilitiesMD from '../state_specific/MD/SharedUtilitiesMD';
import TrashRemovalMD from '../state_specific/MD/TrashRemovalMD';
import SharedUtilitiesME from '../state_specific/ME/SharedUtilitiesME';
import SharedUtilitiesMN from '../state_specific/MN/SharedUtilitiesMN';
import RecyclingLawOR from '../state_specific/OR/RecyclingLawOR';
import SharedUtilitiesOR from '../state_specific/OR/SharedUtilitiesOR';
import SharedUtilitiesVA from '../state_specific/VA/SharedUtilitiesVA';
import SharedUtilitiesWI from '../state_specific/WI/SharedUtilitiesWI/SharedUtilitiesWI';
import UtilitiesForm from '../UtilitiesForm';

import styles from './SectionUtilitiesFields.module.scss';

const SectionUtilitiesFields = ({
  currentState,
  propertyRole,
  leaseAgreementSelection,
  setPristine,
  standAloneWizard,
}) => {
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  const utilitiesKeyTypes = getUtilitiesKeyTypes(propertyRole);

  return (
    <>
      <FormSpy
        subscription={{ pristine: true, values: true }}
        onChange={({ pristine }) => {
          setPristine(pristine);
        }}
      />
      <div className={styles.utilitiesSection}>
        <SectionTitle title="Utilities & Services" icon={Utilities} />

        <label className={styles.headerLabel}>
          Indicate who is responsible for the following:
        </label>

        <Field component={UtilitiesForm} name="utilities" id="utilities" />
      </div>

      {currentState === 'IL' && !leaseAgreementSelection && (
        <div className={styles.section}>
          <SharedUtilitiesIL standaloneWizard={standAloneWizard} />
        </div>
      )}

      {currentState === 'CA' && (
        <div className={styles.section}>
          <SharedUtilitiesCA standaloneWizard={standAloneWizard} />
        </div>
      )}

      {currentState === 'AZ' && (
        <div className={styles.section}>
          <SharedUtilitiesAZ standaloneWizard={standAloneWizard} />
        </div>
      )}

      {currentState === 'VA' && (
        <div className={styles.section}>
          <SharedUtilitiesVA standaloneWizard={standAloneWizard} />
        </div>
      )}

      {currentState === 'WI' && (
        <div className={styles.section}>
          <SharedUtilitiesWI standaloneWizard={standAloneWizard} />
        </div>
      )}

      {currentState === 'ME' && (
        <div className={styles.section}>
          <SharedUtilitiesME standaloneWizard={standAloneWizard} />
        </div>
      )}

      {currentState === 'MN' && (
        <div className={styles.section}>
          <SharedUtilitiesMN standaloneWizard={standAloneWizard} />
        </div>
      )}

      {currentState === 'IA' && (
        <div className={styles.section}>
          <SharedUtilitiesIA standaloneWizard={standAloneWizard} />
        </div>
      )}

      {currentState === 'OR' && (
        <>
          <div className={styles.section}>
            <RecyclingLawOR />
          </div>
          <div className={styles.section}>
            <SharedUtilitiesOR standaloneWizard={standAloneWizard} />
          </div>
        </>
      )}

      {propertyRole === PropertyRolesEnum.ROOM &&
        currentState !== 'IL' &&
        currentState !== 'CA' &&
        currentState !== 'AZ' &&
        currentState !== 'VA' &&
        currentState !== 'ME' &&
        currentState !== 'MN' && (
          <div className={styles.section}>
            <SharedUtilitiesFields standaloneWizard={standAloneWizard} />
          </div>
        )}

      {leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.montgomery && (
        <TrashRemovalMD />
      )}

      {(leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.montgomery ||
        leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.baltimore) && (
        <SharedUtilitiesMD
          standaloneWizard={standAloneWizard}
          leaseAgreementSelection={leaseAgreementSelection}
        />
      )}

      <div className={styles.section}>
        <SectionTitle title="Keys" icon={Keys} />
        <label className={styles.headerLabel}>
          Which keys will your tenant(s) receive?
        </label>
        <p className={styles.description}>
          Your tenant(s) is required to return copies of these keys and/or
          garage door openers when the lease ends.
        </p>

        <FieldArray name="keys">
          {({ fields }) => (
            <>
              {fields.map((name, index) => (
                <div key={index} className={styles.fieldGroup}>
                  <Field
                    label="Key Type"
                    component={SelectField}
                    name={`${name}.type`}
                    className={styles.propertyType}
                    validate={fieldRequired}
                    options={utilitiesKeyTypes} // move into separate utility func file (?)
                  />

                  <Field
                    className={styles.copies}
                    component={Input}
                    name={`${name}.copies`}
                    id={`${name}.copies`}
                    label="Copies"
                    validate={composeValidators(
                      fieldRequired,
                      isNumber,
                      amountGreaterThenZero,
                      isLowerThan(99999),
                    )}
                    type="number"
                    step="1"
                    pattern="[0-9]*"
                    inputProps={{
                      'data-qa': `lease-agreements-key-copies-${index}`,
                    }}
                  />
                  {fields.length > 1 && (
                    <FlatButton
                      id={`remove_additional_key_${index}`}
                      className={styles.removeButton}
                      icon={MinusCircle}
                      onClick={() => fields.remove(index)}
                    >
                      Remove this key
                    </FlatButton>
                  )}
                  {fields.value[index].type === keyValues.other && (
                    <Field
                      className={styles.otherType}
                      component={Input}
                      name={`${name}.otherType`}
                      id={`${name}.otherType`}
                      label="Specify Type"
                      validate={fieldRequired}
                    />
                  )}
                </div>
              ))}
              <FlatButton
                className={styles.addButton}
                id="add_additional_key"
                icon={PlusCircle}
                disabled={fields.length === KEYS_LIMIT}
                onClick={() => fields.push('')}
              >
                <span
                  data-tooltip-id="disabled-additional-key-tip"
                  data-tooltip-hidden={fields.length !== KEYS_LIMIT}
                >
                  Add additional key
                </span>
              </FlatButton>
              <Tooltip id="disabled-additional-key-tip" place="bottom">
                <span>You've reached the maximum number of keys allowed.</span>
              </Tooltip>
            </>
          )}
        </FieldArray>
      </div>

      {currentState !== 'OR' && (
        <SectionInfoBlock className={styles.infoBlock}>
          <strong>Lost Key:</strong> The tenant(s) are responsible for the full
          cost of rekeying if any keys are lost.
        </SectionInfoBlock>
      )}

      <MaintenanceNeedsSection
        className={cx({
          [styles.maintenanceSection]: !standAloneWizard,
        })}
      />
    </>
  );
};

SectionUtilitiesFields.defaultProps = {
  setPristine: () => {},
  standAloneWizard: false,
};

SectionUtilitiesFields.propTypes = {
  currentState: PropTypes.string.isRequired,
  propertyRole: PropTypes.string,
  leaseAgreementSelection: PropTypes.string,
  setPristine: PropTypes.func,
  standAloneWizard: PropTypes.bool,
};

export default SectionUtilitiesFields;
