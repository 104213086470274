import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const PaintBrush = (props) => (
  <svg {...props} viewBox="0 0 24 30" fill={props.color}>
    <path d="M12 .333c2.132 0 3.859 1.769 3.859 3.951v4.508c0 1.08-.2 2.151-.592 3.149a.223.223 0 00.02.203c.04.06.105.096.175.096h5.842C22.794 12.24 24 13.475 24 15v4.167a.963.963 0 01-.952.974h-.211v8.551c0 .5-.367.912-.84.968l-.111.007H7.93a.963.963 0 01-.952-.975v-.637l-.523 1.073a.953.953 0 01-.718.53l-.133.009H2.115a.963.963 0 01-.952-.975v-8.55H.952a.96.96 0 01-.946-.861L0 19.167V15c0-1.525 1.207-2.76 2.696-2.76h5.843c.07 0 .136-.036.175-.094a.22.22 0 00.02-.204 8.666 8.666 0 01-.593-3.15V4.284c0-2.182 1.728-3.95 3.859-3.95zm8.933 19.808H3.066v7.576h1.95l2.063-4.223c.429-.877 1.681-.62 1.794.3l.009.136v3.787h5.074v-2.001c0-.538.426-.974.952-.974a.96.96 0 01.943.842l.008.132v2.001h.423V23.93c0-.538.426-.974.952-.974a.96.96 0 01.943.842l.008.132v3.787h.423V22.74c0-.538.426-.974.952-.974a.96.96 0 01.943.842l.008.132v4.978h.423l-.001-7.576zM12 2.281c-1.08 0-1.956.897-1.956 2.003v4.508c0 .829.156 1.654.457 2.426a2.21 2.21 0 01-.213 2.022 2.104 2.104 0 01-1.75.948H2.697a.802.802 0 00-.793.812l-.001 3.193h20.194V15a.805.805 0 00-.675-.803l-.117-.009h-5.842a2.104 2.104 0 01-1.75-.95 2.213 2.213 0 01-.212-2.02 6.651 6.651 0 00.456-2.426V4.284c0-1.106-.875-2.002-1.956-2.002zm0 .434c.847 0 1.534.702 1.534 1.57 0 .867-.687 1.569-1.534 1.569-.845 0-1.533-.702-1.533-1.57 0-.866.688-1.57 1.533-1.57zm0 1.19c-.203 0-.37.17-.37.38 0 .209.166.378.37.378.205 0 .37-.169.37-.379a.374.374 0 00-.37-.379z" />
  </svg>
);

PaintBrush.defaultProps = {
  width: 24,
  height: 30,
  color: colors.text,
};

PaintBrush.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(PaintBrush);
