export const TerminationFeeExemptionsEnum = {
  ACTIVE_DUTY_MILLITARY: 'millitary_duty',
  UNINHABITABLE: 'uninhabitable',
  LANDLORD_HARRASMENT: 'landlord_harrasment',
  DOMESTIC_VIOLENCE: 'domestic_violence',
  HEALTH_CRISIS: 'health_crisis',
  HABITUAL_LEASE_VIOLATIONS: 'lease_violations',
  LEASE_TERM_VIOLATIONS: 'term_violations',
  FAILURE_PROVIDING_DISCLOSURES: 'failure_providing_disclosures',
  GAS_LEAK: 'gas_leak',
  OTHER: 'other',
};

export const exemptionsList = [
  {
    value: TerminationFeeExemptionsEnum.ACTIVE_DUTY_MILLITARY,
    name: TerminationFeeExemptionsEnum.ACTIVE_DUTY_MILLITARY,
    label: 'Active duty military',
    leaseText: 'active military duty',
  },
  {
    value: TerminationFeeExemptionsEnum.UNINHABITABLE,
    name: TerminationFeeExemptionsEnum.UNINHABITABLE,
    label: 'Uninhabitable',
    leaseText: 'uninhabitability',
  },
  {
    value: TerminationFeeExemptionsEnum.LANDLORD_HARRASMENT,
    name: TerminationFeeExemptionsEnum.LANDLORD_HARRASMENT,
    label: 'Landlord harassment',
    leaseText: 'landlord harassment',
  },
  {
    value: TerminationFeeExemptionsEnum.DOMESTIC_VIOLENCE,
    name: TerminationFeeExemptionsEnum.DOMESTIC_VIOLENCE,
    label: 'Domestic violence/stalking',
    leaseText: 'domestic violence/stalking',
  },
  {
    value: TerminationFeeExemptionsEnum.HEALTH_CRISIS,
    name: TerminationFeeExemptionsEnum.HEALTH_CRISIS,
    label: 'Senior citizens with serious health conditions',
    leaseText: 'senior citizens with serious health conditions',
  },
  {
    value: TerminationFeeExemptionsEnum.HABITUAL_LEASE_VIOLATIONS,
    name: TerminationFeeExemptionsEnum.HABITUAL_LEASE_VIOLATIONS,
    label: 'Habitual lease violations',
    leaseText: 'habitual lease violations',
  },
  {
    value: TerminationFeeExemptionsEnum.LEASE_TERM_VIOLATIONS,
    name: TerminationFeeExemptionsEnum.LEASE_TERM_VIOLATIONS,
    label: 'Lease term violations',
    leaseText: 'lease term violations',
  },
  {
    value: TerminationFeeExemptionsEnum.FAILURE_PROVIDING_DISCLOSURES,
    name: TerminationFeeExemptionsEnum.FAILURE_PROVIDING_DISCLOSURES,
    label: 'Failure to provide mandatory disclosures',
    leaseText: 'failure to provide mandatory disclosures',
  },
  {
    value: TerminationFeeExemptionsEnum.GAS_LEAK,
    name: TerminationFeeExemptionsEnum.GAS_LEAK,
    label: 'Gas leak',
    leaseText: 'gas leaks',
  },
  {
    value: TerminationFeeExemptionsEnum.OTHER,
    name: TerminationFeeExemptionsEnum.OTHER,
    label: 'Other',
    leaseText: 'other',
  },
];

export const getRequiredExemptionsByState = (state) => {
  switch (state) {
    case 'CA':
    case 'IL':
    case 'AL':
    case 'KS':
    case 'OK':
    case 'MA':
    case 'AR':
    case 'AK':
    case 'AZ':
    case 'NM':
    case 'ID':
    case 'ME':
    case 'MO':
    case 'MT':
    case 'NH':
    case 'PA':
    case 'SD':
    case 'VT':
    case 'WV':
    case 'KY':
      return [
        TerminationFeeExemptionsEnum.ACTIVE_DUTY_MILLITARY,
        TerminationFeeExemptionsEnum.UNINHABITABLE,
        TerminationFeeExemptionsEnum.LANDLORD_HARRASMENT,
      ];
    case 'NY':
    case 'NV':
    case 'NJ':
      return [
        TerminationFeeExemptionsEnum.ACTIVE_DUTY_MILLITARY,
        TerminationFeeExemptionsEnum.UNINHABITABLE,
        TerminationFeeExemptionsEnum.LANDLORD_HARRASMENT,
        TerminationFeeExemptionsEnum.DOMESTIC_VIOLENCE,
        TerminationFeeExemptionsEnum.HEALTH_CRISIS,
      ];
    case 'HI':
      return [
        TerminationFeeExemptionsEnum.ACTIVE_DUTY_MILLITARY,
        TerminationFeeExemptionsEnum.LANDLORD_HARRASMENT,
      ];
    case 'CO':
      return [
        TerminationFeeExemptionsEnum.ACTIVE_DUTY_MILLITARY,
        TerminationFeeExemptionsEnum.UNINHABITABLE,
        TerminationFeeExemptionsEnum.LANDLORD_HARRASMENT,
        TerminationFeeExemptionsEnum.GAS_LEAK,
      ];
    case 'DC':
    case 'ND':
    case 'WY':
    case 'TX':
      return [
        TerminationFeeExemptionsEnum.ACTIVE_DUTY_MILLITARY,
        TerminationFeeExemptionsEnum.UNINHABITABLE,
        TerminationFeeExemptionsEnum.LANDLORD_HARRASMENT,
        TerminationFeeExemptionsEnum.DOMESTIC_VIOLENCE,
      ];
    case 'NC':
    case 'IA':
    case 'MS':
    case 'TN':
      return [
        TerminationFeeExemptionsEnum.ACTIVE_DUTY_MILLITARY,
        TerminationFeeExemptionsEnum.UNINHABITABLE,
        TerminationFeeExemptionsEnum.LANDLORD_HARRASMENT,
        TerminationFeeExemptionsEnum.HABITUAL_LEASE_VIOLATIONS,
      ];
    case 'LA':
      return [
        TerminationFeeExemptionsEnum.ACTIVE_DUTY_MILLITARY,
        TerminationFeeExemptionsEnum.LANDLORD_HARRASMENT,
        TerminationFeeExemptionsEnum.HABITUAL_LEASE_VIOLATIONS,
      ];
    case 'VA':
      return [
        TerminationFeeExemptionsEnum.ACTIVE_DUTY_MILLITARY,
        TerminationFeeExemptionsEnum.UNINHABITABLE,
        TerminationFeeExemptionsEnum.HABITUAL_LEASE_VIOLATIONS,
        TerminationFeeExemptionsEnum.DOMESTIC_VIOLENCE,
        TerminationFeeExemptionsEnum.FAILURE_PROVIDING_DISCLOSURES,
      ];
    case 'OR':
    case 'WA':
    case 'IN':
    case 'MD':
    case 'UT':
    case 'GA':
    case 'WI':
      return [
        TerminationFeeExemptionsEnum.ACTIVE_DUTY_MILLITARY,
        TerminationFeeExemptionsEnum.UNINHABITABLE,
        TerminationFeeExemptionsEnum.LANDLORD_HARRASMENT,
        TerminationFeeExemptionsEnum.DOMESTIC_VIOLENCE,
      ];
    case 'MI':
    case 'CT':
      return [
        TerminationFeeExemptionsEnum.ACTIVE_DUTY_MILLITARY,
        TerminationFeeExemptionsEnum.UNINHABITABLE,
        TerminationFeeExemptionsEnum.LANDLORD_HARRASMENT,
        TerminationFeeExemptionsEnum.DOMESTIC_VIOLENCE,
        TerminationFeeExemptionsEnum.HEALTH_CRISIS,
      ];
    case 'DE':
      return [
        TerminationFeeExemptionsEnum.ACTIVE_DUTY_MILLITARY,
        TerminationFeeExemptionsEnum.UNINHABITABLE,
        TerminationFeeExemptionsEnum.HABITUAL_LEASE_VIOLATIONS,
        TerminationFeeExemptionsEnum.DOMESTIC_VIOLENCE,
        TerminationFeeExemptionsEnum.HEALTH_CRISIS,
      ];
    case 'FL':
      return [
        TerminationFeeExemptionsEnum.ACTIVE_DUTY_MILLITARY,
        TerminationFeeExemptionsEnum.HABITUAL_LEASE_VIOLATIONS,
        TerminationFeeExemptionsEnum.LANDLORD_HARRASMENT,
        TerminationFeeExemptionsEnum.LEASE_TERM_VIOLATIONS,
      ];
    case 'MN':
      return [
        TerminationFeeExemptionsEnum.ACTIVE_DUTY_MILLITARY,
        TerminationFeeExemptionsEnum.LANDLORD_HARRASMENT,
        TerminationFeeExemptionsEnum.DOMESTIC_VIOLENCE,
      ];
    case 'RI':
      return [
        TerminationFeeExemptionsEnum.ACTIVE_DUTY_MILLITARY,
        TerminationFeeExemptionsEnum.LANDLORD_HARRASMENT,
        TerminationFeeExemptionsEnum.HEALTH_CRISIS,
      ];
    default:
      return [];
  }
};

export const initialEarlyTerminationText =
  'Either party, upon 60 days’ advance written notice to the other party, may terminate this lease early. Termination will be effective as of the last day of the calendar month following the end of the 60 day notice period. Each party understands that a notice of termination can happen at any time after lease signing. Tenant may terminate this lease provided that the Tenant pays a termination fee equal to one months’ rent or the maximum allowable by law, whichever is less. Termination charge will be in addition to all rent due and late fees up to the termination date.';

export default TerminationFeeExemptionsEnum;
