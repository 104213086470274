import React, { useState } from 'react';
import ReactFaceBookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import PropTypes from 'prop-types';

import isMobileDevice from '../../helpers/isMobileDevice';
import { useConfig } from '../../providers/ConfigProvider';
import OldButton from '../FacebookButton';

const FacebookLogin = ({
  onSuccess,
  onFailure,
  className,
  onClick,
  type,
  FacebookButton = OldButton,
  buttonProps = {},
}) => {
  const [loading, setLoading] = useState(false);
  const config = useConfig();

  const onFaceBookSuccess = async ({ name, accessToken, email }) => {
    const splitName = (name || '').split(' ');
    const response = {
      service: 'FACEBOOK',
      token: accessToken,
      first_name:
        splitName.length === 1
          ? splitName[0]
          : splitName.slice(0, -1).join(' '),
      last_name: splitName.length === 1 ? '' : splitName[splitName.length - 1],
      email,
    };
    if (response.first_name && response.last_name) {
      setLoading(true);
      try {
        await onSuccess(response);
      } catch {
        // do nothing
      }
      setLoading(false);
      return;
    }
  };

  return (
    <ReactFaceBookLogin
      appId={config.FACEBOOK_OAUTH_CLIENT_ID}
      fields="name,picture,email"
      autoLoad={false}
      callback={onFaceBookSuccess}
      render={(renderProps) => (
        <FacebookButton
          type={type}
          loading={loading}
          onClick={() => {
            if (onClick) {
              onClick();
            }
            renderProps.onClick();
          }}
          className={className}
          {...buttonProps}
        />
      )}
      disableMobileRedirect={isMobileDevice()}
      onFailure={(error) => {
        if (onFailure) {
          onFailure(error);
        }
      }}
    />
  );
};

FacebookLogin.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.oneOf(['login', 'signup']).isRequired,
  FacebookButton: PropTypes.elementType,
  buttonProps: PropTypes.object,
};

export default FacebookLogin;
