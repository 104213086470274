import React, { memo } from 'react';
import PropTypes from 'prop-types';

const WarningCircleFilled = ({ color, ...props }) => (
  <svg viewBox="0 0 20 20" {...props} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0Zm0 14a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm0-10.5c-.552 0-1 .307-1 .685v7.63l.01.093c.065.334.484.592.99.592.552 0 1-.307 1-.685v-7.63l-.01-.093c-.065-.334-.484-.592-.99-.592Z"
      fill={color}
    />
  </svg>
);

WarningCircleFilled.defaultProps = {
  width: 20,
  height: 20,
  color: 'currentColor',
};

WarningCircleFilled.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(WarningCircleFilled);
