import React from 'react';
import cx from 'classnames';
import camelCase from 'lodash.camelcase';
import get from 'lodash.get';
import kebabCase from 'lodash.kebabcase';
import PropTypes from 'prop-types';

import RadioButton from '../../../../../components/PlainRadioButton';
import {
  UTILITY_LABELS,
  UTILITY_WHO_LABELS,
} from '../../../common/UtilityLabels';

import styles from './UtilitiesForm.module.scss';

const UtilitiesForm = ({ input }) => {
  const utilitiesList = get(input, 'value') || [];

  const handleUtilityChange = (utility, responsible) => {
    const newUtilities = utilitiesList.map((utilityElem) => ({
      ...utilityElem,
      ...(utilityElem.name === utility && { responsible }),
    }));
    input.onChange(newUtilities);
  };

  const isChecked = (utility, who) =>
    utilitiesList.find((item) => item.name === utility)?.responsible === who;

  const createUtilityButton = (utility, who) => (
    <label className={styles.radioLabel}>
      <RadioButton
        className={styles.radio}
        checked={isChecked(utility, who)}
        id={`${input.name}.${utility}`}
        name={`${input.name}.${utility}`}
        value={who}
        onClick={(event) => handleUtilityChange(utility, event.target.value)}
        data-qa={kebabCase(
          `lease-agreements-utilities-radio-${utility}-${who}`,
        )}
      />
      <span className={styles.radioLabelText}>{UTILITY_WHO_LABELS[who]}</span>
    </label>
  );

  const createUtilityRow = (utility, label, Icon) => (
    <tr key={utility}>
      <td className={styles.columnLabel}>
        <span
          className={cx(
            styles.utilityIcon,
            styles[camelCase(`${utility}Icon`)],
          )}
        >
          <Icon />
        </span>
        <span className={styles.columnLabelText}>{label}</span>
      </td>
      <td className={styles.columnTenant}>
        {createUtilityButton(utility, 'TENANT')}
      </td>
      <td className={styles.columnLandlord}>
        {createUtilityButton(utility, 'LANDLORD')}
      </td>
      <td className={styles.columnNA}>
        {createUtilityButton(utility, 'NOT_APPLICABLE')}
      </td>
    </tr>
  );

  const utilityRows = [];
  for (const [utility, { label, Icon }] of Object.entries(UTILITY_LABELS)) {
    utilityRows.push(createUtilityRow(utility, label, Icon));
  }

  return (
    <table className={styles.utilitiesTable}>
      <thead>
        <tr>
          <th>Utility or Service</th>
          <th>Tenant</th>
          <th>Landlord</th>
          <th>N/A</th>
        </tr>
      </thead>
      <tbody>{utilityRows}</tbody>
    </table>
  );
};

UtilitiesForm.propTypes = {
  input: PropTypes.object,
};

export default UtilitiesForm;
