import { useFormState } from 'react-final-form';
import PropTypes from 'prop-types';

const MultiCondition = ({ condition, children }) => {
  const { values = {} } = useFormState({ subscription: { values: true } });
  return condition(values) ? children : null;
};

MultiCondition.propTypes = {
  condition: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default MultiCondition;
