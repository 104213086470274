import React from 'react';
import { useMutation } from '@apollo/client';

import ApplicantSetPassword from './ApplicantSetPassword';
import confirmAccountGQL from './confirmAccount.graphql';
import resetPasswordGQL from './resetPassword.graphql';
import setApplicantPasswordGQL from './setApplicantPassword.graphql';

const ApplicantSetPasswordContainer = (props) => {
  const { applicationId } = props.match.params;

  const [confirmAccountRequest, { loading: confirmAccountMutation }] =
    useMutation(confirmAccountGQL);

  const confirmAccount = async (
    token,
    password,
    email,
    ssoService,
    redirectPath,
  ) => {
    const response = await confirmAccountRequest({
      variables: {
        token,
        password,
        email,
        ssoService,
        redirectPath,
      },
    });
    const data = response?.data?.confirmAccount;
    return {
      token: data?.token,
      redirectUrl: data?.redirectUrl,
      messagingToken: data?.messagingToken,
      hasUnreadMessages: data?.hasUnreadMessages,
    };
  };

  const [
    setApplicantPasswordRequest,
    { loading: setApplicantPasswordMutation },
  ] = useMutation(setApplicantPasswordGQL);

  const renterSetPassword = async (pass, applicationId) => {
    const res = await setApplicantPasswordRequest({
      variables: {
        applicationId,
        password: pass,
      },
    });
    const data = res?.data?.setApplicantPassword;
    return {
      token: data.renterToken,
      renter: data.renter,
    };
  };

  const [resetPasswordRequest, { loading: resetPasswordMutation }] =
    useMutation(resetPasswordGQL);

  const resetRenterPassword = async (token, pass, ssoService, redirectPath) => {
    const res = await resetPasswordRequest({
      variables: {
        confirmationToken: token,
        password: pass,
        ssoService,
        redirectPath,
      },
    });
    const data = res?.data?.setPasswordWithToken;
    return {
      token: data?.token,
      redirectUrl: data?.redirectUrl,
    };
  };

  const newProps = {
    applicationId,
    confirmAccount,
    renterSetPassword,
    resetRenterPassword,
    loading:
      confirmAccountMutation ||
      setApplicantPasswordMutation ||
      resetPasswordMutation,
  };

  return <ApplicantSetPassword {...props} {...newProps} />;
};

export default ApplicantSetPasswordContainer;
