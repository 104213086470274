import React from 'react';
import { Field, useFormState } from 'react-final-form';
import cx from 'classnames';

import AddressAutoSuggestFields from '../../../../../../components/AddressAutoSuggestFields';
import {
  InputField,
  RadioField,
  UploadDropZoneField,
} from '../../../../../../components/FinalFormFields';
import PhoneFinalField from '../../../../../../components/FinalFormFields/PhoneField';
import HelpfulInfoCard from '../../../../../../components/HelpfulInfo';
import IconTooltip from '../../../../../../components/IconTooltip';
// TODO: Rename to TaxIDFIeldField
import TaxIDField from '../../../../../../components/TaxIDField';
import colors from '../../../../../../constants/colors';
import CompanyThickIcon from '../../../../../../icons/CompanyThick';
import Document from '../../../../../../icons/Document';
import InformationalIcon from '../../../../../../icons/Informational';
import composeValidators from '../../../../../../validators/composeValidators';
import fieldRequired from '../../../../../../validators/fieldRequired';
import FormSection from '../FormSection';
import StripeIndividualForm from '../StripeIndividualForm';
import ValidInformationDisclaimer from '../StripeIndividualForm/ValidInformationDisclaimer';

import styles from './StripeCompanyForm.module.scss';

const StripeCompanyForm = ({ isEditting, inProduct }) => {
  const { values } = useFormState();
  const bucket = 'stripe-identities';

  return (
    <div className={styles.container}>
      <FormSection title="Company Information" icon={CompanyThickIcon}>
        {!inProduct && <ValidInformationDisclaimer />}
        <div className={styles.fieldContainer}>
          <InputField
            label="Company Name"
            name="company_name"
            disabled={isEditting}
            className={cx(styles.field, styles.companyName)}
          />
          <InputField
            label="Doing Business As (DBA)"
            name="company_doing_business_as"
            className={cx(styles.field, styles.doingBusinessAs)}
            required={false}
          />
          <div className={styles.phoneTaxID}>
            <PhoneFinalField
              label="Phone Number"
              name="company_phone"
              className={cx(styles.field, styles.phone)}
            />
            {!isEditting && (
              <Field
                label={
                  <>
                    Tax ID (TIN, EIN, etc.)
                    <IconTooltip
                      className={styles.questionMarkCircle}
                      tip={
                        <span>
                          Taxpayer Identification Number (TIN) and Employer
                          Identification Number (EIN) are nine-digit numbers
                          assigned by the IRS to identify business entities. We
                          do not collect the number for tax purposes.
                        </span>
                      }
                    />
                  </>
                }
                component={TaxIDField}
                id="company_tax_id"
                name="company_tax_id"
                validate={composeValidators(fieldRequired)}
                className={cx(styles.field, styles.taxID)}
                parse={(value) => value.replace(/_|-/g, '')}
              />
            )}
          </div>
          <div className={styles.addressSection}>
            <AddressAutoSuggestFields
              includePuertoRicoState
              fieldNames={{
                city: 'company_city',
                unit: 'company_unit',
                address: 'company_address',
                zip: 'company_zip',
                state: 'company_state',
              }}
              streetAddressInputProps={{
                label: 'Company Street Address',
              }}
              fieldsClassName={{
                address: styles.addressField,
                unit: styles.unitField,
                city: styles.cityField,
                zip: styles.zipField,
                state: styles.stateField,
              }}
              showPoBoxesNote={true}
            />
          </div>
        </div>
      </FormSection>

      <StripeIndividualForm
        title={'Owner Information'}
        isEditting={isEditting}
        disclaimer={
          'The owner must be listed as a representative of the company.'
        }
        fromBusiness
      />

      <FormSection title="Company Documents" icon={Document}>
        <RadioField
          label="Was your company created or updated less than 90 days ago?"
          name={`created_or_modified_90_days_ago`}
          inline={false}
          required={false}
          withOptionalLabel={false}
          options={[
            { value: false, label: 'No' },
            { value: true, label: 'Yes' },
          ]}
          radiosContainerClassName={styles.radio}
          radioRowClassName={styles.radioLabels}
        />
        {values?.created_or_modified_90_days_ago && (
          <>
            <HelpfulInfoCard
              className={styles.companyInfo}
              noTitle={true}
              Icon={InformationalIcon}
              iconProps={{
                className: styles.companyInfoIcon,
                color: colors.secondary,
                width: 20,
                height: 15,
              }}
            >
              <p className={styles.companyInfoText}>
                The IRS may not have updated its database yet if your company is
                new or recently updated. Stripe could require either a{' '}
                <b>SS-4</b> or <b>147C</b> IRS-issued form to verify your
                ownership. You can preemptively upload the document for Stripe
                to reference if needed.{' '}
                <a
                  className={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://support.turbotenant.com/en/articles/7971229-what-are-the-ss-4-confirmation-letter-and-form-147c-and-how-can-you-obtain-them"
                >
                  See what a SS-4 and 147C form looks like
                </a>
                .
              </p>
            </HelpfulInfoCard>
            <UploadDropZoneField
              name="documents"
              bucket={bucket}
              required={false}
            />
          </>
        )}
      </FormSection>
    </div>
  );
};

export default StripeCompanyForm;
