import Decimal from 'jsdecimal';

const convertDollarsToCents = (val) => {
  if (!val) {
    return val;
  }
  if (Number.isNaN(Number(val))) {
    return val;
  }
  return +Decimal(val).mul(100);
};

export default convertDollarsToCents;
