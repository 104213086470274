export const PAYMENT_OPTIONS_MAP = {
  RP_BY_TT: 'Rent Payments by TurboTenant',
  CASH: 'Cash',
  PERSONAL_CHECK: 'Personal Check',
  CASHIERS_CHECK: 'Cashier’s Check',
  MONEY_ORDER: 'Money Order',
  ACH: 'ACH / Direct Deposit',
};

const PAYMENT_OPTIONS = Object.values(PAYMENT_OPTIONS_MAP);

export default PAYMENT_OPTIONS;
