import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';
import RadioXIcon from '../../../../../icons/RadioX';

import styles from './GlobalNotificationModal.module.scss';

const GlobalNotificationModal = ({
  className,
  containerClassName,
  open,
  lastFour,
  pastDueSubscriptions,
  onUpdate,
  onRemind,
}) => {
  const pastDueFeatures =
    pastDueSubscriptions?.length > 1
      ? pastDueSubscriptions.join(' and ')
      : pastDueSubscriptions.toString();

  return (
    <Modal
      open={open}
      disableOutsideClick
      className={cx(styles.modal, className)}
      containerClassName={cx(styles.modalContainer, containerClassName)}
    >
      <RadioXIcon className={styles.icon} />
      <h2 className={styles.title}>Your payment has failed</h2>
      <p className={styles.description}>
        You will lose your subscription soon. If you would like to continue
        using {pastDueFeatures}, please update your card ending in {lastFour}.
      </p>
      <div className={styles.btnWrapper}>
        <Button className={styles.btn} onClick={onUpdate}>
          Update Payment Method
        </Button>
        <Button className={styles.btn} secondary onClick={onRemind}>
          Remind me later
        </Button>
      </div>
    </Modal>
  );
};

GlobalNotificationModal.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  open: PropTypes.bool,
  lastFour: PropTypes.string.isRequired,
  pastDueSubscriptions: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemind: PropTypes.func.isRequired,
};

export default GlobalNotificationModal;
