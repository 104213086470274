import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import PropTypes from 'prop-types';

import TriangleError from '../../icons/TriangleError';
import Button from '../Button';
import Modal from '../Modal';

import styles from './UnsavedChangesModalV2.module.scss';

const UnsavedChangesModalV2 = ({ when, onConfirm, onCancel }) => {
  return (
    <NavigationPrompt when={when}>
      {({ onConfirm: onConfirmPrompt, onCancel: onCancelPrompt }) => (
        <Modal open className={styles.modal}>
          <TriangleError className={styles.icon} />
          <h1>You have unsaved changes.</h1>
          <div className={styles.description}>
            Stay to keep your changes. If you leave, your changes will be lost.
          </div>
          <div className={styles.btnContainer}>
            <Button
              className={styles.btn}
              onClick={() => {
                onCancel();
                onCancelPrompt();
              }}
            >
              Stay
            </Button>
            <Button
              secondary
              className={styles.btn}
              onClick={() => {
                onConfirm();
                onConfirmPrompt();
              }}
            >
              Leave
            </Button>
          </div>
        </Modal>
      )}
    </NavigationPrompt>
  );
};

UnsavedChangesModalV2.propTypes = {
  when: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  styles: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

UnsavedChangesModalV2.defaultProps = {
  when: false,
  onConfirm: () => ({}),
  onCancel: () => ({}),
};

export default UnsavedChangesModalV2;
