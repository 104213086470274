import React from 'react';
import cx from 'classnames';

import TrashIcon from '../../icons/Remove';
import FlatButton from '../FlatButton';

import styles from './RemoveButton.module.scss';

const RemoveButton = ({
  children,
  className,
  justIcon,
  shrink = true,
  ...props
}) => (
  <FlatButton
    className={cx(
      styles.remove,
      { [styles.shrink]: shrink, [styles.justIcon]: justIcon },
      className,
    )}
    icon={TrashIcon}
    {...props}
  >
    {!justIcon && (
      <span className={styles.hiddenSmall}>
        {children ? children : 'Remove'}
      </span>
    )}
  </FlatButton>
);

export default RemoveButton;
