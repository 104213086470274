import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Phone = (props) => (
  <svg {...props} viewBox="0 0 20 20" fill={props.color}>
    <path d="M13.462 12.03a2.604 2.604 0 013.686 0l2.087 2.088a2.604 2.604 0 01.001 3.686l-1.145 1.145a3.591 3.591 0 01-4.519.454A46.42 46.42 0 01.597 6.427a3.586 3.586 0 01.454-4.518L2.196.763a2.608 2.608 0 013.687 0l2.088 2.09a2.608 2.608 0 010 3.685l-.284.284a46.253 46.253 0 005.49 5.492l.285-.283zM7.069 3.756L4.98 1.666a1.331 1.331 0 00-1.881 0L1.954 2.812a2.31 2.31 0 00-.292 2.91 45.144 45.144 0 0012.614 12.616 2.314 2.314 0 002.912-.291l1.146-1.146c.52-.52.52-1.361 0-1.88l-2.09-2.089a1.328 1.328 0 00-1.88.001l-.696.696a.638.638 0 01-.856.042A47.295 47.295 0 016.33 7.187a.638.638 0 01.043-.856l.696-.695a1.331 1.331 0 000-1.88z" />
  </svg>
);

Phone.defaultProps = {
  width: 20,
  height: 20,
  color: colors.text,
};

Phone.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Phone);
