import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './SectionTitleWithIcon.module.scss';

const SectionTitleWithIcon = ({
  title,
  icon: Icon,
  iconProps: { className: iconClassName, ...otherIconProps },
  className,
  centered = true,
  iconClassNames,
  big = false,
}) => {
  return (
    <div
      className={cx(
        {
          [styles.container]: !centered,
          [styles.centeredContainer]: centered,
          [styles.big]: big,
        },
        className,
      )}
    >
      <span className={styles.iconHolder}>
        <Icon className={cx(styles.icon, iconClassNames)} {...otherIconProps} />
      </span>
      <h3 className={styles.title}>{title}</h3>
    </div>
  );
};

SectionTitleWithIcon.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
  className: PropTypes.string,
  iconClassNames: PropTypes.string,
  centered: PropTypes.bool,
  big: PropTypes.bool,
  iconProps: PropTypes.object,
};

SectionTitleWithIcon.defaultProps = {
  iconProps: {},
};

export default SectionTitleWithIcon;
