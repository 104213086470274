import React from 'react';

import RadioGroup from '../RadioGroup';

import CoreField from './CoreField';

export const RadioField = (props) => (
  <CoreField component={RadioGroup} {...props} />
);

export default RadioField;
