import React, { useState } from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import IconButton from '../../../../../../../components/IconButton';
import Modal from '../../../../../../../components/Modal';
import RadioGroup from '../../../../../../../components/RadioGroup';
import CloseIcon from '../../../../../../../icons/Close';
import InformationCircleThickIcon from '../../../../../../../icons/InformationCircleThick';
import WaterThickIcon from '../../../../../../../icons/WaterThick';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './FloodDisclosure.module.scss';

const FloodDisclosure = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  const [open, changeOpen] = useState(false);
  const closeModal = () => changeOpen(false);
  const openModal = () => changeOpen(true);
  return (
    <>
      <div className={cx(styles.container, className)}>
        <SectionTitle title="Flood Disclosure" icon={WaterThickIcon} />
        <label className={styles.label}>
          Has the property flooded 3 or more times in the last 5 years?
        </label>
        <p className={styles.description}>
          This is not common.{' '}
          <span className={styles.link} onClick={openModal}>
            Learn how Georgia defines flooding.
          </span>
        </p>
        <Field
          component={RadioGroup}
          id="custom_state_data.has_property_flooded"
          name="custom_state_data.has_property_flooded"
          radiosContainerClassName={styles.radioButtonsContainer}
          options={[
            {
              id: 'has_property_flooded_yes',
              value: true,
              label: 'Yes',
            },
            {
              id: 'has_property_flooded_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />
      </div>
      <Modal open={open} className={styles.modal} onClose={closeModal}>
        <div>
          <span className={styles.title}>
            <span className={styles.iconContainer}>
              <InformationCircleThickIcon className={styles.icon} />
            </span>
            <h2>Flooding</h2>
          </span>
          <p className={styles.description}>
            Georgia Code defines flooding as{' '}
            <i>
              "the inundation of a portion of the living space covered by the
              lease which was caused by an increased water level in an
              established water source such as a river, stream, or drainage
              ditch or as a ponding of water at or near the point where heavy or
              excessive rain fell."
            </i>
          </p>
          <IconButton
            className={styles.closeContainer}
            iconProps={{ className: styles.closeIcon }}
            icon={CloseIcon}
            onClick={closeModal}
          />
        </div>
      </Modal>
    </>
  );
};

FloodDisclosure.propTypes = {
  className: PropTypes.string,
};

export default FloodDisclosure;
