import React from 'react';

import OptionItemCard from '../../../../../components/OptionItemCard';
import { H2 } from '../../../../../components/Typography';

import items from './items';

import styles from './FreeEducationSection.module.scss';

const FreeEducationSection = () => {
  return (
    <div className={styles.container}>
      <H2 className="mb-24">Free Education </H2>
      <div className={styles.items}>
        {items.map((item, index) => (
          <OptionItemCard
            {...item}
            key={index}
            className={styles.item}
            iconProps={{ className: styles.icon }}
          />
        ))}
      </div>
    </div>
  );
};

export default FreeEducationSection;
