import React from 'react';

import validShareAbleUnit from '../../validators/validShareAbleUnit';

import InputField from './InputField';

const UnitField = ({ validate = [], ...props }) => (
  <InputField
    validate={[
      validShareAbleUnit,
      ...(Array.isArray(validate) ? validate : [validate]),
    ]}
    {...props}
  />
);

export default UnitField;
