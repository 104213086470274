import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import cx from 'classnames';
import PropTypes from 'prop-types';

import SelectField from '../../../../../../components/SelectField';
import convertCardinalToOrdinal from '../../../../../../helpers/cardinal-to-ordinal';
import getFormattedDateLocalTZ from '../../../../../../helpers/format-date-local';
import toCurrency from '../../../../../../helpers/toCurrency';
import { getRentPaymentCategoryLabel } from '../../../../../../services/utilities';
import fieldRequired from '../../../../../../validators/fieldRequired';

import styles from './AssignPaymentRequestRulesToBank.module.scss';

const AssignPaymentRequestRulesToBank = ({
  paymentRequestRules,
  bankAccounts,
}) => {
  const hasMonthlyCharges = !!paymentRequestRules?.filter(
    (charge) => charge.type === 'MONTHLY',
  )?.length;

  const hasOneTimeCharges = !!paymentRequestRules?.filter(
    (charge) => charge.type === 'ONE_TIME',
  )?.length;

  const bankAccountOptions = [
    { value: null, label: 'Select Bank Account...' },
    ...bankAccounts.map((ba) => ({
      value: ba.id,
      label: ba.nickname || ba.bank_name,
      default: ba.default_for_currency,
    })),
  ];

  const AssignRuleRow = ({ rule, isMonthly, name }) => {
    let doesLastDayOfMonthTerm;

    if (isMonthly) {
      doesLastDayOfMonthTerm =
        rule.due_day_of_month === '31'
          ? 'last day of every month'
          : `the ${convertCardinalToOrdinal(rule.due_day_of_month)}`;
    } else {
      doesLastDayOfMonthTerm = getFormattedDateLocalTZ(rule.end_date);
    }

    return (
      <div className={styles.assignRuleContainer}>
        <div className={styles.ruleDataContainer}>
          <div className={styles.ruleCategory}>
            {getRentPaymentCategoryLabel(rule.category)}
          </div>
          <div>
            {toCurrency(rule.amount)} due on {doesLastDayOfMonthTerm}
          </div>
        </div>
        <Field
          component={SelectField}
          id={`${name}.destination_id`}
          name={`${name}.destination_id`}
          className={cx(styles.selectField)}
          options={bankAccountOptions}
          validate={fieldRequired}
          disabled={bankAccounts?.length === 1}
        />
      </div>
    );
  };

  return (
    <>
      <div className={styles.title}>Assign Charges</div>
      <div>
        Decide which bank account your money goes to when your renters pay
        charges.
      </div>
      <FieldArray name="paymentRequestRules">
        {({ fields }) => {
          return (
            <>
              {hasMonthlyCharges && (
                <>
                  <h2 className={styles.sectionHeading}>MONTHLY CHARGES</h2>
                  {fields.map((name, index) => {
                    const rule = paymentRequestRules[index];
                    if (rule.type === 'MONTHLY') {
                      return (
                        <AssignRuleRow
                          rule={rule}
                          key={rule.id}
                          isMonthly
                          name={name}
                        />
                      );
                    }
                  })}
                </>
              )}
              {hasOneTimeCharges && (
                <>
                  <h2 className={styles.sectionHeading}>ONE-TIME CHARGES</h2>
                  {fields.map((name, index) => {
                    const rule = paymentRequestRules[index];
                    if (rule.type !== 'MONTHLY') {
                      return (
                        <AssignRuleRow rule={rule} key={rule.id} name={name} />
                      );
                    }
                  })}
                </>
              )}
            </>
          );
        }}
      </FieldArray>
    </>
  );
};

AssignPaymentRequestRulesToBank.propTypes = {
  paymentRequestRules: PropTypes.array,
};

export default AssignPaymentRequestRulesToBank;
