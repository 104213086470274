import React, { useEffect, useRef, useState } from 'react';
import DayPicker from 'react-day-picker';
import { DateUtils } from 'react-day-picker';
import moment from 'moment';
import PropTypes from 'prop-types';

import IconButton from '../../components/IconButton';
import CloseIcon from '../../icons/Close';

import YearMonthForm from './YearMonthForm';

import './DatePicker.css';
import styles from './DatePicker.module.scss';

const getMonth = (value, { fromDate, toDate }) => {
  let month = moment(value, 'MM/DD/YYYY');
  let selected;
  if (!value || !month.isValid()) {
    month = new Date();
  } else {
    selected = month.toDate();
    month = month.toDate();
  }
  if (fromDate.getTime() > month.getTime()) {
    month = fromDate;
  } else if (month.getTime() > toDate.getTime()) {
    month = toDate;
  }
  return {
    month,
    selected,
  };
};

const DatePicker = React.forwardRef(
  ({ value, fromDate, toDate, onChange, onClose, ...otherProps }, ref) => {
    const date = getMonth(value, { fromDate, toDate });
    const { selected } = date;

    const [month, setMonth] = useState(date.month);
    const componentMounted = useRef(false);
    useEffect(() => {
      if (!componentMounted.current) {
        return;
      }
      componentMounted.current = true;
      const newDate = getMonth(value, { fromDate, toDate });
      setMonth(newDate.month);
    }, [value, fromDate, toDate]);

    return (
      <div ref={ref} {...otherProps} className={styles.wrap}>
        <IconButton
          icon={CloseIcon}
          onClick={onClose}
          className={styles.closeAction}
        />
        <YearMonthForm
          date={month}
          onChange={(newDate) => {
            setMonth(newDate);
            if (selected) {
              const daysInMonth = new Date(
                newDate.getFullYear(),
                newDate.getMonth() + 1,
                0,
              ).getDate();
              onChange(
                new Date(
                  newDate.getFullYear(),
                  newDate.getMonth(),
                  Math.min(selected.getDate(), daysInMonth),
                ),
              );
            }
          }}
          fromDate={fromDate}
          toDate={toDate}
        />
        <div onMouseDown={(e) => e.preventDefault()}>
          <DayPicker
            month={month}
            fromMonth={fromDate}
            toMonth={toDate}
            className={styles.container}
            disabledDays={{
              before: fromDate,
              after: toDate,
            }}
            onDayClick={(date, { disabled }) => {
              if (disabled) {
                return null;
              }
              onChange(date);
              onClose();
            }}
            modifiers={{
              selected: (day) => selected && DateUtils.isSameDay(selected, day),
            }}
            captionElement={() => null}
          />
        </div>
      </div>
    );
  },
);

DatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fromDate: PropTypes.any,
  toDate: PropTypes.any,
  onChange: PropTypes.func,
};

DatePicker.defaultProps = {
  fromDate: new Date(1900, 0, 1),
  toDate: new Date(2100, 0, 1),
};

export default DatePicker;
