import React from 'react';
import PropTypes from 'prop-types';

import SelectionRadioBoxGroup from '../../../components/SelectionRadioBoxGroup';
import LandlordKeyIcon from '../../../icons/Key';
import RenterHouseIcon from '../../../icons/RenterHouse';

import styles from './WhichDescribeYouSection.module.scss';

const WhichDescribeYouSection = ({ onChange, value }) => (
  <SelectionRadioBoxGroup
    vertical
    id="landlord"
    options={[
      {
        label: 'Landlord',
        value: 'landlord',
        icon: LandlordKeyIcon,
        id: 'landlord',
      },
      {
        label: 'Renter',
        value: 'renter',
        icon: RenterHouseIcon,
        id: 'renter',
      },
    ]}
    input={{
      name: 'which-describe-you',
      onChange,
      value,
    }}
    radioProps={{
      className: styles.describeRadio,
    }}
  />
);

WhichDescribeYouSection.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default WhichDescribeYouSection;
