import React from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../../../../../../components/Button';
import Textarea from '../../../../../../components/Textarea';

import styles from './HowDidYouHearStandAlone.module.scss';

const HowDidYouHearStandAlone = ({ onSubmit, initialValues }) => {
  return (
    <Form
      onSubmit={(...data) => {
        onSubmit(...data);
      }}
      initialValues={{ ...initialValues }}
      subscription={{
        submitting: true,
        values: true,
        form: true,
      }}
    >
      {({ handleSubmit, values }) => (
        <div className={styles.container}>
          <h2>How did you hear about TurboTenant? </h2>
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Field
              component={Textarea}
              hint={'(Optional)'}
              name="how_did_you_hear_about_us"
              className={styles.HowDidYouHearAboutUs}
              validate={(value) => {
                if (value?.length && value.length > 250) {
                  return 'Max 250 characters';
                }
              }}
              skipTouchedCheck
            />
            <div className={styles.buttonWrapper}>
              <Button
                secondary
                onClick={() => {
                  onSubmit();
                }}
              >
                {' '}
                SKIP QUESTION
              </Button>
              <Button
                type={'submit'}
                className={styles.submit}
                disabled={!values.how_did_you_hear_about_us}
              >
                Continue
              </Button>
            </div>
          </form>
        </div>
      )}
    </Form>
  );
};

export default HowDidYouHearStandAlone;
