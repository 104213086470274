import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useFocusTo from '../../helpers/use-focus-to';
import ErrorIcon from '../../icons/ErrorIcon';
import Callout from '../Callout';

import styles from './FormError.module.scss';

const FormError = ({ className, focus, error, style }) => {
  const errorElement = useFocusTo(error && focus);

  if (!error) {
    return null;
  }
  return (
    <Callout
      className={cx(styles.container, className)}
      style={style}
      type="error"
      ref={errorElement}
    >
      <div className={styles.iconWrap}>
        <ErrorIcon className={styles.icon} />
      </div>{' '}
      <span id="form-error-text">{error}</span>
    </Callout>
  );
};

FormError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  style: PropTypes.object,
  focus: PropTypes.bool,
};

export default FormError;
