import isString from 'lodash.isstring';

const clearMask = (phone) => {
  if (!isString(phone)) {
    return '';
  }
  return phone.replace(/[^A-Z0-9]/gi, '');
};

export default clearMask;
