import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const AccountNav = (props) => (
  <svg {...props} viewBox="0 0 20 20" fill={props.color}>
    <path d="M10 0c5.523 0 10 4.477 10 10a9.967 9.967 0 0 1-2.882 7.022l-.007.007A9.964 9.964 0 0 1 10 20a9.97 9.97 0 0 1-7.11-2.968A9.969 9.969 0 0 1 0 10C0 4.477 4.477 0 10 0ZM8.553 5.659a.612.612 0 0 1-.714.452c-.29-.058-.359-.035-.389.018-.061.108-.08.364-.032.718.027.2.073.417.136.662.032.128.065.243.122.446.064.23.08.295.08.412 0 .291-.204.535-.476.597a.732.732 0 0 0-.03.22c0 .085.012.164.03.22a.612.612 0 0 1 .475.596c0 .75.18 1.259.465 1.591.15.176.286.259.341.277.25.084.419.318.419.581v2.04a.612.612 0 0 1-.396.573 67.717 67.717 0 0 1-1.329.489c-1.434.52-2.3.857-2.906 1.161A8.733 8.733 0 0 0 10 18.776a8.733 8.733 0 0 0 5.658-2.07c-.248-.124-.54-.253-.875-.39-.25-.103-.815-.316-1.412-.537l-.36-.133c-.838-.31-1.628-.596-1.593-.583a.612.612 0 0 1-.398-.573v-2.041c0-.263.169-.497.419-.58.055-.02.19-.102.341-.278.285-.332.465-.841.465-1.591 0-.29.203-.534.475-.597a.732.732 0 0 0 .03-.22.732.732 0 0 0-.03-.219.612.612 0 0 1-.475-.597c0-.163.278-1.037.262-.979.116-.407.189-.769.209-1.1.014-.24 0-.452-.045-.63-.253-1.014-3.864-1.014-4.118 0ZM10 1.224a8.775 8.775 0 0 0-6.572 14.59c.697-.398 1.637-.772 3.41-1.415a282.827 282.827 0 0 0 .917-.334v-1.251a2.478 2.478 0 0 1-.465-.426c-.413-.481-.677-1.135-.743-1.968-.655-.49-.694-1.786-.116-2.371a13.616 13.616 0 0 1-.063-.236 7.032 7.032 0 0 1-.163-.8c-.081-.597-.046-1.09.18-1.49.247-.434.67-.656 1.205-.662 1.173-1.736 5.752-1.569 6.27.5.077.31.1.644.078 1.001a6.347 6.347 0 0 1-.254 1.363c-.038.133-.041.145-.102.337.564.591.521 1.872-.129 2.358-.066.833-.33 1.487-.743 1.968-.155.18-.314.32-.465.426v1.247c.784.286 2.493.913 3.002 1.121.532.218.97.421 1.331.625A8.775 8.775 0 0 0 10 1.225Z" />
  </svg>
);

AccountNav.defaultProps = {
  width: 20,
  height: 20,
  color: colors.greyLight,
};

AccountNav.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(AccountNav);
