import React, { useState } from 'react';
import LightBox from 'react-images';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import BackButton from '../../../../components/BackButton';
import Button from '../../../../components/Button';
import FlatButton from '../../../../components/FlatButton';
import IconButton from '../../../../components/IconButton';
import IconTooltip from '../../../../components/IconTooltip';
import Tag from '../../../../components/Tag';
import { Tooltip } from '../../../../components/Tooltip';
import colors from '../../../../constants/colors';
import AccountNav from '../../../../icons/AccountNav';
import Attachment from '../../../../icons/Attachment';
import EditIcon from '../../../../icons/Edit';
import Invoice from '../../../../icons/Invoice';
import Lula from '../../../../icons/Lula';
import MessagesIcon from '../../../../icons/Messages';
import Notes from '../../../../icons/Notes';
import RadioCheck from '../../../../icons/RadioCheck';
import Share from '../../../../icons/Share';
import StarEmpty from '../../../../icons/StarEmpty';
import { useConfig } from '../../../../providers/ConfigProvider';

import AvatarImage from './avatar.png';
import { LulaExampleModalTypes } from './LulaExampleModalTypes';
import LulaReceiptInvoiceExample from './LulaReceiptInvoiceExample';

import styles from './LulaMaintenanceRequestDemo.module.scss';

const lulaMaintenanceBenefits = [
  '24/7 expert troubleshooting with tenant',
  'Coordination between Pro and tenant handled for you',
  'Real-time updates with before & after photos',
  'Final work reviewed to ensure quality standards',
];

const renderLulaBenefits = (list) =>
  list.map((item, index) => (
    <div key={index} className={styles.checkListItem}>
      <RadioCheck color={colors.lightGreenAlt} className={styles.checkIcon} />
      <div className={styles.checkContent}>
        <p className={styles.checkTitle}>{item}</p>
      </div>
    </div>
  ));

const LulaMaintenanceRequestDemo = ({ baseUrl }) => {
  const { ASSET_BUCKET_URL } = useConfig();
  const [open, setOpen] = useState(null);
  const [previewedImage, setPreviewedImage] = useState(null);

  const photos = [
    `${ASSET_BUCKET_URL}uploads/assets/mr-demo-image-2.jpeg`,
    `${ASSET_BUCKET_URL}uploads/assets/mr-demo-image-1.jpeg`,
  ];

  return (
    <div className={styles.container}>
      <Link className={styles.topBar} to={`${baseUrl}/lula`}>
        This is a Demo Maintenance Plus Request - Learn More About Maintenance
        Plus Here
      </Link>
      <div className={styles.content}>
        <BackButton className={styles.back} />
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <img
              className={styles.image}
              src={AvatarImage}
              alt="Avatar image"
            />
            <div className={styles.titleWrapper}>
              <h3 className={styles.title}>Heat not working</h3>
              <address className={styles.address}>
                1234 Broadway, Littleton, CO 80127
              </address>
            </div>
          </div>
          <div className={styles.headerRight}>
            <Tag className={styles.tag}>Resolved</Tag>
            <StarEmpty className={styles.star} />
          </div>
        </div>
        <div className={styles.mainContent}>
          <div className={styles.mainContentLeft}>
            <div className={styles.detailsCard}>
              <div className={styles.detailsTitleWrapper}>
                <h4 className={styles.detailsTitle}>Details</h4>
                <FlatButton
                  data-tooltip-id="tooltip-demo-edit-maintenance"
                  className={styles.editBtn}
                  icon={EditIcon}
                  iconProps={{
                    className: styles.editIcon,
                  }}
                  disabled={true}
                >
                  Edit
                </FlatButton>
                <Tooltip id="tooltip-demo-edit-maintenance">
                  This is a demo, actions are disabled.
                </Tooltip>
              </div>
              <div className={styles.detailsItem}>
                <span className={styles.detailsItemTitle}>Requested:</span>
                <span className={styles.detailsItemContent}>
                  Jan 15, 2024 | 2:05 pm
                </span>
              </div>
              <div className={styles.detailsItem}>
                <span className={styles.detailsItemTitle}>
                  Maintenance ID:{' '}
                </span>
                <span className={styles.detailsItemContent}>54321</span>
              </div>
              <div className={styles.detailsItem}>
                <span className={styles.detailsItemTitle}>
                  Preferred Time to Enter:{' '}
                </span>
                <span className={styles.detailsItemContent}>Anytime</span>
              </div>
              <div className={styles.detailsItem}>
                <span className={styles.detailsItemTitle}>Category: </span>
                <span className={styles.detailsItemContent}>
                  Heating and Cooling
                </span>
              </div>
              <div className={styles.detailsItem}>
                <span className={styles.detailsItemTitle}>Issue Title: </span>
                <span className={styles.detailsItemContent}>
                  Heat not working
                </span>
              </div>
              <div className={styles.detailsItem}>
                <span className={styles.detailsItemTitle}>Description: </span>
                <span className={styles.detailsItemContent}>
                  Only cold air is coming out when I turn the heat on. I tried
                  turning it up really high to see if that would make a
                  difference and it didn't seem to do anything.
                </span>
              </div>
              <div className={styles.imagesWrapper}>
                {photos.map((photo, index) => (
                  <div
                    className={styles.imageHolder}
                    key={index}
                    style={{
                      backgroundImage: `url('${photo}')`,
                    }}
                    onClick={() => {
                      setPreviewedImage(index);
                    }}
                  />
                ))}
              </div>
            </div>
            <div className={styles.tenantCard}>
              <h4 className={styles.tenantCardTitle}>Tenant Contact</h4>
              <div className={styles.tenantCardNameWrapper}>
                <span className={styles.tenantCardName}>John Smith</span>
                <IconButton
                  className={styles.messageIcon}
                  icon={MessagesIcon}
                  iconProps={{ width: 20, height: 18, color: colors.primary }}
                  disabled={true}
                  data-tooltip-id="tooltip-demo-message-maintenance"
                />
                <Tooltip id="tooltip-demo-message-maintenance">
                  This is a demo, actions are disabled.
                </Tooltip>
              </div>
              <span className={styles.tenantCardText}>
                Last active Jan 16, 2023 | 7:13 am
              </span>
              <span className={styles.tenantCardText}>
                tenant.example@gmail.com | (970) 555-5555
              </span>
            </div>
            <div className={cx(styles.promoCard, styles.promoCardMobile)}>
              <h4 className={styles.promoCardTitle}>
                Stress-Free Maintenance with
                <Lula className={styles.promoCardIcon} />
              </h4>
              <div className={styles.benefitsWrapper}>
                {renderLulaBenefits(lulaMaintenanceBenefits)}
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  className={styles.button}
                  to="/owners/maintenance/onboarding"
                  secondary
                >
                  Get Started
                </Button>
                <Button className={styles.button} to="/owners/maintenance/lula">
                  Learn More
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.mainContentRight}>
            <div className={styles.activityCard}>
              <div className={styles.activityCardTitleWrapper}>
                <h4 className={styles.activityCardTitle}>
                  Activity
                  <IconTooltip
                    className={styles.activityCardInfo}
                    tip={
                      'Activity Log is visible to both you and all of the tenants on the lease unless there is a lock icon.'
                    }
                  />
                </h4>
                <FlatButton
                  className={styles.addNote}
                  icon={Notes}
                  disabled={true}
                  data-tooltip-id="tooltip-demo-notes-maintenance"
                >
                  Add Note
                </FlatButton>
                <Tooltip id="tooltip-demo-notes-maintenance">
                  This is a demo, actions are disabled.
                </Tooltip>
              </div>
              <div className={styles.activityItemWrapper}>
                <div className={styles.activity}>
                  <div className={styles.activityIconWrapper}>
                    <Lula className={styles.activityIcon} />
                  </div>
                  <div className={styles.activityContent}>
                    <h5 className={styles.activityTitle}>Receipt</h5>
                    <span className={styles.activityDate}>
                      Jan 19, 2024 | 12:00 pm
                    </span>
                    <button
                      className={styles.activityReceipt}
                      onClick={() => setOpen(LulaExampleModalTypes.RECEIPT)}
                    >
                      <Invoice className={styles.activityReceiptIcon} />
                      <span className={styles.activityReceiptTitle}>
                        Receipt
                      </span>
                    </button>
                  </div>
                </div>
                <div className={styles.activity}>
                  <div className={styles.activityIconWrapper}>
                    <Lula className={styles.activityIcon} />
                  </div>
                  <div className={styles.activityContent}>
                    <h5 className={styles.activityTitle}>Invoice</h5>
                    <span className={styles.activityDate}>
                      Jan 19, 2024 | 12:00 pm
                    </span>
                    <button
                      className={styles.activityReceipt}
                      onClick={() => setOpen(LulaExampleModalTypes.INVOICE)}
                    >
                      <Invoice className={styles.activityReceiptIcon} />
                      <span className={styles.activityReceiptTitle}>
                        Invoice
                      </span>
                    </button>
                  </div>
                </div>
                <div className={styles.activity}>
                  <div className={styles.activityIconWrapper}>
                    <Lula className={styles.activityIcon} />
                  </div>
                  <div className={styles.activityContent}>
                    <h5 className={styles.activityTitle}>Pro Resolved</h5>
                    <span className={styles.activityDate}>
                      Jan 18, 2024 | 11:40 am
                    </span>
                    <div className={styles.activityItem}>
                      <span className={styles.activityItemTitle}>
                        Completion Date:
                      </span>
                      <span className={styles.activityItemInfo}>
                        01/18/2023
                      </span>
                    </div>
                    <div className={styles.activityItem}>
                      <span className={styles.activityItemTitle}>
                        Service Notes:
                      </span>
                      <span className={styles.activityItemInfo}>
                        I arrived at the customers house, and the furnace blower
                        would run, but the heat would not turn on. I checked the
                        voltages at the furnace system board, and the blower did
                        have voltage but the white wire for heat did not have
                        voltage. I went to the thermostat and jumpered the white
                        and red wires, and the furnace came on and worked as
                        expected. I replaced the thermostat with a new
                        thermostat and the furnace began working. noticed that a
                        filter had collapsed and was blocking the air to the
                        blower motor. I replaced the filter also.
                      </span>
                      <div className={styles.activityAttachment}>
                        <Attachment className={styles.activityAttachmentIcon} />
                        <a
                          className={styles.activityAttachmentTitle}
                          href={`${ASSET_BUCKET_URL}uploads/assets/heat-issue.jpeg`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          IMG_0627.jpg
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.activity}>
                  <div className={styles.activityIconWrapper}>
                    <Lula className={styles.activityIcon} />
                  </div>
                  <div className={styles.activityContent}>
                    <h5 className={styles.activityTitle}>Pro Dispatched</h5>
                    <span className={styles.activityDate}>
                      Jan 18, 2024 | 9:40 am
                    </span>
                  </div>
                </div>
                <div className={styles.activity}>
                  <div className={styles.activityIconWrapper}>
                    <Lula className={styles.activityIcon} />
                  </div>
                  <div className={styles.activityContent}>
                    <h5 className={styles.activityTitle}>Scheduled</h5>
                    <span className={styles.activityDate}>
                      Jan 15, 2024 | 4:40 pm
                    </span>
                    <div className={styles.activityItem}>
                      <span className={styles.activityItemTitle}>Date:</span>
                      <span className={styles.activityItemInfo}>
                        Jan 18, 2023
                      </span>
                    </div>
                    <div className={styles.activityItem}>
                      <span className={styles.activityItemTitle}>
                        Start Time:
                      </span>
                      <span className={styles.activityItemInfo}>
                        10:00 am MT
                      </span>
                    </div>
                    <div className={styles.activityItem}>
                      <span className={styles.activityItemTitle}>
                        End Time:
                      </span>
                      <span className={styles.activityItemInfo}>
                        12:00 am MT
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.activity}>
                  <div className={styles.activityIconWrapper}>
                    <Lula className={styles.activityIcon} />
                  </div>
                  <div className={styles.activityContent}>
                    <h5 className={styles.activityTitle}>Scheduling</h5>
                    <span className={styles.activityDate}>
                      Jan 15, 2024 | 4:32 pm
                    </span>
                  </div>
                </div>
                <div className={styles.activity}>
                  <div className={styles.activityIconWrapper}>
                    <Lula className={styles.activityIcon} />
                  </div>
                  <div className={styles.activityContent}>
                    <h5 className={styles.activityTitle}>Troubleshooting</h5>
                    <span className={styles.activityDate}>
                      Jan 15, 2024 | 2:15 pm
                    </span>
                  </div>
                </div>
                <div className={styles.activity}>
                  <div className={styles.activityIconWrapper}>
                    <Share
                      className={styles.activityIcon}
                      color={colors.lightGreenAlt}
                    />
                  </div>
                  <div className={styles.activityContent}>
                    <h5 className={styles.activityTitle}>Sent to Lula</h5>
                    <span className={styles.activityDate}>
                      Jan 15, 2024 | 2:05 pm
                    </span>
                  </div>
                </div>
                <div className={styles.activity}>
                  <div className={styles.activityIconWrapper}>
                    <AccountNav className={styles.activityIcon} />
                  </div>
                  <div
                    className={cx(
                      styles.activityContent,
                      styles.activityContentNoMargin,
                    )}
                  >
                    <h5 className={styles.activityTitle}>
                      Requested by John Brown
                    </h5>
                    <span className={styles.activityDate}>
                      Jan 15, 2024 | 2:05 pm
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={cx(styles.promoCard, styles.promoCardDesktop)}>
              <h4 className={styles.promoCardTitle}>
                Stress-Free Maintenance with
                <Lula className={styles.promoCardIcon} />
              </h4>
              <div className={styles.benefitsWrapper}>
                {renderLulaBenefits(lulaMaintenanceBenefits)}
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  className={styles.button}
                  to="/owners/maintenance/onboarding"
                  secondary
                >
                  Get Started
                </Button>
                <Button className={styles.button} to="/owners/maintenance/lula">
                  Learn More
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LulaReceiptInvoiceExample open={open} onClose={() => setOpen(false)} />
      {previewedImage !== null && (
        <LightBox
          isOpen
          currentImage={previewedImage}
          images={photos.map((src) => ({ src }))}
          onClickPrev={() => setPreviewedImage(previewedImage - 1)}
          onClickNext={() => setPreviewedImage(previewedImage + 1)}
          onClose={() => setPreviewedImage(null)}
        />
      )}
    </div>
  );
};

LulaMaintenanceRequestDemo.propTypes = {
  baseUrl: PropTypes.string.isRequired,
};

export default LulaMaintenanceRequestDemo;
