import { v4 as uuidv4 } from 'uuid';

import * as localStorageService from '../services/localStorageService';

const getDeviceSignature = () => {
  const existingSignature = localStorageService.getItem('deviceSignature');
  if (!existingSignature) {
    const newSignature = uuidv4();
    localStorageService.setItem('deviceSignature', newSignature);
    return newSignature;
  }
  return existingSignature;
};

export default getDeviceSignature;
