import React, { useState } from 'react';
import PropTypes from 'prop-types';

const iOSIframeAnimation = ({ src, style = {}, className, ...props }) => {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  return (
    <>
      {!iframeLoaded ? (
        <div
          className={className}
          style={{
            ...style,
            background: 'transparent',
          }}
        />
      ) : null}

      <iframe
        className={className}
        style={{
          border: 'none',
          ...style,
          display: iframeLoaded ? 'inline' : 'none',
        }}
        src={src}
        onLoad={() => setIframeLoaded(true)}
        {...props}
      />
    </>
  );
};

iOSIframeAnimation.propTypes = {
  src: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  props: PropTypes.object,
};

export default iOSIframeAnimation;
