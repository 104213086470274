import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const RenterHouse = (props) => (
  <svg {...props} viewBox="0 0 48 45" fill={props.color}>
    <path d="M7.472 21.87c.537 0 .972.431.972.963v21.203a.968.968 0 01-.972.964.968.968 0 01-.972-.964V22.833c0-.532.435-.963.972-.963zm33.056 0c.537 0 .972.431.972.963v21.203a.968.968 0 01-.972.964.968.968 0 01-.972-.964V22.833c0-.532.435-.963.972-.963zm-19.445 3.853c.537 0 .973.431.973.963v5.786c-.003 1.98-.552 3.326-1.945 3.728v7.834a.968.968 0 01-.972.964h-5.833a.968.968 0 01-.973-.964v-7.833c-1.395-.402-1.944-1.749-1.944-3.73v-5.785c0-.532.435-.963.972-.963h9.722zm15.556 0c.537 0 .972.431.972.963v5.786c-.002 1.98-.551 3.326-1.944 3.728v7.834a.968.968 0 01-.973.964h-5.833a.968.968 0 01-.972-.964v-7.833c-1.395-.402-1.945-1.749-1.945-3.73v-5.785c0-.532.436-.963.973-.963h9.722zM20.11 27.65h-7.778v4.821c0 1.466.252 1.928.973 1.928.537 0 .972.431.972.963v7.709h3.889v-7.709c0-.532.435-.963.972-.963.718 0 .97-.463.972-1.928v-4.82zm15.556 0h-7.778v4.821c0 1.466.251 1.928.972 1.928.537 0 .972.431.972.963v7.709h3.89v-7.709c0-.532.435-.963.971-.963.719 0 .97-.463.973-1.928v-4.82zM16.222 16.087c2.147 0 3.89 1.727 3.89 3.855s-1.743 3.855-3.89 3.855c-2.147 0-3.889-1.727-3.889-3.855s1.742-3.855 3.89-3.855zm15.556 0c2.147 0 3.889 1.727 3.889 3.855s-1.742 3.855-3.89 3.855c-2.146 0-3.888-1.727-3.888-3.855s1.742-3.855 3.889-3.855zM24.656.919l22.36 20.239a.958.958 0 01.063 1.361.978.978 0 01-1.373.062L24 2.935 2.294 22.581a.978.978 0 01-1.373-.062.958.958 0 01.062-1.361L23.344.918a.979.979 0 011.312 0zm-8.434 17.095a1.937 1.937 0 00-1.944 1.928c0 1.064.871 1.928 1.944 1.928a1.937 1.937 0 001.945-1.928 1.937 1.937 0 00-1.945-1.928zm15.556 0a1.937 1.937 0 00-1.945 1.928c0 1.064.872 1.928 1.945 1.928a1.937 1.937 0 001.944-1.928 1.937 1.937 0 00-1.944-1.928z" />
  </svg>
);

RenterHouse.defaultProps = {
  width: 48,
  height: 45,
  color: colors.text,
};

RenterHouse.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(RenterHouse);
