import React, { memo } from 'react';
import PropTypes from 'prop-types';

const MultiFamilyColor = ({ color, insideColor, borderColor, ...props }) => (
  <svg viewBox="0 0 40 33" {...props} fill={color}>
    <g fill="none" fillRule="nonzero">
      <path
        fill={insideColor}
        d="M20 1.577V6.91l-1.305-.895-1.667-1.145-.361-.248V1.577H20Zm17.5 0V6.91l-1.305-.895-1.667-1.145-.361-.248V1.577H37.5ZM13.75 23.5v9.333h-5V23.5h5Zm17.5 0v9.333h-5V23.5h5ZM9.583 9.833v5H6.25v-5h3.333Zm6.667 0v5h-3.333v-5h3.333Zm10.833 0v5H23.75v-5h3.333Zm6.667 0v5h-3.333v-5h3.333Z"
      />
      <path
        fill={borderColor}
        d="m11.284 0 .024.002a.837.837 0 0 1 .107.014l.05.012a.655.655 0 0 1 .104.035l.059.028.071.04 4.134 2.646v-1.2c0-.46.373-.833.834-.833H20c.46 0 .833.373.833.833V4.89L28.269.132l.073-.042.061-.028a.886.886 0 0 1 .256-.06L28.69 0h.063l.025.002a.837.837 0 0 1 .256.06l.058.027.077.043 4.165 2.665v-1.22c0-.46.373-.833.834-.833H37.5c.46 0 .833.373.833.833v4.42l1.251.801c.356.228.48.68.307 1.052l-.054.1a.833.833 0 0 1-1.151.252l-.353-.226V32h.834a.833.833 0 0 1 0 1.667H.833a.833.833 0 1 1 0-1.667h.833V7.955l-.383.247a.833.833 0 0 1-.899-1.404L10.801.132l.038-.024.022-.012.008-.004.006-.003.056-.026a.874.874 0 0 1 .104-.035l.05-.011a.744.744 0 0 1 .107-.015L11.22 0h.063Zm19.133 24.333h-3.334V32h3.334v-7.667Zm-17.5 0H9.583V32h3.334v-7.667Zm-1.668-22.51L3.333 6.889V32h4.583v-8.5c0-.46.374-.833.834-.833h5c.46 0 .833.373.833.833V32h4.583V6.885a.844.844 0 0 1-.064-.037L11.25 1.823Zm17.469-.001-7.852 5.026-.033.017V32h4.583v-8.5c0-.46.374-.833.834-.833h5c.46 0 .833.373.833.833V32h4.583V6.909l-7.948-5.087Zm3.365 16.511a.833.833 0 0 1 0 1.667h-6.666a.833.833 0 1 1 0-1.667h6.666Zm-17.5 0a.833.833 0 0 1 0 1.667H7.917a.833.833 0 1 1 0-1.667h6.666ZM9.583 9c.46 0 .834.373.834.833v5c0 .46-.373.834-.834.834H6.25a.833.833 0 0 1-.833-.834v-5c0-.46.373-.833.833-.833h3.333Zm17.5 0c.46 0 .834.373.834.833v5c0 .46-.373.834-.834.834H23.75a.833.833 0 0 1-.833-.834v-5c0-.46.373-.833.833-.833h3.333Zm6.667 0c.46 0 .833.373.833.833v5c0 .46-.373.834-.833.834h-3.333a.833.833 0 0 1-.834-.834v-5c0-.46.373-.833.834-.833h3.333Zm-17.5 0c.46 0 .833.373.833.833v5c0 .46-.373.834-.833.834h-3.333a.833.833 0 0 1-.834-.834v-5c0-.46.374-.833.834-.833h3.333Zm16.667 1.667H31.25V14h1.667v-3.333Zm-24.167 0H7.083V14H8.75v-3.333Zm6.667 0H13.75V14h1.667v-3.333Zm10.833 0h-1.667V14h1.667v-3.333ZM36.667 2.41H35v1.453l1.666 1.066V2.41Zm-17.5 0H17.5v1.433l1.666 1.066v-2.5Z"
      />
    </g>
  </svg>
);

MultiFamilyColor.defaultProps = {
  width: 40,
  height: 33,
  color: 'currentColor',
  insideColor: '#7FE3FF',
  borderColor: '#042238',
};

MultiFamilyColor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(MultiFamilyColor);
