import { useEffect, useState } from 'react';

import { getItem, setItem } from '../../../../services/localStorageService';

export const additionalSignupDataKey = 'ADDITIONAL_SIGNUP_DATA';

const useAdditionaSignupData = (initial) => {
  const [additionalSignupData, setAdditionalSignupData] = useState(initial);

  useEffect(() => {
    const loadedData = getItem(additionalSignupDataKey);
    setAdditionalSignupData(JSON.parse(loadedData) || {});
  }, []);

  useEffect(() => {
    setItem(additionalSignupDataKey, JSON.stringify(additionalSignupData));
  }, [additionalSignupData]);

  return [additionalSignupData, setAdditionalSignupData];
};

export default useAdditionaSignupData;
