import React, { useRef, useState } from 'react';
import Draggable from 'react-draggable';
import useWindowSize from '@rooks/use-window-size';
import { AnimatePresence, motion } from 'framer-motion';

import { smallScreenWidth } from '../../constants/media-breakpoints';
import { useUserProfile } from '../../core/TTgraphql';
import UniversalMessagingBubble from '../../icons/UniversalMessagingBubble';
import { useUniversalMessagingContext } from '../../providers/UniversalMessagingProvider';
import { getConfig } from '../../services/configService';
import { CometChatUI } from '../CometChatWorkspace/src';
import useMessagingNotifications from '../navigation/useMessagingNotifications';

import styles from './UniversalMessaging.module.scss';

const CHAT_WIDTH = 384;
const CHAT_HEIGHT = 625;
const RIGHT_OFFSET = 90;
const BOTTOM_OFFSET = 92;
const ADDITIONAL_TOP_OFFSET = 108;
const ADDITIONAL_LEFT_OFFSET = 16;
const ADDITIONAL_RIGHT_OFFSET = 8;

const ANIMATION_SETTINGS = {
  initial: { opacity: 0, scale: 0.5, x: 100, y: 100 },
  animate: { opacity: 1, scale: 1, x: 0, y: 0 },
  exit: { opacity: 0, scale: 0.5, x: 100, y: 100 },
  transition: {
    duration: 0.4,
    ease: [0.22, 1, 0.36, 1],
    exit: { duration: 0.2 },
  },
};

const UniversalMessaging = () => {
  const { isOpen, position, toggleChat, updatePosition } =
    useUniversalMessagingContext();
  const { IS_OWNER } = getConfig();

  const { innerWidth, innerHeight } = useWindowSize();
  const isMobile = innerWidth <= smallScreenWidth;
  const [bounds, setBounds] = useState({});
  const dragRef = useRef(null);

  const { user } = useUserProfile({}, false);
  const { unreadMessagesCount } = useMessagingNotifications(user?.id);

  const updateBounds = () => {
    if (dragRef.current) {
      setBounds({
        left: CHAT_WIDTH + RIGHT_OFFSET - innerWidth + ADDITIONAL_LEFT_OFFSET,
        top: CHAT_HEIGHT + ADDITIONAL_TOP_OFFSET - innerHeight,
        right: RIGHT_OFFSET - ADDITIONAL_RIGHT_OFFSET,
        bottom: BOTTOM_OFFSET,
      });
    }
  };

  if (isMobile || !IS_OWNER) {
    return null;
  }

  return (
    <div>
      <div className={styles.chatContainer}>
        <div className={styles.chatBubble} onClick={toggleChat}>
          <UniversalMessagingBubble />
          {unreadMessagesCount > 0 && (
            <div className={styles.chatBubbleCount}>{unreadMessagesCount}</div>
          )}
        </div>
        <Draggable
          bounds={bounds}
          key={open}
          onStart={updateBounds}
          position={position}
          onStop={(_, data) => {
            updatePosition(data);
          }}
          cancel=".scrollable"
        >
          <div ref={dragRef}>
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  className={styles.chatWindow}
                  initial={ANIMATION_SETTINGS.initial}
                  animate={ANIMATION_SETTINGS.animate}
                  exit={ANIMATION_SETTINGS.exit}
                  transition={ANIMATION_SETTINGS.transition}
                >
                  <div className={styles.header} />
                  <div className={`${styles.chatBody} scrollable`}>
                    <CometChatUI isFromUniversalMessaging={true} />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </Draggable>
      </div>
    </div>
  );
};

export default UniversalMessaging;
