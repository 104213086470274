import React from 'react';

import styles from './ValidInformationDisclaimer.module.scss';

const ValidInformationDisclaimer = ({ text }) => (
  <>
    <div className={styles.description}>
      <p>
        {text != null ? (
          text
        ) : (
          <>
            <b>
              This information must match the information on the bank account
              you’ll use to collect rent.
            </b>{' '}
            You’ll add your bank account on the next step. <br />
          </>
        )}
      </p>
      <p>
        <a
          href="https://support.turbotenant.com/en/articles/6029545-what-is-kyc-and-why-does-it-matter"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          Why do you require this information?
        </a>
      </p>
    </div>
  </>
);

export default ValidInformationDisclaimer;
