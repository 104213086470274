import React, { memo } from 'react';
import PropTypes from 'prop-types';

const PremiumBadge = (props) => (
  <svg {...props} viewBox="0 0 34 48">
    <g fill="none">
      <path fill="#033A6D" d="M34 48 17 36.522 0 48V0h34z" />
      <path
        fill="#FF9250"
        d="m17 8.348 3 7.304h8l-6 5.218 2 8.347L17 24l-7 5.217 2-8.347-6-5.218h8z"
      />
    </g>
  </svg>
);

PremiumBadge.defaultProps = {
  width: 34,
  height: 48,
};

PremiumBadge.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(PremiumBadge);
