import { useCallback, useEffect, useState } from 'react';
import qs from 'query-string';

import { TTfetch } from '../core/TTfetch';
import { getConfig } from '../services/configService';

export const useInviteTokenStatus = () => {
  const { API_URL } = getConfig();
  const queryParams = qs.parse(location.search);
  const inviteToken = queryParams.user_invite_token;

  const [response, setResponse] = useState({
    loading: true,
  });

  const verifyUserInvite = useCallback(async (token) => {
    setResponse({ loading: true });

    // Use TTFetch to make the request
    try {
      const res = await TTfetch(
        `${API_URL}/public/verify_user_invite?inviteToken=${token}`,
      );

      const apiResponse = await res.json();

      setResponse({ loading: false, ...apiResponse, inviteToken: token });
    } catch (e) {
      setResponse({ loading: false });
    }
  }, []);

  useEffect(() => {
    if (!inviteToken) {
      setResponse({ loading: false });
      return;
    }

    verifyUserInvite(inviteToken);
  }, [inviteToken]);

  return response;
};

export default useInviteTokenStatus;
