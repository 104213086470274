import React from 'react';
import PropTypes from 'prop-types';

import getFieldError from '../../validators/get-field-error';
import PlainGroupComboBox from '../PlainGroupComboBox';

const GroupComboBox = ({
  input,
  dropdownClassName,
  inputClassName,
  label,
  className,
  meta,
  getOptions,
  onSelected,
  itemToString,
  groupBy,
  groupClassName,
  renderGroup,
  itemClassName,
  inputLabel,
  renderItem,
  renderEmpty,
  emptyListClassName,
  disabled,
  showLoading,
  placeholder,
  debounce,
  dataQa,
  resultFilter,
  showResultsOnFocus,
  labelOnTop,
}) => {
  const error = getFieldError(meta);
  return (
    <PlainGroupComboBox
      label={label}
      className={className}
      getOptions={getOptions}
      itemToString={itemToString}
      onSelected={(item) => {
        const newValue = onSelected(item);
        if (newValue) {
          input.onChange(newValue);
        }
      }}
      {...input}
      error={error}
      groupBy={groupBy}
      groupClassName={groupClassName}
      renderGroup={renderGroup}
      itemClassName={itemClassName}
      renderItem={renderItem}
      inputClassName={inputClassName}
      inputLabel={inputLabel}
      dropdownClassName={dropdownClassName}
      disabled={disabled}
      showLoading={showLoading}
      placeholder={placeholder}
      renderEmpty={renderEmpty}
      emptyListClassName={emptyListClassName}
      debounce={debounce}
      dataQa={dataQa}
      resultFilter={resultFilter}
      showResultsOnFocus={showResultsOnFocus}
      labelOnTop={labelOnTop}
    />
  );
};

GroupComboBox.propTypes = {
  input: PropTypes.object,
  dropdownClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  inputLabel: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.object,
  getOptions: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  itemToString: PropTypes.func,
  groupBy: PropTypes.string.isRequired,
  groupClassName: PropTypes.string,
  renderGroup: PropTypes.func.isRequired,
  itemClassName: PropTypes.string,
  disabled: PropTypes.bool,
  showLoading: PropTypes.bool,
  renderItem: PropTypes.func.isRequired,
  renderEmpty: PropTypes.func,
  emptyListClassName: PropTypes.string,
  placeholder: PropTypes.string,
  debounce: PropTypes.number,
};

export default GroupComboBox;
