import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ApplicationStatusCheck } from '../../../../../../components/ApplicationInfoForm/OtherPeople/OtherPeople';
import IconTooltip from '../../../../../../components/IconTooltip';
import InlineAlert from '../../../../../../components/InlineAlert';
import Tag from '../../../../../../components/Tag';
import { Link, Paragraph } from '../../../../../../components/Typography';
import getSmokingTitle from '../../../../../../helpers/getSmokingTitle';
import toCurrencyFromCents from '../../../../../../helpers/to-currency-from-cents';
import Calendar from '../../../../../../icons/CalendarColored';
import MonthlyIncomeIcon from '../../../../../../icons/MonthlyIncome';
import PeopleSilhouetteBlue from '../../../../../../icons/PeopleSilhouetteBlue';
import PetsIcon from '../../../../../../icons/PetsColored';
import ProfileLogo from '../../../../../../icons/Profile';
import Smoking from '../../../../../../icons/SmokingColored';
import { useConfig } from '../../../../../../providers/ConfigProvider';
import { getCurrentTotalIncome } from '../../../../../../services/utilities';

import styles from './ApplicationContent.module.scss';

const ApplicationContent = ({ application, partnersApplication }) => {
  const location = useLocation();
  const { IS_OWNER, PRIVATE_BASE_PATH } = useConfig();

  const coApplications =
    useMemo(() => {
      return application?.co_applications?.reduce(
        (coApplications, current) => ({
          ...coApplications,
          [current?.renter?.email]: current,
        }),
        {},
      );
    }, [application]) || {};

  const isSubmitted = application?.has_payment;

  const getDesiredMoveIn = ({ move_in_date }) => {
    if (!move_in_date) return '';
    const [y, m, d] = move_in_date.split('-');
    return `${m}/${d}/${y}`;
  };

  const renderCoApplicantName = (application, name) => {
    return (
      <div className={styles.container}>
        {isSubmitted && PRIVATE_BASE_PATH === '/owners/' ? (
          <Link
            className={styles.link}
            to={{
              pathname: `${PRIVATE_BASE_PATH}applicant-profile/${application?.renter?.id}/tab_summary`,
              search: `?rrId=${encodeURIComponent(application?.id)}`,
            }}
          >
            {name}
          </Link>
        ) : (
          name
        )}
      </div>
    );
  };

  const renderCoApplicant = ({ name, email = '', type }, idx) => {
    const coApplication = coApplications[email];
    const listingId = (atob(application?.listing?.id)?.split(':') || [])[1];

    return (
      <div key={idx} className={styles.coApplicantItem}>
        <ProfileLogo className={styles.profileLogo} />
        <div className={styles.infoHolder}>
          <div className={styles.nameHolder}>
            {renderCoApplicantName(coApplication, name)}
            {type === 'CO_SIGNER' && (
              <Tag small className={styles.coAppTag}>
                Co-Signer
              </Tag>
            )}
          </div>
          {email && (
            <>
              <div>{email}</div>
              <ApplicationStatusCheck
                className={styles.applicationStatus}
                application={coApplication}
                email={email}
                public_listing_id={listingId}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  let coApplicants = [];
  if ((application.co_applicants || []).length > 0) {
    coApplicants = application.co_applicants;
  } else if (application.co_applicant_description) {
    coApplicants = [{ name: application.co_applicant_description }];
  }
  const totalMonthlyIncome = getCurrentTotalIncome(application);

  const desiredMoveIn = getDesiredMoveIn(application);

  const pets = application.pets || [];

  let animalsData = { hasESA: false, text: 'Yes' };
  if (pets.length < 1 || !application.pet) {
    animalsData = { hasESA: false, text: 'No' };
  } else if (pets.some((pet) => pet.esa_animal)) {
    animalsData = { hasESA: true, text: 'ESA / Service Animal' };
  }

  return (
    <div>
      <div className={styles.content}>
        {application.applying_as_tenant && desiredMoveIn && (
          <div className={styles.summaryItem}>
            <Calendar className={styles.summaryItemIcon} />
            <div>
              <div className={styles.summaryItemTitle}>DESIRED MOVE-IN</div>
              <div className={styles.summaryItemValue}>{desiredMoveIn}</div>
            </div>
          </div>
        )}

        {application.applying_as_tenant &&
          application?.number_of_people_living && (
            <div className={styles.summaryItem}>
              <PeopleSilhouetteBlue className={styles.summaryItemIcon} />
              <div>
                <div className={styles.summaryItemTitle}>TOTAL OCCUPANTS</div>
                <div className={styles.summaryItemValue}>
                  {application?.number_of_people_living}
                </div>
              </div>
            </div>
          )}

        <div className={styles.summaryItem}>
          <MonthlyIncomeIcon className={styles.summaryItemIcon} />
          <div>
            <div className={styles.summaryItemTitle}>Monthly Income</div>
            <div className={styles.summaryItemValue}>
              {toCurrencyFromCents(totalMonthlyIncome, false)}
            </div>
          </div>
          {IS_OWNER && (
            <IconTooltip
              className={styles.tooltip}
              tip={
                <span>
                  This is self-reported information. Verify their income by
                  checking pay stubs, contacting any employers, or using Income
                  Insights.
                </span>
              }
            />
          )}
        </div>
        {(application?.applying_as_tenant || application?.pets?.length > 0) && (
          <div className={styles.summaryItem}>
            <PetsIcon className={styles.summaryItemIcon} />
            <div>
              <div className={styles.summaryItemTitle}>ANIMALS</div>
              <div className={styles.summaryItemValue}>{animalsData.text}</div>
            </div>
            {IS_OWNER && animalsData.hasESA && (
              <IconTooltip
                className={styles.tooltip}
                tip={
                  <span>
                    This applicant has an emotional support animal (ESA) or
                    service animal.
                  </span>
                }
              />
            )}
          </div>
        )}

        {application.smoking != null && (
          <div className={styles.summaryItem}>
            <Smoking className={styles.summaryItemIcon} />
            <div>
              <div className={styles.summaryItemTitle}>Smoking</div>
              <div className={styles.summaryItemValue}>
                {getSmokingTitle(application.smoking)}
              </div>
            </div>
          </div>
        )}
      </div>
      {((IS_OWNER && location.pathname.includes('tab_application')) ||
        partnersApplication) &&
        animalsData.hasESA && (
          <InlineAlert
            type="warning"
            containerClassName={styles.inlineAlert}
            title="By law, you must accommodate applicants with emotional support animals and service animals, regardless of your pet policy."
          >
            <Paragraph className="mt-0">
              <Link
                href="https://www.turbotenant.com/blog/emotional-support-animal-laws/"
                target="_blank"
              >
                Learn more
              </Link>{' '}
              about your responsibility under the Fair Housing Act and how to
              validate ESAs / service animals.
            </Paragraph>
          </InlineAlert>
        )}
      {application.co_applicant && coApplicants && coApplicants.length > 0 && (
        <div>
          <p className={styles.applyingWith}>
            {application.applying_as_tenant
              ? 'Applying with:'
              : 'Co-Signing For:'}
          </p>
          <div className={styles.coAppList}>
            {coApplicants.map(renderCoApplicant)}
          </div>
        </div>
      )}
    </div>
  );
};

ApplicationContent.propTypes = {
  application: PropTypes.object,
  partnersApplication: PropTypes.bool,
};

export default ApplicationContent;
