import { exemptionsList } from '../constants/enums/TerminationFeeExemptionsEnum';

const getExeptionLabel = (exemption) => {
  const exemptionObj = exemptionsList.find(
    (exemptionObj) => exemptionObj.value === exemption,
  );
  return exemptionObj ? exemptionObj.leaseText : null;
};

export default getExeptionLabel;
