import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const QuotationMarkOrange = (props) => (
  <svg viewBox="0 0 21 12" {...props}>
    <path
      fill="#FF9250"
      d="m8.374 12 2.041-12H4.889L0 12h8.374Zm10.586 0L21 0h-5.526l-4.889 12h8.375Z"
    />
  </svg>
);

QuotationMarkOrange.defaultProps = {
  width: 21,
  height: 12,
  color: colors.text,
};

QuotationMarkOrange.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(QuotationMarkOrange);
