import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';

import styles from './AdditionsToLeaseAgreementAZ.module.scss';

const AdditionsToLeaseAgreementAZ = ({ className }) => {
  return (
    <HelpfulInfoCard
      noIcon
      noTitle
      className={cx(styles.helpfulInfoCard, className)}
      color="iceCold"
    >
      Arizona requires that the{' '}
      <a
        className={styles.link}
        href="https://housing.az.gov/sites/default/files/documents/files/Landlord_Tenant_Act_May-2023_1.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Arizona Landlord-Tenant Handbook
      </a>{' '}
      must be presented to the tenant(s). We've already attached it for you.
    </HelpfulInfoCard>
  );
};

AdditionsToLeaseAgreementAZ.propTypes = {
  className: PropTypes.string,
};

export default AdditionsToLeaseAgreementAZ;
