import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../Button';

import styles from './VideoAndDescriptionHolder.module.scss';

const VideoAndDescriptionHolder = ({ className, openAcademy }) => {
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.description}>
        <h3>
          <span>Invest in Student Housing</span>
        </h3>
        <p className={styles.desc}>
          Last fall, there were over 18 million students enrolled in
          degree-granting colleges and universities in America, and they all
          need a place to live. Led by expert landlords from CU and CSU, this
          course gives you the blueprint to create and grow your own lucrative
          student housing business.
        </p>
        <Button variant="prominent" onClick={openAcademy} fullWidth>
          Learn More
        </Button>
      </div>
      <div className={styles.videoHolder}>
        <iframe
          className={styles.video}
          src="https://www.youtube-nocookie.com/embed/MGRJ_JrNJ1c?rel=0"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

VideoAndDescriptionHolder.propTypes = {
  className: PropTypes.string,
  openAcademy: PropTypes.func,
};

export default VideoAndDescriptionHolder;
