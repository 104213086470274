import React, { useEffect, useRef } from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useWindowWidth } from '@react-hook/window-size';
import cx from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import FlatButton from '../../../../../components/FlatButton';
import Input from '../../../../../components/Input';
import { Tooltip } from '../../../../../components/Tooltip';
import breakpoints from '../../../../../constants/media-breakpoints';
import MinusCircle from '../../../../../icons/MinusCircle';
import PlusCircle from '../../../../../icons/PlusCircle';
import composeValidators from '../../../../../validators/composeValidators';
import fieldRequiredShortStandard from '../../../../../validators/fieldRequiredShort';
import isGreaterOrEqualThan from '../../../../../validators/isGreaterOrEqualThan';
import isInt from '../../../../../validators/isInt';
import isLowerThan from '../../../../../validators/isLowerThan';

import styles from './PetsForm.module.scss';

const MOBILE_BREAKPOINT = breakpoints.mobileBreakpoint;

const ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove',
  EDIT: 'edit',
};

const petsReducer = (state, action) => {
  const { type, id, data } = action;
  const newState = [...state];

  switch (type) {
    case ACTIONS.ADD:
      return [
        ...state,
        {
          type: '',
          breed: '',
          weight: '',
          age: '',
        },
      ];
    case ACTIONS.REMOVE: {
      newState.splice(id, 1);
      return newState;
    }
    case ACTIONS.EDIT: {
      newState[id] = {
        ...newState[id],
        ...data,
      };
      return newState;
    }
    default:
      return state;
  }
};

const noop = () => {};

const PetsForm = ({ input, limit, standAloneWizard = false }) => {
  const screenWidth = useWindowWidth();
  const lastInput = useRef(null);

  const pets = get(input, 'value') || [];

  const fieldRequiredShort = standAloneWizard
    ? noop
    : fieldRequiredShortStandard;

  useEffect(() => {
    if (lastInput.current && !lastInput.current.value) {
      lastInput.current.focus();
    }
  }, [lastInput.current]);

  const modPets = (action) => {
    input.onChange(petsReducer(pets, action));
  };

  const renderPets = () =>
    pets.map((pet, key) => (
      <div key={`pet_${key}`} className={styles.feesBlock}>
        <div className={styles.fields}>
          <OnChange name={`${input.name}.${key}.type`}>
            {(value) => {
              modPets({
                type: ACTIONS.EDIT,
                id: key,
                data: {
                  type: value,
                },
              });
            }}
          </OnChange>
          <Field
            label="TYPE (DOG, CAT, ETC.)"
            component={Input}
            id={`${input.name}.${key}.type`}
            name={`${input.name}.${key}.type`}
            validate={fieldRequiredShort}
            className={styles.inputType}
            inputProps={{
              'data-qa': `lease-agreements-pet-type-${key}`,
              'className': styles.input,
              'ref':
                key === pets.length - 1
                  ? (ref) => (lastInput.current = ref)
                  : undefined,
            }}
          />
          <OnChange name={`${input.name}.${key}.breed`}>
            {(value) => {
              modPets({
                type: ACTIONS.EDIT,
                id: key,
                data: {
                  breed: value,
                },
              });
            }}
          </OnChange>
          <Field
            label="BREED"
            component={Input}
            id={`${input.name}.${key}.breed`}
            name={`${input.name}.${key}.breed`}
            className={styles.inputBreed}
            inputProps={{
              'data-qa': `lease-agreements-pet-breed-${key}`,
              'className': styles.input,
            }}
          />
        </div>
        <div className={styles.fields}>
          <OnChange name={`${input.name}.${key}.weight`}>
            {(value) => {
              modPets({
                type: ACTIONS.EDIT,
                id: key,
                data: {
                  weight: value,
                },
              });
            }}
          </OnChange>
          <Field
            label="WEIGHT (LBS)"
            component={Input}
            id={`${input.name}.${key}.weight`}
            name={`${input.name}.${key}.weight`}
            validate={composeValidators(
              isInt,
              isLowerThan(1000),
              isGreaterOrEqualThan(0),
            )}
            className={styles.inputWeight}
            inputProps={{
              'type': 'number',
              'data-qa': `lease-agreements-pet-weight-${key}`,
              'className': styles.input,
            }}
          />
          <OnChange name={`${input.name}.${key}.age`}>
            {(value) => {
              modPets({
                type: ACTIONS.EDIT,
                id: key,
                data: {
                  age: value,
                },
              });
            }}
          </OnChange>
          <Field
            label="AGE"
            component={Input}
            id={`${input.name}.${key}.age`}
            name={`${input.name}.${key}.age`}
            validate={composeValidators(
              isInt,
              isLowerThan(200),
              isGreaterOrEqualThan(0),
            )}
            className={styles.inputAge}
            inputProps={{
              'type': 'number',
              'data-qa': `lease-agreements-pet-age-${key}`,
              'className': styles.input,
            }}
          />
        </div>
        {pets.length > 1 && (
          <FlatButton
            type="button"
            onClick={() =>
              modPets({
                type: ACTIONS.REMOVE,
                id: key,
              })
            }
            icon={MinusCircle}
            disabled={pets.length === 1}
            className={styles.removeButton}
          >
            Remove this pet
          </FlatButton>
        )}
      </div>
    ));

  return (
    <div className={styles.container}>
      {renderPets()}
      <FlatButton
        data-tooltip-id="add_additional_pets_tip"
        data-tooltip-hidden={pets.length !== limit}
        type="button"
        onClick={() =>
          modPets({
            type: ACTIONS.ADD,
          })
        }
        icon={PlusCircle}
        className={cx(styles.addButton, {
          [styles.disabled]: pets.length === limit,
        })}
        id="add_another_pet"
      >
        Add another pet
      </FlatButton>
      <Tooltip
        id="add_additional_pets_tip"
        place={screenWidth < MOBILE_BREAKPOINT ? 'bottom' : 'right'}
        offset={screenWidth < MOBILE_BREAKPOINT ? 24 : 0}
      >
        <span>You've reached the maximum number of pets allowed.</span>
      </Tooltip>
    </div>
  );
};

PetsForm.propTypes = {
  input: PropTypes.object,
  limit: PropTypes.number,
  standAloneWizard: PropTypes.bool,
};

export default PetsForm;
