import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import RadioGroup from '../../../../../../../components/RadioGroup';
import EnvironmentIcon from '../../../../../../../icons/Environment';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';

import styles from './SuperfundDisclosureIA.module.scss';

const SuperfundDisclosureIA = ({ className, standaloneWizard }) => {
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standaloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle
        title="Environmental Liability Disclosure"
        icon={EnvironmentIcon}
      />
      <label className={styles.label}>
        Is the property located in a CERCLA (Superfund) directory?
      </label>
      <p className={styles.description}>
        The Comprehensive Environmental Response, Compensation, and Liability
        Act (CERCLA), also known as “Superfund” aims to reduce pollutants and
        contaminants in the environment. Some properties in Iowa are located and
        listed in the CERCLA directory and must be disclosed in the rental
        agreement.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.has_superfund"
        name="custom_state_data.has_superfund"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'custom_state_data.has_superfund_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'custom_state_data.has_superfund_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition when="custom_state_data.has_superfund" is={true}>
        <p className={styles.text}>
          According to the Environmental Protection Agency, this property is
          included in the “Superfund” system and may have been exposed to toxic
          contaminants and pollutants in the past.
        </p>
      </Condition>
    </div>
  );
};

SuperfundDisclosureIA.propTypes = {
  className: PropTypes.string,
  standaloneWizard: PropTypes.bool,
};

export default SuperfundDisclosureIA;
