import React from 'react';
import PropTypes from 'prop-types';

import SmokingThickIcon from '../../../../icons/SmokingThick';
import SectionTitle from '../../common/SectionTitle';

import styles from './SectionPetsSmokingOther.module.scss';

export const SectionPetsSmokingOtherSmokingSection = ({ children }) => (
  <div className={styles.section}>
    <SectionTitle
      title="Smoking"
      icon={SmokingThickIcon}
      iconProps={{ style: { marginBottom: 6 } }}
    />
    <label className={styles.headerLabel}>
      Do you allow smoking at this property?
    </label>
    {children}
  </div>
);

SectionPetsSmokingOtherSmokingSection.propTypes = {
  children: PropTypes.node,
};
