import React, { memo } from 'react';

import colors from '../constants/colors';

const Dollar = (props) => (
  <svg viewBox="0 0 8 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4 0c.368 0 .667.256.667.571v1.19C6.558 2.035 8 3.445 8 5.144c0 .315-.298.571-.667.571-.368 0-.666-.256-.666-.571 0-1.065-.85-1.96-2-2.214v4.547C6.558 7.748 8 9.159 8 10.857c0 1.7-1.441 3.11-3.333 3.381v1.19c0 .316-.299.572-.667.572-.368 0-.667-.256-.667-.571v-1.19C1.441 13.965 0 12.555 0 10.856c0-.315.298-.571.667-.571.368 0 .666.256.666.571 0 1.065.85 1.96 2 2.214V8.524C1.441 8.252 0 6.842 0 5.143c0-1.699 1.442-3.11 3.333-3.381V.572C3.333.255 3.632 0 4 0zm.667 8.644v4.427c1.15-.254 2-1.149 2-2.214s-.85-1.96-2-2.213zM3.333 2.929c-1.15.254-2 1.15-2 2.214 0 1.065.85 1.96 2 2.214z"
      fillRule="evenodd"
    />
  </svg>
);

Dollar.defaultProps = {
  fill: colors.text,
  width: 8,
  height: 16,
};

export default memo(Dollar);
