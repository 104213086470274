import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';

import Input from '../../../../../components/Input';
import KeysIcon from '../../../../../icons/Keys';
import composeValidators from '../../../../../validators/composeValidators';
import conditionalValidation from '../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../validators/fieldRequired';
import isLengthBetweenWithLimits from '../../../../../validators/isLengthBetweenWithLimits';
import SectionTitle from '../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../standalone-wizard/StandaloneWizardProvider';

import styles from './RoomSpecificsFields.module.scss';

const RoomSpecificsFields = () => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={styles.container}>
      <SectionTitle
        title="Room Specifics"
        icon={KeysIcon}
        iconProps={{ className: styles.sectionIcon }}
      />
      <label className={styles.label} htmlFor="room_specifics">
        Room Location Description
      </label>
      <p className={styles.description}>
        Rooms don't have addresses. As an alternative please describe the
        location of the room rental in as much detail as possible so it is
        indisputably clear which room in the rental this lease agreement is for.
        e.g. "The 3rd floor North West Bedroom with the 2 windows and attached
        bathroom”
      </p>
      <Field
        as="textarea"
        id={'room_specifics'}
        name={'room_specifics'}
        component={Input}
        type="text"
        className={styles.textArea}
        validate={composeValidators(
          fieldRequired,
          isLengthBetweenWithLimits(0, 2000),
        )}
        help={(val = '') => (
          <span
            className={cx({
              [styles.helpTextRed]: val.length > 2000,
            })}
          >
            {`${val.length} / 2000 characters used | 2000 maximum`}
          </span>
        )}
        inputProps={{ 'data-qa': 'lease-agreements-parking-rules' }}
      />
    </div>
  );
};

export default RoomSpecificsFields;
