import React from 'react';
import { Field } from 'react-final-form';

import composeValidators from '../../validators/composeValidators';

import styles from './CoreField.module.scss';

// TODO: move this function to the validators folder
const isRequired = (isRequired) =>
  isRequired
    ? (value) =>
        value == null || value.length == 0
          ? 'This value is required'
          : undefined
    : () => {};

const NOT_OPTIONAL_LABEL_TYPES = ['checkbox', 'radio', 'hidden'];

/**
 *
 * @NOTICE
 *
 * This component is not intended to be used outside of this folder,
 * it is intended to help build abtractations of <Fields /> so that
 * every abstraction has the same basic logic.
 *
 * In other words, if you are not creating a field component you
 * should NOT be using this component. You should be using one of the
 * other components, such as:
 *
 * <InputField />
 * <SelectField />
 * <TextAreaField />
 * <MoneyField />
 *
 * Among others...
 *
 */
export const CoreField = ({
  id,
  name,
  key,
  label,
  type,
  required = true, // by default fields are required
  validate = [], // validate can be a function or an array of functions
  withOptionalLabel = true,
  parse = (value) => value,
  ...props
}) => {
  return (
    <Field
      id={id || name}
      name={name}
      label={
        label != null &&
        required === false &&
        withOptionalLabel &&
        !NOT_OPTIONAL_LABEL_TYPES.includes(type) ? (
          <>
            {label} <span className={styles.optionalLabel}>(Optional)</span>
          </>
        ) : (
          label
        )
      }
      parse={parse}
      type={type}
      validate={composeValidators(
        isRequired(required !== false),
        ...(Array.isArray(validate) ? [...validate] : [validate]),
      )}
      key={key != null ? `${key}_${required}` : required}
      {...props}
    />
  );
};

export default React.memo(CoreField);
