import React from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import LoadingScreen from '../../../../components/LoadingScreen';
import { useErrorToast } from '../../../../components/Toast';
import {
  getDecodedCurrentToken,
  login,
  silentLogin,
  silentLogout,
} from '../../../../core/auth/authService';
import acceptInviteGQL from '../../../../graphql/mutations/users/acceptInvite.graphql';
import { useLoginRecaptcha } from '../../../../helpers/login-recaptcha';
import useInviteTokenStatus from '../../../../hooks/useInviteTokenStatus';
import { useConfig } from '../../../../providers/ConfigProvider';
import handleLoginRequest from '../../../../services/handleLoginRequest';
import { segmentTracking } from '../../../../services/utilities';

import AcceptInviteExistingUser from './AcceptInviteExistingUser';
import AcceptInviteNewUser from './AcceptInviteNewUser';
import InvalidInvitePage from './InvalidInvitePage';

const AcceptInviteWrapped = ({ history }) => {
  const { PRIVATE_BASE_PATH, OWNERS_URL } = useConfig();
  const { getLoginCaptchaToken } = useLoginRecaptcha();
  const showErrorToast = useErrorToast();

  const [acceptInviteMutation, { loading: loadingAcceptInviteMutation }] =
    useMutation(acceptInviteGQL);

  const { loading, ...response } = useInviteTokenStatus();

  if (loading) {
    return <LoadingScreen loading />;
  }

  if (!response.success) {
    return <InvalidInvitePage />;
  }

  const {
    has_account,
    user: { email, first_name: userName },
    owner: { company, first_name, last_name },
    owner_id,
    inviteToken,
  } = response;

  const preemptivelyLogoutCurrentUser = () => {
    const currentUser = getDecodedCurrentToken();
    try {
      if (
        currentUser.role !== 'USER' ||
        atob(response?.user_id).split(',')[1] !== currentUser.id
      ) {
        // If there's a logged in user that doesn't match this invite token, we log them out.
        silentLogout();
      }
    } catch (e) {
      // No need to handle this
    }
  };

  // Existing user flow
  const handleAcceptInvite = async (data) => {
    segmentTracking('accept_invite clicked', {
      location: 'Multi User Access Accept Invite',
    });

    // We show error toast for social sign-on only if the email is different
    if (data?.email && data.email !== email) {
      showErrorToast(
        'Oops! In order to login using social sign-on, use the same email that was originally invited to the account.',
      );
      return;
    }

    const body = { ...data, user: email };
    const captchaToken = await getLoginCaptchaToken();
    const res = await handleLoginRequest({
      captchaToken,
      history,
      data: body,
      redirectOnSuccess: async (response) => {
        preemptivelyLogoutCurrentUser();
        silentLogin(response.token);
        await acceptInviteMutation({
          variables: {
            invite_token: inviteToken,
          },
        });

        history.replace(
          `${PRIVATE_BASE_PATH}dashboard?&isFromLogIn=true&ttAccountId=${owner_id}`,
        );
      },
    });

    return res;
  };

  // New user flow
  const handleSetPassword = async ({ password }) => {
    segmentTracking('set_password clicked', {
      location: 'Multi User Access Accept Invite',
    });
    preemptivelyLogoutCurrentUser();
    const {
      data: {
        acceptInvite: { token },
      },
    } = await acceptInviteMutation({
      variables: {
        invite_token: inviteToken,
        password,
      },
    });

    login(token);
    history.replace(
      `${PRIVATE_BASE_PATH}dashboard?&isFromLogIn=true&ttAccountId=${owner_id}`,
    );
  };

  return (
    <LoadingScreen loading={loadingAcceptInviteMutation}>
      {has_account ? (
        <AcceptInviteExistingUser
          handleSubmit={handleAcceptInvite}
          ownersUrl={OWNERS_URL}
          email={email}
          userName={userName}
          company={company}
          accountName={`${first_name} ${last_name}`}
          history={history}
          forgotToBackUrl={encodeURIComponent(
            location.pathname + location.search,
          )}
        />
      ) : (
        <AcceptInviteNewUser
          handleSubmit={handleSetPassword}
          userName={userName}
          email={email}
          company={company}
          accountName={`${first_name} ${last_name}`}
        />
      )}
    </LoadingScreen>
  );
};

AcceptInviteWrapped.propTypes = {
  history: PropTypes.object,
};

export default AcceptInviteWrapped;
