import React, { memo } from 'react';
import PropTypes from 'prop-types';

const AlarmFilled = ({ color, exclamationColor, ...props }) => (
  <svg viewBox="0 0 24 24" {...props} fill={color}>
    <g fillRule="evenodd">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Z" />
      <g fill={exclamationColor}>
        <path d="M11.875 6c.443 0 .81.258.867.592l.008.093v7.63c0 .378-.392.685-.875.685-.443 0-.81-.258-.867-.592L11 14.315v-7.63c0-.378.392-.685.875-.685ZM11.875 17.75a.875.875 0 1 1 0-1.75.875.875 0 0 1 0 1.75Z" />
      </g>
    </g>
  </svg>
);

AlarmFilled.defaultProps = {
  width: 24,
  height: 24,
  color: '#ff3b3b',
  exclamationColor: '#fff',
};

AlarmFilled.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(AlarmFilled);
