import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Location = ({ color, ...props }) => (
  <svg viewBox="0 0 20 20" {...props} fill={color}>
    <path
      d="m14.05 15.673.083.002.543.049.52.053.496.058.239.03.46.066.433.07.208.037.395.08.37.084c1.423.35 2.203.845 2.203 1.553 0 .698-.918 1.232-2.476 1.604l-.371.083-.393.077-.414.071-.215.034-.444.062-.464.057-.482.052-.5.045-.257.02-.525.038-.541.031-.556.026-.571.02-.584.014-.598.008L10 20l-.61-.003-.596-.008-.585-.015-.57-.02-.557-.025-.541-.031-.525-.037-.509-.043a33.711 33.711 0 0 1-.248-.023l-.482-.052-.464-.057-.444-.062a21.47 21.47 0 0 1-.215-.034l-.414-.071-.393-.077-.37-.083C.917 18.987 0 18.453 0 17.755c0-.815 1.031-1.347 2.887-1.701l.426-.076c.146-.024.297-.048.452-.07l.479-.065c.164-.021.332-.041.504-.06l.53-.055.554-.05a.612.612 0 0 1 .104 1.22c-1.522.13-2.807.33-3.697.572a5.3 5.3 0 0 0-.736.25l-.075.035.05.024.105.045c.424.175 1.068.34 1.884.482 1.711.298 4.042.47 6.533.47 2.491 0 4.822-.172 6.533-.47.816-.141 1.46-.307 1.884-.482l.105-.045.049-.024-.077-.036a4.782 4.782 0 0 0-.567-.202l-.175-.05c-.384-.104-.841-.2-1.359-.286l-.537-.083c-.371-.053-.767-.101-1.184-.144l-.642-.06a.612.612 0 0 1 .02-1.22ZM10 0a6.21 6.21 0 0 1 6.209 6.21c0 .962-.441 2.335-1.24 4.066l-.265.558-.141.288-.3.594a55.93 55.93 0 0 1-.93 1.734 80.888 80.888 0 0 1-1.938 3.276l-.49.778-.394.608a.612.612 0 0 1-1.021 0l-.394-.608-.49-.778a80.886 80.886 0 0 1-1.938-3.276 55.927 55.927 0 0 1-.932-1.734c-1.241-2.42-1.945-4.29-1.945-5.507C3.791 2.78 6.571 0 10.001 0Zm0 1.225a4.984 4.984 0 0 0-4.984 4.984c0 .635.292 1.624.829 2.879l.212.482c.075.165.153.334.235.507l.256.529.278.55c.28.546.584 1.113.91 1.697a79.683 79.683 0 0 0 1.91 3.226l.354.564.355-.564a79.685 79.685 0 0 0 1.909-3.226 54.716 54.716 0 0 0 1.052-1.974l.267-.54.246-.518a29.3 29.3 0 0 0 .114-.25l.213-.483.097-.232.178-.445c.36-.936.553-1.686.553-2.202A4.985 4.985 0 0 0 10 1.225Zm0 1.224a3.47 3.47 0 1 1 0 6.939 3.47 3.47 0 0 1 0-6.939Zm0 1.225a2.244 2.244 0 1 0-.001 4.488A2.244 2.244 0 0 0 10 3.674Z"
      fillRule="evenodd"
    />
  </svg>
);

Location.defaultProps = {
  width: 20,
  height: 20,
  color: 'currentColor',
};

Location.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Location);
