import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Caution = (props) => (
  <svg {...props} viewBox="0 0 76 77">
    <g fill="none">
      <path d="M-10-10h96v96h-96z" />
      <g fill={props.color}>
        <path d="M38.16 25.544c.78.122 1.367.735 1.458 1.528.324 8.539.004 17.09.004 25.634-.066 1.759-3.083 2.02-3.299.128-.324-8.539-.005-17.09-.005-25.634.037-.978.567-1.755 1.842-1.656Z" />
        <path d="M38.291.054c.47.127.758.2 1.157.89l36.348 73.333c.467 1.113-.204 2.33-1.478 2.412H1.622c-1.237-.08-1.944-1.298-1.477-2.412L36.493.944c.538-.929.688-1.037 1.798-.89ZM4.296 73.356h67.349L37.97 5.416 4.296 73.356Z" />
        <path d="M38.14 60.026c1.649.127 3.049 1.519 3.131 3.16.113 2.255-2.45 4.153-4.587 3.24-2.716-1.159-2.76-6.508 1.455-6.4Z" />
      </g>
    </g>
  </svg>
);

Caution.defaultProps = {
  width: 76,
  height: 77,
  color: colors.text,
};

Caution.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Caution);
