import React, { memo } from 'react';
import PropTypes from 'prop-types';

const CreditCardVisa = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 23" {...props}>
    <g>
      <g transform="translate(-945 -256)translate(912 127)translate(0 50)translate(32 74)translate(1.333333 5.333333)">
        <rect width="29.3" height="22" rx="4.4" fill="#1A1876" />
        <polygon points="11.6 14.1 13.3 14.1 14.3 7.9 12.7 7.9" fill="#FFF" />
        <path
          d="M17.7 10.4C17.1 10.1 16.8 9.9 16.8 9.6 16.8 9.4 17.1 9.1 17.7 9.1 18.2 9.1 18.7 9.2 19.1 9.4L19.3 8.1C18.9 7.9 18.4 7.8 17.8 7.8 16.2 7.8 15 8.6 15 9.8 15 10.7 15.9 11.2 16.5 11.5 17.1 11.8 17.4 12 17.4 12.3 17.3 12.7 16.8 12.9 16.4 12.9 15.7 12.9 15.1 12.8 14.6 12.5L14.3 13.9C14.9 14.1 15.6 14.2 16.2 14.2 17.9 14.2 19.1 13.4 19.1 12.1 19.1 11.4 18.7 10.8 17.7 10.4Z"
          fill="#FFF"
        />
        <path
          d="M23.6 7.9L22.3 7.9C21.9 7.9 21.6 8.1 21.4 8.4L19 14.1 20.7 14.1C20.7 14.1 21 13.3 21.1 13.2L23.2 13.2C23.2 13.4 23.4 14.1 23.4 14.1L24.9 14.1 23.6 7.9ZM21.5 11.9C21.6 11.7 22.3 10 22.4 9.6 22.7 10.7 22.4 9.6 22.9 11.9L21.5 11.9Z"
          fill="#FFF"
        />
        <path
          d="M10.2 7.9L8.6 12.1 8.4 11.3 7.8 8.4C7.8 8.1 7.4 7.9 7.1 7.9L4.4 7.9 4.4 8C5 8.2 5.6 8.4 6.1 8.7L7.6 14.1 9.4 14.1 12 7.9 10.2 7.9Z"
          fill="#FFF"
        />
      </g>
    </g>
  </svg>
);

CreditCardVisa.defaultProps = {
  width: 31,
  height: 23,
};

CreditCardVisa.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(CreditCardVisa);
