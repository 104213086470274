import React from 'react';
import PropTypes from 'prop-types';

import { linkTo, routes } from '../../../../../helpers/routes';
import Exclamation from '../../../../../icons/streamline/filled/Exclamation';
import { segmentTracking } from '../../../../../services/utilities/segment';
import { Link, Span } from '../../../../Typography';

import styles from '../../GlobalBanner.module.scss';

const BankAccountErrored = ({ account }) => {
  return (
    <div className={styles['gb-style-danger']}>
      <Exclamation color="white" />
      <div className={styles.content}>
        <Span weight="bold">ACTION REQUIRED: </Span>
        We were unable to deposit funds into your bank account ending in{' '}
        {account?.endingIn || 'unknown'}.
        <Link
          to={linkTo(routes.bankAccount, {
            accountId: account?.id,
          })}
          onClick={() => {
            segmentTracking('update_bank clicked', {
              location: 'Errored Bank Banner',
            });
          }}
        >
          Update Bank
        </Link>
      </div>
    </div>
  );
};

BankAccountErrored.propTypes = {
  account: PropTypes.shape({
    endingIn: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default BankAccountErrored;
