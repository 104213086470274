import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import { parse } from 'query-string';

import Button from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import Divider from '../../../../../../components/Divider';
import FlatButton from '../../../../../../components/FlatButton';
import LoadingScreen from '../../../../../../components/LoadingScreen';
import PremiumSubscriptionModal from '../../../../../../components/PremiumSubscription';
import { MODAL_LOCATIONS } from '../../../../../../components/PremiumSubscription/constants';
import basicUserProfileGQL from '../../../../../../graphql/basicUserProfile.graphql';
import { useRenderOutside } from '../../../../../../helpers/render-outside';
import BusinessBankIcon from '../../../../../../icons/BusinessBank';
import Edit from '../../../../../../icons/Edit';
import ESignIcon from '../../../../../../icons/ESign';
import PremiumBanner from '../../../../../../icons/PremiumBanner';
import Remove from '../../../../../../icons/Remove';
import TelevisionIcon from '../../../../../../icons/Television';
import { getLinkToken } from '../../../../../../services/plaidService';
import { segmentTracking } from '../../../../../../services/utilities';
import RemoveBankAccountModal from '../../../../components/RemoveBankAccountModal';
import AssignPaymentRequestRulesToBank from '../AssignPaymentRequestRulesToBank';
import OpenPlaidFlow from '../OpenPlaidFlow';

import EditNicknameForm from './EditNicknameForm';

import styles from './BankStepOptions.module.scss';

const BankStepOptions = ({
  bankAccounts,
  onAddBankManually,
  onPlaidSuccess,
  onNext,
  user,
  onRemoveBankAccount,
  paymentRequestRules,
  isDeleteLoading,
  onUpdateBankAccountNickname,
  location,
}) => {
  const [config, setConfig] = useState(null);
  const [showAddMore, setShowAddMore] = useState(null);
  const [bankIdToDelete, setBankIdToDelete] = useState(null);
  const [editNickName, setEditNickName] = useState(false);

  const renderOutside = useRenderOutside();
  const { offlineConversion } = parse(location.search, { parseBooleans: true });

  const isPremiumUser = user.premium_subscription_subscribed;

  const getInitialValues = () => {
    let rules = paymentRequestRules;

    if (bankAccounts.length === 1) {
      rules = paymentRequestRules.map((pr) => ({
        ...pr,
        destination_id: bankAccounts[0].id,
      }));
    }

    return { paymentRequestRules: rules };
  };

  useEffect(() => {
    const setupPlaidConfig = async () => {
      const token = await getLinkToken();
      setConfig({
        // Required, fetch a link token from your server and pass it
        // back to your app to initialize Link.
        token,
        onSuccess: onPlaidSuccess,
      });
    };
    setupPlaidConfig();
  }, []);

  const handleAddMore = () => {
    if (isPremiumUser) {
      return setShowAddMore(true);
    }

    segmentTracking('add_another_bank clicked', {
      location: offlineConversion
        ? 'Offline to Online Bank Form'
        : 'rp_setup rent payments subscription modal',
    });

    return renderOutside((done) => (
      <PremiumSubscriptionModal
        openLocation={
          location.pathname.includes('/payments/onboarding')
            ? MODAL_LOCATIONS.PAYMENTS_MBA_ONBOARDING
            : MODAL_LOCATIONS.PAYMENTS_MBA
        }
        onClose={done}
        singlePaymentAction={done}
        onSuccess={() => {
          done();
          setShowAddMore(true);
        }}
        refetchQueries={[basicUserProfileGQL]}
        benefitsType="payments"
        copy="multipleBankAccounts"
      />
    ));
  };

  const onSubmit = useCallback(
    (...args) => {
      if (offlineConversion) {
        segmentTracking('finish_setup clicked', {
          location: 'Offline to Online Bank Form',
        });
      }
      onNext(...args);
    },
    [offlineConversion, onNext],
  );

  if (!config) {
    return <LoadingScreen loading={!!config} />;
  }

  return (
    <Card className={styles.card}>
      <div className={styles.title}>Review Bank Account</div>
      {(bankAccounts || []).map((ba) => {
        return (
          <div className={styles.existingAccount} key={ba.id}>
            <div className={styles.content}>
              {editNickName === ba.id ? (
                <EditNicknameForm
                  id={ba.id}
                  nickname={ba.nickname || ba.bank_name}
                  onSubmit={async (formdata) => {
                    await onUpdateBankAccountNickname(ba.id, formdata.nickname);
                    setEditNickName(false);
                  }}
                  onCancel={() => {
                    setEditNickName(false);
                  }}
                />
              ) : (
                <>
                  <div className={styles.bankNameContainer}>
                    <div className={styles.bankName}>
                      {ba.nickname || ba.bank_name}
                    </div>
                    {isPremiumUser && (
                      <FlatButton
                        className={styles.editButtonDesktop}
                        onClick={() => setEditNickName(ba.id)}
                      >
                        - EDIT NICKNAME
                      </FlatButton>
                    )}
                  </div>

                  <div className={styles.bankData}>
                    <BusinessBankIcon
                      className={styles.icon}
                      width={17}
                      height={18}
                    />
                    <span>Checking account ending in {ba.last4}</span>
                  </div>
                </>
              )}
            </div>
            {!editNickName && (
              <>
                {isPremiumUser ? (
                  <div className={styles.mobileActions}>
                    <FlatButton
                      icon={Edit}
                      className={styles.editButtonMobile}
                      onClick={() => setEditNickName(ba.id)}
                    >
                      <span className={styles.removeBtnLabel}>
                        EDIT NICKNAME
                      </span>
                    </FlatButton>
                    {bankAccounts.length > 1 && (
                      <FlatButton
                        icon={Remove}
                        onClick={() => setBankIdToDelete(ba.id)}
                      >
                        <span className={styles.removeBtnLabel}>REMOVE</span>
                      </FlatButton>
                    )}
                  </div>
                ) : (
                  <FlatButton
                    secondary
                    className={styles.changeBankBtn}
                    onClick={() => setShowAddMore(true)}
                  >
                    Change bank
                  </FlatButton>
                )}
              </>
            )}
          </div>
        );
      })}

      {showAddMore && (
        <>
          <Divider text="" />
          <div>
            <div className={styles.option}>
              <TelevisionIcon className={styles.icon} width={54} height={48} />
              <div className={styles.optionTitleContainer}>
                <div className={styles.optionTitle}>Use Your Bank Login</div>
                <span>Easily connect via your online account.</span>
              </div>
              <div className={styles.optionActionContainer}>
                <OpenPlaidFlow
                  className={styles.optionBtn}
                  plaidConfig={config}
                />
              </div>
            </div>
            <div className={styles.option}>
              <ESignIcon className={styles.icon} width={54} height={54} />
              <div className={styles.optionTitleContainer}>
                <div className={styles.optionTitle}>
                  Enter Bank Info Manually
                </div>
                <span>Add account and routing numbers.</span>
              </div>
              <div className={styles.optionActionContainer}>
                <Button
                  secondary
                  className={styles.optionBtn}
                  onClick={() => onAddBankManually(true)}
                >
                  Enter Manually
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
      <Divider text="" />
      <Form
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        subscription={{
          submitting: true,
          values: true,
        }}
        mutators={{ ...arrayMutators }}
        initialValues={getInitialValues()}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            {!!paymentRequestRules?.length && isPremiumUser && (
              <AssignPaymentRequestRulesToBank
                paymentRequestRules={paymentRequestRules}
                bankAccounts={bankAccounts}
              />
            )}
            <div className={styles.nextButtonContainer}>
              {!showAddMore && (
                <Button
                  secondary
                  className={cx(styles.addMoreButton, {
                    [styles.banner]: !isPremiumUser,
                  })}
                  onClick={handleAddMore}
                >
                  Add Another Bank
                  {!isPremiumUser && (
                    <PremiumBanner
                      className={styles.addMoreButtonRibbon}
                      color="#033A6D"
                      starColor="#FF9250"
                    />
                  )}
                </Button>
              )}
              <Button
                type={'submit'}
                className={styles.nextButton}
                data-qa="next-button-bank-step"
                loading={submitting}
              >
                {offlineConversion ? 'FINISH SETUP' : 'NEXT'}
              </Button>
            </div>
          </form>
        )}
      </Form>
      <RemoveBankAccountModal
        onDeleteClick={() => onRemoveBankAccount(bankIdToDelete)}
        isDeleteLoading={isDeleteLoading}
        open={!!bankIdToDelete}
        onCancel={() => setBankIdToDelete(null)}
      />
    </Card>
  );
};

BankStepOptions.propTypes = {
  bankAccounts: PropTypes.array,
  paymentRequestRules: PropTypes.array,
  onAddBankManually: PropTypes.func.isRequired,
  onPlaidSuccess: PropTypes.func.isRequired,
  onNext: PropTypes.func,
  onRemoveBankAccount: PropTypes.func,
  isDeleteLoading: PropTypes.bool,
  onUpdateBankAccountNickname: PropTypes.func,
  user: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(BankStepOptions);
