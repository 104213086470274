import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const PropertyBold = (props) => (
  <svg {...props} viewBox="0 0 26 21" fill={props.color}>
    <path
      d="M13.425.519a.986.986 0 011.15 0l4.37 3.156V3.38c0-.478.365-.874.837-.937l.131-.008h3.226a.96.96 0 01.958.817l.01.128-.001 4.023 2.833 2.048c.72.52.389 1.61-.461 1.7l-.114.007h-2.258v8.616l2.258.001c.534 0 .967.423.967.946a.953.953 0 01-.836.937l-.131.009H1.636a.957.957 0 01-.967-.946c0-.479.364-.875.836-.937l.131-.009h2.258v-8.618H1.636c-.894 0-1.292-1.069-.663-1.635l.088-.07zm8.745 10.638H5.828v8.617h1.29l.001-6.41c0-.485.374-.885.855-.94l.113-.006h3.225c.535 0 .968.424.968.946v6.41h9.89v-8.617zM10.344 14.31h-1.29v5.045h1.29v-5.045zm9.57-1.89c.534 0 .967.423.967.945v3.153a.957.957 0 01-.968.946h-5.375a.957.957 0 01-.968-.946v-3.153c0-.522.433-.946.968-.946zm-.969 1.89h-3.44v1.261h3.44v-1.26zM14 2.454L4.573 9.265h18.853L14 2.454zm8.17 1.871h-1.29v.748l1.29.932v-1.68z"
      fillRule="evenodd"
    />
  </svg>
);

PropertyBold.defaultProps = {
  width: 26,
  height: 21,
  color: colors.text,
};

PropertyBold.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(PropertyBold);
