import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Callout from '../../../../components/Callout';
import { Column, Row } from '../../../../components/Grid';
import Tag from '../../../../components/Tag';
import formatPhone from '../../../../helpers/format-phone';
import PetsPow from '../../../../icons/PetsThick';
import PhoneIcon from '../../../../icons/Phone';
import VehicleIcon from '../../../../icons/Vehicle';

import styles from './GeneralDetails.module.scss';

const GeneralDetails = (props) => {
  const { application } = props;
  const contacts = props.contacts || [];
  const pets = application?.pets || [];
  const vehicles = application?.vehicles || [];

  return (
    <Row className={styles.blockContainer}>
      {contacts.map((contact, index) => (
        <Column sm={12} md={6} key={`contacts-${index}`}>
          <Callout
            key={`contact-${index}`}
            className={cx(styles.block, styles.contact)}
          >
            <div className={cx(styles.datapoint, styles.title)}>
              <span className={styles.sectionTitle}>EMERGENCY CONTACT</span>
              <PhoneIcon styles={{ width: 30, height: 30 }} />
            </div>
            <div className={styles.datapoint}>
              <span className={styles.mainValue}>{contact.name}</span>
            </div>
            <div className={cx(styles.bottom, styles.datapoint, styles.reOder)}>
              <div>
                <span className={styles.subsectionTitle}>Relationship: </span>
                <span
                  className={styles.subsectionValue}
                  style={{ marginLeft: 4 }}
                >
                  {contact.relationship}
                </span>
              </div>
              <span className={styles.telephone}>
                <a href={`tel:${contact.telephone}`}>
                  {formatPhone(contact.telephone)}
                </a>
              </span>
            </div>
          </Callout>
        </Column>
      ))}
      {application?.pet
        ? pets.map((pet, index) => (
            <Column sm={12} md={6} key={`pets-${index}`}>
              <Callout
                key={`pet-${index}`}
                className={cx(styles.block, styles.pet)}
              >
                <div className={cx(styles.datapoint, styles.title)}>
                  <div>
                    <span className={styles.sectionTitle}>ANIMAL</span>
                    {pet.esa_animal && (
                      <Tag small accent className="ml-8">
                        ESA / Service Animal
                      </Tag>
                    )}
                  </div>
                  <PetsPow styles={{ width: 30, height: 30 }} />
                </div>
                <div className={styles.datapoint}>
                  <span className={styles.mainValue}>{pet.kind}</span>
                </div>
                <div
                  className={cx(styles.datapoint, styles.bottom, styles.reOder)}
                >
                  <div>
                    <span
                      className={styles.subsectionValue}
                      style={{ marginRight: 4 }}
                    >
                      <span style={{ textTransform: 'capitalize' }}>
                        {pet.sex}
                      </span>{' '}
                      - {pet.breed}
                    </span>
                  </div>
                  <div>
                    <span className={styles.subsectionValueItalic}>
                      {pet.weight} pounds{' '}
                      <span
                        className={styles.subsectionValue}
                        style={{ marginLeft: 4 }}
                      >
                        |
                      </span>{' '}
                      {pet.age} years old
                    </span>
                  </div>
                </div>
              </Callout>
            </Column>
          ))
        : null}
      {vehicles.map((vehicle, index) => (
        <Column sm={12} md={6} key={`vehicles-${index}`}>
          <Callout
            key={`vehicle-${index}`}
            className={cx(styles.block, styles.vehicle)}
          >
            <div className={styles.datapoint}>
              <span className={cx(styles.datapoint, styles.title)}>
                VEHICLE
              </span>
              <VehicleIcon style={{ width: 40, height: 20 }} />
            </div>
            <div className={styles.datapoint}>
              <span className={styles.mainValue}>
                {vehicle.make} {vehicle.name}
              </span>
            </div>
            <div className={cx(styles.datapoint, styles.bottom, styles.reOder)}>
              <div>
                {vehicle.registration && (
                  <>
                    <span className={styles.subsectionTitle}>
                      License Plate:{' '}
                    </span>
                    <span
                      className={styles.subsectionValue}
                      style={{
                        marginLeft: 4,
                        marginRight: 4,
                      }}
                    >
                      {vehicle.registration?.toUpperCase()}
                    </span>
                  </>
                )}
              </div>
              <div>
                <span className={styles.subsectionValueItalic}>
                  {vehicle.color}{' '}
                  <span
                    className={styles.subsectionValue}
                    style={{ marginLeft: 4 }}
                  >
                    |
                  </span>{' '}
                  {vehicle.year}
                </span>
              </div>
            </div>
          </Callout>
        </Column>
      ))}
    </Row>
  );
};

GeneralDetails.propTypes = {
  application: PropTypes.object,
  contacts: PropTypes.array,
};

export default GeneralDetails;
