import React, { createContext, useContext, useState } from 'react';

const initialState = {
  standAloneWizard: false,
};

const StandaloneWizardStateContext = createContext(initialState);

export const StandaloneWizardProvider = ({
  children,
  standAloneWizard = false,
}) => {
  const [standaloneWizardState] = useState({
    standAloneWizard,
  });

  return (
    <StandaloneWizardStateContext.Provider value={standaloneWizardState}>
      {children}
    </StandaloneWizardStateContext.Provider>
  );
};

export const useStandaloneWizardState = () =>
  useContext(StandaloneWizardStateContext);
