import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import BankAccountWarningNotice from '../../../../../../components/AddBankManuallyForm/BankAccountWarningNotice';
import Button from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import LoadingScreen from '../../../../../../components/LoadingScreen';
import { useUserProfile } from '../../../../../../core/TTgraphql';
import ESignIcon from '../../../../../../icons/ESign';
import TelevisionIcon from '../../../../../../icons/Television';
import { getLinkToken } from '../../../../../../services/plaidService';
import OpenPlaidFlow from '../OpenPlaidFlow';

import styles from './BankStepNoBankAccounts.module.scss';

const BankStepNoBankAccounts = ({ onAddBankManually, onPlaidSuccess }) => {
  const [config, setConfig] = useState(null);
  const { user } = useUserProfile({ polling: false });
  useEffect(() => {
    const setupPlaidConfig = async () => {
      const token = await getLinkToken();
      setConfig({
        // Required, fetch a link token from your server and pass it
        // back to your app to initialize Link.
        token,
        onSuccess: onPlaidSuccess,
      });
    };
    setupPlaidConfig();
  }, []);

  if (!config) {
    return <LoadingScreen loading={!!config} />;
  }

  return (
    <Card className={styles.card}>
      <h3 className={styles.subTitle}>PAYMENT SETUP</h3>
      <h2 className={styles.title}>Connect A Bank Account</h2>
      <BankAccountWarningNotice
        className={styles.notice}
        name={user?.payments_data?.entity_name}
      />
      <div className={styles.newAccount}>
        <div className={styles.options}>
          <div className={styles.option}>
            <label className={styles.badge}>Recommended</label>
            <TelevisionIcon className={styles.icon} />
            <h4 className={styles.optionTitle}>Use Your Bank Login</h4>
            <span className={styles.optionText}>
              Securely connect your online account in seconds with Plaid
            </span>
            <OpenPlaidFlow className={styles.optionBtn} plaidConfig={config} />
          </div>
          <div className={styles.option}>
            <ESignIcon className={styles.icon} />
            <h4 className={styles.optionTitle}>Enter Bank Info Manually</h4>
            <span className={styles.optionText}>
              Add your account and routing numbers in minutes
            </span>
            <Button
              secondary
              className={styles.optionBtn}
              onClick={onAddBankManually}
            >
              Enter Manually
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
};

BankStepNoBankAccounts.propTypes = {
  onAddBankManually: PropTypes.func.isRequired,
  onPlaidSuccess: PropTypes.func.isRequired,
};

export default BankStepNoBankAccounts;
