import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from '../../../BankInfo.module.scss';

const BankInfo = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.row}>
      <HelpfulInfoCard
        Icon={ResourcesIcon}
        className={styles.helpfulInfoCard}
        title="New Hampshire Security Deposit Laws"
        color="iceCold"
      >
        Though they're included in the lease agreement, we've highlighted some
        rules you need to know:
        <HelpfulInfoList className={styles.helpfulInfoList}>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            If a landlord charges a security deposit, they are required to
            provide an inventory of the rental unit’s condition in the form of a
            checklist.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            If the tenant stays for more than one year the landlord is required
            to pay interest on the security deposit.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Landlords cannot deposit Security Deposit with personal funds. It
            must be kept separate.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            You must return the security deposit within 30 days of the tenant
            moving out.
          </HelpfulInfoListItem>
          <div className={styles.learnMore}>
            <a
              href="https://support.turbotenant.com/en/articles/6145936-new-hampshire-lease-agreement"
              target="_blank noopener noreferrer"
            >
              Learn other security deposit laws
            </a>
          </div>
        </HelpfulInfoList>
      </HelpfulInfoCard>
    </div>
  </div>
);

BankInfo.propTypes = {
  formValues: PropTypes.object,
  className: PropTypes.string,
};

export default BankInfo;
