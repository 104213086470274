import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Question = ({ color, ...props }) => (
  <svg {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C17.5227 2 22 6.47726 22 12C22 17.5227 17.5227 22 12 22C6.47726 22 2 17.5227 2 12C2 6.47726 6.47726 2 12 2ZM12 16C11.448 16 11 16.448 11 17C11 17.552 11.448 18 12 18C12.552 18 13 17.552 13 17C13 16.448 12.552 16 12 16ZM12 6C10.0671 6 8.5 7.46571 8.5 9.27273C8.5 9.7246 8.89175 10.0909 9.375 10.0909C9.85825 10.0909 10.25 9.7246 10.25 9.27273C10.25 8.36936 11.0337 7.63636 12 7.63636C12.9658 7.63636 13.75 8.36969 13.75 9.27273C13.75 10.1768 12.9664 10.9091 12 10.9091L11.898 10.9146C11.4628 10.9619 11.125 11.3077 11.125 11.7273V14.1818C11.125 14.6337 11.5168 15 12 15L12.102 14.9945C12.5372 14.9472 12.875 14.6014 12.875 14.1818V12.4415L13.0108 12.4069C14.4509 12.0015 15.5 10.7519 15.5 9.27273C15.5 7.46595 13.9322 6 12 6Z"
      fill={color}
    />
  </svg>
);

Question.defaultProps = {
  width: 24,
  height: 24,
  color: 'currentcolor',
};

Question.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Question);
