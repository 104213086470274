import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';

import suggestionQuery from './suggestion.graphql';

const useSuggestion = () => {
  const [query, data] = useLazyQuery(suggestionQuery, {
    fetchPolicy: 'network-only',
  });

  const getSuggestion = useCallback(async (flow) => {
    await query({ variables: { flow } });
  }, []);

  return {
    getSuggestion,
    loading: data.loading,
    suggestion: data.data?.suggestion,
  };
};

export default useSuggestion;
