import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Property = (props) => (
  <svg {...props} viewBox="0 0 52 42" fill={props.color}>
    <path d="M25.364.21a1.063 1.063 0 011.272 0l10.057 7.487V5.384c0-.53.379-.969.878-1.06l.192-.017h6.416c.525 0 .962.38 1.052.883l.017.194v8.681l5.983 4.454c.797.593.427 1.842-.52 1.937l-.116.006-5.347-.001v19.385h5.347c.59 0 1.07.482 1.07 1.077 0 .529-.379.968-.878 1.06l-.192.017H1.405c-.59 0-1.07-.482-1.07-1.077 0-.529.379-.968.878-1.06l.192-.017H6.75V20.461H1.405c-.992 0-1.431-1.225-.726-1.868l.09-.074zm17.744 20.251H8.888v19.383h2.14l.001-3.23c0-.555.417-1.012.953-1.07l.116-.007h1.07v-9.692c0-.555.417-1.012.953-1.07l.116-.007h6.416c.59 0 1.07.482 1.07 1.077v9.692h1.069c.551 0 1.005.42 1.063.96l.006.117v3.23h19.248V20.461zM21.72 37.691l-1.05-.001-.017.001H13.166v2.153h8.554v-2.153zm-2.138-10.77h-4.277v8.616h4.277v-8.615zm18.18-2.153c.592 0 1.07.482 1.07 1.077v6.462c0 .594-.478 1.077-1.07 1.077H27.07c-.59 0-1.069-.483-1.069-1.077v-6.462c0-.595.479-1.077 1.07-1.077zm-1.069 2.154H28.14v4.307h8.554v-4.307zM26 2.417L4.649 18.307h42.7L26 2.417zM43.109 6.46h-4.277v2.828l4.277 3.185V6.46z" />
  </svg>
);

Property.defaultProps = {
  width: 52,
  height: 42,
  color: colors.text,
};

Property.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Property);
