import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Personalize = ({ width, height, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M8.687 7.197c1.38 0 2.507 1.097 2.507 2.45l-.001 5.943 4.374 1.442c1.431.452 1.98 1.93 1.693 3.45l-.042.197-1.133 5.987a.828.828 0 0 1-.819.667H6.402a.837.837 0 0 1-.692-.362l-4.464-6.538c-.367-.55-.539-1.299-.435-1.945.14-.873.77-1.495 1.724-1.495 1.27 0 2.352.56 3.661 2.1l.002-9.464c0-1.284 1.023-2.337 2.312-2.426Zm0 1.633a.815.815 0 0 0-.824.799v11.99c0 .813-1.08 1.126-1.531.445l-.362-.536c-1.573-2.284-2.46-2.902-3.435-2.902l-.048.001c-.009.003-.013.01-.017.028l-.014.087c-.039.24.04.581.179.79l4.21 6.168h7.729l1.014-5.353c.196-.834.002-1.506-.442-1.724l-.1-.04-4.95-1.632a.818.818 0 0 1-.568-.774v-6.53c0-.448-.379-.817-.841-.817Zm6.001-5.693a8.316 8.316 0 0 1 .215 11.713l-.215.218-1.178-1.155a6.708 6.708 0 0 0 0-9.622c-2.709-2.655-7.104-2.656-9.812 0a6.708 6.708 0 0 0-.198 9.42l.198.203-1.178 1.154a8.32 8.32 0 0 1 0-11.931c3.358-3.294 8.809-3.293 12.168 0Z"
    />
  </svg>
);

Personalize.defaultProps = {
  width: 18,
  height: 28,
  color: colors.text,
};

Personalize.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Personalize);
