import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Bullet = (props) => (
  <svg {...props} viewBox="0 0 8 10" fill={props.color}>
    <path d="M8 5l-8 5V0z" />
  </svg>
);

Bullet.defaultProps = {
  width: 8,
  height: 10,
  color: colors.secondary,
};

Bullet.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Bullet);
