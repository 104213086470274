import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import CloseIcon from '../../icons/Close';
import MaintenanceAltColoredIcon from '../../icons/MaintenanceAltColored';
import Button from '../Button';
import Card from '../Card';

import styles from './LulaWaitlistBanner.module.scss';

const LulaWaitlistBanner = ({
  className,
  onBannerDismiss,
  onLearnMore,
  showOffer,
}) => (
  <Card
    className={cx(styles.container, className, {
      [styles.showOffer]: showOffer,
    })}
  >
    <CloseIcon
      className={styles.close}
      width={14}
      height={14}
      onClick={onBannerDismiss}
    />
    <MaintenanceAltColoredIcon className={styles.maintenanceIcon} />
    <div className={styles.content}>
      <h3 className={styles.title}>
        {showOffer
          ? 'Stress-Free Maintenance for $0'
          : 'A Maintenance process run for you, not by you.'}
      </h3>
      <p className={styles.description}>
        {showOffer && (
          <>
            Get a FREE year of Maintenance Plus, a $149 value.{' '}
            <strong>Offer expires November 14th</strong>.<br />
          </>
        )}
        We provide troubleshooting, scheduling and vetted contractors while
        keeping you informed with real-time job status updates.
      </p>
    </div>
    <Button className={styles.btn} secondary onClick={onLearnMore}>
      Learn More
    </Button>
  </Card>
);

LulaWaitlistBanner.propTypes = {
  className: PropTypes.string,
  onBannerDismiss: PropTypes.func,
  onLearnMore: PropTypes.func,
  showOffer: PropTypes.bool,
};

export default LulaWaitlistBanner;
