import React, { useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../../constants/colors';
import CheckMarkFilled from '../../../icons/CheckMarkFilled';
import Button from '../../Button';
import Modal from '../../Modal';
import SimpleSlider from '../../SimpleSlider';

import LulaWaitlistModalAnimation from './LulaWaitlistModalAnimation';

import styles from './LulaWaitlistModal.module.scss';

const LulaWaitlistModal = ({
  className,
  containerClassName,
  open,
  onClose,
  onJoinWaitlist,
  onModalLoaded,
}) => {
  useEffect(() => {
    if (open) {
      onModalLoaded?.();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      className={cx(styles.modal, className)}
      containerClassName={cx(styles.modalContainer, containerClassName)}
      onClose={onClose}
    >
      <h2 className={styles.title}>
        Maintenance <b className={styles.bolded}>without the stress for less</b>
      </h2>
      <p className={styles.description}>
        TurboTenant is partnering with Lula to handle your maintenance requests
        24/7. Unlike a home warranty, we'll coordinate directly with your
        tenants to get issues resolved quickly and done right, all while keeping
        you in the loop and in control.
      </p>
      <div className={styles.content}>
        <div className={styles.checklistContainer}>
          <div className={styles.checklist}>
            <CheckMarkFilled
              className={styles.checklistIcon}
              color={colors.primary}
            />
            <div className={styles.checklistContent}>
              <h4 className={styles.checklistTitle}>
                No more wasted time and money on the little things
              </h4>
              <p className={styles.checklistDescription}>
                We troubleshoot every request with tenants before dispatching a
                pro.
              </p>
            </div>
          </div>
          <div className={styles.checklist}>
            <CheckMarkFilled
              className={styles.checklistIcon}
              color={colors.primary}
            />
            <div className={styles.checklistContent}>
              <h4 className={styles.checklistTitle}>
                No more hunting for a trusted pro to do the work right
              </h4>
              <p className={styles.checklistDescription}>
                The pros take competency tests, provide work credentials, agree
                to fair pricing, & verify their licensing and insurance at
                regular intervals.
              </p>
            </div>
          </div>
          <div className={styles.checklist}>
            <CheckMarkFilled
              className={styles.checklistIcon}
              color={colors.primary}
            />
            <div className={styles.checklistContent}>
              <h4 className={styles.checklistTitle}>
                No more coordination between you, your tenants, and a pro
              </h4>
              <p className={styles.checklistDescription}>
                We will schedule with the tenant and pro, then review the
                completed work to make sure it's up to standards.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.animation}>
          <LulaWaitlistModalAnimation />
        </div>
      </div>
      <SimpleSlider className={styles.slideContainer} infinite={false}>
        <div className={styles.slide}>
          <h4 className={styles.slideTitle}>
            No more wasted time and money on the little things
          </h4>
          <p className={styles.slideDescription}>
            We troubleshoot every request with tenants before dispatching a pro.
          </p>
        </div>
        <div className={styles.slide}>
          <h4 className={styles.slideTitle}>
            No more hunting for a trusted pro to do the work right
          </h4>
          <p className={styles.slideDescription}>
            The pros take competency tests, provide work credentials, agree to
            fair pricing, & verify their licensing and insurance at regular
            intervals.
          </p>
        </div>
        <div className={styles.slide}>
          <h4 className={styles.slideTitle}>
            No more coordination between you, your tenants, and a pro
          </h4>
          <p className={styles.slideDescription}>
            We will schedule with the tenant and pro, then review the completed
            work to make sure it's up to standards.
          </p>
        </div>
      </SimpleSlider>
      <div className={styles.btnWrapper}>
        <Button className={styles.btn} onClick={onClose} secondary>
          Not Now
        </Button>
        <Button className={styles.btn} onClick={onJoinWaitlist}>
          Join Waitlist
        </Button>
      </div>
    </Modal>
  );
};

LulaWaitlistModal.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  open: PropTypes.bool,
  onJoinWaitlist: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onModalLoaded: PropTypes.func,
};

export default LulaWaitlistModal;
