import React from 'react';
import cx from 'classnames';

import styles from './Button.module.scss';

const Button = ({ className, size, position, dark }) => (
  <div
    className={cx(
      className,
      styles.skeletonButton,
      styles[size],
      styles[position],
      {
        [styles.dark]: dark,
      },
    )}
  ></div>
);

export default Button;
