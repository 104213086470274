import React from 'react';

import isZip from '../../validators/isZip';
import Input from '../Input';

import CoreField from './CoreField';

export const ZipCodeField = ({ validate = [], ...props }) => (
  <CoreField
    component={Input}
    type="number"
    pattern="[0-9]*"
    parse={(value) => (value ? value.substring(0, 5) : '')}
    validate={[isZip, ...(Array.isArray(validate) ? validate : [validate])]}
    {...props}
  />
);

export default ZipCodeField;
