import React from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../../../../../../components/Button';
import Stepper from '../../../../../../components/Stepper';
import fieldRequired from '../../../../../../validators/fieldRequired';

import styles from './PropertiesStandAlone.module.scss';

const PropertiesStandAlone = ({ onSubmit, initialValues }) => {
  return (
    <Form
      onSubmit={(...data) => {
        onSubmit(...data);
      }}
      initialValues={{ ...initialValues }}
      subscription={{
        submitting: true,
        values: true,
        form: true,
      }}
    >
      {({ handleSubmit }) => (
        <div className={styles.container}>
          <h2>How many rentals do you currently own/manage? </h2>
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Field
              component={Stepper}
              labelProps={{
                className: styles.stepperLabel,
              }}
              errorClassName={styles.stepperError}
              small
              name="properties"
              id="propertiesCount"
              className={styles.stepper}
              fieldClassName={styles.stepperField}
              validate={fieldRequired}
            />
            <Button type={'submit'} className={styles.submit}>
              {' '}
              Continue{' '}
            </Button>
          </form>
        </div>
      )}
    </Form>
  );
};

export default PropertiesStandAlone;
