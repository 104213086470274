import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const ChevronDown = (props) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={props.color}
      d="M19.1585 16.2526C19.5422 15.9169 19.6076 15.3527 19.3293 14.941L19.2526 14.8415L13.2526 7.8415C12.8826 7.41869 12.2454 7.38849 11.8373 7.75089L11.7474 7.8415L5.74741 14.8415C5.38373 15.2571 5.42585 15.8889 5.84148 16.2526C6.22515 16.5883 6.79297 16.5782 7.1641 16.2478L7.25256 16.1585L12.5 10.019L17.7474 16.1585C18.0831 16.5422 18.6472 16.6076 19.059 16.3293L19.1585 16.2526Z"
    />
  </svg>
);

ChevronDown.defaultProps = {
  color: colors.primary,
  width: '24',
  height: '24',
};

ChevronDown.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default memo(ChevronDown);
