import React, { memo } from 'react';
import PropTypes from 'prop-types';

const BrokenPage = ({ color, ...props }) => (
  <svg viewBox="0 0 258 226" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="404-Error---In-Product"
        transform="translate(-511.000000, -111.000000)"
      >
        <g
          id="add-rental-property"
          transform="translate(512.000000, 112.000000)"
        >
          <path
            d="M245.345608,127.855622 C245.345608,62.1728104 192.807046,8.92965529 128,8.92965529 C63.1929544,8.92965529 10.654392,62.1728104 10.654392,127.855622 C10.654392,145.352604 14.4153188,161.951696 21.1098857,176.912583 L234.890114,176.912583 C241.584681,161.951696 245.345608,145.352604 245.345608,127.855622"
            id="Fill-1"
            fill="#EBEFF5"
          />
          <path
            d="M13.5880322,176.912583 L242.411968,176.912583"
            id="Stroke-3"
            stroke="#033A6D"
            strokeWidth="2.7461918"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.386651323,176.912583 L7.72075182,176.912583"
            id="Stroke-5"
            stroke="#033A6D"
            strokeWidth="2.7461918"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M248.279248,176.912583 L255.613349,176.912583"
            id="Stroke-7"
            stroke="#033A6D"
            strokeWidth="2.7461918"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M157.090309,20.5668584 C157.090309,15.8425244 153.31178,12.0131083 148.650226,12.0131083 C147.57358,12.0131083 146.555607,12.2360945 145.610975,12.604765 C144.537262,5.81111914 138.772659,0.605134959 131.764193,0.605134959 C124.75866,0.605134959 118.994057,5.81111914 117.920345,12.604765 C116.975713,12.2360945 115.95774,12.0131083 114.881094,12.0131083 C110.219539,12.0131083 106.438077,15.8425244 106.438077,20.5668584 C106.438077,25.2941656 110.219539,29.1235817 114.881094,29.1235817 L148.650226,29.1235817 C153.31178,29.1235817 157.090309,25.2941656 157.090309,20.5668584"
            id="Fill-9"
            fill="#FFFFFF"
          />
          <path
            d="M157.090309,20.5668584 C157.090309,15.8425244 153.31178,12.0131083 148.650226,12.0131083 C147.57358,12.0131083 146.555607,12.2360945 145.610975,12.604765 C144.537262,5.81111914 138.772659,0.605134959 131.764193,0.605134959 C124.75866,0.605134959 118.994057,5.81111914 117.920345,12.604765 C116.975713,12.2360945 115.95774,12.0131083 114.881094,12.0131083 C110.219539,12.0131083 106.438077,15.8425244 106.438077,20.5668584 C106.438077,25.2941656 110.219539,29.1235817 114.881094,29.1235817 L148.650226,29.1235817 C153.31178,29.1235817 157.090309,25.2941656 157.090309,20.5668584 Z"
            id="Stroke-11"
            stroke="#033A6D"
            strokeWidth="2.7461918"
            strokeLinejoin="round"
          />
          <path
            d="M231.379427,105.52519 L231.379427,82.2038083 C231.379427,80.2355835 229.804062,78.6390024 227.861992,78.6390024 L218.013762,78.6390024 C216.068759,78.6390024 214.496328,80.2355835 214.496328,82.2038083 L214.496328,90.7129612 L231.379427,105.52519 Z"
            id="Fill-13"
            fill="#D6DAFF"
          />
          <path
            d="M231.379427,105.52519 L231.379427,82.2038083 C231.379427,80.2355835 229.804062,78.6390024 227.861992,78.6390024 L218.013762,78.6390024 C216.068759,78.6390024 214.496328,80.2355835 214.496328,82.2038083 L214.496328,90.7129612 L231.379427,105.52519 Z"
            id="Stroke-15"
            stroke="#033A6D"
            strokeWidth="2.7461918"
            strokeLinejoin="round"
          />
          <polygon
            id="Fill-17"
            fill="#FFFFFF"
            points="235.715347 177.028833 151.296917 177.028833 151.296917 126.303935 193.506132 89.1990336 235.715347 126.300962"
          />
          <polygon
            id="Stroke-19"
            stroke="#033A6D"
            strokeWidth="2.7461918"
            strokeLinejoin="round"
            points="235.715347 177.028833 151.296917 177.028833 151.296917 126.303935 193.506132 89.1990336 235.715347 126.300962"
          />
          <path
            d="M147.194808,127.832431 C145.331946,127.838377 143.571762,127.011842 142.366036,125.572838 C141.274722,124.267625 140.7496,122.608608 140.884548,120.899047 C141.019495,119.19246 141.808644,117.634529 143.099446,116.528518 L189.404023,75.8885421 C190.545209,74.9044298 192.000295,74.3573703 193.505252,74.3573703 C195.010209,74.3573703 196.465295,74.9044298 197.606481,75.8944884 L243.922792,116.528518 C245.207727,117.634529 245.996876,119.19246 246.131824,120.899047 C246.266771,122.608608 245.738716,124.267625 244.644468,125.572838 C243.444609,127.011842 241.681491,127.832431 239.821563,127.832431 C238.316606,127.832431 236.855653,127.288345 235.714467,126.298286 L193.505252,89.1993309 L151.296037,126.304232 C150.157784,127.288345 148.699765,127.832431 147.194808,127.832431"
            id="Fill-21"
            fill="#D6DAFF"
          />
          <path
            d="M147.194808,127.832431 C145.331946,127.838377 143.571762,127.011842 142.366036,125.572838 C141.274722,124.267625 140.7496,122.608608 140.884548,120.899047 C141.019495,119.19246 141.808644,117.634529 143.099446,116.528518 L189.404023,75.8885421 C190.545209,74.9044298 192.000295,74.3573703 193.505252,74.3573703 C195.010209,74.3573703 196.465295,74.9044298 197.606481,75.8944884 L243.922792,116.528518 C245.207727,117.634529 245.996876,119.19246 246.131824,120.899047 C246.266771,122.608608 245.738716,124.267625 244.644468,125.572838 C243.444609,127.011842 241.681491,127.832431 239.821563,127.832431 C238.316606,127.832431 236.855653,127.288345 235.714467,126.298286 L193.505252,89.1993309 L151.296037,126.304232 C150.157784,127.288345 148.699765,127.832431 147.194808,127.832431 Z"
            id="Stroke-23"
            stroke="#033A6D"
            strokeWidth="2.7461918"
            strokeLinejoin="round"
          />
          <path
            d="M218.716663,78.6390024 L227.156745,78.6390024 L227.156745,73.647085 C227.156745,71.6788602 225.584314,70.0822792 223.639311,70.0822792 L222.234097,70.0822792 C220.292027,70.0822792 218.716663,71.6788602 218.716663,73.647085 L218.716663,78.6390024 Z"
            id="Fill-25"
            fill="#D6DAFF"
          />
          <path
            d="M218.716663,78.6390024 L227.156745,78.6390024 L227.156745,73.647085 C227.156745,71.6788602 225.584314,70.0822792 223.639311,70.0822792 L222.234097,70.0822792 C220.292027,70.0822792 218.716663,71.6788602 218.716663,73.647085 L218.716663,78.6390024 Z"
            id="Stroke-27"
            stroke="#033A6D"
            strokeWidth="2.7461918"
            strokeLinejoin="round"
          />
          <path
            d="M193.39084,177.028833 L159.621708,177.028833 L159.621708,171.445259 C159.621708,169.80408 160.935979,168.47211 162.555348,168.47211 L190.4572,168.47211 C192.076569,168.47211 193.39084,169.80408 193.39084,171.445259 L193.39084,177.028833 Z"
            id="Fill-29"
            fill="#FFFFFF"
          />
          <path
            d="M193.39084,177.028833 L159.621708,177.028833 L159.621708,171.445259 C159.621708,169.80408 160.935979,168.47211 162.555348,168.47211 L190.4572,168.47211 C192.076569,168.47211 193.39084,169.80408 193.39084,171.445259 L193.39084,177.028833 Z"
            id="Stroke-31"
            stroke="#033A6D"
            strokeWidth="2.7461918"
            strokeLinejoin="round"
          />
          <path
            d="M189.169625,168.47211 L163.843509,168.47211 L163.843509,149.220969 C163.843509,142.135954 169.514236,136.388857 176.508034,136.388857 C183.498899,136.388857 189.169625,142.135954 189.169625,149.220969 L189.169625,168.47211 Z"
            id="Fill-33"
            fill="#D6DAFF"
          />
          <path
            d="M189.169625,168.47211 L163.843509,168.47211 L163.843509,149.220969 C163.843509,142.135954 169.514236,136.388857 176.508034,136.388857 C183.498899,136.388857 189.169625,142.135954 189.169625,149.220969 L189.169625,168.47211 Z"
            id="Stroke-35"
            stroke="#033A6D"
            strokeWidth="2.7461918"
            strokeLinejoin="round"
          />
          <path
            d="M180.728075,157.778881 L180.728075,153.50052"
            id="Stroke-37"
            stroke="#033A6D"
            strokeWidth="2.7461918"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M201.83239,114.99913 C201.83239,119.726437 198.053861,123.555853 193.392307,123.555853 C188.727819,123.555853 184.94929,119.726437 184.94929,114.99913 C184.94929,110.274796 188.727819,106.44538 193.392307,106.44538 C198.053861,106.44538 201.83239,110.274796 201.83239,114.99913"
            id="Fill-39"
            fill="#D6DAFF"
          />
          <path
            d="M199.359918,108.95115 C197.831491,107.402139 195.722204,106.444785 193.38996,106.444785 C188.728406,106.444785 184.949877,110.274201 184.949877,114.998535 C184.949877,117.362189 185.894509,119.502856 187.420002,121.048894 L199.359918,108.95115 Z"
            id="Fill-41"
            fill="#FFFFFF"
          />
          <path
            d="M201.83239,114.99913 C201.83239,119.726437 198.053861,123.555853 193.392307,123.555853 C188.727819,123.555853 184.94929,119.726437 184.94929,114.99913 C184.94929,110.274796 188.727819,106.44538 193.392307,106.44538 C198.053861,106.44538 201.83239,110.274796 201.83239,114.99913 Z"
            id="Stroke-43"
            stroke="#033A6D"
            strokeWidth="2.7461918"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M220.00277,164.195532 L208.986951,164.195532 C207.367582,164.195532 206.053311,162.863561 206.053311,161.222383 L206.053311,141.501484 C206.053311,139.857333 207.367582,138.528335 208.986951,138.528335 L220.00277,138.528335 C221.62214,138.528335 222.936411,139.857333 222.936411,141.501484 L222.936411,161.222383 C222.936411,162.863561 221.62214,164.195532 220.00277,164.195532"
            id="Fill-45"
            fill="#D6DAFF"
          />
          <path
            d="M222.883605,141.824368 C222.74279,139.989935 221.270103,138.530119 219.418976,138.530119 L209.570746,138.530119 C207.628676,138.530119 206.053311,140.123727 206.053311,142.094925 L206.053311,158.881325 L222.883605,141.824368 Z"
            id="Fill-47"
            fill="#FFFFFF"
          />
          <path
            d="M220.00277,164.195532 L208.986951,164.195532 C207.367582,164.195532 206.053311,162.863561 206.053311,161.222383 L206.053311,141.501484 C206.053311,139.857333 207.367582,138.528335 208.986951,138.528335 L220.00277,138.528335 C221.62214,138.528335 222.936411,139.857333 222.936411,141.501484 L222.936411,161.222383 C222.936411,162.863561 221.62214,164.195532 220.00277,164.195532 Z"
            id="Stroke-49"
            stroke="#033A6D"
            strokeWidth="2.7461918"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="woman" transform="translate(27.004219, 35.697115)">
            <g id="17--industry-engineer-female-asian-1">
              <g id="Scene" transform="translate(0.098197, 0.000000)">
                <path
                  d="M41.6355965,64.5907136 C43.2872727,64.5907136 45.9926045,62.1995192 48.1853471,62.9396508 C53.1118987,64.5907136 56.3867741,59.6659919 58.0099731,61.3170547 C59.6331722,62.9681174 66.2114001,64.5907136 67.8345992,59.6659919 C69.0021634,56.1930668 62.9365247,56.392333 62.9365247,48.2793522 C62.9365247,41.5327682 66.2114001,43.3546306 67.8345992,38.4583755 C69.4577982,33.5621204 64.5597238,28.6373988 64.5597238,28.6373988 C64.5597236,15.9814557 54.2962422,5.72178692 41.6355965,5.72178692 C28.9749507,5.72178692 18.7114694,15.9814557 18.7114691,28.6373988 C18.7114691,28.6373988 13.8418719,33.4767206 15.465071,38.4299089 C17.08827,43.3830972 20.3916226,41.5043016 20.3916226,48.2508856 C20.3916226,56.4207996 14.3259839,56.2215334 15.465071,59.6375253 C17.1167472,64.562247 23.6664979,62.9111842 25.289697,61.2885881 C26.912896,59.6659919 30.2162486,64.562247 35.114323,62.9111842 C37.3070656,62.1995192 40.0408746,64.5907136 41.6355965,64.5907136 Z"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  fill="#FFFFFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M64.588201,38.4299089 C64.5802474,36.5048259 63.2061193,34.8564847 61.3133257,34.5015182 L61.3133257,31.8825911 C61.3133257,21.0346398 52.5160559,12.2406377 41.6640736,12.2406377 C30.8120914,12.2406377 22.0148216,21.0346398 22.0148216,31.8825911 L22.0148216,34.5015182 C20.1673024,34.9036668 18.850792,36.5398014 18.853855,38.4299089 C18.8744437,40.519329 20.4742364,42.2538443 22.555888,42.4436994 C24.4539767,51.5522789 32.4846632,58.0800726 41.7922209,58.0800726 C51.0997787,58.0800726 59.1304652,51.5522789 61.0285539,42.4436994 C63.053842,42.1868573 64.5758813,40.4706346 64.588201,38.4299089 Z"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  fill="#FFFFFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M34.2884848,31.8541245 C32.9397373,31.8698028 31.8575721,32.9727119 31.867998,34.3210093 C31.8784238,35.6693068 32.9775154,36.7553615 34.3263442,36.7501961 C35.6751731,36.7450306 36.765908,35.6505898 36.7659992,34.302252 C36.7660435,33.6480058 36.5041152,33.0209618 36.0386541,32.5610217 C35.5731929,32.1010816 34.9429299,31.846517 34.2884848,31.8541245 Z"
                  id="Path"
                  fill="#033A6D"
                />
                <path
                  d="M49.0396624,31.8541245 C47.6870965,31.8541245 46.5906252,32.9501885 46.5906252,34.302252 C46.5906252,35.6543155 47.6870965,36.7503796 49.0396624,36.7503796 C50.3922283,36.7503796 51.4886997,35.6543155 51.4886997,34.302252 C51.4964148,33.6506346 51.2408702,33.0234527 50.7799036,32.5626573 C50.3189369,32.1018619 49.691522,31.8464122 49.0396624,31.8541245 Z"
                  id="Path"
                  fill="#033A6D"
                />
                <ellipse
                  id="Oval"
                  fill="#D6DAFF"
                  cx="31.0136095"
                  cy="40.8495698"
                  rx="2.44903721"
                  ry="2.44812753"
                />
                <ellipse
                  id="Oval"
                  fill="#D6DAFF"
                  cx="52.3145378"
                  cy="40.8495698"
                  rx="2.44903721"
                  ry="2.44812753"
                />
                <path
                  d="M36.7659992,46.7136893 C39.7383715,48.7035879 43.618253,48.7035879 46.5906252,46.7136893"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.6326352,26.9294028 L65.7557652,26.9294028"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M62.4808899,23.6557439 C62.4808899,11.8644925 52.9186401,2.30579453 41.1230073,2.30579453 C29.3273744,2.30579453 19.7651247,11.8644925 19.7651247,23.6557439 L19.7651247,27.0432692 L62.4808899,27.0432692 L62.4808899,23.6557439 Z"
                  id="Path"
                  fill="#D6DAFF"
                />
                <path
                  d="M62.4808899,23.6557439 C62.4808899,11.8644925 52.9186401,2.30579453 41.1230073,2.30579453 C29.3273744,2.30579453 19.7651247,11.8644925 19.7651247,23.6557439 L19.7651247,27.0432692 L62.4808899,27.0432692 L62.4808899,23.6557439 Z"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M46.1065132,27.0432692 L36.2818872,27.0432692 L33.0924434,2.39119433 C38.330653,0.200817711 44.2286105,0.200817711 49.4668201,2.39119433 L46.1065132,27.0432692 Z"
                  id="Path"
                  fill="#D6DAFF"
                />
                <path
                  d="M46.1065132,27.0432692 L36.2818872,27.0432692 L33.0924434,2.39119433 C38.330653,0.200817711 44.2286105,0.200817711 49.4668201,2.39119433 L46.1065132,27.0432692 Z"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M55.9880936,8.36918016 L54.279463,20.382085"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M26.3718297,8.36918016 L28.0804603,20.382085"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M74.412827,184.40663 C74.412827,187.253289 59.7470809,189.302885 41.6640736,189.302885 C23.5810664,189.302885 8.91532029,187.110956 8.91532029,184.40663 C8.91532029,181.702303 23.5810664,179.481908 41.6640736,179.481908 C59.7470809,179.481908 74.412827,181.673836 74.412827,184.40663 Z"
                  id="Path"
                  fill="#EBEFF5"
                />
                <path
                  d="M79.3393786,109.112475 C74.8648831,109.113149 71.2156338,105.528587 71.1379517,101.056427 L71.1379517,91.0931174 C71.1339494,80.0283878 64.8393653,69.9276771 54.9059609,65.0461791 C50.784942,62.9529567 46.2554478,61.7869966 41.6355965,61.6301872 C37.0141311,61.4306333 32.4179038,62.4102751 28.2798005,64.4768472 C10.9371998,73.1591599 12.1617185,91.975582 12.1617185,99.291498 L12.1617185,120.556048 L23.5525892,120.556048 L23.5525892,182.7271 L59.6901266,182.7271 L59.6901266,101.056427 C59.7001367,107.739456 63.1039063,113.960247 68.7278086,117.573859 C74.3517109,121.187471 81.4263321,121.69952 87.5123283,118.933451 L87.5123283,101.056427 C87.4500331,105.524016 83.8090616,109.112909 79.3393786,109.112475 Z"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  fill="#D6DAFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M59.6901266,101.056427 L59.6901266,92.5164474 C59.6789546,90.4779953 60.3062994,88.4871645 61.4841887,86.8231275 C62.5414252,85.1183002 63.0576655,83.133498 62.9650019,81.1298077"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.6664979,120.556048 L23.6664979,92.2887146 C23.6810725,90.2453412 23.0425499,88.2506992 21.8439586,86.5953947 C20.8388759,84.9532939 20.3341971,83.0540324 20.3916226,81.1298077"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M31.41229,144.183325 L27.3970081,139.657136 C26.7766065,138.958067 25.8827492,138.563253 24.9479708,138.575405 L8.14643652,138.575405 C7.22077768,138.567761 6.33746179,138.962508 5.72587649,139.657136 L1.62516302,144.183325 C1.08410511,144.784261 0.789262356,145.566829 0.799324895,146.375253 L0.799324895,158.217358 C0.799324895,159.085587 1.14435541,159.918255 1.75851367,160.532185 C2.37267193,161.146115 3.20564929,161.491017 4.07420023,161.491017 L29.0486843,161.491017 C30.850901,161.475552 32.3080891,160.018906 32.3235596,158.217358 L32.3235596,146.375253 C32.307605,145.555793 31.9820504,144.772718 31.41229,144.183325 Z"
                  id="Path"
                  fill="#FFFFFF"
                />
                <path
                  d="M31.41229,144.183325 L27.3970081,139.657136 C26.7766065,138.958067 25.8827492,138.563253 24.9479708,138.575405 L8.14643652,138.575405 C7.22077768,138.567761 6.33746179,138.962508 5.72587649,139.657136 L1.62516302,144.183325 C1.08410511,144.784261 0.789262356,145.566829 0.799324895,146.375253 L0.799324895,158.217358 C0.799324895,159.085587 1.14435541,159.918255 1.75851367,160.532185 C2.37267193,161.146115 3.20564929,161.491017 4.07420023,161.491017 L29.0486843,161.491017 C30.850901,161.475552 32.3080891,160.018906 32.3235596,158.217358 L32.3235596,146.375253 C32.307605,145.555793 31.9820504,144.772718 31.41229,144.183325 Z"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5669965,145.777454 L14.66771,127.331098"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22.4704565,145.948254 L18.4551745,127.957363"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M41.6071193,135.301746 L41.6071193,182.7271"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M41.6355965,182.7271 C41.6355965,177.303125 37.2369616,172.906123 31.8109705,172.906123 L30.2447257,172.906123 C26.6983014,172.901988 23.4282562,174.819702 21.7015727,177.916245 C21.1045645,178.925869 21.1045645,180.18028 21.7015727,181.189904 C22.2879152,182.205269 23.3765327,182.825551 24.5492904,182.8125 L41.6355965,182.7271 Z"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  fill="#FFFFFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M41.6355965,182.7271 C41.6512373,177.309614 46.0407232,172.921758 51.4602225,172.906123 L53.0264672,172.906123 C56.5696078,172.914057 59.8339994,174.828456 61.5696203,177.916245 C62.1483836,178.930022 62.1433756,180.175159 61.5564762,181.184251 C60.9695767,182.193343 59.8895811,182.813719 58.7219026,182.8125 L41.6355965,182.7271 Z"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  fill="#FFFFFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <rect
                  id="Rectangle"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  fill="#FFFFFF"
                  strokeLinejoin="round"
                  x="23.6664979"
                  y="109.112475"
                  width="36.0236287"
                  height="6.54731781"
                />
                <rect
                  id="Rectangle"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  fill="#D6DAFF"
                  strokeLinejoin="round"
                  x="36.7659992"
                  y="109.112475"
                  width="9.82462601"
                  height="6.54731781"
                />
                <path
                  d="M54.9059609,65.0461791 L51.6026084,79.6495445 L44.938949,109.112475 L38.3891983,109.112475 L31.7540161,79.6495445 L28.3082777,64.4768472 C32.446381,62.4102751 37.0426083,61.4306333 41.6640736,61.6301872 C46.2742502,61.7911217 50.7935887,62.9569694 54.9059609,65.0461791 Z"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  fill="#FFFFFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <polygon
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  fill="#FFFFFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  points="51.6026084 79.6495445 44.938949 109.112475 38.3891983 109.112475 31.7540161 79.6495445"
                />
                <path
                  d="M12.1901956,120.556048 L12.1901956,127.928897 C12.1901958,131.07323 14.740129,133.622216 17.885631,133.622216 C21.031133,133.622216 23.5810662,131.07323 23.5810664,127.928897 L23.5810664,120.556048 L12.1901956,120.556048 Z"
                  id="Path"
                  fill="#FFFFFF"
                />
                <path
                  d="M12.1901956,120.556048 L12.1901956,127.928897 C12.1901958,131.07323 14.740129,133.622216 17.885631,133.622216 C21.031133,133.622216 23.5810662,131.07323 23.5810664,127.928897 L23.5810664,120.556048 L12.1901956,120.556048 Z"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M41.6640736,79.6495445 L41.6640736,76.3758856"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M35.0288914,94.1675101 C33.9182815,92.3741144 25.2042654,77.9984818 23.6380207,77.9984818 L20.3916226,77.9984818 L28.4506636,65.1031123 L31.7540161,79.6495445 L35.0288914,94.1675101 Z"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  fill="#D6DAFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M62.9650019,77.9984818 L59.6901266,77.9984818 C58.152359,77.9984818 49.4383429,92.3741144 48.2992558,94.1675101 L51.5741312,79.6495445 L54.8774837,65.1031123 L62.9650019,77.9984818 Z"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  fill="#D6DAFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M100.668784,80.4750759 C100.664597,79.2475383 100.432866,78.0314063 99.9853318,76.8882844 L95.5713694,81.3006073 C93.9986183,82.8334699 91.4805593,82.8016075 89.9471271,81.2294407 C88.4136949,79.6572738 88.445569,77.1401502 90.0183199,75.6072874 L94.4038051,71.1949646 C89.4429014,69.3863508 83.9376174,71.8086873 81.9212929,76.6872963 C79.9049684,81.5659053 82.0945268,87.1661509 86.8858305,89.3851215 L86.8858305,129.722293 C87.0600691,131.770228 88.7738269,133.343835 90.8299194,133.343835 C92.886012,133.343835 94.5997698,131.770228 94.7740084,129.722293 L94.7740084,89.4989879 C98.3569752,87.9277567 100.670461,84.3861985 100.668784,80.4750759 Z"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  fill="#FFFFFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M97.6502033,100.259362 C97.8053362,99.3637052 97.2070653,98.5111677 96.311776,98.3521002 L90.6163406,97.3842358 C87.4708385,96.8182558 84.4619173,98.9084244 83.8957269,102.052758 C83.3295365,105.197092 85.4204818,108.204895 88.5659839,108.770876 L94.2614193,109.73874 C94.685197,109.821378 95.1243845,109.728713 95.4786044,109.481924 C95.8328242,109.235135 96.0717999,108.855316 96.1409129,108.429276 L97.6502033,100.259362 Z"
                  id="Path"
                  fill="#FFFFFF"
                />
                <path
                  d="M97.6502033,100.259362 C97.8053362,99.3637052 97.2070653,98.5111677 96.311776,98.3521002 L90.6163406,97.3842358 C87.4708385,96.8182558 84.4619173,98.9084244 83.8957269,102.052758 C83.3295365,105.197092 85.4204818,108.204895 88.5659839,108.770876 L94.2614193,109.73874 C94.685197,109.821378 95.1243845,109.728713 95.4786044,109.481924 C95.8328242,109.235135 96.0717999,108.855316 96.1409129,108.429276 L97.6502033,100.259362 Z"
                  id="Path"
                  stroke="#033A6D"
                  strokeWidth="2.84254941"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

BrokenPage.defaultProps = {
  width: 258,
  height: 226,
  color: 'currentColor',
};

BrokenPage.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(BrokenPage);
