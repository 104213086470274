import React from 'react';
import { useMutation } from '@apollo/client';
import cx from 'classnames';
import PropTypes from 'prop-types';

import retryLulaInvoicePaymentGQL from '../../graphql/mutations/maintenance_requests/retryLulaInvoicePayment.graphql';
import maintenanceRequestQuery from '../../graphql/queries/maintenanceRequestQuery.graphql';
import Attachment from '../../icons/Attachment';
import Lock from '../../icons/Lock';
import Button from '../Button';
import IconTooltip from '../IconTooltip';

import styles from './ActivityItem.module.scss';

const ActivityItem = ({
  className,
  Icon,
  title,
  date,
  content,
  isPrivate,
  attachments,
  actions,
  maintenanceRequestId,
}) => {
  const [retryLulaInvoicePayment, { loading: retryLoading }] = useMutation(
    retryLulaInvoicePaymentGQL,
    {
      refetchQueries: [
        {
          query: maintenanceRequestQuery,
          variables: { id: maintenanceRequestId },
        },
      ],
      awaitRefetchQueries: true,
    },
  );

  const actionFunctions = {
    'Retry Payment': {
      handler: async (invoiceEventId) => {
        await retryLulaInvoicePayment({
          variables: { invoiceEventId },
        });
      },
      loading: retryLoading,
    },
  };

  function renderContent(content) {
    if (typeof content === 'string' || !content) {
      return content;
    }
    return content.map((item, i) => {
      return (
        <div className={styles.contentRow} key={i}>
          {item.label && <b className={styles.contentLabel}>{item.label}:</b>}
          {item.text}
        </div>
      );
    });
  }

  return (
    <div
      className={cx(styles.container, {
        [className]: className,
      })}
    >
      <div className={styles.iconContainer}>
        {<Icon className={styles.icon} />}
      </div>

      <div className={styles.body}>
        <div className={styles.heading}>
          <h3 className={styles.title}>{title}</h3>
          {isPrivate && (
            <div className={styles.lock}>
              <IconTooltip
                tip={<span>Tenants can not see this activity.</span>}
                icon={Lock}
              />
            </div>
          )}
        </div>
        <p className={styles.date}>{date}</p>
        <div className={styles.content}>{renderContent(content)}</div>
        {attachments && (
          <div className={styles.attachments}>
            {attachments.map((attachment, index) => (
              <div key={index} className={styles.attachment}>
                <div className={styles.icon}>
                  {attachment?.icon?.() || <Attachment />}
                </div>
                <a
                  href={attachment.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  {attachment.filename}
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
      {actions && (
        <div className={styles.actions}>
          {actions.map((action, index) => (
            <div className={styles.action} key={index}>
              <Button
                className={styles.actionButton}
                tertiary
                onClick={async () => {
                  await actionFunctions[action.label]?.handler?.(
                    action.data.eventId,
                  );
                }}
                loading={actionFunctions[action.label]?.loading}
              >
                {action.label}
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

ActivityItem.propTypes = {
  className: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  title: PropTypes.string,
  date: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  isPrivate: PropTypes.bool,
  attachments: PropTypes.array,
  maintenanceRequestId: PropTypes.string,
};

export default ActivityItem;
