import React from 'react';
import PropTypes from 'prop-types';

import SectionInfoBlock from '../../../../../common/SectionInfoBlock';

const LateFeeInfo = ({ className }) => (
  <SectionInfoBlock className={className}>
    <>
      <strong>Late Fees:</strong> Rent is due in full on the first day of each
      and every month. If rent is not received on or before the second day of
      each month, a late fee of 5% of the unpaid rent amount may be charged.
    </>
  </SectionInfoBlock>
);

LateFeeInfo.propTypes = {
  className: PropTypes.string,
};

export default LateFeeInfo;
