import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import Close from '../../icons/Close';
import IOSShareIcon from '../../icons/IOSShare';
import { useConfig } from '../../providers/ConfigProvider';
import { gtmDataLayerPush } from '../../services/utilities';
import { segmentTracking } from '../../services/utilities/segment';
import IconButton from '../IconButton';

import styles from './IOSPWAPrompt.module.scss';

const IOSPWAPlainPrompt = ({ hide }) => {
  const { ASSET_BUCKET_URL } = useConfig();
  useEffect(() => {
    segmentTracking('add_app_to_phone_display');
    gtmDataLayerPush({
      event: 'gtmEvent',
      eventCategory: 'pwa',
      eventAction: 'prompt',
      eventLabel: 'view install prompt',
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <IconButton onClick={hide} icon={Close} className={styles.close} />
        <img
          src={`${ASSET_BUCKET_URL}app-icons/android-chrome-192x192.png`}
          className={styles.logo}
        />
        <h3>Install TurboTenant</h3>
        <p>Add to your home screen!</p>
        <ol>
          <li>
            {'1. Tap'}&nbsp;
            <IOSShareIcon />
            &nbsp;
            {'below'}
          </li>
          <li>2. Scroll & tap "Add to Home Screen"</li>
        </ol>
      </div>
    </div>
  );
};

export default withRouter(IOSPWAPlainPrompt);
