import React from 'react';
import { Field, useForm } from 'react-final-form';
import cx from 'classnames';
import kebabCase from 'lodash.kebabcase';
import PropTypes from 'prop-types';

import getFieldError from '../../validators/get-field-error';
import Input from '../Input';
import Label from '../Label';
import PlainRadioButton from '../PlainRadioButton';
import RadioGroupItemDescription from '../RadioGroup/components/RadioGroupItemDescription';

import styles from '../RadioGroup/RadioGroup.module.scss';

const RadioGroupAdditionalOptions = ({
  className,
  options,
  hint,
  input,
  radioProps,
  labelProps,
  style,
  meta,
  label,
  id,
  disabled,
  vertical,
  canUnselect,
  radiosContainerClassName,
  radioRowClassName,
  inline = true,
}) => {
  const form = useForm();

  const error = getFieldError(meta);
  return (
    <div
      className={cx(
        styles.wrap,
        {
          [styles.inline]: inline,
          [styles.block]: !inline,
        },
        className,
      )}
      style={style}
    >
      {label && (
        <Label
          {...labelProps}
          className={labelProps.className}
          htmlFor={id}
          hint={hint}
          hintClassName={labelProps.hintClassName}
        >
          {label}
        </Label>
      )}
      <div
        className={cx(
          styles.container,
          {
            [styles.horizontal]: !vertical,
            [styles.block]: !inline,
          },
          radiosContainerClassName,
        )}
      >
        {options.map(
          ({ value, label, additionalInput, description, ...restProps }) => {
            const shouldShowAdditionalInput =
              additionalInput &&
              form.getState().values?.[id]?.includes?.(value);
            return (
              <React.Fragment key={`radio-${value}`}>
                <label
                  className={cx(
                    styles.radioRow,
                    {
                      [styles.horizontalRow]: !vertical,
                    },
                    radioRowClassName,
                  )}
                  key={`radio-${value}`}
                >
                  <PlainRadioButton
                    checked={input.value === value}
                    value={value}
                    name={input.name}
                    disabled={disabled}
                    className={styles.radio}
                    onClick={(e) => {
                      if (e.target.value === input.value && canUnselect) {
                        input.onChange(null);
                      }
                    }}
                    onChange={() => {
                      input.onChange(value);
                    }}
                    data-qa={kebabCase(`radio-${id}-input-option-${value}`)}
                    {...radioProps}
                    {...restProps}
                  />
                  <div>
                    <span
                      className={cx(styles.radioLabel, {
                        [styles.disabled]: disabled,
                      })}
                    >
                      {label}
                    </span>
                    <RadioGroupItemDescription description={description} />
                  </div>
                </label>
                {shouldShowAdditionalInput ? (
                  <>
                    {additionalInput.label && (
                      <Label
                        className={styles.label}
                        hint={additionalInput.isOptional && '(Optional)'}
                        htmlFor={id}
                      >
                        {label}
                      </Label>
                    )}
                    <Field
                      component={Input}
                      as={additionalInput.as || 'textarea'}
                      id={additionalInput.id}
                      value={additionalInput.value}
                      name={additionalInput.name}
                      validate={additionalInput.validate}
                      inputProps={{
                        autoFocus: true,
                        rows: 3,
                        placeholder: additionalInput.placeholder || '',
                      }}
                      data-qa={`input-${id}-${additionalInput.id}`}
                    />
                  </>
                ) : null}
              </React.Fragment>
            );
          },
        )}
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

RadioGroupAdditionalOptions.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      additionalInput: PropTypes.shape({
        value: PropTypes.any,
        id: PropTypes.string,
        as: PropTypes.oneOf(['input', 'textarea']),
        name: PropTypes.string,
        placeholder: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        isOptional: PropTypes.bool,
        validate: PropTypes.func,
      }),
    }),
  ).isRequired,
  input: PropTypes.object,
  radioProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string,
  disabled: PropTypes.bool,
  labelProps: PropTypes.object,
  vertical: PropTypes.bool,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

RadioGroupAdditionalOptions.defaultProps = {
  radioProps: {},
  meta: {},
  input: {
    value: '',
  },
  labelProps: {},
};

export default RadioGroupAdditionalOptions;
