import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Tenants = (props) => (
  <svg {...props} viewBox="0 0 40 38" fill={props.color}>
    <path d="M6.072 17.868c.64 0 1.157.507 1.157 1.132v17.868c0 .625-.518 1.132-1.157 1.132a1.145 1.145 0 01-1.156-1.132V19c0-.625.518-1.132 1.156-1.132zm27.856 0c.638 0 1.156.507 1.156 1.132v17.868c0 .625-.518 1.132-1.156 1.132a1.145 1.145 0 01-1.157-1.132V19c0-.625.518-1.132 1.157-1.132zm-16.386 3.247c.639 0 1.157.507 1.157 1.132v4.876c-.002 1.692-.468 2.917-1.639 3.372v6.371c0 .626-.518 1.132-1.156 1.132h-4.916a1.145 1.145 0 01-1.157-1.132v-6.37c-1.172-.456-1.638-1.682-1.638-3.374v-4.875c0-.625.518-1.132 1.156-1.132h8.193zm13.109 0c.638 0 1.156.507 1.156 1.132v4.876c-.002 1.692-.468 2.917-1.638 3.372v6.371c0 .626-.518 1.132-1.157 1.132h-4.916a1.145 1.145 0 01-1.156-1.132v-6.37c-1.173-.456-1.639-1.682-1.639-3.374v-4.875c0-.625.518-1.132 1.157-1.132h8.193zm-14.265 2.264h-5.88v3.743c0 1.082.121 1.304.482 1.304.639 0 1.157.507 1.157 1.132v6.176h2.602v-6.176c0-.625.518-1.132 1.157-1.132.358 0 .48-.224.482-1.304v-3.743zm13.108 0h-5.88v3.743c0 1.082.122 1.304.482 1.304.64 0 1.157.507 1.157 1.132v6.176h2.602v-6.176c0-.625.518-1.132 1.157-1.132.359 0 .48-.224.482-1.304v-3.743zM13.446 12.995c1.999 0 3.614 1.601 3.614 3.568s-1.615 3.57-3.614 3.57c-1.999 0-3.615-1.603-3.615-3.57 0-1.967 1.616-3.568 3.615-3.568zm13.108 0c1.999 0 3.615 1.601 3.615 3.568s-1.616 3.57-3.615 3.57-3.614-1.603-3.614-3.57c0-1.967 1.615-3.568 3.614-3.568zM20.785.3L39.63 17.357c.469.424.497 1.14.064 1.6a1.174 1.174 0 01-1.635.062L20 2.674 1.942 19.02c-.47.425-1.2.397-1.635-.063a1.115 1.115 0 01.064-1.6L19.215.302a1.175 1.175 0 011.57 0zm-7.34 14.958a1.304 1.304 0 000 2.609 1.304 1.304 0 000-2.609zm13.11 0a1.304 1.304 0 000 2.609 1.304 1.304 0 000-2.609z" />
  </svg>
);

Tenants.defaultProps = {
  width: 40,
  height: 38,
  color: colors.text,
};

Tenants.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Tenants);
