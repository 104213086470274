import isSinglePropertyType from '../../../../../helpers/isSinglePropertyType';
import PropertyRolesEnum from '../../../../../helpers/propertyRolesEnum';

export const calculatePropertyRole = ({ property_type, room_name }) => {
  const isSingleProperty = isSinglePropertyType(property_type);

  if (isSingleProperty) {
    if (room_name) {
      return PropertyRolesEnum.ROOM;
    }

    return PropertyRolesEnum.SINGLE_UNIT;
  } else {
    if (room_name) {
      return PropertyRolesEnum.ROOM;
    }

    return PropertyRolesEnum.UNIT;
  }
};
