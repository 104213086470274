import React, { memo } from 'react';
import PropTypes from 'prop-types';

const DrivingLicense = ({ color, ...props }) => (
  <svg viewBox="0 0 24 18" {...props} width="24" height="18">
    <path
      fill={color}
      fillRule="evenodd"
      d="M20.892.667C22.61.667 24 2.096 24 3.863v10.94C24 16.57 22.612 18 20.892 18H3.108C1.39 18 0 16.571 0 14.804V3.863C0 2.097 1.388.667 3.108.667Zm0 1.52H3.108c-.9 0-1.627.748-1.627 1.676v10.94c0 .929.728 1.677 1.627 1.677h17.784c.9 0 1.627-.749 1.627-1.676V3.863c0-.928-.728-1.676-1.627-1.676ZM7.852 4.316c1.718 0 3.111 1.43 3.111 3.193a3.23 3.23 0 0 1-.777 2.11c1.637.694 2.555 2.159 2.555 3.972a.75.75 0 0 1-.741.76H3.704a.75.75 0 0 1-.741-.76c0-1.814.918-3.279 2.555-3.973a3.22 3.22 0 0 1-.777-2.11c0-1.762 1.392-3.192 3.11-3.192Zm0 6.386c-1.824 0-2.924.77-3.28 1.966l-.045.162h6.648l-.043-.162c-.339-1.14-1.354-1.892-3.025-1.961Zm11.852.304c.409 0 .74.34.74.76a.754.754 0 0 1-.63.752l-.11.008H14.37a.75.75 0 0 1-.74-.76c0-.382.274-.698.63-.752l.11-.008h5.334Zm0-2.433c.409 0 .74.34.74.76a.754.754 0 0 1-.63.752l-.11.009H14.37a.75.75 0 0 1-.74-.76c0-.382.274-.698.63-.753l.11-.008h5.334ZM7.852 5.836c-.9 0-1.63.75-1.63 1.673s.73 1.672 1.63 1.672c.9 0 1.63-.749 1.63-1.672 0-.924-.73-1.673-1.63-1.673Zm8.889.304c.409 0 .74.34.74.76a.754.754 0 0 1-.63.753l-.11.008h-2.37a.75.75 0 0 1-.741-.76c0-.382.274-.698.63-.752l.11-.009h2.37Z"
    />
  </svg>
);

DrivingLicense.defaultProps = {
  width: 24,
  height: 18,
  color: 'currentColor',
};

DrivingLicense.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(DrivingLicense);
