import React from 'react';
import PropTypes from 'prop-types';

import NumberedList from '../../../../components/NumberedList';
import SidebarMarketing from '../../../../components/SidebarMarketing';
import colors from '../../../../constants/colors';
import openInNewTab from '../../../../helpers/openInNewTab';
import PlayIcon from '../../../../icons/PlayIcon';

import thumbnail from './how-to-set-up-online-applications-in-turboTenant.jpg';

import styles from './InviteRenterHowItWorks.module.scss';

const InviteRenterHowItWorks = ({
  onPlayVideo,
  onViewAppClicked,
  listItems,
  isPublicFlow,
}) => (
  <SidebarMarketing
    className={styles.marketing}
    title="RENTAL APPLICATION & SCREENING"
    subtitle="How it Works"
    description={
      <>
        <div className={styles.howItWorks}>
          <NumberedList
            className={styles.stepsList}
            variant="bold"
            size="medium"
            list={listItems}
          />
          {!isPublicFlow && (
            <a
              className={styles.link}
              onClick={() => {
                openInNewTab(
                  `/applications/demo?utm_source=rental_app_onboarding`,
                );
                onViewAppClicked();
              }}
            >
              Try the application yourself {'>>'}
            </a>
          )}
        </div>
        <div className={styles.videoThumbWrap}>
          <button className={styles.playVideo} onClick={onPlayVideo}>
            <img
              className={styles.thumb}
              src={thumbnail}
              alt="Video how Rental Application & Screening works"
            />
            <div className={styles.playIcon}>
              <PlayIcon bgColor={colors.info} width="68px" height="68px" />
            </div>
          </button>
        </div>
      </>
    }
  />
);

InviteRenterHowItWorks.propTypes = {
  onPlayVideo: PropTypes.func,
  onViewAppClicked: PropTypes.func,
  listItems: PropTypes.array,
  isPublicFlow: PropTypes.bool,
};

export default InviteRenterHowItWorks;
