import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const DollarDocumentColor = (props) => (
  <svg {...props} viewBox="0 0 29 40">
    <g fill="none" fillRule="evenodd">
      <path fill={props.color} d="m19 2 9 9h-9z" />
      <path
        d="M18.528 0a.781.781 0 0 1 .57.244l9.666 10 .012.013a.831.831 0 0 1 .061.074l-.073-.087a.827.827 0 0 1 .146.206l.036.082a.846.846 0 0 1 .042.156l.003.021c.006.04.009.082.009.124v-.037 28.371c0 .46-.36.833-.806.833H.806C.36 40 0 39.627 0 39.167V.833C0 .373.36 0 .806 0Zm-.806 1.666H1.612v36.667h25.777l-.001-26.667h-8.86a.816.816 0 0 1-.8-.728l-.006-.105V1.666Zm5.64 31.667c.444 0 .805.373.805.834 0 .46-.361.833-.806.833H5.773c-.445 0-.806-.373-.806-.833 0-.46.36-.834.806-.834Zm0-3.333c.444 0 .805.373.805.833 0 .46-.361.834-.806.834H5.773c-.445 0-.806-.373-.806-.834 0-.46.36-.833.806-.833Zm0-3.333c.444 0 .805.373.805.833 0 .46-.361.833-.806.833H5.773c-.445 0-.806-.373-.806-.833 0-.46.36-.833.806-.833Zm-.002-3.334c.445 0 .806.373.806.834 0 .46-.36.833-.806.833h-8.03c-.444 0-.805-.373-.805-.833 0-.46.36-.834.806-.834ZM9.667 6.668c.445 0 .805.373.805.833v1.137c1.558.377 2.719 1.824 2.719 3.549 0 .46-.36.833-.806.833-.444 0-.805-.373-.805-.833 0-1.092-.858-1.979-1.914-1.979-1.055 0-1.913.887-1.913 1.979 0 1.094.857 1.98 1.913 1.98 1.946 0 3.525 1.634 3.525 3.645 0 1.727-1.16 3.172-2.718 3.549v1.138c0 .46-.361.834-.806.834-.445 0-.806-.374-.806-.834v-1.138c-1.558-.376-2.719-1.822-2.719-3.55 0-.46.361-.833.806-.833.445 0 .805.373.805.834 0 1.093.857 1.978 1.913 1.978 1.058 0 1.914-.885 1.914-1.978 0-1.09-.858-1.979-1.914-1.979-1.946 0-3.524-1.632-3.524-3.646 0-1.726 1.162-3.173 2.72-3.55V7.501c0-.46.36-.833.805-.833ZM23.36 20c.445 0 .806.373.806.833 0 .46-.361.834-.806.834h-4.833c-.445 0-.806-.373-.806-.834 0-.46.36-.833.806-.833Zm0-3.333c.445 0 .806.373.806.833 0 .46-.361.833-.806.833h-4.833c-.445 0-.806-.373-.806-.833 0-.46.36-.833.806-.833ZM19.333 2.844V10h6.916l-6.916-7.156Z"
        fill="#042238"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

DollarDocumentColor.defaultProps = {
  width: 20,
  height: 24,
  color: colors.babyBlue,
};

DollarDocumentColor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(DollarDocumentColor);
