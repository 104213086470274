import React from 'react';
import cx from 'classnames';

import Card from '../Card';

const OptionalCard = ({ withCard, className, children }) => {
  if (withCard) {
    return (
      <Card className={cx({ [className]: className != null })}>{children}</Card>
    );
  }

  return <>{children}</>;
};

export default OptionalCard;
