import React from 'react';
import { Field, FormSpy, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../components/Condition';
import HelpfulInfoCard from '../../../../../components/HelpfulInfo';
import RadioGroup from '../../../../../components/RadioGroup';
import Tag from '../../../../../components/Tag';
import SMOKING_POLICY from '../../../../../constants/lease_agreement/smoking-policy';
import { PETS_LIMIT } from '../../../../../constants/lease_agreement/wizard-limitations';
import { isBoolean } from '../../../../../helpers/isBoolean';
import TurboTenantSmallLogo from '../../../../../icons/TurboTenantSmallLogo';
import { useConfig } from '../../../../../providers/ConfigProvider';
import fieldRequiredStandard from '../../../../../validators/fieldRequired';
import SectionInfoBlock from '../../../common/SectionInfoBlock';
import ParkingForm from '../ParkingForm';
import PetsForm from '../PetsForm';
import { SectionPetsSmokingOtherInsuranceSection } from '../SectionPetsSmokingOtherInsuranceSection';
import { SectionPetsSmokingOtherParkingSection } from '../SectionPetsSmokingOtherParkingSection';
import { SectionPetsSmokingOtherPetsSection } from '../SectionPetsSmokingOtherPetsSection';
import { SectionPetsSmokingOtherSmokingSection } from '../SectionPetsSmokingOtherSmokingSection';
import TexasParkingInfo from '../state_specific/TX/ParkingInfo';

import styles from './SectionPetsSmokingOtherFields.module.scss';

const noop = () => {};

const SectionPetsSmokingOtherFields = ({
  currentState,
  setPristine,
  standAloneWizard,
}) => {
  const {
    input: { value: petsChoice },
  } = useField('pets_choice');
  const {
    input: { value: requireRentersInsurance },
  } = useField('require_renters_insurance');
  const {
    input: { value: parkingTypes },
  } = useField('custom_state_data.parking_types');
  const {
    input: { value: hasParkingRules },
  } = useField('custom_state_data.has_parking_rules');

  const fieldRequired = standAloneWizard ? noop : fieldRequiredStandard;

  const { INSURANCE_PRICE } = useConfig();
  return (
    <>
      <FormSpy
        subscription={{ pristine: true, values: true }}
        onChange={({ pristine }) => {
          setPristine(pristine);
        }}
      />
      <SectionPetsSmokingOtherPetsSection>
        <div className={styles.column}>
          <Field
            component={RadioGroup}
            id="pets_choice"
            name="pets_choice"
            radiosContainerClassName={styles.radioGroupPets}
            radioRowClassName={styles.radioRowHorizontal}
            options={[
              {
                value: true,
                label: 'Yes',
              },
              {
                value: false,
                label: 'No',
              },
            ]}
            validate={fieldRequired}
          />
          {petsChoice && (
            <Field
              component={PetsForm}
              name="pets"
              id="pets"
              limit={PETS_LIMIT}
              standAloneWizard={standAloneWizard}
            />
          )}
        </div>
      </SectionPetsSmokingOtherPetsSection>

      <SectionPetsSmokingOtherSmokingSection>
        <div className={styles.column}>
          <Field
            component={RadioGroup}
            id="smoking_policy"
            name="smoking_policy"
            radiosContainerClassName={styles.radioGroupSmoking}
            radioRowClassName={styles.radioButtonSmoking}
            labelProps={{
              className: styles.radioButtonHorizontal,
            }}
            options={[
              {
                'id': 'lease-agreements-smoking-policy-radio-yes',
                'value': SMOKING_POLICY.YES,
                'label': 'Yes',
                'data-qa': 'lease-agreements-smoking-policy-radio-yes',
              },
              {
                'id': 'lease-agreements-smoking-policy-radio-no',
                'value': SMOKING_POLICY.NO,
                'label': 'No',
                'data-qa': 'lease-agreements-smoking-policy-radio-no',
              },
              {
                'id': 'lease-agreements-smoking-policy-radio-outside-only',
                'value': SMOKING_POLICY.OUTSIDE_ONLY,
                'label': 'Outside Only',
                'data-qa': 'lease-agreements-smoking-policy-radio-outside-only',
              },
            ]}
            validate={fieldRequired}
          />
        </div>
        {currentState === 'OR' && (
          <SectionInfoBlock
            className={cx(styles.infoBlock, styles.oregonSmoking)}
          >
            <strong>Smoking Policy:</strong> Oregon law requires a smoking
            policy be stated in the lease agreement.
          </SectionInfoBlock>
        )}
      </SectionPetsSmokingOtherSmokingSection>

      <SectionPetsSmokingOtherParkingSection>
        <div className={styles.column}>
          <Field
            component={RadioGroup}
            id="custom_state_data.has_parking_rules"
            name="custom_state_data.has_parking_rules"
            radiosContainerClassName={styles.radioGroupParking}
            radioRowClassName={styles.radioButtonSmoking}
            labelProps={{
              className: styles.radioButtonHorizontal,
            }}
            options={[
              {
                'id': 'lease-agreements-parking-policy-radio-yes',
                'value': true,
                'label': 'Yes',
                'data-qa': 'lease-agreements-parking-policy-radio-yes',
              },
              {
                'id': 'lease-agreements-parking-policy-radio-no',
                'value': false,
                'label': 'No',
                'data-qa': 'lease-agreements-parking-policy-radio-no',
              },
            ]}
            validate={fieldRequired}
          />
          {currentState === 'TX' && <TexasParkingInfo />}
          {hasParkingRules && (
            <ParkingForm
              parkingTypes={parkingTypes || []}
              standAloneWizard={standAloneWizard}
            />
          )}
        </div>
      </SectionPetsSmokingOtherParkingSection>

      <SectionPetsSmokingOtherInsuranceSection>
        <div className={styles.column}>
          <label className={styles.headerLabel}>
            Do you require renter's insurance?
          </label>
          <p className={styles.description}>
            Requiring renter insurance could save you and your tenants
            thousands. That's why 72% of TurboTenant landlords require it.{' '}
            <a
              href="https://www.turbotenant.com/blog/renters-insurance-the-benefits-for-landlords/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Learn what it could mean for you if you don't.
            </a>
          </p>
          <Field
            component={RadioGroup}
            id="require_renters_insurance"
            name="require_renters_insurance"
            radiosContainerClassName={styles.radioGroupInsurance}
            labelProps={{
              className: styles.radioButtonInsurance,
            }}
            options={[
              {
                'id': 'lease-agreements-smoking-policy-radio-yes',
                'value': true,
                'label': (
                  <>
                    Yes{' '}
                    <Tag small className={styles.recommendedTag}>
                      Recommended
                    </Tag>
                  </>
                ),
                'data-qa': 'lease-agreements-renters-insurance-radio-yes',
              },
              {
                'id': 'lease-agreements-smoking-policy-radio-no',
                'value': false,
                'label': 'No',
                'data-qa': 'lease-agreements-renters-insurance-radio-no',
              },
            ]}
            validate={fieldRequired}
          />
          {isBoolean(requireRentersInsurance) && (
            <HelpfulInfoCard
              Icon={TurboTenantSmallLogo}
              iconProps={{ className: styles.ttLogoIcon }}
              className={cx(styles.helpfulInfoCard, {
                [styles.helpfulInfoCardRed]: !requireRentersInsurance,
              })}
              noTitle
            >
              <Condition when="require_renters_insurance" is={true}>
                <>
                  We'll automatically ask your tenants to upload their own
                  insurance policy or use our preferred partner <i>Sure</i>. If
                  they use <i>Sure</i>, our direct integration makes it easy for
                  you to see if the policy is active, the coverage amount, and
                  more, all within TurboTenant!{' '}
                  <a
                    href="https://support.turbotenant.com/en/articles/6467678-benefits-of-sure-renter-s-insurance"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                  >
                    Learn about all the benefits of <i>Sure</i>.
                  </a>
                </>
              </Condition>
              <Condition when="require_renters_insurance" is={false}>
                Are you sure? This could increase your risk for claims on your
                landlord insurance policy for theft, damage and injury. Though
                affordable at about ${INSURANCE_PRICE}
                /month, most tenants do not purchase insurance if not required.
              </Condition>
            </HelpfulInfoCard>
          )}
        </div>
      </SectionPetsSmokingOtherInsuranceSection>
    </>
  );
};

SectionPetsSmokingOtherFields.propTypes = {
  currentState: PropTypes.string.isRequired,
  setPristine: PropTypes.func,
  standAloneWizard: PropTypes.bool,
};

SectionPetsSmokingOtherFields.defaultProps = {
  setPristine: () => {},
  standAloneWizard: false,
};

export default SectionPetsSmokingOtherFields;
