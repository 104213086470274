import { useEffect } from 'react';
import PropTypes from 'prop-types';

const UnsavedChangesPrompt = ({ when }) => {
  useEffect(() => {
    if (when) {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        return (event.returnValue = '');
      };
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [when]);

  return null;
};

UnsavedChangesPrompt.propTypes = {
  when: PropTypes.bool,
};

export default UnsavedChangesPrompt;
