import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './SectionTitle.module.scss';

const SectionTitle = ({ className, icon: Icon, title, iconProps }) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.iconContainer}>
      <Icon {...iconProps} />
    </div>
    <h4 className={styles.title}>{title}</h4>
  </div>
);

SectionTitle.propTypes = {
  className: PropTypes.string,
  iconProps: PropTypes.object,
  title: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.object]),
};

export default SectionTitle;
