const scoring = [
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth',
  'Seventh',
  'Eighth',
  'Ninth',
];

export const getPetAccountName = (key) =>
  scoring[key] || 'Need to add name of count';
