import ElectricityIcon from '../../../icons/Electricity';
import GasIcon from '../../../icons/FireCircled';
import InternetThick from '../../../icons/InternetThick';
import LawnIcon from '../../../icons/Lawn';
import PhoneThick from '../../../icons/PhoneThick';
import CondoFeeIcon from '../../../icons/RealEstateHouseDollarThick';
import TrashIcon from '../../../icons/Remove';
import SewerIcon from '../../../icons/Sewer';
import SnowIcon from '../../../icons/Snow';
import TVThick from '../../../icons/TVThick';
import WaterIcon from '../../../icons/WaterThick';

export const UTILITY_LABELS = {
  ELECTRICITY: { label: 'Electricity', Icon: ElectricityIcon },
  INTERNET: { label: 'Internet', Icon: InternetThick },
  PHONE: { label: 'Phone', Icon: PhoneThick },
  CABLE_SATELLITE: { label: 'Cable / Satellite', Icon: TVThick },
  GAS: { label: 'Gas', Icon: GasIcon },
  WATER: { label: 'Water', Icon: WaterIcon },
  SEWER_SEPTIC: { label: 'Sewer / Septic', Icon: SewerIcon },
  TRASH: { label: 'Trash', Icon: TrashIcon },
  LAWN_CARE: { label: 'Lawn Care', Icon: LawnIcon },
  SNOW_REMOVAL: { label: 'Snow Removal', Icon: SnowIcon },
  HOA_CONDO_FEE: { label: 'HOA / Condo Fee', Icon: CondoFeeIcon },
};

export const UTILITY_WHO_LABELS = {
  TENANT: 'Tenant',
  LANDLORD: 'Landlord',
  NOT_APPLICABLE: 'N/A',
};
