import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import HabitabilityIcon from '../../../../../../../icons/Habitability';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './UninhabitableSpaceDisclosureWA.module.scss';

const UninhabitableSpaceDisclosureWA = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle
        title="Uninhabitable Space Disclosure"
        icon={HabitabilityIcon}
      />
      <label className={styles.label}>
        Are there any uninhabitable spaces on the premises? This would include
        the attic, garage, crawl space, etc., which are not permitted for
        occupancy.
      </label>
      <p className={styles.description}>
        Tacoma, Washington ordinance requires that you disclose any
        uninhabitable spaces on the premises.
      </p>
      <Field
        component={RadioGroup}
        label="Are there any uninhabitable spaces on the premises?"
        id="custom_state_data.has_uninhabitable_space"
        name="custom_state_data.has_uninhabitable_space"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'has_uninhabitable_space_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'has_uninhabitable_space_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition when="custom_state_data.has_uninhabitable_space" is={true}>
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.has_uninhabitable_space_explanation"
          name="custom_state_data.has_uninhabitable_space_explanation"
          label="Please list the uninhabitable spaces on the premises that tenant is not permitted to occupy:"
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={500}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 500 characters used`}
        />
      </Condition>
    </div>
  );
};

UninhabitableSpaceDisclosureWA.propTypes = {
  className: PropTypes.string,
};

export default UninhabitableSpaceDisclosureWA;
