import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const ErrorCircleFilled = (props) => (
  <svg {...props} viewBox="0 0 28 28">
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M14 .607c7.333 0 13.333 6 13.333 13.334 0 7.333-6 13.333-13.333 13.333S.667 21.274.667 13.941C.667 6.607 6.667.607 14 .607ZM9.385 7.274 7.333 9.325l4.615 4.616-4.615 4.615 2.052 2.051L14 15.991l4.616 4.616 2.05-2.05-4.615-4.616 4.616-4.616-2.052-2.05L14 11.888 9.385 7.274Z"
      clipRule="evenodd"
    />
  </svg>
);

ErrorCircleFilled.defaultProps = {
  width: 28,
  height: 28,
  color: colors.greyLight,
};

ErrorCircleFilled.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ErrorCircleFilled);
