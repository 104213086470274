import React from 'react';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';

import styles from './LeaseRenewalsMD.module.scss';

const LeaseRenewalsMD = () => {
  return (
    <HelpfulInfoCard
      noIcon
      title="Montgomery County, MD Lease Renewals, Rent Increases"
      className={styles.helpfulInfoCard}
      color="iceCold"
    >
      <p className={styles.description}>
        Initial lease terms and renewals must be at least 2 years in duration.
        If the term is less than 2 years, you must give a reasonable
        explanation, such as the property is up for sale or there will be an
        intent to sell within the year.
        <br />
        There is a 90 day notice requirement for any rent increases or lease
        renewals. 60 days notice is required for any non-renewal of lease.
      </p>
    </HelpfulInfoCard>
  );
};

export default LeaseRenewalsMD;
