import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from './BankInfo.module.scss';

const BankInfo = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.row}>
      <HelpfulInfoCard
        Icon={ResourcesIcon}
        className={styles.helpfulInfoCard}
        title="Alabama Security Deposit Laws"
        color="iceCold"
        collapsable
      >
        <p>
          Though they're included in the lease agreement, we've highlighted some
          rules you need to know:
        </p>
        <HelpfulInfoList>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            The maximum allowed security deposit in Alabama is equal to the
            monthly rent. This rule does not apply to other types of deposits,
            such as a pet deposit.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            You must return the security deposit within 60 days of the tenant
            moving out.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            If there are deductions from the security deposit, they must be
            clearly itemized.
          </HelpfulInfoListItem>
          <div className={styles.learnMore}>
            <a
              href="https://support.turbotenant.com/en/articles/5599141-alabama-lease-agreement#h_a1879c7f8d"
              target="_blank noopener noreferrer"
            >
              Learn other security deposit laws
            </a>
          </div>
        </HelpfulInfoList>
      </HelpfulInfoCard>
    </div>
  </div>
);

BankInfo.propTypes = {
  formValues: PropTypes.object,
  className: PropTypes.string,
};

export default BankInfo;
