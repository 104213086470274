/**
 * @param {*} err
 * @param {[key: number | string]: func} mapper
 * @returns {boolean}
 */

const handleGraphQLErrors = (err, mapper) => {
  if (!err?.graphQLErrors?.length) return false;
  const errorHandled = (err?.graphQLErrors || []).some((error) => {
    const mapperFn = mapper[error?.code];
    if (mapperFn && typeof mapperFn === 'function') {
      mapperFn(error);
      return true;
    }
    return false;
  });

  if (errorHandled) {
    return true;
  }

  if (mapper?.default && typeof mapper.default === 'function') {
    mapper.default();
    return true;
  }

  return false;
};

export default handleGraphQLErrors;
