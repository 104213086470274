import { withRouter } from 'react-router-dom';
import { useSubscription } from '@apollo/client';

// import { toast } from 'sonner';
import userEventSubscriptionGQL from '../graphql/subscriptions/userEventSubscription.graphql';
import { useGlobalBanner } from '../providers/GlobalBannerProvider';
import { getConfig } from '../services/configService';

import { getCurrentToken } from './auth/authService';

const PUBSUB_EVENTS = {
  NEW_APPLICANT: 'NEW_APPLICANT',
  UPDATED_APPLICANT: 'UPDATED_APPLICANT',
  SCREENING_REPORT_UPDATED: 'SCREENING_REPORT_UPDATED',
  NEW_LEAD: 'NEW_LEAD',
  NEW_MAINTENANCE_REQUEST: 'NEW_MAINTENANCE_REQUEST',
  NEW_LULA_ACTIVITY: 'NEW_LULA_ACTIVITY',
  GLOBAL_BANNER_UPDATED: 'GLOBAL_BANNER_UPDATED',
};

const evictUserDataField = (client, fieldName) => {
  const cache = client?.cache;
  const data = cache?.data?.data;
  if (!data || !cache) return false;
  // TODO here we should consider moving items in userData to their own queries,
  // this would simplify this code
  const userDataKey = Object.keys(data).find((key) =>
    key.match(/UserData\..+$/),
  );
  if (!userDataKey || !client?.cache) return false;
  return client?.cache.evict({
    id: userDataKey,
    fieldName,
  });
};

const evictNodeFieldsFromCache = (client, nodeType, id, fields = []) => {
  if (!id || !client?.cache || !client.refetchQueries) return false;
  client.refetchQueries({
    updateCache(cache) {
      if (!fields || fields.length === 0) {
        cache.evict({
          id: `${nodeType}.${btoa(`${nodeType}:${id}`)}`,
        });
      } else {
        fields.forEach((field) => {
          // TODO can probably be reduced to one call with a list of fields instead
          cache.evict({
            id: `${nodeType}.${btoa(`${nodeType}:${id}`)}`,
            fieldName: field,
          });
        });
      }
    },
  });
};

const UserEventSubscriptionHandler = () => {
  const { refetchBanner: refetchGlobalBanner } = useGlobalBanner();
  useSubscription(userEventSubscriptionGQL, {
    variables: {},
    skip:
      !getCurrentToken() ||
      getConfig().USER_TYPE !== 'OWNER' ||
      getConfig().WS_URL === 'false',
    onData: (result) => {
      const eventType = result?.data?.data?.appStateUpdate?.event;
      const eventPayload = result?.data?.data?.appStateUpdate?.eventPayload;
      switch (eventType) {
        case PUBSUB_EVENTS.NEW_LEAD:
          // toast('New Lead!');
          evictUserDataField(result.client, 'leads');
          evictUserDataField(result.client, 'basicProfile');
          break;
        case PUBSUB_EVENTS.NEW_APPLICANT:
          // toast('New Applicant!');
          evictUserDataField(result.client, 'basicProfile');
          evictUserDataField(result.client, 'applicants');
          break;
        case PUBSUB_EVENTS.NEW_MAINTENANCE_REQUEST:
          // toast('New Maintenance Request!');
          evictUserDataField(result.client, 'basicProfile');
          evictUserDataField(result.client, 'maintenance_requests');
          evictNodeFieldsFromCache(
            result.client,
            'Listing',
            eventPayload?.listingId,
            [],
          );
          break;
        case PUBSUB_EVENTS.NEW_LULA_ACTIVITY:
          // toast('New Lula Activity!');
          evictNodeFieldsFromCache(
            result.client,
            'MaintenanceRequest',
            eventPayload?.maintenanceRequestId,
            ['last_activity', 'status', 'maintenance_activities'],
          );
          break;
        case PUBSUB_EVENTS.SCREENING_REPORT_UPDATED:
          // toast('Report Updated!');
          evictNodeFieldsFromCache(
            result.client,
            'RentalRequest',
            eventPayload?.rentalRequestId,
            ['credit_check'],
          );
          break;
        case PUBSUB_EVENTS.GLOBAL_BANNER_UPDATED:
          refetchGlobalBanner();
          break;
        default:
      }
    },
  });
  return null;
};

export default withRouter(UserEventSubscriptionHandler);
