import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useConfig } from '../../../../providers/ConfigProvider';
import { getBackgroundCheckTitle } from '../../../../services/utilities';

import { questions } from './questions';

import styles from './SelfReportedBg.module.scss';

const SelfReportedBg = ({ id, application, partnersApplication }) => {
  const { IS_RENTER } = useConfig();
  const {
    evicted,
    evicted_description,
    civil_action,
    civil_action_description,
    conviction,
    conviction_description,
    evicted_bkg_question_enabled,
    conviction_bkg_question_enabled,
    civil_action_bkg_question_enabled,
  } = application;

  if (
    !evicted_bkg_question_enabled &&
    !conviction_bkg_question_enabled &&
    !civil_action_bkg_question_enabled
  ) {
    return null;
  }

  return (
    <React.Fragment>
      {IS_RENTER ? (
        <p></p>
      ) : (
        <p className={styles.title}>
          Make sure you verify their responses to these questions with
          TurboTenant’s extensive screening report.
          {!partnersApplication && (
            <>
              You may choose to{' '}
              <Link className={styles.link} to="/owners/settings/application">
                not ask these background questions
              </Link>{' '}
              on the application if needed.
            </>
          )}
        </p>
      )}
      <article id={id} className={styles.container}>
        {evicted_bkg_question_enabled && (
          <>
            <div className={styles.question}>{questions.evicted}</div>
            <div className={styles.answer}>
              <div
                className={cx(styles.defaultText, {
                  [styles.yesTitle]: evicted,
                })}
              >
                {getBackgroundCheckTitle(evicted)}
              </div>
              {evicted && (
                <div className={styles.pleaseExplainBlock}>
                  <p className={styles.explainTitle}>Please Explain</p>
                  <span>{evicted_description}</span>
                </div>
              )}
            </div>
          </>
        )}
        {application?.applying_as_tenant && conviction_bkg_question_enabled && (
          <>
            <div className={styles.question}>{questions.conviction}</div>
            <div className={styles.answer}>
              <div
                className={cx(styles.defaultText, {
                  [styles.yesTitle]: conviction,
                })}
              >
                {getBackgroundCheckTitle(conviction)}
              </div>
              {conviction && (
                <div className={styles.pleaseExplainBlock}>
                  <p className={styles.explainTitle}>Please Explain</p>
                  <span>{conviction_description}</span>
                </div>
              )}
            </div>
          </>
        )}
        {civil_action_bkg_question_enabled && (
          <>
            <div className={styles.question}>{questions.civil_action}</div>
            <div className={styles.answer}>
              <div
                className={cx(styles.defaultText, {
                  [styles.yesTitle]: civil_action,
                })}
              >
                {getBackgroundCheckTitle(civil_action)}
              </div>
              {civil_action && (
                <div className={styles.pleaseExplainBlock}>
                  <p className={styles.explainTitle}>Please Explain</p>
                  <span>{civil_action_description}</span>
                </div>
              )}
            </div>
          </>
        )}
      </article>
    </React.Fragment>
  );
};

SelfReportedBg.propTypes = {
  id: PropTypes.string,
  info: PropTypes.object,
  application: PropTypes.object,
  partnersApplication: PropTypes.bool,
};

export default SelfReportedBg;
