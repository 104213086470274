import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Card from '../Card';
import Modal from '../Modal';

import AddEditTenantForm from './AddEditTenantForm';

import styles from './AddEditTenant.module.scss';

const AddEditTenant = ({
  className,
  containerClassName,
  open,
  onClose,
  initialValues,
  onSubmit,
  onCancelButtonClick,
  inUseValidators,
  isEdit,
  submitLabel,
  title,
  description,
  card,
  submitBtnId,
}) => {
  const Element = card ? Card : Modal;

  let titleToPrint = title;
  if (!title && isEdit) {
    titleToPrint = 'Edit Tenant Information';
  } else if (!title && !isEdit) {
    titleToPrint = 'Add New Tenant';
  }

  let descriptionToPrint = description;
  if (!description && description !== false && isEdit) {
    descriptionToPrint =
      'You can edit this tenant’s info since they have not set up their account yet.';
  } else if (!description && description !== false && !isEdit) {
    descriptionToPrint = `We’ll invite them to set up their tenant portal so they can send you
          messages, pay rent, and request maintenance.`;
  }

  return (
    <Element
      open={open}
      className={cx(styles.modal, { [styles.card]: card }, className)}
      containerClassName={cx(styles.modalContainer, containerClassName)}
      onClose={onClose}
    >
      <div className="headerContainer">
        <h2 className={styles.title} id="add-new-tenant">
          {titleToPrint}
        </h2>
        <p className={styles.text}>{descriptionToPrint}</p>
      </div>
      <AddEditTenantForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        onCancelButtonClick={onCancelButtonClick}
        inUseValidators={inUseValidators}
        isEdit={isEdit}
        submitLabel={submitLabel}
        submitBtnId={submitBtnId}
      />
    </Element>
  );
};

AddEditTenant.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancelButtonClick: PropTypes.func,
  isEdit: PropTypes.bool,
  inUseValidators: PropTypes.object,
  submitLabel: PropTypes.string,
  card: PropTypes.bool,
  submitBtnId: PropTypes.string,
};

export default AddEditTenant;
