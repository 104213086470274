import React, { useState } from 'react';
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import getFieldError from '../../validators/get-field-error';
import Label from '../Label';

import Content from './components/Content';
import Item from './components/Item';
import Trigger from './components/Trigger';
import { DropdownSubtextMenuContext } from './context';

import styles from './DropdownSubtextMenu.module.scss';

const DropdownSubtextMenu = ({
  id,
  disabled,
  input,
  label,
  className,
  meta,
  emptyLabel,
  onChange,
  children,
  options,
}) => {
  const error = getFieldError(meta);
  const [isOpen, setIsOpen] = useState(false);
  const defaultSelected =
    options?.find((opt) => opt?.value === input?.value)?.label || emptyLabel;
  const [selected, setSelected] = useState(defaultSelected);

  const handleSelect = (option) => {
    setSelected(option.label);
    setIsOpen(false);
    onChange ? onChange(option) : input.onChange(option.value);
  };

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(3),
      flip({ fallbackAxisSideDirection: 'end' }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const value = {
    isOpen,
    refs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
    handleSelect,
    selected,
    error,
    options,
  };

  return (
    <DropdownSubtextMenuContext.Provider value={value}>
      <div
        className={cx(styles.wrapper, styles.container, className, {
          [styles.disabled]: disabled,
          [styles.invalid]: !!error,
        })}
      >
        {label && <Label htmlFor={id}>{label}</Label>}
        {children}
        {error && <span className={styles.error}>{error}</span>}
      </div>
    </DropdownSubtextMenuContext.Provider>
  );
};

DropdownSubtextMenu.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      subtext: PropTypes.string,
      icon: PropTypes.elementType,
    }),
  ),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  input: PropTypes.object,
  className: PropTypes.string,
  meta: PropTypes.object,
  emptyLabel: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
};

DropdownSubtextMenu.defaultProps = {
  disabled: false,
  meta: {},
  emptyLabel: 'Select an option',
  options: [],
};

DropdownSubtextMenu.Content = Content;
DropdownSubtextMenu.Item = Item;
DropdownSubtextMenu.Trigger = Trigger;

export default DropdownSubtextMenu;
