import React, { memo } from 'react';

const RedfinLogo = (props) => (
  <svg {...props} viewBox="0 0 969 255">
    <g fill="#A02021">
      <path d="M498.483 49.76c-17.897-11.803-42.004-16.21-74.108-16.21h-60.717V234.63h61.991c22.075 0 41.045-2.861 56.897-9.199 15.852-6.338 28.398-16.81 36.854-31.689 8.455-14.879 12.875-34.207 12.875-58.843 0-21.288-2.689-38.699-8.24-52.72a67.383 67.383 0 0 0-25.552-32.418Zm-16.252 119.875c-2.99 10.014-7.783 17.683-15.222 23.005-8.169 5.722-20.516 8.913-35.766 8.913h-21.732V66.613h18.255c17.969 0 31.26 2.862 40.201 9.514 6.553 4.879 11.102 11.674 14.078 20.96 2.976 9.284 4.292 21.03 4.292 35.551s-1.116 26.953-4.106 36.997Z" />
      <path d="M498.483 49.76c-17.897-11.803-42.004-16.21-74.108-16.21h-60.717V234.63h61.991c22.075 0 41.045-2.861 56.897-9.199 15.852-6.338 28.398-16.81 36.854-31.689 8.455-14.879 12.875-34.207 12.875-58.843 0-21.288-2.689-38.699-8.24-52.72a67.383 67.383 0 0 0-25.552-32.418Zm-16.252 119.875c-2.99 10.014-7.783 17.683-15.222 23.005-8.169 5.722-20.516 8.913-35.766 8.913h-21.732V66.613h18.255c17.969 0 31.26 2.862 40.201 9.514 6.553 4.879 11.102 11.674 14.078 20.96 2.976 9.284 4.292 21.03 4.292 35.551s-1.116 26.953-4.106 36.997ZM561.046 234.557h45.91v-85.682h53.077v-32.747h-53.077V66.612l81.991.015V33.522H561.046v201.035ZM718.003 234.629h45.967V33.522h-45.967s-.343 201.107 0 201.107ZM966.006 33.522h-46.152v115.353L834.429 33.522h-36.811v201.035H843.8V116.156l84.509 118.401h37.697V33.522Z" />
      <path d="M561.046 234.557h45.91v-85.682h53.077v-32.747h-53.077V66.612l81.991.015V33.522H561.046v201.035ZM966.006 33.522h-46.152v115.353L834.429 33.522h-36.811v201.035H843.8V116.156l84.509 118.401h37.697V33.522ZM190.191 242.224c-3.848-1.545-7.725-4.506-18.112-21.989l-20.702-34.336-24.407-40.444-2.06-3.448a70.853 70.853 0 0 0 3.52-1.102c28.012-9.614 44.35-35.251 44.35-71.833a62.464 62.464 0 0 0-21.274-47.212 69.71 69.71 0 0 0-6.395-4.878C126.498 4.62 101.39 0 67.956 0H3.776C-.343 0-.73 2.332.887 4.464c1.431 1.917 3.105 4.463 3.82 10.915v201.852c-.715 8.212-2.389 10.83-3.82 12.733-1.63 2.131-.915 4.578 3.191 4.535h46.826v-85.625h21.273l14.95 25.394 18.742 31.947 9.199 15.537c11.603 19.542 21.775 30.372 41.99 32.676 2.947.375 5.914.566 8.885.572h3.118c7.282 0 14.307-2.861 18.799-4.793a38.623 38.623 0 0 0 1.774-.715c5.308-2.346 3.834-5.966.558-7.268ZM80.646 116.126H50.903V33.535h29.743c14.721 0 25.952 1.902 34.336 9.542a29.795 29.795 0 0 1 7.153 10.787 51.494 51.494 0 0 1 3.19 19.014c0 10.973-1.016 43.248-44.68 43.248ZM250.408 148.905h53.191v-32.748h-53.191v-49.63h81.947V33.551H204.398v201.035h127.986v-33.334h-81.976v-52.347Z" />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" transform="scale(3)" d="M0 0h322.002v85H0z" />
      </clipPath>
    </defs>
  </svg>
);

RedfinLogo.defaultProps = {
  width: '86px',
  height: '22px',
};

export default memo(RedfinLogo);
