import React from 'react';
import { LocaleUtils } from 'react-day-picker';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './YearMonthForm.module.scss';

const months = LocaleUtils.getMonths();

const YearMonthForm = ({ date, fromDate, toDate, onChange }) => {
  /**
   * NOTE: There is a bug caused by date time
   * when to and from dates are set to the current date
   * (which will include local time as well).
   * In some instances this can cause valid months to be
   * missing from the dropdown, which this aims to fix.
   */
  fromDate.setHours(0, 0, 0, 0);
  toDate.setHours(0, 0, 0, 0);

  const years = [];
  for (let i = fromDate.getFullYear(); i <= toDate.getFullYear(); i += 1) {
    years.push(i);
  }

  return (
    <div className={cx('DayPicker-Caption', styles.container)}>
      <select
        name="month"
        onChange={(e) => {
          onChange(new Date(date.getFullYear(), e.target.value));
        }}
        value={date.getMonth()}
        className={styles.select}
      >
        {months.map((month, i) => {
          const monthDate = new Date(date.getTime());
          monthDate.setMonth(i);
          monthDate.setDate(fromDate.getDate());

          if (i === 11 || monthDate.getTime() >= fromDate.getTime()) {
            return (
              <option key={month} value={i}>
                {month}
              </option>
            );
          }
          return null;
        })}
      </select>
      <select
        name="year"
        onChange={(e) => {
          onChange(new Date(e.target.value, date.getMonth()));
        }}
        value={date.getFullYear()}
        className={styles.select}
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

YearMonthForm.propTypes = {
  fromDate: PropTypes.any,
  toDate: PropTypes.any,
  date: PropTypes.any,
};

export default YearMonthForm;
