import React from 'react';
import { useField } from 'react-final-form';
import PropTypes from 'prop-types';

import { SUB_DIVISION_NAMES_KEYS } from '../../../../../../../constants/lease_agreement/sub-division-names';
import BedBugDisclosure from '../BedBugDisclosure';
import LeaseRider from '../LeaseRider';
import RentStabilizationDisclosure from '../RentStabilizationDisclosure';
import SprinklerDisclosure from '../SprinklerDisclosure';

import styles from './AdditionalProvisions.module.scss';

const AdditionalProvisions = ({
  handleRemoveAttachment,
  leaseRiderUploadFiles,
  attachmentTypes,
  leaseAgreementSelection,
  leaseRiderFilesFilter,
  bedBugsUploadFiles,
  bedBugsFilesFilter,
}) => {
  const {
    input: { value: rentStabilization },
  } = useField('custom_state_data.rent_stabilization');

  return (
    <>
      {leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.newYorkCity ? (
        <div className={styles.section}>
          <BedBugDisclosure
            handleRemoveAttachment={handleRemoveAttachment}
            uploadFiles={bedBugsUploadFiles}
            filesFilter={bedBugsFilesFilter}
            attachmentTypes={attachmentTypes}
          />
        </div>
      ) : null}
      <div className={styles.section}>
        <SprinklerDisclosure />
      </div>
      <div className={styles.section}>
        <RentStabilizationDisclosure />
        {leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.newYorkCity &&
        rentStabilization ? (
          <LeaseRider
            handleRemoveAttachment={handleRemoveAttachment}
            uploadFiles={leaseRiderUploadFiles}
            attachmentTypes={attachmentTypes}
            filesFilter={leaseRiderFilesFilter}
          />
        ) : null}
      </div>
    </>
  );
};

AdditionalProvisions.propTypes = {
  className: PropTypes.string,
};

export default AdditionalProvisions;
