import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { parse } from 'query-string';

import colors from '../../../constants/colors';
import getLandlordCountQueryGQL from '../../../graphql/queries/general_insights/getLandlordCount.graphql';
import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen';
import ChevronArrowDown from '../../../icons/ChevronArrowDown';
import Close from '../../../icons/Close';
import PremiumBadge from '../../../icons/PremiumBadge';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';
import Button from '../../Button';
import ComparisonChart from '../../ComparisonChart';
import FlatButton from '../../FlatButton';
import IconButton from '../../IconButton';
import Modal from '../../Modal';
import { comparisonRows, MODAL_LOCATIONS } from '../constants';
import getModalContent from '../get-premium-modal-content';
import getSegmentLocation from '../get-premium-modal-segment-location';
import PremiumTestimonial from '../PremiumTestimonial';

import SinglePaymentCard from './SinglePaymentCard';
import SubscriptionDetailsBlueCardAlt from './SubscriptionDetailsBlueCardAlt';

import styles from './PremiumSubscriptionModalOdd.module.scss';

const MONTHLY_LOCATIONS = [MODAL_LOCATIONS.MARKETING];

const PremiumSubscriptionModalOdd = ({
  onClose,
  onSubscribe,
  singlePaymentAction,
  singlePaymentType,
  hideButtons,
  openLocation,
  segmentProperties = {},
  user,
  isMobileApp,
  extraParams,
  variantToRender,
  wantsCosignerAgreement,
}) => {
  const modalTypeDesktop = openLocation || singlePaymentType;
  const modalType = isMobileApp ? MODAL_LOCATIONS.MOBILE_APP : modalTypeDesktop;

  const segmentLocation = getSegmentLocation(modalType);

  const config = useConfig();

  const { data: landlordCountData, loading: loadingLandlordCount } = useQuery(
    getLandlordCountQueryGQL,
  );
  const landlordCount = landlordCountData?.general_insights?.landlordCount;

  const { title, subtitle, benefits, testimonial } = getModalContent(
    openLocation,
    user,
    config,
    variantToRender,
  );

  const premiumPrice =
    user?.premium_price_signup || config.PREMIUM_PLAN_PRICE_DEFAULT;

  // TODO I THINK WE NEED TO DO THIS FOR THE LISTING FLOW
  // const displayMonthlyPrice =
  //   modalType === MODAL_LOCATIONS.MARKETING ||
  //   modalType === MODAL_LOCATIONS.USE_YOUR_OWN_APP;

  const initialTab = parse(location.search)?.tab || 0;
  const isSmallScreen = useIsSmallScreen();
  const [showComparison, setShowComparison] = useState(false);
  const [selectedBenefit, setSelectedBenefit] = useState(
    benefits[initialTab]?.key,
  );
  const onGetPremiumClick = () => {
    segmentTracking('get_premium clicked', {
      location: segmentLocation,
      ...segmentProperties,
    });
    onSubscribe();
  };

  return (
    <Modal
      fullScreen={isSmallScreen}
      open
      className={cx(styles.container, {
        [styles.standalone]: !singlePaymentType,
      })}
      onCancel={() => {
        segmentTracking('close clicked', {
          location: segmentLocation,
          ...segmentProperties,
        });
        onClose();
      }}
      containerClassName={styles.modalWrap}
    >
      <PremiumBadge className={styles.premiumBadge} />
      <IconButton
        className={styles.close}
        onClick={() => {
          segmentTracking('close clicked', {
            location: segmentLocation,
            ...segmentProperties,
          });
          onClose();
        }}
        icon={Close}
        data-qa="close-premium-modal"
        iconProps={{ width: 18.7, height: 18.7 }}
      />
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.subtitle}>{subtitle}</p>

      <SubscriptionDetailsBlueCardAlt
        benefits={benefits.find(({ key }) => key === selectedBenefit)}
        extraParams={extraParams}
        onSubscribe={onGetPremiumClick}
        tabs={benefits.map(({ key, title, titleShort }) => ({
          key,
          title: titleShort || title,
        }))}
        onTabClick={(key) => {
          setSelectedBenefit(key);
        }}
        premiumPrice={premiumPrice}
        displayMonthlyPrice={MONTHLY_LOCATIONS.includes(openLocation)}
      />

      <div
        className={cx(styles.flatButtonContainer, {
          [styles.expanded]: showComparison,
        })}
      >
        <FlatButton
          onClick={() => {
            setShowComparison(!showComparison);
            segmentTracking(
              !showComparison ? 'compare expanded' : 'compare collapsed',
              {
                location: segmentLocation,
                ...segmentProperties,
              },
            );
          }}
          icon={() => <ChevronArrowDown color={colors.primary} />}
          iconAfter
          className={styles.flatButton}
        >
          {showComparison
            ? 'Hide plan comparison'
            : 'Compare Plans & See All Features'}
        </FlatButton>
      </div>

      {showComparison && (
        <ComparisonChart
          className={styles.comparisonChart}
          rows={comparisonRows(isSmallScreen)}
          premiumCta={
            <Button
              className={cx(styles.getPremiumBtn, styles.btn)}
              onClick={onGetPremiumClick}
              data-qa="get-premium-btn"
            >
              Get premium
            </Button>
          }
        />
      )}
      {singlePaymentType && (
        <SinglePaymentCard
          openLocation={singlePaymentType}
          hideButtons={hideButtons}
          action={() => {
            segmentTracking('continue clicked', {
              location: segmentLocation,
              ...segmentProperties,
            });
            singlePaymentAction();
          }}
          wantsCosignerAgreement={wantsCosignerAgreement}
        />
      )}
      <PremiumTestimonial
        showReviews
        className={styles.testimonial}
        paragraph={testimonial.paragraph}
        name={testimonial.name}
        landlordOf={testimonial.landlordOf}
        landlordCount={landlordCount}
        loading={loadingLandlordCount}
      />
      {isSmallScreen && showComparison && (
        <div className={styles.mobileButtonWrapper}>
          <Button
            className={styles.getPremiumBtn}
            onClick={onGetPremiumClick}
            data-qa="get-premium-btn"
          >
            Get premium
          </Button>
        </div>
      )}
    </Modal>
  );
};

PremiumSubscriptionModalOdd.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onSubscribe: PropTypes.func,
  singlePaymentAction: PropTypes.func,
  singlePaymentType: PropTypes.oneOf([
    MODAL_LOCATIONS.FORMS,
    MODAL_LOCATIONS.LEASE,
    MODAL_LOCATIONS.ADDENDUM,
    MODAL_LOCATIONS.E_SIGN,
  ]),
  openLocation: PropTypes.oneOf(Object.keys(MODAL_LOCATIONS)),
  hideButtons: PropTypes.bool,
  loading: PropTypes.bool,
  segmentProperties: PropTypes.object,
  user: PropTypes.object,
  extraParams: PropTypes.object,
  isMobileApp: PropTypes.bool,
  variantToRender: PropTypes.string,
  wantsCosignerAgreement: PropTypes.bool,
};

export default PremiumSubscriptionModalOdd;
