import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import Input from '../../../../../../../components/Input';
import ResourcesIcon from '../../../../../../../icons/resources';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredShortStandard from '../../../../../../../validators/fieldRequiredShort';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './BankInfo.module.scss';

const BankInfo = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequiredShort = conditionalValidation(
    fieldRequiredShortStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <HelpfulInfoCard
          Icon={ResourcesIcon}
          className={styles.helpfulInfoCard}
          title="North Carolina Deposit Laws"
          color="iceCold"
          collapsable
        >
          <HelpfulInfoList>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The maximum security deposit you can collect is 1.5 times the
              monthly rent if a tenancy is month-to-month, and 2 times the
              monthly rent for terms greater than month-to-month.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              You are required to provide the name and location of the
              bank/depository, and deposit the security deposit separate from
              your personal funds.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              You are not required to pay interest on the security deposit to
              the tenant.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The security deposit must be refunded to the tenant within 30 days
              of you obtaining possession of the property.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              If the tenant's address is unknown you may apply the deposit as
              permitted and the landlord shall hold the balance of the deposit
              for collection by the tenant for at least 6 months.
            </HelpfulInfoListItem>
          </HelpfulInfoList>
        </HelpfulInfoCard>
        <Field
          label="Bank / Depository Name"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_name"
          name="deposit_bank_name"
          component={Input}
          validate={fieldRequiredShort}
        />
        <Field
          label="Bank / Depository Address"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_address"
          name="deposit_bank_address"
          component={Input}
          validate={fieldRequiredShort}
        />
      </div>
    </div>
  );
};

BankInfo.propTypes = {
  className: PropTypes.string,
};

export default BankInfo;
