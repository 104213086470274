import React, { useContext, useEffect, useState } from 'react';

let id = 0;

const ModalContext = React.createContext({});

const ModalRenderer = () => {
  const { modals } = useContext(ModalContext);
  return modals.map(({ id, modal }) => (
    <React.Fragment key={id}>{modal}</React.Fragment>
  ));
};

export const ModalPlug = ({ children, id }) => {
  const { addModal, removeModal } = useContext(ModalContext);
  useEffect(() => {
    const modalId = addModal(children, id);
    return () => removeModal(modalId);
  }, [children]);
  return null;
};

export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState([]);
  const addModal = (modal, modalId) => {
    const newId = modalId ? modalId : ++id;
    setModals((prevModals) => [...prevModals, { id: newId, modal }]);
    return newId;
  };
  const removeModal = (idToRemove) => {
    setModals((prevModals) =>
      prevModals.filter((modal) => idToRemove !== modal.id),
    );
  };
  return (
    <ModalContext.Provider
      value={{
        modals: modals,
        addModal,
        removeModal,
      }}
    >
      <>
        {children}
        <ModalRenderer />
      </>
    </ModalContext.Provider>
  );
};
