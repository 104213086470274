import React from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';

import useQuestionnaires from '../../hooks/useQuestionnaires';
import QuestionMarkCircle from '../../icons/QuestionMarkCircle';
import fieldRequiredCustom from '../../validators/fieldRequiredCustom';
import Button from '../Button';
import FinalFormError from '../FinalFormError';
import Input from '../Input';
import Modal from '../Modal';
import RadioGroupAdditionalOptions from '../RadioGroupAdditionalOptions/RadioGroupAdditionalOptions';
import { useErrorToast } from '../Toast';
import { H4 } from '../Typography';

import styles from './LeaseAgreementQuestionnaireModal.module.scss';

const createLeaseAgreementOptions = [
  {
    value: 'leaseBuilder',
    label: (
      <>
        Using{' '}
        <a
          className={styles.link}
          href={'https://www.turbotenant.com/rental-lease-agreement/'}
          target="_blank"
          rel="noreferrer"
        >
          TurboTenant's state-specific lease builder
        </a>
      </>
    ),
  },
  { value: 'helpOfLawyer', label: 'Getting help from a lawyer' },
  {
    value: 'anotherService',
    label: 'Using another online service',
  },
  { value: 'alreadyHaveLa', label: 'Using a lease agreement I already have' },
  { value: 'notSureYet', label: "I'm not sure yet" },
];

const LeaseAgreementQuestionnaireModal = ({
  afterSubmit,
  listingAddress,
  onClose,
  submitButtonLabel,
}) => {
  const showErrorMessage = useErrorToast();
  const { saveLAQuestionnaireAnswer } = useQuestionnaires();
  const onSubmit = async (values) => {
    const data = {
      createLeaseAgreementsTool: values.createLeaseAgreementOption,
      createLeaseAgreementsToolComment: null,
    };
    if (values.createLeaseAgreementOption === 'anotherService') {
      data.createLeaseAgreementsToolComment = values.anotherServiceName;
    }
    try {
      await saveLAQuestionnaireAnswer({ variables: { input: data } });

      const showTooltip = ['leaseBuilder', 'dontKnow'].includes(
        values.createLeaseAgreementOption,
      );
      afterSubmit && afterSubmit(showTooltip);
      return Promise.resolve();
    } catch (err) {
      showErrorMessage(
        'There was a problem saving your answers. Please try again.',
      );
      window.atatus &&
        window.atatus.notify({
          message: 'Failed to save Lease Agreement questionnaire answers',
          err,
        });
    }
  };
  return (
    <Modal onClose={onClose} open showCloseIcon size="medium">
      <Form
        onSubmit={onSubmit}
        key="la_questionnaire_form"
        subscription={{
          values: true,
          submitting: true,
        }}
      >
        {({ handleSubmit, submitting, values }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.HeaderTitle
                  icon={QuestionMarkCircle}
                  iconProps={{
                    blueCircle: true,
                    height: 26.67,
                    width: 26.67,
                  }}
                  title="Got a moment?"
                />
              </Modal.Header>
              <Modal.Content className={styles.contentContainer}>
                <H4>
                  What are you doing for a lease agreement at {listingAddress}?
                </H4>
                <div className={styles.radioGroupContainer}>
                  <Field
                    component={RadioGroupAdditionalOptions}
                    id="createLeaseAgreementOption"
                    name="createLeaseAgreementOption"
                    options={createLeaseAgreementOptions}
                    validate={fieldRequiredCustom(
                      'Please select an option above',
                    )}
                    radiosContainerClassName={styles.radioGroup}
                    vertical
                  />
                  {values?.createLeaseAgreementOption === 'anotherService' && (
                    <Field
                      id="anotherServiceName"
                      as="input"
                      className={styles.anotherServiceInput}
                      component={Input}
                      inputProps={{ autoFocus: true }}
                      label="What service will you use?"
                      name="anotherServiceName"
                      validate={fieldRequiredCustom(
                        'Please provide the answer',
                      )}
                    />
                  )}
                  <FinalFormError />
                </div>
              </Modal.Content>
              <Modal.Footer>
                <div className={styles.actions}>
                  <Button
                    small
                    type="submit"
                    disabled={submitting}
                    loading={submitting}
                  >
                    {submitButtonLabel}
                  </Button>
                </div>
              </Modal.Footer>
            </form>
          </>
        )}
      </Form>
    </Modal>
  );
};

LeaseAgreementQuestionnaireModal.propTypes = {
  afterSubmit: PropTypes.func,
  listingAddress: PropTypes.string,
  onClose: PropTypes.func,
  submitButtonLabel: PropTypes.string,
};

LeaseAgreementQuestionnaireModal.defaultProps = {
  submitButtonLabel: 'Next',
};

export default LeaseAgreementQuestionnaireModal;
