import React from 'react';
import cx from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import { H3, Paragraph } from '../../../../../components/Typography';
import parseScreeningReportDate from '../../../../../helpers/parseScreeningReportDate';

import styles from './EvictionRecordsSection.module.scss';

const getSubjectFullName = (record) => {
  const firstName = get(record, 'subject.firstName', '');
  const lastName = get(record, 'subject.lastName', '');
  if (!firstName || !lastName) {
    return 'N/A';
  }
  return `${firstName} ${lastName}`;
};

const getSubjectFullAddress = (record) => {
  const streetAddress = get(record, 'subject.streetAddress', '');
  const city = get(record, 'subject.city', '');
  const zip = get(record, 'subject.zipCode', '');
  const state = get(record, 'subject.state', '');

  if (!streetAddress || !city || !zip || !state) {
    return 'N/A';
  }

  if (state === ' ' && zip === ' ') {
    return `${streetAddress} ${city}`;
  }

  return `${streetAddress} ${city}, ${state} ${zip}`;
};

const EvictionRecordsSection = ({ evictions }) => {
  const hasEvictions = !!evictions.length;

  return (
    <div className={styles.container}>
      <H3 className={cx(styles.title, 'mb-8')}>Eviction Records</H3>
      {hasEvictions ? (
        <Records evictions={evictions} />
      ) : (
        <Paragraph>
          No eviction records are available. This could be because they have no
          records. Or the local area does not allow the use of their eviction
          history in evaluating them as tenants.
        </Paragraph>
      )}
    </div>
  );
};

const Records = ({ evictions }) => (
  <div className={styles.innerContainer}>
    {evictions.map(({ state, eviction }, i) => (
      <div key={i} className={styles.item}>
        <span className={styles.state}>{state}</span>
        {eviction.map((record, index) => (
          <div
            key={index}
            className={cx(styles.record, {
              [styles.odd]: index % 2 === 0,
            })}
          >
            <div className={styles.row}>
              <div className={styles.rowContent}>
                <span className={styles.rowTitle}>Action Date</span>
                <span className={styles.info}>
                  {parseScreeningReportDate(record.actionDate)}
                </span>
              </div>
              <div className={styles.rowContent}>
                <span className={styles.rowTitle}>Description</span>
                <span className={styles.info}>
                  {record.componentDescription || 'N/A'}
                </span>
              </div>
              <div className={styles.rowContent}>
                <span className={styles.rowTitle}>County</span>
                <span className={styles.info}>{record.county || 'N/A'}</span>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.rowContent}>
                <span className={styles.rowTitle}>Subject Name</span>
                <span className={styles.rowInfo}>
                  {getSubjectFullName(record)}
                </span>
              </div>
              <div className={styles.rowContent}>
                <span className={styles.rowTitle}>Plaintiff</span>
                <span className={styles.rowInfo}>
                  {record.plaintiff || 'N/A'}
                </span>
              </div>
              <div className={styles.rowContent}>
                <span className={styles.rowTitle}>Record</span>
                <span className={styles.rowInfo}>
                  {record.recordId || 'N/A'}
                </span>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.rowContent}>
                <span className={styles.rowTitle}>Subject Address</span>
                <span className={styles.rowInfo}>
                  {getSubjectFullAddress(record)}
                </span>
              </div>
              <div className={styles.rowContent}>
                <span className={styles.rowTitle}>Case Type</span>
                <span className={styles.rowInfo}>
                  {record.caseType || record.actionType || 'N/A'}
                </span>
              </div>
              <div className={styles.rowContent}>
                <span className={styles.rowTitle}>File Number</span>
                <span className={styles.rowInfo}>
                  {record.fileNumber || 'N/A'}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    ))}
  </div>
);

EvictionRecordsSection.propTypes = {
  evictions: PropTypes.array,
};

export default EvictionRecordsSection;
