import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../components/HelpfulInfo';
import statesWithRequiredPropertyDisclosure from '../../../../../../helpers/statesWithRequiredPropertyDisclosure';
import InformationalIcon from '../../../../../../icons/Informational';
import { useConfig } from '../../../../../../providers/ConfigProvider';
import {
  getStateFullName,
  segmentTracking,
} from '../../../../../../services/utilities';

import styles from './PropertyConditionReport.module.scss';

const PropertyConditionReport = ({
  className,
  leaseId,
  standAloneWizard,
  currentState,
  leaseAgreementSelection,
  onCreateAccount,
}) => {
  const { PRIVATE_BASE_PATH } = useConfig();
  const stateCityName = getStateFullName(currentState, leaseAgreementSelection);
  const isStateWithRequiredPropertyDisclosure =
    statesWithRequiredPropertyDisclosure.includes(currentState);
  const conditionReportLink = `${PRIVATE_BASE_PATH}leases/view/${leaseId}/condition_reporting`;

  return (
    <HelpfulInfoCard
      className={cx(styles.card, className)}
      type="info"
      title={
        <div className={styles.titleWrapper}>
          <InformationalIcon className={styles.icon} width={20} height={18} />
          Property Condition Reports
        </div>
      }
      noIcon
    >
      {isStateWithRequiredPropertyDisclosure ? (
        <>
          <b>{stateCityName} law requires</b> that a Condition Report form be
          filled out and attached to the lease agreement if you are collecting a
          security deposit. This report will be used upon move-in and move-out
          to document any damages that occurred during the lease term.{' '}
          {standAloneWizard ? (
            <>
              Stay compliant with TurboTenant's Condition Report.{' '}
              <span className={styles.link} onClick={onCreateAccount}>
                Create an account
              </span>{' '}
              to make a custom Condition Report.
            </>
          ) : (
            <a
              className={styles.link}
              href={conditionReportLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                segmentTracking('condition_report clicked', {
                  location: 'Provisions & Attachments Form',
                });
              }}
            >
              Stay compliant with TurboTenant's Condition Report.
            </a>
          )}
        </>
      ) : (
        <>
          While {stateCityName} does not require a Property Condition Report
          form be filled out at the beginning of a lease term and attached to
          the lease, it is a good idea to complete this form at the beginning of
          the lease term so any resulting damages at the end of the lease term
          are properly documented.{' '}
          {standAloneWizard ? (
            <>
              Be a better landlord with TurboTenant's Condition Report.{' '}
              <span className={styles.link} onClick={onCreateAccount}>
                Create an account
              </span>{' '}
              to make a custom Condition Report.
            </>
          ) : (
            <a
              className={styles.link}
              href={conditionReportLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                segmentTracking('condition_report clicked', {
                  location: 'Provisions & Attachments Form',
                });
              }}
            >
              Be a better landlord with TurboTenant's Condition Report.
            </a>
          )}
        </>
      )}
    </HelpfulInfoCard>
  );
};

PropertyConditionReport.propTypes = {
  className: PropTypes.string,
  leaseId: PropTypes.string,
  standAloneWizard: PropTypes.bool,
  currentState: PropTypes.string,
  leaseAgreementSelection: PropTypes.string,
  onCreateAccount: PropTypes.func,
};

export default PropertyConditionReport;
