import React from 'react';
import { has } from 'lodash';
import PropTypes from 'prop-types';

import InformationCircleFilled from '../../icons/InformationCircleFilled';

const mapper = {
  info: {
    icon: InformationCircleFilled,
    width: 20,
    height: 20,
    color: '#2370A3',
  },
  warning: {
    icon: InformationCircleFilled,
    width: 20,
    height: 20,
    color: '#FF9F2A',
  },
  danger: {
    icon: InformationCircleFilled,
    width: 20,
    height: 20,
    color: '#BF2C2C',
  },
  success: {
    icon: InformationCircleFilled,
    width: 20,
    height: 20,
    color: '##04BF8D',
  },
};

const IconForType = ({ type }) => {
  if (!type || !has(mapper, type)) return null;

  const { icon: Icon, width, height, color } = mapper[type];

  return <Icon width={width} height={height} color={color} />;
};

IconForType.propTypes = {
  type: PropTypes.oneOf(['info', 'warning', 'danger', 'success']),
};

export default IconForType;
