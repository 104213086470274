import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from './BankInfo.module.scss';

const BankInfo = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.row}>
      <HelpfulInfoCard
        Icon={ResourcesIcon}
        className={styles.helpfulInfoCard}
        title="Wisconsin Security Deposit Laws"
        color="iceCold"
        collapsable
      >
        <p>
          Here is some helpful info that is also included in the lease
          agreement:
        </p>
        <HelpfulInfoList>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Tenant can do any of the following by no later than 7 days after the
            start of tenancy:
            <HelpfulInfoList>
              <HelpfulInfoListItem
                className={cx(styles.helpfulItem, styles.nested)}
              >
                Inspect the premises and notify you of any preexisting damages
                or defects.
              </HelpfulInfoListItem>
              <HelpfulInfoListItem
                className={cx(styles.helpfulItem, styles.nested)}
              >
                Request a list of physical damages or defects, if any, charged
                to the previous tenant's security deposit. You can require the
                tenant to make this request in writing.
              </HelpfulInfoListItem>
            </HelpfulInfoList>
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            You must return tenant’s security deposit within 21 days after lease
            termination along with a written itemization of any lawful
            deductions, mailed to tenant’s forwarding address.
          </HelpfulInfoListItem>

          <div className={styles.learnMore}>
            <a
              href="https://support.turbotenant.com/en/articles/5953145-wisconsin-lease-agreement#h_82e9d41738"
              target="_blank noopener noreferrer"
            >
              Learn other security deposit laws
            </a>
          </div>
        </HelpfulInfoList>
      </HelpfulInfoCard>
    </div>
  </div>
);

BankInfo.propTypes = {
  className: PropTypes.string,
};

export default BankInfo;
