import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import NumberedList from '../../../components/NumberedList';
import { segmentTracking } from '../../../services/utilities';

import PUBLIC_SCREENING_REPORT_SIDEBAR_IMAGE from './screening-report-info.png';

import styles from './PublicScreeningReportSidebar.module.scss';

const listStepsInfo = [
  'You provide information about the renter',
  'The renter verifies their identity',
  `You'll receive an email when their screening report is ready to view`,
];

const PublicScreeningReportSidebar = ({
  className,
  onLetsGetStarted,
  segmentLocation,
  segmentData = {},
}) => (
  <div className={cx(styles.container, className)}>
    <img
      className={styles.image}
      src={PUBLIC_SCREENING_REPORT_SIDEBAR_IMAGE}
      alt="Public screening report example"
    />
    <div className={styles.content}>
      <span className={styles.subtitle}>FIND THE RIGHT TENANT</span>
      <h3 className={styles.title}>Screen your tenant in 3 easy steps</h3>
      <p className={styles.description}>
        With access to their credit, background, and eviction history, you'll
        know how reliable they'll be as a tenant.
      </p>
      <NumberedList
        className={cx(styles.stepsList, {
          [styles.buttonPresent]: onLetsGetStarted,
        })}
        variant="bold"
        size="medium"
        list={listStepsInfo}
      />
    </div>
    {onLetsGetStarted ? (
      <Button
        className={styles.btn}
        onClick={() => {
          segmentTracking('get_started clicked', {
            location: segmentLocation,
            ...segmentData,
          });
          onLetsGetStarted();
        }}
      >
        Let's Get Started
      </Button>
    ) : null}
    <div className={styles.linkWrapper}>
      <span className={styles.linkInfo}>
        Want them to fill out a rental application first?
      </span>
      <a
        className={styles.link}
        href={`/invite-to-apply`}
        onClick={() => {
          segmentTracking('invite_them_to_apply clicked', {
            location: segmentLocation,
            ...segmentData,
          });
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        Invite them to apply.
      </a>
    </div>
  </div>
);

PublicScreeningReportSidebar.propTypes = {
  className: PropTypes.string,
  onLetsGetStarted: PropTypes.func,
  segmentLocation: PropTypes.string,
  segmentData: PropTypes.object,
};

export default PublicScreeningReportSidebar;
