import React, { memo } from 'react';

const Quill = (props) => (
  <svg viewBox="0 0 54 54" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M20.358 33.667v2.222h-1.111c-.615 0-1.113.498-1.113 1.112v4.444c0 .558.414 1.029.968 1.101l.588.085c5.593.875 8.678 3.516 9.54 8.415l.064.399H2.684l.066-.41c.877-5.097 4.05-7.715 9.966-8.489.554-.072.968-.543.968-1.101V37l-.018-.2a1.112 1.112 0 0 0-1.095-.912h-1.114v-2.222h8.9Z"
        fill="#7FE3FF"
      />
      <path
        d="M21.472 31.445H10.346c-.614 0-1.112.498-1.112 1.111v4.445l.018.2c.094.518.548.91 1.094.91h1.111v2.378l-.284.051C4.463 41.782.853 45.635.337 52.472a1.112 1.112 0 0 0 1.109 1.195h29.09c.648 0 1.159-.55 1.11-1.195l-.055-.61c-.687-6.426-4.382-10.109-10.937-11.32l-.296-.053v-2.377h1.114c.614 0 1.112-.498 1.112-1.111v-4.445c0-.613-.498-1.11-1.112-1.11Zm-1.114 2.222v2.222h-1.111c-.615 0-1.113.498-1.113 1.112v4.444c0 .558.414 1.029.968 1.101l.588.085c5.593.875 8.678 3.516 9.54 8.415l.064.399H2.684l.066-.41c.877-5.097 4.05-7.715 9.966-8.489.554-.072.968-.543.968-1.101V37l-.018-.2a1.112 1.112 0 0 0-1.095-.912h-1.114v-2.222h8.9ZM44.82 1.261l.015.185V6.96l5.55-5.544a1.114 1.114 0 0 1 1.685-.13c2.45 2.45 2 6.926-.81 11.823a1.113 1.113 0 0 1-.768.54l-.197.018h-9.029l-2.226 2.222 8.02.001a1.112 1.112 0 0 1 .2 2.204l-.2.018H36.816l-2.226 2.222h9.133c1.017 0 1.501 1.25.749 1.934-5.507 4.998-12.894 6.807-16.506 4.683l-2.871 2.868a1.114 1.114 0 0 1-1.574 0 1.11 1.11 0 0 1-.128-1.417l.128-.154 2.89-2.887c-2.142-3.783 1.093-11.651 7.281-17.827C36.736 4.488 40.41 1.844 43.23.45a1.113 1.113 0 0 1 1.592.811Zm-4.333 21.296h-8.123l-2.747 2.745c2.226.864 6.424.044 10.362-2.415l.508-.33Zm2.12-19.231-.17.102C40.361 4.69 38 6.482 35.891 8.495l-.625.61c-5.234 5.224-8.03 11.676-7.176 14.58l11.875-11.861c.036-.04.074-.079.116-.114l2.53-2.527-.002-5.857Zm8.52.494-6.5 6.493c-.071.098-.157.184-.255.254l-.88.877h6.147l.241-.458C51.395 8 51.794 5.438 51.128 3.82Z"
        fill="#042238"
      />
    </g>
  </svg>
);

Quill.defaultProps = {
  width: 54,
  height: 54,
};

export default memo(Quill);
