import React, { memo } from 'react';

const EmptyIllustration = (props) => (
  <svg {...props} viewBox="0 0 84 78" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#7FE3FF"
        d="M40.025 34.11h41.47V61.66H75.57L73.596 73.47 59.773 61.661H40.025z"
      />
      <path
        fill="#042238"
        d="M81.495 32.116c.948 0 1.717.766 1.717 1.711v27.74c0 .945-.769 1.711-1.717 1.711l-5.15-.002-.002 12.16c0 1.409-1.59 2.184-2.695 1.408l-.17-.135-14.965-13.433-18.23.002a1.715 1.715 0 0 1-1.704-1.496l-.013-.215v-27.74c0-.945.768-1.71 1.717-1.71Zm-1.716 3.421H42v24.317l17.172.002c.34 0 .67.1.949.285l.2.154 12.59 11.296-.002-10.024c0-.872.655-1.592 1.502-1.698l.215-.013 5.153-.002V35.537ZM67.758.91c.875 0 1.597.653 1.703 1.497l.014.214V25.16c0 .945-.77 1.711-1.717 1.711a1.715 1.715 0 0 1-1.704-1.497l-.014-.214V4.33H4.224v38.187h8.585c.875 0 1.598.653 1.704 1.497l.013.215v9.7l10.798-10.902a1.72 1.72 0 0 1 .995-.494l.227-.016h3.435c.948 0 1.717.767 1.717 1.712 0 .872-.655 1.592-1.502 1.698l-.215.013h-2.718L14.03 59.3c-1.027 1.038-2.764.397-2.927-.988l-.012-.213V45.94l-8.586.001a1.715 1.715 0 0 1-1.704-1.497l-.013-.214V2.62c0-.872.655-1.592 1.502-1.698L2.505.91h65.253Z"
      />
    </g>
  </svg>
);

EmptyIllustration.defaultProps = {
  width: 84,
  height: 78,
};

export default memo(EmptyIllustration);
