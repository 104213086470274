import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RadioGroup from '../../../../../../../components/RadioGroup';
import DRUGS_MANUFACTURED_OPTIONS from '../../../../../../../constants/lease_agreement/drugs-manufactured-options';
import CristalIcon from '../../../../../../../icons/Cristal';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './DrugDisclosureCA.module.scss';

const DrugDisclosureCA = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Drug Disclosure" icon={CristalIcon} />
      <label className={styles.label}>
        Do you know if Methamphetamines or Fentanyl have ever been used, stored
        or manufactured at your property?
      </label>
      <p className={styles.description}>
        California law requires you to disclose this.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.drugs_manufactured"
        name="custom_state_data.drugs_manufactured"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'drugs_manufactured_yes_unsafe',
            value: DRUGS_MANUFACTURED_OPTIONS.YES_UNSAFE,
            label:
              'Yes - The property is contaminated above safe levels but is in the process of decontamination',
          },
          {
            id: 'drugs_manufactured_yes_safe',
            value: DRUGS_MANUFACTURED_OPTIONS.YES_SAFE,
            label:
              'Yes - The property is contaminated, but falls within safe levels after tests were conducted',
          },
          {
            id: 'drugs_manufactured_no',
            value: DRUGS_MANUFACTURED_OPTIONS.NO,
            label: 'No - There are no known contaminations',
          },
        ]}
        validate={fieldRequired}
      />
    </div>
  );
};

DrugDisclosureCA.propTypes = {
  className: PropTypes.string,
};

export default DrugDisclosureCA;
