import React, { createContext, useContext, useState } from 'react';

export const DashboardContext = createContext();

export const useDashboardContext = () => {
  const value = useContext(DashboardContext);
  return value;
};

export const DashboardProvider = (props) => {
  const [tooltipsToShow, setTooltipsToShow] = useState({
    enabled: false,
    showHowToCreateLeaseAgreement: false,
    showHowToSetupRentPayments: false,
  });

  const value = {
    tooltipsToShow,
    setTooltipsToShow,
  };

  return (
    <DashboardContext.Provider value={value}>
      {props.children}
    </DashboardContext.Provider>
  );
};
