import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from './BankInfo.module.scss';

const listItems = [
  'The maximum security deposit you can collect is 2 times the monthly rent.',
  'The security deposit must be returned within 45 days of the lease ending.',
  'You must include a written itemized statement of any lawful charges and deductions when you return the security deposit.',
  'Security deposit will be returned as one payment, to one tenant’s forwarding address, unless all tenants agree to a different plan.',
];

const BankInfo = ({ className }) => {
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <HelpfulInfoCard
          collapsable
          Icon={ResourcesIcon}
          className={cx(styles.helpfulInfoCard)}
          title={`Virginia Security Deposit Laws`}
          color="iceCold"
        >
          Though they're included in the lease agreement, we've highlighted some
          rules you need to know:
          <HelpfulInfoList className={styles.helpfulInfoList}>
            {listItems.map((item, index) => (
              <HelpfulInfoListItem className={styles.helpfulItem} key={index}>
                {item}
              </HelpfulInfoListItem>
            ))}
          </HelpfulInfoList>
        </HelpfulInfoCard>
      </div>
    </div>
  );
};

BankInfo.propTypes = {
  className: PropTypes.string,
};

export default BankInfo;
