import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TurboTenantSmallLogo = ({ color, ...props }) => (
  <svg viewBox="0 0 54 39" {...props} fill={color}>
    <path
      d="M39.953 30.423c0-.451.112-1.129.227-1.691l3.86-14.368h8.007l1.591-5.86h-8.01l2.22-8.17h-6.875l-2.212 8.17h-3.807l-1.589 5.86h3.803l-4.03 15.1a11.98 11.98 0 0 0-.512 3.437c0 3.55 2.668 5.86 7.496 5.86 2.102 0 3.975-.338 5.624-1.07l1.59-5.916c-1.478.62-3.012.958-4.374.958-1.989 0-3.01-.79-3.01-2.31M29.089 14.12l.937-5.26H10.297l-.941 5.288 19.732-.028Zm-3.715 19.068.934-5.261H6.58l-.941 5.288 19.734-.027Zm1.245-9.773.935-5.263H1.273l-.94 5.29 26.285-.027Z"
      fillRule="evenodd"
    />
  </svg>
);

TurboTenantSmallLogo.defaultProps = {
  width: 32,
  height: 24,
  color: 'currentColor',
};

TurboTenantSmallLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(TurboTenantSmallLogo);
