import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

import Radio from './icons/Radio';
import RadioChecked from './icons/RadioChecked';

import styles from './ProgressBar.module.scss';

// progress bar have an extra 50px padding on left and right sides
// so when adding a width to it, you should include that padding
// for example
// if you need to have a ProgressBar to be 300px wide
// pass width: 400px style to it
const ProgressBar = ({
  className,
  options,
  optionLabels,
  active,
  desktopVariant,
  stepsVariant,
  isCurrentStepCompleted = false,
  progressBarWrapClassName,
}) => {
  const activeIndex = options
    .map((a) => a.toUpperCase())
    .indexOf(active.toUpperCase());

  return (
    <div
      className={cx(className, {
        [styles.forceDesktop]: desktopVariant,
        [styles.forceSteps]: stepsVariant,
      })}
    >
      <div
        className={cx(styles.progressBarWrap, {
          [progressBarWrapClassName]: progressBarWrapClassName,
        })}
      >
        <div className={styles.progressBar}>
          <div className={styles.bar}>
            <div
              className={styles.progress}
              style={{
                width: `${(100 / (options.length - 1)) * activeIndex}%`,
              }}
            />
          </div>
          {options.map((option, index) => {
            let icon;
            if (index < activeIndex) {
              icon = <RadioChecked className={styles.icon} />;
            } else if (index === activeIndex) {
              if (isCurrentStepCompleted) {
                icon = <RadioChecked className={styles.icon} />;
              } else {
                icon = <Radio className={styles.icon} fill={colors.primary} />;
              }
            } else {
              icon = <Radio className={styles.icon} fill={colors.greyLight} />;
            }
            return (
              <div key={option} className={styles.option}>
                <div
                  className={cx(styles.label, {
                    [styles.unfilled]: index > activeIndex,
                  })}
                >
                  {optionLabels[option] || option}
                </div>
                {icon}
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.steps}>
        STEP {activeIndex + 1} OF {options.length}
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  optionLabels: PropTypes.object,
  active: PropTypes.string.isRequired,
  desktopVariant: PropTypes.bool,
  stepsVariant: PropTypes.bool,
  isCurrentStepCompleted: PropTypes.bool,
  progressBarWrapClassName: PropTypes.string,
};

ProgressBar.defaultProps = {
  optionLabels: {},
};

export default ProgressBar;
