import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from './BankInfoMT.module.scss';

const BankInfoMT = ({ className }) => {
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <HelpfulInfoCard
          Icon={ResourcesIcon}
          className={styles.helpfulInfoCard}
          title="Montana Deposit Laws"
          color="iceCold"
          collapsable
        >
          <HelpfulInfoList>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              There is no maximum security deposit you can collect.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The security deposit must be returned within 10 days if their are
              no deductions at lease end. You must return the deposit within 30
              days if there are deductions.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Written itemization stating the amount of the security deposit
              collected minus any deductions, needs to be mailed to tenant's
              forwarding address.
            </HelpfulInfoListItem>
          </HelpfulInfoList>
        </HelpfulInfoCard>
      </div>
    </div>
  );
};

BankInfoMT.propTypes = {
  className: PropTypes.string,
};

export default BankInfoMT;
