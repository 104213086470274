import { v4 as uuidv4 } from 'uuid';

const errorCodesToRegenerateIdempotencyKey = [
  563, // StripeDeclinedCardError
  567, // StripeConnectPaymentError
];

/**
 * This method will check the type of error we are getting from the mutation and
 * if this code does belong to the group of errors that should reset the IDK, i will set
 * a new IDK in the session storage, otherwise the key won't change at all so the next
 * request will use the same IDK.
 * @param {*} errors errors returned in the mutation
 */
export const resetIdempotencyKey = (errors) => {
  // IDK should be cleared if no errors were thrown in the operation
  if (!errors) {
    return sessionStorage.setItem('idempotencyKey', '');
  }

  const code = errors[0]?.code || undefined;
  const message = errors[0]?.message || 'An error occurred';

  // the error that you can't pay a different charge from the one that failed before
  // comes also with code 567 so if that's the case we don't have to reset the key
  if (
    errorCodesToRegenerateIdempotencyKey.includes(code) &&
    !message.includes('Keys for idempotent requests')
  ) {
    sessionStorage.setItem('idempotencyKey', uuidv4());
  }
};
