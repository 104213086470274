import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import FlatButton from '../../../components/FlatButton';
import Modal from '../../../components/Modal';
import { segmentTracking } from '../../../services/utilities';

import styles from './AuditLAErrorModal.module.scss';

const AuditLAErrorModal = ({
  onClose,
  onAction,
  openLocation,
  errorMessage,
}) => {
  useEffect(() => {
    segmentTracking('error modal loaded', {
      location: openLocation,
    });
  }, []);

  return (
    <Modal
      open
      onClose={onClose}
      disableOutsideClick
      className={styles.container}
    >
      <div>
        <h3 className={styles.title}>Oh no! There was an error.</h3>
        {errorMessage && <p>{errorMessage}</p>}
        <p>
          Please check to make sure the file is ≤20MB and either .doc, .docx, or
          .pdf. If you continue to have issues, you can reach out to{' '}
          <a href="mailto:support@turbotenant.com">support@turbotenant.com</a>.
        </p>
        <div className={styles.actions}>
          <Button
            className={styles.tryAgainButton}
            onClick={() => {
              segmentTracking('try_again clicked', {
                location: 'Lease Audit Upload Error Modal',
              });
              onClose();
            }}
          >
            Try Again
          </Button>
          <FlatButton
            className={styles.exploreOurLAButton}
            onClick={() => {
              segmentTracking('explore_lease_agreement clicked', {
                location: 'Lease Audit Upload Error Modal',
              });
              onAction();
            }}
          >
            Explore our lease agreements
          </FlatButton>
        </div>
      </div>
    </Modal>
  );
};

AuditLAErrorModal.propTypes = {
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  openLocation: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default AuditLAErrorModal;
