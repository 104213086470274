import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Input from '../../../../../../../components/Input';
import TenantsIcon from '../../../../../../../icons/Tenants';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './OccupantDisclosureWA.module.scss';

const OccupantDisclosureWA = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle
        title="Occupant Disclosure"
        icon={TenantsIcon}
        iconProps={{ width: 28, height: 24 }}
      />
      <label className={styles.label}>
        What is the number of occupants allowed to occupy the premises?
      </label>
      <p className={styles.description}>
        Tacoma, Washington ordinance requires that you disclose the total number
        of occupants allowed to occupy the premises. Any lease for more than 4
        tenants must have separate rental agreements for each “livable space”.
      </p>
      <Field
        as="textarea"
        component={Input}
        id="custom_state_data.occupant_disclosure_explanation"
        name="custom_state_data.occupant_disclosure_explanation"
        label="Please state the number of occupants allowed to occupy the premises:"
        className={styles.termsContainer}
        inputProps={{
          rows: '5',
        }}
        maxLength={500}
        validate={fieldRequired}
        help={(val = '') => `${val.length} / 500 characters used`}
      />
    </div>
  );
};

OccupantDisclosureWA.propTypes = {
  className: PropTypes.string,
};

export default OccupantDisclosureWA;
