import React from 'react';
import Dropzone from 'react-dropzone';
import cx from 'classnames';
import PropTypes from 'prop-types';

import UploadIcon from '../../icons/DownloadForms';
import getFieldError from '../../validators/get-field-error';

import styles from './UploadDropZoneAlt.module.scss';

const UploadDropZoneAlt = ({
  className,
  id,
  meta,
  inputProps,
  attachedState,
  uploadFiles,
  icon: Icon,
  iconProps,
  label,
  description,
  uploadLabel,
  onFileDrop,
  ...otherProps
}) => {
  const error = meta && getFieldError(meta);

  const handleDrop = (acceptedFiles) => {
    onFileDrop && onFileDrop(acceptedFiles);
  };

  return (
    <div className={cx(styles.container, className)}>
      <Dropzone onDrop={handleDrop} {...otherProps}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              className: styles.dropzone,
            })}
          >
            <input
              id={id}
              onChange={(e) => {
                handleDrop(e.target.files);
              }}
              {...getInputProps()}
              {...inputProps}
            />
            {attachedState ? (
              attachedState
            ) : (
              <>
                <div className={styles.iconWrap}>
                  <Icon
                    {...iconProps}
                    className={cx(styles.icon, iconProps?.className)}
                  />
                </div>
                <h4 className={styles.label}>{label}</h4>
                {description && (
                  <span className={styles.description}>{description}</span>
                )}
              </>
            )}
          </div>
        )}
      </Dropzone>
      {error && (
        <div id={`${id}_errormsg`} className={styles.error}>
          {error}
        </div>
      )}
    </div>
  );
};

UploadDropZoneAlt.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  meta: PropTypes.object,
  inputProps: PropTypes.object,
  icon: PropTypes.object,
  iconProps: PropTypes.object,
  label: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  uploadLabel: PropTypes.string,
  onFileDrop: PropTypes.func,
  attachedState: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

UploadDropZoneAlt.defaultProps = {
  label: 'Click or drag to upload',
  input: {
    value: [],
    onChange: () => null,
  },
  icon: UploadIcon,
  uploadLabel: 'Uploading...',
};

export default UploadDropZoneAlt;
