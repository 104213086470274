import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import STATE_NAMES from '../../../constants/lease_agreement/state-names';

import styles from './AuditLAH1.module.scss';

const AuditLAH1 = ({ score, state }) => {
  if (score.notIncluded >= 1) {
    return (
      <h1 className={cx(styles.container, styles.h1Error)}>
        Your {STATE_NAMES[state]} Lease Agreement{' '}
        <span>could use some work</span>
      </h1>
    );
  }

  if (score.notIncluded === 0 && score.indefinite === 0) {
    return (
      <h1 className={cx(styles.container, styles.h1Success)}>
        Your {state} Lease Agreement <span>is good to go</span>
      </h1>
    );
  }

  if (score.notIncluded === 0 && score.indefinite >= 1) {
    return (
      <h1 className={cx(styles.container, styles.h1Warning)}>
        Your {state} Lease Agreement <span>could use another look</span>
      </h1>
    );
  }

  return (
    <h1 className={cx(styles.container, styles.h1Success)}>
      Your {state} Lease Agreement <span>is good to go</span>
    </h1>
  );
};

AuditLAH1.propTypes = {
  score: PropTypes.shape({
    included: PropTypes.number,
    notIncluded: PropTypes.number,
    indefinite: PropTypes.number,
  }).isRequired,
  state: PropTypes.string.isRequired,
};

export default AuditLAH1;
