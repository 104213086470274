import LEASE_PROPERTY_TYPE from '../../../../constants/lease/LeasePropertyType';
import ApartmentColor from '../../../../icons/ApartmentColor';
import CondominiumColor from '../../../../icons/CondominiumColor';
import HelpFill from '../../../../icons/HelpFill';
import ListingHouseColor from '../../../../icons/ListingHouseColor';
import MultiFamilyColor from '../../../../icons/MultiFamilyColor';
import TownhouseColor from '../../../../icons/TownhouseColor';

export const propertyTypeDropdownOptions = [
  {
    value: LEASE_PROPERTY_TYPE.SINGLE_FAMILY,
    label: 'Single Family Home',
    subtext: 'Standalone residence',
    icon: ListingHouseColor,
  },
  {
    value: LEASE_PROPERTY_TYPE.TOWNHOUSE,
    label: 'Townhouse',
    subtext: 'Individual unit',
    icon: TownhouseColor,
  },
  {
    value: LEASE_PROPERTY_TYPE.CONDO,
    label: 'Condominium',
    subtext: 'Individual unit',
    icon: CondominiumColor,
  },
  {
    value: LEASE_PROPERTY_TYPE.MULTI_FAMILY,
    label: 'Small Multi-Family',
    subtext: '2-4 units at the property ',
    icon: MultiFamilyColor,
  },
  {
    value: LEASE_PROPERTY_TYPE.APARTMENT,
    label: 'Apartment Building',
    subtext: '5+ units at the property',
    icon: ApartmentColor,
  },
  {
    value: LEASE_PROPERTY_TYPE.OTHER,
    label: 'Other',
    subtext: 'Mixed-use, RV park, etc.',
    icon: HelpFill,
  },
];
