import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { AuditItemIncludedEnum } from '../../../../constants/ai-audit-tool';
import colors from '../../../../constants/colors';
import CheckMarkFilled from '../../../../icons/CheckMarkFilled';
import Question from '../../../../icons/Question';
import RadioX from '../../../../icons/RadioX';

import styles from './AuditItem.module.scss';

const AuditItem = ({ item, description, included, className }) => {
  function getIcon() {
    if (included === AuditItemIncludedEnum.YES) {
      return (
        <CheckMarkFilled
          className={styles.itemIcon}
          color={colors.lightGreenAlt}
          width={20}
          height={20}
        />
      );
    }
    if (included === AuditItemIncludedEnum.NO) {
      return (
        <RadioX
          className={styles.itemIcon}
          color={colors.error}
          width={20}
          height={20}
        />
      );
    }
    if (included === AuditItemIncludedEnum.INDEFINITE) {
      return (
        <Question
          className={cx(styles.itemIcon, styles.itemQ)}
          width={24}
          height={24}
          color={colors.warningToastOrange}
        />
      );
    }
  }

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.itemHeader}>
        {getIcon()}
        {item}
      </div>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

AuditItem.propTypes = {
  item: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  included: PropTypes.oneOf([
    AuditItemIncludedEnum.YES,
    AuditItemIncludedEnum.NO,
    AuditItemIncludedEnum.INDEFINITE,
  ]).isRequired,
  className: PropTypes.string,
};

export default AuditItem;
