import React from 'react';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from './LeaseNotarizationWA.module.scss';

const LeaseNotarizationWA = () => {
  return (
    <HelpfulInfoCard
      Icon={ResourcesIcon}
      className={styles.helpfulInfoCard}
      title="Washington Notarization Laws"
      color="iceCold"
    >
      <HelpfulInfoList>
        <HelpfulInfoListItem className={styles.helpfulItem}>
          Any Washington lease over 12 months in length requires the signatures
          to be notarized.
        </HelpfulInfoListItem>
        <HelpfulInfoListItem className={styles.helpfulItem}>
          Failure to notarize a lease over 1 year in length will result in the
          lease being considered a month-to-month lease.
        </HelpfulInfoListItem>
      </HelpfulInfoList>
    </HelpfulInfoCard>
  );
};

export default LeaseNotarizationWA;
