import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from '../../../BankInfo.module.scss';

const BankInfo = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.row}>
      <HelpfulInfoCard
        Icon={ResourcesIcon}
        className={styles.helpfulInfoCard}
        title="Idaho Security Deposit Laws"
        color="iceCold"
      >
        Though they're included in the lease agreement, we've highlighted some
        rules you need to know:
        <HelpfulInfoList className={styles.helpfulInfoList}>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            By default, a landlord must return the security deposit within 21
            days. The lease agreement may extend this time to 30 days. This
            lease agreement has extended the return time to 30 days.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            There is no state statutory maximum security deposit amount in
            Idaho. Typically deposits are 1-2 times the monthly rent.
          </HelpfulInfoListItem>

          <div className={styles.learnMore}>
            <a
              href="http://support.turbotenant.com/en/articles/6145855-idaho-lease-agreement"
              target="_blank noopener noreferrer"
            >
              Learn other security deposit laws
            </a>
          </div>
        </HelpfulInfoList>
      </HelpfulInfoCard>
    </div>
  </div>
);

BankInfo.propTypes = {
  formValues: PropTypes.object,
  className: PropTypes.string,
};

export default BankInfo;
