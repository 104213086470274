import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import IconTooltip from '../../../../../../../components/IconTooltip';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import ASBESTOS_OPTIONS from '../../../../../../../constants/lease_agreement/asbestos-options';
import RadiationIcon from '../../../../../../../icons/Radiation';
import TurboTenantSmallLogo from '../../../../../../../icons/TurboTenantSmallLogo';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './AsbestosNoticeCA.module.scss';

const AsbestosNoticeCA = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  const {
    input: { value: asbestosDisclosure },
  } = useField('custom_state_data.asbestos_disclosure');

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Asbestos Disclosure" icon={RadiationIcon} />
      <label className={styles.label}>
        Do you have any knowledge of asbestos contamination on the property?
      </label>
      <p className={styles.text}>
        California law requires you to disclose this.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.asbestos_disclosure"
        name="custom_state_data.asbestos_disclosure"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'custom_state_data.asbestos_disclosure_yes',
            value: ASBESTOS_OPTIONS.YES,
            label: 'Yes',
          },
          {
            id: 'custom_state_data.asbestos_disclosure_no',
            value: ASBESTOS_OPTIONS.NO,
            label: 'No',
          },
          {
            id: 'custom_state_data.asbestos_disclosure_not_available',
            value: ASBESTOS_OPTIONS.NOT_AVAILABLE,
            label: (
              <div className={styles.tooltipWrapper}>
                N/A, rental was built after 1979
                <IconTooltip
                  tip={
                    <span>
                      Buildings built after 1979 do not need to disclose
                      anything about asbestos.
                    </span>
                  }
                />
              </div>
            ),
          },
        ]}
        validate={fieldRequired}
      />
      {asbestosDisclosure === ASBESTOS_OPTIONS.YES && (
        <HelpfulInfoCard
          className={styles.helpfulInfoCard}
          noTitle
          Icon={TurboTenantSmallLogo}
          iconProps={{ className: styles.cardLogo }}
        >
          California law requires you to disclose any known asbestos in any
          buildings built before 1979, as well as all test results and notices
          received from a government agency on the contamination, and
          remediation plans. We recommend you discuss it with your tenant.
        </HelpfulInfoCard>
      )}
      {asbestosDisclosure === ASBESTOS_OPTIONS.YES && (
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.asbestos_additional_info"
          name="custom_state_data.asbestos_additional_info"
          label="Please explain any test results, notices, and/or remediation plans:"
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      )}
    </div>
  );
};

AsbestosNoticeCA.propTypes = {
  className: PropTypes.string,
};

export default AsbestosNoticeCA;
