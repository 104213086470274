import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Maintenance = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 25" {...rest} fill={color}>
    <path d="M8.268 13.862a.6.6 0 0 1 .849 0l1.76 1.76a.6.6 0 0 1 0 .85L5.42 21.93a1.839 1.839 0 0 1-2.612-.002 1.839 1.839 0 0 1 .002-2.608l5.458-5.458Zm.424 1.272-5.034 5.034a.639.639 0 0 0 0 .913l.004.004a.639.639 0 0 0 .91-.004l5.033-5.034-.913-.913Z" />
    <path d="M7.387 12.98a.6.6 0 0 1 .849 0l3.522 3.523a.6.6 0 0 1-.848.849l-3.523-3.523a.6.6 0 0 1 0-.848Z" />
    <path d="m9.14 14.75 8.881-8.88.85.848-8.882 8.881-.849-.849Z" />
    <path d="M19.973 2.685a.6.6 0 0 1 .767.069L21.986 4a.6.6 0 0 1 .056.784l-2.135 2.85a.6.6 0 0 1-.905.064L16.95 5.646a.6.6 0 0 1 .082-.917l2.941-2.044Zm-1.667 2.62 1.055 1.055 1.409-1.88-.524-.523-1.94 1.348ZM4.277 2.9c1.427-.61 3.077-.33 4.241.834l.002.002a3.928 3.928 0 0 1 .974 3.914l2.845 2.838-.847.85L8.376 8.23a.6.6 0 0 1-.128-.659 2.728 2.728 0 0 0-.58-2.99c-.58-.579-1.318-.84-2.051-.788l.899 1.014a.6.6 0 0 1 .15.398v1.662a.6.6 0 0 1-.6.6h-1.66a.6.6 0 0 1-.409-.16l-.962-.893c-.044.74.22 1.487.79 2.056.804.805 1.986.987 2.988.561a.6.6 0 0 1 .658.128l3.115 3.107-.847.85-2.846-2.839c-1.337.411-2.85.109-3.917-.959a3.872 3.872 0 0 1-.834-4.273.6.6 0 0 1 .96-.205L4.64 6.267h.826v-.834L4.064 3.85a.6.6 0 0 1 .213-.95ZM16.947 15.104l-2.846-2.846-.848.849 3.115 3.115a.6.6 0 0 0 .66.128 2.713 2.713 0 0 1 2.987.577c.57.576.835 1.34.79 2.09l-1.038-.96a.6.6 0 0 0-.407-.16h-1.662a.6.6 0 0 0-.6.6v1.662a.6.6 0 0 0 .168.416l.96 1a2.702 2.702 0 0 1-2.063-.792 2.728 2.728 0 0 1-.58-2.99.6.6 0 0 0-.127-.658l-3.116-3.116-.848.849 2.846 2.846a3.928 3.928 0 0 0 .974 3.915l.001.001a3.913 3.913 0 0 0 4.306.835.6.6 0 0 0 .198-.969l-1.519-1.578v-.82h.827l1.613 1.493a.6.6 0 0 0 .96-.205 3.928 3.928 0 0 0-.832-4.305l-.002-.001a3.909 3.909 0 0 0-3.917-.976Z" />
  </svg>
);

Maintenance.defaultProps = {
  width: 24,
  height: 25,
  color: colors.primary,
};

Maintenance.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Maintenance);
