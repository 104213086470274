import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import roundToThousand from '../../../helpers/roundToThousand';
import Stars from '../../../icons/Stars';
import LoadingScreen from '../../LoadingScreen';
import ReviewsIoWidget from '../../ReviewsIoWidget';

import styles from './PremiumTestimonial.module.scss';

const PremiumTestimonial = ({
  className,
  showReviews,
  paragraph,
  name,
  landlordOf,
  landlordCount,
  loading,
}) => {
  const totalLandlordCount = roundToThousand(landlordCount);

  return (
    <LoadingScreen
      loading={loading}
      className={cx(styles.container, className)}
    >
      <h4 className={styles.title}>
        {totalLandlordCount?.toLocaleString()}+ landlords trust TurboTenant
      </h4>
      {showReviews ? (
        <a
          target="_blank"
          href="https://www.reviews.io/company-reviews/store/turbotenant-com"
          rel="noopener noreferrer"
          className={styles.reviews}
        >
          <ReviewsIoWidget />
        </a>
      ) : (
        <Stars className={styles.stars} />
      )}
      {paragraph && name && (
        <>
          <p className={styles.paragraph}>{paragraph}</p>
          <p className={styles.name}>- {name} -</p>
          <p className={styles.lanlordOf}>{landlordOf}</p>
        </>
      )}
    </LoadingScreen>
  );
};

PremiumTestimonial.propTypes = {
  showReviews: PropTypes.bool,
  paragraph: PropTypes.string,
  name: PropTypes.string,
  landlordOf: PropTypes.string,
  landlordCount: PropTypes.number,
  loading: PropTypes.bool,
};

export default PremiumTestimonial;
