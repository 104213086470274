import config from '../../config';

export const PREMIUM_SUBSCRIPTION_SUPPORT_NUMBER = '(970) 519-5037';
export const APP_STORE_LANDLORD_URL =
  'https://turbo-tenant.app.link/transactional/email/footer/app/store';
export const APP_STORE_RENTER_URL =
  'https://apps.apple.com/us/app/turbotenant-for-renters/id6476510444';
export const PLAY_STORE_LANDLORD_URL =
  'https://turbo-tenant.app.link/transactional/email/footer/play/store';
export const PLAY_STORE_RENTER_URL =
  'https://play.google.com/store/apps/details?id=com.turbotenant.renter&hl=en_US&gl=US';
export const APP_STORE_BUTTON_IMG = `${config.assetsBaseUrl}/emails/ios2.png`;
export const PLAY_STORE_BUTTON_IMG = `${config.assetsBaseUrl}/emails/android2.png`;
