import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import RadiationIcon from '../../../../../../../icons/Radiation';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './RadonDisclosure.module.scss';

const RadonDisclosure = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  const {
    input: { value: radonHazard },
  } = useField('custom_state_data.radon_hazard');

  return (
    <>
      <div className={cx(styles.container, className)}>
        <SectionTitle title="Radon Disclosure" icon={RadiationIcon} />
        <label className={styles.label}>
          Was a radon test performed on the property?
        </label>
        <p className={styles.description}>
          Maine law requires that you disclose this and all information shown
          below. As of 3/1/2014, radon must be tested in all rental units every
          10 years. For residential buildings built after 3/1/2014, you must
          have the air tested within 12 months of the occupancy of the tenant.
          As is required by law, a warning on the dangers of radon will appear
          in the lease agreement and a copy of,{' '}
          <a
            target="_blank noopener noreferrer"
            href="https://www.maine.gov/dhhs/mecdc/environmental-health/rad/radon/documents/2019tipsheet11.pdf"
          >
            Radon in Rental Housing - A Serious Hidden Danger to Family Health
          </a>{' '}
          is attached.
        </p>
        <Field
          component={RadioGroup}
          id="custom_state_data.radon_hazard"
          name="custom_state_data.radon_hazard"
          radiosContainerClassName={styles.radioButtonsContainer}
          options={[
            {
              id: 'radon_hazard_yes',
              value: true,
              label: 'Yes',
            },
            {
              id: 'radon_hazard_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />
      </div>
      {radonHazard === true && (
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.radon_hazard_explanation"
          name="custom_state_data.radon_hazard_explanation"
          label={
            <div className={styles.labelContainer}>
              <span className={styles.labelTitle}>
                Please list the following information:
              </span>
              <ol>
                <li>
                  The date of the last radon test performed on the property.{' '}
                </li>
                <li>The radon level found.</li>
                <li>
                  Whether the radon test was performed by a Maine Registered
                  Radon Tester, the landlord or a tenant.
                </li>
                <li>
                  If tested by a Maine Registered Radon Tester, list their Maine
                  Radon ID number.
                </li>
              </ol>
            </div>
          }
          labelProps={{
            className: styles.radonLabel,
          }}
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      )}
    </>
  );
};

RadonDisclosure.propTypes = {
  className: PropTypes.string,
};

export default RadonDisclosure;
