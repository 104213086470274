import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import Modal from '../Modal';

import styles from './UnsavedChangesModal.module.scss';

const UnsavedChangesModal = ({ onConfirm, onCancel }) => {
  return (
    <Modal open className={styles.unsavedChangesModal} id="UnsavedChangesModal">
      <p className={styles.title}>You have unsaved changes.</p>
      <div className={styles.description}>
        Stay to keep your changes. If you leave, your changes will be lost.
      </div>
      <div className={styles.btnContainer}>
        <Button className={styles.btn} onClick={onCancel}>
          Stay
        </Button>
        <Button secondary className={styles.btn} onClick={onConfirm}>
          Leave
        </Button>
      </div>
    </Modal>
  );
};

UnsavedChangesModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default UnsavedChangesModal;
