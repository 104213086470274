import React from 'react';
import PropTypes from 'prop-types';

import getColor from '../utils/getColor';

import styles from './Numbered.module.scss';

const Numbered = ({ color, number = 1, numericColor }) => (
  <div
    className={styles.numericBullet}
    style={{ color: getColor(numericColor), backgroundColor: getColor(color) }}
  >
    {number}
  </div>
);

Numbered.propTypes = {
  color: PropTypes.string,
  numericColor: PropTypes.string,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Numbered;
