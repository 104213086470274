import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const LeaseColored = ({ width, height, color }) => (
  <svg width={width} height={height}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        d="m21.212 48.03 1.212-6.06 13.334-12.121 4.848 4.848-13.333 12.122zM23.636 1.97l9.697 9.697h-9.697z"
      />
      <path
        fill="#042238"
        d="m37.436 29.028 4.003 4.446c.553.614.514 1.55-.086 2.09L27.721 47.838c-.199.18-.445.3-.71.347l-5.578.998a1.515 1.515 0 0 1-1.71-1.9l1.575-5.444c.074-.259.22-.49.418-.67L35.35 28.896c.6-.54 1.534-.48 2.087.133ZM24.64.758a.92.92 0 0 1 .663.283l8.663 8.973.015.015a.951.951 0 0 1 .05.059l-.065-.074a.947.947 0 0 1 .231.4l.009.033a.93.93 0 0 1 .023.125l.006.111V25.64a.941.941 0 0 1-.93.951.941.941 0 0 1-.931-.951V11.635h-9.178a.941.941 0 0 1-.93-.952V2.661H2.466v42.966h13.51c.513 0 .93.426.93.952a.941.941 0 0 1-.93.951H1.537a.941.941 0 0 1-.93-.951V1.709c0-.525.416-.951.93-.951H24.64Zm-1.517 41.705-.05.047-1.36 4.7 4.817-.86.008-.01-3.415-3.877Zm9.756-8.784-8.358 7.526 3.414 3.878 8.358-7.525-3.414-3.88Zm-18.417 2.974c.514 0 .93.426.93.952a.941.941 0 0 1-.93.952h-7.15a.941.941 0 0 1-.93-.952c0-.526.417-.952.93-.952h7.15Zm21.864-6.076-2.05 1.844 3.404 3.866.01.013 2.093-1.884-3.457-3.84Zm-21.864.093c.514 0 .93.427.93.952a.941.941 0 0 1-.93.952h-7.15a.941.941 0 0 1-.93-.952c0-.525.417-.952.93-.952h7.15Zm8.664-8.973c.514 0 .93.426.93.951a.941.941 0 0 1-.93.952H7.313a.941.941 0 0 1-.931-.952c0-.525.417-.951.93-.951h15.814Zm0-5.983c.514 0 .93.426.93.952a.941.941 0 0 1-.93.952H7.313a.941.941 0 0 1-.931-.952c0-.526.417-.952.93-.952h15.814Zm-8.664-5.983c.514 0 .93.427.93.952a.941.941 0 0 1-.93.952h-7.15a.941.941 0 0 1-.93-.952c0-.525.417-.952.93-.952h7.15Zm9.79-7.07h-.126l.001 7.07h6.95l-6.825-7.07Z"
      />
    </g>
  </svg>
);

LeaseColored.defaultProps = {
  width: 42,
  height: 50,
  color: colors.babyBlue,
};

LeaseColored.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(LeaseColored);
