import React from 'react';
import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';

import searchRentersQuery from '../../graphql/queries/searchRentersQuery.graphql';

import SearchTenantField from './SeachTenantField/SearchTenantField';

const SearchTenants = ({
  dropdownClassName,
  defaultAddress,
  showResultsOnFocus,
  suggestedRenters,
  tenants,
  defaultRole,
  inUseValidators,
  skipTenants,
  dataQa,
}) => {
  /**
   * NOTE: I needed the function that runs the searchRentersQuery query
   * to return a promise which is why I ran it using apolloClient.
   * That way i'm able to await for the query result and feed the data
   * into the recepients dropdown list
   */
  const apolloClient = useApolloClient();

  const getOptions = async (value) => {
    if (!value) {
      const results = suggestedRenters.map(
        ({ id, first_name, last_name, profile_pic, email, telephone }) => ({
          id,
          first_name,
          last_name,
          profile_pic,
          renter_id: id,
          email,
          phone: telephone,
          role: 'SUGGESTED RENTERS',
        }),
      );
      return results;
    }

    const {
      data: {
        userData: { ownerRenters },
      },
    } = await apolloClient.query({
      query: searchRentersQuery,
      variables: { search: value },
    });

    const results = (ownerRenters || []).map(
      ({
        id,
        first_name,
        last_name,
        profile_pic,
        address,
        type = '',
        email,
        renter_id,
        telephone,
      }) => ({
        id,
        address,
        first_name,
        last_name,
        profile_pic,
        renter_id,
        email,
        phone: telephone,
        role: type.toUpperCase(),
      }),
    );

    return results;
  };

  return (
    <SearchTenantField
      dropdownClassName={dropdownClassName}
      showResultsOnFocus={showResultsOnFocus}
      getOptions={getOptions}
      defaultRole={defaultRole}
      tenants={tenants}
      inUseValidators={inUseValidators}
      defaultAddress={defaultAddress}
      skipTenants={skipTenants}
      dataQa={dataQa}
    />
  );
};

SearchTenants.propTypes = {
  dropdownClassName: PropTypes.string,
  suggestedRenters: PropTypes.array,
  tenants: PropTypes.array,
  defaultRole: PropTypes.string,
  defaultAddress: PropTypes.string,
  inUseValidators: PropTypes.object,
  skipTenants: PropTypes.array,
  showResultsOnFocus: PropTypes.bool,
  dataQa: PropTypes.string,
};

export default SearchTenants;
