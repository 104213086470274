import fieldRequiredCustom from '../../validators/fieldRequiredCustom';

export const paymentMethodsOfOfflinePayments = [
  { id: 'cash', value: 'cash', label: 'Cash' },
  { id: 'personalCheck', value: 'personalCheck', label: 'Personal check' },
  { id: 'cashiersCheck', value: 'cashiersCheck', label: "Cashier's check" },
  { id: 'moneyOrder', value: 'moneyOrder', label: 'Money order' },
  { id: 'venmo', value: 'venmo', label: 'Venmo' },
  { id: 'zelle', value: 'zelle', label: 'Zelle' },
  {
    id: 'other',
    value: 'other',
    label: 'Other payment service (please specify)',
    additionalInput: {
      id: 'otherServiceName',
      name: 'otherServiceName',
      as: 'input',
      validate: fieldRequiredCustom(
        'Please provide what payment service(s) you will use',
      ),
      placeholder: 'What payment service(s) will you use?',
    },
  },
];

export const trackingOfflinePayments = [
  {
    value: 'manualWithinTT',
    label: 'Manually tracking payments in TurboTenant',
  },
  { value: 'spreadsheets', label: 'Spreadsheets' },
  { value: 'penPaper', label: 'Pen & paper' },
  { value: 'dontTrack', label: "I don't plan on tracking payments" },
  {
    value: 'otherTracking',
    label: 'Other (please specify)',
    additionalInput: {
      id: 'otherTrackingService',
      name: 'otherTrackingService',
      as: 'input',
      validate: fieldRequiredCustom(
        'Please provide what tracking method(s) you will use',
      ),
      placeholder: 'What tracking method(s) will you use?',
    },
  },
];
