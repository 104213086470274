import { Experiments } from '../../constants/experiments';
import getModalContent from '../PremiumSubscription/get-premium-modal-content';

import AccordionVariant from './AccordionVariant';
import {
  FORM_PACK_LOCATIONS,
  GENERAL_LOCATIONS,
  LEASE_AGREEMENT_LOCATIONS,
  LEASE_AND_FORMS_LOCATIONS,
  PAYMENTS_LOCATIONS,
  VACANCY_LOCATIONS,
} from './constants';
import TabVariantGBB from './TabVariantGBB';
import TabVariantPremiumPlus from './TabVariantPremiumPlus';
import ToggleVariant from './ToggleVariant';

export const getModalVersion = (
  variantToRender,
  reihubSubscriptionSubscribed,
) => {
  const modalVariants = {
    [Experiments.GoodBetterBest.variants.A]: TabVariantGBB,
    [Experiments.GoodBetterBest.variants.B]: AccordionVariant,
    [Experiments.GoodBetterBest.variants.C]: ToggleVariant,
    [Experiments.GoodBetterBest.variants.D]: reihubSubscriptionSubscribed
      ? TabVariantGBB
      : TabVariantPremiumPlus,
    [Experiments.GoodBetterBest.variants.E]: reihubSubscriptionSubscribed
      ? TabVariantGBB
      : TabVariantPremiumPlus,
  };

  return modalVariants[variantToRender] || (() => null);
};

export const getModalDetails = (
  openLocation,
  user,
  config,
  variantToRender,
) => {
  let title,
    subtitle,
    benefitsOrder = ['marketing', 'leases', 'payments', 'support'];

  let { benefits, testimonial, defaultPricing } = getModalContent(
    openLocation,
    user,
    config,
    variantToRender,
  );

  if (VACANCY_LOCATIONS.includes(openLocation)) {
    title = 'Find the right tenant, faster';
    subtitle =
      'Get access to everything you need to find and keep great tenants';
  } else if (GENERAL_LOCATIONS.includes(openLocation)) {
    title = 'Two plans. Unlimited uses.';
    subtitle =
      'Get the tools you need to find tenants and manage your rental professionally, efficiently, and confidently.';
  } else if (
    [
      ...LEASE_AGREEMENT_LOCATIONS,
      ...LEASE_AND_FORMS_LOCATIONS,
      ...FORM_PACK_LOCATIONS,
    ].includes(openLocation)
  ) {
    title = 'Professionalize your Rental Business';
    subtitle =
      'Stop searching for landlord documents and tools. It’s all here.';
    benefitsOrder = ['leases', 'payments', 'marketing', 'support'];
  } else if (PAYMENTS_LOCATIONS.includes(openLocation)) {
    title = 'Better Rent Payments. Better Everything';
    subtitle =
      'Upgrade and get everything you need to make landlording easier.';
    benefitsOrder = ['payments', 'leases', 'marketing', 'support'];
  }

  const filteredBenefits = benefitsOrder
    .map((key) => benefits.find((b) => b.key === key))
    .filter((benefit) => benefit !== undefined);

  return {
    title,
    subtitle,
    benefits: filteredBenefits,
    testimonial,
    defaultPricing,
  };
};
