import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import Input from '../../../../../../../components/Input';
import InputWithIcon from '../../../../../../../components/InputWithIcon';
import RadioGroup from '../../../../../../../components/RadioGroup';
import PercentageIcon from '../../../../../../../icons/Percentage';
import ResourcesIcon from '../../../../../../../icons/resources';
import amountGreaterThenZero from '../../../../../../../validators/amountGreaterThenZero';
import composeValidators from '../../../../../../../validators/composeValidators';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import fieldRequiredShortStandard from '../../../../../../../validators/fieldRequiredShort';
import isLowerThan from '../../../../../../../validators/isLowerThan';
import isNumber from '../../../../../../../validators/isNumber';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './BankInfo.module.scss';

const BankInfo = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequiredShort = conditionalValidation(
    fieldRequiredShortStandard,
    !standAloneWizard,
  );
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  const {
    input: { value: bankInfo },
  } = useField('custom_state_data.bank_info');

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <HelpfulInfoCard
          Icon={ResourcesIcon}
          className={styles.helpfulInfoList}
          title="New Jersey Security Deposit Laws"
          collapsable
        >
          <HelpfulInfoList>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The maximum security deposit you can collect is 1.5 times the
              monthly rent.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              If you collect an additional amount of security deposit, the
              annual amount collected cannot be greater than 10% of the current
              security deposit.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Notice is required of the name and address of the investment
              company, state or federally chartered bank or financial
              institution, the type of account, the current interest rate for
              that account, and the amount of the deposit.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              If you own 10 or more rental units, the security deposit must be
              placed in a bank account that pays interest at a rate equal to or
              greater than interest paid on the bank’s money market accounts.
              For owners of 9 or less units, the security deposit must earn
              interest, but there is no requirement on the interest rate.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Interest shall be paid to the tenant or credit given for future
              rent on an annual basis.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The security deposit must be refunded to tenant within{' '}
              <b>30 days</b> after tenant has surrendered the premises, along
              with the tenant’s portion of any interest accrued but not
              previously paid to the tenant, sent by hand delivery, certified or
              registered mail.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              An itemized statement of any deductions must be returned with the
              security deposit.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Security deposit must be refunded to tenant within <b>5 days</b>{' '}
              if tenant is displaced{' '}
              <b>by fire, flood, condemnation, or evacuation</b>.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Security deposit must be refunded to tenant within <b>15 days</b>{' '}
              if tenant is terminating the lease <b>due domestic violence</b>.
            </HelpfulInfoListItem>
          </HelpfulInfoList>
        </HelpfulInfoCard>
        <label className={styles.label}>
          Would you like to provide this information in the rental agreement?
        </label>

        <Field
          component={RadioGroup}
          id="custom_state_data.bank_info"
          name="custom_state_data.bank_info"
          radiosContainerClassName={cx(styles.radioButtonsContainer, {
            [styles.withSpacing]: !!bankInfo,
          })}
          options={[
            {
              id: 'bank_info_yes',
              value: true,
              label: 'Yes',
            },
            {
              id: 'bank_info_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />

        {bankInfo && (
          <>
            <Field
              label="What is the name of the financial institution holding the deposit?"
              className={styles.inputContainer}
              inputProps={{
                className: styles.input,
              }}
              id="deposit_bank_name"
              name="deposit_bank_name"
              component={Input}
              validate={fieldRequiredShort}
            />
            <Field
              label="What is the address of the financial institution holding the deposit?"
              className={styles.inputContainer}
              inputProps={{
                className: styles.input,
              }}
              id="deposit_bank_address"
              name="deposit_bank_address"
              component={Input}
              validate={fieldRequiredShort}
            />

            <Field
              label="What type of account? (e.g. savings, checking)"
              className={styles.inputContainer}
              inputProps={{
                className: styles.input,
              }}
              id="custom_state_data.bank_account_type"
              name="custom_state_data.bank_account_type"
              component={Input}
              validate={fieldRequiredShort}
            />
            <div>
              <Field
                label="What is the current interest rate?"
                className={styles.inputContainer}
                inputProps={{
                  className: styles.interestRateField,
                }}
                id="custom_state_data.interest_rate_account"
                name="custom_state_data.interest_rate_account"
                component={InputWithIcon}
                icon={PercentageIcon}
                iconRight
                validate={composeValidators(
                  fieldRequired,
                  isNumber,
                  amountGreaterThenZero,
                  isLowerThan(1000),
                )}
                type="number"
                step="1"
                pattern="[0-9]*"
                parse={(value) => (value ? parseFloat(value) : value)}
                format={(value) => (value ? parseFloat(value) : value)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

BankInfo.propTypes = {
  className: PropTypes.string,
};

export default BankInfo;
