const ALLOWED_EXTENSIONS = [
  'jpg',
  'png',
  'jpeg',
  'pdf',
  'zip',
  'xls',
  'xlsx',
  'txt',
  'csv',
  'doc',
  'docx',
];

export default ALLOWED_EXTENSIONS;
