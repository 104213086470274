import addUnitSuffix from '../helpers/addUnitSuffix';

function getListingBreadcrumbName({
  listing,
  skipCurrentLevel = false,
  separator = ' | ',
  fromListingId = null,
  showRootLevel = false,
}) {
  if (!listing) {
    return null;
  }

  function getNameAndId(l) {
    const unitSuffix = l.property_role ? addUnitSuffix(l) : '';
    if (l.property_role === 'ROOM')
      return { id: l.id, name: l.nickname || l.room_name };
    if (l.property_role === 'UNIT')
      return { id: l.id, name: l.nickname || l.unit };
    return {
      id: l.id,
      name: l.id && (l.nickname || l.address) + unitSuffix,
    };
  }

  function recurse(parent_listing) {
    if (!parent_listing) return [];
    return [
      ...recurse(parent_listing.parent_listing),
      getNameAndId(parent_listing),
    ];
  }

  const namesArray = [
    // We only show root listing if it's different than parent listing
    ...(showRootLevel &&
    listing?.root_listing?.id !== listing?.parent_listing?.id
      ? recurse(listing.root_listing)
      : []),
    ...recurse(listing.parent_listing),
    getNameAndId(listing),
  ];

  if (skipCurrentLevel) {
    namesArray.shift();
  }

  if (fromListingId) {
    const fromListingIndex = namesArray.findIndex(
      (item) => item.id === fromListingId,
    );
    if (fromListingIndex > 0) {
      namesArray.splice(0, fromListingIndex);
    }
  }

  return namesArray.map((item) => item.name).join(separator);
}

export default getListingBreadcrumbName;
