import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Lock = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} {...props}>
    <path
      d="M5 .333c1.866 0 3.378 1.523 3.378 3.402V5.23l1.217.001c.223 0 .405.183.405.408v7.62a.407.407 0 0 1-.405.408H.405A.407.407 0 0 1 0 13.259v-7.62c0-.225.182-.408.405-.408h1.216V3.734c0-1.816 1.414-3.3 3.194-3.397Zm4.19 5.715H.81v6.802h8.38V6.048ZM5 7.952a.678.678 0 0 1 .596 1l-.001 1.585c0 .226-.224.409-.5.409-.277 0-.5-.183-.5-.409l-.002-1.361A.681.681 0 0 1 5 7.952ZM5 1.15a2.576 2.576 0 0 0-2.568 2.585V5.23h5.136V3.735a2.577 2.577 0 0 0-2.45-2.583Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

Lock.defaultProps = {
  width: 10,
  height: 14,
  color: colors.text,
};

Lock.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Lock);
