import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const SmokingThick = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 17">
    <path
      d="M22.222 11c.491 0 .89.398.89.889v4c0 .49-.399.889-.89.889H3.556a.889.889 0 01-.89-.89v-4c0-.49.399-.888.89-.888zM.89 11a.89.89 0 01.88.758l.009.13v4a.889.889 0 01-1.768.132L0 15.89v-4C0 11.398.398 11 .889 11zM16 12.776H4.444V15H16v-2.224zm5.333 0h-3.556V15h3.556v-2.222zM8.889 3c.49 0 .889.398.889.889a3.556 3.556 0 01-3.33 3.548l-.226.007c-.924 0-1.683.706-1.77 1.607l-.008.171a.889.889 0 11-1.777 0 3.556 3.556 0 013.33-3.548l.225-.007c.924 0 1.684-.705 1.77-1.607L8 3.89C8 3.398 8.398 3 8.889 3zM6.222.333c.491 0 .89.398.89.89 0 1.884-1.605 3.388-3.556 3.388-.932 0-1.68.646-1.77 1.449l-.008.162V7.89A.889.889 0 01.01 8.02L0 7.89V6.222c0-1.884 1.604-3.389 3.556-3.389.932 0 1.679-.645 1.768-1.448l.01-.163c0-.49.397-.889.888-.889z"
      fill={props.color}
      fillRule="evenodd"
    />
  </svg>
);

SmokingThick.defaultProps = {
  width: 24,
  height: 17,
  color: colors.text,
};

SmokingThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(SmokingThick);
