import React from 'react';
import PropTypes from 'prop-types';

import ExpandableCard from '../../../components/ExpandableCard';
import InlineAlert from '../../../components/InlineAlert';
import { AIAuditCategoriesEnum } from '../../../constants/ai-audit-tool';
import colors from '../../../constants/colors';
import STATE_NAMES from '../../../constants/lease_agreement/state-names';
import CheckMarkFilled from '../../../icons/CheckMarkFilled';
import TurboTenantSmallLogo from '../../../icons/TurboTenantSmallLogo';
import { segmentTracking } from '../../../services/utilities';

import AuditItem from './AuditItem';

import styles from './AuditExpandable.module.scss';

const AuditExpandable = ({
  category,
  description,
  state,
  onShowPreview,
  onTriggerModal,
}) => {
  const uncompliantItems = category.items.filter(
    (item) => item.included !== 'yes',
  );

  function getSectionIcon() {
    return uncompliantItems.length ? (
      <div className={styles.uncompliantIcon}>{uncompliantItems.length}</div>
    ) : (
      <CheckMarkFilled
        className={styles.checkmarkIcon}
        color={colors.lightGreenAlt}
        width={20}
        height={20}
      />
    );
  }

  function getNameToDisplay() {
    return category.name === AIAuditCategoriesEnum.MANDATORY_DISCLOSURES
      ? `${category.name} for ${STATE_NAMES[state]}`
      : category.name;
  }

  return (
    <ExpandableCard
      defaultExpanded={
        category.name === AIAuditCategoriesEnum.ESSENTIAL_LEASE_INFORMATION
      }
      className={styles.container}
      title={
        <div className={styles.auditDropdownTitle}>
          {getSectionIcon()} <span>{getNameToDisplay()}</span>
        </div>
      }
      onSetExpanded={(expanded) => {
        if (expanded) {
          segmentTracking(
            category.name.toLowerCase().replace(/ /g, '_') + ' expanded',
            {
              location: category.name,
            },
          );
        }
      }}
    >
      <p className={styles.description}>{description}</p>
      {category.items.map((item) => (
        <AuditItem key={item.item} {...item} className={styles.item} />
      ))}
      {uncompliantItems?.length > 0 && (
        <InlineAlert
          type="info"
          icon={TurboTenantSmallLogo}
          containerClassName={styles.ttAlert}
          titleFontWeight="bold"
        >
          <div className={styles.ttAlertText}>
            TurboTenant adds all required disclosures, clauses, terms, and
            attachments to each lease agreement made with our builder. All
            TurboTenant Lease Agreements are consistently updated with the
            latest legal guidance.
          </div>
          <div
            className={styles.ttAlertCta}
            onClick={() => {
              onShowPreview();
              segmentTracking('explore_compliant_lease clicked', {
                location: category.name,
              });
            }}
          >
            Explore a compliant lease agreement
          </div>
        </InlineAlert>
      )}
      <div className={styles.somethingInaccurate}>
        <span
          onClick={() => {
            onTriggerModal();
            segmentTracking('see_something_inaccurate clicked', {
              location: category.name,
            });
          }}
        >
          See something inaccurate?
        </span>
      </div>
    </ExpandableCard>
  );
};

AuditExpandable.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        item: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        included: PropTypes.oneOf(['yes', 'no', 'indefinite']).isRequired,
      }),
    ).isRequired,
  }).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  state: PropTypes.string,
  onShowPreview: PropTypes.func,
  onTriggerModal: PropTypes.func,
};

export default AuditExpandable;
