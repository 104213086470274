import React, { memo } from 'react';
import PropTypes from 'prop-types';

const PeopleSilhouetteBlue = (props) => (
  <svg {...props} viewBox="0 0 40 38">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#7FE3FF"
        d="M9 23h8v6h-1v8h-5v-8H9zM22 23h8v6h-1v8h-5v-8h-2z"
      />
      <circle fill="#7FE3FF" cx="13.5" cy="16.5" r="2.5" />
      <circle fill="#7FE3FF" cx="26.5" cy="16.5" r="2.5" />
      <path
        d="M5.833 18.174c.46 0 .834.37.834.826v18.174a.83.83 0 0 1-.834.826.83.83 0 0 1-.833-.826V19a.83.83 0 0 1 .833-.826Zm28.334 0c.46 0 .833.37.833.826v18.174a.83.83 0 0 1-.833.826.83.83 0 0 1-.834-.826V19a.83.83 0 0 1 .834-.826ZM17.5 21.477c.46 0 .833.37.833.826v4.959c-.002 1.697-.472 2.85-1.666 3.195v6.715a.83.83 0 0 1-.834.826h-5a.83.83 0 0 1-.833-.826v-6.714c-1.196-.345-1.667-1.499-1.667-3.197v-4.958a.83.83 0 0 1 .834-.826H17.5Zm13.333 0c.46 0 .834.37.834.826v4.959c-.002 1.697-.473 2.85-1.667 3.195v6.715a.83.83 0 0 1-.833.826h-5a.83.83 0 0 1-.834-.826v-6.714c-1.196-.345-1.666-1.499-1.666-3.197v-4.958a.83.83 0 0 1 .833-.826h8.333Zm-14.166 1.652H10v4.132c0 1.256.216 1.652.833 1.652.46 0 .834.37.834.826v6.607H15V29.74a.83.83 0 0 1 .833-.826c.616 0 .832-.397.834-1.652v-4.132Zm13.333 0h-6.667v4.132c0 1.256.216 1.652.834 1.652.46 0 .833.37.833.826v6.607h3.333V29.74a.83.83 0 0 1 .834-.826c.615 0 .831-.397.833-1.652v-4.132Zm-16.667-9.912c1.84 0 3.334 1.48 3.334 3.305 0 1.824-1.493 3.304-3.334 3.304-1.84 0-3.333-1.48-3.333-3.304 0-1.824 1.493-3.305 3.333-3.305Zm13.334 0c1.84 0 3.333 1.48 3.333 3.305 0 1.824-1.493 3.304-3.333 3.304s-3.334-1.48-3.334-3.304c0-1.824 1.493-3.305 3.334-3.305ZM20.562.216l19.167 17.348c.34.307.363.83.053 1.167a.838.838 0 0 1-1.177.053L20 1.944 1.395 18.785a.838.838 0 0 1-1.177-.053.821.821 0 0 1 .053-1.167L19.438.216a.839.839 0 0 1 1.124 0ZM13.333 14.87c-.92 0-1.666.74-1.666 1.652a1.66 1.66 0 0 0 1.666 1.652c.92 0 1.667-.74 1.667-1.652a1.66 1.66 0 0 0-1.667-1.652Zm13.334 0c-.92 0-1.667.74-1.667 1.652a1.66 1.66 0 0 0 1.667 1.652c.92 0 1.666-.74 1.666-1.652a1.66 1.66 0 0 0-1.666-1.652Z"
        fill="#042238"
      />
    </g>
  </svg>
);

PeopleSilhouetteBlue.defaultProps = {
  width: 40,
  height: 38,
};

PeopleSilhouetteBlue.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(PeopleSilhouetteBlue);
