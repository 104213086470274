import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import cx from 'classnames';
import PropTypes from 'prop-types';

import LEASE_END_ACTIONS from '../../../constants/lease_agreement/lease-end-actions';
import getValidFromDate from '../../../helpers/get-valid-from-date';
import getInitialMUPFieldDropdownValues from '../../../helpers/getInitialMUPFieldDropdownValues';
import getListingFromArrayOfTreesById from '../../../helpers/getListingFromArrayOfTreesById';
import LeaseIcon from '../../../icons/Lease';
import { segmentTracking } from '../../../services/utilities/segment';
import fieldRequired from '../../../validators/fieldRequired';
import isDate from '../../../validators/isDate';
import validLeaseEndDate from '../../../validators/validLeaseEndDate';
import validLeaseStartDate from '../../../validators/validLeaseStartDate';
import AddressAutoSuggestFields from '../../AddressAutoSuggestFields';
import Button from '../../Button';
import Checkbox from '../../Checkbox';
import DateField from '../../DateField';
import FinalFormError from '../../FinalFormError';
import Input from '../../Input';
import PropertyUnitRoomDropdowns from '../../PropertyUnitRoomDropdowns';
import RadioGroup from '../../RadioGroup';

import InsuranceStep from './InsuranceStep';

import styles from './LeaseDetailsForm.module.scss';

const NEW_PROPERTY_ID = 'new-property';

const LeaseDetailsForm = ({
  className,
  onSubmit,
  properties,
  onCancel,
  hideCancel,
  defaultListingId,
  fieldIds = {},
  segmentLocation,
  title,
  showInsuranceStep,
  requireInsurance,
  hideHeader,
}) => {
  const [selectedListingId, setSelectedListingId] = useState(
    defaultListingId || null,
  );

  const getSegmentProperty = () => {
    if (!selectedListingId) {
      return 'no property';
    }
    if (selectedListingId === NEW_PROPERTY_ID) {
      return 'new property';
    }
    return 'existing property';
  };

  const defalutListing = getListingFromArrayOfTreesById(
    properties,
    defaultListingId,
  );

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{
        submitting: true,
        values: true,
      }}
      initialValues={{
        ...(defalutListing
          ? getInitialMUPFieldDropdownValues(defalutListing)
          : {}),
        ...(title ? { title } : {}),
        require_renters_insurance:
          showInsuranceStep && !requireInsurance ? true : false,
      }}
    >
      {({ handleSubmit, submitting, values }) => (
        <form
          onSubmit={handleSubmit}
          className={cx(styles.container, className)}
        >
          {!hideHeader && (
            <div className={styles.iconHeader}>
              <div className={styles.icon}>
                <LeaseIcon width={21} height={24} />
              </div>
              <h4 className={styles.iconText}>Lease Details</h4>
            </div>
          )}

          <FinalFormError />

          <OnChange name="listing_id">
            {(value) => {
              setSelectedListingId(value);
            }}
          </OnChange>
          <PropertyUnitRoomDropdowns properties={properties} required />
          {selectedListingId === NEW_PROPERTY_ID && (
            <div className={styles.row}>
              <AddressAutoSuggestFields
                className={styles.autoSuggestHolder}
                streetAddressInputProps={{
                  label: 'Street Address',
                }}
              />
            </div>
          )}

          <Field
            label="Lease Nickname"
            component={Input}
            name="title"
            id={fieldIds.title || 'title'}
            className={styles.leaseTitle}
            validate={fieldRequired}
          />
          <span className={styles.helpText}>
            Most landlords use a combination of the property address and
            tenant’s names (“Main St. #12 - Rodriguez”).
          </span>
          <div className={cx(styles.row, styles.dates)}>
            <Field
              className={styles.smallField}
              label="Start Date"
              component={DateField}
              hint={
                values.end_date && !values.month_to_month ? null : '(Optional)'
              }
              name="start_date"
              id={fieldIds.start_date || 'Start Date'}
              validate={validLeaseStartDate}
            />
            <Field
              className={styles.smallField}
              label="End Date"
              component={DateField}
              hint="(Optional)"
              name="end_date"
              id={fieldIds.end_date || 'End Date'}
              validate={validLeaseEndDate}
              disabled={values.month_to_month}
              fromDate={getValidFromDate(values.start_date)}
            />
          </div>

          <Field
            name="month_to_month"
            type="checkbox"
            id={fieldIds.month_to_month || 'month-to-month'}
            label="Month to Month"
            component={Checkbox}
            className={styles.monthToMonth}
          />
          {!values.month_to_month &&
          values.end_date &&
          !isDate(values.end_date) ? (
            <Field
              component={RadioGroup}
              id="end_action"
              name="end_action"
              label="When the lease ends on the date above, which of these should occur?"
              vertical
              className={styles.radioGroup}
              options={[
                {
                  id: 'end_action_continue',
                  value: LEASE_END_ACTIONS.CONTINUE_MONTHLY,
                  label: 'Go month-to-month automatically',
                },
                {
                  id: 'end_action_terminate',
                  value: LEASE_END_ACTIONS.TERMINATE,
                  label: 'Let the lease terminate on the date above',
                },
              ]}
              validate={fieldRequired}
            />
          ) : null}
          {showInsuranceStep && !requireInsurance && (
            <InsuranceStep
              className={styles.insuranceContainer}
              values={values}
            />
          )}
          <div className={styles.row}>
            {!hideCancel && (
              <Button
                className={cx(styles.button, styles.cancel)}
                onClick={() => {
                  if (segmentLocation) {
                    segmentTracking('cancel clicked', {
                      location: segmentLocation,
                      properties: getSegmentProperty(),
                    });
                  }
                  onCancel();
                }}
                secondary
              >
                Cancel
              </Button>
            )}

            <Button
              className={styles.button}
              type="submit"
              loading={submitting}
              id={fieldIds.submit || 'add_lease'}
              onClick={() => {
                if (segmentLocation) {
                  segmentTracking('add_lease clicked', {
                    location: segmentLocation,
                    properties: getSegmentProperty(),
                  });
                }
              }}
            >
              Add Lease
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};

LeaseDetailsForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  hideCancel: PropTypes.bool,
  properties: PropTypes.array,
  listingId: PropTypes.string,
  initialValues: PropTypes.object,
  fieldIds: PropTypes.shape({
    listing_id: PropTypes.string,
    title: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    month_to_month: PropTypes.string,
    submit: PropTypes.string,
  }),
  segmentLocation: PropTypes.string,
  showInsuranceStep: PropTypes.bool,
  requireInsurance: PropTypes.bool,
};

LeaseDetailsForm.defaultProps = {
  initialValues: {},
};

export default LeaseDetailsForm;
