import get from 'lodash.get';

/**
 * This function ONLY returns ONE lease that is NOT PAST.
 * Keep in mind that if the renter is only in a past leases
 * this function will return null
 *
 * Also if the renter is in multiple current leases it will just
 * return the first that was found in the list
 * @param {*} user
 * @returns
 */
export const getActiveLeaseForRenter = (user) => {
  const activeListingLeases = get(user, 'leases', []) || [];

  let activeListingLease = activeListingLeases.find(
    (lease) => lease.status === 'CURRENT',
  );

  if (!activeListingLease) {
    activeListingLease = activeListingLeases.find(
      (lease) => lease.status === 'ENDING_SOON',
    );
  }

  if (!activeListingLease) {
    activeListingLease = activeListingLeases.find(
      (lease) => lease.status === 'UPCOMING',
    );
  }

  if (!activeListingLease) {
    activeListingLease = activeListingLeases.find(
      (lease) => lease.status === 'DRAFT',
    );
  }

  return activeListingLease;
};
