import { useMutation } from '@apollo/client';

import saveLAQuestionnaireAnswerGql from './saveLAQuestionnaireAnswer.graphql';
import saveRPQuestionnaireAnswerGql from './saveRPQuestionnaireAnswer.graphql';

const useQuestionnaires = () => {
  const [saveLAQuestionnaireAnswer] = useMutation(saveLAQuestionnaireAnswerGql);
  const [saveRPQuestionnaireAnswer] = useMutation(saveRPQuestionnaireAnswerGql);

  return {
    saveLAQuestionnaireAnswer,
    saveRPQuestionnaireAnswer,
  };
};

export default useQuestionnaires;
