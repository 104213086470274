import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useDropdownSubtextMenuContext } from '../../context';

import styles from './Item.module.scss';

const Item = ({ className, onClick, option }) => {
  const { handleSelect } = useDropdownSubtextMenuContext();
  const { value, label, subtext, icon: Icon } = option;

  return (
    <div
      className={cx(styles.container, className)}
      key={value}
      onClick={() => {
        onClick && onClick();
        handleSelect(option);
      }}
    >
      {Icon && (
        <span className={styles.iconWrapper}>
          <Icon />
        </span>
      )}
      <div className={styles.textHolder}>
        <span className={styles.itemLabel}>{label}</span>
        <span className={styles.itemSubtext}>{subtext}</span>
      </div>
    </div>
  );
};

Item.propTypes = {
  className: PropTypes.string,
  option: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    subtext: PropTypes.string,
    icon: PropTypes.elementType,
  }),
  onClick: PropTypes.func,
};

export default Item;
