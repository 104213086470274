import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import Input from '../../../../../../../components/Input';
import { SUB_DIVISION_NAMES_KEYS } from '../../../../../../../constants/lease_agreement/sub-division-names';
import ResourcesIcon from '../../../../../../../icons/resources';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredShortStandard from '../../../../../../../validators/fieldRequiredShort';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './BankInfoWA.module.scss';

const divisionTexts = {
  [SUB_DIVISION_NAMES_KEYS.tacoma]: {
    name: 'Tacoma, Washington',
    listItems: [
      `The total of the security deposit and move-in fees combined cannot exceed one (1) month's rent.`,
      `For lease agreement terms 6 months or longer, tenant can choose to pay their deposit, move-in fees, and last month's rent in 6 equal monthly installments.`,
      'A written receipt is required when you collect a security deposit.',
      'The security deposit must be returned within 30 days of the lease ending.',
      'You must include a written itemized statement of any lawful charges and deductions when you return the security deposit.',
    ],
  },
  [SUB_DIVISION_NAMES_KEYS.seattle]: {
    name: 'Seattle, Washington',
    listItems: [
      `The total of the security deposit and move-in fees combined cannot exceed one (1) month's rent.`,
      `For lease agreement terms 6 months or longer, tenant can choose to pay their deposit, move-in fees, and last month's rent in 6 equal monthly installments.`,
      'A written receipt is required when you collect a security deposit.',
      'The security deposit must be returned within 21 days of the lease ending.',
      'You must include a written itemized statement of any lawful charges and deductions when you return the security deposit.',
    ],
  },
};

const stateTexts = {
  name: 'Washington',
  listItems: [
    'There is no maximum security deposit you can collect.',
    'A written receipt is required when you collect a security deposit.',
    'The security deposit must be returned within 30 days of the lease ending.',
    'You must include a written itemized statement of any lawful charges and deductions when you return the security deposit.',
  ],
};

const BankInfoWA = ({ className, leaseAgreementSelection }) => {
  const division = divisionTexts[leaseAgreementSelection] || stateTexts;
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequiredShort = conditionalValidation(
    fieldRequiredShortStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <HelpfulInfoCard
          Icon={ResourcesIcon}
          className={styles.helpfulInfoCard}
          title={`${division.name} Security Deposit Laws`}
          collapsable
          color="iceCold"
        >
          <HelpfulInfoList>
            {division.listItems.map((item, index) => (
              <HelpfulInfoListItem className={styles.helpfulItem} key={index}>
                {item}
              </HelpfulInfoListItem>
            ))}
            <div className={styles.learnMore}>
              <a
                href="https://support.turbotenant.com/en/articles/6064453-washington-lease-agreement#h_865b122886"
                target="_blank noopener noreferrer"
              >
                Learn other security deposit laws
              </a>
            </div>
          </HelpfulInfoList>
        </HelpfulInfoCard>
        <Field
          label="Bank / Depository Name"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_name"
          name="deposit_bank_name"
          component={Input}
          validate={fieldRequiredShort}
        />
        <Field
          label="Bank / Depository Address"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_address"
          name="deposit_bank_address"
          component={Input}
          validate={fieldRequiredShort}
        />
      </div>
    </div>
  );
};

BankInfoWA.propTypes = {
  className: PropTypes.string,
  leaseAgreementSelection: PropTypes.string,
};

export default BankInfoWA;
