import React from 'react';
import { Field, Form } from 'react-final-form';

import SquareSimpleRadioGroup from '../../../../../../components/SquareSimpleRadioGroup';

import styles from './HowLongStandAlone.module.scss';
const HowLongStandAlone = ({ onSubmit, initialValues }) => {
  return (
    <Form
      onSubmit={(...data) => {
        onSubmit(...data);
      }}
      initialValues={{ ...initialValues }}
      subscription={{
        submitting: true,
        values: true,
        form: true,
      }}
    >
      {({ handleSubmit, form }) => (
        <div className={styles.container}>
          <h2>How long have you managed rentals? </h2>
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Field
              component={SquareSimpleRadioGroup}
              onChange={() => {
                form.submit();
              }}
              submitOnClick={() => {
                form.submit();
              }}
              name="years_of_management"
              options={[
                {
                  label: 'Less than a year',
                  value: 'Less than a year',
                },
                {
                  label: '1-4 years',
                  value: '1-4 years',
                },
                {
                  label: '5-10 years',
                  value: '5-10 years',
                },
                {
                  label: 'More than 10 years',
                  value: 'More than 10 years',
                },
                {
                  label: 'I don’t manage any rentals yet',
                  value: 'I don’t manage any rentals yet',
                },
              ]}
            />
          </form>
        </div>
      )}
    </Form>
  );
};

export default HowLongStandAlone;
