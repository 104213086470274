import React from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';

import toCurrency from '../../../../helpers/toCurrency';
import { useConfig } from '../../../../providers/ConfigProvider';

import styles from './RenterReportedIncome.module.scss';

const RenterReportedIncome = ({ application, history }) => {
  const config = useConfig();

  let income = parseFloat(application?.renter?.income);
  const incomeFrequency = application?.renter?.income_frequency;

  /**
   * Most of the LLs care only about what the income is per month so we are
   * facilitating them the math if the renter reported the income annualy.
   */
  if (incomeFrequency === 'ANNUAL') {
    income = Math.round(income / 12);
  }

  const redirectToApplication = () => {
    if (application?.external) return null;

    const link = `${config.PRIVATE_BASE_PATH}applicant-profile/${application?.renter?.id}==/tab_application?redirectToIncome=true`;
    history.push(link);
  };

  return (
    <>
      <div
        className={cx(styles.reportedIncomeTitle, {
          [styles.link]: !application?.external,
        })}
        onClick={redirectToApplication}
      >
        APPLICANT-REPORTED INCOME
      </div>

      <div className={styles.reportedIncome}>
        {toCurrency(income, false)} / month
      </div>
    </>
  );
};

export default withRouter(RenterReportedIncome);
