import React, { useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useConfig } from '../../../providers/ConfigProvider';

const StripeElementsWrapper = ({ stripeKey, children }) => {
  const { STRIPE_PUBLISHED_KEY } = useConfig();

  const stripePromise = useMemo(
    () => loadStripe(stripeKey || STRIPE_PUBLISHED_KEY),
    [],
  );
  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap',
          },
        ],
      }}
    >
      {children}
    </Elements>
  );
};

export default StripeElementsWrapper;
