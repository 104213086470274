import moment from 'moment';

const defaultMinDate = moment('19000101', 'YYYYMMDD');
const defaultMaxDate = moment('21001231', 'YYYYMMDD');
const dateFormat = 'MM/DD/YYYY';

const isDayInRange = (day, startDate, endDate) => {
  const before = startDate.clone().startOf('day').subtract(1, 'seconds');
  const after = endDate.clone().startOf('day').add(1, 'seconds');
  return day.clone().startOf('day').isBetween(before, after);
};

const getAsDate = (str) => {
  const d = moment(str, dateFormat, true); //strict parsing, if not, validation accepts 04/10/2017asdsadas
  if (!d || !d.isValid()) return false;
  if (str.indexOf(d.format(dateFormat)) < 0) return false;
  if (!isDayInRange(d, defaultMinDate, defaultMaxDate)) {
    return false;
  }
  return d;
};

export const isDate = (value) => {
  if (value && !getAsDate(value)) {
    return 'Must be a valid date.';
  }
  return undefined;
};

export default isDate;
