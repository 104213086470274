import { without } from 'lodash';

import PAYMENT_OPTIONS from '../../../../constants/lease_agreement/payment-options';
import fieldRequiredCustom from '../../../../validators/fieldRequiredCustom';

export const otherPaymentMethods = [
  ...without(PAYMENT_OPTIONS, 'Rent Payments by TurboTenant').map((method) => {
    if (method === 'ACH / Direct Deposit') {
      return {
        value: method,
        label: 'ACH / Direct Deposit (Venmo, Zelle, Paypal, etc.)',
      };
    }
    return {
      label: method,
      value: method,
    };
  }),
  {
    id: 'other',
    value: 'other',
    label: 'Other payment service (please specify)',
    additionalInput: {
      id: 'otherServiceName',
      name: 'other_payments',
      as: 'input',
      validate: fieldRequiredCustom(
        'Please provide what payment service(s) you will use',
      ),
      placeholder: 'What payment service(s) will you use?',
      inputProps: {
        autoFocus: false,
      },
    },
  },
];

export const otherPaymentMethodsValues = otherPaymentMethods.map(
  (_) => _.value,
);
