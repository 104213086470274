import React, { memo } from 'react';
import PropTypes from 'prop-types';

const HelpFill = ({ primaryColor, secondaryColor, ...props }) => (
  <svg {...props} viewBox="0 0 50 50">
    <g fill="none" fillRule="evenodd">
      <path
        d="M32.879 43.182c9.037 0 16.363-9.75 16.363-18.788 0-9.037-7.326-18.788-16.363-18.788-9.038 0-16.364 9.75-16.364 18.788 0 9.037 7.326 18.788 16.364 18.788Z"
        fill={secondaryColor}
      />
      <path
        d="M25 .758C38.388.758 49.242 11.612 49.242 25c0 13.388-10.854 24.242-24.242 24.242C11.612 49.242.758 38.388.758 25 .758 11.612 11.612.758 25 .758Zm0 2.108C12.776 2.866 2.866 12.776 2.866 25S12.776 47.134 25 47.134 47.134 37.224 47.134 25 37.224 2.866 25 2.866Zm0 32.674a2.108 2.108 0 1 1 0 4.217 2.108 2.108 0 0 1 0-4.217Zm0-23.188a7.38 7.38 0 0 1 1.197 14.66l-.143.02v5.346c0 .518-.373.948-.865 1.037l-.189.017c-.517 0-.948-.373-1.037-.864l-.017-.19v-6.324c0-.582.472-1.054 1.054-1.054a5.27 5.27 0 1 0-5.27-5.27 1.054 1.054 0 1 1-2.108 0A7.38 7.38 0 0 1 25 12.352Z"
        fill={primaryColor}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

HelpFill.defaultProps = {
  width: 50,
  height: 50,
  primaryColor: '#042238',
  secondaryColor: '#7FE3FF',
};

HelpFill.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
};

export default memo(HelpFill);
