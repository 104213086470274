import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const NewWindow = (props) => (
  <svg {...props} viewBox="0 0 18 18" fill={props.color}>
    <path
      d="M8.267 1.79a.671.671 0 110 1.343H1.343V16.66h12.545V9.726a.671.671 0 011.344 0v7.606c0 .371-.3.671-.672.671H.672a.671.671 0 01-.672-.67V2.461c0-.371.3-.671.672-.671zM11.36 0l5.967.003c.371 0 .672.301.672.672l.003 5.968a.67.67 0 11-1.342-.001l-.003-4.352-9.293 9.294a.671.671 0 01-.866.07l-.083-.07a.671.671 0 010-.95l9.288-9.29-4.343-.001a.672.672 0 01-.664-.573l-.007-.099c0-.37.3-.671.67-.671z"
      fillRule="evenodd"
    />
  </svg>
);

NewWindow.defaultProps = {
  width: 10,
  height: 6,
  color: colors.primary,
};

NewWindow.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(NewWindow);
