import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import EnergyEfficient from '../../../../../../../icons/EnergyEfficient';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './EnergyEfficiencyDisclosure.module.scss';

const EnergyEfficiencyDisclosure = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  const {
    input: { value: meetsEnergyEfficiency },
  } = useField('custom_state_data.meets_energy_efficiency');

  return (
    <>
      <div className={cx(styles.container, className)}>
        <SectionTitle
          title="Energy Efficiency Disclosure"
          icon={EnergyEfficient}
        />
        <label className={styles.label}>
          Does this rental unit meet the minimum energy efficiency guidelines
          for rental units in Maine?
        </label>
        <p className={styles.description}>
          Maine law requires that you disclose this and all information shown
          below. For more information, contact Efficiency Maine. The areas below
          are the most important ones and indicate where the unit meets, exceeds
          or falls below the efficiency guidelines suggested for Maine.
        </p>
        <Field
          component={RadioGroup}
          id="custom_state_data.meets_energy_efficiency"
          name="custom_state_data.meets_energy_efficiency"
          radiosContainerClassName={styles.radioButtonsContainer}
          options={[
            {
              id: 'meets_energy_efficiency_yes',
              value: true,
              label: 'Yes',
            },
            {
              id: 'meets_energy_efficiency_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />
      </div>
      {(meetsEnergyEfficiency === true || meetsEnergyEfficiency === false) && (
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.energy_efficiency_explanation"
          name="custom_state_data.energy_efficiency_explanation"
          label={
            <div className={styles.labelContainer}>
              <span className={styles.labelTitle}>
                Please list the following information, if known:
              </span>
              <ol>
                <li>
                  Heating Systems efficiency rating. Also list the type of
                  heating fuel.
                </li>
                <li>
                  Insulation - walls, ceilings, floors, list thickness and
                  whether filled or partially filled.
                </li>
                <li>
                  Windows and Doors - single pane, storm? Insulated doors?
                </li>
                <li>
                  Appliances - refrigerator energy star rating; Gas stove,
                  electric ignition?
                </li>
              </ol>
            </div>
          }
          labelProps={{
            className: styles.energyLabel,
          }}
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      )}
    </>
  );
};

EnergyEfficiencyDisclosure.propTypes = {
  className: PropTypes.string,
};

export default EnergyEfficiencyDisclosure;
