import React from 'react';

function Icon({ width = 40 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 40 40"
      preserveAspectRatio={'xMidYMid slice'}
    >
      <defs>
        <clipPath id="a">
          <path d="M1280 0v1772H0V0h1280z"></path>
        </clipPath>
        <clipPath id="b">
          <path d="M13.5 0C20.956 0 27 8.044 27 15.5 27 22.956 20.956 31 13.5 31S0 22.956 0 15.5C0 8.044 6.044 0 13.5 0z"></path>
        </clipPath>
        <clipPath id="c">
          <path d="M20 0c11.046 0 20 8.955 20 20 0 11.047-8.953 20-20 20C8.955 40 0 31.046 0 20 0 8.956 8.956 0 20 0zm0 1.74C9.917 1.74 1.74 9.916 1.74 20c0 10.085 8.176 18.26 18.26 18.26 10.087 0 18.26-8.173 18.26-18.26C38.26 9.916 30.086 1.74 20 1.74zm0 6.086c.48 0 .87.39.87.87v1.81a5.22 5.22 0 014.347 5.146.87.87 0 11-1.739 0 3.48 3.48 0 00-2.608-3.368v6.919a5.22 5.22 0 010 10.29v1.811a.87.87 0 11-1.74 0v-1.81a5.218 5.218 0 01-4.347-5.146.87.87 0 111.739 0 3.479 3.479 0 002.608 3.368v-6.919a5.218 5.218 0 010-10.29V8.696c0-.48.39-.87.87-.87zm.87 13.153v6.737a3.479 3.479 0 000-6.737zm-1.74-8.695a3.48 3.48 0 000 6.737v-6.737z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#a)" transform="translate(-920 -493)">
        <g clipPath="url(#b)" transform="translate(933 498)">
          <path fill="#7FE3FF" d="M0 0h27v31H0V0z"></path>
        </g>
        <g clipPath="url(#c)" transform="translate(920 493)">
          <path fill="#042138" d="M0 0h40v40H0V0z"></path>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(Icon);
