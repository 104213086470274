import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from '../../../BankInfo.module.scss';

const BankInfo = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.row}>
      <HelpfulInfoCard
        collapsable
        Icon={ResourcesIcon}
        className={styles.helpfulInfoCard}
        title="Oregon Security Deposit Laws"
        color="iceCold"
      >
        Though they're included in the lease agreement, we've highlighted some
        rules you need to know:
        <HelpfulInfoList className={styles.helpfulInfoList}>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Security deposits include any last month’s rent deposit.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Security deposits need to be returned to Tenant within 31 days at
            the termination or expiration of the Lease.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            At that time, you shall provide Tenant with a written accounting of
            any lawful charges or deductions.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Tenant must provide you with their forwarding address, in writing.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Should Tenant fail to provide this forwarding address, you may
            process the unclaimed security deposit in accordance with state law.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            For residents of Portland, receipts must be written for all security
            deposits and the name and location of the bank where the security
            deposits are kept must be shown in the lease.
          </HelpfulInfoListItem>
          <div className={styles.learnMore}>
            <a
              href="http://support.turbotenant.com/en/articles/6191480-oregon-lease-agreement"
              target="_blank noopener noreferrer"
            >
              Learn other security deposit laws
            </a>
          </div>
        </HelpfulInfoList>
      </HelpfulInfoCard>
    </div>
  </div>
);

BankInfo.propTypes = {
  className: PropTypes.string,
};

export default BankInfo;
