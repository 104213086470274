import React from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import cx from 'classnames';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';

import PREVIEWABLE_FILETYPES, {
  PREVIEW_PLACEHOLDER,
} from '../../constants/previewable_filetypes';
import getFileTypeFromUrl from '../../helpers/getFileTypeFromUrl';
import ArrowLeftIcon from '../../icons/ArrowLeft';
import ArrowRightIcon from '../../icons/ArrowRight';
import RemoveIcon from '../../icons/Remove';
import RotateIcon from '../../icons/Rotate';
import { useConfig } from '../../providers/ConfigProvider';
import IconButton from '../IconButton';

import styles from './PhotoUploadPreview.module.scss';

const reorder = (list, source, destination) => {
  const newList = [...list];
  const [item] = newList.splice(source, 1);
  newList.splice(destination, 0, item);
  return newList;
};

const ImageHandle = SortableHandle(({ src, style }) => (
  <div className={styles.imageWrap}>
    <img src={src} style={style} />
  </div>
));

const Image = SortableElement(
  ({
    src,
    rotation = 0,
    rotate,
    fileIndex,
    featured,
    onMove,
    onRotate,
    onRemove,
    preview,
  }) => (
    <div
      className={cx(styles.image, {
        [styles.featured]: featured,
      })}
    >
      <ImageHandle
        src={src}
        style={{
          transform: `rotate(${-90 * rotation}deg)`,
        }}
      />
      {preview ? null : (
        <>
          {rotate ? (
            <IconButton
              icon={RotateIcon}
              onClick={(e) => {
                e.preventDefault();
                onRotate({
                  index: fileIndex,
                  rotation: (rotation + 1) % 4,
                });
              }}
              className={cx(styles.icon, styles.rotate)}
            />
          ) : null}

          <IconButton
            icon={RemoveIcon}
            onClick={(e) => {
              e.preventDefault();
              onRemove(fileIndex);
            }}
            className={cx(styles.icon, styles.remove)}
          />
          <IconButton
            icon={ArrowLeftIcon}
            onClick={(e) => {
              e.preventDefault();
              onMove({
                direction: 'left',
                index: fileIndex,
              });
            }}
            className={cx(styles.icon, styles.left)}
          />
          <IconButton
            icon={ArrowRightIcon}
            onClick={(e) => {
              e.preventDefault();
              onMove({
                direction: 'right',
                index: fileIndex,
              });
            }}
            className={cx(styles.icon, styles.right)}
          />
        </>
      )}
    </div>
  ),
);

const SortableList = SortableContainer(
  ({
    files,
    getPreviewUrl,
    rotate,
    onRemove,
    onRotate,
    onMove,
    featured,
    preview,
  }) => (
    <div
      className={cx(styles.preview, {
        [styles.featured]: featured,
      })}
    >
      {files.map((file, index) => (
        <Image
          rotate={rotate}
          preview={preview}
          key={`${file.id || file}`}
          index={index}
          featured={index === 0 && featured}
          src={getPreviewUrl(file)}
          rotation={file.rotation}
          onRemove={onRemove}
          fileIndex={index}
          onRotate={onRotate}
          onMove={onMove}
        />
      ))}
    </div>
  ),
);

const PhotoUploadPreview = ({
  featured,
  rotate,
  getPreviewUrl,
  input: { value, onChange },
  preview,
}) => {
  const { ASSET_BUCKET_URL } = useConfig();
  const files = value;

  const getPreviewUrlFallback = (file) => {
    const isPreviewable = PREVIEWABLE_FILETYPES.includes(
      getFileTypeFromUrl(file.url.params?.key || file.url || ''),
    );

    if (!isPreviewable) {
      return `${ASSET_BUCKET_URL}${PREVIEW_PLACEHOLDER}`;
    }

    if (isString(file.url)) {
      return file.url;
    } else {
      const {
        url: {
          endpoint_url,
          params: { key },
        },
      } = file;
      return `${endpoint_url}/${encodeURIComponent(key)}`;
    }
  };

  if (!files || files.length === 0) {
    return null;
  }
  return (
    <SortableList
      featured={featured}
      files={files}
      getPreviewUrl={getPreviewUrl || getPreviewUrlFallback}
      preview={preview}
      useDragHandle
      onChange={onChange}
      onSortEnd={({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
          onChange(reorder(files, oldIndex, newIndex));
        }
      }}
      rotate={rotate}
      onRemove={(index) => {
        onChange([...files.slice(0, index), ...files.slice(index + 1)]);
      }}
      onMove={({ index, direction }) => {
        const next = direction === 'left' ? index - 1 : index + 1;
        if (next >= files.length || next < 0) {
          return;
        }
        onChange(reorder(files, index, next));
      }}
      onRotate={({ index, rotation }) => {
        onChange([
          ...files.slice(0, index),
          {
            ...files[index],
            rotation,
          },
          ...files.slice(index + 1),
        ]);
      }}
      helperClass={styles.dragging}
      axis="xy"
    />
  );
};

PhotoUploadPreview.propTypes = {
  input: PropTypes.object,
  getPreviewUrl: PropTypes.func,
  featured: PropTypes.bool,
  rotate: PropTypes.bool,
  preview: PropTypes.bool,
};

export default PhotoUploadPreview;
