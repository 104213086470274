import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import InsuranceThickIcon from '../../../../icons/InsuranceThick';
import TurboTenantSmallLogo from '../../../../icons/TurboTenantSmallLogo';
import SectionTitle from '../../../../pages/digital-leases/common/SectionTitle';
import { useConfig } from '../../../../providers/ConfigProvider';
import fieldRequired from '../../../../validators/fieldRequired';
import HelpfulInfoCard from '../../../HelpfulInfo';
import RadioGroup from '../../../RadioGroup';
import Tag from '../../../Tag';

import styles from './InsuranceStep.module.scss';

const InsuranceStep = ({ className, values }) => {
  const config = useConfig();

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle
        title="Renters Insurance"
        icon={InsuranceThickIcon}
        iconProps={{ height: 27, width: 27 }}
      />
      <Field
        component={RadioGroup}
        id="require_renters_insurance"
        name="require_renters_insurance"
        label={
          <>
            <h4 className={styles.labelTitle}>
              Do you require renter's insurance?
            </h4>
            <p className={styles.labelText}>
              Requiring renters insurance could save you and your tenants
              thousands. That's why 72% of TurboTenant landlords require it.{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
                href="https://www.turbotenant.com/blog/renters-insurance-the-benefits-for-landlords/"
              >
                Learn what it could mean for you if you don't.
              </a>
            </p>
          </>
        }
        vertical
        className={styles.radioGroup}
        options={[
          {
            id: 'require_renters_insurance_yes',
            value: true,
            label: (
              <>
                Yes <Tag className={styles.recommended}>Recommended</Tag>
              </>
            ),
          },
          {
            id: 'require_renters_insurance_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      {values.require_renters_insurance ? (
        <HelpfulInfoCard
          className={styles.helpfulInfoCard}
          noTitle
          Icon={TurboTenantSmallLogo}
          iconProps={{ className: styles.cardLogo }}
        >
          We'll automatically ask your tenants to upload their own insurance
          policy or use our preferred partner Sure. If they use Sure, our direct
          integration makes it easy for you to see if the policy is active, the
          coverage amount, and more, all within TurboTenant!{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://support.turbotenant.com/en/articles/6467678-benefits-of-sure-renter-s-insurance"
            className={styles.link}
          >
            Learn about all the benefits of Sure.
          </a>
        </HelpfulInfoCard>
      ) : (
        <HelpfulInfoCard
          className={cx(styles.helpfulInfoCard, styles.bgOrange)}
          noTitle
          Icon={TurboTenantSmallLogo}
          iconProps={{ className: styles.cardLogo }}
        >
          Are you sure? This could increase your risk for claims on your
          landlord insurance policy for theft, damage and injury. Though
          affordable at about ${config.INSURANCE_PRICE}/month, most tenants do
          not purchase insurance if not required.
        </HelpfulInfoCard>
      )}
    </div>
  );
};

InsuranceStep.propTypes = {
  className: PropTypes.string,
  values: PropTypes.object.isRequired,
};

export default InsuranceStep;
