import PARKING_RULES from '../../../../constants/lease_agreement/parking-rules';
import { PROCEDURE_INITIAL_VALUE } from '../../sections/SectionAdditionalProvisions/constants/procedureInitialValues';
import { propertyTypeDropdownOptions } from '../helpers/propertyTypeDropdownOptions';

export const getInitialStandaloneLAData = (currentState) => {
  const utilities = [
    {
      name: 'ELECTRICITY',
      responsible: 'NOT_APPLICABLE',
    },
    {
      name: 'INTERNET',
      responsible: 'NOT_APPLICABLE',
    },
    {
      name: 'PHONE',
      responsible: 'NOT_APPLICABLE',
    },
    {
      name: 'CABLE_SATELLITE',
      responsible: 'NOT_APPLICABLE',
    },
    {
      name: 'GAS',
      responsible: 'NOT_APPLICABLE',
    },
    {
      name: 'WATER',
      responsible: 'NOT_APPLICABLE',
    },
    {
      name: 'SEWER_SEPTIC',
      responsible: 'NOT_APPLICABLE',
    },
    {
      name: 'TRASH',
      responsible: 'NOT_APPLICABLE',
    },
    {
      name: 'LAWN_CARE',
      responsible: 'NOT_APPLICABLE',
    },
    {
      name: 'SNOW_REMOVAL',
      responsible: 'NOT_APPLICABLE',
    },
    {
      name: 'HOA_CONDO_FEE',
      responsible: 'NOT_APPLICABLE',
    },
  ];

  const keys = [
    {
      type: 'Property',
      copies: '',
    },
  ];

  const initialBedbugDisclosure = PROCEDURE_INITIAL_VALUE[currentState];

  const initialValues = {
    utilities,
    keys,
    one_time_fees: [
      {
        name: '',
        amount: '',
      },
    ],
    additional_occupants: [
      {
        name: '',
        relationship: '',
        age: '',
      },
    ],
    pets: [
      {
        type: '',
        breed: '',
        weight: '',
        age: '',
      },
    ],
    custom_state_data: {
      ...(initialBedbugDisclosure
        ? {
            bed_bug_procedure: initialBedbugDisclosure,
          }
        : {}),
      parking_rules: PARKING_RULES,
    },
    propertyDetails: {
      property_type: propertyTypeDropdownOptions[0].value,
      state: currentState,
    },
    tenants: [],
    skip_tenants: [],
    landlords: [],
  };

  return initialValues;
};
