import React, { memo } from 'react';
import PropTypes from 'prop-types';

const StatusCheckCircleFilled = (props) => (
  <svg {...props} viewBox="0 0 24 24">
    <g
      stroke={props.color}
      fill={props.fill}
      fillOpacity={props.fill ? '1' : '0'}
      fillRule="non-zero"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    >
      <circle cx="12" cy="12" r="11.5" />
      <path d="M17 8.5l-7.5 7-2.5-2.5" />
    </g>
  </svg>
);

StatusCheckCircleFilled.defaultProps = {
  width: 24,
  height: 24,
  color: 'currentColor',
};

StatusCheckCircleFilled.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default memo(StatusCheckCircleFilled);
