import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const DownloadIcon = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Icon / Streamline / DownloadIcon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.77778 10.8889C8.14597 10.8889 8.44444 11.1874 8.44444 11.5556C8.44444 11.9237 8.14597 12.2222 7.77778 12.2222H5.33333V20.6667H18.2222V12.2222H15.7778C15.4403 12.2222 15.1613 11.9714 15.1172 11.646L15.1111 11.5556C15.1111 11.1874 15.4096 10.8889 15.7778 10.8889H18.8889C19.2571 10.8889 19.5556 11.1874 19.5556 11.5556V21.3333C19.5556 21.7015 19.2571 22 18.8889 22H4.66667C4.29848 22 4 21.7015 4 21.3333V11.5556C4 11.1874 4.29848 10.8889 4.66667 10.8889H7.77778ZM11.7778 2C11.8786 2 11.9742 2.02238 12.0599 2.06245C12.0988 2.08057 12.1365 2.10278 12.1721 2.129C12.216 2.16134 12.2554 2.19846 12.2899 2.23982L12.2984 2.25047L15.8539 6.69491C16.0839 6.98242 16.0373 7.40195 15.7498 7.63196C15.4623 7.86196 15.0428 7.81535 14.8128 7.52784L12.444 4.568L12.4444 16.5467C12.4444 16.8814 12.1978 17.1585 11.8763 17.2061L11.7778 17.2133C11.4096 17.2133 11.1111 16.9149 11.1111 16.5467L11.111 4.566L8.7428 7.52784C8.5358 7.7866 8.17528 7.85023 7.89565 7.6927L7.80576 7.63196C7.51825 7.40195 7.47164 6.98242 7.70164 6.69491L11.2572 2.25047C11.2784 2.2243 11.2954 2.20581 11.3134 2.18833C11.3203 2.18175 11.3268 2.17565 11.3334 2.1697C11.3511 2.15387 11.37 2.13865 11.3898 2.12448C11.396 2.11993 11.4022 2.11564 11.4085 2.11147C11.5192 2.03947 11.6439 2 11.7778 2Z"
        fill={color}
      />
    </g>
  </svg>
);

DownloadIcon.defaultProps = {
  width: 24,
  height: 24,
  color: colors.primary,
};

DownloadIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(DownloadIcon);
