import React from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useConfig } from '../../providers/ConfigProvider';
import { segmentTracking } from '../../services/utilities/segment';
import composeValidators from '../../validators/composeValidators';
import fieldRequired from '../../validators/fieldRequired';
import validEmail from '../../validators/validEmail';
import validPassword from '../../validators/validPassword';
import Button from '../Button';
import Checkbox from '../Checkbox';
import FinalFormError from '../FinalFormError';
import Input from '../Input';
import trimEmail from '../Input/trimEmail';
import PasswordInput from '../PasswordInput';

import styles from './LoginForm.module.scss';

const LoginForm = ({
  onSubmit,
  className,
  hideRememberMe = false,
  hideForgotPassword = false,
  prefilledEmail = null,
  segment = {},
  history,
  forgotToBackUrl,
}) => {
  const config = useConfig();

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{
        submitting: true,
      }}
    >
      {({ handleSubmit, submitting }) => (
        <form
          onSubmit={handleSubmit}
          className={cx(styles.container, className)}
        >
          <FinalFormError />
          <Field
            label="Email"
            component={Input}
            type="email"
            name="user"
            id="user"
            className={styles.email}
            parse={trimEmail}
            validate={composeValidators(validEmail, fieldRequired)}
            inputProps={{
              autoComplete: 'username',
            }}
            defaultValue={prefilledEmail}
          />
          <Field
            label="Password"
            component={PasswordInput}
            name="password"
            id="password"
            validate={composeValidators(fieldRequired, validPassword)}
            inputProps={{
              autoComplete: 'new-password',
            }}
          />
          <div className={styles.row}>
            {!hideRememberMe && (
              <Field
                label="Remember Me"
                component={Checkbox}
                id="remember"
                name="remember"
                className={styles.checkbox}
              />
            )}
            {!hideForgotPassword && (
              <Link
                to={`/auth${
                  (history?.location?.pathname || '').match(
                    /(\/academy|\/forum)\//,
                  )?.[1] || ''
                }/resetpassword/reset${
                  forgotToBackUrl ? `?backToUrl=${forgotToBackUrl}` : ''
                }`}
                className={styles.link}
              >
                Forgot Password?
              </Link>
            )}
          </div>
          <Button
            className={styles.submit}
            type="submit"
            loading={submitting}
            onClick={() => {
              segmentTracking(segment?.event || 'login clicked', {
                location:
                  segment?.location ||
                  `${config.IS_RENTER ? 'Renter' : 'Landlord'} Login`,
              });
            }}
            id="login_submit"
          >
            LOG IN
          </Button>
        </form>
      )}
    </Form>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hideRememberMe: PropTypes.bool,
  prefilledEmail: PropTypes.string,
  className: PropTypes.string,
  segment: PropTypes.object,
  history: PropTypes.object,
  forgotToBackUrl: PropTypes.string,
  hideForgotPassword: PropTypes.bool,
};

export default LoginForm;
