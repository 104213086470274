import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from './OneTimeFeeHelperAZ.module.scss';

const OneTimeFeeHelperAZ = ({ className }) => {
  return (
    <HelpfulInfoCard
      Icon={ResourcesIcon}
      className={cx(styles.helpfulInfoCard, className)}
      title="Arizona Non-Refundable Fee Law"
      collapsable
    >
      <p className={styles.desc}>
        If non-refundable fees are charged in the lease for pets or other
        one-time expenses like access to amenities, they must be stated to be
        non refundable in the lease. Otherwise, they are subject to a refund
        upon termination of the lease.
      </p>
    </HelpfulInfoCard>
  );
};

OneTimeFeeHelperAZ.propTypes = {
  className: PropTypes.string,
};

export default OneTimeFeeHelperAZ;
