import React from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import LoadingScreen from '../../../../../components/LoadingScreen';
import { useErrorToast } from '../../../../../components/Toast';
import ListingModeEnum from '../../../../../constants/enums/ListingModeEnum';
import { useLeases, useListings } from '../../../../../core/TTgraphql';
import createLeaseGraphql from '../../../../../graphql/mutations/createLeaseWithRenters.graphql';
import leasesQuery from '../../../../../graphql/queries/leasesQuery.graphql';
import clearMask from '../../../../../helpers/clear-mask';
import convertServerDate from '../../../../../helpers/convert-server-date';
import removeTypeNames from '../../../../../helpers/remove-type-names';
import { useRentPaymentRules } from '../../../../../hooks/useRentPaymentRules';
import LeaseDetailsNewOnboarding from '../forms/LeaseDetailsNewOnboarding';

import styles from './LeasesOnboardingWrapper.module.scss';

const LeasesOnboardingWrapper = ({ onNext, isEdit, ...rest }) => {
  const showErrorMessage = useErrorToast();
  const { wantsOfflineTracking } = useRentPaymentRules();
  const [createLeaseMutation] = useMutation(createLeaseGraphql);
  const { leases = [], loading: loadingLeases } = useLeases();
  const { listings } = useListings(
    { variables: { mode: ListingModeEnum.LEAVES_ONLY } },
    true,
  );

  /**
   * @NOTICE:
   *
   * We are assuming the user will never have more than one during the onboarding.
   */
  const listing = listings[0] ? { ...listings[0] } : {};
  const { address, unit, state, city, zip } = listing;

  const lease = leases[0]
    ? { ...leases[0] }
    : {
        title: address,
        address,
        unit,
        state,
        city,
        zip,
        listing: listing,
      };

  lease.title = lease?.title || listing?.address;

  const onSubmit = async (data) => {
    const {
      address,
      unit,
      city,
      state,
      zip,
      title,
      start_date,
      end_date,
      end_action,
      month_to_month,
      partial_payments,
      autopay_enabled,
      listing,
      id: leaseId,
    } = data;

    const renters = (data.renters || []).map(
      ({ telephone, invite_type, ...r }) => ({
        ...r,
        telephone: telephone ? clearMask(telephone) : null,
      }),
    );

    const newRentersSet = new Set(renters.map(({ id }) => id));

    const renters_to_be_deleted = lease?.renters?.filter(
      ({ id }) => !newRentersSet.has(id),
    );

    renters_to_be_deleted?.map((renter) => {
      renter.invite_type && delete renter.invite_type;
    });

    const inputData = {
      title,
      end_date: month_to_month ? null : end_date,
      start_date,
      partial_payments,
      autopay_enabled,
      listing_id: listing?.id,
      listing: {
        id: listing?.id,
        address,
        city,
        state,
        unit,
        zip,
      },
      renters_to_be_created: renters,
      renters_to_be_deleted,
      should_send_renters_welcome_email: wantsOfflineTracking,
      end_action: !month_to_month && end_date ? end_action : null,
    };

    try {
      // NOTICE: The name of this mutation is misleading since it executes also
      //         when going back and editing.
      const res = await createLeaseMutation({
        variables: { id: leaseId, lease: removeTypeNames(inputData) },
        refetchQueries: [{ query: leasesQuery }],
        awaitRefetchQueries: true,
      });

      onNext(res?.data?.upsertLease?.lease?.id || leaseId);
    } catch (error) {
      console.log(error);
      showErrorMessage('Something went wrong');
    }
  };

  /**
   * @NOTICE:
   *
   * The listing id, could cause problems if is passed when the lease
   * do not exist yet.
   */
  const { id, ...restOfListing } = listing;
  const initialValues = {
    ...restOfListing,
    ...lease,
    start_date: convertServerDate(lease?.start_date),
    end_date: lease?.month_to_month ? '' : convertServerDate(lease?.end_date),
    month_to_month: lease?.month_to_month,
    end_action: lease?.end_action,
  };

  if (!loadingLeases && lease?.id != null) {
    initialValues.add_tenants = lease?.renters?.length > 0;

    const rentersWithInviteType = initialValues.renters?.map((renter) => {
      let type = 'both';
      if (renter.email && !renter.telephone) {
        type = 'email';
      } else if (!renter.email && renter.telephone) {
        type = 'text';
      }
      return { ...renter, invite_type: type };
    });

    initialValues.renters = rentersWithInviteType;
  }

  return (
    <LoadingScreen loading={loadingLeases} className={styles.column}>
      <LeaseDetailsNewOnboarding
        submitLabel={isEdit ? 'Save' : null}
        onSubmit={onSubmit}
        {...rest}
        initialValues={initialValues}
      />
    </LoadingScreen>
  );
};

LeasesOnboardingWrapper.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(LeasesOnboardingWrapper);
