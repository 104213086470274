const LEASE_PROPERTY_TYPE = {
  SINGLE_FAMILY: 'SINGLE_FAMILY',
  APARTMENT: 'APARTMENT',
  CONDO: 'CONDO',
  MULTI_FAMILY: 'MULTI_FAMILY',
  MANUFACTURED: 'MANUFACTURED',
  VACANT_LAND: 'VACANT_LAND',
  CO_OP: 'CO_OP',
  TOWNHOUSE: 'TOWNHOUSE',
  OTHER: 'OTHER',
};

export const LEASE_PROPERTY_TYPE_LABELS = {
  [LEASE_PROPERTY_TYPE.SINGLE_FAMILY]: 'Single Family',
  [LEASE_PROPERTY_TYPE.APARTMENT]: 'Apartment',
  [LEASE_PROPERTY_TYPE.CONDO]: 'Condo',
  [LEASE_PROPERTY_TYPE.MULTI_FAMILY]: 'Multi Family',
  [LEASE_PROPERTY_TYPE.MANUFACTURED]: 'Manufactured',
  [LEASE_PROPERTY_TYPE.VACANT_LAND]: 'Vacant Land',
  [LEASE_PROPERTY_TYPE.CO_OP]: 'Co-op',
  [LEASE_PROPERTY_TYPE.TOWNHOUSE]: 'Townhouse',
  [LEASE_PROPERTY_TYPE.OTHER]: 'Other',
};

export default LEASE_PROPERTY_TYPE;
