import {
  blueLight,
  line,
  text,
  textLight,
} from '../../../../../../constants/colors';

export const listItem = (props) => {
  const selectedState =
    props.selectedUser && props.selectedUser.uid === props.user.uid
      ? {
          backgroundColor: blueLight,
        }
      : {};

  return {
    'display': 'flex',
    'flexDirection': 'row',
    'justifyContent': 'left',
    'alignItems': 'center',
    'cursor': 'pointer',
    'width': '100%',
    'padding': '8px 16px',
    'borderBottom': `1px solid ${line}`,
    ...selectedState,
    '&:hover': {
      backgroundColor: blueLight,
    },
  };
};

export const itemThumbnailStyle = () => {
  return {
    display: 'inline-block',
    width: '40px',
    height: '40px',
    flexShrink: '0',
  };
};

export const itemDetailStyle = () => {
  return {
    width: 'calc(100% - 45px)',
    flexGrow: 1,
    paddingLeft: '8px',
  };
};

export const itemNameStyle = () => {
  return {
    fontSize: '14px',
    fontWeight: 'bold',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: '21px',
    letterSpacing: 0,
    color: text,
    fontFamily: 'Open Sans',
  };
};

export const renterTypeLabelStyle = () => {
  return {
    color: textLight,
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '18px',
    textTransform: 'capitalize',
  };
};
