import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import PixelTracking from '../PixelTracking';

import styles from './SidebarMarketing.module.scss';

const SidebarMarketing = ({
  title,
  subtitle,
  className,
  titleClassName,
  graphic,
  description,
  ...otherProps
}) => (
  <div className={cx(styles.container, className)} {...otherProps}>
    <span className={cx(styles.title, titleClassName)}>{title}</span>
    <span
      className={cx(styles.subtitle, {
        [styles.withDescription]: !!description,
      })}
    >
      {subtitle}
    </span>
    {!!description && description}
    {graphic && <img src={graphic} alt={title} />}
    <PixelTracking />
  </div>
);

SidebarMarketing.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.node,
  className: PropTypes.string,
  graphic: PropTypes.string,
  description: PropTypes.node,
};

export default SidebarMarketing;
