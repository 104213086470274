import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Footer.module.scss';

const Footer = ({ className, ...rest }) => {
  return <div className={cx(styles.container, className)} {...rest} />;
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
