import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import GlobalBanner from '../../components/Banners/GlobalBanner';
import { LAYOUT_IDS } from '../../constants';
import GlobalComponents from '../../pages/GlobalComponents';
import { useConfig } from '../../providers/ConfigProvider';

const BlankPageLayout = ({ children, history }) => {
  const config = useConfig();

  return (
    <>
      <div>
        {config.IS_OWNER && <GlobalBanner />}
        <>{children}</>
      </div>
      <GlobalComponents history={history} />
    </>
  );
};

BlankPageLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  history: PropTypes.object,
};

BlankPageLayout.layoutId = LAYOUT_IDS.BLANK_PAGE;

export default withRouter(BlankPageLayout);
