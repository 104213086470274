import { isAdminLogin } from '../../core/auth/authService';
import { getSignupTrackingCookie } from '../../helpers/signupTrackingData';
import { getConfig } from '../configService';
import { getItem } from '../localStorageService';

/**
 * NOTE: Some experiments start before a user is authenticated. These do not live under 'active_experiments'
 * since they are set directly into localStorage. 'signup_test_onboarding' is an example of this. Moving
 * forward we should try and set them with a prefix like 'experiment_' so we can easily identify them here.
 */
function retrieveUnAuthExperiments() {
  const toRetrieve = ['signup_test_onboarding'];
  const experiments = Object.keys(localStorage)
    .filter((key) => key.startsWith('experiment_') || toRetrieve.includes(key))
    .reduce((acc, key) => {
      acc[key] = getItem(key);
      return acc;
    }, {});

  return experiments;
}

export function segmentTracking(event, properties) {
  const loggedInAsAdmin = isAdminLogin();
  if (!window.analytics) {
    return;
  }
  if (!loggedInAsAdmin) {
    //grab values from cookie and send them to segment
    const { source, medium, campaign, content, term } =
      getSignupTrackingCookie();

    const experiments = JSON.parse(getItem('active_experiments') || '[]');
    const experimentsAttributes = {};
    experiments.forEach((experiment) => {
      experimentsAttributes[`experiment_${experiment.name}`] = experiment.value;
    });

    window.analytics.track(
      event,
      {
        ...properties,
        utm_source: source,
        utm_medium: medium,
        utm_campaign: campaign,
        utm_content: content,
        utm_term: term,
        ...experimentsAttributes,
        ...(retrieveUnAuthExperiments() || {}),
      },
      {
        context: {
          campaign: {
            source,
            medium,
            campaign,
            content,
            term,
            name: campaign,
          },
        },
      },
    );
  }
}

export function segmentIdentify(globalId, intercomHash, traits = {}) {
  const { IS_OWNER } = getConfig();
  const loggedInAsAdmin = isAdminLogin();
  if (window.analytics && !loggedInAsAdmin) {
    const decodedId = atob(globalId).split(':').pop();
    window.analytics.ready(function () {
      window.analytics.identify(
        `${IS_OWNER ? 'Owner' : 'Renter'}:${decodedId}`,
        { ...traits },
        {
          ...(intercomHash && {
            integrations: {
              Intercom: {
                user_hash: intercomHash,
              },
            },
          }),
        },
        () => {
          //grab values from cookie and send them to segment
          const { source, medium, campaign, content, term } =
            getSignupTrackingCookie();
          window.analytics.page(
            null,
            null,
            {
              utm_source: source,
              utm_medium: medium,
              utm_campaign: campaign,
              utm_content: content,
              utm_term: term,
            },
            {
              context: {
                campaign: {
                  source,
                  medium,
                  campaign,
                  content,
                  term,
                  name: campaign,
                },
              },
            },
          );
        },
      );
    });
  }
}
