import React, { memo } from 'react';
import PropTypes from 'prop-types';

const HouseAdd = ({ color, ...props }) => (
  <svg viewBox="0 0 32 32" {...props}>
    <g fillRule="evenodd" clipRule="evenodd" fill={color}>
      <path d="M6.638 15.196a.8.8 0 0 1 .8.8v11.316h9.113a.8.8 0 1 1 0 1.6H6.638a.8.8 0 0 1-.8-.8V15.996a.8.8 0 0 1 .8-.8Z" />
      <path d="M15.46 3.29a.8.8 0 0 1 1.08 0l12.666 11.564a.8.8 0 0 1-1.079 1.182L16 4.963 3.873 16.036a.8.8 0 0 1-1.08-1.182L15.462 3.29ZM19.606 4.981a.8.8 0 0 1 .8-.8h3.855a.8.8 0 0 1 .8.8v3.856a.8.8 0 1 1-1.6 0V5.78h-3.055a.8.8 0 0 1-.8-.8Z" />
      <path d="M13.548 9.387a.8.8 0 0 1 .8-.8h3.304a.8.8 0 0 1 .8.8v3.305a.8.8 0 0 1-.8.8h-3.304a.8.8 0 0 1-.8-.8V9.387Zm1.6.8v1.705h1.704v-1.705h-1.704ZM23.16 17.897a4.707 4.707 0 1 0 0 9.415 4.707 4.707 0 0 0 0-9.415Zm-6.308 4.708a6.307 6.307 0 1 1 12.614 0 6.307 6.307 0 0 1-12.614 0Z" />
      <path d="M23.16 19.051a.8.8 0 0 1 .8.8v5.507a.8.8 0 1 1-1.6 0v-5.507a.8.8 0 0 1 .8-.8Z" />
      <path d="M19.606 22.605a.8.8 0 0 1 .8-.8h5.507a.8.8 0 0 1 0 1.6h-5.507a.8.8 0 0 1-.8-.8Z" />
    </g>
  </svg>
);

HouseAdd.defaultProps = {
  width: 32,
  height: 32,
  color: 'currentColor',
};

HouseAdd.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(HouseAdd);
