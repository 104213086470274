import React from 'react';
import cx from 'classnames';

import Button from '../Button';
import Line from '../common/Line';

import styles from './AvatarCard.module.scss';

const AvatarCard = ({ className }) => {
  return (
    <div className={cx(styles.skeletonAvatarCard, className, styles.section)}>
      <div className={styles.top}>
        <div className={styles.avatar} />
        <div className={styles.wrapper}>
          <Line size={'thick'} />
        </div>
      </div>
      <div className={styles.bottom}>
        <Line size={'max'} />
        <Line size={'max'} />
        <Button size={'slider'} position={'center'} />
        <div className={styles.dots}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default AvatarCard;
