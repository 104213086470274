import React, { useCallback, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { usePopper } from 'react-popper';
import useOutsideClick from '@rooks/use-outside-click';
import useWindowSize from '@rooks/use-window-size';
import cx from 'classnames';
import get from 'lodash.get';
import moment from 'moment';
import PropTypes from 'prop-types';

import { smallScreenWidth } from '../../constants/media-breakpoints';
import CalendarIcon from '../../icons/Calendar';
import getFieldError from '../../validators/get-field-error';
import Label from '../Label';
import Modal from '../Modal';
import PlainInputWithIcon from '../PlainInputWithIcon';

import DatePicker from './DatePicker';

import styles from './DateField.module.scss';

const DateField = ({
  input,
  id,
  label,
  className,
  meta,
  disabled,
  hint,
  fromDate,
  toDate,
  disableCalendar,
  maskClassName,
  ...otherProps
}) => {
  const error = getFieldError(meta);
  const [calendar, showCalendar] = useState();
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const containerRef = useRef();
  const popper = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 15],
        },
      },
    ],
  });
  const { innerWidth } = useWindowSize();
  const isSmallScreen = innerWidth <= smallScreenWidth;

  const placement = get(popper, 'state.placement');
  let picker;
  const outsideClickHandler = useCallback(() => {
    if (calendar && !isSmallScreen) {
      showCalendar(false);
    }
  }, [calendar, innerWidth]);

  useOutsideClick(containerRef, outsideClickHandler);

  if (calendar) {
    const datePicker = (
      <DatePicker
        value={input.value}
        onChange={(date) => input.onChange(moment(date).format('MM/DD/YYYY'))}
        onClose={() => showCalendar(false)}
        fromDate={fromDate}
        toDate={toDate}
      />
    );

    if (!isSmallScreen) {
      picker = (
        <div
          style={popper.styles.popper}
          {...popper.attributes.popper}
          ref={setPopperElement}
          className={styles.popper}
        >
          {' '}
          {datePicker}
          <div
            ref={setArrowElement}
            style={popper.styles.arrow}
            className={cx(styles.arrow, {
              [styles[placement]]: true,
            })}
          />
        </div>
      );
    } else {
      picker = (
        <Modal
          open={calendar}
          onClose={() => showCalendar(false)}
          className={styles.modal}
          id="date-picker"
        >
          {datePicker}
        </Modal>
      );
    }
  }

  return (
    <div
      ref={containerRef}
      className={cx(styles.container, className, {
        [styles.disabled]: disabled,
      })}
    >
      <div>
        {label ? (
          <Label htmlFor={id} hint={hint}>
            {label}
          </Label>
        ) : null}
        <InputMask
          mask="99/99/9999"
          maskChar="_"
          inputMode="tel"
          className={cx(styles.mask, maskClassName)}
          {...input}
          disabled={disabled}
          {...otherProps}
          onFocus={(e) => {
            if (!isSmallScreen) {
              !disableCalendar && showCalendar(true);
            }

            otherProps.onFocus && otherProps.onFocus(e);
          }}
        >
          {(passProps) => (
            <PlainInputWithIcon
              ref={setReferenceElement}
              iconProps={{
                onClick: () => !disableCalendar && showCalendar(!calendar),
                className: styles.icon,
              }}
              icon={CalendarIcon}
              invalid={!!error}
              id={id}
              type="text"
              className={styles.field}
              disabled={disabled}
              {...passProps}
            />
          )}
        </InputMask>
        {picker}
      </div>
      {!!error && (
        <span id={`${id}_errormsg`} className={styles.error}>
          {error}
        </span>
      )}
    </div>
  );
};

DateField.propTypes = {
  input: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

DateField.defaultProps = {
  placeholder: 'MM/DD/YYYY',
  disabled: false,
  fromDate: new Date(1900, 0, 1),
  toDate: new Date(2100, 0, 1),
  meta: {},
  input: {},
};

export default DateField;
