import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const RadioX = ({ color, ...props }) => (
  <svg {...props} viewBox="0 0 80 80">
    <path
      fill={color}
      fillRule="evenodd"
      d="M40 0c22 0 40 18 40 40S62 80 40 80 0 62 0 40 18 0 40 0ZM26.154 20 20 26.154 33.847 40 20 53.846 26.154 60 40 46.153 53.846 60 60 53.846 46.153 40 60 26.154 53.846 20 40 33.847 26.154 20Z"
    />
  </svg>
);

RadioX.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

RadioX.defaultProps = {
  width: 80,
  height: 80,
  color: colors.error,
};

export default memo(RadioX);
