import React from 'react';
import {
  Field,
  FormSpy,
  useField,
  useForm,
  useFormState,
} from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import AddressAutoSuggestFields from '../../../../../components/AddressAutoSuggestFields';
import FlatButton from '../../../../../components/FlatButton';
import HelpfulInfoCard from '../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import IconTooltip from '../../../../../components/IconTooltip';
import Input from '../../../../../components/Input';
import trimEmail from '../../../../../components/Input/trimEmail';
import PhoneField from '../../../../../components/PhoneField';
import RadioGroup from '../../../../../components/RadioGroup';
import SearchTenants from '../../../../../components/SearchTenants';
import { ADDITIONAL_OCCUPANTS_LIMIT } from '../../../../../constants/lease_agreement/wizard-limitations';
import PropertyRolesEnum from '../../../../../helpers/propertyRolesEnum';
import MinusCircle from '../../../../../icons/MinusCircle';
import PlusCircle from '../../../../../icons/PlusCircle';
import PremiumBannerIcon from '../../../../../icons/PremiumBanner';
import ResourcesIcon from '../../../../../icons/resources';
import TenantsIcon from '../../../../../icons/TenantsThick';
import composeValidators from '../../../../../validators/composeValidators';
import fieldRequiredStandard from '../../../../../validators/fieldRequired';
import fieldRequiredCustomStandard from '../../../../../validators/fieldRequiredCustom';
import fieldRequiredShortStandard from '../../../../../validators/fieldRequiredShort';
import validEmail from '../../../../../validators/validEmail';
import validName from '../../../../../validators/validName';
import validPhone from '../../../../../validators/validPhone';
import AdditionalSignersForm from '../../../../electronic-signature/AdditionalSignersForm';
import SectionTitle from '../../../common/SectionTitle';
import AdditionalOccupantsForm from '../AdditionalOccupants';
import AddTenantField from '../AddTenantField';
import { emailInUseForNewTenantValidator } from '../emailInUseForNewTenantValidator';
import { emailInUseValidator } from '../emailInUseValidator';
import { LandlordEntityType } from '../LandlordEntityType';
import { phoneInUseForNewTenantValidator } from '../phoneInUseForNewTenantValidator';
import { SectionPeopleOnTheLeaseAdditionalLandlordSection } from '../SectionPeopleOnTheLeaseAdditionalLandlordSection';
import { SectionPeopleOnTheLeaseAdditionalOccupantsSection } from '../SectionPeopleOnTheLeaseAdditionalOccupantsSection';
import { SectionPeopleOnTheLeaseLandlordMailingAddressSection } from '../SectionPeopleOnTheLeaseLandlordMailingAddressSection';
import { SectionPeopleOnTheLeaseLandlordSection } from '../SectionPeopleOnTheLeaseLandlordSection';
import { SectionPeopleOnTheLeaseUncommonScenariosSection } from '../SectionPeopleOnTheLeaseUncommonScenariosSection';
import DEMailingInfo from '../state_specifics/DE/MailingInfo';
import IslandAgentPrompt from '../state_specifics/HI/IslandAgentPrompt';
import EmergencyContactPrompt from '../state_specifics/NV/EmergencyContactPrompt';

import COSIGNER_FORM_IMAGE_PREVIEW from './cosigner-agreement-form.png';

import styles from './SectionPeopleOnTheLeaseFields.module.scss';

const roomRentalAdditionalText =
  'For room rentals, do not include occupants that will not be staying in the room.';

const noop = () => {};

const SectionPeopleOnTheLeaseFields = ({
  setPristine,
  propertyRole,
  renters,
  propertyAddress,
  currentState,
  standAloneWizard,
  userIsInCosignerExperiment,
  onLearnMoreClick,
  hasPremiumSubscription,
  isLeaseAgreementPaid,
}) => {
  const form = useForm();
  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  const {
    input: { value: skipTenants },
  } = useField('skip_tenants');

  const {
    input: { value: additionalOccupantsChoice },
  } = useField('additional_occupants_choice');

  const {
    input: { value: landlordChoice },
  } = useField('landlord_choice');

  const {
    input: { value: hasAdditionalLandlord },
  } = useField('hasAdditionalLandlord');

  const {
    input: { value: tenantsAddressChoice },
  } = useField('tenants_address_choice');

  const {
    input: { value: cosignersChoice },
  } = useField('custom_state_data.cosigners_choice');

  const {
    input: { value: additionalSignersChoice },
  } = useField('custom_state_data.additional_cosigners_choice');

  const {
    input: { value: wantsCosignerAgreement },
  } = useField('custom_state_data.wants_cosigner_agreement');

  const {
    input: { value: landlords },
  } = useField('landlords');

  const {
    input: { value: tenants = [] },
  } = useField('tenants');

  const fieldRequired = standAloneWizard ? noop : fieldRequiredStandard;
  const fieldRequiredShort = standAloneWizard
    ? noop
    : fieldRequiredShortStandard;
  const fieldRequiredCustom = standAloneWizard
    ? noop
    : fieldRequiredCustomStandard;

  // We exclude renters that have applied as cosigners
  const suggestedRenters =
    renters?.filter(
      (renter) => renter?.applications[0]?.applying_as_tenant !== false,
    ) || [];

  const rentersWithoutCoSigners =
    renters
      ?.filter(
        (renter) => renter?.applications[0]?.applying_as_tenant !== false,
      )
      .map((renter) => renter.email)
      .filter((email) => typeof email === 'string') || [];

  const filteredRenters =
    renters
      ?.map((renter) => renter.email)
      .filter((email) => typeof email === 'string') || [];

  const filteredLandlords =
    landlords
      ?.map((renter) => renter.email)
      .filter((email) => typeof email === 'string') || [];

  // We are using this one to check for tenants added in the wizard
  // But that are still not created in the backend
  const filteredTenants =
    (tenants &&
      tenants
        ?.map((renter) => renter.email)
        .filter((email) => typeof email === 'string')) ||
    [];

  const cosignersEmailsToExclude = [
    ...filteredRenters,
    ...filteredLandlords,
    ...filteredTenants,
  ];

  const additionalCosignersEmailsToExclude = [
    ...rentersWithoutCoSigners,
    ...filteredLandlords,
    ...filteredTenants,
  ];

  const showExperimentInfo =
    userIsInCosignerExperiment && !hasPremiumSubscription;

  // if LL purchases a la carte option he should not be allowed to attach cosigner form = isLeaseAgreementPaid
  const showCosignerForm = !standAloneWizard && !isLeaseAgreementPaid;

  return (
    <>
      <FormSpy
        subscription={{ pristine: true, values: true }}
        onChange={({ pristine, values }) => {
          setPristine(pristine);

          if (
            values.custom_state_data.cosigners_choice === false &&
            values.custom_state_data.cosigners?.length > 0
          ) {
            form.change('custom_state_data.cosigners', []);
          }

          if (
            values.custom_state_data.additional_cosigners_choice === false &&
            values.custom_state_data.additional_cosigners?.length > 0
          ) {
            form.change('custom_state_data.additional_cosigners', []);
          }
        }}
      />
      <div className={styles.section}>
        <SectionTitle title="Tenants" icon={TenantsIcon} />
        <HelpfulInfoCard
          Icon={ResourcesIcon}
          className={styles.helpfulInfoCard}
          title="What's the difference between tenants and additional occupants?"
        >
          <HelpfulInfoList>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              <b>Tenants</b> live at the property, sign the lease, and are
              financially responsible—typically anyone 18 years of age or older.
            </HelpfulInfoListItem>

            <HelpfulInfoListItem className={styles.helpfulItem}>
              <b>Additional occupants</b> live at the property, either full-time
              or part-time, but do not sign the lease—typically dependents like
              children or elderly relatives.{' '}
              {propertyRole === PropertyRolesEnum.ROOM &&
                roomRentalAdditionalText}
            </HelpfulInfoListItem>
          </HelpfulInfoList>
        </HelpfulInfoCard>
        {standAloneWizard ? (
          <AddTenantField
            defaultAddress={propertyAddress}
            tenants={formValues.tenants}
            inUseValidators={{
              emailInUse: emailInUseForNewTenantValidator(formValues),
              phoneInUse: phoneInUseForNewTenantValidator(formValues),
            }}
            skipTenants={skipTenants}
          />
        ) : (
          <SearchTenants
            suggestedRenters={suggestedRenters}
            defaultRole="Tenant"
            tenants={formValues.tenants}
            skipTenants={skipTenants}
            defaultAddress={propertyAddress}
            inUseValidators={{
              emailInUse: emailInUseForNewTenantValidator(formValues),
              phoneInUse: phoneInUseForNewTenantValidator(formValues),
            }}
            showResultsOnFocus={true}
            dataQa="search-tenants"
          />
        )}
      </div>
      <SectionPeopleOnTheLeaseAdditionalOccupantsSection
        propertyRole={propertyRole}
        roomRentalAdditionalText={roomRentalAdditionalText}
      >
        <Field
          component={RadioGroup}
          id="additional_occupants_choice"
          name="additional_occupants_choice"
          radiosContainerClassName={cx(styles.radioButtonsContainer, {
            [styles.withSpacing]: additionalOccupantsChoice,
          })}
          options={[
            {
              id: 'additional_occupants_choice_yes',
              value: true,
              label: 'Yes',
            },
            {
              id: 'additional_occupants_choice_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />
        {additionalOccupantsChoice && (
          <Field
            component={AdditionalOccupantsForm}
            name="additional_occupants"
            id="additional_occupants"
            limit={ADDITIONAL_OCCUPANTS_LIMIT}
          />
        )}
      </SectionPeopleOnTheLeaseAdditionalOccupantsSection>
      <SectionPeopleOnTheLeaseLandlordSection>
        <Field
          label="Entity Type"
          component={RadioGroup}
          id="landlord_choice"
          name="landlord_choice"
          radiosContainerClassName={cx(
            styles.radioButtonsContainer,
            styles.withSpacing,
          )}
          options={[
            {
              id: 'landlord_choice_individual',
              value: LandlordEntityType.individual,
              label: 'Individual',
            },
            {
              id: 'landlord_choice_company',
              value: LandlordEntityType.company,
              label: 'Company',
            },
          ]}
          validate={fieldRequired}
        />
        {landlordChoice !== LandlordEntityType.company ? (
          <div className={styles.row}>
            <Field
              label="Legal First Name"
              component={Input}
              id="landlords[0].first_name"
              name="landlords[0].first_name"
              validate={composeValidators(fieldRequiredShort, validName)}
              className={cx(
                styles.inputContainer,
                styles.nameField,
                styles.withSpacing,
              )}
              labelProps={{
                className: styles.inputLabel,
              }}
              inputProps={{
                className: styles.input,
              }}
            />
            <Field
              label="Last Name"
              component={Input}
              id="landlords[0].last_name"
              name="landlords[0].last_name"
              validate={composeValidators(fieldRequiredShort, validName)}
              className={cx(styles.inputContainer, styles.nameField)}
              labelProps={{
                className: styles.inputLabel,
              }}
              inputProps={{
                className: styles.input,
              }}
            />
          </div>
        ) : (
          <Field
            label="Company Name"
            component={Input}
            id="company_name"
            name="company_name"
            validate={fieldRequiredShort}
            className={cx(styles.inputContainer, styles.withSpacing)}
            labelProps={{
              className: styles.inputLabel,
            }}
            inputProps={{
              className: cx(styles.input, styles.companyName),
            }}
          />
        )}
        <Field
          label="Email"
          component={Input}
          id="landlords[0].email"
          name="landlords[0].email"
          parse={trimEmail}
          validate={composeValidators(
            fieldRequiredShort,
            validEmail,
            emailInUseValidator(false),
          )}
          className={cx(styles.inputContainer, styles.withSpacing)}
          labelProps={{
            className: styles.inputLabel,
          }}
          inputProps={{
            className: cx(styles.input, styles.landlordEmail),
          }}
        />
        <Field
          label="Phone"
          component={PhoneField}
          name="landlords[0].phone"
          id="landlords[0].phone"
          className={styles.phone}
          validate={composeValidators(fieldRequired, validPhone)}
        />
        {landlordChoice === LandlordEntityType.company && (
          <>
            <p className={styles.fatDescription}>OWNER INFORMATION</p>
            <div className={styles.row}>
              <Field
                label="Legal First Name"
                component={Input}
                id="landlords[0].first_name"
                name="landlords[0].first_name"
                validate={fieldRequiredShort}
                className={cx(styles.inputContainer, styles.withSpacing)}
                labelProps={{
                  className: styles.inputLabel,
                }}
                inputProps={{
                  className: styles.input,
                }}
              />
              <Field
                label="Last Name"
                component={Input}
                id="landlords[0].last_name"
                name="landlords[0].last_name"
                validate={fieldRequiredShort}
                className={styles.inputContainer}
                labelProps={{
                  className: styles.inputLabel,
                }}
                inputProps={{
                  className: styles.input,
                }}
              />
            </div>
          </>
        )}
      </SectionPeopleOnTheLeaseLandlordSection>
      <SectionPeopleOnTheLeaseAdditionalLandlordSection
        hasAdditionalLandlord={hasAdditionalLandlord}
      >
        {hasAdditionalLandlord && (
          <>
            <p className={styles.fatDescription}>2ND LANDLORD</p>
            <p className={styles.description}>
              This landlord will be added as a signer on the lease agreement.
            </p>
          </>
        )}
        {!hasAdditionalLandlord && (
          <FlatButton
            onClick={() => {
              form.change('hasAdditionalLandlord', true);
            }}
            icon={PlusCircle}
            className={cx(styles.btn, styles.add)}
          >
            Add another landlord
          </FlatButton>
        )}
        {hasAdditionalLandlord && (
          <>
            <div className={styles.row}>
              <Field
                label="Legal First Name"
                component={Input}
                id="landlords[1].first_name"
                name="landlords[1].first_name"
                validate={composeValidators(fieldRequiredShort, validName)}
                className={cx(
                  styles.inputContainer,
                  styles.nameField,
                  styles.withSpacing,
                )}
                labelProps={{
                  className: styles.inputLabel,
                }}
                inputProps={{
                  className: styles.input,
                }}
              />
              <Field
                label="Last Name"
                component={Input}
                id="landlords[1].last_name"
                name="landlords[1].last_name"
                validate={composeValidators(fieldRequiredShort, validName)}
                className={cx(styles.inputContainer, styles.nameField)}
                labelProps={{
                  className: styles.inputLabel,
                }}
                inputProps={{
                  className: styles.input,
                }}
              />
            </div>
            <Field
              label="Email"
              component={Input}
              id="landlords[1].email"
              name="landlords[1].email"
              validate={composeValidators(
                fieldRequiredShort,
                validEmail,
                emailInUseValidator(true),
              )}
              parse={trimEmail}
              className={cx(styles.inputContainer, styles.withSpacing)}
              labelProps={{
                className: styles.inputLabel,
              }}
              inputProps={{
                className: cx(styles.input, styles.landlordEmail),
              }}
            />
            <Field
              label="Phone"
              component={PhoneField}
              name="landlords[1].phone"
              id="landlords[1].phone"
              className={styles.phone}
              validate={composeValidators(fieldRequired, validPhone)}
            />
          </>
        )}
        {hasAdditionalLandlord && (
          <FlatButton
            id="remove_landlord"
            onClick={async () => {
              form.change('hasAdditionalLandlord', false);

              // This will reset additional_landlord values when 2nd landlord is removed
              await form.change('landlords', [landlords[0]]);

              setPristine(false);
            }}
            icon={MinusCircle}
            className={cx(styles.btn, styles.remove)}
          >
            Remove 2nd landlord
          </FlatButton>
        )}
      </SectionPeopleOnTheLeaseAdditionalLandlordSection>
      <SectionPeopleOnTheLeaseLandlordMailingAddressSection>
        <AddressAutoSuggestFields
          className={styles.address}
          fieldNames={{
            city: 'landlord_mailing_city',
            unit: 'landlord_mailing_unit',
            address: 'landlord_mailing_address',
            zip: 'landlord_mailing_zip',
            state: 'landlord_mailing_state',
          }}
          areFieldsRequired={!standAloneWizard}
        />
        {currentState === 'DE' && <DEMailingInfo />}
      </SectionPeopleOnTheLeaseLandlordMailingAddressSection>
      {currentState === 'HI' ? (
        <div className={cx(styles.section, styles.midSpacing)}>
          <IslandAgentPrompt />
        </div>
      ) : null}
      {currentState === 'NV' ? (
        <div className={cx(styles.section, styles.midSpacing)}>
          <EmergencyContactPrompt />
        </div>
      ) : null}
      <SectionPeopleOnTheLeaseUncommonScenariosSection
        tenantAddressChoice={tenantsAddressChoice}
      >
        <div className={styles.subSection}>
          <label
            htmlFor="tenants_address_choice"
            className={cx(styles.secondaryLabel, styles.mediumSpacing)}
          >
            Will the tenant(s) have a mailing address that differs from the
            rental property address—such as a PO box?
          </label>
          <Field
            component={RadioGroup}
            id="tenants_address_choice"
            name="tenants_address_choice"
            radiosContainerClassName={cx(
              styles.radioButtonsContainer,
              styles.withSpacing,
            )}
            options={[
              {
                id: 'tenants_address_choice_yes',
                value: true,
                label: 'Yes',
              },
              {
                id: 'tenants_address_choice_no',
                value: false,
                label: 'No',
              },
            ]}
            validate={fieldRequired}
          />
          {tenantsAddressChoice && (
            <AddressAutoSuggestFields
              className={styles.address}
              fieldNames={{
                city: 'tenant_mailing_city',
                unit: 'tenant_mailing_unit',
                address: 'tenant_mailing_address',
                zip: 'tenant_mailing_zip',
                state: 'tenant_mailing_state',
              }}
              areFieldsRequired={false}
              validatePOBoxes={false}
            />
          )}
        </div>
        <div className={styles.subSection}>
          <label
            htmlFor="additional_cosigners_choice"
            className={cx(
              styles.secondaryLabel,
              styles.smallSpacing,
              styles.row,
            )}
          >
            Are there any co-signers?{' '}
            <IconTooltip
              tip={
                <span>
                  A co-signer is an individual who jointly signs a lease with
                  another person to guarantee payment and is legally responsible
                  for fulfilling its terms. Co-signers are commonly family
                  members or close friends. They should sign a co-signer
                  agreement form that is attached to the lease.
                </span>
              }
            />
          </label>
          <p className={styles.labelDescription}>
            Add any co-signers that need to sign this document.
          </p>
          <Field
            component={RadioGroup}
            id="additional_cosigners_choice"
            name="custom_state_data.additional_cosigners_choice"
            radiosContainerClassName={cx(
              styles.radioButtonsContainer,
              styles.withSpacing,
            )}
            options={[
              {
                id: 'additional_cosigners_choice_yes',
                value: true,
                label: 'Yes',
              },
              {
                id: 'additional_cosigners_choice_no',
                value: false,
                label: 'No',
              },
            ]}
            validate={fieldRequired}
          />
          {additionalSignersChoice && (
            <>
              <Field
                component={AdditionalSignersForm}
                name="custom_state_data.additional_cosigners"
                id="custom_state_data.additional_cosigners"
                usedEmails={additionalCosignersEmailsToExclude}
                showLabel={false}
                signerLabel="Co-signer"
                addBtnLabel="Add Additional Co-Signer"
                disabledStateTooltip="Save a co-signer above to add another."
                preOpenEdit={true}
              />
              {showCosignerForm && (
                <>
                  <label
                    htmlFor="wants_cosigner_agreement"
                    className={cx(styles.secondaryLabel, {
                      [styles.mediumSpacing]: !showExperimentInfo,
                    })}
                  >
                    Would you like to attach TurboTenant's co-signer agreement
                    form?
                  </label>
                  {showExperimentInfo ? (
                    <p className={styles.labelDescription}>
                      Our pre-written form (and free lease agreements!) are
                      available for TurboTenant subscribers.{' '}
                      <a
                        className={styles.link}
                        onClick={() => {
                          onLearnMoreClick({
                            wantsCosignerAgreement,
                          });
                        }}
                      >
                        Learn more about subscription benefits
                      </a>
                      .
                    </p>
                  ) : null}
                  <Field
                    component={RadioGroup}
                    vertical
                    id="wants_cosigner_agreement"
                    name="custom_state_data.wants_cosigner_agreement"
                    radiosContainerClassName={cx(
                      styles.radioButtonsContainer,
                      styles.withSpacing,
                      styles.vertical,
                    )}
                    options={[
                      {
                        id: 'wants_cosigner_agreement_yes',
                        value: true,
                        label: (
                          <div className={styles.cosignerLabel}>
                            <p className={styles.cosignerLabelText}>
                              <b>Yes</b>, attach TurboTenant's co-signer
                              agreement form.
                            </p>
                            {hasPremiumSubscription ||
                            userIsInCosignerExperiment ? (
                              <PremiumBannerIcon
                                className={styles.premiumBannerIcon}
                                starColor={'#FF9F2A'}
                                color={'#033A6D'}
                              />
                            ) : null}
                            <IconTooltip
                              className={styles.cosignerFormTooltipIcon}
                              tip={
                                <div className={styles.cosignerFormTooltip}>
                                  <div
                                    className={
                                      styles.cosignerFormTooltipImageContainer
                                    }
                                  >
                                    <img
                                      src={COSIGNER_FORM_IMAGE_PREVIEW}
                                      alt="TurboTenant cs-signer agreement form preview"
                                      className={
                                        styles.cosignerFormTooltipImage
                                      }
                                    />
                                  </div>
                                  <h6
                                    className={styles.cosignerFormTooltipTitle}
                                  >
                                    Co-Signer Agreement Form
                                  </h6>
                                  <span
                                    className={styles.cosignerFormTooltipText}
                                  >
                                    {showExperimentInfo
                                      ? `Not subscribed? Choose between our Pro or Premium subscriptions and pay when you're ready to send your lease for signing.`
                                      : `Maximize transparency and compliance by automatically attaching TurboTenant's co-signer agreement form to your lease.`}
                                  </span>
                                </div>
                              }
                            />
                          </div>
                        ),
                      },
                      {
                        id: 'wants_cosigner_agreement_no',
                        value: false,
                        label: (
                          <>
                            <b>No</b>, I'll upload my own form later.
                          </>
                        ),
                      },
                    ]}
                    validate={fieldRequiredCustom(
                      'Please choose an option above.',
                    )}
                  />
                </>
              )}
            </>
          )}
        </div>
        <div>
          <label
            htmlFor="cosigners_choice"
            className={cx(
              styles.secondaryLabel,
              styles.smallSpacing,
              styles.row,
            )}
          >
            Are there any additional signers?
            <IconTooltip
              tip={
                <span>
                  An additional signer is another party to the lease agreement
                  who is not legally responsible for fulfilling its terms.
                  Additional signers are commonly leasing agents or an agent for
                  a rental assistant program. They will sign the lease agreement
                  itself.
                </span>
              }
            />
          </label>
          <p className={styles.labelDescription}>
            Add any non-tenant that needs to sign this document.
          </p>
          <Field
            component={RadioGroup}
            id="cosigners_choice"
            name="custom_state_data.cosigners_choice"
            radiosContainerClassName={cx(
              styles.radioButtonsContainer,
              styles.withSpacing,
            )}
            options={[
              {
                id: 'additional_cosigners_yes',
                value: true,
                label: 'Yes',
              },
              {
                id: 'additional_cosigners_no',
                value: false,
                label: 'No',
              },
            ]}
            validate={fieldRequired}
          />
          {cosignersChoice ? (
            <Field
              component={AdditionalSignersForm}
              name="custom_state_data.cosigners"
              id="custom_state_data.cosigners"
              usedEmails={cosignersEmailsToExclude}
              showLabel={false}
              showCompany={true}
              disabledStateTooltip="Save an additional signer above to add another."
              preOpenEdit={true}
            />
          ) : null}
        </div>
      </SectionPeopleOnTheLeaseUncommonScenariosSection>
    </>
  );
};

SectionPeopleOnTheLeaseFields.propTypes = {
  setPristine: PropTypes.func,
  propertyRole: PropTypes.string,
  renters: PropTypes.array,
  allLandlords: PropTypes.array,
  propertyAddress: PropTypes.string,
  currentState: PropTypes.string,
  standAloneWizard: PropTypes.bool,
  userIsInCosignerExperiment: PropTypes.bool,
  onLearnMoreClick: PropTypes.func,
  hasPremiumSubscription: PropTypes.bool,
  isLeaseAgreementPaid: PropTypes.bool,
};

SectionPeopleOnTheLeaseFields.defaultProps = {
  setPristine: () => null,
  standAloneWizard: false,
  userIsInCosignerExperiment: false,
  hasPremiumSubscription: false,
  isLeaseAgreementPaid: false,
};

export default SectionPeopleOnTheLeaseFields;
