import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import TurboTenantSmallLogo from '../../icons/TurboTenantSmallLogo';
import Notice from '../Notice';

import styles from './BankAccountWarningNotice.module.scss';

const BankAccountWarningNotice = ({ name, className }) => (
  <Notice
    icon={TurboTenantSmallLogo}
    iconProps={{
      color: '#2370A3',
      height: 14.41,
      width: 19.99,
    }}
    className={cx(styles.notice, { [className]: className != null })}
  >
    <strong>This bank account must be connected to {name}.</strong>
  </Notice>
);

BankAccountWarningNotice.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default BankAccountWarningNotice;
