import React from 'react';

import { withConfig } from '../providers/ConfigProvider';

import OwnersRoutes from './owners/routes.owners';
import RentersRoutes from './renters/routes.renters';

@withConfig
export default class RenterRoutes extends React.Component {
  render() {
    const me = this.props.match.url;
    if (me.startsWith('/renters') && this.props.config.IS_RENTER) {
      return <RentersRoutes {...this.props} />;
    } else {
      return <OwnersRoutes {...this.props} />;
    }
  }
}
