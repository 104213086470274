import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import CristalIcon from '../../../../../../../icons/Cristal';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './MethamphetamineManufacturingMT.module.scss';

const MethamphetamineManufacturingMT = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Methamphetamines Disclosure" icon={CristalIcon} />
      <label className={styles.label}>
        Do you know if Methamphetamines have ever been manufactured and not
        cleaned up by a certified contractor at your property?
      </label>
      <p className={styles.description}>
        Montana law requires that you disclose this.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.methamphetamine_manufactured"
        name="custom_state_data.methamphetamine_manufactured"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'methamphetamine_manufactured_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'methamphetamine_manufactured_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition
        when="custom_state_data.methamphetamine_manufactured"
        is={true}
      >
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.methamphetamine_manufactured_explanation"
          name="custom_state_data.methamphetamine_manufactured_explanation"
          label="Please describe methamphetamine levels found on the Property and clean up efforts:"
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      </Condition>
    </div>
  );
};

MethamphetamineManufacturingMT.propTypes = {
  className: PropTypes.string,
};

export default MethamphetamineManufacturingMT;
