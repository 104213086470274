import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import loadingImage from './loading.gif';
import loadingImageSmall from './loading-small.gif';

import styles from './LoadingScreen.module.scss';

const LoadingScreen = ({
  className,
  imageContainerClassName,
  loading,
  small,
  message = 'Loading...',
  children,
  overlay = true,
  hideContent = false,
  'data-qa': dataQa,
  ...other
}) => {
  const imgSrc = small ? loadingImageSmall : loadingImage;

  return (
    <div
      {...other}
      className={cx(styles.container, className, {
        [styles.loading]: loading,
        [styles.content]: children,
        [styles.small]: small,
      })}
    >
      {children && children}
      {loading && (
        <div
          className={cx(styles.imageContainer, imageContainerClassName, {
            [styles.overlay]: overlay && loading,
            [styles.hideContent]: hideContent && loading,
          })}
          data-qa={dataQa || 'loading-screen'}
        >
          <span className={styles.text}>{message}</span>
          <img className={styles.image} src={imgSrc} alt={message} />
        </div>
      )}
    </div>
  );
};

LoadingScreen.propTypes = {
  className: PropTypes.string,
  imageContainerClassName: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  small: PropTypes.bool,
  overlay: PropTypes.bool,
  message: PropTypes.string,
  children: PropTypes.node,
};

LoadingScreen.defaultProps = {
  loading: false,
};

export default LoadingScreen;
