import React from 'react';
import PropTypes from 'prop-types';

import { mapAddressTypeToInt } from '../../helpers/mapAddressTypeToInt';
import { mapAddressTypeToString } from '../../helpers/mapAddressTypeToString';

import SegmentedControllerField from './SegmentedControllerField';

const ResidenceTypeField = ({ options = [], ...props }) => (
  <SegmentedControllerField
    options={
      options?.length > 0
        ? options
        : [
            {
              label: 'RENT',
              value: 'RENT',
            },
            {
              label: 'OWN',
              value: 'OWN',
            },
            {
              label: 'OTHER',
              value: 'OTHER',
            },
          ]
    }
    format={(value) =>
      value != null && value !== '' ? mapAddressTypeToString(value) : ''
    }
    parse={(value) =>
      value != null && value !== '' ? mapAddressTypeToInt(value) : ''
    }
    {...props}
  />
);

ResidenceTypeField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

export default ResidenceTypeField;
