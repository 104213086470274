import React from 'react';
import PropTypes from 'prop-types';

import BackButton from '../../../components/BackButton';
import EmbeddedSignUpForm from '../../../components/EmbeddedSignUpForm';
import SideBarReviews from '../../../components/SideBarReviews';
import { H2, Paragraph } from '../../../components/Typography';
import MarketingLayout from '../../../layout/MarketingLayout';
import { segmentTracking } from '../../../services/utilities';

import styles from './SignUpStep.module.scss';

const SignUpStep = ({ onNext, segmentData = {} }) => {
  return (
    <MarketingLayout
      headerClassName={styles.header}
      side={
        <SideBarReviews
          className={styles.reviewContainer}
          alignTop
          titleClassName={styles.title}
          quote="TurboTenant is easy to use, super functional and provides one place to manage all tenant leads. Background check and [the] application is easy to read and thorough as well."
          reviewer="– Jack"
        />
      }
    >
      <div className={styles.container}>
        <BackButton className={styles.back} />
        <div className={styles.content}>
          <H2>Get updates on your applicants</H2>
          <Paragraph>
            Create an account to view your applications, organize your leads,
            and explore TurboTenant.
          </Paragraph>
          <EmbeddedSignUpForm
            className={styles.signUpForm}
            handleSubmit={(data) => {
              segmentTracking('send_application clicked', {
                location: 'Create Account - Public Application',
                ...segmentData,
              });
              return onNext(data);
            }}
            buttonLabel="Send Application"
            segmentLocation="Create Account - Public Application"
          />
        </div>
      </div>
    </MarketingLayout>
  );
};

SignUpStep.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default SignUpStep;
