import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../../../../components/Button';

import InvalidInviteImage from './invalid-invite.svg';

import styles from './InvalidInvitePage.module.scss';

const InvalidInvitePage = () => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <img
        className={styles.image}
        src={InvalidInviteImage}
        alt="Invalid invite"
      />
      <h2 className={styles.title}>Your invite is invalid</h2>
      <p className={styles.text}>
        Your invite to join the account may have already been accepted, or it
        might have expired. Contact for assistance or to request a new invite.
      </p>
      <Button
        className={styles.btn}
        onClick={() => {
          history.replace('/auth/login');
        }}
      >
        Back to Turbotenant
      </Button>
    </div>
  );
};

export default InvalidInvitePage;
