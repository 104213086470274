import React, { useEffect, useState } from 'react';
import useWindowSize from '@rooks/use-window-size';
import PropTypes from 'prop-types';

import Modal from '../../../components/Modal';
import { smallScreenWidth } from '../../../constants/media-breakpoints';
import MarketingLayout from '../../../layout/MarketingLayout';
import { segmentTracking } from '../../../services/utilities';
import PublicScreeningReportSidebar from '../PublicScreeningReportSidebar';

import ScreenATenantStepForm from './ScreenATenantStepForm';

import styles from './ScreenATenantStep.module.scss';

const ScreenATenantStep = ({ onContinue, initialValues, segmentData = {} }) => {
  const [showModal, setShowModal] = useState(true);

  const { innerWidth } = useWindowSize();
  const isSmallScreen = innerWidth < smallScreenWidth;

  useEffect(() => {
    segmentTracking('public_screening started', {
      location: 'Screen a Tenant - Public Screening',
      ...segmentData,
    });
  }, []);

  return (
    <MarketingLayout
      headerClassName={styles.header}
      className={styles.container}
      hideFooter
      side={
        <PublicScreeningReportSidebar segmentLocation="Screen a Tenant - Public Screening" />
      }
    >
      <Modal
        open={isSmallScreen && showModal}
        fullScreen
        className={styles.modal}
        autoFocus={false}
      >
        <PublicScreeningReportSidebar
          className={styles.modalContent}
          segmentLocation="Screen a Tenant Modal - Public Screening"
          onLetsGetStarted={() => {
            setShowModal(false);
          }}
        />
      </Modal>
      <div className={styles.content}>
        <h2 className={styles.title}>Screen a Tenant</h2>
        <p className={styles.description}>
          Enter info below for a free background check, including credit,
          criminal, and eviction history.
        </p>
        <ScreenATenantStepForm
          className={styles.form}
          onSubmit={(data) => {
            segmentTracking('continue clicked', {
              location: 'Screen a Tenant - Public Screening',
              ...segmentData,
            });
            return onContinue(data);
          }}
          initialValues={initialValues}
        />
      </div>
    </MarketingLayout>
  );
};

ScreenATenantStep.propTypes = {
  onContinue: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  segmentData: PropTypes.object,
};

export default ScreenATenantStep;
