import React from 'react';
import useWindowSize from '@rooks/use-window-size';
import PropTypes from 'prop-types';

import BackButton from '../../../../components/BackButton';
import Button from '../../../../components/Button';
import breakpoints from '../../../../constants/media-breakpoints';

import styles from './WizardHeader.module.scss';

const WizardHeader = ({
  backLink,
  onPreview,
  onSave,
  formFilledIn,
  pristine,
}) => {
  const { innerWidth } = useWindowSize();
  const isMobile = innerWidth <= breakpoints.mobileBreakpoint;

  return (
    <div className={styles.container}>
      <BackButton className={styles.backButton} to={backLink} />

      {!pristine && (
        <Button
          type="submit"
          onClick={onSave}
          className={styles.button}
          secondary
        >
          {isMobile ? 'Save & Exit' : 'Save & Finish Later'}
        </Button>
      )}

      <Button
        // NOTE: If necessary info was filled in the form this should be a submit button
        type={formFilledIn ? 'submit' : 'button'}
        className={styles.button}
        onClick={onPreview}
      >
        {formFilledIn ? 'Review and E-Sign' : 'Preview Addendum'}
      </Button>
    </div>
  );
};

WizardHeader.propTypes = {
  backLink: PropTypes.string,
  onPreview: PropTypes.func,
  onSave: PropTypes.func,
  formFilledIn: PropTypes.bool,
  pristine: PropTypes.bool,
};

export default WizardHeader;
