export const EnumRentChargeType = {
  RENT: 'RENT',
  LATE_FEE: 'LATE_FEE',
  SECURITY_DEPOSIT: 'SECURITY_DEPOSIT',
  UTILITY_CHARGE: 'UTILITY_CHARGE',
  NSF_RETURNED_PAYMENT: 'NSF_RETURNED_PAYMENT',
  OTHER: 'OTHER',
};

export const ApolloNetworkStatus = {
  LOADING: 1,
  SET_VARIABLES: 2,
  FETCH_MORE: 3,
  REFETCH: 4,
  POLLING: 6,
  READY: 7,
  ERROR: 8,
};

export const EnumPaymentMethodStatusTypes = {
  NEW: 'NEW',
  VALIDATED: 'VALIDATED',
  VERIFIED: 'VERIFIED',
  VERIFICATION_FAILED: 'VERIFICATION_FAILED',
  ERRORED: 'ERRORED',
  LOGIN_EXPIRED: 'LOGIN_EXPIRED',
};
