import React, { useEffect, useRef, useState } from 'react';
import { Field, FormSpy, useField, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import cx from 'classnames';
import { intersection, isEqual } from 'lodash';
import PropTypes from 'prop-types';

import CheckboxGroupFields from '../../../../../components/CheckboxGroupFields';
import FlatButton from '../../../../../components/FlatButton';
import InlineAlert from '../../../../../components/InlineAlert';
import InputWithIcon from '../../../../../components/InputWithIcon';
import RadioGroup from '../../../../../components/RadioGroup';
import Tag from '../../../../../components/Tag';
import VideoPlayerModal from '../../../../../components/VideoPlayerModal';
import { SUB_DIVISION_NAMES_KEYS } from '../../../../../constants/lease_agreement/sub-division-names';
import { ONE_TIME_FEES_LIMIT } from '../../../../../constants/lease_agreement/wizard-limitations';
import preventNonNumericCharsOnInput from '../../../../../helpers/preventNonNumericCharsOnInput';
import DollarIcon from '../../../../../icons/Dollar';
import PlayIcon from '../../../../../icons/PlayIcon';
import TurboTenantSmallLogo from '../../../../../icons/TurboTenantSmallLogo';
import composeValidators from '../../../../../validators/composeValidators';
import fieldRequiredStandard from '../../../../../validators/fieldRequired';
import fieldRequiredCheckboxStandard from '../../../../../validators/fieldRequiredCheckbox';
import fieldRequiredShortStandard from '../../../../../validators/fieldRequiredShort';
import isNumber from '../../../../../validators/isNumber';
import SectionInfoBlock from '../../../common/SectionInfoBlock';
import OneTimeFeesForm from '../OneTimeFees';
import {
  otherPaymentMethods,
  otherPaymentMethodsValues,
} from '../otherPaymentMethods';
import { SectionRentDepositFeeDepositSection } from '../SectionRentDepositFeeDepositSection';
import { SectionRentDepositFeeOneTimeFeesSection } from '../SectionRentDepositFeeOneTimeFeesSection';
import { SectionRentDepositFeePaymentSection } from '../SectionRentDepositFeePaymentSection';
import { SectionRentDepositFeeProratedRentSection } from '../SectionRentDepositFeeProratedRentSection';
import { SectionRentDepositFeeRentSection } from '../SectionRentDepositFeeRentSection';
import BankInfoAK from '../state_specific/AK/BankInfoAK';
import DepositDeductionsAK from '../state_specific/AK/DepositDeductionsAK';
import AlaskaLateFeeInfo from '../state_specific/AK/LateFeeInfo';
import AlabamaBankInfo from '../state_specific/AL/BankInfo';
import ArkansasBankInfo from '../state_specific/AR/BankInfo';
import ArkansasLateFeeInfo from '../state_specific/AR/LateFeeInfo';
import BankInfoAZ from '../state_specific/AZ/BankInfoAZ';
import LateFeeInfoAZ from '../state_specific/AZ/LateFeeInfoAZ';
import OneTimeFeeHelperAZ from '../state_specific/AZ/OneTimeFeeHelperAZ';
import CaliforniaLateFeeInfo from '../state_specific/CA/LateFeeInfo';
import BankInfoCO from '../state_specific/CO/BankInfoCO';
import LateFeeInfoCO from '../state_specific/CO/LateFeeInfoCO';
import PetRentCO from '../state_specific/CO/PetRentCO';
import ConnecticutBankInfo from '../state_specific/CT/BankInfo';
import CTLateFeeInfo from '../state_specific/CT/LateFeeInfo';
import DisColumbiaBankInfo from '../state_specific/DC/BankInfo';
import DisColumbiaLateFeeInfo from '../state_specific/DC/LateFeeInfo';
import DelawareBankInfo from '../state_specific/DE/BankInfo';
import DelawareLateFeeInfo from '../state_specific/DE/LateFeeInfo';
import DelawareNonRefundableFeeInfo from '../state_specific/DE/NonRefundableFeeInfo';
import FloridaBankInfo from '../state_specific/FL/BankInfo';
import FloridaLateFeeInfo from '../state_specific/FL/LateFeeInfo';
import GeorgiaBankInfo from '../state_specific/GA/BankInfo';
import HawaiiBankInfo from '../state_specific/HI/BankInfo';
import HawaiiLateFeeInfo from '../state_specific/HI/LateFeeInfo';
import IowaBankInfo from '../state_specific/IA/BankInfo';
import IALateFeeInfo from '../state_specific/IA/LateFeeInfo';
import IdahoBankInfo from '../state_specific/ID/BankInfo';
import IdahoLateFeeInfo from '../state_specific/ID/LateFeeInfo';
import IllinoisBankInfo from '../state_specific/IL/BankInfo';
import IllinoisLateFeeInfo from '../state_specific/IL/LateFeeInfo';
import IndianaLateFeeInfo from '../state_specific/IN/LateFeeInfoIN';
import KansasBankInfo from '../state_specific/KS/BankInfo';
import KansasLateFeeInfo from '../state_specific/KS/LateFeeInfo';
import KentuckyBankInfo from '../state_specific/KY/BankInfo';
import KentuckyLateFeeInfo from '../state_specific/KY/LateFeeInfo';
import MassachusettsBankInfo from '../state_specific/MA/BankInfo';
import MassachusettsLateFeeInfo from '../state_specific/MA/LateFeeInfo';
import MarylandLateFeeInfo from '../state_specific/MD/LateFeeInfo';
import MaineBankInfo from '../state_specific/ME/BankInfo';
import MaineLateFeeInfo from '../state_specific/ME/LateFeeInfo';
import MichiganBankInfo from '../state_specific/MI/BankInfo';
import MichiganLateFeeInfo from '../state_specific/MI/LateFeeInfo';
import MinnesotaBankInfo from '../state_specific/MN/BankInfo';
import MinnesotaLateFeeInfo from '../state_specific/MN/LateFeeInfo';
import MissouriBankInfo from '../state_specific/MO/BankInfo';
import MissouriLateFeeInfo from '../state_specific/MO/LateFeeInfo';
import MississippiBankInfo from '../state_specific/MS/BankInfo';
import MississippiLateFeeInfo from '../state_specific/MS/LateFeeInfo';
import MontanaBankInfo from '../state_specific/MT/BankInfoMT';
import MontanaLateFeeInfo from '../state_specific/MT/LateFeeInfoMT';
import NorthCarolinaBankInfo from '../state_specific/NC/BankInfo';
import NorthCarolinaLateFeeInfo from '../state_specific/NC/LateFeeInfo';
import NorthDakotaBankInfo from '../state_specific/ND/BankInfo';
import NorthDakotaLateFeeInfo from '../state_specific/ND/LateFeeInfo';
import NebraskaBankInfo from '../state_specific/NE/BankInfo';
import NebraskaLateFeeInfo from '../state_specific/NE/LateFeeInfo';
import NewHampshireBankInfo from '../state_specific/NH/BankInfo';
import NewHampshireLateFeeInfo from '../state_specific/NH/LateFeeInfo';
import NewJerseyBankInfo from '../state_specific/NJ/BankInfo';
import NewJerseyLateFeeInfo from '../state_specific/NJ/LateFeeInfo';
import NewMexicoBankInfo from '../state_specific/NM/BankInfo';
import NewMexicoLateFeeInfo from '../state_specific/NM/LateFeeInfo';
import NevadaBankInfo from '../state_specific/NV/BankInfo';
import NevadaLateFeeInfo from '../state_specific/NV/LateFeeInfo';
import NewYorkBankInfo from '../state_specific/NY/BankInfo';
import NewYorkLateFeeInfo from '../state_specific/NY/LateFeeInfo';
import OklahomaBankInfo from '../state_specific/OK/BankInfo';
import OklahomaLateFeeInfo from '../state_specific/OK/LateFeeInfo';
import OregonBankInfo from '../state_specific/OR/BankInfo';
import BankInfoFieldsOR from '../state_specific/OR/BankInfoFields/BankInfoFields';
import OregonLateFeeInfo from '../state_specific/OR/LateFeeInfo';
import PennsylvaniaBankInfo from '../state_specific/PA/BankInfo';
import PennsylvaniaLateFeeInfo from '../state_specific/PA/LateFeeInfo';
import RhodeIslandBankInfo from '../state_specific/RI/BankInfo';
import RhodeIslandLateFeeInfo from '../state_specific/RI/LateFeeInfo';
import SouthDakotaBankInfo from '../state_specific/SD/BankInfo';
import SouthDakotaLateFeeInfo from '../state_specific/SD/LateFeeInfo';
import TennesseeBankInfo from '../state_specific/TN/BankInfo';
import TennesseeLateFeeInfo from '../state_specific/TN/LateFeeInfo';
import TexasBankInfo from '../state_specific/TX/BankInfo';
import TexasLateFeeInfo from '../state_specific/TX/LateFeeInfo';
import UtahBankInfo from '../state_specific/UT/BankInfo';
import UtahLateFeeInfo from '../state_specific/UT/LateFeeInfo';
import VirginiaBankInfo from '../state_specific/VA/BankInfo';
import VirginiaLateFeeInfo from '../state_specific/VA/LateFeeInfo';
import PrepaidRentLawHelper from '../state_specific/VA/PrepaidRentLawHelper';
import VermontBankInfo from '../state_specific/VT/BankInfo';
import VermontLateFeeInfo from '../state_specific/VT/LateFeeInfo';
import WashingtonBankInfo from '../state_specific/WA/BankInfoWA';
import WashingtonLateFeeInfo from '../state_specific/WA/LateFeeInfo';
import MoveInFeeHelperWA from '../state_specific/WA/MoveInFeeHelperWA';
import PetDepositWA from '../state_specific/WA/PetDepositWA';
import WisconsinBankInfo from '../state_specific/WI/BankInfo';
import WisconsinLateFeeInfo from '../state_specific/WI/LateFeeInfo';
import WestVirginiaBankInfo from '../state_specific/WV/BankInfo';
import WestVirginiaLateFeeInfo from '../state_specific/WV/LateFeeInfo';
import WyomingBankInfo from '../state_specific/WY/BankInfo';
import WyomingLateFeeInfo from '../state_specific/WY/LateFeeInfo';

import styles from './SectionRentDepositFeeFields.module.scss';

const noop = () => {};

const SectionRentDepositFeeFields = ({
  setPristine,
  currentState,
  leaseAgreementSelection,
  standAloneWizard,
}) => {
  const form = useForm();
  const [fieldRefToFocus, setFieldRefToFocus] = useState(null);
  const [play, setPlay] = useState(false);

  const petRentField = useRef(null);
  const proratedRentField = useRef(null);
  const petDepositField = useRef(null);
  const otherPaymentsField = useRef(null);

  useEffect(() => {
    if (fieldRefToFocus && fieldRefToFocus.current) {
      fieldRefToFocus.current.focus();
    }
  }, [fieldRefToFocus]);

  const {
    input: { value: petRentChoice },
  } = useField('pet_rent_choice');

  const {
    input: { value: proratedRentChoice },
  } = useField('prorated_rent_choice');

  const {
    input: { value: petDepositChoice },
  } = useField('pet_deposit_choice');

  const {
    input: { value: oneTimeFeeChoice },
  } = useField('one_time_fee_choice');

  const {
    input: { value: acceptedPayments },
  } = useField('accepted_payments');

  const fieldRequired = standAloneWizard ? noop : fieldRequiredStandard;
  const fieldRequiredShort = standAloneWizard
    ? noop
    : fieldRequiredShortStandard;
  const fieldRequiredCheckbox = standAloneWizard
    ? noop
    : fieldRequiredCheckboxStandard;

  const paymentValidationStandard = (selectedValues) => {
    if (intersection(selectedValues, otherPaymentMethodsValues)?.length === 0) {
      return 'Please select at least one payment method above';
    }
    return null;
  };
  const paymentValidation = standAloneWizard ? noop : paymentValidationStandard;

  return (
    <>
      <FormSpy
        subscription={{ pristine: true, values: true }}
        onChange={({ pristine }) => {
          setPristine(pristine);
        }}
      />

      <SectionRentDepositFeeRentSection>
        <div className={cx(styles.row, styles.subsection)}>
          <Field
            label="Monthly Base Rent"
            className={styles.inputContainer}
            labelProps={{
              className: styles.inputLabel,
            }}
            inputProps={{
              className: styles.input,
            }}
            id="base_rent_amount"
            name="base_rent_amount"
            component={InputWithIcon}
            type="number"
            step=".01"
            pattern="[0-9]*"
            inputMode="decimal"
            validate={composeValidators(fieldRequiredShort, isNumber)}
            icon={DollarIcon}
          />
        </div>
        {currentState === 'CA' && (
          <CaliforniaLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'CO' && (
          <LateFeeInfoCO className={styles.infoBlock} />
        )}
        {currentState === 'AZ' && (
          <LateFeeInfoAZ className={styles.infoBlock} />
        )}
        {currentState === 'NJ' && (
          <NewJerseyLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'TN' && (
          <TennesseeLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'NC' && (
          <NorthCarolinaLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'MN' && (
          <MinnesotaLateFeeInfo className={styles.infoBlock} />
        )}
        {(currentState === 'MD' || currentState === 'LA') && (
          //Louisiana is same as Maryland
          <MarylandLateFeeInfo
            className={styles.infoBlock}
            leaseAgreementSelection={leaseAgreementSelection}
          />
        )}
        {currentState === 'WI' && (
          <WisconsinLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'WA' && (
          <WashingtonLateFeeInfo
            className={styles.infoBlock}
            leaseAgreementSelection={leaseAgreementSelection}
          />
        )}
        {currentState === 'MO' && (
          <MissouriLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'NM' && (
          <NewMexicoLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'ID' && (
          <IdahoLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'FL' && (
          <FloridaLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'KS' && (
          <KansasLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'AR' && (
          <ArkansasLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'NH' && (
          <NewHampshireLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'MS' && (
          <MississippiLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'WV' && (
          <WestVirginiaLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'NE' && (
          <NebraskaLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'RI' && (
          <RhodeIslandLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'HI' && (
          <HawaiiLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'MA' && (
          <MassachusettsLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'KY' && (
          <KentuckyLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'OK' && (
          <OklahomaLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'ME' && (
          <MaineLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'NV' && (
          <NevadaLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'CT' && (
          <CTLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'IA' && (
          <IALateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'OR' && (
          <OregonLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'ND' && (
          <NorthDakotaLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'UT' && (
          <UtahLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'WY' && (
          <WyomingLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'SD' && (
          <SouthDakotaLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'DE' && (
          <DelawareLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'AK' && (
          <AlaskaLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'DC' && (
          <DisColumbiaLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'VT' && (
          <VermontLateFeeInfo
            className={styles.infoBlock}
            leaseAgreementSelection={leaseAgreementSelection}
          />
        )}
        {currentState === 'IL' && (
          <IllinoisLateFeeInfo
            className={styles.infoBlock}
            leaseAgreementSelection={leaseAgreementSelection}
          />
        )}
        {currentState === 'NY' && (
          <NewYorkLateFeeInfo
            className={styles.infoBlock}
            leaseAgreementSelection={leaseAgreementSelection}
          />
        )}
        {currentState === 'VA' && (
          <VirginiaLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'TX' && (
          <TexasLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'MI' && (
          <MichiganLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'PA' && (
          <PennsylvaniaLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'IN' && (
          <IndianaLateFeeInfo className={styles.infoBlock} />
        )}
        {currentState === 'MT' && (
          <MontanaLateFeeInfo className={styles.infoBlock} />
        )}
        {![
          'CA',
          'CO',
          'NJ',
          'TN',
          'NC',
          'MN',
          'MD',
          'LA',
          'WI',
          'WA',
          'MO',
          'NM',
          'ID',
          'KS',
          'AR',
          'NH',
          'MS',
          'WV',
          'NE',
          'RI',
          'HI',
          'MA',
          'KY',
          'MI',
          'OK',
          'ME',
          'NV',
          'CT',
          'IA',
          'OR',
          'ND',
          'UT',
          'WY',
          'SD',
          'DE',
          'AK',
          'DC',
          'VT',
          'IL',
          'NY',
          'VA',
          'TX',
          'AZ',
          'PA',
          'FL',
          'IN',
          'MT',
        ].includes(currentState) && (
          <SectionInfoBlock className={styles.infoBlock}>
            <>
              <strong>Late Fees:</strong> Rent is due in full on the first day
              of each and every month. If rent is not received on or before the
              2nd day of the month, you may charge your tenants a late fee of
              10% of the total unpaid amount.
            </>
          </SectionInfoBlock>
        )}
        <div className={styles.column}>
          <OnChange name="pet_rent_choice">
            {(value) => {
              setFieldRefToFocus(value ? petRentField : null);
            }}
          </OnChange>
          <Field
            component={RadioGroup}
            id="pet_rent_choice"
            name="pet_rent_choice"
            label="Will you charge pet rent?"
            radiosContainerClassName={cx(styles.radioButtonsContainer, {
              [styles.withSpacing]: petRentChoice,
            })}
            labelProps={{
              className: styles.secondaryLabel,
            }}
            options={[
              {
                id: 'pet_rent_choice_yes',
                value: true,
                label: 'Yes',
              },
              {
                id: 'pet_rent_choice_no',
                value: false,
                label: 'No',
              },
            ]}
            validate={fieldRequired}
          />
          {petRentChoice && (
            <Field
              id="monthly_pet_rent_amount"
              name="monthly_pet_rent_amount"
              label="Monthly Pet Rent"
              className={styles.inputContainer}
              labelProps={{
                className: styles.inputLabel,
              }}
              inputProps={{
                className: styles.input,
                ref: (ref) => (petRentField.current = ref),
              }}
              component={InputWithIcon}
              type="number"
              step=".01"
              pattern="[0-9]*"
              inputMode="decimal"
              validate={composeValidators(fieldRequiredShort, isNumber)}
              icon={DollarIcon}
            />
          )}
          {currentState === 'CO' && <PetRentCO />}
        </div>
      </SectionRentDepositFeeRentSection>

      <SectionRentDepositFeeProratedRentSection>
        <OnChange name="prorated_rent_choice">
          {(value) => {
            setFieldRefToFocus(value ? proratedRentField : null);
          }}
        </OnChange>
        <Field
          component={RadioGroup}
          id="prorated_rent_choice"
          name="prorated_rent_choice"
          radiosContainerClassName={cx(styles.radioButtonsContainer, {
            [styles.withSpacing]: proratedRentChoice,
          })}
          labelProps={{
            className: styles.secondaryLabel,
          }}
          options={[
            {
              id: 'prorated_rent_choice_yes',
              value: true,
              label: 'Yes',
            },
            {
              id: 'prorated_rent_choice_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />
        {proratedRentChoice && (
          <React.Fragment>
            <Field
              label="Prorated Rent Amount"
              className={styles.inputContainer}
              labelProps={{
                className: styles.inputLabel,
              }}
              inputProps={{
                className: styles.input,
                ref: (ref) => (proratedRentField.current = ref),
              }}
              id="prorated_rent_amount"
              name="prorated_rent_amount"
              component={InputWithIcon}
              type="number"
              step=".01"
              pattern="[0-9]*"
              inputMode="decimal"
              validate={composeValidators(fieldRequiredShort, isNumber)}
              icon={DollarIcon}
            />
            <p className={styles.proratedRentLinkContainer}>
              Use our{' '}
              <a
                href="https://www.turbotenant.com/prorated-rent-calculator/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.proratedRentLink}
              >
                prorated rent calculator
              </a>
            </p>
          </React.Fragment>
        )}
      </SectionRentDepositFeeProratedRentSection>

      <SectionRentDepositFeeDepositSection>
        {currentState === 'OR' && <BankInfoFieldsOR />}
        <div className={cx(styles.row, styles.subsection)}>
          <Field
            label="Security Deposit"
            className={cx(styles.inputContainer, styles.withSpacing)}
            labelProps={{
              className: styles.inputLabel,
            }}
            inputProps={{
              className: styles.input,
            }}
            id="security_deposit_amount"
            name="security_deposit_amount"
            component={InputWithIcon}
            type="number"
            step=".01"
            pattern="[0-9]*"
            inputMode="decimal"
            validate={composeValidators(fieldRequiredShort, isNumber)}
            icon={DollarIcon}
            onKeyDown={preventNonNumericCharsOnInput}
          />
          <Field
            label="Other Deposit"
            hint="(Optional)"
            className={styles.inputContainer}
            labelProps={{
              className: cx(styles.inputLabel, styles.otherDepositLabel),
            }}
            inputProps={{
              className: styles.input,
            }}
            id="other_deposit_amount"
            name="other_deposit_amount"
            component={InputWithIcon}
            type="number"
            step=".01"
            pattern="[0-9]*"
            inputMode="decimal"
            validate={isNumber}
            icon={DollarIcon}
            onKeyDown={preventNonNumericCharsOnInput}
          />
        </div>
        <OnChange name="pet_deposit_choice">
          {(value) => {
            setFieldRefToFocus(value ? petDepositField : null);
          }}
        </OnChange>
        <Field
          component={RadioGroup}
          id="pet_deposit_choice"
          name="pet_deposit_choice"
          label="Do you require a pet deposit?"
          radiosContainerClassName={cx(styles.radioButtonsContainer, {
            [styles.withSpacing]: petDepositChoice,
          })}
          labelProps={{
            className: styles.secondaryLabel,
          }}
          options={[
            {
              id: 'pet_deposit_choice_yes',
              value: true,
              label: 'Yes',
            },
            {
              id: 'pet_deposit_choice_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />
        {petDepositChoice && (
          <div className={styles.row}>
            <Field
              label="Pet Deposit"
              className={styles.inputContainer}
              labelProps={{
                className: styles.inputLabel,
              }}
              inputProps={{
                className: styles.input,
                ref: (ref) => (petDepositField.current = ref),
              }}
              id="pet_deposit_amount"
              name="pet_deposit_amount"
              component={InputWithIcon}
              type="number"
              step=".01"
              pattern="[0-9]*"
              inputMode="decimal"
              validate={composeValidators(fieldRequiredShort, isNumber)}
              icon={DollarIcon}
            />
          </div>
        )}
        {(leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.tacoma ||
          leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.seattle) && (
          <PetDepositWA leaseAgreementSelection={leaseAgreementSelection} />
        )}
      </SectionRentDepositFeeDepositSection>

      {currentState === 'FL' && (
        <div className={styles.section}>
          <FloridaBankInfo />
        </div>
      )}
      {currentState === 'GA' && (
        <div className={styles.section}>
          <GeorgiaBankInfo />
        </div>
      )}
      {currentState === 'MI' && (
        <div className={styles.section}>
          <MichiganBankInfo />
        </div>
      )}
      {currentState === 'TN' && (
        <div className={styles.section}>
          <TennesseeBankInfo />
        </div>
      )}
      {currentState === 'PA' && (
        <div className={styles.section}>
          <PennsylvaniaBankInfo />
        </div>
      )}
      {currentState === 'NJ' && (
        <div className={styles.section}>
          <NewJerseyBankInfo />
        </div>
      )}
      {currentState === 'NC' && (
        <div className={styles.section}>
          <NorthCarolinaBankInfo />
        </div>
      )}
      {currentState === 'WI' && (
        <div className={styles.section}>
          <WisconsinBankInfo />
        </div>
      )}
      {currentState === 'WA' && (
        <div className={styles.section}>
          <WashingtonBankInfo
            leaseAgreementSelection={leaseAgreementSelection}
          />
        </div>
      )}
      {currentState === 'MO' && (
        <div className={styles.section}>
          <MissouriBankInfo />
        </div>
      )}
      {currentState === 'AL' && (
        <div className={styles.section}>
          <AlabamaBankInfo />
        </div>
      )}
      {currentState === 'NM' && (
        <div className={styles.section}>
          <NewMexicoBankInfo />
        </div>
      )}
      {currentState === 'ID' && (
        <div className={styles.section}>
          <IdahoBankInfo />
        </div>
      )}
      {currentState === 'KS' && (
        <div className={styles.section}>
          <KansasBankInfo />
        </div>
      )}
      {currentState === 'AR' && (
        <div className={styles.section}>
          <ArkansasBankInfo />
        </div>
      )}
      {currentState === 'NH' && (
        <div className={styles.section}>
          <NewHampshireBankInfo />
        </div>
      )}
      {currentState === 'MS' && (
        <div className={styles.section}>
          <MississippiBankInfo />
        </div>
      )}
      {currentState === 'WV' && (
        <div className={styles.section}>
          <WestVirginiaBankInfo />
        </div>
      )}
      {currentState === 'NE' && (
        <div className={styles.section}>
          <NebraskaBankInfo />
        </div>
      )}
      {currentState === 'RI' && (
        <div className={styles.section}>
          <RhodeIslandBankInfo />
        </div>
      )}
      {currentState === 'HI' && (
        <div className={styles.section}>
          <HawaiiBankInfo />
        </div>
      )}
      {currentState === 'MA' && (
        <div className={styles.section}>
          <MassachusettsBankInfo />
        </div>
      )}
      {currentState === 'KY' && (
        <div className={styles.section}>
          <KentuckyBankInfo />
        </div>
      )}
      {currentState === 'OK' && (
        <div className={styles.section}>
          <OklahomaBankInfo />
        </div>
      )}
      {currentState === 'ME' && (
        <div className={styles.section}>
          <MaineBankInfo />
        </div>
      )}
      {currentState === 'NV' && (
        <div className={styles.section}>
          <NevadaBankInfo />
        </div>
      )}
      {currentState === 'CT' && (
        <div className={styles.section}>
          <ConnecticutBankInfo />
        </div>
      )}
      {currentState === 'IA' && (
        <div className={styles.section}>
          <IowaBankInfo />
        </div>
      )}
      {currentState === 'OR' && (
        <div className={styles.section}>
          <OregonBankInfo />
        </div>
      )}
      {currentState === 'ND' && (
        <div className={styles.section}>
          <NorthDakotaBankInfo />
        </div>
      )}
      {currentState === 'UT' && (
        <div className={styles.section}>
          <UtahBankInfo />
        </div>
      )}
      {currentState === 'WY' && (
        <div className={styles.section}>
          <WyomingBankInfo />
        </div>
      )}
      {currentState === 'SD' && (
        <div className={styles.section}>
          <SouthDakotaBankInfo />
        </div>
      )}
      {currentState === 'DE' && (
        <div className={styles.section}>
          <DelawareBankInfo />
        </div>
      )}
      {currentState === 'AK' && (
        <div className={styles.section}>
          <BankInfoAK />
          <DepositDeductionsAK />
        </div>
      )}
      {currentState === 'CO' && (
        <div className={styles.section}>
          <BankInfoCO />
        </div>
      )}
      {currentState === 'AZ' && (
        <div className={styles.section}>
          <BankInfoAZ />
        </div>
      )}
      {currentState === 'DC' && (
        <div className={styles.section}>
          <DisColumbiaBankInfo />
        </div>
      )}
      {currentState === 'VT' && (
        <div className={styles.section}>
          <VermontBankInfo
            state={currentState}
            leaseAgreementSelection={leaseAgreementSelection}
          />
        </div>
      )}
      {currentState === 'IL' && (
        <div className={styles.section}>
          <IllinoisBankInfo
            state={currentState}
            leaseAgreementSelection={leaseAgreementSelection}
          />
        </div>
      )}
      {currentState === 'MN' && (
        <div className={styles.section}>
          <MinnesotaBankInfo />
        </div>
      )}
      {currentState === 'VA' && (
        <div className={styles.section}>
          <VirginiaBankInfo
            state={currentState}
            leaseAgreementSelection={leaseAgreementSelection}
          />
        </div>
      )}
      {currentState === 'NY' && (
        <NewYorkBankInfo
          className={styles.section}
          leaseAgreementSelection={leaseAgreementSelection}
        />
      )}
      {currentState === 'TX' && <TexasBankInfo className={styles.section} />}
      {currentState === 'MT' && <MontanaBankInfo className={styles.section} />}

      <SectionRentDepositFeeOneTimeFeesSection
        currentState={currentState}
        leaseAgreementSelection={leaseAgreementSelection}
      >
        <Field
          component={RadioGroup}
          id="one_time_fee_choice"
          name="one_time_fee_choice"
          radiosContainerClassName={cx(styles.radioButtonsContainer, {
            [styles.withSpacing]: oneTimeFeeChoice,
          })}
          labelProps={{
            className: styles.secondaryLabel,
          }}
          options={[
            {
              id: 'one_time_fee_choice_yes',
              value: true,
              label: 'Yes',
            },
            {
              id: 'one_time_fee_choice_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />
        {oneTimeFeeChoice && (
          <Field
            component={OneTimeFeesForm}
            name="one_time_fees"
            id="one_time_fees"
            limit={ONE_TIME_FEES_LIMIT}
          />
        )}
      </SectionRentDepositFeeOneTimeFeesSection>

      {currentState === 'VA' && (
        <PrepaidRentLawHelper className={styles.infoBlock} />
      )}

      {currentState === 'AZ' && <OneTimeFeeHelperAZ />}

      {currentState === 'DE' && (
        <div className={styles.section}>
          <DelawareNonRefundableFeeInfo />
        </div>
      )}

      {(leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.tacoma ||
        leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.seattle) && (
        <MoveInFeeHelperWA leaseAgreementSelection={leaseAgreementSelection} />
      )}

      <SectionRentDepositFeePaymentSection>
        <label
          htmlFor="accepted_payments"
          className={styles.howWillYouCollectRentLabel}
        >
          How will you collect rent?
        </label>
        <p className={styles.paymentDescription}>(SELECT ALL THAT APPLY)</p>
        <OnChange name="accepted_payments">
          {(value, previous) => {
            if (!isEqual(value, previous) && !value.includes('OTHER')) {
              // deselect all checkboxes below & clear text input when the user deselects "Other payment methods outside TT"
              form.change('accepted_payments_other', []);
              form.change('other_payments', null);
            }
          }}
        </OnChange>
        <OnChange name="accepted_payments_other">
          {(value, previous) => {
            if (!isEqual(value, previous)) {
              if (!value.includes('other')) {
                form.change('other_payments', null);
                form.change(
                  'accepted_payments_other',
                  value.filter((val) =>
                    otherPaymentMethodsValues.includes(val),
                  ),
                );
              }
            }
          }}
        </OnChange>
        <CheckboxGroupFields
          className={styles.checkboxGroup}
          labelProps={{
            className: styles.label,
          }}
          id="accepted_payments"
          name="accepted_payments"
          validate={fieldRequiredCheckbox}
          options={[
            {
              value: 'Rent Payments by TurboTenant',
              label: (
                <div className={styles.recommended}>
                  <div className={styles.paymentOption}>
                    <span className={styles.option}>
                      Rent Payments by TurboTenant
                    </span>
                    <Tag small className={styles.tag}>
                      Recommended
                    </Tag>
                  </div>
                  <InlineAlert
                    containerClassName={styles.rentPaymentsInfo}
                    title={`Why TurboTenant Rent Payments?`}
                    icon={TurboTenantSmallLogo}
                    iconClassName={styles.rentPaymentsInfoIconWrapper}
                    iconProps={{
                      className: styles.rentPaymentsInfoIcon,
                      width: 24,
                      height: 24,
                    }}
                  >
                    <ul className={styles.rentPaymentsInfoList}>
                      <li>
                        <b>Get paid on time.</b> Automatic charges, reminders,
                        late fees, and receipts make rent collection effortless.
                      </li>
                      <li>
                        <b>Let tenants pay how they want.</b> Get paid via ACH
                        direct deposit, credit, or debit card with the option
                        for automatic payments.
                      </li>
                      <li>
                        <b>Dependable security.</b> We use the same processes
                        that big banks use to keep your payments secure.
                      </li>
                    </ul>
                    <FlatButton
                      className={styles.rentPaymentsInfoBtn}
                      icon={PlayIcon}
                      iconProps={{
                        className: styles.rentPaymentsInfoBtnIcon,
                        width: 20,
                        height: 20,
                      }}
                      onClick={() => setPlay(true)}
                    >
                      WATCH VIDEO
                    </FlatButton>
                    <VideoPlayerModal
                      onClose={() => setPlay(false)}
                      url={'https://vimeo.com/684816042'}
                      open={play}
                    />
                  </InlineAlert>
                </div>
              ),
              id: 0,
            },
            {
              value: 'OTHER',
              label: 'Other methods outside of TurboTenant',
              id: 1,
            },
          ]}
          checkboxProps={{
            className: styles.checkbox,
          }}
        />
        {acceptedPayments.includes('OTHER') ? (
          <div className={styles.otherPayments}>
            <label
              htmlFor="accepted_payments_other"
              className={styles.otherPaymentMethodsLabel}
            >
              What payment methods will you accept outside of TurboTenant?
            </label>
            <CheckboxGroupFields
              ref={otherPaymentsField}
              className={styles.checkboxGroup}
              options={otherPaymentMethods}
              name="accepted_payments_other"
              validate={paymentValidation}
              id="accepted_payments_other"
              checkboxProps={{ className: styles.checkbox }}
            />
            <InlineAlert
              containerClassName={styles.otherPaymentsInfo}
              title={`Things to consider:`}
            >
              <ul>
                <li>
                  Choose payment methods your tenants can easily use—even better
                  if they can set up automatic payments.
                </li>
                <li>
                  Venmo, Zelle, Paypal, etc. are convenient, but some charge
                  fees to landlords and have low payment limits.
                </li>
                <li>
                  Having clear records simplifies year end accounting and helps
                  resolve disputes—some states even require it by law.
                </li>
                <li>
                  In some states, once a renter pays any amount, you may not be
                  able to evict them. Most payment providers don't let you
                  prevent payments.
                </li>
              </ul>
            </InlineAlert>
          </div>
        ) : null}
      </SectionRentDepositFeePaymentSection>
    </>
  );
};

SectionRentDepositFeeFields.propTypes = {
  setPristine: PropTypes.func,
  currentState: PropTypes.string.isRequired,
  leaseAgreementSelection: PropTypes.string,
  standAloneWizard: PropTypes.bool,
};

SectionRentDepositFeeFields.defaultProps = {
  setPristine: () => null,
  standAloneWizard: false,
};

export default SectionRentDepositFeeFields;
