import React, { useCallback, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import propTypes from 'prop-types';

import colors from '../../../constants/colors';
import SUPPORTED_FILE_TYPES from '../../../constants/verification_documents/supported-file-types';
import { useUserProfile } from '../../../core/TTgraphql';
import createMaintenanceNoteMutation from '../../../graphql/mutations/createMaintenanceNote.graphql';
import maintenanceRequestQuery from '../../../graphql/queries/maintenanceRequestQuery.graphql';
import InformationCircleFilled from '../../../icons/InformationCircleFilled';
import {
  getMaintenanceRequestAttachmentUploadURL,
  uploadFileToS3,
} from '../../../services/cloudFilesService';
import fieldRequired from '../../../validators/fieldRequired';
import Button from '../../Button';
import Input from '../../Input';
import Modal from '../../Modal';
import PhotoUploadPreview from '../../PhotoUploadPreview';
import SelectField from '../../SelectField';
import { useErrorToast, useInfoToast } from '../../Toast';
import UnsavedChangesModal from '../../UnsavedChangesModal';
import UploadDropZone from '../../UploadDropZone';

import styles from './AddNoteModal.module.scss';

const noteTypeOptions = [
  { label: 'Private', value: 'PRIVATE' },
  { label: 'Shared with Tenants', value: 'SHARED' },
];

const AddNoteModal = ({ onClose, listingId, maintenanceRequestId }) => {
  const { user } = useUserProfile();

  const isLulaExperimentActive = get(
    user,
    'maintenance_plus_active_experiment',
    false,
  );
  const maintenancePlusSettings =
    user?.maintenance_request_management_settings || false;
  const isEnrolledFreeLula =
    isLulaExperimentActive && !!maintenancePlusSettings;

  const isMaintenancePlus = user?.maintenance_plus_subscription_subscribed;
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [createMaintenanceNote] = useMutation(createMaintenanceNoteMutation, {
    refetchQueries: [
      {
        query: maintenanceRequestQuery,
        variables: {
          id: maintenanceRequestId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });
  const [uploading, setUploading] = useState(false);
  const shouldShowUnsavedChangesModal = useRef(false);
  const showInfoToast = useInfoToast();
  const showErrorToast = useErrorToast();

  async function handleSubmit(formData) {
    const submitData = {
      ...formData,
      maintenance_request_id: maintenanceRequestId,
      attachments: (formData.attachments || []).map((a) => ({
        id: a.id,
      })),
    };

    try {
      await createMaintenanceNote({
        variables: { ...submitData },
      });
      onClose();
      showInfoToast('Note saved successfully');
    } catch (e) {
      console.log('Error: ', e);
      showErrorToast(
        'An error occured while saving the note. Please try again.',
      );
    }
  }

  function handleClose() {
    shouldShowUnsavedChangesModal.current
      ? setShowUnsavedChangesModal(true)
      : onClose();
  }

  const uploadFiles = useCallback(
    async (needUpload) => {
      setUploading(true);
      const files = await Promise.all(
        needUpload.map(async (file) => {
          const urlResponse = await getMaintenanceRequestAttachmentUploadURL({
            filename: file.path,
            listing_id: listingId,
          });
          const s3Params = get(urlResponse, 'attachments[0].s3Params');
          await uploadFileToS3(s3Params.url, file);
          return s3Params;
        }),
      );
      setUploading(false);
      return files;
    },
    [setUploading, listingId],
  );

  return (
    <Modal
      className={styles.container}
      open
      showCloseIcon
      onClose={handleClose}
      disableOutsideClick
    >
      {showUnsavedChangesModal && (
        <UnsavedChangesModal
          onConfirm={onClose}
          onCancel={() => setShowUnsavedChangesModal(false)}
        />
      )}
      <Form
        onSubmit={handleSubmit}
        subscription={{
          submitting: true,
          values: true,
          pristine: true,
        }}
        initialValues={{
          note_type: noteTypeOptions[0].value,
        }}
      >
        {({ handleSubmit, submitting, pristine }) => {
          shouldShowUnsavedChangesModal.current = !pristine;
          return (
            <form onSubmit={handleSubmit}>
              <div className={styles.content}>
                <h3 className={styles.title}>Notes & Attachments</h3>
                <p className={styles.description}>
                  Write a note or add attachments to stay organized and keep
                  accurate records. Only you can see Private Notes.
                </p>
                {(isMaintenancePlus || isEnrolledFreeLula) && (
                  <div className={styles.warning}>
                    <div className={styles.title}>
                      <InformationCircleFilled
                        height={18}
                        width={18}
                        color={colors.warningToastOrange}
                      />
                      Notes will NOT be shared with Lula
                    </div>
                    <div className={styles.warningDescription}>
                      If you have questions or comments about this maintenance
                      request, you can text or call Lula at (913) 303-7781.{' '}
                    </div>
                  </div>
                )}
                <Field
                  label="Note Type"
                  component={SelectField}
                  name="note_type"
                  id="note_type"
                  options={noteTypeOptions}
                  validate={fieldRequired}
                  className={styles.typeSelect}
                />
                <Field
                  component={Input}
                  as="textarea"
                  id="note"
                  name="note"
                  inputProps={{
                    className: styles.textArea,
                  }}
                  validate={fieldRequired}
                  maxLength={1000}
                  help={(val = '') => `${val.length} / 1000 characters used`}
                />
                <div className={styles.uploader}>
                  <label>
                    Attachments <span>(Optional)</span>
                  </label>
                  <Field component={PhotoUploadPreview} name="attachments" />
                  <Field
                    className={styles.dropZone}
                    component={UploadDropZone}
                    uploadFiles={uploadFiles}
                    accept={SUPPORTED_FILE_TYPES.join(',')}
                    name="attachments"
                    id="attachments"
                  />
                </div>
                <Button
                  className={styles.addNoteButton}
                  disabled={submitting || uploading}
                  loading={submitting}
                  type="submit"
                >
                  Add
                </Button>
              </div>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};

AddNoteModal.propTypes = {
  onClose: propTypes.func.isRequired,
  listingId: propTypes.string.isRequired,
  maintenanceRequestId: propTypes.string.isRequired,
};

export default AddNoteModal;
