import React from 'react';
import { Field, useField } from 'react-final-form';
import { useWindowWidth } from '@react-hook/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';

import IconTooltip from '../../../../../../../components/IconTooltip';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import fieldRequiredShortStandard from '../../../../../../../validators/fieldRequiredShort';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './BankInfo.module.scss';

/**
 * NOTE: This is not a specific breakpoint, it's a width on which
 * the info icon breaks to new line and when it need to open to right
 */

const MOBILE_BREAKPOINT = 435;

const BankInfo = ({ className }) => {
  const screenWidth = useWindowWidth();

  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequiredShort = conditionalValidation(
    fieldRequiredShortStandard,
    !standAloneWizard,
  );
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  const {
    input: { value: bankInfo },
  } = useField('custom_state_data.bank_info');

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <label className={styles.label}>
          Do any of the 3 conditions below apply to you?
          <IconTooltip
            tip={
              <span>
                Georgia law requires landlords to disclose a separate bank
                account for your tenant’s deposit if any of the below apply.
              </span>
            }
            place={screenWidth <= MOBILE_BREAKPOINT ? 'right' : 'top'}
          />
        </label>
        <span className={styles.description}>
          <span className={styles.item}>
            <strong>(1)</strong> I do not own the property. I am an
            agent/property manager.
          </span>
          <span className={styles.item}>
            <strong>(2)</strong> This property is not owned by a natural person.
            It is owned by some other form of legal entity (e.g., LLC,
            partnership, corporation, etc.).
          </span>
          <span className={styles.item}>
            <strong>(3)</strong> I, together with my spouse (if applicable),
            have more than 10 units.
          </span>
        </span>

        <Field
          component={RadioGroup}
          id="custom_state_data.bank_info"
          name="custom_state_data.bank_info"
          radiosContainerClassName={cx(styles.radioButtonsContainer, {
            [styles.withSpacing]: !!bankInfo,
          })}
          options={[
            {
              id: 'bank_info_yes',
              value: true,
              label: 'Yes',
            },
            {
              id: 'bank_info_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />

        {bankInfo && (
          <>
            <Field
              label="Bank / Depository Name"
              className={styles.inputContainer}
              inputProps={{
                className: styles.input,
              }}
              id="deposit_bank_name"
              name="deposit_bank_name"
              component={Input}
              validate={fieldRequiredShort}
            />
            <Field
              label="Bank / Depository Address"
              className={styles.inputContainer}
              inputProps={{
                className: styles.input,
              }}
              id="deposit_bank_address"
              name="deposit_bank_address"
              component={Input}
              validate={fieldRequiredShort}
            />
          </>
        )}
      </div>
    </div>
  );
};

BankInfo.propTypes = {
  className: PropTypes.string,
};

export default BankInfo;
