import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RadioGroup from '../../../../../../../components/RadioGroup';
import DrywallIcon from '../../../../../../../icons/Drywall';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './DrywallDisclosureVA.module.scss';

const DrywallDisclosureVA = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Drywall Disclosure" icon={DrywallIcon} />
      <label className={styles.label}>
        Do you have knowledge of defective drywall in the property which has not
        been fixed?
      </label>
      <p className={styles.description}>
        Virginia law requires that you disclose this. Virginia Code, Title 36,
        Chapter 10.1 defines defective drywall as having been installed during
        new construction or renovation that occurred between 2001 and 2008, and
        exhibits strontium, sulfur or hydrogen sulfur exceeding acceptable
        levels under the Consumer Products Safety Commission.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.drywall_defective"
        name="custom_state_data.drywall_defective"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'drywall_defective_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'drywall_defective_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
    </div>
  );
};

DrywallDisclosureVA.propTypes = {
  className: PropTypes.string,
};

export default DrywallDisclosureVA;
