import React from 'react';
import { useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import { PhoneField, TextField } from '../../../../components/FinalFormFields';
import { Column, Row } from '../../../../components/Grid';
import RemoveButton from '../../../../components/RemoveButton';
import { useIsSmallScreen } from '../../../../hooks/useIsSmallScreen';

import styles from './EmergencyContactsEdit.module.scss';

const defaultContact = {
  name: '',
  telephone: '',
  relationship: '',
};

const EmergencyContactsEdit = () => {
  const form = useForm();
  const { values } = useFormState({ values: true });

  const isSmallScreen = useIsSmallScreen();

  return (
    <div>
      <article>
        <p className={styles.sectionTitle}>Contacts</p>
        <FieldArray name="contacts">
          {({ fields }) =>
            fields?.map((name, index) => (
              <Row
                key={`emergency-contact-${index}`}
                className={styles.contactsContainer}
              >
                <Column sm={12} md={3}>
                  <TextField
                    className={styles.field}
                    name={`${name}.name`}
                    label="Name"
                  />
                </Column>
                <Column sm={12} md={3}>
                  <TextField
                    className={styles.field}
                    name={`${name}.relationship`}
                    label="Relationship"
                  />
                </Column>
                <Column sm={12} md={3}>
                  <PhoneField
                    className={styles.field}
                    name={`${name}.telephone`}
                    label="Phone"
                  />
                </Column>
                {fields?.value?.length == 2 && (
                  <Column sm={12} md={3}>
                    <RemoveButton
                      className={styles.remove}
                      onClick={() => form?.mutators?.remove('contacts', index)}
                    />
                  </Column>
                )}
              </Row>
            ))
          }
        </FieldArray>
      </article>
      {values?.contacts?.length <= 1 && (
        <Button
          small={isSmallScreen}
          large={!isSmallScreen}
          secondary
          className={styles.addButton}
          onClick={() => form?.mutators?.push('contacts', defaultContact)}
          id="add_contact"
        >
          + add contact
        </Button>
      )}
    </div>
  );
};

EmergencyContactsEdit.propTypes = {
  styles: PropTypes.object,
  data: PropTypes.object,
  onChange: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
};

export default EmergencyContactsEdit;
