import React, { memo } from 'react';

import colors from '../constants/colors';

const Rotate = (props) => (
  <svg {...props} viewBox="0 0 19 16" fill={props.color}>
    <path d="M2.89 9.343l-.06-.074L.578 5.98a.62.62 0 01.18-.874.66.66 0 01.838.101l.06.074 1.135 1.66C3.35 3.13 6.361.392 10.215.254c4.295-.153 7.904 3.11 8.06 7.289.161 4.18-3.193 7.693-7.488 7.846a.64.64 0 01-.67-.607.638.638 0 01.623-.653c3.58-.127 6.375-3.056 6.241-6.54-.13-3.483-3.139-6.203-6.718-6.075-3.235.116-5.754 2.415-6.2 5.672L5.51 5.581a.66.66 0 01.838-.115l.076.056c.245.208.29.556.119.815l-.058.074L3.9 9.288a.65.65 0 01-.477.26h-.1a.651.651 0 01-.433-.205z" />
  </svg>
);

Rotate.defaultProps = {
  width: 19,
  height: 16,
  color: colors.primary,
};

export default memo(Rotate);
