import React from 'react';
import PropTypes from 'prop-types';

import getFieldError from '../../validators/get-field-error';
import PlainComboBox from '../PlainComboBox';

const ComboBox = ({
  input,
  label,
  placeholder,
  className,
  containerClassName,
  menuClassName,
  highlightClassName,
  meta,
  getOptions,
  onSelected,
  debounce,
  itemToString,
  'data-qa': dataQa,
}) => {
  const error = getFieldError(meta);
  return (
    <PlainComboBox
      label={label}
      placeholder={placeholder}
      className={className}
      containerClassName={containerClassName}
      menuClassName={menuClassName}
      highlightClassName={highlightClassName}
      getOptions={getOptions}
      itemToString={itemToString}
      onSelected={(item) => {
        const newValue = onSelected(item);
        if (newValue) {
          input.onChange(newValue);
        }
      }}
      debounce={debounce}
      {...input}
      error={error}
      data-qa={dataQa}
    />
  );
};

ComboBox.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  highlightClassName: PropTypes.string,
  meta: PropTypes.object,
  getOptions: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  debounce: PropTypes.number,
  itemToString: PropTypes.func,
};

export default ComboBox;
