import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const House = (props) => (
  <svg {...props} viewBox="0 0 41 39" fill={props.color}>
    <path
      d="M6.042 19.826c.575 0 1.041.462 1.041 1.033v15.489H15V25.815c0-.57.466-1.032 1.042-1.032h8.333c.575 0 1.042.462 1.042 1.032v10.533h7.916V21.685c0-.57.467-1.033 1.042-1.033s1.042.462 1.042 1.033V37.38c0 .57-.467 1.033-1.042 1.033h-10a1.037 1.037 0 01-1.042-1.033V26.848h-6.25V37.38c0 .57-.466 1.033-1.041 1.033h-10A1.037 1.037 0 015 37.38V20.86c0-.57.466-1.033 1.042-1.033zM20.945.302l19.167 19a1.026 1.026 0 010 1.46 1.048 1.048 0 01-1.474 0l-18.43-18.27-18.43 18.27a1.048 1.048 0 01-1.473 0 1.026 1.026 0 010-1.46l19.167-19a1.048 1.048 0 011.473 0zm11.763 1.35c.576 0 1.042.462 1.042 1.033v5.782c0 .57-.466 1.033-1.042 1.033a1.037 1.037 0 01-1.041-1.033v-4.75h-4.792a1.037 1.037 0 01-1.042-1.032c0-.57.467-1.033 1.042-1.033h5.833z"
      fillRule="evenodd"
    />
  </svg>
);

House.defaultProps = {
  width: 41,
  height: 39,
  color: colors.text,
};

House.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(House);
