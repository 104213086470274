import React from 'react';
import { Field, useField } from 'react-final-form';

import FileUploadPreview from '../../../../../../../components/FileUploadPreview';
import UploadDropZone from '../../../../../../../components/UploadDropZone';
import PhotosIcon from '../../../../../../../icons/Photos';
import { useConfig } from '../../../../../../../providers/ConfigProvider';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './LeaseRider.module.scss';

const LeaseRider = ({
  uploadFiles,
  handleRemoveAttachment,
  attachmentTypes,
  filesFilter,
}) => {
  const { ASSET_BUCKET_URL } = useConfig();
  const { standAloneWizard } = useStandaloneWizardState();

  const {
    input: { value: attachments },
  } = useField('attachments');

  return (
    <div className={styles.container}>
      <p>
        You must provide the{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${ASSET_BUCKET_URL}uploads/assets/lease-agreement-new-york-city-rental-rider-09-2019_0_0.pdf`}
          download
        >
          New York City Lease Rider
        </a>
        . We have not attached this. We recommend you download this form, fill
        it out, and then upload it here along with any other relevant
        attachments.
      </p>
      <Field
        component={FileUploadPreview}
        id="attachments"
        name="attachments"
        onRemove={handleRemoveAttachment}
        filter={filesFilter}
      />
      {!attachments?.find?.(filesFilter) ? (
        <Field
          component={UploadDropZone}
          id="attachments"
          name="attachments"
          accept={attachmentTypes.join(',')}
          uploadFiles={uploadFiles}
          icon={PhotosIcon}
          iconProps={{ width: 30, height: 30 }}
          disabled={standAloneWizard}
        />
      ) : null}
    </div>
  );
};

export default LeaseRider;
