import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';

import styles from './AdditionsToLeaseAgreementDC.module.scss';

const AdditionsToLeaseAgreementDC = ({ className }) => {
  return (
    <HelpfulInfoCard
      noIcon
      title="Washington, DC  Landlord Tenant Handbook and Rental Regulations"
      className={cx(styles.helpfulInfoCard, className)}
      color="iceCold"
    >
      Washington, DC requires that the District of Columbia Tenant Bill of
      Rights must be presented to the tenant(s) along with the following
      regulations:{' '}
      <a
        className={styles.link}
        href="https://www.dcregs.dc.gov/Common/DCMR/RuleList.aspx?ChapterNum=14-3"
        target="_blank"
        rel="noopener noreferrer"
      >
        DC Municipal Regulations Landlord/Tenant
      </a>
      ,{' '}
      <a
        className={styles.link}
        href="https://dcregs.dc.gov/Common/DCMR/RuleList.aspx?ChapterNum=14-1"
        target="_blank"
        rel="noopener noreferrer"
      >
        DC Municipal Regulations, Housing
      </a>
      , and{' '}
      <a
        className={styles.link}
        href="https://dcregs.dc.gov/Common/DCMR/SectionList.aspx?SectionNumber=14-106"
        target="_blank"
        rel="noopener noreferrer"
      >
        DC Municipal Regulations, Housing Violations
      </a>
      .
    </HelpfulInfoCard>
  );
};

AdditionsToLeaseAgreementDC.propTypes = {
  className: PropTypes.string,
};

export default AdditionsToLeaseAgreementDC;
