/**
 * This object is called sub division because we can have cities or counties defined
 *
 * If we want to display the lease selection modal, we only need to add another state entry here, and
 * it will be displayed automatically on lease agreement creation
 *
 * [state abbreviation]: {
 *  [key stored in db]: [label displayed in modal]
 * }
 *
 * If we add a new value here, also we need to keep api/src/constants/lease_agreement/sub-division-names.js in sync
 */
const SUB_DIVISION_NAMES = {
  VT: {
    burlingtonBarre: 'Burlington/Barre',
  },
  IL: {
    cookCounty: 'Cook County',
    chicago: 'Chicago',
  },
  NY: {
    newYorkCity: 'New York City',
  },
  MD: {
    montgomery: 'Montgomery County',
    baltimore: 'Baltimore City',
  },
  WA: {
    tacoma: 'Tacoma city',
    seattle: 'Seattle city',
  },
};

export const SUB_DIVISION_NAMES_KEYS = Object.keys(SUB_DIVISION_NAMES).reduce(
  (acc, state) => {
    const keys = Object.keys(SUB_DIVISION_NAMES[state]);
    keys.forEach((key) => {
      acc[key] = key;
    });
    return acc;
  },
  {},
);

export default SUB_DIVISION_NAMES;
