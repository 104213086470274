import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import MoneyBagThickIcon from '../../../../icons/MoneyBagThick';
import SectionTitle from '../../common/SectionTitle';

import styles from './SectionRentDepositFee.module.scss';

export const SectionRentDepositFeeDepositSection = ({ children }) => {
  return (
    <div className={cx(styles.column, styles.section, styles.depositSection)}>
      <SectionTitle title="Deposit(s)" icon={MoneyBagThickIcon} />
      {children}
    </div>
  );
};

SectionRentDepositFeeDepositSection.propTypes = {
  children: PropTypes.node,
};
