import React, { createContext, useContext } from 'react';

export const ModalContext = createContext();

export const useModalContext = () => {
  const value = useContext(ModalContext);
  return value;
};

export const ModalContextProvider = ({ hasBanner, children }) => {
  const value = {
    hasBanner,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};
