import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import { useConfig } from '../../../../../../../providers/ConfigProvider';

import styles from './ResidentialLandlordTenantCode.module.scss';

const ResidentialLandlordTenantCode = ({ className }) => {
  const { ASSET_BUCKET_URL } = useConfig();

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <HelpfulInfoCard
          noIcon
          noTitle
          className={styles.helpfulInfoCard}
          color="iceCold"
        >
          Delaware requires that the{' '}
          <a
            href={`${ASSET_BUCKET_URL}uploads/assets/Delaware_Landlord_Tenant_Code_Summary_for_Tenants_2014.pdf`}
            target="_blank noopener noreferrer"
          >
            Summary of Delaware’s Residential Landlord-Tenant Code
          </a>{' '}
          be presented to new tenants at the time of signing a lease. We've
          already attached it to the lease agreement for you.
        </HelpfulInfoCard>
      </div>
    </div>
  );
};

ResidentialLandlordTenantCode.propTypes = {
  className: PropTypes.string,
};

export default ResidentialLandlordTenantCode;
