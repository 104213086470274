import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import getFieldError from '../../validators/get-field-error';
import Label from '../Label';
import PlainInput from '../PlainInput';

import styles from './AccountNumberInput.module.scss';

const AccountNumberInput = ({
  input,
  inputProps,
  label,
  className,
  inputContainerClassName,
  id,
  meta,
  style,
  showText = false,
  ...rest
}) => {
  const error = getFieldError(meta);
  const invalid = !!error;
  const [inputType, setInputType] = useState(showText ? 'text' : 'password');

  useEffect(() => {
    if (showText) setInputType('text');
  }, [showText]);

  const maskValue = (value) => {
    if (!value) return value;
    const valLength = value.length;
    if (valLength >= 5) {
      const last4 = value.slice(-4);
      return (
        Array(valLength - 4)
          .fill('*')
          .join('') + last4
      );
    }
    return Array(valLength).fill('*').join('');
  };
  return (
    <div className={cx(styles.container, className)} style={style}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <div className={cx(styles.inputContainer, inputContainerClassName)}>
        <PlainInput
          type={inputType}
          id={id}
          invalid={invalid}
          {...input}
          {...inputProps}
          {...rest}
          className={cx(styles.input)}
          onBlur={(e) => {
            input.onBlur(e);
            setInputType('text');
          }}
          onFocus={(e) => {
            input.onFocus(e);
            if (!showText) setInputType('password');
          }}
          value={
            inputType === 'text' && !showText
              ? maskValue(input.value)
              : input.value
          }
        />
      </div>
      {invalid && (
        <span id={`${id}_errormsg`} className={styles.error}>
          {error}
        </span>
      )}
    </div>
  );
};

AccountNumberInput.propTypes = {
  input: PropTypes.object,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  inputContainerClassName: PropTypes.string,
  id: PropTypes.string,
  meta: PropTypes.object,
};

export default AccountNumberInput;
