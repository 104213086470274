import { TTfetch } from '../core/TTfetch';
import { getConfig } from '../services/configService';

const validateEmailSendgrid = async (email) => {
  const { API_URL } = getConfig();
  const url = new URL(API_URL + '/public/validateEmail');
  url.searchParams.append('email', email);

  try {
    const response = await TTfetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};

export default validateEmailSendgrid;
