import React, { useEffect } from 'react';

import FullPageError from '../../components/FullPageError';

export default function renderErrorScreen({ error, _resetErrorBoundary }) {
  /**
   * Resetting the error boundary might be problematic in many cases since it might end
   * up in a loop. So logging the error and prompting the user to refresh themselves
   * is safer.
   */
  useEffect(() => {
    if (window.atatus) {
      try {
        const errorString =
          typeof error === 'string' ? error : JSON.stringify(error);
        window.atatus.notify(errorString, {
          error,
        });
      } catch (e) {
        console.warn('Atatus client error, uncaught error not sent', e);
      }
    }
  }, []);

  return <FullPageError reason={error} />;
}
