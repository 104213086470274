import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ms from 'ms';
import PropTypes from 'prop-types';

import * as AuthService from '../../core/auth/authService';
import { useIsSmallScreen } from '../../hooks/useIsSmallScreen';
import Close from '../../icons/Close';
import { useConfig } from '../../providers/ConfigProvider';
import * as localStorageService from '../../services/localStorageService';
import { gtmDataLayerPush } from '../../services/utilities';
import { segmentTracking } from '../../services/utilities/segment';
import IconButton from '../IconButton';

import styles from './NativePWAPrompt.module.scss';

const showEvery = ms('10days');

const NativePWAPrompt = ({ prompt, promptToInstall, forced }) => {
  const { ASSET_BUCKET_URL } = useConfig();
  const [isVisible, setVisibleState] = useState(false);

  const isSmallScreen = useIsSmallScreen();

  const hide = () => {
    setVisibleState(false);
    localStorageService.setItem('prompt-hidden', Date.now());
  };

  useEffect(() => {
    const promptHidden = localStorageService.getItem('prompt-hidden');
    if (
      !forced &&
      promptHidden &&
      Number(promptHidden) + showEvery > Date.now()
    ) {
      return;
    }
    if (prompt && AuthService.isLoggedIn() && isSmallScreen) {
      segmentTracking('add_app_to_phone_display');
      gtmDataLayerPush({
        event: 'gtmEvent',
        eventCategory: 'pwa',
        eventAction: 'prompt',
        eventLabel: 'view install prompt',
      });
      setVisibleState(true);
    } else if (forced) {
      setVisibleState(true);
    }
  }, [prompt, AuthService.isLoggedIn()]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.container}>
      <IconButton onClick={hide} icon={Close} className={styles.close} />
      <div className={styles.media}>
        <img
          src={`${ASSET_BUCKET_URL}app-icons/android-chrome-192x192.png`}
          className={styles.logo}
        />
        <div className={styles.mediaContent}>
          <h3>Install TurboTenant</h3>
          <p>Add to your home screen!</p>
        </div>
      </div>
      <button
        onClick={() => {
          promptToInstall();
          segmentTracking('add_app_to_phone');
          hide();
        }}
        className={styles.button}
      >
        ADD TO HOME SCREEN
      </button>
    </div>
  );
};

NativePWAPrompt.propTypes = {
  prompt: PropTypes.any,
  promptToInstall: PropTypes.func,
  forced: PropTypes.bool,
};

// We use withRouter here because there is
// no way to subscribe on AuthService.isLoggedIn()
// By using withRouter we make sure that this
// component will rerender on every router change
export default withRouter(NativePWAPrompt);
