import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const ChevronArrowDown = ({ color, ...props }) => (
  <svg {...props} fill="none">
    <g id="Icon / Streamline / Chevron Arrow Down">
      <path
        id="Shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.84151 7.74742C4.45784 8.08313 4.39244 8.64726 4.6707 9.05897L4.74744 9.1585L10.7474 16.1585C11.1174 16.5813 11.7546 16.6115 12.1627 16.2491L12.2526 16.1585L18.2526 9.1585C18.6163 8.74287 18.5742 8.1111 18.1585 7.74742C17.7749 7.41172 17.207 7.42178 16.8359 7.75222L16.7474 7.8415L11.5 13.981L6.25259 7.8415C5.91688 7.45783 5.35275 7.39243 4.94104 7.67069L4.84151 7.74742Z"
        fill={color}
      />
    </g>
  </svg>
);

ChevronArrowDown.defaultProps = {
  width: 24,
  height: 23,
  color: colors.text,
};

ChevronArrowDown.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ChevronArrowDown);
