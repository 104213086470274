import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import Input from '../../../../../../../components/Input';
import ResourcesIcon from '../../../../../../../icons/resources';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredShortStandard from '../../../../../../../validators/fieldRequiredShort';
import SectionInfoBlock from '../../../../../common/SectionInfoBlock';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './BankInfoAK.module.scss';

const BankInfoAK = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequiredShort = conditionalValidation(
    fieldRequiredShortStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <HelpfulInfoCard
          Icon={ResourcesIcon}
          className={styles.helpfulInfoCard}
          title="Alaska Security Deposit Laws"
          collapsable
        >
          <p className={styles.desc}>
            Though they're included in the lease agreement, we've highlighted
            some rules you need to know:
          </p>
          <HelpfulInfoList>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Security deposit shall not exceed 2 month's rent, unless monthly
              rent exceeds $2,000.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Pet deposits are limited to the amount of one month’s rent and
              must be refundable.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The security deposit must be kept in a financial institution trust
              account and separate accounting kept for these funds.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Location of the trust account must be provided to the Tenant.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              You must provide your Tenant with a description of situations
              where the security deposit or a portion of it may be withheld
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              If no charges are owed at Lease termination, you must return the
              security deposit within 14 days.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              If rent or other charges are owed at Lease termination, you must
              return the deposit within 30 days, less the amounts owed, along
              with a statement itemizing all charges and deductions.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Tenant must provide you with a forwarding address.
            </HelpfulInfoListItem>
            <div className={styles.learnMore}>
              <a
                href="https://support.turbotenant.com/en/articles/6207342-alaska-lease-agreement"
                target="_blank noopener noreferrer"
              >
                Learn other security deposit laws
              </a>
            </div>
          </HelpfulInfoList>
        </HelpfulInfoCard>
        <SectionInfoBlock className={styles.infoBlock}>
          <strong>Alaska Law:</strong> Landlords are required to provide the
          location of the financial institution where the security deposit is
          held.
        </SectionInfoBlock>
        <Field
          label="Financial Institution Trust Acct. Name"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_name"
          name="deposit_bank_name"
          component={Input}
          validate={fieldRequiredShort}
        />
        <Field
          label="Financial Institution Address:"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_address"
          name="deposit_bank_address"
          component={Input}
          validate={fieldRequiredShort}
        />
      </div>
    </div>
  );
};

BankInfoAK.propTypes = {
  className: PropTypes.string,
};

export default BankInfoAK;
