import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import DollarCircledIcon from '../../../../../../../icons/DollarCircled';
import ResourcesIcon from '../../../../../../../icons/resources';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import fieldRequiredShortStandard from '../../../../../../../validators/fieldRequiredShort';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './BankInfo.module.scss';

const BankInfo = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequiredShort = conditionalValidation(
    fieldRequiredShortStandard,
    !standAloneWizard,
  );
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  const {
    input: { value: depositPaysInterest },
  } = useField('deposit_pays_interest');

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.infoBlock}>
        <SectionTitle
          title="Interest or Non-Interest Bearing Security Deposit Account"
          icon={DollarCircledIcon}
        />
        <label className={styles.label}>
          Do you hold your security deposits or advance rental funds in an
          interest bearing escrow account?
        </label>
        <Field
          component={RadioGroup}
          id="deposit_pays_interest"
          name="deposit_pays_interest"
          radiosContainerClassName={cx(
            styles.radioButtonsContainer,
            styles.withSpacing,
          )}
          options={[
            {
              id: 'deposit_pays_interest_yes',
              value: true,
              label: 'Yes',
            },
            {
              id: 'deposit_pays_interest_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />

        <Field
          label={`${
            depositPaysInterest ? '' : 'Non-'
          }Interest Bearing Bank / Depository Name`}
          className={styles.inputContainer}
          inputProps={{
            className: styles.input,
          }}
          id="deposit_bank_name"
          name="deposit_bank_name"
          component={Input}
          validate={fieldRequiredShort}
        />
        <Field
          label="Bank / Depository Address"
          className={styles.inputContainer}
          inputProps={{
            className: styles.input,
          }}
          id="deposit_bank_address"
          name="deposit_bank_address"
          component={Input}
          validate={fieldRequiredShort}
        />
      </div>

      <div className={styles.row}>
        <HelpfulInfoCard
          collapsable
          Icon={ResourcesIcon}
          className={cx(styles.helpfulInfoCard, styles.securityDeposit)}
          title={`Florida Security Deposit Laws`}
          color="iceCold"
        >
          Though they're included in the lease agreement, we've highlighted some
          rules you need to know:
          <HelpfulInfoList className={styles.helpfulInfoList}>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              There is no maximum security deposit you can collect.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The security deposit must be returned within 15 days after lease
              ends if there are no charges or deductions.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              If there are deductions against the security deposit, within 30
              days of lease end, you must mail a written itemized statement of
              all deductions to tenant.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              If tenant does not object to the itemized deductions within 15
              days of mailing, you may deduct the charges and mail the balance,
              if any, to tenant.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Security deposits must be held in either an interest bearing or
              non-interest bearing account for the benefit of tenant.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              You must provide the name and address of the bank’s location.
            </HelpfulInfoListItem>
          </HelpfulInfoList>
        </HelpfulInfoCard>
      </div>
    </div>
  );
};

BankInfo.propTypes = {
  className: PropTypes.string,
};

export default BankInfo;
