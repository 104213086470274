import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import cx from 'classnames';
import PropTypes from 'prop-types';

import PlusCircle from '../../../icons/PlusCircle';
import fieldRequiredCheckbox from '../../../validators/fieldRequiredCheckbox';
import AddEditTenant from '../../AddEditTenant';
import Button from '../../Button';
import CheckboxGroupFields from '../../CheckboxGroupFields';
import FlatButton from '../../FlatButton';
import GroupComboBox from '../../GroupComboBox';
import ProfilePicture from '../../ProfilePicture';
import { Tooltip } from '../../Tooltip';

import styles from './SearchTenantField.module.scss';

const SearchTenantField = ({
  dropdownClassName,
  defaultAddress,
  showResultsOnFocus,
  getOptions,
  defaultRole,
  tenants,
  inUseValidators,
  skipTenants,
  dataQa,
}) => {
  const [openAddTenant, setOpenAddTenant] = useState(false);
  const selectedTenants = tenants?.map(({ email }) => email) || [];

  const renterRole = (role) => {
    if (role) {
      if (role === 'SUGGESTED RENTERS') {
        return defaultRole;
      } else {
        return role;
      }
    } else {
      return defaultRole;
    }
  };

  return (
    <FieldArray name="tenants">
      {({ fields }) => (
        <>
          <div className={styles.dropdownWrapper}>
            <Field
              className={styles.container}
              inputClassName={styles.inputField}
              groupClassName={styles.groupContainer}
              itemClassName={styles.itemContainer}
              emptyListClassName={styles.emptyListContainer}
              dropdownClassName={cx(
                styles.dropdownContainer,
                dropdownClassName,
              )}
              renderGroup={(group) => (
                <span className={styles.groupHeader}>{group}</span>
              )}
              renderItem={(item, highlighted) => (
                <span
                  data-qa="search-result"
                  className={cx(styles.listItem, {
                    [styles.hovered]: highlighted,
                  })}
                >
                  <ProfilePicture
                    firstName={item.first_name || ''}
                    lastName={item.last_name || ''}
                    profilePictureURL={item.profile_pic || ''}
                    className={styles.avatar}
                    size="xsmall"
                  />
                  <div className={styles.recepientInfo}>
                    <span
                      className={styles.recepientName}
                    >{`${item.first_name} ${item.last_name}`}</span>
                    <span className={styles.recepientSeparator}> - </span>
                    <span className={styles.recepientAddress}>
                      {item.address ? item.address : defaultAddress}
                    </span>
                  </div>
                </span>
              )}
              renderEmpty={() => (
                <span className={styles.emptyList}>
                  Sorry, no results found.
                </span>
              )}
              name="searchField"
              inputLabel="Search Existing Renters"
              placeholder="Search by name or property..."
              component={GroupComboBox}
              showResultsOnFocus={showResultsOnFocus}
              getOptions={getOptions}
              groupBy="role"
              itemToString={() => null}
              onSelected={(item) => {
                fields.push(item);
                return () => null;
              }}
              disabled={skipTenants?.length > 0}
              showLoading
              labelOnTop
              debounce={400}
              resultFilter={(tenant) => !selectedTenants.includes(tenant.email)}
              dataQa={dataQa}
            />
          </div>
          {tenants?.length > 0 ? (
            <div className={styles.tenantsHolder}>
              {tenants.map((tenant, index) => (
                <div className={styles.tenantRow} key={index}>
                  <ProfilePicture
                    firstName={tenant.first_name || ''}
                    lastName={tenant.last_name || ''}
                    profilePictureURL={tenant.profile_pic || ''}
                    className={styles.avatar}
                    size="small"
                  />
                  <div className={styles.tenantsInfo}>
                    <p className={styles.tenantsName}>
                      {`${tenant.first_name} ${tenant.last_name}`}{' '}
                    </p>
                    <p className={styles.tenantsAddress}>
                      <span className={styles.tenantsRole}>
                        {`${renterRole(tenant.role)} AT:`}{' '}
                      </span>
                      <span data-tooltip-id={`tennat-at-key-${index}`}>
                        {tenant.address ? tenant.address : defaultAddress}
                      </span>
                    </p>
                    <Tooltip id={`tennat-at-key-${index}`}>
                      {tenant.address ? tenant.address : defaultAddress}
                    </Tooltip>
                  </div>
                  <Button
                    className={styles.tenantsBtn}
                    onClick={() => fields.remove(index)}
                    secondary
                  >
                    remove
                  </Button>
                </div>
              ))}
            </div>
          ) : null}
          <FlatButton
            className={styles.addTenant}
            iconProps={{ className: styles.addTenantIcon }}
            icon={PlusCircle}
            disabled={skipTenants?.length > 0}
            onClick={() => {
              setOpenAddTenant(true);
            }}
          >
            Enter New Tenant
          </FlatButton>
          {tenants?.length === 0 ? (
            <CheckboxGroupFields
              labelClassName={styles.checkboxLabel}
              validate={fieldRequiredCheckbox}
              id="skip_tenants"
              name="skip_tenants"
              options={[
                {
                  value: 'skip',
                  label: "I'll add my tenants later",
                },
              ]}
            />
          ) : null}
          <AddEditTenant
            onSubmit={(rawData) => {
              fields.push({ ...rawData, address: defaultAddress });
              setOpenAddTenant(false);
            }}
            onClose={() => setOpenAddTenant(false)}
            open={openAddTenant}
            inUseValidators={inUseValidators}
            initialValues={{ role: 'Tenant', invite_type: 'email' }}
            submitLabel="Enter New Tenant"
            title="Enter New Tenant's Info"
            description={false}
          />
        </>
      )}
    </FieldArray>
  );
};

SearchTenantField.propTypes = {
  dropdownClassName: PropTypes.string,
  defaultAddress: PropTypes.string,
  defaultRole: PropTypes.string,
  showResultsOnFocus: PropTypes.bool,
  getOptions: PropTypes.func,
  tenants: PropTypes.array,
  inUseValidators: PropTypes.object,
  skipTenants: PropTypes.array,
  dataQa: PropTypes.string,
};

export default SearchTenantField;
