import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import CheckboxIcon from './CheckboxIcon';

import styles from './PlainCheckbox.module.scss';

const PlainCheckbox = ({
  checked,
  value,
  id,
  disabled,
  className,
  color,
  checkedIcon: Icon,
  ...otherProps
}) => {
  return (
    <label
      className={cx(styles.container, styles[color], className, {
        [styles.disabled]: disabled,
      })}
    >
      <input
        type="checkbox"
        id={id}
        disabled={disabled}
        value={value}
        checked={checked}
        {...otherProps}
      />
      <div className={cx(styles.iconContainer, styles[color])}>
        {Icon ? (
          <Icon
            className={cx(styles.icon, styles.checked, styles[color])}
            checked
          />
        ) : (
          <CheckboxIcon
            className={cx(styles.icon, styles.checked, styles[color])}
            checked
          />
        )}
        <CheckboxIcon
          className={cx(styles.icon, styles.notChecked, styles[color])}
        />
      </div>
    </label>
  );
};

PlainCheckbox.propTypes = {
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  id: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(['blue', 'green']),
  checkedIcon: PropTypes.any,
};

PlainCheckbox.defaultProps = {
  color: 'blue',
};

export default PlainCheckbox;
