import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import { SUB_DIVISION_NAMES_KEYS } from '../../../../../../../constants/lease_agreement/sub-division-names';

import styles from './AdditionsToLeaseAgreementWA.module.scss';

const divisionContent = {
  [SUB_DIVISION_NAMES_KEYS.tacoma]: {
    title:
      'Tacoma, WA Bill of Rights and City of Tacoma and WA Secretary of State websites.',
    content: (
      <>
        Tacoma, WA requires that the{' '}
        <a
          className={styles.link}
          href="https://cms.cityoftacoma.org/cityclerk/files/Documents/2023InitiativePetition/2023-05_FullLanguageWeb.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tacoma Bill of Rights
        </a>{' '}
        be attached to the lease, as well as website addresses for the City of
        Tacoma and WA Secretary of State for voter information, must be
        presented to the tenant(s) yearly. We've already attached the Bill of
        Rights for you. Web address for City of Tacoma is:{' '}
        <a
          className={styles.link}
          href="https://www.cityoftacoma.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.cityoftacoma.org/
        </a>{' '}
        Web address for WA Secretary of State is:{' '}
        <a
          className={styles.link}
          href="https://www.sos.wa.gov/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.sos.wa.gov/
        </a>
      </>
    ),
  },
  [SUB_DIVISION_NAMES_KEYS.seattle]: {
    title: `Seattle, WA Renter's Handbook and Voter Registration Packet`,
    content: (
      <>
        Seattle, WA requires that the{' '}
        <a
          className={styles.link}
          href="https://www.seattle.gov/Documents/Departments/RentingInSeattle/languages/English/RentersHandbook_English.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Seattle Renter's Handbook
        </a>
        , which includes the Voter Registration Packet, must be presented to the
        tenant(s) yearly. We've already attached it for you.
      </>
    ),
  },
};

const AdditionsToLeaseAgreementWA = ({
  className,
  leaseAgreementSelection,
}) => {
  const data = divisionContent[leaseAgreementSelection];

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <HelpfulInfoCard
          noIcon
          title={data.title}
          className={styles.helpfulInfoCard}
          color="iceCold"
        >
          {data.content}
        </HelpfulInfoCard>
      </div>
    </div>
  );
};

AdditionsToLeaseAgreementWA.propTypes = {
  className: PropTypes.string,
  leaseAgreementSelection: PropTypes.string,
};

export default AdditionsToLeaseAgreementWA;
