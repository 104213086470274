import React from 'react';

import Footer from '../../../components/Footer';
import colors from '../../../constants/colors';
import CheckMarkFilled from '../../../icons/CheckMarkFilled';
import Sparkles from '../../../icons/Sparkles';
import TurboTenantLargeLogo from '../../../icons/TurboTenantLargeLogo';
import AuditUploadAnimation from '../AuditUploadAnimation';

import styles from './LeaseAgreementAuditLoadingPage.module.scss';

const checkMarks = [
  'Recent state legislation changes',
  'Fair housing violations',
  'Required disclosures for your state',
  'Rent, deposit & late fee compliance',
  'General lease provisions',
  'Essential contractual terms',
];

const LeaseAgreementAuditLoadingPage = () => {
  return (
    <div className={styles.container}>
      <section className={styles.mainContent}>
        <div className={styles.mainContentInner}>
          <div className={styles.titleSection}>
            <h2 className={styles.subtitle}>Free instant audit</h2>
            <h1 className={styles.title}>
              <Sparkles
                className={styles.sparkles}
                // This value enables gradient color on the icon
                color="url(#a)"
                sparkleTopClassName={styles.sparkleTop}
                sparkleBottomClassName={styles.sparkleBottom}
                sparkleBigClassName={styles.sparkleBig}
              />
              <span>Our legally trained AI</span> will analyze your lease
              agreement for:
            </h1>
          </div>
          <div className={styles.checkMarks}>
            {checkMarks.map((checkMark, index) => (
              <div key={index} className={styles.checkMark}>
                <CheckMarkFilled className={styles.checkMarkIcon} />
                <div>{checkMark}</div>
              </div>
            ))}
          </div>
          <div className={styles.form}>
            <div className={styles.uploadSection}>
              <div className={styles.uploadZone}>
                <div className={styles.animationWrapper}>
                  <AuditUploadAnimation style={{ width: '100%' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footerWrapper}>
          <TurboTenantLargeLogo
            className={styles.turboLogo}
            color={colors.white}
          />
          <Footer className={styles.footer} />
        </div>
      </section>
    </div>
  );
};

export default LeaseAgreementAuditLoadingPage;
