import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const PetsThick = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
    <path
      d="M12.356 9.057c2.373 0 3.871 1.959 4.392 4.556.055.274.247.595.628 1.036.105.122.223.251.379.418l.695.745c1.186 1.321 1.73 2.35 1.73 3.798 0 6.59-15.648 6.59-15.648 0 0-1.552.624-2.622 1.993-4.086l.576-.612.235-.263c.38-.441.572-.762.627-1.037.521-2.596 2.019-4.555 4.393-4.555zm0 2c-1.178 0-2.076 1.174-2.432 2.948-.136.684-.488 1.272-1.075 1.95-.125.147-.26.294-.433.479l-.645.691c-.891.989-1.24 1.645-1.24 2.485 0 3.924 11.649 3.924 11.649 0 0-.905-.404-1.596-1.455-2.72l-.733-.787-.13-.147c-.587-.68-.938-1.267-1.075-1.95-.356-1.775-1.253-2.949-2.431-2.949zM3.5 5.315c2.055 0 3.5 2.295 3.5 4.97 0 2.677-1.445 4.972-3.5 4.972-2.055 0-3.5-2.295-3.5-4.971s1.445-4.971 3.5-4.971zm17.71 0c2.056 0 3.5 2.295 3.5 4.97 0 2.677-1.444 4.972-3.5 4.972-2.054 0-3.5-2.295-3.5-4.971s1.446-4.971 3.5-4.971zm-17.71 2c-.706 0-1.5 1.26-1.5 2.97 0 1.711.794 2.972 1.5 2.972S5 11.997 5 10.286c0-1.71-.794-2.971-1.5-2.971zm17.71 0c-.706 0-1.5 1.26-1.5 2.97 0 1.711.794 2.972 1.5 2.972.707 0 1.5-1.26 1.5-2.971 0-1.71-.793-2.971-1.5-2.971zM9.069.002c1.823.074 3.152 2.09 3.247 4.475.098 2.474-1.16 4.66-3.086 4.733-1.928.079-3.355-1.999-3.453-4.473C5.678 2.263 6.935.079 8.863.002zm6.78 0l.204.016c1.812.217 2.977 2.333 2.883 4.719-.098 2.474-1.525 4.552-3.45 4.473-1.929-.073-3.187-2.259-3.089-4.733.098-2.473 1.524-4.55 3.452-4.475zM15.768 2l-.097.007c-.561.091-1.22 1.125-1.276 2.549-.06 1.509.588 2.634 1.168 2.656.58.024 1.313-1.046 1.373-2.554.06-1.51-.587-2.634-1.168-2.658zM8.941 2l-.096.014c-.553.136-1.127 1.218-1.07 2.644.06 1.508.794 2.578 1.375 2.554.578-.022 1.226-1.147 1.166-2.656-.06-1.508-.794-2.578-1.375-2.556z"
      fill={props.color}
      fillRule="evenodd"
    />
  </svg>
);

PetsThick.defaultProps = {
  width: 25,
  height: 25,
  color: colors.text,
};

PetsThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(PetsThick);
