import React from 'react';
import PropTypes from 'prop-types';

import { SUB_DIVISION_NAMES_KEYS } from '../../../../../../../constants/lease_agreement/sub-division-names';
import SectionInfoBlock from '../../../../../common/SectionInfoBlock';

const LateFeeInfo = ({ className, leaseAgreementSelection }) => {
  let lateFeeText;
  switch (leaseAgreementSelection) {
    case SUB_DIVISION_NAMES_KEYS.montgomery:
    case SUB_DIVISION_NAMES_KEYS.baltimore:
      lateFeeText = (
        <>
          <strong>Late Fees:</strong> Rent is due in full on the first day of
          each and every month. If rent is not received on or before the 10th
          day of the month, you may charge your tenant a late fee of 5% of the
          unpaid rent amount, whichever is less.
        </>
      );
      break;
    default:
      lateFeeText = (
        <>
          <strong>Late Fees:</strong> Rent is due in full on the first day of
          each and every month. If rent is not received on or before the 5th day
          of the month, you may charge your tenant a late fee of 5% of the
          unpaid rent amount, whichever is less.
        </>
      );
  }

  return (
    <SectionInfoBlock className={className}>{lateFeeText}</SectionInfoBlock>
  );
};

LateFeeInfo.propTypes = {
  className: PropTypes.string,
  leaseAgreementSelection: PropTypes.string,
};

export default LateFeeInfo;
