import React from 'react';
import { FormSpy } from 'react-final-form';
import PropTypes from 'prop-types';

const WithFormValues = ({ children }) => {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => children(values)}
    </FormSpy>
  );
};

WithFormValues.propTypes = {
  children: PropTypes.func.isRequired,
};

export default WithFormValues;
