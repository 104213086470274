import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { range } from 'lodash';

import { useRentPaymentRules } from '../../../hooks/useRentPaymentRules';
import PlusCircle from '../../../icons/PlusCircle';
import TenantsIcon from '../../../icons/TenantsThick';
import AddEditTenantForm from '../../AddEditTenant/AddEditTenantForm';
import Button from '../../Button';
import RadioGroupQuestion from '../../RadioGroupQuestion';
import StepSection from '../StepSection';

import styles from './InviteTenantsSection.module.scss';

const defaultTenant = {
  first_name: '',
  last_name: '',
  email: '',
  telephone: '',
  invite_type: 'email',
};

const InviteTenantsSection = ({ form, values, fieldName = 'renters' }) => {
  const { wantsOfflineTracking } = useRentPaymentRules();

  return (
    <StepSection
      title={'Invite Tenants'}
      icon={TenantsIcon}
      iconSettings={{ width: 30, height: 32 }}
      showEdit={false}
    >
      <RadioGroupQuestion
        label="Are you ready to add tenants to the lease?"
        options={[
          {
            id: 'add_tenants_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'add_tenants_no',
            value: false,
            label: 'No',
          },
        ]}
        isRequired={true}
        name="add_tenants"
        defaultValue={false}
        onChange={(value) => {
          form.resetFieldState(fieldName);
          if (value) {
            /**
             * @ NOTICE:
             *
             * This if statement handles the case when form is loaded
             * with renters already existing (editing). This prevents
             * adding an empty tenant form just because the info was
             * loaded with this answer being Yes.
             */
            if (values[fieldName]?.length > 0) return;

            form.mutators.push(fieldName, defaultTenant);
          } else {
            form.mutators.removeBatch(
              fieldName,
              range(values[fieldName]?.length || 1),
            );
          }
        }}
      />
      {values?.add_tenants && (
        <p className={styles.addTenantNotice}>
          {`Once you’ve added your tenants and finished this setup process, they
        will be invited to their Tenant Portal where they can ${
          wantsOfflineTracking ? 'view upcoming' : 'make'
        } payments,
        request maintenance, and more!`}
        </p>
      )}
      <FieldArray name={fieldName}>
        {({ fields }) =>
          fields.map((name, index) => (
            <AddEditTenantForm
              classNameFields={styles.tenantFields}
              fieldName={fieldName}
              index={index}
              key={index}
              onRemove={() => {
                if (fields.length === 1) {
                  form.resetFieldState(fieldName);
                }
                fields.remove(index);
              }}
            />
          ))
        }
      </FieldArray>
      {values?.add_tenants && (
        <Button
          tertiary
          className={styles.addTenant}
          type="button"
          icon={PlusCircle}
          onClick={() => {
            form.resetFieldState(fieldName);
            form.mutators.push(fieldName, defaultTenant);
          }}
          data-qa="lease-details-add-tenant-btn"
        >
          Add Another Tenant
        </Button>
      )}
    </StepSection>
  );
};

export default InviteTenantsSection;
