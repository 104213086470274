import React, { memo } from 'react';
import PropTypes from 'prop-types';

const StarEmpty = ({ color, ...props }) => (
  <svg {...props} viewBox="0 0 20 20">
    <path
      d="M10.098.007c.057.012.072.013.126.035a.615.615 0 0 1 .297.248c.03.05.034.064.057.118l2.305 6.53h6.506l.065.004.065.01a.618.618 0 0 1 .297 1.036c-.015.016-.033.03-.05.044l-5.375 4.224 2.313 6.939.018.064c.003.023.008.044.01.067a.615.615 0 0 1-.66.672.614.614 0 0 1-.255-.08c-.02-.01-.037-.024-.056-.036l-5.76-4.225-5.762 4.225-.056.037-.06.03a.614.614 0 0 1-.844-.69c.004-.021.012-.043.017-.064l2.313-6.939L.233 8.032l-.05-.043L.14 7.94a.614.614 0 0 1 .159-.915.614.614 0 0 1 .248-.083l.066-.004h6.506L9.423.408l.025-.06c.028-.052.033-.066.069-.112a.616.616 0 0 1 .581-.229Zm-1.97 7.747a.616.616 0 0 1-.453.396c-.054.011-.068.01-.124.013H2.382l4.323 3.396.047.041c.038.041.049.05.08.096a.616.616 0 0 1 .075.538l-1.84 5.523 4.571-3.353.054-.035c.051-.026.063-.035.118-.053a.616.616 0 0 1 .552.088l4.572 3.353-1.84-5.523-.018-.06a.616.616 0 0 1 .13-.528c.036-.042.048-.05.09-.087l4.322-3.396H12.45l-.062-.003c-.055-.009-.07-.009-.123-.026a.615.615 0 0 1-.392-.38L10 2.451 8.128 7.754Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

StarEmpty.defaultProps = {
  width: 20,
  height: 20,
  color: 'currentColor',
};

StarEmpty.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(StarEmpty);
