import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';
import Card from '../../../../../components/Card';
import HelpfulInfo from '../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import DollarIcon from '../../../../../icons/DollarCircled';
import RecurringIcon from '../../../../../icons/Recurring';
import leasePropTypes from '../../../../../prop-types/lease';
import MonthlyChargesListForm from '../forms/MonthlyChargesListForm';
import OneTimeChargesListForm from '../forms/OneTimeChargesListForm';

import styles from './AddCharges.module.scss';

const AddCharges = ({
  onChargeCreate,
  onChargeDelete,
  onChargeEdit,
  monthlyCharges,
  oneTimeCharges,
  onNext,
  onSkipCharges,
  submitLabel,
  leaseHasTenants,
  isPremiumUser,
  bankAccountOptions,
  lease,
}) => {
  const onMonthlyChargeCreate = async (charge) => {
    await onChargeCreate({ ...charge, type: 'MONTHLY' });
  };

  const onOneTimeChargeCreate = async (charge) => {
    // We need to format end date to include time zone.
    const newEndDate = charge.end_date
      ? moment(
          `${charge.end_date} 00:00 +0000`,
          'MM/DD/YYYY HH:mm Z',
        ).toISOString()
      : charge.end_date;

    // We need clone object to have a separate data for FE and BE
    await onChargeCreate({
      ...charge,
      type: 'ONE_TIME',
      end_date: newEndDate,
    });
  };

  const hasCharges = oneTimeCharges.length > 0 || monthlyCharges.length > 0;

  return (
    <Card className={styles.card}>
      <h1 className={styles.title}>Add Your Charges</h1>
      <p className={styles.subTitle}>
        We automatically send charges to tenants 15 days before the due date.
        You can add and edit charges later on too.
      </p>

      <div className={styles.sectionHeadingContainer}>
        <div className={styles.iconContainer}>
          <RecurringIcon />
        </div>
        <h2 className={styles.sectionHeading}>Monthly Charges</h2>
      </div>

      <MonthlyChargesListForm
        charges={monthlyCharges}
        onAdd={onMonthlyChargeCreate}
        onEdit={onChargeEdit}
        onDelete={onChargeDelete}
        lease={lease}
        isPremiumUser={isPremiumUser}
        bankAccountOptions={bankAccountOptions}
        closedForm={false}
      />

      <div
        className={cx(
          styles.sectionHeadingContainer,
          styles.oneTimeChargesSection,
        )}
      >
        <div className={styles.iconContainer}>
          <DollarIcon />
        </div>
        <h2 className={styles.sectionHeading}>One-time Charges</h2>
      </div>
      <p className={cx(styles.subTitle, styles.oneTimeChargesSubtitle)}>
        Good for deposits, pro-rated rent, move-in fees, etc.
      </p>

      <OneTimeChargesListForm
        charges={oneTimeCharges}
        onAdd={onOneTimeChargeCreate}
        onEdit={onChargeEdit}
        onDelete={onChargeDelete}
        isPremiumUser={isPremiumUser}
        bankAccountOptions={bankAccountOptions}
        closedForm={false}
      />
      {!leaseHasTenants && (
        <HelpfulInfo className={styles.helpCard}>
          <HelpfulInfoList>
            <HelpfulInfoListItem>
              Charges won’t be sent until you add tenants to the lease. Make
              sure to double-check charge due dates before you add tenants.
            </HelpfulInfoListItem>
          </HelpfulInfoList>
        </HelpfulInfo>
      )}
      <div className={styles.buttonContainer}>
        <Button
          className={styles.submitButton}
          secondary
          type="submit"
          data-qa="add-charges-skip-button"
          onClick={onSkipCharges}
          disabled={hasCharges}
        >
          Skip for Now
        </Button>
        <Button
          className={styles.submitButton}
          type="submit"
          data-qa="add-charges-next-button"
          disabled={!hasCharges}
          onClick={onNext}
        >
          {submitLabel || 'Next'}
        </Button>
      </div>
    </Card>
  );
};

AddCharges.propTypes = {
  onChargeCreate: PropTypes.func,
  onChargeDelete: PropTypes.func,
  onChargeEdit: PropTypes.func,
  monthlyCharges: PropTypes.array,
  oneTimeCharges: PropTypes.array,
  onNext: PropTypes.func,
  onSkipCharges: PropTypes.func,
  submitLabel: PropTypes.string,
  leaseHasTenants: PropTypes.bool,
  isPremiumUser: PropTypes.bool,
  bankAccountOptions: PropTypes.array,
  isOfflineTracking: PropTypes.bool,
  lease: leasePropTypes,
};

export default AddCharges;
