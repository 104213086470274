import React, { useRef, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import TTLogo from '../../../../assets/tt-logo.png';
import Card from '../../../../components/Card';
import { H1, H2, Link, Paragraph } from '../../../../components/Typography';
import formatPhone from '../../../../helpers/format-phone';
import getAppliedTo from '../../../../helpers/getAppliedTo';
import getFormattedDate from '../../../../helpers/getFormattedDate';
import ApplicationComponentInProduct from '../../../applications/ApplicationComponent';
import authorizationClause from '../../renter-profile/authorizationClause';

import ScreeningReportComponent from './PartnerScreeningReportComponent';

import styles from './PartnerApplicantView.module.scss';

const formatAddress = (listing) => {
  if (!listing) {
    return '';
  }

  const { address, unit, state, zip, city } = listing;

  if (address) {
    const unitString = !unit ? '' : ` # ${unit.trim()}`;
    return `${address},${unitString} ${city}, ${state} ${zip}`;
  }
  return '';
};

const PartnerApplicantView = ({ application }) => {
  const ref = useRef(null);
  const [activeTab, setActiveTab] = useState('application');

  const tabs = [
    {
      id: 'application',
      label: 'Application',
      end: true,
    },
    {
      id: 'screening_report',
      label: 'Screening Report',
      end: true,
    },
  ];

  return (
    <Card className={styles.card}>
      <H1 className={styles.print}>Online Rental Application</H1>
      <div className={styles.header}>
        <div className={styles.renter}>
          <H1>
            {application?.renter?.first_name} {application?.renter?.last_name}
          </H1>
          <Paragraph className={cx(styles.noPrint, styles.address)}>
            {formatAddress(application?.listing)}
          </Paragraph>
          <Paragraph className={cx(styles.noPrint, styles.renterInfo)}>
            {`${application?.renter?.email} | ${formatPhone(
              application?.renter?.telephone,
            )}`}
          </Paragraph>
        </div>
      </div>
      <div ref={ref}>
        <div className={cx(styles.print, styles.printHeader)}>
          <Paragraph>
            <strong>APPLIED TO</strong>: {getAppliedTo(application?.listing)}
            <br />
            <strong>DATE SUBMITTED</strong>:{' '}
            {getFormattedDate(application?.created_at)}
          </Paragraph>
        </div>
        <div className={cx(styles.tabsHeader, styles.noPrint)}>
          <div className={styles.content}>
            {tabs && (
              <div className={styles.links}>
                {tabs.map((tab) => (
                  <Link
                    key={tab.id}
                    id={tab.id}
                    className={cx(styles.link, {
                      [styles.activeLink]: activeTab === tab.id,
                    })}
                    onClick={() => setActiveTab(tab.id)}
                  >
                    {tab.label}
                  </Link>
                ))}
                <div className={styles.spacer} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.tabs}>
        <div className={styles.tabContent}>
          {activeTab === 'application' && (
            <>
              <ApplicationComponentInProduct
                renterEmail={application.renter?.email}
                application={application}
                partnersApplication
              />

              <div className={cx(styles.authorization, styles.print)}>
                <H2 className={styles.title}>Authorization</H2>
                <Paragraph>{authorizationClause}</Paragraph>
                <div className={styles.signatureWrapper}>
                  <div>
                    {application?.agrees_to_terms && (
                      <span>{`${application?.first_name} ${application?.last_name}`}</span>
                    )}
                    <strong>PRINTED NAME</strong>
                  </div>
                  <div>
                    {application?.agrees_to_terms && (
                      <span
                        className={styles.signature}
                      >{`${application?.first_name} ${application?.last_name}`}</span>
                    )}
                    <strong>SIGNATURE</strong>
                  </div>
                  <div>
                    {application?.agrees_to_terms && (
                      <span>{getFormattedDate(application?.created_at)}</span>
                    )}
                    <strong>DATE AGREED TO</strong>
                  </div>
                </div>
                <div className={styles.ttFooter}>
                  <img className={styles.ttLogo} src={TTLogo} />
                  <Paragraph>
                    <strong>The Renter Applied Through TurboTenant</strong>
                  </Paragraph>
                  <Paragraph>
                    TurboTenant is the all-in-one property management software
                    for landlords.
                  </Paragraph>
                </div>
              </div>
            </>
          )}

          {activeTab === 'screening_report' && (
            <ScreeningReportComponent application={application} />
          )}
        </div>
      </div>
    </Card>
  );
};

PartnerApplicantView.propTypes = {
  application: PropTypes.object,
};

export default PartnerApplicantView;
