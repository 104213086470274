import React from 'react';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { parse } from 'query-string';

import Button from '../../../../../components/Button';
import Card from '../../../../../components/Card';
import StepSection from '../../../../../components/SetUpPaymentsForm/StepSection';
import clearMask from '../../../../../helpers/clear-mask';
import formatAddress from '../../../../../helpers/format-address';
import formatPhone from '../../../../../helpers/format-phone';
import getFormattedDate from '../../../../../helpers/getFormattedDate';
import { getUserBankAccounts } from '../../../../../helpers/getUserBankAccounts';
import formatMupAddress from '../../../../../helpers/mup/formatMupAddress';
import BusinessBankIcon from '../../../../../icons/BusinessBank';
import DollarCircledIcon from '../../../../../icons/DollarCircled';
import LeaseIcon from '../../../../../icons/Lease';
import PropertyIcon from '../../../../../icons/PropertyBold';

import ChargesList from './ChargesList';

import styles from './ConfirmRentPaymentsSetup.module.scss';

const ConfirmRentPaymentsSetup = ({
  onSubmit,
  onEdit,
  lease,
  rules,
  loading,
  showLeaseEdit,
  showPropertySection = false,
  user,
}) => {
  const monthlyRules = rules?.filter((r) => r.type === 'MONTHLY') || [];
  const oneTimeRules = rules?.filter((r) => r.type === 'ONE_TIME') || [];
  const phoneNumber = formatPhone(clearMask(user?.payments_data?.phone));
  const bankAccounts = getUserBankAccounts(user);
  const { premium_subscription_subscribed: isPremium } = user;
  const showBankName = isPremium && bankAccounts.length > 1;
  const location = useLocation();
  const queryParams = parse(location?.search, { parseBooleans: true });

  const onEditClicked = (step) => {
    onEdit(step, {
      ONLINE: queryParams.ONLINE,
      OFFLINE: queryParams.OFFLINE,
    });
  };

  return (
    <Card className={styles.formContainer}>
      <h2 className={styles.title}>Confirm Rent Payments Setup</h2>
      <StepSection
        title={'Rental Details'}
        icon={PropertyIcon}
        iconSettings={{ width: 30, height: 32 }}
        step="property"
        onEdit={onEditClicked}
        show={showPropertySection}
      >
        <span
          className={styles.listingAddress}
          data-qa={'summary-lease-address'}
        >
          {formatMupAddress({
            listing: lease?.listing,
          })}
        </span>
      </StepSection>
      <StepSection
        title={'Lease Details'}
        icon={LeaseIcon}
        iconSettings={{ width: 22, height: 25 }}
        step="lease"
        showEdit={showLeaseEdit}
        onEdit={onEditClicked}
      >
        <p className={styles.subtitle}>{lease?.title}</p>
        {!showPropertySection && (
          <span
            className={styles.listingAddress}
            data-qa={'summary-lease-address'}
          >
            {formatAddress(lease?.listing)}
          </span>
        )}
        {(lease?.start_date || lease?.end_date) && (
          <div className={styles.leaseTerm}>
            <span className={styles.sectionTitle}>Lease Term:</span>&nbsp;
            <span className={styles.dates}>
              {getFormattedDate(lease?.start_date)}
            </span>
            <span className={styles.dates}>
              {' '}
              -{' '}
              {!lease?.end_date
                ? 'Month-to-Month'
                : getFormattedDate(lease?.end_date)}
            </span>
          </div>
        )}
        {!!lease?.renters.length && (
          <div>
            <span className={cx(styles.sectionTitle, styles.marginBottom8)}>
              Tenants
            </span>
            <div className={styles.rentersWrap}>
              {lease?.renters.map((renter) => (
                <div className={styles.renter} key={`renter-key-${renter.id}`}>
                  <p style={{ marginBottom: 2 }}>
                    {renter.first_name} {renter.last_name}
                  </p>
                  <div className={styles.emailField}>{renter.email}</div>
                  {renter.telephone && (
                    <div style={{ marginBottom: 2 }}>
                      {formatPhone(renter.telephone)}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </StepSection>
      <StepSection
        className={styles.chargeSection}
        title={'Charges'}
        icon={DollarCircledIcon}
        iconSettings={{ width: 27, height: 27 }}
        step="charges"
        onEdit={onEditClicked}
        showContent={monthlyRules.length > 0 || oneTimeRules.length > 0}
      >
        <ChargesList
          title="Monthly Charges"
          items={monthlyRules}
          className={styles.monthlyContainer}
          showBankName={showBankName}
          user={user}
        />
        <ChargesList
          title="One-time charges"
          items={oneTimeRules}
          showBankName={showBankName}
          user={user}
        />
      </StepSection>
      {!!bankAccounts?.length && (
        <StepSection
          title={'Bank Account'}
          icon={BusinessBankIcon}
          iconSettings={{ width: 27, height: 27 }}
          step="bank"
          onEdit={onEditClicked}
        >
          {bankAccounts.map((ba) => (
            <div key={ba.id} className={styles.bankContainer}>
              <div className={styles.bankNameContainer}>
                <span className={styles.category}>
                  {ba.nickname || ba.bank_name}
                </span>
              </div>
              <div
                className={styles.bankTextContainer}
                data-qa={'summary-bank-account'}
              >
                <BusinessBankIcon style={{ marginRight: 5 }} /> Checking ending
                in {ba.last4}
              </div>
            </div>
          ))}
          <span className={cx(styles.sectionTitle, styles.marginBottom8)}>
            Your Information
          </span>
          <div className={styles.personWrap}>
            <p style={{ marginBottom: 2 }}>
              {user?.payments_data?.person_name}
            </p>
            <div style={{ marginBottom: 2 }}>
              {formatAddress(user?.payments_data?.person_address)}
            </div>
            <div className={styles.emailField}>
              {user?.payments_data?.email}
            </div>
            <div>{phoneNumber}</div>
          </div>
        </StepSection>
      )}
      <Button
        className={styles.action}
        onClick={() =>
          onSubmit({ online: queryParams.ONLINE, offline: queryParams.OFFLINE })
        }
        loading={loading}
        data-qa="confirm-rent-payments-setup-button"
      >
        Finish Setup
      </Button>
    </Card>
  );
};

ConfirmRentPaymentsSetup.propTypes = {
  onSubmit: PropTypes.func,
  onEdit: PropTypes.func,
  lease: PropTypes.object,
  rules: PropTypes.array,
  user: PropTypes.object,
  verification_documents: PropTypes.array,
  loading: PropTypes.bool,
  showLeaseEdit: PropTypes.bool,
  showPropertySection: PropTypes.bool,
};

export default ConfirmRentPaymentsSetup;
