import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Forms = (props) => (
  <svg {...props} viewBox="0 0 50 44">
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.color}
        d="M1.97 26.849h13.333v4.848h19.394v-4.848H48.03v15.757H1.97z"
      />
      <path
        d="M42.172.182c.518 0 .945.383 1.003.876l.007.116v24.793h5.05c.558 0 1.01.444 1.01.992v15.867c0 .548-.452.992-1.01.992H1.768c-.558 0-1.01-.444-1.01-.992V26.96c0-.548.452-.992 1.01-.992h5.05V5.14c0-.508.39-.927.892-.985l.118-.006 11.11-.001.001-2.974c0-.509.39-.928.893-.986l.117-.006h22.223ZM14.899 27.95H2.778v13.885h44.444V27.95H35.101v2.976c0 .547-.452.991-1.01.991H15.909c-.558 0-1.01-.444-1.01-.991V27.95Zm4.04-21.818h-10.1l-.001 19.835h7.071c.558 0 1.01.444 1.01.992v2.975h2.02V6.132Zm22.223-3.967H20.96v27.769h12.12v-2.975c0-.548.453-.992 1.01-.992h7.072V2.165ZM38.132 22c.557 0 1.01.444 1.01.992 0 .547-.453.991-1.01.991H23.99c-.558 0-1.01-.444-1.01-.991 0-.548.452-.992 1.01-.992Zm0-3.967c.557 0 1.01.444 1.01.992s-.453.992-1.01.992H23.99c-.558 0-1.01-.444-1.01-.992s.452-.992 1.01-.992Zm0-3.967c.557 0 1.01.444 1.01.992s-.453.992-1.01.992H23.99c-.558 0-1.01-.444-1.01-.992s.452-.992 1.01-.992Zm0-3.967c.557 0 1.01.444 1.01.992s-.453.992-1.01.992H23.99c-.558 0-1.01-.444-1.01-.992s.452-.992 1.01-.992Zm0-3.967c.557 0 1.01.444 1.01.992s-.453.992-1.01.992H28.03c-.558 0-1.01-.444-1.01-.992s.452-.992 1.01-.992Z"
        fill="#042238"
      />
    </g>
  </svg>
);

Forms.defaultProps = {
  width: 50,
  height: 44,
  color: '#7FE3FF',
};

Forms.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(Forms);
