import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import Logo from '../../../components/Logo';
import { segmentTracking } from '../../../services/utilities';

import styles from './AuditLAHeader.module.scss';

const AuditLAHeader = ({ step }) => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <Link to={'/'}>
        <Logo className={styles.logo} color={'white'} />
      </Link>
      <div className={styles.colRight}>
        {step !== 'upload' && (
          <Button
            secondary
            onClick={() => {
              history.push('/lease-agreement-audit-ai');
              segmentTracking('run_new_audit clicked', {
                location: 'Lease Audit Results Page',
              });
            }}
            className={cx(styles.buildYourOwn, styles.mobileHidden)}
          >
            Run a New Audit
          </Button>
        )}
      </div>
    </div>
  );
};

AuditLAHeader.propTypes = {
  step: PropTypes.string,
};

export default AuditLAHeader;
