import React from 'react';
import PropTypes from 'prop-types';

import MarketingList from '../../../../../components/MarketingList';
import SidebarMarketing from '../../../../../components/SidebarMarketing';
import ApplicantThick from '../../../../../icons/ApplicantThick';
import DollarCircledThick from '../../../../../icons/DollarCircledThick';
import Lease from '../../../../../icons/Lease';
import MarketingThick from '../../../../../icons/MarketingThick';
import ScreeningOption from '../../../../../icons/ScreeningOption';

import styles from './SideBarMarketingSignUp.module.scss';

const SideBarMarketingSignUp = ({ isBroker }) => {
  const marketingListItems = [
    ...(!isBroker
      ? [
          {
            title: 'Rental Marketing',
            icon: MarketingThick,
            text: 'Advertise your rental on dozens of sites for free.',
          },
        ]
      : []),
    {
      title: 'Rental Applications',
      icon: ApplicantThick,
      text: 'Get the info you need to decide on a tenant.',
    },
    {
      title: 'Tenant Screening',
      icon: ScreeningOption,
      text: 'Request a credit, criminal, and eviction report. ',
    },
    {
      title: 'Online Rent Collection',
      icon: DollarCircledThick,
      text: 'Make collecting rent convenient for everyone.',
    },
    {
      title: 'Lease Agreements',
      icon: Lease,
      text: 'Get a customizable, state-specific lease agreement.',
    },
  ];
  return (
    <SidebarMarketing
      className={styles.sidebarMarketing}
      title="For landlords by landlords"
      subtitle="Manage The Entire Rental Process"
      description={<MarketingList items={marketingListItems} />}
    />
  );
};

SideBarMarketingSignUp.propTypes = {
  isBroker: PropTypes.bool,
};

export default SideBarMarketingSignUp;
