import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import cx from 'classnames';

import LoadingScreen from '../../../components/LoadingScreen';
import Logo from '../../../components/Logo';
import { silentLogout } from '../../../core/auth/authService';
import { getExternalApplication } from '../../../graphql/queries/externalApplicationQuery.graphql';
import RedfinLogo from '../../../icons/RedfinLogo';

import ScreeningReportError from './error/ScreeningReportError';
import PartnerApplicantView from './report/PartnerApplicantView';

import styles from './PartnerApplicantViewWrapper.module.scss';

const PartnerApplicantViewWrapper = () => {
  /*
  This is a fix to logout any TT user that has logged in previously in the browser.
  For now it's really weird for it to happen in real life, but if at some point 
  we convert Redfin users to TT it's a possible scenario.
  If we don't logout the TT user, it sends a mixed information to the BE using both tokens
  and we throw an error while accessing the Redfin Screening Report.
  TODO: Find a way so both work together.
  */
  useEffect(() => {
    silentLogout();
  }, []);

  const { data, loading, error } = useQuery(getExternalApplication, {
    // TODO: find a better way to get the report after pulled, maybe using websocket
    pollInterval: 30000,
  });

  if (loading) return <LoadingScreen loading />;

  return (
    <div className={cx(styles.container, { [styles.error]: error != null })}>
      <div className={styles.logoContainer}>
        <RedfinLogo className={styles.partnerLogo} />

        <div className={styles.poweredByTT}>
          <span>Powered by</span>
          <Logo />
        </div>
      </div>
      {error ? (
        <ScreeningReportError />
      ) : (
        <PartnerApplicantView application={data?.external_application} />
      )}
    </div>
  );
};

export default PartnerApplicantViewWrapper;
