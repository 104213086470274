import { useMutation } from '@apollo/client';
import { CometChat } from '@cometchat-pro/chat';

import { useSuccessToast } from '../components/Toast';
import archiveChatMutation from '../graphql/mutations/archiveChat.graphql';

export const useChatArchive = () => {
  const [archiveChat, { loading: archiveInProgress }] =
    useMutation(archiveChatMutation);
  const showToast = useSuccessToast();

  const extractOwnerRenterIds = (id) => {
    if (!id) {
      return {};
    }

    const parts = id.split('_');
    const ownerId = `${parts[0]}_${parts[1]}`;
    const renterId = `${parts[3]}_${parts[4]}`;

    return {
      ownerId,
      renterId,
    };
  };
  const handleChatArchiving = async (conversationId, onSuccess) => {
    const { ownerId, renterId } = extractOwnerRenterIds(conversationId);
    if (!ownerId || !renterId) {
      return;
    }
    const chatArchived = await archiveChat({
      variables: {
        renterId,
        chatOwnerId: ownerId,
        tags: ['archive_conversation'],
      },
    });

    if (chatArchived?.data?.archiveChat?.ok) {
      if (onSuccess) {
        onSuccess();
        showToast('Chat archived');
      }
    }
  };

  const unarchiveChat = async (conversationId) => {
    const { ownerId, renterId } = extractOwnerRenterIds(conversationId);

    if (!ownerId || !renterId) {
      return;
    }

    const conversation = await CometChat.getConversation(renterId, 'user');
    if (
      !ownerId ||
      !renterId ||
      !conversation?.tags ||
      !conversation?.tags?.includes('archive_conversation')
    ) {
      return;
    }
    try {
      await archiveChat({
        variables: {
          renterId,
          chatOwnerId: ownerId,
          tags: [],
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return {
    handleChatArchiving,
    archiveInProgress,
    unarchiveChat,
  };
};
