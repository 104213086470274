import React from 'react';
import PropTypes from 'prop-types';

import links from './late-fee-support-articles.json';

import styles from './LateFeeSupportArticleLink.module.scss';

const LateFeeSupportArticleLink = ({ children, state, city }) => {
  const href =
    links[state][(city || '').toLowerCase()] || links[state]['default'];

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.lateFeeSupportArticleLink}
    >
      {children}
    </a>
  );
};

LateFeeSupportArticleLink.propTypes = {
  children: PropTypes.node,
  state: PropTypes.string.isRequired,
  city: PropTypes.string,
};

export default LateFeeSupportArticleLink;
