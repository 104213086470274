import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './HelpfulInfoListItem.module.scss';

const HelpfulInfoListItem = ({
  children,
  className,
  icon: Icon,
  iconProps: { className: iconClassName, ...otherIconProps },
}) => {
  return (
    <li
      className={cx(
        styles.helpfulListItem,
        { [styles.withIcon]: Icon },
        className,
      )}
    >
      {Icon && (
        <span>
          <Icon
            className={cx(styles.icon, iconClassName)}
            {...otherIconProps}
          />
        </span>
      )}
      {children}
    </li>
  );
};

HelpfulInfoListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.object,
  iconProps: PropTypes.object,
};

HelpfulInfoListItem.defaultProps = {
  iconProps: {},
};

export default HelpfulInfoListItem;
