import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import Input from '../../../../../../../components/Input';
import ResourcesIcon from '../../../../../../../icons/resources';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredShortStandard from '../../../../../../../validators/fieldRequiredShort';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from '../../../BankInfo.module.scss';

const BankInfo = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequiredShort = conditionalValidation(
    fieldRequiredShortStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <HelpfulInfoCard
          collapsable
          Icon={ResourcesIcon}
          className={styles.helpfulInfoCard}
          title="Kentucky Security Deposit Laws"
          color="iceCold"
        >
          Though they're included in the lease agreement, we've highlighted some
          rules you need to know:
          <HelpfulInfoList className={styles.helpfulInfoList}>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              No statutory minimum deposit amount in Kentucky. Typically,
              deposits are 1-2 times the monthly rent.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              If rent or other charges are owed at the time the Lease terminates
              you must return the deposit within 30 days, less the amounts owed.
              If no rent or past due amounts are owed, then you have 60 days to
              provide written notice to return the security deposit.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              You have a duty to send a written statement of charges against the
              security deposit to the Tenant’s last known address within the
              above applicable time period. If Tenant does not respond within 60
              days, you may keep the security deposit.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Security deposits must be held in a separate account at a
              financial institution and those details must be shared before
              tenancy. We are collecting those details below.
            </HelpfulInfoListItem>
            <div className={styles.learnMore}>
              <a
                href="http://support.turbotenant.com/en/articles/6192505-kentucky-lease-agreement"
                target="_blank noopener noreferrer"
              >
                Learn other security deposit laws
              </a>
            </div>
          </HelpfulInfoList>
        </HelpfulInfoCard>
        <Field
          label="Financial Institution Name where security deposit will be held"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_name"
          name="deposit_bank_name"
          component={Input}
          validate={fieldRequiredShort}
        />
        <Field
          label="Financial Institution Address"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_address"
          name="deposit_bank_address"
          component={Input}
          validate={fieldRequiredShort}
        />
        <Field
          label="Account Number"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="custom_state_data.bank_account_number"
          name="custom_state_data.bank_account_number"
          component={Input}
          validate={fieldRequiredShort}
        />
      </div>
    </div>
  );
};

BankInfo.propTypes = {
  className: PropTypes.string,
};

export default BankInfo;
