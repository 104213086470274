import React from 'react';
import { isEmpty, lowerCase, startCase } from 'lodash';
import momentTz from 'moment-timezone';

import colors from '../constants/colors';
import MaintenanceActivityTypesEnum from '../constants/enums/MaintenanceActivityTypesEnum';
import AccountNav from '../icons/AccountNav';
import Documents from '../icons/Documents';
import Invoice from '../icons/Invoice';
import Lula from '../icons/Lula';
import MaintenanceAltThick from '../icons/MaintenanceAltThick';
import PaperPicture from '../icons/PaperPicture';
import Pencil from '../icons/Pencil';
import Personalize from '../icons/Personalize';
import ResolvedThick from '../icons/ResolvedThick';
import Share from '../icons/Share';
import ThumbsDownLineThick from '../icons/ThumbsDownLineThick';
import ThumbsUpLineThick from '../icons/ThumbsUpLineThick';

import getFormattedDateLocalTZ from './format-date-local';
import getDecodedId from './getDecodedId';

const ShareGreen = () => <Share color={colors.lightGreenAlt} />;
const LulaIcon = () => <Lula height={14} />;
const InvoiceIcon = () => <Invoice />;

const sortActivities = (activities) => {
  return activities.sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );
};

const formatDate = (date) =>
  getFormattedDateLocalTZ(date, 'MMM DD, YYYY | hh:mm A');

const formatReasonString = (str) =>
  str
    ? str.replace(/-(.)/g, ' $1').replace(/^./, (str) => str.toUpperCase())
    : '';

const lulaActivitiesDataMapper = {
  'Scheduling': {
    content: ({ additional_data }) => {
      if (
        !additional_data?.rescheduleDetails ||
        isEmpty(additional_data?.rescheduleDetails)
      ) {
        return [];
      }
      return [
        {
          label: 'Reason',
          text: formatReasonString(additional_data.rescheduleDetails.reason),
        },
        ...(additional_data.rescheduleDetails.details
          ? [
              {
                label: 'Details',
                text: additional_data.rescheduleDetails.details,
              },
            ]
          : []),
      ];
    },
  },
  'Scheduled': {
    content: ({ additional_data, listingTZ }) => {
      if (!additional_data?.serviceSchedule?.serviceWindow) {
        return [];
      }
      const { startTimeUTC, endTimeUTC } =
        additional_data.serviceSchedule.serviceWindow;
      return [
        {
          label: 'Date',
          text: getFormattedDateLocalTZ(
            new Date(startTimeUTC),
            'MMMM DD, YYYY',
          ),
        },
        {
          label: 'Start Time',
          text: listingTZ
            ? momentTz(new Date(startTimeUTC)).tz(listingTZ).format('h:mm a z')
            : '',
        },
        {
          label: 'End Time',
          text: listingTZ
            ? momentTz(new Date(endTimeUTC)).tz(listingTZ).format('h:mm a z')
            : listingTZ,
        },
      ];
    },
  },
  'Pro Resolved': {
    content: ({ additional_data }) => {
      return [
        {
          label: 'Completion Date',
          text: getFormattedDateLocalTZ(
            new Date(additional_data.completionDate),
            'M/D/YYYY',
          ),
        },
        ...(additional_data?.serviceNotes?.map((note) => ({
          label: 'Service Notes',
          text: note,
        })) || []),
      ];
    },
    attachments: (attachments) => {
      return attachments.map((attachment) => ({
        ...attachment,
      }));
    },
  },
  'Invoice': {
    attachments: (attachments) => {
      return attachments.map((attachment) => ({
        ...attachment,
        icon: InvoiceIcon,
      }));
    },
  },
  'Invoice Payment Failed': {
    content: ({ additional_data }) => {
      return additional_data?.reason
        ? [
            {
              label: 'Reason',
              text: additional_data.reason,
            },
          ]
        : [];
    },
    actions: ({ unpaidInvoice, isMostRecentFailedPayment }) => {
      return unpaidInvoice && isMostRecentFailedPayment
        ? [
            {
              label: 'Retry Payment',
              data: unpaidInvoice.additional_data,
            },
          ]
        : [];
    },
  },
  'Receipt': {
    attachments: (_, additional_data) => {
      return additional_data.receipts.map((receipt) => ({
        filename: 'Receipt',
        url: `/receipt/${receipt.receiptId}/${receipt.receiptType}`,
        icon: InvoiceIcon,
      }));
    },
  },
  'Paused': {
    content: ({ additional_data }) => {
      const { pauseDetails } = additional_data;
      if (!pauseDetails) {
        return [];
      }
      return [
        ...(pauseDetails.reason
          ? [
              {
                label: 'Reason',
                text: formatReasonString(pauseDetails.reason),
              },
            ]
          : []),
        ...(pauseDetails.details
          ? [
              {
                label: 'Details',
                text: pauseDetails.details,
              },
            ]
          : []),
      ];
    },
  },
  'Canceled': {
    content: ({ additional_data }) => {
      const { cancelDetails } = additional_data;
      if (!cancelDetails) {
        return [];
      }
      return [
        ...(cancelDetails.reason
          ? [
              {
                label: 'Reason',
                text: formatReasonString(cancelDetails.reason),
              },
            ]
          : []),
        ...(cancelDetails.details
          ? [
              {
                label: 'Details',
                text: cancelDetails.details,
              },
            ]
          : []),
      ];
    },
  },
  'Resolved Remotely': {
    content: ({ additional_data }) => {
      if (!additional_data?.details) {
        return [];
      }
      return [
        {
          label: 'Details',
          text: additional_data.details,
        },
      ];
    },
  },
  'Quote Needs Review': {
    content: ({ additional_data }) => {
      const { createdAt, expiresAt } = additional_data;
      return [
        ...(createdAt
          ? [
              {
                label: 'Created at',
                text: getFormattedDateLocalTZ(
                  new Date(createdAt),
                  'MM/DD/YYYY hh:mm a',
                ),
              },
            ]
          : []),
        ...(expiresAt
          ? [
              {
                label: 'Expires at',
                text: getFormattedDateLocalTZ(
                  new Date(expiresAt),

                  'MM/DD/YYYY hh:mm a',
                ),
              },
            ]
          : []),
      ];
    },
  },
  'Quote Updated': {
    content: ({ additional_data }) => {
      const { createdAt, expiresAt } = additional_data;
      return [
        ...(createdAt
          ? [
              {
                label: 'Created at',
                text: getFormattedDateLocalTZ(
                  new Date(createdAt),
                  'MM/DD/YYYY hh:mm a',
                ),
              },
            ]
          : []),
        ...(expiresAt
          ? [
              {
                label: 'Expires at',
                text: getFormattedDateLocalTZ(
                  new Date(expiresAt),
                  'MM/DD/YYYY hh:mm a',
                ),
              },
            ]
          : []),
      ];
    },
  },
  'Quote Approved': {
    content: ({ additional_data }) => {
      const { reviewedAt } = additional_data;

      return [
        ...(reviewedAt
          ? [
              {
                label: 'Reviewed at',
                text: getFormattedDateLocalTZ(
                  new Date(reviewedAt),
                  'MM/DD/YYYY hh:mm a',
                ),
              },
            ]
          : []),
      ];
    },
  },
  'Quote Rejected': {
    content: ({ additional_data }) => {
      const { reviewedAt } = additional_data;

      return [
        ...(reviewedAt
          ? [
              {
                label: 'Reviewed at',
                text: getFormattedDateLocalTZ(
                  new Date(reviewedAt),
                  'MM/DD/YYYY hh:mm a',
                ),
              },
            ]
          : []),
      ];
    },
  },
  'Quote Expiring': {
    content: ({ additional_data }) => {
      const { expiresAt } = additional_data;
      return [
        ...(expiresAt
          ? [
              {
                label: 'Expires at',
                text: getFormattedDateLocalTZ(
                  new Date(expiresAt),
                  'MM/DD/YYYY hh:mm a',
                ),
              },
            ]
          : []),
      ];
    },
  },
  'Quote Restored': {
    content: ({ additional_data }) => {
      const { createdAt, expiresAt } = additional_data;
      return [
        ...(createdAt
          ? [
              {
                label: 'Created at',
                text: getFormattedDateLocalTZ(
                  new Date(createdAt),
                  'MM/DD/YYYY hh:mm a',
                ),
              },
            ]
          : []),
        ...(expiresAt
          ? [
              {
                label: 'Expires at',
                text: getFormattedDateLocalTZ(
                  new Date(expiresAt),
                  'MM/DD/YYYY hh:mm a',
                ),
              },
            ]
          : []),
      ];
    },
  },
  // For LULA_NOTE_EVENT, the content is the note itself so we use the type as the key instead
  [MaintenanceActivityTypesEnum.LULA_NOTE_EVENT]: {
    content: ({ additional_data: note }) => {
      return [
        {
          text: note,
        },
      ];
    },
  },
};

const getActivityData = ({
  item: {
    type,
    content,
    additional_data,
    created_at,
    private: isPrivate, // private is a reserved word
    attachments,
  },
  userId,
  listingTZ,
  unpaidInvoice,
  isMostRecentFailedPayment,
}) => {
  const formattedDate = formatDate(created_at);

  let activity;
  switch (type) {
    case MaintenanceActivityTypesEnum.OPENED:
      activity = {
        icon: Personalize,
        title: 'Status Update: Open',
        date: formattedDate,
      };
      break;
    case MaintenanceActivityTypesEnum.IN_PROGRESS:
      activity = {
        icon: Personalize,
        title: 'Status Update: In Progress',
        date: formattedDate,
      };
      break;
    case MaintenanceActivityTypesEnum.DISMISSED:
      activity = {
        icon: Personalize,
        title: 'Status Update: Dismissed',
        date: formattedDate,
      };
      break;
    case MaintenanceActivityTypesEnum.RESOLVED:
      activity = {
        icon: ResolvedThick,
        title: 'Resolved',
        date: formattedDate,
      };
      break;
    case MaintenanceActivityTypesEnum.RE_OPENED:
      activity = {
        icon: MaintenanceAltThick,
        title: 'Re-opened',
        date: formattedDate,
      };
      break;
    case MaintenanceActivityTypesEnum.STATUS_UPDATE:
      activity = {
        icon: MaintenanceAltThick,
        title: 'Status Update: ' + startCase(lowerCase(content)),
        date: formattedDate,
      };
      break;
    case MaintenanceActivityTypesEnum.FEEDBACK:
      activity = {
        icon:
          additional_data?.feedback === 'GOOD'
            ? ThumbsUpLineThick
            : ThumbsDownLineThick,
        title: `Feedback from ${additional_data?.renterFirstName} ${additional_data?.renterLastName}`,
        date: formattedDate,
        content,
      };
      break;
    case MaintenanceActivityTypesEnum.USER_EVENT:
      activity = {
        icon: Pencil,
        title: `Request Edited by ${
          getDecodedId(userId) === additional_data?.userId
            ? 'You'
            : `${additional_data?.first_name} ${additional_data?.last_name}`
        }`,
        date: formattedDate,
      };
      break;
    case MaintenanceActivityTypesEnum.NOTE:
      activity = {
        icon:
          additional_data?.attachments?.length > 0 ? PaperPicture : Documents,
        title: 'Note Added',
        date: formattedDate,
        content,
      };
      break;
    case MaintenanceActivityTypesEnum.WORK_ORDER_SENT:
      activity = {
        icon: Share,
        title: 'Work Order Sent',
        date: formattedDate,
        content: `${additional_data?.toEmail} | ${content}`,
      };
      break;
    case MaintenanceActivityTypesEnum.LULA_WORK_ORDER_SENT:
      activity = {
        icon: ShareGreen,
        title: 'Sent To Lula',
        date: formattedDate,
      };
      break;
    case MaintenanceActivityTypesEnum.LULA_TROUBLESHOOTING:
      activity = {
        icon: LulaIcon,
        title: 'Troubleshooting',
        date: formattedDate,
      };
      break;
    case MaintenanceActivityTypesEnum.LULA_EVENT:
      activity = {
        icon: LulaIcon,
        title: content,
        date: formattedDate,
        content: lulaActivitiesDataMapper[content]?.['content']?.({
          additional_data,
          listingTZ,
        }),
        attachments: lulaActivitiesDataMapper[content]?.['attachments']?.(
          attachments,
          additional_data,
        ),
        actions: lulaActivitiesDataMapper[content]?.['actions']?.({
          additional_data,
          unpaidInvoice,
          isMostRecentFailedPayment,
        }),
      };
      break;
    case MaintenanceActivityTypesEnum.LULA_STATUS_UPDATE:
      activity = {
        icon: LulaIcon,
        title: content,
        date: formattedDate,
        content: lulaActivitiesDataMapper[content]?.['content']?.({
          additional_data,
          listingTZ,
        }),
        attachments: lulaActivitiesDataMapper[content]?.['attachments']?.(
          attachments,
          additional_data,
        ),
      };
      break;
    case MaintenanceActivityTypesEnum.LULA_QUOTE_UPDATE:
      activity = {
        icon: LulaIcon,
        title: content,
        date: formattedDate,
        content: lulaActivitiesDataMapper[content]?.['content']?.({
          additional_data,
        }),
      };
      break;
    case MaintenanceActivityTypesEnum.LULA_NOTE_EVENT:
      activity = {
        icon: LulaIcon,
        title: 'Note Added',
        date: formattedDate,
        content: lulaActivitiesDataMapper[
          MaintenanceActivityTypesEnum.LULA_NOTE_EVENT
        ]?.['content']?.({ additional_data: content }),
      };
      break;
    default:
      activity = {};
      break;
  }

  return { attachments, ...activity, type, private: isPrivate } || {};
};

// MLULA TODO: We might have this be an activity item itself so addCreationActivity might be unnecessary
const addCreationActivity = (
  activityArray,
  creationDate,
  isRequester,
  requester,
) => {
  function getTitle() {
    const titlePrefix = 'Requested by';
    if (isRequester) {
      return `${titlePrefix} You`;
    }
    return `${titlePrefix}  ${requester.first_name}  ${requester.last_name}`;
  }

  activityArray.push({
    icon: AccountNav,
    title: getTitle(),
    date: formatDate(creationDate),
  });
};

const getFormattedMaintenanceActivity = ({
  activity,
  creationDate,
  isRequester,
  requester,
  attachments,
  userId,
  listingTZ,
}) => {
  const activities = [...activity].map((a) => ({
    ...a,
    attachments: (attachments || []).filter(
      (att) => att.maintenance_request_activity_id === a.id,
    ),
  }));
  const activitySorted = sortActivities(activities);
  const oldToNewActivities = [...activitySorted].reverse();
  const unpaidInvoice = oldToNewActivities.find((activity, index) => {
    return (
      activity.content === 'Invoice' &&
      oldToNewActivities
        .slice(index + 1)
        .every((subActivity) => subActivity.content !== 'Receipt')
    );
  });
  const mostRecentFailedPaymentIndex = activitySorted.findIndex(
    (activity) => activity.content === 'Invoice Payment Failed',
  );

  const formattedActivity = activitySorted.map((item, i) =>
    getActivityData({
      item,
      userId,
      listingTZ,
      unpaidInvoice,
      isMostRecentFailedPayment: i === mostRecentFailedPaymentIndex,
    }),
  );
  addCreationActivity(formattedActivity, creationDate, isRequester, requester);
  return formattedActivity;
};

export default getFormattedMaintenanceActivity;
