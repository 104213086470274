import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const ChevronRight = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 25" fill={color} {...rest}>
    <path d="M7.24744 5.64498C7.58314 5.26131 8.14727 5.19591 8.55899 5.47417L8.65852 5.5509L15.6585 11.5509C16.0813 11.9209 16.1115 12.558 15.7491 12.9662L15.6585 13.0561L8.65852 19.0561C8.24288 19.4197 7.61112 19.3776 7.24744 18.962C6.91173 18.5783 6.92179 18.0105 7.25224 17.6394L7.34151 17.5509L13.481 12.3035L7.34151 7.05606C6.95784 6.72035 6.89244 6.15622 7.1707 5.74451L7.24744 5.64498Z" />
  </svg>
);

ChevronRight.defaultProps = {
  width: 24,
  height: 25,
  color: colors.primary,
};

ChevronRight.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ChevronRight);
