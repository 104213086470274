import React from 'react';
import cx from 'classnames';

import convertCardinalToOrdinal from '../../../../../helpers/cardinal-to-ordinal';
import { getBankNameFromAccount } from '../../../../../helpers/getBankNameFromAccount';
import getFormattedDate from '../../../../../helpers/getFormattedDate';
import toCurrency from '../../../../../helpers/toCurrency';
import useUserDefaultEntityAccounts from '../../../../../hooks/entities/useUserDefaultEntityAccounts';
import { getRentPaymentCategoryLabel } from '../../../../../services/utilities';

import styles from './ChargesList.module.scss';

const ChargesList = ({ title, items = [], className }) => {
  const accounts = useUserDefaultEntityAccounts();
  const defaultAccount = accounts.find((a) => a.default_for_currency);
  const defaultAccountName = getBankNameFromAccount({
    account: defaultAccount,
    short: true,
  });

  if (items?.length === 0) return null;

  return (
    <>
      <div className={className}>
        <span className={cx(styles.sectionTitle, styles.marginBottom8)}>
          {title}
        </span>
        {items.map((charge = {}, idx) => {
          const {
            category,
            description,
            amount,
            due_day_of_month,
            end_date,
            type,
            short_bank_name,
          } = charge;
          const bankName = short_bank_name || defaultAccountName;
          const date =
            type === 'ONE_TIME'
              ? getFormattedDate(end_date)
              : `the ${convertCardinalToOrdinal(due_day_of_month)}`;

          return (
            <div className={styles.chargeContainer} key={`charge-key-${idx}`}>
              <div className={styles.chargeRowInfo}>
                <div className={styles.bankNameContainer}>
                  <div>
                    <span
                      className={styles.category}
                      data-qa={`charge-${idx}-summary-category`}
                    >
                      {getRentPaymentCategoryLabel(category)}
                    </span>
                    {description && (
                      <span>
                        &nbsp;{' - '}
                        {description}
                      </span>
                    )}
                  </div>
                </div>

                <div className={styles.amountDueDate}>
                  {toCurrency(amount / 100)}
                  {` due on ${date}`}
                </div>
              </div>
              {bankName && (
                <div className={styles.chargeBankName}>{bankName}</div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ChargesList;
