import React, { useEffect, useRef } from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useWindowWidth } from '@react-hook/window-size';
import cx from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import FlatButton from '../../../../../components/FlatButton';
import Input from '../../../../../components/Input';
import InputWithIcon from '../../../../../components/InputWithIcon';
import { Tooltip } from '../../../../../components/Tooltip';
import breakpoints from '../../../../../constants/media-breakpoints';
import DollarIcon from '../../../../../icons/Dollar';
import MinusCircleIcon from '../../../../../icons/MinusCircle';
import PlusCircleIcon from '../../../../../icons/PlusCircle';
import composeValidators from '../../../../../validators/composeValidators';
import fieldRequiredShort from '../../../../../validators/fieldRequiredShort';
import isNumber from '../../../../../validators/isNumber';

import styles from './OneTimeFees.module.scss';

const MOBILE_BREAKPOINT = breakpoints.mobileBreakpoint;

const ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove',
  EDIT: 'edit',
};

const feesReducer = (state, action) => {
  const { type, id, data } = action;
  const newState = [...state];

  switch (type) {
    case ACTIONS.ADD:
      return [
        ...state,
        {
          name: '',
          amount: '',
        },
      ];
    case ACTIONS.REMOVE: {
      newState.splice(id, 1);
      return newState;
    }
    case ACTIONS.EDIT: {
      newState[id] = {
        ...newState[id],
        ...data,
      };
      return newState;
    }
    default:
      return state;
  }
};

const OneTimeFees = ({ input, limit }) => {
  const screenWidth = useWindowWidth();

  const currentField = useRef(null);
  const fees = get(input, 'value') || [];

  const modFees = (action) => {
    const newValue = feesReducer(fees, action);
    input.onChange(newValue);
  };

  useEffect(() => {
    if (currentField.current) {
      currentField.current.focus();
    }
  }, [currentField.current]);

  return (
    <React.Fragment>
      <div>
        {fees.map((_fee, index) => (
          <React.Fragment key={index}>
            <div className={styles.row}>
              <OnChange name={`${input.name}.${index}.name`}>
                {(value) => {
                  modFees({
                    type: ACTIONS.EDIT,
                    id: index,
                    data: {
                      name: value,
                    },
                  });
                }}
              </OnChange>
              <Field
                label="Fee Name"
                component={Input}
                id={`${input.name}.${index}.name`}
                name={`${input.name}.${index}.name`}
                validate={fieldRequiredShort}
                className={styles.inputContainer}
                labelProps={{
                  className: styles.inputLabel,
                }}
                inputProps={{
                  className: styles.input,
                  ref:
                    index === fees.length - 1
                      ? (ref) => (currentField.current = ref)
                      : undefined,
                }}
              />
              <OnChange name={`${input.name}.${index}.amount`}>
                {(value) => {
                  modFees({
                    type: ACTIONS.EDIT,
                    id: index,
                    data: {
                      amount: value,
                    },
                  });
                }}
              </OnChange>
              <Field
                label="Fee Amount"
                component={InputWithIcon}
                id={`${input.name}.${index}.amount`}
                name={`${input.name}.${index}.amount`}
                type="number"
                step=".01"
                pattern="[0-9]*"
                inputMode="decimal"
                validate={composeValidators(fieldRequiredShort, isNumber)}
                icon={DollarIcon}
                className={styles.inputContainer}
                labelProps={{
                  className: styles.inputLabel,
                }}
                inputProps={{
                  className: styles.input,
                }}
              />
            </div>
            {fees.length > 1 && (
              <FlatButton
                type="button"
                onClick={() => {
                  modFees({
                    type: ACTIONS.REMOVE,
                    id: index,
                  });
                }}
                icon={MinusCircleIcon}
                disabled={fees.length === 1}
                className={cx(styles.btn, styles.remove)}
              >
                Remove this fee
              </FlatButton>
            )}
          </React.Fragment>
        ))}
      </div>
      <FlatButton
        data-tooltip-id="add_fee_tooltip"
        data-tooltip-hidden={fees.length !== limit}
        type="button"
        onClick={() => {
          /**
           * Manually disable the button because having
           * `disable` prop on it breaks the tooltip
           */
          if (fees.length === limit) {
            return;
          }
          modFees({
            type: ACTIONS.ADD,
          });
        }}
        icon={PlusCircleIcon}
        className={cx(styles.btn, styles.add, {
          [styles.disabled]: fees.length === limit,
        })}
      >
        Add additional fee
      </FlatButton>
      <Tooltip
        id="add_fee_tooltip"
        place={screenWidth < MOBILE_BREAKPOINT ? 'bottom' : 'right'}
        offset={screenWidth < MOBILE_BREAKPOINT ? 24 : 0}
      >
        <span>You've reached the maximum number of fees allowed.</span>
      </Tooltip>
    </React.Fragment>
  );
};

OneTimeFees.propTypes = {
  input: PropTypes.object,
  limit: PropTypes.number,
};

export default OneTimeFees;
