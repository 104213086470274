import Calculator from '../../../../../icons/twoTone/Calculator';
import Description from '../../../../../icons/twoTone/Description';
import Ebook from '../../../../../icons/twoTone/Ebook';
import Legal from '../../../../../icons/twoTone/Legal';
import Podcast from '../../../../../icons/twoTone/Podcast';
import ResourceBlue from '../../../../../icons/twoTone/Resources';
import Sent from '../../../../../icons/twoTone/Sent';
import VirtualShowing from '../../../../../icons/twoTone/VirtualShowing';

const items = [
  {
    Icon: Podcast,
    title: 'Podcasts',
    description:
      'Watch or listen to our podcast discussions on key landlording topics.',
    onClick: () => {
      window.open('https://www.turbotenant.com/education/podcasts/', '_blank');
    },
  },
  {
    Icon: Ebook,
    title: 'Ebooks',
    description: 'Property management insights to keep you informed.',
    onClick: () => {
      window.open('https://www.turbotenant.com/education/ebooks/', '_blank');
    },
  },
  {
    Icon: VirtualShowing,
    title: 'Webinars',
    description:
      'Watch a recording or tune in to a live webinar hosted by our Education team.',
    onClick: () => {
      window.open('https://www.turbotenant.com/webinars/', '_blank');
    },
  },
  {
    Icon: Description,
    title: 'Blog',
    description:
      'Need more information about a tricky rental subject? Find it in our blog.',
    onClick: () => {
      window.open('https://www.turbotenant.com/blog/', '_blank');
    },
  },
  {
    Icon: Sent,
    title: 'TheKey Newsletter',
    description:
      'Our weekly landlord roundup of actionable tips and trending topics.',
    onClick: () => {
      window.open('https://www.turbotenant.com/thekey-newsletter/', '_blank');
    },
  },
  {
    Icon: ResourceBlue,
    title: 'Property Management Dictionary',
    description: 'Talk the talk with the terms you need to know.',
    onClick: () => {
      window.open(
        'https://www.turbotenant.com/property-management-definitions/',
        '_blank',
      );
    },
  },
  {
    Icon: Legal,
    title: 'Landlord-Tenant Laws',
    description: 'Know your state’s laws and avoid expensive lawsuits.',
    onClick: () => {
      window.open(
        'https://www.turbotenant.com/state-landlord-tenant-laws/',
        '_blank',
      );
    },
  },

  {
    Icon: Calculator,
    title: 'Rent Estimate Calculator',
    description: 'Estimate the perfect rent amount to optimize your ROI.',
    onClick: () => {
      window.open(
        'https://rental.turbotenant.com/rent-estimate-report',
        '_blank',
      );
    },
  },
];

export default items;
