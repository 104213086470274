import React from 'react';

import SelectField from '../SelectField';

import CoreField from './CoreField';

export const SelectFinalField = (props) => (
  <CoreField component={SelectField} {...props} />
);

export default SelectFinalField;
