const NAME_REGEX =
  /^([A-Za-z\u0027\u005F-\u0060\u00B4\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]+[.-]?[A-Za-z\u0027\u005F-\u0060\u00B4\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)+$/g;

export const validName = (value) => {
  if (value && !value.match(NAME_REGEX)) {
    return 'Only letters, hyphens, accents and single dots are allowed.';
  }

  // Additional check to explicitly disallow sequences that look like URLs
  if (/[\w-]+(\.[\w-]+)+/.test(value)) {
    return 'Any dots should be followed by a space or be at the end.';
  }

  return undefined;
};

export default validName;
