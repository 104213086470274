import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Reputation = (props) => (
  <svg {...props} viewBox="0 0 19 28">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#7FE3FF"
        d="m9.333 20.5-2.547 1.339.486-2.836-2.06-2.008 2.847-.414L9.333 14l1.274 2.58 2.847.415-2.06 2.008.486 2.836z"
      />
      <path
        fill="#042238"
        d="M9.333 9.556c4.971 0 9 3.979 9 8.888 0 4.91-4.028 8.89-9 8.89-4.97 0-9-3.98-9-8.89 0-4.909 4.03-8.888 9-8.888Zm0 1.11c-4.349 0-7.875 3.483-7.875 7.778 0 4.297 3.526 7.778 7.875 7.778 4.35 0 7.875-3.481 7.875-7.778 0-4.295-3.525-7.777-7.875-7.777Zm.503 2.53 1.532 3.026h3.028c.498 0 .736.581.422.924l-.07.066-2.521 1.99 1.022 3.534c.133.459-.337.843-.756.667l-.083-.043-3.077-1.901-3.076 1.9c-.41.254-.914-.086-.858-.532l.018-.09 1.022-3.535-2.52-1.99c-.389-.308-.207-.908.256-.982l.096-.008h3.026l1.533-3.026a.566.566 0 0 1 1.006 0Zm-.503 1.49-1.184 2.34a.563.563 0 0 1-.503.307h-1.77l1.559 1.233c.151.12.228.307.208.494l-.019.093L6.9 21.65l2.135-1.318a.569.569 0 0 1 .502-.047l.095.047 2.134 1.318-.724-2.498a.55.55 0 0 1 .12-.522l.07-.065 1.558-1.233h-1.77a.565.565 0 0 1-.447-.219l-.055-.088-1.185-2.34ZM16.67.667a.56.56 0 0 1 .554.456l.009.1V4l-.027.171-1.642 5a.564.564 0 0 1-1.093-.245l.023-.097 1.613-4.916V1.778h-2.274l.001 6.11a.56.56 0 0 1-.562.556.56.56 0 0 1-.554-.455l-.009-.1V1.778h-2.25v6.11a.56.56 0 0 1-.562.556.56.56 0 0 1-.554-.455l-.009-.1V1.778h-2.25v6.11a.56.56 0 0 1-.562.556.56.56 0 0 1-.554-.455l-.009-.1V1.778h-2.25v2.129L5.413 8.82a.553.553 0 0 1-.257.665l-.093.04a.566.566 0 0 1-.673-.253l-.042-.092-1.734-5-.03-.18V1.222c0-.273.198-.5.46-.546l.102-.01h13.523Z"
      />
    </g>
  </svg>
);

Reputation.defaultProps = {
  width: 19,
  height: 28,
};

Reputation.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(Reputation);
