import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const CopySquare = ({ color, ...props }) => (
  <svg viewBox="0 0 18 18" {...props} fill={color}>
    <path
      d="M16.177 0C17.184 0 18 .816 18 1.823V13.67a1.823 1.823 0 0 1-1.823 1.823l-1.14-.001.001.115c0 1.27-.99 2.31-2.241 2.387l-.151.005H2.392c-1.27 0-2.31-.99-2.387-2.241L0 15.608V5.354c0-1.27.99-2.31 2.241-2.387l.151-.005h.114v-1.14C2.506.817 3.322 0 4.33 0h11.848ZM2.506 4.329h-.114c-.528 0-.963.4-1.019.914l-.006.111v10.254c0 .528.4.963.914 1.019l.111.006h10.254c.528 0 .963-.4 1.019-.914l.006-.111-.001-.115H4.33a1.823 1.823 0 0 1-1.824-1.822V4.329Zm13.671-2.962H4.33a.456.456 0 0 0-.448.374l-.008.082V13.67c0 .224.162.41.374.448l.082.008h11.848c.224 0 .41-.162.449-.374l.007-.082V1.823a.456.456 0 0 0-.374-.449l-.082-.007Z"
      fillRule="evenodd"
    />
  </svg>
);

CopySquare.defaultProps = {
  width: 18,
  height: 18,
  color: colors.primary,
};

CopySquare.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(CopySquare);
