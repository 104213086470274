import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const PlayIcon = ({ color, bgColor, ...props }) => (
  <svg {...props}>
    <g>
      <path
        d="M0 36c0 19.854 16.15 36 36.002 36C55.85 72 72 55.854 72 36 72 16.152 55.85 0 36.002 0 16.15 0 0 16.152 0 36Z"
        fill={bgColor}
      />
      <path
        d="M29.56 49.86a1.02 1.02 0 0 1-1.041-.004 1.084 1.084 0 0 1-.519-.933V23.075c0-.38.196-.736.518-.93.321-.192.72-.194 1.041-.002l21.915 12.924c.326.191.526.549.526.932 0 .388-.2.745-.526.937L29.559 49.86Z"
        fill={color}
      />
    </g>
  </svg>
);

PlayIcon.defaultProps = {
  width: 72,
  height: 72,
  viewBox: '0 0 72 72',
  color: colors.white,
  bgColor: colors.primary,
};

PlayIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  bgColor: PropTypes.string,
};

export default memo(PlayIcon);
