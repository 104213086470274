import { CometChat } from '@cometchat-pro/chat';
import { line, text, textLight } from '../../../../../../constants/colors';

export const chatHeaderStyle = (context) => {
  return {
    padding: '12px 16px',
    width: '100%',
    backgroundColor: `${context.theme.backgroundColor.white}`,
    zIndex: '1',
    borderBottom: `1px solid ${line}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: '67px',
  };
};

export const chatDetailStyle = () => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'calc(100% - 116px)',
  };
};

export const chatSideBarBtnStyle = (img, props, context) => {
  const displayValue =
    props.hasOwnProperty('sidebar') && props.sidebar === 0
      ? { display: 'none!important' }
      : {};

  const mq = [...context.theme.breakPoints];

  return {
    cursor: 'pointer',
    display: 'none',
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${context.theme.primaryColor}`,
    width: '18px',
    height: '18px',
    marginRight: '12px',
    float: 'left',
    [`@media ${mq[1]}, ${mq[2]}`]: {
      display: 'block',
    },
    ...displayValue,
  };
};

export const chatThumbnailStyle = () => {
  return {
    display: 'inline-block',
    width: '40px',
    height: '40px',
    flexShrink: '0',
    margin: '0 8px 0 0',
  };
};

export const chatUserStyle = (context) => {
  const mq = [...context.theme.breakPoints];

  return {
    width: 'calc(100% - 50px)',
    padding: '0',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    [`@media ${mq[1]}, ${mq[2]}`]: {
      width: 'calc(100% - 80px)!important',
    },
  };
};

export const chatNameStyle = () => {
  return {
    margin: '0',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    letterSpacing: '0',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: text,
    fontFamily: 'Open Sans',
  };
};

export const chatStatusStyle = (state, context) => {
  let status = {};
  if (context.type === CometChat.ACTION_TYPE.TYPE_USER) {
    status = {
      color: `${context.theme.color.blue}`,
      textTransform: 'capitalize',
    };

    if (state.presence === 'offline') {
      status = {
        color: `${context.theme.color.helpText}`,
        textTransform: 'capitalize',
      };
    }

    if (state.typing) {
      status = {
        color: `${context.theme.color.helpText}`,
        textTransform: 'none',
        fontStyle: 'italic',
      };
    }
  } else if (context.type === CometChat.ACTION_TYPE.TYPE_GROUP) {
    status = {
      color: `${context.theme.color.helpText}`,
    };

    if (state.typing) {
      status = {
        color: `${context.theme.color.helpText}`,
        fontStyle: 'italic',
      };
    }
  }

  return {
    fontSize: '13px',
    width: '100%',
    ...status,
  };
};

export const chatOptionWrapStyle = () => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'auto',
  };
};

export const chatOptionIconStyle = () => {
  return {
    cursor: 'pointer',
  };
};

export const chatOptionStyle = (
  img,
  context,
  ongoingCall,
  hideOnDesktop = false,
) => {
  const bgColor = ongoingCall
    ? {
        backgroundColor: `${context.theme.secondaryTextColor}`,
      }
    : {
        backgroundColor: `${context.theme.primaryColor}`,
      };

  const hideForDesktop = hideOnDesktop
    ? {
        display: 'none',
        ['@media screen and (max-width: 768px)']: {
          display: 'block',
        },
      }
    : {};

  return {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 16px',
    i: {
      width: '24px',
      height: '24px',
      display: 'inline-block',
      mask: `url(${img}) center center no-repeat`,
      ...bgColor,
    },
    ...hideForDesktop,
  };
};

export const renterTypeLabelStyle = () => {
  return {
    color: textLight,
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '18px',
    textTransform: 'capitalize',
  };
};
