import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const CheckMarkFilled = (props) => (
  <svg {...props} viewBox="0 0 20 20" fill={props.color}>
    <path d="M10 0c5.522 0 10 4.477 10 10s-4.478 10-10 10C4.476 20 0 15.524 0 10S4.476 0 10 0zm5.207 6.293a1 1 0 00-1.414 0L8.5 11.585 6.207 9.293l-.094-.083a1 1 0 00-1.32 1.497l2.823 2.823.102.091a1.25 1.25 0 001.666-.09l5.823-5.824.083-.094a1 1 0 00-.083-1.32z" />
  </svg>
);

CheckMarkFilled.defaultProps = {
  width: 20,
  height: 20,
  color: colors.info,
};

CheckMarkFilled.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(CheckMarkFilled);
