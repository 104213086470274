import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HouseIcon from './HouseIcon';

import styles from './Footer.module.scss';

const Footer = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.top}>
      <a
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.turbotenant.com/scams"
      >
        Avoid Scams in Rental Housing
      </a>
      <span className={styles.icon}>
        <HouseIcon />
      </span>
      <a
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.turbotenant.com/fair-housing/"
      >
        Equal Housing Opportunity
      </a>
    </div>
    <div className={styles.bottom}>
      <span className={styles.copyright}>
        TurboTenant, Inc., © {new Date().getFullYear()}
        <span className={styles.separator}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
      </span>
      <span className={styles.termsAndPolicy}>
        <a
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.turbotenant.com/terms-of-use/"
        >
          Terms of Use
        </a>
        {' & '}
        <a
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.turbotenant.com/privacy-policy/"
        >
          Privacy Policy
        </a>
      </span>
    </div>
  </div>
);

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
