import {
  LateFeeLimitTypeEnum,
  LateFeeUnitEnum,
} from '../../../pages/payments/owner/new-setup-2021/components/ConfigureLateFeeModal/constants';

const getAmountForUnit = (amount, unit, fromBackend = false) => {
  if (unit === LateFeeUnitEnum.FLAT) {
    return fromBackend ? amount / 100 : Math.round(amount * 100);
  }
  return amount;
};

const prepareLateFeeDataForSubmission = (data) => {
  const {
    oneTimeFeeId,
    oneTimeFeeAmount,
    oneTimeFeeGracePeriod,
    oneTimeLateFeeUnit,
    dailyLateFeeAmount,
    dailyLateFeeGracePeriod,
    dailyLateFeeId,
    lateFeeLimitUnit,
    lateFeeLimitAmount,
    lateFeeLimitType,
    lateFeeLimitDays,
    oneTimeFeeApplied,
    dailyFeesApplied,
  } = data;

  const oneTimeFee = oneTimeFeeApplied && {
    id: oneTimeFeeId,
    amount: getAmountForUnit(Number(oneTimeFeeAmount), oneTimeLateFeeUnit),
    grace_period_days: Number(oneTimeFeeGracePeriod),
    late_fee_policy_type: 'ONE_TIME',
    fee_unit: oneTimeLateFeeUnit,
  };

  const dailyFee = dailyFeesApplied && {
    id: dailyLateFeeId,
    amount: getAmountForUnit(Number(dailyLateFeeAmount), LateFeeUnitEnum.FLAT),
    grace_period_days: Number(dailyLateFeeGracePeriod),
    late_fee_policy_type: 'DAILY',
    fee_unit: LateFeeUnitEnum.FLAT,
  };

  const lateFeeLimitValue =
    lateFeeLimitType === LateFeeLimitTypeEnum.MAX_DAYS
      ? lateFeeLimitDays
      : lateFeeLimitUnit === LateFeeUnitEnum.FLAT
        ? lateFeeLimitAmount * 100
        : lateFeeLimitAmount;

  const resetLimit = !dailyFeesApplied;
  let lateFeeLimit = {};
  if (resetLimit) {
    lateFeeLimit = {
      late_fee_limit_type: null,
      late_fee_limit_value: null,
      late_fee_limit_unit: null,
    };
  } else {
    const includeLimit = lateFeeLimitType !== LateFeeLimitTypeEnum.NO_LIMIT;
    lateFeeLimit = includeLimit
      ? {
          late_fee_limit_type: lateFeeLimitType,
          late_fee_limit_value: Number(lateFeeLimitValue),
          late_fee_limit_unit: lateFeeLimitUnit,
        }
      : {};
  }

  return {
    policies: [oneTimeFee, dailyFee].filter((fee) => fee?.amount),
    limit: lateFeeLimit,
  };
};

// inverse of the above
const parseLateFeeBackendData = (data) => {
  const { policies, limit } = data;
  const oneTimeFee = policies.find(
    (policy) => policy.late_fee_policy_type === 'ONE_TIME',
  );
  const dailyFee = policies.find(
    (policy) => policy.late_fee_policy_type === 'DAILY',
  );

  const oneTimeFeeAmount =
    oneTimeFee &&
    getAmountForUnit(oneTimeFee.amount, oneTimeFee.fee_unit, true);

  const dailyLateFeeAmount =
    dailyFee && getAmountForUnit(dailyFee.amount, LateFeeUnitEnum.FLAT, true);

  return {
    ...(oneTimeFee && {
      oneTimeFeeId: oneTimeFee.id,
      oneTimeFeeAmount,
      oneTimeFeeGracePeriod: oneTimeFee.grace_period_days,
      oneTimeLateFeeUnit: oneTimeFee.fee_unit,
    }),
    ...(dailyFee && {
      dailyLateFeeId: dailyFee.id,
      dailyLateFeeAmount,
      dailyLateFeeGracePeriod: dailyFee.grace_period_days,
    }),
    ...(limit.late_fee_limit_type === LateFeeLimitTypeEnum.MAX_DAYS
      ? {
          lateFeeLimitDays: limit.late_fee_limit_value,
        }
      : {
          lateFeeLimitDays: 1,
          lateFeeLimitAmount:
            limit.late_fee_limit_unit === LateFeeUnitEnum.FLAT
              ? limit.late_fee_limit_value / 100
              : limit.late_fee_limit_value,
        }),
    lateFeeLimitType:
      limit.late_fee_limit_type || LateFeeLimitTypeEnum.NO_LIMIT,
    lateFeeLimitUnit: limit.late_fee_limit_unit,
    oneTimeFeeApplied: !!oneTimeFee?.amount,
    dailyFeesApplied: !!dailyFee?.amount,
  };
};

export { parseLateFeeBackendData, prepareLateFeeDataForSubmission };
