import Decimal from 'jsdecimal';

const convertCentsToDollars = (val) => {
  if (!val) {
    return val;
  }
  if (Number.isNaN(parseInt(val, 10))) {
    return val;
  }
  return +Decimal(val).div(100).toFloat().toFixed(2);
};

export default convertCentsToDollars;
