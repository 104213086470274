import React from 'react';
import PropTypes from 'prop-types';

import styles from './ScoreInfo.module.scss';

const ScoreInfo = ({ score }) => {
  let backgroundColor = 'rgba(245,85,74,0.5)';
  let label = 'POOR';
  if (score > 520) {
    if (score < 640) {
      backgroundColor = 'rgba(245,245,73,0.5)';
      label = 'FAIR';
    } else if (score < 720) {
      backgroundColor = 'rgba(144,213,118,0.5)';
      label = 'GOOD';
    } else {
      backgroundColor = 'rgba(67,160,71,0.5)';
      label = 'EXCELLENT';
    }
  }

  return (
    <span className={styles.scoreTag} style={{ backgroundColor }}>
      {label} SCORE
    </span>
  );
};

ScoreInfo.propTypes = {
  score: PropTypes.number,
};

export default ScoreInfo;
