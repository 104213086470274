import { useMutation } from '@apollo/client';

import updateMaintenanceRequestMutation from '../../graphql/mutations/maintenance_requests/updateMaintenanceRequest.graphql';

const useOptimisticMaintenanceRequestUpdate = (mutationOptions = {}) => {
  const [updateMaintenanceRequest, rest] = useMutation(
    updateMaintenanceRequestMutation,
    mutationOptions,
  );

  const optimisticUpdate = (id, props) =>
    updateMaintenanceRequest({
      variables: {
        id,
        ...props,
      },
      optimisticResponse: {
        updateMaintenanceRequest: {
          __typename: 'updateMaintenanceRequestPayload',
          maintenance_request: {
            __typename: 'MaintenanceRequest',
            id,
            ...props,
          },
        },
      },
    });

  return [optimisticUpdate, rest];
};

export default useOptimisticMaintenanceRequestUpdate;
