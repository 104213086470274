const otherBuildingTypes = [
  { label: 'Commercial', value: 'COMMERCIAL' },
  { label: 'Garage', value: 'GARAGE' },
  { label: 'Manufactured', value: 'MANUFACTURED' },
  { label: 'Mixed-Use', value: 'MIXED_USE' },
  { label: 'Mobile Home / RV Park', value: 'MOBILE_HOME_RV_PARK' },
  { label: 'Office', value: 'OFFICE' },
  { label: 'Parking', value: 'PARKING' },
  { label: 'Storage', value: 'STORAGE' },
  { label: 'Other (specify)', value: 'OTHER' },
];

export default otherBuildingTypes;
