import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from '../../../BankInfo.module.scss';

const BankInfo = ({ className }) => (
  <div className={cx(styles.container, className)} style={{ marginBottom: 0 }}>
    <div className={styles.row}>
      <HelpfulInfoCard
        Icon={ResourcesIcon}
        className={styles.helpfulInfoCard}
        title="Security Deposit Return"
        color="iceCold"
      >
        <HelpfulInfoList className={styles.helpfulInfoList}>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            You must return the security deposit by the 30th day after obtaining
            possession of the property.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            If you retain any portion of the security deposit you must send the
            tenant written itemization of the charges by first-class mail to the
            last known address.
          </HelpfulInfoListItem>
          <div className={styles.learnMore}>
            <a
              href="http://support.turbotenant.com/en/articles/4394106-texas-lease-agreement#h_c4c363931e"
              target="_blank noopener noreferrer"
            >
              Learn other security deposit laws
            </a>
          </div>
        </HelpfulInfoList>
      </HelpfulInfoCard>
    </div>
  </div>
);

BankInfo.propTypes = {
  className: PropTypes.string,
};

export default BankInfo;
