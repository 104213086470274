import React, { memo } from 'react';

import colors from '../constants/colors';

const MoneyBagThick = (props) => (
  <svg viewBox="0 0 21 30" {...props} fill={props.color}>
    <path d="M14.009 12.24a.99.99 0 01.584.19c3.247 2.372 6.074 6.541 6.074 9.714 0 5.082-3.16 7.523-10 7.523s-10-2.441-10-7.523c0-3.172 2.826-7.341 6.072-9.714a.99.99 0 01.584-.19zm-.332 1.948H7.653l-.338.271c-2.445 2.016-4.516 5.16-4.672 7.418l-.01.267c0 3.885 2.188 5.574 8.034 5.574 5.845 0 8.032-1.689 8.032-5.574 0-2.271-2.139-5.589-4.681-7.685l-.341-.27zm-3.076 1.028a.98.98 0 01.974.842l.01.133v.393a2.763 2.763 0 011.803 2.583c0 .538-.44.974-.984.974a.98.98 0 01-.975-.842l-.009-.132a.816.816 0 00-.82-.812.816.816 0 00-.819.812c0 .449.367.812.82.812 1.538 0 2.787 1.236 2.787 2.76 0 1.182-.75 2.19-1.804 2.584v.393c0 .538-.44.974-.983.974a.98.98 0 01-.975-.842l-.009-.132v-.393a2.762 2.762 0 01-1.803-2.584c0-.538.44-.974.983-.974a.98.98 0 01.975.842l.01.132c0 .449.366.812.819.812.452 0 .82-.363.82-.812a.816.816 0 00-.82-.812c-1.54 0-2.787-1.235-2.787-2.76 0-1.18.75-2.19 1.803-2.583v-.393c0-.538.44-.975.984-.975zm4.273-5.357c.543 0 .984.436.984.974 0 .493-.37.9-.85.965l-.134.009H6.459a.979.979 0 01-.984-.974c0-.493.37-.901.85-.966l.134-.008h8.415zM9.754.946c.329-.817 1.496-.817 1.826 0l.826 2.048 3.283-1.391c.86-.365 1.674.544 1.269 1.33l-.063.106-.047.07-.304.486c-.27.445-.54.925-.79 1.422a13.963 13.963 0 00-.773 1.808c-.214.634-.326 1.187-.326 1.626 0 .538-.44.975-.983.975H7.66a.979.979 0 01-.984-.975c0-.439-.111-.992-.325-1.626a13.963 13.963 0 00-.773-1.808 21.24 21.24 0 00-.964-1.704l-.177-.274c-.543-.807.307-1.818 1.206-1.436l3.28 1.391zm.912 2.985l-.289.715a.987.987 0 01-1.3.534L7.52 4.52l.218.467c.124.278.236.551.338.82l.142.402c.118.35.213.687.283 1.012l.05.256h4.23l.05-.256c.047-.217.105-.439.173-.667l.11-.345c.134-.398.295-.806.48-1.222l.215-.468-1.553.66a.989.989 0 01-1.242-.412l-.059-.121-.29-.715z" />
  </svg>
);

MoneyBagThick.defaultProps = {
  color: colors.text,
  width: 21,
  height: 30,
};

export default memo(MoneyBagThick);
