import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Divider.module.scss';

const Divider = ({ className, text = 'OR' }) => {
  return (
    <div className={cx(styles.row, className)}>
      <hr className={styles.line} />
      {text && <div className={styles.orText}>{text}</div>}
      <hr className={styles.line} />
    </div>
  );
};

Divider.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default Divider;
