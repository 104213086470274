import React from 'react';
import PropTypes from 'prop-types';

import styles from './PixelTracking.module.scss';

const PixelTracking = () => {
  const { href } = window.location;
  const searchInBase64 = btoa(`href=${href}`);
  return (
    <div className={styles.pixelContainer}>
      <img
        className={styles.img}
        src={`https://api.turbotenant.com/public/login-stats?data=${searchInBase64}`}
      />
    </div>
  );
};

PixelTracking.propTypes = {
  url: PropTypes.string,
  routesToRun: PropTypes.array,
};

export default PixelTracking;
