import React, { useContext, useEffect, useState } from 'react';

import { fetchConfig } from '../services/configService';

const ConfigContext = React.createContext(null);

/**
 * Since ConfigProvider blocks the rendering of the app until the config is loaded,
 * we can assume that the config is always available for the children.
 */
export const useConfig = () => useContext(ConfigContext);

export const withConfig = (Component) => {
  const WithConfig = (props) => {
    const config = useConfig();

    return <Component {...props} config={config} />;
  };

  return WithConfig;
};

const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState();

  useEffect(() => {
    async function fetchAndSaveConfig() {
      const config = await fetchConfig();
      setConfig(config);
    }
    fetchAndSaveConfig();
  }, []);

  if (!config) {
    return null;
  }

  return (
    <ConfigContext.Provider value={config}>
      {children(config)}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
