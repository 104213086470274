import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Sparkles = ({ color, ...props }) => (
  <svg
    viewBox="0 0 34 26"
    fill={color}
    width={props.width}
    height={props.height}
    {...props}
  >
    <g>
      <path
        fill={color}
        className={props.sparkleBigClassName}
        d="M12.044 7.328C11.589 9.038 10.977 10.281 10.029 11.238C9.081 12.195 7.848 12.812 6.152 13.269C7.848 13.727 9.081 14.345 10.029 15.301C10.978 16.257 11.591 17.501 12.044 19.211C12.498 17.501 13.11 16.257 14.058 15.301C15.006 14.344 16.24 13.726 17.935 13.269C16.24 12.811 15.007 12.195 14.058 11.238C13.109 10.281 12.497 9.038 12.044 7.328ZM8.814 10.013C10.15 8.665 10.678 6.338 11.189 2.236C11.2085 2.04174 11.2933 1.85983 11.4295 1.71998C11.5657 1.58012 11.7453 1.49059 11.939 1.466C12.409 1.409 12.839 1.746 12.896 2.222C13.406 6.333 13.936 8.664 15.273 10.012C16.609 11.361 18.917 11.894 22.985 12.409C23.373 12.449 23.698 12.757 23.747 13.165C23.7756 13.3921 23.7129 13.6213 23.5726 13.8023C23.4324 13.9832 23.2261 14.1011 22.999 14.13C18.923 14.645 16.611 15.178 15.274 16.527C13.937 17.875 13.409 20.202 12.898 24.304C12.8785 24.4983 12.7937 24.6802 12.6575 24.82C12.5213 24.9599 12.3417 25.0494 12.148 25.074C12.0355 25.0875 11.9214 25.0787 11.8123 25.0479C11.7032 25.0172 11.6012 24.9652 11.5123 24.895C11.4234 24.8247 11.3492 24.7376 11.2941 24.6386C11.2389 24.5395 11.2039 24.4306 11.191 24.318C10.681 20.207 10.152 17.876 8.814 16.527C7.478 15.179 5.17 14.646 1.103 14.131C0.909101 14.1098 0.728115 14.0235 0.589552 13.8862C0.45099 13.7489 0.363025 13.5687 0.34 13.375C0.311403 13.1479 0.374112 12.9187 0.514357 12.7378C0.654601 12.5568 0.860917 12.4389 1.088 12.41C5.165 11.895 7.476 11.362 8.813 10.013H8.814Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1="37"
          x2="9.5"
          y1="28.5"
          y2="9.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00C8FF" />
          <stop offset="1" stopColor="#CFF5FF" />
        </linearGradient>
      </defs>
      <path
        d="M25.757 4.19302C26.707 3.23602 27.351 2.02402 27.834 0.40802C28.317 2.02402 28.96 3.23602 29.91 4.19302C30.86 5.15002 32.062 5.79802 33.667 6.28502C32.062 6.77002 30.86 7.41802 29.91 8.37502C28.96 9.33202 28.317 10.544 27.833 12.161C27.35 10.545 26.707 9.33202 25.757 8.37502C24.809 7.41902 23.605 6.77102 22 6.28502C23.605 5.79702 24.807 5.15002 25.757 4.19302Z"
        fill={color}
        className={props.sparkleTopClassName}
      />
      <path
        d="M20.334 20.556C21.709 20.138 22.74 19.583 23.554 18.763C24.368 17.943 24.92 16.904 25.333 15.519C25.747 16.903 26.299 17.943 27.113 18.763C27.927 19.584 28.958 20.139 30.333 20.556C28.958 20.972 27.927 21.528 27.113 22.348C26.299 23.168 25.747 24.207 25.334 25.593C24.92 24.208 24.368 23.168 23.554 22.348C22.741 21.529 21.709 20.973 20.334 20.556Z"
        fill={color}
        className={props.sparkleBottomClassName}
      />
    </g>
  </svg>
);

Sparkles.defaultProps = {
  width: 40,
  height: 40,
  color: colors.white,
  sparkleTopClassName: '',
  sparkleBottomClassName: '',
  sparkleBigClassName: '',
};

Sparkles.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  sparkleTopClassName: PropTypes.string,
  sparkleBottomClassName: PropTypes.string,
  sparkleBigClassName: PropTypes.string,
};

export default memo(Sparkles);
