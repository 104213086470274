import moment from 'moment';

const dateFormat = 'MM/DD/YYYY';

// it will use provided start date
// and if valid it will add 1 day

const getValidFromDate = (startDate) => {
  if (!startDate) {
    return undefined;
  }
  const date = moment(startDate, dateFormat);
  if (!date.isValid()) {
    return undefined;
  }

  return date.add(1, 'day').toDate();
};

export default getValidFromDate;
