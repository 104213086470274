import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import parseScreeningReportDate from '../../../../../helpers/parseScreeningReportDate';

import styles from './InquiriesSection.module.scss';

const InquiriesSection = ({ report }) => {
  return report.inquiries && report.inquiries.length > 0 ? (
    <div className={styles.flexColumn}>
      <span className={styles.iconNumber}>Inquiries</span>
      <div className={styles.flexColumn}>
        <div className={cx(styles.flexRow, styles.headerRow)}>
          <div className={styles.cell}>DATE</div>
          <div className={cx(styles.cell, styles.hideSmall)}>NAME</div>
          <div className={styles.cell}>KIND OF BUSINESS</div>
        </div>
        {report.inquiries.map((inquiry, idx) => {
          return (
            <div
              key={idx}
              className={cx(styles.flexRow, { [styles.oddRow]: idx % 2 === 0 })}
            >
              <div className={styles.cell}>
                {parseScreeningReportDate(inquiry.inquiryDate)}
              </div>
              <div className={cx(styles.cell, styles.hideSmall)}>
                {inquiry.subscriberName ? inquiry.subscriberName : 'N/A'}
              </div>
              <div className={styles.cell}>
                {inquiry.industryCode ? inquiry.industry : 'N/A'}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

InquiriesSection.propTypes = {
  report: PropTypes.object.isRequired,
};

export default InquiriesSection;
