import React from 'react';
import cx from 'classnames';
import get from 'lodash.get';
import moment from 'moment';
import PropTypes from 'prop-types';

import formatPhone from '../../../../helpers/format-phone';
import toCurrencyFromCents from '../../../../helpers/to-currency-from-cents';
import BusinessBank from '../../../../icons/BusinessBank';
import { useConfig } from '../../../../providers/ConfigProvider';
import { getCurrentTotalIncome } from '../../../../services/utilities';

import styles from './Employments.module.scss';

const Employments = ({ id, application, innerRef }) => {
  const { IS_RENTER } = useConfig();
  const { bank, income_sources = [] } = application;
  const employments = get(application, 'employments', []);
  const currentEmployments = employments.filter((e) => e.current);
  const pastEmployments = employments.filter((e) => !e.current);
  const totalIncome = getCurrentTotalIncome(application);

  return (
    <div className={styles.column} id={id} ref={innerRef}>
      <div className={styles.headerContainer}>
        {IS_RENTER ? (
          <span></span>
        ) : (
          <span className={styles.subTitle}>
            We asked for 5 years of employment history, if applicable.
          </span>
        )}
        <div className={styles.totalIncomeContainer}>
          <span className={styles.totalIncomeSpan}>TOTAL INCOME</span>
          <div className={styles.verticalEndRow}>
            <span className={styles.totalIncomeValue}>
              {toCurrencyFromCents(totalIncome)}
            </span>
            <span className={styles.totalIncomeMonth}>/month</span>
          </div>
        </div>
      </div>
      <div className={cx(styles.column, styles.contentContainer)}>
        {application?.unemployed ? (
          <div className={cx(styles.column, styles.itemContainer)}>
            <div
              className={cx(
                styles.verticalCenterColumn,
                styles.itemTitleContainer,
              )}
            >
              CURRENT EMPLOYER
            </div>
            <div
              className={cx(styles.jobContainer, styles.itemContentContainer)}
            >
              <i>
                {IS_RENTER ? 'You' : 'Applicant'} selected “I’m currently
                unemployed.”
              </i>
            </div>
          </div>
        ) : (
          <>
            {currentEmployments.map((employment, index) => (
              <div
                className={cx(styles.column, styles.itemContainer)}
                key={`employment-${index}`}
              >
                <div
                  className={cx(
                    styles.verticalCenterColumn,
                    styles.itemTitleContainer,
                  )}
                >
                  CURRENT EMPLOYER
                </div>
                <div
                  className={cx(
                    styles.jobContainer,
                    styles.itemContentContainer,
                  )}
                >
                  <>
                    <div className={styles.columnInfoContainer}>
                      <span className={styles.employerTitle}>
                        {employment.employer}
                      </span>
                      <span className={styles.addressStyle}>
                        {employment.occupation}
                        {employment.location && ` in ${employment.location}`}
                      </span>
                      <span className={styles.dateStyle}>
                        {moment(employment.start_date).format('MMM YYYY')} -
                        Present
                      </span>
                    </div>
                    <div className={styles.columnInfoContainer}>
                      <span className={styles.referenceStyle}>REFERENCE</span>
                      <span className={styles.employerName}>
                        {employment.contact_name}
                      </span>
                      <span className={styles.employerPhone}>
                        {formatPhone(employment.contact_telephone)}
                      </span>
                    </div>
                    <div className={styles.horizontalEndColumn}>
                      <div className={styles.verticalEndRow}>
                        <span className={styles.incomeValueStyle}>
                          {toCurrencyFromCents(employment.monthly_income)}
                        </span>
                        <span className={styles.totalIncomeMonth}>/month</span>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            ))}
          </>
        )}

        {income_sources.map((income_source, index) => (
          <div
            className={cx(styles.column, styles.itemContainer)}
            key={`income-${index}`}
          >
            <div
              className={cx(
                styles.verticalCenterColumn,
                styles.itemTitleContainer,
              )}
            >
              OTHER INCOME SOURCES
            </div>
            <div className={styles.itemIncomeContainer}>
              <span className={styles.employerTitle}>
                {income_source.source}
              </span>
              <div className={styles.verticalEndRow}>
                <span className={styles.incomeValueStyle}>
                  {toCurrencyFromCents(income_source.monthly_income)}
                </span>
                <span className={styles.totalIncomeMonth}>/month</span>
              </div>
            </div>
          </div>
        ))}
        {application?.not_past_employers ? (
          <div className={cx(styles.column, styles.itemContainer)}>
            <div
              className={cx(
                styles.verticalCenterColumn,
                styles.itemTitleContainer,
              )}
            >
              PAST EMPLOYER
            </div>
            <div
              className={cx(styles.jobContainer, styles.itemContentContainer)}
            >
              <i>
                {IS_RENTER ? 'You' : 'Applicant'} selected “Not applicable.”
              </i>
            </div>
          </div>
        ) : (
          pastEmployments.map((employment, index) => (
            <div
              className={cx(styles.column, styles.itemContainer)}
              key={`past-employment-${index}`}
            >
              <div
                className={cx(
                  styles.verticalCenterColumn,
                  styles.itemTitleContainer,
                )}
              >
                PAST EMPLOYER
              </div>
              <div
                className={cx(styles.jobContainer, styles.itemContentContainer)}
              >
                <div className={styles.columnInfoContainer}>
                  <span className={styles.employerTitle}>
                    {employment.employer}
                  </span>
                  <span className={styles.addressStyle}>
                    {employment.occupation}
                    {employment.location && ` in ${employment.location}`}
                  </span>
                  <span className={styles.dateStyle}>
                    {moment(employment.start_date).format('MMM YYYY')} -{' '}
                    {moment(employment.end_date).format('MMM YYYY')}
                  </span>
                </div>
                <div className={styles.columnInfoContainer}>
                  <span className={styles.referenceStyle}>REFERENCE</span>
                  <span className={styles.employerName}>
                    {employment.contact_name}
                  </span>
                  <span className={styles.employerPhone}>
                    {employment.contact_telephone}
                  </span>
                </div>
                <div className={styles.horizontalEndColumn}>
                  <div className={styles.verticalEndRow}>
                    <span className={styles.incomeValueStyle}>
                      {toCurrencyFromCents(employment.monthly_income)}
                    </span>
                    <span className={styles.totalIncomeMonth}>/month</span>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
        <div
          className={cx(
            styles.verticalCenterRow,
            styles.itemContainer,
            styles.itemContainerPadding,
          )}
        >
          <BusinessBank
            color="black"
            style={{ height: 42, marginRight: 12, width: 42 }}
          />
          <div className={styles.column}>
            <span className={styles.financialTitle}>FINANCIAL INSTITUTION</span>
            <span className={styles.financialValue}>{bank || 'N/A'}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

Employments.propTypes = {
  id: PropTypes.string,
  application: PropTypes.object,
};

export default Employments;
