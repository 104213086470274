import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RadioGroup from '../../../../../../../components/RadioGroup';
import WaterThickIcon from '../../../../../../../icons/WaterThick';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './FloodDisclosureMD.module.scss';

const FloodDisclosureMD = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Flood Disclosure" icon={WaterThickIcon} />
      <label className={styles.label}>
        Do you know, or has the city or some other governmental agency notified
        you of the fact, that the premises, storage or parking area is within a
        floodplain?
      </label>
      <p className={styles.description}>
        City of Baltimore law requires that you disclose this. Floodplain is
        defined as a flat or low land area adjacent to a river or stream that is
        subject to partial or complete inundation, an area subject to unusual
        and rapid accumulation of runoff of surface waters from any source, or
        an area subject to tidal surge or extreme tides. If yes, we will add the
        required disclosure text to your lease agreement.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.has_property_flooded"
        name="custom_state_data.has_property_flooded"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'has_property_flooded_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'has_property_flooded_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
    </div>
  );
};

FloodDisclosureMD.propTypes = {
  className: PropTypes.string,
};

export default FloodDisclosureMD;
