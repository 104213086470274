import React from 'react';
import PropTypes from 'prop-types';

import { SUB_DIVISION_NAMES_KEYS } from '../../../../../../../constants/lease_agreement/sub-division-names';
import SectionInfoBlock from '../../../../../common/SectionInfoBlock';

const lateFeeInfoDivisionTexts = {
  [SUB_DIVISION_NAMES_KEYS.tacoma]: (
    <>
      Rent is due in full on the first day of each and every month. If rent is
      not received on or before the 5th day of the month, you may charge your
      tenant a late fee of $10. Per Tacoma, WA ordinance, Tenant can request the
      rent due date be moved if they receive governmental rental assistance to
      align when the assistance comes in.
    </>
  ),
  [SUB_DIVISION_NAMES_KEYS.seattle]: (
    <>
      Rent is due in full on the first day of each and every month. If rent is
      not received on or before the 5th day of the month, you may charge your
      tenant a late fee of $10.
    </>
  ),
};

const lateFeeInfoStateText = (
  <>
    Rent is due in full on the first day of each and every month. If rent is not
    received on or before the 5th day of the month, you may charge your tenant a
    late fee of $20 or 20% of the unpaid rent amount, whichever is greater.
  </>
);

const LateFeeInfo = ({ className, leaseAgreementSelection }) => {
  const lateFeeInfoText =
    lateFeeInfoDivisionTexts[leaseAgreementSelection] || lateFeeInfoStateText;

  return (
    <SectionInfoBlock className={className}>
      <strong>Late Fees:</strong> {lateFeeInfoText}
    </SectionInfoBlock>
  );
};

LateFeeInfo.propTypes = {
  className: PropTypes.string,
  leaseAgreementSelection: PropTypes.string,
};

export default LateFeeInfo;
