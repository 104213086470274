import moment from 'moment';

const dateFormats = [
  'MM/DD/YYYY',
  'YYYY-MM-DD',
  'YYYY-MM-DDTHH:mm:ss.SSSZ',
  'MM/DD/YYYY HH:mm:ss',
  'MM/YY',
  'YYYYMMDD',
];

const getFormattedDate = (date, format = 'MM/DD/YYYY') => {
  return (
    (date && moment.utc(date, dateFormats, true).format(format)) || undefined
  );
};

export default getFormattedDate;
