import React, { useCallback, useRef } from 'react';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Document, Page } from 'react-pdf';
import PropTypes from 'prop-types';

import PlusIcon from '../../icons/PlusCircle';
import Remove from '../../icons/Remove';
import { useErrorToast } from '../Toast';

import CoreField from './CoreField';

import styles from './FileField.module.scss';

const FileField = ({
  name,
  label,
  onChange: onFilesChanged = () => {},
  button: Button,
  maxFileSize,
  ...props
}) => {
  const form = useForm();
  const inputRef = useRef(null);

  const showErrorMessage = useErrorToast();

  return (
    <>
      <FieldArray name={name}>
        {({ fields }) => {
          const files = fields?.value;

          const onDelete = useCallback(
            (idx) => {
              form.mutators.remove(name, idx);
              onFilesChanged(form?.getFieldState(name)?.value || []);
            },
            [inputRef, form],
          );

          return (
            <>
              {files?.length > 0 && (
                <div className={styles.imagePreviewerContainer}>
                  {files.map((file, idx) => (
                    <div key={idx} className={styles.imagePreviewer}>
                      <div className={styles.imagePreview}>
                        {file.type === 'application/pdf' ? (
                          <Document file={file.url}>
                            <Page
                              pageNumber={1}
                              renderAnnotationLayer={false}
                              renderTextLayer={false}
                              width={100}
                            />
                          </Document>
                        ) : (
                          <img src={file.url} />
                        )}
                      </div>
                      <p className={styles.filename}>{file.name}</p>
                      <div
                        className={styles.deleteButton}
                        onClick={() => onDelete(idx)}
                      >
                        <Remove />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          );
        }}
      </FieldArray>
      <CoreField name={name} {...props}>
        {({ input: { value, onChange, ...input }, ...fieldProps }) => {
          const handleChange = useCallback(
            ({ target }) => {
              const filesToAdd = Array.from(target.files)
                .filter((file) => {
                  if (maxFileSize) {
                    if (file.size <= maxFileSize) return true;

                    showErrorMessage(
                      `File ${file.name} exceeds the size limit of 10MB and cannot be uploaded. Please reduce the file size and try again.`,
                    );

                    return false;
                  }

                  return true;
                })
                .map((file) => ({
                  name: file.name,
                  url: file.url || URL.createObjectURL(file),
                  type: file.type,
                  file,
                }));

              if (filesToAdd.length) {
                form.mutators.concat(name, filesToAdd);

                onFilesChanged(form?.getFieldState(name)?.value || []);
              }

              // if user selects the same file multiple times in a row input element won't trigger onChange event at all
              // we need to clear target.value
              // more context in this article https://bobbyhadz.com/blog/file-input-onchange-event-not-working
              target.value = null;
            },
            [form],
          );

          return (
            <>
              <input
                ref={inputRef}
                style={{ display: 'none' }}
                type="file"
                onChange={handleChange}
                {...input}
                {...fieldProps}
              />
              <Button
                label={label}
                icon={PlusIcon}
                size="small"
                onClick={() => inputRef?.current?.click()}
              />
            </>
          );
        }}
      </CoreField>
    </>
  );
};

FileField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  button: PropTypes.elementType,
  maxFileSize: PropTypes.number,
};

export default FileField;
