import React from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import FinalFormError from '../../../components/FinalFormError';
import PremiumRadioButtonOffer, {
  ShowPremiumPriceForOffer,
} from '../../../components/PremiumRadioButtonOffer';
import { MODAL_LOCATIONS } from '../../../components/PremiumSubscription/constants';
import SidebarMarketing from '../../../components/SidebarMarketing';
import { H1, H2, Link } from '../../../components/Typography';
import ApplicantColor from '../../../icons/ApplicantColor';
import CheckMarkFilled from '../../../icons/CheckMarkFilled';
import HelpFill from '../../../icons/HelpFill';
import PiggyBankColor from '../../../icons/PiggyBankColor';
import MarketingLayout from '../../../layout/MarketingLayout';
import { segmentTracking } from '../../../services/utilities';
import fieldRequired from '../../../validators/fieldRequired';

import forbes_logo from './assets/forbes_logo.png';
import fortune_logo from './assets/fortune_logo.png';
import inc5000_logo from './assets/inc_logo.png';

import styles from './SuccessPage.module.scss';

const SuccessPage = ({ onSubmit, segmentData = {} }) => {
  const marketingListItems = [
    {
      text: (
        <>
          Over<span> 805,000</span> rental applications and screenings collected
        </>
      ),
    },
    {
      text: (
        <>
          Trusted by <span>650,000+</span> landlords to streamline their rental
          process
        </>
      ),
    },
    {
      text: <>Featured on CNBC, Fortune, Inc. 5000, and Forbes</>,
    },
  ];

  const SidebarCard = () => (
    <div className={styles.sidebarCard}>
      <div className={styles.sidebarCardHeader}>
        <H1 className={styles.title}>Applications made easy</H1>
      </div>
      <div className={styles.sidebarCardList}>
        {marketingListItems.map((item, index) => (
          <div key={index} className={styles.sidebarCardListItem}>
            <CheckMarkFilled
              width={28}
              height={28}
              color={'#033a6d'}
              className={styles.sidebarCardListItemIcon}
            />
            <span className={styles.sidebarCardListItemText}>
              <span>{item.text}</span>
            </span>
          </div>
        ))}
      </div>
      <div className={styles.imagesHolder}>
        <img src={fortune_logo} alt="fortune" />
        <img src={inc5000_logo} alt="inc5000" />
        <img src={forbes_logo} alt="forbes" />
      </div>
    </div>
  );

  const PremiumDescription = () => (
    <div className={styles.premiumDescription}>
      <div className={styles.item}>
        <PiggyBankColor
          width={44}
          height={44}
          className={styles.itemIcon}
          color={'#FF9F2A'}
        />
        <div className={styles.promoTextWrapper}>
          <h3>Income Insights</h3>
          <p>
            Instantly verify the accuracy of an applicant’s self-reported income
            with TransUnion.{' '}
            <Link
              href={
                'http://support.turbotenant.com/en/articles/6539674-what-is-income-insights'
              }
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </Link>
          </p>
        </div>
      </div>

      <div className={styles.item}>
        <ApplicantColor
          width={44}
          height={44}
          className={styles.itemIcon}
          secondaryColor={'#FF9F2A'}
        />
        <div className={styles.promoTextWrapper}>
          <h3>Lower Fees</h3>
          <p>
            Get 31% more applicants with a<b>$45</b> fee instead of $55
          </p>
        </div>
      </div>

      <div className={styles.item}>
        <HelpFill
          width={44}
          height={44}
          className={styles.itemIcon}
          secondaryColor={'#FF9F2A'}
        />
        <div className={styles.promoTextWrapper}>
          <h3>Custom Questions</h3>
          <p>
            Add up to 4 custom questions to your pre-screener and application
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <MarketingLayout
      side={
        <SidebarMarketing
          className={styles.sidebarMarketing}
          description={<SidebarCard />}
        />
      }
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <H2 className={styles.title}>
            Which application type do you prefer?
          </H2>
          <Form
            onSubmit={onSubmit}
            subscription={{
              submitting: true,
              values: true,
            }}
            initialValues={{
              premium_offer: null,
            }}
          >
            {({ handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <FinalFormError />

                <Field
                  component={PremiumRadioButtonOffer}
                  validate={fieldRequired}
                  name="premium_offer"
                  purchase_location="premium_invite_to_apply_onboarding"
                  copy="inviteToApply"
                  openLocation={MODAL_LOCATIONS.INVITE_TO_APPLY_ONBOARDING}
                  reverseButtons
                  hideLabel
                  {...{
                    free: {
                      title: 'FREE FOR LANDLORD',
                      desc: 'Background, Eviction, Credit Only',
                      desc2: (
                        <>
                          Renter pays <ShowPremiumPriceForOffer isPublicFlow />{' '}
                          fee
                        </>
                      ),
                      tracking: {
                        event: 'normal_app clicked',
                        location: 'Application Type - Public Application',
                        ...segmentData,
                      },
                    },
                    premium: {
                      title: 'PREMIUM',
                      desc: (
                        <>
                          <span> Background, Eviction, Credit </span>
                          <b>
                            {' '}
                            + <i> More</i>
                          </b>
                        </>
                      ),
                      desc2: <PremiumDescription />,
                      tracking: {
                        event: 'premium_app_type clicked',
                        location: 'Application Type - Public Application',
                        ...segmentData,
                      },
                    },
                  }}
                />

                <Button
                  className={styles.btn}
                  type="submit"
                  loading={submitting}
                  id="invite_submit"
                  disabled={values.premium_offer === null}
                  data-qa="onboarding-renter-info-button-next"
                  onClick={() => {
                    segmentTracking('next clicked', {
                      location: 'Application Type - Public Application',
                      ...segmentData,
                    });
                  }}
                >
                  NEXT
                </Button>
              </form>
            )}
          </Form>
        </div>
      </div>
    </MarketingLayout>
  );
};

SuccessPage.propTypes = {
  onSubmit: PropTypes.func,
};

export default SuccessPage;
