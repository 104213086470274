import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Warning = (props) => (
  <svg {...props} viewBox="0 0 80 80" fill={props.color}>
    <path
      d="M40.336.056c.496.132.798.21 1.219.928l38.288 76.5c.492 1.16-.214 2.43-1.556 2.516H1.709C.406 79.917-.339 78.646.152 77.484l38.29-76.5c.566-.968.724-1.08 1.894-.928zm-.338 5.593L4.525 76.523h70.946L39.998 5.649zm.178 56.969c1.737.132 3.212 1.584 3.299 3.296.12 2.352-2.58 4.332-4.833 3.38-2.86-1.209-2.907-6.789 1.534-6.676zm.022-35.971c.822.127 1.44.767 1.535 1.594.342 8.907.005 17.827.005 26.74-.07 1.835-3.247 2.108-3.475.134-.342-8.907-.005-17.827-.005-26.741.039-1.02.597-1.83 1.94-1.727z"
      fillRule="evenodd"
    />
  </svg>
);

Warning.defaultProps = {
  width: 80,
  height: 80,
  color: colors.primary,
};

Warning.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Warning);
