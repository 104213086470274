import React from 'react';
import { Field } from 'react-final-form';

import Condition from '../../../../../../../components/Condition';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import HabitabilityIcon from '../../../../../../../icons/Habitability';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './HabitabilityMD.module.scss';

const HabitabilityMD = () => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={styles.container}>
      <SectionTitle title="Habitability Disclosure" icon={HabitabilityIcon} />
      <label className={styles.label}>
        Are the premises delivered in habitable condition?
      </label>
      <p className={styles.description}>
        Maryland law requires that you disclose this.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.habitability"
        name="custom_state_data.habitability"
        className={styles.radioWrapper}
        radiosContainerClassName={styles.radioButtonsContainer}
        labelProps={{
          className: styles.headerLabel,
        }}
        options={[
          {
            'id': 'custom_state_data.habitability_choice_yes',
            'value': true,
            'label': 'Yes',
            'data-qa':
              'lease-agreements-lead-paint-disclosure-required-radio-yes',
          },
          {
            id: 'custom_state_data.habitability_choice_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition when="custom_state_data.habitability" is={false}>
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.habitability_explanation"
          name="custom_state_data.habitability_explanation"
          label="The following defects are known and agreed upon by both Landlord and Tenant.  Please indicate if Landlord or Tenant will be responsible fixing the defect. Landlord is not responsible for damages resulting from unremedied defects designated to Tenant(s)."
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      </Condition>
    </div>
  );
};

export default HabitabilityMD;
