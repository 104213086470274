import React, { useEffect, useState } from 'react';
import { Field, Form, useForm } from 'react-final-form';
import cx from 'classnames';

import checkEmailForPaidRR from '../../../core/services/checkEmail';
import truncate from '../../../helpers/truncate';
import CheckMarkCircle from '../../../icons/CheckMarkCircle';
import CloseCircle from '../../../icons/CloseCircle';
import Delete from '../../../icons/Delete';
import Edit from '../../../icons/Edit';
import PlusCircleIcon from '../../../icons/PlusCircle';
import composeValidators from '../../../validators/composeValidators';
import fieldRequired from '../../../validators/fieldRequired';
import validEmail from '../../../validators/validEmail';
import Button from '../../Button';
import GrayFlatButton from '../../GrayFlatButton';
import IconButton from '../../IconButton';
import Input from '../../Input';
import SegmentedController from '../../SegmentedController';

import styles from './OtherPeople.module.scss';

export const useCheckForPaidApplication = ({
  application,
  listingId,
  email,
  isDemo,
}) => {
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);

  /**
   * @NOTICE
   *
   * Sometimes applications are readily available, but sometimes
   * we need to call the back-end to do the check for us. This hook handles
   * both scenarios.
   */
  useEffect(() => {
    if (isDemo) return;
    if (application != null) {
      setCheck(application?.has_payment);
      return;
    }

    setLoading(true);
    checkEmailForPaidRR(email, listingId)
      .then(({ exists }) => {
        setCheck(exists);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setCheck(false);
      });
  }, [listingId, email, isDemo]);

  return [check, loading];
};

export const ApplicationStatusCheck = ({
  className,
  application,
  email,
  public_listing_id,
  isDemo,
}) => {
  const [isSubmitted, loading] = useCheckForPaidApplication({
    application,
    listingId: public_listing_id,
    email,
    isDemo,
  });

  return (
    <div className={cx(styles.applicationSubmittedCheck, className)}>
      {loading ? (
        <div className={styles.loading}>Loading...</div>
      ) : (
        <div className={cx(styles.applicationSubmittedCheck, className)}>
          <div className={styles.check}>
            {isSubmitted ? (
              <>
                <CheckMarkCircle /> <div>Application Submitted!</div>
              </>
            ) : (
              <>
                <CloseCircle /> <div>Application Not Submitted Yet</div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const PeopleForm = ({
  saving,
  initialValues,
  onSubmit,
  onDelete,
  deleteIcon,
  classNameAddAnother,
  emailInUseValidator,
  index,
}) => {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      subscription={{
        submitting: true,
      }}
    >
      {({ handleSubmit }) => (
        <div className={cx(styles.occupant, classNameAddAnother)}>
          {deleteIcon && (
            <IconButton
              icon={Delete}
              className={styles.deletePerson}
              onClick={onDelete}
            />
          )}
          <Field
            className={styles.segmentedController}
            component={SegmentedController}
            containerClassName={styles.segmentedControllerContainer}
            name={`type`}
            label="They are a:"
            options={[
              { label: 'Tenant', value: 'TENANT' },
              { label: 'Co-signer', value: 'CO_SIGNER' },
            ]}
            validate={fieldRequired}
          />
          <Field
            name={`name`}
            id={`name`}
            component={Input}
            label={`Full Name`}
            type="text"
            validate={fieldRequired}
          />
          <Field
            className={styles.email}
            name={`email`}
            label={`Email Address`}
            hint={'(Optional)'}
            id={`email`}
            component={Input}
            type="text"
            validate={composeValidators(
              validEmail,
              emailInUseValidator(index, initialValues.email),
            )}
          />
          <Button
            onClick={handleSubmit}
            loading={saving}
            secondary
            className={styles.save}
          >
            save
          </Button>
        </div>
      )}
    </Form>
  );
};

const RenderPerson = ({
  saving,
  person: { name, email, type },
  onDelete,
  onEdit,
  public_listing_id,
  isDemo,
}) => {
  const [deleteClicked, setDeleteClicked] = useState(false);

  return (
    <div className={styles.person}>
      <div className={styles.personHeader}>
        <div className={styles.informations}>
          <h4 className={styles.personName}>
            <div>{name}</div>
            <div className={styles.hideOnMobile}>
              <ApplicationStatusCheck
                email={email}
                public_listing_id={public_listing_id}
                isDemo={isDemo}
              />
            </div>
          </h4>
          <p className={styles.personEmail}>{truncate(email, 35)} </p>
          <p className={styles.personType}>
            {type === 'TENANT' ? 'Tenant' : 'Co-signer'}{' '}
          </p>
        </div>
        <div className={styles.hideOnDesktop}>
          <ApplicationStatusCheck
            email={email}
            public_listing_id={public_listing_id}
            isDemo={isDemo}
          />
        </div>
      </div>
      <div className={styles.personActions}>
        <GrayFlatButton icon={Edit} onClick={onEdit} className={styles.action}>
          edit
        </GrayFlatButton>
        <GrayFlatButton
          icon={Delete}
          onClick={(...args) => {
            setDeleteClicked(true);
            onDelete(...args);
          }}
          className={styles.action}
          loading={saving && deleteClicked}
        >
          delete
        </GrayFlatButton>
      </div>
    </div>
  );
};

const OtherPeople = ({
  saving,
  coApplicants = [],
  public_listing_id,
  isDemo,
  emailInUseValidator,
}) => {
  const form = useForm();
  const [edittingIndex, setEdditingIndex] = useState(null);
  const [showAddFrom, setShowAddFrom] = useState(coApplicants.length === 0);
  const [saveButtonClicked, setSaveButtonCliked] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  useEffect(() => {
    setShowAddFrom(coApplicants.length === 0);
  }, [coApplicants]);

  useEffect(() => {
    if ((deleteClicked || saveButtonClicked) && !saving) {
      setEdditingIndex(null);
    }
  }, [saving]);

  return (
    <div className={styles.container}>
      {coApplicants.map(({ edditing, ...person }, index) => (
        <div key={index}>
          {edittingIndex === index ? (
            <PeopleForm
              saving={saving}
              deleteIcon={coApplicants.length > 0}
              initialValues={person}
              index={index}
              onSubmit={(data) => {
                setSaveButtonCliked(true);
                form.mutators.update('co_applicants', index, {
                  ...data,
                });
              }}
              onDelete={() => {
                setDeleteClicked(true);
                form.mutators.remove('co_applicants', index);
              }}
              emailInUseValidator={emailInUseValidator}
            />
          ) : (
            <RenderPerson
              saving={saving}
              person={person}
              public_listing_id={public_listing_id}
              isDemo={isDemo}
              onDelete={() => form.mutators.remove('co_applicants', index)}
              onEdit={() => setEdditingIndex(index)}
            />
          )}
        </div>
      ))}

      {!showAddFrom && (
        <GrayFlatButton
          onClick={() => {
            setShowAddFrom(true);
          }}
          className={styles.addAnother}
          icon={PlusCircleIcon}
        >
          Add Another{' '}
        </GrayFlatButton>
      )}
      {showAddFrom && (
        <PeopleForm
          saving={saving}
          classNameAddAnother={styles.addAnotherForm}
          deleteIcon={coApplicants.length > 0}
          onDelete={() => setShowAddFrom(false)}
          initialValues={{
            type: 'TENANT',
          }}
          emailInUseValidator={emailInUseValidator}
          onSubmit={(data) => {
            setSaveButtonCliked(true);
            form.mutators.push('co_applicants', {
              ...data,
            });
          }}
        />
      )}
    </div>
  );
};

export default OtherPeople;
