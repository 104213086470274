const formatAddress = (input = {}) => {
  const {
    short = false,
    address,
    unit,
    city,
    state,
    zip,
    hide_street_number = false,
  } = input;

  if (!address) return '';

  const unitString = unit && !hide_street_number ? `, ${unit}` : '';

  const addressString = hide_street_number
    ? address.replace(/^[0-9]+[ ]/, '')
    : address;

  if (short) {
    return `${addressString}${unitString}`;
  }

  return `${addressString}${unitString}, ${city}, ${state} ${zip}`;
};

export default formatAddress;
