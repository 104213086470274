import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Payments = (props) => (
  <svg {...props} viewBox="0 0 54 54">
    <g fill="none" fillRule="evenodd">
      <path
        d="M35.667 48.333c9.94 0 18-10.725 18-20.666S45.607 7 35.667 7c-9.941 0-18 10.726-18 20.667 0 9.94 8.059 20.666 18 20.666Z"
        fill={props.color}
      />
      <path
        d="M27 .333C41.728.333 53.667 12.273 53.667 27c0 14.73-11.937 26.667-26.667 26.667C12.273 53.667.333 41.728.333 27 .333 12.275 12.275.333 27 .333Zm0 2.32C13.556 2.652 2.652 13.555 2.652 27c0 13.447 10.902 24.348 24.348 24.348 13.449 0 24.348-10.9 24.348-24.348 0-13.446-10.9-24.348-24.348-24.348Zm0 8.115c.64 0 1.16.52 1.16 1.16v2.415a6.96 6.96 0 0 1 5.797 6.86 1.16 1.16 0 1 1-2.32 0 4.64 4.64 0 0 0-3.477-4.491v9.225a6.96 6.96 0 0 1 0 13.72v2.415a1.16 1.16 0 0 1-2.32 0v-2.415a6.957 6.957 0 0 1-5.797-6.86 1.16 1.16 0 0 1 2.32 0 4.639 4.639 0 0 0 3.477 4.492v-9.226a6.957 6.957 0 0 1 0-13.72v-2.415c0-.64.52-1.16 1.16-1.16Zm1.16 17.538v8.982a4.639 4.639 0 0 0 0-8.982ZM25.84 16.71a4.64 4.64 0 0 0 0 8.984Z"
        fill="#042238"
      />
    </g>
  </svg>
);

Payments.defaultProps = {
  width: 54,
  height: 54,
  color: '#7FE3FF',
};

Payments.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(Payments);
