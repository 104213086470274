import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Callout from '../../../../components/Callout';

import styles from './SectionInfoBlock.module.scss';

const SectionInfoBlock = ({ children, className }) => (
  <Callout type="info" className={cx(styles.container, className)}>
    {children}
  </Callout>
);

SectionInfoBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default SectionInfoBlock;
