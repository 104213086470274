import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import DateField from '../../../../../../components/DateField';
import Input from '../../../../../../components/Input';
import InputWithIcon from '../../../../../../components/InputWithIcon';
import SelectField from '../../../../../../components/SelectField';
import { DATE_FORMATS_ARRAY } from '../../../../../../constants/charges_date_formats';
// TODO: Rename the styles file after we delete the old component. It imports
// both files since they have the same name.
import DollarIcon from '../../../../../../icons/Dollar';
import composeValidators from '../../../../../../validators/composeValidators';
import fieldRequired from '../../../../../../validators/fieldRequired';
import isDate from '../../../../../../validators/isDate';
import isGreaterThan from '../../../../../../validators/isGreaterThan';
import isNumber from '../../../../../../validators/isNumber';

import { oneTimeChargesCategoriesOptions } from './oneTimeChargeCategoryOptions';

import styles from './OneTimeChargeFormNew.module.scss';

const OneTimeChargeForm = ({
  onSubmit,
  onCancel,
  chargeInfo,
  isPremiumUser,
  bankAccountOptions,
}) => {
  const isEdit = !!chargeInfo?.id;

  const [endDate, setEndDate] = useState(
    chargeInfo?.end_date || chargeInfo?.due_date || null,
  );

  const userBankOptions = bankAccountOptions.length
    ? [{ value: null, label: '' }, ...bankAccountOptions]
    : [];

  return (
    <Card className={styles.formCard}>
      <Form
        onSubmit={onSubmit}
        subscription={{
          submitting: true,
        }}
        initialValues={{
          ...chargeInfo,
          end_date:
            (endDate &&
              moment(endDate, DATE_FORMATS_ARRAY).format('MM/DD/YYYY')) ||
            null,
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                label="Category"
                component={SelectField}
                id="category"
                name="category"
                options={oneTimeChargesCategoriesOptions}
                className={styles.categorySelect}
                validate={fieldRequired}
                dataQa="one-time-charge-category-input"
              />
              <div className={styles.row}>
                <Field
                  component={InputWithIcon}
                  id="amount"
                  name="amount"
                  className={styles.amountField}
                  label="Amount"
                  type="number"
                  step=".01"
                  pattern="[0-9]*"
                  inputMode="decimal"
                  validate={composeValidators(
                    isNumber,
                    fieldRequired,
                    isGreaterThan(0),
                  )}
                  icon={DollarIcon}
                  inputProps={{
                    'data-qa': 'one-time-charge-amount-input',
                  }}
                />
                <OnChange name="end_date">
                  {(value) => {
                    setEndDate(value);
                  }}
                </OnChange>
                <Field
                  component={DateField}
                  className={styles.dateField}
                  label="Due Date"
                  name="end_date"
                  id="end_date"
                  validate={composeValidators(fieldRequired, isDate)}
                  data-qa="one-time-charge-due-date-input"
                />
              </div>
              <Field
                label="Description"
                component={Input}
                hint="(Optional)"
                name="description"
                id="description"
                className={styles.descriptionTextField}
                maxLength={50}
                help={(val = '') => `${val.length} / 50 characters used`}
                data-qa="one-time-charge-description-input"
              />
              {isPremiumUser && bankAccountOptions.length > 1 && (
                <Field
                  label="Bank Account"
                  component={SelectField}
                  id="destination_id"
                  name="destination_id"
                  options={userBankOptions}
                  className={styles.bankSelect}
                  validate={fieldRequired}
                />
              )}
              <div className={styles.buttonRow}>
                <Button
                  className={styles.button}
                  secondary
                  type="button"
                  disabled={submitting}
                  data-qa="monthly-charge-cancel-button"
                  onClick={onCancel}
                >
                  Cancel
                </Button>

                <Button
                  className={styles.button}
                  type="submit"
                  loading={submitting}
                  data-qa="one-time-charge-save-button"
                >
                  {!isEdit ? 'Add' : 'Save'}
                </Button>
              </div>
            </form>
          );
        }}
      </Form>
    </Card>
  );
};

OneTimeChargeForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  chargeInfo: PropTypes.object,
  loadingUser: PropTypes.bool,
  isPremiumUser: PropTypes.bool,
  bankAccountOptions: PropTypes.array,
};

export default OneTimeChargeForm;
