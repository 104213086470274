/**
 * Gets the default card that a renter added to pay for
 * subscriptions and other type of purchases like screening, applications
 * etc.
 * @param {*} user
 * @returns
 */
export const getRenterBillingCard = (user) =>
  // TODO if we at some point allow billing through ACH we need to remove the type check
  user?.payment_methods?.find((pm) => pm.type === 'CARD' && pm.is_default);
