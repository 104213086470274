import { getConfig } from '../../services/configService';
import { TTfetch } from '../TTfetch';

const verifyConfirmationToken = (url) => {
  const { API_URL } = getConfig();

  return TTfetch(`${API_URL}/public/${url}`, {
    method: 'GET',
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json().then(function (data) {
          // `data` is the parsed version of the JSON returned from the above endpoint.
          return data;
        });
      }
      return false;
    })
    .catch(() => false);
};

export default verifyConfirmationToken;
