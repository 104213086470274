import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import getFieldError from '../../validators/get-field-error';
import Label from '../Label';
import SelectionRadioBox from '../SelectionRadioBox';

import styles from './SelectionRadioBoxGroup.module.scss';

const SelectionRadioBoxGroup = ({
  className,
  containerClassName,
  options,
  input,
  vertical,
  disabled,
  radioProps,
  labelStyles,
  style,
  meta,
  label,
  id,
}) => {
  const error = getFieldError(meta);
  return (
    <div className={cx(styles.wrap, className)} style={style}>
      {label && (
        <Label className={cx(styles.label, labelStyles)} htmlFor={id}>
          {label}
        </Label>
      )}
      <div className={cx(styles.container, containerClassName)}>
        {options.map(
          ({
            value,
            label,
            subLabel,
            footerNote,
            icon,
            iconProps,
            className: iconClassName,
            id,
            dataQa,
            labelClassName,
            subLabelClassName,
            footerNoteClassName,
          }) => (
            <SelectionRadioBox
              {...input}
              id={id}
              key={value}
              checked={input.value === value}
              value={value}
              icon={icon}
              iconProps={iconProps}
              iconClassName={iconClassName}
              labelClassName={labelClassName}
              subLabelClassName={subLabelClassName}
              footerNoteClassName={footerNoteClassName}
              label={label}
              subLabel={subLabel}
              footerNote={footerNote}
              disabled={disabled}
              vertical={vertical}
              {...radioProps}
              className={cx(styles.radioBox, radioProps.className)}
              data-qa={dataQa}
            />
          ),
        )}
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

SelectionRadioBoxGroup.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.node,
      icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      iconProps: PropTypes.object,
      className: PropTypes.string,
    }),
  ).isRequired,
  disabled: PropTypes.bool,
  vertical: PropTypes.bool,
  input: PropTypes.object,
  radioProps: PropTypes.object,
};

SelectionRadioBoxGroup.defaultProps = {
  radioProps: {},
  meta: {},
};

export default SelectionRadioBoxGroup;
