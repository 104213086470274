import React from 'react';

import browserHistory from '../../../browserHistory';
import Button from '../../../components/Button';
import Logo from '../../../components/Logo';
import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen';

import styles from './WelcomeHeader.module.scss';

const WelcomeHeader = () => {
  const handleLlLogin = () => {
    browserHistory.push('/auth/login');
  };

  const isSmallScreen = useIsSmallScreen();

  return (
    <div className={styles.container}>
      <Logo width={isSmallScreen ? 125 : 163} />

      <Button
        className={styles.button}
        small={isSmallScreen}
        secondary
        onClick={handleLlLogin}
      >
        {isSmallScreen ? 'Login' : 'Landlord Login'}
      </Button>
    </div>
  );
};

export default WelcomeHeader;
