import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RealEstateHouseDollarThickIcon from '../../../../icons/RealEstateHouseDollarThick';
import SectionTitle from '../../common/SectionTitle';

import styles from './SectionRentDepositFee.module.scss';

export const SectionRentDepositFeeProratedRentSection = ({ children }) => {
  return (
    <div className={cx(styles.column, styles.section)}>
      <SectionTitle
        title="Prorated Rent"
        icon={RealEstateHouseDollarThickIcon}
      />
      <label htmlFor="prorated_rent_choice" className={styles.secondaryLabel}>
        Will there be prorated rent due at move-in?
      </label>
      <p className={cx(styles.description, styles.proratedRentDescription)}>
        If your tenant is <strong>not</strong> moving in on the 1st of the
        month, you may want to charge a prorated amount. This covers their rent
        until the first full rent payment is due.
      </p>
      {children}
    </div>
  );
};

SectionRentDepositFeeProratedRentSection.propTypes = {
  children: PropTypes.node,
};
