import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../components/HelpfulInfo';
import ResourcesIcon from '../../../../../../icons/resources';

import styles from '../../ParkingInfo.module.scss';

const ParkingInfo = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.row}>
      <HelpfulInfoCard
        Icon={ResourcesIcon}
        className={styles.helpfulInfoCard}
        title="Texas Law on Parking Rules"
        color="iceCold"
      >
        For multi-unit properties, Texas requires that parking rules are
        included in the lease. If parking rules change, you will need to provide
        written notice to your tenant before they take effect. New rules must be
        mailed by certified mail, or hand delivered with time and date recorded.
      </HelpfulInfoCard>
    </div>
  </div>
);

ParkingInfo.propTypes = {
  formValues: PropTypes.object,
  className: PropTypes.string,
};

export default ParkingInfo;
