import React from 'react';
import { useUserProfile } from '../../../../../../../../src/core/TTgraphql';
import AccountAccessRoleEnum from '../../../../../../../../src/constants/enums/AccountAccessRoleEnum';
import styles from './MuaDisclaimer.module.scss';

const MuaDisclaimer = () => {
  const { user, muaUser } = useUserProfile({}, false);

  if (
    (muaUser?.users_accounts || []).find(
      (acc) => acc.user_role === AccountAccessRoleEnum.MAIN,
    )?.is_selected === false
  ) {
    return (
      <div className={styles.container}>
        You'll appear as {user.first_name} {user.last_name} when sending
        messages
      </div>
    );
  }

  return null;
};

export default MuaDisclaimer;
