import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Input from '../../../../../../../components/Input';
import BugIcon from '../../../../../../../icons/Bug';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './BedbugReportingDisclosureCA.module.scss';

const BedbugReportingDisclosureCA = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Bedbug Disclosure" icon={BugIcon} />
      <label className={styles.label}>
        How should your tenant report a bed bug infestation if one happens?
      </label>
      <p className={styles.description}>
        California law requires you provide a procedure for doing this. We've
        included a standard procedure for you in the box below. You may edit it
        if you wish.
      </p>
      <Field
        as="textarea"
        component={Input}
        id="custom_state_data.bed_bug_procedure"
        name="custom_state_data.bed_bug_procedure"
        className={styles.termsContainer}
        inputProps={{
          rows: '5',
        }}
        maxLength={2000}
        validate={fieldRequired}
        help={(val = '') => `${val.length} / 2000 characters used`}
      />
    </div>
  );
};

BedbugReportingDisclosureCA.propTypes = {
  className: PropTypes.string,
};

export default BedbugReportingDisclosureCA;
