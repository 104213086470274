import convertDollarsToCents from '../../../../helpers/convert-dollars-to-cents';

export const preparePreviewPublicLAData = (leaseAgreementData) => {
  return {
    ...leaseAgreementData,
    ...(leaseAgreementData?.base_rent_amount
      ? {
          base_rent_amount: convertDollarsToCents(
            leaseAgreementData?.base_rent_amount,
          ),
        }
      : {}),
    ...(leaseAgreementData?.monthly_pet_rent_amount
      ? {
          monthly_pet_rent_amount: convertDollarsToCents(
            leaseAgreementData?.monthly_pet_rent_amount,
          ),
        }
      : {}),
    ...(leaseAgreementData?.prorated_rent_amount
      ? {
          prorated_rent_amount: convertDollarsToCents(
            leaseAgreementData?.prorated_rent_amount,
          ),
        }
      : {}),
    ...(leaseAgreementData?.security_deposit_amount
      ? {
          security_deposit_amount: convertDollarsToCents(
            leaseAgreementData?.security_deposit_amount,
          ),
        }
      : {}),
    ...(leaseAgreementData?.other_deposit_amount
      ? {
          other_deposit_amount: convertDollarsToCents(
            leaseAgreementData?.other_deposit_amount,
          ),
        }
      : {}),
    ...(leaseAgreementData?.pet_deposit_amount
      ? {
          pet_deposit_amount: convertDollarsToCents(
            leaseAgreementData?.pet_deposit_amount,
          ),
        }
      : {}),
    ...(leaseAgreementData?.one_time_fees
      ? {
          one_time_fees: leaseAgreementData?.one_time_fees.map((fee) => ({
            ...fee,
            amount: convertDollarsToCents(fee.amount),
          })),
        }
      : {}),
    ...(leaseAgreementData?.propertyDetails?.address
      ? {
          property_address: leaseAgreementData?.propertyDetails?.address,
        }
      : {}),
    ...(leaseAgreementData?.propertyDetails?.unit
      ? {
          property_unit: leaseAgreementData?.propertyDetails?.unit,
        }
      : {}),
    ...(leaseAgreementData?.propertyDetails?.city
      ? {
          property_city: leaseAgreementData?.propertyDetails?.city,
        }
      : {}),
    ...(leaseAgreementData?.propertyDetails?.state
      ? {
          property_state: leaseAgreementData?.propertyDetails?.state,
        }
      : {}),
    ...(leaseAgreementData?.propertyDetails?.zip
      ? {
          property_zip: leaseAgreementData?.propertyDetails?.zip,
        }
      : {}),
  };
};
