import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../../../../constants/colors';
import Avatar from '../../../../Avatar';

import styles from './Benefit.module.scss';

const Benefit = ({ item, extraParams, isProPlan = false }) => {
  const ItemIcon = item.icon;
  const iconProps = {
    color: colors.orangeLight,
    ...(item.iconProps || {}),
  };

  return (
    <div key={item.title} className={styles.benefitItem}>
      {ItemIcon && (
        <Avatar
          className={styles.benefitAvatar}
          icon={<ItemIcon width={27} height={27} {...iconProps} />}
        />
      )}
      <div className={cx(styles.plan, { [styles.proPlan]: isProPlan })}>
        <h3 className={styles.itemTitle}>{item.title}</h3>
        {item?.description && (
          <p className={styles.itemDescription}>{item.description}</p>
        )}
        {item.cta && (
          <div className={styles.itemCta}>
            <a
              href={
                typeof item.ctaLink === 'function'
                  ? item.ctaLink(extraParams)
                  : item.ctaLink
              }
              target="_blank"
              rel="noopener noreferrer"
              onClick={item.onClick || (() => {})}
            >
              {item.cta}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

Benefit.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    cta: PropTypes.string,
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    onClick: PropTypes.func,
    icon: PropTypes.func,
    iconProps: PropTypes.object,
  }).isRequired,
  extraParams: PropTypes.object,
  isProPlan: PropTypes.bool,
};

export default Benefit;
