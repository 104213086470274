import React from 'react';

import validShareAbleCity from '../../validators/validShareAbleCity';

import InputField from './InputField';

const CityField = ({ validate = [], ...props }) => (
  <InputField
    validate={[
      validShareAbleCity,
      ...(Array.isArray(validate) ? validate : [validate]),
    ]}
    {...props}
  />
);

export default CityField;
