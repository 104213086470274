import moment from 'moment';

import convertCardinalToOrdinal from './cardinal-to-ordinal';

const dateToMonthAndYearFormatted = (dateString) =>
  `${moment.utc(dateString).format('MMMM YYYY')}`;

export const getPaymentRequestRuleDueToNarrative = ({
  due_day_of_month: dueDayOfMonth,
  start_date: startDate,
  end_date: endDate,
}) => {
  const doesLastDayOfMonthTerm =
    dueDayOfMonth === '31'
      ? 'last day of every month'
      : `${convertCardinalToOrdinal(dueDayOfMonth)}`;

  if (startDate) {
    let dueNarrative = `Due on the ${doesLastDayOfMonthTerm} 
    from ${dateToMonthAndYearFormatted(startDate)}`;

    if (endDate) dueNarrative += ` to ${dateToMonthAndYearFormatted(endDate)}`;
    else dueNarrative += ` onwards`;
    return dueNarrative;
  }

  return `Due on ${moment.utc(endDate).format('MMMM DD, YYYY')}`;
};
