import React from 'react';

import GBBPremiumModal from '../components/GBBPremiumModal';
import { Experiments } from '../constants/experiments';
import { useUserProfile } from '../core/TTgraphql';

//Im aware this is a hacky solution, since this isn't hoc anymore , but it's the quickest way to get this tests in place
const withBillingPlansTest = (Component) => {
  return (props) => {
    const { user, loading } = useUserProfile();
    const isGBBExperiment =
      user?.active_experiments?.some(
        (e) => e.name === Experiments.GoodBetterBest.name,
      ) || false;

    if (loading) return null;

    if (isGBBExperiment) {
      const experimentValue = user.active_experiments?.find(
        (exp) => exp.name === Experiments.GoodBetterBest.name,
      )?.value;

      if (
        Object.values(Experiments.GoodBetterBest.variants).includes(
          experimentValue,
        ) &&
        experimentValue !== Experiments.GoodBetterBest.variants.control
      ) {
        return <GBBPremiumModal {...props} />;
      }
    }

    return <Component {...props} />;
  };
};

export default withBillingPlansTest;
