import React from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Card from '../../../../../components/Card';
import IconButton from '../../../../../components/IconButton';
import colors from '../../../../../constants/colors';
import getFormattedDateLocalTZ from '../../../../../helpers/format-date-local';
import formatPhone from '../../../../../helpers/format-phone';
import MessagesIcon from '../../../../../icons/Messages';
import { useConfig } from '../../../../../providers/ConfigProvider';

import styles from './TenantDetailsCard.module.scss';

const TenantDetailsCard = ({
  className,
  tenantId,
  fullName,
  lastActive,
  invitedToPortal,
  email,
  telephone,
  isRenter,
  history,
}) => {
  const { PRIVATE_BASE_PATH } = useConfig();

  return (
    <Card className={cx(styles.container, className)}>
      <h3 className={styles.title}>Tenant Contact</h3>
      <div className={styles.content}>
        <span className={styles.name}>
          {fullName}
          {!isRenter && (
            <IconButton
              className={styles.icon}
              icon={MessagesIcon}
              iconProps={{ width: 20, height: 18, color: colors.primary }}
              onClick={() => {
                history.push({
                  pathname: `${PRIVATE_BASE_PATH}tenant-profile/${tenantId}/tab_messages`,
                });
              }}
            />
          )}
        </span>
        <span className={styles.text}>
          {lastActive
            ? `Last active: ${getFormattedDateLocalTZ(
                lastActive,
                'MMM DD, YYYY | hh:mm A',
              )}`
            : `Invited to portal: ${getFormattedDateLocalTZ(
                invitedToPortal,
                'MMM DD, YYYY | hh:mm A',
              )}`}
        </span>
        <span className={styles.text}>
          <a className={styles.email} href={`mailto:${email}`}>
            {email}
          </a>
          {telephone ? (
            <div className={styles.phoneWrapper}>
              {' | '}
              <span>{formatPhone(telephone)}</span>
            </div>
          ) : null}
        </span>
      </div>
    </Card>
  );
};

TenantDetailsCard.propTypes = {
  className: PropTypes.string,
  tenantId: PropTypes.string,
  fullName: PropTypes.string,
  lastActive: PropTypes.string,
  invitedToPortal: PropTypes.string,
  email: PropTypes.string,
  telephone: PropTypes.string,
  isRenter: PropTypes.bool,
  history: PropTypes.object,
};

export default withRouter(TenantDetailsCard);
