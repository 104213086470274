function supportsLocalStorage() {
  const mod = 'modernizr';
  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
}

const supportsLS = supportsLocalStorage();
const memoryStorage = {};

export function isAdminLogin() {
  if (!supportsLS) return false;
  return !!(
    window.localStorage.getItem('adminLogin') === 'true' &&
    window.sessionStorage.getItem('auth_token')
  );
}

export function setItem(name, value) {
  if (supportsLS) {
    window.localStorage.setItem(name, value);
    if (name === 'auth_token') {
      if (!value) {
        window.localStorage.removeItem('adminLogin');
      } else if (window.localStorage.getItem('adminLogin') === 'true') {
        window.sessionStorage && window.sessionStorage.setItem(name, value);
      }
    }
  } else {
    memoryStorage[name] = value;
  }
}

export function getItem(name) {
  try {
    if (supportsLS) {
      if (name === 'auth_token' && window.localStorage.getItem('adminLogin')) {
        return window.sessionStorage && window.sessionStorage.getItem(name);
      }
      return window.localStorage.getItem(name);
    } else {
      return memoryStorage[name];
    }
  } catch (e) {
    console.log('Faild getting item from the store', e);
  }
}

export function removeItem(name) {
  if (supportsLS) {
    window.localStorage.removeItem(name);
  } else {
    delete memoryStorage[name];
  }
}
