import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import RadioGroup from '../../../../../../../components/RadioGroup';
import DollarCircled from '../../../../../../../icons/DollarCircled';
import ResourcesIcon from '../../../../../../../icons/resources';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './RentControlDisclosure.module.scss';

const RentControlDisclosure = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <>
      <div className={cx(styles.container, className)}>
        <SectionTitle title="Rent Control Disclosure" icon={DollarCircled} />
        <label className={styles.label}>
          Is the property subject to rent control?
        </label>
        <p className={styles.description}>
          Section 205 of the District of Columbia Rental Housing Act of 1985
          requires you to notify the Tenant whether or not the property is
          subject to rent control.{' '}
          <a
            href="https://dhcd.dc.gov/service/rent-control"
            target="_blank noopener noreferrer"
          >
            Learn more about rent control and exemptions in DC.
          </a>
        </p>
        <Field
          component={RadioGroup}
          id="custom_state_data.rent_control_notification"
          name="custom_state_data.rent_control_notification"
          radiosContainerClassName={styles.radioButtonsContainer}
          options={[
            {
              id: 'rent_control_notification',
              value: true,
              label: 'Yes',
            },
            {
              id: 'rent_control_notification',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />
      </div>
      <HelpfulInfoCard
        className={styles.helpfulInfoCard}
        noTitle
        Icon={ResourcesIcon}
      >
        If the property is subject to Rent Control, a rent ceiling applies. A
        rent ceiling is the maximum price Landlord can charge for rent in the
        area. If you’d like to charge nonrefundable fees, such as an application
        fee or move-in fee, you must provide written notice of any pending
        requests for adjustment in the rent ceiling.{' '}
        <a
          target="_blank noopener noreferrer"
          href="https://dhcd.dc.gov/sites/default/files/dc/sites/dhcd/publication/attachments/RentControlFactSheet.pdf"
        >
          Learn more.
        </a>
      </HelpfulInfoCard>
    </>
  );
};

RentControlDisclosure.propTypes = {
  className: PropTypes.string,
};

export default RentControlDisclosure;
