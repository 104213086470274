import * as atatus from 'atatus-spa';

import 'babel-polyfill';
import 'classlist-polyfill'; // for IE
import 'whatwg-fetch';
import './pages/common/pollyfills/IE-console';
import './pages/common/pollyfills/canvas-to-blob';
import './assets/modernizr-custom';
import './assets/user-activity-script.js';

import { fetchConfig } from './services/configService';

import './main.scss';

fetchConfig().then((config) => {
  if (config.USER_TYPE !== 'ADMIN' && config.ATATUS_API_KEY) {
    atatus
      .config(config.ATATUS_API_KEY, {
        allowedDomains: ['turbotenant.com', 'segment.io', 'mixpanel.com'],
      })
      .install();
  }
});
