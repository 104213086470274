import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const ResolvedThick = (props) => (
  <svg {...props} viewBox="0 0 28 28" fill={props.color}>
    <g
      id="Maintenance-Request"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Maintenance-Request---No-Main-Contact"
        transform="translate(-290.000000, -708.000000)"
        fill="#042238"
      >
        <g id="Card" transform="translate(260.000000, 391.000000)">
          <g id="Activity" transform="translate(24.000000, 311.000000)">
            <g
              id="Icons/Thick/Electricity"
              transform="translate(4.000000, 4.000000)"
            >
              <path
                d="M16,2.66666667 C23.3631423,2.66666667 29.3333333,8.63614061 29.3333333,16 C29.3333333,23.3638594 23.3631423,29.3333333 16,29.3333333 C8.6347038,29.3333333 2.66666667,23.3649377 2.66666667,16 C2.66666667,8.63506231 8.6347038,2.66666667 16,2.66666667 Z M16,4.55218855 C9.67607414,4.55218855 4.55218855,9.67638193 4.55218855,16 C4.55218855,22.3236181 9.67607414,27.4478114 16,27.4478114 C22.321848,27.4478114 27.4478114,22.3224637 27.4478114,16 C27.4478114,9.6775363 22.321848,4.55218855 16,4.55218855 Z M22.0764157,11.5856932 C22.4316801,11.9663336 22.4111088,12.5629022 22.0304684,12.9181666 L13.9496603,20.4602541 C13.5782646,20.8068901 12.9989938,20.7969056 12.6397647,20.4376764 L9.94616196,17.7440737 C9.57799091,17.3759027 9.57799091,16.7789795 9.94616196,16.4108084 C10.314333,16.0426374 10.9112562,16.0426374 11.2794273,16.4108084 L13.3289884,18.4603696 L20.7439424,11.5397459 C21.1245828,11.1844815 21.7211514,11.2050529 22.0764157,11.5856932 Z"
                id="contacted-checkmark"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

ResolvedThick.defaultProps = {
  width: 28,
  height: 28,
  color: colors.text,
};

ResolvedThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ResolvedThick);
