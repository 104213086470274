import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';

import styles from './RemoveBankAccountModal.module.scss';

const RemoveBankAccountModal = ({
  onDeleteClick,
  isDeleteLoading,
  open,
  onCancel,
  replacementBankAccount,
}) => {
  return (
    <Modal className={styles.modal} open={open}>
      <h1 className={styles.title}>Remove Bank Account? </h1>
      {replacementBankAccount ? (
        <p className={styles.description}>
          Any charges (or pending deposits) currently assigned to this bank
          account will be{' '}
          <b>automatically assigned to {replacementBankAccount}</b>
        </p>
      ) : (
        <p className={styles.description}>
          If only one bank account remains, all charges will be{' '}
          <strong>automatically assigned to that bank.</strong>
        </p>
      )}
      <div className={styles.row}>
        <Button onClick={onCancel} secondary className={styles.cancelButton}>
          Cancel
        </Button>
        <Button
          onClick={onDeleteClick}
          id="confirm_delete_bank"
          loading={isDeleteLoading}
        >
          Remove Bank
        </Button>
      </div>
    </Modal>
  );
};

RemoveBankAccountModal.propTypes = {
  onDeleteClick: PropTypes.func,
  isDeleteLoading: PropTypes.bool,
  open: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default RemoveBankAccountModal;
