import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Illustration from './Illustration';

import styles from './MessageThreadEmpty.module.scss';

const MessageThreadEmpty = ({ className, type, userType }) => {
  let subtitle = '';
  if (type === 'SIDEBAR_CHATS') {
    subtitle = `Start a conversation with your ${
      userType === 'OWNER' ? 'renters' : 'landlords'
    } today!`;
  } else {
    subtitle =
      userType === 'OWNER'
        ? 'Pick a renter on the left to have a conversation with.'
        : 'Start a conversation with your landlords today!';
  }

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.illustrationContainer}>
        <Illustration className={styles.illustration} />
      </div>
      <h2 className={styles.title}>
        “Great landlord-tenant relationships start with great communication.”
      </h2>
      <span className={styles.subtitle}>{subtitle}</span>
    </div>
  );
};

MessageThreadEmpty.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'SIDEBAR_CHATS',
    'SIDEBAR_USERS',
    'SIDEBAR_GROUPS',
    'SIDEBAR_MOREINFO',
  ]),
  userType: PropTypes.oneOf(['OWNER', 'RENTER']).isRequired,
};

export default MessageThreadEmpty;
