import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../../../../constants/colors';
import Avatar from '../../../../Avatar';

import styles from './Benefit.module.scss';

const Benefit = ({ item, extraParams, isPremiumPlus }) => {
  const {
    icon: ItemIcon,
    iconProps: itemIconProps = {},
    title,
    description,
    cta,
    ctaLink,
    onClick,
  } = item;

  const iconProps = {
    color: colors.orangeLight,
    ...(itemIconProps || {}),
  };

  return (
    <div className={styles.benefitItem}>
      {ItemIcon && (
        <Avatar
          className={styles.benefitAvatar}
          icon={<ItemIcon width={27} height={27} {...iconProps} />}
        />
      )}
      <div className={cx(styles.plan, { [styles.proPlan]: !isPremiumPlus })}>
        <h3 className={styles.itemTitle}>{title}</h3>
        {description && <p className={styles.itemDescription}>{description}</p>}
        {cta && (
          <div className={styles.itemCta}>
            <a
              href={
                typeof ctaLink === 'function' ? ctaLink(extraParams) : ctaLink
              }
              target="_blank"
              rel="noopener noreferrer"
              onClick={onClick}
            >
              {cta}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

Benefit.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    cta: PropTypes.string,
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    onClick: PropTypes.func,
    icon: PropTypes.func,
    iconProps: PropTypes.object,
  }).isRequired,
  extraParams: PropTypes.object,
  isPremiumPlus: PropTypes.bool,
};

Benefit.defaultProps = {
  extraParams: {},
  isPremiumPlus: false,
};

export default Benefit;
