import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Line from '../common/Line';

import styles from './Cube.module.scss';

const Cube = ({ className, animated, small }) => (
  <div
    className={cx(
      className,
      styles.cube,
      { [styles.animated]: animated },
      { [styles.small]: small },
    )}
  >
    <div className={styles.circle} />
    <Line className={styles.line} />
  </div>
);

Cube.propTypes = {
  className: PropTypes.string,
  animated: PropTypes.bool,
  small: PropTypes.bool,
};

export default Cube;
