import React from 'react';
import cx from 'classnames';

import DownArrow from '../../../../icons/DownArrow';
import FlatButton from '../../../FlatButton';
import { useDropdownSubtextMenuContext } from '../../context';

import styles from './Trigger.module.scss';

const Trigger = () => {
  const { refs, getReferenceProps, isOpen, id, selected, error } =
    useDropdownSubtextMenuContext();

  return (
    <FlatButton
      ref={refs.setReference}
      id={id}
      {...getReferenceProps()}
      className={cx(styles.container, {
        [styles.open]: isOpen,
        [styles.invalid]: !!error,
      })}
    >
      {selected}
      <span className={styles.arrow}>
        <DownArrow />
      </span>
    </FlatButton>
  );
};

export default Trigger;
