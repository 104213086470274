import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TVThick = (props) => (
  <svg {...props} viewBox="0 0 20 17">
    <path
      d="M18.571 0C19.36 0 20 .651 20 1.451v10.781c0 .8-.64 1.451-1.429 1.451h-7.143l.001.89c1.887.153 3.591.594 4.83 1.254.3.16.415.536.258.84a.608.608 0 0 1-.826.262c-1.37-.73-3.477-1.173-5.765-1.173-2.287 0-4.394.443-5.764 1.173-.3.16-.67.042-.826-.262a.627.627 0 0 1 .257-.84c1.272-.677 3.032-1.124 4.978-1.266v-.878H1.429a1.44 1.44 0 0 1-1.422-1.311L0 12.232V1.452C0 .651.641 0 1.429 0Zm-8.367 13.683h-.409v.83l.131-.001.278.002v-.831Zm8.367-12.439H1.43a.208.208 0 0 0-.205.207v10.781c0 .113.094.208.205.208H18.57a.208.208 0 0 0 .205-.208V1.452a.208.208 0 0 0-.205-.208Zm-.816.414c.338 0 .612.279.612.622v9.123a.617.617 0 0 1-.612.622H2.245a.617.617 0 0 1-.612-.622V2.28c0-.343.274-.622.612-.622Zm-.612 1.244H2.857v7.879h14.286V2.902Z"
      fill="#042238"
      fillRule="evenodd"
    />
  </svg>
);

TVThick.defaultProps = {
  width: 20,
  height: 17,
};

TVThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(TVThick);
