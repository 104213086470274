import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import fieldRequiredShortStandard from '../../../../../../../validators/fieldRequiredShort';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './BankInfoFields.module.scss';

const BankInfoFieldsOR = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequiredShort = conditionalValidation(
    fieldRequiredShortStandard,
    !standAloneWizard,
  );
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  const {
    input: { value: isPortlandResident },
  } = useField('custom_state_data.is_portland_resident');

  return (
    <div className={cx(styles.container, className)}>
      <label className={styles.label}>Are you a resident of Portland?</label>
      <p className={styles.description}>
        Portland residents must give receipts for all security deposits
        collected and the name and location of the bank where the security
        deposit is held must be disclosed in the lease.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.is_portland_resident"
        name="custom_state_data.is_portland_resident"
        options={[
          {
            id: 'is_portland_resident_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'is_portland_resident_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      {isPortlandResident && (
        <div className={styles.row}>
          <Field
            label="Name of Bank"
            className={styles.inputContainer}
            id="deposit_bank_name"
            name="deposit_bank_name"
            component={Input}
            validate={fieldRequiredShort}
          />
          <Field
            label="Address"
            className={styles.inputContainer}
            id="deposit_bank_address"
            name="deposit_bank_address"
            component={Input}
            validate={fieldRequiredShort}
          />
        </div>
      )}
    </div>
  );
};

BankInfoFieldsOR.propTypes = {
  className: PropTypes.string,
};

export default BankInfoFieldsOR;
