import React, { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import cx from 'classnames';
import PropTypes from 'prop-types';

import AskForAccountPasswordModal from '../../../../../../components/AskForAccountPasswordModal';
import Button from '../../../../../../components/Button';
import { useErrorToast } from '../../../../../../components/Toast';
import { useUserProfile } from '../../../../../../core/TTgraphql';
import { useConfig } from '../../../../../../providers/ConfigProvider';

const OpenPlaidFlow = ({
  className,
  buttonLabel = 'Add Via Login',
  plaidConfig,
}) => {
  const { IS_RENTER } = useConfig();
  const { open, ready, error } = usePlaidLink(plaidConfig);
  const { user } = useUserProfile();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const errorToast = useErrorToast();
  const bankAccount = user && user.payments_data;

  useEffect(() => {
    if (error) {
      errorToast('An error has occurred');
    }
  }, [error]);

  const onValidationOk = () => {
    setShowPasswordModal(null);
    open();
  };

  const openPlaidFlow = () => {
    if (IS_RENTER || !bankAccount || !location?.state?.fromSettings) {
      open();
    } else {
      if (!user.has_password) {
        open();
        return;
      }
      setShowPasswordModal(true);
    }
  };

  return (
    <>
      <Button
        className={cx(className)}
        onClick={openPlaidFlow}
        disabled={!ready}
        data-qa={'add-bank-account-plaid-link-button'}
      >
        {buttonLabel}
      </Button>
      <AskForAccountPasswordModal
        open={showPasswordModal}
        onClose={() => setShowPasswordModal(null)}
        onPasswordValid={onValidationOk}
      />
    </>
  );
};

OpenPlaidFlow.propTypes = {
  className: PropTypes.string,
  buttonLabel: PropTypes.string,
  plaidConfig: PropTypes.object.isRequired,
};

export default OpenPlaidFlow;
