import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import cx from 'classnames';
import PropTypes from 'prop-types';

import SecurityIcon from '../../icons/Security';
import getFieldError from '../../validators/get-field-error';
import FlatButton from '../FlatButton';
import Label from '../Label';
import PlainInputWithIcon from '../PlainInputWithIcon';

import styles from './SSNField.module.scss';

const SSNField = ({
  input,
  id,
  label,
  className,
  meta,
  disabled,
  hint,
  ...otherProps
}) => {
  const error = getFieldError(meta);
  const [show, showPassword] = useState(false);
  const inputType = show || !input?.value ? 'text' : 'password';
  const toggleButtonLabel = show ? 'Hide' : 'Show';
  const mask = inputType === 'text' ? '999-99-9999' : '999999999';
  const maskChar = inputType === 'text' ? '_' : '';

  return (
    <div
      className={cx(styles.container, className, {
        [styles.disabled]: disabled,
      })}
    >
      {label ? (
        <div className={styles.label}>
          <Label htmlFor={id} hint={hint} className={styles.subLabel}>
            {label}
          </Label>
        </div>
      ) : null}
      <InputMask
        mask={mask}
        maskChar={maskChar}
        inputMode="tel"
        className={styles.mask}
        {...input}
        onChange={(e) => {
          e.preventDefault();
          input.onChange(e.target.value);
        }}
        value={input.value}
        disabled={disabled}
        {...otherProps}
      >
        {(passProps) => (
          <div className={cx(styles.inputContainer)}>
            <PlainInputWithIcon
              icon={SecurityIcon}
              invalid={!!error}
              id={id}
              type={inputType}
              className={styles.field}
              disabled={disabled}
              {...passProps}
            />
            <FlatButton
              type="button"
              onClick={() => showPassword(!show)}
              className={cx(styles.toggleButton)}
            >
              {toggleButtonLabel}
            </FlatButton>
          </div>
        )}
      </InputMask>
      {!!error && (
        <span id={`${id}_errormsg`} className={styles.error}>
          {error}
        </span>
      )}
    </div>
  );
};

SSNField.propTypes = {
  input: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.object,
  alwaysShowMask: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

SSNField.defaultProps = {
  alwaysShowMask: false,
  disabled: false,
};

export default SSNField;
