import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import KeysIcon from '../../../../icons/Keys';
import ListingHouseIcon from '../../../../icons/ListingHouse';
import fieldRequired from '../../../../validators/fieldRequired';
import Input from '../../../Input';
import RentalDetailsFields from '../../../RentalDetailsFields';
import SectionTitleWithIcon from '../../../SectionTitleWithIcon';

import styles from './PropertyInformationMultiUnits.module.scss';

const PropertyInformationMultiUnits = ({ className, hasRoomRentals }) => {
  return (
    <div className={cx(styles.container, className)}>
      <SectionTitleWithIcon
        className={styles.unitsSection}
        centered={false}
        title={'Add Unit'}
        icon={KeysIcon}
        iconProps={{ height: 27, width: 27 }}
        iconClassNames={styles.icon}
      />
      <p className={styles.sectionDescription}>
        Start with one unit, even if you have more. You'll be able to set up the
        rest later.
      </p>
      <Field
        id={`unit_name`}
        name={`unit_name`}
        className={styles.field}
        component={Input}
        label="Unit #"
        validate={fieldRequired}
        maxLength={50}
        placeholder="Address 2 (Apt, Suite, etc.)"
      />
      {hasRoomRentals ? (
        <>
          <Field
            id={`room_name`}
            name={`room_name`}
            className={styles.field}
            component={Input}
            label="Room Name"
            validate={fieldRequired}
            maxLength={50}
          />
        </>
      ) : (
        <>
          <SectionTitleWithIcon
            centered={false}
            title={'Rental Details'}
            icon={ListingHouseIcon}
            iconProps={{ height: 27, width: 27 }}
            iconClassNames={styles.icon}
          />
          <RentalDetailsFields />
        </>
      )}
    </div>
  );
};

PropertyInformationMultiUnits.propTypes = {
  className: PropTypes.string,
  hasRoomRentals: PropTypes.bool,
};

export default PropertyInformationMultiUnits;
