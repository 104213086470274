import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import SuccessAnimation from '../Animations/SuccessAnimation';

import LinePatternImg from './circle-lines.svg';
import RadialPatternImg from './radial-circle-pattern.svg';

import styles from './DottedHeader.module.scss';

const DottedHeader = ({
  icon: Icon,
  iconProps,
  circleProps,
  patternProps,
  backgroundColor,
  height,
  linePattern = false,
  className,
  circleCenterClassName,
  animation,
}) => (
  <div
    className={cx(
      styles.header,
      {
        [styles.hasAnimation]: animation,
      },
      className,
    )}
    style={{
      backgroundColor,
      height,
    }}
  >
    <img
      className={cx(styles.imgPattern, patternProps?.className)}
      style={{
        color: patternProps?.color,
        height: patternProps?.height ? patternProps.height : height,
      }}
      src={linePattern ? LinePatternImg : RadialPatternImg}
    />
    <div
      className={cx(styles.circleCenter, circleCenterClassName, {
        [styles.transparent]: circleProps?.transparent,
      })}
      style={{
        width: circleProps?.width || iconProps?.width,
        height: circleProps?.height || iconProps?.height,
      }}
    >
      {animation || !Icon ? <SuccessAnimation /> : <Icon {...iconProps} />}
    </div>
  </div>
);

DottedHeader.propTypes = {
  icon: PropTypes.object,
  backgroundColor: PropTypes.string,
  patternProps: PropTypes.object,
  iconProps: PropTypes.object,
  height: PropTypes.number,
  circularPattern: PropTypes.bool,
  circleProps: PropTypes.object,
  linePattern: PropTypes.bool,
  className: PropTypes.string,
  circleCenterClassName: PropTypes.string,
  animation: PropTypes.bool,
};

export default DottedHeader;
