import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';
import FlatButton from '../../../../../components/FlatButton';
import Pencil from '../../../../../icons/Pencil';

import styles from './SectionCard.module.scss';

const SectionCard = ({
  id,
  title,
  description,
  onStart,
  icon,
  started,
  className,
}) => {
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.leftCol}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.texts}>
          <div className={styles.title}>{title}</div>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
      <div className={styles.buttons}>
        {started ? (
          <FlatButton
            icon={Pencil}
            className={styles.editButton}
            onClick={onStart}
          >
            Edit
          </FlatButton>
        ) : (
          <Button
            className={styles.startButton}
            {...(id !== 1 && { secondary: true })}
            onClick={onStart}
          >
            Start
          </Button>
        )}
      </div>
    </div>
  );
};

SectionCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onStart: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  started: PropTypes.bool,
  className: PropTypes.string,
};

export default SectionCard;
