import React from 'react';
import cx from 'classnames';

import styles from './FormSection.module.scss';

const FormSection = ({ title, icon: Icon, children, className }) => (
  <div className={cx(styles.section, className)}>
    <div className={styles.subHeader}>
      <div className={styles.subHeaderIcon}>
        <Icon />
      </div>
      <h4 className={styles.subHeaderText}>{title}</h4>
    </div>
    {children}
  </div>
);

export default FormSection;
