import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Attachment = ({ width, height, color, ...props }) => (
  <svg width={width} height={height} {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M10.516 1.428c1.942-1.904 5.088-1.904 7.028 0a4.803 4.803 0 0 1 .162 6.723l-.162.166-6.662 6.533a.668.668 0 0 1-.931 0 .636.636 0 0 1-.07-.833l.07-.08 6.663-6.533a3.53 3.53 0 0 0 0-5.063 3.704 3.704 0 0 0-5.016-.14l-.15.14-9.406 9.221a2.398 2.398 0 0 0 0 3.436 2.515 2.515 0 0 0 3.373.118l.13-.118 9-8.852a.88.88 0 0 0 0-1.261.923.923 0 0 0-1.2-.074l-.086.074-6.663 6.533a.668.668 0 0 1-.93 0 .636.636 0 0 1-.07-.833l.07-.08 6.662-6.533a2.257 2.257 0 0 1 3.148 0c.83.813.867 2.108.114 2.966l-.113.12-9 8.853a3.85 3.85 0 0 1-5.367 0 3.67 3.67 0 0 1-.143-5.113l.143-.148 9.406-9.222Z"
    />
  </svg>
);

Attachment.defaultProps = {
  width: 19,
  height: 17,
  color: colors.text,
};

Attachment.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Attachment);
