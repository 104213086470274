import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import MaintenanceRequestStatusEnum, {
  MaintenanceRequestSelectableStatusesEnum,
} from '../../../constants/enums/MaintenanceRequestStatusEnum';
import CaretDown from '../../../icons/CaretDown';
import ButtonPopover from '../../ButtonPopover';
import FlatButton from '../../FlatButton';
import StatusTag from '../MaintenanceTable/StatusTag';

import styles from './StatusSelector.module.scss';

const StatusSelector = ({
  className,
  status,
  onStatusChange,
  lastStatusUpdatedManually,
  sentToPartner,
}) => {
  return (
    <ButtonPopover
      label={status}
      className={cx(
        className,
        styles.statusPopover,
        styles[status.toLowerCase()],
      )}
      optionsClassName={styles.statusOptions}
      buttonProps={{
        className: styles.statusSelector,
        type: status,
      }}
      closeOnClick
      customAction={(props) => (
        <StatusTag
          {...props}
          sentToPartner={sentToPartner}
          lastStatusUpdatedManually={lastStatusUpdatedManually}
        >
          <CaretDown className={styles.caretDown} />
        </StatusTag>
      )}
    >
      {Object.values(MaintenanceRequestSelectableStatusesEnum)
        .filter((s) => s !== status)
        .map((status) => (
          <FlatButton
            key={status}
            className={styles.statusItem}
            onClick={() => {
              onStatusChange(status);
            }}
          >
            {status.replace('_', ' ')}
          </FlatButton>
        ))}
    </ButtonPopover>
  );
};

StatusSelector.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf(Object.values(MaintenanceRequestStatusEnum)),
  onStatusChange: PropTypes.func.isRequired,
  lastStatusUpdatedManually: PropTypes.bool,
};

export default StatusSelector;
