import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ESignColor = (props) => (
  <svg viewBox="0 0 28 28" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.color}
        d="M10.679 17.334v1.11h-.556a.556.556 0 0 0-.556.556v2.222c0 .28.207.515.484.551l.294.042c2.797.438 4.34 1.758 4.77 4.208l.032.2H1.842l.033-.206c.439-2.548 2.025-3.857 4.983-4.244a.556.556 0 0 0 .484-.55V19l-.009-.1a.556.556 0 0 0-.547-.455h-.557v-1.111h4.45Z"
      />
      <path
        fill="#042238"
        d="M11.236 16.223H5.673a.556.556 0 0 0-.556.555V19l.009.1c.047.26.274.456.547.456h.556v1.189l-.143.025c-3.354.62-5.16 2.547-5.418 5.966a.556.556 0 0 0 .555.597h14.545a.556.556 0 0 0 .555-.597l-.028-.305c-.343-3.213-2.19-5.054-5.468-5.66l-.148-.026v-1.19h.557a.556.556 0 0 0 .556-.555v-2.222a.556.556 0 0 0-.556-.555Zm-.557 1.11v1.112h-.556a.556.556 0 0 0-.556.555v2.222c0 .28.207.515.484.551l.294.042c2.797.438 4.34 1.758 4.77 4.208l.032.2H1.842l.033-.206c.439-2.548 2.025-3.857 4.983-4.244a.556.556 0 0 0 .484-.55V19l-.009-.1a.556.556 0 0 0-.547-.455h-.557v-1.111h4.45ZM22.91 1.132l.008.092V3.98l2.776-2.774a.557.557 0 0 1 .841-.063c1.225 1.225 1 3.463-.404 5.911a.556.556 0 0 1-.385.27l-.098.01-4.515-.001-1.113 1.112h4.01a.556.556 0 0 1 .1 1.102l-.1.01-5.123-.001-1.112 1.11 4.567.001c.508 0 .75.625.374.967-2.753 2.499-6.447 3.404-8.253 2.342l-1.436 1.434a.557.557 0 0 1-.85-.71l.064-.076 1.444-1.443c-1.07-1.892.547-5.826 3.641-8.914 1.522-1.523 3.358-2.845 4.769-3.542a.557.557 0 0 1 .795.406Zm-2.166 10.647h-4.062l-1.374 1.373c1.113.432 3.213.022 5.182-1.208l.254-.165Zm1.06-9.615-.085.05a19.928 19.928 0 0 0-3.274 2.534l-.312.305c-2.617 2.612-4.015 5.838-3.588 7.29l5.944-5.938a.56.56 0 0 1 .034-.034l1.282-1.278V2.163Zm4.26.247-3.25 3.246a.56.56 0 0 1-.13.13l-.438.436h3.074l.12-.229c.758-1.493.957-2.774.624-3.583Z"
      />
    </g>
  </svg>
);

ESignColor.defaultProps = {
  width: 54,
  height: 48,
  color: '#7FE3FF',
};

ESignColor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(ESignColor);
