import React from 'react';
import cx from 'classnames';

import QuotationMarkOrange from '../../icons/QuotationMarkOrange';
import ReviewsIoWidget from '../ReviewsIoWidget';

import styles from './SideBarReviews.module.scss';

const SideBarReviews = ({
  title,
  subtitle,
  className,
  titleClassName,
  graphic,
  description,
  quote,
  alignTop,
  reviewer,
  ...otherProps
}) => (
  <div
    className={cx(styles.container, className, {
      [styles.alignTop]: !!alignTop,
    })}
    {...otherProps}
  >
    <div className={styles.titleContainer}>
      <QuotationMarkOrange className={styles.quotationMark} />
      <span className={cx(styles.title, titleClassName)}>{quote}</span>
      <span className={styles.reviewer}>{reviewer}</span>
      <ReviewsIoWidget className={styles.reviewsIo} />
    </div>
  </div>
);

SideBarReviews.defaultProps = {
  quote:
    'This app is so easy to use and gives me all the tools I need to rent my properties!',
  reviewer: '— Connie M.',
};

export default SideBarReviews;
