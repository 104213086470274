import { TT_ACCOUNT_ID_PARAM } from '../providers/TTAccountProvider';

function updateAnchorTags(ttAccountId, domainUrl) {
  const anchors = document.querySelectorAll('a');

  anchors.forEach((anchor) => {
    const url = new URL(anchor.href, window.location.origin);
    if (
      domainUrl.includes(url.host) &&
      url.protocol !== 'blob:' &&
      !url.searchParams.has(TT_ACCOUNT_ID_PARAM)
    ) {
      url.searchParams.set(TT_ACCOUNT_ID_PARAM, ttAccountId);
      anchor.href = url.toString();
    }
  });
}

export default updateAnchorTags;
