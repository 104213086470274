import React from 'react';

import TransUnionInsightsLogo from '../../icons/TransUnionInsightsLogo';
import IncomeAnalysisAnimation from '../Animations/IncomeAnalysisAnimation';

import LowerIncomeInsightCard from './components/IncomeInsightCards/LowerIncomeInsightCard';
import NoDataInsightCard from './components/IncomeInsightCards/NoDataInsightCard';
import OkIncomeInsightCard from './components/IncomeInsightCards/OkIncomeInsightCard';

import styles from './InsightsResult.module.scss';

const InsightsResult = ({
  incomeInsights,
  onAnimationComplete,
  showLoader,
  application,
}) => {
  const firstName = application?.first_name;
  const incomeInsightsEstimate = incomeInsights?.estimate;
  /**
   * NOT IN USE right now but we could use this value to show
   * what the renter reported to TU. This value will always be annualy
   * as TU confirmed that to us.
   */
  // const reportedAmount = incomeInsights?.reportedAmount;

  const getCard = () => {
    if (incomeInsightsEstimate === 'OK') {
      return (
        <OkIncomeInsightCard firstName={firstName} application={application} />
      );
    }

    if (incomeInsightsEstimate === 'NO_DATA' || !incomeInsightsEstimate) {
      return <NoDataInsightCard firstName={firstName} />;
    }

    if (
      incomeInsightsEstimate !== 'NO_DATA' &&
      incomeInsightsEstimate !== 'OK'
    ) {
      return (
        <LowerIncomeInsightCard
          firstName={firstName}
          estimate={incomeInsightsEstimate}
          application={application}
        />
      );
    }
  };

  return (
    <>
      {showLoader && (
        <div className={styles.loadingAnimation}>
          <IncomeAnalysisAnimation onComplete={onAnimationComplete} />
          <span>Analyzing {firstName}’s income…</span>
        </div>
      )}
      {!showLoader && getCard()}
      <TransUnionInsightsLogo className={styles.transunionLogo} />
    </>
  );
};

export default InsightsResult;
