import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

//
const SecurityFilled = ({ color, ...props }) => (
  <svg {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M12 3c2.617 0 4.737 2.06 4.737 4.603v1.993h1.675c.292 0 .535.207.58.479l.008.093v10.26a.58.58 0 0 1-.588.572H5.588A.58.58 0 0 1 5 20.428v-10.26a.58.58 0 0 1 .588-.572h1.674V7.603c0-2.472 2.005-4.488 4.521-4.598L12 3zm0 10.26a.952.952 0 0 0-.966.939c0 .302.148.572.378.743v1.822l.006.084a.583.583 0 0 0 .582.488.58.58 0 0 0 .588-.572v-1.822a.928.928 0 0 0 .378-.743.952.952 0 0 0-.966-.938zm0-9.117c-1.967 0-3.56 1.549-3.56 3.46v1.993h7.12V7.603c0-1.852-1.495-3.363-3.377-3.455L12 4.143z"
        fill={color}
      />
    </g>
  </svg>
);

SecurityFilled.defaultProps = {
  width: 15,
  height: 20,
  color: colors.text,
};

SecurityFilled.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(SecurityFilled);
