import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import MinusCircle from '../../icons/MinusCircle';
import PlusCircle from '../../icons/PlusCircle';
import getFieldError from '../../validators/get-field-error';
import FlatButton from '../FlatButton';
import Label from '../Label';
import PlainInput from '../PlainInput';

import styles from './Stepper.module.scss';

const clamp = (value, min, max) => {
  if (value === '' || value == null) {
    return value;
  }
  return Math.round(Math.min(Math.max(value, min), max));
};

const Stepper = ({
  input: { value, onChange, ...restInput },
  label,
  className,
  fieldClassName,
  errorClassName,
  id,
  meta,
  disabled,
  small,
  min,
  max,
  labelProps,
  onPlusClick,
  onMinusClick,
  ...restProps
}) => {
  const error = getFieldError(meta);
  return (
    <div
      className={cx(
        styles.input,
        {
          [styles.big]: !small,
          [styles.small]: small,
        },
        className,
      )}
    >
      {label && (
        <Label {...labelProps} htmlFor={id}>
          {label}
        </Label>
      )}
      <div className={styles.content}>
        <FlatButton
          onClick={() => {
            onChange(clamp(value - 1, min, max));
            onMinusClick();
          }}
          disabled={disabled || value === min || value === ''}
          icon={MinusCircle}
          iconProps={{ className: styles.icon }}
        />
        <PlainInput
          className={cx(styles.field, fieldClassName)}
          type="number"
          id={id}
          disabled={disabled}
          invalid={!!error}
          value={value}
          onChange={(e) => {
            e.preventDefault();
            onChange(clamp(e.target.value, min, max));
          }}
          {...restInput}
          {...restProps}
        />
        <FlatButton
          onClick={() => {
            onChange(clamp(value + 1, min, max));
            onPlusClick();
          }}
          disabled={disabled || value === max}
          icon={PlusCircle}
          iconAfter
          iconProps={{ className: styles.icon }}
        />
      </div>
      {error && (
        <span
          id={`${id}_errormsg`}
          className={cx(styles.error, errorClassName)}
        >
          {error}
        </span>
      )}
    </div>
  );
};

Stepper.defaultProps = {
  min: 0,
  max: 999,
  onPlusClick: () => {},
  onMinusClick: () => {},
};

Stepper.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  onPlusClick: PropTypes.func,
  onMinusClick: PropTypes.func,
};

export default Stepper;
