import get from 'lodash.get';

const getAppliedTo = (listing = {}) => {
  const address = get(listing, 'address', '');
  const unit = get(listing, 'unit', '');
  const unitString = !unit ? '' : ` Unit# ${unit.trim()}`;
  return `${address}${unitString}`;
};

export default getAppliedTo;
