import {
  grey1,
  greyLight,
  line,
  text,
} from '../../../../../../constants/colors';

export const contactWrapperStyle = (props, theme) => {
  const borderStyle =
    props._parent === ''
      ? {
          border: `1px solid ${theme.borderColor.primary}`,
        }
      : {};

  return {
    'display': 'flex',
    'flexDirection': 'column',
    'height': '100%',
    'boxSizing': 'border-box',
    ...borderStyle,
    '*': {
      'boxSizing': 'border-box',
      '::-webkit-scrollbar': {
        width: '8px',
        height: '4px',
      },
      '::-webkit-scrollbar-track': {
        background: '#ffffff00',
      },
      '::-webkit-scrollbar-thumb': {
        'background': '#ccc',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  };
};

export const contactHeaderStyle = () => {
  return {
    padding: '12px 16px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${line}`,
    minHeight: '67px',
  };
};

export const contactHeaderCloseStyle = (img, theme) => {
  const mq = [...theme.breakPoints];

  return {
    cursor: 'pointer',
    display: 'none',
    mask: `url(${img}) left center no-repeat`,
    backgroundColor: `${theme.secondaryTextColor}`,
    height: '24px',
    width: '33%',
    [`@media ${mq[1]}, ${mq[2]}`]: {
      display: 'block!important',
    },
  };
};

export const contactHeaderTitleStyle = () => {
  return {
    color: text,
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '34px',
    margin: 0,
    width: '100%',
  };
};

export const contactSearchContainerStyle = () => {
  return {
    borderBottom: `1px solid ${line}`,
  };
};

export const contactSearchStyle = () => {
  return {
    margin: '16px',
    position: 'relative',
    borderRadius: '4px',
    boxShadow: `inset 0 0 0 1px ${greyLight}`,
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
};

export const contactSearchButtonStyle = (img) => {
  return {
    width: '32px',
    height: '100%',
    cursor: 'default',
    mask: `url(${img}) 10px center no-repeat`,
    backgroundColor: `${text}!important`,
  };
};

export const contactSearchInputStyle = (props) => {
  return {
    width: 'calc(100% - 30px)',
    padding: '11px 16px 11px 8px',
    fontSize: '17px',
    letterSpacing: 0,
    fontWeight: '400',
    lineHeight: '26px',
    color: grey1,
    outline: 'none',
    border: 'none',
    color: props.theme.color.search,
    backgroundColor: 'transparent',
  };
};

export const contactMsgStyle = () => {
  return {
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
  };
};

export const contactMsgTxtStyle = (theme) => {
  return {
    margin: '0',
    minHeight: '36px',
    color: `${theme.color.secondary}`,
    fontSize: '20px!important',
    fontWeight: '600',
    lineHeight: '30px',
    wordWrap: 'break-word',
    padding: '0 16px',
  };
};

export const contactListStyle = () => {
  return {
    height: 'calc(100% - 125px)',
    overflowY: 'auto',
    margin: '0',
    padding: '0',
  };
};

export const contactAlphabetStyle = (props) => {
  return {
    padding: '0 16px',
    margin: '5px 0',
    width: '100%',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '20px',
    color: `${props.theme.color.tertiary}`,
  };
};
