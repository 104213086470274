import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Spinner from '../../components/Spinner';

import styles from './IconButton.module.scss';

const IconButton = React.forwardRef(
  (
    {
      to,
      href,
      type,
      icon: Icon,
      iconProps,
      className,
      disabled,
      loading,
      ...otherProps
    },
    ref,
  ) => {
    let Element = 'button';
    const props = {};
    if (to && !disabled) {
      props.to = to;
      Element = Link;
    } else if (href && !disabled) {
      props.href = href;
      Element = 'a';
    } else {
      props.type = type;
    }
    if (loading) return <Spinner secondary />;
    return (
      <Element
        className={cx(
          styles.button,
          {
            [styles.disabled]: disabled,
          },
          className,
        )}
        disabled={disabled}
        {...otherProps}
        {...props}
        ref={ref}
      >
        <Icon {...iconProps} className={cx(styles.icon, iconProps.className)} />
      </Element>
    );
  },
);

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
  iconProps: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

IconButton.defaultProps = {
  type: 'button',
  iconProps: {},
};

export default IconButton;
