import React from 'react';
import cx from 'classnames';

import styles from './StepSection.module.scss';

const StepSection = ({ title, description, className, children }) => (
  <div className={cx(styles.section, className)}>
    {title && (
      <h3
        className={cx(styles.title, {
          [styles.hasDescription]: description != null,
        })}
      >
        {title}
      </h3>
    )}
    {description && <p className={styles.description}>{description}</p>}
    {children}
  </div>
);

export default StepSection;
