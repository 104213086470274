import React, { useCallback, useEffect } from 'react';
import { Field } from 'react-final-form';
import { useWindowWidth } from '@react-hook/window-size';
import cx from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import FlatButton from '../../components/FlatButton';
import Input from '../../components/Input';
import { Tooltip } from '../../components/Tooltip';
import breakpoints from '../../constants/media-breakpoints';
import EditIcon from '../../icons/Edit';
import PlusCircleIcon from '../../icons/PlusCircle';
import composeValidators from '../../validators/composeValidators';
import fieldRequiredShort from '../../validators/fieldRequiredShort';
import validEmail from '../../validators/validEmail';
import validName from '../../validators/validName';

import styles from './AdditionalSignersForm.module.scss';

const MOBILE_BREAKPOINT = breakpoints.smallScreenWidth;

const ACTIONS = {
  ADD: 'add',
  CANCEL: 'cancel',
  EDIT: 'edit',
  OPENEDIT: 'openedit',
};

const signersReducer = (state, action, showCompany) => {
  const { type, id, data } = action;
  const newState = [...state];

  switch (type) {
    case ACTIONS.INIT:
      return [...data];
    case ACTIONS.ADD:
      return [
        ...state,
        {
          first_name: '',
          last_name: '',
          checked: true,
          editing: true,
          ...(showCompany ? { company_name: '' } : {}),
        },
      ];
    case ACTIONS.CANCEL: {
      if (newState[id]?.oldState?.email) {
        newState[id] = {
          ...newState[id].oldState,
          oldState: newState[id].oldState,
        };
        return newState;
      } else {
        newState.splice(id, 1);
        return newState;
      }
    }
    case ACTIONS.EDIT: {
      newState[id] = {
        ...newState[id],
        ...data,
      };
      return newState;
    }
    case ACTIONS.OPENEDIT: {
      newState[id] = {
        ...newState[id],
        editing: true,
        oldState: { ...newState[id] },
      };
      return newState;
    }
    default:
      return state;
  }
};

const emailInUseValidator =
  (signerKey, usedEmails, inputName) => (value, allValues) => {
    const signers = get(allValues, inputName) || [];
    const emailsToCheck = signers?.reduce((acc, signer, idx) => {
      if (idx !== signerKey) {
        acc.push(signer.email);
      }
      return acc;
    }, []);
    emailsToCheck?.push(...usedEmails);

    const emailIsUsed = emailsToCheck?.some(
      (email) => email?.toLowerCase().trim() === value?.toLowerCase().trim(),
    );

    if (emailIsUsed) {
      return 'Email is already in use by another party in this lease agreement.';
    }

    return undefined;
  };

const AdditionalSignersForm = ({
  input,
  meta,
  usedEmails,
  showLabel,
  signersText,
  signerLabel,
  showCompany,
  addBtnLabel,
  preOpenEdit,
  disabledStateTooltip,
}) => {
  const inputName = input.name;
  const signers = get(input, 'value') || [];

  const screenWidth = useWindowWidth();
  const modSigners = useCallback(
    (action) => {
      const newValue = signersReducer(signers, action, showCompany);
      input.onChange(newValue);
    },
    [signers, showCompany, input],
  );

  useEffect(() => {
    if (signers?.length === 0 && preOpenEdit) {
      modSigners({
        type: ACTIONS.ADD,
      });
    }
  }, [modSigners]);

  const isEditing = signers.some((signer) => signer.editing);

  const renderSigners = () =>
    signers.map((signer, index) => (
      <React.Fragment key={index}>
        <div className={cx(styles.row, styles.signerContainer)}>
          {!signer.editing && (
            <div
              className={cx(styles.row, styles.signerData, {
                [styles.checked]: signer.checked,
              })}
            >
              <Field
                component={Checkbox}
                className={styles.checkbox}
                type="checkbox"
                id={`${input.name}.${index}.checked`}
                name={`${input.name}.${index}.checked`}
              />
              <div className={styles.infoContainer}>
                <p
                  className={styles.name}
                >{`${signer.first_name} ${signer.last_name}`}</p>
                {showCompany && signer.company_name && (
                  <p className={styles.company}>{signer.company_name}</p>
                )}
                <div className={styles.contact}>
                  <span className={styles.email}>{signer.email}</span>
                </div>
              </div>
            </div>
          )}
          {signer.editing && (
            <div className={styles.editForm}>
              <p className={styles.title}>{signerLabel}</p>
              <div className={styles.fieldWrapper}>
                <Field
                  label="Legal First Name"
                  component={Input}
                  id={`${input.name}.${index}.first_name`}
                  name={`${input.name}.${index}.first_name`}
                  validate={composeValidators(fieldRequiredShort, validName)}
                  className={styles.inputContainer}
                  labelProps={{
                    className: styles.inputLabel,
                  }}
                  inputProps={{
                    className: styles.input,
                  }}
                />
                <Field
                  label="Last Name"
                  component={Input}
                  id={`${input.name}.${index}.last_name`}
                  name={`${input.name}.${index}.last_name`}
                  validate={composeValidators(fieldRequiredShort, validName)}
                  className={styles.inputContainer}
                  labelProps={{
                    className: styles.inputLabel,
                  }}
                  inputProps={{
                    className: styles.input,
                  }}
                />
              </div>
              {showCompany ? (
                <div className={styles.fieldWrapper}>
                  <Field
                    label="Company Name"
                    component={Input}
                    id={`${input.name}.${index}.company_name`}
                    name={`${input.name}.${index}.company_name`}
                    className={cx(styles.inputContainer, styles.email)}
                    labelProps={{
                      className: styles.inputLabel,
                    }}
                    inputProps={{
                      className: styles.input,
                    }}
                    hint="(Optional)"
                  />
                </div>
              ) : null}
              <div className={styles.fieldWrapper}>
                <Field
                  label="Email"
                  component={Input}
                  id={`${input.name}.${index}.email`}
                  name={`${input.name}.${index}.email`}
                  validate={composeValidators(
                    fieldRequiredShort,
                    validEmail,
                    emailInUseValidator(index, usedEmails, inputName),
                  )}
                  className={cx(styles.inputContainer, styles.email)}
                  labelProps={{
                    className: styles.inputLabel,
                  }}
                  inputProps={{
                    className: styles.input,
                  }}
                />
              </div>
              <p className={styles.text}>
                Please double check their email. Whoever receives this email
                will be able to access and sign the document.
              </p>
              <div className={styles.row}>
                {signers?.length > 1 || !preOpenEdit ? (
                  <Button
                    small
                    secondary
                    className={cx(styles.btn, styles.cancel)}
                    id="btn_cancel_tenant_edit"
                    onClick={() => {
                      modSigners({
                        type: ACTIONS.CANCEL,
                        id: index,
                      });
                    }}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button
                  small
                  className={cx(styles.btn, styles.save, {
                    [styles.disabled]: meta.error && meta.error[index],
                  })}
                  id="btn_save_tenant_info"
                  onClick={() => {
                    if (!meta.error || (meta.error && !meta.error[index])) {
                      modSigners({
                        type: ACTIONS.EDIT,
                        id: index,
                        data: { editing: false },
                      });
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
          {!signer.editing && (
            <FlatButton
              id={`edit_tenant_${index}_button`}
              onClick={() => {
                modSigners({
                  type: ACTIONS.OPENEDIT,
                  id: index,
                });
              }}
              icon={EditIcon}
              className={cx(styles.btn, styles.edit)}
            >
              <span>{screenWidth < MOBILE_BREAKPOINT ? '' : 'EDIT'}</span>
            </FlatButton>
          )}
        </div>
        <div className={styles.divider} />
      </React.Fragment>
    ));

  return (
    <div className={styles.container}>
      {showLabel && (
        <>
          <p className={styles.greyTitle}>Are there any additional signers?</p>
          <p className={styles.text}>
            {signersText
              ? signersText
              : `Add another landlord, co-signer, or any non-tenant that needs to sign
        this document.`}
          </p>
        </>
      )}
      {renderSigners()}
      <Button
        data-tooltip-id={`add-signer-tooltip-${disabledStateTooltip}`}
        type="button"
        tertiary={true}
        onClick={() => {
          modSigners({
            type: ACTIONS.ADD,
          });
        }}
        icon={PlusCircleIcon}
        iconProps={{
          width: 15,
          height: 15,
        }}
        className={cx(styles.btn, styles.add)}
        disabled={isEditing}
      >
        {addBtnLabel}
      </Button>
      {isEditing && disabledStateTooltip ? (
        <Tooltip id={`add-signer-tooltip-${disabledStateTooltip}`}>
          {disabledStateTooltip}
        </Tooltip>
      ) : null}
    </div>
  );
};

AdditionalSignersForm.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  usedEmails: PropTypes.array,
  showLabel: PropTypes.bool,
  signersText: PropTypes.string,
  signerLabel: PropTypes.string,
  showCompany: PropTypes.bool,
  addBtnLabel: PropTypes.string,
  preOpenEdit: PropTypes.bool,
  disabledStateTooltip: PropTypes.string,
};

AdditionalSignersForm.defaultProps = {
  usedEmails: [],
  showLabel: true,
  signerLabel: 'Additional Signer',
  showCompany: false,
  addBtnLabel: 'Add Additional Signer',
  preOpenEdit: false,
};

export default AdditionalSignersForm;
