import { useMutation } from '@apollo/client';

import dismissBannerMutation from '../graphql/mutations/dismissBanner.graphql';

const useDismissBanner = (options, bannerName) =>
  useMutation(dismissBannerMutation, {
    variables: { bannerName },
    ...options,
  });

export default useDismissBanner;
