import React from 'react';

import PowerdByStripe from '../../icons/PowerdByStripe';
import SecuredBySectigo from '../../icons/SecuredBySectigo';
import SecureSSLEncryption from '../../icons/SecureSSLEncryption';
import SecurityFilled from '../../icons/SecurityFilled';
import Notice from '../Notice';

import styles from './AddBankManuallyFooter.module.scss';

const AddBankManuallyFooter = () => (
  <>
    <p className={styles.termsOfServiceDisclaimer}>
      By clicking the button above, you are agreeing to{' '}
      <a
        href="https://stripe.com/connect-account/legal"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        Stripe's
      </a>
      ,{' '}
      <a
        href="https://plaid.com/legal/"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        Plaid's
      </a>
      , and{' '}
      <a
        href="https://www.turbotenant.com/terms-of-use/"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        TurboTenant's
      </a>{' '}
      terms of service. Stripe is the industry leader in payment processing,
      while Plaid makes it easy to connect to your bank.
    </p>

    <>
      <Notice
        icon={SecurityFilled}
        iconProps={{
          color: '#042238',
          height: 21,
          width: 20,
        }}
        className={styles.securityNotice}
      >
        <span>
          <b>Guaranteed secure</b>. Our payment processor, Stripe, requires
          identity verification in order to collect rent. TurboTenant takes
          security seriously.{' '}
          <a
            href="https://support.turbotenant.com/en/articles/4003994-how-secure-is-turbotenant"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Learn how TurboTenant protects its user’s data
          </a>
          .
        </span>
      </Notice>
      <div className={styles.securityIcons}>
        <PowerdByStripe />
        <SecuredBySectigo />
        <SecureSSLEncryption />
      </div>
    </>
  </>
);

export default AddBankManuallyFooter;
