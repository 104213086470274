import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Resend = (props) => (
  <svg {...props} viewBox="0 0 22 19" fill={props.color}>
    <path d="M15.653 14.822c.376 0 .68.312.68.696a.689.689 0 01-.68.697h-1.084a3.772 3.772 0 002.899 1.392c1.453 0 2.733-.94 3.21-2.32a.677.677 0 01.868-.423.7.7 0 01.415.889C21.293 17.683 19.503 19 17.468 19a5.132 5.132 0 01-3.404-1.32v.16a.689.689 0 01-.68.696.689.689 0 01-.68-.697v-2.32c0-.385.304-.697.68-.697h2.269zM18.376 0c1.127 0 2.042.936 2.042 2.089v5.106a.689.689 0 01-.68.697.689.689 0 01-.681-.697l-.001-4.957-8.422 6.894a.669.669 0 01-.85 0L1.36 2.238v10.064a.69.69 0 00.68.696h7.26c.376 0 .681.312.681.696a.689.689 0 01-.68.697h-7.26C.915 14.39 0 13.455 0 12.3V2.09C0 .936.915 0 2.042 0h16.334zm-1.361 9.284c1.267 0 2.492.498 3.403 1.32v-.16c0-.384.305-.696.68-.696.376 0 .681.312.681.697v2.32a.689.689 0 01-.68.697h-2.27a.689.689 0 01-.68-.696c0-.385.305-.696.68-.696l1.085-.001a3.772 3.772 0 00-2.9-1.392c-1.453 0-2.732.941-3.209 2.32a.677.677 0 01-.869.423.7.7 0 01-.414-.888c.668-1.93 2.458-3.248 4.493-3.248zm.895-7.892H2.507l7.702 6.304 7.701-6.304z" />
  </svg>
);

Resend.defaultProps = {
  width: 22,
  height: 19,
  color: colors.primary,
};

Resend.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Resend);
