import * as auth from './auth/authService';
import { getClient } from './apolloClient';

export class TTInitializer {
  constructor({ getRootReducer } = {}) {
    this.getRootReducer = getRootReducer;
  }

  getApolloClient(config) {
    if (this.client) {
      return this.client;
    }

    const GRAPHQL_URL = config.API_URL + '/graphql';

    const params = new URLSearchParams(location.search);
    const passedToken = params.get('ttk') || null;

    if (passedToken) {
      auth.login(passedToken);
    }

    const client = getClient(
      GRAPHQL_URL,
      {
        getToken: auth.getCurrentToken,
        getAlternateToken: auth.getAlternateToken,
        onUnauthorized: auth.logout,
      },
      config,
    );
    this.client = client;
    auth.setApolloClient(this.client); // @TODO - we may move this somewhere else
    return client;
  }
}
