import React, { memo } from 'react';
import PropTypes from 'prop-types';

const KeyColor = ({ color, insideColor, keyBorderColor, ...props }) => (
  <svg viewBox="0 0 53 53" {...props} fill={color}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={insideColor}
        d="M16.667 21.333C25.135 21.333 32 28.198 32 36.667 32 45.135 25.135 52 16.667 52 8.198 52 1.333 45.135 1.333 36.667c0-8.469 6.865-15.334 15.334-15.334Zm-4 16a3.333 3.333 0 1 0 0 6.667 3.333 3.333 0 0 0 0-6.667Z"
      />
      <path
        fill={keyBorderColor}
        d="M52.222 0c.614 0 1.111.497 1.111 1.111v6.667c0 .294-.117.577-.325.785l-1.897 1.895v2.875c0 .546-.393 1-.911 1.094l-.2.017h-2.876l-.457.458v2.876c0 .545-.393.999-.912 1.093l-.2.018H42.68l-.458.458v2.875c0 .546-.393 1-.911 1.093l-.2.018h-2.875l-6.416 6.418.251.573a16.53 16.53 0 0 1 1.246 5.611l.016.732c0 9.205-7.462 16.666-16.666 16.666C7.46 53.333 0 45.873 0 36.667 0 27.462 7.462 20 16.667 20c2.201 0 4.346.435 6.345 1.264l.57.25L44.77.324c.167-.166.38-.275.61-.311L45.557 0Zm-1.11 2.222h-5.099L24.595 23.646a1.111 1.111 0 0 1-1.109.277l-.19-.077a14.341 14.341 0 0 0-6.63-1.624c-7.977 0-14.444 6.467-14.444 14.445 0 7.979 6.466 14.444 14.445 14.444 7.976 0 14.444-6.466 14.444-14.444 0-2.331-.562-4.585-1.622-6.629a1.111 1.111 0 0 1 .2-1.297l7.303-7.304c.208-.209.491-.326.786-.326H40V18.89c0-.236.075-.464.212-.652l.113-.134 1.112-1.11c.208-.21.49-.326.785-.326h2.222v-2.223c0-.235.075-.463.212-.652l.114-.133 1.111-1.111c.208-.209.491-.326.786-.326h2.222V10c0-.236.075-.464.211-.652l.114-.134 1.897-1.898V2.222Zm-38.89 34.445a4.446 4.446 0 0 1 0 8.889 4.444 4.444 0 1 1 0-8.89Zm0 2.222a2.223 2.223 0 0 0 0 4.444 2.222 2.222 0 1 0 0-4.444Z"
      />
    </g>
  </svg>
);

KeyColor.defaultProps = {
  width: 53,
  height: 53,
  color: 'currentColor',
  insideColor: '#7FE3FF',
  keyBorderColor: '#042238',
};

KeyColor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(KeyColor);
