import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../components/Condition';
import DropdownSubtextMenu from '../../../../components/DropdownSubtextMenu';
import Input from '../../../../components/Input';
import MultiCondition from '../../../../components/MultiCondition';
import PropertyAddressFields from '../../../../components/PropertyAddressFields';
import RadioGroup from '../../../../components/RadioGroup';
import { Tooltip } from '../../../../components/Tooltip';
import isSinglePropertyType from '../../../../helpers/isSinglePropertyType';
import PropertyRolesEnum from '../../../../helpers/propertyRolesEnum';
import PropertyThickIcon from '../../../../icons/PropertyThick';
import validShareAbleAddress from '../../../../validators/validShareAbleAddress';
import validShareAbleCity from '../../../../validators/validShareAbleCity';
import validShareAbleUnit from '../../../../validators/validShareAbleUnit';
import SectionTitle from '../../common/SectionTitle';
import RoomSpecificsFields from '../../sections/SectionLeaseSpecifics/RoomSpecificsFields';
import { propertyTypeDropdownOptions } from '../helpers/propertyTypeDropdownOptions';
import { calculatePropertyRole } from '../StandaloneLeaseAgreement/helpers/calculatePropertyRole';

import styles from './PropertyDetailsPublicLA.module.scss';

const PropertyDetailsPublicLA = ({ className }) => {
  const {
    input: { value: chosenPropertyType },
  } = useField('propertyDetails.property_type');

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Property Address" icon={PropertyThickIcon} />
      <Field
        label="Property Type"
        id="propertyDetails.property_type"
        name="propertyDetails.property_type"
      >
        {(props) => (
          <DropdownSubtextMenu options={propertyTypeDropdownOptions} {...props}>
            <DropdownSubtextMenu.Trigger />
            <DropdownSubtextMenu.Content>
              {(options) =>
                options.map((option) => (
                  <DropdownSubtextMenu.Item
                    key={option.value}
                    option={option}
                  />
                ))
              }
            </DropdownSubtextMenu.Content>
          </DropdownSubtextMenu>
        )}
      </Field>
      <PropertyAddressFields
        excludeTerritories
        validators={{
          city: [validShareAbleCity],
          unit: [validShareAbleUnit],
          address: [validShareAbleAddress],
        }}
        fieldNames={{
          address: 'propertyDetails.address',
          city: 'propertyDetails.city',
          state: 'propertyDetails.state',
          zip: 'propertyDetails.zip',
          unit: 'propertyDetails.unit',
        }}
        showUnit={isSinglePropertyType(chosenPropertyType)}
        className={styles.propertyAddressFields}
      />
      <Field
        component={RadioGroup}
        id="propertyDetails.roomRentals"
        name="propertyDetails.roomRentals"
        label={
          <div className={styles.roomRentalsWrapper}>
            Will you have room rentals?{' '}
            <span
              className={styles.learnMore}
              data-tooltip-id="learn-more-key-tip"
            >
              Learn more
            </span>
            <Tooltip id="learn-more-key-tip">
              Room rentals are when you're renting out rooms separately within
              the property, each with their own lease.
            </Tooltip>
          </div>
        }
        options={[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ]}
      />
      <MultiCondition
        condition={({ propertyDetails }) =>
          isSinglePropertyType(propertyDetails.property_type) &&
          propertyDetails.roomRentals
        }
      >
        <div className={styles.unitRoomContainer}>
          <div className={styles.sectionTitle}>Add Room</div>
          <p className={styles.sectionDescription}>
            Start with one room, even if you have more. You'll be able to set up
            the rest later.
          </p>
          <Field
            id={`propertyDetails.room_name`}
            name={`propertyDetails.room_name`}
            component={Input}
            label="Room Name"
            maxLength={50}
          />
        </div>
      </MultiCondition>
      <MultiCondition
        condition={({ propertyDetails }) =>
          isSinglePropertyType(propertyDetails.property_type) === false
        }
      >
        <div className={styles.unitRoomContainer}>
          <div className={styles.sectionTitle}>Add Unit</div>
          <p className={styles.sectionDescription}>
            Start with one unit, even if you have more. You'll be able to set up
            the rest later.
          </p>
          <Field
            id={`propertyDetails.unit_name`}
            name={`propertyDetails.unit_name`}
            className={styles.field}
            component={Input}
            label="Unit #"
            maxLength={50}
            placeholder="Address 2 (Apt, Suite, etc.)"
          />
          <Condition when="propertyDetails.roomRentals" is={true}>
            <Field
              id={`propertyDetails.room_name`}
              name={`propertyDetails.room_name`}
              component={Input}
              label="Room Name"
              maxLength={50}
            />
          </Condition>
        </div>
      </MultiCondition>
      <MultiCondition
        condition={({ propertyDetails }) =>
          calculatePropertyRole({
            property_type: propertyDetails.property_type,
            room_name: propertyDetails.room_name,
          }) === PropertyRolesEnum.ROOM && propertyDetails.roomRentals
        }
      >
        <RoomSpecificsFields />
      </MultiCondition>
    </div>
  );
};

PropertyDetailsPublicLA.propTypes = {
  className: PropTypes.string,
};

export default PropertyDetailsPublicLA;
