/* eslint-disable */

/*! modernizr 3.5.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-csstransforms3d-prefixed-setclasses !*/

!(function (e, n, t) {
  function r(e) {
    return e
      .replace(/([a-z])-([a-z])/g, function (e, n, t) {
        return n + t.toUpperCase();
      })
      .replace(/^-/, '');
  }
  function s(e) {
    let n = w.className,
      t = Modernizr._config.classPrefix || '';
    if ((_ && (n = n.baseVal), Modernizr._config.enableJSClass)) {
      const r = new RegExp('(^|\\s)' + t + 'no-js(\\s|$)');
      n = n.replace(r, '$1' + t + 'js$2');
    }
    Modernizr._config.enableClasses &&
      ((n += ' ' + t + e.join(' ' + t)),
      _ ? (w.className.baseVal = n) : (w.className = n));
  }
  function i(e, n) {
    return typeof e === n;
  }
  function o() {
    let e, n, t, r, s, o, a;
    for (const l in S)
      if (S.hasOwnProperty(l)) {
        if (
          ((e = []),
          (n = S[l]),
          n.name &&
            (e.push(n.name.toLowerCase()),
            n.options && n.options.aliases && n.options.aliases.length))
        )
          for (t = 0; t < n.options.aliases.length; t++)
            e.push(n.options.aliases[t].toLowerCase());
        for (r = i(n.fn, 'function') ? n.fn() : n.fn, s = 0; s < e.length; s++)
          (o = e[s]),
            (a = o.split('.')),
            1 === a.length
              ? (Modernizr[a[0]] = r)
              : (!Modernizr[a[0]] ||
                  Modernizr[a[0]] instanceof Boolean ||
                  (Modernizr[a[0]] = new Boolean(Modernizr[a[0]])),
                (Modernizr[a[0]][a[1]] = r)),
            C.push((r ? '' : 'no-') + a.join('-'));
      }
  }
  function a(e, n) {
    return !!~('' + e).indexOf(n);
  }
  function l() {
    return 'function' != typeof n.createElement
      ? n.createElement(arguments[0])
      : _
        ? n.createElementNS.call(n, 'http://www.w3.org/2000/svg', arguments[0])
        : n.createElement.apply(n, arguments);
  }
  function f(e, n) {
    return function () {
      return e.apply(n, arguments);
    };
  }
  function u(e, n, t) {
    let r;
    for (const s in e)
      if (e[s] in n)
        return t === !1
          ? e[s]
          : ((r = n[e[s]]), i(r, 'function') ? f(r, t || n) : r);
    return !1;
  }
  function p() {
    let e = n.body;
    return e || ((e = l(_ ? 'svg' : 'body')), (e.fake = !0)), e;
  }
  function d(e, t, r, s) {
    let i,
      o,
      a,
      f,
      u = 'modernizr',
      d = l('div'),
      c = p();
    if (parseInt(r, 10))
      for (; r--; )
        (a = l('div')), (a.id = s ? s[r] : u + (r + 1)), d.appendChild(a);
    return (
      (i = l('style')),
      (i.type = 'text/css'),
      (i.id = 's' + u),
      (c.fake ? c : d).appendChild(i),
      c.appendChild(d),
      i.styleSheet
        ? (i.styleSheet.cssText = e)
        : i.appendChild(n.createTextNode(e)),
      (d.id = u),
      c.fake &&
        ((c.style.background = ''),
        (c.style.overflow = 'hidden'),
        (f = w.style.overflow),
        (w.style.overflow = 'hidden'),
        w.appendChild(c)),
      (o = t(d, e)),
      c.fake
        ? (c.parentNode.removeChild(c), (w.style.overflow = f), w.offsetHeight)
        : d.parentNode.removeChild(d),
      !!o
    );
  }
  function c(n, t, r) {
    let s;
    if ('getComputedStyle' in e) {
      s = getComputedStyle.call(e, n, t);
      const i = e.console;
      if (null !== s) r && (s = s.getPropertyValue(r));
      else if (i) {
        const o = i.error ? 'error' : 'log';
        i[o].call(
          i,
          'getComputedStyle returning null, its possible modernizr test results are inaccurate',
        );
      }
    } else s = !t && n.currentStyle && n.currentStyle[r];
    return s;
  }
  function m(e) {
    return e
      .replace(/([A-Z])/g, function (e, n) {
        return '-' + n.toLowerCase();
      })
      .replace(/^ms-/, '-ms-');
  }
  function v(n, r) {
    let s = n.length;
    if ('CSS' in e && 'supports' in e.CSS) {
      for (; s--; ) if (e.CSS.supports(m(n[s]), r)) return !0;
      return !1;
    }
    if ('CSSSupportsRule' in e) {
      for (var i = []; s--; ) i.push('(' + m(n[s]) + ':' + r + ')');
      return (
        (i = i.join(' or ')),
        d(
          '@supports (' + i + ') { #modernizr { position: absolute; } }',
          function (e) {
            return 'absolute' == c(e, null, 'position');
          },
        )
      );
    }
    return t;
  }
  function h(e, n, s, o) {
    function f() {
      p && (delete L.style, delete L.modElem);
    }
    if (((o = i(o, 'undefined') ? !1 : o), !i(s, 'undefined'))) {
      const u = v(e, s);
      if (!i(u, 'undefined')) return u;
    }
    for (
      var p, d, c, m, h, g = ['modernizr', 'tspan', 'samp'];
      !L.style && g.length;

    )
      (p = !0), (L.modElem = l(g.shift())), (L.style = L.modElem.style);
    for (c = e.length, d = 0; c > d; d++)
      if (
        ((m = e[d]),
        (h = L.style[m]),
        a(m, '-') && (m = r(m)),
        L.style[m] !== t)
      ) {
        if (o || i(s, 'undefined')) return f(), 'pfx' == n ? m : !0;
        try {
          L.style[m] = s;
        } catch (y) {}
        if (L.style[m] != h) return f(), 'pfx' == n ? m : !0;
      }
    return f(), !1;
  }
  function g(e, n, t, r, s) {
    let o = e.charAt(0).toUpperCase() + e.slice(1),
      a = (e + ' ' + E.join(o + ' ') + o).split(' ');
    return i(n, 'string') || i(n, 'undefined')
      ? h(a, n, r, s)
      : ((a = (e + ' ' + T.join(o + ' ') + o).split(' ')), u(a, n, t));
  }
  function y(e, n, r) {
    return g(e, t, t, n, r);
  }
  var C = [],
    S = [],
    x = {
      _version: '3.5.0',
      _config: {
        classPrefix: '',
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0,
      },
      _q: [],
      on: function (e, n) {
        const t = this;
        setTimeout(function () {
          n(t[e]);
        }, 0);
      },
      addTest: function (e, n, t) {
        S.push({ name: e, fn: n, options: t });
      },
      addAsyncTest: function (e) {
        S.push({ name: null, fn: e });
      },
    },
    Modernizr = function () {};
  (Modernizr.prototype = x), (Modernizr = new Modernizr());
  var w = n.documentElement,
    _ = 'svg' === w.nodeName.toLowerCase(),
    b = 'CSS' in e && 'supports' in e.CSS,
    P = 'supportsCSS' in e;
  Modernizr.addTest('supports', b || P);
  var z = 'Moz O ms Webkit',
    E = x._config.usePrefixes ? z.split(' ') : [];
  x._cssomPrefixes = E;
  const N = function (n) {
    let r,
      s = prefixes.length,
      i = e.CSSRule;
    if ('undefined' == typeof i) return t;
    if (!n) return !1;
    if (
      ((n = n.replace(/^@/, '')),
      (r = n.replace(/-/g, '_').toUpperCase() + '_RULE'),
      r in i)
    )
      return '@' + n;
    for (let o = 0; s > o; o++) {
      const a = prefixes[o],
        l = a.toUpperCase() + '_' + r;
      if (l in i) return '@-' + a.toLowerCase() + '-' + n;
    }
    return !1;
  };
  x.atRule = N;
  var T = x._config.usePrefixes ? z.toLowerCase().split(' ') : [];
  x._domPrefixes = T;
  const k = (x.testStyles = d),
    j = { elem: l('modernizr') };
  Modernizr._q.push(function () {
    delete j.elem;
  });
  var L = { style: j.elem.style };
  Modernizr._q.unshift(function () {
    delete L.style;
  }),
    (x.testAllProps = g);
  x.prefixed = function (e, n, t) {
    return 0 === e.indexOf('@')
      ? N(e)
      : (-1 != e.indexOf('-') && (e = r(e)), n ? g(e, n, t) : g(e, 'pfx'));
  };
  (x.testAllProps = y),
    Modernizr.addTest('csstransforms3d', function () {
      let e = !!y('perspective', '1px', !0),
        n = Modernizr._config.usePrefixes;
      if (e && (!n || 'webkitPerspective' in w.style)) {
        let t,
          r = '#modernizr{width:0;height:0}';
        Modernizr.supports
          ? (t = '@supports (perspective: 1px)')
          : ((t = '@media (transform-3d)'),
            n && (t += ',(-webkit-transform-3d)')),
          (t +=
            '{#modernizr{width:7px;height:18px;margin:0;padding:0;border:0}}'),
          k(r + t, function (n) {
            e = 7 === n.offsetWidth && 18 === n.offsetHeight;
          });
      }
      return e;
    }),
    o(),
    s(C),
    delete x.addTest,
    delete x.addAsyncTest;
  for (let A = 0; A < Modernizr._q.length; A++) Modernizr._q[A]();
  e.Modernizr = Modernizr;
})(window, document);
