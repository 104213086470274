import React, { memo } from 'react';

const LeaseAddendum = (props) => (
  <svg viewBox="0 0 36 40" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill={props.color} d="m20 0 9 9h-9z" />
      <circle fill={props.color} cx="24" cy="31" r="8" />
      <path
        d="M21.354 0c.19 0 .373.06.525.17l.108.092 7.67 7.637c.028.028.048.05.067.074l-.066-.074a.905.905 0 0 1 .252.482l.004.02.01.143V19.46c0 .501-.402.907-.9.907a.902.902 0 0 1-.885-.744l-.014-.163-.002-10.009h-8.047a.902.902 0 0 1-.885-.743l-.015-.163V1.813H1.8v36.373h10.084c.442 0 .81.321.886.744l.014.163a.905.905 0 0 1-.738.892l-.162.015H.9a.902.902 0 0 1-.885-.744L0 39.093V.907C0 .462.318.09.738.015L.9 0h20.454ZM33.07 28.754l.111.136 2.666 3.984a.911.911 0 0 1-.243 1.26.895.895 0 0 1-1.147-.119l-.102-.127-1.058-1.581c-.617 3.483-3.34 6.498-6.92 7.346a9.01 9.01 0 0 1-8.425-2.37.912.912 0 0 1-.013-1.282.895.895 0 0 1 1.273-.012 7.22 7.22 0 0 0 6.753 1.899c2.912-.69 5.124-3.184 5.577-6.01l-1.485 1.785a.896.896 0 0 1-1.135.205l-.133-.094a.912.912 0 0 1-.204-1.143l.093-.134 2.99-3.591a.909.909 0 0 1 .115-.134l-.037.042a.896.896 0 0 1 1.324-.06Zm-2.004-3.93a.912.912 0 0 1-.074 1.28.895.895 0 0 1-1.271-.076 7.214 7.214 0 0 0-7.086-2.255c-3.396.805-5.81 3.915-5.837 7.293l1.747-2.096a.896.896 0 0 1 1.135-.205l.133.094c.339.286.415.77.204 1.143l-.093.134-3.067 3.683a.896.896 0 0 1-1.324.06l-.111-.136-2.667-3.984a.911.911 0 0 1 .243-1.259.895.895 0 0 1 1.147.118l.102.127.822 1.23c.483-3.77 3.308-7.056 7.153-7.967a9.005 9.005 0 0 1 8.844 2.815ZM9.614 30.548c.497 0 .9.406.9.907a.905.905 0 0 1-.739.892l-.161.015h-3.6a.903.903 0 0 1-.9-.907c0-.445.318-.815.738-.892l.162-.015h3.6Zm0-5.091c.497 0 .9.406.9.906a.905.905 0 0 1-.739.893l-.161.014h-3.6a.903.903 0 0 1-.9-.907c0-.445.318-.815.738-.892l.162-.014h3.6Zm10.4-8.544c.496 0 .9.406.9.906a.905.905 0 0 1-.739.893l-.162.014h-14a.903.903 0 0 1-.9-.907c0-.445.319-.815.739-.892l.162-.014h14Zm0-5.092c.496 0 .9.406.9.907a.905.905 0 0 1-.739.892l-.162.015h-14a.903.903 0 0 1-.9-.907c0-.445.319-.816.739-.892l.162-.015h14Zm-7.671-5.091c.497 0 .9.405.9.906a.905.905 0 0 1-.738.892l-.162.015h-6.33a.903.903 0 0 1-.9-.907c0-.445.319-.815.739-.892l.162-.014h6.329Zm8.643-4.917-.01-.001v5.824h5.858l-5.848-5.823Z"
        fill="#042238"
      />
    </g>
  </svg>
);

LeaseAddendum.defaultProps = {
  width: 36,
  height: 40,
  color: '#7FE3FF',
};

export default memo(LeaseAddendum);
