import React, { useEffect } from 'react';
import { FORM_ERROR } from 'final-form';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import MarketingImg from '../../../../../assets/marketing/product-overview.png';
import SidebarMarketing from '../../../../../components/SidebarMarketing';
import SignupForm from '../../../../../components/SignupForm/partners/AppSumo';
import {
  COOKIE_NAMES,
  getCookies,
  setCookie,
} from '../../../../../helpers/cookies';
import authPage from '../../../../../hoc/auth-page';
import MarketingLayout from '../../../../../layout/MarketingLayout';
import { segmentTracking } from '../../../../../services/utilities';
import useSignupMutation from '../../useSignupMutation';

import AppSumoLogo from './appSumoLogo.svg';

import styles from './SignupPage.module.scss';

const SignupPage = ({ location, history }) => {
  const signup = useSignupMutation(location);

  const { appsumo } = getCookies();

  useEffect(() => {
    if (!appsumo || Number(appsumo) !== 1) {
      const forever = 1000 * 60 * 60 * 24 * 365 * 12;
      setCookie(COOKIE_NAMES.APP_SUMO, 1, forever);
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      await signup(data);
      history.push('/onboarding/process');
    } catch (error) {
      const parsedError = get(error, `graphQLErrors[0]`) || {};
      if (parsedError && parsedError.code === 471) {
        // return email error
        return {
          email: parsedError.message,
        };
      }
      if (parsedError && parsedError.code === 500) {
        return {
          [FORM_ERROR]: 'Something went wrong, please try again',
        };
      }
      const message = parsedError.message || 'Something went wrong';
      return {
        [FORM_ERROR]: message,
      };
    }
  };
  return (
    <MarketingLayout
      onLogoClick={() =>
        segmentTracking('tt_logo clicked', { location: 'Landlord Signup' })
      }
      side={
        <SidebarMarketing
          title="Advertising, Screening, And More"
          subtitle="As an independent landlord, manage your rental yourself."
          graphic={MarketingImg}
        />
      }
      partnerLogo={AppSumoLogo}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.title}>
            Sign up for TurboTenant with your{' '}
            <span className={styles.highlighted}>AppSumo code</span>!
          </h2>
          <SignupForm onSubmit={onSubmit} />
        </div>
      </div>
    </MarketingLayout>
  );
};

SignupPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default authPage(SignupPage);
