const validPasswordStrength = (value) => {
  if (!value) {
    return true;
  }

  const password = value.trim();

  const hasLength = password.length >= 8;
  const hasLowercase = /[a-z]/.test(password);
  const hasUppercase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[^a-zA-Z0-9\s]/.test(password);

  const isValid =
    hasLength && hasLowercase && hasUppercase && hasNumber && hasSpecialChar;

  if (!isValid) {
    return true;
  }
  return undefined;
};

export default validPasswordStrength;
