import React, { memo } from 'react';

export const CheckFilled = (props) => (
  <svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M16.6666 5L7.49992 14.1667L3.33325 10"
      stroke={props.color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

CheckFilled.defaultProps = {
  color: 'currentColor',
  width: 20,
  height: 20,
};

export default memo(CheckFilled);
