import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Key = (props) => (
  <svg {...props} viewBox="0 0 48 48" fill={props.color}>
    <path d="M46.361.667c.537 0 .972.435.972.972v5.833a.972.972 0 01-.284.688l-1.66 1.657v2.516a.973.973 0 01-.798.957l-.174.016H41.9l-.401.4v2.516a.973.973 0 01-.797.957l-.175.015h-2.516l-.4.401v2.516a.973.973 0 01-.798.957l-.175.015h-2.516L28.509 26.7l.22.501a14.464 14.464 0 011.09 4.91l.014.64c0 8.055-6.53 14.583-14.583 14.583C7.195 47.333.667 40.805.667 32.75c0-8.054 6.529-14.583 14.583-14.583 1.926 0 3.803.38 5.552 1.105l.5.219L39.84.95a.972.972 0 01.535-.272l.153-.012zM45.39 2.61h-4.46L22.186 21.357a.972.972 0 01-.97.243l-.166-.068a12.549 12.549 0 00-5.801-1.42c-6.98 0-12.639 5.658-12.639 12.638 0 6.982 5.658 12.639 12.639 12.639 6.98 0 12.639-5.658 12.639-12.639 0-2.04-.492-4.012-1.42-5.8a.972.972 0 01.176-1.135l6.39-6.391a.972.972 0 01.687-.285h1.945v-1.945c0-.206.065-.405.185-.57l.1-.117.972-.972a.972.972 0 01.687-.285h1.945v-1.944c0-.207.065-.406.185-.571l.1-.117.972-.972a.972.972 0 01.687-.285h1.944V9.417c0-.207.066-.406.186-.571l.1-.117 1.659-1.661V2.61zM11.36 32.75a3.89 3.89 0 11-.002 7.78 3.89 3.89 0 01.002-7.78zm0 1.944a1.946 1.946 0 10.002 3.891 1.946 1.946 0 00-.002-3.89z" />
  </svg>
);

Key.defaultProps = {
  width: 48,
  height: 48,
  color: colors.text,
};

Key.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Key);
