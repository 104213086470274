import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import DollarCircledIcon from '../../../../../../../icons/DollarCircled';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './FinancialDistressDisclosureMN.module.scss';

const FinancialDistressDisclosureMN = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle
        title="Financial Distress Disclosure"
        icon={DollarCircledIcon}
      />
      <label className={styles.label}>
        Is there a pending foreclosure on the premises?
      </label>
      <p className={styles.description}>
        Minnesota law requires that you disclose this.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.financial_distress_disclosure"
        name="custom_state_data.financial_distress_disclosure"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'financial_distress_disclosure_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'financial_distress_disclosure_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition
        when="custom_state_data.financial_distress_disclosure"
        is={true}
      >
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.financial_distress_disclosure_explanation"
          name="custom_state_data.financial_distress_disclosure_explanation"
          label="The property has a pending deed cancellation set to execute on the date set forth below.  Note that until further notice, this lease agreement will terminate on the below stated date."
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      </Condition>
    </div>
  );
};

FinancialDistressDisclosureMN.propTypes = {
  className: PropTypes.string,
};

export default FinancialDistressDisclosureMN;
