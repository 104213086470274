import { keyTypes } from '../constants';

import PropertyRolesEnum from './propertyRolesEnum';

function getUtilitiesKeyTypes(propertyRole) {
  const utilitiesKeyTypes =
    propertyRole === PropertyRolesEnum.ROOM
      ? keyTypes.toSpliced(keyTypes.length - 1, 0, {
          label: 'Room Key',
          value: 'Room Key',
        })
      : keyTypes;

  return utilitiesKeyTypes;
}

export default getUtilitiesKeyTypes;
