import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Tombstone = ({ color, ...props }) => (
  <svg viewBox="0 0 24 28" {...props} fill={color}>
    <path
      d="m21.86 6.637.007.252v13.626a3.535 3.535 0 0 1 2.128 3.072l.005.198c0 2.525-1.693 3.506-3.748 3.546l-16.215-.004a4.61 4.61 0 0 1-2.008-.345C.782 26.462 0 25.393 0 23.785c0-1.495.895-2.743 2.133-3.27V6.888c0-8.212 19.333-8.295 19.727-.252ZM12 16.427c-1.175 0-2.221.714-2.686 1.799a.796.796 0 0 1-.914.468c-1.721-.41-3.345.786-3.56 2.536a.802.802 0 0 1-.968.696C2.695 21.66 1.6 22.57 1.6 23.785c0 .909.361 1.403 1.034 1.684.462.193.97.245 1.358.224l16.068.002.174.002c1.285-.026 2.166-.536 2.166-1.912 0-1.215-1.096-2.126-2.273-1.859a.802.802 0 0 1-.968-.696c-.215-1.751-1.838-2.947-3.56-2.536a.796.796 0 0 1-.915-.469c-.464-1.084-1.51-1.798-2.684-1.798ZM3.74 6.675l-.007.214v12.563c.822-1.612 2.528-2.627 4.387-2.456l.022.002.032-.053a4.512 4.512 0 0 1 3.59-2.147l.236-.006c1.58 0 3.008.835 3.825 2.153l.03.052.25-.017c1.771-.072 3.373.925 4.162 2.472V6.889C20.267.845 4.12.774 3.74 6.675Zm8.292.501c.405 0 .74.308.793.707l.007.11v4.361a.809.809 0 0 1-.8.817.806.806 0 0 1-.792-.706l-.008-.111v-4.36c0-.452.358-.818.8-.818Zm3.676 0c1.26 0 1.819.754 1.819 1.908 0 1.096-.505 1.831-1.635 1.902l-.184.005h-.221v1.363a.812.812 0 0 1-.691.81l-.109.007a.806.806 0 0 1-.792-.706l-.008-.111v-4.36c0-.414.301-.756.692-.81l.108-.008h1.021Zm-7.414 0c1.26 0 1.818.754 1.818 1.908 0 .721-.218 1.286-.69 1.609l.612 1.309a.825.825 0 0 1-.377 1.09.79.79 0 0 1-1.013-.289l-.054-.097-.518-1.108v.756a.812.812 0 0 1-.69.81l-.11.007a.806.806 0 0 1-.792-.706l-.007-.111v-4.36c0-.414.3-.756.691-.81l.109-.008h1.02Zm.164 1.631-.164.004h-.222v.545h.222c.185 0 .218.03.221-.066v-.412c-.002-.056-.013-.07-.057-.07Zm7.414 0-.164.004h-.221v.545h.221c.185 0 .218.03.222-.066v-.412c-.002-.055-.014-.07-.058-.07Z"
      fillRule="evenodd"
    />
  </svg>
);

Tombstone.defaultProps = {
  width: 24,
  height: 28,
  color: 'currentColor',
};

Tombstone.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Tombstone);
