import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import TriangleError from '../../../icons/TriangleError';

import styles from './AppleSigninModal.module.scss';

const AppleSigninModal = ({ onClose }) => (
  <Modal
    open={true}
    className={styles.appleModal}
    containerClassName={styles.modalContainer}
    onClose={onClose}
  >
    <div className={styles.iconContainer}>
      <TriangleError width={58} height={58} />
    </div>
    <p className={styles.content}>No account associated with your Apple ID</p>
    <Button style={{ maxWidth: 232, marginBottom: 16 }} onClick={onClose}>
      Try Another Log In
    </Button>
    <Button
      style={{ maxWidth: 232 }}
      secondary={true}
      onClick={onClose}
      to={`/auth/signup`}
    >
      Create a new account
    </Button>
  </Modal>
);

AppleSigninModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AppleSigninModal;
