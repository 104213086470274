import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import { isAdminLogin } from '../core/auth/authService';
import { isIntercomAllowedRoute } from '../services/utilities';

const IntercomSetUp = () => {
  const history = useHistory();

  useEffect(() => {
    if (window.analytics) {
      window.analytics.ready(function () {
        if (window.Intercom) {
          window.Intercom('update', {
            hide_default_launcher:
              isAdminLogin() ||
              !isIntercomAllowedRoute(history.location.pathname) ||
              isMobile,
          });
          if (isAdminLogin()) {
            // Intercom should be totally disabled when logged in as admin
            window.Intercom('shutdown');
          }
        }
      });
    }

    history.listen(({ pathname: newPathname }) => {
      if (window.Intercom) {
        window.Intercom('update', {
          hide_default_launcher:
            isAdminLogin() || !isIntercomAllowedRoute(newPathname) || isMobile,
        });
        if (isAdminLogin()) {
          // Intercom should be totally disabled when logged in as admin
          window.Intercom('shutdown');
        }
      }
    });
  }, []);

  return <></>;
};

export default IntercomSetUp;
