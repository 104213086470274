import React, { memo } from 'react';
import PropTypes from 'prop-types';

const PremiumBanner = ({ color, starColor, ...props }) => (
  <svg viewBox="0 0 17 24" {...props}>
    <g fillRule="evenodd">
      <path fill={color} d="m17 24-8.5-5.739L0 24V0h17z" />
      <path
        fill={starColor}
        d="M8.5 4.174 10 7.826h4l-3 2.609 1 4.174L8.5 12 5 14.609l1-4.174-3-2.609h4z"
      />
    </g>
  </svg>
);

PremiumBanner.defaultProps = {
  width: 17,
  height: 24,
  color: '#00c8ff',
  starColor: '#fff',
};

PremiumBanner.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  starColor: PropTypes.string,
};

export default memo(PremiumBanner);
