import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import Input from '../../../../../../../components/Input';
import resources from '../../../../../../../icons/resources';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredShort from '../../../../../../../validators/fieldRequiredShort';
import SectionInfoBlock from '../../../../../common/SectionInfoBlock';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './BankInfo.module.scss';

const bankNameValidator = (value, allValues) =>
  allValues.deposit_bank_address ? fieldRequiredShort(value) : undefined;

const bankAddressValidator = (value, allValues) =>
  allValues.deposit_bank_name ? fieldRequiredShort(value) : undefined;

const BankInfo = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();

  // NOTE: Perhaps bankNameValidator and bankAddressValidator are unnecessary.
  // This could be simplified to just use fieldRequiredShort if we discover this is the case.
  const validatorBankName = conditionalValidation(
    bankNameValidator,
    !standAloneWizard,
  );

  const validatorBankAddress = conditionalValidation(
    bankAddressValidator,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <SectionInfoBlock className={styles.infoBlock}>
          <strong>Pennsylvania Law:</strong> On the third year of a lease,
          landlords are required to provide the location of the depository /
          bank where the security deposit is held.
        </SectionInfoBlock>
        <HelpfulInfoCard
          Icon={resources}
          className={styles.helpfulInfoCard}
          title="Pennsylvania Security Deposit Laws"
          collapsable
          color="iceCold"
        >
          <HelpfulInfoList>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The maximum amount you can charge for a security deposit is 2
              month's rent for new rentals. Any increase in security deposit
              requirements for tenants renewing for 2nd - 4th year is capped at
              1 mo. rent and no security deposit increases allowed for tenant
              after renting for 5 years and beyond.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              You must place the security deposit into a separate bank account
              for the full term of lease.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The bank name and location must be shown in the lease.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The security deposit must be returned to tenant by the 30th day
              after obtaining possession of the premises.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              If you are retaining any portion of the security deposit, you need
              to provide tenant with a written itemization of any lawful
              charges.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The itemization and the security deposit balance needs to be
              mailed to tenant’s last known address via first-class mail.
            </HelpfulInfoListItem>
          </HelpfulInfoList>
        </HelpfulInfoCard>
        <Field
          label="Bank / Depository Name"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_name"
          name="deposit_bank_name"
          component={Input}
          validate={validatorBankName}
        />
        <Field
          label="Bank / Depository Address"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_address"
          name="deposit_bank_address"
          component={Input}
          validate={validatorBankAddress}
        />
      </div>
    </div>
  );
};

BankInfo.propTypes = {
  className: PropTypes.string,
};

export default BankInfo;
