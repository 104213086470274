import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Calculator = ({ color, secondaryColor, ...rest }) => (
  <svg viewBox="0 0 40 40" {...rest}>
    {secondaryColor && (
      <path
        d="M29.167 6.666H10.833v5.833h18.334V6.666Z"
        fill={secondaryColor}
      />
    )}
    <path
      d="M29.123 3.334c2.316 0 4.21 1.874 4.21 4.171v24.991c0 2.297-1.894 4.171-4.21 4.171H10.877c-2.316 0-4.21-1.874-4.21-4.17V7.504c0-2.297 1.894-4.171 4.21-4.171h18.246ZM19.297 25.56H8.07v6.936c0 1.47 1.18 2.687 2.654 2.767l.153.004h8.42V25.56Zm12.632 0H20.702v9.707h8.42c1.493 0 2.723-1.168 2.803-2.62l.005-.15-.001-6.937Zm-19.963 2.02 1.73 1.727 1.709-1.69.988.995-1.705 1.685 1.707 1.705-.993.99-1.71-1.708-1.729 1.71-.988-.995 1.724-1.706-1.726-1.723.992-.99Zm17.157 3.522v1.4h-5.614v-1.4h5.614Zm0-2.777v1.4h-5.614v-1.4h5.614Zm-9.826-12.483H8.07v8.317h11.227v-8.317Zm12.632 0H20.702v8.317h11.227v-8.317Zm-18.245 1.382V19.3h2.105v1.4h-2.105v2.077h-1.403l-.001-2.076h-2.105v-1.4l2.105-.001v-2.076h1.404Zm15.439 2.77v1.401h-5.614v-1.4h5.614Zm0-15.26H10.877c-1.544 0-2.807 1.25-2.807 2.771v6.936h23.859V7.505c0-1.47-1.18-2.687-2.653-2.766l-.153-.004Zm-.555 1.377c1.078 0 1.958.87 1.958 1.943v3.07c0 1.07-.88 1.94-1.958 1.94H11.431a1.952 1.952 0 0 1-1.957-1.94v-3.07c0-1.073.88-1.943 1.957-1.943h17.137Zm0 1.4H11.431a.55.55 0 0 0-.554.543v3.07a.55.55 0 0 0 .554.54h17.137a.55.55 0 0 0 .555-.54v-3.07a.55.55 0 0 0-.555-.543Z"
      fill={color}
    />
  </svg>
);

Calculator.defaultProps = {
  width: 32,
  height: 32,
  color: colors.text,
  secondaryColor: '',
};

Calculator.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
};

export default memo(Calculator);
