import { MODAL_LOCATIONS_DATA } from './constants';

const getSegmentLocation = (location) => {
  if (!location || !MODAL_LOCATIONS_DATA[location]) {
    return 'Standalone Subscription Modal';
  }

  return MODAL_LOCATIONS_DATA[location]?.segmentLocation;
};

export default getSegmentLocation;
