import React, { memo } from 'react';
import PropTypes from 'prop-types';

const DollarCircleBlue = (props) => (
  <svg {...props} viewBox="0 0 40 40">
    <g fill="none" fillRule="evenodd">
      <path
        d="M26.5 36C33.956 36 40 27.956 40 20.5 40 13.044 33.956 5 26.5 5S13 13.044 13 20.5C13 27.956 19.044 36 26.5 36Z"
        fill="#7FE3FF"
      />
      <path
        d="M20 0c11.046 0 20 8.955 20 20 0 11.047-8.953 20-20 20C8.955 40 0 31.046 0 20 0 8.956 8.956 0 20 0Zm0 1.74C9.917 1.74 1.74 9.916 1.74 20c0 10.085 8.176 18.26 18.26 18.26 10.087 0 18.26-8.173 18.26-18.26C38.26 9.916 30.086 1.74 20 1.74Zm0 6.086c.48 0 .87.39.87.87v1.81a5.22 5.22 0 0 1 4.347 5.146.87.87 0 1 1-1.739 0 3.48 3.48 0 0 0-2.608-3.368v6.919a5.22 5.22 0 0 1 0 10.29v1.811a.87.87 0 1 1-1.74 0v-1.81a5.218 5.218 0 0 1-4.347-5.146.87.87 0 1 1 1.739 0 3.479 3.479 0 0 0 2.608 3.368v-6.919a5.218 5.218 0 0 1 0-10.29V8.696c0-.48.39-.87.87-.87Zm.87 13.153v6.737a3.479 3.479 0 0 0 0-6.737Zm-1.74-8.695a3.48 3.48 0 0 0 0 6.737Z"
        fill="#042238"
      />
    </g>
  </svg>
);

DollarCircleBlue.defaultProps = {
  width: 40,
  height: 40,
};

DollarCircleBlue.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(DollarCircleBlue);
