import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import getFieldError from '../../validators/get-field-error';
import Label from '../Label';

import RadioGroupBoxItem from './RadioGroupBoxItem';

import styles from './RadioGroupBox.module.scss';

const RadioGroupBox = ({
  className,
  containerClassName,
  options,
  input,
  disabled,
  radioProps,
  style,
  meta,
  label,
  id,
}) => {
  const error = getFieldError(meta);

  return (
    <div className={cx(styles.wrap, className)} style={style}>
      {label && (
        <Label className={styles.label} htmlFor={id}>
          {label}
        </Label>
      )}
      <div className={cx(styles.container, containerClassName)}>
        {options.map(({ value, label, ...restProps }) => {
          return (
            <RadioGroupBoxItem
              key={`radio-group-box-${value}`}
              id={`${id}-${value}`}
              value={value}
              label={label}
              disabled={disabled}
              className={radioProps.className}
              contentClassName={radioProps.contentClassName}
              onChange={() => {
                input?.onChange(value);
              }}
              onClick={(e) => {
                if (e.target.value === input?.value) {
                  input.onChange(null);
                }
              }}
              checked={input?.value === value}
              name={input?.name}
              {...radioProps}
              {...restProps}
            />
          );
        })}
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

RadioGroupBox.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      value: PropTypes.any,
    }),
  ),
  input: PropTypes.object,
  disabled: PropTypes.bool,
  radioProps: PropTypes.shape({
    contentClassName: PropTypes.string,
    className: PropTypes.string,
  }),
  style: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

RadioGroupBox.defaultProps = {
  radioProps: {},
  meta: {},
};

export default RadioGroupBox;
