import React from 'react';
import { Field, Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FORM_ERROR } from 'final-form';
import get from 'lodash.get';
import queryString from 'query-string';

import Button from '../../../components/Button';
import FinalFormError from '../../../components/FinalFormError';
import Input from '../../../components/Input';
import trimEmail from '../../../components/Input/trimEmail';
import PasswordInput from '../../../components/PasswordInput';
import { login } from '../../../core/auth/authService';
import { useConfig } from '../../../providers/ConfigProvider';
import composeValidators from '../../../validators/composeValidators';
import fieldRequired from '../../../validators/fieldRequired';
import validEmail from '../../../validators/validEmail';
import validPassword from '../../../validators/validPassword';

import updateRenterEmailAndPassword from './updateRenterEmailAndPassword.graphql';

import styles from './ApplicantSetPasswordForOthers.module.scss';

const ApplicantSetPasswordForOthers = ({ location, history }) => {
  const { token, redirectTo } = queryString.parse(location.search);
  const [updateRenterAccount] = useMutation(updateRenterEmailAndPassword);
  const { PRIVATE_BASE_PATH } = useConfig();
  const confirmationToken = token;

  const onSubmit = async ({ email, password }) => {
    try {
      const { data } = await updateRenterAccount({
        variables: {
          confirmationToken,
          email,
          password,
        },
      });

      const token = get(data, 'updateRenterEmailAndPassword.token');
      login(token);
      history.replace(redirectTo || `${PRIVATE_BASE_PATH}dashboard/todos`);
    } catch (error) {
      return {
        [FORM_ERROR]: 'An error has occurred',
      };
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h2 className={styles.title}>Sign Up to Access Your Tenant Portal</h2>
        <Form
          onSubmit={onSubmit}
          subscription={{
            submitting: true,
          }}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <FinalFormError />
              <Field
                label="Email Address"
                component={Input}
                labelProps={{ className: styles.label }}
                className={styles.fields}
                type="email"
                name="email"
                id="email"
                parse={trimEmail}
                validate={composeValidators(validEmail, fieldRequired)}
                inputProps={{
                  autoComplete: 'username',
                }}
              />
              <Field
                label="Password"
                component={PasswordInput}
                hintProps={{ className: styles.hint }}
                labelProps={{ className: styles.label }}
                className={styles.fields}
                name="password"
                id="password"
                validate={composeValidators(fieldRequired, validPassword)}
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
              <Button
                type="submit"
                className={styles.action}
                loading={submitting}
                id="create_account_submit"
              >
                Create Account
              </Button>
              <div className={styles.terms}>
                By clicking the button above you are agreeing to our{' '}
                <Link
                  className={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.turbotenant.com/terms-of-use/"
                >
                  Terms of Use
                </Link>
                {' & '}
                <Link
                  className={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://www.turbotenant.com/privacy-policy/"
                >
                  Privacy Policy
                </Link>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

export default withRouter(ApplicantSetPasswordForOthers);
