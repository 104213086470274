import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Messages = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 25" {...rest} fill={color}>
    <path d="M1.9 3.754a.6.6 0 0 1 .6-.6h15.696a.6.6 0 0 1 .6.6v5.37a.6.6 0 0 1-1.2 0v-4.77H3.1v8.713h1.878a.6.6 0 0 1 .6.6v1.856l2.28-2.28a.6.6 0 0 1 .425-.176h.826a.6.6 0 0 1 0 1.2H8.53l-3.129 3.129a.6.6 0 0 1-1.024-.424v-2.705H2.5a.6.6 0 0 1-.6-.6V3.754Z" />
    <path d="M10.987 11.19a.6.6 0 0 1 .6-.6H21.5a.6.6 0 0 1 .6.6v6.608a.6.6 0 0 1-.6.6h-1.052v2.704a.6.6 0 0 1-.999.449l-3.547-3.153h-4.315a.6.6 0 0 1-.6-.6v-6.609Zm1.2.6v5.408h3.943a.6.6 0 0 1 .399.151l2.719 2.417v-1.968a.6.6 0 0 1 .6-.6H20.9v-5.409h-8.713Z" />
  </svg>
);

Messages.defaultProps = {
  width: 24,
  height: 25,
  color: colors.primary,
};

Messages.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Messages);
