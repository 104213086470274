import React from 'react';
import cx from 'classnames';

import styles from './Grid.module.scss';

export const Row = ({ children, flex, style, gap, reverse, className }) => (
  <div
    className={cx({
      [styles.row]: !flex,
      [styles.flex]: flex,
      [styles.reverse]: reverse,
      [className]: className != null,
    })}
    style={{
      ...style,
      gap: (typeof gap === 'number' ? `${gap}px` : gap) || '16px',
    }}
  >
    {children}
  </div>
);

export const Column = ({ span, xs, sm, md, lg, xl, className, children }) => {
  const classes = [
    span ? styles[`col-${span}`] : styles[`col-${12}`],
    xs ? styles[`col-xs-${xs}`] : '',
    sm ? styles[`col-sm-${sm}`] : '',
    md ? styles[`col-md-${md}`] : '',
    lg ? styles[`col-lg-${lg}`] : '',
    xl ? styles[`col-xl-${xl}`] : '',
    className || '',
  ];

  return <div className={cx(styles.column, ...classes)}>{children}</div>;
};
