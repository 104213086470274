import React from 'react';
import cx from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import useDelayedRender from 'use-delayed-render';

import getFieldError from '../../validators/get-field-error';
import Label from '../Label';
import PlainInputWithIcon from '../PlainInputWithIcon';

import styles from './Input.module.scss';

const FieldError = ({ error, id }) => {
  const { mounted, rendered } = useDelayedRender(error, {
    enterDelay: 300,
  });

  if (!mounted || !rendered) {
    return null;
  }

  return (
    <span id={`${id}_errormsg`} className={styles.error}>
      {error}
    </span>
  );
};

const InputWithIcon = ({
  as,
  input,
  inputProps,
  label,
  hint,
  className,
  id,
  type,
  meta,
  disabled,
  labelProps,
  icon: Icon,
  iconRight,
  ...rest
}) => {
  const error = getFieldError(meta);
  const inputLength = get(input, 'value.length', 0);
  return (
    <div className={cx(styles.container, className)}>
      {label && (
        <Label htmlFor={id} {...labelProps} hint={hint}>
          {label}
        </Label>
      )}
      <PlainInputWithIcon
        icon={Icon}
        as={as}
        type={type}
        id={id}
        disabled={disabled}
        invalid={!!error}
        {...input}
        {...inputProps}
        {...rest}
        iconRight={iconRight}
        onWheel={(e) => {
          if (input.type === 'number') {
            e.currentTarget.blur();
          }
          const onWheel = input.onWheel || inputProps.onWheel;
          if (onWheel) {
            onWheel(e);
          }
        }}
      />
      <FieldError error={error} id={id} />
      {!error && inputProps.maxLength && (
        <span className={styles.help}>
          {`${inputLength} / ${inputProps.maxLength} characters used`}
        </span>
      )}
    </div>
  );
};

InputWithIcon.propTypes = {
  as: PropTypes.string,
  input: PropTypes.object,
  inputProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  id: PropTypes.string,
  meta: PropTypes.object,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelProps: PropTypes.object,
};

InputWithIcon.defaultProps = {
  inputProps: {},
  meta: {},
};

export default InputWithIcon;
