import React from 'react';
import PropTypes from 'prop-types';

const Podcast = ({ width, height, ...rest }) => (
  <svg viewBox="0 0 48 49" fill="none" width={width} height={height} {...rest}>
    <path
      d="M24 34.3035V4.30347C26.8571 4.30347 31.0476 5.50347 32.5714 10.3035C34.4762 16.3035 34.4762 26.3035 32.5714 29.3035C30.0317 33.3035 25.5873 34.3035 24 34.3035Z"
      fill="#7FE3FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.2175 5.20356C18.2458 5.20356 14.2044 9.24497 14.2044 14.2166V24.1297C14.2044 29.1013 18.2458 33.1427 23.2175 33.1427C28.1891 33.1427 32.2305 29.1013 32.2305 24.1297V14.2166C32.2305 9.24497 28.1891 5.20356 23.2175 5.20356ZM12.4044 14.2166C12.4044 8.25086 17.2517 3.40356 23.2175 3.40356C29.1832 3.40356 34.0305 8.25086 34.0305 14.2166V24.1297C34.0305 30.0954 29.1832 34.9427 23.2175 34.9427C17.2517 34.9427 12.4044 30.0954 12.4044 24.1297V14.2166Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0001 19.9253C10.4972 19.9253 10.9001 20.3282 10.9001 20.8253V24.1297C10.9001 31.0178 16.3293 36.447 23.2175 36.447C30.1056 36.447 35.5349 31.0178 35.5349 24.1297V20.8253C35.5349 20.3282 35.9378 19.9253 36.4349 19.9253C36.9319 19.9253 37.3349 20.3282 37.3349 20.8253V24.1297C37.3349 32.0119 31.0998 38.247 23.2175 38.247C15.3352 38.247 9.1001 32.0119 9.1001 24.1297V20.8253C9.1001 20.3282 9.50304 19.9253 10.0001 19.9253Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.2175 36.447C23.7145 36.447 24.1175 36.85 24.1175 37.347V42.3036C24.1175 42.8006 23.7145 43.2036 23.2175 43.2036C22.7204 43.2036 22.3175 42.8006 22.3175 42.3036V37.347C22.3175 36.85 22.7204 36.447 23.2175 36.447Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.2175 3.40356C23.7145 3.40356 24.1175 3.80651 24.1175 4.30356V9.26009C24.1175 9.75714 23.7145 10.1601 23.2175 10.1601C22.7204 10.1601 22.3175 9.75714 22.3175 9.26009V4.30356C22.3175 3.80651 22.7204 3.40356 23.2175 3.40356Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5218 3.96505C27.0189 3.96505 27.4218 4.36799 27.4218 4.86505V9.25988C27.4218 9.75694 27.0189 10.1599 26.5218 10.1599C26.0248 10.1599 25.6218 9.75694 25.6218 9.25988V4.86505C25.6218 4.36799 26.0248 3.96505 26.5218 3.96505Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9131 3.96505C20.4102 3.96505 20.8131 4.36799 20.8131 4.86505V9.25988C20.8131 9.75694 20.4102 10.1599 19.9131 10.1599C19.4161 10.1599 19.0131 9.75694 19.0131 9.25988V4.86505C19.0131 4.36799 19.4161 3.96505 19.9131 3.96505Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.4349 25.0296H10.0001V23.2297H36.4349V25.0296Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4044 14.2166C12.4044 13.7196 12.8074 13.3166 13.3044 13.3166H19.9131C20.4102 13.3166 20.8131 13.7196 20.8131 14.2166C20.8131 14.7137 20.4102 15.1166 19.9131 15.1166H13.3044C12.8074 15.1166 12.4044 14.7137 12.4044 14.2166Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4044 17.521C12.4044 17.0239 12.8074 16.621 13.3044 16.621H19.9131C20.4102 16.621 20.8131 17.0239 20.8131 17.521C20.8131 18.018 20.4102 18.421 19.9131 18.421H13.3044C12.8074 18.421 12.4044 18.018 12.4044 17.521Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.6218 14.2166C25.6218 13.7196 26.0248 13.3166 26.5218 13.3166H33.1305C33.6276 13.3166 34.0305 13.7196 34.0305 14.2166C34.0305 14.7137 33.6276 15.1166 33.1305 15.1166H26.5218C26.0248 15.1166 25.6218 14.7137 25.6218 14.2166Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.6218 17.521C25.6218 17.0239 26.0248 16.621 26.5218 16.621H33.1305C33.6276 16.621 34.0305 17.0239 34.0305 17.521C34.0305 18.018 33.6276 18.421 33.1305 18.421H26.5218C26.0248 18.421 25.6218 18.018 25.6218 17.521Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4044 20.8253C12.4044 20.3282 12.8074 19.9253 13.3044 19.9253H19.9131C20.4102 19.9253 20.8131 20.3282 20.8131 20.8253C20.8131 21.3224 20.4102 21.7253 19.9131 21.7253H13.3044C12.8074 21.7253 12.4044 21.3224 12.4044 20.8253Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.6218 20.8253C25.6218 20.3282 26.0248 19.9253 26.5218 19.9253H33.1305C33.6276 19.9253 34.0305 20.3282 34.0305 20.8253C34.0305 21.3224 33.6276 21.7253 33.1305 21.7253H26.5218C26.0248 21.7253 25.6218 21.3224 25.6218 20.8253Z"
      fill="#042238"
    />
  </svg>
);

Podcast.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Podcast;
