import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import cookies from 'js-cookie';

import isPWA from '../components/AddAppHomeScreen/isPWA';
import UserRoleEnums from '../constants/enums/UserRolesEnum';
import * as AuthService from '../core/auth/authService';
import { getConfig } from '../services/configService';
import { getItem } from '../services/localStorageService';
import {
  getCookieDomainName,
  getSubdomain,
  gtmDataLayerPush,
} from '../services/utilities';

export default (WrappedComponent) => {
  const trackPage = (page) => {
    const { IS_OWNER, IS_RENTER } = getConfig();
    const subdomain = getSubdomain();
    const experience = isPWA() ? 'pwa' : 'website';
    if (IS_OWNER || IS_RENTER) {
      const token = AuthService.getCurrentToken();

      if (!token) {
        return gtmDataLayerPush({
          event: 'pageview',
          pagePath: page,
          subdomain: subdomain,
          experience,
        });
      }

      const decodedToken = AuthService.decodeToken(token) || {};

      let userId = decodedToken?.currentId || decodedToken?.id;
      if (decodedToken?.role === UserRoleEnums.USER) {
        // TODO pull user id from query string, ttAccountId
        userId = '';
      }

      const isCustomer = JSON.parse(getItem('isCustomer'));
      const ownerType = isCustomer ? 'customer' : 'signup';

      if (userId) {
        if (IS_OWNER) {
          cookies.set('ownerType', ownerType, {
            domain: getCookieDomainName(),
          });
          gtmDataLayerPush({
            event: 'pageview',
            pagePath: page,
            subdomain: subdomain,
            userId: userId,
            ownerId: `owner${userId}`,
            tenantId: 'na',
            sessionType: 'client',
            ownerType: ownerType,
            experience,
          });
        }
        if (IS_RENTER) {
          gtmDataLayerPush({
            event: 'pageview',
            pagePath: page,
            subdomain: subdomain,
            userId: userId,
            ownerId: 'na',
            tenantId: `tenant${userId}`,
            sessionType: 'client',
            ownerType: 'na',
            experience,
          });
        }
      }
    } else {
      gtmDataLayerPush({
        event: 'pageview',
        pagePath: page,
        subdomain: subdomain,
        experience,
      });
    }
  };

  const HOC = (props) => {
    const location = useLocation();
    useEffect(() => trackPage(location.pathname), [location.pathname]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};
