import React from 'react';
import PropTypes from 'prop-types';

import showIntercom from '../helpers/showIntercom';

const ShowIntercomLink = ({ fallback, ...props }) => (
  <a
    href={fallback}
    onClick={(e) => {
      if (typeof window.Intercom === 'undefined') {
        return;
      }
      e.preventDefault();
      showIntercom();
    }}
    {...props}
  />
);

ShowIntercomLink.propTypes = {
  fallback: PropTypes.string,
};

ShowIntercomLink.defaultProps = {
  fallback: 'mailto:support@turbotenant.com',
};

export default ShowIntercomLink;
