import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import AddNewLeadModal from '../../../pages/owners/renters/leads/AddNewLeadModal';
import { useConfig } from '../../../providers/ConfigProvider';

import AddRentersModal from './AddRentersModal';

const AddRentersModalWrapped = ({
  history,
  className,
  open,
  onClose,
  hasLeases,
}) => {
  const [modalStates, setModalStates] = useState({
    base: false,
    leads: false,
  });
  const config = useConfig();

  useEffect(() => {
    if (open) {
      setModalStates({
        base: true,
        leads: false,
      });
    } else {
      setModalStates({
        base: false,
        leads: false,
      });
    }
  }, [open]);

  if (modalStates.base) {
    return (
      <AddRentersModal
        onAddLead={() =>
          setModalStates({
            base: false,
            leads: true,
          })
        }
        className={className}
        open
        hasLeases={hasLeases}
        onClose={onClose}
      />
    );
  }
  if (modalStates.leads) {
    return (
      <AddNewLeadModal
        onClose={(success) => {
          if (success === true) {
            return history.push(`${config.PRIVATE_BASE_PATH}renters/leads`);
          }
          setModalStates({ base: true, leads: false });
        }}
      />
    );
  }
  return null;
};

AddRentersModalWrapped.propTypes = {
  history: PropTypes.object,
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  hasLeases: PropTypes.bool,
};

export default withRouter(AddRentersModalWrapped);
