import React from 'react';
import { useLottie } from 'lottie-react';
import PropTypes from 'prop-types';

import IframeAnimation from '../../../components/Animations/iOSIframeAnimation';
import isiOSDevice from '../../../helpers/isIosDevice';

import animationPathJson from './LeaseAgreementFlow.animation.json';

import styles from './LeaseAgreementFlowAnimation.module.scss';

const LeaseAgreementFlowAnimation = ({ style }) => {
  const iOS = isiOSDevice();

  if (iOS) {
    return (
      <div className={styles.iframeContainer}>
        <IframeAnimation
          style={style}
          src="https://lottie.host/?file=1e1d6331-4d3c-430a-9643-a982b024defd/PlgNaFa0gg.json"
        />
      </div>
    );
  }

  const options = {
    path: animationPathJson,
    loop: true,
    autoplay: true,
  };

  const LoaderData = useLottie(options, style);
  return LoaderData.View;
};

LeaseAgreementFlowAnimation.propTypes = {
  style: PropTypes.object,
};

export default LeaseAgreementFlowAnimation;
