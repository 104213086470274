import React, { memo } from 'react';
import PropTypes from 'prop-types';

const BinocularsColor = (props) => (
  <svg {...props} viewBox="0 0 53 48">
    <g fill="none" fillRule="evenodd">
      <circle cx="12.094" cy="36.584" r="11.082" fill="#7FE3FF" />
      <circle cx="41.24" cy="36.584" r="11.082" fill="#7FE3FF" />
      <path
        fill="#042238"
        d="M24.119 1.558c.167.165.275.377.312.604l.013.173v8.79c0 .607-.497 1.1-1.11 1.1-.546 0-1-.39-1.094-.902l-.018-.198V2.843l-.197-.131c-1.25-.743-3.191-.681-4.326.186l-.088.073-3.237 8.54-.255.391-3.183 3.147-4.679 10.796a12.275 12.275 0 0 1 5.965-1.534c4.135 0 7.79 2.03 10.001 5.136V17.256c-1.905.597-3.17 1.535-3.32 2.473l-.014.187c0 .607-.498 1.099-1.111 1.099a1.105 1.105 0 0 1-1.111-1.1c0-3.241 4.555-5.493 10-5.493 5.444 0 10 2.252 10 5.494 0 .607-.498 1.099-1.111 1.099-.546 0-1-.389-1.094-.902l-.018-.197c0-.999-1.302-2.023-3.332-2.66l-.001 12.19a12.247 12.247 0 0 1 10-5.135c2.166 0 4.2.557 5.964 1.534l-4.68-10.796-3.18-3.147-.253-.385-3.24-8.403-.088-.078c-1.134-.867-3.076-.929-4.326-.186l-.197.132v8.143c0 .54-.393.988-.911 1.082l-.2.017c-.545 0-1-.388-1.093-.901l-.018-.198V2.473c0-.291.117-.57.325-.777 2.01-1.987 5.79-2.073 7.954-.259l.284.26.253.385 3.248 8.433 3.166 3.13.236.344 6.615 15.263a11.945 11.945 0 0 1 2.363 7.146c0 6.675-5.471 12.087-12.222 12.087-6.75 0-12.222-5.412-12.222-12.087v-19.63a16.408 16.408 0 0 0-2.222-.149c-.776 0-1.522.053-2.223.15v19.629c0 6.675-5.471 12.087-12.222 12.087C5.472 48.485 0 43.073 0 36.398c0-2.675.878-5.147 2.365-7.15L8.98 13.99l.235-.344 3.162-3.128 3.25-8.568.255-.391c2.1-2.077 6.137-2.077 8.238 0Zm-11.897 24.95a10.021 10.021 0 0 0-8 3.957 9.764 9.764 0 0 0-2 5.933c0 5.462 4.477 9.89 10 9.89 5.524 0 10-4.428 10-9.89 0-5.463-4.476-9.89-10-9.89Zm28.89 0c-5.525 0-10 4.427-10 9.89 0 5.462 4.476 9.89 10 9.89 5.522 0 10-4.428 10-9.89 0-5.463-4.477-9.89-10-9.89Zm-28.89 2.198c.614 0 1.111.492 1.111 1.1 0 .606-.497 1.098-1.11 1.098-3.067 0-5.556 2.462-5.556 5.494 0 .607-.498 1.099-1.111 1.099a1.105 1.105 0 0 1-1.112-1.099c0-4.246 3.485-7.692 7.778-7.692Zm28.89 0c.613 0 1.11.492 1.11 1.1 0 .606-.497 1.098-1.11 1.098-3.067 0-5.556 2.462-5.556 5.494 0 .607-.498 1.099-1.112 1.099a1.105 1.105 0 0 1-1.11-1.099c0-4.246 3.483-7.692 7.777-7.692Z"
      />
    </g>
  </svg>
);

BinocularsColor.defaultProps = {
  width: 53,
  height: 48,
};

BinocularsColor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(BinocularsColor);
