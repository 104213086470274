import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import LoadingScreen from '../../../../components/LoadingScreen';
import SUBSCRIPTION_STATUS from '../../../../constants/stripe_subscriptions/subscription_status';
import { useUserProfile } from '../../../../core/TTgraphql';
import ownerSettingsQueryGQL from '../../../../graphql/queries/ownerSettingsQuery.graphql';
import daysToMilliseconds from '../../../../helpers/daysToMiliseconds';
import { useConfig } from '../../../../providers/ConfigProvider';
import { getItem, setItem } from '../../../../services/localStorageService';
import { segmentTracking } from '../../../../services/utilities';

import GlobalNotificationModal from './GlobalNotificationModal';

const SHOW_GLOBAL_MODAL = 'showGlobalModal';

const setModalTimestamp = () => {
  const now = new Date().getTime();
  setItem(SHOW_GLOBAL_MODAL, now);
};

const GlobalNotification = ({ history }) => {
  const { user, loading: userLoading } = useUserProfile();
  const { loading: loadingOwnerSettings, data: ownerSettingsData } = useQuery(
    ownerSettingsQueryGQL,
  );
  const { PRIVATE_BASE_PATH } = useConfig();

  const paymentMethods = user?.payment_methods || [];
  const defaultBank = paymentMethods.find((pm) => pm.is_default === true);

  const { premiumPlusSubscriptionInfo, reiHubSubscriptionInfo } =
    ownerSettingsData?.userData?.settingsAndBilling?.data || {};
  const premiumStatus = premiumPlusSubscriptionInfo?.status || null;
  const reiHubStatus = reiHubSubscriptionInfo?.status || null;

  const subscriptions = [
    {
      label: 'Premium',
      status: premiumStatus,
    },
    {
      label: 'REI Hub',
      status: reiHubStatus,
    },
  ];

  const pastDueSubscriptions = subscriptions
    .filter((s) => s.status === SUBSCRIPTION_STATUS.PAST_DUE)
    .map((s) => s.label);

  const modalOpenTimestamp = getItem(SHOW_GLOBAL_MODAL);
  let showModal;

  // If timestamp is present we check if less than 24h passed
  if (modalOpenTimestamp) {
    const oneDayMs = daysToMilliseconds(1);
    const now = new Date().getTime();
    const timeDifference = now - modalOpenTimestamp;

    if (timeDifference < oneDayMs) {
      showModal = false;
    } else {
      showModal = true;
    }
  } else {
    showModal = true;
  }

  const [modalOpen, setOpenModal] = useState(showModal);

  // We had issues with some users where we switched to another subscription where we lost payment method
  // This is safeguard that we might remove at some point since users with subscription should always have a payment method
  if (paymentMethods.length === 0) {
    return null;
  }

  if (
    premiumStatus !== SUBSCRIPTION_STATUS.PAST_DUE &&
    reiHubStatus !== SUBSCRIPTION_STATUS.PAST_DUE
  ) {
    return null;
  }

  if (loadingOwnerSettings || userLoading) {
    return <LoadingScreen loading={loadingOwnerSettings || userLoading} />;
  }

  return (
    <GlobalNotificationModal
      open={modalOpen}
      lastFour={defaultBank.last4}
      pastDueSubscriptions={pastDueSubscriptions}
      onUpdate={() => {
        segmentTracking('update_payment_method clicked', {
          location: 'Past Due Modal',
        });
        setModalTimestamp();
        setOpenModal(false);
        history.push(`${PRIVATE_BASE_PATH}settings/billing`);
      }}
      onRemind={() => {
        segmentTracking('remind_me_later clicked', {
          location: 'Past Due Modal',
        });
        setModalTimestamp();
        setOpenModal(false);
      }}
    />
  );
};

export default withRouter(GlobalNotification);
