import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import useWindowSize from '@rooks/use-window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';
import { smallScreenWidth } from '../../constants/media-breakpoints';
import isMatterportPresentation from '../../helpers/isMatterportPresentation';
import CloseIcon from '../../icons/Close';
import Modal from '../Modal';

import styles from './VideoPlayerModal.module.scss';

const VideoPlayerModal = ({
  open,
  onClose,
  url,
  containerClassName,
  className,
}) => {
  const [showIcon, setShowIcon] = useState(false);
  const { innerWidth } = useWindowSize();
  const isMatterport = isMatterportPresentation(url);
  const isMobileBreapoint = innerWidth < smallScreenWidth;
  const currentHeight = (9 * innerWidth) / 16;

  return (
    <Modal
      containerClassName={cx(styles.container, containerClassName)}
      className={cx(styles.modal, className)}
      open={open}
      onClose={onClose}
    >
      <CloseIcon
        style={
          isMobileBreapoint
            ? { top: '50%', marginTop: `${-currentHeight / 2 - 36}px` } // We calculate position of close icon programatically on mobile since player is forcing 16:9 aspect ratio which created extra height
            : null
        }
        className={cx(styles.icon, {
          [styles.hidden]: !showIcon,
          [styles.matterport]: isMatterport,
        })}
        width={24}
        height={24}
        color={colors.white}
        onClick={onClose}
      />
      {isMatterport ? (
        <iframe className={styles.matterportIframe} src={url} frameBorder="0" />
      ) : (
        <ReactPlayer
          className={styles.player}
          width={isMobileBreapoint ? '100%' : null}
          url={url}
          playing
          controls
          onReady={() => {
            setShowIcon(true);
          }}
        />
      )}
    </Modal>
  );
};

VideoPlayerModal.propTypes = {
  url: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default VideoPlayerModal;
