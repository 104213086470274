import React, { useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import PropTypes from 'prop-types';

import AddEditTenant from '../../../../../components/AddEditTenant';
import Button from '../../../../../components/Button';
import CheckboxGroupFields from '../../../../../components/CheckboxGroupFields';
import FlatButton from '../../../../../components/FlatButton';
import ProfilePicture from '../../../../../components/ProfilePicture';
import { Tooltip } from '../../../../../components/Tooltip';
import PlusCircle from '../../../../../icons/PlusCircle';

import styles from './AddTenantField.module.scss';

const AddTenantField = ({
  defaultAddress,
  tenants,
  inUseValidators,
  skipTenants,
}) => {
  const [openAddTenant, setOpenAddTenant] = useState(false);

  return (
    <FieldArray name="tenants">
      {({ fields }) => (
        <>
          {tenants?.length > 0 ? (
            <div className={styles.tenantsHolder}>
              {tenants.map((tenant, index) => (
                <div className={styles.tenantRow} key={index}>
                  <ProfilePicture
                    firstName={tenant.first_name || ''}
                    lastName={tenant.last_name || ''}
                    profilePictureURL={tenant.profile_pic || ''}
                    className={styles.avatar}
                    size="small"
                  />
                  <div className={styles.tenantsInfo}>
                    <p className={styles.tenantsName}>
                      {`${tenant.first_name} ${tenant.last_name}`}{' '}
                    </p>
                    <Tooltip id={`tennat-at-key-${index}`}>
                      {tenant.address ? tenant.address : defaultAddress}
                    </Tooltip>
                  </div>
                  <Button
                    className={styles.tenantsBtn}
                    onClick={() => fields.remove(index)}
                    secondary
                  >
                    remove
                  </Button>
                </div>
              ))}
            </div>
          ) : null}
          <FlatButton
            className={styles.addTenant}
            iconProps={{ className: styles.addTenantIcon }}
            icon={PlusCircle}
            disabled={skipTenants?.length > 0}
            onClick={() => {
              setOpenAddTenant(true);
            }}
          >
            Enter New Tenant
          </FlatButton>
          {tenants?.length === 0 ? (
            <CheckboxGroupFields
              labelClassName={styles.checkboxLabel}
              id="skip_tenants"
              name="skip_tenants"
              options={[
                {
                  value: 'skip',
                  label: "I'll add my tenants later",
                },
              ]}
            />
          ) : null}
          <AddEditTenant
            onSubmit={(rawData) => {
              fields.push({ ...rawData, address: defaultAddress });
              setOpenAddTenant(false);
            }}
            onClose={() => setOpenAddTenant(false)}
            open={openAddTenant}
            inUseValidators={inUseValidators}
            initialValues={{ role: 'Tenant', invite_type: 'email' }}
            submitLabel="Enter New Tenant"
            title="Enter New Tenant's Info"
            description={false}
          />
        </>
      )}
    </FieldArray>
  );
};

AddTenantField.propTypes = {
  dropdownClassName: PropTypes.string,
  defaultAddress: PropTypes.string,
  defaultRole: PropTypes.string,
  showResultsOnFocus: PropTypes.bool,
  getOptions: PropTypes.func,
  tenants: PropTypes.array,
  inUseValidators: PropTypes.object,
  skipTenants: PropTypes.array,
  showSearch: PropTypes.bool,
  dataQa: PropTypes.string,
};

export default AddTenantField;
