import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { CometChat } from '@cometchat-pro/chat';
import { AnimatePresence, motion } from 'framer-motion';
import { CometChatUserListItem } from '../Users';
import { UserListManager } from '../Users/CometChatUserList/controller';

import {
  CometChatContext,
  CometChatContextProvider,
} from '../../util/CometChatContext';

import Translator from '../../resources/localization/translator';
import { theme } from '../../resources/theme';
import searchIcon from '../Users/CometChatUserList/resources/search-ul.svg';

const overlayVariants = {
  hidden: { x: '100%' },
  visible: { x: '0%' },
  exit: { x: '100%' },
};
import {
  newMessageModalStyle,
  newMessageModalTitleContainer,
  newMessageModalTitle,
  newMessageModalTitleCloseButton,
  contactSearchContainerStyle,
  contactSearchStyle,
  contactSearchButtonStyle,
  contactSearchInputStyle,
  contactListStyle,
  contactAlphabetStyle,
  contactMsgStyle,
  contactMsgTxtStyle,
  overlayContentStyle,
  backButtonContainerStyle,
  backButtonStyle,
  emptyStateContainerStyle,
  emptyStateTitleStyle,
  emptyStateTextStyle,
} from './style';
import Modal from '../../../../Modal';
import IconButton from '../../../../IconButton';

import CloseIcon from '../../../../../icons/Close';
import { primary } from '../../../../../constants/colors';

import styles from './NewMessageModal.module.scss';
import BackArrow from '../../../../../icons/BackArrow';
import { useUniversalMessagingContext } from '../../../../../providers/UniversalMessagingProvider';
import BinocularsColor from '../../../../../icons/BinocularsColor';

class NewMessageModalClass extends React.Component {
  static contextType = CometChatContext;

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      enableSearchUser: false,
      userlist: [],
      decoratorMessage: Translator.translate('LOADING', props.lang),
      filter: 'ALL',
      searchKey: '',
      universalMessagingOverlayOpen: false,
      showEmptyState: true,
      universalMessagingSearchVisible: true,
    };
    CometChat.getLoggedinUser()
      .then((user) => (this.loggedInUser = user))
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidMount() {
    this.item =
      this.context.type === CometChat.ACTION_TYPE.TYPE_USER
        ? this.context.item
        : null;
    this.toggleUserSearch();

    this.UserListManager = new UserListManager(this.context);
    this.getUsers();
    this.setState({
      universalMessagingOverlayOpen: this.props.isFromUniversalMessaging,
    });
  }

  componentDidUpdate(prevProps) {
    //if user is blocked/unblocked, update userlist
    if (
      this.item &&
      Object.keys(this.item).length &&
      this.context.type === CometChat.ACTION_TYPE.TYPE_USER &&
      this.item.uid === this.context.item.uid &&
      this.item.blockedByMe !== this.context.item.blockedByMe
    ) {
      let userlist = [...this.state.userlist];

      //search for user
      let userKey = userlist.findIndex((u) => u.uid === this.context.item.uid);
      if (userKey > -1) {
        let userObject = { ...userlist[userKey] };
        let newUserObject = Object.assign({}, userObject, {
          blockedByMe: this.context.item.blockedByMe,
        });

        userlist.splice(userKey, 1, newUserObject);
        this.setState({ userlist: userlist });
      }
    }

    this.item =
      this.context.type === CometChat.ACTION_TYPE.TYPE_USER
        ? this.context.item
        : null;
    this.toggleUserSearch();
  }

  componentWillUnmount() {
    this.UserListManager.removeListeners();
    this.UserListManager = null;
  }

  toggleUserSearch = () => {
    this.context.FeatureRestriction.isUserSearchEnabled()
      .then((response) => {
        /**
         * Don't update state if the response has the same value
         */
        if (response !== this.state.enableSearchUser) {
          this.setState({ enableSearchUser: response });
        }
      })
      .catch((error) => {
        if (this.state.enableSearchUser !== false) {
          this.setState({ enableSearchUser: false });
        }
      });
  };

  getUsers = () => {
    this.setState({ showEmptyState: true });
    this.UserListManager.initializeUsersRequest()
      .then((response) => {
        this.timeout = setTimeout(() => {
          this.setState(
            {
              userlist: [],
              decoratorMessage: Translator.translate(
                'LOADING',
                this.props.lang,
              ),
            },
            () => {
              this.UserListManager.fetchNextUsers().then((userList) => {
                if (userList.length === 0) {
                  if (this.state.userlist.length === 0) {
                    const isOwner = this.loggedInUser?.role === 'owner';
                    this.setState({
                      decoratorMessage: isOwner
                        ? 'No renters found'
                        : 'No landlords found',
                      showEmptyState: true,
                      universalMessagingSearchVisible: !this.props
                        .isFromUniversalMessaging
                        ? true
                        : false,
                    });
                  }
                } else {
                  this.setState({
                    userlist: [...this.state.userlist, ...userList],
                    decoratorMessage: '',
                    universalMessagingSearchVisible: true,
                    showEmptyState: false,
                  });
                }
              });
            },
          );
        }, 500);
      })
      .catch((error) =>
        this.setState({
          decoratorMessage: Translator.translate(
            'SOMETHING_WRONG',
            this.props.lang,
          ),
        }),
      );
  };

  getNextUsers = () => {
    this.UserListManager.fetchNextUsers()
      .then((userList) => {
        if (userList.length === 0) {
          if (this.state.userlist.length === 0) {
            const isOwner = this.loggedInUser?.role === 'owner';
            this.setState({
              decoratorMessage: isOwner
                ? 'No renters found'
                : 'No landlords found',
            });
          }
        } else {
          this.setState({
            userlist: [...this.state.userlist, ...userList],
            decoratorMessage: '',
          });
        }
      })
      .catch((error) =>
        this.setState({
          decoratorMessage: Translator.translate(
            'SOMETHING_WRONG',
            this.props.lang,
          ),
        }),
      );
  };

  searchUsers = ({ searchKey, filter }) => {
    if (this.props.isFromUniversalMessaging) {
      this.setState({
        showEmptyState: true,
        decoratorMessage: Translator.translate('LOADING', this.props.lang),
        universalMessagingSearchVisible: true,
      });
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.setState(
      {
        searchKey: searchKey ?? this.state.searchKey,
        filter: filter ?? this.state.filter,
      },
      () => {
        let filters = [];
        switch (this.state.filter) {
          case 'LEAD':
            filters = [`${this.loggedInUser.uid}_lead`];
            break;
          case 'APPLICANT':
            filters = [`${this.loggedInUser.uid}_applicant`];
            break;
          case 'TENANT':
            filters = [`${this.loggedInUser.uid}_tenant`];
            break;
          case 'ALL':
            filters = [];
            break;

          default:
            filters = [];
            break;
        }

        this.UserListManager.setParams({
          searchKey: this.state.searchKey,
          filters,
        });
        this.getUsers();
      },
    );
  };

  handleScroll = (e) => {
    const bottom =
      Math.round(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) ===
      Math.round(e.currentTarget.clientHeight);
    if (bottom) this.getNextUsers();
  };

  handleClose = () => {
    this.searchUsers({ searchKey: '', filter: 'ALL' });
    this.props.onClose();
  };

  render() {
    let userList = [...this.state.userlist];
    let currentLetter = '';

    if (this.props.isFromUniversalMessaging) {
      userList = userList.sort((a, b) => a.name.localeCompare(b.name));
    }
    let messageContainer = null;
    if (
      this.state.decoratorMessage.length !== 0 &&
      !this.props.isFromUniversalMessaging
    ) {
      messageContainer = (
        <div css={contactMsgStyle()} className="contacts__decorator-message">
          <p css={contactMsgTxtStyle(theme)} className="decorator-message">
            {this.state.decoratorMessage}
          </p>
        </div>
      );
    }

    const users = userList.map((user) => {
      const chr = user.name[0].toUpperCase();
      let firstChar = null;
      if (chr !== currentLetter) {
        currentLetter = chr;
        firstChar = (
          <div
            css={contactAlphabetStyle(this.props)}
            className="contacts__list__alphabet-filter"
          >
            {currentLetter}
          </div>
        );
      } else {
        firstChar = null;
      }

      let selectedUser =
        this.context.type === CometChat.ACTION_TYPE.TYPE_USER &&
        this.context.item.uid === user.uid
          ? user
          : null;

      return (
        <React.Fragment key={user.uid}>
          {firstChar}
          <CometChatUserListItem
            user={user}
            loggedInUser={this.loggedInUser}
            selectedUser={selectedUser}
            clickHandler={() => {
              this.handleClose();
              this.props.onUserSelect(user);
            }}
          />
        </React.Fragment>
      );
    });

    let searchUser = null;
    if (
      this.state.enableSearchUser &&
      (this.state.universalMessagingSearchVisible || this.state.searchKey)
    ) {
      searchUser = (
        <div css={contactSearchContainerStyle()}>
          <div css={contactSearchStyle()} className="contacts__search">
            <button
              type="button"
              className="search__button"
              css={contactSearchButtonStyle(searchIcon, theme)}
            />
            <input
              type="text"
              autoComplete="off"
              css={contactSearchInputStyle(this.props)}
              className="search__input"
              placeholder={Translator.translate('SEARCH', this.props.lang)}
              onChange={(e) => {
                this.searchUsers({ searchKey: e.target.value });
              }}
            />
          </div>
        </div>
      );
    }

    if (this.props.isFromUniversalMessaging) {
      return (
        <AnimatePresence>
          {this.state.universalMessagingOverlayOpen && (
            <motion.div
              key="modal"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={overlayVariants}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'flex-end',
                zIndex: 1000,
              }}
            >
              <div css={overlayContentStyle()}>
                <CometChatContextProvider
                  ref={(el) => (this.contextProviderRef = el)}
                >
                  <div
                    css={newMessageModalTitleContainer(
                      this.props.isFromUniversalMessaging,
                    )}
                  >
                    <div css={backButtonContainerStyle()}>
                      <div css={backButtonStyle()}>
                        <BackArrow
                          onClick={() => {
                            this.setState({
                              universalMessagingOverlayOpen: false,
                            });
                            setTimeout(() => {
                              this.handleClose();
                            }, 300);
                          }}
                        />
                      </div>
                      <h2
                        css={newMessageModalTitle(
                          this.props.isFromUniversalMessaging,
                        )}
                      >
                        New Message
                      </h2>
                    </div>
                    <IconButton
                      css={newMessageModalTitleCloseButton()}
                      icon={CloseIcon}
                      onClick={this.props.toggleChat}
                      iconProps={{ color: primary }}
                    />
                  </div>
                  {searchUser}
                  {messageContainer}
                  {this.state.showEmptyState &&
                    this.props.isFromUniversalMessaging && (
                      <div css={emptyStateContainerStyle()}>
                        {this.state.decoratorMessage === 'Loading...' ? (
                          <p css={contactMsgTxtStyle(theme)}>
                            {this.state.decoratorMessage}
                          </p>
                        ) : (
                          <div css={emptyStateContainerStyle()}>
                            <BinocularsColor />
                            <p css={emptyStateTitleStyle()}>
                              {this.state.searchKey.length
                                ? `Oops, no results found`
                                : `You don’t have any renters yet`}
                            </p>
                            <p css={emptyStateTextStyle()}>
                              {this.state.searchKey.length
                                ? `Double check your search criteria or try searching for another renter. `
                                : `Once you do, you’ll be able to start a new conversation
                        here.`}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  <div
                    css={contactListStyle()}
                    className="contacts__list"
                    onScroll={this.handleScroll}
                  >
                    {users}
                  </div>
                </CometChatContextProvider>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      );
    }
    return (
      <Modal
        css={newMessageModalStyle()}
        open={this.props.open && !this.props.isFromUniversalMessaging}
        onClose={this.handleClose}
        mobileFullScreen
        containerClassName={styles.modalWrap}
      >
        <CometChatContextProvider ref={(el) => (this.contextProviderRef = el)}>
          <div css={newMessageModalTitleContainer()}>
            <h2 css={newMessageModalTitle()}>New Message</h2>
            <IconButton
              css={newMessageModalTitleCloseButton()}
              icon={CloseIcon}
              onClick={this.handleClose}
              iconProps={{ color: primary }}
            />
          </div>
          {searchUser}
          {messageContainer}
          <div
            css={contactListStyle()}
            className="contacts__list"
            onScroll={this.handleScroll}
          >
            {users}
          </div>
        </CometChatContextProvider>
      </Modal>
    );
  }
}

const NewMessageModal = (props) => {
  const { toggleChat } = useUniversalMessagingContext();
  return <NewMessageModalClass toggleChat={toggleChat} {...props} />;
};

// Specifies the default values for props:
NewMessageModal.defaultProps = {
  open: false,
  onClose: () => {},
  onUserSelect: () => {},
};

NewMessageModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUserSelect: PropTypes.func,
};

export { NewMessageModal };
