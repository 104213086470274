import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const ProfileThick = (props) => (
  <svg {...props} viewBox="0 0 28 28" fill={props.color}>
    <path d="M14 .667C21.365.667 27.333 6.635 27.333 14a13.29 13.29 0 01-3.842 9.363l-.009.007A13.286 13.286 0 0114 27.333c-3.608 0-6.989-1.441-9.478-3.957l.007.008A13.293 13.293 0 01.667 14C.667 6.635 6.635.667 14 .667zm-1.93 7.545a.816.816 0 01-.951.602c-.387-.077-.479-.046-.518.024-.083.145-.108.487-.043.958.036.267.096.556.18.883.044.17.087.324.163.595.085.306.106.393.106.55 0 .387-.27.712-.633.795a.976.976 0 00-.041.293c0 .114.016.218.04.292a.817.817 0 01.634.796c0 1 .24 1.678.62 2.122.2.234.381.345.454.369a.816.816 0 01.558.774v2.721c0 .34-.21.644-.527.764-.306.116-.63.236-1.028.38l-.744.27c-1.913.694-3.067 1.144-3.877 1.55A11.655 11.655 0 0014 25.7c2.809 0 5.457-.993 7.544-2.758a17.488 17.488 0 00-1.167-.522 82.09 82.09 0 00-1.898-.72l-.603-.223c-1.064-.392-2.03-.743-1.986-.726a.816.816 0 01-.53-.765v-2.72c0-.352.225-.664.559-.775.073-.024.254-.135.454-.37.38-.443.62-1.121.62-2.12 0-.389.27-.713.633-.797a.976.976 0 00.041-.292.976.976 0 00-.04-.293.817.817 0 01-.634-.796c0-.217.371-1.382.35-1.305.154-.543.251-1.026.278-1.467.02-.32 0-.602-.06-.84-.337-1.352-5.152-1.352-5.49 0zM14 2.299C7.537 2.3 2.3 7.537 2.3 14c0 2.901 1.06 5.633 2.938 7.753.928-.531 2.182-1.03 4.546-1.887a377.153 377.153 0 001.223-.446v-1.668a3.304 3.304 0 01-.62-.568c-.55-.642-.903-1.514-.991-2.624-.873-.653-.925-2.381-.155-3.161-.031-.113-.058-.211-.084-.315a9.376 9.376 0 01-.217-1.068c-.109-.795-.06-1.453.24-1.984.33-.58.892-.876 1.607-.884 1.563-2.314 7.669-2.092 8.359.668.103.412.134.858.105 1.334-.036.578-.154 1.17-.34 1.816-.05.179-.054.195-.135.45.752.788.695 2.496-.172 3.144-.088 1.11-.44 1.982-.991 2.624-.206.241-.418.428-.62.568v1.662c1.046.382 3.325 1.218 4.003 1.496.71.29 1.293.561 1.775.832A11.65 11.65 0 0025.701 14C25.7 7.537 20.463 2.3 14 2.3z" />
  </svg>
);

ProfileThick.defaultProps = {
  width: 28,
  height: 28,
  color: colors.text,
};

ProfileThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ProfileThick);
