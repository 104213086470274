import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import CheckMarkFilled from '../../icons/CheckMarkFilled';
import Close from '../../icons/Close';
import InformationCircleFilled from '../../icons/InformationCircleFilled';
import RadioX from '../../icons/RadioX';
import WarningCircleFilled from '../../icons/WarningCircleFilled';
import Callout from '../Callout';
import IconButton from '../IconButton';

import styles from './PlainToast.module.scss';

const icons = {
  error: RadioX,
  info: InformationCircleFilled,
  warning: WarningCircleFilled,
  success: CheckMarkFilled,
};

const PlainToast = ({ message, type, className, onClose }) => {
  const Icon = icons[type];

  return (
    <Callout
      className={cx(
        styles.container,
        {
          [styles[type]]: true,
        },
        className,
        'tt-toast',
      )}
      type={type}
      data-qa="plain-toast"
    >
      <Icon className={cx(styles.icon, styles[type])} width={20} height={20} />
      <div className={styles.message}>{message}</div>
      <IconButton className={styles.close} icon={Close} onClick={onClose} />
    </Callout>
  );
};

PlainToast.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['error', 'info', 'warning', 'success']),
  onClose: PropTypes.func,
  className: PropTypes.string,
};

export default PlainToast;
