import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import HelpfulInfoList from '../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../components/HelpfulInfo/HelpfulInfoListItem';
import Label from '../../../../components/Label';
import Modal from '../../../../components/Modal';
import KeyboardIcon from '../../../../icons/Keyboard';
import QuillIcon from '../../../../icons/Quill';
import ScaleIcon from '../../../../icons/Scale';

import styles from './WizardIntroModal.module.scss';

const WizardIntroModal = ({ className, onClose }) => (
  <Modal
    open
    mobileFullScreen
    onCancel={onClose}
    className={cx(styles.container, className)}
    containerClassName={styles.modalWrapper}
  >
    <div className={styles.header}>
      <h2 className={styles.title}>Start Your Lease Addendum!</h2>
      <span className={styles.paragraph}>
        Use an addendum to make minor changes to your current lease agreement
        while keeping the rest intact.
      </span>
      <Label className={styles.label}>
        The most common reasons for using an addendum are:
      </Label>
      <HelpfulInfoList className={styles.list}>
        <HelpfulInfoListItem>
          Extending the end date because a tenant wants to stay
        </HelpfulInfoListItem>
        <HelpfulInfoListItem>Changing the rent amount</HelpfulInfoListItem>
        <HelpfulInfoListItem>
          Updating a provision mid-lease, like when a tenant gets a pet or you
          change who is responsible for lawn upkeep
        </HelpfulInfoListItem>
      </HelpfulInfoList>
      {/**
        * NOTE: Commented out untill we add multiple lease agreements
        * <span className={styles.paragraph}>
        If you want to make major changes, like changing the tenants on the
        lease, we recommend you{' '}
        <a
          className={styles.link}
          href="#"
          target="_blank"
          rel="noopener noreferrer"
        >
          get a new lease agreement
        </a>
        .
      </span> */}
      <h4 className={styles.smallTitle}>Why use our addendum?</h4>
      <div className={styles.reasons}>
        <div className={styles.reason}>
          <div className={styles.iconWrapper}>
            <KeyboardIcon />
          </div>
          <div className={styles.reasonInfo}>
            <h4>Easy to Fill Out</h4>
            <span>We'll walk you through filling out the addendum.</span>
          </div>
        </div>
        <div className={styles.reason}>
          <div className={styles.iconWrapper}>
            <ScaleIcon />
          </div>
          <div className={styles.reasonInfo}>
            <h4>Legal Confidence</h4>
            <span>A flexible document that keeps you covered.</span>
          </div>
        </div>
        <div className={styles.reason}>
          <div className={styles.iconWrapper}>
            <QuillIcon />
          </div>
          <div className={styles.reasonInfo}>
            <h4>Fast to Sign</h4>
            <span>Includes e-sign, or simply print & sign in person.</span>
          </div>
        </div>
      </div>
      <Button className={styles.button} onClick={onClose}>
        SOUNDS GOOD
      </Button>
    </div>
  </Modal>
);

WizardIntroModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

WizardIntroModal.defaultProps = {
  onClose: () => ({}),
};

export default WizardIntroModal;
