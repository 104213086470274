import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import deepEqual from 'deep-equal';
import { useCombobox } from 'downshift';
import lodashDebounce from 'lodash.debounce';
import lodashGroupBy from 'lodash.groupby';
import PropTypes from 'prop-types';

import Label from '../Label';
import PlainInput from '../PlainInput';
import Spinner from '../Spinner';

import styles from './PlainGroupComboBox.module.scss';

const PlainGroupComboBox = ({
  dropdownClassName,
  inputClassName,
  inputLabel,
  groupBy,
  groupClassName,
  renderGroup,
  itemClassName,
  renderItem,
  renderEmpty,
  emptyListClassName,
  disabled,
  getOptions,
  onSelected,
  itemToString,
  label,
  labelProps,
  className,
  style,
  onChange,
  onBlur,
  onFocus,
  value,
  name,
  showLoading,
  debounce,
  placeholder,
  error,
  dataQa,
  resultFilter,
  showResultsOnFocus,
  labelOnTop,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const pauseOnFocusRef = useRef(false);

  const debouncedSearch = useCallback(
    lodashDebounce(async (query, filter) => {
      !loading && setLoading(true);
      let results = [];
      try {
        results = await getOptions(query);
        if (filter) {
          results = results.filter(filter);
        }
      } catch (e) {
        // ignore error
      }
      setLoading(false);
      setOptions(results);
    }, debounce),
    [],
  );

  const groups = options.reduce((acc, curr) => {
    if (!acc.includes(curr.role)) {
      acc.push(curr.role);
    }
    return acc;
  }, []);

  let groupedOptions = [];
  if (options && options.length && Object.keys(options[0]).includes(groupBy)) {
    groupedOptions = lodashGroupBy(options, (opt) => opt[groupBy]);
  }

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
  } = useCombobox({
    items: options || [],
    itemToString,
    inputValue: value,
    onSelectedItemChange: async ({ selectedItem }) => {
      onSelected(selectedItem);
    },
    onInputValueChange: ({ inputValue, selectedItem }) => {
      if (selectedItem && itemToString(selectedItem) === inputValue) {
        return null;
      }
      onChange(inputValue);
      !loading && setLoading(true);
      debouncedSearch(inputValue, resultFilter);
    },
  });

  useEffect(() => {
    pauseOnFocusRef.current = isOpen;
  }, [isOpen]);

  const renderOptions = () => {
    const content = groups.map((group) => {
      const groupLabel = (
        <li className={groupClassName}>{renderGroup(group)}</li>
      );
      const groupItems = groupedOptions[group].map((item) => {
        const itemIndex = options.findIndex((originalItem) =>
          deepEqual(originalItem, item),
        );
        return (
          <li
            className={itemClassName}
            key={`${String(item)}${itemIndex}`}
            {...getItemProps({ item, index: itemIndex })}
          >
            {renderItem(item, highlightedIndex === itemIndex)}
          </li>
        );
      });

      return (
        <React.Fragment key={group}>
          {groupLabel}
          {groupItems}
        </React.Fragment>
      );
    });

    if (!content.length && value) {
      return renderEmpty ? (
        <li className={emptyListClassName}>{renderEmpty()}</li>
      ) : null;
    }
    return content;
  };

  return (
    <div
      className={cx(styles.wrap, className, {
        [styles.disabled]: disabled,
      })}
      style={style}
    >
      {label && (
        <Label {...labelProps} {...getLabelProps()}>
          {label}
        </Label>
      )}
      <div className={styles.container} {...getComboboxProps()}>
        <div
          className={cx(styles.inputContainer, {
            [styles.labelOnTop]: labelOnTop,
          })}
        >
          {inputLabel && (
            <label
              className={cx(styles.inputLabel, {
                [styles.labelOnTop]: labelOnTop,
              })}
            >
              {inputLabel}
            </label>
          )}
          <PlainInput
            className={inputClassName}
            disabled={disabled}
            placeholder={placeholder}
            name={name}
            autoComplete="off"
            invalid={!!error}
            {...getInputProps({
              onFocus: async (evt) => {
                if (showResultsOnFocus && !pauseOnFocusRef.current) {
                  !loading && setLoading(true);
                  await debouncedSearch(evt.currentTarget.value, resultFilter);
                  openMenu();
                }
                onFocus(evt);
              },
              onBlur,
              // onFocus,
            })}
            data-qa={dataQa}
          />
          {loading && showLoading && (
            <Spinner
              secondary
              className={cx(styles.spinner, {
                [styles.labelOnTop]: labelOnTop,
              })}
            />
          )}
        </div>
      </div>
      <ul
        {...getMenuProps()}
        className={cx(styles.menu, dropdownClassName, {
          [styles.open]: isOpen && options && !loading,
        })}
      >
        {isOpen && options && !loading ? renderOptions() : null}
      </ul>
      {error ? <div className={styles.error}>{error}</div> : null}
    </div>
  );
};

PlainGroupComboBox.propTypes = {
  dropdownClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  inputLabel: PropTypes.string,
  groupBy: PropTypes.string.isRequired,
  groupClassName: PropTypes.string,
  renderGroup: PropTypes.func.isRequired,
  itemClassName: PropTypes.string,
  renderItem: PropTypes.func.isRequired,
  renderEmpty: PropTypes.func,
  emptyListClassName: PropTypes.string,
  itemToString: PropTypes.func,
  disabled: PropTypes.bool,
  getOptions: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelProps: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  debounce: PropTypes.number,
  showLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.string,
};

PlainGroupComboBox.defaultProps = {
  renderGroup: (item) => (item ? `Group: ${String(item)}` : ''),
  renderItem: (item, highlighted) =>
    item ? `Item: ${String(item)} ${highlighted ? ' | highlighted' : ''}` : '',
  itemToString: (item) => (item ? String(item) : ''),
  debounce: 100,
  showResultsOnFocus: false,
};

export default PlainGroupComboBox;
