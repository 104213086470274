import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const ErrorIcon = (props) => (
  <svg {...props} viewBox="0 0 20 20" fill={props.color}>
    <path d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 1.356a8.644 8.644 0 100 17.288 8.644 8.644 0 000-17.288zM10.025 14a.875.875 0 110 1.75.875.875 0 010-1.75zM10 4a.7.7 0 01.694.605l.006.095v7.8a.7.7 0 01-1.394.095L9.3 12.5V4.7A.7.7 0 0110 4z" />
  </svg>
);

ErrorIcon.defaultProps = {
  width: 20,
  height: 20,
  color: colors.text,
};

ErrorIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ErrorIcon);
