import validShareAbleAddress from '../validators/validShareAbleAddress';
import validShareAbleCity from '../validators/validShareAbleCity';
import validShareAbleUnit from '../validators/validShareAbleUnit';

const validateMailingAddress = (mailingAddress) => {
  const { city, address, unit } = mailingAddress;

  const addressError = validShareAbleAddress(address);
  const cityError = validShareAbleCity(city);
  const unitError = validShareAbleUnit(unit);

  if (addressError || cityError || unitError) {
    return false;
  }

  return true;
};

export default validateMailingAddress;
