import { useHistory } from 'react-router-dom';

const allowedMethods = ['push', 'replace'];

function useNavigator() {
  const history = useHistory();

  /**
   *
   * @param {string} path - The destination path.
   * @param {Object} options - The options for navigation.
   * @param {boolean} [options.preserveParams=false] - Whether to preserve URL parameters.
   * @param {string} [options.method='push'] - The navigation method ('push' or 'replace').
   * @param {Object} [options.state] - The state to pass to the history.
   */
  const navigate = (path, options = {}) => {
    const { preserveParams = false, method = 'push', state = {} } = options;

    // baseUrl is used only to avoid breaking for relative paths
    const baseUrl = window.location.origin;
    const url = new URL(path, baseUrl);

    if (preserveParams) {
      const currentParams = new URLSearchParams(window.location.search);

      currentParams.forEach((value, key) => {
        // query params from path takes precedence over current query params
        if (!url.searchParams.has(key)) {
          url.searchParams.append(key, value);
        }
      });
    }

    if (!allowedMethods.includes(method)) {
      throw new Error(`Invalid navigation method: ${method}`);
    }

    // Function signature https://github.com/remix-run/history/blob/main/docs/navigation.md
    history[method](`${url.pathname}${url.search}`, state);
  };

  return navigate;
}

export default useNavigator;
