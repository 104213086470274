import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './PlainInput.module.scss';

const PlainInput = React.forwardRef(
  ({ className, id, as: As, invalid, disabled, ...props }, ref) => (
    <As
      id={id}
      className={cx(styles.input, className, {
        [styles.invalid]: invalid,
        [styles.disabled]: disabled,
      })}
      disabled={disabled}
      {...props}
      ref={ref}
    />
  ),
);

PlainInput.displayName = 'PlainInput';

PlainInput.propTypes = {
  id: PropTypes.string.isRequired,
  as: PropTypes.string,
  className: PropTypes.string,
  invalid: PropTypes.bool,
};

PlainInput.defaultProps = {
  as: 'input',
};

export default PlainInput;
