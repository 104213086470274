import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import RadioGroup from '../../../../../../../components/RadioGroup';
import RadiationIcon from '../../../../../../../icons/Radiation';
import ResourcesIcon from '../../../../../../../icons/resources';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './RadeonDisclosure.module.scss';

const RadeonDisclosure = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <>
      <div className={cx(styles.container, className)}>
        <SectionTitle title="Radon Disclosure" icon={RadiationIcon} />
        <label className={styles.label}>
          Is there any known radon hazard located in or around the property?
        </label>
        <p className={styles.description}>
          For rental units on the 2nd floor and below, you are required to
          disclose any known radon hazard in or around the property.
        </p>
        <Field
          component={RadioGroup}
          id="custom_state_data.radon_hazard"
          name="custom_state_data.radon_hazard"
          radiosContainerClassName={styles.radioButtonsContainer}
          options={[
            {
              id: 'radon_hazard_yes',
              value: true,
              label: 'Yes',
            },
            {
              id: 'radon_hazard_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />
      </div>
      <Condition when="custom_state_data.radon_hazard" is={true}>
        <HelpfulInfoCard
          className={styles.helpfulInfoCard}
          noTitle
          Icon={ResourcesIcon}
        >
          A copy of the{' '}
          <a
            target="_blank noopener noreferrer"
            href="https://www2.illinois.gov/iema/NRS/Radon/Documents/radontestguidelineforrealestatePamphlet.pdf"
          >
            radon pamphlet
          </a>{' '}
          prepared by the State of Illinois is required to be attached to your
          lease agreement, which we have done for you.
        </HelpfulInfoCard>
      </Condition>
    </>
  );
};

RadeonDisclosure.propTypes = {
  className: PropTypes.string,
};

export default RadeonDisclosure;
