import React, { memo } from 'react';

import colors from '../constants/colors';

const RadialCircleNew = ({ color, ...props }) => (
  <svg viewBox="0 0 152 152" {...props} fill={props.color}>
    <path
      fillRule="evenodd"
      d="M.51 52.87C30.09 52.87 54.067 29.198 54.067 0h-.957c0 28.632-23.596 51.925-52.6 51.925v.945ZM0 46.26c25.635 0 46.416-20.712 46.416-46.26h-.961c0 24.978-20.392 45.3-45.455 45.3v.96Zm.51-7.625C21.92 38.635 39.275 21.338 39.275 0h-.957c0 20.776-16.96 37.68-37.808 37.68v.955ZM0 32.027c17.746 0 32.134-14.338 32.134-32.027h-.983c0 17.118-13.977 31.045-31.151 31.045v.982Zm.51-8.134c13.24 0 23.973-10.697 23.973-23.893h-.963c0 12.644-10.324 22.931-23.01 22.931v.962ZM0 16.776c9.298 0 16.832-7.512 16.832-16.776h-.985c0 8.709-7.109 15.792-15.847 15.792v.984Zm.51-8.134C5.3 8.642 9.181 4.773 9.181 0h-.933c0 4.253-3.471 7.71-7.738 7.71v.932ZM0 143.866c79.44 0 143.839-64.184 143.839-143.358h-.96c0 78.52-64.097 142.398-142.879 142.398v.96Zm0-15.25c70.988 0 128.537-57.355 128.537-128.108h-.959c0 70.111-57.232 127.15-127.578 127.15v.957Zm0-7.118c67.044 0 121.396-54.17 121.396-120.99h-.963c0 66.184-54.026 120.03-120.433 120.03v.96Zm0-8.133c62.537 0 113.235-50.529 113.235-112.857h-.958C112.277 62.212 61.911 112.41 0 112.41v.955Zm0-6.1c59.158 0 107.114-47.796 107.114-106.757h-.97c0 58.333-47.618 105.79-106.144 105.79v.966Zm.51-8.643c54.93 0 99.463-43.928 99.463-98.114h-.969c0 53.574-44.185 97.158-98.494 97.158v.956ZM0 92.013c50.705 0 91.812-40.968 91.812-91.505h-.968C90.844 50.434 50.092 91.05 0 91.05v.964Zm.51-7.625c46.481 0 84.161-37.555 84.161-83.88h-.966c0 45.72-37.32 82.917-83.195 82.917v.963ZM0 76.763c42.256 0 76.51-34.142 76.51-76.255h-.965c0 41.517-33.89 75.293-75.545 75.293v.962Zm0-15.251c33.803 0 61.208-27.313 61.208-61.004h-.96c0 33.11-27.027 60.047-60.248 60.047v.957Zm.51 7.625c38.03 0 68.86-30.5 68.86-68.12h-.964c0 37.036-30.459 67.168-67.896 67.168v.952ZM0 152c83.948 0 152.002-67.372 152-150.475h-.963C151.037 83.971 83.28 151.042 0 151.042V152Zm0-15.25c75.496 0 136.698-60.543 136.698-135.225h-.963c0 74.037-60.89 134.269-135.735 134.269v.955Z"
    />
  </svg>
);

RadialCircleNew.defaultProps = {
  width: 152,
  height: 152,
  color: colors.warning,
};

export default memo(RadialCircleNew);
