import { stringify } from 'query-string';

import { getConfig } from '../../services/configService';
import { TTfetch } from '../TTfetch';

const smartyStreetsAllowedUrls = [
  /listingsearch/g,
  /rent-estimate-report/g,
  /rent-reporting/g,
  /identity_verification/g,
  /owners\/payments/g,
];

export const getAddressSuggestions = (prefix, pathname, maxResults = 10) => {
  const { SMARTY_STREETS_AUTH_ID } = getConfig();
  const host = 'https://us-autocomplete.api.smartystreets.com/suggest';
  const queryString = stringify({
    key: SMARTY_STREETS_AUTH_ID,
    prefix: decodeURIComponent(prefix),
    suggestions: maxResults,
  });
  const location = (pathname || '').replace(/\/([^/]*[A-Z]+[^/]*)/g, '/*/');
  if (!smartyStreetsAllowedUrls.some((p) => !!location.match(p))) {
    // Router disabled temporarily
    return;
  }
  window.atatus.recordTransaction(
    `SmartyStreets-GetAddressSuggestions-${location}`,
    1,
  );
  return TTfetch(`${host}?${queryString}`, { method: 'GET' })
    .then((res) => {
      if (res.status === 200) {
        return res.json().then((data) => data);
      }
      return false;
    })
    .catch(() => false);
};

export const getUSFullAddress = (address) => {
  const { SMARTY_STREETS_AUTH_ID } = getConfig();
  const host = 'https://us-street.api.smartystreets.com/street-address';
  const queryString = stringify({
    'auth-id': SMARTY_STREETS_AUTH_ID,
    ...address,
    'street': decodeURIComponent(address.street),
  });
  window.atatus.recordTransaction('SmartyStreets-getUSFullAddress', 1);
  return TTfetch(`${host}?${queryString}`, { method: 'GET' })
    .then((res) => {
      if (res.status === 200) {
        return res.json().then((data) => data);
      }
      return false;
    })
    .catch(() => false);
};
