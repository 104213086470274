import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import FileUploadPreview from '../../../../../../../components/FileUploadPreview';
import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import UploadDropZone from '../../../../../../../components/UploadDropZone';
import BugIcon from '../../../../../../../icons/Bug';
import PhotosIcon from '../../../../../../../icons/Photos';
import Security from '../../../../../../../icons/Security';
import TurboTenantSmallLogo from '../../../../../../../icons/TurboTenantSmallLogo';
import { useConfig } from '../../../../../../../providers/ConfigProvider';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './BedBugDisclosure.module.scss';

const BedBugDisclosure = ({
  className,
  uploadFiles,
  handleRemoveAttachment,
  attachmentTypes,
  filesFilter,
}) => {
  const { ASSET_BUCKET_URL } = useConfig();
  const { standAloneWizard } = useStandaloneWizardState();

  const {
    input: { value: attachments },
  } = useField('attachments');

  return (
    <>
      <div className={cx(styles.container, className)}>
        <SectionTitle
          title={
            standAloneWizard
              ? 'Bedbug Disclosure - Available upon saving'
              : 'Bed Bug Disclosure'
          }
          icon={standAloneWizard ? Security : BugIcon}
        />
        <p
          className={cx(styles.description, {
            [styles.disabled]: standAloneWizard,
          })}
        >
          All New York City leases must provide the{' '}
          <a
            href="https://hcr.ny.gov/system/files/documents/2021/07/dbb-n-fillable.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Approved Notice Form
          </a>{' '}
          regarding bedbugs at the property.{' '}
          <strong>We have not attached this</strong>. We recommend you download
          this form, fill it out, and then upload it here.
        </p>
        <Field
          component={FileUploadPreview}
          id="attachments"
          name="attachments"
          onRemove={handleRemoveAttachment}
          filter={filesFilter}
        />
        {!attachments?.find?.(filesFilter) ? (
          <Field
            component={UploadDropZone}
            id="attachments"
            name="attachments"
            accept={attachmentTypes.join(',')}
            uploadFiles={uploadFiles}
            icon={PhotosIcon}
            iconProps={{ width: 30, height: 30 }}
            disabled={standAloneWizard}
          />
        ) : null}
        <HelpfulInfoCard
          Icon={TurboTenantSmallLogo}
          iconProps={{ className: styles.logo }}
          noTitle
          className={styles.helpfulInfoCard}
          color="iceCold"
        >
          It is also required that you attach this{' '}
          <strong>
            <a
              href={`${ASSET_BUCKET_URL}uploads/assets/new-york-city-bed-bug-guide.pdf`}
              target="_blank"
              rel="noopener noreferrer"
            >
              bedbug informational pamphlet
            </a>
          </strong>{' '}
          to the lease. We have already done this for you.
        </HelpfulInfoCard>
      </div>
    </>
  );
};

BedBugDisclosure.propTypes = {
  className: PropTypes.string,
  standaloneWizard: PropTypes.bool,
};

export default BedBugDisclosure;
