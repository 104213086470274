import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import queryString from 'query-string';

import Button from '../../../components/Button';
import Card from '../../../components/Card';
import LoadingScreen from '../../../components/LoadingScreen';
import verifyConfirmationToken from '../../../core/services/tokenService';
import { useConfig } from '../../../providers/ConfigProvider';
import getListingFullAddress from '../../../services/utilities/getListingFullAddress';

import styles from './RenterSMSExternalApplication.module.scss';

const RenterSMSExternalApplication = ({ location, history }) => {
  const { confirmationToken } = queryString.parse(location.search);
  const { PRIVATE_BASE_PATH, PUBLIC_LISTING_URL, IS_OWNER } = useConfig();

  const [checkingToken, setCheckingToken] = useState(true);
  const [rentalRequest, setRentalRequest] = useState({});

  const checkConfirmationToken = async () => {
    try {
      if (!confirmationToken) {
        return history.replace('/auth/login');
      }

      const res = await verifyConfirmationToken(
        `verify?accountType=${
          IS_OWNER ? '1' : '2'
        }&confirmationToken=${confirmationToken}`,
      );

      if (!res) {
        return history.replace('/auth/login');
      }

      setCheckingToken(false);
      setRentalRequest(
        (res.rental_requests.length > 0 && res.rental_requests[0]) || {},
      );
    } catch (error) {
      console.log(error);
      return history.replace('/auth/login');
    }
  };

  useEffect(() => {
    checkConfirmationToken();
  }, []);

  const url = `/setpassword${location.search}&sms=true&action=external_applicant&redirectTo=${PRIVATE_BASE_PATH}identity_verification`;

  const publicListingUrl =
    (rentalRequest?.listing &&
      `${PUBLIC_LISTING_URL}${rentalRequest.listing.id}`) ||
    null;

  let descriptionText = (
    <p>
      Thanks for your interest in{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={publicListingUrl}
        className={styles.link}
      >
        {getListingFullAddress(rentalRequest.listing)}.
      </a>{' '}
      The landlord requested a credit report and background check.
      <br />
      <br />
      For your security, you must verify your identity before the report can be
      sent to the landlord. It only takes a few minutes. The credit check is a
      “soft” inquiry so you don’t have to worry about it affecting your credit
      score!
      <br />
      <br />
      If you don’t want to send the landlord your report,{' '}
      <a href={url} className={styles.link}>
        first set a password for your TurboTenant account
      </a>{' '}
      and then login to deny the request.
    </p>
  );

  if (!rentalRequest.landlord_pays) {
    descriptionText = (
      <p>
        Thanks for your interest in{' '}
        <a
          href={publicListingUrl}
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {getListingFullAddress(rentalRequest.listing)}.
        </a>
        <br />
        <br />
        The landlord requested a credit report and background check.
        <br />
        <br />
        For your security, you must verify your identity before the report can
        be sent to the landlord.{' '}
        <span className={styles.bold}>
          You also need to pay the ${rentalRequest.application_fee} fee for the
          report.
        </span>{' '}
        It only takes a few minutes. The credit check is a “soft” inquiry so you
        don’t have to worry about it affecting your credit score!
        <br />
        <br />
        If you don’t want to send the landlord your report,{' '}
        <a href={url} className={styles.link}>
          first set a password for your TurboTenant account
        </a>{' '}
        and then login to deny the request.
      </p>
    );
  }
  return (
    <LoadingScreen loading={checkingToken} style={{ paddingTop: 75 }}>
      {!checkingToken && (
        <Card className={styles.card}>
          <div className={styles.container}>
            <div className={styles.textContainer}>
              <p className={styles.title} id="congrats_title">
                Approve a credit & background check by verifying your identity
              </p>
              <div>{descriptionText}</div>
            </div>

            <div className={styles.buttonContainer}>
              <Button
                className={styles.button}
                onClick={() => history.replace(url)}
                id={'done_button'}
              >
                Approve Screening Request
              </Button>
            </div>
          </div>
        </Card>
      )}
    </LoadingScreen>
  );
};

RenterSMSExternalApplication.propTypes = {
  location: object.isRequired,
  history: object.isRequired,
  styles: object,
  config: object,
};

export default RenterSMSExternalApplication;
