import React, { useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import BenefitItemsMobile from './BenefitItemsMobile';
import BenefitSection from './BenefitSection';
import PackageHeader from './PackageHeader';

import styles from './BenefitsView.module.scss';

const BenefitsView = ({
  benefits,
  selectedBenefit,
  extraParams,
  displayMonthlyPrice,
  proPrice,
  premiumPlusPrice,
  onGetPro,
  onGetPremiumPlus,
  premiumPrice,
  onGetPremium,
  isMobile,
  onClickSegment,
}) => {
  const premiumPackageRef = useRef(null);
  const premiumPlusPackageRef = useRef(null);

  const scrollToPackage = (ref) => {
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <>
      {isMobile ? (
        <>
          <div
            className={cx(styles.packageMobile, styles.pro)}
            ref={premiumPackageRef}
          >
            <PackageHeader
              title="Premium"
              price={premiumPrice}
              displayMonthlyPrice={displayMonthlyPrice}
              onButtonClick={onGetPremium}
            />
            <BenefitItemsMobile
              benefits={benefits}
              selectedBenefit={selectedBenefit}
              extraParams={extraParams}
              scrollToPremiumPlus={() => scrollToPackage(premiumPlusPackageRef)}
              onClickSegment={onClickSegment}
            />
          </div>
          <div
            className={cx(styles.packageMobile, styles.premium)}
            ref={premiumPlusPackageRef}
          >
            <PackageHeader
              title="Premium Plus"
              price={premiumPlusPrice}
              displayMonthlyPrice={displayMonthlyPrice}
              onButtonClick={onGetPremiumPlus}
              isPremiumPlus
            />
            <BenefitItemsMobile
              benefits={benefits}
              selectedBenefit={selectedBenefit}
              extraParams={extraParams}
              onClickSegment={onClickSegment}
              isPremiumPlus
            />
          </div>
          <div className={cx(styles.packageMobile, styles.pro)}>
            <PackageHeader
              title="Pro"
              price={proPrice}
              displayMonthlyPrice={displayMonthlyPrice}
              onButtonClick={onGetPro}
            />
            <BenefitItemsMobile
              benefits={benefits}
              selectedBenefit={selectedBenefit}
              extraParams={extraParams}
              scrollToPremium={() => scrollToPackage(premiumPackageRef)}
              onClickSegment={onClickSegment}
              isPro
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.headerRow}>
            <PackageHeader
              title="Pro"
              price={proPrice}
              displayMonthlyPrice={displayMonthlyPrice}
              onButtonClick={onGetPro}
            />
            <PackageHeader
              title="Premium"
              price={premiumPrice}
              displayMonthlyPrice={displayMonthlyPrice}
              onButtonClick={onGetPremium}
            />
            <PackageHeader
              title="Premium Plus"
              price={premiumPlusPrice}
              displayMonthlyPrice={displayMonthlyPrice}
              onButtonClick={onGetPremiumPlus}
              isPremiumPlus
            />
          </div>
          <BenefitSection
            benefits={benefits}
            selectedBenefit={selectedBenefit}
            extraParams={extraParams}
          />
        </>
      )}
    </>
  );
};

BenefitsView.propTypes = {
  benefits: PropTypes.array.isRequired,
  selectedBenefit: PropTypes.string.isRequired,
  extraParams: PropTypes.object.isRequired,
  displayMonthlyPrice: PropTypes.bool.isRequired,
  proPrice: PropTypes.number.isRequired,
  premiumPlusPrice: PropTypes.number.isRequired,
  onGetPro: PropTypes.func.isRequired,
  onGetPremiumPlus: PropTypes.func.isRequired,
  premiumPrice: PropTypes.number.isRequired,
  onGetPremium: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onClickSegment: PropTypes.func.isRequired,
};

export default BenefitsView;
