import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import CodeViolation from '../../../../../../../icons/CodeViolation';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './CodeViolationsDC.module.scss';

const CodeViolationsDC = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle
        title="Code Violations"
        icon={CodeViolation}
        className={styles.sectionTitle}
      />
      <label className={styles.label}>
        Do you have any past housing and/or property maintenance code violation
        reports made on the premises within the past 12 months that have not
        been fixed?
      </label>
      <p className={styles.description}>
        District of Columbia law requires that you disclose this.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.has_code_violations"
        name="custom_state_data.has_code_violations"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'has_code_violations_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'has_code_violations_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition when="custom_state_data.has_code_violations" is={true}>
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.code_violations_explanation"
          name="custom_state_data.code_violations_explanation"
          label="If there are any past code violations made within the past 12 months
            on the premises which have not been fixed, please describe all
            housing code and property maintenance code violations and plans for
            repair. Examples of code violations include hot or cold water not
            running, structural issues or non-operational heating, electrical or
            plumbing conditions:"
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
            className: styles.termsField,
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      </Condition>
    </div>
  );
};

CodeViolationsDC.propTypes = {
  className: PropTypes.string,
};

export default CodeViolationsDC;
