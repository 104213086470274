import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const ApplicantThick = (props) => (
  <svg {...props} viewBox="0 0 20 24" fill={props.color}>
    <path d="M7.667 0l1.166 2.4h9.917c.322 0 .583.269.583.6v19.8c0 .663-.522 1.2-1.166 1.2H1.833c-.644 0-1.166-.537-1.166-1.2V.6c0-.331.26-.6.583-.6h6.417zM6.585 1.8H2.417v20.4h15.166v-18H7.752L6.585 1.8zM10 6.6c2.087 0 3.774 1.746 3.774 3.895a3.98 3.98 0 01-.5 1.937l.118.07c1.35.853 2.154 2.106 2.397 3.82l.035.29.202 1.988H3.974l.202-1.987c.19-1.865 1.007-3.211 2.432-4.111l.115-.07a3.986 3.986 0 01-.49-1.677l-.007-.258C6.226 8.346 7.91 6.6 10 6.6zm0 1.8c-1.12 0-2.024.937-2.024 2.097 0 .704.336 1.346.89 1.737.466.328.367 1.066-.168 1.252-1.708.592-2.602 1.551-2.781 3.314h8.166l-.032-.258c-.238-1.599-1.128-2.493-2.75-3.056-.534-.186-.633-.923-.167-1.252a2.12 2.12 0 00.89-1.739c0-1.157-.907-2.095-2.024-2.095z" />
  </svg>
);

ApplicantThick.defaultProps = {
  width: 20,
  height: 24,
  color: colors.text,
};

ApplicantThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ApplicantThick);
