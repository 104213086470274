import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import colors from '../../../constants/colors';
import { REIHUB_PACKAGE_ITEMS } from '../../../constants/reihub/reihubPackageItems';
import userReiHubPricing from '../../../core/hooks/userReiHubPricing';
import getReiHubPrice from '../../../helpers/getReiHubPrice';
import openInNewTab from '../../../helpers/openInNewTab';
import CheckMarkFilled from '../../../icons/CheckMarkFilled';
import HelpfulInfoList from '../../HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../HelpfulInfo/HelpfulInfoListItem';
import Stepper from '../../Stepper';
import Tag from '../../Tag';
import { H3, H4, Link, Paragraph } from '../../Typography';

import styles from './ReiHubOfferModalContent.module.scss';

const ReiHubOfferModalContent = ({
  unitCount,
  setUnitCount,
  listingsCount,
  privateBasePath,
  sendSegmentEvent,
  listItems,
  titlePrefix,
  text,
  showExpirationDate,
}) => {
  const onLearnMore = () => {
    sendSegmentEvent('learn_more_about_rental_accounting_link clicked');
    openInNewTab(`${privateBasePath}payments/accounting`);
  };

  const pricingMap = userReiHubPricing();
  const reiHubPriceData = getReiHubPrice(pricingMap, unitCount);

  return (
    <div className={styles.contentWrapper}>
      <Tag accent small uppercase={false} className={styles.tag}>
        Limited Time Offer
      </Tag>
      <H3>
        {titlePrefix}{' '}
        <span className={styles.savePrice}>
          save ${reiHubPriceData?.savings}
        </span>{' '}
        on rental accounting!
      </H3>
      <div className={styles.textWrapper}>
        <Paragraph size="large">{text}</Paragraph>
        {showExpirationDate && (
          <Paragraph size="large" className={styles.paragraph} italic>
            Offer expires in 7 days on{' '}
            {moment().add(7, 'days').format('MMMM D, YYYY')}.
          </Paragraph>
        )}
      </div>
      <Stepper
        className={styles.stepper}
        input={{
          value: unitCount,
          onChange: (val) => setUnitCount(val),
        }}
        id="stepper"
        label="How many units do you own or manage?"
        small
        meta={{
          touched: false,
          error: '',
        }}
        min={listingsCount}
      />
      <H4 className={styles.subTitle}>Rental Accounting powered by REI Hub</H4>
      <div className={styles.priceHolder}>
        <Paragraph className={styles.priceWrapper}>
          <span className={styles.oldPrice}>
            ${reiHubPriceData?.slashedPrice}
          </span>
          <span className={styles.price}>${reiHubPriceData?.price}</span>
          <span className={styles.priceType}>per year</span>
        </Paragraph>
      </div>

      <HelpfulInfoList className={styles.offerListWrapper}>
        {listItems.map((item, index) => (
          <HelpfulInfoListItem
            icon={CheckMarkFilled}
            iconProps={{ color: colors.primary }}
            className={styles.listItem}
            key={index}
          >
            {item}
          </HelpfulInfoListItem>
        ))}
      </HelpfulInfoList>

      <Link
        className={styles.learnMoreLink}
        onClick={(e) => {
          e.preventDefault();
          onLearnMore();
        }}
      >
        Learn more about Rental Accounting
      </Link>
    </div>
  );
};

ReiHubOfferModalContent.defaultProps = {
  titlePrefix: 'Bundle and',
  text: `It’s never too early to get organized with automated bookkeeping. You’ll turn next tax season into “relax” season!`,
  listItems: REIHUB_PACKAGE_ITEMS,
  showExpirationDate: false,
};

ReiHubOfferModalContent.propTypes = {
  unitCount: PropTypes.number.isRequired,
  setUnitCount: PropTypes.func.isRequired,
  listingsCount: PropTypes.number.isRequired,
  privateBasePath: PropTypes.string.isRequired,
  sendSegmentEvent: PropTypes.func.isRequired,
  listItems: PropTypes.arrayOf(PropTypes.string),
  titlePrefix: PropTypes.string.isRequired,
  text: PropTypes.string,
  showExpirationDate: PropTypes.bool,
};

export default ReiHubOfferModalContent;
