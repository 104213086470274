import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import DateField from '../../../../../../../components/DateField';
import RadioGroup from '../../../../../../../components/RadioGroup';
import WaterThickIcon from '../../../../../../../icons/WaterThick';
import composeValidators from '../../../../../../../validators/composeValidators';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import isDate from '../../../../../../../validators/isDate';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './SprinklerDisclosure.module.scss';

const SprinklerDisclosure = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  const {
    input: { value: isThereFireSprinklerSystem },
  } = useField('custom_state_data.is_there_fire_sprinkler_system');

  return (
    <>
      <div className={cx(styles.container, className)}>
        <SectionTitle title="Sprinkler Disclosure" icon={WaterThickIcon} />
        <label className={styles.label}>
          Is there an operative fire sprinkler system on the property?
        </label>
        <p className={styles.description}>
          You are required to provide notice of the existence of an operable
          fire sprinkler system located on the property and the last date of
          maintenance and inspection.
        </p>
        <Field
          component={RadioGroup}
          id="custom_state_data.is_there_fire_sprinkler_system"
          name="custom_state_data.is_there_fire_sprinkler_system"
          radiosContainerClassName={styles.radioButtonsContainer}
          options={[
            {
              id: 'is_there_fire_sprinkler_system_yes',
              value: true,
              label: 'Yes',
            },
            {
              id: 'is_there_fire_sprinkler_system_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />
        {isThereFireSprinklerSystem === true && (
          <Field
            className={styles.date}
            label=" Date of last maintenance and inspection:"
            component={DateField}
            name="custom_state_data.fire_sprinkler_system_last_maintenance_date"
            id="custom_state_data.fire_sprinkler_system_last_maintenance_date"
            validate={composeValidators(fieldRequired, isDate)}
          />
        )}
      </div>
    </>
  );
};

SprinklerDisclosure.propTypes = {
  className: PropTypes.string,
};

export default SprinklerDisclosure;
