/**
 * Returns the list of bank accounts belonging to the user.
 * This is used to show the proper bank account attached to a payment
 *
 * @param {object} user
 * @returns
 */
export const getUserBankAccounts = (user) => {
  return user?.payments_data?.active_external_accounts || [];
};
