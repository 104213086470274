import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import parseScreeningReportDate from '../../../../../helpers/parseScreeningReportDate';
import toCurrency from '../../../../../helpers/toCurrency';

import styles from './CollectionSection.module.scss';

const CollectionSection = ({ report }) => {
  return (
    <div className={cx(styles.flexColumn, styles.container)}>
      <span className={styles.iconNumber}>Collections</span>
      <div className={styles.flexColumn}>
        <div style={{ marginTop: 12 }}>
          <div className={styles.tradelinesContent}>
            <div className={styles.resultBlock}>
              <span className={styles.iconNumber}>
                {report.collections.length}
              </span>
              <span>Count</span>
            </div>
            <div className={styles.resultBlock}>
              <span className={styles.iconNumber}>
                {isNaN(report.collections_balance_total)
                  ? report.collections_balance_total
                  : toCurrency(report.collections_balance_total)}
              </span>
              <span>Balance Total</span>
            </div>
          </div>
        </div>
        {report.collections.map((collection, idx) => {
          return (
            <div
              key={idx}
              className={styles.hideSmall}
              style={{ marginTop: 28 }}
            >
              <span className={styles.iconFooter}>COLLECTION</span>
              <div className={styles.flexColumn} style={{ marginTop: 12 }}>
                <div className={styles.fedloanRow}>
                  <div className={styles.fedloanItem}>
                    <span className={styles.sectionTitle}>Industry</span>
                    <span className={styles.marginTop4}>
                      {collection.industryCode || 'N/A'}
                    </span>
                  </div>

                  <div className={styles.fedloanItem}>
                    <span className={styles.sectionTitle}>Open</span>
                    <span className={styles.marginTop4}>
                      {parseScreeningReportDate(collection.dateOpened)}
                    </span>
                  </div>

                  <div className={styles.fedloanItem}>
                    <span className={styles.sectionTitle}>Balance</span>
                    <span className={styles.marginTop4}>
                      {collection.currentBalance !== null
                        ? toCurrency(collection.currentBalance)
                        : 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.flexColumn} style={{ marginTop: 12 }}>
                <div className={styles.fedloanRow}>
                  <div className={styles.fedloanItem}>
                    <span className={styles.sectionTitle}>Agency Name</span>
                    <span className={styles.marginTop4}>
                      {collection.collectionAgencyName || 'N/A'}
                    </span>
                  </div>

                  <div className={styles.fedloanItem}>
                    <span className={styles.sectionTitle}>Verified</span>
                    <span className={styles.marginTop4}>
                      {parseScreeningReportDate(collection.dateVerified)}
                    </span>
                  </div>

                  <div className={styles.fedloanItem}>
                    <span className={styles.sectionTitle}>
                      Original Balance
                    </span>
                    <span className={styles.marginTop4}>
                      {collection.highCredit !== null
                        ? toCurrency(collection.highCredit)
                        : 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.flexColumn} style={{ marginTop: 12 }}>
                <div className={styles.fedloanRow}>
                  <div className={styles.fedloanItem}>
                    <span className={styles.sectionTitle}>Loan Type</span>
                    <span className={styles.marginTop4}>
                      {collection.loanType || 'N/A'}
                    </span>
                  </div>

                  <div className={styles.fedloanItem}>
                    <span className={styles.sectionTitle}>Closed</span>
                    <span className={styles.marginTop4}>
                      {parseScreeningReportDate(collection.dateClosed)}
                    </span>
                  </div>

                  <div className={styles.fedloanItem}>
                    <span className={styles.sectionTitle}>Past Due</span>
                    <span className={styles.marginTop4}>
                      {collection.pastDue || 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.flexColumn} style={{ marginTop: 12 }}>
                <div className={styles.fedloanRow}>
                  <div className={styles.fedloanItem}>
                    <span className={styles.sectionTitle}>Account Type</span>
                    <span className={styles.marginTop4}>
                      {collection.accountType || 'N/A'}
                    </span>
                  </div>

                  <div className={styles.fedloanItem}>
                    <span className={styles.sectionTitle}>Paid</span>
                    <span className={styles.marginTop4}>
                      {parseScreeningReportDate(collection.datePaidOut)}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.flexColumn} style={{ marginTop: 12 }}>
                <div className={styles.fedloanRow}>
                  <div className={styles.fedloanItem}>
                    <span className={styles.sectionTitle}>Status</span>
                    <span className={styles.marginTop4}>
                      {collection.status || 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.flexColumn} style={{ marginTop: 12 }}>
                <div className={styles.fedloanRow}>
                  <div className={styles.fedloanItem}>
                    <span className={styles.sectionTitle}>Remarks</span>
                    <span className={styles.marginTop4}>
                      {collection.remarks || 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.flexColumn} style={{ marginTop: 12 }}>
                <div className={styles.fedloanRow}>
                  <div className={styles.fedloanItem}>
                    <span className={styles.sectionTitle}>Comment</span>
                    <span className={styles.marginTop4}>
                      {collection.comment || 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.flexColumn} style={{ marginTop: 12 }}>
                <div className={styles.fedloanRow}>
                  <div className={styles.fedloanItem}>
                    <span className={styles.sectionTitle}>Credit Grantor</span>
                    <span className={styles.marginTop4}>
                      {collection.creditorsName || 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

CollectionSection.propTypes = {
  report: PropTypes.object,
};

export default CollectionSection;
