import PROPERTY_ROLES from './propertyRolesEnum';

const addUnitSuffix = (property) => {
  if (
    property?.property_role === PROPERTY_ROLES.SINGLE_UNIT &&
    property?.unit
  ) {
    return `, ${property.unit}`;
  }

  return '';
};

export default addUnitSuffix;
