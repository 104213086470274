import { v4 as uuidv4 } from 'uuid';

/**
 * This method will try to retrieve the IDK from the session storage and return it.
 * In case that non IDK exists already in the session storage, it will create a new one
 * uuidv4 key and save it to the session storage for further uses if needed.
 * @returns uuidv4 idempotencyKey
 */
export const createAndSaveIdempotencyKey = () => {
  let idempotencyKey = sessionStorage.getItem('idempotencyKey');
  if (!idempotencyKey) {
    idempotencyKey = uuidv4();
    sessionStorage.setItem('idempotencyKey', idempotencyKey);
  }
  return idempotencyKey;
};
