import React from 'react';
import PropTypes from 'prop-types';

import Bullet from '../../../../../icons/Bullet';

import ScoreInfo from './ScoreInfo';

import styles from './ScreeningScoreBar.module.scss';

const ScreeningScoreBar = ({ score }) => {
  const marginLeft = `calc(${(Number(score) - 350) / 5}% - 7px)`;

  return (
    <div className={styles.container}>
      <div className={styles.scoreWrapper}>
        <span className={styles.score}>{score}</span>
        <ScoreInfo score={score} />
      </div>
      <Bullet
        className={styles.icon}
        color={'#000'}
        height={14}
        width={14}
        style={{ marginLeft }}
      />
      <div className={styles.scoreBar}>
        <div className={styles.scoreBarSectionRed} />
        <div className={styles.scoreBarSectionYellow} />
        <div className={styles.scoreBarSectionLightGreen} />
        <div className={styles.scoreBarSectionGreen} />
      </div>
      <div className={styles.scoreBar}>
        <span className={styles.scoreBarLabel}>350</span>
        <span
          className={styles.scoreBarLabel}
          style={{ marginLeft: `calc(34% - 42px)` }}
        >
          520
        </span>
        <span
          className={styles.scoreBarLabel}
          style={{ marginLeft: `calc(24% - 28px)` }}
        >
          640
        </span>
        <span
          className={styles.scoreBarLabel}
          style={{ marginLeft: `calc(16% - 28px)` }}
        >
          720
        </span>
        <span
          className={styles.scoreBarLabel}
          style={{ marginLeft: `calc(26% - 40.5px)` }}
        >
          850
        </span>
      </div>
    </div>
  );
};

ScreeningScoreBar.propTypes = {
  score: PropTypes.number,
};

export default ScreeningScoreBar;
