import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Applicant = (props) => (
  <svg {...props} viewBox="0 0 40 41">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.307 3.488c.369 0 .705.22.862.564l1.533 3.358H32.38c.526 0 .952.439.952.98v27.451c0 .542-.426.98-.952.98H7.619c-.526 0-.952-.438-.952-.98V4.47c0-.542.426-.98.952-.98h8.688Zm-.605 1.961h-7.13v29.412h22.857V9.37H18.097a.95.95 0 0 1-.862-.564L15.702 5.45ZM20 13.292c2.764 0 5.006 2.332 5.006 5.208a5.34 5.34 0 0 1-.97 3.083c2.662 1.384 4.06 3.696 4.06 7.328 0 .579-.452 1.048-1.01 1.048H12.914c-.557 0-1.008-.47-1.008-1.048 0-3.633 1.395-5.945 4.058-7.328a5.336 5.336 0 0 1-.968-3.08c0-2.88 2.237-5.21 5.005-5.21Zm0 2.096c-1.653 0-2.989 1.392-2.989 3.115 0 1.045.496 1.999 1.314 2.579.689.488.543 1.584-.247 1.86-2.521.878-3.842 2.303-4.107 4.92H26.03c-.265-2.615-1.587-4.041-4.107-4.921-.79-.276-.935-1.37-.248-1.859A3.153 3.153 0 0 0 22.99 18.5c0-1.719-1.34-3.112-2.989-3.112Z"
      fill={colors.text}
    />
  </svg>
);

Applicant.defaultProps = {
  color: colors.text,
};

Applicant.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Applicant);
