export const LulaStatusesEnum = {
  SCHEDULING: 'SCHEDULING',
  SCHEDULED: 'SCHEDULED',
  PRO_DISPATCHED: 'PRO_DISPATCHED',
  QUOTE_NEEDS_REVIEW: 'QUOTE_NEEDS_REVIEW',
  QUALITY_CHECK: 'QUALITY_CHECK',
  PAUSED: 'PAUSED',
  TROUBLESHOOTING: 'TROUBLESHOOTING',
  SENT_TO_LULA: 'SENT_TO_LULA',
  QUOTE_REJECTED: 'QUOTE_REJECTED',
  QUOTE_EXPIRED: 'QUOTE_EXPIRED',
  QUOTE_APPROVED: 'QUOTE_APPROVED',
  QUOTING: 'QUOTING',
};

const MaintenanceRequestStatusEnum = {
  NEW: 'NEW',
  OPEN: 'OPEN',
  IN_PROGRESS: 'IN_PROGRESS',
  DISMISSED: 'DISMISSED',
  RESOLVED: 'RESOLVED',
  ...LulaStatusesEnum,
};

export const MaintenanceRequestSelectableStatusesEnum = {
  OPEN: MaintenanceRequestStatusEnum.OPEN,
  IN_PROGRESS: MaintenanceRequestStatusEnum.IN_PROGRESS,
  DISMISSED: MaintenanceRequestStatusEnum.DISMISSED,
  RESOLVED: MaintenanceRequestStatusEnum.RESOLVED,
};

export default MaintenanceRequestStatusEnum;
