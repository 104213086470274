import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Time = ({ color, ...props }) => (
  <svg {...props} fill={color} viewBox="0 0 16 17">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99994 2.5666C4.72305 2.5666 2.0666 5.22305 2.0666 8.49994C2.0666 11.7768 4.72305 14.4333 7.99994 14.4333C11.2768 14.4333 13.9333 11.7768 13.9333 8.49994C13.9333 5.22305 11.2768 2.5666 7.99994 2.5666ZM1.2666 8.49994C1.2666 4.78122 4.28122 1.7666 7.99994 1.7666C11.7187 1.7666 14.7333 4.78122 14.7333 8.49994C14.7333 12.2187 11.7187 15.2333 7.99994 15.2333C4.28122 15.2333 1.2666 12.2187 1.2666 8.49994Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.72457 5.07095C7.94549 5.07095 8.12457 5.25004 8.12457 5.47095V8.32397L11.2992 11.2341C11.4621 11.3833 11.4731 11.6364 11.3238 11.7992C11.1745 11.9621 10.9215 11.9731 10.7586 11.8238L7.45428 8.7948C7.37163 8.71903 7.32457 8.61206 7.32457 8.49994V5.47095C7.32457 5.25004 7.50366 5.07095 7.72457 5.07095Z"
      fill={color}
    />
  </svg>
);

Time.defaultProps = {
  width: 16,
  height: 17,
  color: colors.text,
};

Time.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Time);
