import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { H5 } from '../../../../Typography';
import Benefit from '../Benefit';

import styles from './BenefitSection.module.scss';

const BenefitSection = ({ benefits, selectedBenefit, extraParams }) => {
  return benefits.map((benefit) => (
    <div
      className={cx(
        styles.benefitsGroup,
        { [styles.all]: selectedBenefit === 'all_features' },
        {
          [styles.show]:
            selectedBenefit === benefit.key ||
            selectedBenefit === 'all_features',
        },
      )}
      key={benefit.key}
    >
      <div className={styles.proBenefit}>
        {selectedBenefit === 'all_features' && (
          <H5>{benefit.titleShort || benefit.title}</H5>
        )}
        {benefit.items
          .filter((item) => item.plan !== 'premium') // Display items for Pro plan only
          .map((item) => (
            <Benefit
              key={item.key}
              item={item}
              extraParams={extraParams}
              isProPlan={true}
            />
          ))}
      </div>

      <div className={styles.premiumBenefit}>
        {selectedBenefit === 'all_features' && (
          <H5>{benefit.titleShort || benefit.title}</H5>
        )}
        {benefit.items
          .filter((item) => item.plan !== 'pro') // Display items for Premium plan only
          .map((item) => (
            <Benefit key={item.key} item={item} extraParams={extraParams} />
          ))}
      </div>
    </div>
  ));
};

BenefitSection.propTypes = {
  benefits: PropTypes.array.isRequired,
  selectedBenefit: PropTypes.string.isRequired,
  extraParams: PropTypes.object.isRequired,
};

export default BenefitSection;
