import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RadioGroup from '../../../../../../../components/RadioGroup';
import WaterThickIcon from '../../../../../../../icons/WaterThick';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './FloodZone.module.scss';

const FloodZone = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <>
      <div className={cx(styles.container, className)}>
        <SectionTitle title="Flood Zone Disclosure" icon={WaterThickIcon} />
        <label className={styles.label}>
          Is this property in a flood zone?
        </label>
        <Field
          component={RadioGroup}
          id="custom_state_data.flood_zone"
          name="custom_state_data.flood_zone"
          radiosContainerClassName={styles.radioButtonsContainer}
          options={[
            {
              id: 'flood_zone_yes',
              value: true,
              label: 'Yes',
            },
            {
              id: 'flood_zone_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />
      </div>
    </>
  );
};

FloodZone.propTypes = {
  className: PropTypes.string,
};

export default FloodZone;
