import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const InformationCircleThick = (props) => (
  <svg {...props} viewBox="0 0 22 22" fill={props.color}>
    <path d="M11 .333C16.89.333 21.667 5.11 21.667 11c0 5.89-4.777 10.667-10.667 10.667C5.108 21.667.333 16.89.333 11S5.108.333 11 .333zm0 1.778A8.888 8.888 0 002.111 11 8.89 8.89 0 1011 2.111zm0 5.778c.49 0 .889.398.889.889v7.11h1.778a.889.889 0 010 1.779H8.333a.889.889 0 010-1.778h1.777l.001-6.222h-.889a.889.889 0 110-1.778H11zm-.444-4a1.333 1.333 0 110 2.666 1.333 1.333 0 010-2.666z" />
  </svg>
);

InformationCircleThick.defaultProps = {
  width: 22,
  height: 22,
  color: colors.greyLight,
};

InformationCircleThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(InformationCircleThick);
