import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <defs>
        <clipPath id="pets-colored-i0">
          <path d="M1280 0v1772H0V0h1280z"></path>
        </clipPath>
        <clipPath id="pets-colored-i1">
          <path d="M5.061 0c2.898 0 5.06 3.458 5.06 7.593s-2.162 7.593-5.06 7.593C2.163 15.186 0 11.728 0 7.593S2.163 0 5.061 0zm0 1.698c-1.76 0-3.374 2.58-3.374 5.895 0 3.315 1.613 5.895 3.374 5.895 1.76 0 3.374-2.58 3.374-5.895 0-3.315-1.613-5.895-3.374-5.895z"></path>
        </clipPath>
        <clipPath id="pets-colored-i2">
          <path d="M3.374 0c1.76 0 3.374 2.58 3.374 5.895 0 3.315-1.613 5.895-3.374 5.895C1.614 11.79 0 9.21 0 5.895 0 2.58 1.613 0 3.374 0z"></path>
        </clipPath>
        <clipPath id="pets-colored-i3">
          <path d="M9.353 6.787c.15 3.793-1.716 7.054-4.397 7.157-2.684.11-4.798-2.99-4.948-6.782C-.14 3.369 1.722.11 4.407.002 7.09-.102 9.204 2.998 9.353 6.788zM4.473 1.7c-1.547.062-2.896 2.421-2.779 5.394.117 2.973 1.648 5.217 3.196 5.153 1.544-.06 2.894-2.42 2.777-5.393C7.55 3.884 6.02 1.64 4.473 1.7z"></path>
        </clipPath>
        <clipPath id="pets-colored-i4">
          <path d="M2.786.001c1.547-.06 3.077 2.184 3.194 5.155.117 2.973-1.233 5.333-2.777 5.393-1.548.063-3.079-2.18-3.196-5.153C-.11 2.423 1.24.063 2.787.001z"></path>
        </clipPath>
        <clipPath id="pets-colored-i5">
          <path d="M3.374 0c1.76 0 3.374 2.58 3.374 5.895 0 3.315-1.613 5.895-3.374 5.895C1.614 11.79 0 9.21 0 5.895 0 2.58 1.613 0 3.374 0z"></path>
        </clipPath>
        <clipPath id="pets-colored-i6">
          <path d="M5.061 0c2.898 0 5.06 3.458 5.06 7.593s-2.162 7.593-5.06 7.593C2.163 15.186 0 11.728 0 7.593S2.163 0 5.061 0zm0 1.698c-1.76 0-3.374 2.58-3.374 5.895 0 3.315 1.613 5.895 3.374 5.895 1.76 0 3.374-2.58 3.374-5.895 0-3.315-1.613-5.895-3.374-5.895z"></path>
        </clipPath>
        <clipPath id="pets-colored-i7">
          <path d="M3.202.001C4.749.063 6.097 2.423 5.98 5.396c-.117 2.972-1.647 5.216-3.193 5.153C1.24 10.49-.11 8.13.007 5.156.124 2.186 1.655-.06 3.202 0z"></path>
        </clipPath>
        <clipPath id="pets-colored-i8">
          <path d="M4.956.003C7.639.11 9.502 3.369 9.353 7.162c-.15 3.792-2.264 6.892-4.946 6.782C1.724 13.841-.14 10.58.008 6.787c.15-3.79 2.264-6.89 4.948-6.784zM4.889 1.7c-1.547-.061-3.078 2.184-3.195 5.154-.117 2.973 1.233 5.334 2.78 5.393 1.546.064 3.076-2.18 3.193-5.153C7.784 4.121 6.436 1.762 4.89 1.7z"></path>
        </clipPath>
        <clipPath id="pets-colored-i9">
          <path d="M12.355 0c3.5 0 5.774 2.993 6.584 7.054.127.64.518 1.298 1.247 2.148.186.217.392.445.662.736l.468.499.194.208.065.07c2.176 2.341 3.136 3.997 3.136 6.358 0 10.06-24.711 10.06-24.711 0 0-2.361.96-4.017 3.136-6.359l.065-.069.194-.208.468-.5c.27-.29.476-.518.662-.735.73-.85 1.12-1.508 1.247-2.148C6.582 2.994 8.856 0 12.355 0zm0 1.698c-2.49 0-4.258 2.327-4.928 5.69-.196.987-.722 1.872-1.625 2.924-.204.237-.423.48-.708.786l-.726.777c-1.907 2.052-2.681 3.387-2.681 5.198 0 7.796 21.337 7.796 21.337 0 0-1.811-.774-3.146-2.681-5.198l-.726-.777a25.876 25.876 0 01-.708-.786c-.903-1.052-1.429-1.937-1.625-2.924-.67-3.363-2.437-5.69-4.929-5.69z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#pets-colored-i0)" transform="translate(-312 -591)">
        <g
          clipPath="url(#pets-colored-i1)"
          transform="translate(260 393) translate(32 80) translate(0 97) translate(16 16) translate(4 5) translate(0 9.026)"
        >
          <path
            fill="#032238"
            d="M0 0L10.121927 0 10.121927 15.1862983 0 15.1862983 0 0z"
          ></path>
        </g>
        <g
          clipPath="url(#pets-colored-i2)"
          transform="translate(260 393) translate(32 80) translate(0 97) translate(16 16) translate(4 5) translate(1.687 10.724)"
        >
          <path
            fill="#7FE3FF"
            d="M0 0L6.74795136 0 6.74795136 11.789676 0 11.789676 0 0z"
          ></path>
        </g>
        <g
          clipPath="url(#pets-colored-i3)"
          transform="translate(260 393) translate(32 80) translate(0 97) translate(16 16) translate(4 5) translate(9.735)"
        >
          <path
            fill="#032238"
            d="M0 -4.33680869e-19L9.36134099 -4.33680869e-19 9.36134099 13.9472371 0 13.9472371 0 -4.33680869e-19z"
          ></path>
        </g>
        <g
          clipPath="url(#pets-colored-i4)"
          transform="translate(260 393) translate(32 80) translate(0 97) translate(16 16) translate(4 5) translate(11.422 1.698)"
        >
          <path
            fill="#7FE3FF"
            d="M8.8817842e-16 -7.85700394e-11L5.98745851 -7.85700394e-11 5.98745851 10.5503258 8.8817842e-16 10.5503258 8.8817842e-16 -7.85700394e-11z"
          ></path>
        </g>
        <g
          clipPath="url(#pets-colored-i5)"
          transform="translate(260 393) translate(32 80) translate(0 97) translate(16 16) translate(4 5) translate(31.565 10.724)"
        >
          <path
            fill="#7FE3FF"
            d="M0 0L6.74795136 0 6.74795136 11.789676 0 11.789676 0 0z"
          ></path>
        </g>
        <g
          clipPath="url(#pets-colored-i6)"
          transform="translate(260 393) translate(32 80) translate(0 97) translate(16 16) translate(4 5) translate(29.878 9.026)"
        >
          <path
            fill="#032238"
            d="M0 0L10.121927 0 10.121927 15.1862983 0 15.1862983 0 0z"
          ></path>
        </g>
        <g
          clipPath="url(#pets-colored-i7)"
          transform="translate(260 393) translate(32 80) translate(0 97) translate(16 16) translate(4 5) translate(22.59 1.698)"
        >
          <path
            fill="#7FE3FF"
            d="M-0.00000117514844 -1.14058069e-16L5.98745784 -1.14058069e-16 5.98745784 10.550376 -0.00000117514844 10.550376 -0.00000117514844 -1.14058069e-16z"
          ></path>
        </g>
        <g
          clipPath="url(#pets-colored-i8)"
          transform="translate(260 393) translate(32 80) translate(0 97) translate(16 16) translate(4 5) translate(20.903)"
        >
          <path
            fill="#032238"
            d="M8.8817842e-16 1.5373125e-10L9.36134151 1.5373125e-10 9.36134151 13.9472868 8.8817842e-16 13.9472868 8.8817842e-16 1.5373125e-10z"
          ></path>
        </g>
        <g
          clipPath="url(#pets-colored-i9)"
          transform="translate(260 393) translate(32 80) translate(0 97) translate(16 16) translate(4 5) translate(7.645 15.382)"
        >
          <path
            fill="#032238"
            d="M0 0L24.7109979 0 24.7109979 24.6182938 0 24.6182938 0 0z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(Icon);
