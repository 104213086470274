import { useCallback } from 'react';

import { useUserProfile } from '../core/TTgraphql';

/**
 * This hook is useful for checking whether or not one or multiple
 * features are toggled on.
 *
 * Use:
 *
 * # Single feature
 * const [isXEnabled] = useFeatureToggles('x');
 *
 * # Multiple features
 * const [isYEnabled, isZEnabled] = useFeatureToggles('y', 'z');
 *
 * if (isXEnabled() || isYEnabled() || isZEnabled()) { ... }
 *
 * @param  {...string} candidates List of features you want to check.
 * @returns {Array} List of check functions for each feature.
 */
const useFeatureToggles = (...candidates) => {
  const { features = [], loading } = useUserProfile({ polling: false });

  return candidates.map(
    (feature) =>
      useCallback(() => {
        if (loading) return false;

        return features?.includes(feature);
      }),
    [features, loading],
  );
};

export default useFeatureToggles;
