import { useEffect, useRef } from 'react';

const scrollTo = (el, offset) => {
  const y = el.getBoundingClientRect().top + window.pageYOffset + offset;

  window.scrollTo({ top: y, behavior: 'smooth' });
};

const useFocusTo = (focus, offset = -16) => {
  const element = useRef(null);
  useEffect(() => {
    if (focus && element && element.current) {
      scrollTo(element.current, offset);
    }
  }, [focus]);

  return element;
};

export default useFocusTo;
