import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from './PetRentCO.module.scss';

const PetRentCO = ({ className }) => {
  return (
    <div className={cx(styles.container, className)}>
      <HelpfulInfoCard
        Icon={ResourcesIcon}
        className={styles.helpfulInfoCard}
        title="Colorado Pet Rent Laws"
        collapsable
      >
        <p className={styles.desc}>
          Under Colorado law, the maximum you can charge as additional pet rent
          is $35.00 a month or 1.5% of the rent cost, whichever is greater.
        </p>
      </HelpfulInfoCard>
    </div>
  );
};

PetRentCO.propTypes = {
  className: PropTypes.string,
};

export default PetRentCO;
