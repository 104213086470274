import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import PropTypes from 'prop-types';

import useQuestionnaires from '../../hooks/useQuestionnaires';
import QuestionMarkCircle from '../../icons/QuestionMarkCircle';
import fieldRequiredCustom from '../../validators/fieldRequiredCustom';
import Button from '../Button';
import CheckboxGroupFields from '../CheckboxGroupFields';
import FinalFormError from '../FinalFormError';
import Modal from '../Modal';
import RadioGroup from '../RadioGroup';
import Tag from '../Tag';
import { useErrorToast } from '../Toast';
import { H4, Paragraph } from '../Typography';

import {
  paymentMethodsOfOfflinePayments,
  trackingOfflinePayments,
} from './constants';

import styles from './RentPaymentsQuestionnaireModal.module.scss';

const collectRentOptions = [
  {
    value: 'throughTT',
    label: 'Tenants will pay through TurboTenant',
    description: (
      <>
        <Paragraph size="small" className={styles.descriptionWrapper} italic>
          Includes ACH, debit card, and credit card
          <span className={styles.recommendedTagWrapper}>
            <Tag
              accent
              uppercase={false}
              className={styles.recommendedTag}
              as="span"
            >
              Recommended
            </Tag>
          </span>
        </Paragraph>
      </>
    ),
  },
  { value: 'offline', label: 'Tenants will pay outside of TurboTenant' },
  {
    value: 'online_offline_combined',
    label: 'Tenants will pay BOTH through and outside of TurboTenant',
  },
  { value: 'notSureYet', label: "I'm not sure yet" },
];

const stylesForCheckboxGroupFields = {
  checkboxProps: { className: styles.checkbox },
  checkboxesClassName: styles.checkboxes,
};

const RentPaymentsQuestionnaireModal = ({
  afterSubmit,
  listingAddress,
  onClose,
}) => {
  const showErrorMessage = useErrorToast();
  const { saveRPQuestionnaireAnswer } = useQuestionnaires();
  const onSubmit = async (values) => {
    const data = {
      collectRentThrough: values.collectRentOption,
      collectRentAcceptedPaymentMethods: null,
      collectRentTrackPayments: null,
    };
    if (values.collectRentOption === 'offline') {
      data.collectRentAcceptedPaymentMethods = [
        ...values.paymentMethodsOfOfflinePayment,
      ];
      if ((values.paymentMethodsOfOfflinePayment || []).includes('other')) {
        data.collectRentAcceptedPaymentMethods.push(values.otherServiceName);
      }
      data.collectRentTrackPayments = [...values.trackingOfflinePayment];
      if ((values.trackingOfflinePayment || []).includes('otherTracking')) {
        data.collectRentTrackPayments.push(values.otherTrackingService);
      }
    }
    try {
      await saveRPQuestionnaireAnswer({ variables: { input: data } });
      const showTooltip = ['throughTT', 'dontKnow'].includes(
        values.collectRentOption,
      );
      afterSubmit && afterSubmit(showTooltip);
      return Promise.resolve();
    } catch (err) {
      showErrorMessage(
        'There was a problem saving your answers. Please try again.',
      );
      window.atatus &&
        window.atatus.notify({
          message: 'Failed to save Rent Payments questionnaire answers',
          err,
        });
    }
  };
  return (
    <Modal onClose={onClose} open showCloseIcon size="medium">
      <Form
        onSubmit={onSubmit}
        key="rp_questionnaire_form"
        subscription={{
          submitting: true,
        }}
      >
        {({ handleSubmit, submitting }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.HeaderTitle
                  icon={QuestionMarkCircle}
                  iconProps={{
                    blueCircle: true,
                    height: 26.67,
                    width: 26.67,
                  }}
                  title="Got a moment?"
                />
              </Modal.Header>
              <Modal.Content className={styles.contentContainer}>
                <H4>What are you doing to collect rent at {listingAddress}?</H4>
                <div className={styles.radioGroupContainer}>
                  <Field
                    component={RadioGroup}
                    id="collectRentOption"
                    name="collectRentOption"
                    options={collectRentOptions}
                    radiosContainerClassName={styles.radioGroup}
                    validate={fieldRequiredCustom(
                      'Please select an option above',
                    )}
                    vertical
                  />
                  <FinalFormError />
                </div>
                <FormSpy
                  subscription={{
                    values: true,
                  }}
                >
                  {({ values }) => {
                    if (values.collectRentOption !== 'offline') {
                      return null;
                    }
                    return (
                      <>
                        <div className={styles.checkboxGroupContainer}>
                          <p className={styles.sectionTitle}>
                            What payment methods will you accept outside of
                            TurboTenant?
                          </p>
                          <CheckboxGroupFields
                            id="paymentMethodsOfOfflinePayment"
                            name="paymentMethodsOfOfflinePayment"
                            options={paymentMethodsOfOfflinePayments}
                            checkboxesClassName={styles.checkboxes}
                            validate={fieldRequiredCustom(
                              'Please select an option above',
                            )}
                            {...stylesForCheckboxGroupFields}
                          />
                        </div>
                        <div className={styles.checkboxGroupContainer}>
                          <p className={styles.sectionTitle}>
                            How do you plan to track those payments?
                          </p>
                          <CheckboxGroupFields
                            id="trackingOfflinePayment"
                            name="trackingOfflinePayment"
                            options={trackingOfflinePayments}
                            className={styles.checkboxGroupFields}
                            checkboxesClassName={styles.checkboxes}
                            validate={fieldRequiredCustom(
                              'Please select an option above',
                            )}
                            {...stylesForCheckboxGroupFields}
                          />
                        </div>
                      </>
                    );
                  }}
                </FormSpy>
              </Modal.Content>
              <Modal.Footer>
                <div className={styles.actions}>
                  <Button
                    small
                    type="submit"
                    disabled={submitting}
                    loading={submitting}
                  >
                    Done
                  </Button>
                </div>
              </Modal.Footer>
            </form>
          </>
        )}
      </Form>
    </Modal>
  );
};

RentPaymentsQuestionnaireModal.propTypes = {
  afterSubmit: PropTypes.func,
  listingAddress: PropTypes.string,
  onClose: PropTypes.func,
};

export default RentPaymentsQuestionnaireModal;
