import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import FinalForm from '../../../../components/FinalForm';
import {
  DateField,
  InputField,
  MoneyField,
  PhoneField,
  RadioField,
} from '../../../../components/FinalFormFields';
import FlatButton from '../../../../components/FlatButton';
import { Column, Row } from '../../../../components/Grid';
import TrashIcon from '../../../../icons/Remove';

import styles from './EmploymentsEdit.module.scss';

const emptyEmployer = {
  employer: '',
  location: '',
  occupation: '',
  monthly_income: '',
  contact_name: '',
  contact_telephone: '',
  current: '',
  start_date: '',
  end_date: '',
};

const emptyIncome = {
  monthly_income: null,
  source: '',
};

const EmploymentsEdit = ({ id, application, onSubmit }) => {
  const handleSubmit = async (data) => {
    const currentEmployments =
      data?.employments?.filter((e) => e.current) || [];

    const pastEmployments = data?.employments?.filter((e) => !e.current) || [];

    await onSubmit({
      ...data,
      unemployed: currentEmployments.length <= 0,
      not_past_employers: pastEmployments.length <= 0,
    });
  };

  return (
    <FinalForm
      id={id}
      initialValues={{
        employments: application?.employments?.map((e) =>
          _.pick(
            e,
            'id',
            'employer',
            'location',
            'occupation',
            'monthly_income',
            'contact_name',
            'contact_telephone',
            'current',
            'start_date',
            'end_date',
          ),
        ),
        income_sources: application?.income_sources?.map((e) =>
          _.pick(e, 'id', 'source', 'monthly_income'),
        ),
        bank: application?.bank,
      }}
      onSubmit={handleSubmit}
    >
      {({ form, values }) => (
        <article>
          <FieldArray name="employments">
            {({ fields }) =>
              fields?.map((name, index) => (
                <>
                  <div
                    key={`employment-key-${index}`}
                    className={styles.container}
                  >
                    <Row className={styles.employmentInfo}>
                      <Column xs={12} sm={6} md={4}>
                        <InputField
                          name={`${name}.employer`}
                          label="Employer"
                          className={styles.item}
                        />
                      </Column>
                      <Column xs={12} sm={6} md={4}>
                        <InputField
                          name={`${name}.location`}
                          label="Location (city+state)"
                          className={styles.item}
                        />
                      </Column>
                      <Column xs={12} sm={6} md={4}>
                        <InputField
                          name={`${name}.occupation`}
                          label="Occupation"
                          className={styles.item}
                        />
                      </Column>
                      <Column xs={12} sm={6} md={4}>
                        <MoneyField
                          asString
                          name={`${name}.monthly_income`}
                          label="Monthly gross income"
                          className={styles.item}
                        />
                      </Column>
                      <Column xs={12} sm={6} md={4}>
                        <InputField
                          name={`${name}.contact_name`}
                          label="Contact Name"
                          className={styles.item}
                        />
                      </Column>
                      <Column xs={12} sm={6} md={4}>
                        <PhoneField
                          name={`${name}.contact_telephone`}
                          label="Contact phone"
                          className={styles.item}
                        />
                      </Column>
                      <Column
                        xs={12}
                        sm={!fields?.value?.[index]?.current ? 4 : 8}
                      >
                        <RadioField
                          inline={false}
                          name={`${name}.current`}
                          options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                          ]}
                          label="Currently working here"
                        />
                      </Column>
                      <Column xs={12} sm={4}>
                        <DateField
                          name={`${name}.start_date`}
                          label="Start date"
                        />
                      </Column>
                      {!fields?.value?.[index]?.current && (
                        <Column xs={12} sm={4}>
                          <DateField
                            name={`${name}.end_date`}
                            label="End date"
                          />
                        </Column>
                      )}
                    </Row>
                    <FlatButton
                      small
                      className={styles.remove}
                      icon={TrashIcon}
                      onClick={() =>
                        form?.mutators?.remove('employments', index)
                      }
                    >
                      <span className={styles.hideSmall}>Remove</span>
                    </FlatButton>
                  </div>
                  {values?.employments?.length - 1 !== index && (
                    <div className={styles.divider} />
                  )}
                </>
              ))
            }
          </FieldArray>
          <div>
            <Button
              large
              secondary
              className={styles.addButton}
              onClick={() => form?.mutators?.push('employments', emptyEmployer)}
            >
              + add employer
            </Button>
          </div>
          <FieldArray name="income_sources">
            {({ fields }) =>
              fields?.map((name, index) => (
                <>
                  <div
                    className={styles.sourceContainer}
                    key={`employment-income-source-${index}`}
                  >
                    <div className={styles.incomeInfo}>
                      <MoneyField
                        asString
                        name={`${name}.monthly_income`}
                        className={styles.income}
                        label="Monthly income"
                      />
                      <InputField
                        name={`${name}.source`}
                        className={styles.income}
                        label="source"
                      />
                    </div>
                    <FlatButton
                      small
                      icon={TrashIcon}
                      className={styles.remove}
                      onClick={() =>
                        form?.mutators?.remove('income_sources', index)
                      }
                    >
                      <span className={styles.hideSmall}>Remove</span>
                    </FlatButton>
                  </div>
                </>
              ))
            }
          </FieldArray>
          <div>
            <Button
              large
              secondary
              className={styles.addButton}
              onClick={() =>
                form?.mutators?.push('income_sources', emptyIncome)
              }
            >
              + add income
            </Button>
          </div>
          <div>
            <div className={styles.subTitle}>banking</div>
            <InputField
              name="bank"
              className={styles.bank}
              label="Current Bank or financial institute"
              required={false}
            />
          </div>
        </article>
      )}
    </FinalForm>
  );
};

EmploymentsEdit.propTypes = {
  application: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default EmploymentsEdit;
