import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../../../constants/colors';
import Button from '../../../Button';
import Card from '../../../Card';

import styles from './SubscriptionDetailsBlueCardAlt.module.scss';

const SubscriptionDetailsBlueCardAlt = ({
  className,
  benefits,
  extraParams,
  onSubscribe,
  tabs,
  onTabClick,
  premiumPrice,
  displayMonthlyPrice = false,
}) => {
  const renderBenefitItems = (benefitItems) =>
    benefitItems.map((item) => {
      const ItemIcon = item.icon;
      const iconProps = {
        color: colors.orangeLight,
        ...(item.iconProps || {}),
      };
      return (
        <div key={item.title} className={styles.benefitItem}>
          {ItemIcon && (
            <div className={styles.itemIconWrapper}>
              <ItemIcon width={27} height={27} {...iconProps} />
            </div>
          )}
          <div className={styles.itemInfo}>
            <h3
              className={cx(styles.itemTitle, {
                [styles.fullHeight]: !item?.description,
              })}
            >
              {item.title}
            </h3>
            {item?.description && (
              <p className={styles.itemDescription}>{item.description}</p>
            )}
            {item.cta && (
              <div className={styles.itemCta}>
                <a
                  href={
                    typeof item.ctaLink === 'function'
                      ? item.ctaLink(extraParams)
                      : item.ctaLink
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  {...(item.onClick
                    ? {
                        onClick: item.onClick,
                      }
                    : {})}
                >
                  {item.cta}
                </a>
              </div>
            )}
          </div>
        </div>
      );
    });

  return (
    <Card className={cx(styles.container, className)}>
      <div className={styles.topRow}>
        <div className={styles.colLeft}>
          <div className={styles.title}>Premium</div>
          <div className={styles.subtitle}>
            <span className={styles.price}>
              $
              {displayMonthlyPrice
                ? (premiumPrice / 12).toFixed(2)
                : premiumPrice}
            </span>
            <span className={styles.perYear}>
              {displayMonthlyPrice ? 'per month' : 'per year'}
            </span>
          </div>
          {displayMonthlyPrice && (
            <span className={styles.billedAnnuallyLabel}>Billed annually</span>
          )}
        </div>
        <div className={styles.colRight}>
          <Button
            className={styles.getPremiumBtn}
            onClick={onSubscribe}
            data-qa="get-premium-btn"
          >
            Get Premium
          </Button>
        </div>
      </div>
      <div
        className={cx(styles.itemsCard, {
          [styles.withTabs]: !!tabs,
        })}
      >
        {tabs && (
          <div className={styles.tabs}>
            {tabs.map((tab) => (
              <div
                key={tab.key}
                className={cx(styles.tab, {
                  [styles.active]: tab.key === benefits.key,
                })}
                onClick={() => onTabClick?.(tab.key)}
              >
                {tab.title}
              </div>
            ))}
          </div>
        )}
        <div className={styles.columns}>
          <div className={styles.itemsCol}>
            {renderBenefitItems(
              benefits.items.slice(0, Math.ceil(benefits.items.length / 2)),
            )}
          </div>
          <div className={styles.itemsCol}>
            {renderBenefitItems(
              benefits.items.slice(Math.ceil(benefits.items.length / 2)),
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

SubscriptionDetailsBlueCardAlt.defaultProps = {
  className: '',
};

SubscriptionDetailsBlueCardAlt.propTypes = {
  className: PropTypes.string,
  benefits: PropTypes.object,
  extraParams: PropTypes.object.isRequired,
  onSubscribe: PropTypes.func.isRequired,
  tabs: PropTypes.array,
  onTabClick: PropTypes.func,
  premiumPrice: PropTypes.number.isRequired,
  displayMonthlyPrice: PropTypes.bool,
};

export default SubscriptionDetailsBlueCardAlt;
