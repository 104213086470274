import { useMutation, useQuery } from '@apollo/client';

import { useInfoToast } from '../components/Toast';
import joinWaitlistFeatureGQL from '../graphql/mutations/joinWaitlistFeature.graphql';
import toolboxWaitlistFeaturesGQL from '../graphql/queries/toolbox/toolboxWaitlistFeatures.graphql';

const useAddFeatureToWaitlist = () => {
  const showInfoToast = useInfoToast();
  const { data } = useQuery(toolboxWaitlistFeaturesGQL);
  const waitlistFeatures = data?.toolbox_waitlist_features?.features || [];
  const [joinWaitlistFeature] = useMutation(joinWaitlistFeatureGQL);

  const response = data?.toolbox_waitlist_features;
  return (feature, location) => {
    joinWaitlistFeature({
      variables: {
        feature,
        location,
      },
      optimisticResponse: {
        joinWaitlistFeature: {
          toolbox_waitlist_features: {
            id: response?.id,
            __typename: response?.__typename,
            features: [...waitlistFeatures, feature],
          },
        },
      },
    });

    showInfoToast('Added to waitlist!');
  };
};

export default useAddFeatureToWaitlist;
