import React from 'react';
import cx from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import toCurrency from '../../../../../helpers/toCurrency';

import styles from './ProfileSummarySection.module.scss';

const ProfileSummarySection = ({ report }) => {
  let rowCounter = 0;

  const rowsNames = [
    'revolving',
    'installment',
    'mortgage',
    'open',
    'closedWithBal',
    'total',
  ];
  const rowTitles = [
    'Revolving',
    'Installment',
    'Mortgage',
    'Open',
    'Closed with Bal',
    'Total',
  ];

  const countTradelines = report.tradelines ? report.tradelines.length : 'N/A';
  const countCollections =
    report.summary &&
    report.summary.derogItems &&
    report.summary.derogItems.collectionCount !== undefined
      ? report.summary.derogItems.collectionCount
      : 'N/A';
  const countPublicRecords =
    report.summary &&
    report.summary.derogItems &&
    report.summary.derogItems.publicRecordCount !== undefined
      ? report.summary.derogItems.publicRecordCount
      : 'N/A';
  const countIniquiries = report.number_of_inquiries || 'N/A';

  const countNegTrad =
    report.summary &&
    report.summary.derogItems &&
    report.summary.derogItems.negTradelineCount !== undefined
      ? report.summary.derogItems.negTradelineCount
      : 'N/A';
  const countHistNegTradeline =
    report.summary &&
    report.summary.derogItems &&
    report.summary.derogItems.histNegTradelineCount !== undefined
      ? report.summary.derogItems.histNegTradelineCount
      : 'N/A';
  const countOccurrenceHist =
    report.summary &&
    report.summary.derogItems &&
    (report.summary.derogItems.occuranceHistCount !== undefined ||
      report.summary.derogItems.occuranceHistCount !== undefined)
      ? report.summary.derogItems.occuranceHistCount ||
        report.summary.derogItems.occuranceHistCount
      : 'N/A';
  const isEmpty =
    Object.values(report.summary).every((x) => x === null || x === '') ||
    get(report, 'recommendation.title', '').toLowerCase() === 'refer';
  return (
    <div className={cx(styles.flexColumn, styles.container)}>
      <span className={styles.iconNumber}>Profile Summary</span>
      <div className={styles.flexColumn}>
        <div className={cx(styles.marginSmall, styles.top)}>
          <span className={styles.iconFooter}>RECORD COUNTS</span>
          <div className={cx(styles.row, styles.tableRow)}>
            <div className={styles.resultBlock}>
              <span className={styles.iconNumber}>{countTradelines}</span>
              <span>Tradelines</span>
            </div>
            <div className={styles.resultBlock}>
              <span className={styles.iconNumber}>{countCollections}</span>
              <span>Collections</span>
            </div>

            <div className={styles.resultBlock}>
              <span className={styles.iconNumber}>{countPublicRecords}</span>
              <span>Public Records</span>
            </div>
            <div className={styles.resultBlock}>
              <span className={styles.iconNumber}>{countIniquiries}</span>
              <span>Inquiries</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.flexColumn}>
        <div className={cx(styles.marginMedium, styles.top)}>
          <span className={styles.iconFooter}>DEROGATORY ITEMS</span>
          <div className={cx(styles.row, styles.bigRow)}>
            <div className={styles.resultBlock}>
              <span className={styles.iconNumber}>{countNegTrad}</span>
              <div>{`Negative`}</div>
              <div>{`Tradelines`}</div>
            </div>
            <div className={styles.resultBlock}>
              <span className={styles.iconNumber}>{countHistNegTradeline}</span>
              <div>{`Tradelines with`}</div>
              <div>{`Historical Negatives`}</div>
            </div>
            <div className={styles.resultBlock}>
              <span className={styles.iconNumber}>{countOccurrenceHist}</span>
              <div>{`Occurrence of`}</div>
              <div>{`Historical Negatives`}</div>
            </div>
          </div>
        </div>
      </div>
      {!isEmpty && (
        <div className={(styles.flexColumn, styles.hideSmall)}>
          <div className={cx(styles.marginMedium, styles.top)}>
            <span className={styles.iconFooter}>TRADELINE SUMMARY</span>
            <div className={styles.profileSummaryContent}>
              <div className={styles.flexColumn}>
                <div
                  className={cx(
                    styles.flexRow,
                    styles.headerRow,
                    styles.marginSmall,
                    styles.top,
                  )}
                >
                  <div className={styles.firstCell}></div>
                  <div className={styles.cell}>COUNT</div>
                  <div className={styles.cell}>HIGH CREDIT</div>
                  <div className={styles.cell}>CREDIT LIMIT</div>
                  <div className={styles.cell}>BALANCE</div>
                  <div className={styles.cell}>PAST DUE</div>
                  <div className={styles.cell}>PAYMENT</div>
                  <div className={styles.cell}>AVAILABLE</div>
                </div>
                {rowsNames.map((rowName, idx) => {
                  const tradeline = report.summary[rowName];
                  if (
                    !tradeline ||
                    (!tradeline.count &&
                      !tradeline.highCredit &&
                      !tradeline.creditLimit &&
                      !tradeline.balance &&
                      !tradeline.monthlyPayment &&
                      !tradeline.percentCreditAvail)
                  )
                    return;
                  rowCounter++;
                  const pastDue = report.summary.pastDueItems
                    ? report.summary.pastDueItems[`${rowName}PastDue`]
                    : null;
                  return (
                    <div
                      key={idx}
                      className={cx(
                        styles.flexRow,
                        rowCounter % 2 === 1 && styles.oddRow,
                      )}
                    >
                      <div className={styles.firstCell}>{rowTitles[idx]}</div>
                      <div className={styles.cell}>
                        {tradeline.count ? tradeline.count : 'N/A'}
                      </div>
                      <div className={styles.cell}>
                        {tradeline.highCredit
                          ? `${toCurrency(tradeline.highCredit)}`
                          : 'N/A'}
                      </div>
                      <div className={styles.cell}>
                        {tradeline.creditLimit
                          ? `${toCurrency(tradeline.creditLimit)}`
                          : 'N/A'}
                      </div>
                      <div className={styles.cell}>
                        {tradeline.balance
                          ? `${toCurrency(tradeline.balance)}`
                          : 'N/A'}
                      </div>
                      <div className={styles.cell}>
                        {pastDue !== null ? `${toCurrency(pastDue)}` : 'N/A'}
                      </div>
                      <div className={styles.cell}>
                        {tradeline.monthlyPayment
                          ? `${toCurrency(tradeline.monthlyPayment)}`
                          : 'N/A'}
                      </div>
                      <div className={styles.cell}>
                        {tradeline.percentCreditAvail
                          ? `${tradeline.percentCreditAvail}%`
                          : 'N/A'}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ProfileSummarySection.propTypes = {
  report: PropTypes.object,
};

export default ProfileSummarySection;
