import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';

import styles from './AdditionsToLeaseAgreementMD.module.scss';

const AdditionsToLeaseAgreementMD = ({ className }) => {
  return (
    <HelpfulInfoCard
      noIcon
      title="Montgomery County, MD Landlord Tenant Handbook"
      className={cx(styles.helpfulInfoCard, className)}
      color="iceCold"
    >
      Montgomery County, MD requires that the{' '}
      <a
        className={styles.link}
        href="https://www.montgomerycountymd.gov/DHCA/Resources/Files/housing/landlordtenant/publications/hbk_olta_eng.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Montgomery County, MD Landlord-Tenant Handbook
      </a>{' '}
      must be presented to the tenant(s) and each party must sign it. We’ve
      already attached it for you.{' '}
    </HelpfulInfoCard>
  );
};

AdditionsToLeaseAgreementMD.propTypes = {
  className: PropTypes.string,
};

export default AdditionsToLeaseAgreementMD;
