import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Sewer = ({ color, ...props }) => (
  <svg {...props} viewBox={props.viewBox || '0 0 18 22'}>
    <g fill={color}>
      <path d="M16.65 11.917c.248 0 .45-.206.45-.46V2.293c0-.76-.604-1.375-1.35-1.375h-1.8c-.746 0-1.35.615-1.35 1.375v9.625h4.05zm1.35-.46c0 .76-.605 1.376-1.35 1.376h-4.5c-.25 0-.45-.205-.45-.458V2.292C11.7 1.026 12.707 0 13.95 0h1.8C16.993 0 18 1.026 18 2.292v9.166z" />
      <path d="M14.4 21.542c0 .253-.2.458-.45.458h-9c-.275 0-.486-.25-.445-.526l.602-4.086C2.74 17.125.9 15.083.9 12.603v-.228c0-.253.2-.458.45-.458h15.3c.25 0 .45.205.45.458v1.833c0 .154-.075.297-.2.382l-2.5 1.696v5.256zm-8.927-.46H13.5v-5.04c0-.155.075-.298.2-.383l2.5-1.7v-1.13H1.807c.116 2.046 1.78 3.667 3.818 3.667.275 0 .486.25.445.525l-.597 4.057z" />
      <path d="M11.7 11.917V11H.9v.917h10.8zm.45.916H.45c-.25 0-.45-.205-.45-.458v-1.833c0-.253.2-.46.45-.46h11.7c.25 0 .45.207.45.46v1.833c0 .253-.2.458-.45.458zm3.6-9.166c0-.253-.202-.46-.45-.46-.25 0-.45.206-.45.46 0 .252.202.458.45.458s.45-.206.45-.458zm.9 0c0 .758-.605 1.375-1.35 1.375-.746 0-1.35-.617-1.35-1.375 0-.76.604-1.375 1.35-1.375.745 0 1.35.616 1.35 1.375z" />
    </g>
  </svg>
);

Sewer.defaultProps = {
  width: 22,
  height: 21,
  color: colors.text,
};

Sewer.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Sewer);
