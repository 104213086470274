import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import BackButton from '../../../../../../components/BackButton';
import Button from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import ReviewsIoWidget from '../../../../../../components/ReviewsIoWidget';
import {
  H2,
  H5,
  Link,
  Paragraph,
} from '../../../../../../components/Typography';
import VideoPlayerModal from '../../../../../../components/VideoPlayerModal';
import ReceivingMoney from '../../../../../../icons/twoTone/ReceivingMoney';
import Security from '../../../../../../icons/twoTone/Security';
import Tenants from '../../../../../../icons/twoTone/Tenants';
import { segmentTracking } from '../../../../../../services/utilities';
import SocialProof from '../../../../intro/experiments/extras/SocialProof';

import styles from './HowPaymentsWork.module.scss';

const RP_VIDEO_URL = 'https://vimeo.com/684816042';

const HowPaymentsWork = ({
  className,
  onBack,
  action,
  segmentLocation,
  triggerSegmentEventOnLoad,
  variant,
  extraInfoContainerClassName,
}) => {
  const [play, setPlay] = useState(false);
  const {
    label: actionLabel,
    eventName,
    onClick: actionOnClick,
    to: actionTo,
  } = action;

  useEffect(() => {
    if (triggerSegmentEventOnLoad) {
      segmentTracking('rp_empty_state loaded', {
        location: triggerSegmentEventOnLoad,
        experiment: variant,
      });
    }
  }, []);

  return (
    <div className={cx(styles.container, className)}>
      {onBack && <BackButton onClick={onBack} className={styles.backbutton} />}
      <div className={styles.cardsContainer}>
        <div className={styles.recommendedContainer}>
          <Card className={styles.card}>
            <H2 className={cx(styles.cardTitle, 'mb-16')}>
              Securely collect rent. <span>For free</span>.
            </H2>

            <div className={styles.row}>
              <div className={styles.svgContainer}>
                <ReceivingMoney />
              </div>
              <div className={styles.itemCol}>
                <H5>Get paid on time</H5>
                <Paragraph>
                  Automatic charges, reminders, and receipts make rent
                  collection effortless.{' '}
                  <Link
                    onClick={() => {
                      segmentTracking('how_it_works clicked', {
                        location: segmentLocation,
                        experiment: variant,
                      });
                      setPlay(true);
                    }}
                  >
                    Watch how it works
                  </Link>
                  .
                </Paragraph>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.svgContainer}>
                <Tenants />
              </div>
              <div className={styles.itemCol}>
                <H5>Let tenants pay how they want</H5>
                <Paragraph>
                  Get paid via ACH, card, check, cash, or 3rd-party apps through
                  or outside of TurboTenant.
                </Paragraph>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.svgContainer}>
                <Security />
              </div>
              <div className={styles.itemCol}>
                <H5>Dependable security</H5>
                <Paragraph>
                  We use the same processes that big banks use to keep your
                  payments secure.
                </Paragraph>
              </div>
            </div>
            <div className={styles.ctaContainer}>
              <Button
                data-qa="lets-get-started"
                variant="primary"
                to={actionTo}
                onClick={async () => {
                  segmentTracking(`${eventName}_clicked`, {
                    location: segmentLocation,
                    experiment: variant,
                  });
                  actionOnClick && (await actionOnClick());
                }}
              >
                {actionLabel}
              </Button>
            </div>
          </Card>
        </div>
      </div>
      <div
        className={cx(styles.extraInfoContainer, extraInfoContainerClassName)}
      >
        <SocialProof className={styles.socialProof} />

        <div className={styles.centeredRow}>
          <ReviewsIoWidget />
        </div>
      </div>
      {play && (
        <VideoPlayerModal
          onClose={() => setPlay(false)}
          url={RP_VIDEO_URL}
          open={play}
        />
      )}
    </div>
  );
};

HowPaymentsWork.propTypes = {
  triggerSegmentEventOnLoad: PropTypes.string,
  segmentLocation: PropTypes.string,
  className: PropTypes.string,
  onBack: PropTypes.func,
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    eventName: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    to: PropTypes.string,
  }).isRequired,
  variant: PropTypes.string,
  extraInfoContainerClassName: PropTypes.string,
};

export default HowPaymentsWork;
