import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import get from 'lodash.get';
import isBoolean from 'lodash/isBoolean';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Experiments } from '../../constants/experiments';
import userReiHubPricing from '../../core/hooks/userReiHubPricing';
import { useUserFields } from '../../core/TTgraphql';
import createReiHubSubscription from '../../graphql/mutations/rei_hub/createReiHubSubscription.graphql';
import ownerSettingsQueryGQL from '../../graphql/queries/ownerSettingsQuery.graphql';
import calculateReiHubPrice from '../../helpers/calculate-reihub-price';
import { createAndSaveIdempotencyKey } from '../../helpers/createAndSaveIdempotencyKey';
import getExperimentVariant from '../../helpers/experiments';
import getReiHubPrice from '../../helpers/getReiHubPrice';
import { resetIdempotencyKey } from '../../helpers/resetIdempotencyKey';
import StripeElementsModal from '../../pages/common/stripe/StripeElementsModal';
import { segmentTracking } from '../../services/utilities';
import { useErrorToast } from '../Toast';

import {
  FREE_TRIAL_DAYS,
  REIHUB_DISCOUNT_FACTOR,
  RENEWAL_FREQUENCIES,
} from './constants';

import styles from './ReiHubModalWrapped.module.scss';

const AMOUNT_LABELS = {
  [RENEWAL_FREQUENCIES.MONTHLY]: 'PER MONTH',
  [RENEWAL_FREQUENCIES.QUARTERLY]: 'PER QUARTER',
  [RENEWAL_FREQUENCIES.YEARLY]: 'PER YEAR',
};

const AUTO_RENEWAL_PERIODS = {
  [RENEWAL_FREQUENCIES.MONTHLY]: 'monthly',
  [RENEWAL_FREQUENCIES.QUARTERLY]: (
    <>
      every
      <br />3 months
    </>
  ),
  [RENEWAL_FREQUENCIES.YEARLY]: 'yearly',
};

const ReiHubModalWrapped = ({
  onClose,
  onSuccess,
  refetchQueries,
  unitCount,
  renewalFrequency,
  fromPremiumSuccessModal,
}) => {
  const [isFreeTrialVersion, setIsFreeTrialVersion] = useState(null);

  const { user } = useUserFields([
    'reihub_listings_count',
    'active_experiments',
  ]);
  const errorToast = useErrorToast();

  const pricingMap = userReiHubPricing();

  const { reihub_listings_count: listings_count, id: userId } = user;

  const { loading: loadingOwnerSettings, data: ownerSettingsData } = useQuery(
    ownerSettingsQueryGQL,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const reiHubMonthlyTrialOfferVariant = getExperimentVariant(
    user,
    Experiments.ReiHubMonthlyTrialOffer.name,
  );

  useEffect(() => {
    if (
      !loadingOwnerSettings &&
      ownerSettingsData &&
      isBoolean(
        ownerSettingsData?.userData?.settingsAndBilling?.data
          ?.reiHubSubscriptionInfo?.is_reihub_trial_activated,
      )
    ) {
      const trialActivated =
        ownerSettingsData?.userData?.settingsAndBilling?.data
          ?.reiHubSubscriptionInfo?.is_reihub_trial_activated;

      const trialOffer =
        !trialActivated &&
        !fromPremiumSuccessModal &&
        reiHubMonthlyTrialOfferVariant ===
          Experiments.ReiHubMonthlyTrialOffer.variants.test;
      setIsFreeTrialVersion(trialOffer);
    }
  }, [
    loadingOwnerSettings,
    ownerSettingsData,
    fromPremiumSuccessModal,
    reiHubMonthlyTrialOfferVariant,
  ]);

  const title =
    renewalFrequency === RENEWAL_FREQUENCIES.QUARTERLY && !isFreeTrialVersion
      ? 'Rental Accounting Subscription'
      : 'Subscribe to REI Hub';

  const pricing =
    unitCount !== null && renewalFrequency !== null
      ? getReiHubPrice(pricingMap, unitCount)
      : null;

  const monthlyPrice = calculateReiHubPrice(listings_count);
  const monthlyReiHubPrice = monthlyPrice * REIHUB_DISCOUNT_FACTOR;

  const [createSubscription] = useMutation(createReiHubSubscription, {
    refetchQueries: refetchQueries?.length
      ? refetchQueries.map((query) => ({ query }))
      : null,
    awaitRefetchQueries: true,
  });

  const onToken = useCallback(async (token) => {
    let error;
    try {
      const idempotencyKey = createAndSaveIdempotencyKey();
      await createSubscription({
        variables: {
          token,
          idempotencyKey,
          ...(isFreeTrialVersion
            ? { trial_period_days: FREE_TRIAL_DAYS }
            : { unitCount }),
        },
      });

      onSuccess();
    } catch (e) {
      const message = get(e, 'graphQLErrors[0].message', 'An error occurred');
      errorToast(message);
      error = e?.graphQLErrors;
    }

    resetIdempotencyKey(error);
  });

  if (!userId) {
    return null;
  }

  let price = monthlyReiHubPrice;
  let slashedPrice = null;

  if (pricing !== null) {
    if (renewalFrequency === RENEWAL_FREQUENCIES.QUARTERLY) {
      price = pricing.quarterly_price;
      slashedPrice = pricing.slashedPrice * 3;
    } else if (renewalFrequency === RENEWAL_FREQUENCIES.YEARLY) {
      price = pricing.price;
      slashedPrice = pricing.slashedPrice;
    } else {
      price = monthlyReiHubPrice;
    }
  }

  const trialOfferPrice = pricing?.slashedPrice ?? monthlyPrice;

  const trialEndDate = moment().add(FREE_TRIAL_DAYS, 'days');
  const formattedDate = trialEndDate.format('MM/DD/YY');

  const autoRenewTermsFreeTrialOffer = (
    <span className={styles.terms}>
      I understand I’ll be charged ${trialOfferPrice}/mo starting on{' '}
      {formattedDate}, unless I cancel. Price subject to change based on units.
    </span>
  );

  const handlePayClicked = () => {
    if (isFreeTrialVersion) {
      segmentTracking(`subscribe clicked`, {
        location: 'REI Hub Free Trial Credit Card Form',
        [`experiment_${Experiments.ReiHubMonthlyTrialOffer.name}`]:
          reiHubMonthlyTrialOfferVariant,
      });
    } else {
      segmentTracking(`purchase_reihub_subscription clicked`, {
        location: 'reihub accounting tab subscription modal',
      });
    }
  };

  return (
    <StripeElementsModal
      open
      onClose={() => {
        onClose();
      }}
      onToken={onToken}
      title={title}
      autoRenewTermsLink="https://www.turbotenant.com/terms-of-use/#partnerships"
      onPayClicked={handlePayClicked}
      {...(isFreeTrialVersion
        ? {
            amount: trialOfferPrice,
            amountToShow: 0.0,
            amountTitle: 'Total Due Today',
            disclaimer: autoRenewTermsFreeTrialOffer,
            buttonLabel: 'SUBSCRIBE',
          }
        : {
            amount: price,
            slashedAmount: slashedPrice,
            buttonLabel: pricing !== null ? `Pay $${price}` : 'SUBSCRIBE',
            autoRenewPeriod:
              AUTO_RENEWAL_PERIODS[renewalFrequency] ??
              // NOTICE: this line of code below is here for backwards compatibility
              (pricing ? 'yearly' : 'monthly'),
            amountLabel:
              AMOUNT_LABELS[renewalFrequency] ??
              // NOTICE: this line of code below is here for backwards compatibility
              (pricing ? 'year' : 'month'),
          })}
    />
  );
};

ReiHubModalWrapped.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  refetchQueries: PropTypes.array,
  unitCount: PropTypes.number,
  renewalFrequency: PropTypes.oneOf(Object.values(RENEWAL_FREQUENCIES)),
  fromPremiumSuccessModal: PropTypes.bool,
};

export default ReiHubModalWrapped;
