// - Common boilerplate
import React, { Suspense } from 'react';

import componentLoader from '../../helpers/component-loader';
const AdminMainRoutes = componentLoader(() => import('./routes.admin.main'));

// ------------------------------
// Bootstrapping

// ------------------------------

const MainApplication = () => (
  <>
    <Suspense fallback={null}>
      <AdminMainRoutes />
    </Suspense>
  </>
);

export default MainApplication;
