import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../Button';
import Card from '../Card';
import FlatButton from '../FlatButton';

import styles from './DoneCard.module.scss';

const DoneCard = ({
  title,
  description,
  icon: Icon,
  iconProps,
  to,
  onClick,
  buttonTitle,
  secondaryTo,
  secondaryOnClick,
  secondaryButtonTitle,
  className,
}) => (
  <Card className={cx(styles.container, className)}>
    {Icon ? (
      <Icon {...iconProps} className={cx(styles.icon, iconProps.className)} />
    ) : null}
    {title ? (
      <h1 id="done-title" data-qa="done-card-title">
        {title}
      </h1>
    ) : null}
    {description ? <p>{description}</p> : null}
    <Button
      className={styles.action}
      id="done-action"
      to={to}
      onClick={onClick}
      data-qa="done-card-button-done"
    >
      {buttonTitle}
    </Button>
    {secondaryButtonTitle && (
      <FlatButton
        className={styles.secondaryAction}
        id="secondary-done-action"
        to={secondaryTo}
        onClick={secondaryOnClick}
        data-qa="done-card-button-done-secondary"
      >
        {secondaryButtonTitle}
      </FlatButton>
    )}
  </Card>
);

DoneCard.propTypes = {
  title: PropTypes.node,
  icon: PropTypes.elementType,
  iconProps: PropTypes.object,
  to: PropTypes.string,
  onClick: PropTypes.func,
  buttonTitle: PropTypes.string,
  secondaryTo: PropTypes.string,
  secondaryOnClick: PropTypes.func,
  secondaryButtonTitle: PropTypes.string,
};

DoneCard.defaultProps = {
  iconProps: {},
  buttonTitle: 'Done',
};

export default DoneCard;
