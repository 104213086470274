// Represents the discount applied to the subscription.
// The value 0.25 means there is a 75% discount
export const REIHUB_DISCOUNT_FACTOR = 0.5;

export const RENEWAL_FREQUENCIES = {
  MONTHLY: 1,
  QUARTERLY: 2,
  YEARLY: 3,
  // TODO: make sure what value is the correct one, yearly or annually
  ANNUALLY: 3,
};

export const FREE_TRIAL_DAYS = 14;
