import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useUserProfile } from '../../../core/TTgraphql';
import basicUserProfile from '../../../graphql/basicUserProfile.graphql';
import ownerSettingsQuery from '../../../graphql/queries/ownerSettingsQuery.graphql';
import PremiumBadge from '../../../icons/PremiumBadge';
import { segmentTracking } from '../../../services/utilities';
import SuccessAnimation from '../../Animations/SuccessAnimation';
import Button from '../../Button';
import Modal from '../../Modal';
import { RENEWAL_FREQUENCIES } from '../../ReiHubSubscription/constants';
import ReiHubModalWrapped from '../../ReiHubSubscription/ReiHubModalWrapped';
import ReiHubSubscriptionSuccessModal from '../../ReiHubSubscription/ReiHubSubscriptionSuccessModal';
import ReiHubOfferModalContent from '../../RentalAccountingOfferModal/ReiHubOfferModalContent';
import ReiHubOfferModalFooter from '../../RentalAccountingOfferModal/ReiHubOfferModalFooter';
import { H2, Paragraph } from '../../Typography';

import styles from './PremiumIsActiveModal.module.scss';

const planTitles = {
  pro: 'Pro',
  premium: 'Premium',
  premiumPlus: 'Premium Plus',
};

const PremiumIsActiveModal = ({
  onClose,
  segmentLocation,
  segmentProperties,
  segmentLoadEvent,
  showREIHubOption,
  privateBasePath,
  open,
  listingsCount,
  showPremiumIsNowActive,
  titlePrefix,
  text,
  listItems,
  mobileFullScreen,
  showExpirationDate,
  isProPlan,
  isPremiumPlusPlan,
}) => {
  const { user } = useUserProfile();
  const [unitCount, setUnitCount] = useState(listingsCount || 1);
  const [showReiHubPaymentModal, setShowReiHubPaymentModal] = useState(false);
  const [showReiHubSuccessModal, setShowReiHubSuccessModal] = useState(false);

  useEffect(() => {
    if (user?.onboarding_data?.properties > 0) {
      setUnitCount(
        Math.max(user.onboarding_data.properties, listingsCount || 1),
      );
    }
  }, [user?.onboarding_data?.properties]);

  const getPlanTitle = () => {
    if (isPremiumPlusPlan) {
      return planTitles.premiumPlus;
    } else if (isProPlan) {
      return planTitles.pro;
    }
    return planTitles.premium;
  };

  const sendSegmentEvent = (event) => {
    segmentTracking(event, {
      location: segmentLocation,
      ...segmentProperties,
    });
  };

  useEffect(() => {
    if (segmentLoadEvent) {
      sendSegmentEvent(segmentLoadEvent);
    }
  }, []);

  const onNoInterested = () => {
    sendSegmentEvent('not_right_now clicked');
    onClose();
  };

  const onClaimDiscount = () => {
    setShowReiHubPaymentModal(true);
    sendSegmentEvent('claim_my_discount clicked');
  };

  const onSoundsGood = () => {
    sendSegmentEvent('sounds_good clicked');
    isPremiumPlusPlan ? setShowReiHubSuccessModal(true) : onClose();
  };
  const renewalFrequency = RENEWAL_FREQUENCIES.YEARLY;

  if (showReiHubSuccessModal) {
    return (
      <ReiHubSubscriptionSuccessModal
        open={showReiHubSuccessModal}
        onClose={() => {
          onClose();
          setShowReiHubSuccessModal(false);
          setTimeout(() => document.activeElement.blur(), 1);
        }}
        onShowFinalHintClose={() => {
          onClose();
          setShowReiHubSuccessModal(false);
          setTimeout(() => document.activeElement.blur(), 1);
        }}
        renewalFrequency={renewalFrequency}
        isPremiumPlusPlan={isPremiumPlusPlan}
        fromPremiumSuccessModal
      />
    );
  }

  return (
    <>
      <Modal
        autoFocus={false}
        disableOutsideClick
        id="premiumModalActivated"
        onClose={onClose}
        className={cx(styles.modal, { [styles.modalV2]: isPremiumPlusPlan })}
        open={open}
        mobileFullScreen={mobileFullScreen}
      >
        {showPremiumIsNowActive && (
          <>
            <div
              className={cx(styles.row, { [styles.mb24]: !showREIHubOption })}
            >
              <div className={styles.animationHolder}>
                <PremiumBadge className={styles.icon} />
                <div className={styles.animation}>
                  <SuccessAnimation
                    style={{ width: isPremiumPlusPlan ? 30 : 50 }}
                  />
                </div>
              </div>
              <div className={styles.headerWrapper}>
                <H2
                  className={cx(styles.title, {
                    [styles.bm4]: !showREIHubOption,
                  })}
                >
                  {getPlanTitle()} is now active!
                </H2>

                {!showREIHubOption && (
                  <Paragraph size="large" className={styles.desc}>
                    You’ve got the tools you need to manage your property
                    professionally, efficiently, and confidently.
                  </Paragraph>
                )}
              </div>
            </div>
            <hr className={styles.divider} />
          </>
        )}

        {showREIHubOption ? (
          <>
            <ReiHubOfferModalContent
              unitCount={unitCount}
              setUnitCount={setUnitCount}
              listingsCount={listingsCount}
              privateBasePath={privateBasePath}
              sendSegmentEvent={sendSegmentEvent}
              listItems={listItems}
              titlePrefix={titlePrefix}
              text={text}
              showExpirationDate={showExpirationDate}
            />
            <ReiHubOfferModalFooter
              onClaimDiscount={onClaimDiscount}
              onNoInterested={onNoInterested}
            />
          </>
        ) : (
          <Button onClick={onSoundsGood} className={styles.goodBtn}>
            Sounds good
          </Button>
        )}
      </Modal>

      {showReiHubPaymentModal && (
        <ReiHubModalWrapped
          unitCount={unitCount}
          onClose={() => {
            setShowReiHubPaymentModal(false);
            setTimeout(() => document.activeElement.blur(), 1);
          }}
          onSuccess={() => {
            sendSegmentEvent('purchase_reihub_subscription success');
            setShowReiHubPaymentModal(false);
            setTimeout(() => document.activeElement.blur(), 1);
            setShowReiHubSuccessModal(true);
          }}
          refetchQueries={[basicUserProfile, ownerSettingsQuery]}
          renewalFrequency={renewalFrequency}
          fromPremiumSuccessModal
        />
      )}
    </>
  );
};

PremiumIsActiveModal.defaultProps = {
  showREIHubOption: false,
  showPremiumIsNowActive: true,
  mobileFullScreen: false,
  planName: 'Premium',
};

PremiumIsActiveModal.propTypes = {
  onClose: PropTypes.func,
  segmentLocation: PropTypes.string,
  segmentProperties: PropTypes.object,
  segmentLoadEvent: PropTypes.string,
  showREIHubOption: PropTypes.bool,
  open: PropTypes.bool,
  privateBasePath: PropTypes.string,
  listingsCount: PropTypes.number,
  showPremiumIsNowActive: PropTypes.bool,
  titlePrefix: PropTypes.string,
  text: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.string),
  mobileFullScreen: PropTypes.bool,
  showExpirationDate: PropTypes.bool,
  isProPlan: PropTypes.bool,
  isPremiumPlusPlan: PropTypes.bool,
};

export default PremiumIsActiveModal;
