import { EnumRentChargeType } from '../../pages/enums';

const monthlyChargeTypes = [
  {
    label: '',
    value: null,
  },
  {
    label: 'Rent',
    value: EnumRentChargeType.RENT,
  },
  {
    label: 'Utility Charge',
    value: EnumRentChargeType.UTILITY_CHARGE,
  },
  {
    label: 'Other',
    value: EnumRentChargeType.OTHER,
  },
];

export default monthlyChargeTypes;
