import React, { memo } from 'react';
import PropTypes from 'prop-types';

const PencilDocument = (props) => (
  <svg {...props} viewBox="0 0 47 54">
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.secondarycolor}
        d="m23.334 52.333 1.333-6.667 14.667-13.333 5.333 5.333L30 51zM26 1.666l10.667 10.667H26z"
      />
      <path
        d="m41.18 31.43 4.403 4.891c.608.676.566 1.705-.094 2.3L30.493 52.121a1.582 1.582 0 0 1-.78.381l-6.137 1.098a1.667 1.667 0 0 1-1.913-1.319 1.627 1.627 0 0 1 .031-.77l1.733-5.989c.083-.285.242-.54.46-.736l14.996-13.502c.66-.595 1.689-.53 2.297.146ZM27.104.334c.274 0 .536.112.729.312l9.53 9.87.015.016c.053.058.1.123.14.192l.006.01a1.054 1.054 0 0 1 .055.113l.008.02c.012.03.022.062.031.094l.01.037a.991.991 0 0 1 .02.102l.002.022.002.016.006.115v16.452c0 .578-.458 1.047-1.023 1.047a1.035 1.035 0 0 1-1.024-1.047V12.298H25.516a1.035 1.035 0 0 1-1.024-1.046V2.426l-21.778.001V49.69h14.86c.565 0 1.024.468 1.024 1.047 0 .578-.459 1.047-1.024 1.047H1.69a1.035 1.035 0 0 1-1.023-1.047V1.38C.667.802 1.125.333 1.69.333h25.414ZM25.437 46.21l-.057.051-1.496 5.172 5.3-.948.008-.009-3.755-4.266Zm10.73-9.663-9.194 8.28 3.756 4.265 9.194-8.278-3.756-4.267Zm-20.26 3.272c.566 0 1.024.47 1.024 1.047 0 .579-.458 1.047-1.023 1.047H8.044a1.035 1.035 0 0 1-1.024-1.047c0-.578.458-1.047 1.024-1.047h7.864Zm24.052-6.684-2.255 2.03 3.744 4.252.01.014 2.303-2.072-3.802-4.224Zm-24.051.104c.565 0 1.023.468 1.023 1.046 0 .579-.458 1.047-1.023 1.047H8.044a1.035 1.035 0 0 1-1.024-1.047c0-.578.458-1.046 1.024-1.046h7.864Zm9.53-9.872c.565 0 1.024.469 1.024 1.047s-.459 1.047-1.024 1.047H8.044a1.035 1.035 0 0 1-1.024-1.047c0-.578.458-1.047 1.024-1.047h17.394Zm0-6.58c.565 0 1.024.468 1.024 1.046 0 .579-.459 1.047-1.024 1.047H8.044a1.035 1.035 0 0 1-1.024-1.047c0-.578.458-1.047 1.024-1.047h17.394Zm-9.53-6.581c.565 0 1.023.468 1.023 1.047 0 .578-.458 1.046-1.023 1.046H8.044a1.035 1.035 0 0 1-1.024-1.046c0-.579.458-1.047 1.024-1.047h7.864Zm10.769-7.778h-.138v7.778h7.646l-7.508-7.778Z"
        fill={props.color}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

PencilDocument.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  secondarycolor: PropTypes.string,
};

PencilDocument.defaultProps = {
  width: 47,
  height: 54,
  color: '#042238',
  secondarycolor: '#7FE3FF',
};

export default memo(PencilDocument);
