import React from 'react';

import Input from '../Input';

import CoreField from './CoreField';

export const TextField = (props) => (
  <CoreField
    component={Input}
    type="text"
    pattern="[a-zA-Z]*"
    parse={(value) => (value ? value.replace(/[^a-zA-Z\s]/gi, '') : '')}
    {...props}
  />
);

export default TextField;
