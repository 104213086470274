import React from 'react';

import convertCentsToDollars from '../../helpers/convert-cents-to-dollars';
import convertDollarsToCents from '../../helpers/convert-dollars-to-cents';
import DollarIcon from '../../icons/Dollar';
import InputWithIcon from '../InputWithIcon';

import CoreField from './CoreField';

const MoneyField = ({ asString = false, asCents = true, ...props }) => (
  <CoreField
    component={InputWithIcon}
    type="number"
    pattern="[0-9]*"
    icon={DollarIcon}
    format={(value) => {
      if (!asCents) return value;

      return value != null && value !== '' ? convertCentsToDollars(value) : '';
    }}
    parse={(value) => {
      if (!asCents) return asString ? `${value}` : value;

      if (value == null || value === '') return null;

      const truncated = `${value}`.substring(
        0,
        `${value}`.indexOf('.') !== -1
          ? `${value}`.indexOf('.') + 3
          : `${value}`.length,
      );

      const cents = convertDollarsToCents(truncated);

      return asString ? `${cents}` : cents;
    }}
    {...props}
  />
);

export default MoneyField;
