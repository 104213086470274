export function mapAddressTypeToString(type) {
  if (type == undefined) {
    return undefined;
  }
  switch (type) {
    case 0:
      return 'OWN';
    case 1:
      return 'RENT';
    case 2:
      return 'FAMILY';
    case 3:
      return 'OTHER';
  }
}
