import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import moment from 'moment';

import AddressAutoSuggestFields from '../../../../../../components/AddressAutoSuggestFields';
import {
  DateField,
  InputField,
  PhoneField,
} from '../../../../../../components/FinalFormFields';
import SSNField from '../../../../../../components/SSNField';
import ProfileThick from '../../../../../../icons/ProfileThick';
import Security from '../../../../../../icons/Security';
import composeValidators from '../../../../../../validators/composeValidators';
import fieldRequired from '../../../../../../validators/fieldRequired';
import isDate from '../../../../../../validators/isDate';
import isDateLowerThan from '../../../../../../validators/isDateLowerThan';
import isSSN from '../../../../../../validators/isSSN';
import isValidStripeAccountName from '../../../../../../validators/isValidStripeAccountName';
import FormSection from '../FormSection';

import ValidInformationDisclaimer from './ValidInformationDisclaimer';

import styles from './StripeIndividualForm.module.scss';

const StripeIndividualForm = ({
  title,
  isEditting,
  disclaimer,
  fromBusiness = false,
  inProduct,
}) => {
  return (
    <FormSection title={title || 'Your Information'} icon={ProfileThick}>
      {!inProduct && <ValidInformationDisclaimer text={disclaimer} />}
      <div className={styles.fieldsContainer}>
        <InputField
          label="Legal First Name"
          name="first_name"
          validate={isValidStripeAccountName}
          className={cx(styles.field, styles.firstName)}
          data-qa="stripe-individual-first-name-input"
        />
        <InputField
          label="Middle Name"
          name="middle_name"
          required={false}
          validate={isValidStripeAccountName}
          className={cx(styles.field, styles.middleName)}
          data-qa="stripe-individual-middle-name-input"
        />
        <InputField
          label="Last Name"
          name="last_name"
          validate={isValidStripeAccountName}
          className={cx(styles.field, styles.lastName)}
          data-qa="stripe-individual-last-name-input"
        />
        <PhoneField
          label="Phone Number"
          name="phone"
          className={cx(styles.field, styles.phone)}
          data-qa="stripe-individual-phone-input"
        />
        <div className={styles.addressSection}>
          <AddressAutoSuggestFields
            includePuertoRicoState
            streetAddressInputProps={
              fromBusiness
                ? {
                    label: 'Your Street Address',
                  }
                : {}
            }
            fieldsClassName={{
              address: styles.addressField,
              unit: styles.unitField,
              city: styles.cityField,
              zip: styles.zipField,
              state: styles.stateField,
            }}
            showPoBoxesNote={true}
          />
        </div>

        <div className={styles.lastRow}>
          <DateField
            data-qa="stripe-individual-birthdate-input"
            id="dob"
            name="dob"
            label="Date of Birth"
            validate={composeValidators(
              fieldRequired,
              isDate,
              isDateLowerThan(
                moment().subtract(18, 'years').format('MM/DD/YYYY'),
                "You can't be less than 18 years old",
              ),
            )}
            className={cx(styles.field, styles.birthdate)}
            toDate={moment().subtract(18, 'years').toDate()}
          />
          {!isEditting && (
            <Field
              data-qa="stripe-individual-ssn-input"
              label="SSN"
              component={SSNField}
              id="full_ssn"
              name="full_ssn"
              validate={composeValidators(isSSN, fieldRequired)}
              className={cx(styles.field, styles.ssn)}
              inputContainerClassName={cx(
                styles.ssnContainer,
                styles.moreSpecificity,
              )}
              icon={Security}
              parse={(value) => value.replace(/_|-/g, '')}
            />
          )}
        </div>
      </div>
    </FormSection>
  );
};

export default StripeIndividualForm;
