import { Experiments } from '../../constants/experiments';
import ApplicantOrange from '../../icons/ApplicantOrange';
import ApplicantColor from '../../icons/ApplicantOrange';
import BusinessBankColorIcon from '../../icons/BusinessBankColor';
import CustomQuestionsBadge from '../../icons/CustomQuestionsBadge';
import ESignColorIcon from '../../icons/ESignColor';
import FormsIcon from '../../icons/Forms';
import LeaseAddendumIcon from '../../icons/LeaseAddendum';
import LeaseColorIcon from '../../icons/LeaseColor';
import MarketingColorIcon from '../../icons/MarketingColor';
import MessagesColorIcon from '../../icons/MessagesColor';
import PaymentsIcon from '../../icons/Payments';
import PhoneFillIcon from '../../icons/PhoneFill';
import PiggyBankColorIcon from '../../icons/PiggyBankColor';
import { segmentTracking } from '../../services/utilities';

import { MODAL_LOCATIONS } from './constants';

const getOrderedItems = (items, order) => {
  return order.map((objectKey) => {
    return items[objectKey] || {};
  });
};

const sortBenefits = (location, user) => {
  const isGBBExperiment =
    user?.active_experiments?.some(
      (e) => e.name === Experiments.GoodBetterBest.name,
    ) || false;

  let benefitsOrder = ['marketing', 'leases', 'payments', 'support'];

  const phoneForwardingEnabled = user.has_phone_masking_enabled
    ? ['callForwarding']
    : [];

  const appFeeExperimentEnabled = user.app_fee_price_exp_premium_price
    ? ['appFees']
    : [];

  const itemsOrder = {
    marketing: [
      'review',
      ...(isGBBExperiment
        ? [
            'customQuestions',
            'insight',
            ...appFeeExperimentEnabled,
            ...phoneForwardingEnabled,
          ]
        : [
            ...phoneForwardingEnabled,
            ...appFeeExperimentEnabled,
            'customQuestions',
            'insight',
          ]),
    ],
    leases: [
      'agreements',
      'addendums',
      ...(isGBBExperiment
        ? ['forms', 'eSignatures']
        : ['eSignatures', 'forms']),
    ],
    payments: [
      'payouts',
      ...(isGBBExperiment
        ? ['achPayments', 'accounts']
        : ['accounts', 'achPayments']),
    ],
    support: ['chat', 'phone'],
  };

  // In this switch case we see which openLocation param is sent by
  // the modal to determine order and content of benefits displayed

  switch (location) {
    case MODAL_LOCATIONS.INVITE_TO_APPLY_ONBOARDING:
    case MODAL_LOCATIONS.INVITE_TO_APPLY:
    case MODAL_LOCATIONS.INCOME_INSIGHT_BANNER:
    case MODAL_LOCATIONS.STANDALONE_SCREENING:
      if (!isGBBExperiment) {
        itemsOrder.marketing = [
          ...appFeeExperimentEnabled,
          'insight',
          'customQuestions',
          ...phoneForwardingEnabled,
          'review',
        ];
      }
      break;

    case MODAL_LOCATIONS.PRESCREENER_TAB:
    case MODAL_LOCATIONS.APPLICATION_TAB:
      if (!isGBBExperiment) {
        itemsOrder.marketing = [
          'customQuestions',
          ...appFeeExperimentEnabled,
          'insight',
          ...phoneForwardingEnabled,
          'review',
        ];
      }
      break;

    case MODAL_LOCATIONS.HELP_CENTER_FAQ:
    case MODAL_LOCATIONS.HELP_CENTER_CALL:
      benefitsOrder = ['support', 'leases', 'payments', 'marketing'];

      break;

    case MODAL_LOCATIONS.BILLING:
    case MODAL_LOCATIONS.DASHBOARD:
    case MODAL_LOCATIONS.RESUBSCRIBE:
      benefitsOrder = ['leases', 'marketing', 'payments', 'support'];
      break;

    case MODAL_LOCATIONS.LEASE:
    case MODAL_LOCATIONS.LEASE_REVIEW:
    case MODAL_LOCATIONS.LEASE_IN_PROGRESS:
      benefitsOrder = ['leases', 'payments', 'marketing', 'support'];
      if (!isGBBExperiment) {
        itemsOrder.marketing = [
          ...appFeeExperimentEnabled,
          'insight',
          'customQuestions',
          ...phoneForwardingEnabled,
          'review',
        ];
      }
      break;

    case MODAL_LOCATIONS.E_SIGN_DASHBOARD:
      benefitsOrder = ['leases', 'payments', 'marketing', 'support'];
      if (!isGBBExperiment) {
        itemsOrder.marketing = [
          ...appFeeExperimentEnabled,
          'insight',
          'customQuestions',
          ...phoneForwardingEnabled,
          'review',
        ];
        itemsOrder.leases = ['eSignatures', 'addendums', 'agreements', 'forms'];
      }

      break;

    case MODAL_LOCATIONS.ADDENDUM_IN_PROGRESS:
    case MODAL_LOCATIONS.ADDENDUM:
      benefitsOrder = ['leases', 'payments', 'marketing', 'support'];
      if (!isGBBExperiment) {
        itemsOrder.leases = ['addendums', 'forms', 'eSignatures', 'agreements'];
      }

      break;
    case MODAL_LOCATIONS.GET_FORMS_PACK:
      benefitsOrder = ['leases', 'payments', 'marketing', 'support'];
      if (!isGBBExperiment) {
        itemsOrder.leases = ['forms', 'eSignatures', 'addendums', 'agreements'];
      }
      break;

    case MODAL_LOCATIONS.PAYMENTS_OVERVIEW:
    case MODAL_LOCATIONS.PAYMENTS_DASHBOARD:
      benefitsOrder = ['payments', 'leases', 'marketing', 'support'];
      if (!isGBBExperiment) {
        itemsOrder.leases = ['addendums', 'eSignatures', 'forms', 'agreements'];
      }
      break;

    case MODAL_LOCATIONS.PAYMENTS_MBA:
    case MODAL_LOCATIONS.BANK_ACCOUNTS:
    case MODAL_LOCATIONS.ENTITY_BANK_ACCOUNTS:
      benefitsOrder = ['payments', 'leases', 'marketing', 'support'];
      break;

    case MODAL_LOCATIONS.USE_YOUR_OWN_APP:
      benefitsOrder = ['marketing', 'leases', 'payments'];
      if (!isGBBExperiment) {
        itemsOrder.marketing = [
          'useYourOwnApp',
          'review',
          ...phoneForwardingEnabled,
          'insight',
          'customQuestions',
          ...appFeeExperimentEnabled,
        ];
      }
      break;

    default:
      break;
  }

  return { benefitsOrder, itemsOrder };
};

const getModalBenefits = (location, user, config) => {
  const { app_fee_price_exp_premium_price, application_fee } = user;
  const { LEASE_AGREEMENT_FEE, FORMS_PACK_PRICE, ACH_FEE, PRIVATE_BASE_PATH } =
    config;

  const benefits = {
    marketing: {
      key: 'marketing',
      title: 'Finding a Tenant',
      titleShort: 'Vacancy',
      items: {
        useYourOwnApp: {
          title: 'Link to Your Own Application',
          description: `Use your application instead of ours`,
          icon: ApplicantOrange,
        },
        callForwarding: {
          title: 'Marketing Call Forwarding',
          description: 'Keep your phone number private.',
          icon: PhoneFillIcon,
          cta: 'Read more >>',
          ctaLink: (extraParams) =>
            `${PRIVATE_BASE_PATH}marketing_call_forwarding${
              extraParams?.listingGlobalId
                ? `/${extraParams.listingGlobalId}`
                : ''
            }`,
          onClick: () => {
            segmentTracking('learn_more clicked', {
              location: 'Marketing Set Up Listing Completed Modal',
            });
          },
          plan: 'premium',
        },
        ownApplication: {
          title: 'Link to Your Own Application',
          description: 'Use your application instead of ours',
          icon: ApplicantColor,
        },
        review: {
          title: 'Priority Listing Review',
          description: 'Get your listing posted faster',
          icon: MarketingColorIcon,
        },
        customQuestions: {
          title: 'Custom Questions',
          description:
            'Add 4 custom questions to the pre-screener & application',
          icon: CustomQuestionsBadge,
        },
        insight: {
          title: 'Income Insights',
          description: 'Verify a renter’s income',
          icon: PiggyBankColorIcon,
          plan: 'premium',
        },
        appFees: {
          title: 'Lower Screening Fees',
          description: `Get 31% more applicants with a $${app_fee_price_exp_premium_price} fee instead of $${application_fee}`,
          icon: ApplicantOrange,
          plan: 'premium',
        },
      },
    },

    leases: {
      key: 'leases',
      title: 'Leases & Forms',
      titleShort: 'Leases',
      items: {
        agreements: {
          title: `Unlimited Lease Agreements`,
          description: `Normally $${LEASE_AGREEMENT_FEE}`,
          icon: LeaseColorIcon,
          iconProps: {
            style: {
              marginLeft: '6px',
            },
          },
        },
        addendums: {
          title: `Unlimited Lease Addendums`,
          icon: LeaseAddendumIcon,
          iconProps: {
            style: {
              marginLeft: '6px',
            },
          },
        },
        eSignatures: {
          title: `Unlimited E-Signatures`,
          icon: ESignColorIcon,
          plan: 'premium',
        },
        forms: {
          title: 'Landlord Forms Pack',
          description: `Normally $${FORMS_PACK_PRICE}`,
          icon: FormsIcon,
        },
      },
    },

    payments: {
      key: 'payments',
      title: 'Rent Payments',
      titleShort: 'Payments',
      items: {
        payouts: {
          title: 'Expedited Rent Payouts',
          description: 'Deposits hit your account in just 2-4 business days',
          icon: PaymentsIcon,
        },
        accounts: {
          title: 'Unlimited Bank Accounts',
          description: 'Deposits into unlimited number of bank accounts',
          icon: BusinessBankColorIcon,
          plan: 'premium',
        },
        achPayments: {
          title: 'Free ACH Payments',
          description: `Normally $${ACH_FEE} fee for renters`,
          icon: BusinessBankColorIcon,
          plan: 'premium',
        },
      },
    },

    support: {
      key: 'support',
      title: 'Support',
      items: {
        chat: {
          title: 'Prioritized Chat Support',
          description: 'Faster responses from chat support',
          icon: MessagesColorIcon,
        },
        phone: {
          title: 'Phone Support',
          description: 'Call our Colorado-based team to get help',
          icon: PhoneFillIcon,
        },
      },
    },
  };

  const { benefitsOrder, itemsOrder } = sortBenefits(location, user);

  return benefitsOrder.map((section) => {
    return {
      ...benefits[section],
      items: getOrderedItems(benefits[section].items, itemsOrder[section]),
    };
  });
};

export default getModalBenefits;
