import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../Button';
import Tag from '../../../../Tag';
import { H3, Paragraph } from '../../../../Typography';

import styles from './PackageHeader.module.scss';

const PackageHeader = ({
  title,
  price,
  displayMonthlyPrice,
  onButtonClick,
  isPro = false,
}) => (
  <div
    className={cx(styles.packHeader, {
      [styles.proHeader]: isPro,
      [styles.premiumHeader]: !isPro,
    })}
  >
    <div className={styles.packTitleWrapper}>
      <H3 className={styles.packTitle}>{title}</H3>
      {!isPro && (
        <Tag accent uppercase={false}>
          Best Value
        </Tag>
      )}
    </div>
    <Paragraph className={styles.price}>
      ${displayMonthlyPrice ? (price / 12).toFixed(2) : price}
      <span className={styles.per}>
        {displayMonthlyPrice ? 'Per month' : 'Per year'}
      </span>
      {displayMonthlyPrice && (
        <span className={styles.annually}>Billed annually</span>
      )}
    </Paragraph>
    <Button
      className={styles.btn}
      onClick={onButtonClick}
      {...(isPro ? { secondary: true } : {})}
    >
      Get {title}
    </Button>
  </div>
);

PackageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  displayMonthlyPrice: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  isPro: PropTypes.bool,
};

export default PackageHeader;
