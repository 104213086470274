import LEASE_PROPERTY_TYPE from '../constants/lease/LeasePropertyType';

const isSinglePropertyType = (propertyType) => {
  if (!propertyType) {
    return;
  }

  switch (propertyType) {
    case LEASE_PROPERTY_TYPE.SINGLE_FAMILY:
    case LEASE_PROPERTY_TYPE.TOWNHOUSE:
    case LEASE_PROPERTY_TYPE.CONDO:
      return true;
    case LEASE_PROPERTY_TYPE.MULTI_FAMILY:
    case LEASE_PROPERTY_TYPE.APARTMENT:
    case LEASE_PROPERTY_TYPE.OTHER:
      return false;
    default:
      // There could be values outside of these from legacy data
      // In those cases they will always be single property types
      return true;
  }
};

export default isSinglePropertyType;
