const DIGITAL_LEASE_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED_UNPAID: 'COMPLETED_UNPAID',
  COMPLETED: 'COMPLETED',
};

export const { NOT_STARTED, IN_PROGRESS, COMPLETED_UNPAID, COMPLETED } =
  DIGITAL_LEASE_STATUS;

export default DIGITAL_LEASE_STATUS;
