import React from 'react';
import PropTypes from 'prop-types';

import FinalForm from '../../../../components/FinalForm';
import {
  RadioField,
  SelectField,
  TextAreaField,
} from '../../../../components/FinalFormFields';
import { howDidYouHear } from '../../../../constants';

import styles from './OtherInfoEdit.module.scss';

const OtherInfoEdit = ({ onSubmit, application, id }) => {
  const {
    custom_application_question_1,
    custom_application_question_2,
    custom_application_question_3,
    custom_application_question_4,
  } = application || {};

  return (
    <FinalForm
      id={id}
      initialValues={{
        disability: application?.disability,
        disability_description: application?.disability_description,
        additional_comments: application?.additional_comments,
        custom_application_answer_1: application?.custom_application_answer_1,
        custom_application_answer_2: application?.custom_application_answer_2,
        custom_application_answer_3: application?.custom_application_answer_3,
        custom_application_answer_4: application?.custom_application_answer_4,
        how_did_you_hear: application?.how_did_you_hear,
        how_did_you_hear_other: application?.how_did_you_hear_other,
      }}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <article>
          <div className={styles.section}>
            {custom_application_question_1 && (
              <TextAreaField
                className={styles.question}
                label={custom_application_question_1}
                name="custom_application_answer_1"
                rows="4"
              />
            )}
            {custom_application_question_2 && (
              <TextAreaField
                className={styles.question}
                label={custom_application_question_2}
                name="custom_application_answer_2"
                rows="4"
              />
            )}
            {custom_application_question_3 && (
              <TextAreaField
                className={styles.question}
                label={custom_application_question_3}
                name="custom_application_answer_3"
                rows="4"
              />
            )}
            {custom_application_question_4 && (
              <TextAreaField
                className={styles.question}
                label={custom_application_question_4}
                name="custom_application_answer_4"
                rows="4"
              />
            )}
          </div>
          {application?.applying_as_tenant && (
            <>
              <div className={styles.section}>
                <label className={styles.label}>
                  Do you have any special needs or requirements we should be
                  aware of?
                </label>
                <RadioField
                  name="disability"
                  className={styles.radioGroup}
                  options={[
                    {
                      label: 'Yes',
                      value: true,
                    },
                    {
                      label: 'No',
                      value: false,
                    },
                  ]}
                />
                {values?.disability && (
                  <TextAreaField
                    className={styles.description}
                    label="Please explain"
                    name="disability_description"
                    maxLength={1000}
                    withCounter
                    rows="4"
                  />
                )}
              </div>
              <div className={styles.howDidYouHear}>
                <SelectField
                  name="how_did_you_hear"
                  label="How did you hear about this property?"
                  options={howDidYouHear?.map(({ title, ...option }) => ({
                    ...option,
                    label: title,
                  }))}
                />
              </div>
              {values?.how_did_you_hear === 'OTHER' && (
                <div className={styles.section}>
                  <TextAreaField
                    name="how_did_you_hear_other"
                    maxLength={250}
                    withCounter
                    rows="4"
                  />
                </div>
              )}
            </>
          )}
          <div className={styles.section}>
            <TextAreaField
              required={false}
              label="Other comments"
              name="additional_comments"
              rows="4"
            />
          </div>
        </article>
      )}
    </FinalForm>
  );
};

OtherInfoEdit.propTypes = {
  styles: PropTypes.object,
  application: PropTypes.object,
  data: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onInvalidSubmit: PropTypes.func,
  doSubmit: PropTypes.bool,
};

export default OtherInfoEdit;
