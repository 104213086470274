import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const RealEstateHouseLock = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g
      stroke={props.color}
      strokeWidth={props.strokeWidth || 1.25}
      fillOpacity="0"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    >
      <g strokeLinecap="round">
        <path d="M3.5 12.5v11h10M.5 12l11.5-10.5 11.5 10.5M16 2.5h3.5v3.5" />
        <path d="M10.5 6.5h3v3h-3z" />
      </g>
      <path
        strokeLinecap="round"
        d="M15.5 17h8v6.5h-8zM19.5 21.5v-1.5M16.5 17v-1c0-1.657 1.342-3 3-3 1.657 0 3 1.343 3 3v1"
      />
      <path d="M20 19.5c0 .276-.223.5-.5.5-.276 0-.5-.224-.5-.5s.224-.5.5-.5c.277 0 .5.224.5.5z" />
    </g>
  </svg>
);

RealEstateHouseLock.defaultProps = {
  width: 56,
  height: 56,
  color: colors.black,
};

RealEstateHouseLock.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(RealEstateHouseLock);
