import React from 'react';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';

import styles from './PrepaidRentLawHelper.module.scss';

const PrepaidRentLawHelper = () => {
  return (
    <HelpfulInfoCard
      noIcon
      title={'Virginia Prepaid Rent Law'}
      className={styles.helpfulInfoCard}
      color="iceCold"
    >
      <p className={styles.description}>
        Any prepaid rent (not including security deposit) must be kept in an
        escrow account in a FDIC bank authorized to do business in Virginia. You
        must deposit it by the end of the 5th business day after you receive it,
        and it should remain in the account until the prepaid rent is due.
      </p>
    </HelpfulInfoCard>
  );
};

export default PrepaidRentLawHelper;
