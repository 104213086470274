import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import cx from 'classnames';
import kebabCase from 'lodash.kebabcase';
import PropTypes from 'prop-types';

import RemoveIcon from '../../../icons/Remove';
import Resources from '../../../icons/resources';
import composeValidators from '../../../validators/composeValidators';
import fieldRequired from '../../../validators/fieldRequired';
import fieldRequiredCustom from '../../../validators/fieldRequiredCustom';
import validEmail from '../../../validators/validEmail';
import validName from '../../../validators/validName';
import validPhone from '../../../validators/validPhone';
import Button from '../../Button';
import FinalFormError from '../../FinalFormError';
import FlatButton from '../../FlatButton';
import HelpfulInfoCard from '../../HelpfulInfo/HelpfulInfoCard';
import Input from '../../Input';
import trimEmail from '../../Input/trimEmail';
import PhoneField from '../../PhoneField';
import RadioGroup from '../../RadioGroup';

import styles from './AddEditTenantForm.module.scss';

const FormFields = ({
  classNameFields,
  inUseValidators,
  onRemove,
  isEdit,
  fieldName,
  index,
}) => {
  const fieldNamePrefix = fieldName ? `${fieldName}[${index}].` : '';

  return (
    <div className={classNameFields}>
      <div className={styles.row}>
        <Field
          label="First Name"
          component={Input}
          name={`${fieldNamePrefix}first_name`}
          id="first_name"
          className={styles.name}
          validate={composeValidators(fieldRequired, validName)}
          inputProps={{
            'data-qa': kebabCase(`${fieldNamePrefix}first-name`),
          }}
        />
        <Field
          label="Last Name"
          component={Input}
          name={`${fieldNamePrefix}last_name`}
          id="last_name"
          className={styles.name}
          validate={composeValidators(fieldRequired, validName)}
          inputProps={{
            'data-qa': kebabCase(`${fieldNamePrefix}last-name`),
          }}
        />
      </div>
      {!isEdit && (
        <div className={styles.row}>
          <Field
            component={RadioGroup}
            id="invite_type"
            name={`${fieldNamePrefix}invite_type`}
            label="Send the tenant portal invite by:"
            radioRowClassName={styles.radioOption}
            options={[
              {
                label: 'Email',
                value: 'email',
              },
              {
                label: 'Text',
                value: 'text',
              },
              {
                label: 'Email & Text',
                value: 'both',
              },
            ]}
            validate={fieldRequired}
            className={styles.fieldMarginBottom}
          />
        </div>
      )}
      <FormSpy
        subscription={{
          values: true,
        }}
      >
        {({ values }) => {
          const inviteType = fieldName
            ? values[fieldName][index].invite_type
            : values.invite_type;

          if (values.invite_type === 'text') {
            fieldName
              ? (values[fieldName][index].email = '')
              : (values.email = '');
          }

          if (values.invite_type === 'email') {
            fieldName
              ? (values[fieldName][index].telephone = '')
              : (values.telephone = '');
          }

          const emailValidations = [];
          const phoneValidations = [];

          if (isEdit) {
            values[`${fieldNamePrefix}telephone`]
              ? emailValidations.push(validEmail)
              : emailValidations.push(
                  fieldRequiredCustom('Add an email or phone number'),
                  validEmail,
                );

            values[`${fieldNamePrefix}email`]
              ? phoneValidations.push(validPhone)
              : phoneValidations.push(
                  fieldRequiredCustom('Add an email or phone number'),
                  validPhone,
                );
          } else {
            phoneValidations.push(fieldRequired, validPhone);
            emailValidations.push(fieldRequired, validEmail);
          }

          inUseValidators?.emailInUse &&
            emailValidations.push(inUseValidators.emailInUse);
          inUseValidators?.phoneInUse &&
            phoneValidations.push(inUseValidators.phoneInUse);

          return (
            <>
              {(isEdit || inviteType === 'both' || inviteType === 'email') && (
                <div className={styles.row}>
                  <Field
                    label="Email"
                    component={Input}
                    type="email"
                    name={`${fieldNamePrefix}email`}
                    id="email"
                    className={styles.email}
                    validate={composeValidators(...emailValidations)}
                    inputProps={{
                      'data-qa': kebabCase(`${fieldNamePrefix}email`),
                    }}
                    parse={trimEmail}
                  />
                </div>
              )}
              {(isEdit || inviteType === 'both' || inviteType === 'text') && (
                <div className={styles.row}>
                  <Field
                    label="Phone"
                    component={PhoneField}
                    name={`${fieldNamePrefix}telephone`}
                    id="telephone"
                    className={styles.phone}
                    inputProps={{
                      'data-qa': kebabCase(`${fieldNamePrefix}phone`),
                    }}
                    validate={composeValidators(...phoneValidations)}
                  />
                </div>
              )}
              {!isEdit && inviteType === 'text' && (
                <HelpfulInfoCard
                  noTitle
                  Icon={Resources}
                  className={styles.helpfulInfoCard}
                  iconProps={{ className: styles.helpfulInfoCardIcon }}
                >
                  While you can invite a tenant to their portal via text, they
                  will need an email address to sign up and access their portal.
                </HelpfulInfoCard>
              )}
              {onRemove && index !== 0 && (
                <div
                  className={cx(styles.removeBtnColumn, {
                    [styles.removeBtnMore]: inviteType === 'text',
                  })}
                >
                  <FlatButton
                    icon={RemoveIcon}
                    onClick={onRemove}
                    className={styles.removeBtn}
                    iconProps={{ className: styles.removeIcon }}
                  >
                    <span>REMOVE</span>
                  </FlatButton>
                </div>
              )}
            </>
          );
        }}
      </FormSpy>
    </div>
  );
};

const AddEditTenantForm = ({
  className,
  onSubmit,
  initialValues,
  onCancelButtonClick,
  submitLabel,
  submitBtnId,
  classNameFields,
  inUseValidators,
  onRemove,
  isEdit,
  fieldName,
  index,
}) => {
  if (!fieldName) {
    return (
      <Form
        onSubmit={onSubmit}
        subscription={{
          submitting: true,
          values: true,
        }}
        initialValues={initialValues}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit} className={cx(styles.form, className)}>
            <FormFields
              classNameFields={classNameFields}
              inUseValidators={inUseValidators}
              isEdit={isEdit}
            />
            <div className={styles.btnWrapper}>
              {onCancelButtonClick && (
                <Button
                  secondary
                  className={styles.btn}
                  onClick={() => {
                    onCancelButtonClick();
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                className={styles.btn}
                type="submit"
                loading={submitting}
                {...(submitBtnId ? { id: submitBtnId } : {})}
              >
                {submitLabel}
              </Button>
            </div>
            <FinalFormError className={styles.error} />
            {values.role && (
              <Field component="input" type="hidden" name="role" />
            )}
          </form>
        )}
      </Form>
    );
  } else {
    return (
      <FormFields
        classNameFields={classNameFields}
        inUseValidators={inUseValidators}
        onRemove={onRemove}
        isEdit={isEdit}
        fieldName={fieldName}
        index={index}
      />
    );
  }
};

AddEditTenantForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onCancelButtonClick: PropTypes.func,
  submitLabel: PropTypes.string,
  submitBtnId: PropTypes.string,
  inUseValidators: PropTypes.object,
  onRemove: PropTypes.func,
  isEdit: PropTypes.bool,
};

AddEditTenantForm.defaultProps = {
  initialValues: {},
  submitLabel: 'Invite Tenant',
  isEdit: false,
};

export default AddEditTenantForm;
