import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';

import AddressAutoSuggestFields from '../../../../../../../components/AddressAutoSuggestFields';
import Input from '../../../../../../../components/Input';
import trimEmail from '../../../../../../../components/Input/trimEmail';
import PhoneField from '../../../../../../../components/PhoneField';
import RadioGroup from '../../../../../../../components/RadioGroup';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import validEmail from '../../../../../../../validators/validEmail';
import validPhone from '../../../../../../../validators/validPhone';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './IslandAgentPrompt.module.scss';

const IslandAgentPrompt = () => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  const {
    input: { value: liveOnTheSameIsland },
  } = useField('custom_state_data.live_on_the_same_island');

  return (
    <div className={styles.container}>
      <Field
        label="Do you, the landlord, live on the same island as the rental property?"
        component={RadioGroup}
        id="custom_state_data.live_on_the_same_island"
        name="custom_state_data.live_on_the_same_island"
        vertical
        options={[
          {
            id: 'custom_state_data.live_on_the_same_island_yes',
            value: true,
            label: 'Yes, I live on the same island.',
          },
          {
            id: 'custom_state_data.live_on_the_same_island_no',
            value: false,
            label:
              'No, I live off-island (e.g., another island OR out-of-state)',
          },
        ]}
        labelProps={{
          className: styles.questionLabel,
        }}
        validate={fieldRequired}
      />
      {liveOnTheSameIsland === false ? (
        <>
          <p className={styles.fatDescription}>ON-ISLAND AGENT INFORMATION</p>
          <p className={styles.description}>
            Landlords who do not live on the same island are required to provide
            contact details of an on-island agent.
          </p>
          <Field
            label="On-Island Agent Name"
            component={Input}
            id="custom_state_data.agent_name"
            name="custom_state_data.agent_name"
            validate={fieldRequired}
            inputProps={{
              className: styles.input,
            }}
          />
          <AddressAutoSuggestFields
            className={styles.address}
            streetAddressInputProps={{
              label: 'Agent Address',
            }}
            fieldNames={{
              city: 'custom_state_data.agent_city',
              unit: 'custom_state_data.agent_unit',
              address: 'custom_state_data.agent_address',
              zip: 'custom_state_data.agent_zip',
              state: 'custom_state_data.agent_state',
            }}
          />
          <Field
            className={styles.agentEmail}
            label="Agent Email"
            component={Input}
            hint="(Optional)"
            id="custom_state_data.agent_email"
            name="custom_state_data.agent_email"
            parse={trimEmail}
            validate={validEmail}
            labelProps={{
              className: styles.inputLabel,
            }}
            inputProps={{
              className: cx(styles.input, styles.agentEmail),
            }}
          />
          <Field
            label="Agent Phone number"
            component={PhoneField}
            hint="(Optional)"
            name="custom_state_data.agent_phone"
            id="custom_state_data.agent_phone"
            validate={validPhone}
            inputProps={{
              className: cx(styles.input, styles.phone),
            }}
          />
        </>
      ) : null}
    </div>
  );
};

export default IslandAgentPrompt;
