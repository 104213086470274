import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import ResourcesIcon from '../../../../../../../icons/resources';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './DepositDeductionsAK.module.scss';

const DepositDeductionsAK = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Security Deposit Deductions" icon={ResourcesIcon} />
      <label className={styles.label}>
        Are there any allowable deductions to be made to the Security Deposit?
      </label>
      <p className={styles.description}>
        Alaska law requires that you disclose and list any allowable deductions
        to be made to the security deposit in the lease agreement. Examples
        would include unpaid rent, cost of repairing damages beyond normal wear
        and tear and cleaning fees.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.has_deposit_deductions"
        name="custom_state_data.has_deposit_deductions"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'has_deposit_deductions_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'has_deposit_deductions_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition when="custom_state_data.has_deposit_deductions" is={true}>
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.deposit_deductions_explanation"
          name="custom_state_data.deposit_deductions_explanation"
          label="Allowable deductions to be made to the security deposit include:"
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      </Condition>
    </div>
  );
};

DepositDeductionsAK.propTypes = {
  className: PropTypes.string,
};

export default DepositDeductionsAK;
