import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import Input from '../../../../../../../components/Input';
import InputWithIcon from '../../../../../../../components/InputWithIcon';
import DollarCircledIcon from '../../../../../../../icons/DollarCircled';
import PercentageIcon from '../../../../../../../icons/Percentage';
import ResourcesIcon from '../../../../../../../icons/resources';
import amountGreaterThenZero from '../../../../../../../validators/amountGreaterThenZero';
import composeValidators from '../../../../../../../validators/composeValidators';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import fieldRequiredShortStandard from '../../../../../../../validators/fieldRequiredShort';
import isLowerThan from '../../../../../../../validators/isLowerThan';
import isNumber from '../../../../../../../validators/isNumber';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './BankInfo.module.scss';

const BankInfo = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();

  const fieldRequiredShort = conditionalValidation(
    fieldRequiredShortStandard,
    !standAloneWizard,
  );

  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.infoBlock}>
        <SectionTitle
          title="Bank Location and Interest Rate"
          icon={DollarCircledIcon}
        />
        <label className={styles.label}>
          Where is your security deposit escrow account located and what is the
          yearly interest rate?
        </label>
        <p className={styles.description}>
          DC law requires that all security deposits be placed in an
          interest-earning escrow account in a FDIC insured bank located in
          Washington, DC and the annual interest rate be disclosed.
        </p>
        <Field
          label="Bank Name"
          className={styles.inputContainer}
          inputProps={{
            className: styles.input,
          }}
          id="deposit_bank_name"
          name="deposit_bank_name"
          component={Input}
          validate={fieldRequiredShort}
        />
        <Field
          label="Bank Address"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_address"
          name="deposit_bank_address"
          component={Input}
          validate={fieldRequiredShort}
        />
        <Field
          label="Interest Rate"
          className={styles.inputContainer}
          inputProps={{
            className: styles.interestRateField,
          }}
          id="custom_state_data.interest_rate_account"
          name="custom_state_data.interest_rate_account"
          component={InputWithIcon}
          icon={PercentageIcon}
          iconRight
          validate={composeValidators(
            fieldRequired,
            isNumber,
            amountGreaterThenZero,
            isLowerThan(1000),
          )}
          type="number"
          step="1"
          pattern="[0-9]*"
          parse={(value) => (value ? parseFloat(value) : value)}
          format={(value) => (value ? parseFloat(value) : value)}
        />
      </div>
      <HelpfulInfoCard
        Icon={ResourcesIcon}
        className={styles.helpfulInfoCard}
        title="District of Columbia Security Deposit Laws"
        color="iceCold"
        collapsable
      >
        <HelpfulInfoList>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            The maximum security deposit you can collect is 1 month's rent.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Security deposit must be kept in an interest bearing FDIC insured
            escrow account located in D.C.{' '}
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            The location of the bank account along with the interest rate must
            be disclosed.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            The security deposit must be returned within 45 days of the lease
            ending.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            You must include a written itemized statement of any lawful charges
            and deductions when you return the security deposit.
          </HelpfulInfoListItem>
        </HelpfulInfoList>
      </HelpfulInfoCard>
    </div>
  );
};

BankInfo.propTypes = {
  formValues: PropTypes.object,
  className: PropTypes.string,
};

export default BankInfo;
