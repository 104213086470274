import React from 'react';
import PropTypes from 'prop-types';

const Ebook = ({ width, height, ...rest }) => (
  <svg viewBox="0 0 48 49" fill="none" width={width} height={height} {...rest}>
    <path d="M8 7.30347H18V33.3035H8V7.30347Z" fill="#7FE3FF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.4448 4.97347C6.47565 4.97347 5.67 5.76895 5.67 6.74827V38.0659C5.67 39.0315 6.47927 39.8407 7.4448 39.8407H32.8817V41.5107H7.4448C5.55695 41.5107 4 39.9538 4 38.0659V6.74827C4 4.83941 5.56058 3.30347 7.4448 3.30347H30.0631C31.9684 3.30347 33.5079 4.84302 33.5079 6.74827V30.2713H31.8379V6.74827C31.8379 5.76534 31.046 4.97347 30.0631 4.97347H7.4448Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.46275 7.60121C7.46275 7.14006 7.83659 6.76621 8.29775 6.76621H29.1762C29.6373 6.76621 30.0112 7.14006 30.0112 7.60121V27.5052C30.0112 27.9664 29.6373 28.3402 29.1762 28.3402C28.715 28.3402 28.3412 27.9664 28.3412 27.5052V8.43621H9.13275V32.8643H29.9417C30.4029 32.8643 30.7767 33.2381 30.7767 33.6993C30.7767 34.1604 30.4029 34.5343 29.9417 34.5343H8.29775C7.83659 34.5343 7.46275 34.1604 7.46275 33.6993V7.60121Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.021 20.1079C32.3205 19.7573 32.8476 19.7158 33.1982 20.0153C34.8759 21.4483 36.4532 22.9077 37.6132 24.4033C38.7705 25.8954 39.5805 27.5099 39.5805 29.2451V36.9007L44.6331 43.6375C44.9098 44.0064 44.835 44.5298 44.4661 44.8065C44.0971 45.0832 43.5738 45.0084 43.2971 44.6395L38.0775 37.68C37.9691 37.5355 37.9105 37.3597 37.9105 37.179V29.2451C37.9105 28.0399 37.3459 26.7836 36.2936 25.4268C35.2439 24.0734 33.7765 22.7056 32.1136 21.2851C31.7629 20.9856 31.7215 20.4586 32.021 20.1079Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.2809 27.454C28.3204 26.4146 30.0322 26.4146 31.0716 27.454L34.9863 31.3687C35.3124 31.6948 35.3124 32.2235 34.9863 32.5496C34.6602 32.8757 34.1315 32.8757 33.8055 32.5496L29.8908 28.6349C29.5035 28.2476 28.8491 28.2476 28.4618 28.6349C28.3452 28.7516 28.2473 29.0094 28.2738 29.3968C28.286 29.5756 28.3237 29.7443 28.3723 29.878C28.4151 29.9956 28.453 30.0503 28.4614 30.0624C28.4634 30.0653 28.4637 30.0657 28.4618 30.0638C28.4972 30.0993 28.5294 30.1378 28.5579 30.179L32.4726 35.8336C32.5693 35.9732 32.6211 36.139 32.6211 36.3089V38.0487C32.6211 38.2709 32.7537 38.7149 33.0766 39.3608C33.381 39.9694 33.7949 40.644 34.2207 41.2828C34.6446 41.9185 35.0699 42.5035 35.3901 42.9304C35.5499 43.1435 35.6829 43.3163 35.7754 43.4353C35.8217 43.4948 35.8578 43.5408 35.8821 43.5716L35.9095 43.6062L35.9161 43.6145L35.9176 43.6163C36.2056 43.9764 36.1475 44.5022 35.7874 44.7903C35.4273 45.0784 34.9019 45.02 34.6138 44.6599L35.2658 44.1383C34.6138 44.6599 34.6139 44.66 34.6138 44.6599L34.6128 44.6586L34.6105 44.6557L34.6022 44.6453L34.5714 44.6064C34.5447 44.5726 34.5061 44.5235 34.4572 44.4606C34.3595 44.3349 34.2205 44.1543 34.0541 43.9324C33.7219 43.4894 33.2773 42.8783 32.8312 42.2091C32.3871 41.543 31.9311 40.8039 31.583 40.1076C31.2535 39.4486 30.9511 38.6965 30.9511 38.0487V36.5697L27.2194 31.1795C27.0261 30.9626 26.8926 30.6954 26.8028 30.4487C26.6992 30.1637 26.6303 29.8409 26.6077 29.5107C26.5646 28.8803 26.6842 28.0507 27.2809 27.454Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2933 38.014H14.4383V36.344H21.2933V38.014Z"
      fill="#042238"
    />
    <path
      d="M11.7775 36.3091C11.2903 36.3091 10.9076 36.6918 10.9076 37.179C10.9076 37.6662 11.2903 38.0489 11.7775 38.0489C12.2647 38.0489 12.6474 37.6662 12.6474 37.179C12.6474 36.6918 12.2647 36.3091 11.7775 36.3091Z"
      fill="#042238"
    />
    <path
      d="M23.9566 36.3091C23.4694 36.3091 23.0866 36.6918 23.0866 37.179C23.0866 37.6662 23.4694 38.0489 23.9566 38.0489C24.4437 38.0489 24.8265 37.6662 24.8265 37.179C24.8265 36.6918 24.4437 36.3091 23.9566 36.3091Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.8265 27.5748H12.6474V25.9048H24.8265V27.5748Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.8265 24.095H12.6474V22.425H24.8265V24.095Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.8265 20.6153H12.6474V18.9453H24.8265V20.6153Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.8265 17.1356H12.6474V15.4656H24.8265V17.1356Z"
      fill="#042238"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6069 13.6558H12.6474V11.9858H19.6069V13.6558Z"
      fill="#042238"
    />
  </svg>
);

Ebook.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Ebook;
