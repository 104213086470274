import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import IconButton from '../../../../../components/IconButton';
import Modal from '../../../../../components/Modal';
import colors from '../../../../../constants/colors';
import CloseIcon from '../../../../../icons/Close';
import { LulaExampleModalTypes } from '../LulaExampleModalTypes';

import InvoiceExample from './invoice-example.png';
import ReceiptExample from './receipt-example.png';

import styles from './LulaReceiptInvoiceExample.module.scss';

const LulaReceiptInvoiceExample = ({
  className,
  containerClassName,
  open,
  onClose,
}) => {
  const modalContent = {
    [LulaExampleModalTypes.RECEIPT]: {
      title: 'Example Receipt',
      img: ReceiptExample,
    },
    [LulaExampleModalTypes.INVOICE]: {
      title: 'Example Invoice',
      img: InvoiceExample,
    },
  };

  const title = modalContent[open]?.title;
  const ImageSrc = modalContent[open]?.img;

  return (
    <Modal
      open={!!open}
      className={cx(styles.modal, className)}
      containerClassName={cx(styles.modalContainer, containerClassName)}
      onClose={onClose}
    >
      <div className={styles.modalHeader}>
        <h2 className={styles.modalTitle}>{title}</h2>
        <IconButton
          className={styles.closeBtn}
          icon={CloseIcon}
          iconProps={{ color: colors.primary }}
          onClick={onClose}
        />
      </div>
      <div className={styles.modalContent}>
        <img className={styles.image} src={ImageSrc} alt={title} />
      </div>
    </Modal>
  );
};

LulaReceiptInvoiceExample.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  open: PropTypes.string,
  onClose: PropTypes.func,
};

export default LulaReceiptInvoiceExample;
