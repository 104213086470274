export default function isTenant(renter) {
  return (
    !(
      (!renter.leases || renter.leases.length === 0) &&
      (renter.past_leases
        ? renter.past_leases.length === 0
        : !renter.has_past_leases)
    ) ||
    (renter.archived_leases ? renter.archived_leases.length > 0 : false) ||
    (renter.applications && renter.applications.length === 0)
  );
}
