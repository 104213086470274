import React from 'react';

import IntercomSetUp from '../setup-components/IntercomSetUp';
import SegmentSetup from '../setup-components/SegmentSetup';
import SiftScienceSetup from '../setup-components/SiftScienceSetup';
import UniversalMessagingSetUp from '../setup-components/UnivesalMessagingSetUp';

const GlobalComponents = () => {
  return (
    <>
      <SiftScienceSetup />
      <IntercomSetUp />
      <SegmentSetup />
      <UniversalMessagingSetUp />
    </>
  );
};

export default GlobalComponents;
