import getListingBreadcrumbName from '../getListingBreadcrumbName';

const formatMupAddress = ({ listing = {}, breadCrumbProps = {} }) => {
  const {
    short = false,
    address,
    unit,
    city,
    state,
    zip,
    hide_street_number = false,
  } = listing;

  if (!address) return '';

  const unitString = unit && !hide_street_number ? `, ${unit}` : '';

  const addressString = hide_street_number
    ? address.replace(/^[0-9]+[ ]/, '')
    : address;

  if (short) {
    return `${addressString}${unitString}`;
  }

  const fullName = getListingBreadcrumbName({
    listing,
    separator: ', ',
    showRootLevel: true,
    ...breadCrumbProps,
  });

  return `${fullName}, ${city}, ${state} ${zip}`;
};

export default formatMupAddress;
