import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ApplicantOrange = ({ color, ...props }) => (
  <svg {...props} viewBox="0 0 24 30">
    <g fill="none" fillRule="evenodd">
      <path
        d="M13.855 17.682c-.762-.257-.903-1.279-.24-1.734a2.912 2.912 0 0 0 1.27-2.409A2.894 2.894 0 0 0 12 10.636a2.892 2.892 0 0 0-2.884 2.905c0 .976.478 1.865 1.268 2.406.664.455.523 1.478-.24 1.735-2.432.82-3.707 2.149-3.962 4.59h11.636c-.255-2.44-1.531-3.77-3.963-4.59Z"
        fill={color}
      />
      <path
        d="M8.641.455c.276 0 .528.155.65.402l1.254 2.507H22.91c.402 0 .727.325.727.727v24c0 .803-.65 1.454-1.454 1.454H1.818a1.455 1.455 0 0 1-1.454-1.454V1.18c0-.4.325-.726.727-.726h7.55Zm-.36 1.309H1.674V28.09c0 .08.065.145.145.145h20.364c.08 0 .145-.065.145-.145V4.673H9.737l-1.455-2.91Zm3.72 7.563c2.316 0 4.193 1.888 4.193 4.212 0 1.195-.5 2.314-1.36 3.107 2.605 1.032 4.001 2.767 4.286 5.49l.067.643a.727.727 0 0 1-.723.803H5.536a.727.727 0 0 1-.723-.803l.067-.642c.284-2.726 1.68-4.46 4.285-5.49a4.212 4.212 0 0 1-1.359-3.106c0-2.327 1.873-4.214 4.194-4.214Zm0 1.31a2.892 2.892 0 0 0-2.885 2.904c0 .976.478 1.865 1.268 2.406.664.455.523 1.478-.24 1.735-2.432.82-3.707 2.149-3.962 4.59h11.636c-.255-2.44-1.531-3.77-3.963-4.59-.762-.257-.903-1.279-.24-1.734a2.912 2.912 0 0 0 1.27-2.409A2.894 2.894 0 0 0 12 10.636Z"
        fill="#042238"
      />
    </g>
  </svg>
);

ApplicantOrange.defaultProps = {
  width: 24,
  height: 30,
  color: '#7FE3FF',
};

ApplicantOrange.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(ApplicantOrange);
