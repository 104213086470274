import React, { memo } from 'react';

import colors from '../constants/colors';

const CreditCardThick = (props) => (
  <svg viewBox="0 0 24 16" {...props} fill={props.color}>
    <path d="M21.12 0C22.705 0 24 1.24 24 2.778v10.444C24 14.76 22.705 16 21.12 16H2.88C1.295 16 0 14.76 0 13.222V2.778C0 1.24 1.295 0 2.88 0h18.24zm.96 5.556H1.92v7.666c0 .49.426.898.96.898h18.24c.534 0 .96-.407.96-.898V5.556zM7.2 9.838c.53 0 .96.42.96.94s-.43.94-.96.94H4.32a.95.95 0 01-.96-.94c0-.52.43-.94.96-.94zm12.48-3.091c.53 0 .96.42.96.94 0 .519-.43.94-.96.94H16.8a.95.95 0 01-.96-.94c0-.52.43-.94.96-.94zm-7.68 0c.53 0 .96.42.96.94 0 .519-.43.94-.96.94H4.32a.95.95 0 01-.96-.94c0-.52.43-.94.96-.94zm9.12-4.867H2.88c-.534 0-.96.407-.96.898v.898h20.16v-.898c0-.49-.426-.898-.96-.898z" />
  </svg>
);

CreditCardThick.defaultProps = {
  color: colors.text,
  width: 24,
  height: 16,
};

export default memo(CreditCardThick);
