import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './PageWrapper.module.scss';

const defaultPageTitle = 'TurboTenant - The Easiest Landlord Software';

const PageWrapper = ({ title, children }) => {
  useEffect(() => {
    document.title = title || defaultPageTitle;
    return () => {
      document.title = defaultPageTitle;
    };
  }, [title]);

  return <div className={styles.container}>{children}</div>;
};

PageWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default PageWrapper;
