import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import MoldIcon from '../../../../../../../icons/Mold';
import { useConfig } from '../../../../../../../providers/ConfigProvider';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './MoldDisclosureWA.module.scss';

const MoldDisclosureWA = ({ className }) => {
  const { ASSET_BUCKET_URL } = useConfig();
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Mold Disclosure" icon={MoldIcon} />
      <label className={styles.label}>
        Are you aware of any mold contamination on the premises?
      </label>
      <p className={styles.description}>
        Washington law requires that you disclose any knowledge of mold
        contamination on the premises as well as providing information on how a
        tenant can prevent mold growth. We have attached this information for
        you -{' '}
        <a
          href={`${ASSET_BUCKET_URL}uploads/assets/2016_10_moldguide12_wa.pdf`}
          target="_blank"
          rel="noreferrer noopener"
        >
          A brief guide to mold, moisture, and your home
        </a>
        .
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.has_mold"
        name="custom_state_data.has_mold"
        className={styles.radioWrapper}
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'has_mold_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'has_mold_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition when="custom_state_data.has_mold" is={true}>
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.has_mold_explanation"
          name="custom_state_data.has_mold_explanation"
          label=" Please state if mold contamination has been found above safe levels and is in the process of decontamination or if mold contamination has been found, but falls within safe levels after tests were conducted."
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      </Condition>
    </div>
  );
};

MoldDisclosureWA.propTypes = {
  className: PropTypes.string,
};

export default MoldDisclosureWA;
