// detect if app is already inside of the pwa
const isPWA = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (
    document.referrer.startsWith('android-app://') ||
    navigator.standalone ||
    isStandalone
  ) {
    return true;
  }
  return false;
};

export default isPWA;
