import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import PageNotFound from '../../../components/PageNotFound';
import componentLoader from '../../../helpers/component-loader';
import InAppLayouts from '../../../layout/InAppLayouts';
import { RouteWithLayout } from '../../../providers/LayoutProvider';
import CreateMaintenancePage from '../../properties/maintenance/CreateMaintenancePage';
import EditMaintenancePage from '../../properties/maintenance/EditMaintenancePage';
import MaintenanceRequest from '../../properties/maintenance/maintenance_request/MaintenanceRequest';
import SendWorkOrderPage from '../../properties/maintenance/send_work_order';
import UniversalCreateMaintenancePage from '../../properties/maintenance/UniversalCreateMaintenancePage';

import LulaMaintenanceRequestDemo from './LulaMaintenanceRequestDemo';

const MaintenancePage = componentLoader(
  () => import('../../../components/Maintenance/Page'),
);

const MaintenanceOnboarding = componentLoader(
  () => import('./MaintenanceOnboarding'),
);

const LulaMarketingPage = componentLoader(() => import('./LulaMarketingPage'));

const MaintenanceRoutes = ({ match }) => {
  const { url } = match;

  return (
    <Switch>
      <Route
        path={`${url}/maintenance-requests`}
        render={() => <MaintenancePage />}
      />
      <RouteWithLayout
        layout={InAppLayouts}
        path={`${url}/onboarding`}
        render={(props) => <MaintenanceOnboarding baseUrl={url} {...props} />}
      />
      <RouteWithLayout
        layout={InAppLayouts}
        exact
        path={`${url}/lula`}
        render={() => <LulaMarketingPage baseUrl={url} />}
      />
      <RouteWithLayout
        layout={InAppLayouts}
        exact
        path={`${url}/lula-demo`}
        render={() => <LulaMaintenanceRequestDemo baseUrl={url} />}
      />
      <Route
        exact
        path={`${url}/create`}
        component={UniversalCreateMaintenancePage}
      />
      <Route
        path={`${url}/:listingGlobalId/create`}
        component={CreateMaintenancePage}
      />
      <Route
        exact
        path={`${url}/:maintenanceRequestId/:listingGlobalId`}
        component={MaintenanceRequest}
      />
      <Route
        exact
        path={`${url}/:maintenanceRequestId/:listingGlobalId/edit`}
        component={EditMaintenancePage}
      />
      <Route
        path={`${url}/work_order/:listingGlobalId/:maintenanceRequestId`}
        component={SendWorkOrderPage}
      />
      <Route component={PageNotFound} />
    </Switch>
  );
};

MaintenanceRoutes.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default MaintenanceRoutes;
