import React from 'react';

import CreditCard from '../../../icons/CreditCard';
import CreditCardAmex from '../../../icons/CreditCardAmex';
import CreditCardDiscover from '../../../icons/CreditCardDiscover';
import CreditCardMasterCard from '../../../icons/CreditCardMasterCard';
import CreditCardVisa from '../../../icons/CreditCardVisa';

const cards = {
  'american express': CreditCardAmex,
  'amex': CreditCardAmex,
  'visa': CreditCardVisa,
  'discover': CreditCardDiscover,
  'mastercard': CreditCardMasterCard,
};

const PaymentCardIcon = ({ brand, ...props }) => {
  const lowerCaseBrand = brand ? brand.toLowerCase() : null;
  const Card = cards[lowerCaseBrand] || CreditCard;
  return <Card {...props} />;
};

export default PaymentCardIcon;
