import React from 'react';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from './RentIncreasesWA.module.scss';

const RentIncreasesWA = () => {
  return (
    <HelpfulInfoCard
      Icon={ResourcesIcon}
      className={styles.helpfulInfoCard}
      title="Rent Increases"
      color="iceCold"
    >
      In Seattle, WA, you must give tenant 180 day advance notice of any rent
      increases.
    </HelpfulInfoCard>
  );
};

export default RentIncreasesWA;
