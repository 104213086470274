import HelloSign from 'hellosign-embedded';

import { getConfig } from '../../services/configService';

import './style.css';

let client;

const HELLO_SIGN_EVENTS = Object.keys(HelloSign.events).map(
  (evKey) => HelloSign.events[evKey],
);

export const initializeHelloSign = () => {
  const { HELLO_SIGN_CLIENT_ID, NODE_ENV } = getConfig();
  client = new HelloSign({
    clientId: HELLO_SIGN_CLIENT_ID,
    locale: HelloSign.locales.EN_US,
    skipDomainVerification: NODE_ENV === 'development',
    // whiteLabeling
  });
};

export const open = ({
  url,
  allowCancel = true,
  container,
  debug = false,
  hideHeader = false,
  redirectTo,
  requestingEmail,
  timeout,
}) => {
  if (!url) {
    return console.error('HELLO_SIGN open(): URL is required.');
  }

  const opts = {
    allowCancel,
    container,
    debug,
    hideHeader,
  };

  if (timeout || timeout === 0) {
    opts.timeout = timeout;
  }
  if (redirectTo) {
    opts.redirectTo = redirectTo;
  }
  if (requestingEmail) {
    opts.requestingEmail = requestingEmail;
  }

  return client.open(url, opts);
};

export const close = () => client.close();

export const on = (event, handler) => {
  if (!HELLO_SIGN_EVENTS.includes(event)) {
    return console.error(
      `HELLO_SIGN on(): Invalid HelloSign event. Must be one of:\n${HELLO_SIGN_EVENTS.join(
        ',\n',
      )}`,
    );
  }

  if (typeof handler !== 'function') {
    return console.error(
      'HELLO_SIGN on(): Invalid handler. Must be a function.',
    );
  }

  return client.on(event, handler);
};

export const once = (event, handler) => {
  if (!HELLO_SIGN_EVENTS.includes(event)) {
    return console.error(
      `HELLO_SIGN once(): Invalid HelloSign event. Must be one of:\n${HELLO_SIGN_EVENTS.join(
        ',\n',
      )}`,
    );
  }

  if (typeof handler !== 'function') {
    return console.error(
      'HELLO_SIGN once(): Invalid handler. Must be a function.',
    );
  }

  return client.once(event, handler);
};

export const off = (event, handler) => {
  if (!HELLO_SIGN_EVENTS.includes(event)) {
    return console.error(
      `HELLO_SIGN off(): Invalid HelloSign event. Must be one of:\n${HELLO_SIGN_EVENTS.join(
        ',\n',
      )}`,
    );
  }

  if (handler && typeof handler !== 'function') {
    return console.error(
      'HELLO_SIGN off(): Invalid handler reference. Must be a reference to a handler function.',
    );
  }

  if (handler) {
    return client.off(event, handler);
  }
  return client.off(event);
};

export default {
  open,
  close,
  on,
  once,
  off,
  events: HelloSign.events,
  locales: HelloSign.locales,
  classNames: HelloSign.classNames,
  messages: HelloSign.messages,
};
