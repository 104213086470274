import { useEffect } from 'react';

function useBodyAndRootStyles(styles) {
  useEffect(() => {
    const originalBodyStyles = document.body.style.cssText;
    const originalRootStyles = document.querySelector('#root').style.cssText;

    document.body.style.cssText += styles;
    document.querySelector('#root').style.cssText += styles;

    return () => {
      document.body.style.cssText = originalBodyStyles;
      document.querySelector('#root').style.cssText = originalRootStyles;
    };
  }, [styles]);
}

export default useBodyAndRootStyles;
