import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from './BankInfoCO.module.scss';

const BankInfoCO = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <HelpfulInfoCard
      Icon={ResourcesIcon}
      className={styles.helpfulInfoCard}
      title="Colorado Security Deposit Laws"
      collapsable
    >
      <p className={styles.desc}>
        Though they're included in the lease agreement, we've highlighted some
        rules you need to know:
      </p>
      <HelpfulInfoList>
        <HelpfulInfoListItem className={styles.helpfulItem}>
          The maximum security deposit you can collect is 2 times the monthly
          rent.
        </HelpfulInfoListItem>
        <HelpfulInfoListItem className={styles.helpfulItem}>
          The maximum additional pet security deposit you can collect is $300,
          which must be refundable.
        </HelpfulInfoListItem>
        <HelpfulInfoListItem className={styles.helpfulItem}>
          The security deposit must be returned within 60 days of the lease
          ending.
        </HelpfulInfoListItem>
        <HelpfulInfoListItem className={styles.helpfulItem}>
          You must include a written itemized statement of any lawful charges
          and deductions when you return the security deposit.
        </HelpfulInfoListItem>
      </HelpfulInfoList>
    </HelpfulInfoCard>
  </div>
);

BankInfoCO.propTypes = {
  className: PropTypes.string,
};

export default BankInfoCO;
