export const COOKIE_NAMES = {
  IMPACT_COOKIE_NAME: 'IR_16969',
  PREMIUM_PRICE: 'premium_price_exp',
  FORMS_EXP: 'forms_exp',
  APP_SUMO: 'appsumo',
  GCLID: 'gclid',
  GCLAW: '_gcl_aw',
  APPLICATION_FEE_EXP: 'application_fee_exp',
  PREMIUM_REFERRAL: 'premium_referral',
  ACADEMY_REFERRAL: 'academy_referral',
  APPLICATION_FEE_PRICE_EXPERIMENT: 'application_fee_price_experiment',
  SIGNUP_TRACKING: 'signup_tracking',
  SHARE_A_SALE_COOKIE_NAME: 'sas_m_awin',
  FACEBOOK_CLICK_COOKIE_NAME: '_fbc',
  STAGE_SPECIFIC_PRICING: 'stage_specific_pricing',
  LULA_MODAL_SEEN: 'lula_modal_seen',
  LULA_PROMO_DISMISSED: 'lula_promo_dismissed',
  LULA_DEMO_MAINTENANCE_REQUEST: 'lula_demo_maintenance_request',
  EXPERIMENT_GBB: 'turbotenant_core_01',
};

export function getCookies() {
  if (document.cookie) {
    const cookieArray = document.cookie.split(/; */);
    const cookies = {};
    cookieArray.forEach((cookie) => {
      const array = cookie.split(/ *= */);
      cookies[array[0]] =
        array.length > 1 ? array.slice(1, array.length).join('') : '';
    });
    return cookies;
  }
  return {};
}

export function setCookie(name, val, ms) {
  const expire = new Date(Date.now() + ms);
  const [topLevel, domainName] = window.location.hostname.split('.').reverse();
  let domain = [domainName, topLevel].filter((a) => a).join('.');
  /**
   * NOTE: `localhost` does not allow `.` at the start of domain
   * but for production it's required to be global cookie
   */
  if (topLevel && domainName) {
    domain = `.${domain}`;
  }
  document.cookie = `${name}=${val};expires=${expire.toUTCString()};path=/;domain=${domain}`;
}
