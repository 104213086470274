import React, { memo } from 'react';
import PropTypes from 'prop-types';

const FacebookLogo = (props) => (
  <svg fill="none" {...props}>
    <g clipPath="url(#a)">
      <mask
        id="b"
        width={24}
        height={24}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path fill="#fff" d="M0 0h24v24H0V0Z" />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#1877F2"
          fillRule="evenodd"
          d="M24 12.073C24 5.405 18.627-.001 12-.001 5.371 0-.002 5.405-.002 12.073c0 6.026 4.388 11.021 10.125 11.927v-8.437H7.077v-3.49h3.047v-2.66c0-3.026 1.792-4.697 4.533-4.697 1.313 0 2.687.235 2.687.235v2.972H15.83c-1.49 0-1.955.93-1.955 1.885v2.265h3.328l-.532 3.49h-2.796V24C19.612 23.094 24 18.1 24 12.073Z"
          clipRule="evenodd"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

FacebookLogo.defaultProps = {
  width: 24,
  height: 24,
};

FacebookLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(FacebookLogo);
