import { CometChat } from '@cometchat-pro/chat';

const loginCometChatUser = (userToken) => {
  CometChat.getLoggedinUser().then(
    (user) => {
      if (!user) {
        CometChat.login(userToken).then(
          (user) => {
            console.log('Messging Login Successful:', { user });
          },
          (error) => {
            console.log('Messaging Login failed with exception:', { error });
          },
        );
      }
    },
    (error) => {
      console.log('Error while logging in messaging user', error);
    },
  );
};

export { loginCometChatUser };
