import MaintenanceActivityTypesEnum from '../constants/enums/MaintenanceActivityTypesEnum';

function wasLastStatusUpdateManual(currentRequest) {
  const lastStatusUpdate = currentRequest?.maintenance_activities
    ?.slice()
    .find(
      (activity) =>
        activity.type === MaintenanceActivityTypesEnum.STATUS_UPDATE,
    );

  return lastStatusUpdate?.additional_data?.source === 'manual';
}

export default wasLastStatusUpdateManual;
