import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Occupants = ({ color, ...props }) => (
  <svg viewBox="0 0 32 32" fill="none" {...props}>
    <g fillRule="evenodd" clipRule="evenodd" fill={color}>
      <path d="M14.597 15.392v-1.981h-1.6v2.544a.8.8 0 0 0 .53.754l4.34 1.553.54-1.507-3.81-1.363ZM8.591 15.392v-1.838h1.6v2.401a.8.8 0 0 1-.53.754L5.324 18.26c-.716.259-1.19.937-1.19 1.704v.841h10.764v1.6H3.333a.8.8 0 0 1-.8-.8v-1.641c0-1.436.892-2.719 2.246-3.209h.003l3.81-1.364Z" />
      <path d="M11.484 4.573c-1.83 0-3.55 1.835-3.55 4.421 0 2.586 1.72 4.421 3.55 4.421 1.83 0 3.55-1.835 3.55-4.42 0-2.587-1.72-4.422-3.55-4.422Zm-5.15 4.421c0-3.185 2.177-6.02 5.15-6.02 2.973 0 5.15 2.835 5.15 6.02 0 3.186-2.177 6.021-5.15 6.021-2.973 0-5.15-2.835-5.15-6.02Z" />
      <path d="M12.127 6.09a.8.8 0 0 1 .712.402c.366.64.714.988 1.055 1.168.324.17.734.237 1.337.121a1.769 1.769 0 0 1 .382-.037l.048-.002.019-.001.243 1.581c-.121.02-.237.02-.292.022a2.338 2.338 0 0 0-.063.002.75.75 0 0 1-.022.004c-.867.168-1.67.108-2.397-.274a3.629 3.629 0 0 1-1.071-.88c-.283.237-.6.437-.92.598-.694.348-1.513.57-2.285.57-.746 0-1.4-.163-2.048-.494l.727-1.426c.43.22.835.32 1.321.32.485 0 1.06-.146 1.566-.4.52-.261.86-.58.995-.841a.8.8 0 0 1 .694-.432ZM20.956 12.611a.8.8 0 0 1 .8.8v1.443a.8.8 0 0 1-1.6 0v-1.443a.8.8 0 0 1 .8-.8Z" />
      <path d="M17.844 3.784a.8.8 0 0 1 .8-.8c2.973 0 5.15 2.836 5.15 6.021s-2.177 6.021-5.15 6.021a.8.8 0 0 1 0-1.6c1.83 0 3.55-1.835 3.55-4.42 0-2.587-1.72-4.422-3.55-4.422a.8.8 0 0 1-.8-.8Z" />
      <path d="M18.906 6.197a.8.8 0 0 1 1.092.295c.367.64.715.988 1.056 1.168.323.17.734.237 1.337.121a1.77 1.77 0 0 1 .382-.037l.047-.002.02-.001a.8.8 0 0 1 .243 1.581c-.122.02-.238.02-.292.022a2.338 2.338 0 0 0-.064.002.825.825 0 0 1-.021.004c-.868.168-1.671.108-2.398-.274-.71-.374-1.25-1.006-1.698-1.787a.8.8 0 0 1 .296-1.092ZM23.16 18a4.707 4.707 0 1 0 0 9.415 4.707 4.707 0 0 0 0-9.415Zm-6.308 4.707a6.307 6.307 0 1 1 12.614 0 6.307 6.307 0 0 1-12.614 0Z" />
      <path d="M23.16 19.154a.8.8 0 0 1 .8.8v5.507a.8.8 0 0 1-1.6 0v-5.507a.8.8 0 0 1 .8-.8Z" />
      <path d="M19.606 22.707a.8.8 0 0 1 .8-.8h5.507a.8.8 0 0 1 0 1.6h-5.507a.8.8 0 0 1-.8-.8Z" />
    </g>
  </svg>
);

Occupants.defaultProps = {
  width: 32,
  height: 32,
  color: 'currentColor',
};

Occupants.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Occupants);
