import React from 'react';
import { Field } from 'react-final-form';

import Condition from '../../../../../../../components/Condition';
import RadioGroup from '../../../../../../../components/RadioGroup';
import RealEstateHouseDollarThick from '../../../../../../../icons/RealEstateHouseDollarThick';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './CommonInterestCommunityDisclosure.module.scss';

const CommonInterestCommunityDisclosure = () => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={styles.container}>
      <SectionTitle
        title="Common Interest Community Disclosure"
        icon={RealEstateHouseDollarThick}
      />
      <Field
        component={RadioGroup}
        radioRowClassName={styles.radioRow}
        id="custom_state_data.is_in_common_interest_community"
        name="custom_state_data.is_in_common_interest_community"
        className={styles.radioWrapper}
        label={
          <div className={styles.label}>
            <div>
              Is this Property located within a Common Interest Community?
            </div>
            <div className={styles.contextCopy}>
              Connecticut law requires that you disclose this.
            </div>
          </div>
        }
        options={[
          {
            'id': 'is_in_common_interest_community_choice_yes',
            'value': true,
            'label': 'Yes',
            'data-qa':
              'lease-agreements-lead-paint-disclosure-required-radio-yes',
          },
          {
            id: 'is_in_common_interest_community_choice_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition
        when="custom_state_data.is_in_common_interest_community"
        is={true}
      >
        <div className={styles.detail}>
          <p>
            This property is located in a common interest community, which means
            that the Tenant may be subjected to fees associated with the support
            of common interests such as amenities.{' '}
          </p>
        </div>
      </Condition>
    </div>
  );
};

export default CommonInterestCommunityDisclosure;
