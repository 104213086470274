import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import CashBag from '../../../../../../../icons/CashBag';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './RentalSurchargesDC.module.scss';

const RentalSurchargesDC = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle
        title="Rental Surcharge Disclosure"
        icon={CashBag}
        className={styles.sectionTitle}
      />
      <label className={styles.label}>
        Are there any rental surcharges charged to tenant? Disclosures if any
        surcharges on rent will be charged, including capital improvement
        surcharges and the expiration date of these charges as well as any
        planned rent increase must be made. Surcharges are any extra fees on top
        of rent such as elevator services, lawn or routine cleaning fees.
      </label>
      <p className={styles.description}>
        District of Columbia law requires that you disclose this along with the
        expiration date of any such charges.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.has_surcharge"
        name="custom_state_data.has_surcharge"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'has_surcharge_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'has_surcharge_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition when="custom_state_data.has_surcharge" is={true}>
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.surcharge_explanation"
          name="custom_state_data.surcharge_explanation"
          label="All rental surcharges charged to tenant, including capital
            improvement surcharges, and the expiration date of these charges as
            well as any planned rent increase are as follows:"
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
            className: styles.termsField,
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      </Condition>
    </div>
  );
};

RentalSurchargesDC.propTypes = {
  className: PropTypes.string,
};

export default RentalSurchargesDC;
