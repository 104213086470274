import React from 'react';

import CreditCardAmex from '../../../../icons/CreditCardAmex';
import CreditCardDiscover from '../../../../icons/CreditCardDiscover';
import CreditCardMasterCard from '../../../../icons/CreditCardMasterCard';
import CreditCardVisa from '../../../../icons/CreditCardVisa';

import styles from './PaymentCardsList.module.scss';

const PaymentCardsList = () => (
  <div className={styles.container}>
    <div className={styles.card}>
      <CreditCardVisa />
    </div>
    <div className={styles.card}>
      <CreditCardAmex />
    </div>
    <div className={styles.card}>
      <CreditCardDiscover />
    </div>
    <div className={styles.card}>
      <CreditCardMasterCard />
    </div>
  </div>
);

export default PaymentCardsList;
