import { EnumRentChargeType } from '../../../../../../pages/enums';

export const oneTimeChargesCategoriesOptions = [
  {
    label: '',
    value: null,
  },
  {
    label: 'Rent',
    value: EnumRentChargeType.RENT,
  },
  {
    label: 'Late Fee',
    value: EnumRentChargeType.LATE_FEE,
  },
  {
    label: 'Security Deposit',
    value: EnumRentChargeType.SECURITY_DEPOSIT,
  },
  {
    label: 'Utility Charge',
    value: EnumRentChargeType.UTILITY_CHARGE,
  },
  {
    label: 'NSF Fee',
    value: EnumRentChargeType.NSF_RETURNED_PAYMENT,
  },
  {
    label: 'Other',
    value: EnumRentChargeType.OTHER,
  },
];
