import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const CodeViolation = ({ color = colors.text, height = 24, width = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 26 26"
  >
    <path
      fill={color}
      fillRule="nonzero"
      d="M25.756 11.973c.45 0 .816.365.816.816v4.126c0 4.052-2.246 7.77-5.831 9.658a.816.816 0 0 1-.76 0 10.912 10.912 0 0 1-5.831-9.658v-4.126c0-.45.365-.816.816-.816h10.79Zm-.817 1.632h-9.157v3.31a9.28 9.28 0 0 0 4.4 7.894l.178.105.179-.105a9.283 9.283 0 0 0 4.395-7.572l.005-.322v-3.31ZM12.79 0c.072 0 .143.01.21.027l.01.003a.8.8 0 0 1 .182.076l.021.012a.778.778 0 0 1 .154.121l5.442 5.442a.814.814 0 0 1 .24.577v4.898h-1.633l-.001-4.082h-4.625a.816.816 0 0 1-.816-.816l-.001-4.626H1.632v21.225H15.51v1.633H.816A.816.816 0 0 1 0 23.673V.816C0 .366.365 0 .816 0H12.79Zm7.62 20.952a.817.817 0 1 1 0 0Zm0-5.813c.45 0 .815.366.815.817v3.56a.816.816 0 0 1-1.632 0v-3.56c0-.451.365-.817.816-.817ZM9.523 16.326a.816.816 0 1 1 0 1.633H4.082a.816.816 0 1 1 0-1.633h5.442Zm1.088-3.265a.816.816 0 1 1 0 1.633h-6.53a.816.816 0 1 1 0-1.633h6.53Zm2.177-3.265a.816.816 0 0 1 0 1.633H4.082a.816.816 0 1 1 0-1.633h8.707ZM8.98 6.53a.816.816 0 1 1 0 1.632H4.083a.816.816 0 0 1 0-1.632H8.98Zm7.28-1.089-2.654-2.655v2.655h2.654Z"
    />
  </svg>
);

CodeViolation.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(CodeViolation);
