import clearMask from '../../../../services/utilities/clearMask';

export const phoneInUseForNewTenantValidator = (allValues) => (value) => {
  if (!value) {
    return undefined;
  }

  let phonesToCheck = [
    allValues.landlord_phone || '',
    allValues.additional_landlord_phone || '',
  ];

  if (allValues.tenants) {
    const rentersPhonesToCheck = allValues.tenants.reduce((acc, tenant) => {
      const phoneToAdd = tenant.phone || tenant.telephone;

      if (phoneToAdd) {
        const phoneToAddCleared = clearMask(phoneToAdd);
        acc.push(phoneToAddCleared);
      }
      return acc;
    }, []);
    phonesToCheck = [...phonesToCheck, ...rentersPhonesToCheck];
  }

  const phoneIsUsed = phonesToCheck.some(
    (phone) => clearMask(phone) === clearMask(value),
  );

  if (phoneIsUsed) {
    return 'Phone is already in use by another party in this lease agreement.';
  }

  return undefined;
};
