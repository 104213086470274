import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import FlatButton from '../FlatButton';

import styles from './SectionTitleWithIconAndAction.module.scss';

const SectionTitleWithIconAndAction = ({
  title,
  icon: Icon,
  className,
  centered = false,
  iconProps,
  text,
  action,
}) => {
  return (
    <div
      className={cx(
        { [styles.container]: !centered, [styles.centeredContainer]: centered },
        className,
      )}
    >
      <span className={styles.iconHolder}>
        <Icon className={styles.icon} {...iconProps} />
      </span>
      <div className={styles.textWrap}>
        <div className={styles.titleWrap}>
          <h3 className={styles.title}>{title}</h3>
          {action && (
            <FlatButton
              className={styles.actionButton}
              icon={action.icon}
              onClick={action.onClick}
              iconProps={{ className: styles.actionButtonIcon }}
            >
              {action.text}
            </FlatButton>
          )}
        </div>
        <span className={styles.text}>{text}</span>
      </div>
    </div>
  );
};

SectionTitleWithIconAndAction.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
  className: PropTypes.string,
  iconProps: PropTypes.string,
  centered: PropTypes.bool,
  action: PropTypes.shape({
    icon: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.object,
      PropTypes.func,
    ]),
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
};

export default SectionTitleWithIconAndAction;
