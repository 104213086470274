import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const KeysIcon = (props) => (
  <svg {...props} viewBox="0 0 24 24" fill={props.color}>
    <path d="M22.988 0C23.547 0 24 .453 24 1.012v2.866c0 .269-.107.526-.296.716l-.66.657v1.016c0 .512-.38.935-.874 1.002l-.137.01h-.9v.899c0 .512-.38.935-.874 1.002l-.137.01-.9-.001v.9c0 .512-.38.935-.874 1.002l-.137.01h-1.015l-2.348 2.348.025.057c.296.757.472 1.558.516 2.38l.011.414a7.7 7.7 0 11-7.7-7.7 7.64 7.64 0 012.795.528l.055.023L19.406.296c.152-.151.347-.25.557-.283L20.122 0zm-1.013 2.023H20.54l-9.053 9.056a1.012 1.012 0 01-1.049.24l-.133-.058a5.633 5.633 0 00-2.605-.638 5.677 5.677 0 105.677 5.677c0-.916-.22-1.801-.637-2.604-.203-.392-.13-.87.182-1.182l3.14-3.14c.19-.19.447-.297.716-.297h.42l.001-.421c0-.215.068-.423.193-.594l.103-.122.478-.477c.19-.19.447-.297.716-.297h.42l.001-.421c0-.215.068-.423.193-.594l.103-.122.478-.477c.19-.19.447-.297.716-.297h.42l.001-.421c0-.215.068-.423.193-.594l.103-.122.658-.66V2.023zM5.79 15.766a2.445 2.445 0 110 4.89 2.445 2.445 0 010-4.89zm0 2.023a.422.422 0 10.001.844.422.422 0 000-.844z" />
  </svg>
);

KeysIcon.defaultProps = {
  color: colors.text,
  width: 24,
  height: 24,
};

KeysIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(KeysIcon);
