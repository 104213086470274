import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RadioGroup from '../../../../../../../components/RadioGroup';
import ScaleIcon from '../../../../../../../icons/Scale';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionInfoBlock from '../../../../../common/SectionInfoBlock';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './TruthInRenting.module.scss';

const TruthInRenting = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <>
      <div className={cx(styles.container, className)}>
        <SectionTitle
          title="Truth in Renting Guide"
          icon={ScaleIcon}
          iconProps={{ secondaryColor: 'none' }}
        />
        <SectionInfoBlock className={styles.infoBlock}>
          <b>New Jersey Law:</b> For premises with 2 or more units and
          owner-occupied premises of 3 or more units, New Jersey law requires
          that the{' '}
          <a
            href="https://www.nj.gov/dca/divisions/codes/publications/pdf_lti/t_i_r.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            New Jersey Truth in Renting Guide
          </a>{' '}
          must be given to the tenant(s) with the lease. If yes is checked, we
          will attach a copy for you.
        </SectionInfoBlock>
        <div className={styles.column}>
          <Field
            component={RadioGroup}
            id="custom_state_data.truth_in_renting"
            name="custom_state_data.truth_in_renting"
            className={styles.radioWrapper}
            radiosContainerClassName={cx(styles.radioButtonsContainer)}
            label="Would you like to attach a copy of the Truth In Renting Guide?"
            labelProps={{
              className: styles.headerLabel,
            }}
            options={[
              {
                'id': 'truth_in_renting_choice_yes',
                'value': true,
                'label': 'Yes',
                'data-qa': 'is_truth_in_renting_guide_attached_radio_yes',
              },
              {
                id: 'truth_in_renting_choice_no',
                value: false,
                label: 'No',
              },
            ]}
            validate={fieldRequired}
          />
        </div>
      </div>
    </>
  );
};

TruthInRenting.propTypes = {
  className: PropTypes.string,
};

export default TruthInRenting;
