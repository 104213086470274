import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Accounting = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 25" {...rest} fill={color}>
    <path d="M5.176 15.334a.6.6 0 0 1 .848 0L9.33 18.64a.6.6 0 1 1-.849.848l-3.304-3.304a.6.6 0 0 1 0-.849Z" />
    <path d="M9.329 15.334a.6.6 0 0 1 0 .849l-3.305 3.304a.6.6 0 0 1-.848-.848l3.304-3.305a.6.6 0 0 1 .849 0ZM7.24 5.649a.6.6 0 0 1 .6.6v3.31a.6.6 0 1 1-1.2 0v-3.31a.6.6 0 0 1 .6-.6Z" />
    <path d="M4.99 7.897a.6.6 0 0 1 .6-.6H8.9a.6.6 0 1 1 0 1.2H5.59a.6.6 0 0 1-.6-.6ZM14.08 7.909a.6.6 0 0 1 .6-.6h4.13a.6.6 0 1 1 0 1.2h-4.13a.6.6 0 0 1-.6-.6ZM14.08 18.491a.6.6 0 0 1 .6-.6h4.13a.6.6 0 0 1 0 1.2h-4.13a.6.6 0 0 1-.6-.6ZM14.08 16.339a.6.6 0 0 1 .6-.6h4.13a.6.6 0 0 1 0 1.2h-4.13a.6.6 0 0 1-.6-.6ZM12 2.559a.6.6 0 0 1 .6.6v19a.6.6 0 1 1-1.2 0v-19a.6.6 0 0 1 .6-.6Z" />
    <path d="M1.9 12.659a.6.6 0 0 1 .6-.6h19a.6.6 0 1 1 0 1.2h-19a.6.6 0 0 1-.6-.6Z" />
    <path d="M4.978 3.759c-1.037 0-1.878.84-1.878 1.878V19.68c0 1.038.84 1.879 1.878 1.879h14.044c1.037 0 1.878-.841 1.878-1.879V5.637c0-1.037-.841-1.878-1.878-1.878H4.978ZM1.9 5.637c0-1.7 1.378-3.078 3.078-3.078h14.044c1.7 0 3.078 1.378 3.078 3.078V19.68c0 1.7-1.378 3.079-3.078 3.079H4.978A3.078 3.078 0 0 1 1.9 19.68V5.637Z" />
  </svg>
);

Accounting.defaultProps = {
  width: 24,
  height: 25,
  color: colors.primary,
};

Accounting.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Accounting);
