import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import { SUB_DIVISION_NAMES_KEYS } from '../../../../../../../constants/lease_agreement/sub-division-names';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from './PetDepositWA.module.scss';

const petDivisionTexts = {
  [SUB_DIVISION_NAMES_KEYS.tacoma]: `The pet deposit cannot exceed 25% of the first month's rent and must be refundable.`,
  [SUB_DIVISION_NAMES_KEYS.seattle]: `The pet deposit cannot exceed 25% of the first month's rent and can be made in 3 equal installments.`,
};

const PetDepositWA = ({ className, leaseAgreementSelection }) => {
  const petText = petDivisionTexts[leaseAgreementSelection];

  return (
    <div className={cx(styles.container, className)}>
      <HelpfulInfoCard
        Icon={ResourcesIcon}
        className={styles.helpfulInfoCard}
        title="Pet Deposits"
      >
        <p className={styles.desc}>{petText}</p>
      </HelpfulInfoCard>
    </div>
  );
};

PetDepositWA.propTypes = {
  className: PropTypes.string,
  leaseAgreementSelection: PropTypes.string,
};

export default PetDepositWA;
