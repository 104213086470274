const getSmokingTitle = (id) => {
  const titles = {
    0: 'Yes',
    1: 'Only outside',
    2: 'Occasionally',
    3: 'No',
  };
  return titles[id] || '';
};

export default getSmokingTitle;
