import { useMutation } from '@apollo/client';

import createWizardDocument from '../../graphql/mutations/wizard_documents/createWizardDocument.graphql';
import createWizardLeaseDocument from '../../graphql/mutations/wizard_documents/createWizardLeaseDocument.graphql';
import generateWizardDocumentESign from '../../graphql/mutations/wizard_documents/generateWizardDocumentESign.graphql';
import payWizardDocument from '../../graphql/mutations/wizard_documents/payWizardDocument.graphql';
import updateWizardDocument from '../../graphql/mutations/wizard_documents/updateWizardDocument.graphql';
import updateWizardLeaseDocument from '../../graphql/mutations/wizard_documents/updateWizardLeaseDocument.graphql';

export const useCreateWizardDocument = (options) =>
  useMutation(createWizardDocument, {
    refetchQueries: [],
    awaitRefetchQueries: true,
    ...options,
  });

export const useUpdateWizardDocument = (options) =>
  useMutation(updateWizardDocument, {
    refetchQueries: [],
    awaitRefetchQueries: true,
    ...options,
  });

export const usePayWizardDocument = (options) =>
  useMutation(payWizardDocument, {
    refetchQueries: [],
    awaitRefetchQueries: true,
    ...options,
  });

export const useCreateWizardLeaseDocument = (options) =>
  useMutation(createWizardLeaseDocument, {
    refetchQueries: [],
    awaitRefetchQueries: true,
    ...options,
  });

export const useUpdateWizardLeaseDocument = (options) =>
  useMutation(updateWizardLeaseDocument, {
    refetchQueries: [],
    awaitRefetchQueries: true,
    ...options,
  });

export const useGenerateWizardDocumentESign = (options) =>
  useMutation(generateWizardDocumentESign, {
    refetchQueries: [],
    awaitRefetchQueries: true,
    ...options,
  });
