import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../RadioGroup.module.scss';

const RadioGroupItemDescription = ({ description }) => {
  if (!description) return null;

  if (typeof description === 'string') {
    return <span className={styles.labelDescription}>{description}</span>;
  }

  return description;
};

export default RadioGroupItemDescription;

RadioGroupItemDescription.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
RadioGroupItemDescription.displayName = 'RadioGroupItemDescription';
