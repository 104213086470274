import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useModalContext } from '../../context';

import styles from './Content.module.scss';

const Content = ({ noHeader, noFooter, className, ...rest }) => {
  const { hasBanner } = useModalContext();
  return (
    <div
      className={cx(styles.content, className, {
        [styles.borderTop]: noHeader,
        [styles.borderBottom]: noFooter,
        [styles.hasBanner]: hasBanner,
      })}
      {...rest}
    />
  );
};

Content.propTypes = {
  noHeader: PropTypes.bool,
  noFooter: PropTypes.bool,
  className: PropTypes.string,
};

export default Content;
