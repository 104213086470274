import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Tag.module.scss';

const Tag = ({
  className,
  small,
  uppercase,
  as: As,
  accent,
  children,
  outlined,
  ...otherProps
}) => {
  return (
    <As
      className={cx(styles.container, className, {
        [styles.small]: !!small,
        [styles.uppercase]: uppercase,
        [styles.accent]: accent,
        [styles.outlined]: outlined,
      })}
      {...otherProps}
    >
      {children}
    </As>
  );
};

Tag.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  small: PropTypes.bool,
  uppercase: PropTypes.bool,
  accent: PropTypes.bool,
  outlined: PropTypes.bool,
  as: PropTypes.oneOf(['div', 'span']),
};

Tag.defaultProps = {
  uppercase: true,
  as: 'div',
};

export default Tag;
