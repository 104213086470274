import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const MultiFamily = ({ color, ...props }) => (
  <svg {...props} viewBox="0 0 22 20">
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons/Property/Type/Co-op/Dark"
        transform="translate(-1.000000, -2.000000)"
        fillRule="nonzero"
        fill={color}
      >
        <g id="Co-op" transform="translate(2.000000, 3.000000)">
          <path
            d="M18.5446809,3.13953488 C18.5446809,3.07454856 18.5600617,3.01045472 18.5896055,2.95232919 L19.3302318,1.49518995 L18.742475,0.627906977 L1.32561011,0.627906977 L0.737853327,1.49518995 L1.47847966,2.95232919 C1.50802337,3.01045472 1.52340426,3.07454856 1.52340426,3.13953488 L1.52340426,17.372093 L18.5446809,17.372093 L18.5446809,3.13953488 Z M19.3957447,3.23835404 L19.3957447,17.7906977 C19.3957447,18.0218866 19.2052276,18.2093023 18.9702128,18.2093023 L1.09787234,18.2093023 C0.862857553,18.2093023 0.672340426,18.0218866 0.672340426,17.7906977 L0.672340426,3.23835404 L-0.133798805,1.65232197 C-0.202090575,1.51796188 -0.191960961,1.35790608 -0.107255444,1.2329162 L0.743808385,-0.0228977566 C0.822729993,-0.139353013 0.955594388,-0.209302326 1.09787234,-0.209302326 L18.9702128,-0.209302326 C19.1124907,-0.209302326 19.2453551,-0.139353013 19.3242767,-0.0228977566 L20.1753406,1.2329162 C20.2600461,1.35790608 20.2701757,1.51796188 20.2018839,1.65232197 L19.3957447,3.23835404 Z"
            id="Shape"
          ></path>
          <path
            d="M8.75744681,10.6744186 L8.75744681,17.372093 L11.7361702,17.372093 L11.7361702,10.6744186 L8.75744681,10.6744186 Z M8.33191489,9.8372093 L12.1617021,9.8372093 C12.3967169,9.8372093 12.587234,10.024625 12.587234,10.255814 L12.587234,17.7906977 C12.587234,18.0218866 12.3967169,18.2093023 12.1617021,18.2093023 L8.33191489,18.2093023 C8.09690011,18.2093023 7.90638298,18.0218866 7.90638298,17.7906977 L7.90638298,10.255814 C7.90638298,10.024625 8.09690011,9.8372093 8.33191489,9.8372093 Z"
            id="Rectangle-path"
          ></path>
          <path
            d="M3.61702128,7.3255814 L5.74468085,7.3255814 L5.74468085,5.23255814 L3.61702128,5.23255814 L3.61702128,7.3255814 Z M3.19148936,4.39534884 L6.17021277,4.39534884 C6.40522755,4.39534884 6.59574468,4.58276452 6.59574468,4.81395349 L6.59574468,7.74418605 C6.59574468,7.97537501 6.40522755,8.1627907 6.17021277,8.1627907 L3.19148936,8.1627907 C2.95647457,8.1627907 2.76595745,7.97537501 2.76595745,7.74418605 L2.76595745,4.81395349 C2.76595745,4.58276452 2.95647457,4.39534884 3.19148936,4.39534884 Z"
            id="Rectangle-path"
          ></path>
          <path
            d="M14.7234458,7.3255814 L16.8511054,7.3255814 L16.8511054,5.23255814 L14.7234458,5.23255814 L14.7234458,7.3255814 Z M14.2979139,4.39534884 L17.2766373,4.39534884 C17.5116521,4.39534884 17.7021692,4.58276452 17.7021692,4.81395349 L17.7021692,7.74418605 C17.7021692,7.97537501 17.5116521,8.1627907 17.2766373,8.1627907 L14.2979139,8.1627907 C14.0628991,8.1627907 13.872382,7.97537501 13.872382,7.74418605 L13.872382,4.81395349 C13.872382,4.58276452 14.0628991,4.39534884 14.2979139,4.39534884 Z"
            id="Rectangle-path"
          ></path>
          <path
            d="M14.7234458,12.7674419 L16.8511054,12.7674419 L16.8511054,10.6744186 L14.7234458,10.6744186 L14.7234458,12.7674419 Z M14.2979139,9.8372093 L17.2766373,9.8372093 C17.5116521,9.8372093 17.7021692,10.024625 17.7021692,10.255814 L17.7021692,13.1860465 C17.7021692,13.4172355 17.5116521,13.6046512 17.2766373,13.6046512 L14.2979139,13.6046512 C14.0628991,13.6046512 13.872382,13.4172355 13.872382,13.1860465 L13.872382,10.255814 C13.872382,10.024625 14.0628991,9.8372093 14.2979139,9.8372093 Z"
            id="Rectangle-path"
          ></path>
          <path
            d="M9.18297872,7.3255814 L11.3106383,7.3255814 L11.3106383,5.23255814 L9.18297872,5.23255814 L9.18297872,7.3255814 Z M8.75744681,4.39534884 L11.7361702,4.39534884 C11.971185,4.39534884 12.1617021,4.58276452 12.1617021,4.81395349 L12.1617021,7.74418605 C12.1617021,7.97537501 11.971185,8.1627907 11.7361702,8.1627907 L8.75744681,8.1627907 C8.52243202,8.1627907 8.33191489,7.97537501 8.33191489,7.74418605 L8.33191489,4.81395349 C8.33191489,4.58276452 8.52243202,4.39534884 8.75744681,4.39534884 Z"
            id="Rectangle-path"
          ></path>
          <path
            d="M3.61702128,12.7674419 L5.74468085,12.7674419 L5.74468085,10.6744186 L3.61702128,10.6744186 L3.61702128,12.7674419 Z M3.19148936,9.8372093 L6.17021277,9.8372093 C6.40522755,9.8372093 6.59574468,10.024625 6.59574468,10.255814 L6.59574468,13.1860465 C6.59574468,13.4172355 6.40522755,13.6046512 6.17021277,13.6046512 L3.19148936,13.6046512 C2.95647457,13.6046512 2.76595745,13.4172355 2.76595745,13.1860465 L2.76595745,10.255814 C2.76595745,10.024625 2.95647457,9.8372093 3.19148936,9.8372093 Z"
            id="Rectangle-path"
          ></path>
          <polygon
            id="Shape"
            points="0.246808511 1.88372093 19.8212766 1.88372093 19.8212766 1.04651163 0.246808511 1.04651163"
          ></polygon>
          <polygon
            id="Shape"
            points="1.09787234 3.55813953 18.9702128 3.55813953 18.9702128 2.72093023 1.09787234 2.72093023"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);

MultiFamily.defaultProps = {
  width: 34,
  height: 44,
  color: colors.text,
};

MultiFamily.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(MultiFamily);
