import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Column, Row } from '../../../../components/Grid';
import ApplicationSummary from '../../../owners/renter-profile/sections/summary/ApplicationSummary';

import styles from './Info.module.scss';

const ApplicantInfo = ({ application, partnersApplication } = {}) => {
  return (
    <Row>
      <Column>
        <span className={styles.bornOn}>{`Born on ${moment(
          application.birthdate,
        ).format('MMMM DD, YYYY')}`}</span>
        <ApplicationSummary
          application={application}
          noBorder
          partnersApplication={partnersApplication}
        />
      </Column>
    </Row>
  );
};

ApplicantInfo.propTypes = {
  application: PropTypes.object,
  partnersApplication: PropTypes.bool,
};

export default ApplicantInfo;
