import React, { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import cx from 'classnames';
import arrayMutators from 'final-form-arrays';
import { func, object, string } from 'prop-types';
import qs from 'query-string';

import AddBankManuallyFooter from '../../../../components/AddBankManuallyForm/AddBankManuallyFooter';
import BackButton from '../../../../components/BackButton';
import Button from '../../../../components/Button';
import SelectionRadioBoxGroup from '../../../../components/SelectionRadioBoxGroup';
import { useErrorToast } from '../../../../components/Toast';
import { UnsavedChangesModalV2 } from '../../../../components/UnsavedChangesModal';
import useSubmitError from '../../../../helpers/use-submit-error';
import AccountNavThin from '../../../../icons/AccountNavThin';
import CompanyThin from '../../../../icons/CompanyThin';
import { segmentTracking } from '../../../../services/utilities';
import clearMask from '../../../../services/utilities/clearMask';
import StripeElementsWrapper from '../../../common/stripe/StripeElementsWrapper';
import { useSetupPayments } from '../../usePayments';

import StripeCompanyForm from './forms/StripeCompanyForm';
import StripeIndividualForm from './forms/StripeIndividualForm';

import styles from './StripeAccountSetupFormsWrapper.module.scss';

const StripeAccountSetupFormsWrapper = ({
  title,
  onSuccess,
  user,
  entity,
  customSaveOperation,
  className,
  submitLabel = 'NEXT',
  hideBackButton = false,
  withTitle = true,
  withOutCard = false,
  withUnsavedChangesPrompt = false,
  withSubtitle = false,
  inProduct = false,
}) => {
  const stripe = useStripe();
  const showErrorMessage = useErrorToast();
  const [setupPayments] = useSetupPayments();
  const history = useHistory();
  const { offlineConversion } = qs.parse(history.location.search, {
    parseBooleans: true,
  });

  const defaultSaveOperation = useCallback(
    async (data) => {
      const result = await setupPayments({
        variables: { data },
      });

      return result?.data?.setupStripeAccount;
    },
    [setupPayments],
  );

  const onSubmit = useCallback(
    async ({ full_ssn, ...values }) => {
      try {
        let token;

        // if is not editting...
        if (entity == null) {
          const response = await stripe.createToken('pii', {
            personal_id_number: full_ssn,
          });

          if (response.error) {
            return showErrorMessage('Something went wrong');
          }

          token = response.token;
        }

        const {
          business_type,
          company_name = '',
          company_doing_business_as = '',
          company_phone = '',
          company_tax_id = '',
          first_name = '',
          middle_name = '',
          last_name = '',
          dob = '',
          phone = '',

          address = '',
          unit = '',
          city = '',
          state = '',
          zip = '',

          company_address = '',
          company_unit = '',
          company_city = '',
          company_state = '',
          company_zip = '',
          created_or_modified_90_days_ago,
          documents = [],
        } = values;

        segmentTracking(
          offlineConversion ? 'next clicked' : 'stripe_submit clicked',
          {
            location: offlineConversion
              ? 'Offline to Online Connect Account Form'
              : 'rent payments',
          },
        );

        const data = {
          business_type: entity == null ? business_type : undefined,
          company_name,
          company_doing_business_as,
          company_phone,
          company_tax_id: clearMask(company_tax_id),
          first_name: `${first_name} ${middle_name || ''}`.trim(),
          last_name,
          ssn_last_4: full_ssn?.substring(
            full_ssn?.length - 4,
            full_ssn?.length,
          ),
          id_number: token?.id,
          dob,
          phone,
          company_created_less_than_90_days: created_or_modified_90_days_ago,
          documents: documents.map(({ id }) => id),
        };

        if (address) {
          data.individual_address = {
            address,
            unit,
            city,
            state,
            zip,
          };
        }

        if (company_address) {
          data.company_address = {
            address: company_address,
            unit: company_unit,
            city: company_city,
            state: company_state,
            zip: company_zip,
          };
        }

        const { ok, entity: savedEntity } = await (customSaveOperation != null
          ? customSaveOperation(data)
          : defaultSaveOperation(data));

        if (!ok) {
          return showErrorMessage('Something went wrong');
        }
        return onSuccess(savedEntity);
      } catch (error) {
        const code = error?.graphQLErrors[0]?.message;
        showErrorMessage(code);
      }
    },
    [stripe, entity, customSaveOperation, defaultSaveOperation],
  );

  const initialValues = {
    business_type: entity?.business_type?.toUpperCase(),
    first_name: entity?.person_first_name,
    middle_name: entity?.person_middle_name,
    last_name: entity?.person_last_name,
    phone: user?.telephone,
    address: entity?.person_address?.address,
    city: entity?.person_address?.city,
    unit: entity?.person_address?.unit,
    zip: entity?.person_address?.zip,
    state: entity?.person_address?.state,
    dob: entity?.person_dob,

    company_name: entity?.entity_name,
    company_doing_business_as: entity?.company_doing_business_as,
    company_phone: entity?.company_phone,
    company_address: entity?.entity_address?.address,
    company_city: entity?.entity_address?.city,
    company_unit: entity?.entity_address?.unit,
    company_zip: entity?.entity_address?.zip,
    company_state: entity?.entity_address?.state,

    created_or_modified_90_days_ago:
      entity?.company_created_less_than_90_days ||
      entity?.company_documents?.length > 0,
    documents: entity?.company_documents,
    ...(entity?.person_address == null ? user?.mailing_address || {} : {}),
  };

  return (
    <div className={className}>
      {!hideBackButton && <BackButton className={styles.backButton} />}
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        decorators={[
          useSubmitError(
            'Some fields are invalid. Please check the fields marked in red.',
          ),
        ]}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({ handleSubmit, values, valid, submitting, dirty }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={styles.mainContainer}>
                <div
                  className={cx(styles.container, {
                    [styles.noCard]: withOutCard,
                  })}
                >
                  {withSubtitle && (
                    <h3 className={styles.subTitle}>PAYMENT SETUP</h3>
                  )}
                  {withTitle && (
                    <div
                      id="payment_account_setup_title"
                      className={styles.title}
                    >
                      {title}
                    </div>
                  )}

                  {entity == null && (
                    <>
                      <h3 className={styles.businessTypeLabel}>
                        What type of bank account will you receive payments
                        with?
                      </h3>
                      <Field
                        component={SelectionRadioBoxGroup}
                        className={styles.businessTypeContainer}
                        containerClassName={styles.businessType}
                        name="business_type"
                        radioProps={{
                          className: styles.businessTypeRadio,
                        }}
                        options={[
                          {
                            icon: AccountNavThin,
                            iconProps: {
                              color: '#042238',
                            },
                            value: 'INDIVIDUAL',
                            label: 'Personal',
                            id: 'business_type_individual',
                          },
                          {
                            icon: CompanyThin,
                            iconProps: {
                              color: '#042238',
                            },
                            value: 'COMPANY',
                            label: 'Business',
                            id: 'business_type_company',
                          },
                        ]}
                      />
                    </>
                  )}

                  {values.business_type != null && (
                    <>
                      {values.business_type === 'INDIVIDUAL' ? (
                        <StripeIndividualForm
                          isEditting={entity != null}
                          inProduct={inProduct}
                        />
                      ) : (
                        <StripeCompanyForm
                          isEditting={entity != null}
                          inProduct={inProduct}
                        />
                      )}
                      <div className={styles.submitContainer}>
                        <Button
                          type="submit"
                          disabled={!valid}
                          loading={submitting}
                          id="submit-setup-payments"
                          className={styles.submitButton}
                          data-qa="stripe-account-setup-submit-button"
                        >
                          {submitLabel}
                        </Button>
                        <AddBankManuallyFooter />
                      </div>
                    </>
                  )}
                </div>
              </div>
              {withUnsavedChangesPrompt && (
                <UnsavedChangesModalV2 when={dirty && !submitting} />
              )}
            </form>
          );
        }}
      </Form>
    </div>
  );
};

StripeAccountSetupFormsWrapper.propTypes = {
  className: string,
  user: object,
  onSuccess: func,
};

const StripeAccountSetupFormsWrapperComponent = (props) => (
  <StripeElementsWrapper>
    <StripeAccountSetupFormsWrapper {...props} />
  </StripeElementsWrapper>
);

export default StripeAccountSetupFormsWrapperComponent;
