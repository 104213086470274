const validateSSN = (value) => {
  if (!value) {
    return 'This value is required';
  } else if (value.length !== 9) {
    return 'SSN should have 9 digits';
  }
  return undefined;
};

export default validateSSN;
