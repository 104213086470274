import React, { memo } from 'react';
import PropTypes from 'prop-types';

const BusinessBank = (props) => (
  <svg viewBox={props.viewBox || '0 0 26 28'} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.color}
        d="M2.666 14H6v8H2.666zM11.333 14h3.333v8h-3.333zM20 14h3.333v8H20z"
      />
      <path
        fill="#042238"
        d="M7.195 12.889c.291 0 .528.249.528.555a.542.542 0 0 1-.528.556h-.529v7.778h.529c.262 0 .48.201.52.465l.008.09a.542.542 0 0 1-.528.556H1.918a.542.542 0 0 1-.528-.556c0-.306.236-.555.528-.555h.526V14h-.526a.537.537 0 0 1-.521-.465l-.007-.09c0-.307.236-.556.528-.556h5.277ZM5.61 21.778V14h-2.11v7.778h2.11ZM.862 27.333h24.276a.542.542 0 0 0 .528-.555.542.542 0 0 0-.528-.556H.862a.542.542 0 0 0-.528.556c0 .307.237.555.528.555Zm0-2.222h24.276a.542.542 0 0 0 .528-.555.542.542 0 0 0-.528-.556H.862a.542.542 0 0 0-.528.556c0 .306.237.555.528.555ZM15.64 12.89c.291 0 .527.249.527.555a.542.542 0 0 1-.527.556h-.529v7.778h.529c.262 0 .48.201.52.465l.007.09a.542.542 0 0 1-.527.556H10.36a.542.542 0 0 1-.527-.556c0-.306.236-.555.527-.555h.527V14h-.527a.537.537 0 0 1-.52-.465l-.007-.09c0-.307.236-.556.527-.556Zm-1.584 1.11h-2.11v7.779h2.11V14Zm10.027-1.11c.292 0 .528.249.528.555a.542.542 0 0 1-.528.556h-.528v7.778h.528c.263 0 .48.201.521.465l.007.09a.542.542 0 0 1-.528.556h-5.277a.542.542 0 0 1-.528-.556c0-.306.237-.555.528-.555h.527V14h-.527a.537.537 0 0 1-.52-.465l-.008-.09c0-.307.237-.556.528-.556Zm-1.584 1.11h-2.11v7.779h2.11V14ZM.862 11.779c-.502 0-.72-.669-.325-.994l12.171-10a.507.507 0 0 1 .65.001l12.106 10c.394.326.175.993-.326.993H.862Zm1.534-1.111h21.215l-10.58-8.74-10.635 8.74Z"
      />
    </g>
  </svg>
);

BusinessBank.defaultProps = {
  width: 26,
  height: 28,
  color: '#7FE3FF',
};

BusinessBank.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(BusinessBank);
