const CreditCheckStatusEnum = {
  NOT_REQUESTED: 'NOT_REQUESTED',
  REQUESTED: 'REQUESTED',
  DECLINED: 'DECLINED',
  PRE_ACCEPTED: 'PRE_ACCEPTED',
  NOT_RECEIVED: 'NOT_RECEIVED',
  IDV_EXPIRED: 'IDV_EXPIRED',
  RECEIVED: 'RECEIVED',
  READY_FOR_PULLING: 'READY_FOR_PULLING',
};

export default CreditCheckStatusEnum;
