import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const QuestionMarkCircle = ({
  thick,
  blueCircle,
  width,
  height,
  color,
  ...props
}) => {
  if (blueCircle) {
    return (
      <svg width={width} height={height} viewBox={props.viewBox || '0 0 28 28'}>
        <g fill="none" fillRule="evenodd">
          <path
            fill="#7FE3FF"
            d="M18.333 24c4.97 0 9-5.363 9-10.333s-4.03-10.334-9-10.334-9 5.363-9 10.334c0 4.97 4.03 10.333 9 10.333Z"
          />
          <path
            fill="#042238"
            d="M14 .667c7.364 0 13.333 5.97 13.333 13.333 0 7.364-5.97 13.333-13.333 13.333C6.636 27.333.667 21.363.667 14 .667 6.636 6.637.667 14 .667Zm0 1.16C7.277 1.826 1.826 7.276 1.826 14S7.276 26.174 14 26.174c6.723 0 12.174-5.45 12.174-12.174 0-6.723-5.45-12.174-12.174-12.174Zm0 17.97a1.16 1.16 0 1 1 0 2.32 1.16 1.16 0 0 1 0-2.32Zm0-12.753a4.06 4.06 0 0 1 .658 8.062l-.078.012v2.94a.58.58 0 0 1-.476.57l-.104.01a.58.58 0 0 1-.57-.476l-.01-.104V14.58c0-.32.26-.58.58-.58a2.898 2.898 0 1 0-2.899-2.899.58.58 0 1 1-1.159 0A4.058 4.058 0 0 1 14 7.044Z"
          />
        </g>
      </svg>
    );
  }

  const thickIcon = (
    <svg
      {...props}
      viewBox={props.viewBox || '0 0 20 20'}
      fill={color}
      width={20}
      height={20}
    >
      <path d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 1.356a8.644 8.644 0 100 17.288 8.644 8.644 0 000-17.288zm-.127 12.881a.72.72 0 110 1.441.72.72 0 010-1.44zM10 4.661a3.221 3.221 0 01.805 6.34l-.127.028v1.937c0 .346-.259.631-.593.673l-.085.005a.678.678 0 01-.673-.593l-.005-.085v-2.542c0-.346.259-.631.593-.673L10 9.746A1.864 1.864 0 108.136 7.88a.678.678 0 11-1.356 0A3.22 3.22 0 0110 4.661z" />
    </svg>
  );

  const thinIcon = (
    <svg
      {...props}
      width={width}
      height={height}
      fill={color}
      viewBox={props.viewBox || '0 0 48 48'}
    >
      <path
        d="M24 .667C36.886.667 47.333 11.114 47.333 24S36.886 47.333 24 47.333.667 36.886.667 24 11.114.667 24 .667Zm0 2.029C12.234 2.696 2.696 12.234 2.696 24c0 11.766 9.538 21.304 21.304 21.304 11.766 0 21.304-9.538 21.304-21.304 0-11.766-9.538-21.304-21.304-21.304Zm0 31.449a2.03 2.03 0 1 1 0 4.059 2.03 2.03 0 0 1 0-4.06Zm0-22.319a7.103 7.103 0 0 1 1.152 14.11l-.138.02V31.1c0 .498-.358.913-.832.999l-.182.016c-.498 0-.912-.359-.998-.832l-.016-.183v-6.087c0-.56.454-1.014 1.014-1.014a5.072 5.072 0 1 0-5.072-5.072 1.014 1.014 0 0 1-2.03 0c0-3.921 3.18-7.102 7.102-7.102Z"
        fill={props.color}
        fillRule="evenodd"
      />
    </svg>
  );

  return thick ? thickIcon : thinIcon;
};

QuestionMarkCircle.defaultProps = {
  thick: true,
  width: 20,
  height: 20,
  color: colors.primary,
};

QuestionMarkCircle.propTypes = {
  blueCircle: PropTypes.bool,
  thick: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(QuestionMarkCircle);
