import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Radiation = ({ color, ...props }) => (
  <svg viewBox="0 0 28 28" {...props} fill={color}>
    <path
      d="M14 .667C21.364.667 27.333 6.636 27.333 14S21.363 27.333 14 27.333C6.635 27.333.667 21.365.667 14S6.635.667 14 .667Zm0 1.632C7.537 2.3 2.3 7.537 2.3 14S7.536 25.7 14 25.7c6.462 0 11.7-5.237 11.7-11.7 0-6.463-5.238-11.7-11.7-11.7Zm.945 13.341a.816.816 0 0 1 1.117.298l3.267 5.66a.816.816 0 0 1-.298 1.114 10.009 10.009 0 0 1-10.063 0 .816.816 0 0 1-.298-1.114l3.268-5.66a.816.816 0 0 1 1.117-.298l.182.095c.245.112.501.17.763.17.327 0 .646-.091.945-.265Zm.065 1.742-.096.03a3.406 3.406 0 0 1-1.828 0l-.097-.03-2.477 4.29.262.117a8.338 8.338 0 0 0 2.853.638l.373.009c1.121 0 2.21-.223 3.225-.647l.262-.117-2.477-4.29Zm2.904-11.797a.816.816 0 0 1 1.116-.298c2.962 1.714 5.038 5.625 5.038 9.257 0 .451-.365.817-.816.817h-6.53a.816.816 0 0 1-.817-.817c0-.878-.464-1.898-.959-2.184l-.092-.062a.816.816 0 0 1-.206-1.053Zm-8.946-.298a.816.816 0 0 1 1.116.298l3.268 5.66a.816.816 0 0 1-.298 1.115l-.098.067c-.457.355-.86 1.298-.86 2.117 0 .451-.366.817-.817.817h-6.53a.816.816 0 0 1-.817-.817c0-3.632 2.076-7.543 5.036-9.257ZM14 12.639a1.36 1.36 0 1 1 0 2.722 1.36 1.36 0 0 1 0-2.722Zm0 1.089a.272.272 0 1 0 0 .544.272.272 0 0 0 0-.544Zm4.893-6.572L16.4 11.472l.173.203c.412.528.714 1.21.862 1.916l.024.137h4.933l-.007-.081c-.27-2.505-1.63-5.06-3.493-6.491Zm-9.788 0c-1.861 1.432-3.221 3.986-3.491 6.49l-.008.082h4.933l.025-.137c.167-.793.528-1.559 1.021-2.106l.013-.013Z"
      fillRule="evenodd"
    />
  </svg>
);

Radiation.defaultProps = {
  width: 28,
  height: 28,
  color: 'currentColor',
};

Radiation.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Radiation);
