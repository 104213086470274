import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Referral = ({ color, secondaryColor, strokeWidth, ...rest }) => (
  <svg
    {...rest}
    viewBox="0 0 96 96"
    fill="none"
    stroke={color}
    strokeWidth={strokeWidth}
  >
    <path
      d="M58 33H38v50h20V33Z"
      {...(secondaryColor && { fill: secondaryColor })}
    />
    <path
      d="M42.108 10.929c.536.536 1.047 1.27 1.564 2.2 1.005 1.81 2.042 4.398 3.102 7.585.42 1.265.83 2.581 1.225 3.918.394-1.336.804-2.652 1.224-3.918 1.07-3.216 2.116-5.82 3.13-7.635.508-.907 1.012-1.625 1.54-2.15 3.903-3.905 10.239-3.905 14.143 0 3.904 3.903 3.904 10.239 0 14.143-.526.527-1.244 1.03-2.151 1.538-1.773.992-4.3 2.013-7.415 3.057h27.863c.92 0 1.667.745 1.667 1.666v13.333c0 .92-.746 1.667-1.667 1.667l-1.667-.001v38.334c0 .92-.745 1.667-1.666 1.667H13c-.92 0-1.667-.746-1.667-1.667l-.001-38.334-1.665.001c-.92 0-1.667-.746-1.667-1.667V31.333c0-.92.746-1.667 1.667-1.667h27.865l-.573-.192c-2.854-.977-5.18-1.933-6.844-2.864-.907-.508-1.625-1.011-2.151-1.539-3.906-3.902-3.905-10.238 0-14.143 3.904-3.904 10.236-3.904 14.144 0Zm-7.442 35.403-20 .001V83l20-.002V46.332Zm23.334 0H37.998v36.666H58V46.332Zm23.333.001-20.001-.001v36.666L81.333 83V46.333ZM34.666 32.998 11.333 33v10l23.333-.002v-10Zm23.334 0H37.998v10H58v-10ZM84.667 33l-23.335-.002v10L84.667 43V33ZM30.32 13.285a6.667 6.667 0 0 0 0 9.43c.276.276.76.616 1.422.986 1.589.89 4.027 1.868 7.059 2.876 2.095.697 4.347 1.366 6.599 1.983l.264.07-.07-.265c-.463-1.689-.956-3.378-1.467-5l-.517-1.599c-1-3.007-1.971-5.43-2.854-7.02-.379-.683-.725-1.18-1.006-1.46a6.67 6.67 0 0 0-9.43 0Zm25.928 0c-.276.277-.616.76-.986 1.422-.89 1.589-1.868 4.026-2.876 7.059a129.933 129.933 0 0 0-1.983 6.599l-.073.265.268-.07c1.69-.463 3.378-.955 5-1.466l1.6-.517c3.032-1.008 5.47-1.987 7.058-2.876.662-.37 1.145-.71 1.421-.986a6.67 6.67 0 0 0 0-9.43 6.67 6.67 0 0 0-9.43 0Z"
      fill={color}
    />
  </svg>
);

Referral.defaultProps = {
  width: 96,
  height: 96,
  color: colors.text,
  secondaryColor: '',
  strokeWidth: 0,
};

Referral.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
  strokeWidth: PropTypes.number,
};

export default memo(Referral);
