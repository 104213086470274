import React from 'react';
import { Field, Form } from 'react-final-form';
import { useMutation } from '@apollo/client';
import get from 'lodash.get';
import { bool, func, string } from 'prop-types';
import queryString from 'query-string';

import Banner from '../../../../assets/tenant-portal-overview.png';
import Button from '../../../../components/Button';
import { loginCometChatUser } from '../../../../components/CometChatWorkspace/utils';
import FinalFormError from '../../../../components/FinalFormError';
import PasswordInput from '../../../../components/PasswordInput';
import SidebarMarketing from '../../../../components/SidebarMarketing';
import { useErrorToast } from '../../../../components/Toast';
import { login } from '../../../../core/auth/authService';
import MarketingLayout from '../../../../layout/MarketingLayout';
import { useConfig } from '../../../../providers/ConfigProvider';
import composeValidators from '../../../../validators/composeValidators';
import fieldRequired from '../../../../validators/fieldRequired';
import validPassword from '../../../../validators/validPassword';
import resetPassword from '../resetPassword.graphql';

import styles from './NewTenantSetPassword.module.scss';

const NewTenantSetPassword = ({ location, history }) => {
  const { confirmationToken, redirectTo } = queryString.parse(location.search);
  const showErrorToast = useErrorToast();
  const [setPassword] = useMutation(resetPassword);
  const { PRIVATE_BASE_PATH } = useConfig();

  const handleSubmit = async ({ password }) => {
    try {
      const response = await setPassword({
        variables: {
          confirmationToken,
          password,
        },
      });
      const token = get(response, 'data.setPasswordWithToken.token');
      const messagingToken = get(
        response,
        'data.setPasswordWithToken.messagingToken',
      );
      const hasUnreadMessages = get(
        response,
        'data.setPasswordWithToken.hasUnreadMessages',
        false,
      );
      if (messagingToken && hasUnreadMessages) {
        loginCometChatUser(messagingToken);
      }
      login(token);
      history.push(redirectTo || `${PRIVATE_BASE_PATH}dashboard/todos`);
    } catch (e) {
      showErrorToast('An error occurred.');
      throw e;
    }
  };
  return (
    <MarketingLayout
      side={
        <SidebarMarketing
          title="Tenant Portal"
          subtitle="Your one-stop-shop for interacting with your landlord."
          graphic={Banner}
        />
      }
    >
      <div className={styles.container}>
        <Form
          onSubmit={handleSubmit}
          subscription={{
            submitting: true,
          }}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.title}>
                Set a Password to Access Your Tenant Portal
              </div>
              <FinalFormError />
              <div className={styles.passwordField}>
                <Field
                  label="Password"
                  component={PasswordInput}
                  name="password"
                  id="password"
                  validate={composeValidators(fieldRequired, validPassword)}
                  className={styles.password}
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                />
              </div>
              <div className={styles.btnCont}>
                <Button
                  className={styles.submitBtn}
                  type="submit"
                  loading={submitting}
                  id="new-tenant-set-password"
                >
                  Set My Password
                </Button>
              </div>
            </form>
          )}
        </Form>
        <div className={styles.termsText}>
          By clicking the button above you are agreeing to our{' '}
          <a
            href="https://www.turbotenant.com/terms-of-use/"
            target="_blank"
            rel="noopener noreferrer"
            title="Terms of Use"
            className={styles.link}
          >
            Terms of Use
          </a>{' '}
          &{' '}
          <a
            href="https://www.turbotenant.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
            title="Privacy Policy"
            className={styles.link}
          >
            Privacy Policy
          </a>
          .
        </div>
      </div>
    </MarketingLayout>
  );
};

NewTenantSetPassword.propTypes = {
  type: string,
  sendResetEmail: func,
  resendConfirmationMail: func,
  isRenter: bool,
};

export default NewTenantSetPassword;
