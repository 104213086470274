import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Footer from '../../components/Footer';
import MarketingHeader from '../../components/MarketingLayoutHeader';

import styles from './MarketingLayout.module.scss';

const MarketingLayout = ({
  action,
  className,
  mainClassName,
  headerClassName,
  side,
  children,
  onLogoClick,
  hideFooter,
  hideLogoLink,
  hideMobileFooter,
  partnerLogo,
  LogoToReplace,
}) => (
  <div className={cx(styles.container, className)}>
    <div className={cx(styles.main, mainClassName)}>
      <MarketingHeader
        className={headerClassName}
        hideLogoLink={hideLogoLink}
        action={action}
        onLogoClick={onLogoClick}
        partnerLogo={partnerLogo}
        LogoToReplace={LogoToReplace}
      />
      <div className={styles.content}>{children}</div>
      {!hideMobileFooter && <Footer className={styles.footer} />}
    </div>
    <div className={styles.marketing}>
      <div className={styles.sideContent}>
        {side}
        {!hideFooter && <Footer className={styles.footer} />}
      </div>
    </div>
  </div>
);

MarketingLayout.propTypes = {
  action: PropTypes.node,
  partnerLogo: PropTypes.node,
  className: PropTypes.string,
  side: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onLogoClick: PropTypes.func,
  hideFooter: PropTypes.bool,
  hideLogoLink: PropTypes.bool,
  hideMobileFooter: PropTypes.bool,
};

MarketingLayout.defaultProps = {
  hideMobileFooter: false,
};

export default MarketingLayout;
