import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import copy from 'copy-to-clipboard';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Button from '../../components/Button';
import EmailRequiredModal from '../../components/EmailRequiredModal';
import FeedbackThankyouModal from '../../components/FeedbackThankyouModal';
import FlatButton from '../../components/FlatButton';
import Footer from '../../components/Footer';
import LoadingScreen from '../../components/LoadingScreen';
import SeeSomethingInaccurateModal from '../../components/SeeSomethingInaccurateModal';
import {
  useErrorToast,
  useInfoToast,
  useSuccessToast,
} from '../../components/Toast';
import { Paragraph } from '../../components/Typography';
import VideoPlayerModal from '../../components/VideoPlayerModal';
import {
  AIAuditCategoriesEnum,
  AuditItemIncludedEnum,
  AuditToolRatingEnum,
  categoriesOrder,
} from '../../constants/ai-audit-tool';
import colors from '../../constants/colors';
import STATE_NAMES from '../../constants/lease_agreement/state-names';
import { USER_FEEDBACK_TYPE } from '../../constants/user_feedback';
import saveLeaseAgreementAIToolFeedbackGQL from '../../graphql/mutations/lease_agreements/saveLeaseAgreementAIToolFeedback.graphql';
import unlockLeaseAgreementAuditResultsMutationGQL from '../../graphql/mutations/lease_agreements/unlockLeaseAgreementAuditResults.graphql';
import getLeaseAgreementAudit from '../../graphql/queries/getLeaseAgreementAudit.graphql';
import CopySquare from '../../icons/CopySquare';
import Play2 from '../../icons/Play2';
import Sparkles from '../../icons/Sparkles';
import ThumbsDown from '../../icons/ThumbsDownLineThick';
import ThumbsUp from '../../icons/ThumbsUpLineThick';
import { segmentTracking } from '../../services/utilities';
import PreviewPublicLease from '../digital-leases/standalone-wizard/PreviewPublicLease';

import AuditExpandable from './AuditExpandable';
import AuditLAH1 from './AuditLAH1';
import AuditLAHeader from './AuditLAHeader';
import AuditShareLinkModal from './AuditShareLinkModal';
import LeaseAgreementFlowAnimation from './LeaseAgreementFlowAnimation';

import styles from './LeaseAgreementAuditResults.module.scss';

function sortByIncluded(data) {
  const dataCopy = [...data];
  const includedOrder = {
    [AuditItemIncludedEnum.NO]: 2,
    [AuditItemIncludedEnum.INDEFINITE]: 1,
    [AuditItemIncludedEnum.YES]: 0,
  };

  function compareIncluded(a, b) {
    return includedOrder[a.included] - includedOrder[b.included];
  }

  dataCopy.sort(compareIncluded);

  return dataCopy;
}

function groupByCategory(data) {
  data = sortByIncluded(data);

  const categories = {};

  data.forEach((item) => {
    const category = item.category;

    if (!categories[category]) {
      categories[category] = [];
    }

    categories[category].push(item);
  });

  const result = Object.keys(categories).map((category) => ({
    name: category,
    items: categories[category],
  }));

  const sortedResult = result.sort((a, b) => {
    return categoriesOrder.indexOf(a.name) - categoriesOrder.indexOf(b.name);
  });

  return sortedResult;
}

function getAuditScore(auditResults) {
  return auditResults.reduce(
    (acc, item) => {
      if (item.included === AuditItemIncludedEnum.YES) {
        acc.included += 1;
      } else if (item.included === AuditItemIncludedEnum.NO) {
        acc.notIncluded += 1;
      } else if (item.included === AuditItemIncludedEnum.INDEFINITE) {
        acc.indefinite += 1;
      }
      return acc;
    },
    { included: 0, notIncluded: 0, indefinite: 0 },
  );
}

const LeaseAgreementAuditResults = ({ location, history, match }) => {
  const { search } = location;
  const resourceId = match.params?.id;
  const { token } = queryString.parse(search);
  const showInfoToast = useInfoToast();
  const [showShareLinkModal, setShowShareLinkModal] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showTTLeaseAgreement, setShowTTLeaseAgreement] = useState(false);
  const [selectedRating, setSelectedRating] = useState('');
  const [showThankyouModal, setShowThankyouModal] = useState(false);
  const [showInaccuraciesModal, setShowInaccuraciesModal] = useState(false);
  const [textFeedback, setTextFeedback] = useState('');
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [categoryInModal, setCategoryInModal] = useState('');
  const { loading: loadingAudit, data } = useQuery(getLeaseAgreementAudit, {
    variables: {
      token,
    },
  });
  const showErrorToast = useErrorToast();
  const showSuccessToast = useSuccessToast();

  const onSelectRating = (rating) => {
    if (rating !== selectedRating || !selectedRating) {
      setSelectedRating(rating);
    }
  };

  const [saveLeaseAgreementAIToolFeedback, { loading: sendingFeedback }] =
    useMutation(saveLeaseAgreementAIToolFeedbackGQL);

  const resetFeedback = () => {
    setFeedbackSent(!feedbackSent);
    setTextFeedback('');
    setSelectedRating('');
  };

  const sendFeedback = async ({ type, thumbs, feedback, category }) => {
    const result = await saveLeaseAgreementAIToolFeedback({
      variables: {
        resourceId,
        feedback,
        type,
        thumbs,
        category,
      },
    });
    setShowInaccuraciesModal(false);

    if (USER_FEEDBACK_TYPE.GENERAL_FEEDBACK_MODAL === type) {
      resetFeedback();
    } else if (result.data.saveLeaseAgreementAIToolFeedback.ok) {
      setShowThankyouModal(true);
    }
  };

  const [unlockLeaseAgreementAuditResultsMutation, { loading: loadingUnlock }] =
    useMutation(unlockLeaseAgreementAuditResultsMutationGQL, {
      refetchQueries: [
        {
          query: getLeaseAgreementAudit,
          variables: { token },
        },
      ],
      awaitRefetchQueries: true,
    });

  async function onUnlockSubmit(formData) {
    try {
      await unlockLeaseAgreementAuditResultsMutation({
        variables: {
          token,
          email: formData.email,
        },
      });
      showSuccessToast('Audit unlocked.');
    } catch (e) {
      showErrorToast('Error unlocking audit results. Please try again.');
    }
  }

  if (loadingAudit) {
    return <LoadingScreen loading />;
  }

  const auditData = get(data, 'getLeaseAgreementAudit.llm_response', []);
  const state = get(data, 'getLeaseAgreementAudit.state', '');
  const email = get(data, 'getLeaseAgreementAudit.email', '');
  const auditScore = getAuditScore(auditData);
  const categorizedData = groupByCategory(auditData);

  function handleSeeHowClicked(category) {
    setShowTTLeaseAgreement(true);
    segmentTracking('see_how_tt_handles clicked', {
      location: category,
    });
  }

  const pageUrl = window.location.href;

  const handleCopyButtonClick = () => {
    copy(pageUrl);
    showInfoToast('Link Copied!');
  };

  const handleShowAuditModal = () => {
    copy(pageUrl);
    setShowShareLinkModal(true);

    segmentTracking('share_audit_results clicked', {
      location: 'Lease Audit Results Page',
    });
  };

  const categoryToParagraph = {
    [AIAuditCategoriesEnum.ESSENTIAL_LEASE_INFORMATION]: (
      <>
        This information is essential to decrease any confusion about the rental
        terms.{' '}
        <span
          onClick={() =>
            handleSeeHowClicked(
              AIAuditCategoriesEnum.ESSENTIAL_LEASE_INFORMATION,
            )
          }
        >
          See how TurboTenant handles essential lease information.
        </span>{' '}
      </>
    ),
    [AIAuditCategoriesEnum.RENT_LATE_FEES_AND_SECURITY_DEPOSITS]: (
      <>
        This information is essential to decrease any confusion about rent
        amounts.{' '}
        <span
          onClick={() =>
            handleSeeHowClicked(
              AIAuditCategoriesEnum.RENT_LATE_FEES_AND_SECURITY_DEPOSITS,
            )
          }
        >
          See how TurboTenant handles rent, late fees, and security deposits.
        </span>{' '}
      </>
    ),
    [AIAuditCategoriesEnum.GENERAL_LEASE_PROVISIONS]: (
      <>
        This information is essential to provide additional clarity,
        specificity, and legal protection.{' '}
        <span
          onClick={() =>
            handleSeeHowClicked(AIAuditCategoriesEnum.GENERAL_LEASE_PROVISIONS)
          }
        >
          See how TurboTenant handles general lease provisions.
        </span>
      </>
    ),
    [AIAuditCategoriesEnum.CONTRACTUAL_TERMS]: (
      <>
        This information is essential for understanding legal and practical
        aspects and dividing responsibilities.{' '}
        <span
          onClick={() =>
            handleSeeHowClicked(AIAuditCategoriesEnum.CONTRACTUAL_TERMS)
          }
        >
          See how TurboTenant handles this.
        </span>
      </>
    ),
    [AIAuditCategoriesEnum.FAIR_HOUSING]: (
      <>
        This is essential to promoting equality, preventing discrimination, and
        minimizing legal risks.{' '}
        <span
          onClick={() =>
            handleSeeHowClicked(AIAuditCategoriesEnum.FAIR_HOUSING)
          }
        >
          See how TurboTenant handles fair housing.
        </span>
      </>
    ),
    [AIAuditCategoriesEnum.MANDATORY_DISCLOSURES]: (
      <>
        Federal law and {STATE_NAMES[state]} law require various disclosures to
        be attached to every lease agreement.{' '}
        <span
          onClick={() =>
            handleSeeHowClicked(AIAuditCategoriesEnum.MANDATORY_DISCLOSURES)
          }
        >
          See how TurboTenant handles mandatory disclosures & attachments.
        </span>
      </>
    ),
  };

  return (
    <div className={styles.container}>
      {showTTLeaseAgreement && (
        <PreviewPublicLease
          leaseAgreement={{
            id: null,
            property_state: state,
          }}
          onClose={() => {
            segmentTracking('close clicked', {
              location: 'Preview Modal',
            });
            setShowTTLeaseAgreement(false);
          }}
          onAction={() => {
            segmentTracking('build clicked', {
              location: 'Preview Modal',
            });
            history.push(`/lease-agreement?state=${state}`);
          }}
          closeLabel={false}
          actionLabel="Build My Own"
          openLocation={'Lease Audit Results Page'}
          title="TurboTenant's Lease Agreement"
          subtitle="Our lease agreements were drafted by a professional team of lawyers and landlords to keep you compliant and covered."
        />
      )}
      <AuditLAHeader />
      {!email && (
        <EmailRequiredModal
          onClose={() => {}}
          onSubmit={onUnlockSubmit}
          open={!email}
          loading={loadingUnlock}
        />
      )}
      <div className={styles.mainContent}>
        <div className={styles.parallax}>
          <section className={styles.results}>
            <div className={styles.titleSection}>
              <h2>
                <Sparkles height={22} width={22} className={styles.sparkles} />
                Lease agreement audit
              </h2>
              <AuditLAH1 score={auditScore} state={state} />
              <p>
                We've analyzed your lease agreement based on the following
                criteria. Expand each category to learn more. This tool is for
                informational purposes only and is not intended to provide or
                suggest legal advice. Please consult with an attorney for any
                questions on legal compliance.
              </p>
              <FlatButton
                className={cx(styles.shareAudit, styles.mobile)}
                icon={CopySquare}
                iconProps={{
                  className: styles.shareAuditIcon,
                }}
                onClick={handleShowAuditModal}
              >
                Share Audit Results
              </FlatButton>
            </div>
            <div className={styles.cols}>
              <div className={styles.colLeft}>
                <div className={styles.dropdowns}>
                  {categorizedData.map((category) => {
                    return (
                      <AuditExpandable
                        key={category.name}
                        category={category}
                        description={categoryToParagraph[category.name]}
                        state={state}
                        onShowPreview={() => setShowTTLeaseAgreement(true)}
                        onTriggerModal={() => {
                          setShowInaccuraciesModal(true);
                          setCategoryInModal(category.name);
                        }}
                      />
                    );
                  })}
                </div>

                {feedbackSent ? (
                  <div className={cx(styles.howDoYouLike, styles.feedbackSent)}>
                    <div className={styles.title}>
                      Thanks for your feedback!
                    </div>
                    <Paragraph className={styles.feedbackLabel}>
                      Your feedback helps us make the best software for over
                      650,000 landlords nationwide.
                    </Paragraph>

                    <div className={styles.btnWrapper}>
                      <Button
                        className={cx(styles.sendBtn, {
                          [styles.loading]: sendingFeedback,
                        })}
                        type="submit"
                        id="submit-btn"
                        secondary
                        onClick={() => resetFeedback()}
                      >
                        Give more feedback
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div
                    className={cx(styles.howDoYouLike, {
                      [styles.clicked]: selectedRating,
                    })}
                  >
                    <div className={styles.thumbs}>
                      <div className={styles.title}>
                        How do you like the lease agreement audit tool?
                      </div>
                      <FlatButton
                        icon={ThumbsUp}
                        iconProps={{
                          iconClassName: styles.thumbsUpIcon,
                          color: colors.babyBlue,
                        }}
                        className={cx(styles.thumbsUp, {
                          [styles.selected]:
                            selectedRating === AuditToolRatingEnum.UP,
                        })}
                        onClick={() => {
                          onSelectRating(AuditToolRatingEnum.UP);
                          segmentTracking('thumbs_up clicked', {
                            location: 'Lease Audit Results Page',
                          });
                        }}
                      />
                      <FlatButton
                        icon={ThumbsDown}
                        iconProps={{
                          iconClassName: styles.thumbsDownIcon,
                          color: colors.babyBlue,
                        }}
                        className={cx(styles.thumbsDown, {
                          [styles.selected]:
                            selectedRating === AuditToolRatingEnum.DOWN,
                        })}
                        onClick={() => {
                          onSelectRating(AuditToolRatingEnum.DOWN);
                          segmentTracking('thumbs_down clicked', {
                            location: 'Lease Audit Results Page',
                          });
                        }}
                      />
                    </div>
                    <div className={styles.feedbackBottom}>
                      <Paragraph className={styles.feedbackLabel}>
                        {`What’s the number one thing you ${
                          selectedRating === AuditToolRatingEnum.UP
                            ? 'found helpful'
                            : 'would improve?'
                        }`}{' '}
                        <span>(Optional)</span>
                      </Paragraph>
                      <textarea
                        name="feedback"
                        rows={3}
                        placeholder={
                          'Please be as detailed as possible, we personally review all feedback.'
                        }
                        value={textFeedback}
                        className={styles.textarea}
                        onChange={(e) => {
                          setTextFeedback(e.target.value);
                        }}
                      />

                      <div className={styles.btnWrapper}>
                        <Button
                          className={cx(styles.sendBtn, {
                            [styles.loading]: sendingFeedback,
                          })}
                          loading={sendingFeedback}
                          type="submit"
                          disabled={sendingFeedback}
                          id="submit-btn"
                          secondary
                          onClick={() =>
                            sendFeedback({
                              type: USER_FEEDBACK_TYPE.GENERAL_FEEDBACK_MODAL,
                              thumbs: selectedRating,
                              feedback: textFeedback,
                            })
                          }
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.colRight}>
                <div className={styles.stickyWrapper}>
                  <div className={styles.overwhelmedCard}>
                    <h3 className={styles.owTitle}>Feeling overwhelmed?</h3>
                    <p className={styles.owDescription}>
                      Our lease agreements are drafted by a professional team of
                      lawyers and landlords, ensuring compliance with your local
                      laws.
                    </p>
                    <Button
                      className={styles.useBuilderButton}
                      onClick={() => {
                        segmentTracking('use_builder clicked', {
                          location: 'Lease Audit Results Page',
                        });
                        history.push(`/lease-agreement?state=${state}`);
                      }}
                    >
                      Use the builder
                    </Button>
                  </div>
                  <FlatButton
                    className={cx(styles.shareAudit, styles.desktop)}
                    icon={CopySquare}
                    iconProps={{
                      className: styles.shareAuditIcon,
                    }}
                    onClick={handleShowAuditModal}
                  >
                    Share Audit Results
                  </FlatButton>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className={styles.blueBg}>
          <div className={styles.placeholder}></div>
          <section className={styles.whyTurbotenant}>
            <div className={styles.titleSection}>
              <h4>Why TurboTenant?</h4>
              <h3>
                <span>More affordable</span> than a lawyer.
                <br />
                <span>More reliable</span> than a repurposed lease.
              </h3>
              <div>
                Select your rental’s state, fill in the important details, and
                generate a legally binding lease to send to your tenants within
                minutes.
              </div>
              <div
                className={styles.watchHow}
                onClick={() => {
                  segmentTracking('watch_how_it_works clicked', {
                    location: 'Lease Audit Results Page',
                  });
                  setShowVideo(true);
                }}
              >
                Watch how it works{' '}
                <Play2 className={styles.playButton} color={colors.babyBlue} />
              </div>
              <VideoPlayerModal
                mobileFullScreen
                open={showVideo}
                url={
                  'https://www.youtube.com/embed/eB8Hh2Sy944?si=W9zdPqtHzQPUBcdK'
                }
                onClose={() => setShowVideo(false)}
              />
            </div>
          </section>
          <section className={styles.stateSpecificLeaseAgreements}>
            <div className={styles.col1}>
              <div className={styles.titleSection}>
                <h4>state-specific lease agreements</h4>
                <h3>
                  <span>Crafted and updated</span> by legal professionals
                </h3>
                <p>
                  TurboTenant’s fill-in-the-blank builder is trusted by both
                  first-time landlords and experienced investors. From standard
                  information to rental specifics, each lease is{' '}
                  <b>completely customizable</b>.
                  <br />
                  <br />
                  Our legal team routinely checks our lease templates to ensure
                  they are up-to-date with local jurisdiction.
                </p>
                <Button
                  secondary
                  className={styles.buildMyLeaseButton}
                  onClick={() => {
                    segmentTracking('build_my_lease clicked', {
                      location: 'Lease Audit Results Page',
                    });
                    history.push(`/lease-agreement?state=${state}`);
                  }}
                >
                  Build My Lease
                </Button>
              </div>
            </div>
            <div className={styles.col2}>
              <div className={styles.buildLeaseImage}>
                <LeaseAgreementFlowAnimation
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
            </div>
          </section>
          <Footer className={styles.footer} />
          <div className={styles.marginSubstractor}></div>
        </div>
      </div>
      {showInaccuraciesModal && (
        <SeeSomethingInaccurateModal
          onSubmit={(data) => {
            segmentTracking('submit clicked', {
              location: 'Something Inaccurate Modal',
            });
            sendFeedback({
              ...data,
              category: categoryInModal,
              type: USER_FEEDBACK_TYPE.INACCURACIES_MODAL,
            });
          }}
          onClose={() => {
            segmentTracking('close clicked', {
              location: 'Something Inaccurate Modal',
            });
            setShowInaccuraciesModal(false);
          }}
          open={showInaccuraciesModal}
          loading={sendingFeedback}
          openLocation={'Lease Audit Results Page'}
        />
      )}
      <FeedbackThankyouModal
        open={showThankyouModal && !sendingFeedback}
        onClose={() => setShowThankyouModal(false)}
      />
      {showShareLinkModal ? (
        <AuditShareLinkModal
          open
          onCopyBtnClick={handleCopyButtonClick}
          onClose={() => setShowShareLinkModal(false)}
        />
      ) : null}
    </div>
  );
};

LeaseAgreementAuditResults.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default LeaseAgreementAuditResults;
