import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../../../../constants/colors';
import Calculator from '../../../../../icons/streamline/Calculator';
import Card from '../../../../Card';
import ExpandableCard from '../../../../ExpandableCard';
import Tag from '../../../../Tag';
import { Link, Paragraph } from '../../../../Typography';
import { H5 } from '../../../../Typography';
import Benefit from '../Benefit';

import styles from './BenefitItemsMobile.module.scss';

const BenefitItemsMobile = ({
  benefits,
  selectedBenefit,
  extraParams,
  scrollToPremium,
  scrollToPremiumPlus,
  isPremiumPlus = false,
  isPro = false,
  onClickSegment,
}) => {
  return benefits.map((benefit) => {
    const justPremiumBenefits = benefit.items.filter(
      (item) => item.plan === 'premium',
    );

    const benefitTitle = benefit.titleShort || benefit.title;

    const showAccounting =
      !isPro &&
      (selectedBenefit !== 'all_features' ||
        (selectedBenefit === 'all_features' && benefit.key === 'support'));

    return (
      <div className={styles.expandableCardWrapper} key={benefit.key}>
        <ExpandableCard
          title={benefitTitle}
          className={cx(styles.expandableCard, styles.bolder)}
          contentClassName={styles.expandableCardContent}
          headerClassName={cx(styles.expandableCardHeader, {
            [styles.premium]: isPremiumPlus,
          })}
          defaultExpanded={selectedBenefit === benefit.key}
          iconProps={
            isPremiumPlus ? { color: colors.white } : { color: colors.primary }
          }
          onClick={() => {
            onClickSegment(`${benefitTitle.toLowerCase()}_tab`);
          }}
        >
          {benefit.items.map((item) => {
            if (isPro && item.plan === 'premium') {
              return null;
            }

            return (
              <Benefit
                key={item.key}
                item={item}
                extraParams={extraParams}
                isPremiumPlus={isPremiumPlus}
              />
            );
          })}

          {isPro &&
            justPremiumBenefits.map((item, index) => (
              <div key={item.key}>
                {index === 0 && (
                  <Paragraph className={styles.onlyWithPremium}>
                    Only included with{' '}
                    <Link onClick={scrollToPremium}>Premium Plan</Link>:
                  </Paragraph>
                )}
                <div className={styles.disabled}>
                  <Benefit
                    item={item}
                    extraParams={extraParams}
                    isPremiumPlus={isPremiumPlus}
                  />
                </div>
              </div>
            ))}

          {showAccounting && (
            <div>
              {!isPremiumPlus && (
                <Paragraph className={styles.onlyWithPremium}>
                  Included with{' '}
                  <Link onClick={scrollToPremiumPlus}>Premium Plus Plan</Link>:
                </Paragraph>
              )}
              <Card
                className={cx(styles.accounting, {
                  [styles.disabled]: !isPremiumPlus,
                })}
              >
                <Tag accent className={styles.tag}>
                  $180 VALUE
                </Tag>
                <div className={styles.accountingHeader}>
                  <Calculator secondaryColor={colors.babyBlue} />
                  <H5>Rental Accounting</H5>
                </div>
                <Paragraph>
                  Bookkeeping built specifically for real estate investors
                </Paragraph>
              </Card>
            </div>
          )}
        </ExpandableCard>
      </div>
    );
  });
};

BenefitItemsMobile.propTypes = {
  benefits: PropTypes.array.isRequired,
  selectedBenefit: PropTypes.string.isRequired,
  extraParams: PropTypes.object.isRequired,
  scrollToPremium: PropTypes.func.isRequired,
  scrollToPremiumPlus: PropTypes.func.isRequired,
  isPremiumPlus: PropTypes.bool,
  isProPlan: PropTypes.bool,
};

export default BenefitItemsMobile;
