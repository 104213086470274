import React from 'react';
import cx from 'classnames';

import SegmentedController from '../SegmentedController';

import CoreField from './CoreField';

import styles from './SegmentedControllerField.module.scss';

const SegmentedControllerField = ({
  id,
  name,
  className,
  containerClassName,
  options = [],
  disabled,
  ...props
}) => (
  <CoreField id={id || name} name={name} {...props}>
    {({ input }) => (
      <SegmentedController
        containerClassName={cx(
          styles.segmentedControllerContainer,
          containerClassName,
        )}
        className={cx(styles.segmentedController, className)}
        input={input}
        options={options}
        label={props.label}
        id={id || name}
        disabled={disabled}
      />
    )}
  </CoreField>
);

export default SegmentedControllerField;
