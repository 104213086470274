import React, { useCallback } from 'react';

import CustomIframe from '../../../components/CustomIframe';
import IconButton from '../../../components/IconButton';
import Modal from '../../../components/Modal';
import PartnersCard from '../../../components/PartnersCard';
import { useListings } from '../../../core/TTgraphql';
import getSteadilyInputs from '../../../helpers/getSteadilyInputs';
import { useRenderOutside } from '../../../helpers/render-outside';
import Close from '../../../icons/Close';
import SteadilyToolboxLogo from '../../../icons/SteadilyToolboxLogo';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';

import styles from './SteadilyToolboxCard.module.scss';

const SteadilyToolboxCard = ({ user, className }) => {
  const onSteadilyRefChange = useCallback((steadilyNode) => {
    if (steadilyNode !== null) {
      steadilyNode.submit();
    }
  }, []);

  const { listings } = useListings({ skip: user?.listings_count !== 1 }, true);
  const listing = user?.listings_count === 1 && listings[0];

  const { STEADILY_FORM_URL } = useConfig();

  const renderOutside = useRenderOutside();

  const steadilyInputs = getSteadilyInputs(user, listing);

  const showSteadilyModal = () => {
    renderOutside((done) => (
      <Modal
        onClose={() => {
          done();
          setTimeout(() => document.activeElement.blur(), 1);
        }}
        open={true}
        containerClassName={styles.steadilyModalContainer}
        className={styles.steadilyModal}
        mobileFullScreen={true}
      >
        <IconButton
          className={styles.close}
          onClick={() => {
            done();
            setTimeout(() => document.activeElement.blur(), 1);
          }}
          icon={Close}
          iconProps={{ width: 19, height: 19 }}
        />
        <CustomIframe className={styles.steadilyIframe} id="myIFrame">
          <form
            action={STEADILY_FORM_URL}
            method="POST"
            ref={onSteadilyRefChange}
          >
            {steadilyInputs &&
              steadilyInputs.map(({ name, value }, index) => (
                <input
                  readOnly
                  type="hidden"
                  name={name}
                  value={value}
                  key={`steadily_${index}`}
                />
              ))}
          </form>
        </CustomIframe>
      </Modal>
    ));
  };

  return (
    <PartnersCard
      className={className}
      Logo={SteadilyToolboxLogo}
      title={'Fast and affordable landlord insurance'}
      description={
        'Get the legal and financial protection you need from America’s best-rated landlord insurance company.'
      }
      tagProps={{
        label: 'Insurance',
      }}
      actions={[
        {
          label: 'Get my free quote',
          onClick: () => {
            segmentTracking('get_my_free_quote clicked', {
              location: 'toolbox',
            });
            showSteadilyModal();
          },
        },
      ]}
    />
  );
};

export default SteadilyToolboxCard;
