import { useUserProfile } from '../../core/TTgraphql';

const useUserDefaultEntityAccounts = () => {
  const { user, loading } = useUserProfile({ polling: false });

  if (loading) return [];

  return user?.payments_data?.active_external_accounts || [];
};

export default useUserDefaultEntityAccounts;
