import React from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FORM_ERROR } from 'final-form';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import LoadingScreen from '../../../../../components/LoadingScreen';
import ListingModeEnum from '../../../../../constants/enums/ListingModeEnum';
import { useListings } from '../../../../../core/TTgraphql';
import basicUserProfile from '../../../../../graphql/basicUserProfile.graphql';
import addPropertyMutation from '../../../../../graphql/mutations/listings/addProperty.graphql';
import updatePropertyOnboardingGQL from '../../../../../graphql/mutations/listings/updatePropertyOnboarding.graphql';
import leasesQuery from '../../../../../graphql/queries/leasesQuery.graphql';
import isSinglePropertyType from '../../../../../helpers/isSinglePropertyType';
import {
  removeFromApolloCache,
  segmentTracking,
} from '../../../../../services/utilities';
import PropertyDetailsEditOnboarding from '../forms/PropertyDetailsEditOnboarding';
import PropertyDetailsNewOnboarding from '../forms/PropertyDetailsNewOnboarding';

const PropertyDetailsNewOnboardingWrapper = ({
  onNext,
  isEdit,
  history,
  wantsOfflineTracking: wantsOfflineTrackingFromProps,
  ...rest
} = {}) => {
  const { listings, loading } = useListings(
    { variables: { mode: ListingModeEnum.ROOTS_ONLY } },
    true,
  );

  /**
   * @NOTICE:
   *
   * We are assuming the user will never have more than one during the onboarding.
   */
  const listing = listings[0] ? { ...listings[0] } : {};

  const initialValues = {
    address: listing.address,
    city: listing.city,
    state: listing.state,
    zip: listing.zip,
    unit: listing.unit,
    ...(!listing.is_multiunit && listing.has_rooms
      ? { room_name: listing.children_listings[0].room_name }
      : {}),
    ...(listing.is_multiunit
      ? {
          unit: listing.children_listings[0].unit,
          ...(listing.children_listings[0].has_rooms
            ? {
                room_name:
                  listing.children_listings[0].children_listings[0].room_name,
              }
            : {}),
        }
      : {}),
  };

  const [addProperty, { loading: addPropertyRequestLoading }] = useMutation(
    addPropertyMutation,
    {
      refetchQueries: [{ query: basicUserProfile }, { query: leasesQuery }],
      update: (cache) => {
        removeFromApolloCache(['listings'], cache);
      },
      awaitRefetchQueries: true,
    },
  );

  const onSubmit = async ({
    property_type,
    roomRentals,
    room_name,
    unit_name,
    ...data
  }) => {
    const isSingleProperty = isSinglePropertyType(property_type);

    const formData = {
      property_type,
      ...data,
      ...(isSingleProperty && roomRentals ? { rooms: [{ room_name }] } : {}),
      ...(!isSingleProperty
        ? {
            units: [
              {
                unit: unit_name,
                ...(roomRentals ? { rooms: [{ room_name }] } : {}),
              },
            ],
          }
        : {}),
    };

    try {
      if (wantsOfflineTrackingFromProps) {
        segmentTracking('property_step submit', {
          location: 'rp offline onboarding property step',
        });
      } else {
        segmentTracking('property_step submit', {
          location: 'rp onboarding property step',
        });
      }

      await addProperty({
        variables: {
          inputData: formData,
          fromOnboarding: true,
        },
      });

      onNext();
    } catch (error) {
      return {
        [FORM_ERROR]:
          get(error, `graphQLErrors[0].message`) || 'Something went wrong',
      };
    }
  };

  const [updatePropertyOnboarding, { loading: updateListingLoading }] =
    useMutation(updatePropertyOnboardingGQL, {
      refetchQueries: [{ query: basicUserProfile }],
      update: (cache) => {
        removeFromApolloCache(['listings'], cache);
      },
      awaitRefetchQueries: true,
    });

  const onUpdate = async ({ address, city, state, zip, unit, room_name }) => {
    try {
      segmentTracking('property_step submit', {
        location: 'rp onboarding property step',
      });

      await updatePropertyOnboarding({
        variables: {
          listingId: listing.id, // Parent listing id
          address,
          city,
          state,
          zip,
          unit,
          roomName: room_name,
        },
      });
      onNext();
    } catch (error) {
      return {
        [FORM_ERROR]:
          get(error, `graphQLErrors[0].message`) || 'Something went wrong',
      };
    }
  };

  if (loading || addPropertyRequestLoading || updateListingLoading) {
    return <LoadingScreen loading={true} />;
  }

  return (
    <>
      {isEdit ? (
        <PropertyDetailsEditOnboarding
          onSubmit={onUpdate}
          initialValues={initialValues}
          isMultiUnitPropertyType={!!listing.is_multiunit}
          hasRooms={
            !!(listing.is_multiunit
              ? listing.children_listings[0].has_rooms
              : listing.has_rooms)
          }
          {...rest}
        />
      ) : (
        <PropertyDetailsNewOnboarding
          onSubmit={onSubmit}
          isOfflineTracking={wantsOfflineTrackingFromProps}
          {...rest}
        />
      )}
    </>
  );
};

PropertyDetailsNewOnboardingWrapper.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  wantsOfflineTracking: PropTypes.bool,
};

export default withRouter(PropertyDetailsNewOnboardingWrapper);
