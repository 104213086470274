import React, { useEffect } from 'react';

const ReviewsIoWidget = ({ className }) => {
  useEffect(() => {
    const element = document.getElementById('badge-ribbon');
    const iframe = element.getElementsByTagName('iframe')[0];
    if (!iframe) {
      window?.reviewsBadgeRibbon?.('badge-ribbon', {
        store: 'turbotenant-com',
        size: 'small',
      });
    }
  }, []);

  return <div className={className} id="badge-ribbon" />;
};

export default ReviewsIoWidget;
