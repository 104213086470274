import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import config from '../../../config';
import calculateDeviceLoginToken from '../../../utils/auth/calculate-device-login-token';
import { EmailQueryStringContext } from '../providers/EmailQueryStringProvider';

const styles = {
  // backgroundBox style
  backgroundBox: {
    borderRadius: 4,
    backgroundColor: '#E7F8FD',
    fontSize: '16px',
    padding: 24,
  },
  // Link styles
  link: {
    color: '#2370A3',
    fontWeight: '600',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '16px',
  },

  footerLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#516A80',
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '21px',
  },

  // H1 styles,
  h1: {
    color: '#21282A',
    fontSize: '24px',
    fontWeight: '600',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '36px',
    margin: '0 0 16px 0',
  },
  h1new: {
    color: '#21282A',
    fontSize: '28px',
    fontWeight: '600',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '42px',
    margin: 0,
  },

  // H2 style,
  h2: {
    color: '#7A7D80',
    fontSize: '20px',
    fontWeight: '600',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '30px',
    margin: '0 0 8px 0',
  },
  h2new: {
    color: '#042238',
    fontSize: '20px',
    fontWeight: '600',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '30px',
    margin: '0 0 8px 0',
  },

  // H3 style,
  h3: {
    color: '#7A7D80',
    fontSize: '20px',
    fontWeight: '600',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '30px',
  },
  h3new: {
    color: '#042238',
    fontSize: '18px',
    fontWeight: '600',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '27px',
  },

  // H4 style,
  h4: {
    color: '#00C8FF',
    fontSize: '16px',
    fontWeight: '600',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '24px',
    textTransform: 'uppercase',
    margin: 0,
  },
  // H6 style,
  h6: {
    color: '#7A7D80',
    fontSize: '12px',
    fontWeight: '600',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '18px',
    textTransform: 'uppercase',
    margin: 0,
    display: 'inline-block',
  },

  // Eyebrow Header style,
  eyebrowHeader: {
    color: '#033A6D',
    fontSize: '14px',
    fontWeight: '700',
    fontFamily: 'Open Sans, sans-serif',
    lineHeight: '21px',
    textTransform: 'uppercase',
    margin: '0 0 2px 0',
    letterSpacing: '0.035px',
  },

  // Eyebrow Header New style,
  eyebrowHeaderNew: {
    color: '#2370A3',
    fontSize: '16px',
    fontWeight: '700',
    fontFamily: 'Open Sans, sans-serif',
    lineHeight: '24px',
    textTransform: 'uppercase',
    margin: '0 0 2px 0',
  },

  // P styles
  p: {
    color: '#042238',
    fontSize: '16px',
    lineHeight: '24px',
    margin: '0 0 16px 0',
  },

  PreTagAsParagraph: {
    color: '#042238',
    fontSize: '16px',
    lineHeight: '24px',
    margin: '0 0 16px 0',
    fontFamily: 'Poppins, sans-serif',
  },

  // Label
  label: {
    color: '#042238',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    margin: '4px 0',
  },
  // Large Body
  largeBody: {
    color: '#516A80',
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: '24px',
    margin: '0 0 4px 0',
    letterSpacing: '0px',
  },

  // LIST
  list: {
    padding: '12px 0 0 16px',
    margin: '0',
    listStyle: 'none',
  },

  // Ordered list
  orderedList: {
    padding: '12px 0 0 40px',
    listStyle: 'decimal',
    textAlign: 'left',
    fontSize: '16px',
    color: '#042238',
  },

  listItem: {
    whiteSpace: 'nowrap',
    position: 'relative',
    lineHeight: '24px',
    margin: '0 0 12px 0',
  },

  listItemOrdered: {
    whiteSpace: 'nowrap',
    position: 'relative',
    lineHeight: '24px',
    margin: '0 0 12px 0',
  },

  listCircleIndex: {
    display: 'inline-block',
    width: 24,
    height: 24,
    background: '#022C52',
    margin: 0,
    borderRadius: 12,
    textAlign: 'center',
    color: '#fff',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '21px',
    marginRight: '8px',
  },

  listChildOrdered: {
    color: '#042238',
    whiteSpace: 'normal',
    display: 'inline-block',
    fontSize: '16px',
    verticalAlign: 'top',
  },

  listArrow: {
    display: 'inline-block',
    borderTop: '8px solid transparent',
    borderBottom: '8px solid transparent',
    borderLeft: '16px solid #2370A3',
    verticalAlign: 'top',
    marginTop: '4px',
  },

  listPrefix: {
    display: 'inline-block',
    verticalAlign: 'top',
    color: '#042238',
    fontSize: '16px',
  },

  listChild: {
    color: '#042238',
    whiteSpace: 'normal',
    verticalAlign: 'middle',
    display: 'inline-block',
    marginLeft: '16px',
    fontSize: '16px',
  },

  listChildPrefix: {
    color: '#042238',
    whiteSpace: 'normal',
    display: 'inline-block',
    marginLeft: '16px',
    fontSize: '16px',
  },

  listArrowNew: {
    display: 'inline-block',
    borderTop: '6px solid transparent',
    borderBottom: '6px solid transparent',
    borderLeft: '12px solid #2370A3',
    verticalAlign: 'top',
    marginTop: '10px',
  },

  listChildNew: {
    color: '#042238',
    whiteSpace: 'normal',
    verticalAlign: 'middle',
    display: 'inline-block',
    marginLeft: '8px',
    fontSize: '16px',
  },

  btnWrap: {
    textAlign: 'center',
    padding: '8px 0',
  },

  btn: {
    maxWidth: '248px',
    width: '90%',
    backgroundColor: '#033A6D',
    height: '44px',
    lineHeight: '44px',
    fontSize: '14px',
    textTransform: 'uppercase',
    borderRadius: '5px',
    padding: '0 10px',
    display: 'inline-block',
    textDecoration: 'none',
    color: '#fff',
    fontWeight: 'bold',
  },

  btnNew: {
    maxWidth: '248px',
    width: '90%',
    backgroundColor: '#033A6D',
    height: '56px',
    lineHeight: '56px',
    fontSize: '14px',
    textTransform: 'uppercase',
    borderRadius: '50px',
    padding: '0 10px',
    display: 'inline-block',
    textDecoration: 'none',
    color: '#fff',
    fontWeight: 'bold',
  },
  btnNewSecondary: {
    maxWidth: '248px',
    width: '90%',
    border: '2px solid #033A6D',
    height: '56px',
    lineHeight: '56px',
    fontSize: '14px',
    textTransform: 'uppercase',
    borderRadius: '50px',
    padding: '0 10px',
    display: 'inline-block',
    textDecoration: 'none',
    color: '#033A6D',
    fontWeight: 'bold',
  },

  hr: {
    borderBottom: '1px solid #E6E9F0',
    margin: '24px 0',
    height: 0,
  },

  hrNew: {
    borderBottom: '1px solid #E6E9F0',
    margin: 0,
    height: 0,
  },

  table: {
    color: '#042238',
    fontSize: '16px',
    lineHeight: '24px',
    paddingBottom: '20px',
  },

  td: {
    paddingBottom: '12px',
  },

  tr: {
    verticalAlign: 'top',
  },

  thumbsHolder: {
    padding: '16px 40px',
    border: '2px solid #ACB9C8',
    borderRadius: '4px',
    display: 'inline-block',
  },

  promoCard: {
    backgroundColor: '#a9f3df',
    textAlign: 'center',
  },
};

const isOwnerUrl = (href) => {
  if (href == null) return false;

  const { host = '', pathname = '' } = new URL(href);
  const [subdomain = ''] = host.split('.');
  const [path = ''] = pathname.split('/').filter((chunk) => chunk !== '');

  return (
    subdomain === 'rental' ||
    subdomain.includes('-owner') ||
    path === 'owners' ||
    !!href.match(/auth\/user\/accept_invite/g)
  );
};

export const Link = ({
  children,
  href,
  style,
  skipDLT = false,
  noUTM = false,
  disabled,
  footerLink = false,
  ...props
}) => {
  if (href.includes('mailto:') || href.includes('tel:')) {
    return (
      <a
        {...props}
        href={href}
        style={{
          ...(footerLink ? styles.footerLink : styles.link),
          ...style,
        }}
        onClick={(e) => {
          if (disabled) {
            e.preventDefault();
          }
        }}
      >
        {children}
      </a>
    );
  }

  const { ownerEmail, userEmail, ...utm } = useContext(EmailQueryStringContext);
  const url = new URL(href);

  const params = {
    ...queryString.parse(url.search),
    ...(noUTM
      ? {}
      : Object.keys(utm).reduce(
          (params, key) => ({ ...params, [`utm_${key}`]: utm[key] }),
          {},
        )),
  };
  // NOTICE: we use 'skipDLT' because there's some urlsa that although
  //         they are owner urls do not require login. e.g.:
  //         https://rental.turbotenant.com/owners/referrals
  if (!skipDLT && isOwnerUrl(href)) {
    if (ownerEmail || userEmail) {
      params.dlt = calculateDeviceLoginToken(ownerEmail || userEmail);
    } else {
      // NOTICE: the owner email must be passed to the email template
      //         when the email links to an owner URL, this is important
      //         since the email is needed to generate the DLT.
      if (!config.isTestMode) {
        console.warn(
          'NOTICE: <Link /> was passed an onwer URL but email was not passed to build the DLT',
        );
      }
    }
  }

  // NOTICE: query-string deals with url encoding and skips undefined values
  const newSearch = queryString.stringify(params);

  return (
    <a
      {...props}
      style={{ ...(footerLink ? styles.footerLink : styles.link), ...style }}
      href={`${url.origin}${url.pathname}?${newSearch}${url.hash}`}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
        }
      }}
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  href: PropTypes.string.isRequired,
  style: PropTypes.object,
  skipDLT: PropTypes.bool,
  noUTM: PropTypes.bool,
  disabled: PropTypes.bool,
  footerLink: PropTypes.bool,
};

export const H1 = ({ style, ...props }) => (
  <h1
    {...props}
    style={{
      ...styles.h1,
      ...style,
    }}
  />
);

H1.propTypes = {
  style: PropTypes.object,
};

export const H1New = ({ style, ...props }) => (
  <h1
    {...props}
    style={{
      ...styles.h1new,
      ...style,
    }}
  />
);

H1New.propTypes = {
  style: PropTypes.object,
};

export const BackgroundBox = ({ style, ...props }) => (
  <div
    {...props}
    style={{
      ...styles.backgroundBox,
      ...style,
    }}
  >
    {props.children}
  </div>
);

BackgroundBox.propTypes = {
  style: PropTypes.object,
};

export const H2 = ({ style, ...props }) => (
  <h2
    {...props}
    style={{
      ...styles.h2,
      ...style,
    }}
  />
);

H2.propTypes = {
  style: PropTypes.object,
};

export const H2New = ({ style, ...props }) => (
  <h3
    {...props}
    style={{
      ...styles.h2new,
      ...style,
    }}
  />
);

H2New.propTypes = {
  style: PropTypes.object,
};

export const H3 = ({ style, ...props }) => (
  <h3
    {...props}
    style={{
      ...styles.h3,
      ...style,
    }}
  />
);

H3.propTypes = {
  style: PropTypes.object,
};

export const H3New = ({ style, ...props }) => (
  <h3
    {...props}
    style={{
      ...styles.h3new,
      ...style,
    }}
  />
);

H3New.propTypes = {
  style: PropTypes.object,
};

export const H4 = ({ style, ...props }) => (
  <h4
    {...props}
    style={{
      ...styles.h4,
      ...style,
    }}
  />
);

H4.propTypes = {
  style: PropTypes.object,
};

export const H6 = ({ style, ...props }) => (
  <h6
    {...props}
    style={{
      ...styles.h6,
      ...style,
    }}
  />
);

H6.propTypes = {
  style: PropTypes.object,
};

export const EyebrowHeader = ({ style, ...props }) => (
  <h4
    {...props}
    style={{
      ...styles.eyebrowHeader,
      ...style,
    }}
  />
);

EyebrowHeader.propTypes = {
  style: PropTypes.object,
};

export const EyebrowHeaderNew = ({ style, ...props }) => (
  <h4
    {...props}
    style={{
      ...styles.eyebrowHeaderNew,
      ...style,
    }}
  />
);

EyebrowHeaderNew.propTypes = {
  style: PropTypes.object,
};

export const P = ({ style, ...props }) => (
  <p
    {...props}
    style={{
      ...styles.p,
      ...style,
    }}
  />
);

P.propTypes = {
  style: PropTypes.object,
};

export const PreTagAsParagraph = ({ style, ...props }) => (
  <pre
    {...props}
    style={{
      ...styles.PreTagAsParagraph,
      ...style,
    }}
  />
);

PreTagAsParagraph.propTypes = {
  style: PropTypes.object,
};

export const Label = ({ style, ...props }) => (
  <p
    {...props}
    style={{
      ...styles.label,
      ...style,
    }}
  />
);

Label.propTypes = {
  style: PropTypes.object,
};

export const LargeBody = ({ style, ...props }) => (
  <p
    {...props}
    style={{
      ...styles.largeBody,
      ...style,
    }}
  />
);

LargeBody.propTypes = {
  style: PropTypes.object,
};

export const List = ({ style, orderedList, ...props }) => {
  let Element = 'ul';

  if (orderedList) {
    Element = 'ol';
  }

  return (
    <Element
      {...props}
      style={{
        ...styles.list,
        ...(orderedList ? styles.orderedList : {}),
        ...style,
      }}
    />
  );
};

List.propTypes = {
  style: PropTypes.object,
  orderedList: PropTypes.bool,
};

export const ListItem = ({
  style,
  children,
  listArrowStyles = {},
  ...props
}) => (
  <li
    {...props}
    style={{
      ...styles.listItem,
      ...style,
    }}
  >
    <div style={{ ...styles.listArrow, ...listArrowStyles }} />
    <span style={styles.listChild}>{children}</span>
  </li>
);

ListItem.propTypes = {
  style: PropTypes.object,
  children: PropTypes.any.isRequired,
  listArrowStyles: PropTypes.object,
};

export const ListItemOrdered = ({ style, children, ...props }) => (
  <li
    {...props}
    style={{
      ...styles.listItem,
      ...style,
    }}
  >
    <span style={styles.listChildOrdered}>{children}</span>
  </li>
);

ListItemOrdered.propTypes = {
  style: PropTypes.object,
  children: PropTypes.any.isRequired,
};

export const ListItemCircleIndex = ({ style, index, children, ...props }) => (
  <li
    {...props}
    style={{
      ...styles.listItemOrdered,
      ...style,
    }}
  >
    <div style={styles.listCircleIndex}>{index}</div>
    <span style={styles.listChildOrdered}>{children}</span>
  </li>
);

ListItemCircleIndex.propTypes = {
  style: PropTypes.object,
  children: PropTypes.any.isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export const ListItemNew = ({
  style,
  children,
  listArrowStyles = {},
  listChildStyles = {},
  ...props
}) => (
  <li
    {...props}
    style={{
      ...styles.listItem,
      ...style,
    }}
  >
    <div style={{ ...styles.listArrowNew, ...listArrowStyles }} />
    <span style={{ ...styles.listChildNew, ...listChildStyles }}>
      {children}
    </span>
  </li>
);

export const ListItemNoBullet = ({ style, children, ...props }) => (
  <li
    {...props}
    style={{
      ...styles.listItem,
      ...style,
    }}
  >
    <span style={styles.listChild}>{children}</span>
  </li>
);

ListItemNoBullet.propTypes = {
  style: PropTypes.object,
  children: PropTypes.element.isRequired,
};

export const ListItemPrefix = ({
  prefix,
  style,
  children,
  listPrefixStyles = {},
  listChildStyles = {},
  ...props
}) => (
  <li
    {...props}
    style={{
      ...styles.listItem,
      ...style,
    }}
  >
    <span style={{ ...styles.listPrefix, ...listPrefixStyles }}>{prefix}</span>
    <span style={{ ...styles.listChildPrefix, ...listChildStyles }}>
      {children}
    </span>
  </li>
);

ListItemPrefix.propTypes = {
  style: PropTypes.object,
  prefix: PropTypes.string,
  children: PropTypes.any.isRequired,
  listPrefixStyles: PropTypes.object,
  listChildStyles: PropTypes.object,
};

export const ActionButton = ({ style, ...props }) => (
  <div style={styles.btnWrap}>
    <a
      {...props}
      style={{
        ...styles.btn,
        ...style,
      }}
    />
  </div>
);

ActionButton.propTypes = {
  style: PropTypes.object,
};

export const ActionButtonNew = ({
  style,
  styleWrapper,
  children,
  secondary,
  ...props
}) => (
  <div style={{ ...styles.btnWrap, ...styleWrapper }}>
    <Link
      {...props}
      style={{
        ...(secondary ? styles.btnNewSecondary : styles.btnNew),
        ...style,
      }}
    >
      {children}
    </Link>
  </div>
);

ActionButtonNew.propTypes = {
  style: PropTypes.object,
  styleWrapper: PropTypes.object,
  children: PropTypes.any.isRequired,
  secondary: PropTypes.bool,
};

export const ActionButtonSecondary = ({ style, styleWrapper, ...props }) => (
  <div style={{ ...styles.btnWrap, ...styleWrapper }}>
    <a
      {...props}
      style={{
        ...styles.btnNew,
        backgroundColor: '#FFFFFF',
        border: '2px solid #033A6D',
        color: '#033A6D',
        ...style,
      }}
    />
  </div>
);

ActionButtonSecondary.propTypes = {
  style: PropTypes.object,
  styleWrapper: PropTypes.object,
};

export const Table = ({ ...props }) => (
  <table style={styles.table}>
    <tbody>{props.children}</tbody>
  </table>
);

Table.propTypes = {
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const TableRow = ({ ...props }) => (
  <tr style={styles.tr}>
    <td style={{ paddingRight: 12 }}>{props.title}</td>
    <td style={{ ...styles.td }}>{props.children}</td>
  </tr>
);

TableRow.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const Hr = ({ style }) => (
  <div
    style={{
      ...style,
      ...styles.hr,
    }}
  >
    &nbsp;
  </div>
);

Hr.propTypes = {
  style: PropTypes.object,
};

export const HrNew = ({ style }) => (
  <div
    style={{
      ...styles.hrNew,
      ...style,
    }}
  >
    &nbsp;
  </div>
);

HrNew.propTypes = {
  style: PropTypes.object,
};

export const ThumbsHolder = ({ style, children, ...props }) => (
  <a
    style={{
      ...style,
      ...styles.thumbsHolder,
    }}
    {...props}
  >
    {children}
  </a>
);

export const PromoCard = ({
  style,
  title,
  description,
  cta,
  ctaTo,
  ...props
}) => (
  <BackgroundBox
    style={{
      ...style,
      ...styles.promoCard,
    }}
    {...props}
  >
    <H4 style={{ marginBottom: '4px', color: 'black' }}>{title}</H4>
    <P style={{ marginBottom: '16px' }}>{description}</P>
    {cta && <ActionButtonNew href={ctaTo}>{cta}</ActionButtonNew>}
  </BackgroundBox>
);

export const ChecklistItem = ({ styles, title, checked, skipped }) => (
  <>
    <table
      style={{
        backgroundColor: '#e7f8fd',
        border: '2px solid #e6e9f0',
        borderRadius: '4px',
        width: '100%',
        marginBottom: '8px',
        ...styles,
      }}
    >
      <tbody>
        <tr>
          <td
            style={{
              paddingTop: 16,
              paddingBottom: 16,
              paddingLeft: 24,
              paddingRight: 8,
            }}
          >
            <img
              style={{ width: 18, height: 18, display: 'block' }}
              src={
                checked
                  ? `${config.assetsBaseUrl}/assets/filled-checkbox.png`
                  : `${config.assetsBaseUrl}/assets/empty-checkbox.png`
              }
            />
          </td>
          <td
            style={{
              color: '#042238',
              fontSize: 16,
              fontWeight: 'bold',
              letterSpacing: 0,
              lineHeight: '24px',
              paddingRight: '9px',
              width: '100%',
              paddingTop: '16px',
              paddingBottom: '16px',
              ...(skipped
                ? { color: '#516a80', textDecoration: 'line-through' }
                : {}),
            }}
          >
            {title}
          </td>
          <td
            style={{
              paddingTop: 16,
              paddingBottom: 16,
              paddingRight: 24,
            }}
          >
            {skipped ? (
              <span
                style={{
                  borderRadius: '4px',
                  padding: '4px 8px',
                  marginLeft: '8px',
                  backgroundColor: ' #e6e9f0',
                  color: '#042238',
                  fontFamily: 'Open Sans, sans-serif',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  letterSpacing: '0.25px',
                  lineHeight: '18px',
                  textDecoration: 'none',
                }}
              >
                Skipped
              </span>
            ) : (
              <img
                style={{ width: 9, height: 13 }}
                src={`${config.assetsBaseUrl}/assets/arrow-right.png`}
              />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  </>
);

export const Checklist = ({ checklist, options, leaseLink }) => {
  if (!checklist.type) {
    return (
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody>
          {options.map((option, index) => (
            <tr key={option.id}>
              <td>
                <div
                  style={{
                    backgroundColor: '#e7f8fd',
                    border: '2px solid #e6e9f0',
                    borderRadius: '4px',
                    width: '100%',
                    ...(index === 0 ? { marginBottom: '8px' } : {}),
                  }}
                >
                  <table
                    style={{
                      width: '100%',
                      borderCollapse: 'collapse',
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            paddingLeft: '12px',
                            width: '1px',
                            paddingRight: '12px',
                          }}
                        >
                          <img src={option.icon} />
                        </td>
                        <td
                          style={{
                            paddingTop: '16px',
                            paddingBottom: '16px',
                          }}
                        >
                          <table>
                            <tbody>
                              <tr>
                                <td>{option.title}</td>
                              </tr>
                              <tr>
                                <td>
                                  <a
                                    href={leaseLink}
                                    style={{
                                      textDecoration: 'none',
                                    }}
                                  >
                                    <table
                                      style={{
                                        borderCollapse: 'collapse',
                                        borderSpacing: '0px',
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              textTransform: 'uppercase',
                                              color: '#033A6D',
                                              textDecoration: 'none',
                                              fontSize: '12px',
                                              fontWeight: 600,
                                              letterSpacing: '0.25px',
                                              lineHeight: '18px',
                                            }}
                                          >
                                            {option.action}
                                          </td>
                                          <td>
                                            <img
                                              style={{
                                                width: 13.5,
                                                height: 13.5,
                                                display: 'block',
                                              }}
                                              src={`${config.assetsBaseUrl}/assets/arrowv2.png`}
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
  return (
    <>
      {options
        .find((option) => option.id === checklist.type)
        .checkList.map((checkItem) => {
          return (
            <ChecklistItem
              key={checkItem.id}
              {...checkItem}
              checked={checklist.completed?.some(
                (complete) => complete === checkItem.id,
              )}
              skipped={checklist.skipped?.some((skip) => skip === checkItem.id)}
            />
          );
        })}
    </>
  );
};

ThumbsHolder.propTypes = {
  style: PropTypes.object,
};
