import moment from 'moment';

import isDate from './isDate';
const dateFormat = 'MM/DD/YYYY';

// This validation is not for general use. It is intended to be used on lease
// forms with a start date, end date, and month-to-month fields. The fields must
// be named start_date, end_date, and month_to_month, and you can only have one
// of each of these fields on the form. You should use both this validation
// and validLeaseStartDate on the form. Look for existing forms where this
// validation is already used as a guide. Also see the tests, which do a good
// job of documenting all the various scenariros.

const validLeaseEndDate = (value, allValues) => {
  // Allow template value only while field has focus. Prevents error when first
  // clicking on field.
  if (value === '__/__/____') return undefined;

  // Month to month is true, so end date is ignored - any value is fine.
  if (allValues?.month_to_month) return undefined;

  // If the end date is valid and a valid start date is specified, the end date
  // must come after it.
  if (
    isDate(value) === undefined &&
    allValues?.start_date &&
    isDate(allValues?.start_date) === undefined
  ) {
    const startDate = moment(allValues.start_date, dateFormat, true);
    const endDate = moment(value, dateFormat, true);

    if (endDate <= startDate) return 'Must be after start date.';
  }

  // No valid start date and it's not month-to-month, so it only has to be a
  // valid date.
  return isDate(value);
};

export default validLeaseEndDate;
