import React from 'react';
import queryString from 'query-string';

import { logout } from '../../core/auth/authService';
import { useConfig } from '../../providers/ConfigProvider';

import AccountBlocklistedModal from './AccountBlocklistedModal';

const AccountBlocklistedModalWrapped = (props) => {
  const config = useConfig();
  const query = queryString.parse(props.location.search);
  return (
    <AccountBlocklistedModal
      {...props}
      /**
       * NOTE: Calling logout with `false` as its parameter
       * will make sure the `redirectTo` url isn't set
       * to the blocklisted route so there is no way for users to be
       * redirected back to the blocklisted modalafter loging in
       */
      onDone={() => logout(false)}
      userType={config.IS_OWNER ? 'owner' : 'renter'}
      accountClosed={query.closed === 'true'}
    />
  );
};

export default AccountBlocklistedModalWrapped;
