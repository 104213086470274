import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { CometChat } from '@cometchat-pro/chat';

import { logout } from '../../../core/auth/authService';
import logoutGQL from '../../../graphql/mutations/logout.graphql';

const LogoutComponent = () => {
  const [logoutMutation] = useMutation(logoutGQL);

  useEffect(() => {
    const handleLogout = async () => {
      try {
        await CometChat.logout();
        console.log('Messaging Logout completed successfully');
      } catch (error) {
        console.log('Logout failed with exception:', error);
      }

      try {
        await logoutMutation();
      } catch (e) {
        // no need to handle this error
      }

      logout();
    };

    handleLogout();
  }, []);

  return null;
};

export default LogoutComponent;
