import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import * as colors from '../../../../constants/colors';
import parseScreeningReportDate from '../../../../helpers/parseScreeningReportDate';
import toCurrency from '../../../../helpers/toCurrency';
import CheckCircleFilled from '../../../../icons/CheckCircleFilled';

import styles from './TradelinesSection.module.scss';

const TradelinesSection = (props) => {
  const renderTradelineCalendar = (pattern, startDate) => {
    const history = {};
    if (pattern && startDate && moment(startDate) !== 'Invalid date') {
      const date = moment(startDate);
      const patternArray = pattern.split('');
      for (let i = 0; i < 12; i++) {
        if (i <= date.month()) {
          patternArray.push(null);
        } else {
          patternArray.unshift(null);
        }
      }
      const yearsCount = Math.ceil(patternArray.length / 12);
      for (let i = 0; i < yearsCount; i++) {
        history[date.year() - i] = patternArray.slice(i * 12, i * 12 + 12);
      }
      // Remove years with no data
      for (const year in history) {
        if (history[year].filter((el) => !!el).length === 0) {
          delete history[year];
        }
      }
    }

    if (Object.keys(history).length === 0) {
      return (
        <div className={cx(styles.calendarContainer)}>
          No payments information
        </div>
      );
    }

    return (
      <div className={cx(styles.calendarContainer)}>
        <div className={cx(styles.calendar, styles.relativeOnPrint)}>
          <div className={cx(styles.calendarRow, styles.calendarHeader)}>
            <div className={cx(styles.calendarMainColumn)}>PAYMENT HISTORY</div>
            {[
              'Dec',
              'Nov',
              'Oct',
              'Sep',
              'Aug',
              'Jul',
              'Jun',
              'May',
              'Apr',
              'Mar',
              'Feb',
              'Jan',
            ].map((month, idx) => (
              <div key={idx} className={cx(styles.calendarCell)}>
                {month.toUpperCase()}
              </div>
            ))}
          </div>
          {Object.keys(history)
            .sort((a, b) => parseInt(a) < parseInt(b))
            .map((year, idx) => {
              return (
                <div
                  key={idx}
                  className={cx(styles.calendarRow, styles.avoidPageBreak)}
                >
                  <div className={cx(styles.calendarMainColumn)}>{year}</div>
                  {history[year].map((month, index) => {
                    let content = null;
                    let color = colors.text;
                    switch (month) {
                      case '1':
                        content = (
                          <CheckCircleFilled
                            color={colors.white}
                            fill={colors.secondary}
                          />
                        );
                        break;
                      case '2':
                        content = '30';
                        color = colors.secondary;
                        break;
                      case '3':
                        content = '60';
                        color = colors.primary;
                        break;
                      case '4':
                        content = '90';
                        color = colors.info;
                        break;
                      case '5':
                        content = '120';
                        color = colors.errorText;
                        break;
                      case 'X':
                        content = 'N/A';
                        break;
                    }
                    return (
                      <div
                        key={index}
                        className={cx(styles.calendarCell)}
                        style={{ color }}
                      >
                        {content}
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const renderTradeline = (tradeline, key) => {
    return (
      <div
        key={key || 0}
        className={cx(styles.hideSmall)}
        style={{ marginTop: 28 }}
      >
        <span className={cx(styles.iconFooter)}>
          {tradeline.subscriberName}
        </span>
        <div className={cx(styles.tradelineContainer)}>
          <div className={cx(styles.tradelineRow)}>
            <div className={cx(styles.tradelineItem)}>
              <span className={cx(styles.sectionTitle)}>Industry</span>
              <span>{tradeline.industry}</span>
            </div>
            <div className={cx(styles.tradelineItem)}>
              <span className={cx(styles.sectionTitle)}>Open</span>
              <span>{parseScreeningReportDate(tradeline.dateOpened)}</span>
            </div>
            <div className={cx(styles.tradelineItem)}>
              <span className={cx(styles.sectionTitle)}>Balance</span>
              <span>{toCurrency(tradeline.balanceAmount)}</span>
            </div>
          </div>
        </div>
        <div className={cx(styles.tradelineContainer)}>
          <div className={cx(styles.tradelineRow)}>
            <div className={cx(styles.tradelineItem)}>
              <span className={cx(styles.sectionTitle)}>Loan Type</span>
              <span>{tradeline.loanType}</span>
            </div>
            <div className={cx(styles.tradelineItem)}>
              <span className={cx(styles.sectionTitle)}>Verified</span>
              <span>{parseScreeningReportDate(tradeline.dateVerified)}</span>
            </div>
            <div className={cx(styles.tradelineItem)}>
              <span className={cx(styles.sectionTitle)}>Past Due</span>
              <span>{toCurrency(tradeline.amountPastDue)}</span>
            </div>
          </div>
        </div>
        <div className={cx(styles.tradelineContainer)}>
          <div className={cx(styles.tradelineRow)}>
            <div className={cx(styles.tradelineItem)}>
              <span className={cx(styles.sectionTitle)}>Loan Terms</span>
              <span>{tradeline.terms || 'N/A'}</span>
            </div>
            <div className={cx(styles.tradelineItem)}>
              <span className={cx(styles.sectionTitle)}>Closed</span>
              <span>{parseScreeningReportDate(tradeline.dateClosed)}</span>
            </div>
            <div className={cx(styles.tradelineItem)}>
              <span className={cx(styles.sectionTitle)}>Credit Limit</span>
              <span>{toCurrency(tradeline.creditLimit)}</span>
            </div>
          </div>
        </div>
        <div className={cx(styles.tradelineContainer)}>
          <div className={cx(styles.tradelineRow)}>
            <div className={cx(styles.tradelineItem)}>
              <span className={cx(styles.sectionTitle)}>Account Type</span>
              <span>{tradeline.accountType}</span>
            </div>
            <div className={cx(styles.tradelineItem)}>
              <span className={cx(styles.sectionTitle)}>Paid</span>
              <span>{parseScreeningReportDate(tradeline.datePaidOut)}</span>
            </div>
            <div className={cx(styles.tradelineItem)}>
              <span className={cx(styles.sectionTitle)}>Payment Amount</span>
              <span>{toCurrency(tradeline.termsAmountOfPayment)}</span>
            </div>
          </div>
        </div>
        <div
          className={cx(styles.tradelineContainer)}
          style={{ marginTop: 24 }}
        >
          <span className={cx(styles.italicSpan)}>
            Payment History Summary (
            {(tradeline.paymentPattern || '').length || 48} months)
          </span>
          <div
            className={cx(styles.tradelinesContent)}
            style={{ marginTop: 8 }}
          >
            <div className={cx(styles.resultBlock)}>
              <span className={cx(styles.iconNumber)}>
                {((tradeline.paymentPattern || '').match(/5/g) || '').length}
              </span>
              <span>120 Days</span>
            </div>
            <div className={cx(styles.resultBlock)}>
              <span className={cx(styles.iconNumber)}>
                {((tradeline.paymentPattern || '').match(/4/g) || '').length}
              </span>
              <span>90 Days</span>
            </div>
            <div className={cx(styles.resultBlock)}>
              <span className={cx(styles.iconNumber)}>
                {tradeline.times60DaysLate || 0}
              </span>
              <span>60 Days</span>
            </div>
            <div className={cx(styles.resultBlock)}>
              <span className={cx(styles.iconNumber)}>
                {tradeline.times30DaysLate || 0}
              </span>
              <span>30 Days</span>
            </div>
          </div>
        </div>
        {renderTradelineCalendar(
          tradeline.paymentPattern,
          tradeline.paymentPatternStartDate,
        )}
      </div>
    );
  };

  const { report } = props;

  return (
    <div className={cx(styles.flexColumn, styles.breakPageBefore)}>
      <span className={cx(styles.iconNumber)}>Tradelines</span>
      <div className={cx(styles.flexColumn)}>
        <div style={{ marginTop: 12 }}>
          <div className={cx(styles.tradelinesContent, styles.hideSmall)}>
            <div className={cx(styles.resultBlock)}>
              <span className={cx(styles.iconNumber)}>
                {(report.tradelines && report.tradelines.length) || 0}
              </span>
              <span>Count</span>
            </div>
            <div className={cx(styles.resultBlock)}>
              <span className={cx(styles.iconNumber)}>
                {toCurrency(report.tradeline_totals.balanceAmount)}
              </span>
              <span>Balance Total</span>
            </div>
            <div className={cx(styles.resultBlock)}>
              <span className={cx(styles.iconNumber)}>
                {toCurrency(report.tradeline_totals.creditLimit)}
              </span>
              <span>Total Credit Limit</span>
            </div>
            <div className={cx(styles.resultBlock)}>
              <span className={cx(styles.iconNumber)}>
                {toCurrency(report.tradeline_totals.amountPastDue)}
              </span>
              <span>Total Past Due</span>
            </div>
          </div>

          <div className={cx(styles.tradelinesContent)}>
            <div className={cx(styles.hideLarge, styles.noPrint)}>
              <div className={cx(styles.resultBlock)}>
                <span className={cx(styles.iconNumber)}>
                  {(report.tradelines && report.tradelines.length) || 0}
                </span>
                <span>Count</span>
              </div>
              <div className={cx(styles.resultBlock)}>
                <span className={cx(styles.iconNumber)}>
                  {toCurrency(report.tradeline_totals.balanceAmount)}
                </span>
                <span>Balance Total</span>
              </div>
            </div>
            <div className={cx(styles.hideLarge, styles.noPrint)}>
              <div className={cx(styles.resultBlock)}>
                <span className={cx(styles.iconNumber)}>
                  {toCurrency(report.tradeline_totals.creditLimit)}
                </span>
                <span>Total Credit Limit</span>
              </div>
              <div className={cx(styles.resultBlock)}>
                <span className={cx(styles.iconNumber)}>
                  {toCurrency(report.tradeline_totals.amountPastDue)}
                </span>
                <span>Total Past Due</span>
              </div>
            </div>
          </div>
        </div>
        {report.tradelines &&
          report.tradelines.map((tradeline, idx) =>
            renderTradeline(tradeline, idx),
          )}
      </div>
    </div>
  );
};

TradelinesSection.propTypes = {
  report: PropTypes.object,
};

export default TradelinesSection;
