import React, { memo } from 'react';
import PropTypes from 'prop-types';

const MoneyCreditCardAmex = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 23" {...props}>
    <g>
      <path
        d="M27.8 0.3L2.2 0.3C1.2 0.3 0.3 1.2 0.3 2.3L0.3 20.4C0.3 21.5 1.2 22.3 2.2 22.3L27.8 22.3C28.8 22.3 29.7 21.5 29.7 20.4L29.7 2.3C29.7 1.2 28.8 0.3 27.8 0.3Z"
        fill="#007AC6"
      />
      <path
        d="M6.4 11.6L7.9 11.6 7.2 9.8 6.4 11.6ZM27.1 8.1L23.3 8.1 22.4 9.1 21.5 8.1 13.5 8.1 12.7 9.8 12 8.1 8.7 8.1 8.7 8.8 8.4 8.1 5.6 8.1 2.9 14.6 6.1 14.6 6.5 13.6 7.4 13.6 7.8 14.6 11.4 14.6 11.4 13.8 11.7 14.6 13.6 14.6 13.8 13.7 13.8 14.6 21.3 14.6 22.2 13.6 23 14.6 26.8 14.6 24.4 11.3 27.1 8.1ZM15.9 13.6L14.9 13.6 14.9 10 13.3 13.6 12.4 13.6 10.8 10 10.8 13.6 8.6 13.6 8.3 12.6 6 12.6 5.7 13.6 4.5 13.6 6.4 9 8 9 9.8 13.4 9.8 9 11.5 9 12.9 12.2 14.2 9 16 9 16 13.6 15.9 13.6 15.9 13.6ZM25.1 13.6L23.7 13.6 22.5 12.1 21.1 13.6 16.9 13.6 16.9 9 21.2 9 22.5 10.5 23.9 9 25.2 9 23.1 11.3 25.1 13.6ZM17.9 9.9L17.9 10.8 20.2 10.8 20.2 11.7 17.9 11.7 17.9 12.6 20.5 12.6 21.7 11.2 20.6 9.9 17.9 9.9Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

MoneyCreditCardAmex.defaultProps = {
  width: 32,
  height: 23,
};

MoneyCreditCardAmex.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(MoneyCreditCardAmex);
