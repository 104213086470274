function getListingFullAddress(listing) {
  if (!listing) {
    return '';
  }
  const { address, unit, state, zip, city } = listing;
  if (address) {
    const unitString = !unit ? '' : ` Unit# ${unit.trim()}`;
    return `${address}${unitString} ${city}, ${state} ${zip}`;
  }
  return '';
}

export default getListingFullAddress;
