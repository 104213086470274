import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Block = (props) => (
  <svg {...props} viewBox="0 0 20 20" fill={props.color}>
    <path
      fill={props.color}
      d="M10 0a10 10 0 110 20 10 10 0 010-20zm0 1a9 9 0 100 18 9 9 0 000-18zm5 5h1l1 4a7 7 0 01-11 6v-1zm0 2l-7 7h2a5 5 0 005-7zm-5-5l4 1v1l-9 9H4l-1-4c0-4 3-7 7-7zm0 2a5 5 0 00-5 7l7-7h-2z"
    />
  </svg>
);

Block.defaultProps = {
  width: 20,
  height: 20,
  color: colors.primary,
};

Block.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Block);
