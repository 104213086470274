import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Payments = ({ color, secondaryColor, strokeWidth, ...rest }) => (
  <svg viewBox="0 0 40 40" {...rest} stroke={color} strokeWidth={strokeWidth}>
    {secondaryColor && (
      <path
        d="M25.416 33.333c6.214 0 11.25-6.703 11.25-12.916 0-6.214-5.036-12.917-11.25-12.917-6.213 0-11.25 6.704-11.25 12.917s5.037 12.916 11.25 12.916Z"
        fill={secondaryColor}
      />
    )}
    <path
      d="M20 3.334c9.205 0 16.667 7.463 16.667 16.667 0 9.206-7.46 16.666-16.667 16.666-9.204 0-16.666-7.461-16.666-16.666 0-9.204 7.463-16.667 16.666-16.667Zm0 1.45C11.598 4.783 4.783 11.597 4.783 20c0 8.404 6.813 15.217 15.217 15.217 8.406 0 15.218-6.812 15.218-15.217 0-8.404-6.813-15.218-15.218-15.218Zm0 5.072c.4 0 .725.324.725.724v1.51a4.35 4.35 0 0 1 3.623 4.288.725.725 0 1 1-1.45 0 2.9 2.9 0 0 0-2.173-2.808v5.766a4.35 4.35 0 0 1 0 8.576v1.509a.725.725 0 1 1-1.45 0v-1.51a4.348 4.348 0 0 1-3.623-4.287.725.725 0 0 1 1.45 0 2.9 2.9 0 0 0 2.173 2.807v-5.766a4.348 4.348 0 0 1 0-8.575v-1.51c0-.4.325-.724.725-.724Zm.725 10.96v5.615a2.9 2.9 0 0 0 0-5.614Zm-1.45-7.246a2.9 2.9 0 0 0 0 5.615V13.57Z"
      fill={color}
    />
  </svg>
);

Payments.defaultProps = {
  width: 32,
  height: 32,
  color: colors.text,
  secondaryColor: '',
  strokeWidth: 0,
};

Payments.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
  strokeWidth: PropTypes.number,
};

export default memo(Payments);
