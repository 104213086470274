import React from 'react';
import useWindowSize from '@rooks/use-window-size';
import pathToRegexp from 'path-to-regexp';
import { object, string } from 'prop-types';
import queryString from 'query-string';

import { mobileBreakpoint } from '../../../constants/media-breakpoints';

import OwnerHeaderAccountSection from './OwnerHeaderAccountSection';
import OwnerHeaderRenterSection from './OwnerHeaderRenterSection';
import RenterHeaderSettings from './RenterHeaderSettings';

const HeaderComponent = (props) => {
  const { pathname } = props;
  const { successNotif } = queryString.parse(window.location.search);
  const { innerWidth } = useWindowSize();

  const isSmallScreen = innerWidth <= mobileBreakpoint;

  const getHeaderComponent = () => {
    if (
      pathToRegexp('/owners/renters/:type').test(pathname) ||
      pathToRegexp('/owners/renters/tenants/archived').test(pathname) ||
      pathToRegexp('/owners/renters/tenants/past').test(pathname) ||
      pathToRegexp('/owners/archived/:renterType').test(pathname)
    ) {
      return OwnerHeaderRenterSection;
    }

    if (
      [
        'account(.*)',
        'rent-payments(.*)',
        'billing(.*)',
        'advanced',
        'application',
        'advanced/email-preferences(.*)',
        'pre-screener',
        'maintenance',
      ].some((item) =>
        pathToRegexp(`/owners/settings/${item}`).test(pathname),
      ) &&
      !isSmallScreen
    ) {
      return OwnerHeaderAccountSection;
    }

    if (
      ['account', 'payment-methods', 'enroll-autopay', 'billing'].some((item) =>
        pathToRegexp(`/renters/settings/${item}`).test(pathname),
      )
    ) {
      return RenterHeaderSettings;
    }
    if (
      pathToRegexp('/renters/(.*)').test(pathname) ||
      pathToRegexp('/esignature/welcome/(.*)').test(pathname) ||
      pathToRegexp('/esignature/sign/(.*)').test(pathname) ||
      pathToRegexp('/owners/(.*)').test(pathname) ||
      pathToRegexp('/auth/logout').test(pathname)
    ) {
      return null;
    }

    return null;
  };

  const HeaderComponent = getHeaderComponent();
  const onlyLogo = pathToRegexp('/onboarding/(.*)').test(pathname);

  if (!HeaderComponent) {
    return null;
  }
  return (
    <HeaderComponent
      {...props}
      onlyLogo={onlyLogo}
      successNotification={successNotif}
    />
  );
};

HeaderComponent.propTypes = {
  pathname: string,
  styles: object,
};

export default HeaderComponent;
