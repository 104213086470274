import React from 'react';
import PropTypes from 'prop-types';

import LeaseColored from '../../../../icons/LeaseColored';
import MonthlyIncome from '../../../../icons/MonthlyIncome';
import PeopleSilhouetteBlue from '../../../../icons/PeopleSilhouetteBlue';
import PetsColored from '../../../../icons/PetsColored';
import Scale from '../../../../icons/Scale';
import UtilitiesColor from '../../../../icons/UtilitiesColor';
import { segmentTracking } from '../../../../services/utilities';

import SectionCard from './SectionCard/SectionCard';

import styles from './OverviewPage.module.scss';

const getLeaseAgreementSections = (stateFullName) => [
  {
    id: 1,
    title: 'Rent, Deposit, & Fees',
    slug: 'rent-deposit-fees',
    description: 'Set the rent amount, security deposit, and fees.',
    icon: <MonthlyIncome />,
  },
  {
    id: 2,
    title: 'Lease Specifics',
    slug: 'lease-specifics',
    description: `Start here to verify the ${stateFullName} rental address and lease terms.`,
    icon: <LeaseColored />,
  },
  {
    id: 3,
    title: 'Utilities, Services, & Keys',
    slug: 'utilities-services-keys',
    description: 'Choose what is included with the lease.',
    icon: <UtilitiesColor />,
  },
  {
    id: 4,
    title: 'Pets, Smoking, & Insurance',
    slug: 'pets-smoking-insurance',
    description:
      'Specify pets, smoking policy, and if renters insurance is required.',
    icon: <PetsColored />,
  },
  {
    id: 5,
    title: 'People on the Lease',
    slug: 'people-on-the-lease',
    description: 'Enter the landlord and tenant info.',
    icon: <PeopleSilhouetteBlue />,
  },
  {
    id: 6,
    title: 'Provisions & Attachments',
    slug: 'additional-provisions',
    description:
      'Add custom clauses, rules, or attachments specific to your property and/or local area.',
    icon: <Scale />,
  },
];

const OverviewPage = ({
  onSectionSelect,
  startedSections,
  stateFullName,
  segmentData = {},
}) => {
  const leaseAgreementSections = getLeaseAgreementSections(stateFullName);

  return (
    <div className={styles.container}>
      {leaseAgreementSections.map((section) => (
        <SectionCard
          id={section.id}
          key={section.id}
          title={section.title}
          description={section.description}
          onStart={() => {
            segmentTracking(`start_${section.slug.replace(/-/g, '_')}`, {
              location: 'Builder Summary Page - Public Lease Agreement',
              ...segmentData,
            });
            onSectionSelect(section.id);
          }}
          className={styles.sectionCard}
          icon={section.icon}
          started={startedSections.includes(section.id)}
        />
      ))}
    </div>
  );
};

OverviewPage.propTypes = {
  onSectionSelect: PropTypes.func.isRequired,
  startedSections: PropTypes.arrayOf(PropTypes.number).isRequired,
  stateFullName: PropTypes.string,
  segmentData: PropTypes.object,
};

export default OverviewPage;
