import getFormattedDate from './getFormattedDate';

const parseScreeningReportDate = (date) => {
  const d = getFormattedDate(date);
  if (!d) {
    return 'N/A';
  }
  return d !== 'Invalid date' ? d : 'N/A';
};

export default parseScreeningReportDate;
