import React from 'react';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { H4, Span } from '../../../../../components/Typography';
import totalMoneyMovedGql from '../../../../../graphql/queries/totalMoneyMoved.graphql';

import styles from './SocialProof.module.scss';

const SocialProof = ({ className }) => {
  const { data, loading: loadingTotalMoneyMoved } =
    useQuery(totalMoneyMovedGql);
  const totalMoneyMoved = data?.totalMoneyMoved || '1.7 billion';

  return (
    <div className={cx(className, styles.socialProofContainer)}>
      <H4 align="center">
        Landlords have collected{' '}
        <Span weight="semi" color="primary">
          over {loadingTotalMoneyMoved ? '...' : `$${totalMoneyMoved}`}
        </Span>{' '}
        and counting through TurboTenant.
      </H4>
    </div>
  );
};

SocialProof.propTypes = {
  className: PropTypes.string,
};

export default SocialProof;
