import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RadioGroup from '../../../../../../../components/RadioGroup';
import WaterThickIcon from '../../../../../../../icons/WaterThick';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './FloodDisclosure.module.scss';

const FloodDisclosure = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <>
      <div className={cx(styles.container, className)}>
        <SectionTitle title="Flood Disclosure" icon={WaterThickIcon} />
        <label className={styles.label}>
          Do you know if the property is located in a 100-year floodplain?
        </label>
        <Field
          component={RadioGroup}
          id="custom_state_data.flood_zone"
          name="custom_state_data.flood_zone"
          radiosContainerClassName={styles.radioButtonsContainer}
          options={[
            {
              'id': 'flood_zone_yes',
              'value': true,
              'label': 'Yes',
              'data-qa': 'lease-agreements-flood-zone-radio-yes',
            },
            {
              id: 'flood_zone_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />
        <label className={styles.label}>
          Has the property flooded 1 or more times in the last 5 years?
        </label>
        <p className={styles.description}>
          Texas Code defines flooding as “the inundation of a partial or
          complete portion of the dwelling covered by the lease which was caused
          by the overflow of inland or tidal waters, the unusual and rapid
          accumulation of runoff or surface waters from any established water
          source such as a river, stream or drainage ditch, or excessive
          rainfall”.
        </p>
        <Field
          component={RadioGroup}
          id="custom_state_data.has_property_flooded"
          name="custom_state_data.has_property_flooded"
          radiosContainerClassName={styles.radioButtonsContainer}
          options={[
            {
              'id': 'has_property_flooded_yes',
              'value': true,
              'label': 'Yes',
              'data-qa': 'lease-agreements-has-flooded-radio-yes',
            },
            {
              id: 'has_property_flooded_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />
      </div>
    </>
  );
};

FloodDisclosure.propTypes = {
  className: PropTypes.string,
};

export default FloodDisclosure;
