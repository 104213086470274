import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const CreditCard = (props) => (
  <svg viewBox="0 0 60 38" {...props}>
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M53.222.333c3.374 0 6.111 2.788 6.111 6.223v24.888c0 3.435-2.737 6.223-6.11 6.223H6.777c-3.374 0-6.111-2.788-6.111-6.223V6.556C.667 3.12 3.404.333 6.777.333h46.445zm3.667 12.444H3.11v18.667c0 2.06 1.643 3.734 3.667 3.734h46.444c2.024 0 3.667-1.673 3.667-3.734V12.777zm-40.333 9.956c.675 0 1.222.557 1.222 1.245 0 .687-.547 1.244-1.222 1.244H9.222A1.233 1.233 0 018 23.978c0-.688.547-1.245 1.222-1.245zm34.222-4.977c.675 0 1.222.557 1.222 1.244 0 .687-.547 1.244-1.222 1.244h-7.334A1.233 1.233 0 0142.222 19c0-.687.547-1.244 1.222-1.244zm-22 0c.675 0 1.222.557 1.222 1.244 0 .687-.547 1.244-1.222 1.244H9.222A1.233 1.233 0 018 19c0-.687.547-1.244 1.222-1.244zM53.222 2.822H6.778c-2.024 0-3.667 1.673-3.667 3.734v3.732h53.778V6.556c0-2.06-1.643-3.734-3.667-3.734z"
    />
  </svg>
);

CreditCard.defaultProps = {
  width: 60,
  height: 38,
  color: colors.black,
};

CreditCard.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(CreditCard);
