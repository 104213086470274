import React, { useEffect, useState } from 'react';
import useWindowSize from '@rooks/use-window-size';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import VideoPlayerModal from '../../../components/VideoPlayerModal';
import { smallScreenWidth } from '../../../constants/media-breakpoints';
import MarketingLayout from '../../../layout/MarketingLayout';
import { segmentTracking } from '../../../services/utilities';
import InviteRenterHowItWorks from '../../onboard/steps/InviteRenterHowItWorks';

import InviteRentersToApplyForm from './InviteRentersToApplyForm';

import styles from './InviteRentersToApplyStep.module.scss';

const InviteRentersToApplyStep = ({
  onNext,
  initialValues,
  segmentData = {},
  isOnboardingExperiment,
}) => {
  const { innerWidth, innerHeight } = useWindowSize();
  const isSmallScreen = innerWidth < smallScreenWidth;
  const [introModal, setIntroModal] = useState(isSmallScreen);
  const [video, setVideo] = useState(false);

  useEffect(() => {
    segmentTracking('public_application started', {
      location: 'Invite Renters to Apply - Public Application',
      ...segmentData,
    });
  }, []);

  const howItWorksListItems = [
    'We send the renter a link to the application.',
    `They fill out the application and pay a fee, which covers the credit, background, and eviction check.`,
    "You'll get an email when they complete the application.",
    'You can then pull the screening report.',
  ];

  return (
    <MarketingLayout
      headerClassName={styles.header}
      side={
        <InviteRenterHowItWorks
          onPlayVideo={() => {
            setVideo(true);
            segmentTracking('play_video clicked', {
              location: 'Onboarding Invite to Apply',
              ...segmentData,
            });
          }}
          onViewAppClicked={() => {
            segmentTracking('try_application clicked', {
              location: 'Onboarding Invite to Apply',
              ...segmentData,
            });
          }}
          listItems={howItWorksListItems}
          isPublicFlow
        />
      }
    >
      {introModal && (
        <Modal
          containerClassName={styles.modalWrap}
          open={true}
          fullScreen
          className={styles.modal}
          style={{
            height: `${innerHeight}px`,
          }}
          autoFocus={false}
        >
          <InviteRenterHowItWorks
            onPlayVideo={() => {
              setVideo(true);
              segmentTracking('play_video clicked', {
                location: 'How It Works Modal - Public Application',
                ...segmentData,
              });
            }}
            onViewAppClicked={() => {
              segmentTracking('try_application clicked', {
                location: 'How It Works Modal - Public Application',
                ...segmentData,
              });
            }}
            listItems={howItWorksListItems}
            isPublicFlow
          />
          <Button
            onClick={() => {
              segmentTracking('sounds_good clicked', {
                location: 'How It Works Modal - Public Application',
                ...segmentData,
              });
              setIntroModal(false);
            }}
            id="how_it_works_sounds_good"
            className={styles.soundGood}
          >
            Sounds Good
          </Button>
        </Modal>
      )}
      {video && (
        <VideoPlayerModal
          mobileFullScreen
          className={styles.videoPlayerModal}
          open
          url="https://vimeo.com/696720478"
          onClose={() => setVideo(false)}
        />
      )}
      <div className={styles.content}>
        <h2 className={styles.title}>Invite renters to apply</h2>
        <InviteRentersToApplyForm
          className={styles.form}
          onSubmit={(data) => {
            onNext(data);
            segmentTracking('next clicked', {
              location: 'Invite Renters to Apply - Public Application',
              ...segmentData,
            });
          }}
          initialValues={initialValues}
          onAddAnother={() => {
            segmentTracking('add_another clicked', {
              location: 'Invite Renters to Apply - Public Application',
              ...segmentData,
            });
          }}
        />
        {!isOnboardingExperiment && (
          <a
            className={styles.link}
            href={`/auth/signup/personalize`}
            onClick={() => {
              segmentTracking('no_tenants clicked', {
                location: 'Invite Renters to Apply - Public Application',
                ...segmentData,
              });
            }}
          >
            I don't have this information
          </a>
        )}
      </div>
    </MarketingLayout>
  );
};

InviteRentersToApplyStep.propTypes = {
  onNext: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  segmentData: PropTypes.object,
  isOnboardingExperiment: PropTypes.bool,
};

export default InviteRentersToApplyStep;
