import React, { memo } from 'react';
import PropTypes from 'prop-types';

const UniversalMessagingBubble = (props) => (
  <svg
    {...props}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" fill="#E6E9F0" />
    <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="#ACB9C8" />
    <path
      d="M27.2014 26.4258H43.9722V37.5682H41.5764L40.7778 42.3435L35.1875 37.5682H27.2014V26.4258Z"
      fill="#7FE3FF"
    />
    <path
      d="M43.9722 25.6205C44.3557 25.6205 44.6666 25.9303 44.6666 26.3126V37.5308C44.6666 37.913 44.3557 38.2229 43.9722 38.2229L41.8894 38.2221L41.8889 43.1399C41.8889 43.7092 41.246 44.0229 40.7986 43.709L40.7297 43.6542L34.678 38.2221L27.3055 38.2229C26.9515 38.2229 26.6594 37.9588 26.6165 37.6176L26.6111 37.5308V26.3126C26.6111 25.9303 26.922 25.6205 27.3055 25.6205H43.9722ZM43.2782 27.0041H28V36.8381L34.9444 36.8387C35.0817 36.8387 35.2151 36.8792 35.3283 36.9541L35.4091 37.0165L40.5006 41.5847L40.5 37.5308C40.5 37.178 40.7649 36.8868 41.1073 36.8441L41.1944 36.8387L43.2782 36.8381V27.0041ZM38.4166 13C38.7707 13 39.0628 13.264 39.1057 13.6053L39.1111 13.6921V22.8069C39.1111 23.1891 38.8002 23.499 38.4166 23.499C38.0626 23.499 37.7705 23.2349 37.7276 22.8937L37.7222 22.8069L37.7223 14.3837H12.7225V29.8271L16.1944 29.8273C16.5485 29.8273 16.8406 30.0913 16.8835 30.4326L16.8889 30.5194L16.8889 34.4424L21.2558 30.0332C21.3645 29.9234 21.5062 29.8536 21.6579 29.8334L21.75 29.8273H23.1389C23.5224 29.8273 23.8333 30.1372 23.8333 30.5194C23.8333 30.8722 23.5684 31.1634 23.226 31.2061L23.1389 31.2115L22.0399 31.2111L16.6887 36.6147C16.2733 37.0341 15.5709 36.7749 15.505 36.2147L15.5 36.1285L15.5001 31.2111L12.0278 31.2115C11.6737 31.2115 11.3816 30.9475 11.3387 30.6062L11.3333 30.5194V13.6921C11.3333 13.3393 11.5982 13.0481 11.9406 13.0054L12.0278 13H38.4166Z"
      fill="#042238"
    />
  </svg>
);

UniversalMessagingBubble.defaultProps = {
  width: 56,
  height: 56,
};

UniversalMessagingBubble.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(UniversalMessagingBubble);
