import React from 'react';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';

import styles from './NonRefundableFeesMD.module.scss';

const NonRefundableFeesMD = () => {
  return (
    <HelpfulInfoCard
      noIcon
      title="Montgomery County, MD Non-Refundable Fees"
      className={styles.helpfulInfoCard}
      color="iceCold"
    >
      <p className={styles.description}>
        Montgomery County, MD does not allow non-refundable fees to be charged
        to tenants, such as pet fees or deposits, move in fees, cleaning fees
        unless such fees are part of certain condominium community bylaws.
      </p>
    </HelpfulInfoCard>
  );
};

export default NonRefundableFeesMD;
