import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RecurringIcon from '../../../../icons/Recurring';
import SectionTitle from '../../common/SectionTitle';

import styles from './SectionRentDepositFee.module.scss';

export const SectionRentDepositFeeRentSection = ({ children }) => {
  return (
    <div className={cx(styles.column, styles.section)}>
      <SectionTitle title="Monthly Rent" icon={RecurringIcon} />
      {children}
    </div>
  );
};

SectionRentDepositFeeRentSection.propTypes = {
  children: PropTypes.node,
};
