import { greyLight, line, text } from '../../../../../../constants/colors';

export const chatComposerStyle = (context) => {
  return {
    padding: '16px',
    backgroundColor: `${context.theme.backgroundColor.white}`,
    zIndex: '1',
    order: '3',
    position: 'relative',
    flex: 'none',
  };
};

export const editPreviewContainerStyle = (context, keyframes) => {
  const slideAnimation = keyframes`
    from {
        bottom: -60px
    }
    to {
        bottom: 0px
    }`;

  return {
    padding: '7px',
    backgroundColor: `${context.theme.backgroundColor.white}`,
    borderColor: `${context.theme.borderColor.primary}`,
    borderWidth: '1px 1px 1px 5px',
    borderStyle: 'solid',
    color: `${context.theme.color.helpText}`,
    fontSize: '13px',
    animation: `${slideAnimation} 0.5s ease-out`,
    position: 'relative',
  };
};

export const previewHeadingStyle = () => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
};

export const previewTextStyle = () => {
  return {
    padding: '5px 0',
  };
};

export const previewCloseStyle = (img, context) => {
  return {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    cursor: 'pointer',
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${context.theme.primaryColor}`,
  };
};

export const composerInputStyle = () => {
  return {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    zIndex: '2',
    padding: '0',
  };
};

export const inputInnerStyle = (context) => {
  return {
    flex: '1 1 auto',
    position: 'relative',
    outline: 'none',
    backgroundColor: `${context.theme.backgroundColor.white}`,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxWidth: 'calc(100% - 96px)',
  };
};

export const messageInputStyle = (disabled, isFromUniversalMessaging) => {
  const disabledState = disabled
    ? {
        pointerEvents: 'none',
        opacity: '0.4',
      }
    : {
        maxHeight: isFromUniversalMessaging ? '176px' : 'unset',
      };

  return {
    'width': '100%',
    'color': text,
    'fontFamily': 'Open Sans',
    'fontSize': '16px',
    'letterSpacing': 0,
    'lineHeight': '24px',
    'fontWeight': '400',
    'padding': '12px 16px',
    'outline': 'none',
    'overflowX': 'hidden',
    'overflowY': 'auto',
    'position': 'relative',
    'whiteSpace': 'pre-wrap',
    'wordWrap': 'break-word',
    'zIndex': '1',
    'minHeight': '48px',
    'maxHeight': '200px',
    'userSelect': 'text',
    'boxShadow': `inset 0 0 0 1px ${greyLight}`,
    'borderRadius': '4px',
    ...disabledState,
    '&:empty:before': {
      content: 'attr(placeholder)',
      pointerEvents: 'none',
      display: 'block' /* For Firefox */,
    },
  };
};

export const inputStickyStyle = (disabled, attachments, context) => {
  const disabledState = disabled
    ? {
        pointerEvents: 'none',
      }
    : {};

  const flexDirectionProp =
    attachments === null
      ? {
          flexDirection: 'row-reverse',
        }
      : {};

  return {
    'padding': '8px 16px 8px 0',
    'display': 'flex',
    'justifyContent': 'space-between',
    ...flexDirectionProp,
    ...disabledState,
    '&:empty:before': {
      pointerEvents: 'none',
    },
  };
};

export const stickyAttachmentStyle = () => {
  return {
    display: 'flex',
    width: 'auto',
  };
};

export const attachmentIconStyle = () => {
  return {
    margin: 'auto 0',
    width: '24px',
    height: '20px',
    cursor: 'pointer',
  };
};

export const filePickerStyle = (state) => {
  const active = state.showFilePicker
    ? {
        width: 'calc(100% - 20px)',
        opacity: '1',
      }
    : {};

  return {
    display: 'flex',
    width: '0',
    borderRadius: '8px',
    overflow: 'hidden',
    zIndex: '1',
    opacity: '0',
    ...active,
    [`@media screen and (max-width: 768px)`]: {
      display: 'none',
    },
  };
};

export const fileListStyle = () => {
  return {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 0 0 16px',
  };
};

export const fileItemStyle = (img, context) => {
  return {
    'height': '24px',
    'cursor': 'pointer',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'margin': '0 16px 0 0',
    ' > i': {
      width: '24px',
      height: '24px',
      display: 'inline-block',
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: `${context.theme.secondaryTextColor}`,
    },
    ' > input': {
      display: 'none',
    },
    '&:last-child': {
      marginRight: 0,
    },
  };
};

export const stickyAttachButtonStyle = (img, context) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '24px',
    i: {
      width: '24px',
      height: '24px',
      display: 'inline-block',
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: `${context.theme.secondaryTextColor}`,
    },
  };
};

export const stickyButtonStyle = (state) => {
  // const active = state.showFilePicker
  //   ? {
  //       display: 'none',
  //     }
  //   : {
  //       display: 'flex',
  //     };

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: 'auto',
    // ...active,
  };
};

export const emojiButtonStyle = (img, context) => {
  return {
    height: '24px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0 0 16px',
    i: {
      width: '24px',
      height: '24px',
      display: 'inline-block',
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: `${context.theme.secondaryTextColor}`,
    },
  };
};

export const sendButtonStyle = () => {
  return {
    height: '44px',
    minWidth: '80px',
    width: 'auto',
    cursor: 'pointer',
    display: 'flex',
    flexShrink: 1,
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0 0 16px',
  };
};

export const reactionBtnStyle = () => {
  return {
    cursor: 'pointer',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0 0 16px',
  };
};

export const stickerBtnStyle = (img, context) => {
  return {
    cursor: 'pointer',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0 0 16px',
    i: {
      width: '24px',
      height: '24px',
      display: 'inline-block',
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: `${context.theme.secondaryTextColor}`,
    },
  };
};

export const attachmentModalStyle = () => {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px',
    borderRadius: '4px 4px 0 0',
  };
};

export const fileItemModalStyle = (img, context) => {
  return {
    'cursor': 'pointer',
    'width': '100%',
    'display': 'flex',
    'padding': '16px 0',
    'borderBottom': `1px solid ${line}`,
    'alignItems': 'center',
    'fontFamily': 'Open Sans',
    'fontSize': '14px',
    'fontWeight': 'bold',
    'letterSpacing': 0,
    'lineHeight': '21px',
    'color': text,
    ' > i': {
      width: '24px',
      height: '24px',
      display: 'inline-block',
      mask: `url(${img}) center center no-repeat`,
      backgroundColor: text,
      marginRight: '8px',
    },
    ' > input': {
      display: 'none',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  };
};
