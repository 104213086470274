import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const ArrowLeft = (props) => (
  <svg {...props} viewBox="0 0 9 13" fill={props.color}>
    <path d="M8.717.695A.89.89 0 007.55.543L7.46.61 1.226 5.955a.89.89 0 00-.08 1.26l.08.08L7.46 12.64a.89.89 0 001.253-1.262l-.08-.079-5.468-4.673 5.468-4.673A.89.89 0 008.785.783L8.717.695z" />
  </svg>
);

ArrowLeft.defaultProps = {
  width: 9,
  height: 13,
  color: colors.primary,
};

ArrowLeft.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ArrowLeft);
