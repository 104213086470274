import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Loader.module.scss';

const Loader = ({ className, loadingMessage, withoutTitle }) => (
  <div className={cx(className, styles.container)}>
    <div className={styles.animation} />
    {!withoutTitle && <span className={styles.message}>{loadingMessage}</span>}
  </div>
);

Loader.propTypes = {
  className: PropTypes.string,
  loadingMessage: PropTypes.string,
};

Loader.defaultProps = {
  loadingMessage: 'Loading...',
};

export default Loader;
