import React, { memo } from 'react';
import PropTypes from 'prop-types';

const LeaseColor = (props) => (
  <svg {...props} viewBox="0 0 34 40">
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.color}
        d="m16.97 38.424.97-4.848 10.666-9.697 3.879 3.88-10.667 9.695zM18.909 1.576l7.758 7.757h-7.758z"
      />
      <path
        d="m29.949 23.222 3.202 3.557c.443.491.412 1.24-.068 1.672l-10.906 9.82a1.15 1.15 0 0 1-.568.277l-4.463.798a1.212 1.212 0 0 1-1.368-1.52l1.26-4.355a1.15 1.15 0 0 1 .335-.535l10.906-9.82c.48-.432 1.228-.385 1.67.106ZM19.712.606c.2 0 .39.082.53.226l6.931 7.18.032.034a.762.762 0 0 1 .033.043l-.065-.077a.758.758 0 0 1 .208.431l.007.104v11.965c0 .42-.333.761-.745.761a.753.753 0 0 1-.744-.761V9.307l-7.342.001a.753.753 0 0 1-.744-.761l-.001-6.419H1.974v34.374H12.78c.411 0 .745.34.745.761 0 .42-.334.761-.745.761H1.23a.753.753 0 0 1-.744-.761V1.367c0-.42.333-.76.744-.76h18.483ZM18.5 33.97l-.04.038-1.089 3.76 3.854-.689.006-.006-2.73-3.103Zm7.804-7.026-6.687 6.02 2.732 3.102 6.686-6.02-2.73-3.102ZM11.57 29.322c.411 0 .745.341.745.762 0 .42-.334.761-.745.761H5.85a.753.753 0 0 1-.744-.761c0-.42.333-.762.744-.762h5.72Zm17.492-4.86-1.64 1.475 2.723 3.093.007.01 1.676-1.507-2.766-3.072Zm-17.492.074c.411 0 .745.341.745.762 0 .42-.334.761-.745.761H5.85a.753.753 0 0 1-.744-.761c0-.42.333-.762.744-.762h5.72Zm6.931-7.179c.412 0 .745.341.745.762 0 .42-.333.761-.745.761H5.85a.753.753 0 0 1-.744-.761c0-.42.333-.762.744-.762H18.5Zm0-4.786c.412 0 .745.341.745.762 0 .42-.333.761-.745.761H5.85a.753.753 0 0 1-.744-.761c0-.42.333-.762.744-.762H18.5Zm-6.93-4.786c.41 0 .744.341.744.762 0 .42-.334.761-.745.761H5.85a.753.753 0 0 1-.744-.761c0-.42.333-.762.744-.762h5.72ZM19.4 2.13h-.1v5.656h5.56l-5.46-5.656Z"
        fill="#042238"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

LeaseColor.defaultProps = {
  width: 34,
  height: 40,
  color: '#7FE3FF',
};

LeaseColor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(LeaseColor);
