import React, { memo } from 'react';

const Document = (props) => (
  <svg {...props} viewBox="0 0 20 24">
    <path
      fill={props.color}
      d="M15.057 0c.177 0 .347.067.472.185l3.61 3.42c.124.118.194.279.194.446v19.317c0 .35-.298.632-.666.632H1.333c-.368 0-.666-.283-.666-.632V.632C.667.282.965 0 1.333 0h13.724zM13 1.894H2.667v20.211h14.666V6h-2.666c-.863 0-1.573-.621-1.658-1.418L13 4.422V1.893zm-.333 16.422c.368 0 .666.298.666.666v.562a.667.667 0 01-.666.667h-8A.667.667 0 014 19.544v-.562c0-.368.298-.666.667-.666h8zm0-3.158c.368 0 .666.298.666.667v.561a.667.667 0 01-.666.667h-8A.667.667 0 014 16.386v-.561c0-.369.298-.667.667-.667h8zm2.666-4.421c.369 0 .667.298.667.667v.56a.667.667 0 01-.667.668H4.667A.667.667 0 014 11.965v-.561c0-.369.298-.667.667-.667h10.666zm0-3.158c.369 0 .667.298.667.667v.561a.667.667 0 01-.667.667H4.667A.667.667 0 014 8.807v-.561c0-.369.298-.667.667-.667h10.666zM8.667 4.42c.368 0 .666.299.666.667v.561a.667.667 0 01-.666.667h-4A.667.667 0 014 5.649v-.561c0-.368.298-.667.667-.667h4zM15 2.364v1.741h1.837L15 2.364z"
    />
  </svg>
);

Document.defaultProps = {
  color: '#042238',
  width: 20,
  height: 24,
};

export default memo(Document);
