import React, { memo } from 'react';

const RadioCheck = (props) => (
  <svg {...props} viewBox="0 0 50 50">
    <path
      d="M25 0c13.75 0 25 11.25 25 25S38.75 50 25 50 0 38.75 0 25 11.25 0 25 0Zm12 15L20.714 29.615 13 22.692l-3 2.693L20.714 35 40 17.692 37 15Z"
      fill={props.color}
      fillRule="evenodd"
    />
  </svg>
);

RadioCheck.defaultProps = {
  width: 50,
  height: 50,
  color: '#04BF8D',
};

export default memo(RadioCheck);
