import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Flood = ({ color, ...props }) => (
  <svg viewBox="0 0 28 28" {...props} fill={color}>
    <path
      d="M2.063 21.756c2.858 2.398 7.226 3.838 11.967 3.838 4.71 0 9.053-1.421 11.911-3.792a.843.843 0 0 1 1.2.125.88.88 0 0 1-.122 1.224c-3.179 2.636-7.904 4.182-12.989 4.182-5.12 0-9.873-1.566-13.052-4.234a.88.88 0 0 1-.117-1.224.843.843 0 0 1 1.202-.119Zm4.48-1.838c1.884 1.384 4.58 2.199 7.487 2.199 2.89 0 5.573-.807 7.456-2.177a.843.843 0 0 1 1.19.202.88.88 0 0 1-.198 1.214c-2.184 1.59-5.216 2.5-8.448 2.5-3.252 0-6.298-.92-8.485-2.527a.88.88 0 0 1-.194-1.214.843.843 0 0 1 1.192-.197Zm8.189-18.876.045.067.029.043.829 1.272a77.29 77.29 0 0 1 1.978 3.273c.36.633.696 1.248 1.003 1.84 1.25 2.413 1.957 4.297 1.957 5.594 0 3.682-2.93 6.667-6.543 6.667-3.614 0-6.544-2.985-6.544-6.667 0-1.297.707-3.18 1.957-5.593.307-.593.643-1.208 1.003-1.841.473-.83.97-1.661 1.474-2.471l.505-.802.652-1.006.25-.376a.845.845 0 0 1 1.405 0ZM14.03 3.1l-.174.271a75.575 75.575 0 0 0-1.933 3.198c-.35.614-.675 1.21-.97 1.78-1.122 2.165-1.76 3.865-1.76 4.782 0 2.721 2.165 4.927 4.837 4.927 2.671 0 4.836-2.206 4.836-4.927 0-.917-.638-2.617-1.76-4.781a51.561 51.561 0 0 0-.97-1.78 72.88 72.88 0 0 0-1.44-2.414l-.493-.785-.173-.27Zm-2.655 8.775c.471 0 .853.39.853.87 0 1.013.807 1.835 1.802 1.835.471 0 .853.39.853.87s-.382.87-.853.87c-1.938 0-3.509-1.601-3.509-3.575 0-.48.382-.87.854-.87Z"
      fillRule="evenodd"
    />
  </svg>
);

Flood.defaultProps = {
  width: 28,
  height: 28,
  color: 'currentColor',
};

Flood.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Flood);
