import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="30"
      viewBox="0 0 44 30"
    >
      <defs>
        <clipPath id="smoking-colored-i0">
          <path d="M1280 0v1772H0V0h1280z"></path>
        </clipPath>
        <clipPath id="smoking-colored-i1">
          <path d="M9 0v8H0V0h9z"></path>
        </clipPath>
        <clipPath id="smoking-colored-i2">
          <path d="M37.583 0c.507 0 .917.42.917.938v7.5c0 .517-.41.937-.917.937H.917A.927.927 0 010 8.437v-7.5C0 .42.41 0 .917 0h36.666zm-.916 1.875H1.833V7.5h34.834V1.875z"></path>
        </clipPath>
        <clipPath id="smoking-colored-i3">
          <path d="M.917 0c.506 0 .916.42.916.938v7.5c0 .517-.41.937-.916.937A.927.927 0 010 8.437v-7.5C0 .42.41 0 .917 0z"></path>
        </clipPath>
        <clipPath id="smoking-colored-i4">
          <path d="M.917 0c.506 0 .916.42.916.938v7.5c0 .517-.41.937-.916.937A.927.927 0 010 8.437v-7.5C0 .42.41 0 .917 0z"></path>
        </clipPath>
        <clipPath id="smoking-colored-i5">
          <path d="M11.917 0c.506 0 .916.42.916.938 0 3.624-2.872 6.562-6.416 6.562-2.531 0-4.584 2.1-4.584 4.688v3.75c0 .517-.41.937-.916.937A.927.927 0 010 15.937v-3.75c0-3.623 2.873-6.562 6.417-6.562C8.948 5.625 11 3.527 11 .937c0-.517.41-.937.917-.937z"></path>
        </clipPath>
        <clipPath id="smoking-colored-i6">
          <path d="M11.917 0c.506 0 .916.42.916.938 0 3.624-2.872 6.562-6.416 6.562-2.531 0-4.584 2.1-4.584 4.688 0 .517-.41.937-.916.937A.927.927 0 010 12.187c0-3.623 2.873-6.562 6.417-6.562C8.948 5.625 11 3.527 11 .937c0-.517.41-.937.917-.937z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#smoking-colored-i0)" transform="translate(-614 -591)">
        <g
          clipPath="url(#smoking-colored-i1)"
          transform="translate(260 393) translate(32 80) translate(304 97) translate(16 16) translate(2 5) translate(34 21)"
        >
          <path fill="#7FE3FF" d="M0 0L9 0 9 8 0 8 0 0z"></path>
        </g>
        <g
          clipPath="url(#smoking-colored-i2)"
          transform="translate(260 393) translate(32 80) translate(304 97) translate(16 16) translate(2 5) translate(5.5 20.625)"
        >
          <path fill="#032238" d="M0 0L38.5 0 38.5 9.375 0 9.375 0 0z"></path>
        </g>
        <g
          clipPath="url(#smoking-colored-i3)"
          transform="translate(260 393) translate(32 80) translate(304 97) translate(16 16) translate(2 5) translate(33 20.625)"
        >
          <path
            fill="#032238"
            d="M0 0L1.83333333 0 1.83333333 9.375 0 9.375 0 0z"
          ></path>
        </g>
        <g
          clipPath="url(#smoking-colored-i4)"
          transform="translate(260 393) translate(32 80) translate(304 97) translate(16 16) translate(2 5) translate(1.833 20.625)"
        >
          <path
            fill="#032238"
            d="M0 0L1.83333333 0 1.83333333 9.375 0 9.375 0 0z"
          ></path>
        </g>
        <g
          clipPath="url(#smoking-colored-i5)"
          transform="translate(260 393) translate(32 80) translate(304 97) translate(16 16) translate(2 5)"
        >
          <path
            fill="#032238"
            d="M0 0L12.8333333 0 12.8333333 16.875 0 16.875 0 0z"
          ></path>
        </g>
        <g
          clipPath="url(#smoking-colored-i6)"
          transform="translate(260 393) translate(32 80) translate(304 97) translate(16 16) translate(2 5) translate(3.667 3.75)"
        >
          <path
            fill="#032238"
            d="M0 0L12.8333333 0 12.8333333 13.125 0 13.125 0 0z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(Icon);
