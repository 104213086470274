import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import Input from '../../../../../../../components/Input';
import ResourcesIcon from '../../../../../../../icons/resources';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredShortStandard from '../../../../../../../validators/fieldRequiredShort';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from '../../../BankInfo.module.scss';

const divisionTexts = {
  cookCounty: {
    name: 'Cook County',
    listItems: [
      'Security deposits cannot exceed 1.5 times the monthly rent.',
      'Additional pet deposits are allowed.',
      'Receipts must be given for any kind of security deposit including owner’s name, date received, description of property and signed by the person accepting the funds.',
      'For electronic payment of security deposit, you can provide an electronic receipt showing the property, date, amount and electronic signature of the person accepting funds.',
      'If you change banks for security deposits, you must notify Tenant within 14 days of the new location.',
      'Security deposits must be returned to Tenant within 30 days after Lease termination.',
      'If there is damage to the property, you must provide Tenant with an itemization of lawful charges and paid repair invoices within 30 days from move out date.',
      'Security deposits must be deposited into a separate FDIC insured  account in an Illinois bank.',
      'The lease must specify the location of the security deposit account.',
    ],
    supportArticle:
      'http://support.turbotenant.com/en/articles/6245234-cook-county-illinois-lease-agreement',
  },

  chicago: {
    name: 'Chicago',
    listItems: [
      'No statutory minimum deposit amount in Chicago.  Typically, deposits are 1-2 times the monthly rent.',
      'Additional pet deposits are allowed.',
      'Receipts must be given for any kind of security deposit including owner’s name, date received, description of premises and signed by the person accepting the funds.',
      'For electronic payment of security deposit, you can provide an electronic receipt showing the property, date, amount and electronic signature of the person accepting funds.',
      'If you change banks for security deposits, you must notify Tenant within 14 days of the new location.',
      'For leases over 6 months, interest earned on security deposits must be returned to the Tenant every 12 months.',
      'Security deposits and all accrued interest must be returned to Tenant within 45 days after Lease termination.',
      'If there is damage to the Property, before deducting any amounts from the security deposit, you must first provide written notice to Tenant with an itemization of charges and repair invoices within 30 days from move out date.',
      'Security deposits must be deposited into a separate FDIC insured, interest-bearing account in an Illinois bank for the benefit of Tenant. ',
      'The lease must specify the location of the security deposit account.',
    ],
    supportArticle:
      'http://support.turbotenant.com/en/articles/6245231-chicago-illinois-lease-agreement',
  },
};

const stateTexts = {
  name: 'Illinois',
  listItems: [
    'No statutory minimum deposit amount in Illinois.  Typically, deposits are 1-2 times the monthly rent.',
    'Additional pet deposits are allowed.',
    'Security deposits must be deposited into a separate FDIC insured account in an Illinois bank. ',
    'If you own 25+ units, you will be required to place security deposits in an interest bearing account.',
    'If applicable, any interest earned will be returned to Tenant yearly.',
    'Security deposits must be returned to Tenant within 45 days after Lease termination.',
    'If there is damage to the Property, you must provide Tenant with an itemization of lawful charges with repair invoices or reasonable estimation of costs within 30 days from move out date.',
  ],
  supportArticle:
    'http://support.turbotenant.com/en/articles/6245229-illinois-lease-agreement',
};

const BankInfo = ({ className, leaseAgreementSelection }) => {
  const division = divisionTexts[leaseAgreementSelection] || stateTexts;
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequiredShort = conditionalValidation(
    fieldRequiredShortStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <HelpfulInfoCard
          collapsable
          Icon={ResourcesIcon}
          className={cx(styles.helpfulInfoCard)}
          title={`${division.name} Security Deposit Laws`}
          color="iceCold"
        >
          Though they're included in the lease agreement, we've highlighted some
          rules you need to know:
          <HelpfulInfoList className={styles.helpfulInfoList}>
            {division.listItems.map((item, index) => (
              <HelpfulInfoListItem className={styles.helpfulItem} key={index}>
                {item}
              </HelpfulInfoListItem>
            ))}

            <div className={styles.learnMore}>
              <a
                href={division.supportArticle}
                target="_blank noopener noreferrer"
              >
                Learn other security deposit laws
              </a>
            </div>
          </HelpfulInfoList>
        </HelpfulInfoCard>
        {leaseAgreementSelection && (
          <>
            <Field
              label="FDIC Insured Institution Name:"
              className={styles.inputContainer}
              inputProps={{
                className: cx(styles.input),
              }}
              id="deposit_bank_name"
              name="deposit_bank_name"
              component={Input}
              validate={fieldRequiredShort}
            />
            <Field
              label="FDIC Insured Institution Address:"
              className={styles.inputContainer}
              inputProps={{
                className: cx(styles.input),
              }}
              id="deposit_bank_address"
              name="deposit_bank_address"
              component={Input}
              validate={fieldRequiredShort}
            />
          </>
        )}
      </div>
    </div>
  );
};

BankInfo.propTypes = {
  className: PropTypes.string,
};

export default BankInfo;
