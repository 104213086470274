import { useEffect, useState } from 'react';

export default function useLocalStorage(key, initialValue) {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [storedValue, setStoredValue] = useState(initialValue);

  useEffect(() => {
    if (isFirstLoad) {
      const item = window.localStorage.getItem(key);
      if (item) {
        setStoredValue(JSON.parse(item));
      }
      setIsFirstLoad(false);
    }
  }, [key, isFirstLoad]);

  useEffect(() => {
    if (!isFirstLoad) {
      window.localStorage.setItem(key, JSON.stringify(storedValue));
    }
  }, [storedValue, key, isFirstLoad]);

  return [storedValue, setStoredValue];
}
