import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from '../../../BankInfo.module.scss';

const BankInfo = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.row}>
      <HelpfulInfoCard
        Icon={ResourcesIcon}
        collapsable
        className={styles.helpfulInfoCard}
        title="Massachusetts Security Deposit and Fees Laws"
        color="iceCold"
      >
        Though they're included in the lease agreement, we've highlighted some
        rules you need to know:
        <HelpfulInfoList className={styles.helpfulInfoList}>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Security deposit shall be no greater than one month’s rent.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Massachusetts law does not allow additional fees, other than the
            monthly rent and security deposit to be charged. So instead of a pet
            deposit, consider inflating the rent to compensate. The exceptions
            to this law are collecting the last month’s rent and a re-key fee.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            You are required to pay interest of 5% (or a lesser amount as
            provided by your bank) on the security deposit to the tenant, which
            is to be disbursed annually. If last month’s rent is collected at
            time of Lease start, the same rules apply.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            If you are collecting a security deposit before or at move-in, you
            must present the Tenant with a written statement on the condition of
            the property. Tenant, within 15 days after move-in, must sign,
            return and notate on such form any damage or defects to the leased
            premises.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            You shall return the security deposit, within 30 days of taking
            possession, along with any accrued interest.
          </HelpfulInfoListItem>
          <div className={styles.learnMore}>
            <a
              href="http://support.turbotenant.com/en/articles/6188726-massachusetts-lease-agreement"
              target="_blank noopener noreferrer"
            >
              Learn other security deposit laws
            </a>
          </div>
        </HelpfulInfoList>
      </HelpfulInfoCard>
    </div>
  </div>
);

BankInfo.propTypes = {
  formValues: PropTypes.object,
  className: PropTypes.string,
};

export default BankInfo;
