import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';

import styles from './SharedUtilitiesOR.module.scss';

const SharedUtilitiesOR = ({ className, standaloneWizard }) => {
  const {
    input: { value: sharedUtilities },
  } = useField('custom_state_data.shared_utilities');

  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standaloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <label className={styles.label}>
        Are there any shared utilities for the property?
      </label>
      <div className={styles.questionContext}>
        Oregon law requires that you disclose this.
      </div>
      <Field
        component={RadioGroup}
        id="custom_state_data.shared_utilities"
        name="custom_state_data.shared_utilities"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'custom_state_data.shared_utilities_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'custom_state_data.shared_utilities_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      {sharedUtilities && (
        <>
          <p className={styles.text}>
            If any utility costs are shared by tenants for the property or
            common areas, landlords must disclose this and how the shared
            utilities are divided:
          </p>
          <Field
            as="textarea"
            component={Input}
            id="custom_state_data.utilities_devided_as_follows"
            name="custom_state_data.utilities_devided_as_follows"
            label="Please list all shared utilities and how they are divided."
            className={styles.termsContainer}
            inputProps={{
              rows: '5',
              className: styles.termsField,
            }}
            maxLength={2000}
            validate={fieldRequired}
            help={(val = '') => `${val.length} / 2000 characters used`}
          />
        </>
      )}
    </div>
  );
};

SharedUtilitiesOR.propTypes = {
  className: PropTypes.string,
  formValues: PropTypes.object,
  standaloneWizard: PropTypes.bool,
};

export default SharedUtilitiesOR;
