import React from 'react';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import { TextField, YearField } from '../../../../components/FinalFormFields';
import { Column, Row } from '../../../../components/Grid';
import Input from '../../../../components/Input';
import RemoveButton from '../../../../components/RemoveButton';

import styles from './VehiclesEdit.module.scss';

const defaultVehicle = {
  make: '',
  name: '',
  color: '',
  year: '',
  registration: null,
};

const VehiclesEdit = () => {
  const form = useForm();

  return (
    <div>
      <article>
        <FieldArray name="vehicles">
          {({ fields }) =>
            fields?.map((name, index) => (
              <div key={`vehicle-key-${index}`}>
                <div className={styles.sectionTitle}>Vehicles</div>
                <div className={styles.vehicleContainer}>
                  <Row className={styles.vehicleForm}>
                    <Column md={2} sm={6}>
                      <TextField
                        className={styles.field}
                        name={`${name}.make`}
                        label="Make"
                      />
                    </Column>
                    <Column md={2} sm={6}>
                      <TextField
                        className={styles.field}
                        name={`${name}.name`}
                        label="Model"
                      />
                    </Column>
                    <Column md={2} sm={6}>
                      <TextField
                        className={styles.field}
                        name={`${name}.color`}
                        label="color"
                      />
                    </Column>
                    <Column md={2} sm={6}>
                      <YearField
                        className={styles.field}
                        name={`${name}.year`}
                        label="year"
                      />
                    </Column>
                    <Column md={4} sm={6}>
                      <Field
                        className={styles.field}
                        component={Input}
                        id={`${name}.registration`}
                        name={`${name}.registration`}
                        label="License Plate"
                        hint="(Optional)"
                        type="text"
                        parse={(value) => {
                          if (!value) {
                            return '';
                          }
                          return value
                            .replace(/[^a-zA-Z0-9]/g, '')
                            .toUpperCase();
                        }}
                      />
                    </Column>
                  </Row>
                  <RemoveButton
                    className={styles.remove}
                    onClick={() => form?.mutators?.remove('vehicles', index)}
                  />
                </div>
              </div>
            ))
          }
        </FieldArray>
        <Button
          large
          secondary
          className={styles.addButton}
          onClick={() => form?.mutators?.push('vehicles', defaultVehicle)}
        >
          + add vehicle
        </Button>
      </article>
    </div>
  );
};

VehiclesEdit.propTypes = {
  styles: PropTypes.object,
  data: PropTypes.object,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
};

export default VehiclesEdit;
