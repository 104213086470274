import React from 'react';
import { Field, useField } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import kebabCase from 'lodash.kebabcase';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import { statesList } from '../../constants';
import { usTerritories } from '../../constants/lease_agreement/state-names';
import {
  getAddressSuggestions,
  getUSFullAddress,
} from '../../core/services/smartyStreets';
import { useStandaloneWizardState } from '../../pages/digital-leases/standalone-wizard/StandaloneWizardProvider';
import composeValidators from '../../validators/composeValidators';
import conditionalValidation from '../../validators/conditionalValidation';
import fieldRequiredStandard from '../../validators/fieldRequired';
import fieldRequiredShortStandard from '../../validators/fieldRequiredShort';
import isWord from '../../validators/isWord';
import isZip from '../../validators/isZip';
import ComboBox from '../ComboBox';
import Input from '../Input';
import SelectField from '../SelectField';
import { Tooltip } from '../Tooltip';

import styles from './PropertyAddressFields.module.scss';

const defaultFieldNames = {
  city: 'city',
  unit: 'unit',
  address: 'address',
  zip: 'zip',
  state: 'state',
};

const getOptions = async (query, pathname) => {
  if (query.length < 5) {
    return [];
  }

  const { suggestions } = await getAddressSuggestions(query, pathname);
  return suggestions;
};

const PropertyAddressFields = ({
  className,
  streetAddressInputProps,
  fieldNames,
  validators,
  fieldsClassName,
  form,
  showUnit = true,
  location,
  excludeTerritories,
  disabledFields,
}) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );
  const fieldRequiredShort = conditionalValidation(
    fieldRequiredShortStandard,
    !standAloneWizard,
  );

  // IN CASE OF STANDALONE WIZARD WE NEED TO REMOVE US TERRITORIES SINCE THEY CANNOT GET A LEASE AGREEMENT
  const filteredStatesList = excludeTerritories
    ? statesList.filter(
        (state) => !Object.keys(usTerritories).includes(state.value),
      )
    : statesList;

  const states = filteredStatesList.map(({ title, value }) => ({
    value,
    label: title,
  }));

  const fields = {
    ...defaultFieldNames,
    ...fieldNames,
  };

  const cityField = useField(fields.city);
  const zipField = useField(fields.zip);
  const stateField = useField(fields.state);

  return (
    <div className={cx(styles.container, className)}>
      <Field
        label={streetAddressInputProps?.label || 'Street Address'}
        id={fields.address}
        name={fields.address}
        data-qa={kebabCase(fields.address)}
        component={ComboBox}
        validate={composeValidators(
          fieldRequired,
          ...(validators.address || []),
        )}
        getOptions={(query) => getOptions(query, location?.pathname)}
        className={cx(styles.field, styles.address, fieldsClassName?.address, {
          [styles.fullWidth]: !showUnit,
        })}
        itemToString={(item) => (item ? item.text : '')}
        onSelected={(item) => {
          const { city, state, street_line } = item;
          getUSFullAddress({
            city,
            state,
            street: street_line,
          }).then((response) => {
            const zip = get(response, '[0].components.zipcode', '');
            if (zip) {
              zipField.input.onChange(zip);
            }
          });
          if (city) {
            cityField.input.onChange(city);
          }
          if (state) {
            stateField.input.onChange(state);
          }

          return street_line;
        }}
        debounce={600}
      />
      <OnChange name="address">
        {form?.mutators?.setLeaseNickName || (() => {})}
      </OnChange>
      {showUnit ? (
        <Field
          label="Unit"
          hint="(Optional)"
          id={fields.unit}
          name={fields.unit}
          inputProps={{
            'data-qa': kebabCase(fields.unit),
          }}
          component={Input}
          className={cx(styles.field, styles.unit, fieldsClassName?.unit)}
          validate={composeValidators(...(validators.unit || []))}
        />
      ) : null}
      <Field
        label="City"
        id={fields.city}
        name={fields.city}
        inputProps={{
          'data-qa': kebabCase(fields.city),
        }}
        component={Input}
        validate={composeValidators(
          fieldRequired,
          isWord,
          ...(validators.city || []),
        )}
        className={cx(styles.field, styles.city, fieldsClassName?.city)}
      />
      <div className={styles.zipStateWrapper}>
        <Field
          className={cx(styles.field, styles.state, fieldsClassName?.state)}
          label="State"
          options={states}
          id={fields.state}
          validate={composeValidators(
            fieldRequiredShort,
            ...(validators.state || []),
          )}
          name={fields.state}
          data-qa={kebabCase(fields.state)}
          component={SelectField}
          selectClassName={fieldsClassName?.stateSelect}
          disabled={disabledFields.some((field) => field.name === fields.state)}
          data-tooltip-id={fields.state}
        />
        {disabledFields.some((field) => field.name === fields.state) && (
          <Tooltip id={fields.state}>
            {
              disabledFields.find((field) => field.name === fields.state)
                .tooltip
            }
          </Tooltip>
        )}
        <Field
          className={cx(styles.field, styles.zip, fieldsClassName?.zip)}
          label="Zip Code"
          type="number"
          pattern="[0-9]*"
          id={fields.zip}
          validate={composeValidators(
            fieldRequiredShort,
            isZip,
            ...(validators.zip || []),
          )}
          name={fields.zip}
          inputProps={{
            'data-qa': kebabCase(fields.zip),
          }}
          component={Input}
          parse={(value) => (value ? value.substring(0, 5) : '')}
        />
      </div>
    </div>
  );
};

PropertyAddressFields.propTypes = {
  className: PropTypes.string,
  streetAddressInputProps: PropTypes.object,
  fieldNames: PropTypes.object,
  fieldsClassName: PropTypes.object,
  validators: PropTypes.shape({
    city: PropTypes.array,
    unit: PropTypes.array,
    address: PropTypes.array,
    zip: PropTypes.array,
    state: PropTypes.array,
  }),
  form: PropTypes.object,
  showUnit: PropTypes.bool,
  location: PropTypes.object,
  excludeTerritories: PropTypes.bool,
  disabledFields: PropTypes.array,
};

PropertyAddressFields.defaultProps = {
  fieldNames: defaultFieldNames,
  validators: {
    city: [],
    unit: [],
    address: [],
    zip: [],
    state: [],
  },
  excludeTerritories: false,
  disabledFields: [],
};

export default withRouter(PropertyAddressFields);
