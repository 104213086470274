import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import img from '../../assets/reihub/mobile-acc-nav.png';
import colors from '../../constants/colors';
import { useUserFields } from '../../core/TTgraphql';
import CheckmarkFilled from '../../icons/CheckMarkFilled';
import Calculator from '../../icons/streamline/Calculator';
import { segmentTracking } from '../../services/utilities';
import Avatar from '../Avatar';
import Button from '../Button';
import Card from '../Card';
import FlatButton from '../FlatButton';
import NumberedList from '../NumberedList';
import ReiHubLoadingModal, {
  useOpenREIHub,
} from '../ReiHubSubscription/ReiHubLoadingModal';

import { RENEWAL_FREQUENCIES } from './constants';

import styles from './REIHubSubscriptionSuccess.module.scss';

const AUTO_RENEWAL_PERIODS = {
  [RENEWAL_FREQUENCIES.MONTHLY]: 'monthly',
  [RENEWAL_FREQUENCIES.QUARTERLY]: 'quarterly',
  [RENEWAL_FREQUENCIES.YEARLY]: 'yearly',
};

const REIHubSubscriptionSuccess = ({
  onClose,
  onShowFinalHintClose,
  isYearlySubscription,
  className,
  renewalFrequency,
  isPremiumPlusPlan,
  fromPremiumSuccessModal,
}) => {
  const [showFinalHint, setShowFinalHint] = useState(false);

  const { user: { reihub_subscription_subscribed } = {} } = useUserFields([
    'reihub_subscription_subscribed',
  ]);

  const [isModalOpen, setOpenREIHub] = useOpenREIHub({
    isDemoMode: !reihub_subscription_subscribed,
  });

  const loginToReiHub = async () => {
    segmentTracking('go_to_reihub clicked', {
      location: 'reihub purchase success screen ',
    });

    await setOpenREIHub();

    if (
      window.location.pathname === '/owners/payments/accounting' ||
      window.location.pathname === '/owners/payments/reports'
    ) {
      setShowFinalHint(true);
    } else {
      onClose?.();
    }
  };

  const Icon = isPremiumPlusPlan ? (
    <Avatar
      size="larger"
      icon={
        <Calculator width={64} height={64} secondaryColor={colors.babyBlue} />
      }
    />
  ) : (
    <CheckmarkFilled color="#04BF8D" width={80} height={80} />
  );

  const title = isPremiumPlusPlan
    ? 'Here’s what’s next for Rental Accounting, powered by REI Hub'
    : 'Success! Here’s what’s next:';

  return showFinalHint ? (
    <Card className={cx(styles.finalHintCard, className)}>
      <img src={img} alt="REIHub mobile navigation" />
      <h3>You can access REI Hub in the future by clicking “Accounting”</h3>
      <Button
        className={styles.actionButton}
        primary
        onClick={() => {
          onShowFinalHintClose?.();
        }}
      >
        {fromPremiumSuccessModal ? 'Sounds Good' : 'Go To My Dashboard'}
      </Button>
    </Card>
  ) : (
    <Card className={cx(styles.card, className)}>
      <div className={styles.banner}>{Icon}</div>
      <div className={styles.content}>
        <h5>{title}</h5>
        <NumberedList
          variant="bold"
          size="large"
          list={[
            'Click below to setup accounting with REI Hub.',
            "We'll automatically send your properties and rent payment information from TurboTenant.",
            "In REI Hub, you'll set settings, review the information we sent over, as well as link any banks and/or credit cards with transactions you want to track.",
            ...(isPremiumPlusPlan
              ? []
              : [
                  `Any changes to your property count will be reflected in your ${
                    AUTO_RENEWAL_PERIODS[renewalFrequency] ??
                    // NOTICE: this line of code below is here for backwards compatibility
                    (isYearlySubscription ? 'yearly' : 'monthly')
                  } subscription price.`,
                ]),
          ]}
          className={styles.itemClassName}
        />
        <Button className={styles.actionButton} primary onClick={loginToReiHub}>
          GO TO REI HUB
        </Button>
        <FlatButton
          className={styles.setupLaterBtn}
          onClick={() => {
            segmentTracking('setup_reihub_later clicked', {
              location: 'reihub purchase success screen ',
            });

            if (window.location.pathname === '/owners/dashboard') {
              onClose?.();
              return;
            }

            setShowFinalHint(true);
          }}
        >
          I’LL SET UP REI HUB LATER
        </FlatButton>
      </div>

      <ReiHubLoadingModal
        open={isModalOpen}
        isDemoMode={!reihub_subscription_subscribed}
      />
    </Card>
  );
};

REIHubSubscriptionSuccess.defaultProps = {
  isPremiumPlusPlan: false,
  fromPremiumSuccessModal: false,
};

REIHubSubscriptionSuccess.propTypes = {
  isYearlySubscription: PropTypes.bool,
  onClose: PropTypes.func,
  onShowFinalHintClose: PropTypes.func,
  className: PropTypes.string,
  renewalFrequency: PropTypes.oneOf(Object.values(RENEWAL_FREQUENCIES)),
  isPremiumPlusPlan: PropTypes.bool,
  fromPremiumSuccessModal: PropTypes.bool,
};

export default REIHubSubscriptionSuccess;
