import React from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import { FORM_ERROR } from 'final-form';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import BackButton from '../../../../components/BackButton';
import LoadingScreen from '../../../../components/LoadingScreen';
import SendWorkOrderForm from '../../../../components/Maintenance/SendWorkOrderForm';
import { useInfoToast } from '../../../../components/Toast';
import { useUserProfile } from '../../../../core/TTgraphql';
import listingQuery from '../../../../graphql/queries/listingQuery.graphql';
import { useConfig } from '../../../../providers/ConfigProvider';
import createMaintenanceRequestWorkOrder from '../../graphql/createMaintenanceRequestWorkOrder.graphql';

import styles from './SendWorkOrderPage.module.scss';

const SendWorkOrderPage = ({ className, history, match }) => {
  const { PRIVATE_BASE_PATH } = useConfig();

  const listingId = get(match, 'params.listingGlobalId', '');
  const requestId = get(match, 'params.maintenanceRequestId', '');

  if (!listingId || !requestId) {
    history.goBack();
  }

  const showInfoToast = useInfoToast();

  // Here we only need the user profile because of the first_name and last_name fields
  const { user } = useUserProfile();

  const { data, loading } = useQuery(listingQuery, {
    variables: {
      id: listingId,
    },
  });

  const [createWorkOrder] = useMutation(createMaintenanceRequestWorkOrder);

  if (loading) {
    return <LoadingScreen loading={loading} />;
  }

  const maintenanceRequests = get(data, 'node.maintenance_requests', []);
  const listing = get(data, 'node', {});
  const currentRequest = maintenanceRequests.find(
    (request) => request.id === requestId,
  );

  const onSubmit = async (data) => {
    const submitData = {
      message: data.additional_message,
      email: data.email,
      maintenance_request_id: requestId,
    };
    try {
      const { data: response } = await createWorkOrder({
        variables: { ...submitData },
      });

      const { createMaintenanceRequestWorkOrder } = response;

      if (createMaintenanceRequestWorkOrder.maintenanceRequest) {
        history.push(`/owners/maintenance/${requestId}/${listingId}`);
        showInfoToast('Work order sent successfully.');
      }
    } catch (error) {
      const parsedError = get(error, `graphQLErrors[0]`) || {};
      const message = parsedError.message || 'Something went wrong';
      window.scrollTo(0, 0);
      return {
        [FORM_ERROR]: message,
      };
    }
  };

  return (
    <div className={cx(styles.container, className)}>
      <div className={cx(styles.back)}>
        <BackButton
          className={styles.back}
          fallback={`${PRIVATE_BASE_PATH}maintenance/${requestId}/${listingId}`}
        />
      </div>
      <SendWorkOrderForm
        onSubmit={onSubmit}
        listing={listing}
        user={user}
        mRequest={currentRequest}
        className={styles.form}
      />
    </div>
  );
};

SendWorkOrderPage.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(SendWorkOrderPage);
