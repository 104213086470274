import React from 'react';
import { Form } from 'react-final-form';

import PersonalizeForm from '../../../../onboard/steps/Personalize/PersonalizeForm/PersonalizeForm';

import styles from './PersonalizeAsFirstStep.module.scss';
const PersonalizeAsFirstStep = ({ onSubmit, initialValues }) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{
        submitting: true,
        values: true,
      }}
    >
      {({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <PersonalizeForm
            hideTitle
            className={styles.noPadding}
            {...{ submitting, values }}
          />
        </form>
      )}
    </Form>
  );
};

export default PersonalizeAsFirstStep;
