import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const BulletSquare = ({ color, ...rest }) => (
  <svg fill={color || colors.primary} {...rest} viewBox="0 0 8 8">
    <defs />
    <rect
      width="8"
      height="8"
      y="1"
      fillRule="evenodd"
      rx="1"
      transform="translate(0 -1)"
    />
  </svg>
);

BulletSquare.defaultProps = {
  width: 8,
  height: 10,
};

BulletSquare.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(BulletSquare);
