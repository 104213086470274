import React, { memo } from 'react';
import PropTypes from 'prop-types';

const InternetThick = (props) => (
  <svg {...props} viewBox="0 0 20 19">
    <path
      d="M10 13.902c1.426 0 2.583 1.142 2.583 2.55C12.583 17.857 11.426 19 10 19c-1.426 0-2.583-1.142-2.583-2.549s1.157-2.549 2.583-2.549Zm0 1.39c-.647 0-1.173.52-1.173 1.16 0 .639.526 1.158 1.174 1.158.647 0 1.173-.52 1.173-1.159 0-.64-.526-1.158-1.173-1.158Zm.244-6.02.243.014c.89.067 1.768.32 2.571.756l.217.124c.43.256.836.568 1.208.934a.689.689 0 0 1 0 .983.707.707 0 0 1-.997 0 4.953 4.953 0 0 0-.553-.471l-.195-.136a4.919 4.919 0 0 0-.828-.44l-.217-.084a4.995 4.995 0 0 0-1.578-.293h-.24a5.024 5.024 0 0 0-.218.011l-.229.02a4.951 4.951 0 0 0-2.165.786l-.195.136a4.958 4.958 0 0 0-.553.471.704.704 0 0 1-.39.196l-.109.008a.707.707 0 0 1-.498-.204.689.689 0 0 1 0-.983 6.348 6.348 0 0 1 1.208-.934l.218-.124a6.404 6.404 0 0 1 3.3-.77Zm2.41-4.289.154.043a10.16 10.16 0 0 1 .859.286l.065.026a10.063 10.063 0 0 1 3.326 2.136l.08.08a.689.689 0 0 1 .074.896l-.073.086a.709.709 0 0 1-.606.196l-.106-.025a.703.703 0 0 1-.285-.17 8.758 8.758 0 0 0-2.136-1.549 8.176 8.176 0 0 0-.955-.42 8.434 8.434 0 0 0-1.074-.32 8.838 8.838 0 0 0-4.983.305A8.702 8.702 0 0 0 3.86 8.536a.703.703 0 0 1-.285.171l-.106.025-.107.008-.108-.008a.704.704 0 0 1-.39-.196.689.689 0 0 1 0-.983c.358-.354.737-.675 1.132-.965l.239-.17a10.051 10.051 0 0 1 3.037-1.414 10.247 10.247 0 0 1 2.587-.369h.284c.845.012 1.69.128 2.51.348Zm7.14-.978a.689.689 0 0 1 0 .983.71.71 0 0 1-.996 0C13.939.192 6.065.19 1.202 4.988a.71.71 0 0 1-.996 0 .689.689 0 0 1 0-.983c5.413-5.342 14.179-5.339 19.588 0Z"
      fill="#042238"
      fillRule="evenodd"
    />
  </svg>
);

InternetThick.defaultProps = {
  width: 20,
  height: 19,
};

InternetThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(InternetThick);
