import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const MarketingThick = (props) => (
  <svg {...props} viewBox="0 0 24 23" fill={props.color} fillRule="evenodd">
    <path d="M19.317 0c.431 0 .78.344.78.768V4.61c0 .425-.349.769-.78.769l-7.025-.001v5.186l8 .001c.399 0 .727.293.775.672l.006.096v4.994h2.147c.395 0 .721.29.773.665l.007.104v4.802c0 .425-.35.769-.78.769H18.34a.774.774 0 01-.78-.769v-4.802c0-.424.35-.768.78-.768l1.171-.001v-4.225l-7.22-.001v4.226h2.147c.395 0 .722.29.773.665l.008.104v4.802c0 .425-.35.769-.781.769H9.561a.774.774 0 01-.78-.769v-4.802c0-.424.349-.768.78-.768l1.17-.001v-4.226H4.488l-.001 4.226h1.172c.395 0 .721.29.773.665l.007.104v4.802c0 .425-.35.769-.78.769H.78a.774.774 0 01-.78-.769v-4.802c0-.424.35-.768.78-.768l2.146-.001v-4.994c0-.391.299-.715.683-.762l.098-.006 7.024-.001V5.378H4.683a.777.777 0 01-.773-.664l-.008-.104V.768c0-.424.35-.768.78-.768zM13.66 17.864H10.34v3.266h3.318v-3.266zm-8.781 0H1.561v3.266h3.317v-3.266zm17.561 0h-3.317v3.266h3.317v-3.266zM18.537 1.537H5.463v2.305h13.074V1.537z" />
  </svg>
);

MarketingThick.defaultProps = {
  color: colors.text,
  width: 20,
  height: 19,
};

MarketingThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(MarketingThick);
