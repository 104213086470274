import { getCurrentToken } from '../core/auth/authService';
import { TTfetch } from '../core/TTfetch';

import { getConfig } from './configService';

export const getLinkToken = async (
  plaidAccessToken,
  manualMicrodepositsFlow = false,
  updateMode = false,
) => {
  const { API_URL } = getConfig();
  const authToken = getCurrentToken();
  const headers = {
    'authorization': authToken,
    'Content-Type': 'application/json',
  };

  const response = await TTfetch(API_URL + '/public/getplaidlinktoken', {
    method: 'POST',
    headers,
    body: JSON.stringify({
      plaidAccessToken,
      manualMicrodepositsFlow,
      updateMode,
    }),
  });
  const responseJSON = await response.json();
  return responseJSON.link_token || null;
};
