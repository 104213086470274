import React from 'react';

import isGreaterThan from '../../validators/isGreaterThan';
import isLessThanOrEqualTo from '../../validators/isLessThanOrEqualTo';
import Input from '../Input';

import CoreField from './CoreField';

export const YearField = (props) => (
  <CoreField
    component={Input}
    type="number"
    pattern="[0-9]*"
    validate={[isGreaterThan(1900), isLessThanOrEqualTo(9999)]}
    parse={(value) => (value ? value.replace(/[^0-9]/gi, '') : '')}
    {...props}
  />
);

export default YearField;
