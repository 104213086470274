import React from 'react';
import cx from 'classnames';
import { string } from 'prop-types';

import browserHistory from '../../../browserHistory';
import LoadingScreen from '../../../components/LoadingScreen';
import { useUserProfile } from '../../../core/TTgraphql';
import isNullEmptyOrUndefined from '../../../helpers/isNullEmptyOrUndefined';
import { renterHasPaymentMethod } from '../../../helpers/payments-helper';
import { useConfig } from '../../../providers/ConfigProvider';
import { shouldShowRentReporting } from '../../../services/utilities/shouldShowRentReporting';

import styles from './RenterHeaderSettings.module.scss';

const RenterHeaderSettings = ({ pathname }) => {
  const getSelectedIndex = () => {
    switch (pathname) {
      case '/renters/settings/account':
        return 0;
      case '/renters/settings/payment-methods':
      case '/renters/settings/enroll-autopay':
        return 1;
      case '/renters/settings/billing':
        return 2;
    }
    return -1;
  };

  const selectedIndex = getSelectedIndex();
  const { user, loading } = useUserProfile({ polling: false });
  const { PRIVATE_BASE_PATH } = useConfig();

  const showRentReporting = shouldShowRentReporting(user);

  const shouldShowPaymentsTab = user.has_payment_request_rules;

  const shouldShowBillingTab =
    renterHasPaymentMethod(user) ||
    showRentReporting ||
    !isNullEmptyOrUndefined(user?.credit_reporting_enabled);

  const tabs = [
    {
      id: 'account_tab',
      label: 'Account',
      active: selectedIndex === 0,
      action: () => browserHistory.push(`${PRIVATE_BASE_PATH}settings/account`),
    },
  ];

  if (shouldShowPaymentsTab) {
    tabs.push({
      id: 'payments_tab',
      label: 'Rent Payments',
      active: selectedIndex === 1,
      action: () =>
        browserHistory.push(`${PRIVATE_BASE_PATH}settings/payment-methods`),
    });
  }

  if (shouldShowBillingTab) {
    tabs.push({
      id: 'billing_tab',
      label: 'Billing',
      active: selectedIndex === 2,
      action: () => browserHistory.push(`${PRIVATE_BASE_PATH}settings/billing`),
    });
  }

  if (loading) {
    return <LoadingScreen loading />;
  }

  return (
    <div className={styles.headerContainer}>
      {tabs.map((tab, index) => {
        const { id, label, action, active } = tab;

        return (
          <div
            key={index}
            className={cx(styles.itemStyles, { [styles.itemSelected]: active })}
            onClick={action}
          >
            <span id={id}>{label}</span>
          </div>
        );
      })}
    </div>
  );
};

RenterHeaderSettings.propTypes = {
  pathname: string,
};

export default RenterHeaderSettings;
