import React from 'react';

import { linkTo, routes } from '../../../../../helpers/routes';
import Exclamation from '../../../../../icons/streamline/filled/Exclamation';
import { segmentTracking } from '../../../../../services/utilities/segment';
import { Link, Span } from '../../../../Typography';

import styles from '../../GlobalBanner.module.scss';

const MultipleBankAccountsErrored = () => {
  return (
    <div className={styles['gb-style-danger']}>
      <Exclamation color="white" />
      <div className={styles.content}>
        <Span weight="bold">ACTION REQUIRED: </Span>
        We were unable to deposit funds into multiple bank accounts.
        <Link
          to={linkTo(routes.rentPayments)}
          onClick={() => {
            segmentTracking('update_now clicked', {
              location: 'Errored Bank Banner',
            });
          }}
        >
          Update Now
        </Link>
      </div>
    </div>
  );
};

export default MultipleBankAccountsErrored;
