import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import RadioGroup from '../../../../components/RadioGroup';
import SelectField from '../../../../components/SelectField';
import STATE_NAMES, {
  usTerritories,
} from '../../../../constants/lease_agreement/state-names';
import SUB_DIVISION_NAMES from '../../../../constants/lease_agreement/sub-division-names';
import getHasSubdivisionLease from '../../../../helpers/getHasSubdivisionLease';
import { segmentTracking } from '../../../../services/utilities';
import fieldRequired from '../../../../validators/fieldRequired';

import styles from './StateCitySelectionModal.module.scss';

const StateCitySelectionModal = ({
  open,
  onSave,
  initialValues,
  segmentData = {},
}) => {
  // We exclude us territories from the list of states since we they cannot have lease agreements
  const statesOptions = Object.keys(STATE_NAMES)
    .filter((key) => !Object.keys(usTerritories).includes(key))
    .map((key) => ({
      label: STATE_NAMES[key],
      value: key,
    }));

  useEffect(() => {
    segmentTracking('lease_agreement_state_modal loaded', {
      location: 'Builder Summary Page - Public Lease Agreement',
      ...segmentData,
    });
  }, []);

  return (
    <Modal
      id="state-city-selection-modal"
      mobileFullScreen
      open={open}
      className={styles.container}
      containerClassName={styles.modalWrap}
    >
      <h2 className={styles.title}>
        Which lease agreement would you like to use?
      </h2>
      <p className={styles.subtitle}>
        There are different landlord-tenant laws depending on where the property
        is located.
      </p>
      <Form
        onSubmit={({ state, lease_agreement_selection }) => {
          onSave({
            state,
            ...(lease_agreement_selection &&
            lease_agreement_selection !== 'state'
              ? { lease_agreement_selection }
              : {}),
          });
        }}
        subscription={{
          submitting: true,
          values: true,
        }}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Field
              className={styles.stateField}
              component={SelectField}
              label="State"
              options={statesOptions}
              id="state"
              name="state"
              validate={fieldRequired}
              empty
            />
            {getHasSubdivisionLease(values.state) ? (
              <Field
                component={RadioGroup}
                id="lease_agreement_selection"
                name="lease_agreement_selection"
                radiosContainerClassName={styles.radioButtonsContainer}
                options={[
                  ...Object.entries(SUB_DIVISION_NAMES[values.state])
                    .sort((a, b) => a[1].localeCompare(b[1]))
                    .map(([key, divisionName]) => ({
                      value: key,
                      label: `${divisionName}`,
                    })),
                  {
                    value: values.state,
                    label: `State of ${STATE_NAMES[values.state]}`,
                  },
                ]}
                validate={fieldRequired}
              />
            ) : null}
            <Button type="submit" className={styles.btn} loading={submitting}>
              Save Selection
            </Button>
          </form>
        )}
      </Form>
    </Modal>
  );
};

StateCitySelectionModal.propTypes = {
  open: PropTypes.bool,
  onSave: PropTypes.func,
  initialValues: PropTypes.object,
  segmentData: PropTypes.object,
};

StateCitySelectionModal.defaultProps = {
  initialValues: {},
};

export default StateCitySelectionModal;
