import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Condition from '../../../../components/Condition';
import DropdownSubtextMenu from '../../../../components/DropdownSubtextMenu';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import MultiCondition from '../../../../components/MultiCondition';
import PropertyAddressFields from '../../../../components/PropertyAddressFields';
import RadioGroup from '../../../../components/RadioGroup';
import { Tooltip } from '../../../../components/Tooltip';
import isSinglePropertyType from '../../../../helpers/isSinglePropertyType';
import { segmentTracking } from '../../../../services/utilities';
import fieldRequired from '../../../../validators/fieldRequired';
import validShareAbleAddress from '../../../../validators/validShareAbleAddress';
import validShareAbleCity from '../../../../validators/validShareAbleCity';
import validShareAbleUnit from '../../../../validators/validShareAbleUnit';
import { propertyTypeDropdownOptions } from '../helpers/propertyTypeDropdownOptions';
import UnsavedChangesPrompt from '../UnsavedChangesPrompt';

import styles from './AddRentalAddressModal.module.scss';

const AddRentalAddressModal = ({
  className,
  containerClassName,
  open,
  onClose,
  onSubmit,
  initialValues,
  stateFullName,
  segmentLocation,
  segmentData = {},
}) => {
  useEffect(() => {
    segmentTracking('property_details_modal loaded', {
      location: segmentLocation,
      ...segmentData,
    });
  }, []);

  function handleSubmission(data) {
    segmentTracking('save_signup clicked', {
      location: 'Property Details Modal',
      ...segmentData,
    });
    onSubmit(data);
    onClose();
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={cx(styles.container, className)}
      containerClassName={cx(styles.modalContainer, containerClassName)}
    >
      <div className={styles.header}>
        <h3 className={styles.title}>
          One more step! Add your rental address to save your {stateFullName}{' '}
          Lease Agreement
        </h3>
        <div className={styles.description}>
          This is required to save your lease agreement.
        </div>
      </div>

      <Form
        onSubmit={handleSubmission}
        subscription={{
          values: true,
          submitting: true,
          pristine: true,
        }}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitting, values, pristine }) => (
          <form onSubmit={handleSubmit} className={cx(styles.form, className)}>
            <UnsavedChangesPrompt when={!pristine} />
            <Field
              label="Property Type"
              id="property_type"
              name="property_type"
              validate={fieldRequired}
            >
              {(props) => (
                <DropdownSubtextMenu
                  options={propertyTypeDropdownOptions}
                  {...props}
                >
                  <DropdownSubtextMenu.Trigger />
                  <DropdownSubtextMenu.Content>
                    {(options) =>
                      options.map((option) => (
                        <DropdownSubtextMenu.Item
                          key={option.value}
                          option={option}
                        />
                      ))
                    }
                  </DropdownSubtextMenu.Content>
                </DropdownSubtextMenu>
              )}
            </Field>

            <PropertyAddressFields
              excludeTerritories
              validators={{
                city: [validShareAbleCity],
                unit: [validShareAbleUnit],
                address: [validShareAbleAddress],
              }}
              showUnit={isSinglePropertyType(values.property_type)}
              className={styles.propertyAddressFields}
              disabledFields={[
                {
                  name: 'state',
                  tooltip:
                    'If you want to change the state of the Lease Agreement, you can do so in the Lease Specifics step of the builder.',
                },
              ]}
            />

            <Field
              component={RadioGroup}
              id="roomRentals"
              name="roomRentals"
              label={
                <div className={styles.roomRentalsWrapper}>
                  Will you have room rentals?{' '}
                  <span
                    className={styles.learnMore}
                    data-tooltip-id="learn-more-key-tip"
                  >
                    Learn more
                  </span>
                  <Tooltip id="learn-more-key-tip">
                    Room rentals are when you're renting out rooms separately
                    within the property, each with their own lease.
                  </Tooltip>
                </div>
              }
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              validate={fieldRequired}
            />

            <MultiCondition
              condition={({ property_type, roomRentals }) =>
                isSinglePropertyType(property_type) && roomRentals
              }
            >
              <div className={styles.unitRoomContainer}>
                <div className={styles.sectionTitle}>Add Room</div>
                <p className={styles.sectionDescription}>
                  Start with one room, even if you have more. You'll be able to
                  set up the rest later.
                </p>
                <Field
                  id={`room_name`}
                  name={`room_name`}
                  component={Input}
                  label="Room Name"
                  validate={fieldRequired}
                  maxLength={50}
                />
              </div>
            </MultiCondition>
            <MultiCondition
              condition={({ property_type }) =>
                isSinglePropertyType(property_type) === false
              }
            >
              <div className={styles.unitRoomContainer}>
                <div className={styles.sectionTitle}>Add Unit</div>
                <p className={styles.sectionDescription}>
                  Start with one unit, even if you have more. You'll be able to
                  set up the rest later.
                </p>
                <Field
                  id={`unit_name`}
                  name={`unit_name`}
                  className={styles.field}
                  component={Input}
                  label="Unit #"
                  validate={fieldRequired}
                  maxLength={50}
                  placeholder="Address 2 (Apt, Suite, etc.)"
                />
                <Condition when="roomRentals" is={true}>
                  <Field
                    id={`room_name`}
                    name={`room_name`}
                    component={Input}
                    label="Room Name"
                    validate={fieldRequired}
                    maxLength={50}
                  />
                </Condition>
              </div>
            </MultiCondition>
            <div className={styles.actions}>
              <Button
                className={styles.signUpButton}
                loading={submitting}
                type="submit"
              >
                Save & Sign Up
              </Button>
            </div>
          </form>
        )}
      </Form>
    </Modal>
  );
};

AddRentalAddressModal.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  stateFullName: PropTypes.string,
  segmentLocation: PropTypes.string,
  segmentData: PropTypes.object,
};

AddRentalAddressModal.defaultProps = {
  initialValues: {},
};

export default AddRentalAddressModal;
