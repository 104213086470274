import React from 'react';
import { Field, useField } from 'react-final-form';

import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import CodeViolation from '../../../../../../../icons/CodeViolation';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './CodeViolations.module.scss';

const CodeViolations = () => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  const {
    input: { value: hasCodeViolations },
  } = useField('custom_state_data.has_code_violations');

  return (
    <div className={styles.container}>
      <SectionTitle title="Code Violations" icon={CodeViolation} />
      <Field
        component={RadioGroup}
        id="custom_state_data.has_code_violations"
        name="custom_state_data.has_code_violations"
        className={styles.radioWrapper}
        label={
          <div className={styles.label}>
            <div>Do you have any past code violations on the premises?</div>
            <div className={styles.contextCopy}>
              Wisconsin law requires that you disclose this.
            </div>
          </div>
        }
        options={[
          {
            'id': 'has_code_violations_choice_yes',
            'value': true,
            'label': 'Yes',
            'data-qa':
              'lease-agreements-lead-paint-disclosure-required-radio-yes',
          },
          {
            id: 'has_code_violations_choice_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      {hasCodeViolations && (
        <div className={styles.textareaWrapper}>
          <p>
            If there are any past code violations on the premises, please
            describe the type of the violation and if and when it was fixed.
            Examples of code violations include hot or cold water not running,
            structural issues or non-operational heating, electrical or plumbing
            conditions:{' '}
          </p>
          <Field
            as="textarea"
            id="custom_state_data.code_violations_explanation"
            component={Input}
            name="custom_state_data.code_violations_explanation"
            maxLength={2000}
            help={(val = '') => `${val.length} / 2000 characters used`}
            validate={fieldRequired}
          />
        </div>
      )}
    </div>
  );
};

export default CodeViolations;
