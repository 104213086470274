import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Expense = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 25" {...rest} fill={color}>
    <path d="M5.16 1.983a.6.6 0 0 1 .654.13l1.228 1.228L8.27 2.113a.6.6 0 0 1 .849 0l1.228 1.228 1.228-1.228a.6.6 0 0 1 .848 0l1.228 1.228 1.228-1.228a.6.6 0 0 1 .848 0l1.228 1.228 1.228-1.228a.6.6 0 0 1 1.024.425v19a.6.6 0 0 1-1.024.424l-1.228-1.228-1.228 1.228a.6.6 0 0 1-.848 0l-1.228-1.228-1.228 1.228a.6.6 0 0 1-.848 0l-1.228-1.228-1.228 1.228a.6.6 0 0 1-.849 0l-1.228-1.228-1.228 1.228a.6.6 0 0 1-1.024-.424v-19a.6.6 0 0 1 .37-.555Zm.83 2.003V20.09l.628-.628a.6.6 0 0 1 .848 0l1.228 1.228 1.228-1.228a.6.6 0 0 1 .849 0l1.228 1.228 1.228-1.228a.6.6 0 0 1 .848 0l1.228 1.228 1.228-1.228a.6.6 0 0 1 .849 0l.627.628V3.986l-.628.628a.6.6 0 0 1-.848 0l-1.228-1.228-1.228 1.228a.6.6 0 0 1-.848 0l-1.228-1.228-1.228 1.228a.6.6 0 0 1-.849 0L8.694 3.386 7.466 4.614a.6.6 0 0 1-.848 0l-.628-.628Z" />
    <path d="M7.268 7.494a.6.6 0 0 1 .6-.6h6.609a.6.6 0 1 1 0 1.2H7.868a.6.6 0 0 1-.6-.6ZM7.268 9.972a.6.6 0 0 1 .6-.6h3.305a.6.6 0 1 1 0 1.2H7.868a.6.6 0 0 1-.6-.6ZM7.268 12.45a.6.6 0 0 1 .6-.6h3.305a.6.6 0 1 1 0 1.2H7.868a.6.6 0 0 1-.6-.6ZM7.268 14.929a.6.6 0 0 1 .6-.6h2.479a.6.6 0 0 1 0 1.2H7.868a.6.6 0 0 1-.6-.6ZM14.89 15.981a.6.6 0 0 1 .6.6v.826a.6.6 0 1 1-1.2 0v-.826a.6.6 0 0 1 .6-.6ZM14.89 10.198a.6.6 0 0 1 .6.6v.826a.6.6 0 0 1-1.2 0v-.826a.6.6 0 0 1 .6-.6Z" />
    <path d="M14.89 12.225a.637.637 0 0 0-.64.639c0 .354.286.639.64.639 1.017 0 1.84.822 1.84 1.839s-.823 1.839-1.84 1.839a1.837 1.837 0 0 1-1.84-1.84.6.6 0 1 1 1.2 0c0 .355.286.64.64.64.354 0 .64-.285.64-.64a.638.638 0 0 0-.64-.638 1.837 1.837 0 0 1-1.84-1.84c0-1.016.823-1.838 1.84-1.838 1.017 0 1.84.822 1.84 1.839a.6.6 0 0 1-1.2 0 .637.637 0 0 0-.64-.64Z" />
  </svg>
);

Expense.defaultProps = {
  width: 24,
  height: 25,
  color: colors.primary,
};

Expense.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Expense);
