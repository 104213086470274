import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import PlainRadioButton from '../PlainRadioButton';

import styles from './SelectionRadioBox.module.scss';

const SelectionRadioBox = ({
  checked,
  value,
  onChange,
  name,
  'icon': Icon,
  iconProps,
  iconClassName,
  labelClassName,
  subLabelClassName,
  footerNoteClassName,
  label,
  subLabel,
  footerNote,
  className,
  vertical,
  disabled,
  style,
  id,
  'data-qa': dataQa,
}) => (
  <div className={cx(styles.wrap, className)} style={style}>
    <label
      className={cx(styles.container, {
        [styles.horizontal]: !vertical,
        [styles.vertical]: vertical,
        [styles.checked]: checked,
        [styles.disabled]: disabled,
      })}
    >
      <PlainRadioButton
        checked={checked}
        value={value}
        id={id}
        name={name}
        disabled={disabled}
        className={styles.radio}
        onChange={() => onChange(value)}
        data-qa={dataQa}
      />
      {Icon && (
        <>
          <Icon className={cx(styles.icon, iconClassName)} {...iconProps} />
        </>
      )}
      <div className={cx(styles.label, labelClassName)}>{label}</div>
      {subLabel && (
        <div className={cx(styles.subLabel, subLabelClassName)}>{subLabel}</div>
      )}
      {footerNote && (
        <div className={cx(styles.footerNote, footerNoteClassName)}>
          {footerNote}
        </div>
      )}
    </label>
  </div>
);

SelectionRadioBox.propTypes = {
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  iconProps: PropTypes.object,
  iconClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.node,
  className: PropTypes.string,
  vertical: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

export default SelectionRadioBox;
