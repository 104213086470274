import React, { useEffect, useRef, useState } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { getUnitRoomOptions } from '../../helpers/propertyUnitRooms';
import buildListingsDropdownOptions from '../../pages/properties/maintenance/utils/buildListingsDropdownOptions';
import fieldRequired from '../../validators/fieldRequired';
import SelectField from '../SelectField';

import styles from './PropertyUnitRoomDropdowns.module.scss';

const PropertyUnitRoomDropdowns = ({
  properties,
  onListingChange,
  lockPreSelectedFields,
  label = 'Property',
  className,
  required,
  dataQaTags,
  specialOptions = [],
  empty = true,
  /**
   * As default we will require the user to select a leaf. But for
   * small use cases we allow the selection of a parent (a listing that
   * has children)
   */
  allowParentSelection = false,
  name = 'listing_id',
}) => {
  const form = useForm();
  const didMountRef = useRef(false);
  const [preSelectedFields, setPreSelectedFields] = useState([]);

  const {
    input: { value: property_id },
  } = useField('property_id');
  const {
    input: { value: unit_id },
  } = useField('unit_id');
  const {
    input: { value: room_id },
  } = useField('room_id');

  const selectedListingId = room_id || unit_id || property_id;

  useEffect(() => {
    if (didMountRef.current) {
      if (onListingChange && selectedListingId !== '') {
        onListingChange(selectedListingId);
      }
      form.change(name, selectedListingId);
    } else {
      didMountRef.current = true;
      setPreSelectedFields([property_id, unit_id, room_id].filter(Boolean));
    }
  }, [selectedListingId, property_id, unit_id, room_id]);

  const { unitOptions, roomOptions } = getUnitRoomOptions(
    properties,
    property_id,
    unit_id,
  );

  // Ensure that the initial values are set after options are loaded
  useEffect(() => {
    if (unitOptions.length > 0 && unit_id) {
      form.change('unit_id', unit_id);
    }
    if (roomOptions.length > 0 && room_id) {
      form.change('room_id', room_id);
    }
  }, [unitOptions, roomOptions, unit_id, room_id]);

  const shouldLockProperty =
    lockPreSelectedFields && preSelectedFields.includes(property_id);
  const shouldLockUnit =
    lockPreSelectedFields && preSelectedFields.includes(unit_id);
  const shouldLockRoom =
    lockPreSelectedFields && preSelectedFields.includes(room_id);

  return (
    <div
      className={cx(styles.propertyUnitRoomDropdowns, {
        [className]: className,
      })}
    >
      <OnChange name="unit_id">
        {() => {
          form.change('room_id', null);
        }}
      </OnChange>

      <OnChange name="property_id">
        {() => {
          form.change('room_id', null);
          form.change('unit_id', null);
        }}
      </OnChange>

      <Field
        label={label}
        component={SelectField}
        name="property_id"
        id="property"
        className={cx(styles.property, {
          [styles.fieldWithMargin]: selectedListingId,
          [styles.disabled]: shouldLockProperty,
        })}
        selectClassName={styles.select}
        options={[
          ...specialOptions,
          ...buildListingsDropdownOptions(properties),
        ]}
        empty={!shouldLockProperty && empty}
        disabled={shouldLockProperty}
        data-qa={dataQaTags?.property}
        validate={required ? fieldRequired : () => {}}
      />
      <div className={styles.unitAndRoomContainer}>
        {unitOptions.length > 0 && (
          <Field
            label="Unit"
            component={SelectField}
            name="unit_id"
            id="unit_id"
            className={cx(styles.property, {
              [styles.disabled]: shouldLockUnit,
            })}
            selectClassName={styles.select}
            labelProps={
              !shouldLockUnit && allowParentSelection
                ? { hint: '(Optional)' }
                : {}
            }
            options={unitOptions}
            empty={!shouldLockUnit}
            disabled={shouldLockUnit}
            validate={!allowParentSelection && fieldRequired}
          />
        )}
        {roomOptions.length > 0 && (
          <Field
            label="Room"
            component={SelectField}
            name="room_id"
            id="room_id"
            className={cx(styles.property, {
              [styles.disabled]: shouldLockRoom,
            })}
            selectClassName={styles.select}
            labelProps={
              !shouldLockRoom && allowParentSelection
                ? { hint: '(Optional)' }
                : {}
            }
            options={roomOptions}
            empty={!shouldLockRoom}
            disabled={shouldLockRoom}
            validate={!allowParentSelection && fieldRequired}
          />
        )}
      </div>
      <Field name={name} type="hidden" component="input" />
    </div>
  );
};

PropertyUnitRoomDropdowns.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object).isRequired,
  onListingChange: PropTypes.func,
  lockPreSelectedFields: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  dataQaTas: PropTypes.object,
  specialOptions: PropTypes.arrayOf(PropTypes.object),
  empty: PropTypes.bool,
  allowParentSelection: PropTypes.bool,
  name: PropTypes.string,
};

export default PropertyUnitRoomDropdowns;
