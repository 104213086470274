import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './IconLabel.module.scss';

const IconLabel = ({
  label,
  labelClassName,
  icon: Icon,
  className,
  iconProps,
}) => (
  <div className={cx(styles.wrapper, className)}>
    <Icon {...iconProps} />
    <p className={cx(styles.label, labelClassName)}>{label}</p>
  </div>
);

IconLabel.propTypes = {
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  icon: PropTypes.object,
  iconProps: PropTypes.object,
  className: PropTypes.string,
};

export default IconLabel;
