import React from 'react';
import get from 'lodash.get';

import { useUserProfile } from '../../../core/TTgraphql';
import { useConfig } from '../../../providers/ConfigProvider';

import PremiumIsActiveModal from './PremiumIsActiveModal';

const PremiumIsActiveModalWrapper = (props) => {
  const { PRIVATE_BASE_PATH } = useConfig();
  const { user, loading } = useUserProfile();

  const reiHubSubscribed = get(user, 'reihub_subscription_subscribed', false);
  const listingsCount = get(user, 'reihub_listings_count', 0);

  if (loading) return null;

  return (
    <PremiumIsActiveModal
      showREIHubOption={!reiHubSubscribed}
      privateBasePath={PRIVATE_BASE_PATH}
      listingsCount={listingsCount}
      {...props}
    />
  );
};

export default PremiumIsActiveModalWrapper;
