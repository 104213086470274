import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Lease = (props) => (
  <svg {...props} viewBox={props.viewBox || '0 0 22 25'}>
    <path
      fill={props.color}
      fillRule="evenodd"
      d="m19.85 13.943 1.937 2.152a.733.733 0 0 1-.067 1.033l-6.909 6.221a.754.754 0 0 1-.356.18l-2.781.556a.714.714 0 0 1-.828-.92l.844-2.708a.754.754 0 0 1 .215-.335l6.91-6.221a.733.733 0 0 1 1.034.042ZM13.318 0c.21 0 .413.069.577.193l.094.08 4.39 4.344.013.012c.02.02.038.042.056.064l-.068-.076a.928.928 0 0 1 .26.503l.002.012.002.017.008.115v7.238a.932.932 0 0 1-.943.922.936.936 0 0 1-.936-.806l-.008-.116V6.185h-4.18a.936.936 0 0 1-.935-.805l-.007-.116V1.842h-9.09v19.873h6.374c.48 0 .878.351.936.805l.007.116a.93.93 0 0 1-.825.914l-.118.007H1.61a.936.936 0 0 1-.936-.805l-.007-.116V.921c0-.47.36-.857.825-.914L1.61 0h11.709Zm.86 20.552-.804.726-.191.613.633-.126.812-.733-.45-.48Zm3.258-2.932-1.855 1.67.449.48 1.838-1.655-.432-.495Zm-9.277-.248c.521 0 .943.412.943.921a.93.93 0 0 1-.824.914l-.119.007H4.537a.932.932 0 0 1-.943-.921c0-.47.36-.857.825-.914l.118-.007H8.16Zm11.035-1.335-.357.32.433.495.364-.327-.44-.488ZM8.16 14.477c.521 0 .943.412.943.92a.93.93 0 0 1-.824.915l-.119.007H4.537a.932.932 0 0 1-.943-.921c0-.47.36-.857.825-.914l.118-.008H8.16Zm4.391-4.343c.52 0 .943.412.943.92a.93.93 0 0 1-.825.915l-.118.007H4.537a.932.932 0 0 1-.943-.921c0-.47.36-.857.825-.914l.118-.007h8.014Zm0-2.896c.52 0 .943.413.943.921a.93.93 0 0 1-.825.915l-.118.007H4.537a.932.932 0 0 1-.943-.922c0-.47.36-.857.825-.914l.118-.007h8.014Zm-4.39-2.895c.52 0 .942.412.942.921a.93.93 0 0 1-.824.914l-.119.007H4.537a.932.932 0 0 1-.943-.92c0-.47.36-.858.825-.915l.118-.007H8.16ZM13.53 2.44v1.901h1.922L13.53 2.441Z"
    />
  </svg>
);

Lease.defaultProps = {
  width: 22,
  height: 25,
};

Lease.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Lease);
