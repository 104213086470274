import React from 'react';
import PropTypes from 'prop-types';

import PetsThickIcon from '../../../../icons/PetsThick';
import SectionTitle from '../../common/SectionTitle';

import styles from './SectionPetsSmokingOther.module.scss';

export const SectionPetsSmokingOtherPetsSection = ({ children }) => {
  return (
    <div className={styles.section}>
      <SectionTitle title="Pets" icon={PetsThickIcon} />
      <label className={styles.headerLabel}>
        Will there be pet(s) on this lease?
      </label>
      {children}
    </div>
  );
};

SectionPetsSmokingOtherPetsSection.propTypes = {
  children: PropTypes.node,
};
