import isSinglePropertyType from '../../../../helpers/isSinglePropertyType';

export const preparePayloadPropertyDetailsPublicLA = ({
  property_type,
  roomRentals,
  address,
  unit,
  city,
  state,
  zip,
  room_name,
  unit_name,
}) => {
  const isSingleProperty = isSinglePropertyType(property_type);

  return {
    property_type,
    address,
    unit,
    city,
    state,
    zip,
    ...(isSingleProperty && roomRentals ? { rooms: [{ room_name }] } : {}),
    ...(!isSingleProperty
      ? {
          units: [
            {
              unit: unit_name,
              ...(roomRentals ? { rooms: [{ room_name }] } : {}),
            },
          ],
        }
      : {}),
  };
};
