import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './HelpfulInfoList.module.scss';

const HelpfulInfoList = ({ children, className }) => {
  return <ul className={cx(styles.helpfulInfoList, className)}>{children}</ul>;
};

HelpfulInfoList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default HelpfulInfoList;
