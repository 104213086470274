import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from './BankInfoAZ.module.scss';

const BankInfoAZ = ({ className }) => {
  return (
    <div className={cx(styles.container, className)}>
      <HelpfulInfoCard
        Icon={ResourcesIcon}
        className={styles.helpfulInfoCard}
        title="Arizona Security Deposit Laws"
        collapsable
      >
        <HelpfulInfoList>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            The maximum security deposit you can collect is 1.5 times the
            monthly rent.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            The security deposit must be returned within 14 days of the lease
            ending.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            You must provide a blank move-in checklist to tenant at the time a
            security deposit is collected.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Tenant has the right to request to be present at the move-out
            inspection.
          </HelpfulInfoListItem>
        </HelpfulInfoList>
      </HelpfulInfoCard>
    </div>
  );
};

BankInfoAZ.propTypes = {
  className: PropTypes.string,
};

export default BankInfoAZ;
