import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ESignThickIcon from '../../../../icons/ESignThick';
import SectionTitle from '../../common/SectionTitle';

import styles from './SectionAdditionalProvisions.module.scss';

export const SectionAdditionalProvisionsBillOfRightsSection = ({
  children,
}) => (
  <div className={cx(styles.column, styles.leadSection)}>
    <SectionTitle
      title="Miami/Dade County Tenant Bill of Rights"
      icon={ESignThickIcon}
    />
    {children}
  </div>
);

SectionAdditionalProvisionsBillOfRightsSection.propTypes = {
  children: PropTypes.node,
};
