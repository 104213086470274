import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const PaperPicture = ({ width, height, color }) => (
  <svg width={width} height={height}>
    <path
      fill={color}
      fillRule="nonzero"
      d="M15.3.667c.071 0 .141.009.207.026l.02.006a.8.8 0 0 1 .175.073l.02.013a.778.778 0 0 1 .155.12l6.53 6.531a.816.816 0 0 1 .24.578v18.503c0 .45-.366.816-.817.816H1.15a.816.816 0 0 1-.817-.816V1.483c0-.45.366-.816.817-.816H15.3Zm-.818 1.632H1.966v23.402h19.048V8.83h-5.715a.816.816 0 0 1-.816-.816V2.299Zm1.056 10.562 2.721 9.252a.816.816 0 0 1-.783 1.046H6.592a.816.816 0 0 1-.73-1.181l2.72-5.442a.816.816 0 0 1 1.308-.213l1.405 1.405 2.743-5.028a.816.816 0 0 1 1.5.16Zm-1.01 2.35-2.322 4.257a.816.816 0 0 1-1.293.187l-1.377-1.376-1.624 3.248h8.472l-1.856-6.316ZM8.224 8.465a2.448 2.448 0 1 1 0 4.898 2.448 2.448 0 1 1 0-4.898Zm0 1.633a.816.816 0 1 0-.001 1.631.816.816 0 0 0 .001-1.631Zm7.891-6.644v3.743h3.743l-3.743-3.743Z"
    />
  </svg>
);

PaperPicture.defaultProps = {
  width: 23,
  height: 28,
  color: colors.text,
};

PaperPicture.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(PaperPicture);
