(function () {
  let hidden = 'hidden';
  function onchange(evt) {
    const evtMap = {
      focus: true,
      focusin: true,
      pageshow: true,
      blur: false,
      focusout: false,
      pagehide: false,
    };

    evt = evt || window.event;
    if (evt.type in evtMap) window.userIsActive = evtMap[evt.type];
    else window.userIsActive = !this[hidden];
  }

  // Standards:
  if (hidden in document)
    document.addEventListener('visibilitychange', onchange);
  else if ((hidden = 'mozHidden') in document)
    document.addEventListener('mozvisibilitychange', onchange);
  else if ((hidden = 'webkitHidden') in document)
    document.addEventListener('webkitvisibilitychange', onchange);
  else if ((hidden = 'msHidden') in document)
    document.addEventListener('msvisibilitychange', onchange);
  // IE 9 and lower:
  else if ('onfocusin' in document)
    document.onfocusin = document.onfocusout = onchange;
  // All others:
  else
    window.onpageshow =
      window.onpagehide =
      window.onfocus =
      window.onblur =
        onchange;

  // set the initial state (but only if browser supports the Page Visibility API)
  if (document[hidden] !== undefined)
    onchange({ type: document[hidden] ? 'blur' : 'focus' });
})();
