import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import Decimal from 'jsdecimal';
import omit from 'lodash.omit';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';

import AskForAccountPasswordModal from '../../../../../components/AskForAccountPasswordModal';
import BankAccountAlreadyAddedModal from '../../../../../components/BankAccountAlreadyAddedModal/BankAccountAlreadyAddedModal';
import LoadingScreen from '../../../../../components/LoadingScreen';
import { useErrorToast, useInfoToast } from '../../../../../components/Toast';
import { useUserProfile } from '../../../../../core/TTgraphql';
import getEntities from '../../../../../graphql/queries/entities/entities.graphql';
import paymentRequestRulesQueryGQL from '../../../../../graphql/queries/payment_request_rules.graphql';
import { getUserBankAccounts } from '../../../../../helpers/getUserBankAccounts';
import handleGraphQLErrors from '../../../../../helpers/handleGraphQLErrors';
import normalizeLateFeeAmount from '../../../../../helpers/normalizeLateFeeAmount';
import { useRenderOutside } from '../../../../../helpers/render-outside';
import { segmentTracking } from '../../../../../services/utilities/segment';
import {
  useAddBankAccount,
  useEditBankAccountName,
  useEditPaymentRequestRules,
  usePaymentRequestRules,
  useRemoveBankAccount,
} from '../../../usePayments';
import AddBankInfoManually from '../../account-setup/AddBankInfoManually';
import StripeAccountSetupFormsWrapper from '../StripeAccountSetupFormsWrapper';

import { BankStepNoBankAccounts, BankStepOptions } from './BankStepOptions';

const BankWrapper = ({ isEdit, onNext, location, leaseId, hideCancel }) => {
  const queryParams = parse(location.search);
  const history = useHistory();
  const { user, loading: loadingUser } = useUserProfile({ polling: false });
  const [getEntitiesQuery] = useLazyQuery(getEntities);

  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showBankStep, setShowBankStep] = useState(
    isEdit || user?.payments_data != null,
  );
  const [showManualBank, setShowManualBank] = useState(false);

  const isPremiumUser = user.premium_subscription_subscribed;

  const showInfoMessage = useInfoToast();
  const showErrorMessage = useErrorToast();
  const renderOutside = useRenderOutside();

  const [addBankAccount, { loading: loadingAddBankAccount }] =
    useAddBankAccount();

  const [removeBankAccountMut, { loading: loadingRemoveBankAccount }] =
    useRemoveBankAccount({
      refetchQueries: [
        {
          query: getEntities,
        },
        {
          query: paymentRequestRulesQueryGQL,
          variables: {
            lease_id: leaseId,
          },
        },
      ],
    });

  const [editPaymentRequestRules] = useEditPaymentRequestRules({ leaseId });

  const { rules, loading: loadingrules } = usePaymentRequestRules({
    variables: { lease_id: leaseId },
    skip: !leaseId,
  });

  const [editBankAccountMutation] = useEditBankAccountName();

  const mappedRules =
    rules.map((node) => ({
      ...node,
      amount: (node.amount / 100).toFixed(2).toString(),
      late_fee_amount: normalizeLateFeeAmount(node),
    })) || [];

  useEffect(() => {
    setShowBankStep(isEdit || user?.payments_data != null);
  }, [isEdit, user]);

  useEffect(() => {
    setShowManualBank(queryParams.manually === 'true');
  }, [queryParams]);

  const removeBankAccount = async (id) => {
    try {
      await removeBankAccountMut({
        variables: {
          id,
        },
      });
    } catch (e) {
      handleGraphQLErrors(e, {
        default: () => {
          showErrorMessage('An error has occurred');
        },
      });
    }
  };

  const handleSuccess = async (publicToken, accounts) => {
    try {
      const { account_id: accountId } = accounts;

      await addBankAccount({
        variables: { plaidPublicToken: publicToken, plaidAccountId: accountId },
      });

      showInfoMessage('Bank account added!');

      setShowBankStep(true);
    } catch (err) {
      handleGraphQLErrors(err, {
        804: async () => {
          await getEntitiesQuery();
          renderOutside((done) => (
            <BankAccountAlreadyAddedModal onClose={done} />
          ));
        },
        default: () => {
          showErrorMessage('An error has occurred');
        },
      });
    }
  };

  const handleAddBankManually = () => {
    segmentTracking('add_manually clicked', {
      location: 'Add Bank Account',
    });
    if (!user.has_password || !location?.state?.fromSettings) {
      history.push({
        search: stringify(
          Object.assign({}, parse(location.search), { manually: true }),
        ),
      });
      return;
    }
    setShowPasswordModal(true);
  };

  const onValidationOk = () => {
    setShowPasswordModal(null);
    history.push({
      search: stringify(
        Object.assign({}, parse(location.search), { manually: true }),
      ),
    });
  };

  const onNextWrapper = async (data) => {
    try {
      const rulesToUpdate = data.paymentRequestRules;

      /**
       * If the user is not premium, then we left the charges
       * with NULL destination_id as it means using the default account
       */
      if (rulesToUpdate.length && isPremiumUser) {
        await editPaymentRequestRules({
          variables: {
            rules: rulesToUpdate.map((r) => ({
              id: r.id,
              amount: Decimal(r.amount).mul(100).toFloat(),
              type: r.type,
              category: r.category,
              destination_id: r.destination_id,
            })),
          },
        });
      }

      return onNext();
    } catch (e) {
      showErrorMessage('An error has occurred.');
    }
  };

  const onUpdateBankAccountNickname = async (id, nickname) => {
    try {
      await editBankAccountMutation({
        variables: {
          id,
          nickname,
        },
      });
    } catch (e) {
      console.log(e);
      showErrorMessage('An error has occurred.');
    }
  };

  if (showManualBank) {
    return (
      <AddBankInfoManually
        hideBack
        skipSuccessMessage
        onSuccess={() => {
          history.push({
            search: stringify(omit(parse(location.search), 'manually')),
          });
        }}
        onCancelClick={() =>
          history.push({
            search: stringify(omit(parse(location.search), 'manually')),
          })
        }
        hideCancel={hideCancel}
      />
    );
  }

  if (
    loadingUser ||
    loadingAddBankAccount ||
    loadingRemoveBankAccount ||
    loadingrules
  )
    return <LoadingScreen loading />;

  const getContent = () => {
    const bankAccounts = getUserBankAccounts(user);

    if (showBankStep) {
      if (!bankAccounts?.length) {
        return (
          <BankStepNoBankAccounts
            onPlaidSuccess={handleSuccess}
            onAddBankManually={handleAddBankManually}
          />
        );
      }

      return (
        <BankStepOptions
          paymentRequestRules={mappedRules}
          onNext={onNextWrapper}
          bankAccounts={bankAccounts}
          onAddBankManually={handleAddBankManually}
          onPlaidSuccess={handleSuccess}
          user={user}
          onRemoveBankAccount={removeBankAccount}
          onUpdateBankAccountNickname={onUpdateBankAccountNickname}
        />
      );
    }

    if (!loadingUser) {
      return (
        <StripeAccountSetupFormsWrapper
          hideBackButton
          user={user}
          onSuccess={() => setShowBankStep(true)}
          title="Add Bank Details"
          withSubtitle
        />
      );
    }
  };

  return (
    <>
      {getContent()}
      <AskForAccountPasswordModal
        open={showPasswordModal}
        onClose={() => setShowPasswordModal(null)}
        onPasswordValid={onValidationOk}
      />
    </>
  );
};

BankWrapper.propTypes = {
  isEdit: PropTypes.bool,
  location: PropTypes.object,
  leaseId: PropTypes.string,
  onNext: PropTypes.func.isRequired,
  hideCancel: PropTypes.bool,
};

export default withRouter(BankWrapper);
