import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import RadioGroup from '../../../../../../../components/RadioGroup';
import RealEstateHouseDollarThickIcon from '../../../../../../../icons/RealEstateHouseDollarThick';
import TurboTenantSmallLogo from '../../../../../../../icons/TurboTenantSmallLogo';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './RentControlDisclosureCA.module.scss';

const RentControlDisclosureCA = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle
        title="Rent Control Disclosure"
        icon={RealEstateHouseDollarThickIcon}
      />
      <label className={styles.label}>
        Is the property subject to the rent control limits (10% maximum rent
        increase per year) imposed by Section 1947.12 of the CA Civil Code?
      </label>
      <a
        className={cx(styles.link, styles.linkStyle)}
        href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=1947.12.&lawCode=CIV"
        target="_blank noopener noreferrer"
      >
        Find more information here.
      </a>
      <Field
        component={RadioGroup}
        id="custom_state_data.rent_control_disclosure"
        name="custom_state_data.rent_control_disclosure"
        className={styles.radioWrapper}
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'rent_control_disclosure_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'rent_control_disclosure_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <label className={styles.label}>
        Is the property subject to the just cause requirements of Section 1946.2
        of the Civil Code?
      </label>
      <p className={styles.text}>
        Just cause limits the reasons you can end a lease when all tenants have
        occupied the premises continuously for 12 months or more.{' '}
        <a
          className={styles.linkStyle}
          href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?sectionNum=1947.12.&lawCode=CIV"
          target="_blank"
          rel="noopener noreferrer"
        >
          Find the just cause requirements here
        </a>
        .
      </p>
      <div className={styles.listWrapper}>
        <span className={styles.listTitle}>
          This does not apply to you if <i>any</i> of these are true:
        </span>
        <HelpfulInfoList>
          <HelpfulInfoListItem className={styles.listItem}>
            You have affordable housing designation
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.listItem}>
            Your property is a duplex and you live in one of the units
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.listItem}>
            You have a multi-family property with a certificate of occupancy
            issued 15 years ago from building completion
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.listItem}>
            You have a non-owner occupied single family property held in title
            by a natural person (not a corporation)
          </HelpfulInfoListItem>
        </HelpfulInfoList>
      </div>
      <Field
        component={RadioGroup}
        id="custom_state_data.just_cause_requirements"
        name="custom_state_data.just_cause_requirements"
        className={styles.radioWrapper}
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'just_cause_requirements_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'just_cause_requirements_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <HelpfulInfoCard
        noTitle
        Icon={TurboTenantSmallLogo}
        iconProps={{ className: styles.cardLogo }}
      >
        We will provide your tenant with the proper notice in the lease
        agreement
      </HelpfulInfoCard>
    </div>
  );
};

RentControlDisclosureCA.propTypes = {
  className: PropTypes.string,
};

export default RentControlDisclosureCA;
