import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import { getStateLeaseHelpArticle } from '../../../../../../../constants/lease_agreement/state-lease-help-articles';
import ResourcesIcon from '../../../../../../../icons/resources';
import { getStateFullName } from '../../../../../../../services/utilities';

import styles from '../../../BankInfo.module.scss';

const BankInfo = ({ className, leaseAgreementSelection, state }) => {
  const renderVermontDepositLaws = () => (
    <>
      <HelpfulInfoListItem className={styles.helpfulItem}>
        No statutory minimum deposit amount in Vermont. Typically, deposits are
        1-2 times the monthly rent.
      </HelpfulInfoListItem>
      <HelpfulInfoListItem className={styles.helpfulItem}>
        Additional pet deposits are allowed.
      </HelpfulInfoListItem>
      <HelpfulInfoListItem className={styles.helpfulItem}>
        Security deposits must be returned to Tenant within 14 days after Lease
        termination.
      </HelpfulInfoListItem>
      <HelpfulInfoListItem className={styles.helpfulItem}>
        At that time, you must also hand deliver or mail a written statement to
        Tenant’s last known address itemizing any lawful deductions.
      </HelpfulInfoListItem>
      <HelpfulInfoListItem className={styles.helpfulItem}>
        If you do not return the security deposit within 14 days, you must
        refund the full amount to Tenant.
      </HelpfulInfoListItem>
    </>
  );

  const renderBurlingtonBarreDepositLaws = () => (
    <>
      <HelpfulInfoListItem className={styles.helpfulItem}>
        Security deposit and other deposits must not exceed 1 month’s rent.
      </HelpfulInfoListItem>
      <HelpfulInfoListItem className={styles.helpfulItem}>
        An additional pet deposit is allowed at ½ month’s rent.
      </HelpfulInfoListItem>
      <HelpfulInfoListItem className={styles.helpfulItem}>
        Security deposits must be deposited into interest-bearing account
        earning interest at current Vermont bank passbook savings rate for the
        benefit of Tenant (check your local bank for current rate).
      </HelpfulInfoListItem>
      <HelpfulInfoListItem className={styles.helpfulItem}>
        Security deposits must be returned to Tenant within 14 days after Lease
        termination.
      </HelpfulInfoListItem>
      <HelpfulInfoListItem className={styles.helpfulItem}>
        At that time, you must also hand-deliver or mail by certified mail to
        Tenant’s last known address, a statement itemizing any lawful
        deductions.
      </HelpfulInfoListItem>
      <HelpfulInfoListItem className={styles.helpfulItem}>
        In that statement, you must include information on Tenant’s opportunity
        for a hearing before Burlington or Barre Housing Board regarding any
        deductions made to Tenant’s security deposit.
      </HelpfulInfoListItem>
      <HelpfulInfoListItem className={styles.helpfulItem}>
        Interest must be sent, or credited for any funds owed to you, to Tenant
        at Lease termination.
      </HelpfulInfoListItem>
      <HelpfulInfoListItem className={styles.helpfulItem}>
        If you do not return the security deposit within 14 days, you must
        refund the full amount to Tenant.
      </HelpfulInfoListItem>
    </>
  );

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <HelpfulInfoCard
          Icon={ResourcesIcon}
          className={styles.helpfulInfoCard}
          title={`${getStateFullName(
            state,
            leaseAgreementSelection,
          )} Security Deposit Laws`}
          color="iceCold"
          collapsable
        >
          <p>
            Though they're included in the lease agreement, we've highlighted
            some rules you need to know:
          </p>
          <HelpfulInfoList>
            {leaseAgreementSelection === 'burlingtonBarre'
              ? renderBurlingtonBarreDepositLaws()
              : renderVermontDepositLaws()}
            <div className={styles.learnMore}>
              <a
                href={getStateLeaseHelpArticle(state, leaseAgreementSelection)}
                target="_blank noopener noreferrer"
              >
                Learn other security deposit laws
              </a>
            </div>
          </HelpfulInfoList>
        </HelpfulInfoCard>
      </div>
    </div>
  );
};

BankInfo.propTypes = {
  className: PropTypes.string,
};

export default BankInfo;
