import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from '../../../BankInfo.module.scss';

const BankInfo = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.row}>
      <HelpfulInfoCard
        collapsable
        Icon={ResourcesIcon}
        className={styles.helpfulInfoCard}
        title=" North Dakota Security Deposit Laws"
        color="iceCold"
      >
        Though they're included in the lease agreement, we've highlighted some
        rules you need to know:
        <HelpfulInfoList className={styles.helpfulInfoList}>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Security Deposit shall not exceed 1 month’s rent.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Felons and those with previous lease violations may be charged a
            security deposit up to 2 month’s rent.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Additional pet deposits can be charged no greater than $2500 or 2
            month’s rent.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            For leases longer than 9 months, security deposits must be kept in
            FDIC interest-bearing account.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Security deposits and any interest must be returned 30 days after
            Lease termination with notice itemizing any lawful deductions.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            You must mail or deliver returned security deposit, interest and/or
            notice to Tenant at Tenant’s last furnished address.
          </HelpfulInfoListItem>
          <div className={styles.learnMore}>
            <a
              href="http://support.turbotenant.com/en/articles/6217365-north-dakota-lease-agreement"
              target="_blank noopener noreferrer"
            >
              Learn other security deposit laws
            </a>
          </div>
        </HelpfulInfoList>
      </HelpfulInfoCard>
    </div>
  </div>
);

BankInfo.propTypes = {
  className: PropTypes.string,
};

export default BankInfo;
