import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Roommates from '../../../icons/Roommates';

import styles from './AuditShareLinkModal.module.scss';

const AuditShareLinkModal = ({
  className,
  containerClassName,
  open,
  onClose,
  onCopyBtnClick,
}) => {
  return (
    <Modal
      open={open}
      className={cx(styles.modal, className)}
      containerClassName={cx(styles.modalContainer, containerClassName)}
      onClose={onClose}
      showCloseIcon
    >
      <div className={styles.modalHeader}>
        <div className={styles.roommatesIconContainer}>
          <Roommates />
        </div>
      </div>
      <div className={styles.content}>
        <h2 className={styles.title}>Sharable link copied!</h2>
        <p className={styles.text}>
          This link is to your audit results only and will not include the
          original file for reference. Link valid for 30 days.
        </p>
        <Button className={styles.btn} onClick={onCopyBtnClick}>
          Copy Link Again
        </Button>
      </div>
    </Modal>
  );
};

AuditShareLinkModal.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCopyBtnClick: PropTypes.func.isRequired,
};

export default AuditShareLinkModal;
