import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import SyndicationIcon from '../../../../../../../icons/Syndication';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './InspectionCondemnationMN.module.scss';

const InspectionCondemnationMN = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle
        title="Inspection and Condemnation Disclosure"
        icon={SyndicationIcon}
      />
      <label className={styles.label}>
        Are there any outstanding health and safety inspection or condemnation
        orders with a citation issued for the premises?
      </label>
      <p className={styles.description}>
        Minnesota law requires that you disclose this along with attaching a
        copy of the citation if the inspection revealed a health or safety risk.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.condemnation_disclosure"
        name="custom_state_data.condemnation_disclosure"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'condemnation_disclosure_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'condemnation_disclosure_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition when="custom_state_data.condemnation_disclosure" is={true}>
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.condemnation_disclosure_explanation"
          name="custom_state_data.condemnation_disclosure_explanation"
          label="Please provide information on the nature of the citation and whether or not it poses a health risk. If there is a citation and it poses a health risk, you must attach a copy of the citation to the lease.  If there is a citation, but it does not pose a health risk, please state that the inspection order is available for review upon request."
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      </Condition>
    </div>
  );
};

InspectionCondemnationMN.propTypes = {
  className: PropTypes.string,
};

export default InspectionCondemnationMN;
