import React from 'react';
import PropTypes from 'prop-types';

const VirtualShowing = ({ width, height, ...rest }) => (
  <svg viewBox="0 0 48 49" fill="none" width={width} height={height} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.3333 17.6368V22.6368L27.344 22.773C27.4353 23.3445 28.0929 23.6656 28.6083 23.3435L34 19.9718V28.6351L28.6083 25.2635L28.4872 25.2004C27.9541 24.9749 27.3333 25.3623 27.3333 25.9701V30.9701H14V17.6368H27.3333Z"
      fill="#7FE3FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.1667 4.30347C41.3764 4.30347 44 6.9277 44 10.1368V38.4701C44 41.6804 41.3769 44.3035 38.1667 44.3035H9.83333C6.6231 44.3035 4 41.6804 4 38.4701V10.1368C4 6.9277 6.62363 4.30347 9.83333 4.30347H38.1667ZM38.1667 5.97013H9.83333C7.54419 5.97013 5.66667 7.84809 5.66667 10.1368V38.4701C5.66667 40.7599 7.54357 42.6368 9.83333 42.6368H38.1667C40.4564 42.6368 42.3333 40.7599 42.3333 38.4701V10.1368C42.3333 7.84809 40.4558 5.97013 38.1667 5.97013ZM28.1667 15.9701C28.6269 15.9701 29 16.3432 29 16.8035V21.1335L34.3917 17.7635C34.9071 17.4413 35.5647 17.7624 35.656 18.334L35.6667 18.4701V30.1368C35.6667 30.7913 34.9467 31.1904 34.3917 30.8435L29 27.4718V31.8035C29 32.2126 28.7052 32.5528 28.3165 32.6234L28.1667 32.6368H13.1667C12.7064 32.6368 12.3333 32.2637 12.3333 31.8035V16.8035C12.3333 16.3432 12.7064 15.9701 13.1667 15.9701H28.1667ZM27.3333 17.6368H14V30.9701H27.3333V25.9701C27.3333 25.3624 27.9541 24.9749 28.4872 25.2004L28.6083 25.2635L34 28.6351V19.9718L28.6083 23.3435C28.0929 23.6656 27.4353 23.3445 27.344 22.773L27.3333 22.6368V17.6368Z"
      fill="#042238"
    />
  </svg>
);

VirtualShowing.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default VirtualShowing;
