import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import isOwnerLoggedIn from '../core/auth/authService';
import { getConfig } from '../services/configService';
import {
  getUrlToRedirectAfterLogin,
  setUrlToRedirectAfterLogin,
} from '../services/utilities';

const authPage = (BaseComponent) => {
  const Component = (props) => {
    const { PRIVATE_BASE_PATH } = getConfig();
    const { location, history } = props;
    const { redirectUrl, buyPremium } = queryString.parse(location.search);
    const ssoLogin =
      !!history.location.pathname.match(/\/academy|forum\//g) ||
      !!(redirectUrl || '').match(/(learnworlds|academy|forum)/g);
    useEffect(() => {
      if (!isOwnerLoggedIn()) {
        return;
      }
      if (ssoLogin) {
        // If it's academy or forum login, no need to redirect
        return;
      }
      const to = getUrlToRedirectAfterLogin();
      if (to) {
        setUrlToRedirectAfterLogin('');
        history.replace(to);
      }
      if (buyPremium) {
        history.replace(`${PRIVATE_BASE_PATH}settings/billing/?premium=true`);
      } else {
        history.replace('/');
      }
    }, [isOwnerLoggedIn(), location.pathname]);

    return React.createElement(BaseComponent, props);
  };

  Component.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  return withRouter(Component);
};

export default authPage;
