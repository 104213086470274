import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Applicants = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 25" {...rest} fill={color}>
    <path d="M9.784 2.168a.57.57 0 0 1 .517.338l.92 2.015h8.208a.58.58 0 0 1 .571.588V21.58a.58.58 0 0 1-.571.588H4.57A.58.58 0 0 1 4 21.58V2.756a.58.58 0 0 1 .571-.588h5.213Zm-.363 1.176H5.143v17.648h13.714V5.696h-7.999a.57.57 0 0 1-.517-.338l-.92-2.015ZM12 8.05c1.658 0 3.003 1.4 3.003 3.125 0 .68-.21 1.324-.581 1.85 1.597.83 2.435 2.217 2.435 4.397 0 .347-.27.628-.605.628H7.748a.617.617 0 0 1-.605-.628c0-2.18.837-3.567 2.435-4.397a3.202 3.202 0 0 1-.581-1.848c0-1.728 1.342-3.127 3.003-3.127Zm0 1.258c-.992 0-1.793.835-1.793 1.869 0 .627.297 1.2.788 1.547.413.293.325.95-.148 1.116-1.513.527-2.306 1.382-2.464 2.953h7.234c-.159-1.57-.952-2.426-2.464-2.953-.473-.166-.561-.823-.149-1.116.491-.348.79-.921.79-1.549 0-1.031-.804-1.867-1.794-1.867Z" />
  </svg>
);

Applicants.defaultProps = {
  width: 24,
  height: 25,
  color: colors.primary,
};

Applicants.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Applicants);
