import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './MarketingList.module.scss';

const MarketingList = ({ items, className, itemClassName }) => {
  return (
    <div className={cx(styles.container, className)}>
      {items.map((item, index) => {
        return (
          <div
            className={cx(styles.item, itemClassName)}
            key={item.title + index}
          >
            <div className={cx(styles.iconHolder)}>
              {<item.icon className={styles.icon} />}
            </div>{' '}
            <div className={cx(styles.narrative)}>
              <div className={cx(styles.title)}>{item.title}</div>
              <div className={cx(styles.text)}>{item.text}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

MarketingList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.object,
      text: PropTypes.string,
    }),
  ),
  itemClassName: PropTypes.string,
  className: PropTypes.string,
};

export default MarketingList;
