import useWindowSize from '@rooks/use-window-size';

import { mobileBreakpoint } from '../constants/media-breakpoints';
/**
 * This hook checks for the windows size and returns true if it
 * is <= than our mobileBreakpoint defined in media-breakpoints file
 * @returns
 */
export const useIsSmallScreen = () => {
  const { innerWidth } = useWindowSize();
  return innerWidth <= mobileBreakpoint;
};
