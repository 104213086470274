import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const DollarCircled = (props) => (
  <svg {...props} viewBox={props.viewBox || '0 0 22 22'} fill={props.color}>
    <path d="M11 .333C16.891.333 21.667 5.11 21.667 11c0 5.892-4.775 10.667-10.667 10.667C5.11 21.667.333 16.89.333 11 .333 5.11 5.11.333 11 .333zm0 1.447a9.22 9.22 0 000 18.44A9.22 9.22 0 0020.22 11 9.22 9.22 0 0011 1.78zM11 4.4c.369 0 .673.276.718.633l.005.09v.721a2.985 2.985 0 012.26 2.895.723.723 0 01-1.44.09l-.006-.09c0-.587-.33-1.097-.814-1.356v2.981a2.985 2.985 0 010 5.79v.72a.723.723 0 01-1.44.091l-.006-.09v-.721a2.984 2.984 0 01-2.26-2.895.723.723 0 111.446 0c0 .587.33 1.097.813 1.356v-2.981a2.984 2.984 0 010-5.79v-.72c0-.4.325-.724.724-.724zm.723 7.503v2.712a1.536 1.536 0 000-2.712zM9.463 8.74c0 .587.33 1.098.813 1.356V7.384a1.537 1.537 0 00-.813 1.356z" />
  </svg>
);

DollarCircled.defaultProps = {
  width: 22,
  height: 22,
  color: colors.text,
};

DollarCircled.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(DollarCircled);
