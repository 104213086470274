import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import RealEstateHouseDollarThick from '../../../../../../../icons/RealEstateHouseDollarThick';
import composeValidators from '../../../../../../../validators/composeValidators';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import isLengthBetweenWithLimits from '../../../../../../../validators/isLengthBetweenWithLimits';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './RentStabilizationDisclosure.module.scss';

const RENT_STABILIZATION_MAX_CHARS = 5000;

const lessThan1000 = isLengthBetweenWithLimits(0, RENT_STABILIZATION_MAX_CHARS);

const RentStabilizationDisclosure = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  const {
    input: { value: rentStabilization },
  } = useField('custom_state_data.rent_stabilization');

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle
        title="Rent Stabilization Disclosure"
        icon={RealEstateHouseDollarThick}
      />
      <label className={styles.label}>
        Do Rent Stabilization laws apply to this property?
      </label>
      <Field
        component={RadioGroup}
        id="custom_state_data.rent_stabilization"
        name="custom_state_data.rent_stabilization"
        radioRowClassName={styles.radioRow}
        radiosContainerClassName={styles.radioContainer}
        options={[
          {
            id: 'rent_stabilization_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'rent_stabilization_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      {rentStabilization === true && (
        <>
          <p className={styles.description}>
            You must provide your tenants information on the (1) rent paid by
            previous tenants (2) any improvements and (3) past rent increases.
            Lastly, you must provide a copy of the fully signed lease agreement,
            information on rights and duties of Landlords and Tenants under Rent
            Stabilization Law, RSC, TPR and other information on Preferential
            Rents, Air Conditioner Surcharges and IAI notification requirements.
          </p>
          <Field
            as="textarea"
            component={Input}
            id="custom_state_data.rent_stabilization_disclosures"
            name="custom_state_data.rent_stabilization_disclosures"
            label="Rent Stabilization Disclosures"
            inputProps={{
              rows: '5',
              className: styles.disclosure,
            }}
            validate={composeValidators(fieldRequired, lessThan1000)}
            maxLength={RENT_STABILIZATION_MAX_CHARS}
            help={(val = '') =>
              `${val.length} / ${RENT_STABILIZATION_MAX_CHARS} characters used`
            }
          />
        </>
      )}
    </div>
  );
};

RentStabilizationDisclosure.propTypes = {
  className: PropTypes.string,
};

export default RentStabilizationDisclosure;
