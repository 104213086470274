import moment from 'moment';

const isBannerHidden = (dismissedBanners = [], banner) => {
  const bannerFound = (dismissedBanners || []).find(
    (b) => b.bannerName === banner,
  );
  if (!bannerFound) return false;

  if (bannerFound.dismissedUntil) {
    return moment(bannerFound.dismissedUntil).isAfter(moment());
  }
  return true;
};

export default isBannerHidden;
