import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Spinner from '../Spinner';

import GoogleIcon from './Icon';
import LoginTextIcon from './LoginTextIcon';
import SignupTextIcon from './SignupTextIcon';

import styles from './GoogleButton.module.scss';

const icons = {
  signup: SignupTextIcon,
  login: LoginTextIcon,
};

const GoogleButton = ({
  className,
  disabled,
  loading,
  type,
  ...otherProps
}) => {
  const GoogleText = icons[type];
  return (
    <button
      type="button"
      className={cx(
        styles.button,
        { [styles.disabled]: disabled || loading },
        className,
      )}
      disabled={disabled || loading}
      {...otherProps}
    >
      <React.Fragment>
        <div className={cx(styles.icon)}>
          <GoogleIcon />
        </div>
        {loading ? <Spinner /> : <GoogleText />}
      </React.Fragment>
    </button>
  );
};

GoogleButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['signup', 'login']),
};

export default GoogleButton;
