import React, { useState } from 'react';
import useWindowSize from '@rooks/use-window-size';
import PropTypes from 'prop-types';

import BackButton from '../../../components/BackButton';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import VideoPlayerModal from '../../../components/VideoPlayerModal';
import { smallScreenWidth } from '../../../constants/media-breakpoints';
import MarketingLayout from '../../../layout/MarketingLayout';
import { segmentTracking } from '../../../services/utilities';
import InviteRenterHowItWorks from '../../onboard/steps/InviteRenterHowItWorks';

import ChooseRentalPropertyStepForm from './ChooseRentalPropertyStepForm';

import styles from './ChooseRentalPropertyStep.module.scss';

const ChooseRentalPropertyStep = ({
  onNext,
  initialValues,
  segmentData = {},
}) => {
  const [introModal, setIntroModal] = useState(false);
  const [video, setVideo] = useState(false);

  const { innerWidth, innerHeight } = useWindowSize();
  const isSmallScreen = innerWidth < smallScreenWidth;

  const howItWorksListItems = [
    'We send the renter a link to the application.',
    `They fill out the application and pay a fee, which covers the credit, background, and eviction check.`,
    "You'll get an email when they complete the application.",
    'You can then pull the screening report.',
  ];

  return (
    <MarketingLayout
      headerClassName={styles.header}
      className={styles.container}
      side={
        <InviteRenterHowItWorks
          onPlayVideo={() => {
            setVideo(true);
            segmentTracking('play_video clicked', {
              location: 'Property - Public Application',
              ...segmentData,
            });
          }}
          onViewAppClicked={() => {
            segmentTracking('try_application clicked', {
              location: 'Property - Public Application',
            });
          }}
          listItems={howItWorksListItems}
          isPublicFlow
        />
      }
    >
      {!video ? (
        <Modal
          containerClassName={styles.modalWrap}
          open={introModal && isSmallScreen}
          fullScreen
          className={styles.modal}
          style={{
            height: `${innerHeight}px`,
          }}
          autoFocus={false}
        >
          <InviteRenterHowItWorks
            onPlayVideo={() => {
              setVideo(true);
              segmentTracking('play_video clicked', {
                location: 'Onboarding Invite to Apply - How it Works Modal',
                ...segmentData,
              });
            }}
            onViewAppClicked={() => {
              segmentTracking('view_app clicked', {
                location: 'Onboarding Invite to Apply - How it Works Modal',
                ...segmentData,
              });
            }}
            listItems={howItWorksListItems}
            isPublicFlow
          />
          <Button
            onClick={() => {
              setIntroModal(false);
            }}
            id="how_it_works_sounds_good"
            className={styles.soundGood}
          >
            Sounds Good
          </Button>
        </Modal>
      ) : (
        <VideoPlayerModal
          mobileFullScreen
          className={styles.videoPlayerModal}
          open
          url="https://vimeo.com/696720478"
          onClose={() => setVideo(false)}
        />
      )}
      <div className={styles.wrapper}>
        <BackButton className={styles.back} />
        <div className={styles.content}>
          <h2 className={styles.title}>
            What rental property are they applying to?
          </h2>
          <ChooseRentalPropertyStepForm
            className={styles.form}
            onSubmit={(data) => {
              onNext(data);
              segmentTracking('next clicked', {
                location: 'Property - Public Application',
                ...segmentData,
              });
            }}
            initialValues={initialValues}
          />
        </div>
      </div>
    </MarketingLayout>
  );
};

ChooseRentalPropertyStep.propTypes = {
  onNext: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  segmentData: PropTypes.object,
};

export default ChooseRentalPropertyStep;
