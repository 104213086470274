import React from 'react';
import { Field, Form } from 'react-final-form';
import { Tooltip } from 'react-tooltip';
import cx from 'classnames';
import createFinalFormErrorDecorator from 'final-form-focus';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Condition from '../../../../components/Condition';
import DropdownSubtextMenu from '../../../../components/DropdownSubtextMenu';
import FinalFormError from '../../../../components/FinalFormError';
import Input from '../../../../components/Input';
import trimEmail from '../../../../components/Input/trimEmail';
import MultiCondition from '../../../../components/MultiCondition';
import PhoneField from '../../../../components/PhoneField';
import PropertyAddressFields from '../../../../components/PropertyAddressFields';
import RadioGroup from '../../../../components/RadioGroup';
import SectionTitleWithIcon from '../../../../components/SectionTitleWithIcon';
import isSinglePropertyType from '../../../../helpers/isSinglePropertyType';
import PropertyIcon from '../../../../icons/streamline/Property';
import TenantsIcon from '../../../../icons/Tenants';
import composeValidators from '../../../../validators/composeValidators';
import fieldRequired from '../../../../validators/fieldRequired';
import validEmail from '../../../../validators/validEmail';
import validName from '../../../../validators/validName';
import validPhone from '../../../../validators/validPhone';
import validShareAbleAddress from '../../../../validators/validShareAbleAddress';
import validShareAbleCity from '../../../../validators/validShareAbleCity';
import validShareAbleUnit from '../../../../validators/validShareAbleUnit';
import { propertyTypeDropdownOptions } from '../../../digital-leases/standalone-wizard/helpers/propertyTypeDropdownOptions';

import styles from './ScreenATenantStepForm.module.scss';

const ScreenATenantStepForm = ({ className, onSubmit, initialValues = {} }) => {
  const finalFormErrorDecorator = createFinalFormErrorDecorator();
  return (
    <Form
      onSubmit={onSubmit}
      subscription={{
        values: true,
        submitting: true,
      }}
      initialValues={{
        inviteType: 'both',
        ...initialValues,
      }}
      decorators={[finalFormErrorDecorator]}
    >
      {({ handleSubmit, submitting, values }) => (
        <form className={cx(styles.form, className)} onSubmit={handleSubmit}>
          <FinalFormError />
          <div className={styles.formGroup}>
            <SectionTitleWithIcon
              centered={false}
              title="Renter Info"
              icon={TenantsIcon}
              iconProps={{
                width: 28,
                height: 28,
              }}
            />
            <div className={styles.nameWrapper}>
              <Field
                component={Input}
                id="first_name"
                name="first_name"
                className={styles.name}
                label="First Name"
                validate={composeValidators(fieldRequired, validName)}
              />
              <Field
                component={Input}
                id="last_name"
                name="last_name"
                className={styles.name}
                label="Last Name"
                validate={composeValidators(fieldRequired, validName)}
              />
            </div>
            <Field
              component={RadioGroup}
              id="inviteType"
              name="inviteType"
              label="Send Invite By:"
              radioRowClassName={styles.radioOption}
              options={[
                {
                  label: 'Email & Text',
                  value: 'both',
                },
                {
                  label: 'Email',
                  value: 'email',
                },
                {
                  label: 'Text',
                  value: 'text',
                },
              ]}
              validate={fieldRequired}
              className={styles.inviteBy}
            />
            <div className={styles.inviteTypeWrapper}>
              <Condition when="inviteType" is="email">
                <Field
                  label="Renter's Email"
                  component={Input}
                  type="email"
                  id="email"
                  name="email"
                  className={styles.email}
                  validate={composeValidators(validEmail, fieldRequired)}
                  parse={trimEmail}
                />
              </Condition>
              <Condition when="inviteType" is="text">
                <Field
                  label="Renter's phone"
                  component={PhoneField}
                  type="phone"
                  id="phone"
                  name="phone"
                  className={styles.phone}
                  validate={composeValidators(fieldRequired, validPhone)}
                />
              </Condition>
              <Condition when="inviteType" is="both">
                <Field
                  label="Renter's Email"
                  component={Input}
                  type="email"
                  id="email"
                  name="email"
                  className={styles.email}
                  validate={composeValidators(validEmail, fieldRequired)}
                  parse={trimEmail}
                />
                <Field
                  label="Phone"
                  component={PhoneField}
                  type="phone"
                  id="phone"
                  name="phone"
                  className={styles.phone}
                  validate={composeValidators(fieldRequired, validPhone)}
                />
              </Condition>
            </div>
          </div>
          <div className={styles.formGroup}>
            <SectionTitleWithIcon
              centered={false}
              title="Rental Property"
              icon={PropertyIcon}
              iconProps={{
                width: 32,
                height: 32,
              }}
            />

            {/*
              NOTE: In order to enable focus on errors, the field needs to expose a focus method.
              Since the DropdownSubtextMenu doesn't expose a focus method, we add a hidden input.
              */}
            <input name="property_type" className={styles.hiddenInput} />
            <Field
              label="Property Type"
              id="property_type"
              name="property_type"
              validate={fieldRequired}
            >
              {(props) => (
                <DropdownSubtextMenu
                  options={propertyTypeDropdownOptions}
                  {...props}
                >
                  <DropdownSubtextMenu.Trigger />
                  <DropdownSubtextMenu.Content>
                    {(options) =>
                      options.map((option) => (
                        <DropdownSubtextMenu.Item
                          key={option.value}
                          option={option}
                        />
                      ))
                    }
                  </DropdownSubtextMenu.Content>
                </DropdownSubtextMenu>
              )}
            </Field>
            <PropertyAddressFields
              validators={{
                city: [validShareAbleCity],
                unit: [validShareAbleUnit],
                address: [validShareAbleAddress],
              }}
              showUnit={isSinglePropertyType(values.property_type)}
            />
            <Field
              component={RadioGroup}
              id="roomRentals"
              name="roomRentals"
              label={
                <div className={styles.roomRentalsWrapper}>
                  Will you have room rentals?{' '}
                  <span
                    className={styles.learnMore}
                    data-tooltip-id="learn-more-key-tip"
                  >
                    Learn more
                  </span>
                  <Tooltip id="learn-more-key-tip">
                    Room rentals are when you're renting out rooms separately
                    within the property, each with their own lease.
                  </Tooltip>
                </div>
              }
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              validate={fieldRequired}
            />

            <MultiCondition
              condition={({ property_type, roomRentals }) =>
                isSinglePropertyType(property_type) && roomRentals
              }
            >
              <div className={styles.unitRoomContainer}>
                <div className={styles.sectionTitle}>Add Room</div>
                <p className={styles.sectionDescription}>
                  Start with one room, even if you have more. You'll be able to
                  set up the rest later.
                </p>
                <Field
                  id={`room_name`}
                  name={`room_name`}
                  component={Input}
                  label="Room Name"
                  validate={fieldRequired}
                  maxLength={50}
                />
              </div>
            </MultiCondition>
            <MultiCondition
              condition={({ property_type }) =>
                isSinglePropertyType(property_type) === false
              }
            >
              <div className={styles.unitRoomContainer}>
                <div className={styles.sectionTitle}>Add Unit</div>
                <p className={styles.sectionDescription}>
                  Start with one unit, even if you have more. You'll be able to
                  set up the rest later.
                </p>
                <Field
                  id={`unit_name`}
                  name={`unit_name`}
                  className={styles.field}
                  component={Input}
                  label="Unit #"
                  validate={fieldRequired}
                  maxLength={50}
                  placeholder="Address 2 (Apt, Suite, etc.)"
                />
                <Condition when="roomRentals" is={true}>
                  <Field
                    id={`room_name`}
                    name={`room_name`}
                    component={Input}
                    label="Room Name"
                    validate={fieldRequired}
                    maxLength={50}
                  />
                </Condition>
              </div>
            </MultiCondition>
          </div>
          <div className={styles.formGroup}>
            <SectionTitleWithIcon
              centered={false}
              title="Your Mailing Address"
              icon={PropertyIcon}
              iconProps={{
                width: 32,
                height: 32,
              }}
            />
            <p className={styles.formGroupDescription}>
              TransUnion requires your mailing address and phone number.{' '}
              <b>
                Please note this is not the rental address and PO Boxes are not
                permitted.
              </b>
            </p>
            <PropertyAddressFields
              className={styles.address}
              streetAddressInputProps={{
                label: 'Street Address',
              }}
              fieldNames={{
                city: 'mailingCity',
                unit: 'mailingUnit',
                address: 'mailingAddress',
                zip: 'mailingZip',
                state: 'mailingState',
              }}
            />
            <Field
              label="Your Phone Number"
              component={PhoneField}
              type="owner_telephone"
              name="owner_telephone"
              id="owner_telephone"
              className={styles.ownerPhone}
              validate={composeValidators(fieldRequired, validPhone)}
            />
          </div>
          <Button className={styles.btn} loading={submitting} type="submit">
            Continue
          </Button>
        </form>
      )}
    </Form>
  );
};

ScreenATenantStepForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default ScreenATenantStepForm;
