import React from 'react';
import PropTypes from 'prop-types';

import { linkTo, routes } from '../../../../../helpers/routes';
import { useRentPaymentRules } from '../../../../../hooks/useRentPaymentRules';
import Exclamation from '../../../../../icons/streamline/filled/Exclamation';
import { segmentTracking } from '../../../../../services/utilities/segment';
import Spinner from '../../../../Spinner';
import { Link } from '../../../../Typography';
import DismissAction from '../DismissAction';

import styles from '../../GlobalBanner.module.scss';

const TenantAddedPaymentMethodNoRpLl = ({ renterName, leaseId, bannerId }) => {
  const { rentPaymentsStatus, loading } = useRentPaymentRules();

  if (loading) {
    return (
      <div className={styles['gb-style-warning']}>
        <Spinner className={styles.spinner} />
      </div>
    );
  }
  if (!rentPaymentsStatus.hasOwnerSetupRentPayments) {
    const link = leaseId
      ? linkTo(routes.paymentsSetupChargesStep, {}, { leaseId })
      : linkTo(routes.paymentsSetupLeaseStep);

    return (
      <div className={styles['gb-style-warning']}>
        <Exclamation color="white" />
        <div className={styles.content}>
          <strong>{renterName}</strong> is ready to make payments through
          TurboTenant!
          <Link
            to={link}
            onClick={() => {
              segmentTracking('add_charge clicked', {
                location: 'Tenant Added Payment Method Global Banner',
              });
            }}
          >
            Add a rent charge now
          </Link>
        </div>
        <DismissAction bannerId={bannerId} />
      </div>
    );
  }

  return (
    <div className={styles['gb-style-warning']}>
      <Exclamation color="white" />
      <div className={styles.content}>
        <strong>{renterName}</strong> is ready to make payments through
        TurboTenant!
        <Link
          to={linkTo(routes.offlineConversion)}
          onClick={() => {
            segmentTracking('add_charge clicked', {
              location: 'Tenant Added Payment Method Global Banner',
            });
          }}
        >
          Add a bank account to receive payments
        </Link>
      </div>
      <DismissAction bannerId={bannerId} />
    </div>
  );
};

TenantAddedPaymentMethodNoRpLl.propTypes = {
  renterName: PropTypes.string,
  leaseId: PropTypes.string,
  bannerId: PropTypes.string,
};

export default TenantAddedPaymentMethodNoRpLl;
