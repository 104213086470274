import { includes, some } from 'lodash';

const emailsToHide = ['zumperchat', 'hunt.com'];

const hideLeadEmailByDomainFromUI = (leadEmail) => {
  if (!leadEmail) {
    return false;
  }

  return some(emailsToHide, (el) => includes(leadEmail, el));
};

export default hideLeadEmailByDomainFromUI;
