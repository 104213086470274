import { get } from 'lodash';

import { COOKIE_NAMES, getCookies } from './cookies';
import getDeviceDetails from './getDeviceDetails';

export const getSignupTrackingCookie = () => {
  const cookies = getCookies();
  let cookieValue = get(cookies, COOKIE_NAMES.SIGNUP_TRACKING, null);

  // If marketing cookie is not present we will still collect other information on signup
  if (!cookieValue) {
    const { deviceType, operatingSystem } = getDeviceDetails();
    return {
      deviceType: deviceType,
      operatingSystem: operatingSystem,
    };
  }
  return JSON.parse(cookieValue);
};
