import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';

import AddressAutoSuggestFields from '../../../../../../../components/AddressAutoSuggestFields';
import Input from '../../../../../../../components/Input';
import PhoneField from '../../../../../../../components/PhoneField';
import composeValidators from '../../../../../../../validators/composeValidators';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import validName from '../../../../../../../validators/validName';
import validPhone from '../../../../../../../validators/validPhone';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider.js';

import styles from './EmergencyContactPrompt.module.scss';

const EmergencyContactPrompt = () => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={styles.container}>
      <p className={styles.fatDescription}>EMERGENCY CONTACT</p>
      <p className={styles.description}>
        Nevada requires that you must provide the contact information of an
        emergency contact within the County or 60 miles of the property. The
        emergency contact may be yourself.
      </p>
      <div className={styles.row}>
        <Field
          label="Legal First Name"
          component={Input}
          id="contact_name"
          name="custom_state_data.contact_name"
          validate={composeValidators(fieldRequired, validName)}
          className={styles.input}
          inputProps={{
            className: styles.input,
          }}
        />
        <Field
          label="Last Name"
          component={Input}
          id="contact_last_name"
          name="custom_state_data.contact_last_name"
          validate={composeValidators(fieldRequired, validName)}
          className={styles.input}
          inputProps={{
            className: styles.input,
          }}
        />
      </div>
      <AddressAutoSuggestFields
        className={styles.address}
        streetAddressInputProps={{
          label: 'Street Address',
        }}
        fieldNames={{
          city: 'custom_state_data.contact_city',
          unit: 'custom_state_data.contact_unit',
          address: 'custom_state_data.contact_address',
          zip: 'custom_state_data.contact_zip',
          state: 'custom_state_data.contact_state',
        }}
        areFieldsRequired={!standAloneWizard}
      />
      <Field
        label="Phone"
        component={PhoneField}
        name="custom_state_data.contact_phone"
        id="contact_phone"
        validate={composeValidators(fieldRequired, validPhone)}
        inputProps={{
          className: cx(styles.input, styles.phone),
        }}
      />
    </div>
  );
};

export default EmergencyContactPrompt;
