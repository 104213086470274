import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDashboardContext } from '../../pages/owners/dashboard/context';
import LeaseAgreementQuestionnaireModal from '../LeaseAgreementQuestionnaireModal';
import RentPaymentsQuestionnaireModal from '../RentPaymentsQuestionnaireModal';

const LaRpQuestionnaire = ({ suggestion, resetSuggestion, getSuggestion }) => {
  const [showLAQuestionnaireModal, setShowLAQuestionnaireModal] =
    useState(false);
  const [showRPQuestionnaireModal, setShowRPQuestionnaireModal] =
    useState(false);
  const { setTooltipsToShow } = useDashboardContext();

  useEffect(() => {
    if (!suggestion) return;

    switch (suggestion.suggestionType) {
      default:
        break;
      case 'LA_RP_QUESTIONNAIRE': {
        const laQuestionnaireDismissed = sessionStorage.getItem(
          'LAQuestionnaireDismissed',
        );
        const rpQuestionnaireDismissed = sessionStorage.getItem(
          'RPQuestionnaireDismissed',
        );

        if (suggestion.data.showLAQuestionnaire && !laQuestionnaireDismissed) {
          setShowLAQuestionnaireModal(true);
        } else if (
          suggestion.data.showRPQuestionnaire &&
          !rpQuestionnaireDismissed
        ) {
          setShowRPQuestionnaireModal(true);
        }
        break;
      }
    }
  }, [suggestion]);

  const afterSubmitLA = useCallback(
    (showTooltip) => {
      // if true, it means that there is a follow-up
      // questionnaire for collecting rent payments
      if (suggestion.data.showRPQuestionnaire) {
        setShowRPQuestionnaireModal(true);
        setShowLAQuestionnaireModal(false);
        setTooltipsToShow({
          enabled: false,
          showHowToCreateLeaseAgreement: showTooltip,
          showHowToSetupRentPayments: false,
        });
      } else {
        setTooltipsToShow({
          enabled: showTooltip,
          showHowToCreateLeaseAgreement: showTooltip,
        });

        resetSuggestion && resetSuggestion();
        getSuggestion('DASHBOARD');
      }
    },
    [suggestion],
  );

  const afterSubmitRP = useCallback(
    (showTooltip) => {
      setShowRPQuestionnaireModal(false);
      resetSuggestion && resetSuggestion();
      getSuggestion('DASHBOARD');
      setTooltipsToShow((prevState) => ({
        ...prevState,
        enabled: prevState.showHowToCreateLeaseAgreement || showTooltip,
        showHowToSetupRentPayments: showTooltip,
      }));
    },
    [suggestion],
  );

  if (showLAQuestionnaireModal) {
    const submitButtonLabel = suggestion.data.showRPQuestionnaire
      ? 'Next'
      : 'Done';
    return (
      <LeaseAgreementQuestionnaireModal
        afterSubmit={afterSubmitLA}
        listingAddress={suggestion?.data?.listingAddress}
        onClose={() => {
          sessionStorage.setItem('LAQuestionnaireDismissed', 'true');
          setShowLAQuestionnaireModal(false);
        }}
        submitButtonLabel={submitButtonLabel}
      />
    );
  }
  if (showRPQuestionnaireModal) {
    return (
      <RentPaymentsQuestionnaireModal
        afterSubmit={afterSubmitRP}
        listingAddress={suggestion?.data?.listingAddress}
        onClose={() => {
          sessionStorage.setItem('RPQuestionnaireDismissed', 'true');
          setShowRPQuestionnaireModal(false);
        }}
      />
    );
  }

  return null;
};

LaRpQuestionnaire.propTypes = {
  suggestion: PropTypes.shape({}),
  getSuggestion: PropTypes.func,
  resetSuggestion: PropTypes.func,
};

export default LaRpQuestionnaire;
