import React from 'react';
import { useLottie } from 'lottie-react';
import PropTypes from 'prop-types';

import isiOSDevice from '../../../helpers/isIosDevice';
import IframeAnimation from '../iOSIframeAnimation';

import animationPathJson from './ExportMessages.animation.json';

const ExportMessages = ({ style, loop }) => {
  const iOS = isiOSDevice();

  if (iOS) {
    return (
      <IframeAnimation
        style={style}
        src="https://lottie.host/embed/472dec7d-7e31-42f1-8043-eda741a654a6/Ruuh2j8f4R.json"
      />
    );
  }

  const LoaderData = useLottie(
    {
      path: animationPathJson,
      autoplay: true,
      loop,
    },
    style,
  );

  return LoaderData.View;
};

ExportMessages.propTypes = {
  style: PropTypes.object,
  loop: PropTypes.bool,
};

export default ExportMessages;
