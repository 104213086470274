import React from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import cx from 'classnames';

import fieldRequired from '../../validators/fieldRequired';
import RadioGroup from '../RadioGroup';

import styles from './RadioGroupQuestion.module.scss';

const RadioGroupQuestion = ({
  label,
  description,
  options,
  isRequired,
  id,
  name,
  onChange,
  containerClassName,
  ...otherProps
}) => (
  <div className={styles.radioGroupQuestion}>
    <p className={styles.radioGroupQuestionTitle}>{label}</p>
    {description && (
      <span className={styles.radioGroupQuestionDesc}>{description}</span>
    )}
    <div className={styles.radioGroupQuestionContainer}>
      <Field
        component={RadioGroup}
        vertical
        id={id || name}
        name={name}
        radioRowClassName={styles.radioGroupQuestionOption}
        radiosContainerClassName={cx(
          styles.fieldContainerCustom,
          { [styles.row]: !otherProps.vertical },
          containerClassName,
        )}
        validate={isRequired ? fieldRequired : undefined}
        options={options}
        {...otherProps}
      />
      {onChange && <OnChange name={name}>{onChange}</OnChange>}
    </div>
  </div>
);

export default RadioGroupQuestion;
