import React, { useEffect, useRef } from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useWindowWidth } from '@react-hook/window-size';
import cx from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import FlatButton from '../../../../../components/FlatButton';
import Input from '../../../../../components/Input';
import { Tooltip } from '../../../../../components/Tooltip';
import breakpoints from '../../../../../constants/media-breakpoints';
import MinusCircleIcon from '../../../../../icons/MinusCircle';
import PlusCircleIcon from '../../../../../icons/PlusCircle';
import composeValidators from '../../../../../validators/composeValidators';
import fieldRequiredShort from '../../../../../validators/fieldRequiredShort';
import isNumber from '../../../../../validators/isNumber';
import validName from '../../../../../validators/validName';

import styles from './AdditionalOccupants.module.scss';

const MOBILE_BREAKPOINT = breakpoints.mobileBreakpoint;

const ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove',
  EDIT: 'edit',
};

const occupantsReducer = (state, action) => {
  const { type, id, data } = action;
  const newState = [...state];

  switch (type) {
    case ACTIONS.ADD:
      return [
        ...state,
        {
          name: '',
          relationship: '',
          age: '',
        },
      ];
    case ACTIONS.REMOVE: {
      newState.splice(id, 1);
      return newState;
    }
    case ACTIONS.EDIT: {
      newState[id] = {
        ...newState[id],
        ...data,
      };
      return newState;
    }
    default:
      return state;
  }
};

const AdditionalOccupants = ({ input, limit }) => {
  const screenWidth = useWindowWidth();

  const currentField = useRef(null);
  const firstFocus = useRef(true);
  const occupants = get(input, 'value') || [];
  const modOccupants = (action) => {
    const newValue = occupantsReducer(occupants, action);
    input.onChange(newValue);
  };

  useEffect(() => {
    if (currentField.current && !firstFocus.current) {
      currentField.current.focus();
    } else {
      // we want to skip foccusing on first render
      firstFocus.current = false;
    }
  }, [currentField.current]);

  return (
    <div className={styles.container}>
      <div>
        {occupants.map((_occ, index) => (
          <React.Fragment key={index}>
            <div className={styles.row}>
              <OnChange name={`${input.name}.${index}.name`}>
                {(value) => {
                  modOccupants({
                    type: ACTIONS.EDIT,
                    id: index,
                    data: {
                      name: value,
                    },
                  });
                }}
              </OnChange>
              <Field
                label="Full Name"
                component={Input}
                id={`${input.name}.${index}.name`}
                name={`${input.name}.${index}.name`}
                validate={composeValidators(fieldRequiredShort, validName)}
                className={styles.inputContainer}
                labelProps={{
                  className: styles.inputLabel,
                }}
                inputProps={{
                  'data-qa': `additional-occupant-name-${index}`,
                  'className': cx(styles.input, styles.name),
                  'ref':
                    index === occupants.length - 1
                      ? (ref) => (currentField.current = ref)
                      : undefined,
                }}
              />
              <OnChange name={`${input.name}.${index}.relationship`}>
                {(value) => {
                  modOccupants({
                    type: ACTIONS.EDIT,
                    id: index,
                    data: {
                      relationship: value,
                    },
                  });
                }}
              </OnChange>
              <Field
                label="Relationship"
                component={Input}
                id={`${input.name}.${index}.relationship`}
                name={`${input.name}.${index}.relationship`}
                validate={fieldRequiredShort}
                className={styles.inputContainer}
                labelProps={{
                  className: styles.inputLabel,
                }}
                inputProps={{
                  'data-qa': `additional-occupant-relationship-${index}`,
                  'className': cx(styles.input, styles.relationship),
                }}
              />
              <OnChange name={`${input.name}.${index}.age`}>
                {(value) => {
                  modOccupants({
                    type: ACTIONS.EDIT,
                    id: index,
                    data: {
                      age: value,
                    },
                  });
                }}
              </OnChange>
              <Field
                label="Age"
                component={Input}
                id={`${input.name}.${index}.age`}
                name={`${input.name}.${index}.age`}
                type="number"
                pattern="[0-9]*"
                validate={composeValidators(fieldRequiredShort, isNumber)}
                className={styles.inputContainer}
                labelProps={{
                  className: styles.inputLabel,
                }}
                inputProps={{
                  'data-qa': `additional-occupant-age-${index}`,
                  'className': cx(styles.input, styles.age),
                }}
              />
            </div>
            {occupants.length > 1 && (
              <FlatButton
                type="button"
                onClick={() => {
                  modOccupants({
                    type: ACTIONS.REMOVE,
                    id: index,
                  });
                }}
                icon={MinusCircleIcon}
                disabled={occupants.length === 1}
                className={cx(styles.btn, styles.remove)}
              >
                Remove occupant
              </FlatButton>
            )}
          </React.Fragment>
        ))}
      </div>
      <FlatButton
        data-tooltip-id="add_occupant_tooltip"
        data-tooltip-hidden={occupants.length !== limit}
        type="button"
        onClick={() => {
          /**
           * Manually disable the button because having
           * `disable` prop on it breaks the tooltip
           */
          if (occupants.length === limit) {
            return;
          }
          modOccupants({
            type: ACTIONS.ADD,
          });
        }}
        icon={PlusCircleIcon}
        className={cx(styles.btn, styles.add, {
          [styles.disabled]: occupants.length === limit,
        })}
      >
        Add another occupant
      </FlatButton>
      <Tooltip
        id="add_occupant_tooltip"
        place={screenWidth < MOBILE_BREAKPOINT ? 'bottom' : 'right'}
        offset={screenWidth < MOBILE_BREAKPOINT ? 24 : 0}
      >
        <span>
          You've reached the maximum number of additional occupants allowed.
        </span>
      </Tooltip>
    </div>
  );
};

AdditionalOccupants.propTypes = {
  input: PropTypes.object,
  limit: PropTypes.number,
};

export default AdditionalOccupants;
