import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import MobileAppBanner from '../../components/MobileAppBanner';
import DesktopNavigation from '../../components/navigation/DesktopNavigation';
import MobileNavigation from '../../components/navigation/MobileNavigation';
import { LAYOUT_IDS } from '../../constants';

import styles from './InAppLayouts.module.scss';

const InAppLayouts = ({ children }) => (
  <>
    <DesktopNavigation />
    <MobileNavigation />
    <div className={styles.container}>
      <MobileAppBanner />
      <>{children}</>
    </div>
  </>
);

InAppLayouts.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

InAppLayouts.layoutId = LAYOUT_IDS.IN_APP;

export default withRouter(InAppLayouts);
