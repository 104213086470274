import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { segmentTracking } from '../../services/utilities';
import composeValidators from '../../validators/composeValidators';
import fieldRequired from '../../validators/fieldRequired';
import validEmail from '../../validators/validEmail';
import Button from '../Button';
import Input from '../Input';
import trimEmail from '../Input/trimEmail';
import Modal from '../Modal';
import { H2, Paragraph } from '../Typography';

import styles from './EmailRequiredModal.module.scss';

const EmailRequiredModal = ({ open, onSubmit, loading }) => {
  useEffect(() => {
    segmentTracking('enter_email_modal loaded', {
      location: 'Lease Audit Results Page',
    });
  }, []);
  return (
    <Modal
      open={open}
      showCloseIcon={false}
      size="small"
      containerClassName={styles.container}
    >
      <Modal.Content noFooter>
        <H2 className={cx(styles.title, styles.desktop)}>
          Enter your email to unlock your full audit
        </H2>
        <H2 className={cx(styles.title, styles.mobile)}>
          Unlock your full audit
        </H2>
        <Paragraph className={styles.mobile}>
          Enter your email to view your full audit
        </Paragraph>

        <Form onSubmit={onSubmit}>
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Field
                  label="Email"
                  component={Input}
                  type="email"
                  name={`email`}
                  id="email"
                  className={styles.email}
                  validate={composeValidators(fieldRequired, validEmail)}
                  parse={trimEmail}
                />
                <div className={styles.bottomWrapper}>
                  <div className={styles.btnWrapper}>
                    <Button
                      className={styles.btn}
                      loading={loading}
                      type="submit"
                      disabled={loading}
                      id="submit-email-btn"
                      onClick={() => {
                        segmentTracking('view_audit clicked', {
                          location: 'Lease Audit Enter Email Modal',
                        });
                      }}
                    >
                      View your audit
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      </Modal.Content>
    </Modal>
  );
};

EmailRequiredModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default EmailRequiredModal;
