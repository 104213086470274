import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities/segment';
import Button from '../../Button';

import styles from './SendWorkOrderCard.module.scss';

const SendWorkOrderCard = ({
  className,
  onClick,
  listingId,
  maintenanceReqId,
}) => {
  const config = useConfig();

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.texts}>
        <h4 className={styles.title}>Send Work Order</h4>
        <p className={styles.description}>
          Share this maintenance request with your pro.
        </p>
      </div>
      <Button
        small
        className={styles.button}
        secondary={true}
        to={`${config.PRIVATE_BASE_PATH}maintenance/work_order/${listingId}/${maintenanceReqId}`}
        onClick={() => {
          segmentTracking('send_work_order_card clicked', {
            location: 'Maintenance Request',
          });
          onClick && onClick();
        }}
        dataQa="send-work-order"
      >
        Send Work Order
      </Button>
    </div>
  );
};

SendWorkOrderCard.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  listingId: PropTypes.string.isRequired,
  maintenanceReqId: PropTypes.string.isRequired,
};

export default SendWorkOrderCard;
