// copied originally from fe/src/helpers/format-address.js, there's no need of adding unit tests again
const formatAddress = (input = {}, short = false, isRenter = false) => {
  const {
    address,
    unit,
    room_name,
    city,
    state,
    zip,
    hide_street_number = false,
  } = input;

  if (!address) return '';
  const hideNumber = hide_street_number && isRenter;

  const unitString = unit && !hideNumber ? `, ${unit.trim()}` : '';
  const roomString =
    room_name && !['YES', 'NO'].includes(room_name) && !hideNumber
      ? `, ${room_name.trim()}`
      : '';

  const addressString = hideNumber
    ? address.replace(/^[0-9]+[ ]/, '')
    : address;

  if (short) {
    return `${addressString}${unitString}${roomString}`;
  }

  return `${addressString}${unitString}${roomString}, ${city}, ${state} ${zip}`;
};

export default formatAddress;

export const formatShortAddress = ({ address, unit }) => {
  if (!address) return '';

  return `${address}${unit ? `, ${unit}` : ''}`;
};

export const getPublicStreetAddress = (listing) =>
  listing.hide_street_number
    ? listing.address.replace(/^[0-9]+[ ]/, '')
    : listing.address;

export const getPublicUnit = (listing) =>
  listing.hide_street_number ? '' : listing.unit;
