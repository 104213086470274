import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Education = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 25" {...rest} fill={color}>
    <path d="m12.277 4.598 9.41 5.138a.616.616 0 0 1 0 1.073l-2.73 1.49v5.707l-.135.169a5.43 5.43 0 0 1-.749.732c.106.235.165.498.165.774 0 1.02-.804 1.846-1.795 1.846-.58 0-1.095-.282-1.423-.72a8.459 8.459 0 0 1-2.973.518c-1.97 0-3.659-.596-5.061-1.587-.846-.598-1.414-1.202-1.71-1.625l-.112-.16-.001-5.588-2.85-1.556a.617.617 0 0 1-.082-1.019l.083-.054 9.409-5.138a.577.577 0 0 1 .554 0Zm4.166 14.453a.622.622 0 0 0-.614.63c0 .348.275.63.614.63a.622.622 0 0 0 .613-.63.622.622 0 0 0-.613-.63Zm1.332-6.107-.713.39v3.328a.6.6 0 0 1-.59.608.6.6 0 0 1-.592-.608V13.98l-3.603 1.968a.577.577 0 0 1-.465.04l-.09-.04-5.376-2.936v4.534l.093.108.132.144c.3.32.661.64 1.085.94 1.214.858 2.674 1.372 4.391 1.372.951 0 1.817-.16 2.602-.455l-.002.027c0-1.02.804-1.846 1.796-1.846.288 0 .56.07.802.194.115-.093.22-.183.316-.272l.214-.208v-4.605ZM12 5.824l-8.148 4.448L12 14.722l3.414-1.866-3.695-2.048a.616.616 0 0 1-.273-.749l.034-.075a.583.583 0 0 1 .8-.246l4.388 2.433 3.48-1.898L12 5.823Z" />
  </svg>
);

Education.defaultProps = {
  width: 24,
  height: 25,
  color: colors.primary,
};

Education.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Education);
