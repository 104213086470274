import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import BANNER_NAMES from '../../constants/banner_names';
import { FEATURE_FLAGS } from '../../constants/feature_flags';
import WAITLIST_FEATURES from '../../constants/owner_waitlist_features/waitlist-features';
import { useUserProfile } from '../../core/TTgraphql';
import toolboxWaitlistFeaturesGQL from '../../graphql/queries/toolbox/toolboxWaitlistFeatures.graphql';
import isBannerHidden from '../../helpers/isBannerHidden';
import useDismissBanner from '../../helpers/useDismissBanner';
import useAddFeatureToWaitlist from '../../hooks/useAddFeatureToWaitlist';
import { useConfig } from '../../providers/ConfigProvider';
import { segmentTracking } from '../../services/utilities';
import LoadingScreen from '../LoadingScreen';

import LulaWaitlistBanner from './LulaWaitlistBanner';
import LulaWaitlistModal from './LulaWaitlistModal';

const LulaWaitlistBannerWrapped = ({ bannerClassName, bannerLocation }) => {
  const [open, setOpen] = useState(false);
  const addFeatureToWaitlist = useAddFeatureToWaitlist();

  const { IS_OWNER, PRIVATE_BASE_PATH } = useConfig();

  const history = useHistory();

  const { user, features = [], loading: loadingUser } = useUserProfile();

  const isLulaEnabled = features?.includes(FEATURE_FLAGS.LULA_MAINTENANCE);

  const { data, loading: loadingWaitlist } = useQuery(
    toolboxWaitlistFeaturesGQL,
  );

  const maintenancePlusSubscribed = get(
    user,
    'maintenance_plus_subscription_subscribed',
    false,
  );

  const userAddedToLulaWaitlist =
    data?.toolbox_waitlist_features?.features?.includes(
      WAITLIST_FEATURES.LULA_MAINTENANCE,
    );
  const lulaBannerDismissed = isBannerHidden(
    user?.dismissed_banners,
    BANNER_NAMES.LULA_BANNER,
  );

  const isLulaEligible = get(user, 'lula_eligible', false);

  const canAccessMarketingPage = isLulaEnabled && isLulaEligible;

  const isLulaExperimentActive = get(
    user,
    'maintenance_plus_active_experiment',
    false,
  );
  const maintenancePlusSettings =
    user?.maintenance_request_management_settings || false;
  const isEnrolledFreeLula =
    isLulaExperimentActive && !!maintenancePlusSettings;

  const hideLulaBanner =
    maintenancePlusSubscribed ||
    isEnrolledFreeLula ||
    lulaBannerDismissed ||
    (userAddedToLulaWaitlist && !canAccessMarketingPage);
  const [dismissBannerMutation, { loading: loadingDismiss }] =
    useDismissBanner();

  const handleCloseBanner = async () => {
    await dismissBannerMutation({
      variables: { bannerName: BANNER_NAMES.LULA_BANNER },
    });
  };

  if (!IS_OWNER) {
    return null;
  }

  if (loadingDismiss || loadingUser || loadingWaitlist) {
    return <LoadingScreen loading={loadingDismiss} />;
  }

  if (hideLulaBanner) {
    return null;
  }

  return (
    <>
      <LulaWaitlistBanner
        className={bannerClassName}
        onBannerDismiss={handleCloseBanner}
        showOffer={isLulaExperimentActive}
        onLearnMore={() => {
          segmentTracking('learn_more clicked', {
            location: `${bannerLocation} - ${
              canAccessMarketingPage ? 'Lula Eligible' : 'Waitlist'
            }`,
          });

          canAccessMarketingPage
            ? history.push(`${PRIVATE_BASE_PATH}maintenance/lula`)
            : setOpen(true);
        }}
      />
      <LulaWaitlistModal
        open={open}
        onClose={() => {
          segmentTracking('not_now clicked', {
            location: 'Lula Waitlist Modal',
          });
          setOpen(false);
        }}
        onModalLoaded={() => {
          segmentTracking('lula_maintenance_waitlist_modal loaded', {
            location: bannerLocation,
          });
        }}
        onJoinWaitlist={() => {
          segmentTracking('join_waitlist clicked', {
            location: 'Lula Waitlist Modal',
          });
          addFeatureToWaitlist(WAITLIST_FEATURES.LULA_MAINTENANCE);
          setOpen(false);
        }}
      />
    </>
  );
};

LulaWaitlistBannerWrapped.defaultProps = {
  bannerLocation: 'Maintenance Index Page',
};

LulaWaitlistBannerWrapped.propTypes = {
  bannerClassName: PropTypes.string,
  bannerLocation: PropTypes.string,
};

export default LulaWaitlistBannerWrapped;
