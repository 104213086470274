import { has } from 'lodash';

import colors from '../../../constants/colors';

const getColor = (color) => {
  if (!color) return '#033A6D';
  if (has(colors, color)) return colors[color];

  return color;
};

export default getColor;
