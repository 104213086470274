import React, { useState } from 'react';
import cx from 'classnames';
import propTypes from 'prop-types';

import Notes from '../../../icons/Notes';
import ActivityItem from '../../ActivityItem';
import Card from '../../Card';
import FlatButton from '../../FlatButton';
import IconTooltip from '../../IconTooltip';
import AddNoteModal from '../AddNoteModal';

import styles from './Activity.module.scss';

const Activity = ({
  activity,
  isOwner = true,
  className,
  listingId = '',
  maintenanceRequestId = '',
}) => {
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  return (
    <Card
      className={cx(styles.container, className)}
      dataQa="maintenance-activity"
    >
      {showAddNoteModal && isOwner && (
        <AddNoteModal
          listingId={listingId}
          maintenanceRequestId={maintenanceRequestId}
          onClose={() => setShowAddNoteModal(false)}
        />
      )}
      <div className={styles.header}>
        <div className={styles.col1}>
          <h3 className={styles.title}>Activity</h3>
          <div>
            <IconTooltip
              tip={
                <span>
                  {isOwner
                    ? 'Activity Log is visible to both you and all of the tenants on the lease unless there is a lock icon. Notes are not shared with Pros or Lula when the request is sent.'
                    : 'This is a log of actions your landlord is taking related to this request. It is visible to everyone on the lease.'}
                </span>
              }
            />
          </div>
        </div>

        {isOwner && (
          <FlatButton
            className={styles.addNote}
            icon={Notes}
            onClick={() => setShowAddNoteModal(true)}
          >
            Add Note
          </FlatButton>
        )}
      </div>
      <div className={styles.content}>
        {activity.map((activity, index) => (
          <div key={index} className={styles.activity}>
            <ActivityItem
              Icon={activity.icon}
              title={activity.title}
              date={activity.date}
              content={activity.content}
              isPrivate={activity.private}
              attachments={activity.attachments}
              actions={activity.actions}
              maintenanceRequestId={maintenanceRequestId}
            />
          </div>
        ))}
      </div>
    </Card>
  );
};

Activity.propTypes = {
  activity: propTypes.array.isRequired,
  isOwner: propTypes.bool,
  className: propTypes.string,
  listingId: propTypes.string,
  maintenanceRequestId: propTypes.string,
};

export default Activity;
