import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ArrowV3 = ({ color, ...rest }) => (
  <svg {...rest} viewBox="0 0 24 25" fill="none">
    <path
      fill={color}
      d="M9.804 21.69a.294.294 0 0 0 .226-.114l1.741-2.09a.444.444 0 0 0 .002-.545l-.002-.002-4.164-4.994H21.93c.176 0 .32-.173.32-.386v-2.74c0-.213-.144-.386-.32-.386H7.607L11.77 5.44a.444.444 0 0 0 .002-.546s0-.002-.002-.002L10.03 2.802a.294.294 0 0 0-.218-.113h-.016a.294.294 0 0 0-.218.113L1.75 12.19l7.828 9.386a.294.294 0 0 0 .226.113Z"
    />
  </svg>
);

ArrowV3.defaultProps = {
  color: 'currentColor',
  width: 24,
  height: 25,
};

ArrowV3.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ArrowV3);
