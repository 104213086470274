import React from 'react';
import { useLottie } from 'lottie-react';
import PropTypes from 'prop-types';

import colors from '../../../constants/colors';
import isiOSDevice from '../../../helpers/isIosDevice';
import CheckMarkFilled from '../../../icons/CheckMarkFilled';

import animationPathJson from './SuccessAnimation.animation.json';

const SuccessAnimation = ({ style = {} }) => {
  const iOS = isiOSDevice();

  if (iOS) {
    return (
      <CheckMarkFilled
        width={style.width || style.height}
        height={style.height || style.width}
        color={colors.lightGreenAlt}
      />
    );
  }

  const options = {
    path: animationPathJson,
    loop: false,
    autoplay: true,
  };

  const LoaderData = useLottie(options, style);
  return LoaderData.View;
};

SuccessAnimation.propTypes = {
  style: PropTypes.object,
};

export default SuccessAnimation;
