import React, { memo } from 'react';
import PropTypes from 'prop-types';

const MultiFamilyColor = ({ color, insideColor, borderColor, ...props }) => (
  <svg viewBox="0 0 31 40" {...props} fill={color}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={insideColor}
        d="M17.222 33.333v5h-3.444v-5zM26.337 1.667l1.722 1.666H2.94l1.722-1.666z"
      />
      <path
        fill={borderColor}
        d="M26.693 0c.229 0 .448.088.609.244l3.444 3.333a.923.923 0 0 1 .091.103l.015.022a.783.783 0 0 1 .147.49v34.975c0 .46-.386.833-.862.833H.863c-.476 0-.861-.373-.861-.833L0 4.193a.789.789 0 0 1 .146-.49l.017-.023a.806.806 0 0 1 .09-.103l-.043.045a.848.848 0 0 1 .017-.018l.026-.027L3.698.244A.876.876 0 0 1 4.307 0Zm2.583 5H1.724v33.333h10.331V32.5c0-.425.33-.775.754-.827l.108-.006h5.166c.476 0 .861.373.861.833v5.833h10.332V5ZM17.222 33.333h-3.444v5h3.444v-5Zm.861-10c.476 0 .861.373.861.834v5c0 .46-.385.833-.861.833h-5.166c-.476 0-.861-.373-.861-.833v-5c0-.46.385-.834.861-.834Zm8.61 0c.476 0 .861.373.861.834v5c0 .46-.385.833-.86.833h-5.167c-.475 0-.86-.373-.86-.833v-5c0-.46.385-.834.86-.834Zm-17.22 0c.475 0 .86.373.86.834v5c0 .46-.385.833-.86.833H4.307c-.476 0-.861-.373-.861-.833v-5c0-.46.385-.834.86-.834ZM17.222 25h-3.444v3.333h3.444V25Zm8.61 0h-3.444v3.333h3.444V25Zm-17.22 0H5.168v3.333h3.444V25Zm9.471-10c.476 0 .861.373.861.833v5c0 .46-.385.834-.861.834h-5.166c-.476 0-.861-.373-.861-.834v-5c0-.46.385-.833.861-.833Zm8.61 0c.476 0 .861.373.861.833v5c0 .46-.385.834-.86.834h-5.167c-.475 0-.86-.373-.86-.834v-5c0-.46.385-.833.86-.833Zm-17.22 0c.475 0 .86.373.86.833v5c0 .46-.385.834-.86.834H4.307c-.476 0-.861-.373-.861-.834v-5c0-.46.385-.833.86-.833Zm7.749 1.667h-3.444V20h3.444v-3.333Zm8.61 0h-3.444V20h3.444v-3.333Zm-17.22 0H5.168V20h3.444v-3.333Zm9.471-10c.476 0 .861.373.861.833v5c0 .46-.385.833-.861.833h-5.166c-.476 0-.861-.373-.861-.833v-5c0-.46.385-.833.861-.833Zm8.61 0c.476 0 .861.373.861.833v5c0 .46-.385.833-.86.833h-5.167c-.475 0-.86-.373-.86-.833v-5c0-.46.385-.833.86-.833Zm-17.22 0c.475 0 .86.373.86.833v5c0 .46-.385.833-.86.833H4.307c-.476 0-.861-.373-.861-.833v-5c0-.46.385-.833.86-.833Zm7.749 1.666h-3.444v3.334h3.444V8.333Zm8.61 0h-3.444v3.334h3.444V8.333Zm-17.22 0H5.168v3.334h3.444V8.333Zm17.725-6.666H4.662L2.94 3.333h25.119l-1.722-1.666Z"
      />
    </g>
  </svg>
);

MultiFamilyColor.defaultProps = {
  width: 31,
  height: 40,
  color: 'currentColor',
  insideColor: '#7FE3FF',
  borderColor: '#042238',
};

MultiFamilyColor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(MultiFamilyColor);
