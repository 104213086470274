import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Screening = (props) => (
  <svg {...props} viewBox="0 0 40 41">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.607 3.488c7.331 0 13.273 5.942 13.273 13.274 0 3.284-1.193 6.29-3.169 8.608l9.645 9.644a1.059 1.059 0 0 1-1.497 1.498l-9.646-9.644a13.221 13.221 0 0 1-8.606 3.167c-7.332 0-13.274-5.942-13.274-13.273 0-7.332 5.942-13.274 13.274-13.274Zm0 2.117C10.445 5.605 5.45 10.6 5.45 16.762s4.995 11.156 11.157 11.156 11.156-4.994 11.156-11.156c0-6.162-4.994-11.157-11.156-11.157Zm5.777 4.793c.263 1.053.107 2.058-.313 3.125.417.493.594 1.21.53 1.97-.066.793-.367 1.538-.889 1.963-.083 1.145-.38 2.04-.846 2.705a3.26 3.26 0 0 1-.486.555v1.441a60.41 60.41 0 0 1 2.787 1.24l-.91 1.911a58.193 58.193 0 0 0-3.329-1.456 1.058 1.058 0 0 1-.666-.983v-2.715c0-.45.285-.85.71-1-.03.011.041-.039.161-.21.292-.415.487-1.112.487-2.183 0-.483.323-.89.765-1.017a1.328 1.328 0 0 0 .105-.664 1.059 1.059 0 0 1-.827-1.033c0-.225.25-.87.38-1.18l.061-.14a.405.405 0 0 1 .014-.03l-.001.003.09-.245c.19-.572.24-1.075.123-1.544-.411-1.646-5.678-1.646-6.09 0-.137.55-.683.893-1.238.78a2.674 2.674 0 0 0-.186-.031c.018.095.047.222.092.372.053.178.123.374.213.597.064.162.295.707.267.642.163.39.206.514.206.776 0 .517-.371.948-.862 1.04a1.293 1.293 0 0 0 .097.657c.442.128.765.535.765 1.017 0 1.07.195 1.768.486 2.183.12.171.19.221.161.21.425.15.71.55.71 1v2.715c0 .433-.264.822-.666.983a58.254 58.254 0 0 0-3.33 1.458l-.91-1.912a61 61 0 0 1 2.789-1.241v-1.441a3.256 3.256 0 0 1-.487-.555c-.466-.665-.762-1.56-.846-2.706-.524-.426-.824-1.176-.884-1.97-.059-.77.134-1.493.581-1.96a9.215 9.215 0 0 1-.319-.884c-.246-.824-.296-1.504.005-2.115.322-.653.917-.969 1.681-.991 1.894-2.895 8.951-2.608 9.819.863Z"
      fill={colors.text}
    />
  </svg>
);

Screening.defaultProps = {
  color: colors.text,
};

Screening.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Screening);
