const REGEX =
  /^\s*(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(b|box|bin)[-.\s]*)|.*((p|post)[-.\s]*(o|off|office)[-.\s]*)|.*((p|post)[-.\s]*(b|box|bin)[-.\s]*)|(box|bin)[-.\s]*)(#|n|num|number)?\s*\d+/i;

export const validNotPoBoxAddress = (value) => {
  if (value && value.match(REGEX)) {
    return 'PO Box Addresses are not allowed';
  }
  return undefined;
};

export default validNotPoBoxAddress;
