import React, { memo } from 'react';

const Calendar = (props) => (
  <svg viewBox="0 0 16 16" {...props}>
    <path
      d="M5.054 0c.26 0 .47.21.47.47v.839h4.951V.471c0-.26.211-.471.471-.471h1.964c.26 0 .471.21.471.47v.839h2.148a.47.47 0 01.463.386L16 1.78v13.75c0 .26-.21.47-.47.47H.47a.47.47 0 01-.47-.47V1.78c0-.26.21-.47.47-.47l2.148-.001V.471C2.619.21 2.83 0 3.09 0h1.964zm10.004 5.524H.941v9.535H15.06l-.001-9.535zm-4.112.369c.26 0 .47.21.47.47v.839h2.804a.47.47 0 01.463.386l.008.085c0 .26-.211.47-.471.47h-2.804V9.82l2.804.001a.47.47 0 01.463.386l.008.085c0 .26-.211.47-.471.47h-2.804v1.677h2.804a.47.47 0 01.463.387l.008.084c0 .26-.211.471-.471.471h-2.804v.839a.47.47 0 01-.385.463l-.085.008a.47.47 0 01-.47-.471l-.001-.839H8.143v.839a.47.47 0 01-.386.463l-.084.008a.47.47 0 01-.471-.471v-.839H4.869v.839a.47.47 0 01-.385.463l-.085.008a.47.47 0 01-.47-.471l-.001-.839H1.78a.47.47 0 01-.463-.386l-.008-.085c0-.26.211-.47.471-.47l2.148-.001v-1.677H1.78a.47.47 0 01-.463-.386l-.008-.084c0-.26.211-.471.471-.471l2.148-.001V8.143H1.78a.47.47 0 01-.463-.386l-.008-.084c0-.26.211-.471.471-.471h2.148v-.839a.47.47 0 01.386-.463l.085-.007c.26 0 .47.21.47.47v.839h2.333v-.839a.47.47 0 01.386-.463l.085-.007c.26 0 .47.21.47.47v.839h2.332v-.839a.47.47 0 01.387-.463zm-3.744 4.869H4.869v1.677h2.333v-1.677zm3.273 0H8.143v1.677h2.332v-1.677zM7.202 8.143H4.869V9.82h2.333V8.143zm3.273 0H8.143V9.82h2.332V8.143zM2.618 2.25H.941v2.333h14.117V2.251l-1.677-.001v.84c0 .26-.21.47-.47.47h-1.965a.47.47 0 01-.47-.47l-.001-.84H5.524v.84c0 .26-.21.47-.47.47H3.09a.47.47 0 01-.471-.47l-.001-.84zM4.583.941H3.56V2.62h1.023V.94zm7.857 0h-1.023V2.62h1.023V.94z"
      fill={props.color}
      fillRule="evenodd"
    />
  </svg>
);

Calendar.defaultProps = {
  width: 16,
  height: 16,
};

export default memo(Calendar);
