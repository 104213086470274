import React from 'react';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from './RentStabilizationNoticeNY.module.scss';

const RentStabilizationNoticeNY = () => {
  return (
    <HelpfulInfoCard
      collapsable
      Icon={ResourcesIcon}
      className={styles.helpfulInfoCard}
      noTitle
      color="iceCold"
    >
      New York requires that for rent stabilization properties, you must provide
      your tenant written notice of intent to renew lease, by personal delivery
      or mail, 90-150 days prior to the start of the new term. Your tenant then
      has 60 days to accept and then 30 days to return the signed lease. There
      may be other terms and conditions, please check local laws for specifics.
    </HelpfulInfoCard>
  );
};

export default RentStabilizationNoticeNY;
