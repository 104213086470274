import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Callout from '../../../../components/Callout';
import showIntercom from '../../../../helpers/showIntercom';
import BulletSquare from '../../../../icons/BulletSquare';
import DocumentUploadIcon from '../../../../icons/FormThick';
import Security from '../../../../icons/Security';
import SectionTitle from '../../common/SectionTitle';

import PropertyConditionReport from './SectionAdditionalProvisionsFields/PropertyConditionReport';

import styles from './SectionAdditionalProvisions.module.scss';

const renderAttachmentErrors = (attachmentErrors) => (
  <Callout className={cx(styles.uploadError)} type="error">
    <div>
      <div>There is an issue with the following attachment(s):</div>
      {attachmentErrors.map((e, idx) => (
        <div key={idx}>
          <i>{e}</i>
        </div>
      ))}
    </div>
    <div>Please try one of the following:</div>
    <div>
      {' '}
      <BulletSquare className={styles.bullet} />
      <span>
        Upload the attachment as a different file type: PDF or word doc
      </span>
    </div>
    <div>
      <BulletSquare className={styles.bullet} />
      <span>Upload the attachment later as part of the e-sign process</span>
    </div>
    <div>
      <BulletSquare className={styles.bullet} />
      <span>
        <a
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.preventDefault();
            showIntercom();
          }}
        >
          Contact customer support
        </a>{' '}
        for help
      </span>
    </div>
  </Callout>
);

export const SectionAdditionalProvisionsAttachmentsSection = ({
  attachmentErrors,
  children,
  standAloneWizard,
  leaseId,
  currentState,
  leaseAgreementSelection,
  onCreateAccount,
}) => {
  return (
    <div
      className={cx(styles.column, styles.attachmentsSection, {
        [styles.disabled]: standAloneWizard,
      })}
    >
      <SectionTitle
        title={
          standAloneWizard
            ? 'Attachments available upon completion'
            : 'Attachments'
        }
        icon={standAloneWizard ? Security : DocumentUploadIcon}
        iconProps={{
          width: 27,
          height: 24,
        }}
      />
      <p className={styles.description}>
        Attachments are added to the end of your lease agreement and will be
        arranged by the order they are uploaded. If applicable, attachments can
        be signed electronically along with the lease.
      </p>
      <PropertyConditionReport
        className={styles.conditionReportCard}
        leaseId={leaseId}
        standAloneWizard={standAloneWizard}
        currentState={currentState}
        leaseAgreementSelection={leaseAgreementSelection}
        onCreateAccount={onCreateAccount}
      />
      {attachmentErrors.length > 0 && renderAttachmentErrors(attachmentErrors)}
      {children}
    </div>
  );
};

SectionAdditionalProvisionsAttachmentsSection.propTypes = {
  attachmentErrors: PropTypes.array,
  children: PropTypes.node,
  standAloneWizard: PropTypes.bool,
  leaseId: PropTypes.string,
  currentState: PropTypes.string,
  leaseAgreementSelection: PropTypes.string,
  onCreateAccount: PropTypes.func,
};
