import { useEffect } from 'react';
import loadScript from 'load-script';
import moment from 'moment';

let loaded = false;

function useWootricSDK({ email, created_at, config }) {
  useEffect(() => {
    if (!email) return; // Do nothing if email is not provided

    // Function to initialize Wootric
    const initWootric = () => {
      try {
        window.wootricSettings = {
          account_token: config.WOOTRICS_ACCOUNT_TOKEN,
          created_at: moment(created_at, ['MM/DD/YYYY', 'YYYY-MM-DD']).unix(),
          email,
        };
        window.wootric?.('run');
      } catch (e) {
        window.atatus?.notify(
          new Error('Wootric init error: ' + e?.toString()),
        );
      }
    };

    // Load the Wootric SDK script if not already loaded
    if (!loaded) {
      loadScript('https://cdn.wootric.com/wootric-sdk.js', () => {
        loaded = true;
        initWootric();
      });
    } else {
      initWootric();
    }

    // Cleanup function to stop Wootric on unmount
    return () => {
      if (window.WootricSurvey) {
        try {
          window.WootricSurvey.stop();
        } catch (e) {
          console.log('Wootric unmount error: ' + e?.toString());
        }
      }
    };
  }, [email, created_at, config]);
}

export default useWootricSDK;
