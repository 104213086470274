import { line, textLight } from '../../../../../../constants/colors';

export const blockedMessageWrapperStyle = () => {
  return {
    padding: '4px 16px 32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    zIndex: '1',
    order: '3',
    position: 'relative',
    flex: 'none',
    minHeight: '105px',
    width: '100%',
    margin: 'auto',
    borderTop: `1px solid ${line}`,
  };
};

export const blockedMessageContainerStyle = () => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };
};

export const bockedSubTitleStyle = () => {
  return {
    margin: '0 0 16px 0',
    textAlign: 'center',
    color: textLight,
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: '0.25px',
    lineHeight: '18px',
    textAlign: 'center',
    textTransform: 'uppercase',
  };
};

export const buttonStyle = () => {
  return {
    maxWidth: '200px',
  };
};
