import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const InformationCircleFilled = ({ color, ...rest }) => (
  <svg {...rest} viewBox={'0 0 20 20'} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0c5.522 0 10 4.478 10 10 0 5.523-4.478 10-10 10-5.523 0-10-4.477-10-10S4.477 0 10 0Zm0 7.083c.46 0 .833.373.833.834v6.665l1.667.001a.833.833 0 0 1 0 1.667h-5a.833.833 0 1 1 0-1.667h1.666V8.75h-.833a.833.833 0 0 1 0-1.667H10Zm-.417-3.75a1.251 1.251 0 0 1 0 2.5 1.25 1.25 0 1 1 0-2.5Z"
      fill={color}
    />
  </svg>
);

InformationCircleFilled.defaultProps = {
  width: 20,
  height: 20,
  color: colors.primary,
};

InformationCircleFilled.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(InformationCircleFilled);
