import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from '../../../BankInfo.module.scss';

const NonRefundableFeeInfo = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.row}>
      <HelpfulInfoCard
        Icon={ResourcesIcon}
        noTitle
        className={cx(styles.helpfulInfoCard, styles.noMargin)}
        color="iceCold"
      >
        Delaware stipulates that except for an optional service fee, ie. a Pool
        fee, a Landlord may not charge any non-refundable fees.
      </HelpfulInfoCard>
    </div>
  </div>
);

NonRefundableFeeInfo.propTypes = {
  className: PropTypes.string,
};

export default NonRefundableFeeInfo;
