import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import CreditCardThickIcon from '../../../../icons/CreditCardThick';
import SectionTitle from '../../common/SectionTitle';

import styles from './SectionRentDepositFee.module.scss';

export const SectionRentDepositFeePaymentSection = ({ children }) => {
  return (
    <div className={cx(styles.column, styles.section)}>
      <SectionTitle title="Payment Accepted" icon={CreditCardThickIcon} />
      {children}
    </div>
  );
};

SectionRentDepositFeePaymentSection.propTypes = {
  children: PropTypes.node,
};
