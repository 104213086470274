import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useDropdownSubtextMenuContext } from '../../context';

import styles from './Content.module.scss';

const Content = ({ className, children }) => {
  const { options, isOpen, refs, floatingStyles, getFloatingProps, disabled } =
    useDropdownSubtextMenuContext();

  const childrenValue =
    typeof children === 'function' ? children(options) : children;

  if (!isOpen || disabled) return null;

  return (
    <div
      ref={refs.setFloating}
      className={cx(styles.floatingDropdown, className)}
      style={floatingStyles}
      {...getFloatingProps()}
    >
      {childrenValue}
    </div>
  );
};

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default Content;
