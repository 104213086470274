import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RadioGroup from '../../../../../../../components/RadioGroup';
import CristalIcon from '../../../../../../../icons/Cristal';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './DisclosureOfPriorManufacturingOfMeth.module.scss';

const DisclosureOfPriorManufacturingOfMeth = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <>
      <div className={cx(styles.container, className)}>
        <SectionTitle
          title="Methamphetamine Manufacturing Disclosure"
          icon={CristalIcon}
        />
        <label className={styles.label}>
          Do you know if methamphetamines have ever been manufactured at your
          property?
        </label>
        <p className={styles.description}>
          South Dakota law requires that you disclose to the Tenant, or any
          prospective Tenant, knowledge of prior manufacturing of
          methamphetamines on the property.
        </p>
        <Field
          component={RadioGroup}
          id="custom_state_data.methamphetamine_manufactured"
          name="custom_state_data.methamphetamine_manufactured"
          radiosContainerClassName={styles.radioButtonsContainer}
          options={[
            {
              id: 'methamphetamine_manufactured_yes',
              value: true,
              label: 'Yes',
            },
            {
              id: 'methamphetamine_manufactured_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />
      </div>
    </>
  );
};

DisclosureOfPriorManufacturingOfMeth.propTypes = {
  className: PropTypes.string,
};

export default DisclosureOfPriorManufacturingOfMeth;
