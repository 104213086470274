import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import LEASE_PROPERTY_TYPE from '../../constants/lease/LeasePropertyType';
import otherBuildingTypes from '../../constants/listings/other-building-types';
import getPropertyTypeIcon from '../../helpers/get-property-type-icon';
import fieldRequired from '../../validators/fieldRequired';
import Input from '../Input';
import MultiCondition from '../MultiCondition';
import RadioGroup from '../RadioGroup';
import RadioGroupBox from '../RadioGroupBox';
import SelectField from '../SelectField';
import { Tooltip } from '../Tooltip';

import styles from './PropertyTypeFields.module.scss';

const propertyTypeOptions = [
  {
    label: (
      <div className={styles.propertyItem}>
        {React.createElement(
          getPropertyTypeIcon(LEASE_PROPERTY_TYPE.SINGLE_FAMILY),
          { width: 40, height: 33, className: styles.propertyIcon },
        )}
        <span className={cx(styles.propertyLabel, styles.desktop)}>
          Single-Family House
        </span>
        <span className={cx(styles.propertyDescription, styles.desktop)}>
          Standalone residence
        </span>
        <span className={cx(styles.propertyLabel, styles.mobile)}>
          Single-Family
        </span>
        <span className={cx(styles.propertyDescription, styles.mobile)}>
          Standalone
        </span>
      </div>
    ),
    value: LEASE_PROPERTY_TYPE.SINGLE_FAMILY,
  },
  {
    label: (
      <div className={styles.propertyItem}>
        {React.createElement(
          getPropertyTypeIcon(LEASE_PROPERTY_TYPE.TOWNHOUSE),
          { width: 40, height: 37, className: styles.propertyIcon },
        )}
        <span className={styles.propertyLabel}>Townhouse</span>
        <span className={styles.propertyDescription}>Individual unit</span>
      </div>
    ),
    value: LEASE_PROPERTY_TYPE.TOWNHOUSE,
  },
  {
    label: (
      <div className={styles.propertyItem}>
        {React.createElement(getPropertyTypeIcon(LEASE_PROPERTY_TYPE.CONDO), {
          width: 33,
          height: 40,
          className: styles.propertyIcon,
        })}
        <span className={styles.propertyLabel}>Condominium</span>
        <span className={styles.propertyDescription}>Individual unit</span>
      </div>
    ),
    value: LEASE_PROPERTY_TYPE.CONDO,
  },
  {
    label: (
      <div className={styles.propertyItem}>
        {React.createElement(
          getPropertyTypeIcon(LEASE_PROPERTY_TYPE.MULTI_FAMILY),
          { width: 40, height: 33, className: styles.propertyIcon },
        )}
        <span className={cx(styles.propertyLabel, styles.desktop)}>
          Small Multi-Family
        </span>
        <span className={cx(styles.propertyDescription, styles.desktop)}>
          2-4 units at the property
        </span>
        <span className={cx(styles.propertyLabel, styles.mobile)}>
          Multi-Family
        </span>
        <span className={cx(styles.propertyDescription, styles.mobile)}>
          2-4 unit property
        </span>
      </div>
    ),
    value: LEASE_PROPERTY_TYPE.MULTI_FAMILY,
  },
  {
    label: (
      <div className={styles.propertyItem}>
        {React.createElement(
          getPropertyTypeIcon(LEASE_PROPERTY_TYPE.APARTMENT),
          { width: 31, height: 40, className: styles.propertyIcon },
        )}
        <span className={cx(styles.propertyLabel, styles.desktop)}>
          Apartment Building
        </span>
        <span className={cx(styles.propertyDescription, styles.desktop)}>
          5+ units at the property
        </span>
        <span className={cx(styles.propertyLabel, styles.mobile)}>
          Apartments
        </span>
        <span className={cx(styles.propertyDescription, styles.mobile)}>
          5+ unit property
        </span>
      </div>
    ),
    value: LEASE_PROPERTY_TYPE.APARTMENT,
  },
  {
    label: (
      <div className={styles.propertyItem}>
        {React.createElement(getPropertyTypeIcon(LEASE_PROPERTY_TYPE.OTHER), {
          width: 40,
          height: 40,
          className: styles.propertyIcon,
        })}
        <span className={styles.propertyLabel}>Other Types</span>
        <span className={cx(styles.propertyDescription, styles.desktop)}>
          Mixed-use, RV park, etc.
        </span>
        <span className={cx(styles.propertyDescription, styles.mobile)}>
          Mixed, RV park, etc.
        </span>
      </div>
    ),
    value: LEASE_PROPERTY_TYPE.OTHER,
  },
];

const PropertyTypeFields = ({
  className,
  propertiesCantainerClassName,
  propertiesRadioProps: {
    className: propertiesRadioPropsClassName,
    contentClassName: propertiesRadioPropsContentClassName,
    ...otherPropertiesRadioProps
  },
  defaultValueForPropertyType,
  defaultValueForRoomRentals,
}) => {
  return (
    <div className={cx(styles.container, className)}>
      <Field
        className={styles.radioContainer}
        containerClassName={propertiesCantainerClassName}
        component={RadioGroupBox}
        id="property_type"
        name="property_type"
        radioProps={{
          className: propertiesRadioPropsClassName,
          contentClassName: cx(
            styles.itemWrapper,
            propertiesRadioPropsContentClassName,
          ),
          ...otherPropertiesRadioProps,
        }}
        options={propertyTypeOptions}
        validate={fieldRequired}
        defaultValue={defaultValueForPropertyType}
      />
      <div className={cx(styles.buildingTypeWrapper)}>
        <Field name="property_type">
          {({ input }) =>
            input.value === LEASE_PROPERTY_TYPE.OTHER ? (
              <Field
                empty
                emptyLabel="Select One"
                required
                label="Other Building Type"
                component={SelectField}
                id="other_building_type"
                name="other_building_type"
                className={styles.buildingTypeSelect}
                validate={fieldRequired}
                options={otherBuildingTypes}
              />
            ) : null
          }
        </Field>
        <MultiCondition
          condition={({ property_type, other_building_type }) =>
            property_type === LEASE_PROPERTY_TYPE.OTHER &&
            other_building_type === LEASE_PROPERTY_TYPE.OTHER
          }
        >
          <Field
            component={Input}
            labelProps={{
              className: styles.buildingTypeLabel,
            }}
            label="Other Building Type"
            id="other_building_type_specify"
            name="other_building_type_specify"
            className={styles.buildingTypeInput}
            validate={fieldRequired}
          />
        </MultiCondition>
      </div>
      <Field
        component={RadioGroup}
        id="roomRentals"
        name="roomRentals"
        label={
          <div className={styles.roomRentalsWrapper}>
            Will you have room rentals?{' '}
            <span
              className={styles.learnMore}
              data-tooltip-id="learn-more-key-tip"
            >
              Learn more
            </span>
            <Tooltip id="learn-more-key-tip">
              Room rentals are when you're renting out rooms separately within
              the property, each with their own lease.
            </Tooltip>
          </div>
        }
        options={[
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ]}
        defaultValue={defaultValueForRoomRentals}
        validate={fieldRequired}
      />
    </div>
  );
};

PropertyTypeFields.propTypes = {
  className: PropTypes.string,
  propertiesCantainerClassName: PropTypes.string,
  propertiesRadioProps: PropTypes.object,
  defaultValueForPropertyType: PropTypes.string,
  defaultValueForRoomRentals: PropTypes.bool,
};

PropertyTypeFields.defaultProps = {
  propertiesRadioProps: {},
};

export default PropertyTypeFields;
