import React from 'react';
import { useLocation } from 'react-router-dom';

import AccountAccessRoleEnum from '../../../constants/enums/AccountAccessRoleEnum';
import { useUserProfile } from '../../../core/TTgraphql';
import { linkTo, routes } from '../../../helpers/routes';
import AccountingIcon from '../../../icons/streamline/Accounting';
import DashboardIcon from '../../../icons/streamline/Dashboard';
import ExpensesIcon from '../../../icons/streamline/Expense';
import FormsIcon from '../../../icons/streamline/Forms';
import HelpIcon from '../../../icons/streamline/Help';
import LeasesIcon from '../../../icons/streamline/Leases';
import LogoutIcon from '../../../icons/streamline/Logout';
import MaintenanceIcon from '../../../icons/streamline/Maintenance';
import MessagesIcon from '../../../icons/streamline/Messages';
import PaymentsIcon from '../../../icons/streamline/Payments';
import PropertiesIcon from '../../../icons/streamline/Property';
import ReferralIcon from '../../../icons/streamline/Referral';
import RentersIcon from '../../../icons/streamline/Renters';
import ReportingIcon from '../../../icons/streamline/Reporting';
import SettingsIcon from '../../../icons/streamline/Settings';
import ToolboxNav from '../../../icons/streamline/Toolbox';
import { useConfig } from '../../../providers/ConfigProvider';
import { useTTAccountId } from '../../../providers/TTAccountProvider';
import { segmentTracking } from '../../../services/utilities';
import ReiHubLoadingModal, {
  useOpenREIHub,
} from '../../ReiHubSubscription/ReiHubLoadingModal';
import { useGetItemStatus } from '../../ShowHideComponents';
import useMessagingNotifications from '../useMessagingNotifications';

import MobileNavigation from './MobileNavigation';

const MobileNavigationWrapped = () => {
  const location = useLocation();

  const { user, muaUser, loading: userLoading } = useUserProfile({}, false);
  const { ttAccountId, setTTAccountId } = useTTAccountId();

  const usersAccounts = (muaUser?.users_accounts || []).filter(
    (acc) => acc.user_role === AccountAccessRoleEnum.MAIN || !!acc.confirmed_at,
  );

  const selectedOwner =
    usersAccounts.find((account) => account.owner.id === ttAccountId)?.owner ||
    usersAccounts[0]?.owner ||
    {};

  const { PRIVATE_BASE_PATH, IS_OWNER } = useConfig();

  const { unreadMessagesCount: newMessagingCount } = useMessagingNotifications(
    user?.id,
  );
  const isPremiumUser = user?.premium_subscription_subscribed;

  const [isModalOpen, setOpenREIHub] = useOpenREIHub({
    isDemoMode: !user.reihub_subscription_subscribed,
  });

  /**
   * NOTE: Mobile navigation can be hidden on the Messages page.
   */
  const isHidden = !useGetItemStatus('mobileNav')();

  const loginToReiHub = async () => {
    if (user.reihub_subscription_subscribed) {
      return setOpenREIHub();
    }

    return null;
  };

  const OWNER_DRAWER_ITEMS = [
    {
      label: 'Leases',
      id: 'leases',
      isActiveFilter: 'leases',
      to: PRIVATE_BASE_PATH + 'leases',
      Icon: LeasesIcon,
      onClick: () => {
        segmentTracking('leases clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Forms',
      id: 'forms',
      isActiveFilter: 'forms',
      to: PRIVATE_BASE_PATH + 'forms',
      Icon: FormsIcon,
      onClick: () => {
        segmentTracking('forms clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Payments',
      id: 'payments',
      isActiveFilter: 'payments',
      isInactiveFilter:
        'payments/expenses,payments/accounting,payments/reports',
      to: PRIVATE_BASE_PATH + 'payments',
      Icon: PaymentsIcon,
      IconProps: { strokeWidth: 0.5 },
      onClick: () => {
        segmentTracking('payments clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Expenses',
      id: 'expenses',
      isActiveFilter: 'payments/expenses',
      to: PRIVATE_BASE_PATH + 'payments/expenses',
      Icon: ExpensesIcon,
      onClick: () => {
        segmentTracking('expenses clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Accounting',
      id: 'accounting',
      Icon: AccountingIcon,
      ...(user.reihub_subscription_subscribed
        ? {}
        : {
            isActiveFilter: 'owners/payments/accounting',
            to: linkTo(routes.accounting),
          }),
      onClick: () => {
        segmentTracking('accounting clicked', {
          location: 'Nav Bar',
        });
        loginToReiHub();
      },
    },
    {
      label: 'Reports',
      id: 'reports',
      ...(user.reihub_subscription_subscribed
        ? {}
        : {
            isActiveFilter: 'owners/payments/reports',
            to: linkTo(routes.reports),
          }),
      Icon: ReportingIcon,
      onClick: () => {
        segmentTracking('reports clicked', {
          location: 'Nav Bar',
        });
        loginToReiHub();
      },
    },
    {
      label: 'Toolbox',
      id: 'toolbox',
      isActiveFilter: 'toolbox',
      to: PRIVATE_BASE_PATH + 'toolbox',
      Icon: ToolboxNav,
      onClick: () => {
        segmentTracking('toolbox clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Maintenance',
      id: 'maintenance',
      isActiveFilter: 'owners/maintenance',
      to: `${PRIVATE_BASE_PATH}maintenance/maintenance-requests`,
      Icon: MaintenanceIcon,
      onClick: () => {
        segmentTracking('maintenance clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Settings',
      id: 'settings',
      isActiveFilter: 'settings',
      to: PRIVATE_BASE_PATH + 'settings',
      Icon: SettingsIcon,
      onClick: () => {
        segmentTracking('settings clicked', {
          location: 'Nav Bar',
        });
      },
    },
    ...(isPremiumUser
      ? []
      : [
          {
            label: 'Referrals',
            id: 'earn_referral',
            to: `${PRIVATE_BASE_PATH}referrals`,
            Icon: ReferralIcon,
            IconProps: { strokeWidth: 1 },
            onClick: () => {
              segmentTracking('referrals clicked', {
                location: 'Nav Bar',
              });
            },
          },
        ]),
    {
      label: 'Help',
      id: 'help',
      isActiveFilter: 'help_center',
      to: PRIVATE_BASE_PATH + 'help_center',
      Icon: HelpIcon,
      onClick: () => {
        segmentTracking('help clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Logout',
      id: 'logout',
      Icon: LogoutIcon,
      to: '/logout',
      onClick: () => {
        segmentTracking('logout clicked', {
          location: 'Nav Bar',
        });
      },
    },
  ];

  const OWNER_NAV_ITEMS = [
    {
      label: 'Dashboard',
      id: 'dashboard',
      isActiveFilter: 'dashboard',
      to: PRIVATE_BASE_PATH + 'dashboard',
      Icon: DashboardIcon,
      onClick: () => {
        segmentTracking('dashboard clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Properties',
      id: 'properties',
      isActiveFilter: 'properties',
      to: PRIVATE_BASE_PATH + 'properties',
      Icon: PropertiesIcon,
      onClick: () => {
        segmentTracking('properties clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Messages',
      id: 'messages',
      isActiveFilter: 'owners/messages',
      to: PRIVATE_BASE_PATH + 'messages',
      Icon: MessagesIcon,
      onClick: () => {
        segmentTracking('messages clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Renters',
      id: 'renters',
      isActiveFilter: 'renters',
      to: PRIVATE_BASE_PATH + 'renters',
      Icon: RentersIcon,
      onClick: () => {
        segmentTracking('renters clicked', {
          location: 'Nav Bar',
        });
      },
    },
  ];

  const RENTER_NAV_ITEMS = [
    {
      label: 'Home',
      id: 'dashboard',
      isActiveFilter: 'dashboard',
      to: PRIVATE_BASE_PATH + 'dashboard',
      Icon: PropertiesIcon,
    },
    {
      label: 'Messages',
      id: 'messages',
      isActiveFilter: 'messages',
      to: PRIVATE_BASE_PATH + 'messages',
      Icon: MessagesIcon,
    },
    {
      label: 'Toolbox',
      id: 'toolbox',
      href: 'https://www.turbotenant.com/renter-toolbox/',
      Icon: ToolboxNav,
      onClick: () => {
        segmentTracking('toolbox clicked', {
          location: 'Tenants Nav Bar',
        });
      },
    },
    {
      label: 'Help',
      id: 'help_center',
      isActiveFilter: 'help_center',
      to: PRIVATE_BASE_PATH + 'help_center',
      Icon: HelpIcon,
    },
    {
      label: 'Settings',
      id: 'settings',
      isActiveFilter: 'settings',
      to: PRIVATE_BASE_PATH + 'settings',
      Icon: SettingsIcon,
    },
  ];

  const currentAccount = selectedOwner
    ? {
        label: selectedOwner.company
          ? selectedOwner.company
          : `${selectedOwner.first_name} ${selectedOwner.last_name}`,
        IconProps: {
          squircle: true,
          size: 'small',
          ...(selectedOwner.company
            ? {
                firstName: selectedOwner.company,
                profilePictureURL: selectedOwner.custom_logo,
              }
            : {
                firstName: selectedOwner.first_name,
                lastName: selectedOwner.last_name,
              }),
        },
      }
    : null;

  const accounts = usersAccounts.map(({ owner }) => ({
    label: owner.company
      ? owner.company
      : `${owner.first_name} ${owner.last_name}`,
    IconProps: {
      squircle: true,
      size: 'small',
      ...(owner.company
        ? {
            firstName: owner.company,
            profilePictureURL: owner.custom_logo,
          }
        : {
            firstName: owner.first_name,
            lastName: owner.last_name,
          }),
    },
    id: `account-${owner.id}`,
    onClick: () => setTTAccountId(owner.id),
    isActive: owner.id === ttAccountId,
  }));

  return (
    <>
      <MobileNavigation
        location={location}
        isHidden={isHidden}
        isOwner={IS_OWNER}
        ownerNavItems={OWNER_NAV_ITEMS}
        ownerDrawerItems={OWNER_DRAWER_ITEMS}
        renterNavItems={RENTER_NAV_ITEMS}
        unreadMessagesCount={newMessagingCount}
        userDataLoaded={userLoading}
        user={user}
        accounts={accounts}
        currentAccount={currentAccount}
      />
      <ReiHubLoadingModal open={isModalOpen} />
    </>
  );
};

export default MobileNavigationWrapped;
