import React from 'react';

import RadioCheck from '../../../../icons/RadioCheck';

import {
  IncomeInsightsContent,
  IncomeInsightsIcon,
  IncomeInsightsTitle,
} from './Elements';
import RenterReportedIncome from './RenterReportedIncome';

const OkIncomeInsightCard = ({ firstName, application }) => (
  <>
    <IncomeInsightsIcon okIcon>
      <RadioCheck />
    </IncomeInsightsIcon>
    <IncomeInsightsContent>
      <IncomeInsightsTitle greenTitle biggerTitle>
        TransUnion estimates {firstName}’s annual income is{' '}
        <b>close to or higher</b> than what they reported.
      </IncomeInsightsTitle>
      <RenterReportedIncome application={application} />
    </IncomeInsightsContent>
  </>
);

export default OkIncomeInsightCard;
