import React, { memo } from 'react';
import PropTypes from 'prop-types';

const PhoneThick = (props) => (
  <svg {...props} viewBox="0 0 18 18">
    <path
      d="M1.987.72.975 1.734a3.326 3.326 0 0 0-.422 4.19 41.222 41.222 0 0 0 11.523 11.523 3.33 3.33 0 0 0 4.19-.421l1.013-1.013c.961-.96.961-2.519 0-3.478l-1.847-1.847-.14-.13a2.457 2.457 0 0 0-3.338.13l-.151.15-.097-.08a40.864 40.864 0 0 1-4.463-4.464l-.08-.098.15-.15a2.46 2.46 0 0 0 0-3.477L5.467.72a2.46 2.46 0 0 0-3.479 0Zm2.464 1.015 1.847 1.849a1.025 1.025 0 0 1 0 1.447l-.615.615a.718.718 0 0 0-.048.962 41.996 41.996 0 0 0 5.756 5.758.718.718 0 0 0 .962-.047l.616-.615a1.021 1.021 0 0 1 1.447-.001l1.848 1.848c.4.399.4 1.047 0 1.446l-1.013 1.014a1.894 1.894 0 0 1-2.383.239A39.786 39.786 0 0 1 1.75 5.132a1.89 1.89 0 0 1 .24-2.384l1.012-1.013c.4-.4 1.048-.4 1.448 0Z"
      fill="#042238"
      fillRule="evenodd"
    />
  </svg>
);

PhoneThick.defaultProps = {
  width: 18,
  height: 18,
};

PhoneThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(PhoneThick);
