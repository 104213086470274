import React from 'react';
import cx from 'classnames';
import { array, bool, object, oneOfType, string } from 'prop-types';

import Footer from '../../components/Footer';
import Logo from '../../components/Logo';
import { LAYOUT_IDS } from '../../constants';
import useBodyAndRootStyles from '../../hooks/useBodyAndRootStyles';

import styles from './BluePageSimple.module.scss';

const BluePageSimple = ({
  showFooterContent = true,
  showLogo = true,
  dark = false,
  children,
} = {}) => {
  useBodyAndRootStyles(`
    min-height: 100vh;
  `);

  return (
    <div className={cx(styles.container, { [styles.dark]: dark })}>
      {showLogo && (
        <Logo maxheight={40} maxwidth={139} className={styles.logo} />
      )}
      {children}
      {showFooterContent && <Footer className={styles.footer} />}
    </div>
  );
};

BluePageSimple.propTypes = {
  children: oneOfType([object, array]),
  loading: bool,
  title: string,
};

BluePageSimple.layoutId = LAYOUT_IDS.BLUE_PAGE_SIMPLE;

export default BluePageSimple;
