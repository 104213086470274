import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Delete = (props) => (
  <svg {...props} viewBox="0 0 18 20" fill={props.color}>
    <path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z" />
  </svg>
);

Delete.defaultProps = {
  width: 18,
  height: 20,
  color: colors.primary,
};

Delete.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Delete);
