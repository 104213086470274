import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const ESignThick = (props) => (
  <svg {...props} viewBox="0 0 24 24" fill={props.color}>
    <path d="M9.572 13.659a.78.78 0 01.782.78v1.952a.78.78 0 01-.782.78h-.196v.507l.152.031c2.841.606 4.473 2.307 4.778 5.18l.025.272a.78.78 0 01-.78.839H.782a.78.78 0 01-.779-.84c.23-3.05 1.829-4.83 4.736-5.451l.145-.03v-.508H4.69a.781.781 0 01-.776-.683l-.006-.097v-1.952c0-.43.35-.78.782-.78zm-.782 1.56H5.47v.391h.195c.399 0 .727.298.776.683l.006.098v1.95a.78.78 0 01-.68.775c-2.384.311-3.668 1.304-4.082 3.263l-.012.06h10.983l-.01-.054c-.406-1.877-1.653-2.882-3.898-3.233l-.254-.036a.78.78 0 01-.68-.774V16.39c0-.431.35-.78.782-.78h.194v-.391zM20.115.682l.007.1-.001 1.715L22.044.575a.782.782 0 011.168-.07c1.194 1.193.983 3.291-.308 5.542a.781.781 0 01-.574.386l-.104.007-3.843-.001-.391.391h2.813a.78.78 0 01.098 1.555l-.098.006-4.375-.001-.392.391h3.302c.715 0 1.054.878.526 1.358-2.404 2.182-5.615 3.035-7.388 2.199l-1.108 1.107a.782.782 0 01-1.18-1.016l.075-.088 1.12-1.118c-.82-1.868.649-5.285 3.345-7.975 1.358-1.36 2.997-2.54 4.264-3.166a.782.782 0 011.121.6zm-3.277 9.661h-2.363l-.755.756c.754.087 1.849-.14 2.979-.686l.139-.07zm1.72-8.206l-.182.12a17.686 17.686 0 00-2.272 1.832l-.269.263c-1.94 1.936-3.08 4.257-3.14 5.563l4.79-4.787a.786.786 0 01.042-.041l1.031-1.028V2.136zm3.87.265l-2.47 2.466a.784.784 0 01-.008.011h1.81l.016-.028c.498-.979.706-1.83.652-2.45z" />
  </svg>
);

ESignThick.defaultProps = {
  width: 24,
  height: 24,
  color: colors.text,
};

ESignThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ESignThick);
