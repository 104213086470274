import React from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { useUserProfile } from '../../../core/TTgraphql';
import checkIfLandlordPaysExperimentIsTest from '../../../helpers/experiments/checkIfLandlordPaysExperimentIsTest';
import Close from '../../../icons/Close';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';

import styles from './InviteApplyScreenOptionsModal.module.scss';

const InviteApplyScreenOptionsModal = ({
  className,
  onClose,
  open,
  history,
  listingId,
}) => {
  const { PRIVATE_BASE_PATH } = useConfig();
  const { user } = useUserProfile({}, false);

  const isLandlordPaysExperimentTest = checkIfLandlordPaysExperimentIsTest(
    user.active_experiments,
  );

  const handleInvite = (e) => {
    e.preventDefault();
    segmentTracking('invite_to_apply clicked', {
      location: 'combine_actions_screen_application_modal',
      ...(isLandlordPaysExperimentTest !== null && {
        experiment: `LL Pays ${
          isLandlordPaysExperimentTest ? 'Test' : 'Control'
        }`,
      }),
    });
    history.push(
      `${PRIVATE_BASE_PATH}invite-renter/${
        listingId ? `?propertyId=${encodeURIComponent(listingId)}` : ''
      }`,
    );

    onClose();
  };

  const handleScreen = (e) => {
    e.preventDefault();
    segmentTracking('screen_a_tenant clicked', {
      location: 'combine_actions_screen_application_modal',
      experiment: 'combine_actions_screen_application',
    });
    history.push(`${PRIVATE_BASE_PATH}externalapplication/intro`);
    onClose();
  };

  return (
    <Modal
      open={open}
      className={cx(styles.container, className)}
      autoFocus={false}
    >
      <div className={styles.header}>
        <Close className={styles.closeButton} onClick={onClose} />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          How do you want to screen the tenant?
        </div>

        <div className={styles.row}>
          <div
            className={cx(
              styles.card,
              styles.withBackground,
              styles.recommended,
            )}
          >
            <div className={styles.recommendedBanner}>RECOMMENDED</div>
            <div className={cx(styles.cardTitle, styles.recommended)}>
              Rental Application & Screening Report
            </div>
            <div className={styles.cardText}>
              Get the full picture with everything you need to decide if they're
              a good fit.
            </div>
            <Button onClick={handleInvite}>INVITE TO APPLY</Button>
          </div>
          <div className={styles.card}>
            <div className={styles.cardTitle}>Screening Report Only</div>
            <div className={styles.cardText}>
              Perfect when the renter already filled out an application
              elsewhere.
            </div>
            <Button onClick={handleScreen}>REQUEST A REPORT</Button>
          </div>
        </div>

        <div
          className={cx(
            styles.cardText,
            styles.semibold,
            styles.noMarginBottom,
          )}
        >
          Not sure which one you need?{' '}
          <a
            href="http://support.turbotenant.com/en/articles/6362365-rental-applications-vs-screening-reports#h_66d8135827"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            See our comparison chart here.
          </a>
        </div>
      </div>
    </Modal>
  );
};

InviteApplyScreenOptionsModal.propTypes = {
  history: PropTypes.object,
  listingId: PropTypes.string,
};

export default withRouter(InviteApplyScreenOptionsModal);
