import React from 'react';
import { PatternFormat } from 'react-number-format';
import cx from 'classnames';
import PropTypes from 'prop-types';

import getFieldError from '../../validators/get-field-error';
import Label from '../Label';
import PlainInput from '../PlainInput';

import styles from './PhoneField.module.scss';

const PhoneField = ({
  input,
  id,
  label,
  className,
  meta,
  disabled,
  hint,
  inputProps,
  labelProps,
  ...otherProps
}) => {
  const error = getFieldError(meta);

  return (
    <div
      className={cx(styles.container, className, {
        [styles.disabled]: disabled,
      })}
    >
      {label && (
        <Label htmlFor={id} hint={hint} {...labelProps}>
          {label}
        </Label>
      )}
      <PatternFormat
        format="(###) ###-####"
        mask="_"
        {...input}
        onChange={(e) => {
          e.preventDefault();
          input.onChange(e.target.value);
        }}
        value={input.value}
        disabled={disabled}
        {...inputProps}
        {...otherProps}
        customInput={PlainInput}
        invalid={!!error}
        id={id}
        type="tel"
        inputMode="tel"
      />
      {!!error && (
        <span id={`${id}_errormsg`} className={styles.error}>
          {error}
        </span>
      )}
    </div>
  );
};

PhoneField.propTypes = {
  input: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  className: PropTypes.string,
  meta: PropTypes.object,
  alwaysShowMask: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

PhoneField.defaultProps = {
  alwaysShowMask: false,
  disabled: false,
  inputProps: {},
  labelProps: {},
};

export default PhoneField;
