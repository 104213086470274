import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import PlainInput from '../PlainInput';

import styles from './PlainInputWithIcon.module.scss';

const PlainInputWithIcon = React.forwardRef(
  ({ icon: Icon, className, iconProps, iconRight, ...otherProps }, ref) => (
    <div className={cx(styles.field, className)}>
      <div
        className={cx(styles.iconWrap, {
          [styles.right]: iconRight,
        })}
      >
        <Icon {...iconProps} className={cx(styles.icon, iconProps.className)} />
      </div>
      <PlainInput
        ref={ref}
        className={cx(styles.input, { [styles.right]: iconRight })}
        {...otherProps}
      />
    </div>
  ),
);

PlainInputWithIcon.displayName = 'PlainInputWithIcon';

PlainInputWithIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  className: PropTypes.string,
  iconProps: PropTypes.object,
  iconRight: PropTypes.bool,
};

PlainInputWithIcon.defaultProps = {
  iconProps: {},
  iconRight: false,
};

export default PlainInputWithIcon;
