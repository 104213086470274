import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const SIDES = {
  right: '0',
  bottom: '90deg',
  left: '180deg',
  top: '270deg',
};

const ArrowRight = ({ color, ...props }) => (
  <svg
    viewBox="0 0 9 13"
    fill={color}
    style={{
      transform: `rotateZ(${SIDES[props.side]})`,
      ...props.style,
    }}
    {...props}
  >
    <path d="M.392.695A.89.89 0 011.56.543L1.65.61l6.234 5.344a.89.89 0 01.081 1.26l-.08.08-6.235 5.344a.89.89 0 01-1.252-1.262l.079-.079 5.468-4.673L.476 1.952A.89.89 0 01.324.783L.392.695z" />
  </svg>
);

ArrowRight.defaultProps = {
  color: colors.primary,
  width: 9,
  height: 13,
  side: 'right',
};

ArrowRight.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  side: PropTypes.oneOf(['right', 'left', 'top', 'bottom']),
};

export default memo(ArrowRight);
