import React from 'react';
import PropTypes from 'prop-types';

import SectionInfoBlock from '../../../../../common/SectionInfoBlock';

const LateFeeInfo = ({ className }) => (
  <SectionInfoBlock className={className}>
    <>
      <strong>Late Fees:</strong> Rent is due in full on the first day of each
      and every month. If rent is not received on or before the 5th day of the
      month, you may charge your tenant a late fee of 10% of the rent or 10% of
      the balance due of unpaid rent amount, whichever is less.
    </>
  </SectionInfoBlock>
);

LateFeeInfo.propTypes = {
  className: PropTypes.string,
};

export default LateFeeInfo;
