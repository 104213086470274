import { line, text } from '../../../../../../constants/colors';

export const chatsWrapperStyle = (props, theme) => {
  const borderStyle =
    props._parent === ''
      ? {
          border: `1px solid ${theme.borderColor.primary}`,
        }
      : {};

  return {
    'display': 'flex',
    'flexDirection': 'column',
    'height': '100%',
    'boxSizing': 'border-box',
    ...borderStyle,
    '*': {
      'boxSizing': 'border-box',
      '::-webkit-scrollbar': {
        width: '8px',
        height: '4px',
      },
      '::-webkit-scrollbar-track': {
        background: '#ffffff00',
      },
      '::-webkit-scrollbar-thumb': {
        'background': '#ccc',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  };
};

export const chatsHeaderStyle = (theme, isFromUniversalMessaging) => {
  return {
    padding: '12px 16px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    minHeight: isFromUniversalMessaging ? '50px' : '67px',
    borderBottom: `1px solid ${line}`,
  };
};

export const chatsHeaderCloseStyle = (img, theme) => {
  const mq = [...theme.breakPoints];

  return {
    cursor: 'pointer',
    display: 'none',
    mask: `url(${img}) no-repeat left center`,
    backgroundColor: `${theme.primaryColor}`,
    height: '24px',
    width: '33%',
    [`@media ${mq[0]}`]: {
      display: 'block!important',
    },
  };
};

export const chatsHeaderTitleStyle = (props) => {
  const alignment =
    props.hasOwnProperty('enableCloseMenu') && props.enableCloseMenu.length > 0
      ? {
          width: '33%',
          textAlign: 'center',
        }
      : {};

  return {
    margin: '0',
    display: 'inline-block',
    width: '100%',
    textAlign: 'left',
    color: text,
    fontFamily: 'Poppins',
    fontSize: props.isFromUniversalMessaging ? '20px' : '24px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '34px',
  };
};

export const chatsMsgStyle = (isFromUniversalMessaging) => {
  return {
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: isFromUniversalMessaging ? '25%' : '50%',
  };
};

export const chatsMsgTxtStyle = (theme) => {
  return {
    margin: '0',
    minHeight: '36px',
    color: `${theme.color.secondary}`,
    fontSize: '20px!important',
    fontWeight: '600',
    lineHeight: '30px',
    wordWrap: 'break-word',
    padding: '0 16px',
  };
};

export const chatsListStyle = () => {
  return {
    width: '100%',
    overflowY: 'auto',
    margin: '0',
    padding: '0',
  };
};

export const loaderContainerStyle = () => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };
};

export const loaderTextStyle = () => {
  return {
    margin: '0',
    fontSize: '18px',
    fontWeight: 600,
    fontFamily: 'Poppins',
  };
};
