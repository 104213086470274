import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RadioGroup from '../../../../../../../components/RadioGroup';
import FloodIcon from '../../../../../../../icons/Flood';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './FloodDisclosureIN.module.scss';

const FloodDisclosureIN = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Flood Disclosure" icon={FloodIcon} />
      <label className={styles.label}>
        Is the Property located within a flood zone?
      </label>
      <p className={styles.description}>
        Indiana law requires that you disclose this. Disclosure is required if
        the lowest floor of the property, including a basement, is at or below
        the 100 year frequency flood elevation as determined by FEMA or the
        Department of Natural Resources.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.has_property_flooded"
        name="custom_state_data.has_property_flooded"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'has_property_flooded_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'has_property_flooded_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
    </div>
  );
};

FloodDisclosureIN.propTypes = {
  className: PropTypes.string,
};

export default FloodDisclosureIN;
