import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import BackArrow from '../../icons/BackArrow';
import { segmentTracking } from '../../services/utilities';
import FlatButton from '../FlatButton';

import styles from './BackButton.module.scss';

const BackButton = ({
  className,
  to,
  fallback,
  history,
  disabled,
  text = 'Back',
  onClick,
  segmentEvent,
  icon: Icon,
}) => {
  const goBack = useCallback(() => {
    if (segmentEvent) {
      segmentTracking(segmentEvent.event, segmentEvent.properties);
    }

    let useFallback = true;
    const before = () => {
      useFallback = false;
      window.removeEventListener('beforeunload', before);
    };
    window.addEventListener('popstate', before);
    history.goBack();

    setTimeout(function () {
      if (useFallback && fallback) {
        history.push(fallback);
      }
    }, 100);
  }, [fallback, history]);
  return (
    <FlatButton
      icon={Icon ? Icon : BackArrow}
      iconProps={{ className: styles.icon }}
      className={cx(styles.button, className)}
      onClick={onClick ? onClick : to ? undefined : goBack}
      to={to}
      disabled={disabled}
    >
      {text}
    </FlatButton>
  );
};

BackButton.propTypes = {
  fallback: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  segmentEvent: PropTypes.shape({
    event: PropTypes.string,
    properties: PropTypes.object,
  }),
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
};

export default withRouter(BackButton);
