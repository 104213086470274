const BANNER_NAMES = {
  PREMIUM_SUBSCRIPTION: 'PREMIUM_SUBSCRIPTION',
  CUSTOM_QUESTIONS_GLOBALLY: 'CUSTOM_QUESTIONS_GLOBALLY',
  CUSTOM_QUESTIONS_MODAL: 'CUSTOM_QUESTIONS_MODAL',
  CUSTOM_QUESTIONS_BANNER: 'CUSTOM_QUESTIONS_BANNER',
  MOVERS_INTERNET_CARD: 'MOVERS_INTERNET_CARD',
  MOVERS_READY_CARD: 'MOVERS_READY_CARD',
  TURBO_PAY: 'TURBO_PAY',
  RENT_REPORTING: 'RENT_REPORTING',
  ROOM_RENTALS_FAKEDOOR: 'ROOM_RENTALS_FAKEDOOR',
  LULA_BANNER: 'LULA_BANNER',
  LEADS_QUESTIONNAIRE: 'LEADS_QUESTIONNAIRE',
  OFFLINE_TO_ONLINE_CONVERSION: 'OFFLINE_TO_ONLINE_CONVERSION',
  OFFLINE_TO_ONLINE_CONVERSION_PAGE: 'OFFLINE_TO_ONLINE_CONVERSION_PAGE',
};

export default BANNER_NAMES;
