import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import HandyPerson from '../../icons/HandyPerson';
import Button from '../Button';
import Card from '../Card';
import ShowIntercomLink from '../ShowIntercomLink';

import styles from './FullPageError.module.scss';

const FullPageError = ({
  title,
  description,
  action,
  to,
  onClick,
  style,
  reason,
}) => {
  useEffect(() => {
    window?.atatus &&
      window.atatus.notify(
        'FullPageError' + (reason && JSON.stringify(reason)),
      );
  }, []);
  return (
    <Card className={styles.container} style={style}>
      <div className={styles.iconWrap}>
        <HandyPerson />
      </div>
      <div className={styles.content}>
        {title ? (
          <h1 id="done-title" data-qa="done-card-title">
            {title}
          </h1>
        ) : null}
        {description ? <p>{description}</p> : null}
        {action ? (
          <Button
            className={styles.action}
            id="done-action"
            to={to}
            onClick={onClick}
            data-qa="done-card-button-done"
          >
            {action}
          </Button>
        ) : null}
      </div>
    </Card>
  );
};

FullPageError.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.elementType,
  iconProps: PropTypes.object,
  to: PropTypes.string,
  onClick: PropTypes.func,
  action: PropTypes.string,
  reason: PropTypes.object,
};

FullPageError.defaultProps = {
  title: 'Uh oh! Something went wrong…',
  description: (
    <>
      We might need to call in someone handy! Try refreshing the page and seeing
      if that fixes the problem. If that doesn’t work, you can{' '}
      <ShowIntercomLink>contact our customer support team.</ShowIntercomLink>
    </>
  ),
};

export default FullPageError;
