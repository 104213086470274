import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import PlainToast from '../PlainToast';

import { useHideToast } from './ToastController';

const Toast = ({ id, message, className, type, timeout, centerScreen }) => {
  const hide = useHideToast(id);

  useEffect(() => {
    if (!timeout) {
      return null;
    }
    const timer = setTimeout(hide, timeout);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <PlainToast
      className={className}
      onClose={hide}
      message={message}
      type={type}
      centerScreen={centerScreen}
    />
  );
};

Toast.propTypes = {
  id: PropTypes.number,
  message: PropTypes.string,
  type: PropTypes.oneOf(['info', 'error', 'warning', 'success']),
  className: PropTypes.string,
  centerScreen: PropTypes.bool,
  timeout: PropTypes.number,
};

export default Toast;
