import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const More = (props) => (
  <svg {...props} viewBox="0 0 4 16" fill={props.color}>
    <path d="M2 12a2 2 0 110 4 2 2 0 010-4zm0-6a2 2 0 110 4 2 2 0 010-4zm0-6a2 2 0 110 4 2 2 0 010-4z" />
  </svg>
);

More.defaultProps = {
  width: 4,
  height: 16,
  color: colors.primary,
};

More.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(More);
