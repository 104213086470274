import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../components/Modal/index';

import { RENEWAL_FREQUENCIES } from './constants';
import REIHubSubscriptionSuccess from './REIHubSubscriptionSuccess';

import styles from './REIHubSubscriptionSuccessModal.module.scss';

const ReiHubSubscriptionSuccessModal = ({
  open,
  onClose,
  renewalFrequency,
  ...rest
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      containerClassName={styles.modalWrap}
      className={styles.container}
      autoFocus={false}
      id="reiHubSubscriptionSuccessModal"
    >
      <REIHubSubscriptionSuccess
        onClose={onClose}
        className={styles.card}
        isYearlySubscription={renewalFrequency === RENEWAL_FREQUENCIES.YEARLY}
        renewalFrequency={renewalFrequency}
        {...rest}
      />
    </Modal>
  );
};

ReiHubSubscriptionSuccessModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  renewalFrequency: PropTypes.oneOf(Object.values(RENEWAL_FREQUENCIES)),
};

export default ReiHubSubscriptionSuccessModal;
