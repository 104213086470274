import { FORM_ERROR } from 'final-form';

import { loginCometChatUser } from '../components/CometChatWorkspace/utils';
import { requestLogin } from '../core/auth/authService';
import { getCookies, setCookie } from '../helpers/cookies';
import getDeviceLoginToken from '../helpers/get-device-login-token';
import getDeviceSignature from '../helpers/get-device-signature';
import setDeviceLoginToken from '../helpers/set-device-login-token';

import * as localStorageService from './localStorageService';
import { gtmDataLayerPush } from './utilities';

const handleLoginRequest = async ({
  history,
  data,
  captchaToken,
  ssoService,
  redirectOnSuccess,
  redirectPath,
  showErrorToast,
  apolloClient,
}) => {
  const deviceSignature = getDeviceSignature();
  const deviceLoginToken = getDeviceLoginToken();
  const { user, password, remember, service, token } = data;
  let response;

  try {
    response = await requestLogin({
      email: user,
      password,
      accountType: 3,
      service,
      token,
      deviceSignature,
      deviceLoginToken,
      captchaToken,
      ...(ssoService ? { ssoService } : {}),
      redirectPath,
    });

    const cookies = getCookies();
    const alreadyMember = cookies?.alreadyMember || null;
    if (!alreadyMember) {
      const expirationTime = 1000 * 60 * 60 * 24 * 60;
      setCookie('alreadyMember', true, expirationTime);
    }
  } catch (error) {
    if (error.error === 400 && error.trustedToken) {
      return history.push({
        pathname: '/auth/notrecognized',
        state: {
          userEmail: user,
          trustedToken: error.trustedToken,
        },
      });
    }
    const errorMessage =
      error.code === 403
        ? error.response?.reason === 'ACCOUNT_CLOSED'
          ? 'Your account has been closed. Please contact support@turbotenant.com if you need your account reactivated.'
          : 'Your account has been suspended please email support@turbotenant.com'
        : 'Wrong email or password.';
    if (service) {
      showErrorToast(errorMessage);
      return;
    }
    return {
      [FORM_ERROR]: errorMessage,
    };
  }
  const { messaging_token } = response || {};

  gtmDataLayerPush({
    event: 'login',
    method: service ? service : 'email',
  });

  if (messaging_token) {
    loginCometChatUser(messaging_token);
  }
  if (remember) {
    localStorageService.setItem(
      'credentials',
      JSON.stringify({
        user,
        password: btoa(password),
      }),
    );
  }
  setDeviceLoginToken('');
  apolloClient && apolloClient.resetStore().catch(() => {});
  redirectOnSuccess(response);
};

export default handleLoginRequest;
