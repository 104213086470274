import React, { useEffect, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../Button';
import FinalFormError from '../FinalFormError';

import styles from './Wizard.module.scss';

const Wizard = ({
  className,
  onSubmit,
  page,
  nextBtnClassName,
  nextBtnLabel,
  onNextClick,
  previousBtnClassName,
  previousBtnLabel,
  onPrevClick,
  submitLabel,
  initialValues,
  initialValuesEqual,
  keepDirtyOnReinitialize,
  mutators,
  decorators,
  subscription,
  submitDisabled,
  buttonsClassName,
  showNavigation,
  onValuesUpdate,
  children,
}) => {
  const [values, setValues] = useState(initialValues || {});

  const validate = (values) => {
    const activePage = React.Children.toArray(children)[page];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  const handleSubmit = (values) => {
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(values);
    } else {
      onNextClick && onNextClick(values);
      setValues(values);
    }
  };

  const activePage = React.Children.toArray(children)[page];
  const isLastPage = page === React.Children.count(children) - 1;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={values}
      initialValuesEqual={initialValuesEqual}
      keepDirtyOnReinitialize={keepDirtyOnReinitialize}
      validate={validate}
      subscription={subscription}
      mutators={mutators}
      decorators={decorators}
    >
      {({ handleSubmit, form, submitting }) => (
        <form className={className} onSubmit={handleSubmit}>
          {activePage}
          <div className={cx(styles.buttons, buttonsClassName)}>
            {onValuesUpdate && (
              <FormSpy subscription={{ values: true }}>
                {({ values }) => {
                  // Schedule the state update after render is complete
                  useEffect(() => {
                    onValuesUpdate(values);
                  }, [values]);

                  return null;
                }}
              </FormSpy>
            )}
            {page > 0 && onPrevClick && (
              <Button
                className={cx(styles.btn, styles.prev, previousBtnClassName)}
                type="button"
                // We use form.getState().values because weirdly, 'values' does not contain
                // latest form state here
                onClick={() => onPrevClick(form.getState().values)}
              >
                {previousBtnLabel}
              </Button>
            )}
            {showNavigation && (
              <>
                {!isLastPage && onNextClick && (
                  <Button
                    className={cx(styles.btn, styles.next, nextBtnClassName)}
                    type="submit"
                  >
                    {nextBtnLabel}
                  </Button>
                )}
                {isLastPage && (
                  <Button
                    className={cx(styles.btn, styles.submit)}
                    type="submit"
                    loading={submitting}
                    disabled={submitting || submitDisabled}
                  >
                    {submitLabel}
                  </Button>
                )}
              </>
            )}
          </div>
          {isLastPage && <FinalFormError className={styles.error} />}
        </form>
      )}
    </Form>
  );
};

Wizard.Page = ({ children }) => children;

Wizard.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  nextBtnClassName: PropTypes.string,
  nextBtnLabel: PropTypes.string,
  onNextClick: PropTypes.func,
  previousBtnClassName: PropTypes.string,
  previousBtnLabel: PropTypes.string,
  onPrevClick: PropTypes.func,
  submitLabel: PropTypes.string,
  initialValues: PropTypes.object,
  initialValuesEqual: PropTypes.func,
  keepDirtyOnReinitialize: PropTypes.bool,
  mutators: PropTypes.object,
  decorators: PropTypes.array,
  subscription: PropTypes.object,
  submitDisabled: PropTypes.bool,
  buttonsClassName: PropTypes.string,
  showNavigation: PropTypes.bool,
  onValuesUpdate: PropTypes.func,
  children: PropTypes.node,
};

Wizard.defaultProps = {
  nextBtnLabel: 'Next',
  previousBtnLabel: 'Previous',
  submitLabel: 'Submit',
  showNavigation: true,
  onValuesUpdate: () => null,
};

export default Wizard;
