import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import get from 'lodash/get';

import Button from '../../../components/Button';
import FieldError from '../../../components/FieldError';
import FlatButton from '../../../components/FlatButton';
import IconButton from '../../../components/IconButton';
import Label from '../../../components/Label';
import PlainInput from '../../../components/PlainInput';
import colors from '../../../constants/colors';
import PromoCodeDurationsEnum from '../../../constants/enums/PromoCodeDurationsEnum';
import convertCentsToDollars from '../../../helpers/convert-cents-to-dollars';
import toCurrency from '../../../helpers/toCurrency';
import CloseIcon from '../../../icons/Close';

import promoCodeQuery from './promoCode.graphql';

import styles from './PromoCodeField.module.scss';

const discount = (promoCode) => {
  if (promoCode.percent_off) {
    return `${promoCode.percent_off}%`;
  }
  return `${toCurrency(convertCentsToDollars(promoCode.amount_off))}`;
};

const getFieldError = ({
  touched,
  error,
  dirtySinceLastSubmit,
  submitError,
  submitFailed,
}) => {
  if (touched && error && submitFailed && !dirtySinceLastSubmit) {
    return error;
  }
  if (!dirtySinceLastSubmit && submitError) {
    return submitError;
  }
  return null;
};

const PromoCodeField = ({ input, id, name, meta, coupon, setCoupon }) => {
  const [apply, setApply] = useState(null);
  const [error, setError] = useState(null);

  const [getPromoCode, { loading }] = useLazyQuery(promoCodeQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (result) => {
      const promo = get(result, 'viewer.promoCode');
      if (!promo) {
        setError('Code does not exist or has expired.');
      } else {
        setCoupon(promo);
      }
    },
    onError: () => {
      setError('Something went wrong, please try again.');
    },
  });

  const getDiscountText = () => {
    return `(${discount(coupon)} ${
      coupon.duration === PromoCodeDurationsEnum.ONCE ? 'off first year' : ''
    })`;
  };

  if (coupon) {
    return (
      <div className={styles.container}>
        <Label htmlFor={id}>Promo Code</Label>
        <div className={styles.promo}>
          <div className={styles.code}>{coupon.code}</div>
          <div className={styles.discount}>{getDiscountText()}</div>
          <IconButton
            icon={CloseIcon}
            className={styles.remove}
            iconProps={{
              color: colors.textLight,
              width: 8,
              height: 8,
            }}
            onClick={() => {
              setCoupon(null);
              input.onChange('');
            }}
          />
        </div>
      </div>
    );
  }

  if (!apply) {
    return (
      <FlatButton className={styles.start} onClick={() => setApply(true)}>
        Apply a promo code.
      </FlatButton>
    );
  }

  const promoError = error || getFieldError(meta);
  return (
    <div className={styles.container}>
      <Label htmlFor={id} hint="(Optional)">
        Promo Code
      </Label>
      <div className={styles.group}>
        <PlainInput
          id={id}
          name={name}
          {...input}
          onChange={(e) => {
            if (error) {
              setError(null);
            }
            input.onChange && input.onChange(e);
          }}
          className={styles.input}
          invalid={!!promoError}
        />
        <Button
          small
          secondary
          onClick={() => {
            getPromoCode({
              variables: {
                code: input.value,
              },
            });
          }}
          loading={loading}
          type="button"
          disabled={!input.value}
          className={styles.apply}
        >
          Apply
        </Button>
      </div>
      <FieldError id={id} error={promoError} />
    </div>
  );
};

export default PromoCodeField;
