import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';
import IconInformationCircleThick from '../../icons/InformationCircleThick';
import { Tooltip } from '../Tooltip';

import styles from './IconTooltip.module.scss';

let idCounter = 0;
const useTooltipId = () => {
  const id = useMemo(() => idCounter++, []);
  return `tooltip-${id}`;
};

const IconTooltip = ({ className, tip, icon, iconProps, place }) => {
  const tooltipId = useTooltipId();

  const Icon = icon || IconInformationCircleThick;

  // Merge default iconProps with the provided iconProps
  const mergedIconProps = {
    color: colors.primary,
    width: 16,
    height: 16,
    ...iconProps,
  };

  return (
    <>
      <div
        data-tooltip-id={tooltipId}
        className={cx({ [styles.ml8]: !icon }, styles.icon, className)}
      >
        <Icon {...mergedIconProps} />
      </div>
      <Tooltip id={tooltipId} place={place}>
        {tip}
      </Tooltip>
    </>
  );
};

IconTooltip.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.object]),
  tip: PropTypes.node,
  place: PropTypes.string,
  iconProps: PropTypes.object,
};

export default IconTooltip;
