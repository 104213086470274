function getResidenceFullAddress(residence) {
  if (!residence) {
    return '';
  }
  const { address_1, address_2, state, zip, city } = residence;
  if (address_1) {
    const unitString = !address_2 ? '' : ` Unit# ${address_2.trim()}`;
    return `${address_1}${unitString} ${city}, ${state} ${zip}`;
  }
  return '';
}

export default getResidenceFullAddress;
