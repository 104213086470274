import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';

import styles from './SharedUtilitiesIA.module.scss';

const SharedUtilitiesIA = ({ className, standaloneWizard }) => {
  const {
    input: { value: sharedUtilities },
  } = useField('custom_state_data.shared_utilities');

  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standaloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <label className={styles.label}>
        Are there any shared utilities for the property?
      </label>
      <p className={styles.description}>
        Iowa law requires that you disclose all shared utilities along with how
        the charges are billed, any services charged assessed and the formula
        used to split the charges.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.shared_utilities"
        name="custom_state_data.shared_utilities"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'custom_state_data.shared_utilities_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'custom_state_data.shared_utilities_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      {sharedUtilities && (
        <>
          <p className={styles.text}>
            If any utility costs are shared by tenants for the property, please
            list all the shared utilities, how the charges are billed and the
            formula used to calculate tenant's share of the expense, including
            any service charges. This includes disclosure of any shared common
            area utilities. Please note that these costs are separate from the
            rent amount:
          </p>
          <Field
            as="textarea"
            component={Input}
            id="custom_state_data.utilities_devided_as_follows"
            name="custom_state_data.utilities_devided_as_follows"
            label="Please list all shared utilities and how they are divided."
            className={styles.termsContainer}
            inputProps={{
              rows: '5',
              className: styles.termsField,
            }}
            maxLength={2000}
            validate={fieldRequired}
            help={(val = '') => `${val.length} / 2000 characters used`}
          />
        </>
      )}
    </div>
  );
};

SharedUtilitiesIA.propTypes = {
  className: PropTypes.string,
  standaloneWizard: PropTypes.bool,
};

export default SharedUtilitiesIA;
