import React, { memo } from 'react';

import colors from '../constants/colors';

const Percentage = (props) => (
  <svg viewBox="0 0 13 12" {...props}>
    <path d="M2.597 7.33c.873 0 1.534-.308 1.982-.923.448-.615.671-1.534.671-2.758 0-1.171-.229-2.072-.687-2.703C4.104.316 3.449 0 2.597 0 .866 0 0 1.216 0 3.649c0 1.181.227 2.09.68 2.726.453.636 1.092.954 1.917.954zm1.384 4.51L10.582.168H9.003L2.401 11.84h1.58zM2.597 6.02c-.358 0-.616-.194-.774-.583-.157-.388-.236-.98-.236-1.772 0-.793.079-1.384.236-1.773.158-.388.416-.583.774-.583.716 0 1.074.785 1.074 2.356 0 1.57-.358 2.355-1.074 2.355zM10.354 12c.863 0 1.52-.306 1.97-.918.45-.612.676-1.53.676-2.755 0-1.176-.232-2.078-.696-2.706-.464-.628-1.114-.942-1.95-.942-1.736 0-2.604 1.216-2.604 3.648 0 1.187.227 2.096.683 2.727.456.63 1.097.946 1.921.946zm0-1.301c-.358 0-.617-.197-.777-.591-.16-.394-.24-.988-.24-1.78 0-.794.08-1.382.24-1.765.16-.383.42-.575.777-.575.711 0 1.067.78 1.067 2.34 0 1.58-.356 2.37-1.067 2.37z" />
  </svg>
);

Percentage.defaultProps = {
  fill: colors.text,
  width: 13,
  height: 12,
};

export default memo(Percentage);
