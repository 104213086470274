import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const MoreNavigation = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 25" {...rest} fill={color}>
    <path d="M2.5 6.71a.75.75 0 0 1 .75-.75h17.5a.75.75 0 0 1 0 1.5H3.25a.75.75 0 0 1-.75-.75ZM2.5 12.71a.75.75 0 0 1 .75-.75h17.5a.75.75 0 0 1 0 1.5H3.25a.75.75 0 0 1-.75-.75ZM2.5 18.71a.75.75 0 0 1 .75-.75h17.5a.75.75 0 0 1 0 1.5H3.25a.75.75 0 0 1-.75-.75Z" />
  </svg>
);

MoreNavigation.defaultProps = {
  width: 24,
  height: 25,
  color: colors.primary,
};

MoreNavigation.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(MoreNavigation);
