import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const CompanyThick = (props) => (
  <svg {...props} viewBox="0 0 28 24" fill={props.color}>
    <path d="M24.366 0c.26 0 .5.132.645.347l.056.098 2.183 4.478a.856.856 0 01.034.08.81.81 0 01.049.316v1.083a4.177 4.177 0 01-2.183 3.693v13.1c0 .404-.29.739-.668.796l-.116.009H3.634a.791.791 0 01-.776-.686l-.008-.119v-13.1A4.171 4.171 0 01.675 6.63l-.006-.228V5.27a.745.745 0 01.037-.233l.02-.057v-.003l.012-.026.003-.007.003-.007.007-.014L2.933.445a.786.786 0 01.591-.437L3.634 0h20.732zm-3.16 9.81l-.15.168c-.863.907-2.03 1.53-3.243 1.674l-.26.024-.28.009a5.034 5.034 0 01-3.033-1.018l-.197-.156-.043-.038-.043.038a5.055 5.055 0 01-2.663 1.142l-.27.023-.297.009c-1.318 0-2.628-.59-3.604-1.527l-.18-.18-.15-.168-.192.143c-.443.307-.9.503-1.402.578l-.218.024-.256.01c-.103 0-.206-.004-.308-.011l.001 11.836h11.525v-9.27c0-.411.3-.75.686-.8l.099-.005h5.456c.4 0 .73.307.778.703l.006.101v9.271h.612l.002-11.836a4.012 4.012 0 01-.307.011c-.617 0-1.16-.166-1.68-.485l-.195-.127-.194-.143zm.192 4.113h-3.887v8.466h3.887v-8.466zm-6.852-1.608c.4 0 .73.307.778.703l.006.101v6.717c0 .41-.3.749-.686.799l-.098.006h-8.73c-.4 0-.73-.307-.778-.704l-.006-.1v-6.718c0-.41.3-.748.686-.798l.098-.006h8.73zm-.786 1.608H6.6v5.109h7.16v-5.109zm6.323 3.618c.346 0 .628.288.628.643a.635.635 0 01-.628.644.635.635 0 01-.627-.644c0-.355.28-.643.627-.643zM13.216 6.087H7.69l.001 2.31c.654.936 1.75 1.597 2.832 1.672l.203.007c.944 0 1.827-.38 2.49-1.04l-.001-2.949zm7.092 0h-5.524v2.949a3.52 3.52 0 002.268 1.033l.221.007c1.147 0 2.34-.685 3.036-1.68l-.001-2.309zm5.456 0h-3.887v2.209c.443.404.833.614 1.25.653l.148.007c1.32 0 2.4-1.054 2.484-2.386l.005-.168v-.315zm-19.641 0H2.236v.315c0 1.061.63 1.97 1.528 2.356a.734.734 0 01.255.092c.111.035.225.061.343.079l.178.02.185.007c.474 0 .904-.209 1.397-.66V6.087zm.872-4.479H4.118L2.72 4.479l3.576-.001.699-2.869zm6.22 0H8.612l-.7 2.869h5.305V1.608zm6.173 0h-4.604v2.869h5.303l-.7-2.869zm4.492 0h-2.875l.699 2.869h3.574L23.88 1.61z" />
  </svg>
);

CompanyThick.defaultProps = {
  width: 28,
  height: 24,
  color: colors.text,
};

CompanyThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(CompanyThick);
