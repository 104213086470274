import React, { memo } from 'react';
import PropTypes from 'prop-types';

const PhoneFill = (props) => (
  <svg {...props} viewBox="0 0 50 50">
    <g fill="none" fillRule="evenodd">
      <path
        d="m10.89 11.699 4.288 4.285a3.036 3.036 0 0 1 0 4.287L13.75 21.7a1.01 1.01 0 0 0-.068 1.355A96.81 96.81 0 0 0 26.947 36.32c.402.329.988.3 1.355-.067l1.428-1.429a3.03 3.03 0 0 1 4.285 0l4.287 4.286a3.03 3.03 0 0 1 0 4.284l-2.353 2.352a5.046 5.046 0 0 1-6.352.638c-10.214-6.756-19.224-15.767-25.978-25.98a5.041 5.041 0 0 1 .636-6.354L6.606 11.7a3.033 3.033 0 0 1 4.285 0Z"
        fill={props.color}
      />
      <path
        d="M5.178 10.27a5.053 5.053 0 0 1 7.141 0l4.288 4.286a5.057 5.057 0 0 1 0 7.144l-.778.775.382.457a95.392 95.392 0 0 0 10.857 10.857l.456.382.778-.775a5.05 5.05 0 0 1 6.877-.247l.264.246 4.287 4.286a5.05 5.05 0 0 1 0 7.143l-2.352 2.35a7.066 7.066 0 0 1-8.895.895C18.042 41.163 8.837 31.958 1.934 21.519a7.062 7.062 0 0 1 .892-8.897Zm5.713 1.429a3.033 3.033 0 0 0-4.285 0L4.255 14.05a5.041 5.041 0 0 0-.636 6.354c6.754 10.213 15.764 19.224 25.978 25.98a5.046 5.046 0 0 0 6.352-.638l2.353-2.352a3.03 3.03 0 0 0 0-4.284l-4.287-4.286a3.03 3.03 0 0 0-4.285 0l-1.428 1.429a1.01 1.01 0 0 1-1.355.067 96.81 96.81 0 0 1-13.265-13.266 1.01 1.01 0 0 1 .068-1.355l1.427-1.428a3.036 3.036 0 0 0 .001-4.287ZM24.999.758c13.39 0 24.243 10.853 24.243 24.245a1.01 1.01 0 1 1-2.02 0c0-12.276-9.948-22.225-22.223-22.225a1.01 1.01 0 0 1 0-2.02Zm0 8.081c8.927 0 16.162 7.236 16.162 16.164a1.01 1.01 0 1 1-2.02 0C39.141 17.19 32.81 10.86 25 10.86a1.01 1.01 0 0 1 0-2.02Zm0 8.082a8.08 8.08 0 0 1 8.081 8.082 1.01 1.01 0 1 1-2.02 0A6.06 6.06 0 0 0 25 18.94a1.01 1.01 0 0 1 0-2.02Z"
        fill="#042238"
      />
    </g>
  </svg>
);

PhoneFill.defaultProps = {
  width: 50,
  height: 50,
  color: '#7FE3FF',
};

PhoneFill.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(PhoneFill);
