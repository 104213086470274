import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import { useConfig } from '../../../../../../../providers/ConfigProvider';

import styles from './AdditionsToLeaseAgreement.module.scss';

const AdditionsToLeaseAgreement = ({ className }) => {
  const { ASSET_BUCKET_URL } = useConfig();

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <HelpfulInfoCard
          noIcon
          noTitle
          className={styles.helpfulInfoCard}
          color="iceCold"
        >
          Cook County requires that a copy of the{' '}
          <a
            href={`${ASSET_BUCKET_URL}uploads/assets/summary_of_residential_tenant_landlord_ordinance_en_may_2021.pdf`}
            target="_blank noopener noreferrer"
          >
            Residential Landlord Tenant Ordinance summary
          </a>{' '}
          and a copy of the{' '}
          <a
            href={`${ASSET_BUCKET_URL}uploads/assets/bed-bug-prevention-detection-control.pdf`}
            target="_blank noopener noreferrer"
          >
            Bed Bug pamphlet
          </a>{' '}
          prepared by the US Environmental Protection Agency must be attached to
          the lease agreement. We’ve already attached them for you.
        </HelpfulInfoCard>
      </div>
    </div>
  );
};

AdditionsToLeaseAgreement.propTypes = {
  className: PropTypes.string,
};

export default AdditionsToLeaseAgreement;
