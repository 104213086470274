import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../Button';

import styles from './Elements.module.scss';

export const IncomeInsightsIcon = ({
  children,
  okIcon,
  noDataIcon,
  mismatchIcon,
}) => {
  let className = '';
  if (okIcon) className = styles.okIconDiv;
  else if (noDataIcon) className = styles.noDataIconDiv;
  else if (mismatchIcon) className = styles.errorIconDiv;

  return (
    <>
      {children && (
        <div className={cx(styles.iconDiv, className, styles.hideMobile)}>
          {children}
        </div>
      )}
    </>
  );
};

IncomeInsightsIcon.propTypes = {
  children: PropTypes.node,
  okIcon: PropTypes.bool,
  noDataIcon: PropTypes.bool,
  mismatchIcon: PropTypes.bool,
};

export const IncomeInsightsContent = ({ children }) => (
  <div className={styles.content}>{children}</div>
);

IncomeInsightsContent.propTypes = {
  children: PropTypes.any,
};

export const IncomeInsightsTitle = ({
  children,
  biggerTitle,
  greenTitle,
  redTitle,
}) => {
  let className = '';
  if (greenTitle) className = styles.green;
  else if (redTitle) className = styles.red;

  return (
    <div
      className={cx(styles.title, biggerTitle && styles.biggerTitle, className)}
    >
      <span>{children}</span>
    </div>
  );
};

IncomeInsightsTitle.propTypes = {
  children: PropTypes.any,
  biggerTitle: PropTypes.bool,
  greenTitle: PropTypes.bool,
  redTitle: PropTypes.bool,
};

export const IncomeInsightsDescription = ({ children }) => (
  <div className={styles.description}>{children}</div>
);

IncomeInsightsDescription.propTypes = {
  children: PropTypes.any,
};

export const IncomeInsightsButton = ({
  style,
  onClick,
  label,
  className,
  ...otherProps
}) => (
  <>
    <Button
      className={cx(styles.action, className)}
      onClick={onClick}
      {...otherProps}
    >
      {label}
    </Button>
  </>
);

IncomeInsightsButton.propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
};
