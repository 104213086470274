import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import getResidenceFullAddress from '../../../../services/utilities/getResidenceFullAddress';

import styles from './AddressHeader.module.scss';

const AddressHeader = ({ address }) => {
  return (
    <div>
      {address.id ? (
        <div className={styles.container}>
          <div className={styles.subTitle}>
            {address.current ? 'Current' : 'Past'} Address:
          </div>
          <span className={styles.blockTitle}>
            {getResidenceFullAddress(address)}
          </span>
        </div>
      ) : (
        <div className={styles.subTitle}>New Past Address:</div>
      )}
    </div>
  );
};

AddressHeader.propTypes = {
  address: PropTypes.object,
};

export default compose(withRouter)(AddressHeader);
