import React from 'react';

/* eslint-disable no-dupe-keys */
const styles = {
  base: {
    textAlign: 'center',
    padding: '115px 0px 0px 0px',
  },
  p: {
    margin: '18px 0px 45px 0px',
    fontFamily: 'Century Gothic',
    fontSize: '2em',
    color: 'gray',
    textAlign: 'center',
  },
  span: { color: '#347BBC' },
  a: {
    color: '#fff',
    fontFamily: 'Century Gothic',
    fontSize: '1em',
    textDecoration: 'none',
    background: '#183956',
    background: '-moz-linear-gradient(top,  #183956 0%, #183956 100%)',
    background:
      '-webkit-gradient(linear, left top, left bottom, color-stop(0%,#183956), color-stop(100%,#183956))',
    background: '-webkit-linear-gradient(top,  #183956 0%,#183956 100%)',
    background: '-o-linear-gradient(top,  #183956 0%,#183956 100%)',
    background: '-ms-linear-gradient(top,  #183956 0%,#183956 100%)',
    background: 'linear-gradient(to bottom,  #183956 0%,#183956 100%)',
    filter:
      "progid:DXImageTransform.Microsoft.gradient( startColorstr='#183956', endColorstr='#183956',GradientType=0 )",
    padding: '15px 20px',
    borderRadius: '1em',
  },
};

const MaintenanceComponent = () => (
  <div style={styles.base}>
    <p style={styles.p}>
      <span>TurboTenant is currently undergoing maintenance.</span>
    </p>
    {/*<Link to="/" style={styles.a}> Back To Home</Link>*/}
  </div>
);

export default MaintenanceComponent;
