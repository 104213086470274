import React from 'react';
import PropTypes from 'prop-types';

import SectionInfoBlock from '../../../../../common/SectionInfoBlock';

const LateFeeInfo = ({ className }) => (
  <SectionInfoBlock className={className}>
    <>
      <strong>Late Fees:</strong> Rent is due in full on the first day of each
      and every month. Late fees are not allowed in Vermont. If rent is not
      received on or before the 2nd day of the month, you may charge your Tenant
      a reasonable percentage of your actual expenses incurred. Example: You
      missed your mortgage payment and you were charged an additional fee. You
      may pass this onto the Tenant.
    </>
  </SectionInfoBlock>
);

LateFeeInfo.propTypes = {
  className: PropTypes.string,
};

export default LateFeeInfo;
