import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import Input from '../../../../../../../components/Input';
import ResourcesIcon from '../../../../../../../icons/resources';
import composeValidators from '../../../../../../../validators/composeValidators';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredShortStandard from '../../../../../../../validators/fieldRequiredShort';
import isNumber from '../../../../../../../validators/isNumber';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './BankInfo.module.scss';

const BankInfo = () => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequiredShort = conditionalValidation(
    fieldRequiredShortStandard,
    !standAloneWizard,
  );

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <HelpfulInfoCard
          Icon={ResourcesIcon}
          className={styles.infoCard}
          title="Maine Security Deposit Laws"
          color="iceCold"
        >
          Maine requires landlords to list the name, location and account number
          of the bank where a security deposit is held.
          <HelpfulInfoList className={styles.helpfulInfoList}>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Security deposits may not exceed 2 month’s rent.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Security deposits need to be held in a separate bank account.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The name, location and account number of the bank where security
              deposits are held must be disclosed in the lease.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Security deposits need to be returned to Tenant within 30 days
              after termination or expiration of the Lease for yearly leases.
              For month-to-month leases, security deposits need to be returned
              within 21 days.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              At that time, you will need to provide Tenant with a written
              accounting of any lawful charges or deductions.
            </HelpfulInfoListItem>
            <div className={styles.learnMore}>
              <a
                href="https://support.turbotenant.com/en/articles/6207332-lease-agreements-in-maine"
                target="_blank noopener noreferrer"
              >
                Learn other security deposit laws
              </a>
            </div>
          </HelpfulInfoList>
        </HelpfulInfoCard>
        <div className={styles.row}>
          <Field
            label="FDIC Insured Institution Name:"
            className={styles.inputContainer}
            inputProps={{
              className: cx(styles.input),
            }}
            id="deposit_bank_name"
            name="deposit_bank_name"
            component={Input}
            validate={fieldRequiredShort}
          />
          <Field
            label="FDIC Insured Institution Address:"
            className={styles.inputContainer}
            inputProps={{
              className: cx(styles.input),
            }}
            id="deposit_bank_address"
            name="deposit_bank_address"
            component={Input}
            validate={fieldRequiredShort}
          />
          <Field
            label="Account Number:"
            className={styles.inputContainer}
            inputProps={{
              className: cx(styles.input),
            }}
            id="custom_state_data.bank_account_number"
            name="custom_state_data.bank_account_number"
            component={Input}
            validate={composeValidators(fieldRequiredShort, isNumber)}
            type="number"
          />
        </div>
      </div>
    </div>
  );
};

BankInfo.propTypes = {
  className: PropTypes.string,
};

export default BankInfo;
