import React, { memo } from 'react';
import PropTypes from 'prop-types';

const DocumentIcon = (props) => (
  <svg {...props} viewBox="0 0 34 30">
    <path d="M28.806 0c.383 0 .694.305.694.682v17.045h3.472c.384 0 .695.306.695.682v10.91c0 .376-.311.681-.695.681H1.028a.688.688 0 01-.695-.682V18.41c0-.376.311-.682.695-.682h3.471L4.5 3.409c0-.342.257-.626.592-.674l.102-.008h7.638l.001-2.045c0-.343.257-.626.592-.675L13.528 0zm-18.75 19.09H1.722v9.546h30.556v-9.545h-8.334v2.045c0 .377-.31.682-.694.682h-12.5a.688.688 0 01-.694-.682v-2.045zm2.776-15H5.89v13.637h4.861c.384 0 .694.306.694.682v2.046l1.388-.001V4.09zm15.28-2.726h-13.89v19.09h8.334V18.41c0-.376.31-.682.694-.682h4.86l.001-16.363zM26.027 15c.383 0 .694.305.694.682 0 .376-.31.682-.694.682h-9.722a.688.688 0 01-.695-.682c0-.377.311-.682.695-.682zm0-2.727c.383 0 .694.305.694.682 0 .376-.31.681-.694.681h-9.722a.688.688 0 01-.695-.681c0-.377.311-.682.695-.682zm0-2.728c.383 0 .694.306.694.682 0 .377-.31.682-.694.682h-9.722a.688.688 0 01-.695-.682c0-.376.311-.682.695-.682zm0-2.727c.383 0 .694.305.694.682 0 .377-.31.682-.694.682h-9.722a.688.688 0 01-.695-.682c0-.377.311-.682.695-.682zm0-2.727c.383 0 .694.305.694.682 0 .376-.31.682-.694.682h-6.945a.688.688 0 01-.694-.682c0-.377.31-.682.694-.682z" />
  </svg>
);

DocumentIcon.defaultProps = {
  width: 34,
  height: 30,
};

DocumentIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(DocumentIcon);
