import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './FieldError.module.scss';

const FieldError = ({ className, id, error, focusOnErrorMessage }) => {
  const ref = useRef();

  useEffect(() => {
    if (focusOnErrorMessage && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [focusOnErrorMessage]);

  if (!error) {
    return null;
  }

  return (
    <div
      id={`${id}_errormsg`}
      className={cx(styles.error, className)}
      ref={ref}
    >
      {error}
    </div>
  );
};

FieldError.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.string,
  focusOnErrorMessage: PropTypes.bool,
};

export default FieldError;
