import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';

import styles from './SharedUtilitiesVA.module.scss';

const SharedUtilitiesVA = ({ className, standaloneWizard }) => {
  const {
    input: { value: sharedUtilities },
  } = useField('custom_state_data.shared_utilities');

  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standaloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <label className={styles.label}>
        Are there any shared utilities between you and the tenant?
      </label>
      <Field
        component={RadioGroup}
        id="custom_state_data.shared_utilities"
        name="custom_state_data.shared_utilities"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'custom_state_data.shared_utilities_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'custom_state_data.shared_utilities_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      {sharedUtilities && (
        <>
          <p className={styles.text}>
            If any utility costs are shared by tenants for the property, the
            specific formula used to calculate tenant's share of the expense
            must be included. For example, if the water is metered together you
            must describe how utilities and maintenance cost is broken down for
            your share and the tenant's share. This includes disclosure of any
            shared common area utilities. Please note that these costs are
            separate from the rent amount. Please list all shared utilities and
            how they are divided:
          </p>
          <Field
            as="textarea"
            component={Input}
            id="custom_state_data.utilities_devided_as_follows"
            name="custom_state_data.utilities_devided_as_follows"
            label="Please list all shared utilities and how they are divided."
            className={styles.termsContainer}
            inputProps={{
              rows: '5',
              className: styles.termsField,
            }}
            maxLength={2000}
            validate={fieldRequired}
            help={(val = '') => `${val.length} / 2000 characters used`}
          />
        </>
      )}
    </div>
  );
};

SharedUtilitiesVA.propTypes = {
  className: PropTypes.string,
  standaloneWizard: PropTypes.bool,
};

export default SharedUtilitiesVA;
