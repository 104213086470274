import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Syndication = ({ color, ...props }) => (
  <svg viewBox="0 0 28 28" {...props}>
    <path
      d="M10.229.667c5.28 0 9.562 4.281 9.562 9.562a9.523 9.523 0 0 1-2.167 6.062l9.433 9.433a.943.943 0 1 1-1.333 1.333l-9.434-9.432a9.523 9.523 0 0 1-6.061 2.166c-5.28 0-9.562-4.281-9.562-9.562 0-5.28 4.281-9.562 9.562-9.562Zm0 1.885a7.677 7.677 0 1 0 0 15.354 7.677 7.677 0 0 0 0-15.354Zm.672 1.53 5.332 4.915a.943.943 0 1 1-1.279 1.386l-.55-.507v4.663c0 .52-.422.942-.943.942H6.997a.943.943 0 0 1-.943-.942v-4.64l-.532.487A.943.943 0 0 1 4.25 8.994L9.626 4.08a.943.943 0 0 1 1.275.003Zm-.641 1.973L7.27 8.788c.387.117.67.477.67.902v3.906h4.579V9.69c0-.435.295-.802.697-.91L10.26 6.055Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

Syndication.defaultProps = {
  width: 28,
  height: 28,
  color: 'currentColor',
};

Syndication.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Syndication);
