import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Reporting = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 25" {...rest} fill={color}>
    <path d="M1.9 19.472a.6.6 0 0 1 .6-.6h19a.6.6 0 1 1 0 1.2h-19a.6.6 0 0 1-.6-.6Z" />
    <path d="M2.726 15.08a.6.6 0 0 1 .6-.6h2.478a.6.6 0 0 1 .6.6v4.392a.6.6 0 0 1-.6.6H3.326a.6.6 0 0 1-.6-.6V15.08Zm1.2.6v3.192h1.278V15.68H3.926ZM7.683 9.08a.6.6 0 0 1 .6-.6h2.478a.6.6 0 0 1 .6.6v10.392a.6.6 0 0 1-.6.6H8.283a.6.6 0 0 1-.6-.6V9.08Zm1.2.6v9.192h1.278V9.68H8.883ZM12.639 12.08a.6.6 0 0 1 .6-.6h2.478a.6.6 0 0 1 .6.6v7.392a.6.6 0 0 1-.6.6H13.24a.6.6 0 0 1-.6-.6V12.08Zm1.2.6v6.192h1.278V12.68H13.84ZM17.596 5.08a.6.6 0 0 1 .6-.6h2.478a.6.6 0 0 1 .6.6v14.392a.6.6 0 0 1-.6.6h-2.478a.6.6 0 0 1-.6-.6V5.08Zm1.2.6v13.192h1.278V5.68h-1.278Z" />
  </svg>
);

Reporting.defaultProps = {
  width: 24,
  height: 25,
  color: colors.text,
};

Reporting.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Reporting);
