import React, { memo } from 'react';

const Keyboard = (props) => (
  <svg viewBox="0 0 54 54" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#7FE3FF" d="M1.666 29.666h48v22.667h-8v-4H11v4H1.666z" />
      <path
        d="M27 .333c.614 0 1.111.498 1.111 1.111 0 1.414 1.099 3.107 2.037 3.313l.185.02h14.445c5.128 0 8.889 3.019 8.889 7.779 0 4.604-3.523 7.581-8.397 7.768l-.492.01h-30c-2.733 0-5.354 3.099-5.545 6.297l-.01.37-.002 2.22h41.112c.573 0 1.045.434 1.105.991l.006.121v22.223c0 .613-.497 1.11-1.11 1.11H1.443a1.111 1.111 0 0 1-1.11-1.11V30.333c0-.614.497-1.111 1.11-1.111H7L7 27c0-4.318 3.37-8.603 7.375-8.875l.403-.014h30c3.994 0 6.666-2.145 6.666-5.555 0-3.286-2.477-5.395-6.228-5.547L44.778 7H30.333c-2.417 0-4.444-2.96-4.444-5.556 0-.613.497-1.11 1.111-1.11ZM8.092 31.443H2.556v20.001H9.22l.001-3.333c0-.573.434-1.044.99-1.105l.121-.006h31.111c.614 0 1.112.497 1.112 1.111v3.332l6.666.001v-20H8.092Zm32.241 17.779H11.444v2.222h28.89v-2.222Zm5.556-6.666a1.111 1.111 0 0 1 .2 2.204l-.2.018h-40a1.111 1.111 0 0 1-.2-2.204l.2-.018h40Zm0-4.444a1.111 1.111 0 0 1 .2 2.204l-.2.018h-40a1.111 1.111 0 0 1-.2-2.205l.2-.017h40Zm0-4.445a1.111 1.111 0 0 1 .2 2.204l-.2.018h-40a1.111 1.111 0 0 1-.2-2.204l.2-.018h40Z"
        fill="#042238"
      />
    </g>
  </svg>
);

Keyboard.defaultProps = {
  width: 54,
  height: 54,
};

export default memo(Keyboard);
