import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Manufactured = ({ color, ...props }) => (
  <svg {...props} viewBox="0 0 24 20">
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons/Property/Type/Vacant-Land/Dark"
        transform="translate(0.000000, -2.000000)"
        fillRule="nonzero"
        fill={color}
      >
        <g id="Vacant-Land" transform="translate(0.000000, 1.500000)">
          <path
            d="M10.9398157,8.91915799 C11.1448611,9.22603476 10.9216391,9.63461538 10.5489362,9.63461538 L9.51255004,9.63461538 L11.9079697,12.8213291 C12.1369225,13.1259134 11.9164165,13.5576923 11.5319149,13.5576923 L1.70212766,13.5576923 C1.31762607,13.5576923 1.09712005,13.1259134 1.32607289,12.8213291 L3.72149251,9.63461538 L2.68510638,9.63461538 C2.31240341,9.63461538 2.08918146,9.22603476 2.29422687,8.91915799 L4.43745734,5.71153846 L3.99574468,5.71153846 C3.62304171,5.71153846 3.39981976,5.30295784 3.60486516,4.99608106 L6.22614176,1.07300414 C6.41110821,0.796178107 6.82293434,0.796178107 7.00790079,1.07300414 L9.62917739,4.99608106 C9.83422279,5.30295784 9.61100084,5.71153846 9.23829787,5.71153846 L8.79658522,5.71153846 L10.9398157,8.91915799 Z M7.53678006,5.50391894 C7.33173465,5.19704216 7.55495661,4.78846154 7.92765957,4.78846154 L8.36937223,4.78846154 L6.61702128,2.16584339 L4.86467032,4.78846154 L5.30638298,4.78846154 C5.67908595,4.78846154 5.9023079,5.19704216 5.69726249,5.50391894 L3.55403203,8.71153846 L4.65106383,8.71153846 C5.03556542,8.71153846 5.25607144,9.14331736 5.0271186,9.44790164 L2.63169898,12.6346154 L10.6023436,12.6346154 L8.20692396,9.44790164 C7.97797111,9.14331736 8.19847713,8.71153846 8.58297872,8.71153846 L9.68001053,8.71153846 L7.53678006,5.50391894 Z"
            id="Stroke-1413"
          ></path>
          <path
            d="M6.14893617,13.0961538 L6.14893617,16.3653846 C6.14893617,16.6202853 6.35850501,16.8269231 6.61702128,16.8269231 C6.87553754,16.8269231 7.08510638,16.6202853 7.08510638,16.3653846 L7.08510638,13.0961538 C7.08510638,12.8412532 6.87553754,12.6346154 6.61702128,12.6346154 C6.35850501,12.6346154 6.14893617,12.8412532 6.14893617,13.0961538 Z"
            id="Stroke-1414"
          ></path>
          <path
            d="M8.41244672,15.62 L20.1457073,15.62 L22.3480818,19.1369231 L1.79604465,19.1369231 L3.99357175,15.6569231 L5.11446809,15.6569231 C5.37298435,15.6569231 5.58255319,15.4502853 5.58255319,15.1953846 C5.58255319,14.940484 5.37298435,14.7338462 5.11446809,14.7338462 L3.99357175,14.7338462 C3.6702243,14.7338462 3.36976778,14.8983733 3.19885975,15.169023 L1.00133265,18.649023 C0.908879053,18.7954323 0.859874441,18.9644529 0.859874441,19.1369231 C0.859874441,19.6467244 1.27901212,20.06 1.79604465,20.06 L22.3480818,20.06 C22.5218084,20.06 22.6921084,20.0123354 22.8399268,19.9223395 C23.2798531,19.6545001 23.4162773,19.0857305 23.1446389,18.651957 L20.9422644,15.1350339 C20.7717296,14.8627108 20.4703005,14.6969231 20.1457073,14.6969231 L8.41244672,14.6969231 C8.15393046,14.6969231 7.94436162,14.9035609 7.94436162,15.1584615 C7.94436162,15.4133622 8.15393046,15.62 8.41244672,15.62 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

Manufactured.defaultProps = {
  width: 34,
  height: 44,
  color: colors.text,
};

Manufactured.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Manufactured);
