import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const BillingCheck = (props) => (
  <svg {...props} viewBox="0 0 400 188">
    <g fill="none">
      <rect
        stroke="#ACB9C8"
        strokeWidth="1.538"
        fill="#F5F6F8"
        x=".769"
        y=".769"
        width="398.462"
        height="150.769"
        rx="1.538"
      />
      <path stroke="#ACB9C8" strokeWidth="1.538" d="M53.077 61.538h248.461" />
      <text
        fontFamily="OpenSans-Bold, Open Sans"
        fontSize="7.692"
        fontWeight="bold"
        letterSpacing="-.214"
        fill="#516A80"
        transform="translate(10 47.692)"
      >
        <tspan x="0" y="8">
          PAY TO THE
        </tspan>
        <tspan x="0" y="15.692">
          ORDER OF
        </tspan>
      </text>
      <g transform="translate(307.692 44.615)">
        <rect
          stroke="#ACB9C8"
          strokeWidth="1.538"
          x="11.538"
          y="1.538"
          width="70.769"
          height="15.385"
          rx=".769"
        />
        <text
          fontFamily="OpenSans-Semibold, Open Sans"
          fontSize="13.846"
          fontWeight="500"
          fill="#516A80"
        >
          <tspan x="0" y="15">
            $
          </tspan>
        </text>
      </g>
      <text
        fontFamily="OpenSans-Bold, Open Sans"
        fontSize="7.692"
        fontWeight="bold"
        letterSpacing="-.214"
        fill="#516A80"
        transform="translate(10.77 77.692)"
      >
        <tspan x="301.538" y="8">
          DOLLARS
        </tspan>
      </text>
      <path stroke="#ACB9C8" strokeWidth="1.538" d="M10.77 84.615h295.384" />
      <text
        fontFamily="OpenSans-Bold, Open Sans"
        fontSize="7.692"
        fontWeight="bold"
        letterSpacing="-.214"
        fill="#516A80"
        transform="translate(244.615 23.846)"
      >
        <tspan x="0" y="8">
          DATE
        </tspan>
      </text>
      <path stroke="#ACB9C8" strokeWidth="1.538" d="M266.153 31.538h56.924" />
      <text
        fontFamily="OpenSans-Bold, Open Sans"
        fontSize="7.692"
        fontWeight="bold"
        letterSpacing="-.214"
        fill="#516A80"
        transform="translate(9.23 106.923)"
      >
        <tspan x="0" y="8">
          FOR
        </tspan>
      </text>
      <path
        stroke="#ACB9C8"
        strokeWidth="1.538"
        d="M29.23 113.846h85.386M206.154 113.846h180.769"
      />
      <g stroke="#ACB9C8" strokeWidth="2.308">
        <path d="M115.385 133.076V140M120 131.538v3.077M120 138.461v3.077" />
      </g>
      <g stroke="#ACB9C8" strokeWidth="2.308">
        <path d="M14.616 133.076V140M19.23 131.538v3.077M19.23 138.461v3.077" />
      </g>
      <text
        fontFamily="OpenSans-Semibold, Open Sans"
        fontSize="11.538"
        fontWeight="500"
        letterSpacing=".154"
        fill="#042238"
        transform="translate(14.23 127.692)"
      >
        <tspan x="10" y="12">
          1 2 3 4 5 6 7 8 0
        </tspan>
      </text>
      <g>
        <text
          fontFamily="OpenSans-Semibold, Open Sans"
          fontSize="11.538"
          fontWeight="500"
          letterSpacing="1"
          fill="#042238"
          transform="translate(251.923 127.692)"
        >
          <tspan x="0" y="12">
            0001
          </tspan>
        </text>
        <g stroke="#ACB9C8">
          <path
            strokeWidth=".769"
            d="M287.693 133.077v5.385M290 133.077v5.385"
          />
          <path strokeWidth="2.308" d="M293.077 132.308v3.077" />
        </g>
      </g>
      <text
        fontFamily="OpenSans-Semibold, Open Sans"
        fontSize="11.538"
        fontWeight="500"
        letterSpacing="1"
        fill="#042238"
      >
        <tspan x="360" y="21.231">
          0001
        </tspan>
      </text>
      <g>
        <text
          fontFamily="OpenSans-Semibold, Open Sans"
          fontSize="11.538"
          fontWeight="500"
          letterSpacing=".154"
          fill="#042238"
          transform="translate(132.692 127.692)"
        >
          <tspan x="0" y="12">
            9 8 7 6 5 4 3 2 1 0
          </tspan>
        </text>
        <g stroke="#ACB9C8">
          <path
            strokeWidth=".769"
            d="M233.847 133.846v5.385M236.154 133.846v5.385"
          />
          <path strokeWidth="2.308" d="M239.23 133.077v3.077" />
        </g>
      </g>
      <g>
        <g stroke="#033A6D" strokeWidth="2.308">
          <path d="M24.615 158.847h86.154M68.077 159.23v12.309" />
        </g>
        <text
          fontFamily="OpenSans-Semibold, Open Sans"
          fontSize="11.538"
          fontWeight="500"
          letterSpacing=".154"
          fill="#042238"
          transform="translate(20 158.462)"
        >
          <tspan x="0" y="25.077">
            Routing Number
          </tspan>
        </text>
      </g>
      <g>
        <g stroke="#033A6D" strokeWidth="2.308">
          <path d="M133.462 158.847h93.846M181.154 159.23v12.309" />
        </g>
        <text
          fontFamily="OpenSans-Semibold, Open Sans"
          fontSize="11.538"
          fontWeight="500"
          letterSpacing=".154"
          fill="#042238"
          transform="translate(131.538 158.462)"
        >
          <tspan x="0" y="25.077">
            Account Number
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

BillingCheck.defaultProps = {
  width: 400,
  height: 188,
  color: colors.text,
};

BillingCheck.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(BillingCheck);
