import React from 'react';

import Checkbox from '../Checkbox';

import CoreField from './CoreField';

export const CheckboxField = (props) => (
  <CoreField component={Checkbox} {...props} type="checkbox" />
);

export default CheckboxField;
