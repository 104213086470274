import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Scale = (props) => (
  <svg viewBox="0 0 54 45" width={props.width} height={props.height}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M37.669 23.825h14.86a7.335 7.335 0 0 1-7.335 7.334h-.19a7.335 7.335 0 0 1-7.335-7.334ZM1.471 23.825h14.86a7.335 7.335 0 0 1-7.334 7.334h-.19a7.335 7.335 0 0 1-7.336-7.334Z"
        fill={props.secondaryColor}
      />
      <path
        d="M27.028 9.585v32.773h5.458c.628 0 1.138.517 1.138 1.155 0 .588-.434 1.073-.995 1.145l-.143.009h-6.597l-.018-.001H19.23a1.146 1.146 0 0 1-1.138-1.153c0-.589.434-1.074.996-1.146l.142-.009h5.521V9.585c-1.85-.493-3.231-2.16-3.323-4.171l-.003-.08H10.948l7.002 16.69c.103.153.17.331.19.524l.008.126c0 5.45-3.895 9.596-8.908 9.596S.333 28.124.333 22.674c0-.02 0-.04.002-.06l.003-.04a.832.832 0 0 1 .01-.08l.006-.04a.977.977 0 0 1 .024-.104l.008-.025.016-.047.024-.064 7.101-16.88H5.855A1.146 1.146 0 0 1 4.717 4.18c0-.588.434-1.074.995-1.145l.143-.009h16.113A4.456 4.456 0 0 1 25.89.666c1.67 0 3.125.929 3.893 2.304l18.307.002c.629 0 1.138.516 1.138 1.154 0 .588-.434 1.074-.995 1.145l-.143.009h-1.618l7.087 16.9c.053.114.088.238.102.368l.007.126c0 5.45-3.894 9.596-8.907 9.596-4.926 0-8.77-4.002-8.904-9.31l-.004-.262.001.115a1.162 1.162 0 0 1 .034-.462l.048-.137L43.047 5.28h-12.69l-.003.137c-.093 2.01-1.475 3.675-3.326 4.168ZM15.794 23.827H2.685l.055.356c.57 3.228 2.958 5.523 5.982 5.759l.247.015.271.005c3.287 0 5.929-2.397 6.511-5.839l.04-.254.003-.042Zm35.52 0h-13.11l.055.356c.57 3.228 2.958 5.523 5.983 5.759l.246.015.272.005c3.286 0 5.928-2.397 6.51-5.839l.04-.254.003-.042ZM9.236 7.152 3.192 21.52h12.07L9.237 7.152Zm35.522-.057L38.7 21.52h12.106L44.76 7.095Zm-18.87-4.12c-1.21 0-2.19.995-2.19 2.225 0 1.231.98 2.225 2.19 2.225 1.212 0 2.193-.994 2.193-2.225 0-.233-.035-.457-.1-.668a2.192 2.192 0 0 0-2.092-1.557Z"
        fill={props.color}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

Scale.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
};

Scale.defaultProps = {
  width: 54,
  height: 45,
  color: '#042238',
  secondaryColor: '#7FE3FF',
};

export default memo(Scale);
