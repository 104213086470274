import React, { useEffect, useState } from 'react';

import PDFDocumentViewerModal from '../../../../components/PDFDocumentViewerModal';
import { useErrorToast } from '../../../../components/Toast';
import { getCurrentToken } from '../../../../core/auth/authService';
import { TTfetch } from '../../../../core/TTfetch';
import { useConfig } from '../../../../providers/ConfigProvider';
import { segmentTracking } from '../../../../services/utilities';

const PreviewModal = ({
  wizzardId,
  onClose,
  isFilledIn,
  isPaid,
  renderPremiumModal,
  doCreateWizardLeaseDocument,
  createLeaseDocumentloading,
  completed,
}) => {
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const showErrorToast = useErrorToast();
  const { API_URL, S3_BUCKET, ENV_NAME } = useConfig();

  const previewSample = `https://${S3_BUCKET}.s3.amazonaws.com/empty_lease_addendum/Lease-Addendum${
    ENV_NAME.startsWith('dev') ? `-${ENV_NAME.toUpperCase()}.pdf` : '.pdf'
  }`;

  const previewUrl = wizzardId
    ? `${API_URL}/public/owners/wizard-document/${wizzardId}?token=${getCurrentToken()}`
    : previewSample;

  const getDocument = async () => {
    try {
      const res = await TTfetch(previewUrl);

      const blob = await res.blob();
      setUrl(URL.createObjectURL(blob));
    } catch (e) {
      setError(e.message || 'An error occurred');
      showErrorToast(
        'Something went wrong while processing the document, please refresh the page.',
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    if (open) {
      setLoading(true);
      getDocument();
    }
  }, []);

  useEffect(() => {
    if (error) {
      onClose();
    }
  }, [error]);

  const onAction = async () => {
    if (isPaid) {
      await doCreateWizardLeaseDocument();
      onClose();
    } else {
      onClose();
      segmentTracking('pay_and_esign clicked', {
        location: 'Lease Addendum Review Page',
      });
      renderPremiumModal();
    }
  };

  let actionLabel;
  if (isPaid) {
    actionLabel = 'Finalize and E-Sign';
  } else {
    actionLabel = 'Pay and E-Sign';
  }

  return (
    <PDFDocumentViewerModal
      open
      loading={loading}
      onClose={onClose}
      contentDesc={
        !completed ? (
          <>
            <strong>DISCLAIMER:</strong>This rental addendum is not warrantied,
            either expressly or implied, by TurboTenant, Inc. as to their
            effectiveness or completeness. TurboTenant, Inc. does not provide
            legal advice.
            <strong>
              TURBOTENANT, INC. AND ITS SERVICES, DOCUMENTS, RECORDS, AND
              PRODUCTS ARE NOT A SUBSTITUTE FOR THE ADVICE OF AN ATTORNEY
            </strong>{' '}
            The user is advised to check all applicable state and federal laws
            before using this agreement, attachments, disclosures, forms, or
            parts thereof and to have them reviewed by competent legal counsel
            prior to use.
          </>
        ) : (
          ''
        )
      }
      url={url}
      title={'Rental Addendum '}
      closeLabel={'Close Preview'}
      actionLoading={createLeaseDocumentloading}
      {...(isFilledIn ? { actionLabel, onAction } : {})}
    />
  );
};

export default PreviewModal;
