export const imgStyle = () => {
  return {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    minHeight: 'unset',
    minWidth: 'unset',
    fontFamily: 'Open Sans',
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: '-1px',
    lineHeight: '20px',
    textAlign: 'center',
  };
};
