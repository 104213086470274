import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import cx from 'classnames';
import { FORM_ERROR } from 'final-form';
import get from 'lodash.get';
import { bool, func, string } from 'prop-types';
import queryString from 'query-string';

import Button from '../../../components/Button';
import Card from '../../../components/Card';
import FinalFormError from '../../../components/FinalFormError';
import FlatButton from '../../../components/FlatButton';
import Input from '../../../components/Input';
import { useLoginRecaptcha } from '../../../helpers/login-recaptcha';
import BackArrow from '../../../icons/BackArrow';
import EmailSentIcon from '../../../icons/EmailSent';
import composeValidators from '../../../validators/composeValidators';
import fieldRequired from '../../../validators/fieldRequired';
import validEmail from '../../../validators/validEmail';

import styles from './ResetPassword.module.scss';

const ResetPassword = ({
  type,
  sendResetEmail,
  loading,
  isRenter = false,
  history,
  academy,
}) => {
  const { getResetPasswordCaptchaToken } = useLoginRecaptcha();

  const defaultSubmittedState = {
    submitted: {
      email: '',
      submitDate: '',
    },
  };

  let backButtonTo;

  const backToUrl = queryString.parse(history.location.search)?.backToUrl;

  if (backToUrl) {
    backButtonTo = backToUrl;
  } else if (academy) {
    backButtonTo = `/auth/academy/login${history?.location?.search || ''}`;
  } else {
    backButtonTo = `/login${history?.location?.search || ''}`;
  }

  const [submittedData, setSubmittedData] = useState(defaultSubmittedState);
  const [showSuccess, setShowSuccess] = useState(false);

  const processError = (error) => {
    const myError = get(error, 'graphQLErrors[0]', null);
    let message = 'There was an error sending the e-mail';
    if (myError && myError.code === 473) {
      // email in use
      message = "Sorry, we can't find a user with this email address.";
    }
    return {
      [FORM_ERROR]: message,
    };
  };

  const doReset = async (formData) => {
    const d = new Date();
    if (
      submittedData.email === formData.email &&
      submittedData.date &&
      d.getTime() - submittedData.date < 600
    ) {
      return;
    }
    setSubmittedData({ date: new Date(), email: formData.email });
    const captchaToken = await getResetPasswordCaptchaToken();
    try {
      const { data } = await sendResetEmail({
        variables: {
          accountType: isRenter ? 'RENTER' : 'OWNER',
          email: formData.email,
          isReset: type !== 'setPassRenter',
          redirectUrl: academy ? 'academy.turbotenant.com' : null,
          captchaToken,
        },
      });
      const res = data.resetPasswordRequest.ok;
      setShowSuccess(!!res);
      setSubmittedData(defaultSubmittedState);
    } catch (e) {
      console.log('doReset... caught error', e);
      return processError(e);
    }
  };

  const showSuccessContent = () => {
    const successTitle =
      type === 'setPassRenter' ? 'Set Password Link Sent!' : 'Reset Link Sent';

    const successDescription =
      type === 'setPassRenter'
        ? 'You will receive an email with the link to set your password shortly.'
        : 'You will receive an email with instructions on how to reset your password shortly';

    return (
      <div className={styles.successContainer}>
        <EmailSentIcon className={styles.successIcon} />
        <div className={styles.title}>{successTitle}</div>
        <div className={cx(styles.subTitle, styles.success)}>
          {successDescription}
        </div>
        <Button
          className={styles.successBackButton}
          to={backButtonTo}
          secondary
        >
          {backToUrl ? 'BACK' : 'BACK TO LOGIN'}
        </Button>
      </div>
    );
  };

  const title =
    type === 'setPassRenter' ? 'Set your password.' : 'Forgot your password?';
  const description =
    type === 'setPassRenter'
      ? 'We’ll send you an email with a link to set your password.'
      : 'Enter your email below, and we will send you instructions on how to reset it.';

  const buttonLabel =
    type === 'setPassRenter' ? 'Send Link' : 'SEND RESET LINK';
  const buttonId = type === 'setPassRenter' ? 'send_link' : 'send_reset_link';

  const getFormContent = () => {
    return (
      <Form
        onSubmit={doReset}
        subscription={{
          submitting: true,
        }}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.title}>{title}</div>
            <div className={styles.subTitle}>{description}</div>
            <FinalFormError />
            <div className={styles.fieldSet}>
              <Field
                label="Email"
                component={Input}
                type="email"
                name="email"
                id="email"
                validate={composeValidators(validEmail, fieldRequired)}
                inputProps={{
                  autoComplete: 'username',
                }}
              />
            </div>

            <Button
              className={styles.submitButton}
              type="submit"
              loading={submitting || loading}
              id={buttonId}
            >
              {buttonLabel}
            </Button>
          </form>
        )}
      </Form>
    );
  };

  const content = showSuccess ? showSuccessContent() : getFormContent();

  return (
    <>
      {!showSuccess && (
        <FlatButton
          iconProps={{
            className: cx(styles.backButtonIcon, styles.resetPassword),
          }}
          icon={BackArrow}
          to={backButtonTo}
          className={cx(styles.backButton, isRenter && styles.renter)}
        >
          {backToUrl ? 'BACK' : 'BACK TO LOGIN'}
        </FlatButton>
      )}
      {isRenter ? (
        <Card className={styles.renterCard}>{content}</Card>
      ) : (
        <div className={styles.ownerContainer}>{content}</div>
      )}
    </>
  );
};

ResetPassword.propTypes = {
  type: string,
  sendResetEmail: func,
  isRenter: bool,
};

export default ResetPassword;
