import React from 'react';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo/HelpfulInfoCard';
import resources from '../../../../../../../icons/resources';

const RecyclingLawOR = () => {
  return (
    <HelpfulInfoCard title="Oregon Recycling Law" Icon={resources}>
      For residential buildings with 5+ units that are in a city/county within
      an urban growth area, landlords must provide a separate location and bins
      for recycling. Recycling process and information must be provided to
      tenants yearly. For more information on urban growth area, you can go{' '}
      <a
        href="https://www.oregon.gov/lcd/up/pages/ugbs-and-urbanrural-reserves.aspx#:~:text=Each%20Oregon%20city%20is%20surrounded,such%20as%20parks%20and%20utilities."
        target="_blank"
        rel="noopener noreferrer"
      >
        here.
      </a>
    </HelpfulInfoCard>
  );
};

export default RecyclingLawOR;
