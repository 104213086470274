import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import convertToDigits from '../../helpers/convert-to-digits';
import useEntityQuery from '../../hooks/entities/useEntityQuery';
import BillingCheck from '../../icons/BillingCheck';
import { useConfig } from '../../providers/ConfigProvider';
import composeValidators from '../../validators/composeValidators';
import fieldRequired from '../../validators/fieldRequired';
import fieldRequiredShort from '../../validators/fieldRequiredShort';
import hasLength from '../../validators/hasLength';
import mustMatchTo from '../../validators/mustMatchTo';
import AccountNumberInput from '../AccountNumberInput';
import Button from '../Button';
import Card from '../Card';
import FlatButton from '../FlatButton';
import Input from '../Input';
import Modal from '../Modal';

import AddBankManuallyFooter from './AddBankManuallyFooter';
import BankAccountWarningNotice from './BankAccountWarningNotice';

import styles from './AddBankManuallyForm.module.scss';

const AddBankManuallyForm = ({
  onSubmit,
  onCancelClick,
  className,
  isPremiumUser,
  user,
  showCancelButton = true,
}) => {
  const location = useLocation();
  const entityId = location?.state?.entityId;
  const { entity } = useEntityQuery(entityId, {
    skip: !entityId,
  });
  const businessType =
    entityId != null
      ? entity?.business_type
      : user?.payments_data?.business_type;
  const [accountType, setAccountType] = useState(businessType);
  const [showModal, setShowModal] = useState(false);
  const config = useConfig();

  useEffect(() => {
    setAccountType(businessType);
  }, [businessType]);
  const preventAction = (e) => e.preventDefault();

  let introString = '';
  if (config.IS_RENTER) {
    introString = (
      <p className={styles.textTitle}>
        A manually added bank account takes 1-2 business days to verify.{' '}
        <b>If you need to make a payment sooner</b>,{' '}
        <a href="/renters/payments/add-bank">go back to link your account</a>{' '}
        using your bank’s online login.
      </p>
    );
  } else {
    introString = (
      <BankAccountWarningNotice
        className={styles.notice}
        name={
          !entityId ? user?.payments_data?.entity_name : entity?.entity_name
        }
      />
    );
  }

  const [show, showPassword] = useState(false);
  const toggleButtonLabel = show ? 'Hide' : 'Show';

  return (
    <>
      <Modal
        style={{ maxWidth: '464px', padding: '32px' }}
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <div>
          <h2 className={styles.title}>
            How to Find Your Routing and Account Numbers
          </h2>
          <p className={styles.textTitle}>
            Check your bank’s online login, a past statement, or on a check, as
            shown below.
          </p>
          <BillingCheck className={styles.svg} />
          <Button onClick={() => setShowModal(false)} className={styles.btn}>
            Done
          </Button>
        </div>
      </Modal>
      <Card className={cx(styles.card, className)}>
        <h1 className={styles.title} id="bank-info-title">
          Enter Bank Information
        </h1>
        {introString}
        <Form
          onSubmit={onSubmit}
          initialValues={{
            account_holder_type: user?.payments_data?.business_type,
            account_holder_name: !entityId
              ? user?.payments_data?.entity_name
              : entity?.entity_name,
          }}
          subscription={{
            submitting: true,
          }}
        >
          {({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className={styles.formHolder}>
                  <div className={styles.row}>
                    <Field
                      component={Input}
                      id="account_holder_name"
                      name="account_holder_name"
                      className={styles.holderName}
                      label={
                        accountType === 'company'
                          ? 'Business Name'
                          : "Account Holder's Full Name"
                      }
                      validate={fieldRequired}
                    />
                  </div>
                  <div className={cx(styles.row, styles.routingNumberRow)}>
                    <Field
                      component={Input}
                      id="routing_number"
                      name="routing_number"
                      className={styles.routingNumber}
                      label="Routing Number"
                      maxLength={9}
                      format={convertToDigits}
                      inputProps={{
                        onCopy: preventAction,
                        onPaste: preventAction,
                      }}
                      validate={composeValidators(
                        fieldRequiredShort,
                        hasLength(9),
                      )}
                    />
                    <Field
                      component={Input}
                      id="routing_number_confirm"
                      name="routing_number_confirm"
                      className={styles.routingNumber}
                      label="Re-Enter Routing Number"
                      maxLength={9}
                      format={convertToDigits}
                      inputProps={{
                        onCopy: preventAction,
                        onPaste: preventAction,
                      }}
                      validate={composeValidators(
                        fieldRequiredShort,
                        hasLength(9),
                        mustMatchTo(
                          'routing_number',
                          "Your routing numbers don't match",
                        ),
                      )}
                    />
                  </div>
                  <div className={cx(styles.row, styles.accountNumberRow)}>
                    <Field
                      component={AccountNumberInput}
                      id="account_number"
                      name="account_number"
                      className={styles.accountNumber}
                      label="Account Number"
                      format={convertToDigits}
                      inputProps={{
                        onCopy: preventAction,
                        onPaste: preventAction,
                      }}
                      validate={fieldRequiredShort}
                      showText={show}
                    />
                    <Field
                      component={AccountNumberInput}
                      id="account_number_confirm"
                      name="account_number_confirm"
                      className={styles.accountNumber}
                      label="Re-Enter Account Number"
                      format={convertToDigits}
                      inputProps={{
                        onCopy: preventAction,
                        onPaste: preventAction,
                      }}
                      validate={composeValidators(
                        fieldRequiredShort,
                        mustMatchTo(
                          'account_number',
                          "Your account numbers don't match",
                        ),
                      )}
                      showText={show}
                    />

                    <FlatButton
                      type="button"
                      onClick={() => showPassword(!show)}
                      className={cx(styles.toggleButton)}
                    >
                      <span className={styles.shortLabel}>
                        {toggleButtonLabel}
                      </span>
                      <span className={styles.longLabel}>
                        {toggleButtonLabel + ' Account Number'}
                      </span>
                    </FlatButton>
                  </div>
                  <span
                    className={cx(styles.link, styles.needHelp)}
                    onClick={() => setShowModal(true)}
                  >
                    Need help finding your routing and account numbers?
                  </span>
                  {isPremiumUser && (
                    <Field
                      component={Input}
                      name="nickname"
                      id="nickname"
                      label={'Bank Account Nickname'}
                      hint="(Optional)"
                      maxLength={25}
                      help={(val = '') => `${val.length} / 25 characters used`}
                      className={styles.nickname}
                    />
                  )}
                </div>
                <div
                  className={cx(styles.row, styles.btnWrapper, {
                    [styles.centered]: !showCancelButton,
                  })}
                >
                  {showCancelButton && (
                    <Button
                      className={styles.btn}
                      secondary
                      onClick={onCancelClick}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    className={styles.btn}
                    type="submit"
                    loading={submitting}
                    id="add_bank_manually_submit"
                  >
                    Add Bank Account
                  </Button>
                </div>
                <AddBankManuallyFooter />
              </form>
            );
          }}
        </Form>
      </Card>
    </>
  );
};

AddBankManuallyForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  isPremiumUser: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  user: PropTypes.object,
};

export default AddBankManuallyForm;
