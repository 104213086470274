const validAddress = (value) => {
  const address = value && value.trim();
  if (!address) {
    return undefined;
  }

  const addressRegex = /[?$!;%|><="@`^{}[\]:\\&*]/;
  const containsLetterRegex = /[a-zA-Z]/;
  const containsNumberRegex = /\d/;
  const containsSpaceRegex = /\s/;

  if (address.length < 3 || address.length > 50) {
    return 'Must be 3-50 characters.';
  }

  if (addressRegex.test(address)) {
    return 'Can not contain the following characters: ? $ ! ; % | > < = " ` @ ^ { } [ ] : \\ & *';
  }

  if (!containsLetterRegex.test(address)) {
    return 'Must contain at least one letter.';
  }

  if (!containsNumberRegex.test(address)) {
    return 'Must contain at least one number.';
  }

  if (!containsSpaceRegex.test(address)) {
    return 'Must contain at least one space.';
  }

  return undefined;
};

export default validAddress;
