import React, { memo } from 'react';
import PropTypes from 'prop-types';

const IOSShare = ({ width, height, color, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 21"
    fill={color}
    {...props}
  >
    <path d="M5.292 5.93v1.29H2.97c-.882 0-1.601.666-1.668 1.507l-.005.128v9.22c0 .857.678 1.563 1.542 1.628l.131.005h10.06c.882 0 1.601-.665 1.668-1.506l.005-.128v-9.22c0-.857-.678-1.563-1.542-1.628l-.131-.005h-2.322V5.929h2.322c1.584 0 2.88 1.222 2.966 2.765l.004.16v9.22c0 1.564-1.244 2.838-2.807 2.922L13.03 21H2.97C1.386 21 .09 19.778.004 18.235L0 18.075v-9.22C0 7.29 1.244 6.017 2.807 5.933l.163-.004h2.322zM8.565.204l.078.07 2.844 2.841a.54.54 0 01.063.69l-.062.075a.54.54 0 01-.765 0L8.648 1.808v10.793a.649.649 0 11-1.297 0V1.718L5.17 3.896a.538.538 0 01-.686.062l-.074-.062a.538.538 0 010-.761L7.271.278A.972.972 0 018.564.205z" />
  </svg>
);

IOSShare.defaultProps = {
  width: 16,
  height: 21,
  color: '#297AE0',
};

IOSShare.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(IOSShare);
