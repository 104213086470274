import React, { memo } from 'react';
import PropTypes from 'prop-types';

const StorageIcon = ({ width, height, color, ...props }) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3878 3C21.7259 3 22 3.27552 22 3.61538V7.71795C22 8.05782 21.7259 8.33333 21.3878 8.33333L21.183 8.333L21.1837 18.3846C21.1837 18.7245 20.9096 19 20.5714 19H3.42857C3.09044 19 2.81633 18.7245 2.81633 18.3846L2.816 8.333L2.61224 8.33333C2.27411 8.33333 2 8.05782 2 7.71795V3.61538C2 3.27552 2.27411 3 2.61224 3H21.3878ZM19.9592 8.33333H4.04082V17.7692H19.9592V8.33333ZM14.8571 9.5641C15.1953 9.5641 15.4694 9.89647 15.4694 10.3065V12.2861C15.4694 12.6961 15.1953 13.0285 14.8571 13.0285H9.14286C8.80472 13.0285 8.53061 12.6961 8.53061 12.2861V10.3065C8.53061 9.89647 8.80472 9.5641 9.14286 9.5641H14.8571ZM14.2449 10.7964H9.7551V11.9125H14.2449V10.7964ZM20.7755 4.23077H3.22449V7.10256H20.7755V4.23077Z"
      fill={color}
    />
  </svg>
);

StorageIcon.defaultProps = {
  width: 24,
  height: 24,
  color: '#042238', // Default color from the SVG
};

StorageIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(StorageIcon);
