import fieldRequired from './fieldRequired';
import isDate from './isDate';

// This validation is not for general use. It is intended to be used on lease
// forms with a start date, end date, and month-to-month fields. The fields must
// be named start_date, end_date, and month_to_month, and you can only have one
// of each of these fields on the form. You should use both this validation
// and validLeaseEndDate on the form. Look for existing forms where this
// validation is already used as a guide. Also see the tests, which do a good
// job of documenting all the various scenariros.

const validLeaseStartDate = (value, allValues) => {
  // Allow template value only while field has focus. Prevents error when first
  // clicking on field.
  if (value === '__/__/____') return undefined;

  // No end date or template value for end date, or end date is not valid, or
  // month-to-month: must be a date, but is not a required field.
  if (
    !allValues.end_date ||
    allValues?.end_date === '__/__/____' ||
    isDate(allValues.end_date) ||
    allValues.month_to_month
  )
    return isDate(value);

  // Has end date and it's not month-to-month so the start date is required and
  // must be a valid date.
  // This final test does cause some undesired behavior. To reproduce:
  // 1. Leave the start date blank.
  // 2. Enter a valid end date. You should now see an error for the start date.
  // 3. Click on the start date but do not enter any value.
  // 4. Try to click on month-to-month. It will not change to be checked.
  // Not sure if it's possible to fix that, but if someone can figure it out,
  // please go for it.
  return fieldRequired(value) || isDate(value);
};

export default validLeaseStartDate;
