import React from 'react';
import PropTypes from 'prop-types';

import styles from './Title.module.scss';

const Title = ({ title, icon: Icon, iconProps, description, ...rest }) => {
  return (
    <div className={styles.titleWrapper} {...rest}>
      {Icon ? (
        <div data-test="icon-wrapper" className={styles.iconWrapper}>
          <Icon width={24} height={24} {...iconProps} />
        </div>
      ) : null}
      <div className={styles.textWrapper}>
        <h2 className={styles.title}>{title}</h2>
        {description ? (
          <p data-test="description" className={styles.description}>
            {description}
          </p>
        ) : null}
      </div>
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.elementType,
  iconProps: PropTypes.shape({}),
};

Title.defaultProps = {
  iconProps: {},
};

export default Title;
