import React, { memo } from 'react';

const HouseIcon = (props) => (
  <svg {...props} viewBox="0 0 28 20" fill={props.color}>
    <path
      d="M13.79.667l13.32 6.418V10.1h-1.764v9.045H2.136V10.1H.666V7.085L13.79.667zm0 3.015L4.976 8.155v8.266h17.53V8.155L13.79 3.682zm4.015 8.655v2.918H9.677v-2.918h8.128zm0-4.182v2.917H9.677V8.155h8.128z"
      fillRule="evenodd"
    />
  </svg>
);

HouseIcon.defaultProps = {
  color: '#042238',
};

export default memo(HouseIcon);
