import convertToNumber from '../helpers/convert-to-number';

export const amountGreaterThenZero = (value) => {
  if (!value || convertToNumber(value) <= 0) {
    return 'Amount must be greater than 0';
  }
  return undefined;
};

export default amountGreaterThenZero;
