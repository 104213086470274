import React from 'react';
import PropTypes from 'prop-types';

import { Link } from './EmailTypography';

const LandlordsFooterEmailComponent = ({ isPreview = false }) => {
  return (
    <div style={{ fontSize: '14px' }}>
      Need a little help? Visit the{' '}
      <Link
        style={{ fontSize: '14px' }}
        href="https://support.turbotenant.com/"
        disabled={isPreview}
      >
        Help Center
      </Link>{' '}
      for tips and advice, or email our Colorado-based support staff at{' '}
      <Link
        style={{ fontSize: '14px' }}
        href="mailto:support@turbotenant.com"
        disabled={isPreview}
      >
        support@turbotenant.com
      </Link>
      .
    </div>
  );
};

LandlordsFooterEmailComponent.propTypes = {
  isPreview: PropTypes.bool,
};

export default LandlordsFooterEmailComponent;
