import React, { memo } from 'react';
import PropTypes from 'prop-types';

const CondominiumColor = ({ color, insideColor, borderColor, ...props }) => (
  <svg viewBox="0 0 33 40" {...props} fill={color}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={insideColor}
        d="M7.5 39.167h5v-7.5h-5v7.5ZM5.833 15.833h5V12.5h-5v3.333Zm8.334 0h5V12.5h-5v3.333Zm8.333 0h5V12.5h-5v3.333ZM5.833 22.5h5v-3.333h-5V22.5Zm8.334 0h5v-3.333h-5V22.5Zm8.333 0h5v-3.333h-5V22.5Zm-1.667 16.667h5v-7.5h-5v7.5ZM27.5 7.5V4.167h3.333v6.07"
      />
      <path
        fill={borderColor}
        fillRule="nonzero"
        d="m16.695 0 .023.002a.837.837 0 0 1 .216.042l.043.016a.787.787 0 0 1 .135.069l9.554 6.033V4.167c0-.46.374-.834.834-.834h3.333c.46 0 .834.374.834.834l-.001 5.153 1.279.809a.833.833 0 0 1-.89 1.409l-.389-.247v27.042h.834a.833.833 0 0 1 0 1.667H.833a.833.833 0 1 1 0-1.667h.833V11.292l-.388.246a.833.833 0 1 1-.89-1.41l15.834-10 .03-.018.025-.013.021-.012-.012.007a.834.834 0 0 1 .066-.03l.024-.01.023-.008a.748.748 0 0 1 .217-.042L16.641 0h.054Zm-.029 1.819-13.333 8.42v28.094h3.333v-6.666c0-.46.374-.834.834-.834h5c.46 0 .833.373.833.834v6.666H20v-6.666c0-.46.373-.834.833-.834h5c.46 0 .834.373.834.834l-.001 6.666H30V10.239l-13.334-8.42Zm-5 30.681H8.334v5.833h3.334V32.5ZM25 32.5h-3.333v5.833H25V32.5Zm-11.667-5a.833.833 0 1 1 0 1.667H6.667a.833.833 0 0 1 0-1.667h6.666Zm13.334 0a.833.833 0 1 1 0 1.667H20a.833.833 0 0 1 0-1.667h6.667Zm-15.834-9.167c.46 0 .834.373.834.834V22.5c0 .46-.373.833-.834.833h-5A.833.833 0 0 1 5 22.5v-3.333c0-.46.373-.834.833-.834h5Zm8.334 0c.46 0 .833.373.833.834V22.5c0 .46-.373.833-.833.833h-5a.833.833 0 0 1-.834-.833v-3.333c0-.46.374-.834.834-.834h5Zm8.333 0c.46 0 .833.373.833.834V22.5c0 .46-.373.833-.833.833h-5a.833.833 0 0 1-.833-.833v-3.333c0-.46.373-.834.833-.834h5ZM10 20H6.667v1.667H10V20Zm8.333 0H15v1.667h3.333V20Zm8.334 0h-3.334v1.667h3.334V20Zm-15.834-8.333c.46 0 .834.373.834.833v3.333c0 .46-.373.834-.834.834h-5A.833.833 0 0 1 5 15.833V12.5c0-.46.373-.833.833-.833h5Zm8.334 0c.46 0 .833.373.833.833v3.333c0 .46-.373.834-.833.834h-5a.833.833 0 0 1-.834-.834V12.5c0-.46.374-.833.834-.833h5Zm8.333 0c.46 0 .833.373.833.833v3.333c0 .46-.373.834-.833.834h-5a.833.833 0 0 1-.833-.834V12.5c0-.46.373-.833.833-.833h5ZM10 13.333H6.667V15H10v-1.667Zm8.333 0H15V15h3.333v-1.667Zm8.334 0h-3.334V15h3.334v-1.667ZM30 5h-1.667v2.215L30 8.268V5Z"
      />
    </g>
  </svg>
);

CondominiumColor.defaultProps = {
  width: 33,
  height: 40,
  color: 'currentColor',
  insideColor: '#7FE3FF',
  borderColor: '#042238',
};

CondominiumColor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(CondominiumColor);
