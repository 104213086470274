export const setLeaseNickName = (
  [currentAddress, previousAddress],
  state,
  utils,
) => {
  const { formState = {} } = state;
  const { values = {} } = formState;
  const { title } = values;

  /**
   * NOTICE: The `title === previousAddress` check is important because:
   *         1) After writing the first character title is not longer null.
   *         2) When deleting and we want to check if the current title is
   *            the address we're deleting.
   */
  if (title == null || title === '' || title === previousAddress) {
    utils.changeValue(state, 'title', () => currentAddress);
  }
};
