const STATE_LEASE_HELP_ARTICLES = {
  TX: 'https://support.turbotenant.com/en/articles/4394106-texas-lease-agreement',
  MT: 'https://support.turbotenant.com/en/articles/4449868-montana-lease-agreement',
  AZ: 'https://support.turbotenant.com/en/articles/4856619-arizona-lease-agreement',
  CO: 'https://support.turbotenant.com/en/articles/4860073-colorado-lease-agreement',
  VA: 'https://support.turbotenant.com/en/articles/4860078-virginia-lease-agreement',
  FL: 'https://support.turbotenant.com/en/articles/5075553-florida-lease-agreement',
  MI: 'https://support.turbotenant.com/en/articles/5159980-michigan-lease-agreement',
  SC: 'https://support.turbotenant.com/en/articles/5160037-south-carolina-lease-agreement',
  GA: 'https://support.turbotenant.com/en/articles/5297086-georgia-lease-agreement',
  PA: 'https://support.turbotenant.com/en/articles/5297203-pennsylvania-lease-agreement',
  CA: 'https://support.turbotenant.com/en/articles/5297248-california-lease-agreement',
  AL: 'https://support.turbotenant.com/en/articles/5599141-alabama-lease-agreement',
  IN: 'https://support.turbotenant.com/en/articles/5599140-indiana-lease-agreement',
  OH: 'https://support.turbotenant.com/en/articles/5599112-ohio-lease-agreement',
  NJ: 'https://support.turbotenant.com/en/articles/5581104-new-jersey-lease-agreement',
  TN: 'https://support.turbotenant.com/en/articles/5752376-tennessee-lease-agreement',
  NC: 'https://support.turbotenant.com/en/articles/5761526-north-carolina-lease-agreement',
  MN: 'https://support.turbotenant.com/en/articles/5809369-minnesota-lease-agreement',
  MD: 'https://support.turbotenant.com/en/articles/5858213-maryland-lease-agreement',
  LA: 'https://support.turbotenant.com/en/articles/5873425-louisiana-lease-agreement',
  WI: 'https://support.turbotenant.com/en/articles/5953145-wisconsin-lease-agreement',
  WA: 'https://support.turbotenant.com/en/articles/6064453-washington-lease-agreement',
  MO: 'https://support.turbotenant.com/en/articles/6063920-missouri-lease-agreement',
  NM: 'https://support.turbotenant.com/en/articles/6088589-new-mexico-lease-agreement',
  ID: 'https://support.turbotenant.com/en/articles/6145855-idaho-lease-agreement',
  KS: 'https://support.turbotenant.com/en/articles/6145924-kansas-lease-agreement',
  AR: 'https://support.turbotenant.com/en/articles/6145931-arkansas-lease-agreement',
  NH: 'https://support.turbotenant.com/en/articles/6145936-new-hampshire-lease-agreement',
  MS: 'https://support.turbotenant.com/en/articles/6145940-mississippi-lease-agreement',
  WV: 'https://support.turbotenant.com/en/articles/6145943-west-virginia-lease-agreement',
  NE: 'https://support.turbotenant.com/en/articles/6145942-nebraska-lease-agreement',
  RI: 'https://support.turbotenant.com/en/articles/6155587-rhode-island-lease-agreement',
  HI: 'https://support.turbotenant.com/en/articles/6177435-hawaii-lease-agreement',
  MA: 'https://support.turbotenant.com/en/articles/6188726-massachusetts-lease-agreement',
  KY: 'https://support.turbotenant.com/en/articles/6192505-kentucky-lease-agreement',
  OK: 'https://support.turbotenant.com/en/articles/6194820-oklahoma-lease-agreement',
  ME: 'https://support.turbotenant.com/en/articles/6207332-lease-agreements-in-maine',
  NV: 'https://support.turbotenant.com/en/articles/6198515-nevada-lease-agreement',
  IA: 'https://support.turbotenant.com/en/articles/6195281-iowa-lease-agreement',
  OR: 'https://support.turbotenant.com/en/articles/6191480-oregon-lease-agreement',
  CT: 'https://support.turbotenant.com/en/articles/6198502-connecticut-lease-agreement',
  ND: 'http://support.turbotenant.com/en/articles/6217365-north-dakota-lease-agreement',
  UT: 'http://support.turbotenant.com/en/articles/6217357-utah-lease-agreement',
  WY: 'http://support.turbotenant.com/en/articles/6217363-wyoming-lease-agreement',
  SD: 'http://support.turbotenant.com/en/articles/6207337-south-dakota-lease-agreement',
  DE: 'http://support.turbotenant.com/en/articles/6207336-delaware-lease-agreement',
  DC: 'https://support.turbotenant.com/en/articles/6242312-washington-d-c',
  AK: 'https://support.turbotenant.com/en/articles/6207342-alaska-lease-agreement',
  VT: 'https://support.turbotenant.com/en/articles/6242321-vermont',
  IL: 'http://support.turbotenant.com/en/articles/6245229-illinois-lease-agreement',
  NY: 'https://support.turbotenant.com/en/articles/6246872-new-york-lease-agreement',
};

const SUB_DIVISION_HELP_ARTICLES = {
  VT: {
    burlingtonBarre:
      'http://support.turbotenant.com/en/articles/6242319-burlington-barre-vermont',
  },
  IL: {
    chicago:
      'http://support.turbotenant.com/en/articles/6245231-chicago-illinois-lease-agreement',
    cookCounty:
      'http://support.turbotenant.com/en/articles/6245234-cook-county-illinois-lease-agreement',
  },
  NY: {
    city: 'https://support.turbotenant.com/en/articles/6246872-new-york-lease-agreement',
  },
};

export const getStateLeaseHelpArticle = (state, subDivision) => {
  if (subDivision && SUB_DIVISION_HELP_ARTICLES[state]?.[subDivision]) {
    return SUB_DIVISION_HELP_ARTICLES[state][subDivision];
  }
  return STATE_LEASE_HELP_ARTICLES[state];
};

export default STATE_LEASE_HELP_ARTICLES;
