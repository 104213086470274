import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Dashboard = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 25" {...rest} fill={color}>
    <path d="M12 15.06a.64.64 0 1 0 0 1.279.64.64 0 0 0 0-1.278Zm-1.84.64a1.84 1.84 0 1 1 3.679 0 1.84 1.84 0 0 1-3.678 0Z" />
    <path d="M17.389 10.31a.6.6 0 0 1 0 .85l-4.09 4.088a.6.6 0 0 1-.848-.848l4.089-4.09a.6.6 0 0 1 .849 0ZM3.552 16.113a.6.6 0 0 1 .6-.6h1.652a.6.6 0 1 1 0 1.2H4.152a.6.6 0 0 1-.6-.6ZM17.596 16.113a.6.6 0 0 1 .6-.6h1.652a.6.6 0 1 1 0 1.2h-1.652a.6.6 0 0 1-.6-.6ZM19.808 12.473a.6.6 0 0 1-.327.783l-1.529.628a.6.6 0 1 1-.456-1.11l1.529-.628a.6.6 0 0 1 .783.327ZM15.238 7.893a.6.6 0 0 1 .323.784l-.636 1.528a.6.6 0 1 1-1.108-.46l.636-1.529a.6.6 0 0 1 .785-.323ZM12 7.252a.6.6 0 0 1 .6.6v1.652a.6.6 0 1 1-1.2 0V7.852a.6.6 0 0 1 .6-.6ZM8.773 7.892a.6.6 0 0 1 .783.327l.628 1.528a.6.6 0 1 1-1.11.456l-.628-1.528a.6.6 0 0 1 .327-.783ZM6.034 9.723a.6.6 0 0 1 .849.003l1.164 1.173a.6.6 0 1 1-.851.845L6.03 10.571a.6.6 0 0 1 .003-.848ZM4.2 12.472a.6.6 0 0 1 .784-.325l1.52.627a.6.6 0 1 1-.458 1.11l-1.52-.628a.6.6 0 0 1-.326-.784Z" />
    <path d="M1.9 15.7C1.9 10.123 6.423 5.6 12 5.6s10.1 4.523 10.1 10.1v3.717a.6.6 0 0 1-.6.6h-19a.6.6 0 0 1-.6-.6V15.7ZM12 6.8a8.901 8.901 0 0 0-8.9 8.9v3.117h17.8V15.7c0-4.915-3.986-8.9-8.9-8.9Z" />
  </svg>
);

Dashboard.defaultProps = {
  width: 24,
  height: 25,
  color: colors.primary,
};

Dashboard.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Dashboard);
