import React from 'react';
import { Field, Form } from 'react-final-form';
import { Link, withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';

import validatePasswordGQL from '../../graphql/mutations/users/validateUserPassword.graphql';
import composeValidators from '../../validators/composeValidators';
import fieldRequired from '../../validators/fieldRequired';
import Button from '../Button';
import FinalFormError from '../FinalFormError';
import Modal from '../Modal';
import PasswordInput from '../PasswordInput';

import styles from './AskForAccountPasswordModal.module.scss';

const AskForAccountPasswordModal = ({
  onClose,
  onPasswordValid,
  open,
  onForgotPasswordClick,
}) => {
  const [validatePassword] = useMutation(validatePasswordGQL);

  const onSubmit = async (formData) => {
    try {
      const { data } = await validatePassword({
        variables: { password: formData.password, accountType: 'OWNER' },
      });
      if (data.validateUserPassword.ok) {
        onPasswordValid();
      } else {
        return {
          [FORM_ERROR]: 'Invalid password',
        };
      }
    } catch (error) {
      return {
        [FORM_ERROR]: 'An error has occurred',
      };
    }
  };

  return (
    <Modal open={open} className={styles.container}>
      <Form
        onSubmit={onSubmit}
        subscription={{
          submitting: true,
        }}
        initialValues={{
          current_password: '',
          passsword: '',
          confirm_password: '',
        }}
      >
        {({ handleSubmit, submitting, form }) => (
          <form onSubmit={handleSubmit}>
            <h1>First, enter your password</h1>
            <p>This keeps your account secure.</p>
            <FinalFormError />
            <Field
              label="Password"
              component={PasswordInput}
              name="password"
              id="password"
              hint={null}
              className={styles.current}
              validate={composeValidators(fieldRequired)}
              inputProps={{
                autoComplete: 'password',
              }}
            />
            <Link
              {...(onForgotPasswordClick
                ? {}
                : {
                    to: `/auth/resetpassword/reset?redirectTo=${encodeURIComponent(
                      location.pathname,
                    )}`,
                  })}
              onClick={onForgotPasswordClick ? onForgotPasswordClick : onClose}
              className={styles.forgot}
            >
              Forgot your password?
            </Link>
            <div className={styles.action}>
              <Button
                className={styles.button}
                secondary
                onClick={() => {
                  if (onClose) {
                    onClose();
                  }
                  form.reset();
                }}
                disabled={submitting}
              >
                Cancel
              </Button>
              <Button
                className={styles.button}
                type="submit"
                loading={submitting}
                id="confirm-password"
              >
                Continue
              </Button>
            </div>
          </form>
        )}
      </Form>
    </Modal>
  );
};

AskForAccountPasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPasswordValid: PropTypes.func,
  onForgotPasswordClick: PropTypes.func,
};

export default withRouter(AskForAccountPasswordModal);
