import { exemptionsList } from '../constants/enums/TerminationFeeExemptionsEnum';

const getPrefix = (
  index,
  cancellationFeeOtherExemption,
  cancellationFeeExemptions,
) => {
  const otherObj = exemptionsList.find(
    (exemptionObj) => exemptionObj.value === 'other',
  );

  otherObj.leaseText = cancellationFeeOtherExemption;

  if (index === cancellationFeeExemptions.length - 1) {
    return ' or ';
  }

  if (index > 0) {
    return ', ';
  }

  return '';
};

export default getPrefix;
