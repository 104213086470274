import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import TombstoneIcon from '../../../../../../../icons/Tombstone';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './DeathDisclosureCA.module.scss';

const DeathDisclosureCA = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  const {
    input: { value: hasSomeone },
  } = useField('custom_state_data.has_someone_died');

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Death Disclosure" icon={TombstoneIcon} />
      <label className={styles.label}>
        Has a non-AIDS related death occurred on the property in the last 3
        years?
      </label>
      <p className={styles.description}>
        California law requires you to disclose this and the nature of death,
        unless it was AIDS related.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.has_someone_died"
        name="custom_state_data.has_someone_died"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'has_someone_died_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'has_someone_died_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      {hasSomeone === true && (
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.has_someone_died_explanation"
          name="custom_state_data.has_someone_died_explanation"
          label="Please provide the nature of the non-AIDS related death:"
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      )}
    </div>
  );
};

DeathDisclosureCA.propTypes = {
  className: PropTypes.string,
};

export default DeathDisclosureCA;
