import React from 'react';
import PropTypes from 'prop-types';

import ParkingThickIcon from '../../../../icons/ParkingThick';
import SectionTitle from '../../common/SectionTitle';

import styles from './SectionPetsSmokingOther.module.scss';

export const SectionPetsSmokingOtherParkingSection = ({ children }) => {
  return (
    <div className={styles.section}>
      <SectionTitle title="Parking" icon={ParkingThickIcon} />
      <label className={styles.headerLabel}>
        Do you want to include parking rules?
      </label>
      {children}
    </div>
  );
};

SectionPetsSmokingOtherParkingSection.propTypes = {
  children: PropTypes.node,
};
