import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import PlainRadioButton from '../PlainRadioButton';

import styles from './RadioButton.module.scss';

const RadioButton = ({
  id,
  input,
  name,
  label,
  disabled,
  className,
  meta, // ignored
  labelClassName,
  ...restProps
}) => {
  return (
    <div
      className={cx(styles.container, className, {
        [styles.disabled]: disabled,
        [styles.checked]: input.checked,
      })}
    >
      <label className={styles.radioContainer}>
        <PlainRadioButton
          name={name}
          {...input}
          id={id}
          disabled={disabled}
          className={styles.radio}
          {...restProps}
        />
        {React.isValidElement(label) ? (
          <div className={cx(styles.label, labelClassName)}>{label}</div>
        ) : (
          <span className={cx(styles.label, labelClassName)}>{label}</span>
        )}
      </label>
    </div>
  );
};

RadioButton.propTypes = {
  id: PropTypes.string,
  input: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  meta: PropTypes.object,
};

RadioButton.defaultProps = {
  meta: {},
  input: {},
};

export default RadioButton;
