import React from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Tooltip } from 'react-tooltip';
import cx from 'classnames';
import arrayMutators from 'final-form-arrays';
import createFinalFormErrorDecorator from 'final-form-focus';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Condition from '../../../../components/Condition';
import FlatButton from '../../../../components/FlatButton';
import Input from '../../../../components/Input';
import trimEmail from '../../../../components/Input/trimEmail';
import PhoneField from '../../../../components/PhoneField';
import RadioGroup from '../../../../components/RadioGroup';
import MinusCircle from '../../../../icons/MinusCircle';
import PlusCircle from '../../../../icons/PlusCircle';
import composeValidators from '../../../../validators/composeValidators';
import fieldRequired from '../../../../validators/fieldRequired';
import validEmail from '../../../../validators/validEmail';
import validName from '../../../../validators/validName';
import validPhone from '../../../../validators/validPhone';
import UnsavedChangesPrompt from '../../../digital-leases/standalone-wizard/UnsavedChangesPrompt';

import styles from './InviteRentersToApplyForm.module.scss';

const RENTERS_LIMIT = 5;

const emailInUse = (index) => (value, allValues) => {
  let lastFoundIndex = -1;
  allValues.renters.forEach((renter, i) => {
    if (i !== index && renter.email === value) {
      lastFoundIndex = i;
    }
  });

  if (lastFoundIndex === -1) {
    return undefined;
  }

  if (index > lastFoundIndex) {
    return 'Email already in use';
  }

  return undefined;
};

const phoneInUse = (index) => (value, allValues) => {
  let lastFoundIndex = -1;
  allValues.renters.forEach((renter, i) => {
    if (i !== index && renter.phone === value) {
      lastFoundIndex = i;
    }
  });

  if (lastFoundIndex === -1) {
    return undefined;
  }

  if (index > lastFoundIndex) {
    return 'Phone already in use';
  }

  return undefined;
};

const InviteRentersToApplyForm = ({
  className,
  onSubmit,
  initialValues,
  onAddAnother,
}) => {
  const finalFormErrorDecorator = createFinalFormErrorDecorator();
  return (
    <Form
      onSubmit={onSubmit}
      subscription={{
        submitting: true,
        values: true,
        pristine: true,
      }}
      mutators={{
        ...arrayMutators,
      }}
      decorators={[finalFormErrorDecorator]}
      initialValues={
        !isEmpty(initialValues)
          ? initialValues
          : {
              renters: [
                {
                  first_name: '',
                  last_name: '',
                  inviteType: '',
                  email: '',
                  phone: '',
                },
              ],
            }
      }
    >
      {({ handleSubmit, submitting, pristine }) => (
        <form className={cx(styles.form, className)} onSubmit={handleSubmit}>
          <UnsavedChangesPrompt when={!pristine} />
          <FieldArray name="renters">
            {({ fields }) => (
              <>
                {fields.map((name, index) => (
                  <div
                    key={index}
                    className={cx(styles.fieldGroup, {
                      [styles.fieldGroupWrapped]: fields.length > 1,
                    })}
                  >
                    <div className={styles.nameWrapper}>
                      <Field
                        component={Input}
                        id={`${name}.first_name`}
                        name={`${name}.first_name`}
                        className={styles.name}
                        label="Renter's First Name"
                        validate={composeValidators(fieldRequired, validName)}
                      />
                      <Field
                        component={Input}
                        id={`${name}.last_name`}
                        name={`${name}.last_name`}
                        className={styles.name}
                        label="Renter's Last Name"
                        validate={composeValidators(fieldRequired, validName)}
                      />
                    </div>
                    <Field
                      component={RadioGroup}
                      id={`${name}.inviteType`}
                      name={`${name}.inviteType`}
                      label="Send Invite By:"
                      radioRowClassName={styles.radioOption}
                      options={[
                        {
                          label: 'Email & Text',
                          value: 'both',
                        },
                        {
                          label: 'Email',
                          value: 'email',
                        },
                        {
                          label: 'Text',
                          value: 'text',
                        },
                      ]}
                      validate={fieldRequired}
                      className={styles.inviteBy}
                    />
                    <div className={styles.inviteTypeWrapper}>
                      <Condition when={`${name}.inviteType`} is="email">
                        <Field
                          label="Renter's Email"
                          component={Input}
                          type="email"
                          id={`${name}.email`}
                          name={`${name}.email`}
                          className={styles.email}
                          validate={composeValidators(
                            validEmail,
                            fieldRequired,
                            emailInUse(index),
                          )}
                          parse={trimEmail}
                        />
                      </Condition>
                      <Condition when={`${name}.inviteType`} is="text">
                        <Field
                          label="Renter's Phone"
                          component={PhoneField}
                          type="phone"
                          id={`${name}.phone`}
                          name={`${name}.phone`}
                          className={styles.phone}
                          validate={composeValidators(
                            fieldRequired,
                            validPhone,
                            phoneInUse(index),
                          )}
                        />
                      </Condition>
                      <Condition when={`${name}.inviteType`} is="both">
                        <Field
                          label="Renter's Email"
                          component={Input}
                          type="email"
                          id={`${name}.email`}
                          name={`${name}.email`}
                          className={styles.email}
                          validate={composeValidators(
                            validEmail,
                            fieldRequired,
                            emailInUse(index),
                          )}
                          parse={trimEmail}
                        />
                        <Field
                          label="Renter's Phone"
                          component={PhoneField}
                          type="phone"
                          id={`${name}.phone`}
                          name={`${name}.phone`}
                          className={styles.phone}
                          validate={composeValidators(
                            fieldRequired,
                            validPhone,
                            phoneInUse(index),
                          )}
                        />
                      </Condition>
                    </div>
                    {fields.length > 1 && (
                      <FlatButton
                        id={`remove_additional_key_${index}`}
                        className={styles.removeButton}
                        icon={MinusCircle}
                        onClick={() => fields.remove(index)}
                      >
                        Remove
                      </FlatButton>
                    )}
                  </div>
                ))}
                <Button
                  className={styles.addButton}
                  tertiary
                  data-tooltip-id="add_another_disabled"
                  icon={PlusCircle}
                  disabled={fields.length === RENTERS_LIMIT}
                  onClick={() => {
                    fields.push('');
                    onAddAnother && onAddAnother();
                  }}
                >
                  Add Another
                </Button>
                {fields.length === RENTERS_LIMIT ? (
                  <Tooltip
                    className={styles.addButtonTooltip}
                    id="add_another_disabled"
                    place="top"
                  >
                    You can invite more applicants later.
                  </Tooltip>
                ) : null}
              </>
            )}
          </FieldArray>
          <Button
            className={styles.submitBtn}
            loading={submitting}
            type="submit"
          >
            Next
          </Button>
        </form>
      )}
    </Form>
  );
};

InviteRentersToApplyForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  onAddAnother: PropTypes.func,
};

InviteRentersToApplyForm.defaultProps = {
  initialValues: {},
};

export default InviteRentersToApplyForm;
