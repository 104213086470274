import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';

import styles from './UnsavedChangesModal.module.scss';

const UnsavedChangesModal = ({ when, onConfirm, onCancel }) => {
  return (
    <NavigationPrompt when={when}>
      {({ onConfirm: onConfirmPrompt, onCancel: onCancelPrompt }) => (
        <Modal open className={styles.modal}>
          <p className={styles.title}>You have unsaved changes.</p>
          <div className={styles.description}>
            Stay to keep your changes. If you leave, your changes will be lost.
          </div>
          <div className={styles.btnContainer}>
            <Button
              className={styles.btn}
              onClick={() => {
                onCancel();
                onCancelPrompt();
              }}
            >
              Stay
            </Button>
            <Button
              secondary
              className={styles.btn}
              onClick={() => {
                onConfirm();
                onConfirmPrompt();
              }}
            >
              Leave
            </Button>
          </div>
        </Modal>
      )}
    </NavigationPrompt>
  );
};

UnsavedChangesModal.propTypes = {
  when: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  styles: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

UnsavedChangesModal.defaultProps = {
  when: false,
  onConfirm: () => ({}),
  onCancel: () => ({}),
};

export default UnsavedChangesModal;
