import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Cristal = (props) => (
  <svg {...props}>
    <path
      d="m11.42.639.08.068 3.59 3.61c.21.118.357.343.357.608v10.248c0 .14-.042.273-.117.386l-3.745 5.628a.705.705 0 0 1-1.106.083l-.064-.083-3.745-5.629a.696.696 0 0 1-.117-.385V4.925c0-.265.147-.49.357-.608L10.5.707a.705.705 0 0 1 .92-.068ZM9.29 16.966l1.007 1.513v-1.012l-1.007-.501Zm3.418 0-1.006.501v1.011l1.006-1.512ZM7.958 5.819v8.922l2.339 1.164V6.402l-2.34-.583Zm6.085 0-2.341.583v9.502l2.34-1.164V5.82Zm-3.746-2.922L8.652 4.552l1.645.409V2.897Zm1.405.001v2.063l1.645-.409-1.645-1.654Zm6.259-1.193a.705.705 0 0 1 .993 0c.25.247.272.636.068.91l-.068.078L17.2 4.44a.705.705 0 0 1-.993 0 .696.696 0 0 1-.068-.91l.068-.078 1.755-1.747Zm-1.755 14c.249-.248.639-.271.914-.068l.078.068 1.756 1.745a.696.696 0 0 1 0 .988.705.705 0 0 1-.914.068l-.079-.067-1.755-1.746a.696.696 0 0 1 0-.988Zm5.092-5.89a.7.7 0 0 1 .702.7.7.7 0 0 1-.607.692l-.095.006h-2.809a.7.7 0 0 1-.702-.698.7.7 0 0 1 .607-.693l.095-.006h2.809ZM3.206 1.706c.25-.248.64-.27.914-.068l.079.068 1.755 1.747a.696.696 0 0 1 0 .989.705.705 0 0 1-.914.067l-.08-.068-1.754-1.747a.696.696 0 0 1 0-.988Zm1.755 14a.705.705 0 0 1 .993 0c.25.248.272.636.068.91l-.068.078-1.756 1.746a.705.705 0 0 1-.992 0 .696.696 0 0 1-.068-.91l.068-.079 1.755-1.745Zm-1.45-5.89a.7.7 0 0 1 .702.7.7.7 0 0 1-.607.692l-.095.006H.702A.7.7 0 0 1 0 10.515a.7.7 0 0 1 .607-.693l.095-.006h2.809Z"
      fill="#042238"
      fillRule="evenodd"
    />
  </svg>
);

Cristal.defaultProps = {
  width: 22,
  height: 22,
};

Cristal.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(Cristal);
