import { getGlobals } from '../globals';

let cachedConfig;

export const fetchConfig = async () => {
  if (cachedConfig) {
    return cachedConfig;
  }

  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(`/config.${__COMMIT_HASH__}.json`);
    const config = await response.json();
    cachedConfig = getGlobals(config);
  } catch (err) {
    console.warn('Failed to load config, using globals', err);
    window.atatus && window.atatus.notify('MISSING FE CONFIG');
    cachedConfig = getGlobals();
  }

  return cachedConfig;
};

export const getConfig = () => {
  if (cachedConfig) {
    return cachedConfig;
  }

  return {};
};
