import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';
import TriangleError from '../../icons/TriangleError';
import Button from '../Button';
import Modal from '../Modal';
import { H3 } from '../Typography';

import styles from './ErrorModal.module.scss';

const ErrorModal = ({
  title,
  description,
  buttonLabel,
  open,
  onClose,
  onClick,
  className,
  showCloseIcon,
  iconWrapperClassName,
  icon: Icon,
}) => {
  return (
    <Modal
      className={cx(styles.errorModal, className)}
      open={open}
      onClose={onClose}
      showCloseIcon={showCloseIcon}
      disableOutsideClick
      id="error-modal"
    >
      <div className={styles.content}>
        <div className={cx(styles.iconWrapper, iconWrapperClassName)}>
          <Icon className={styles.icon} halfFillColor={colors.statusWarning} />
        </div>
        <H3 className={styles.title}>{title}</H3>
        <p className={styles.description}>{description}</p>
        <Button className={styles.btn} onClick={onClick ? onClick : onClose}>
          {buttonLabel}
        </Button>
      </div>
    </Modal>
  );
};

ErrorModal.propTypes = {
  title: PropTypes.node,
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  open: PropTypes.bool.isRequired,
  className: PropTypes.string,
  iconWrapperClassName: PropTypes.string,
  showCloseIcon: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
};

ErrorModal.defaultProps = {
  title: 'Sorry, there was an error.',
  buttonLabel: 'Try Again',
  icon: TriangleError,
  showCloseIcon: false,
};

export default ErrorModal;
