import React from 'react';
import { useForm } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import Notice from '../../../../components/Notice';
import WarningCircleFilledIcon from '../../../../icons/WarningCircleFilled';
import { getStateFullName } from '../../../../services/utilities';

import styles from './UpdateStateCityModal.module.scss';

const UpdateStateCityModal = ({
  className,
  containerClassName,
  open,
  currentState,
  onUpdate,
  onCancel,
}) => {
  const form = useForm();
  const newStateFullName = getStateFullName(
    form.getFieldState('propertyDetails.state').value,
  );
  const currentStateFullName = getStateFullName(currentState);

  return (
    <Modal
      open={open}
      className={cx(styles.modal, className)}
      containerClassName={cx(styles.modalContainer, containerClassName)}
      onClose={onCancel}
    >
      <h2 className={styles.title}>
        Do you want to change to a {newStateFullName} Lease Agreement?
      </h2>
      <Notice
        className={styles.notice}
        contentClassName={styles.noticeContent}
        type="warn"
        title={
          <div className={styles.noticeTitleWrapper}>
            <WarningCircleFilledIcon
              className={styles.noticeTitleIcon}
              width={24}
              height={24}
              color={'#FF9F2A'}
            />
            <span className={styles.noticeTitle}>
              This Lease Agreement is specific to {currentStateFullName}.
            </span>
          </div>
        }
      >
        If you update the rental address, any previously entered information{' '}
        <b>will not</b> be saved.
      </Notice>
      <div className={styles.btnWrapper}>
        <Button
          className={styles.btn}
          secondary
          onClick={() => {
            onCancel();
            form.change('propertyDetails.state', currentState);
          }}
        >
          Cancel
        </Button>
        <Button
          className={styles.btn}
          onClick={onUpdate}
          data-qa="property-confirm-delete-modal-button-delete"
        >
          Update
        </Button>
      </div>
    </Modal>
  );
};

UpdateStateCityModal.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  open: PropTypes.bool,
  currentState: PropTypes.string,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
};

export default UpdateStateCityModal;
