import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <defs>
        <clipPath id="i0">
          <path d="M1280 0v1772H0V0h1280z"></path>
        </clipPath>
        <clipPath id="i1">
          <path d="M7.333 0v2.667h3.334L10.666 0h16.667v2.667h3.334L30.666 0H38v8H0V0h7.333z"></path>
        </clipPath>
        <clipPath id="i2">
          <path d="M12.5 0c.46 0 .833.373.833.833v2.5h13.333v-2.5c0-.46.374-.833.834-.833h5c.46 0 .833.373.833.833v2.5h5.834c.425 0 .775.318.827.73l.006.104v35c0 .46-.373.833-.833.833H.833A.833.833 0 010 39.167v-35c0-.46.373-.834.833-.834h5.833v-2.5C6.667.373 7.04 0 7.5 0h5zm25.833 13.333H1.666v25h36.667v-25zM27.5 15c.46 0 .833.373.833.833v2.5h7.5c.425 0 .776.318.827.73l.007.104c0 .46-.373.833-.834.833h-7.5v5h7.5c.425 0 .776.318.827.729l.007.104c0 .46-.373.834-.834.834l-7.5-.001v5h7.5c.425 0 .776.319.827.73l.007.104c0 .46-.373.833-.834.833h-7.5v2.5a.833.833 0 01-.728.827l-.105.007a.833.833 0 01-.833-.834l-.001-2.5H20v2.5a.833.833 0 01-.729.827l-.104.007a.833.833 0 01-.834-.834v-2.5h-6.667v2.5a.833.833 0 01-.728.827l-.105.007a.833.833 0 01-.833-.834v-2.5H4.167a.833.833 0 01-.827-.728l-.007-.105c0-.46.373-.833.834-.833L10 31.666v-5H4.167a.833.833 0 01-.827-.728l-.007-.105c0-.46.373-.833.834-.833H10v-5H4.167a.833.833 0 01-.827-.729l-.007-.104c0-.46.373-.834.834-.834H10v-2.5c0-.425.318-.775.729-.827l.104-.006c.46 0 .834.373.834.833l-.001 2.5h6.667v-2.5c0-.425.318-.775.73-.827l.104-.006c.46 0 .833.373.833.833v2.5h6.666v-2.5c0-.425.319-.775.73-.827L27.5 15zm-9.167 11.666h-6.667v5h6.667v-5zm8.333 0H20v5h6.666v-5zM18.333 20h-6.667v5h6.667v-5zm8.333 0H20v5h6.666v-5zm-20-15h-5v6.666h36.667V5h-5v2.5c0 .46-.373.833-.833.833h-5a.833.833 0 01-.833-.833L26.666 5H13.333v2.5c0 .46-.373.833-.833.833h-5a.833.833 0 01-.833-.833L6.666 5zm5-3.333H8.334v5h3.334v-5zm20 0h-3.333v5h3.334v-5z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#i0)" transform="translate(-312 -493)">
        <g
          clipPath="url(#i1)"
          transform="translate(260 393) translate(32 80) translate(16 16) translate(4 4) translate(1 4)"
        >
          <path
            fill="#7FE3FF"
            d="M0 0L38 0 38 8.00033333 0 8.00033333 0 0z"
          ></path>
        </g>
        <g
          clipPath="url(#i2)"
          transform="translate(260 393) translate(32 80) translate(16 16) translate(4 4)"
        >
          <path fill="#032238" d="M0 0L40 0 40 40 0 40 0 0z"></path>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(Icon);
