export const isValidEmail = (email) => {
  if (!email) {
    return false;
  }
  const unitRegex =
    /^(([^<>/().,;:\s@"]+(\.[^<>/().,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return unitRegex.test(email);
};

export const validEmail = (value) => {
  if (value) {
    const trimedVal = value.trim();
    const hasSpace = /\s/g.test(trimedVal);
    if (!isValidEmail(trimedVal) || hasSpace) {
      return 'Email is not valid';
    }
  }
  return undefined;
};

export default validEmail;
