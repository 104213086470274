import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ChevronDown from '../../icons/streamline/ChevronDown';
import Card from '../Card';
import { H5 } from '../Typography';

import styles from './ExpandableCard.module.scss';

const SIDES = {
  top: '0deg',
  right: '90deg',
  bottom: '180deg',
  left: '270deg',
};

const ExpandableCard = React.forwardRef(
  (
    {
      className,
      contentClassName,
      headerClassName,
      title,
      children,
      icon: Icon,
      iconProps: { className: iconClassName, ...otherIconProps },
      sideOpen,
      sideClosed,
      onSetExpanded,
      defaultExpanded,
      customAnimation,
      ...otherProps
    },
    ref,
  ) => {
    const [expanded, setExpanded] = useState(defaultExpanded);
    const side = expanded ? sideOpen : sideClosed;

    return (
      <Card
        ref={ref}
        className={cx(styles.container, className, {
          [styles.expanded]: expanded,
          [styles.customAnimation]: customAnimation,
        })}
        {...otherProps}
      >
        <div
          className={cx(styles.header, headerClassName)}
          onClick={() => {
            onSetExpanded(!expanded);
            setExpanded(!expanded);
          }}
        >
          <H5>{title}</H5>
          <Icon
            className={cx(styles.icon, iconClassName)}
            style={{
              transform: `rotateZ(${SIDES[side]})`,
            }}
            {...otherIconProps}
          />
        </div>
        <div className={cx(styles.content, contentClassName)}>{children}</div>
      </Card>
    );
  },
);

ExpandableCard.displayName = 'ExpandableCard';

ExpandableCard.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
  sideOpen: PropTypes.string,
  sideClosed: PropTypes.string,
  children: PropTypes.node,
  onSetExpanded: PropTypes.func,
  defaultExpanded: PropTypes.bool,
  iconProps: PropTypes.object,
  customAnimation: PropTypes.bool,
};

ExpandableCard.defaultProps = {
  icon: ChevronDown,
  sideOpen: 'top',
  sideClosed: 'bottom',
  iconProps: {},
  onSetExpanded: () => null,
  defaultExpanded: false,
};

export default ExpandableCard;
