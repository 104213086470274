import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const ParkingThick = (props) => (
  <svg {...props}>
    <path
      d="M10.681 11.551a.816.816 0 0 1 .11 1.625l-.11.008H6.389l-1.524 3.81h15.121a.814.814 0 0 1 .578.238l2.176 2.177c.154.153.24.36.24.577v4.354c0 .451-.366.816-.817.816h-.272v1.361c0 .416-.311.76-.714.81l-.102.006H17.81a.816.816 0 0 1-.817-.816v-1.361H6.653v1.361c0 .416-.311.76-.714.81l-.102.006H2.57a.816.816 0 0 1-.816-.816v-1.361h-.272a.816.816 0 0 1-.81-.713l-.006-.103v-4.354c0-.216.086-.424.239-.577l2.057-2.058 2.115-5.287a.816.816 0 0 1 .649-.506l.11-.007h4.844Zm-5.66 13.605H3.387v.545H5.02v-.545Zm15.238 0h-1.633v.545h1.633v-.545Zm-.611-6.53H3.998l-1.699 1.698v1.023H6.38a.816.816 0 0 1 .11 1.625l-.11.008-4.082-.001v.545h19.048v-.545h-4.082a.816.816 0 0 1-.11-1.625l.11-.007h4.082v-1.022l-1.7-1.7ZM19.442.666a7.89 7.89 0 1 1 0 15.783 7.89 7.89 0 1 1 0-15.782Zm0 1.633a6.258 6.258 0 1 0 0 12.517 6.258 6.258 0 0 0 0-12.517Zm.544 1.633a2.994 2.994 0 0 1 .183 5.98l-.183.006h-1.36v2.45a.816.816 0 0 1-.706.808l-.11.008a.816.816 0 0 1-.81-.706l-.007-.11v-7.62c0-.413.307-.755.706-.809l.11-.007h2.177Zm0 1.633h-1.36v2.72h1.36a1.361 1.361 0 0 0 .14-2.713l-.14-.007Z"
      fill="#042238"
      fillRule="evenodd"
    />
  </svg>
);

ParkingThick.defaultProps = {
  width: 27,
  height: 27,
  color: colors.text,
};

ParkingThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ParkingThick);
