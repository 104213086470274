import React, { useContext, useEffect, useState } from 'react';

import { TTfetch } from '../core/TTfetch';
import { useConfig } from '../providers/ConfigProvider';

const StatusEndpointContext = React.createContext({
  globalFeatures: [],
  loading: true,
  error: false,
  // other fields related to status endpoint
});

export const StatusEndpointProvider = ({ children }) => {
  const [globalFeatures, setGlobalFeatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { API_URL } = useConfig();

  useEffect(() => {
    TTfetch(`${API_URL}/public/status`.replace(/\//g, '/'))
      .then((response) => response.json())
      .then((data) => {
        setGlobalFeatures(data.globalFeatures);
        setLoading(false);
        if (data.status !== 'OK') {
          // Not being used now, but could be useful for maintenance mode
          setError(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  return (
    <StatusEndpointContext.Provider
      value={{
        globalFeatures,
        loading,
        error,
      }}
    >
      <>{children}</>
    </StatusEndpointContext.Provider>
  );
};

export const useStatusEndpoint = () => {
  const { globalFeatures, loading, error } = useContext(StatusEndpointContext);
  return {
    globalFeatures,
    loading,
    error,
  };
};
