import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import dismissBannerMutationQGL from '../../../../../graphql/mutations/global_banners/dismissBanner.graphql';
import bannerGraphlQuery from '../../../../../graphql/queries/globalBanner.graphql';
import CloseIcon from '../../../../../icons/streamline/Close';
import FlatButton from '../../../../FlatButton';

import styles from './DismissAction.module.scss';

const DismissAction = ({ bannerId }) => {
  const [mutate, { loading }] = useMutation(dismissBannerMutationQGL);

  const onDismiss = useCallback(async () => {
    await mutate({
      variables: { id: bannerId },
      refetchQueries: [
        {
          query: bannerGraphlQuery,
        },
      ],
      awaitRefetchQueries: true,
    });
  }, [bannerId]);

  return (
    <div className={styles.container}>
      <FlatButton onClick={onDismiss} loading={loading}>
        <CloseIcon color="white" />
      </FlatButton>
    </div>
  );
};

DismissAction.propTypes = {
  bannerId: PropTypes.string.isRequired,
};

export default DismissAction;
