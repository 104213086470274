export const isNumberLengthLessThanOrEqualTo =
  (max, splitBy = '.') =>
  (val) => {
    if (!val) {
      return;
    }

    const [value] = val.toString().split(splitBy);

    if (value && `${value}`.length > max) {
      return `Must be less than or equal to ${max} characters`;
    }

    return undefined;
  };

export default isNumberLengthLessThanOrEqualTo;
