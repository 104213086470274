import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ThumbsUp = (props) => (
  <svg {...props} viewBox="0 0 136 136" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle cx="68" cy="68" r="68" fill="#C9EDFF" />
      <g transform="translate(25.5 17)">
        <ellipse
          fill="#D6DAFF"
          fillRule="nonzero"
          cx="42.024"
          cy="100.776"
          rx="23.8"
          ry="2.55"
        />
        <path
          d="M54.043 48.365c.85-8.5 1.972-26.809-5.1-26.809a5.1 5.1 0 00-5.1 5.1v1.7c0 12.546-5.44 25.67-15.98 25.67h-3.4v26.282h4.76c6.579 0 9.69 4.573 21.471 4.573h19.89a4.573 4.573 0 000-9.129h2.278a4.573 4.573 0 000-9.129h2.278a4.573 4.573 0 100-9.129h2.278a4.573 4.573 0 100-9.129H54.043"
          stroke="#033A6D"
          strokeWidth="1.771"
          fill="#FFF"
          fillRule="nonzero"
          strokeLinejoin="round"
        />
        <path
          stroke="#033A6D"
          strokeWidth="1.771"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M49.47 48.365h6.851"
        />
        <path
          d="M54.043 48.365c.85-8.5 1.972-26.809-5.1-26.809a5.1 5.1 0 00-5.1 5.1v1.7c0 12.546-5.44 25.67-15.98 25.67h-3.4v26.282h4.76c6.579 0 9.69 4.573 21.471 4.573h19.89a4.573 4.573 0 000-9.129h2.278a4.573 4.573 0 000-9.129h2.278a4.573 4.573 0 100-9.129h2.278a4.573 4.573 0 100-9.129H54.043"
          stroke="#033A6D"
          strokeWidth="1.771"
          strokeLinejoin="round"
        />
        <path
          stroke="#033A6D"
          strokeWidth="1.771"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M70.584 75.752h-2.278M72.862 66.623h-2.278M75.14 57.494h-2.278"
        />
        <rect
          stroke="#033A6D"
          strokeWidth="1.771"
          fill="#D6DAFF"
          fillRule="nonzero"
          strokeLinejoin="round"
          x="4.981"
          y="49.504"
          width="15.963"
          height="35.36"
          rx="2.278"
        />
        <rect
          stroke="#033A6D"
          strokeWidth="1.771"
          strokeLinejoin="round"
          x="4.981"
          y="49.504"
          width="15.963"
          height="35.36"
          rx="2.278"
        />
        <path
          d="M25.517 82.586h-4.556V51.799h4.556a2.278 2.278 0 012.278 2.278v26.231a2.278 2.278 0 01-2.278 2.278h0zM78.196 24.794a3.617 3.617 0 012.714-2.711l5.443-1.25a.467.467 0 00.347-.452.437.437 0 00-.347-.437l-5.443-1.25a3.632 3.632 0 01-2.714-2.71l-1.251-5.437a.453.453 0 00-.453-.347.468.468 0 00-.437.347l-1.251 5.436a3.632 3.632 0 01-2.714 2.711l-5.443 1.25a.452.452 0 00-.347.437c.005.21.145.393.347.452l5.443 1.25a3.586 3.586 0 012.714 2.71l1.251 5.437a.452.452 0 00.89 0l1.251-5.436zM10.908 29.608l5.728-1.8A.546.546 0 0017 27.3a.526.526 0 00-.364-.506l-4.695-1.578a2.024 2.024 0 01-1.356-1.356l-1.478-4.797a.526.526 0 00-.506-.364.546.546 0 00-.506.364L6.436 23.76a2.145 2.145 0 01-1.376 1.355L.365 26.694A.506.506 0 000 27.2c.004.228.15.43.365.506l4.695 1.558c.647.22 1.155.73 1.376 1.376l1.558 4.696c.076.215.278.36.506.364a.526.526 0 00.506-.364l1.902-5.728z"
          stroke="#033A6D"
          strokeWidth="1.771"
          fill="#FFF"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.418 9.817l5.154-1.62a.492.492 0 00.328-.456.474.474 0 00-.328-.455l-4.225-1.421a1.821 1.821 0 01-1.22-1.22L38.796.328A.474.474 0 0038.34 0a.492.492 0 00-.455.328l-1.494 4.226a1.93 1.93 0 01-1.238 1.22l-4.226 1.42a.455.455 0 00-.328.456.492.492 0 00.328.455l4.226 1.403c.582.198 1.04.656 1.238 1.238l1.403 4.226c.068.194.25.324.455.328a.474.474 0 00.456-.328l1.712-5.155z"
          stroke="#033A6D"
          strokeWidth="1.7"
          fill="#FFF"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

ThumbsUp.defaultProps = {
  width: 136,
  height: 136,
};

ThumbsUp.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(ThumbsUp);
