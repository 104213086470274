import React, { useEffect, useRef } from 'react';
import { useMutation } from '@apollo/client';
import cx from 'classnames';
import get from 'lodash.get';
import ms from 'ms';
import PropTypes from 'prop-types';

import Card from '../../components/Card';
import { loginCometChatUser } from '../../components/CometChatWorkspace/utils';
import Footer from '../../components/Footer';
import FullPageError from '../../components/FullPageError';
import * as authService from '../../core/auth/authService';
import WarningIcon from '../../icons/Warning';

import checkDeviceQuery from './graphql/checkDevice.graphql';
import WelcomeHeader from './signup/WelcomeHeader';

import styles from './NotRecognizedDevice.module.scss';

const expireAfter = ms('30min');
const checkAgainAfter = ms('5s');
let currentTime = 0;

const NotRecognizedDevice = ({ location, history }) => {
  const routerState = get(location, 'state', {});
  const timeout = useRef(null);
  const { userEmail, trustedToken } = routerState;
  const [checkDevice] = useMutation(checkDeviceQuery);

  const checkDeviceData = async () => {
    clearTimeout(timeout.current);
    if (currentTime > expireAfter) {
      return;
    }
    try {
      const deviceResponse = await checkDevice({
        variables: { trustedToken },
      });
      const token = get(deviceResponse, 'data.checkDevice.token', null);
      const messagingToken = get(
        deviceResponse,
        'data.checkDevice.messagingToken',
        null,
      );
      if (messagingToken) {
        loginCometChatUser(messagingToken);
      }
      if (token) {
        const decodedToken = authService.decodeToken(trustedToken);

        authService.login(token);
        if (
          decodedToken?.data.accountsCount &&
          decodedToken.data.accountsCount > 1
        ) {
          history.replace('/auth/select-account');
        } else {
          history.replace('/');
        }
        return clearTimeout(timeout.current);
      }
    } catch (e) {
      console.log(e, 'Issue while Checking Device');
    }
    currentTime = currentTime + checkAgainAfter;
    timeout.current = setTimeout(checkDeviceData, checkAgainAfter);
  };

  useEffect(() => {
    if (!userEmail || !trustedToken) {
      return;
    }
    checkDeviceData();
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  if (!userEmail || !trustedToken) {
    return <FullPageError />;
  }

  return (
    <div className={cx(styles.container)}>
      <WelcomeHeader />
      <div className={cx(styles.content)}>
        <Card className={cx(styles.infoCard)}>
          <WarningIcon className={cx(styles.icon)} />
          <p className={cx(styles.title)}>
            Device Not Recognized. Please Check Your Email.
          </p>
          <p className={cx(styles.description)}>
            {`For security purposes, please check your email (${userEmail}) for a
              link to confirm this device and log in.`}
          </p>
        </Card>
      </div>
      <Footer className={cx(styles.footer)} />
    </div>
  );
};

NotRecognizedDevice.propTypes = {
  location: PropTypes.object.isRequired,
};

export default NotRecognizedDevice;
