import { stringify } from 'query-string';

import { useUserProfile } from '../core/TTgraphql';

const useArmadilloLink = () => {
  const { user } = useUserProfile({}, false);
  const { first_name, last_name, email, telephone, company } = user || {};
  const params = {
    ...(first_name ? { firstname: first_name } : {}),
    ...(last_name ? { lastname: last_name } : {}),
    ...(telephone ? { phone: telephone } : {}),
    ...(email ? { email } : {}),
    ...(company ? { company } : {}),
  };
  return `https://www.armadillo.one/plans/landlords-turbotenant/?${stringify(
    params,
  )}`;
};

export default useArmadilloLink;
