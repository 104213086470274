import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { CometChat } from '@cometchat-pro/chat';

import { imgStyle } from './style';

import ProfilePicture from '../../../../../ProfilePicture';

const CometChatAvatar = (props) => {
  const getAvatarProps = () => {
    if (props.image.trim().length) {
      return {
        profilePictureURL: props.image,
      };
    } else if (props.user) {
      if (props.user?.avatar) {
        return {
          profilePictureURL: props.user.avatar,
        };
      } else {
        const fullName = (props?.user?.name || '').split(' ');
        let firstName = '';
        let lastName = '';

        if (fullName.length >= 2) {
          firstName = fullName[0];
          lastName = fullName[1];
        } else {
          firstName = fullName[0];
        }

        return {
          firstName,
          lastName,
        };
      }
    } else if (props.group) {
      if (props.group?.icon) {
        return {
          profilePictureURL: props.group.icon,
        };
      } else {
        const groupName = props.group.name;

        return {
          firstName: groupName,
        };
      }
    }
  };

  const avatarProps = getAvatarProps();

  return <ProfilePicture {...avatarProps} css={imgStyle()} />;
};

// Specifies the default values for props:
CometChatAvatar.defaultProps = {
  image: '',
  user: {},
  group: {},
};

CometChatAvatar.propTypes = {
  image: PropTypes.string,
  user: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape(CometChat.User),
  ]),
  group: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape(CometChat.Group),
  ]),
};

export { CometChatAvatar };
