import React from 'react';

import DollarCircleBlue from '../../../../icons/DollarCircleBlue';

import {
  IncomeInsightsButton,
  IncomeInsightsContent,
  IncomeInsightsDescription,
  IncomeInsightsIcon,
  IncomeInsightsTitle,
} from './Elements';

import styles from './DefaultCard.module.scss';

const DefaultCard = ({ onOpenModal }) => (
  <>
    <IncomeInsightsIcon>
      <DollarCircleBlue width={64} height={64} />
    </IncomeInsightsIcon>
    <IncomeInsightsContent>
      <IncomeInsightsTitle>
        See <i>Income Insights</i>
      </IncomeInsightsTitle>
      <IncomeInsightsDescription>
        Check the accuracy of their self-reported income with TransUnion’s{' '}
        <i>Income Insights</i> which uses historical income data collected by
        independent third parties.
      </IncomeInsightsDescription>
    </IncomeInsightsContent>
    <div className={styles.actionContainer}>
      <IncomeInsightsButton
        onClick={() => onOpenModal()}
        label="View Insights"
      />
    </div>
  </>
);

export default DefaultCard;
