import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import LoadingScreen from '../../../../components/LoadingScreen';
import HeaderSectionComponent from '../../../properties/manage_sections/HeaderSectionComponent';

import UploadAttachmentComponent from './UploadAttachmentComponent';

const ManageAttachments = ({
  partnersApplication,
  maxFileSize,
  onSave,
  application,
  id,
  title,
  loading,
}) => {
  const propsAttachments = (application && application.attachments) || [];
  const [editMode, setEditMode] = useState(false);
  const [saved, setSaved] = useState(false);
  const [originalAttachments, setOriginalAttachments] =
    useState(propsAttachments);
  const [attachments, setAttachments] = useState(propsAttachments);

  useEffect(() => {
    setOriginalAttachments(propsAttachments);
    setAttachments(propsAttachments);
  }, [propsAttachments]);

  const onAttachmentsChange = (attachments) => {
    setAttachments(attachments);
  };

  const handleSave = () => {
    const id = application.id;

    onSave({
      id,
      attachments: attachments.map(({ id, content }) => ({ id, content })),
    }).then(() => {
      setEditMode(false);
      setOriginalAttachments(attachments);
    });
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    setSaved(false);
    // TODO all this can be modernized even further, but we're going to change all this soon
    setAttachments(originalAttachments);
  };

  return (
    <div style={{ paddingTop: 24 }}>
      <LoadingScreen loading={loading && editMode}>
        <HeaderSectionComponent
          saved={saved}
          title={title}
          editMode={editMode}
          onSaveClick={handleSave}
          onEditClick={handleEdit}
          onCancelClick={handleCancel}
          partnersApplication={partnersApplication}
        >
          <article id={id} style={{ marginTop: 12, marginBottom: 24 }}>
            <UploadAttachmentComponent
              attachments={attachments}
              onChange={onAttachmentsChange}
              disableEdit={!editMode}
              maxFileSize={maxFileSize}
            />
          </article>
        </HeaderSectionComponent>
      </LoadingScreen>
    </div>
  );
};

ManageAttachments.propTypes = {
  loading: PropTypes.bool,
  onSave: PropTypes.func,
  application: PropTypes.object,
  id: PropTypes.string,
  title: PropTypes.string,
  maxFileSize: PropTypes.number,
  partnersApplication: PropTypes.bool,
};

ManageAttachments.defaultProps = {
  onSave: Function.prototype,
};

export default ManageAttachments;
