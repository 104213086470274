import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';
import Card from '../../../../../components/Card';
import PartyPopperIcon from '../../../../../icons/PartyPopper';

import styles from './ApplicantSetPasswordSuccess.module.scss';

const ApplicantSetPasswordSuccess = ({
  title,
  description,
  btnLabel,
  onGetStarted,
}) => {
  return (
    <Card className={styles.container}>
      <PartyPopperIcon className={styles.icon} height={84} width={84} />
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.text}>{description}</p>
      <Button id="done_button" className={styles.btn} onClick={onGetStarted}>
        {btnLabel}
      </Button>
    </Card>
  );
};

ApplicantSetPasswordSuccess.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  btnLabel: PropTypes.string.isRequired,
  onGetStarted: PropTypes.func.isRequired,
};

export default ApplicantSetPasswordSuccess;
