import React, { useCallback, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import Modal from '../../../components/Modal';
// import reihubTTLogo from '../../../assets/reihub/reihub-tt-logos.svg';
import { useErrorToast } from '../../../components/Toast';
import academyUrlQuery from '../../../graphql/queries/partner_sso/academySSO.graphql';
import forumUrlQuery from '../../../graphql/queries/partner_sso/forumSSO.graphql';
import { getCookies } from '../../../helpers/cookies';
import SpiningLoader from '../../../icons/SpiningLoader';

import styles from './LoadSSOServiceModal.module.scss';

const LoadSSOServiceModal = ({ open, ssoService }) => {
  const text =
    ssoService === 'forum' ? 'the BeBetter Landlord Forum' : 'academy';
  return (
    <Modal open={open} className={styles.modal}>
      <div className={styles.header}>
        {/* <img src={reihubTTLogo} alt={'academy-turbotenant-logos'} /> */}
      </div>
      <SpiningLoader style={{ marginBottom: '16px', width: 50, height: 50 }} />
      <h3 className={styles.modalTitle}>
        Going to <span className={styles.modalTitleHighlight}>{text}</span>
      </h3>
    </Modal>
  );
};

export const useOpenAcademy = (redirectUrl, inputReferral) => {
  const [isOpen, setIsOpen] = useState(false);
  const showErrorMessage = useErrorToast();

  const { academy_referral } = getCookies();

  const redirectPath = ((redirectUrl || '').match(/https?:\/\/[^/]+(.+)/) ||
    [])[1];

  const openUrl = useCallback(
    (url, newTab = false) => {
      const newWindow = newTab ? window.open(url, '_blank') : null;
      if (!newWindow) {
        // A popup blocker prevented the window from opening
        window.location.href = url;
      }
      if (newTab) {
        setIsOpen(false);
        setTimeout(() => document.activeElement.blur(), 1);
      }
    },
    [isOpen, setIsOpen],
  );
  const referral = inputReferral || academy_referral;

  const [getSSOUrl] = useLazyQuery(academyUrlQuery, {
    variables: {
      redirectPath: redirectPath || '',
      ...(referral || (referral && { referral })),
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const academyUrl = data?.academy?.academy_sso;
      if (academyUrl) openUrl(academyUrl);
      else {
        window.location.href = 'https://rental.turbotenant.com';
      }
    },
    onError: (e) => {
      showErrorMessage(e.message);
      setIsOpen(false);
    },
  });

  const openAcademy = useCallback(() => {
    setIsOpen(true);

    const timeout = setTimeout(() => {
      getSSOUrl();
      clearTimeout(timeout);
    }, 2000);
  });

  return [isOpen, openAcademy];
};

export const useOpenSSOService = (
  ssoService = 'academy',
  redirectUrl,
  inputReferral,
) => {
  const gqlQuery = ssoService === 'academy' ? academyUrlQuery : forumUrlQuery;
  const [isOpen, setIsOpen] = useState(false);
  const showErrorMessage = useErrorToast();

  const { academy_referral } = getCookies();
  const referral =
    ssoService === 'academy' ? inputReferral || academy_referral : null;

  const redirectPath =
    ssoService === 'academy'
      ? ((redirectUrl || '').match(/https?:\/\/[^/]+(.+)/) || [])[1]
      : redirectUrl;

  const openUrl = useCallback(
    (url, newTab = false) => {
      const newWindow = newTab ? window.open(url, '_blank') : null;
      if (!newWindow) {
        // A popup blocker prevented the window from opening
        window.location.href = url;
      }
      if (newTab) {
        setIsOpen(false);
        setTimeout(() => document.activeElement.blur(), 1);
      }
    },
    [isOpen, setIsOpen],
  );

  const [getSSOUrl] = useLazyQuery(gqlQuery, {
    variables: {
      redirectPath: redirectPath || '',
      ...(referral ? { referral } : {}),
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      let destinationUrl;
      if (ssoService === 'academy') {
        destinationUrl = data?.academy?.academy_sso;
      } else {
        destinationUrl = data?.forum?.forum_sso;
      }
      if (destinationUrl) openUrl(destinationUrl);
      else {
        window.location.href = 'https://rental.turbotenant.com';
      }
    },
    onError: (e) => {
      showErrorMessage(e.message);
      setIsOpen(false);
    },
  });

  const openAcademy = useCallback(() => {
    setIsOpen(true);

    const timeout = setTimeout(() => {
      getSSOUrl();
      clearTimeout(timeout);
    }, 2000);
  });

  return [isOpen, openAcademy];
};

export default LoadSSOServiceModal;
