import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import StatusTag from '../../../../components/Maintenance/MaintenanceTable/StatusTag';
import StatusSelector from '../../../../components/Maintenance/StatusSelector';
import { Tooltip } from '../../../../components/Tooltip';
import { LulaStatusesEnum } from '../../../../constants/enums/MaintenanceRequestStatusEnum';
import getListingBreadcrumbName from '../../../../helpers/getListingBreadcrumbName';
import Download from '../../../../icons/Download';
import MaintenanceAltThick from '../../../../icons/MaintenanceAltThick';
import StarEmpty from '../../../../icons/StarEmpty';
import StarFilled from '../../../../icons/StarFilled';
import { getConfig } from '../../../../services/configService';
import { segmentTracking } from '../../../../services/utilities';

import styles from './MaintenanceRequestHeader.module.scss';

const MaintenanceRequestHeader = ({
  className,
  title,
  listing,
  status,
  starred,
  onStatusChange,
  lastStatusUpdatedManually,
  toggleStarred,
  sentToPartner,
  userToken,
  maintenanceRequestId,
  isOwner = false,
}) => {
  const { API_URL } = getConfig();
  const StarComp = starred ? StarFilled : StarEmpty;

  function shouldShowStatusSelector() {
    return (
      !sentToPartner ||
      (sentToPartner && !Object.values(LulaStatusesEnum).includes(status))
    );
  }
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.headLeft}>
        <div className={styles.imgHead}>
          <MaintenanceAltThick width={40} height={40} />
        </div>

        <div className={styles.titleHolder}>
          <div className={styles.titleAndButton}>
            <h3>{title}</h3>
            {!sentToPartner && (
              <a
                href={`${API_URL}/public/owners/maintenance-request/${maintenanceRequestId}?token=${userToken}&action=download`}
                onClick={() =>
                  segmentTracking('download_actions clicked', {
                    location: 'Maintenance Request',
                  })
                }
                className={styles.download}
                target="_blank"
                rel="noopener noreferrer"
                data-qa="download-maintenance-request"
              >
                <Download />
              </a>
            )}
          </div>
          <p>{getListingBreadcrumbName({ listing })}</p>
        </div>
      </div>
      {isOwner ? (
        <div className={styles.buttonsHolder}>
          {shouldShowStatusSelector() ? (
            <StatusSelector
              status={status}
              onStatusChange={onStatusChange}
              className={styles.statusDropdown}
              sentToPartner={sentToPartner}
              lastStatusUpdatedManually={lastStatusUpdatedManually}
            />
          ) : (
            <StatusTag
              className={styles.tag}
              type={status}
              sentToPartner={sentToPartner}
              lastStatusUpdatedManually={lastStatusUpdatedManually}
            />
          )}

          <StarComp
            className={starred ? styles.star : styles.starInactive}
            onClick={(e) => {
              e.stopPropagation();
              toggleStarred();
            }}
          />
        </div>
      ) : (
        <div className={styles.tagContainer}>
          <div data-tooltip-id="lease-term-info">
            <StatusTag className={styles.tag} type={status} />
          </div>
          <Tooltip id="lease-term-info">
            Status is updated by {sentToPartner ? 'Lula' : 'your landlord'}.
          </Tooltip>
        </div>
      )}
    </div>
  );
};

MaintenanceRequestHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  listing: PropTypes.object,
  status: PropTypes.string,
  lastStatusUpdatedManually: PropTypes.bool,
  starred: PropTypes.bool,
  onStatusChange: PropTypes.func,
  toggleStarred: PropTypes.func,
  userToken: PropTypes.string,
  maintenanceRequestId: PropTypes.string,
  isOwner: PropTypes.bool,
  sentToPartner: PropTypes.bool,
};

export default MaintenanceRequestHeader;
