import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';
import SectionTitleWithIconAndAction from '../../../../../components/SectionTitleWithIconAndAction';
import { Paragraph } from '../../../../../components/Typography';
import { hasApplicationInfoBeenFilled } from '../../../../../helpers/hasApplicationInfoBeenFilled';
import Applicant from '../../../../../icons/Applicant';

import ApplicationContent from './ApplicationContent';

import styles from './ApplicationSummary.module.scss';

const ApplicationSummary = ({
  application,
  showInfoButton,
  partnersApplication,
  noBorder,
}) => {
  const history = useHistory();
  const location = useLocation();
  const isApplicant = useRouteMatch('/renters/application/:applicationId');
  const isApplicationFilled = hasApplicationInfoBeenFilled(application);

  const onAddApplicationInfoClicked = () => {
    const renterId = application?.renter?.id;
    return history.push(
      `/owners/applicant-profile/${renterId}/tab_application?${location.search}`,
    );
  };

  return (
    <div className={cx(styles.container, { [styles.noBorder]: noBorder })}>
      {!isApplicationFilled ? (
        <div className={styles.genericInfo}>
          <SectionTitleWithIconAndAction
            className={styles.sectionTitle}
            title={'Application'}
            icon={Applicant}
          />
          <div className={styles.content}>
            <Paragraph>
              {isApplicant
                ? "You did not apply online through TurboTenant for this property. If you'd like to enter your application information do so here."
                : 'You manually requested a screening report for this applicant. They did not submit an online application through TurboTenant. '}
            </Paragraph>
            {showInfoButton && (
              <Button secondary onClick={onAddApplicationInfoClicked}>
                Add Application Info
              </Button>
            )}
          </div>
        </div>
      ) : (
        <>
          {!noBorder && (
            <SectionTitleWithIconAndAction
              className={styles.sectionTitle}
              title="Application Overview"
              icon={Applicant}
            />
          )}

          <ApplicationContent
            application={application}
            partnersApplication={partnersApplication}
          />
        </>
      )}
    </div>
  );
};

ApplicationSummary.propTypes = {
  application: PropTypes.object,
  showInfoButton: PropTypes.bool,
  partnersApplication: PropTypes.bool,
  noBorder: PropTypes.bool,
};

export default ApplicationSummary;
