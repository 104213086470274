import React, { memo } from 'react';
import PropTypes from 'prop-types';

const GoogleLogo = (props) => (
  <svg {...props} fill="none">
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        fill="#4285F4"
        d="M23.76 12.273c0-.851-.076-1.67-.218-2.455H12.24v4.642h6.458a5.52 5.52 0 0 1-2.394 3.622v3.01h3.878c2.269-2.088 3.578-5.165 3.578-8.82Z"
      />
      <path
        fill="#34A853"
        d="M12.24 24c3.24 0 5.956-1.075 7.942-2.907l-3.878-3.011c-1.075.72-2.45 1.145-4.064 1.145-3.125 0-5.77-2.11-6.715-4.947H1.516v3.11A11.995 11.995 0 0 0 12.24 24Z"
      />
      <path
        fill="#FBBC05"
        d="M5.525 14.28A7.213 7.213 0 0 1 5.15 12c0-.79.136-1.56.376-2.28V6.611H1.516a11.995 11.995 0 0 0-1.276 5.39c0 1.935.464 3.768 1.276 5.388l4.01-3.109Z"
      />
      <path
        fill="#EA4335"
        d="M12.24 4.773c1.762 0 3.344.605 4.587 1.794l3.442-3.442C18.191 1.19 15.475 0 12.24 0 7.55 0 3.49 2.69 1.516 6.61l4.01 3.11c.943-2.836 3.589-4.947 6.714-4.947Z"
      />
    </g>
  </svg>
);

GoogleLogo.defaultProps = {
  width: 24,
  height: 24,
};

GoogleLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(GoogleLogo);
