import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const UsersAccountFind2 = (props) => (
  <svg {...props} viewBox="0 0 24 24">
    <g
      stroke={props.color}
      strokeWidth={props.strokeWidth || 1.25}
      fillOpacity="0"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    >
      <circle strokeLinecap="round" cx="9.5" cy="9.5" r="9" />
      <path strokeLinecap="round" d="M15.863 15.864l7.637 7.636" />
      <path d="M13.998 15.093c-.804-.383-1.678-.765-2.498-1.093v-2s1-.35 1-2.5c.695 0 1-2 .032-2 0-.211.766-1.308.468-2.5-.5-2-5.5-2-6 0-2.105-.43-.5 2.212-.5 2.5-1 0-.695 2 0 2 0 2.15 1 2.5 1 2.5v2c-.821.328-1.695.71-2.5 1.094" />
    </g>
  </svg>
);

UsersAccountFind2.defaultProps = {
  width: 56,
  height: 56,
  color: colors.black,
};

UsersAccountFind2.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(UsersAccountFind2);
