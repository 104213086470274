import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const InformationCircle = ({ color, viewBox, strokeWidth, ...rest }) => (
  <svg {...rest} viewBox={viewBox || '0 0 24 24'}>
    <g>
      <circle
        stroke={color}
        strokeWidth={strokeWidth}
        fillOpacity="0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        cx="11.5"
        cy="12.5"
        r="11"
      />
      <path
        stroke={color}
        strokeWidth={strokeWidth}
        fillOpacity="0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M8.5 19.5h6m-5-9h2V19M11 6a.5.5 0 100 1 .5.5 0 000-1z"
      />
    </g>
  </svg>
);

InformationCircle.defaultProps = {
  width: 16,
  height: 16,
  color: colors.primary,
  strokeWidth: 1.25,
};

InformationCircle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

export default memo(InformationCircle);
