import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import {
  profileColorTextPair,
  profilePictureColors,
} from '../../constants/colors';
import Portfolio from '../../icons/streamline/Portfolio';

import styles from './ProfilePicture.module.scss';

const calculateColor = (email) => {
  let sum = 0;
  for (const index in email) {
    sum += email.charCodeAt(index);
  }
  return sum % profilePictureColors.length;
};

const ProfilePicture = ({
  firstName,
  lastName,
  profilePictureURL,
  size,
  className,
  isMUA,
  squircle,
}) => {
  const backgroundColor =
    profilePictureColors[calculateColor(`${firstName} ${lastName}`)];
  const textColor = profileColorTextPair[backgroundColor];

  if (profilePictureURL)
    return (
      <img
        className={cx(
          styles.circle,
          {
            [styles.large]: size === 'large',
            [styles.medium]: size === 'medium',
            [styles.small]: size === 'small',
            [styles.xsmall]: size === 'xsmall',
            [styles.squircle]: squircle,
          },
          className,
        )}
        alt="Profile logo"
        src={profilePictureURL}
      />
    );

  return (
    <div
      className={cx(
        styles.circle,
        {
          [styles.large]: size === 'large',
          [styles.medium]: size === 'medium',
          [styles.small]: size === 'small',
          [styles.xsmall]: size === 'xsmall',
          [styles.squircle]: squircle,
        },
        className,
      )}
      style={{
        backgroundColor,
        color: textColor,
      }}
    >
      {isMUA ? (
        <Portfolio />
      ) : (
        <>
          {firstName ? firstName[0] : ''}
          {lastName ? lastName[0] : ''}
        </>
      )}
    </div>
  );
};

ProfilePicture.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  profilePictureURL: PropTypes.string,
  className: PropTypes.string,
  isMUA: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'medium', 'small', 'xsmall']),
  squircle: PropTypes.bool,
};

ProfilePicture.defaultProps = {
  profilePictureURL: '',
  size: 'medium',
};

export default ProfilePicture;
