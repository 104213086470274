import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { SUB_DIVISION_NAMES_KEYS } from '../../../../constants/lease_agreement/sub-division-names';
import DollarCircledThickIcon from '../../../../icons/DollarCircledThick';
import SectionTitle from '../../common/SectionTitle';

import NonRefundableFeeLawDC from './state_specific/DC/NonRefundableFeeLawDC';
import NonRefundableFeesMD from './state_specific/MD/NonRefundableFeesMD';

import styles from './SectionRentDepositFee.module.scss';

export const SectionRentDepositFeeOneTimeFeesSection = ({
  currentState,
  children,
  leaseAgreementSelection,
}) => {
  return (
    <div
      className={cx(styles.column, styles.section, {
        [styles.smallMargin]: currentState === 'AZ',
      })}
    >
      <SectionTitle title="One Time Fees" icon={DollarCircledThickIcon} />
      <label className={styles.secondaryLabel}>
        Do you charge any one time, nonrefundable fees due at move-in?
      </label>
      <p className={cx(styles.description, styles.proratedRentDescription)}>
        These do not include your deposit, since that is refundable. Examples
        include administrative fees or when the last month's rent is also due at
        move-in.
      </p>
      {leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.montgomery && (
        <NonRefundableFeesMD />
      )}
      {currentState === 'DC' && <NonRefundableFeeLawDC />}
      {children}
    </div>
  );
};

SectionRentDepositFeeOneTimeFeesSection.propTypes = {
  currentState: PropTypes.string,
  children: PropTypes.node,
  leaseAgreementSelection: PropTypes.node,
};
