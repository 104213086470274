import ApartmentColor from '../icons/ApartmentColor';
import CondominiumColor from '../icons/CondominiumColor';
import CoOp from '../icons/CoOp';
import KeyColor from '../icons/KeyColor';
import ListingHouseColor from '../icons/ListingHouseColor';
import Manufactured from '../icons/Manufactured';
import MultiFamilyColor from '../icons/MultiFamilyColor';
import TownhouseColor from '../icons/TownhouseColor';
import VacationLand from '../icons/VacationLand';

const getPropertyTypeIcon = (propertyType) => {
  switch (propertyType) {
    case 'SINGLE_FAMILY':
      return ListingHouseColor;
    case 'APARTMENT':
      return ApartmentColor;
    case 'CONDO':
      return CondominiumColor;
    case 'MULTI_FAMILY':
      return MultiFamilyColor;
    case 'MANUFACTURED':
      return Manufactured;
    case 'VACANT_LAND':
      return VacationLand;
    case 'CO_OP':
      return CoOp;
    case 'TOWNHOUSE':
      return TownhouseColor;
    case 'OTHER':
      return KeyColor;
    default:
      return KeyColor;
  }
};

export default getPropertyTypeIcon;
