import moment from 'moment';

import { getConfig } from '../services/configService';

import { getRenterBillingCard } from './getRenterBillingCard';

/**
 * Checks whether or not an user has an active card on file.
 * This means that has a payment method saved and it expires in 1 month
 * if available it will return the card
 * @param {object} user
 * @returns active payment method if available
 */
export const getUserActiveCard = (user) => {
  const { IS_RENTER } = getConfig();

  if (!user || !user.payment_methods) return null;

  if (IS_RENTER) {
    return getRenterBillingCard(user);
  }

  const paymentMethod = user.payment_methods.find((paymentMethod) => {
    const creditCardDate = moment(paymentMethod.expiration, 'MM/YYYY');
    const today = moment();

    return (
      creditCardDate.isValid() &&
      today.isBefore(creditCardDate.add(1, 'months'))
    );
  });

  return paymentMethod || null;
};
