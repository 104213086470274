import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../../../../constants/colors';
import ExpandableCard from '../../../../ExpandableCard';
import { Link, Paragraph } from '../../../../Typography';
import Benefit from '../Benefit';

import styles from './BenefItemsMobile.module.scss';

const BenefitItemsMobile = ({
  benefits,
  selectedBenefit,
  extraParams,
  scrollToPremium,
  isProPlan = false,
  onClickSegment,
}) => {
  return benefits.map((benefit) => {
    const justPremiumBenefits = benefit.items.filter(
      (item) => item.plan === 'premium',
    );

    const benefitTitle = benefit.titleShort || benefit.title;

    return (
      <div className={styles.expandableCardWrapper} key={benefit.key}>
        <ExpandableCard
          title={benefitTitle}
          className={cx(styles.expandableCard, styles.bolder)}
          contentClassName={styles.expandableCardContent}
          headerClassName={cx(styles.expandableCardHeader, {
            [styles.premium]: !isProPlan,
          })}
          defaultExpanded={selectedBenefit === benefit.key}
          iconProps={
            !isProPlan ? { color: colors.white } : { color: colors.primary }
          }
          onClick={() => {
            onClickSegment(`${benefitTitle.toLowerCase()}_tab`);
          }}
        >
          {benefit.items.map((item) => {
            if (isProPlan && item.plan === 'premium') {
              return null;
            }

            return (
              <Benefit
                key={item.key}
                item={item}
                extraParams={extraParams}
                isProPlan={isProPlan}
              />
            );
          })}

          {isProPlan &&
            justPremiumBenefits.map((item, index) => (
              <div key={item.key}>
                {index === 0 && (
                  <Paragraph className={styles.onlyWithPremium}>
                    Only included with{' '}
                    <Link onClick={scrollToPremium}>Premium Plan</Link>:
                  </Paragraph>
                )}
                <div className={styles.disabled}>
                  <Benefit
                    item={item}
                    extraParams={extraParams}
                    isProPlan={isProPlan}
                  />
                </div>
              </div>
            ))}
        </ExpandableCard>
      </div>
    );
  });
};

BenefitItemsMobile.propTypes = {
  benefits: PropTypes.array.isRequired,
  selectedBenefit: PropTypes.string.isRequired,
  extraParams: PropTypes.object.isRequired,
  scrollToPremium: PropTypes.func.isRequired,
  isProPlan: PropTypes.bool,
};

export default BenefitItemsMobile;
