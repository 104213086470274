import { MODAL_LOCATIONS } from '../PremiumSubscription/constants';

export const VACANCY_LOCATIONS = [
  MODAL_LOCATIONS.APPLICATION_TAB,
  MODAL_LOCATIONS.PRESCREENER_TAB,
  MODAL_LOCATIONS.INVITE_TO_APPLY,
  MODAL_LOCATIONS.INVITE_TO_APPLY_ONBOARDING,
  MODAL_LOCATIONS.INCOME_INSIGHT_BANNER,
  MODAL_LOCATIONS.PUBLIC_SCREENING,
  MODAL_LOCATIONS.STANDALONE_SCREENING,
  MODAL_LOCATIONS.MARKETING,
  MODAL_LOCATIONS.USE_YOUR_OWN_APP,
  MODAL_LOCATIONS.MARKETING_CALL_FORWARDING,
  MODAL_LOCATIONS.LEADS_BULK_INVITE_TO_APPLY,
];

export const LEASE_AND_FORMS_LOCATIONS = [
  MODAL_LOCATIONS.E_SIGN_DASHBOARD,
  MODAL_LOCATIONS.ADDENDUM_IN_PROGRESS,
  MODAL_LOCATIONS.E_SIGN_DASHBOARD,
  MODAL_LOCATIONS.ADDENDUM,
  MODAL_LOCATIONS.ADDENDUM_IN_PROGRESS,
];

export const LEASE_AGREEMENT_LOCATIONS = [
  MODAL_LOCATIONS.LEASE,
  MODAL_LOCATIONS.LEASE_REVIEW,
  MODAL_LOCATIONS.LEASE_IN_PROGRESS,
];

export const FORM_PACK_LOCATIONS = [MODAL_LOCATIONS.GET_FORMS_PACK];

export const GENERAL_LOCATIONS = [
  MODAL_LOCATIONS.HELP_CENTER_FAQ,
  MODAL_LOCATIONS.HELP_CENTER_CALL,
  MODAL_LOCATIONS.BILLING,
  MODAL_LOCATIONS.DASHBOARD,
  MODAL_LOCATIONS.PROPERTIES,
  MODAL_LOCATIONS.SIDEBAR_NAVIGATION_TAG,
  MODAL_LOCATIONS.RESUBSCRIBE,
  MODAL_LOCATIONS.TOOLBOX,
];

export const PAYMENTS_LOCATIONS = [
  MODAL_LOCATIONS.PAYMENTS_OVERVIEW,
  MODAL_LOCATIONS.PAYMENTS_DASHBOARD,
  MODAL_LOCATIONS.PAYMENTS_MBA_ONBOARDING,
  MODAL_LOCATIONS.PAYMENTS_MBA,
  MODAL_LOCATIONS.BANK_ACCOUNTS,
  MODAL_LOCATIONS.ENTITY_BANK_ACCOUNTS,
];
