import React from 'react';

import { getItem } from '../../../../../../services/localStorageService';
import ProcessForm from '../../../../../onboard/forms/Process';
import { additionalSignupDataKey } from '../../useAdditionaSignupData';

const ProcessStandAlone = ({ onSubmit, ...props }) => {
  const loadedDataStr = getItem(additionalSignupDataKey);
  const loadedData = JSON.parse(loadedDataStr);
  const isBroker = loadedData.signupDescription === 'BROKER';

  const handleAllOfThem = () => {
    onSubmit({ process: 'ALL' });
  };

  return (
    <ProcessForm
      onSubmit={onSubmit}
      handleAllOfThem={handleAllOfThem}
      isBroker={isBroker}
      {...props}
    />
  );
};

export default ProcessStandAlone;
