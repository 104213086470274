import React from 'react';

import Input from '../Input';

import CoreField from './CoreField';

export const InputField = (props) => (
  <CoreField component={Input} type="text" {...props} />
);

export default InputField;
