import get from 'lodash.get';
import moment from 'moment';

import { getConfig } from '../configService';

const getFacebookPropertyTypeValue = (type) => {
  let propertyType;
  switch (type) {
    case 'SINGLE_FAMILY':
      propertyType = 'house';
      break;
    case 'APARTMENT':
      propertyType = 'apartment';
      break;
    case 'CONDO':
      propertyType = 'apartment';
      break;
    case 'MANUFACTURED':
      propertyType = 'house';
      break;
    case 'TOWNHOUSE':
      propertyType = 'townhouse';
      break;
    case 'OTHER':
      propertyType = 'house';
      break;
    default:
      propertyType = 'house';
  }
  return propertyType;
};

const getListingFullAddress = (listing) => {
  const { address, city, state, zip } = listing;
  return `${address}, ${city}, ${state}${zip ? ` ${zip}` : ''}`;
};

const getFacebookCoolingSystemValue = (listing) => {
  const coolingSystems =
    get(listing, 'details.amenities.cooling_systems', []) || [];
  const isCentralCoolingSystem = coolingSystems.some(
    (coolingItem) => coolingItem === 'CENTRAL',
  );
  const hasAnyCoolingSystem =
    coolingSystems.length && !coolingSystems.includes('NONE');
  if (isCentralCoolingSystem) return 'central';
  if (hasAnyCoolingSystem) return 'available';
  return '';
};

const getFacebookLaundryValue = (listing) => {
  const otherAmenities = get(listing, 'details.amenities.other', []) || [];
  const appliances = get(listing, 'details.amenities.appliances', []) || [];

  if (otherAmenities.includes('ON_SITE_LAUNDRY')) {
    return 'building';
  }

  return otherAmenities.includes('WASHER_DRYER_HOOKUPS') ||
    (appliances.includes('DRYER') && appliances.includes('WASHER'))
    ? 'available'
    : '';
};

const getFacebookParkingValue = (listing) => {
  const otherAmenities = get(listing, 'details.amenities.other', []) || [];
  if (otherAmenities.includes('GARAGE')) {
    return 'garage';
  }
  if (otherAmenities.includes('OFF_STREET_PARKING')) {
    return 'off-street';
  }
  return '';
};

const getFacebookHeatingValue = (listing) => {
  const heatingSystems =
    get(listing, 'details.amenities.heating_systems', []) || [];
  if (heatingSystems.includes('HEATPUMP')) {
    return 'electric';
  }
  if (heatingSystems.includes('RADIANT')) {
    return 'radiator';
  }
  const hasAnyHeatingSystem = heatingSystems.length;
  if (hasAnyHeatingSystem) {
    return 'available';
  }
  return '';
};

export const getPreparedFacebookMarketplaceData = (
  listing,
  shortLinkListing,
  ownerId,
) => {
  const { LEAD_INTAKE_SHORT } = getConfig();
  const listingId = get(listing, 'id', '') || '';
  const decodedListingId = atob(listingId).split(':').pop();
  const leadIntakeUrl = `${LEAD_INTAKE_SHORT}/${ownerId}?s=fb&p=${decodedListingId}`;
  const photosData = get(listing, 'photos', []) || [];
  const propertyTypeData =
    get(listing, 'details.property_details.property_type', 'SINGLE_FAMILY') ||
    'SINGLE_FAMILY';

  /* 
    NOTE: In extension bathrooms and bedrooms are switched, so 
    to prevent waiting for new version to get pulished we will update
    swtich from the code, and with next major extension update fix mapping 
    on the extension itself
  */
  const bathrooms = get(listing, 'details.property_details.bedrooms', '') || '';
  const bedrooms = get(listing, 'details.property_details.bathrooms', '') || '';

  const squareFeet =
    get(listing, 'details.property_details.square_feet', '') || '';
  const rentAmount = get(listing, 'rent_amount', '') || '';
  const description = get(listing, 'description', '') || '';
  const address = getListingFullAddress(listing);
  const petsAllowed =
    get(listing, 'details.lease_details.pets_allowed', []) || [];
  const availableDate = get(listing, 'available_date', '') || '';

  const parsedPhotos = photosData.length
    ? photosData.map((photoData) => get(photoData, 'picture', null))
    : [];
  const parsedPropertyType = getFacebookPropertyTypeValue(propertyTypeData);
  const catsFriendsly = petsAllowed.includes('CATS');
  const dogsFriendly =
    petsAllowed.includes('SMALL_DOGS') || petsAllowed.includes('LARGE_DOGS');
  const parsedAvailableDate = moment(availableDate).format('MMM DD, YYYY');
  const cooling = getFacebookCoolingSystemValue(listing);
  const heating = getFacebookHeatingValue(listing);
  const parking = getFacebookParkingValue(listing);
  const laundry = getFacebookLaundryValue(listing);
  const fullDescription = `${description}

To contact me about the rental, answer a few questions about yourself ${leadIntakeUrl}

For more information, view this listing on TurboTenant ${shortLinkListing}`;

  return {
    forSaleOrRent: 'rent',
    photos: parsedPhotos,
    propertyType: parsedPropertyType,
    bathrooms,
    bedrooms,
    rentAmount,
    description: fullDescription,
    squareFeet,
    address,
    catsFriendsly,
    dogsFriendly,
    available: parsedAvailableDate,
    cooling,
    heating,
    parking,
    laundry,
  };
};

export const isTurboListInstalled = (extensionCallback) => {
  if (!window.chrome) return extensionCallback(false);
  const { TURBOLIST_EXTENSION_ID } = getConfig();
  try {
    window.chrome.runtime.sendMessage(
      TURBOLIST_EXTENSION_ID,
      { action: 'healthcheck' },
      (reply) => {
        if (window.chrome.runtime.lastError) extensionCallback(false);
        if (reply) extensionCallback(reply);
      },
    );
  } catch (e) {
    extensionCallback(false);
  }
};

export const pushListingToTurboList = (rentalData) => {
  if (!window.chrome) return;
  const { TURBOLIST_EXTENSION_ID } = getConfig();
  try {
    window.chrome.runtime.sendMessage(TURBOLIST_EXTENSION_ID, {
      action: 'populateRental',
      rentalData,
    });
  } catch (e) {
    // console.log(e, 'extension error');
  }
};

export const isChrome = navigator.userAgent.indexOf('Chrome') !== -1;
