import React from 'react';
import cx from 'classnames';

import CheckMarkCircle from '../../icons/CheckMarkCircle';
import SaveTime from '../../icons/SaveTime';
import Button from '../Button';
import Card from '../Card';
import Tag from '../Tag';

import styles from './PartnersCard.module.scss';

const CommingSooonTitle = (
  <div className={styles.commingSooonTitle}>
    <SaveTime className={styles.saveTime} />
    Coming Soon!
  </div>
);

const PartnersCard = ({
  Logo,
  title,
  description,
  actions,
  newTag,
  tagProps: { label: tagLabel, classname: tagClassname, ...tagProps },
  addedToWaitlist,
  logoClassName,
}) => {
  return (
    <Card className={styles.container}>
      <Tag className={cx(styles.tag, tagClassname)} small {...tagProps}>
        {tagLabel}
      </Tag>
      <div className={styles.header}>
        {Logo ? (
          <Logo
            className={cx(styles.logo, logoClassName)}
            height={24}
            width={null}
          />
        ) : (
          CommingSooonTitle
        )}
        {newTag && <span className={styles.newTag}>New!</span>}
      </div>
      <h4 className={styles.title}> {title} </h4>
      <p className={styles.description}>{description}</p>
      <div className={styles.actionsHolder}>
        {actions.map(
          ({ label, className: actionClassName, ...actionProps }, index) => (
            <Button
              key={index}
              className={cx(styles.action, actionClassName)}
              {...actionProps}
              disabled={addedToWaitlist || actionProps.disabled}
            >
              {label}
            </Button>
          ),
        )}
      </div>
      {addedToWaitlist && (
        <div className={styles.addedToWaitlist}>
          <CheckMarkCircle />
          <p>
            <span>Added to waitlist.</span> We’ll email you when this is
            released!
          </p>
        </div>
      )}
    </Card>
  );
};

export default PartnersCard;
