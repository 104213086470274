import isString from 'lodash.isstring';

const formatPhone = (value) => {
  if (!isString(value)) {
    console.warn('Please provide string!');
    return '';
  }
  let maskedValue = value.replace(/\D/g, '');
  if (maskedValue.length > 6) {
    maskedValue = maskedValue
      .slice(-10)
      .replace(/^(\d\d\d)(\d{3})(\d{1,4}).*/, '($1) $2-$3');
  } else if (maskedValue.length > 3) {
    maskedValue = maskedValue.replace(/^(\d\d\d)(\d+).*/, '($1) $2');
  }
  return maskedValue;
};

export default formatPhone;
