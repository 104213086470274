import React, { useCallback, useContext, useRef, useState } from 'react';

const OutsideContext = React.createContext({
  add: () => console.error('Not in the OutsideContext'),
});

export const OutsideProvider = ({ children }) => {
  const [childrens, setChildrens] = useState([]);
  const idRef = useRef(0);
  const add = useCallback(
    (newChild) => {
      const id = ++idRef.current;
      setChildrens((oldChildrens) => [
        ...oldChildrens,
        {
          child: newChild,
          id,
        },
      ]);
      return id;
    },
    [setChildrens],
  );
  const remove = useCallback(
    (id) => {
      setChildrens((oldChildrens) => oldChildrens.filter((t) => t.id !== id));
    },
    [setChildrens],
  );

  return (
    <OutsideContext.Provider
      value={{
        add,
      }}
    >
      <>
        {children}
        {childrens.map(({ child, id }) => (
          <React.Fragment key={id}>{child(() => remove(id))}</React.Fragment>
        ))}
      </>
    </OutsideContext.Provider>
  );
};

export const useRenderOutside = () => {
  const { add } = useContext(OutsideContext);
  return useCallback(
    (outside) => {
      return add(outside);
    },
    [add],
  );
};
