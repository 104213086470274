import React from 'react';
import PropTypes from 'prop-types';

export const ListingContext = React.createContext(null);

const ListingProvider = ({ children, listing }) => (
  <ListingContext.Provider value={listing}>{children}</ListingContext.Provider>
);

ListingProvider.propTypes = {
  listing: PropTypes.object.isRequired,
};

export default ListingProvider;
