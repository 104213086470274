const statesWithRequiredPropertyDisclosure = [
  'AZ',
  'GA',
  'HI',
  'KS',
  'KY',
  'MD',
  'MA',
  'MI',
  'MT',
  'NV',
  'NH',
  'NY',
  'ND',
  'VT',
  'VA',
  'WI',
];

export default statesWithRequiredPropertyDisclosure;
