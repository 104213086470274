import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import PencilDocument from '../../../../icons/PencilDocument';
import SectionTitle from '../../common/SectionTitle';

import styles from './SectionAdditionalProvisions.module.scss';

export const SectionAdditionalProvisionsEarlyTerminationSection = ({
  children,
}) => (
  <div className={cx(styles.column, styles.earlyTerminationSection)}>
    <SectionTitle
      title="Early Termination"
      icon={PencilDocument}
      className={styles.title}
      iconProps={{ width: 29, height: 28, secondarycolor: 'none' }}
    />
    {children}
  </div>
);

SectionAdditionalProvisionsEarlyTerminationSection.propTypes = {
  children: PropTypes.node,
};
