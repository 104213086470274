import get from 'lodash.get';

// We want to return all data that we have for the Owner and his Listing to
// create form inputs that we will posted to Steadily form, that way we will
// autofill their form for Landlord Insurance.
// Here is Steadily Integration Examples:
// https://www.steadily.com/partner-program/examples/#1

const getSteadilyInputs = (user, listing = false) => {
  if (!user?.id) {
    return false;
  }

  const first_name = get(user, 'first_name', '');
  const last_name = get(user, 'last_name', '');
  const email = get(user, 'email', '');
  const userPhone = get(user, 'telephone', '');
  const userAddress = get(user, 'mailing_address.address', '');
  const userUnit = get(user, 'mailing_address.unit', '');
  const userCity = get(user, 'mailing_address.city', '');
  const userState = get(user, 'mailing_address.state', '');
  const userZip = get(user, 'mailing_address.zip', '');

  let allInputs = [
    {
      name: 'product',
      value: 'landlord-insurance',
    },
    {
      name: 'contact_first_name',
      value: first_name,
    },
    {
      name: 'contact_last_name',
      value: last_name,
    },
    {
      name: 'contact_email_address',
      value: email,
    },
    {
      name: 'contact_phone',
      value: userPhone,
    },
    {
      name: 'contact_street_address',
      value: userAddress,
    },
    {
      name: 'contact_street_address_2',
      value: userUnit,
    },
    {
      name: 'contact_city',
      value: userCity,
    },
    {
      name: 'contact_state',
      value: userState,
    },
    {
      name: 'contact_postal_code',
      value: userZip,
    },
  ];

  if (listing) {
    const listingAddress = get(listing, 'address', '');
    const unit = get(listing, 'unit', '');
    const listingCity = get(listing, 'city', '');
    const listingState = get(listing, 'state', '');
    const listingZip = get(listing, 'zip', '');
    const yearBuilt = get(listing, 'details.property_details.year_built', '');
    const squareFeet = get(listing, 'details.property_details.square_feet', '');
    const rentAmount = get(listing, 'rent_amount', '');

    allInputs = [
      {
        name: 'street_address',
        value: listingAddress,
      },
      {
        name: 'street_address_2',
        value: unit,
      },
      {
        name: 'city',
        value: listingCity,
      },
      {
        name: 'state',
        value: listingState,
      },
      {
        name: 'postal_code',
        value: listingZip,
      },
      {
        name: 'year_built',
        value: yearBuilt,
      },
      {
        name: 'building_sqft',
        value: squareFeet,
      },
      {
        name: 'rental_income_per_month',
        value: Math.round(rentAmount),
      },
      ...allInputs,
    ];
  }

  return allInputs;
};

export default getSteadilyInputs;
