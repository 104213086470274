import React, { memo } from 'react';

import colors from '../constants/colors';

const Recurring = (props) => (
  <svg viewBox="0 0 28 22" {...props} fill={props.color}>
    <path d="M24.033 8.573l.093.124 3.023 4.66a1.147 1.147 0 01-.296 1.558 1.06 1.06 0 01-1.416-.185l-.09-.121-1.11-1.712c-.756 4.028-3.834 7.497-7.866 8.482-3.493.855-7.107-.23-9.618-2.79a1.15 1.15 0 01-.012-1.587 1.06 1.06 0 011.535-.012c1.984 2.022 4.837 2.88 7.596 2.204 3.195-.78 5.643-3.556 6.235-6.736l-1.529 1.892a1.06 1.06 0 01-1.409.232l-.12-.09a1.15 1.15 0 01-.223-1.458l.087-.123 3.384-4.192c.042-.062.091-.12.146-.174l-.051.058a1.062 1.062 0 011.64-.03zm-2.31-4.638a1.15 1.15 0 01-.094 1.585 1.06 1.06 0 01-1.532-.097c-1.974-2.307-5.02-3.34-7.97-2.617-3.763.92-6.455 4.434-6.568 8.286l1.863-2.305a1.06 1.06 0 011.409-.232l.12.09c.42.364.507.99.223 1.457l-.087.124-3.478 4.308c-.43.533-1.209.53-1.64.03l-.094-.124L.851 9.78a1.147 1.147 0 01.296-1.558 1.06 1.06 0 011.416.185l.09.12.837 1.292c.612-4.36 3.807-8.14 8.137-9.198 3.736-.915 7.597.393 10.095 3.314z" />
  </svg>
);

Recurring.defaultProps = {
  color: colors.text,
  width: 28,
  height: 22,
};

export default memo(Recurring);
