import React from 'react';
import cx from 'classnames';

import styles from './Content.module.scss';

const Content = ({ className, children, column }) => (
  <div
    className={cx(className, styles.skeletonContainer, {
      [styles.column]: column,
    })}
  >
    {children}
  </div>
);

export default Content;
