import React from 'react';
import ReactDOM from 'react-dom';

import './_entry.on-first-load';
import './assets/segment-script';
import './assets/favicon.png';

import AdminApplication from './admin/App';
import { ModalProvider } from './components/Modal/ModalProvider';
import { ShowHideProvider } from './components/ShowHideComponents';
import { ToastProvider } from './components/Toast';
import { StatusEndpointProvider } from './helpers/status-endpoint';
import MainApplication from './pages/MainApplication';
import ConfigProvider from './providers/ConfigProvider';

ReactDOM.render(
  <ToastProvider>
    <ShowHideProvider>
      <ConfigProvider>
        {(config) => (
          <StatusEndpointProvider>
            <ModalProvider>
              {config.IS_ADMIN ? <AdminApplication /> : <MainApplication />}
            </ModalProvider>
          </StatusEndpointProvider>
        )}
      </ConfigProvider>
    </ShowHideProvider>
  </ToastProvider>,
  document.getElementById('root'),
);
if (module.hot) module.hot.accept();
