import { useCallback } from 'react';

import { useErrorToast } from '../components/Toast';

const onSubmitErrorDecorator = (onError) => (form) => {
  const originalSubmit = form.submit;

  // Subscribe to errors, and keep a local copy of them
  const unsubscribe = form.subscribe(() => {}, { errors: true });

  const checkFormErrors = () => {
    const { errors } = form.getState();

    if (errors && Object.keys(errors).length) {
      onError();

      throw errors;
    }
  };

  form.submit = async () => {
    try {
      return await originalSubmit.call(form);
    } catch (e) {
      console.error(e);
      checkFormErrors();
    }
  };

  return () => {
    unsubscribe();
    form.submit = originalSubmit;
  };
};

const useSubmitError = (error) => {
  const errorToast = useErrorToast();
  return useCallback(
    onSubmitErrorDecorator(() => errorToast(error)),
    [],
  );
};

export default useSubmitError;
