import React from 'react';
import { bool, object } from 'prop-types';

import Callout from '../../../../../components/Callout';
import formatPhone from '../../../../../helpers/format-phone';
import getResidenceFullAddress from '../../../../../services/utilities/getResidenceFullAddress';

import style from './AddressLandlordInformation.module.scss';

const AddressLandlordInformation = ({
  residence,
  withAddressLine,
  showBlockWithColorBar,
}) => {
  const As = showBlockWithColorBar ? Callout : React.Fragment;

  return (
    <As className={style.blueContainer}>
      <div>
        {residence.landlord_name ||
        residence.landlord_email ||
        residence.landlord_phone ? (
          withAddressLine ? (
            <p className={style.title}>{getResidenceFullAddress(residence)}</p>
          ) : (
            <p className={style.smallerTitle}>LANDLORD REFERENCE</p>
          )
        ) : null}
        <div className={style.addressWrapper}>
          {residence.landlord_name && (
            <span className={style.landlordName}>
              {residence.landlord_name}
            </span>
          )}
          {residence.landlord_email && (
            <span id="span_landlord_email" className={style.commonField}>
              {residence.landlord_email}
            </span>
          )}
          {residence.landlord_phone && (
            <span className={style.commonField}>
              {formatPhone(residence.landlord_phone)}
            </span>
          )}
        </div>
      </div>
    </As>
  );
};

AddressLandlordInformation.propTypes = {
  showBlockWithColorBar: bool,
  residence: object.isRequired,
  withAddressLine: bool,
};

export default AddressLandlordInformation;
