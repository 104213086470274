const openInNewTab = (url, forceAbsolute) => {
  let newWindowFeatures = '';

  // Force absolute URL with 'https' if required
  if (
    forceAbsolute &&
    !url.startsWith('http://') &&
    !url.startsWith('https://')
  ) {
    url = 'https://' + url;
  }

  // Add 'noopener,noreferrer' for external links
  if (!url.includes('turbotenant.com')) {
    newWindowFeatures = 'noopener,noreferrer';
  }

  window.open(url, '_blank', newWindowFeatures);
};

export default openInNewTab;
