import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Paragraph } from '../Typography';

import IconForType from './IconForType';

import styles from './InlineAlert.module.scss';

const InlineAlert = ({
  type,
  title,
  children,
  icon: Icon,
  iconClassName,
  iconProps,
  containerClassName,
  contentClassName,
  hideIcon,
}) => (
  <div
    className={cx(styles.container, styles[`type-${type}`], containerClassName)}
  >
    <div className={styles.row}>
      {!hideIcon && (
        <div className={cx(styles.iconWrapper, iconClassName)}>
          {Icon ? (
            <Icon {...iconProps} />
          ) : (
            <IconForType {...iconProps} type={type} />
          )}
        </div>
      )}
      <div className={cx(styles.contentWrapper, contentClassName)}>
        {title && (
          <Paragraph weight="bold" className={styles.title}>
            {title}
          </Paragraph>
        )}
        {children}
      </div>
    </div>
  </div>
);

InlineAlert.propTypes = {
  type: PropTypes.oneOf(['info', 'warning', 'danger', 'success']),
  children: PropTypes.node,
  icon: PropTypes.object,
  iconProps: PropTypes.object,
  iconClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hideIcon: PropTypes.bool,
};

InlineAlert.defaultProps = {
  type: 'info',
  iconProps: {},
};

export default InlineAlert;
