import React, { memo } from 'react';

import colors from '../constants/colors';
const LawnIcon = (props) => (
  <svg {...props} fill={props.color} viewBox="0 0 22 20">
    <path d="M2.597 0c.274 0 .518.165.628.413l.034.097 3.157 11.452V10c0-.353.26-.645.595-.691l.093-.007H8.25L8.25 8.14c0-.354.259-.646.594-.692l.094-.006h3.666c.38 0 .688.312.688.698l-.001 1.162h2.063c2.334 0 4.24 1.864 4.35 4.205l.004.214.001 1.173c1.285.118 2.291 1.214 2.291 2.548C22 18.856 20.873 20 19.48 20a2.527 2.527 0 01-2.427-1.86H8.155A2.526 2.526 0 015.73 20c-1.392 0-2.52-1.145-2.52-2.558 0-.493.137-.953.375-1.343L2.39 14.08a.7.7 0 01.497-1.05l.092-.006 2.302-.001L2.074 1.395H.688A.691.691 0 01.005.792L0 .698C0 .344.259.053.594.006L.688 0h1.909zm16.882 16.28c-.632 0-1.146.52-1.146 1.162 0 .643.513 1.163 1.146 1.163.634 0 1.146-.52 1.146-1.163s-.512-1.163-1.146-1.163zm-13.75 0c-.632 0-1.146.52-1.146 1.162 0 .643.513 1.163 1.146 1.163.634 0 1.146-.52 1.146-1.163s-.512-1.163-1.146-1.163zm1.36-1.862h-.214v.745c.618.32 1.088.892 1.28 1.581h8.898c.193-.69.663-1.26 1.28-1.581v-.745H7.104a.69.69 0 01-.016 0zm-2.895 0l.428.725c.269-.133.565-.22.877-.249v-.475H4.194zm11.16-3.72H7.792v2.325h10.46l-.032-.132c-.341-1.213-1.41-2.115-2.697-2.189l-.169-.004zm-3.437-1.86H9.625v.464h2.292v-.465z" />
  </svg>
);

LawnIcon.defaultProps = {
  color: colors.text,
  width: 22,
  height: 20,
};

export default memo(LawnIcon);
