export const yearListAsOptions = (
  fromDate = new Date(1900, 0, 1),
  toDate = new Date(2100, 0, 1),
  reverse = false,
) => {
  const years = [];
  for (let i = fromDate.getFullYear(); i <= toDate.getFullYear(); i += 1) {
    years.push({
      value: i,
      label: i.toString(),
    });
  }
  return reverse ? years.reverse() : years;
};
