import {
  blueLight,
  line,
  text,
  textLight,
} from '../../../../../../constants/colors';

export const listItem = (props) => {
  const selectedState =
    props.selectedConversation &&
    props.selectedConversation.conversationId ===
      props.conversation.conversationId
      ? {
          backgroundColor: blueLight,
        }
      : {};

  return {
    'display': 'flex',
    'flexDirection': 'row',
    'justifyContent': 'left',
    'alignItems': 'center',
    'cursor': 'pointer',
    'width': '100%',
    'padding': '8px 16px',
    'position': 'relative',
    'borderBottom': `1px solid ${line}`,
    ...selectedState,
    '&:hover': {
      backgroundColor: blueLight,
    },
  };
};

export const itemThumbnailStyle = () => {
  return {
    display: 'inline-block',
    width: '40px',
    height: '40px',
    flexShrink: '0',
  };
};

export const itemDetailStyle = () => {
  return {
    width: 'calc(100% - 45px)',
    flexGrow: '1',
    paddingLeft: '8px',
    position: 'relative',
    paddingRight: '60px',
  };
};

export const itemRowStyle = () => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  };
};

export const itemNameStyle = () => {
  return {
    fontSize: '14px',
    fontWeight: 'bold',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: '21px',
    letterSpacing: 0,
    color: text,
    fontFamily: 'Open Sans',
  };
};

export const itemLastMsgStyle = () => {
  return {
    margin: '0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: text,
    fontFamily: 'Open Sans',
    fontSize: '12px',
    letterSpacing: 0,
    lineHeight: '18px',
  };
};

export const itemLastMsgTimeStyle = () => {
  return {
    color: textLight,
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '18px',
  };
};

export const renterTypeLabelStyle = () => {
  return {
    color: textLight,
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '18px',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };
};

export const itemInfo = () => {
  return {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    alignItems: 'flex-end',
  };
};
