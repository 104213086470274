import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import Input from '../../../../../../../components/Input';
import ResourcesIcon from '../../../../../../../icons/resources';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredShortStandard from '../../../../../../../validators/fieldRequiredShort';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from '../../../BankInfo.module.scss';

const BankInfo = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequiredShort = conditionalValidation(
    fieldRequiredShortStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <HelpfulInfoCard
          collapsable
          Icon={ResourcesIcon}
          className={styles.helpfulInfoCard}
          title="Delaware Security Deposit Laws"
          color="iceCold"
        >
          Though they're included in the lease agreement, we've highlighted some
          rules you need to know:
          <HelpfulInfoList className={styles.helpfulInfoList}>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Security deposits may not exceed one 1 month’s rent, unless the
              lease is month-to-month, and in that event, it may be more than
              one 1 month’s rent.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Pet deposits can be collected, but may not exceed one 1 month’s
              rent.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Security deposit must be held in a separate “Security Deposit”
              account in an FDIC insured bank.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              You must disclose the location of the security deposit account to
              Tenant, we will add it to the lease agreement for you after you
              add it here.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Security deposits need to be returned to Tenant within 20 days at
              the termination or expiration of the Lease.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              You must provide Tenant with a written accounting of any lawful
              charges or deductions.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Tenant must provide you with Tenant’s forwarding address, in
              writing.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              If a forwarding address is not provided, you must retain the
              security deposit in the account for at least 1 year.
            </HelpfulInfoListItem>
            <div className={styles.learnMore}>
              <a
                href="http://support.turbotenant.com/en/articles/6207336-delaware-lease-agreement"
                target="_blank noopener noreferrer"
              >
                Learn other security deposit laws
              </a>
            </div>
          </HelpfulInfoList>
        </HelpfulInfoCard>

        <Field
          label="Financial Institution Name where security deposit will be held"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_name"
          name="deposit_bank_name"
          component={Input}
          validate={fieldRequiredShort}
        />
        <Field
          label="Financial Institution Address"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_address"
          name="deposit_bank_address"
          component={Input}
          validate={fieldRequiredShort}
        />
      </div>
    </div>
  );
};

BankInfo.propTypes = {
  className: PropTypes.string,
};

export default BankInfo;
