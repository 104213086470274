const PARKING_TYPES = {
  GARAGE: { id: 1, value: 'GARAGE', label: 'Garage' },
  DRIVEWAY: {
    id: 2,
    value: 'DRIVEWAY',
    label: 'Driveway',
  },
  STREET: {
    id: 3,
    value: 'STREET',
    label: 'Street',
  },

  CARPORT: {
    id: 4,
    value: 'CARPORT',
    label: 'Carport',
  },
  DESIGNATED_SPACE: {
    id: 5,
    value: 'DESIGNATED_SPACE',
    label: 'Designated Space',
    hasAdditionalInput: true,
  },
  OTHER: {
    id: 6,
    value: 'OTHER',
    label: 'Other',
    hasAdditionalInput: true,
  },
};

module.exports = PARKING_TYPES;
