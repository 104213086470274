import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import IconForType from './Components/IconForType';

import styles from './BulletPoint.module.scss';

const BulletPoint = ({
  type,
  label,
  description,
  children,
  ...iconRelatedProps
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.iconWrapper}>
        <IconForType type={type} {...iconRelatedProps} />
      </div>
      <div className={cx(styles.content, styles[`content-type-${type}`])}>
        {children ? (
          children
        ) : (
          <>
            <span className={cx(styles.label, { [styles.only]: !description })}>
              {label}
            </span>
            {description ? (
              <span className={styles.description}>{description}</span>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

BulletPoint.propTypes = {
  type: PropTypes.oneOf(['checkmark', 'numbered']).isRequired,
  color: PropTypes.string,
  numericColor: PropTypes.string,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.element,
};

export default BulletPoint;
