import React from 'react';

import Card from '../../../../components/Card';
import AlarmFilled from '../../../../icons/AlarmFilled';

import styles from './ScreeningReportError.module.scss';

// TODO: make this component generic enough that we can display different partners names
const ScreeningReportError = () => {
  return (
    <Card className={styles.error}>
      <AlarmFilled className={styles.icon} />
      <h1 className={styles.title}>
        Please go back to Redfin and try to view the screening report again.
      </h1>
      <p className={styles.description}>
        There was an issue with viewing the screening report. In order to keep
        your renter’s information secure, links to their report expire. Go back
        to Redfin and click again to view their report.
      </p>
    </Card>
  );
};

export default ScreeningReportError;
