const removeTypeNames = (obj) => {
  if (typeof obj !== 'object' || obj == null) {
    return obj;
  }
  return Object.keys(obj).reduce((acc, key) => {
    if (key === '__typename') {
      return acc;
    }
    const value = obj[key];
    if (Array.isArray(value)) {
      acc[key] = value.map((val) => removeTypeNames(val));
    } else {
      acc[key] = removeTypeNames(obj[key]);
    }
    return acc;
  }, {});
};

export default removeTypeNames;
