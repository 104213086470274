import React from 'react';
import PropTypes from 'prop-types';

import SuccessAnimation from '../../../components/Animations/SuccessAnimation';
import LoadingScreen from '../../../components/LoadingScreen';
import getMovingServices from '../../../helpers/getMovingServices';
import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen';
import useListingFetcher from '../../../hooks/useListingFetcher';

import styles from './DocumentsUploaded.module.scss';

const DocumentsUploaded = ({ match }) => {
  const fetchListing = useListingFetcher();

  const { data: listing, loading } = fetchListing(match?.params?.listingId);

  const isSmallScreen = useIsSmallScreen();

  const movingServices = getMovingServices(listing, {
    location: 'Rental App V2: App Submitted',
    locationShort: 'application',
    campaign: 'application-success',
  });

  return (
    <LoadingScreen loading={loading}>
      <div className={styles.container}>
        <SuccessAnimation style={{ height: isSmallScreen ? 106 : 145.33 }} />
        <h2 className={styles.title}>Documents Uploaded!</h2>
        <h3>
          The landlord will receive them immediately and review them as part of
          your rental application.
        </h3>
        <hr />
        <h4 className={styles.subtitle}>
          You're one step closer to a new home!{' '}
          <p className={styles.subtitleBlue}>
            We can help with the rest<span>:</span>
          </p>
        </h4>
        <div className={styles.servicesHolder}>
          {movingServices.map((service, index) => {
            const { icon: Icon, title, text, onClick } = service;
            return (
              <div key={index} className={styles.movingWrapper}>
                <a onClick={onClick} className={styles.movingInCard}>
                  <div className={styles.iconWrapper}>
                    <Icon className={styles.icon} />
                  </div>
                  <div className={styles.content}>
                    <p className={styles.titleMoving}>{title}</p>
                    <p className={styles.textMoving}>{text}</p>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </LoadingScreen>
  );
};

DocumentsUploaded.propTypes = {
  match: PropTypes.object,
};

export default DocumentsUploaded;
