import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import convertToNumber from '../../helpers/convert-to-number';
import composeValidators from '../../validators/composeValidators';
import fieldRequiredShort from '../../validators/fieldRequiredShort';
import isNumber from '../../validators/isNumber';
import isNumberLengthLessThanOrEqualTo from '../../validators/isNumberLengthLessThanOrEqualTo';
import Input from '../Input';

import styles from './RentalDetailsFields.module.scss';

const defaultFieldNames = {
  bedrooms: 'bedrooms',
  bathrooms: 'bathrooms',
  square_feet: 'square_feet',
  year_built: 'year_built',
};

const RentalDetailsFields = ({ className, fieldNames }) => {
  const fields = {
    ...defaultFieldNames,
    ...fieldNames,
  };

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <Field
          component={Input}
          id={fields.bedrooms}
          name={fields.bedrooms}
          className={cx(styles.field, styles.beds)}
          label="Beds"
          maxLength={3}
          type="text"
          pattern="[0-9]*"
          inputMode="numeric"
          parse={convertToNumber}
          format={convertToNumber}
          validate={composeValidators(
            fieldRequiredShort,
            isNumber,
            isNumberLengthLessThanOrEqualTo(3),
          )}
        />
        <Field
          component={Input}
          id={fields.bathrooms}
          name={fields.bathrooms}
          className={cx(styles.field, styles.baths)}
          label="Baths"
          type="number"
          step=".5"
          pattern="[0-9]*"
          inputMode="decimal"
          format={convertToNumber}
          parse={convertToNumber}
          validate={composeValidators(
            fieldRequiredShort,
            isNumber,
            isNumberLengthLessThanOrEqualTo(4),
          )}
        />
      </div>
      <div className={cx(styles.row, styles.squareYearBuilt)}>
        <Field
          component={Input}
          hint="(Optional)"
          id={fields.square_feet}
          name={fields.square_feet}
          className={cx(styles.input, styles.squareBuilt)}
          label="Square Feet"
          type="number"
          pattern="[0-9]*"
          validate={composeValidators(
            isNumber,
            isNumberLengthLessThanOrEqualTo(6),
          )}
        />
        <Field
          component={Input}
          hint="(Optional)"
          id={fields.year_built}
          name={fields.year_built}
          className={cx(styles.input, styles.squareBuilt)}
          label="Year Built"
          type="number"
          pattern="[0-9]*"
          validate={composeValidators(
            isNumber,
            isNumberLengthLessThanOrEqualTo(4),
          )}
        />
      </div>
    </div>
  );
};

RentalDetailsFields.propTypes = {
  className: PropTypes.string,
  fieldNames: PropTypes.object,
};

RentalDetailsFields.defaultProps = {
  fieldNames: defaultFieldNames,
};

export default RentalDetailsFields;
