import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import IconButton from '../../../components/IconButton';
import Modal from '../../../components/Modal';
import Close from '../../../icons/Close';
import StripeElementsComponent from '../../../pages/common/stripe/StripeElementsComponent';
import { segmentTracking } from '../../../services/utilities';

import styles from './StripeElementsModal.module.scss';

const StripeElementsModal = ({
  className,
  onClose,
  open,
  segmentProperties,
  ...rest
}) => {
  useEffect(() => {
    if (open && segmentProperties) {
      segmentTracking('checkout_modal loaded', segmentProperties);
    }
  }, [open]);
  const history = useHistory();
  const location = useLocation();
  const removeUpdateCardParas = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('change-card');
    const newSearchString = searchParams.toString();
    history.replace({
      ...location,
      search: newSearchString,
    });
  };

  return (
    <Modal
      open={open}
      className={cx(styles.container, className)}
      mobileFullScreen
    >
      <IconButton
        onClick={() => {
          removeUpdateCardParas();
          onClose();
        }}
        icon={Close}
        className={styles.closeAction}
        iconProps={{ width: 18, height: 18 }}
      />
      <StripeElementsComponent
        className={styles.stripeModalContainer}
        {...rest}
      />
    </Modal>
  );
};

StripeElementsModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  segmentProperties: PropTypes.object,
};

StripeElementsModal.defaultProps = {
  open: true,
};

export default StripeElementsModal;
