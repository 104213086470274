import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';

import styles from './SharedUtilitiesMN.module.scss';

const SharedUtilitiesMN = ({ className, standaloneWizard }) => {
  const {
    input: { value: sharedUtilities },
  } = useField('custom_state_data.shared_utilities');

  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standaloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <p className={styles.description}>
        Are there any single-metered shared utilities for the property?
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.shared_utilities"
        name="custom_state_data.shared_utilities"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'custom_state_data.shared_utilities_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'custom_state_data.shared_utilities_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      {sharedUtilities && (
        <>
          <p className={styles.description}>
            If any utility costs are shared by tenants for the property, the
            frequency of billing, the average cost for each month and the
            specific formula used to calculate tenant’s share of the utility
            expense must be included. For example, if the water is metered
            together you must describe how utilities and maintenance cost is
            broken down for your share and the tenant’s share. This includes
            disclosure of any shared common area utilities. Upon tenant request,
            present and past copies of the utility statements must be shared.
            Please note that these costs are separate from the rent amount.
            There is the possibility for energy assistance from the Low Income
            Home Energy Assistance Program from the State of Minnesota. Contact
            number for additional information: 1-800-657-3710 or 1-651-539-1500.
          </p>
          <Field
            as="textarea"
            component={Input}
            id="custom_state_data.utilities_devided_as_follows"
            name="custom_state_data.utilities_devided_as_follows"
            label="Please list all shared utilities, the billing frequency, average cost for each month and how the utility costs are divided."
            className={styles.termsContainer}
            inputProps={{
              rows: '5',
              className: styles.termsField,
            }}
            maxLength={2000}
            validate={fieldRequired}
            help={(val = '') => `${val.length} / 2000 characters used`}
          />
        </>
      )}
    </div>
  );
};

SharedUtilitiesMN.propTypes = {
  className: PropTypes.string,
  standaloneWizard: PropTypes.bool,
};

export default SharedUtilitiesMN;
