import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Stars = ({ color, ...rest }) => (
  <svg {...rest} viewBox="0 0 136 24">
    <g fill={color} fillRule="evenodd">
      <path d="M12 18l-7.053 3.708 1.347-7.854L.587 8.292l7.886-1.146L12 0l3.527 7.146 7.886 1.146-5.707 5.562 1.347 7.854zM68 18l-7.053 3.708 1.347-7.854-5.707-5.562 7.886-1.146L68 0l3.527 7.146 7.886 1.146-5.707 5.562 1.347 7.854zM40 18l-7.053 3.708 1.347-7.854-5.707-5.562 7.886-1.146L40 0l3.527 7.146 7.886 1.146-5.707 5.562 1.347 7.854zM96 18l-7.053 3.708 1.347-7.854-5.707-5.562 7.886-1.146L96 0l3.527 7.146 7.886 1.146-5.707 5.562 1.347 7.854zM124 18l-7.053 3.708 1.347-7.854-5.707-5.562 7.886-1.146L124 0l3.527 7.146 7.886 1.146-5.707 5.562 1.347 7.854z" />
    </g>
  </svg>
);

Stars.defaultProps = {
  width: 136,
  height: 24,
  color: '#FFCD00',
};

Stars.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Stars);
