import React from 'react';
import PropTypes from 'prop-types';

import {
  APP_STORE_BUTTON_IMG,
  APP_STORE_RENTER_URL,
  PLAY_STORE_BUTTON_IMG,
  PLAY_STORE_RENTER_URL,
} from '../constants';

import { Link, P } from './EmailTypography';
import TTLogo from './Logo';

const styles = {
  logo: {
    height: '36px',
    width: '122px',
    margin: '0 0 8px 0',
  },
  text: {
    align: 'center',
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '14px',
    fontStyle: 'italic',
    lineHeight: '21px',
  },
  wrapper: {
    marginTop: '16px',
  },
  appStoreLink: {
    marginRight: '12px',
    width: '118px',
  },
  playStoreLink: {
    width: '132px',
  },
};

const RenterFooter = ({ showAppStoreButtons }) => (
  <div style={styles.container}>
    <TTLogo style={styles.logo} />
    <P style={styles.text}>
      This landlord uses TurboTenant to manage their rental property. You’ll
      receive messages and to-do's from them through TurboTenant.
    </P>
    {showAppStoreButtons && (
      <div style={styles.wrapper}>
        <Link href={APP_STORE_RENTER_URL}>
          <img style={styles.appStoreLink} src={APP_STORE_BUTTON_IMG} />
        </Link>
        <Link href={PLAY_STORE_RENTER_URL}>
          <img style={styles.playStoreLink} src={PLAY_STORE_BUTTON_IMG} />
        </Link>
      </div>
    )}
  </div>
);

RenterFooter.propTypes = {
  showAppStoreButtons: PropTypes.bool,
};

export default RenterFooter;
