import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RadioCheck from '../../icons/RadioCheck';

import styles from './ComparisonChart.module.scss';

const themes = {
  lightBlue: styles.lightBlue,
  dark: styles.dark,
};

const ComparisonChart = ({ rows, className, theme, premiumCta }) => {
  const categoryIndexes = useMemo(
    () =>
      rows.reduce((acc, row, i) => {
        const category = row[3];
        if (category !== rows[i - 1]?.[3]) {
          acc.push(i);
        }
        return acc;
      }, []),
    [rows],
  );

  function getCellContent(value) {
    if (typeof value === 'string') return value;
    if (value === 0) return '';
    return <RadioCheck className={styles.checkmark} color={'currentColor'} />;
  }

  function willCollapseText(text) {
    return text.length > 41;
  }

  return (
    <div
      className={cx(
        styles.container,
        className,
        (theme && themes[theme]) || '',
      )}
    >
      <table>
        <thead>
          <tr>
            <th className={styles.labelsHeader}></th>
            <th className={cx(styles.free, styles.header)}>
              <div className={styles.innerTHContainer}>
                Current <span className={styles.mobileHidden}>Plan</span>
              </div>
            </th>
            <th className={cx(styles.premium, styles.header)}>
              <div className={styles.innerTHContainer}>
                <div>Premium</div>
                {premiumCta && (
                  <div className={styles.mobileHidden}>{premiumCta}</div>
                )}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => {
            const category = row[3];
            const willCollapseLabel = willCollapseText(row[0]);
            const isLastRow = i === rows.length - 1;
            return (
              <>
                {category && categoryIndexes.includes(i) && (
                  <tr className={styles.titleStripe}>
                    <td colSpan={3}>
                      <div
                        className={cx(
                          styles.innerCellContainer,
                          styles.sectionTitle,
                        )}
                      >
                        {category}
                      </div>
                    </td>
                  </tr>
                )}
                <tr
                  className={cx(styles.row, {
                    [styles.row__collapsed]: willCollapseLabel,
                    [styles.row__last]: isLastRow,
                  })}
                >
                  <td className={styles.label}>
                    <div className={styles.innerCellContainer}>
                      <div>{row[0]}</div>
                    </div>
                  </td>
                  <td>
                    <div className={styles.innerCellContainer}>
                      <div>{getCellContent(row[1])}</div>
                    </div>
                  </td>
                  <td>
                    <div className={styles.innerCellContainer}>
                      <div>{getCellContent(row[2])}</div>
                      {isLastRow && <div className={styles.cellDisplacement} />}
                    </div>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

ComparisonChart.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ).isRequired,
  className: PropTypes.string,
  theme: PropTypes.oneOf(Object.keys(themes)),
  premiumCta: PropTypes.string,
};

export default ComparisonChart;
