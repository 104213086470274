import React, { useState } from 'react';
import ReactGoogleLogin from 'react-google-login';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import { useConfig } from '../../providers/ConfigProvider';
import OldButton from '../GoogleButton';

const googleCookieError = 'idpiframe_initialization_failed';

const GoogleLogin = ({
  onSuccess,
  onFailure,
  className,
  onClick,
  type,
  GoogleButton = OldButton,
  buttonProps = {},
}) => {
  const [loading, setLoading] = useState(false);
  const config = useConfig();

  const onGoogleSuccess = async (data) => {
    const response = {
      service: 'GOOGLE',
      token: data.getAuthResponse && data.getAuthResponse().id_token,
      last_name: get(data, 'profileObj.familyName', ''),
      first_name: get(data, 'profileObj.givenName', ''),
      email: get(data, 'profileObj.email', ''),
    };
    if (response.first_name && response.last_name) {
      setLoading(true);
      try {
        await onSuccess(response);
      } catch {
        // do nothing
      }
      setLoading(false);
      return;
    }
  };

  return (
    <ReactGoogleLogin
      clientId={config.GOOGLE_OAUTH_CLIENT_ID}
      onSuccess={onGoogleSuccess}
      render={(renderProps) => (
        <GoogleButton
          type={type}
          loading={loading}
          onClick={() => {
            if (onClick) {
              onClick();
            }
            renderProps.onClick();
          }}
          disabled={renderProps.disabled}
          className={className}
          {...buttonProps}
        />
      )}
      onFailure={({ error }) => {
        if (error === googleCookieError) return;
        if (onFailure) {
          onFailure(error);
        }
      }}
    />
  );
};

GoogleLogin.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.oneOf(['login', 'signup']).isRequired,
  GoogleButton: PropTypes.elementType,
  buttonProps: PropTypes.object,
};

export default GoogleLogin;
