import React from 'react';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';

import styles from './TrashRemovalMD.module.scss';

const TrashRemovalMD = () => {
  return (
    <HelpfulInfoCard
      noIcon
      title="Montgomery County, MD Trash Removal"
      className={styles.helpfulInfoCard}
      color="iceCold"
    >
      <p className={styles.description}>
        Montgomery County law requires that you must pay for trash removal and
        provide receptacles for multi-family properties. For single family
        properties, you must pay for trash removal, but you don't have to
        provide the bins. If your property is not located in a county trash
        collection district, the tenant pays for trash collection if provided by
        a private trash hauler.
      </p>
    </HelpfulInfoCard>
  );
};

export default TrashRemovalMD;
