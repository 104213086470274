import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Spinner from '../Spinner';

import styles from './FlatButton.module.scss';

const FlatButton = forwardRef(
  (
    {
      to,
      href,
      type,
      icon: Icon,
      iconProps: { className: iconClassName, ...otherIconProps },
      iconAfter,
      className,
      children,
      disabled,
      loading,
      color,
      ...otherProps
    },
    ref,
  ) => {
    let Element = 'button';
    const props = {};
    if (to && !(disabled || loading)) {
      props.to = to;
      Element = Link;
    } else if (href && !(disabled || loading)) {
      props.href = href;
      Element = 'a';
    } else {
      props.type = type;
    }

    let icon;
    let before;
    let after;
    if (Icon) {
      icon = (
        <div
          className={cx(styles.iconWrap, {
            [styles.loading]: loading,
          })}
        >
          <Icon
            className={cx(styles.icon, iconClassName, {
              [styles.iconAfter]: iconAfter,
            })}
            {...otherIconProps}
          />
        </div>
      );
    }
    if (iconAfter) {
      after = icon;
    } else {
      before = icon;
    }
    return (
      <Element
        ref={ref}
        className={cx(
          styles.button,
          {
            [styles.disabled]: disabled || loading,
            [styles[color]]: true,
          },
          className,
        )}
        disabled={disabled || loading}
        {...otherProps}
        {...props}
      >
        {loading ? (
          <Spinner className={styles.spinner} />
        ) : (
          <>
            {before}
            {children}
            {after}
          </>
        )}
      </Element>
    );
  },
);

FlatButton.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
  iconProps: PropTypes.object,
  iconAfter: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'error', 'secondary']),
};

FlatButton.defaultProps = {
  type: 'button',
  iconProps: {},
  color: 'primary',
};

export default FlatButton;
