import React from 'react';

import ErrorIcon from '../../../../icons/ErrorIcon';

import {
  IncomeInsightsContent,
  IncomeInsightsDescription,
  IncomeInsightsIcon,
  IncomeInsightsTitle,
} from './Elements';
import RenterReportedIncome from './RenterReportedIncome';

const LowerIncomeInsightCard = ({ firstName, estimate, application }) => (
  <>
    <IncomeInsightsIcon mismatchIcon>
      <ErrorIcon />
    </IncomeInsightsIcon>
    <IncomeInsightsContent>
      <IncomeInsightsTitle redTitle biggerTitle>
        TransUnion estimates {firstName}’s annual income is{' '}
        <b>at least ${estimate} less</b> than what they reported.
      </IncomeInsightsTitle>
      <IncomeInsightsDescription>
        We recommend you check their pay stubs/bank statements and contact their
        employers to verify their income.
      </IncomeInsightsDescription>
      <RenterReportedIncome application={application} />
    </IncomeInsightsContent>
  </>
);

export default LowerIncomeInsightCard;
