import React, { useCallback } from 'react';
import { useForm, useFormState } from 'react-final-form';
import moment from 'moment';

import ALLOWED_EXTENSIONS from '../../constants/allowed_extensions';
import useS3FileUploader from '../../hooks/useS3FileUploader';
import DocumentUpload from '../../icons/DocumentUpload';
import DownloadIcon from '../../icons/Download';
import Photos from '../../icons/Photos';
import RemoveButton from '../RemoveButton';
import UploadDropZone from '../UploadDropZone';

import HiddenField from './HiddenField';

import styles from './UploadDropZoneField.module.scss';

const checkImageURL = (url) => url.match(/\.(jpeg|jpg|gif|png)$/) != null;

const DocumentPreview = ({ file, editable, onRemove }, index) => {
  const { file_url, created_at, filename } = file;

  return (
    <div className={styles.preview}>
      <a href={file_url} target="_blank" rel="noopener noreferrer">
        <div className={styles.thumbnailWrapper}>
          {checkImageURL(file_url) ? (
            <img src={file_url} className={styles.thumbnail} />
          ) : (
            <DocumentUpload style={{ marginRight: 10 }} />
          )}
        </div>
      </a>
      <div className={styles.description}>
        <span className={styles.filename}>{filename}</span>
        <span className={styles.uploadDate}>
          UPLOADED{' '}
          {moment(
            created_at != null ? new Date(created_at) : new Date(),
          ).format('MM/DD/YY')}
        </span>
      </div>
      {!editable ? (
        <a
          className={styles.link}
          href={file_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <DownloadIcon />
        </a>
      ) : (
        <span className={styles.removeIconWrap}>
          <RemoveButton justIcon onClick={(evt) => onRemove(evt, index)} />
        </span>
      )}
    </div>
  );
};

const UploadDropZoneField = ({
  name,
  bucket,
  allowedExtensions = ALLOWED_EXTENSIONS.map((ext) => `.${ext}`).join(','),
  editable = true,
  ...props
}) => {
  const { values } = useFormState();
  const form = useForm();
  const onUploaded = useCallback(
    (uploaded) => {
      form.mutators.concat(name, uploaded);
    },
    [form],
  );
  const onRemove = useCallback(
    (index) => {
      form.mutators.remove(name, index);
    },
    [form],
  );
  const [upload] = useS3FileUploader({ onUploaded, bucket });

  return (
    <>
      <HiddenField name={name} {...props} />
      <div className={styles.previewContainer}>
        {(values?.[name] || []).map((file, index) => (
          <DocumentPreview
            key={index}
            editable={editable}
            file={file}
            onRemove={() => {
              onRemove(index);
            }}
          />
        ))}
      </div>
      <UploadDropZone
        className={styles.upload}
        id="upload_files"
        name="upload_files"
        accept={allowedExtensions}
        uploadFiles={upload}
        multiple
        icon={Photos}
        iconProps={{ width: 30, height: 30 }}
        dropzoneLabel="CLICK OR DRAG TO UPLOAD (OPTIONAL)"
      />
    </>
  );
};

export default UploadDropZoneField;
