import React, { useState } from 'react';
import dateFormat from 'dateformat';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

import { CometChat } from '@cometchat-pro/chat';

import { CometChatToastNotification } from '../../Shared/';
import { CometChatSharedMediaView } from '../../Shared/CometChatSharedMediaView/index.js';
import { useChatArchive } from '../../../../../../hooks/useChatArchive';
import { UserDetailManager } from './controller';

import exportMessagesMutation from '../../../../../../graphql/mutations/exportMessages.graphql';

import { CometChatContext } from '../../../util/CometChatContext';
import * as enums from '../../../util/enums.js';

import Translator from '../../../resources/localization/translator';
import { theme } from '../../../resources/theme';

import { segmentTracking } from '../../../../../../services/utilities';

import {
  userDetailStyle,
  headerStyle,
  headerCloseStyle,
  headerTitleStyle,
  sectionStyle,
  actionSectionStyle,
  mediaSectionStyle,
  privacySectionStyle,
  sectionHeaderStyle,
  sectionContentStyle,
  contentItemStyle,
  itemLinkStyle,
  itemActionStyle,
  itemIconStyle,
  itemActionDescriptionStyle,
  itemArchiveActionStyle,
  archiveChatTextStyle,
  exportChatTextStyle,
  // userInfoSectionStyle,
  // userThumbnailStyle,
  // userNameStyle,
  // userStatusStyle,
  // userPresenceStyle,
} from './style';

import navigateIcon from './resources/back.svg';
import Block from '../../../../../../icons/Block';
import Storage from '../../../../../../icons/Storage';
import Download from '../../../../../../icons/DownloadIcon';
import Spinner from '../../../../../Spinner';
import { useMutation } from '@apollo/client';
import { smallScreenWidth } from '../../../../../../constants/media-breakpoints';
import useWindowSize from '@rooks/use-window-size';
import ExportMessagesLoadingModal from '../../../../../../components/ExportMessagesLoadingModal';
import { useSuccessToast } from '../../../../../Toast';
class CometChatUserDetailsClass extends React.Component {
  static contextType = CometChatContext;

  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      loggedInUser: null,
      status: null,
      enableSharedMedia: false,
      enableBlockUser: false,
      enableViewProfile: false,
      enableUserPresence: false,
      tags: [],
      tagsLoading: false,
    };

    this.toastRef = React.createRef();
  }

  componentDidMount() {
    this.getChatTags();
    CometChat.getLoggedinUser()
      .then((user) => {
        if (this._isMounted) {
          this.setState({ loggedInUser: user });
        }
      })
      .catch((error) => this.toastRef.setError('SOMETHING_WRONG'));

    this._isMounted = true;
    this.toastRef = React.createRef();

    this.UserDetailManager = new UserDetailManager();
    this.UserDetailManager.attachListeners(this.updateUser);

    this.setStatusForUser();
    this.enableSharedMedia();
    this.enableBlockUser();
    this.enableViewProfile();
    this.enableUserPresence();
  }

  componentDidUpdate(prevProps) {
    this.enableSharedMedia();
    this.enableBlockUser();
    this.enableViewProfile();
    this.enableUserPresence();

    if (prevProps.lang !== this.props.lang) {
      this.setStatusForUser();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.UserDetailManager.removeListeners();
    this.UserDetailManager = null;
  }

  getChatTags = async () => {
    this.setState({ tagsLoading: true });
    try {
      const conversation = await CometChat.getConversation(
        this.context.item.uid,
        'user',
      );
      this.setState({ tags: conversation?.tags || [] });
    } catch (error) {
      console.log('error', error);
    } finally {
      this.setState({ tagsLoading: false });
    }
  };

  enableUserPresence = () => {
    this.context.FeatureRestriction.isUserPresenceEnabled()
      .then((response) => {
        if (response !== this.state.enableUserPresence && this._isMounted) {
          this.setState({ enableUserPresence: response });
        }
      })
      .catch((error) => {
        if (this.state.enableUserPresence !== false) {
          this.setState({ enableUserPresence: false });
        }
      });
  };

  updateUser = (key, user) => {
    switch (key) {
      case enums.USER_ONLINE:
      case enums.USER_OFFLINE: {
        if (
          this.context.type === CometChat.ACTION_TYPE.TYPE_USER &&
          this.context.item.uid === user.uid
        ) {
          //if user presence feature is disabled
          if (this.state.enableUserPresence === false) {
            return false;
          }

          let status = '';
          if (user.status === CometChat.USER_STATUS.OFFLINE) {
            status = Translator.translate('OFFLINE', this.props.lang);
          } else if (user.status === CometChat.USER_STATUS.ONLINE) {
            status = Translator.translate('ONLINE', this.props.lang);
          }
          this.setState({ status: status });
        }
        break;
      }
      default:
        break;
    }
  };

  setStatusForUser = () => {
    let status = null;
    if (
      this.context.item.status === CometChat.USER_STATUS.OFFLINE &&
      this.context.item.lastActiveAt
    ) {
      const lastActive = this.context.item.lastActiveAt * 1000;
      const messageDate = dateFormat(lastActive, 'dS mmm yyyy, h:MM TT');

      status = `${Translator.translate(
        'LAST_ACTIVE_AT',
        this.props.lang,
      )}: ${messageDate}`;
    } else if (this.context.item.status === CometChat.USER_STATUS.OFFLINE) {
      status = Translator.translate('OFFLINE', this.props.lang);
    } else if (this.context.item.status === CometChat.USER_STATUS.ONLINE) {
      status = Translator.translate('ONLINE', this.props.lang);
    }

    this.setState({ status: status });
  };

  enableSharedMedia = () => {
    this.context.FeatureRestriction.isSharedMediaEnabled()
      .then((response) => {
        /**
         * Don't update state if the response has the same value
         */
        if (response !== this.state.enableSharedMedia && this._isMounted) {
          this.setState({ enableSharedMedia: response });
        }
      })
      .catch((error) => {
        if (this.state.enableSharedMedia !== false && this._isMounted) {
          this.setState({ enableSharedMedia: false });
        }
      });
  };

  enableBlockUser = () => {
    this.context.FeatureRestriction.isBlockUserEnabled()
      .then((response) => {
        /**
         * Don't update state if the response has the same value
         */
        if (response !== this.state.enableBlockUser && this._isMounted) {
          this.setState({ enableBlockUser: response });
        }
      })
      .catch((error) => {
        if (this.state.enableBlockUser !== false && this._isMounted) {
          this.setState({ enableBlockUser: false });
        }
      });
  };

  enableViewProfile = () => {
    this.context.FeatureRestriction.isViewProfileEnabled()
      .then((response) => {
        if (response !== this.state.enableViewProfile && this._isMounted) {
          this.setState({ enableViewProfile: response });
        }
      })
      .catch((error) => {
        if (this.state.enableViewProfile !== false && this._isMounted) {
          this.setState({ enableViewProfile: false });
        }
      });
  };

  blockUser = () => {
    if (this.state.loggedInUser?.role === 'renter') {
      return null;
    }

    let uid = this.context.item.uid;
    let usersList = [uid];
    CometChat.blockUsers(usersList)
      .then((response) => {
        if (
          response &&
          response.hasOwnProperty(uid) &&
          response[uid].hasOwnProperty('success') &&
          response[uid]['success'] === true
        ) {
          const newType = CometChat.ACTION_TYPE.TYPE_USER;
          const newItem = Object.assign({}, this.context.item, {
            blockedByMe: true,
          });
          this.context.setTypeAndItem(newType, newItem);
        } else {
          this.toastRef.setError('SOMETHING_WRONG');
        }
      })
      .catch((error) => this.toastRef.setError('SOMETHING_WRONG'));
  };

  unblockUser = () => {
    if (this.state.loggedInUser?.role === 'renter') {
      return null;
    }

    let uid = this.context.item.uid;
    let usersList = [uid];
    CometChat.unblockUsers(usersList)
      .then((response) => {
        if (
          response &&
          response.hasOwnProperty(uid) &&
          response[uid].hasOwnProperty('success') &&
          response[uid]['success'] === true
        ) {
          const newType = CometChat.ACTION_TYPE.TYPE_USER;
          const newItem = Object.assign({}, this.context.item, {
            blockedByMe: false,
          });
          this.context.setTypeAndItem(newType, newItem);
        } else {
          this.toastRef.setError('SOMETHING_WRONG');
        }
      })
      .catch((error) => this.toastRef.setError('SOMETHING_WRONG'));
  };

  viewProfile = () => {
    const profileLink = this.context.item.link;
    window.open(profileLink, '', 'fullscreen=yes, scrollbars=auto');
  };

  closeDetailView = () => {
    this.props.actionGenerated(enums.ACTIONS['CLOSE_USER_DETAIL']);
  };

  render() {
    if (this.state.loggedInUser === null) {
      return null;
    }

    let viewProfile = null;
    if (
      this.state.enableViewProfile === true &&
      this.context.item.hasOwnProperty('link') &&
      this.context.item.link &&
      this.context.item.link.trim().length
    ) {
      viewProfile = (
        <div css={sectionStyle()} className="detailpane__section">
          <div
            css={actionSectionStyle(this.context)}
            className="section section__viewprofile"
          >
            <h6
              css={sectionHeaderStyle(this.props)}
              className="section__header"
            >
              {Translator.translate('ACTIONS', this.context.language)}
            </h6>
            <div css={sectionContentStyle()} className="section__content">
              <div css={contentItemStyle()} className="content__item">
                <div
                  css={itemLinkStyle(this.context)}
                  className="item__link"
                  onClick={this.viewProfile}
                >
                  {Translator.translate('VIEW_PROFILE', this.context.language)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let blockUserText;
    if (this.context.item.blockedByMe) {
      blockUserText = (
        <div
          css={itemLinkStyle(this.context)}
          className="item__link"
          onClick={() => {
            segmentTracking('unblock messages clicked', {
              location: 'Messages Details',
            });
            this.unblockUser();
          }}
        >
          UNBLOCK CHAT
        </div>
      );
    } else {
      blockUserText = (
        <div>
          <div
            css={itemActionStyle(this.context)}
            className="item__link"
            onClick={() => {
              segmentTracking('block messages clicked', {
                location: 'Messages Details',
              });
              this.blockUser();
            }}
          >
            <Block color={itemIconStyle().color} />
            BLOCK CHAT
          </div>
          <p css={itemActionDescriptionStyle()}>
            This will block you and the renter from messaging, but will not
            block the renter from taking other actions such as paying rent.{' '}
          </p>
        </div>
      );
    }

    let archiveChatView = this.state.tags.length === 0 && (
      <div
        css={itemArchiveActionStyle(this.context, this.state.tagsLoading)}
        onClick={() => {
          this.props.handleChatArchiving(
            this.context.conversationId || this?.context?.item?.conversationId,
            () => {
              this.props.toggleSideBar();
              this.props.onArchiveSuccess();
            },
          );
        }}
      >
        {this.state.tagsLoading ? (
          <Spinner secondary />
        ) : (
          <div>
            <div
              css={itemActionStyle(this.context)}
              className="item__link"
              onClick={() => {
                segmentTracking('archive clicked', {
                  location: 'Messages Details',
                });
              }}
            >
              <Storage />
              <span css={archiveChatTextStyle(this.context)}>ARCHIVE CHAT</span>
              {this.props.archiveInProgress && <Spinner secondary />}
            </div>
            <p css={itemActionDescriptionStyle()}>
              Archiving will hide chats between you and this renter within
              Messages. Chats are still available in the renter profile.
            </p>
          </div>
        )}
      </div>
    );

    let exportMessagesView = (
      <div
        css={itemArchiveActionStyle(this.context, false)}
        onClick={() => {
          this.props.exportMessages(
            this.context.conversationId || this?.context?.item?.conversationId,
            this.context.item.name,
          );
        }}
      >
        <div>
          <div
            css={itemActionStyle(this.context)}
            className="item__link"
            onClick={() => {
              segmentTracking('export_chat clicked', {
                location: 'Messages',
              });
            }}
          >
            <Download />
            <span css={exportChatTextStyle(this.context)}>EXPORT CHAT</span>
          </div>
          <p css={itemActionDescriptionStyle()}>
            Export the chats to share or print.
          </p>
        </div>
      </div>
    );

    let blockUserView = (
      <div
        css={sectionStyle(true, this.context)}
        className="detailpane__section"
      >
        <div
          css={privacySectionStyle(this.context)}
          className="section section__privacy"
        >
          <div css={sectionContentStyle()} className="section__content">
            <div css={contentItemStyle()} className="content__item">
              {blockUserText}
            </div>
          </div>
        </div>
      </div>
    );

    //if block/unblock user feature is disabled
    if (
      this.state.enableBlockUser === false ||
      this.state.loggedInUser?.role === 'renter'
    ) {
      blockUserView = null;
    }

    let sharedmediaView = (
      <div css={mediaSectionStyle()} className="detailpane__section">
        <CometChatSharedMediaView
          theme={this.props.theme}
          lang={this.context.language}
        />
      </div>
    );

    //if shared media feature is disabled
    if (this.state.enableSharedMedia === false) {
      sharedmediaView = null;
    }

    if (this.state.loggedInUser?.role === 'renter') {
      archiveChatView = null;
      exportMessagesView = null;
    }
    return (
      <div
        css={userDetailStyle(this.context)}
        className="detailpane detailpane--user"
      >
        <div css={headerStyle(this.context)} className="detailpane__header">
          <div
            css={headerCloseStyle(navigateIcon, this.context)}
            className="header__close"
            onClick={this.closeDetailView}
          ></div>
          <h4 css={headerTitleStyle()} className="header__title">
            {Translator.translate('DETAILS', this.context.language)}
          </h4>
        </div>
        {/* <div css={sectionStyle()} className="detailpane__section">
          <div
            css={userInfoSectionStyle()}
            className="section section__userinfo"
          >
            <div css={userThumbnailStyle()} className="user__thumbnail">
              <CometChatAvatar user={this.context.item} />
            </div>
            <div css={userStatusStyle()} className="user__status">
              <h6 css={userNameStyle()}>{this.context.item.name}</h6>
              <span css={userPresenceStyle(this.context, this.state)}>
                {this.state.status}
              </span>
            </div>
          </div>
        </div> */}
        <CometChatToastNotification
          ref={(el) => (this.toastRef = el)}
          lang={this.props.lang}
        />
        {archiveChatView}
        {exportMessagesView}
        {viewProfile}
        {blockUserView}
        {sharedmediaView}
        <ExportMessagesLoadingModal
          open={this.props.exportInProgress}
          onPressCancel={this.props.handleCancelExport}
        />
      </div>
    );
  }
}

const CometChatUserDetails = (props) => {
  const { handleChatArchiving, archiveInProgress } = useChatArchive();
  const [exportMessages, { loading: exportInProgress }] = useMutation(
    exportMessagesMutation,
  );
  const showToast = useSuccessToast();
  const { innerWidth } = useWindowSize();
  const isMobile = innerWidth <= smallScreenWidth;

  const [abortController, setAbortController] = useState(null);

  const downloadFile = (url, name) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = `Chat-Messages_${name}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const shareFile = async (url, name) => {
    try {
      await navigator.share({
        title: `Chat-Messages_${name}.csv`,
        text: `Chat-Messages for ${name}`,
        url,
      });
    } catch (error) {
      console.error('Share failed:', error);
      downloadFile(url, name); // Fallback to download if share fails
    }
  };

  const handleExportMessages = async (conversationId) => {
    const controller = new AbortController(); // Create a new AbortController
    setAbortController(controller); // Save controller to state

    try {
      const response = await exportMessages({
        variables: {
          chatId: conversationId,
          receiverType: 'user',
        },
        context: {
          fetchOptions: {
            signal: controller.signal, // Attach signal to the fetch request
          },
        },
      });

      return response?.data?.exportMessages.csvData;
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Export request was cancelled.');
      } else {
        console.error('An error occurred during export:', error);
        throw error; // Rethrow error if it's not an abort error
      }
    } finally {
      setAbortController(null); // Reset abort controller
    }
  };

  const exportMessagesHandler = async (conversationId, name) => {
    try {
      const csvData = await handleExportMessages(conversationId);

      if (csvData) {
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);

        if (navigator.share && isMobile) {
          await shareFile(url, name);
          showToast('Your chats are ready for export');
        } else {
          downloadFile(url, name);
        }
      } else {
        console.error('No CSV data found to export.');
      }
    } catch (error) {
      console.error('Export failed:', error);
    }
  };

  const handleCancelExport = () => {
    segmentTracking('export_chat clicked', {
      location: 'Messages',
    });
    if (abortController) {
      abortController.abort();
      showToast('Export cancelled.');
      setAbortController(null);
    }
  };

  return (
    <CometChatUserDetailsClass
      {...props}
      archiveInProgress={archiveInProgress}
      handleChatArchiving={handleChatArchiving}
      exportInProgress={exportInProgress}
      exportMessages={exportMessagesHandler}
      handleCancelExport={handleCancelExport}
    />
  );
};

CometChatUserDetails.defaultProps = {
  theme: theme,
};

CometChatUserDetails.propTypes = {
  theme: PropTypes.object,
};

export { CometChatUserDetails };
