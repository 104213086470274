export function mapAddressTypeToInt(type) {
  if (type == undefined) {
    return undefined;
  }
  switch (type) {
    case 'OWN':
      return 0;
    case 'RENT':
      return 1;
    case 'FAMILY':
      return 2;
    case 'OTHER':
      return 3;
  }
}
