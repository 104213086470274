import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen';
import { segmentTracking } from '../../../services/utilities';
import { H4 } from '../../Typography';

import BenefitsView from './BenefitsView';

import styles from './TabVariantPremiumPlus.module.scss';

const TabVariantPremiumPlus = ({
  segmentProperties = {},
  extraParams,
  sections,
  onGetPro,
  onGetPremium,
  onGetPremiumPlus,
  allBenefits: benefits,
  displayMonthlyPrice,
  premiumPrice,
  proPrice,
  premiumPlusPrice,
}) => {
  const isSmallScreen = useIsSmallScreen();
  const [selectedBenefit, setSelectedBenefit] = useState(
    benefits?.[0]?.key || '',
  );

  const onClickSegment = (event) => {
    segmentTracking(`${event} clicked`, {
      ...segmentProperties,
    });
  };

  const tabs = [
    !isSmallScreen && { key: 'all_features', title: 'All Features' },
    ...sections,
  ];

  const handleTabClick = (tab) => {
    onClickSegment(`${tab.title.toLowerCase().split(' ').join('_')}_tab`);
    setSelectedBenefit(tab.key);
  };

  return (
    <div className={styles.tabsWrapper}>
      <div
        className={cx(styles.tabItems, {
          [styles.withMonthlyPrice]: displayMonthlyPrice,
        })}
      >
        {tabs.map((tab) => (
          <H4
            key={tab.key}
            className={cx(styles.item, {
              [styles.active]: selectedBenefit === tab.key,
            })}
            onClick={() => handleTabClick(tab)}
          >
            {tab.title}
          </H4>
        ))}
      </div>

      <div className={styles.packageWrapper}>
        <BenefitsView
          benefits={benefits}
          selectedBenefit={selectedBenefit}
          extraParams={extraParams}
          displayMonthlyPrice={displayMonthlyPrice}
          proPrice={proPrice}
          onGetPro={onGetPro}
          premiumPlusPrice={premiumPlusPrice}
          onGetPremiumPlus={onGetPremiumPlus}
          premiumPrice={premiumPrice}
          onGetPremium={onGetPremium}
          isMobile={isSmallScreen}
          onClickSegment={onClickSegment}
        />
      </div>
    </div>
  );
};

TabVariantPremiumPlus.propTypes = {
  segmentProperties: PropTypes.object,
  user: PropTypes.object,
  extraParams: PropTypes.object,
  sections: PropTypes.array,
  onGetPro: PropTypes.func,
  onGetPremiumPlus: PropTypes.func,
  onGetPremium: PropTypes.func,
  allBenefits: PropTypes.array,
  displayMonthlyPrice: PropTypes.bool,
  premiumPrice: PropTypes.number,
  proPrice: PropTypes.number,
  premiumPlusPrice: PropTypes.number,
};

export default TabVariantPremiumPlus;
