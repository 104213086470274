import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FORM_ERROR } from 'final-form';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';

import ListingModeEnum from '../../constants/enums/ListingModeEnum';
import { useListings, useUserFields } from '../../core/TTgraphql';
import createLeaseMutation from '../../graphql/mutations/createLease.graphql';
import leasesQuery from '../../graphql/queries/leasesQuery.graphql';
import removeUnusedMUPFields from '../../helpers/removeUnusedMUPFields';
import updateProfileMutation from '../../pages/owners/profile/updateProfile.graphql';
import { removeFromApolloCache } from '../../services/utilities';
import LeaseDetailsForm from '../LeaseAgreements/LeaseDetailsForm';
import LoadingScreen from '../LoadingScreen';

const NEW_PROPERTY_ID = 'new-property';
const LeaseDetailsCreateForm = ({
  history,
  location,
  onCancel,
  onSubmit,
  defaultListingId,
  refetchQueries,
  withRenters,
  fieldIds,
  segmentLocation,
  showInsuranceStep,
  requireInsurance,
  ...rest
}) => {
  const { listings: properties, loading: listingsLoading } = useListings({
    variables: { mode: ListingModeEnum.ROOTS_ONLY },
  });
  const queryParameters = parse(location.search);
  const { user } = useUserFields(['require_insurance']);

  useEffect(() => {
    if (!queryParameters.listingId && properties?.length === 1) {
      history.push({
        pathname: location.pathname,
        search: `?${stringify({
          ...queryParameters,
          listingId: properties[0]?.id,
        })}`,
        state: location.state,
      });
    }
  }, [properties]);

  const [createLease, { loading: createLeaseLoading }] = useMutation(
    createLeaseMutation,
    {
      refetchQueries:
        refetchQueries && refetchQueries.length
          ? refetchQueries
          : [{ query: leasesQuery }],
      awaitRefetchQueries: true,
      update: (cache) => {
        removeFromApolloCache(['listings', 'applicants'], cache);
      },
    },
  );

  const [updateProfile, { loading: updateProfileLoading }] = useMutation(
    updateProfileMutation,
  );

  const onSubmitForm = async (data) => {
    const toSubmit = removeUnusedMUPFields(data);
    const {
      address,
      unit,
      city,
      end_date,
      listing_id,
      start_date,
      state,
      title,
      zip,
      month_to_month,
      end_action,
      require_renters_insurance,
    } = toSubmit;

    const listing = {
      address,
      city,
      state,
      unit,
      zip,
    };

    const lease = {
      title,
      end_date: month_to_month ? null : end_date,
      start_date,
      ...(listing_id !== NEW_PROPERTY_ID
        ? { listing_id: listing_id }
        : { listing: listing }),
      end_action: !month_to_month && end_date ? end_action : null,
    };
    if (withRenters && withRenters.length) {
      lease.renters = withRenters;
    }

    if (require_renters_insurance && !user.require_insurance) {
      await updateProfile({
        variables: {
          require_insurance: true,
        },
      });
    }

    try {
      const { data: response } = await createLease({
        variables: { lease },
      });
      onSubmit &&
        onSubmit({
          leaseId: response.upsertLease.lease.id,
          listingId: response.upsertLease.lease.listing.id,
        });
    } catch (error) {
      const parsedError = get(error, `graphQLErrors[0]`) || {};
      const message = parsedError.message || 'Something went wrong';
      return {
        [FORM_ERROR]: message,
      };
    }
  };

  return (
    <LoadingScreen
      loading={listingsLoading || updateProfileLoading || createLeaseLoading}
    >
      <LeaseDetailsForm
        onSubmit={onSubmitForm}
        onCancel={onCancel}
        properties={properties}
        defaultListingId={defaultListingId}
        fieldIds={fieldIds}
        segmentLocation={segmentLocation}
        showInsuranceStep={showInsuranceStep}
        requireInsurance={requireInsurance}
        {...rest}
      />
    </LoadingScreen>
  );
};

LeaseDetailsCreateForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  properties: PropTypes.array,
  defaultListingId: PropTypes.string,
  refetchQueries: PropTypes.array,
  withRenters: PropTypes.array,
  fieldIds: PropTypes.object,
  segmentLocation: PropTypes.string,
  showInsuranceStep: PropTypes.bool,
  requireInsurance: PropTypes.bool,
};

export default withRouter(LeaseDetailsCreateForm);
