export const validPhone = (value) => {
  if (value) {
    const phone = value.replace(/\D/g, '');
    if (phone.length < 10) {
      return 'Type a valid phone number';
    }
  }
  return undefined;
};

export default validPhone;
