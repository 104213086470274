import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const MoneyPiggyBank = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g
      stroke={props.color}
      strokeWidth={props.strokeWidth || 1.25}
      fillOpacity="0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    >
      <path d="M11.5 6.017c1.78-.118 3.549.394 5 1.483M23.5 9.5c0 1.105-.896 2-2 2" />
      <path d="M12 3.5c-.85 0-1.67.104-2.454.28-.601-1.062-1.738-1.78-3.046-1.78-.638 0-1.234.175-1.75.472.91.527 1.562 1.451 1.715 2.536-2.095 1.268-3.555 3.232-3.891 5.492h-2.074v4h2.697c1.41 2.931 4.817 5 8.803 5 5.247 0 9.5-3.581 9.5-8 0-4.417-4.253-8-9.5-8z" />
      <circle cx="6" cy="10" r=".5" />
      <path d="M7.5 18.766v3.234M16.5 18.734v3.266" />
    </g>
  </svg>
);

MoneyPiggyBank.defaultProps = {
  width: 56,
  height: 56,
  color: colors.black,
};

MoneyPiggyBank.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
export default memo(MoneyPiggyBank);
