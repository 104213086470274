import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ListingHouseIcon from '../../../../icons/ListingHouse';
import SectionTitle from '../../common/SectionTitle';

import styles from './SectionPeopleOnTheLease.module.scss';

export const SectionPeopleOnTheLeaseLandlordSection = ({ children }) => (
  <div className={cx(styles.column, styles.section, styles.xSmallSpacing)}>
    <SectionTitle title="Landlord" icon={ListingHouseIcon} />
    {children}
  </div>
);

SectionPeopleOnTheLeaseLandlordSection.propTypes = {
  children: PropTypes.node,
};
