import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import parseScreeningReportDate from '../../../../helpers/parseScreeningReportDate';

import styles from './CriminalSection.module.scss';

const CriminalSection = ({ report }) => {
  let rowCounter = 0;

  return (
    <div className={styles.container}>
      <span className={styles.title}>Criminal Records</span>
      <div className={styles.records}>
        {/* TODO we're not showingthe whole information in the HTML, but it will require a refactor to map the new fields.*/}
        {/* {report.criminal_report_html && (
              <div
                dangerouslySetInnerHTML={{ __html: report.criminal_report_html }}
              />
            )} */}

        {report.criminal_records &&
          report.criminal_records.sex_offender_status && (
            <p>
              <b>Sex offender crimes detected.</b>
            </p>
          )}
        {report.number_of_criminal_records === 0 ? (
          <span>
            No criminal records are available. This could be because they have
            no records. Or the local area does not allow the use of their
            criminal history in evaluating them as tenants.
          </span>
        ) : (
          report.criminal_records_states &&
          Object.keys(report.criminal_records_states).map((state, idx) => {
            const criminal_record = report.criminal_records_states[state];

            return (
              <div key={idx} className={styles.column}>
                <span className={styles.recordState}>{state}</span>
                {criminal_record.records.map((record) => {
                  rowCounter++;
                  return (
                    <div
                      className={cx(styles.column, {
                        [styles.oddRow]: rowCounter % 2 === 1,
                      })}
                      key={idx}
                    >
                      <div className={styles.row}>
                        <div className={styles.record}>
                          <span className={styles.recordTitle}>Date</span>
                          <span className={styles.recordValue}>
                            {parseScreeningReportDate(record.date)}
                          </span>
                        </div>
                        <div className={styles.record}>
                          <span className={styles.recordTitle}>
                            Description
                          </span>
                          <span className={styles.recordValue}>
                            {record.description}
                          </span>
                        </div>
                        <div className={styles.record}>
                          <span className={styles.recordTitle}>
                            Charge Code
                          </span>
                          <span className={styles.recordValue}>
                            {record.chargeCode}
                          </span>
                        </div>
                      </div>
                      <div className={styles.row}>
                        <div className={styles.record}>
                          <span className={styles.recordTitle}>Court</span>
                          <span className={styles.recordValue}>
                            {record.court}
                          </span>
                        </div>
                        <div className={styles.record}>
                          <span className={styles.recordTitle}>
                            Case Number
                          </span>
                          <span className={styles.recordValue}>
                            {record.caseNumber}
                          </span>
                        </div>
                        <div className={styles.record}>
                          <span className={styles.recordTitle}>
                            Disposition
                          </span>
                          <span className={styles.recordValue}>
                            {record.disposition}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

CriminalSection.propTypes = {
  report: PropTypes.object,
};

export default CriminalSection;
