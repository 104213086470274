import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RadioGroup from '../../../../../../../components/RadioGroup';
import WaterThickIcon from '../../../../../../../icons/WaterThick';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './FloodDisclosure.module.scss';

const FloodDisclosure = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <>
      <div className={cx(styles.container, className)}>
        <SectionTitle title="Flood Disclosure" icon={WaterThickIcon} />
        <label className={styles.label}>
          Are you aware of flooding on the property within the last five years?
        </label>
        <p className={styles.description}>
          Oklahoma law requires this disclosure. This generally means partial or
          complete flooding of normally dry land areas and structures from the
          overflow of lakes, ponds, streams, rivers, creeks and any other inland
          waters.
        </p>
        <Field
          component={RadioGroup}
          id="custom_state_data.has_property_flooded"
          name="custom_state_data.has_property_flooded"
          radiosContainerClassName={styles.radioButtonsContainer}
          options={[
            {
              id: 'has_property_flooded_yes',
              value: true,
              label: 'Yes, I am aware',
            },
            {
              id: 'has_property_flooded_no',
              value: false,
              label: 'No, I am not aware',
            },
          ]}
          validate={fieldRequired}
        />
      </div>
    </>
  );
};

FloodDisclosure.propTypes = {
  className: PropTypes.string,
};

export default FloodDisclosure;
