import React from 'react';

import TransUnionLogo from '../../icons/TransUnionLogo';
import Button from '../Button';
import Modal from '../Modal';

import styles from './IncomeInsightsWarningModal.module.scss';

const IncomeInsightsWarningModal = ({ open = false, onAgree = () => {} }) => (
  <Modal open={open} className={styles.incomeInsightsModal}>
    <TransUnionLogo className={styles.logo} width={155} height={40} />
    <p className={styles.description}>
      <i>Income Insights</i> can help determine when to request proof of income.{' '}
      <b>It cannot be the sole reason to deny an applicant.</b>{' '}
      <a
        className={styles.learnMore}
        href={
          'http://support.turbotenant.com/en/articles/6539674-what-is-income-insights'
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn why.
      </a>
    </p>

    <Button className={styles.action} onClick={onAgree}>
      I UNDERSTAND, VIEW REPORT
    </Button>
  </Modal>
);

export default IncomeInsightsWarningModal;
