/* eslint-disable */

if (!(window.console && console.log)) {
  window.console = {
    log: function () {},
    debug: function () {},
    info: function () {},
    warn: function () {},
    error: function () {},
  };
}
