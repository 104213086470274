import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { Form } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import FinalFormError from '../../../../components/FinalFormError';
import Input from '../../../../components/Input';
import trimEmail from '../../../../components/Input/trimEmail';
import Modal from '../../../../components/Modal';
import PasswordInput from '../../../../components/PasswordInput';
import { segmentTracking } from '../../../../services/utilities';
import composeValidators from '../../../../validators/composeValidators';
import fieldRequired from '../../../../validators/fieldRequired';
import validEmail from '../../../../validators/validEmail';
import validName from '../../../../validators/validName';
import validPasswordStrength from '../../../../validators/validPasswordStrength';

import styles from './SaveLeaseAgreementModal.module.scss';

const SaveLeaseAgreementModal = ({
  className,
  containerClassName,
  open,
  onClose,
  onSignUp,
  location,
  segmentData = {},
}) => {
  useEffect(() => {
    if (open) {
      segmentTracking('save_signup_modal loaded', {
        location,
        ...segmentData,
      });
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={cx(styles.modal, className)}
      containerClassName={cx(styles.modalContainer, containerClassName)}
    >
      <h2 className={styles.title}>Access your Lease Agreement</h2>
      <p className={styles.description}>
        Sign up to save, preview, edit, add attachments, and send to tenants for
        e-sign.
      </p>
      <Form onSubmit={onSignUp}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className={cx(styles.form, className)}>
            <div className={styles.fullNameContainer}>
              <Field
                label="First Name"
                component={Input}
                name="first_name"
                id="first_name"
                className={styles.name}
                validate={composeValidators(fieldRequired, validName)}
              />
              <Field
                label="Last Name"
                component={Input}
                name="last_name"
                id="last_name"
                className={styles.name}
                validate={composeValidators(fieldRequired, validName)}
              />
            </div>
            <Field
              label="Email"
              component={Input}
              type="email"
              name="email"
              id="email"
              className={styles.email}
              parse={trimEmail}
              validate={composeValidators(validEmail, fieldRequired)}
            />
            <Field
              label="Password"
              component={PasswordInput}
              name="password"
              id="password"
              validate={composeValidators(fieldRequired, validPasswordStrength)}
              className={styles.password}
              inputProps={{
                autoComplete: 'new-password',
              }}
              showPasswordStrengthChecker
              passwordStrengthClassName={styles.noMargin}
            />
            <Button
              className={styles.submit}
              type="submit"
              loading={submitting}
            >
              Sign up
            </Button>
            <FinalFormError />
          </form>
        )}
      </Form>
      <div className={styles.terms}>
        By clicking the button above you are agreeing to our{' '}
        <a
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.turbotenant.com/terms-of-use/"
        >
          Terms of Use
        </a>
        {' & '}
        <a
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.turbotenant.com/privacy-policy/"
        >
          Privacy Policy
        </a>
      </div>
    </Modal>
  );
};

SaveLeaseAgreementModal.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSignUp: PropTypes.func.isRequired,
  location: PropTypes.string,
  segmentData: PropTypes.object,
};

export default SaveLeaseAgreementModal;
