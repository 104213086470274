const isLengthBetweenWithLimits = (min, max) => (value) => {
  if (!value) {
    return undefined;
  }

  if (value.length < min) {
    return `${value.length} / ${min} characters used | ${min} minimum`;
  }

  if (value.length > max) {
    return `${value.length} / ${max} characters used | ${max} maximum`;
  }

  return undefined;
};

export default isLengthBetweenWithLimits;
