import React from 'react';
import { Field, Form } from 'react-final-form';
import cx from 'classnames';
import createFinalFormErrorDecorator from 'final-form-focus';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Condition from '../../../../components/Condition';
import DropdownSubtextMenu from '../../../../components/DropdownSubtextMenu';
import Input from '../../../../components/Input';
import MultiCondition from '../../../../components/MultiCondition';
import PropertyAddressFields from '../../../../components/PropertyAddressFields';
import RadioGroup from '../../../../components/RadioGroup';
import { Tooltip } from '../../../../components/Tooltip';
import isSinglePropertyType from '../../../../helpers/isSinglePropertyType';
import fieldRequired from '../../../../validators/fieldRequired';
import validShareAbleAddress from '../../../../validators/validShareAbleAddress';
import validShareAbleCity from '../../../../validators/validShareAbleCity';
import validShareAbleUnit from '../../../../validators/validShareAbleUnit';
import { propertyTypeDropdownOptions } from '../../../digital-leases/standalone-wizard/helpers/propertyTypeDropdownOptions';

import styles from './ChooseRentalPropertyStepForm.module.scss';

const ChooseRentalPropertyStepForm = ({
  className,
  onSubmit,
  initialValues,
}) => {
  const finalFormErrorDecorator = createFinalFormErrorDecorator();
  return (
    <Form
      onSubmit={onSubmit}
      subscription={{
        submitting: true,
        values: true,
      }}
      initialValues={initialValues}
      decorators={[finalFormErrorDecorator]}
    >
      {({ handleSubmit, submitting, values }) => (
        <form className={cx(styles.form, className)} onSubmit={handleSubmit}>
          <Field
            label="Property Type"
            id="property_type"
            name="property_type"
            validate={fieldRequired}
          >
            {(props) => (
              <DropdownSubtextMenu
                options={propertyTypeDropdownOptions}
                {...props}
              >
                <DropdownSubtextMenu.Trigger />
                <DropdownSubtextMenu.Content>
                  {(options) =>
                    options.map((option) => (
                      <DropdownSubtextMenu.Item
                        key={option.value}
                        option={option}
                      />
                    ))
                  }
                </DropdownSubtextMenu.Content>
              </DropdownSubtextMenu>
            )}
          </Field>
          <PropertyAddressFields
            validators={{
              city: [validShareAbleCity],
              unit: [validShareAbleUnit],
              address: [validShareAbleAddress],
            }}
            showUnit={isSinglePropertyType(values.property_type)}
          />
          <Field
            component={RadioGroup}
            id="roomRentals"
            name="roomRentals"
            label={
              <div className={styles.roomRentalsWrapper}>
                Will you have room rentals?{' '}
                <span
                  className={styles.learnMore}
                  data-tooltip-id="learn-more-key-tip"
                >
                  Learn more
                </span>
                <Tooltip id="learn-more-key-tip" place="top">
                  Room rentals are when you're renting out rooms separately
                  within the property, each with their own lease.
                </Tooltip>
              </div>
            }
            options={[
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ]}
            validate={fieldRequired}
          />

          <MultiCondition
            condition={({ property_type, roomRentals }) =>
              isSinglePropertyType(property_type) && roomRentals
            }
          >
            <div className={styles.unitRoomContainer}>
              <div className={styles.sectionTitle}>Add Room</div>
              <p className={styles.sectionDescription}>
                Start with one room, even if you have more. You'll be able to
                set up the rest later.
              </p>
              <Field
                id={`room_name`}
                name={`room_name`}
                component={Input}
                label="Room Name"
                validate={fieldRequired}
                maxLength={50}
              />
            </div>
          </MultiCondition>
          <MultiCondition
            condition={({ property_type }) =>
              isSinglePropertyType(property_type) === false
            }
          >
            <div className={styles.unitRoomContainer}>
              <div className={styles.sectionTitle}>Add Unit</div>
              <p className={styles.sectionDescription}>
                Start with one unit, even if you have more. You'll be able to
                set up the rest later.
              </p>
              <Field
                id={`unit_name`}
                name={`unit_name`}
                className={styles.field}
                component={Input}
                label="Unit #"
                validate={fieldRequired}
                maxLength={50}
                placeholder="Address 2 (Apt, Suite, etc.)"
              />
              <Condition when="roomRentals" is={true}>
                <Field
                  id={`room_name`}
                  name={`room_name`}
                  component={Input}
                  label="Room Name"
                  validate={fieldRequired}
                  maxLength={50}
                />
              </Condition>
            </div>
          </MultiCondition>
          <Button
            className={styles.submitBtn}
            loading={submitting}
            type="submit"
          >
            Next
          </Button>
        </form>
      )}
    </Form>
  );
};

ChooseRentalPropertyStepForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

ChooseRentalPropertyStepForm.defaultProps = {
  initialValues: {},
};

export default ChooseRentalPropertyStepForm;
