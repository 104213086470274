import React from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import toCurrency from '../../../../../helpers/toCurrency';
import { useConfig } from '../../../../../providers/ConfigProvider';
import AddressLandlordInformation from '../AddressLandlordInformation';

import styles from './AddressContent.module.scss';

const mapTypeToString = (type) => {
  switch (type) {
    case 0:
      return 'Owned';
    case 1:
      return 'Rental';
    case 2:
      return 'Family';
    case 3:
      return 'Other';
    default:
      return 'Other';
  }
};

const renderTenancyType = (address) => {
  return (
    <span
      className={cx(styles.tenancyType, {
        [styles.tenancyTypeRental]: address?.tenancy_type === 1,
        [styles.tenancyTypeOther]: address?.tenancy_type !== 1,
      })}
    >
      {mapTypeToString(address?.tenancy_type)}
    </span>
  );
};

const AddressContent = ({ address, notApplicable, isCoSignerAddress }) => {
  const { IS_RENTER } = useConfig();

  if (notApplicable)
    return (
      <div className={cx(styles.addressContainer, styles.grayBorder)}>
        <div className={styles.title}>Past Address</div>

        <span className={cx(styles.text, styles.italic)}>
          {IS_RENTER ? 'You' : 'Applicant'} selected “Not applicable.”
        </span>
      </div>
    );

  if (isCoSignerAddress) {
    return (
      <div className={cx(styles.addressContainer, styles.mailingAddress)}>
        <div className={styles.rowColXs}>
          <div className={styles.leftCol}>
            <div className={cx(styles.row, styles.marginBottom)}>
              <span className={styles.streetAddress}>{`${address?.address_1}${
                address?.address_2 ? ` Unit# ${address?.address_2}` : ''
              }`}</span>
              {renderTenancyType(address)}
            </div>
            <div
              className={styles.addressLine}
            >{`${address?.city}, ${address?.state} ${address?.zip}`}</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={cx(styles.addressContainer, {
          [styles.addressCurrent]: address?.current,
        })}
      >
        <div className={styles.title}>
          {address?.current ? 'Current' : 'Past'} Address
        </div>
        <div className={styles.rowColXs}>
          <div className={styles.leftCol}>
            <div className={styles.row}>
              <span className={styles.streetAddress}>
                {`${address?.address_1}${
                  address?.address_2 ? ` Unit# ${address?.address_2}` : ''
                }`}
              </span>
              {renderTenancyType(address)}
            </div>
            <div
              className={styles.addressLine}
            >{`${address?.city}, ${address?.state} ${address?.zip}`}</div>

            {address.monthly_payment != null &&
              [0, 1].includes(address.tenancy_type) && (
                <div className={styles.monthlyPayment}>
                  {toCurrency(address.monthly_payment / 100, false)}{' '}
                  {address.tenancy_type === 0 ? 'mortgage' : 'rent'} / month
                </div>
              )}
            {address.start_date && (
              <div className={styles.moveInDate}>
                Moved in {moment(address.start_date).format('MMM YYYY')}
              </div>
            )}
          </div>
          <div className={styles.reasonForMoving}>
            <p className={styles.reasonForMovingTitle}>Reason for Moving</p>
            {address?.reason_for_moving ? (
              <span className={cx(styles.text, styles.wordBreak)}>
                {address?.reason_for_moving}
              </span>
            ) : (
              <span className={cx(styles.text, styles.italic)}>
                No reason given.
              </span>
            )}
          </div>
        </div>
        {address?.tenancy_type !== 0 && (
          <div className={cx(styles.summaryDetailsContainer)}>
            <AddressLandlordInformation residence={address || {}} />
          </div>
        )}
      </div>
    </>
  );
};

AddressContent.propTypes = {
  address: PropTypes.object,
  notApplicable: PropTypes.bool,
  isCoSignerAddress: PropTypes.bool,
};

export default withRouter(AddressContent);
