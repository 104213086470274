import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import MoldIcon from '../../../../../../../icons/Mold';
import TurboTenantSmallLogo from '../../../../../../../icons/TurboTenantSmallLogo';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './MoldDisclosureCA.module.scss';

const MoldDisclosureCA = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Mold Disclosure" icon={MoldIcon} />
      <label className={styles.label}>
        Do you have knowledge of any of the following on the property?
      </label>
      <HelpfulInfoList className={styles.list}>
        <HelpfulInfoListItem>Excess mold on the property</HelpfulInfoListItem>
        <HelpfulInfoListItem>
          Presence of mold that exceeds permissible limits
        </HelpfulInfoListItem>
        <HelpfulInfoListItem>
          Any reason to believe mold exists on the property
        </HelpfulInfoListItem>
      </HelpfulInfoList>
      <Field
        component={RadioGroup}
        id="custom_state_data.has_mold"
        name="custom_state_data.has_mold"
        className={styles.radioWrapper}
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'has_mold_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'has_mold_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition when="custom_state_data.has_mold" is={true}>
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.has_mold_explanation"
          name="custom_state_data.has_mold_explanation"
          label="Please explain what you have done to make sure the property is compliant based on safety guidelines:"
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      </Condition>
      <HelpfulInfoCard
        className={styles.helpfulInfoCard}
        noTitle
        Icon={TurboTenantSmallLogo}
        iconProps={{ className: styles.cardLogo }}
      >
        California also requires{' '}
        <a
          className={styles.link}
          href="https://www.cdph.ca.gov/Programs/CCDPHP/DEODC/EHLB/IAQ/CDPH%20Document%20Library/CDPH_Mold_Booklet_2021-May12.pdf"
        >
          this booklet on mold
        </a>{' '}
        to be given to every tenant. We've included a link to it in the lease
        agreement so you're covered.
      </HelpfulInfoCard>
    </div>
  );
};

MoldDisclosureCA.propTypes = {
  className: PropTypes.string,
  formValues: PropTypes.object,
};

export default MoldDisclosureCA;
