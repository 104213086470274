import React, { useEffect } from 'react';
import { useLottie } from 'lottie-react';
import PropTypes from 'prop-types';

import isiOSDevice from '../../../helpers/isIosDevice';
import IframeAnimation from '../iOSIframeAnimation';

import animationPathJson from './IncomeAnalysis.animation.json';

const IncomeAnalysisAnimation = ({ style = {}, onComplete }) => {
  const ANIMATION_DURATION = 4000; // 4 seconds
  const iOS = isiOSDevice();

  useEffect(() => {
    if (onComplete && iOS) {
      setTimeout(() => {
        onComplete();
      }, ANIMATION_DURATION);
    }
  }, []);

  if (iOS) {
    return (
      <IframeAnimation
        style={style}
        src="https://lottie.host/?file=5b615191-dfc2-431a-b59b-776ceffa12dd/09WNenT86b.json"
      />
    );
  }

  const options = {
    path: animationPathJson,
    loop: false,
    autoplay: true,
    onComplete,
  };

  const LoaderData = useLottie(options, style);
  return LoaderData.View;
};

IncomeAnalysisAnimation.propTypes = {
  style: PropTypes.object,
};

export default IncomeAnalysisAnimation;
