import React from 'react';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';

import { setLeaseNickName } from '../../../../../../components/AddressAutoSuggestFields/mutators';
import Button from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import Checkbox from '../../../../../../components/Checkbox';
import DateField from '../../../../../../components/DateField';
import FinalFormError from '../../../../../../components/FinalFormError';
import IconTooltip from '../../../../../../components/IconTooltip';
import Input from '../../../../../../components/Input';
import RadioGroup from '../../../../../../components/RadioGroup';
import InviteTenantsSection from '../../../../../../components/SetUpPaymentsForm/InviteTenants/InviteTenantsSection';
import LEASE_END_ACTIONS from '../../../../../../constants/lease_agreement/lease-end-actions';
import getValidFromDate from '../../../../../../helpers/get-valid-from-date';
import fieldRequired from '../../../../../../validators/fieldRequired';
import isDate from '../../../../../../validators/isDate';
import validLeaseEndDate from '../../../../../../validators/validLeaseEndDate';
import validLeaseStartDate from '../../../../../../validators/validLeaseStartDate';

import styles from './LeaseDetailsNewOnboarding.module.scss';

const LeaseDetailsNewOnboarding = ({
  onSubmit,
  initialValues,
  submitLabel,
}) => {
  return (
    <Card className={styles.formContainer}>
      <h2 className={styles.title}>
        Add Lease Details
        <IconTooltip tip="Leases in TurboTenant help you track and document the occupancy of tenants in a property over a period of time." />
      </h2>
      <p className={styles.subTitle}>You can edit this info later.</p>
      <Form
        keepDirtyOnReinitialize
        onSubmit={onSubmit}
        subscription={{
          submitting: true,
          values: true,
        }}
        mutators={{ ...arrayMutators, setLeaseNickName }}
        initialValues={{ renters: [], ...initialValues }}
      >
        {({ handleSubmit, submitting, values, form }) => (
          <form onSubmit={handleSubmit}>
            <FinalFormError />
            <div className={styles.leaseTitleColumn}>
              <Field
                label="Lease Nickname"
                component={Input}
                id="title"
                name="title"
                className={styles.leaseTitle}
                validate={fieldRequired}
                inputProps={{
                  'data-qa': 'lease-details-basic-title',
                }}
              />
            </div>
            <div className={styles.datesRow}>
              <Field
                className={styles.dateField}
                label="Start Date"
                component={DateField}
                hint={
                  values.end_date && !values.month_to_month
                    ? null
                    : '(Optional)'
                }
                name="start_date"
                id="start_date"
                validate={validLeaseStartDate}
                data-qa="lease-details-basic-start_date"
              />
              <Field
                className={styles.dateField}
                label="End Date"
                component={DateField}
                hint="(Optional)"
                name="end_date"
                id="end_date"
                validate={validLeaseEndDate}
                disabled={values.month_to_month}
                data-qa="lease-details-basic-end_date"
                fromDate={getValidFromDate(values.start_date)}
              />
            </div>
            <Field
              name="month_to_month"
              type="checkbox"
              id="month_to_month"
              label="Month-to-Month"
              component={Checkbox}
              className={styles.monthToMonth}
              data-qa="lease-details-month-to-month-checkbox"
            />
            {!values.month_to_month &&
            values.end_date &&
            !isDate(values.end_date) ? (
              <Field
                component={RadioGroup}
                id="end_action"
                name="end_action"
                label="When the lease ends on the date above, which of these should occur?"
                vertical
                className={styles.endAction}
                options={[
                  {
                    id: 'end_action_continue',
                    value: LEASE_END_ACTIONS.CONTINUE_MONTHLY,
                    label: 'Go month-to-month automatically',
                  },
                  {
                    id: 'end_action_terminate',
                    value: LEASE_END_ACTIONS.TERMINATE,
                    label: 'Let the lease terminate on the date above',
                  },
                ]}
                validate={fieldRequired}
              />
            ) : null}
            <InviteTenantsSection
              form={form}
              values={values}
              fieldName="renters"
            />
            <div className={styles.action}>
              <Button
                className={styles.submit}
                type="submit"
                loading={submitting}
                disabled={submitting}
                id="add_lease_details_submit"
                data-qa="lease-details-basic-button-continue"
              >
                {submitLabel || 'Next'}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </Card>
  );
};

LeaseDetailsNewOnboarding.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  submitLabel: PropTypes.string,
};

export default LeaseDetailsNewOnboarding;
