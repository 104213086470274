import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { bool, func } from 'prop-types';

import { isAdminLogin } from '../core/auth/authService';
import { getConfig } from '../services/configService';

const SiftScienceSetup = () => {
  // See https://sift.com/developers/docs/curl/javascript-api
  // TODO: Consider putting this in its own component.

  const [unListen, setUnlisten] = useState(null);
  const history = useHistory();
  const { SIFT_SCIENCE_BEACON_KEY } = getConfig();

  useEffect(() => {
    const isAdmin = isAdminLogin();

    // Do not load Sift script for admin type users.
    if (isAdmin) return;

    window._sift = window._sift || [];
    window._sift.push(['_setAccount', SIFT_SCIENCE_BEACON_KEY]);
    window._sift.push(['_trackPageview']);

    function ls() {
      const e = document.createElement('script');
      e.src = 'https://cdn.sift.com/s.js';
      document.body.appendChild(e);
    }

    if (window.attachEvent) {
      window.attachEvent('onload', ls);
    } else {
      window.addEventListener('load', ls, false);
    }

    // Detect and report page changes to Sift using a history listener.

    if (history != null && !unListen) {
      setUnlisten(
        history.listen(() => {
          window._sift.push(['_trackPageview']);
        }),
      );
    }

    return () => {
      unListen && unListen();
    };
  }, [unListen]);

  return <></>;
};

SiftScienceSetup.propTypes = {
  closeModal: func,
  errorModalOpened: bool,
};

export default SiftScienceSetup;
