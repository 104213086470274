import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Checkbox from '../../../../../components/Checkbox';
import Condition from '../../../../../components/Condition';
import InlineAlert from '../../../../../components/InlineAlert';
import Input from '../../../../../components/Input';
import trimEmail from '../../../../../components/Input/trimEmail';
import PhoneField from '../../../../../components/PhoneField';
import PropertyAddressFields from '../../../../../components/PropertyAddressFields';
import MaintenanceAltThickIcon from '../../../../../icons/MaintenanceAltThick';
import composeValidators from '../../../../../validators/composeValidators';
import conditionalValidation from '../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../validators/fieldRequired';
import validEmail from '../../../../../validators/validEmail';
import validPhone from '../../../../../validators/validPhone';
import SectionTitle from '../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../standalone-wizard/StandaloneWizardProvider';

import styles from './MaintenanceNeedsSection.module.scss';

const MaintenanceNeedsSection = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  const {
    input: { value: hasUsMail },
  } = useField('maintenance_needs.has_us_mail');

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Maintenance" icon={MaintenanceAltThickIcon} />
      <label className={styles.label}>
        To comply with habitability standards and tenant's rights for repairs,
        what is the best way for tenants to notify you of any maintenance needs?
      </label>
      <p className={styles.description}>(SELECT ALL THAT APPLY)</p>
      <div className={styles.fieldsContainer}>
        <Field
          id="maintenance_needs.has_tt_maintenance_requests"
          name="maintenance_needs.has_tt_maintenance_requests"
          type="checkbox"
          className={styles.checkbox}
          component={Checkbox}
          label={
            <div className={styles.checkboxLabelWrapper}>
              <span className={styles.checkboxLabel}>
                TurboTenant Maintenance Requests
              </span>
              <p className={styles.checkboxLabelDescription}>
                Tenants will be encouraged to submit maintenance requests with
                descriptions and photos. For free, you can manage statuses,
                track requests, and send work orders to pros.
              </p>
            </div>
          }
        />
        <div
          className={cx(styles.checkboxContainer, {
            [styles.noMargin]: hasUsMail,
          })}
        >
          <Field
            id="maintenance_needs.has_us_mail"
            name="maintenance_needs.has_us_mail"
            type="checkbox"
            className={styles.checkbox}
            component={Checkbox}
            label="U.S. Mail"
          />
          <Condition when="maintenance_needs.has_us_mail" is={true}>
            <PropertyAddressFields
              className={styles.addressFields}
              fieldNames={{
                address: 'maintenance_needs.address',
                unit: 'maintenance_needs.unit',
                city: 'maintenance_needs.city',
                state: 'maintenance_needs.state',
                zip: 'maintenance_needs.zip',
              }}
              fieldsClassName={{
                address: styles.addressField,
                unit: styles.unitField,
                city: styles.cityField,
                state: styles.stateField,
                zip: styles.zipField,
              }}
            />
          </Condition>
        </div>
        <div className={styles.checkboxContainer}>
          <Field
            id="maintenance_needs.has_e_mail"
            name="maintenance_needs.has_e_mail"
            type="checkbox"
            className={styles.checkbox}
            component={Checkbox}
            label="E-Mail"
          />
          <Condition when="maintenance_needs.has_e_mail" is={true}>
            <Field
              id="maintenance_needs.e_mail"
              name="maintenance_needs.e_mail"
              type="email"
              className={styles.emailField}
              component={Input}
              label="Email"
              parse={trimEmail}
              validate={composeValidators(validEmail, fieldRequired)}
              inputProps={{
                autoComplete: 'username',
              }}
            />
          </Condition>
        </div>
        <div className={styles.checkboxContainer}>
          <Field
            id="maintenance_needs.has_text_number"
            name="maintenance_needs.has_text_number"
            type="checkbox"
            className={styles.checkbox}
            component={Checkbox}
            label="Text Message"
          />
          <Condition when="maintenance_needs.has_text_number" is={true}>
            <Field
              id="maintenance_needs.text_phone_number"
              name="maintenance_needs.text_phone_number"
              type="text"
              className={styles.phoneField}
              component={PhoneField}
              label="Phone Number"
              validate={composeValidators(validPhone, fieldRequired)}
            />
          </Condition>
        </div>
        <div className={styles.checkboxContainer}>
          <Field
            id="maintenance_needs.has_other"
            name="maintenance_needs.has_other"
            type="checkbox"
            className={styles.checkbox}
            component={Checkbox}
            label="Other"
          />
          <Condition when="maintenance_needs.has_other" is={true}>
            <Field
              id="maintenance_needs.other_description"
              name="maintenance_needs.other_description"
              type="text"
              className={styles.otherField}
              component={Input}
              placeholder="Other"
              validate={fieldRequired}
            />
          </Condition>
        </div>
      </div>
      <InlineAlert
        className={styles.infoNote}
        title="Habitability standards & tenant's right for repairs"
      >
        The implied warranty of habitability is a policy enforced throughout the
        U.S. which requires landlords to provide a generally safe premise fit
        for human habitation. Tenants have legal rights to demand that timely
        repairs are done to fix structural, electrical, plumbing, pest
        infestation, locks/windows and lead paint removal issues.
      </InlineAlert>
    </div>
  );
};

MaintenanceNeedsSection.propTypes = {
  className: PropTypes.string,
};

export default MaintenanceNeedsSection;
