import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Leads = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 25" {...rest} fill={color}>
    <path d="M1.9 10.228a.6.6 0 0 1 .6-.6h4.13a.6.6 0 0 1 .6.6v2.479c0 .357-.034.795-.231 1.159a1.265 1.265 0 0 1-.595.56v3.237a.6.6 0 0 1-.6.6H3.326a.6.6 0 0 1-.6-.6v-3.235a1.245 1.245 0 0 1-.598-.56c-.197-.364-.228-.804-.228-1.161v-2.479Zm1.2.6v1.879c0 .328.039.507.084.591a.12.12 0 0 0 .017.026l.004.004s.032.018.121.018a.6.6 0 0 1 .6.6v3.117h1.278v-3.117a.6.6 0 0 1 .6-.6c.085 0 .114-.018.114-.018s.004-.002.006-.005a.138.138 0 0 0 .02-.029c.047-.086.086-.268.086-.587v-1.879H3.1ZM16.77 10.228a.6.6 0 0 1 .6-.6h4.13a.6.6 0 0 1 .6.6v2.479c0 .357-.035.795-.232 1.159a1.264 1.264 0 0 1-.594.56v3.237a.6.6 0 0 1-.6.6h-2.478a.6.6 0 0 1-.6-.6v-3.235a1.252 1.252 0 0 1-.598-.56c-.197-.364-.229-.804-.229-1.161v-2.479Zm1.2.6v1.879c0 .328.038.507.084.591a.118.118 0 0 0 .017.026.021.021 0 0 0 .004.004s.031.018.12.018a.6.6 0 0 1 .6.6v3.117h1.279v-3.117a.6.6 0 0 1 .6-.6c.084 0 .114-.018.114-.018l.006-.005a.14.14 0 0 0 .02-.029c.046-.086.086-.268.086-.587v-1.879h-2.93ZM8.509 10.228a.6.6 0 0 1 .6-.6h5.782a.6.6 0 0 1 .6.6v4.13a2.26 2.26 0 0 1-1.652 2.171v4.438a.6.6 0 0 1-.6.6h-2.478a.6.6 0 0 1-.6-.6V16.53a2.26 2.26 0 0 1-1.652-2.17v-4.13Zm1.2.6v3.53c0 .578.474 1.053 1.052 1.053a.6.6 0 0 1 .6.6v4.356h1.278v-4.356a.6.6 0 0 1 .6-.6c.577 0 1.052-.475 1.052-1.052v-3.53H9.71ZM4.565 6.698a.637.637 0 0 0-.639.639c0 .354.285.64.64.64.353 0 .638-.286.638-.64a.637.637 0 0 0-.639-.64Zm-1.839.639c0-1.017.822-1.84 1.84-1.84 1.016 0 1.838.823 1.838 1.84 0 1.017-.822 1.84-1.839 1.84a1.837 1.837 0 0 1-1.839-1.84ZM19.435 6.698a.637.637 0 0 0-.64.639c0 .354.285.64.64.64.354 0 .639-.286.639-.64a.637.637 0 0 0-.64-.64Zm-1.84.639c0-1.017.823-1.84 1.84-1.84 1.017 0 1.839.823 1.839 1.84 0 1.017-.822 1.84-1.84 1.84a1.837 1.837 0 0 1-1.838-1.84ZM12 5.046A1.466 1.466 0 1 0 12 7.978 1.466 1.466 0 0 0 12 5.046ZM9.335 6.51a2.666 2.666 0 1 1 5.332.001 2.666 2.666 0 0 1-5.332-.001Z" />
  </svg>
);

Leads.defaultProps = {
  width: 24,
  height: 25,
  color: colors.primary,
};

Leads.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Leads);
