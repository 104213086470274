import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Input from '../../../../../components/Input';
import RadioGroup from '../../../../../components/RadioGroup';
import conditionalValidation from '../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../validators/fieldRequired';

import styles from './SharedUtilitiesFields.module.scss';

export const SharedUtilitiesFields = ({ className, standaloneWizard }) => {
  const {
    input: { value: sharedUtilities },
  } = useField('custom_state_data.shared_utilities');

  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standaloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <label
        className={styles.label}
        htmlFor="custom_state_data.shared_utilities"
      >
        Are there any shared utilities for the property?
      </label>
      <Field
        component={RadioGroup}
        id="custom_state_data.shared_utilities"
        name="custom_state_data.shared_utilities"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'custom_state_data.shared_utilities_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'custom_state_data.shared_utilities_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      {sharedUtilities && (
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.utilities_devided_as_follows"
          name="custom_state_data.utilities_devided_as_follows"
          label="Please list all shared utilities and how they are divided."
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
            className: styles.termsField,
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      )}
    </div>
  );
};

SharedUtilitiesFields.propTypes = {
  className: PropTypes.string,
  formValues: PropTypes.object,
  standaloneWizard: PropTypes.bool,
};

export default SharedUtilitiesFields;
