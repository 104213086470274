import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import Sprinkler from '../../../../../../../icons/Sprinkler';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './FireSafetyDisclosure.module.scss';

const FireSafetyDisclosureWA = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Fire and Safety Disclosure" icon={Sprinkler} />
      <label className={styles.label}>Is this a multi-family property?</label>
      <p className={styles.description}>
        For multi-family properties, Washington law requires that you disclose
        this information, along with any emergency notification, evacuation or
        relocation plan to be attached to the lease.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.fire_safety_disclosure"
        name="custom_state_data.fire_safety_disclosure"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'fire_safety_disclosure_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'fire_safety_disclosure_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition when="custom_state_data.fire_safety_disclosure" is={true}>
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.fire_safety_explanation"
          name="custom_state_data.fire_safety_explanation"
          label="Please provide the following information: are the smoke alarms battery operated or hard wired? Is there a fire sprinkler system and/or fire alarm on the premises? Is there an emergency notification, evacuation or relocation plan? If so, please add that below or attach that plan to the lease agreement."
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      </Condition>
    </div>
  );
};

FireSafetyDisclosureWA.propTypes = {
  className: PropTypes.string,
};

export default FireSafetyDisclosureWA;
