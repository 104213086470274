import React from 'react';
import cx from 'classnames';
import kebabCase from 'lodash.kebabcase';
import PropTypes from 'prop-types';

import DocumentIcon from '../../icons/DocumentUpload';
import MoreIcon from '../../icons/More';
import ButtonPopover from '../ButtonPopover';
import FlatButton from '../FlatButton';
import IconButton from '../IconButton';
import Spinner from '../Spinner';

import styles from './FilesList.module.scss';

const FilesList = ({
  files,
  actions,
  withLinks,
  loading,
  className,
  fileNameClassName,
}) => {
  if (!files || files.length === 0) {
    return null;
  }

  const renderDesktopActions = (index) => {
    if (!actions.length) {
      return null;
    }
    return (
      <div className={cx(styles.actions, styles.desktop)}>
        {actions.map((action, key) => (
          <IconButton
            key={key}
            icon={action.icon}
            onClick={(e) => {
              e.preventDefault();
              action.handler(index);
            }}
            className={styles.action}
          />
        ))}
      </div>
    );
  };

  const renderMobileActions = (index) => {
    if (!actions.length) {
      return null;
    }

    /**
     * When there is only one action, we still render it as button on mobile
     * instead of rendering it in the dropdown
     */
    if (actions.length === 1) {
      return (
        <div className={cx(styles.actions, styles.mobile)}>
          <IconButton
            icon={actions[0].icon}
            onClick={(e) => {
              e.preventDefault();
              actions[0].handler(index);
            }}
            className={styles.action}
          />
        </div>
      );
    }

    return (
      <div className={cx(styles.actions, styles.mobile)}>
        <ButtonPopover
          customAction={(props) => (
            <IconButton className={styles.action} icon={MoreIcon} {...props} />
          )}
        >
          {actions.map((action, key) => (
            <FlatButton
              key={key}
              icon={action.icon}
              onClick={(e) => {
                e.preventDefault();
                action.handler(index);
              }}
              className={styles.dropdownAction}
            >
              {action.title}
            </FlatButton>
          ))}
        </ButtonPopover>
      </div>
    );
  };

  const renderFilesList = () =>
    files.map((file, index) => {
      const ListElement = withLinks && file.url ? 'a' : 'div';
      return (
        <div className={styles.item} key={`${index}_${file.name}`}>
          <ListElement
            {...(withLinks && file.url
              ? {
                  href: file.url,
                  target: '_blank',
                  rel: 'noopener noreferrer',
                }
              : {})}
            className={styles.itemDetails}
          >
            <span className={styles.itemIcon}>
              <DocumentIcon />
            </span>
            <span className={styles.itemTitle}>
              <span
                className={cx(
                  styles.itemName,
                  {
                    [styles.link]: withLinks && file.url,
                  },
                  fileNameClassName,
                )}
                data-qa={kebabCase(`file-name-${file.name}`)}
              >
                {file.name}
              </span>
              {file.subtitle && (
                <span
                  className={cx(styles.itemSubtitle, {
                    [styles.link]: withLinks && file.url,
                  })}
                >
                  {file.subtitle}
                </span>
              )}
            </span>
          </ListElement>
          <div className={styles.actionWrapper}>
            {file.conditionReport?.status === 'NEEDS_APPROVAL' && (
              <div
                className={cx(styles.reportStatusTag, {
                  [styles[file.conditionReport.status.toLowerCase()]]: true,
                })}
              >
                In Review
              </div>
            )}
            {renderDesktopActions(index)}
            {renderMobileActions(index)}
          </div>
        </div>
      );
    });

  return (
    <div className={cx(styles.container, className)}>
      {loading && (
        <div className={styles.loadingContainer}>
          <Spinner secondary className={styles.spinner} />
        </div>
      )}
      {renderFilesList()}
    </div>
  );
};

FilesList.propTypes = {
  className: PropTypes.string,
  files: PropTypes.array,
  withLinks: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.object,
      ]),
      handler: PropTypes.func,
    }),
  ),
  loading: PropTypes.bool,
  conditionReport: PropTypes.object,
  fileNameClassName: PropTypes.string,
};

FilesList.defaultProps = {
  files: [],
  actions: [],
};

export default FilesList;
