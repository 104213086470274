import React, { memo } from 'react';
import PropTypes from 'prop-types';

const HouseCondition = ({ color, ...props }) => (
  <svg viewBox="0 0 28 26" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M4.748 12.525c.414 0 .755.304.81.699l.007.11v11.049h8.98c.412 0 .754.304.808.699l.008.11c0 .409-.308.747-.706.8l-.11.008H4.747a.814.814 0 0 1-.809-.699l-.007-.11V13.333c0-.446.365-.808.816-.808Zm16.327 1.078c3.457 0 6.258 2.774 6.258 6.198 0 3.424-2.801 6.199-6.258 6.199s-6.259-2.775-6.259-6.199 2.801-6.198 6.259-6.198Zm0 1.617c-2.556 0-4.626 2.05-4.626 4.581 0 2.53 2.07 4.582 4.626 4.582 2.555 0 4.626-2.051 4.626-4.582 0-2.531-2.07-4.581-4.626-4.581Zm3.3 2.395a.803.803 0 0 1 .075 1.053l-.08.09-3.292 3.235a.822.822 0 0 1-1.065.072l-.091-.08-2.15-2.162a.803.803 0 0 1 .009-1.143.822.822 0 0 1 1.064-.07l.09.079 1.575 1.584 2.711-2.663a.822.822 0 0 1 1.155.005ZM14.453.802l.098.076 12.517 11.32a.803.803 0 0 1 .053 1.141.822.822 0 0 1-1.058.126l-.095-.074L14 2.57 2.033 13.391a.822.822 0 0 1-1.065.03L.88 13.34a.803.803 0 0 1-.03-1.055l.083-.087L13.45.878a.823.823 0 0 1 1.002-.076Zm1.18 5.255c.451 0 .817.362.817.808V10.1a.812.812 0 0 1-.816.809h-3.266a.812.812 0 0 1-.816-.809V6.865c0-.446.366-.808.816-.808Zm-.816 1.617h-1.632V9.29h1.632V7.674Zm7.347-5.93c.414 0 .755.305.81.7l.007.11v3.772a.812.812 0 0 1-.817.809.814.814 0 0 1-.809-.699l-.007-.11V3.362h-2.993a.814.814 0 0 1-.81-.7l-.007-.109c0-.41.308-.747.706-.8l.11-.008h3.81Z"
    />
  </svg>
);

HouseCondition.defaultProps = {
  width: 28,
  height: 26,
  color: 'currentColor',
};

HouseCondition.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(HouseCondition);
