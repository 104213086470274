import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import KeysIcon from '../../../../icons/Keys';
import ListingHouseIcon from '../../../../icons/ListingHouse';
import fieldRequired from '../../../../validators/fieldRequired';
import Input from '../../../Input';
import RentalDetailsFields from '../../../RentalDetailsFields';
import SectionTitleWithIcon from '../../../SectionTitleWithIcon';

import styles from './PropertyInformationSingleUnits.module.scss';

const PropertyInformationSingleUnits = ({ hasRoomRentals }) => {
  return (
    <div
      className={cx(styles.container, {
        [styles.hasRoomsMargin]: hasRoomRentals,
      })}
    >
      {hasRoomRentals ? (
        <>
          <SectionTitleWithIcon
            className={styles.roomsSection}
            centered={false}
            title={'Add Room'}
            icon={KeysIcon}
            iconProps={{ height: 27, width: 27 }}
            iconClassNames={styles.icon}
          />
          <p className={styles.sectionDescription}>
            Start with one room, even if you have more. You'll be able to set up
            the rest later.
          </p>
          <Field
            id={`room_name`}
            name={`room_name`}
            className={styles.field}
            component={Input}
            label="Room Name"
            validate={fieldRequired}
            maxLength={50}
          />
        </>
      ) : (
        <>
          <SectionTitleWithIcon
            centered={false}
            title={'Rental Details'}
            icon={ListingHouseIcon}
            iconProps={{ height: 27, width: 27 }}
            iconClassNames={styles.icon}
          />
          <RentalDetailsFields />
        </>
      )}
    </div>
  );
};

PropertyInformationSingleUnits.propTypes = {
  hasRoomRentals: PropTypes.bool,
};

export default PropertyInformationSingleUnits;
