import React from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Input';
import fieldRequired from '../../../../../../validators/fieldRequired';

import styles from './EditNicknameForm.module.scss';

const EditNicknameForm = ({ nickname, onSubmit, onCancel }) => (
  <Form
    keepDirtyOnReinitialize
    onSubmit={onSubmit}
    subscription={{
      submitting: true,
      values: true,
    }}
    initialValues={{
      nickname,
    }}
  >
    {({ handleSubmit, submitting }) => (
      <form onSubmit={handleSubmit} className={styles.form}>
        <Field
          component={Input}
          name="nickname"
          id="nickname"
          placeholder="Enter nickname"
          className={styles.name}
          validate={fieldRequired}
          maxLength={25}
          help={(val = '') => `${val.length} / 25 characters used`}
        />
        <div className={styles.formActions}>
          <Button className={styles.formBtn} type="submit" loading={submitting}>
            Save
          </Button>
          <Button className={styles.formBtn} secondary onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </form>
    )}
  </Form>
);

export default EditNicknameForm;
