const showIntercom = () => {
  window.Intercom('show');

  let intercomShown = false;

  window.Intercom('onShow', function () {
    intercomShown = true;
  });

  setTimeout(() => {
    if (!intercomShown) {
      window.atatus && window.atatus.notify('intercom bubble not present');
    }
  }, 2000);
};

export default showIntercom;
