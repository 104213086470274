import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Spinner from '../Spinner';

import styles from './Button.module.scss';

const Button = ({
  to,
  href,
  type,
  children,
  disabled,
  secondary,
  tertiary,
  quaternary,
  small,
  loading,
  icon: Icon,
  iconProps: { className: iconClassName, ...otherIconProps },
  iconAfter,
  className,
  fullWidth,
  variant,
  dataQa,
  ...otherProps
}) => {
  let Element = 'button';
  const props = {};

  if (to && !(disabled || loading)) {
    Element = Link;
    props.to = to;
  } else if (href && !(disabled || loading)) {
    Element = 'a';
    props.href = href;
  } else {
    props.type = type;
  }

  const buttonContent = (children) => {
    return (
      <React.Fragment>
        {Icon && !iconAfter && (
          <Icon
            className={cx(styles.icon, iconClassName)}
            {...otherIconProps}
          />
        )}
        {children}
        {Icon && iconAfter && (
          <Icon
            className={cx(styles.icon, iconClassName, {
              [styles.iconAfter]: iconAfter,
            })}
            {...otherIconProps}
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <Element
      data-qa={dataQa}
      {...props}
      {...otherProps}
      className={cx(
        styles.button,
        {
          [styles.disabled]: disabled || loading,
          [styles.quaternary]:
            !variant && quaternary && !tertiary && !secondary,
          [styles.tertiary]: !variant && tertiary && !quaternary && !secondary,
          [styles.secondary]: !variant && secondary && !tertiary && !quaternary,
          [styles.primary]: !(variant || secondary || tertiary || quaternary),
          [styles.small]: small,
          [styles.fullWidth]: fullWidth,
        },
        variant ? styles[`variant-${variant}`] : '',
        className,
      )}
      disabled={disabled || loading}
    >
      {loading ? (
        <Spinner
          data-qa="button-loading-spinner"
          secondary={secondary || tertiary}
        />
      ) : (
        buttonContent(children)
      )}
    </Element>
  );
};

Button.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
  iconProps: PropTypes.object,
  iconAfter: PropTypes.bool,
  type: PropTypes.string,
  href: PropTypes.string,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  quaternary: PropTypes.bool,
  small: PropTypes.bool,
  loading: PropTypes.bool,
  dataQa: PropTypes.string,
  fullWidth: PropTypes.bool,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'prominent',
    'danger',
    'dangerSecondary',
    'reverse',
    'ghost',
  ]),
};

Button.defaultProps = {
  type: 'button',
  secondary: false,
  tertiary: false,
  quaternary: false,
  iconProps: {},
};

export default Button;
