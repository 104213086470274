import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';
import CheckMarkFilled from '../../icons/CheckMarkFilled';
import Button from '../Button';
import DottedHeader from '../DottedHeader';
import Modal from '../Modal';
import { H2, Paragraph } from '../Typography';

import styles from './FeedbackThankyouModal.module.scss';

const FeedbackThankyouModal = ({ open, onClose }) => (
  <Modal
    onClose={onClose}
    open={open}
    showCloseIcon
    size="small"
    bannerClassName={styles.banner}
    banner={
      <DottedHeader
        className={styles.banner}
        icon={CheckMarkFilled}
        iconProps={{
          className: styles.bannerIcon,
          color: colors.slimProgressBarFill,
        }}
        circleProps={{ transparent: true }}
        circleCenterClassName={styles.bannerCircle}
        patternProps={{ className: styles.bannerPattern }}
      />
    }
  >
    <Modal.Content noFooter>
      <H2 className={styles.title}>Thanks for your feedback! </H2>
      <Paragraph className={styles.desc}>
        Your feedback helps us make the best software for over 650,000 landlords
        nationwide.
      </Paragraph>

      <div className={styles.btnWrapper}>
        <Button onClick={onClose} secondary className={styles.button}>
          Continue
        </Button>
      </div>
    </Modal.Content>
  </Modal>
);

FeedbackThankyouModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FeedbackThankyouModal;
