import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const AppleIcon = (props) => (
  <svg {...props} viewBox="0 0 24 24" fill={props.color}>
    <path d="M15.904 1.078a.438.438 0 0 0-.205.022c-1 .2-2.099.8-2.799 1.5-.6.6-1.1 1.6-1.1 2.5 0 .2.2.4.4.4 1.1-.1 2.2-.7 2.9-1.4.6-.8 1.1-1.7 1.1-2.6 0-.225-.113-.394-.296-.422zM16.2 5.4c-1.8 0-2.599 1.1-3.799 1.1-1.3 0-2.5-1-4-1-2.1 0-5.3 2-5.3 6.6C3 16.3 6.8 21 9 21c1.3 0 1.6-.8 3.4-.8 1.8 0 2.2.8 3.5.8 1.5 0 2.6-1.6 3.5-2.9.4-.7.7-1.1 1-1.7.2-.4 0-.8-.4-1-2.6-1.3-3.1-5.5-.2-7 .5-.3.6-.9.2-1.2-1.1-1-2.7-1.8-3.8-1.8z" />
  </svg>
);

AppleIcon.defaultProps = {
  width: 14,
  height: 16,
  color: colors.white,
};

AppleIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(AppleIcon);
