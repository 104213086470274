import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Content.module.scss';

const Content = ({ className, ...rest }) => {
  return <div className={cx(styles.container, className)} {...rest} />;
};

Content.propTypes = {
  className: PropTypes.string,
};

export default Content;
