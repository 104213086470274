import React from 'react';
import { useLottie } from 'lottie-react';
import PropTypes from 'prop-types';

import IframeAnimation from '../../../components/Animations/iOSIframeAnimation';
import isiOSDevice from '../../../helpers/isIosDevice';

import animationPathJson from './AuditUpload.animation.json';

const AuditUploadAnimation = ({ style }) => {
  const iOS = isiOSDevice();

  if (iOS) {
    return (
      <IframeAnimation
        style={style}
        src="https://lottie.host/?file=fc60dc9d-3d5b-4542-a985-008507f6c81b/gc2XVS5P6A.json"
      />
    );
  }

  const options = {
    path: animationPathJson,
    loop: true,
    autoplay: true,
  };

  const LoaderData = useLottie(options, style);
  return LoaderData.View;
};

AuditUploadAnimation.propTypes = {
  style: PropTypes.object,
};

export default AuditUploadAnimation;
