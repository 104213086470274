import React, { memo } from 'react';
import PropTypes from 'prop-types';

const CheckmarkCircle = (props) => (
  <svg {...props} viewBox="0 0 20 20">
    <path
      fill={props.color}
      d="M10 0c5.522 0 10 4.477 10 10s-4.478 10-10 10C4.476 20 0 15.524 0 10S4.476 0 10 0zm0 1.414A8.585 8.585 0 001.414 10 8.586 8.586 0 1010 1.414zm4.557 5.275a.707.707 0 01-.034 1l-6.06 5.656a.707.707 0 01-.983-.017l-2.02-2.02a.707.707 0 011-1l1.537 1.537 5.561-5.19a.707.707 0 011 .034z"
    />
  </svg>
);

CheckmarkCircle.defaultProps = {
  color: '#43A047',
  width: 30,
  height: 30,
};

CheckmarkCircle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(CheckmarkCircle);
