export default {
  'info': [
    'co_applicant',
    'applying_as_tenant',
    'co_applicant_description',
    'smoking',
    'birthdate',
    'first_name',
    'last_name',
  ],
  'employments': ['employments', 'bank', 'income_sources'],
  'mailing-address': ['residences'],
  'residences': ['residences'],
  'selfReported': [
    'evicted',
    'evicted_description',
    'civil_action',
    'civil_action_description',
    'conviction',
    'conviction_description',
  ],
  'additionalComments': ['additional_comments'],
  'otherInfo': [
    'disability',
    'disability_description',
    'additional_comments',
    'how_did_you_hear',
    'how_did_you_hear_other',
    'custom_application_question_1',
    'custom_application_question_2',
    'custom_application_question_3',
    'custom_application_question_4',
    'custom_application_answer_1',
    'custom_application_answer_2',
    'custom_application_answer_3',
    'custom_application_answer_4',
  ],
  'generalDetails': ['contacts', 'pets', 'vehicles'],
};
