import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import UniversalMessaging from '../components/UniversalMessaging';
import { isAdminLogin } from '../core/auth/authService';

const UniversalMessagingSetUp = () => {
  const history = useHistory();
  const [universalMessagingVisible, setUniversalMessagingVisible] =
    useState(false);

  useEffect(() => {
    const checkVisibility = (pathname) => {
      if (
        isAdminLogin() ||
        !pathname.startsWith('/owners') ||
        pathname.includes('messages') ||
        isMobile
      ) {
        setUniversalMessagingVisible(false);
      } else {
        setUniversalMessagingVisible(true);
      }
    };

    checkVisibility(history.location.pathname);

    const unlisten = history.listen(({ pathname: newPathname }) => {
      checkVisibility(newPathname);
    });

    return () => unlisten();
  }, [history]);

  if (!universalMessagingVisible) {
    return null;
  }

  return <UniversalMessaging />;
};

export default UniversalMessagingSetUp;
