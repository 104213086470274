import moment from 'moment';

import {
  DATE_FORMATS_ARRAY,
  SQL_DATE_FORMAT,
} from '../../../constants/charges_date_formats';

export const prepareDate = ({ date, value, type, due_day_of_month }) => {
  let currentDate;

  if (date) {
    currentDate = moment(date, DATE_FORMATS_ARRAY);
  } else {
    currentDate = moment();
  }

  currentDate.set('date', due_day_of_month);

  if (type && value) {
    currentDate.set(type, value);
  }

  if (due_day_of_month === 'Last Day') {
    return currentDate.endOf('month').format(SQL_DATE_FORMAT);
  }

  return currentDate.format(SQL_DATE_FORMAT);
};
