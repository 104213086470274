import React from 'react';

import Input from '../Input';

import CoreField from './CoreField';

export const NumericField = (props) => (
  <CoreField
    component={Input}
    type="number"
    pattern="[0-9]*"
    parse={(value) => (value ? value.replace(/[^0-9.]/gi, '') : '')}
    {...props}
  />
);

export default NumericField;
