import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import config from '../../../config';
import { EmailQueryStringContext } from '../providers/EmailQueryStringProvider';

const B = ({ children }) => <b style={{ fontWeight: 600 }}>{children}</b>;

B.propTypes = {
  children: PropTypes.node,
};

const groupDescription = {
  landlord_platform_updates: <B>Platform Updates</B>,
  renters_from_turbotenant: <B>Messages from TurboTenant</B>,
  renters_rental_notifications: <B>Rental Notifications</B>,
  renters_messages_from_landlord: <B>Messages from the Landlord</B>,
  landlord_new_leads: (
    <>
      <B>Account Alerts - Leads</B> emails
    </>
  ),
  landlord_messages_from_renters: (
    <>
      <B>Account Alerts - Messages From Renters</B>
    </>
  ),

  landlord_leases: (
    <>
      <B>Account Alerts - Leases</B> emails
    </>
  ),

  landlord_properties: (
    <>
      <B>Account Alerts - Property</B> emails
    </>
  ),

  landlord_applicants: (
    <>
      <B>Account Alerts - Applicants</B> emails
    </>
  ),

  landlord_check_ins_maintenance: (
    <>
      <B>Account Alerts - Check-ins & Maintenance Feedback</B> emails
    </>
  ),

  landlord_promotions: (
    <>
      <B>Updates & Offers - Promotions </B> emails
    </>
  ),
};

const UnsubscribeCategories = () => {
  const { campaign } = useContext(EmailQueryStringContext);
  const group = config.unsubscribeGroups?.[campaign] || null;
  const unsubscribeCategory = groupDescription?.[group] || null;
  if (!unsubscribeCategory) {
    return null;
  }
  return (
    <p
      style={{
        color: '#516A80',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        letterSpacing: '0',
        lineHeight: '21px',
        marginBottom: 8,
        marginTop: 14,
      }}
    >
      You are currently receiving {unsubscribeCategory}
    </p>
  );
};

export default UnsubscribeCategories;
