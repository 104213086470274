import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Share = ({ thin, ...props }) => {
  const thickIcon = (
    <svg {...props} viewBox={props.viewBox || '0 0 24 22'} fill={props.color}>
      <path d="M23.052.338a.85.85 0 01.688.247.812.812 0 01.234.403.787.787 0 01-.022.47l-3.848 17.205c-.123.55-.76.831-1.27.56L13.32 16.29l-2.99 4.958c-.43.71-1.523.46-1.617-.316l-.007-.109v-6.908L.555 10.851a.83.83 0 01-.125-1.51l.108-.052L22.742.42a.853.853 0 01.303-.083zM10.448 14.763v2.947l1.346-2.23-1.346-.717zM21.686 3.62l-10.233 9.186-.4.36 7.596 4.038 3.037-13.584zm-2.695.123L3.21 10.043l6.174 2.322 9.606-8.623z" />
    </svg>
  );

  const thinIcon = (
    <svg {...props} viewBox={props.viewBox || '0 0 18 16'} fill={props.color}>
      <path
        d="M16.918.5c.155.005.292.07.394.17l.013.012.015.017c.072.075.124.17.146.276.022.1.02.206-.016.308l-2.676 12.105a.608.608 0 0 1-.882.394l-3.829-2.062-2.076 3.485c-.294.493-1.038.328-1.12-.2l-.007-.098V10.05L1.218 7.895a.587.587 0 0 1-.106-1.051l.094-.047L16.624.563A.587.587 0 0 1 16.869.5h.013ZM8.089 10.647v2.07l.934-1.568-.934-.502Zm7.805-7.837L8.51 9.522l5.274 2.84 2.11-9.552Zm-1.873.087L3.063 7.327 7.35 8.96l6.67-6.063Z"
        fillRule="evenodd"
      />
    </svg>
  );

  return thin ? thinIcon : thickIcon;
};

Share.defaultProps = {
  thin: false,
  width: 24,
  height: 22,
  color: colors.text,
};

Share.propTypes = {
  thin: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Share);
