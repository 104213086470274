import React from 'react';
import cx from 'classnames';

import PremiumBadge from '../../icons/PremiumBadge';
import { segmentTracking } from '../../services/utilities';
import getFieldError from '../../validators/get-field-error';
import RadioButton from '../RadioButton';

import styles from './PremiumRadioButtonOffer.module.scss';

const OfferButtonRadioButton = ({
  id,
  input,
  title,
  desc,
  desc2,
  tracking,
  reverseButtons,
}) => {
  const premium = id === 'premium';
  return (
    <label
      className={cx(styles.offerButton, {
        [styles.premium]: premium,
        [styles.reverseButtons]: reverseButtons,
      })}
      htmlFor={`offerButton-option${id}`}
      id={`offerButton-option-${id}`}
    >
      {premium && <PremiumBadge className={styles.premiumBadge} />}
      <div>
        <RadioButton
          className={styles.offerButtonRadio}
          checked={input.value === id}
          value={id}
          name={input.name}
          id={'offerButton-option' + id}
          onChange={() => {
            input.onChange(id);
          }}
          onClick={() => {
            tracking?.event &&
              segmentTracking(tracking.event, {
                location: tracking.location,
                experiment: tracking?.experiment,
              });
          }}
        />
      </div>
      <div className={styles.offerButtonData}>
        <span className={styles.offerButtonTitle}>{title}</span>
        <span className={styles.offerButtonDesc}>{desc}</span>
        <span className={styles.offerButtonDesc2}>{desc2}</span>
      </div>
    </label>
  );
};

const PremiumRadioButtonOffer = ({
  input,
  premium,
  free,
  meta,
  reverseButtons = false,
}) => {
  const error = getFieldError(meta);
  const renderButtons = () => {
    const elements = [
      <OfferButtonRadioButton
        key={'premium'}
        input={input}
        id={'premium'}
        {...premium}
        reverseButtons={reverseButtons}
      />,
      <OfferButtonRadioButton
        key={'free'}
        input={input}
        id={'free'}
        {...free}
      />,
    ];

    if (reverseButtons) return elements.reverse();
    else return elements;
  };
  return (
    <>
      {renderButtons()}
      {error && <span className={styles.error}>{error}</span>}
    </>
  );
};

export default PremiumRadioButtonOffer;
