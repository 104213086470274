import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ChromeIcon from '../../assets/chrome.png';
import EdgeIcon from '../../assets/edge.png';
import FirefoxIcon from '../../assets/firefox.png';
import Button from '../../components/Button';
import FlatButton from '../../components/FlatButton';
import Modal from '../../components/Modal';

import styles from './IESupportModal.module.scss';

const IESupportModal = ({ open, onClose }) => {
  const browserIcon = (src, alt) => {
    return <img alt={alt} src={src} className={styles.iconStyle} />;
  };

  return (
    <Modal open={open} className={styles.container}>
      <span className={cx(styles.subTitle, styles.margin, styles.bottomSmall)}>
        TurboTenant No Longer Supports Internet Explorer.
      </span>
      <br />
      <span className={styles.checkboxRadioLabel}>
        While you can continue to use it in Internet Explorer, you'll have a
        significantly easier time using TurboTenant on one of the following
        browsers:
      </span>
      <div
        className={cx(
          styles.row,
          styles.vertical,
          styles.horizontal,
          styles.center,
          styles.margin,
          styles.bottomLarge,
          styles.topMedium,
        )}
      >
        <div
          className={cx(
            styles.column,
            styles.vertical,
            styles.horizontal,
            styles.center,
            styles.margin,
            styles.rightLarge,
          )}
        >
          {browserIcon(ChromeIcon, 'Chrome')}

          <span className={styles.cardSidebarTitle}>Chrome</span>
          <Button
            secondary
            small
            className={cx(styles.extendButton, styles.margin, styles.topMedium)}
            onClick={() =>
              window.open('https://www.google.com/chrome/', '_blank')
            }
          >
            Learn more
          </Button>
        </div>
        <div
          className={cx(
            styles.column,
            styles.horizontal,
            styles.vertical,
            styles.center,
            styles.margin,
            styles.rightLarge,
          )}
        >
          {browserIcon(FirefoxIcon, 'Firefox')}
          <span
            className={cx(
              styles.cardSidebarTitle,
              styles.margin,
              styles.topSmall,
            )}
          >
            Firefox
          </span>
          <Button
            secondary
            small
            className={cx(styles.extendButton, styles.margin, styles.topMedium)}
            onClick={() =>
              window.open('https://www.mozilla.org/en-US/firefox/', '_blank')
            }
          >
            Learn more
          </Button>
        </div>
        <div
          className={cx(
            styles.column,
            styles.horizontal,
            styles.vertical,
            styles.center,
          )}
        >
          {browserIcon(EdgeIcon, 'Edge')}
          <span className={cx(styles.cardSidebarTitle)}>Microsoft Edge</span>
          <Button
            secondary
            small
            className={cx(styles.extendButton, styles.margin, styles.topMedium)}
            onClick={() =>
              window.open(
                'https://www.microsoft.com/en-us/windows/microsoft-edge',
                '_blank',
              )
            }
          >
            Learn more
          </Button>
        </div>
      </div>
      <div
        className={cx(
          styles.row,
          styles.vertical,
          styles.horizontal,
          styles.center,
        )}
      >
        <FlatButton onClick={onClose}>
          Continue with Internet Explorer
        </FlatButton>
      </div>
    </Modal>
  );
};

IESupportModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default IESupportModal;
