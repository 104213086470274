import { MODAL_LOCATIONS_DATA } from './constants';

const getPremiumPurchasedFrom = (location) => {
  if (!location || !MODAL_LOCATIONS_DATA[location]) {
    return 'PATH-' + window.location.pathname;
  }

  return MODAL_LOCATIONS_DATA[location]?.title;
};

export default getPremiumPurchasedFrom;
