import moment from 'moment';
const dateFormat = 'MM/DD/YYYY';

export const isDateLowerThan =
  (dateStr, errorMessage = '') =>
  (value) => {
    const date = moment(dateStr, dateFormat, true);
    const dateSent = moment(value, dateFormat, true);
    if (value && date && dateSent && dateSent >= date) {
      return errorMessage || `Date entered should be before ${dateStr}`;
    }
    return undefined;
  };

export default isDateLowerThan;
