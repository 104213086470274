import React from 'react';
import PropTypes from 'prop-types';

import parseScreeningReportDate from '../../../../helpers/parseScreeningReportDate';
import toCurrency from '../../../../helpers/toCurrency';

import styles from './PublicRecordsSection.module.scss';

const PublicRecordsSection = ({ report }) => {
  if (!report?.public_records || report?.public_records.length === 0) {
    return null;
  }

  return (
    <div className={styles.flexColumn}>
      <span className={styles.iconNumber}>Public Records</span>
      {report.public_records.map((public_record, idx) => (
        <div key={idx} className={styles.flexColumn}>
          <div className={{ marginTop: 12 }}>
            <div className={styles.tradelinesContent}>
              <div className={styles.resultBlock}>
                <span className={styles.sectionTitle}>Type</span>
                <span style={{ marginTop: 4 }}>
                  {public_record.publicRecordType || 'N/A'}
                </span>
              </div>
              <div className={styles.resultBlock}>
                <span className={styles.sectionTitle}>Reported Date</span>
                <span style={{ marginTop: 4 }}>
                  {parseScreeningReportDate(public_record.dateReported)}
                </span>
              </div>
              <div className={styles.resultBlock}>
                <span className={styles.sectionTitle}>Plaintiff</span>
                <span style={{ marginTop: 4 }}>
                  {public_record.plaintiff || 'N/A'}
                </span>
              </div>
            </div>
          </div>
          <div className={{ marginTop: 33 }}>
            <div className={styles.tradelinesContent}>
              <div className={styles.resultBlock}>
                <span className={styles.sectionTitle}>Court Type</span>
                <span style={{ marginTop: 4 }}>
                  {public_record.courtType || 'N/A'}
                </span>
              </div>
              <div className={styles.resultBlock}>
                <span className={styles.sectionTitle}>Settled Date</span>
                <span style={{ marginTop: 4 }}>
                  {parseScreeningReportDate(public_record.dateSettled)}
                </span>
              </div>
              <div className={styles.resultBlock}>
                <span className={styles.sectionTitle}>Liabilities Amount</span>
                <span style={{ marginTop: 4 }}>
                  {public_record.liabilitiesAmount
                    ? `${toCurrency(public_record.liabilitiesAmount)}`
                    : 'N/A'}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

PublicRecordsSection.propTypes = {
  report: PropTypes.object,
};

export default PublicRecordsSection;
