import React, { memo } from 'react';
import PropTypes from 'prop-types';

const MoneyCreditCardDiscover = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 23" {...props}>
    <g fill="none">
      <g transform="translate(-329 -289)translate(48 127)translate(0 50)translate(200 107)translate(80 0)translate(1.333333 5.333333)">
        <path
          d="M29.3 10.9L29.3 1.9C29.3 0.9 28.5 0 27.4 0L1.9 0C0.9 0 0 0.9 0 1.9L0 20.1C0 21.1 0.9 22 1.9 22L8.7 22C18.6 20.6 26.8 14.9 29.3 10.9Z"
          fill="#E6E6E6"
        />
        <path
          d="M29.3 20.1L29.3 10.9C26.8 14.9 18.6 20.6 8.7 22L27.4 22C28.5 22 29.3 21.1 29.3 20.1Z"
          fill="#E6E6E6"
        />
        <path
          d="M29.3 20.1L29.3 10.9C26.8 14.9 18.6 20.6 8.7 22L27.4 22C28.5 22 29.3 21.1 29.3 20.1Z"
          fill="#FA7000"
        />
        <path
          d="M15.3 9.1C14.2 9.1 13.4 9.9 13.4 11 13.4 12.1 14.2 12.9 15.3 12.9 16.4 12.9 17.3 12.1 17.3 11 17.3 9.9 16.4 9.1 15.3 9.1Z"
          fill="#FA7000"
        />
        <path
          d="M4.2 9.1L3.2 9.1 3.2 12.9 4.2 12.9C4.8 12.9 5.2 12.7 5.5 12.4 6 12.1 6.2 11.5 6.2 11 6.2 9.9 5.4 9.1 4.2 9.1ZM5.1 11.9C4.8 12.1 4.6 12.2 4.1 12.2L3.9 12.2 3.9 9.8 4.1 9.8C4.6 9.8 4.8 9.9 5.1 10.1 5.3 10.3 5.5 10.6 5.5 11 5.5 11.3 5.3 11.7 5.1 11.9Z"
          fill="#444"
        />
        <rect x="6.5" y="9.1" width="1" height="3.7" fill="#444" />
        <path
          d="M9 10.6C8.6 10.4 8.4 10.3 8.4 10.1 8.4 9.9 8.7 9.7 9 9.7 9.2 9.7 9.4 9.8 9.5 10L9.9 9.5C9.6 9.2 9.2 9.1 8.8 9.1 8.2 9.1 7.7 9.5 7.7 10.1 7.7 10.7 7.9 10.9 8.6 11.2 8.9 11.3 9 11.3 9.1 11.4 9.2 11.5 9.3 11.6 9.3 11.8 9.3 12.1 9.1 12.3 8.8 12.3 8.4 12.3 8.1 12.1 8 11.8L7.5 12.2C7.8 12.7 8.2 12.9 8.8 12.9 9.5 12.9 10 12.4 10 11.7 10 11.1 9.8 10.9 9 10.6Z"
          fill="#444"
        />
        <path
          d="M10.3 11C10.3 12.1 11.1 12.9 12.2 12.9 12.5 12.9 12.8 12.9 13.1 12.7L13.1 11.9C12.8 12.2 12.6 12.3 12.2 12.3 11.5 12.3 11 11.7 11 11 11 10.3 11.5 9.7 12.2 9.7 12.5 9.7 12.8 9.8 13.1 10.1L13.1 9.3C12.8 9.1 12.5 9.1 12.2 9.1 11.1 9.1 10.3 9.9 10.3 11Z"
          fill="#444"
        />
        <polygon
          points="18.9 11.6 17.9 9.1 17.1 9.1 18.7 12.9 19.1 12.9 20.6 9.1 19.9 9.1"
          fill="#444"
        />
        <polygon
          points="21 12.9 23 12.9 23 12.2 21.7 12.2 21.7 11.2 22.9 11.2 22.9 10.6 21.7 10.6 21.7 9.8 23 9.8 23 9.1 21 9.1"
          fill="#444"
        />
        <path
          d="M25.8 10.2C25.8 9.5 25.4 9.1 24.5 9.1L23.5 9.1 23.5 12.9 24.2 12.9 24.2 11.4 24.3 11.4 25.3 12.9 26.1 12.9 25 11.3C25.5 11.2 25.8 10.8 25.8 10.2ZM24.4 10.8L24.2 10.8 24.2 9.7 24.4 9.7C24.9 9.7 25.1 9.9 25.1 10.3 25.1 10.6 24.9 10.8 24.4 10.8Z"
          fill="#444"
        />
      </g>
    </g>
  </svg>
);

MoneyCreditCardDiscover.defaultProps = {
  width: 31,
  height: 23,
};

MoneyCreditCardDiscover.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(MoneyCreditCardDiscover);
