import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Invoice = ({ color, ...props }) => {
  return (
    <svg {...props}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M10.19 0c.028 0 .055.003.081.008L10.191 0a.43.43 0 0 1 .313.134l5.316 5.5.013.014.03.037-.043-.05a.456.456 0 0 1 .13.323v15.584a.45.45 0 0 1-.443.458H.443A.45.45 0 0 1 0 21.542V.458A.45.45 0 0 1 .443 0Zm-.443.916H.887v20.167h14.177l-.001-14.667H10.19a.448.448 0 0 1-.436-.375l-.007-.083V.916Zm3.102 17.417a.45.45 0 0 1 .443.459.45.45 0 0 1-.443.458H3.175a.45.45 0 0 1-.443-.458.45.45 0 0 1 .443-.459Zm0-1.833a.45.45 0 0 1 .443.458.45.45 0 0 1-.443.459H3.175a.45.45 0 0 1-.443-.459.45.45 0 0 1 .443-.458Zm0-1.833a.45.45 0 0 1 .443.458.45.45 0 0 1-.443.458H3.175a.45.45 0 0 1-.443-.458.45.45 0 0 1 .443-.458Zm0-1.834a.45.45 0 0 1 .442.459.45.45 0 0 1-.443.458H8.432a.45.45 0 0 1-.443-.458.45.45 0 0 1 .443-.459ZM5.316 3.667a.45.45 0 0 1 .443.458v.626c.856.207 1.495 1.003 1.495 1.952a.45.45 0 0 1-.443.458.45.45 0 0 1-.443-.458c0-.6-.472-1.088-1.053-1.088-.58 0-1.052.487-1.052 1.088 0 .601.472 1.089 1.052 1.089 1.07 0 1.939.898 1.939 2.004 0 .95-.638 1.745-1.495 1.953v.626a.45.45 0 0 1-.443.458.45.45 0 0 1-.443-.458v-.626c-.858-.208-1.496-1.003-1.496-1.953a.45.45 0 0 1 .443-.458.45.45 0 0 1 .443.458c0 .602.471 1.088 1.052 1.088.582 0 1.053-.486 1.053-1.088 0-.6-.472-1.088-1.053-1.088-1.07 0-1.938-.897-1.938-2.005 0-.95.639-1.745 1.495-1.952v-.626a.45.45 0 0 1 .444-.458ZM12.849 11a.45.45 0 0 1 .443.458.45.45 0 0 1-.443.459H10.19a.45.45 0 0 1-.443-.459.45.45 0 0 1 .443-.458Zm0-1.833a.45.45 0 0 1 .443.458.45.45 0 0 1-.443.458H10.19a.45.45 0 0 1-.443-.458.45.45 0 0 1 .443-.458Zm-2.216-7.602V5.5h3.804l-3.804-3.935Z"
      />
    </svg>
  );
};

Invoice.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

Invoice.defaultProps = {
  width: 16,
  height: 22,
  color: colors.text,
};

export default memo(Invoice);
