import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Callout from '../../../../components/Callout';
import FlatButton from '../../../../components/FlatButton';
import LoadingScreen from '../../../../components/LoadingScreen';
import RemoveButton from '../../../../components/RemoveButton';
import { useErrorToast } from '../../../../components/Toast';
import UploadDropZone from '../../../../components/UploadDropZone';
import DocumentUpload from '../../../../icons/DocumentUpload';
import DownloadIcon from '../../../../icons/Download';
import PlusCircle from '../../../../icons/PlusCircle';
import {
  getImageURL,
  uploadFileToS3,
} from '../../../../services/cloudFilesService';

import styles from './UploadAttachmentComponent.module.scss';

const UploadAttachmentComponent = ({ imageType, maxFileSize, ...props }) => {
  const fileUploader = useRef(null);
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [attachments, setAttachments] = useState(props.attachments);
  const showErrorMessage = useErrorToast();

  useEffect(() => {
    setAttachments(props.attachments);
  }, [props.attachments]);

  const handleAddButtonClick = () => {
    fileUploader?.current?.click();
  };

  const getUrlObjectFromS3Params = ({ url, urls }) => {
    if (!url && !urls) {
      console.warn('s3Params is not provided');
      return;
    }
    return url || urls[0];
  };

  const upload = (urlsFiles = []) =>
    Promise.all(
      urlsFiles.map((queuedFile) => {
        const urlObject = getUrlObjectFromS3Params(queuedFile.s3Params);

        return uploadFileToS3(urlObject, queuedFile.file)
          .then((response) => response.text())
          .then(() => ({
            id: queuedFile.s3Params.id,
            file_url: `${urlObject.endpoint_url}/${encodeURIComponent(
              urlObject.params.key,
            )}`,
            content: queuedFile.name,
            rotation: 0,
          }))
          .catch((errorResponse) =>
            console.error('Bad request', errorResponse),
          );
      }),
    );

  const onAddedfile = (file) =>
    getImageURL({ fileName: file.name, imageType }).then((content) => ({
      name: file.name,
      file,
      s3Params: JSON.parse(content),
    }));

  const filesUploaded = (newAttachments) => {
    setUploadingFiles(false);
    props.onChange(newAttachments);
  };

  const handleFileDrop = async (files = []) => {
    const filesToAdd = Array.from(files).filter((file) => {
      if (maxFileSize) {
        if (file.size <= maxFileSize) return true;

        showErrorMessage(
          `File ${file.name} exceeds the size limit of 10MB and cannot be uploaded. Please reduce the file size and try again.`,
        );

        return false;
      }

      return true;
    });

    if (filesToAdd?.length === 0) return;
    setUploadingFiles(true);

    const urls = await Promise.all(filesToAdd.map(onAddedfile));
    const uploaded = await upload(urls);
    const newAttachments = [...attachments, ...uploaded];

    setAttachments(newAttachments);
    filesUploaded(newAttachments);
  };

  const onClickDeleteButton = (evt, index) => {
    const clone = [...attachments];
    clone.splice(index, 1);
    props.onChange(clone);
  };

  const checkImageURL = (url) => url.match(/\.(jpeg|jpg|gif|png)$/) != null;

  const renderAttachment = ({ file_url, content }, index) => {
    const { disableEdit, createApplicationFlow } = props;
    return (
      <Callout
        key={`attachment-key-${index}`}
        className={cx(styles.blockWithColorBarStyle, {
          [styles.createApplicationFlowBlockStyles]: createApplicationFlow,
        })}
      >
        <div className={styles.attachmentRow}>
          <div className={styles.attachmentLeftCol}>
            <div className={styles.imageWrapper}>
              {checkImageURL(file_url) ? (
                <img src={file_url} className={styles.attachmentThumbnail} />
              ) : (
                <DocumentUpload style={{ marginRight: 10 }} />
              )}
            </div>
            <a
              className={styles.link}
              href={file_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {content}
            </a>
          </div>
          {disableEdit ? (
            <a
              className={styles.link}
              href={file_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DownloadIcon />
            </a>
          ) : (
            <span className={styles.removeIconWrap}>
              <RemoveButton
                justIcon
                onClick={(evt) => onClickDeleteButton(evt, index)}
              />
            </span>
          )}
        </div>
      </Callout>
    );
  };

  const { disableEdit, addButton } = props;

  return (
    <LoadingScreen
      style={{ width: '100%', marginTop: 14, marginBottom: 24 }}
      loading={uploadingFiles}
    >
      <div className={styles.attachmentListContainer}>
        {attachments.map((attachment, idx) =>
          renderAttachment(attachment, idx),
        )}
        <input
          multiple
          type="file"
          id="fileUploader"
          onChange={(e) => {
            handleFileDrop(e.target.files);
            e.target.value = null;
          }}
          ref={fileUploader}
          style={{ display: 'none' }}
        />
      </div>
      {!disableEdit ? (
        addButton ? (
          <UploadDropZone
            accept="image/*"
            uploadFiles={handleFileDrop}
            multiple={false}
            icon={PlusCircle}
            dropzoneLabel="click to upload Document"
            className={styles.dropzone}
          />
        ) : (
          <FlatButton
            style={{ marginTop: 9 }}
            small
            icon={PlusCircle}
            onClick={handleAddButtonClick}
          >
            Add new attachment
          </FlatButton>
        )
      ) : null}
    </LoadingScreen>
  );
};

UploadAttachmentComponent.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.object),
  disableEdit: PropTypes.bool,
  createApplicationFlow: PropTypes.bool,
  onChange: PropTypes.func,
  imageType: PropTypes.string,
  refs: PropTypes.func,
  addButton: PropTypes.bool,
  maxFileSize: PropTypes.number,
};

UploadAttachmentComponent.defaultProps = {
  imageType: 'attachments',
  disableEdit: true,
  attachments: [],
};

export default UploadAttachmentComponent;
