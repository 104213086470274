export const hasUserFinishedPaymentsOnboarding = (user) => {
  const hasBankAccount = !!user?.payments_data?.bank_account_name;
  const hasSetUpStripeAccount =
    !!user?.payments_status?.account &&
    user?.payments_status?.account !== 'NO_ACCOUNT';

  const userIsOnTheFraudQ = !!user?.payments_status?.fraud;

  return hasBankAccount && hasSetUpStripeAccount && userIsOnTheFraudQ;
};
