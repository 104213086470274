import React from 'react';
import { Field, Form } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';
import HelpfulInfoCard from '../../../../../components/HelpfulInfo';
import Input from '../../../../../components/Input';
import trimEmail from '../../../../../components/Input/trimEmail';
import composeValidators from '../../../../../validators/composeValidators';
import fieldRequiredShort from '../../../../../validators/fieldRequiredShort';
import validEmail from '../../../../../validators/validEmail';
import validName from '../../../../../validators/validName';

import styles from './EditLandlordForm.module.scss';

const EditLandlordForm = ({ landlord, onCancelButtonClick, onSave }) => (
  <HelpfulInfoCard noTitle noIcon className={styles.container}>
    <Form
      onSubmit={onSave}
      initialValues={landlord}
      render={({ handleSubmit, invalid, pristine }) => (
        <form onSubmit={handleSubmit}>
          <h4 className={styles.title}>Landlord</h4>
          <div className={styles.row}>
            <Field
              label="Legal First Name"
              component={Input}
              id="first_name"
              name={`first_name`}
              validate={composeValidators(fieldRequiredShort, validName)}
              className={cx(styles.name, styles.withSpacing)}
            />
            <Field
              label="Last Name"
              component={Input}
              id="last_name"
              name={`last_name`}
              validate={composeValidators(fieldRequiredShort, validName)}
              className={styles.name}
            />
          </div>
          <Field
            label="Company Name"
            hint="(Optional)"
            component={Input}
            id="company_name"
            name={`company_name`}
            className={styles.largeInput}
          />
          <Field
            label="Email"
            component={Input}
            id="email"
            name={`email`}
            parse={trimEmail}
            validate={composeValidators(fieldRequiredShort, validEmail)}
            className={styles.largeInput}
          />
          <p className={styles.infoText}>
            Please double check your email. Whoever receives this email will be
            able to access and sign the document. This will not update your
            account email.
          </p>
          <div className={styles.row}>
            <Button
              small
              secondary
              className={styles.btn}
              onClick={() => {
                onCancelButtonClick();
              }}
            >
              Cancel
            </Button>
            <Button
              small
              className={styles.btn}
              onClick={handleSubmit}
              disabled={invalid || pristine}
            >
              Save
            </Button>
          </div>
        </form>
      )}
    />
  </HelpfulInfoCard>
);

EditLandlordForm.propTypes = {
  onCancelButtonClick: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  landlord: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    company_name: PropTypes.string,
  }).isRequired,
};

export default EditLandlordForm;
