import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import MaintenanceFilled from '../../../icons/MaintenanceFilled';
import Button from '../../Button';
import FlatButton from '../../FlatButton';

import styles from './MaintenanceResolved.module.scss';

const MaintenanceResolved = ({ className, onNotifyClicked, onSkipClicked }) => {
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.imgHead}>
        <MaintenanceFilled className={cx(styles.icon)} />
      </div>

      <h1 className={cx(styles.title)}>
        Nice Work! I bet having that done is a relief.
      </h1>

      <p className={cx(styles.subTitle)}>
        We’ll notify all the tenants on the lease that it is resolved. They will
        be happy to hear it!
      </p>

      <div className={cx(styles.buttonContainer)}>
        <Button
          secondary={false}
          small={false}
          className={cx(styles.button)}
          onClick={onNotifyClicked}
        >
          Notify Tenants
        </Button>
        <FlatButton
          secondary={true}
          small={false}
          className={cx(styles.button, styles.skipBtn)}
          onClick={onSkipClicked}
        >
          SKIP NOTIFYING THEM
        </FlatButton>
      </div>
    </div>
  );
};

MaintenanceResolved.propTypes = {
  className: PropTypes.string,
  onNotifyClicked: PropTypes.func,
  onSkipClicked: PropTypes.func,
};

export default MaintenanceResolved;
