import React, { memo } from 'react';

import colors from '../constants/colors';

const HandyPerson = ({ width, height }) => (
  <svg
    viewBox="0 0 165 145"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M156.7 82.14c0-41.684-33.342-75.473-74.47-75.473-41.127 0-74.469 33.789-74.469 75.472a76.034 76.034 0 006.636 31.132h135.668A76.034 76.034 0 00156.7 82.14"
        fill="#C9EDFF"
      />
      <path
        stroke="#033A6D"
        strokeWidth="1.743"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.623 113.271h145.215M1.245 113.271H5.9M158.562 113.271h4.654"
      />
      <path
        d="M100.692 14.052c0-2.998-2.398-5.428-5.356-5.428-.684 0-1.33.141-1.929.375-.681-4.311-4.34-7.615-8.787-7.615-4.446 0-8.105 3.304-8.786 7.615a5.284 5.284 0 00-1.929-.375c-2.958 0-5.358 2.43-5.358 5.428 0 3 2.4 5.43 5.358 5.43h21.43c2.959 0 5.357-2.43 5.357-5.43"
        fill="#FFF"
      />
      <path
        d="M100.692 14.052c0-2.998-2.398-5.428-5.356-5.428-.684 0-1.33.141-1.929.375-.681-4.311-4.34-7.615-8.787-7.615-4.446 0-8.105 3.304-8.786 7.615a5.284 5.284 0 00-1.929-.375c-2.958 0-5.358 2.43-5.358 5.428 0 3 2.4 5.43 5.358 5.43h21.43c2.959 0 5.357-2.43 5.357-5.43z"
        stroke="#033A6D"
        strokeWidth="1.743"
        strokeLinejoin="round"
      />
      <path
        d="M147.837 67.968v-14.8c0-1.25-1-2.262-2.232-2.262h-6.25c-1.234 0-2.232 1.013-2.232 2.262v5.4l10.714 9.4z"
        fill="#D6DAFF"
      />
      <path
        d="M147.837 67.968v-14.8c0-1.25-1-2.262-2.232-2.262h-6.25c-1.234 0-2.232 1.013-2.232 2.262v5.4l10.714 9.4z"
        stroke="#033A6D"
        strokeWidth="1.743"
        strokeLinejoin="round"
      />
      <path
        fill="#FFF"
        d="M150.589 113.345H97.015v-32.19l26.787-23.548 26.787 23.546z"
      />
      <path
        stroke="#033A6D"
        strokeWidth="1.743"
        strokeLinejoin="round"
        d="M150.589 113.345H97.015v-32.19l26.787-23.548 26.787 23.546z"
      />
      <path
        d="M94.412 82.124a3.977 3.977 0 01-3.064-1.434 4.092 4.092 0 01-.94-2.966 4.054 4.054 0 011.405-2.773L121.2 49.16a3.989 3.989 0 012.602-.972c.955 0 1.879.348 2.603.976l29.393 25.787a4.065 4.065 0 011.402 2.773 4.087 4.087 0 01-.944 2.966 3.979 3.979 0 01-5.667.46l-26.787-23.543-26.786 23.548a3.983 3.983 0 01-2.603.97"
        fill="#D6DAFF"
      />
      <path
        d="M94.412 82.124a3.977 3.977 0 01-3.064-1.434 4.092 4.092 0 01-.94-2.966 4.054 4.054 0 011.405-2.773L121.2 49.16a3.989 3.989 0 012.602-.972c.955 0 1.879.348 2.603.976l29.393 25.787a4.065 4.065 0 011.402 2.773 4.087 4.087 0 01-.944 2.966 3.979 3.979 0 01-5.667.46l-26.787-23.543-26.786 23.548a3.983 3.983 0 01-2.603.97z"
        stroke="#033A6D"
        strokeWidth="1.743"
        strokeLinejoin="round"
      />
      <path
        d="M139.801 50.906h5.356v-3.168c0-1.25-.998-2.263-2.232-2.263h-.892c-1.232 0-2.232 1.014-2.232 2.263v3.168z"
        fill="#D6DAFF"
      />
      <path
        d="M139.801 50.906h5.356v-3.168c0-1.25-.998-2.263-2.232-2.263h-.892c-1.232 0-2.232 1.014-2.232 2.263v3.168z"
        stroke="#033A6D"
        strokeWidth="1.743"
        strokeLinejoin="round"
      />
      <path
        d="M123.729 113.345h-21.43v-3.543c0-1.042.833-1.887 1.861-1.887h17.707c1.028 0 1.862.845 1.862 1.887v3.543z"
        fill="#FFF"
      />
      <path
        d="M123.729 113.345h-21.43v-3.543c0-1.042.833-1.887 1.861-1.887h17.707c1.028 0 1.862.845 1.862 1.887v3.543z"
        stroke="#033A6D"
        strokeWidth="1.743"
        strokeLinejoin="round"
      />
      <path
        d="M121.05 107.915h-16.072V95.698c0-4.496 3.598-8.144 8.037-8.144 4.436 0 8.035 3.648 8.035 8.144v12.217z"
        fill="#D6DAFF"
      />
      <path
        d="M121.05 107.915h-16.072V95.698c0-4.496 3.598-8.144 8.037-8.144 4.436 0 8.035 3.648 8.035 8.144v12.217z"
        stroke="#033A6D"
        strokeWidth="1.743"
        strokeLinejoin="round"
      />
      <path
        stroke="#033A6D"
        strokeWidth="1.743"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M115.693 101.129v-2.715"
      />
      <path
        d="M129.086 73.98c0 3-2.398 5.43-5.356 5.43-2.96 0-5.358-2.43-5.358-5.43 0-2.998 2.398-5.428 5.358-5.428 2.958 0 5.356 2.43 5.356 5.428"
        fill="#D6DAFF"
      />
      <path
        d="M127.517 70.142a5.304 5.304 0 00-3.789-1.59c-2.958 0-5.356 2.43-5.356 5.428 0 1.5.6 2.858 1.568 3.84l7.577-7.678z"
        fill="#FFF"
      />
      <path
        d="M129.086 73.98c0 3-2.398 5.43-5.356 5.43-2.96 0-5.358-2.43-5.358-5.43 0-2.998 2.398-5.428 5.358-5.428 2.958 0 5.356 2.43 5.356 5.428z"
        stroke="#033A6D"
        strokeWidth="1.743"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.617 105.201h-6.99c-1.028 0-1.862-.845-1.862-1.887V90.8c0-1.043.834-1.887 1.861-1.887h6.991c1.028 0 1.862.844 1.862 1.887v12.515c0 1.042-.834 1.887-1.862 1.887"
        fill="#D6DAFF"
      />
      <path
        d="M142.445 91.004c-.089-1.164-1.024-2.09-2.198-2.09h-6.25c-1.233 0-2.232 1.01-2.232 2.262v10.653l10.68-10.825z"
        fill="#FFF"
      />
      <path
        d="M140.617 105.201h-6.99c-1.028 0-1.862-.845-1.862-1.887V90.8c0-1.043.834-1.887 1.861-1.887h6.991c1.028 0 1.862.844 1.862 1.887v12.515c0 1.042-.834 1.887-1.862 1.887z"
        stroke="#033A6D"
        strokeWidth="1.743"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g transform="translate(18.2 23.654)">
        <path
          d="M26.423 40.99c1.048 0 2.765-1.517 4.156-1.048 3.127 1.048 5.205-2.077 6.235-1.03 1.03 1.049 5.205 2.078 6.235-1.047.74-2.204-3.109-2.078-3.109-7.226 0-4.282 2.079-3.125 3.109-6.233 1.03-3.107-2.078-6.232-2.078-6.232 0-8.032-6.514-14.543-14.548-14.543-8.035 0-14.548 6.511-14.548 14.543 0 0-3.09 3.07-2.06 6.214 1.03 3.144 3.126 1.951 3.126 6.233 0 5.185-3.85 5.058-3.127 7.226 1.049 3.125 5.205 2.077 6.235 1.048 1.03-1.03 3.127 2.077 6.235 1.03 1.392-.452 3.127 1.065 4.139 1.065z"
          stroke="#033A6D"
          strokeWidth="1.804"
          fill="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.989 24.388a2.547 2.547 0 00-2.079-2.493v-1.662c0-6.884-5.583-12.465-12.47-12.465-6.886 0-12.47 5.58-12.47 12.465v1.662a2.547 2.547 0 00-2.005 2.493 2.584 2.584 0 002.35 2.547 12.469 12.469 0 0012.207 9.924 12.469 12.469 0 0012.208-9.924 2.584 2.584 0 002.259-2.547z"
          stroke="#033A6D"
          strokeWidth="1.804"
          fill="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.76 20.215a1.554 1.554 0 10.036 3.108 1.554 1.554 0 00-.036-3.108zM31.121 20.215a1.554 1.554 0 101.555 1.554 1.535 1.535 0 00-1.555-1.554z"
          fill="#033A6D"
        />
        <circle fill="#D6DAFF" cx="19.682" cy="25.924" r="1.554" />
        <circle fill="#D6DAFF" cx="33.2" cy="25.924" r="1.554" />
        <path
          d="M23.332 29.645a5.604 5.604 0 006.235 0M10.555 17.09H41.73"
          stroke="#033A6D"
          strokeWidth="1.804"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.651 15.012c0-7.483-6.068-13.549-13.554-13.549-7.485 0-13.554 6.066-13.554 13.55v2.15h27.108v-2.15z"
          fill="#D6DAFF"
        />
        <path
          d="M39.651 15.012c0-7.483-6.068-13.549-13.554-13.549-7.485 0-13.554 6.066-13.554 13.55v2.15h27.108v-2.15z"
          stroke="#033A6D"
          strokeWidth="1.804"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.26 17.162h-6.235L21.001 1.517a13.468 13.468 0 0110.391 0L29.26 17.162z"
          fill="#D6DAFF"
        />
        <path
          d="M29.26 17.162h-6.235L21.001 1.517a13.468 13.468 0 0110.391 0L29.26 17.162zM35.531 5.311l-1.084 7.624M16.736 5.311l1.084 7.624"
          stroke="#033A6D"
          strokeWidth="1.804"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.224 117.027c0 1.807-9.308 3.108-20.783 3.108-11.476 0-20.783-1.392-20.783-3.108 0-1.716 9.307-3.125 20.783-3.125 11.475 0 20.783 1.391 20.783 3.125z"
          fill="#EBEFF5"
        />
        <path
          d="M50.35 69.244a5.204 5.204 0 01-5.205-5.112v-6.323a18.426 18.426 0 00-10.3-16.53 20.102 20.102 0 00-8.422-2.168 17.3 17.3 0 00-8.476 1.807C6.94 46.428 7.718 58.369 7.718 63.012v13.495h7.229v39.454H37.88V64.132a12.482 12.482 0 005.736 10.482 12.492 12.492 0 0011.92.863V64.132a5.186 5.186 0 01-5.186 5.112h0z"
          stroke="#033A6D"
          strokeWidth="1.804"
          fill="#D6DAFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.88 64.132v-5.42A6.195 6.195 0 0139.02 55.1a6.303 6.303 0 00.94-3.613M15.02 76.507v-17.94a6.086 6.086 0 00-1.157-3.612 6.285 6.285 0 01-.922-3.469"
          stroke="#033A6D"
          strokeWidth="1.804"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.935 91.501l-2.548-2.872a2.042 2.042 0 00-1.555-.687H5.17a2.024 2.024 0 00-1.536.687L1.03 91.5c-.343.381-.53.878-.524 1.391v7.515a2.077 2.077 0 002.079 2.078h15.849a2.096 2.096 0 002.078-2.078v-7.515a2.06 2.06 0 00-.578-1.391z"
          fill="#FFF"
        />
        <path
          d="M19.935 91.501l-2.548-2.872a2.042 2.042 0 00-1.555-.687H5.17a2.024 2.024 0 00-1.536.687L1.03 91.5c-.343.381-.53.878-.524 1.391v7.515a2.077 2.077 0 002.079 2.078h15.849a2.096 2.096 0 002.078-2.078v-7.515a2.06 2.06 0 00-.578-1.391h0z"
          stroke="#033A6D"
          strokeWidth="1.804"
          strokeLinejoin="round"
        />
        <path
          stroke="#033A6D"
          strokeWidth="1.804"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.706 92.513l2.602-11.707M14.26 92.621l-2.548-11.417M26.405 85.865v30.096"
        />
        <path
          d="M26.423 115.961a6.234 6.234 0 00-6.235-6.232h-.994a6.2 6.2 0 00-5.422 3.18 2.04 2.04 0 000 2.077 2.06 2.06 0 001.807 1.03l10.844-.055z"
          stroke="#033A6D"
          strokeWidth="1.804"
          fill="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.423 115.961a6.252 6.252 0 016.234-6.232h.994a6.235 6.235 0 015.422 3.18 2.077 2.077 0 01-1.807 3.107l-10.843-.055z"
          stroke="#033A6D"
          strokeWidth="1.804"
          fill="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          stroke="#033A6D"
          strokeWidth="1.804"
          fill="#FFF"
          strokeLinejoin="round"
          d="M15.019 69.244H37.88v4.155H15.019z"
        />
        <path
          stroke="#033A6D"
          strokeWidth="1.804"
          fill="#D6DAFF"
          strokeLinejoin="round"
          d="M23.332 69.244h6.235v4.155h-6.235z"
        />
        <path
          d="M34.844 41.28l-2.096 9.267-4.23 18.697h-4.156l-4.21-18.697-2.187-9.629a17.3 17.3 0 018.476-1.807c2.925.103 5.793.842 8.403 2.168z"
          stroke="#033A6D"
          strokeWidth="1.804"
          fill="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          stroke="#033A6D"
          strokeWidth="1.804"
          fill="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M32.748 50.547l-4.23 18.697h-4.156l-4.21-18.697z"
        />
        <path
          d="M7.736 76.507v4.679a3.614 3.614 0 007.229 0v-4.68H7.736z"
          fill="#FFF"
        />
        <path
          d="M7.736 76.507v4.679a3.614 3.614 0 007.229 0v-4.68H7.736zM26.441 50.547v-2.078"
          stroke="#033A6D"
          strokeWidth="1.804"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.23 59.76C21.525 58.622 15.995 49.5 15 49.5h-2.06l5.114-8.184 2.097 9.232 2.078 9.213zM39.959 49.499H37.88c-.976 0-6.506 9.123-7.229 10.261l2.079-9.213 2.096-9.232L39.96 49.5z"
          stroke="#033A6D"
          strokeWidth="1.804"
          fill="#D6DAFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63.886 51.07a6.303 6.303 0 00-.434-2.276l-2.8 2.8a2.525 2.525 0 01-3.525-3.612l2.783-2.8a6.25 6.25 0 00-4.771 11.544v25.598a2.512 2.512 0 005.006 0V56.797a6.25 6.25 0 003.741-5.726h0z"
          stroke="#033A6D"
          strokeWidth="1.804"
          fill="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M61.97 63.626a1.048 1.048 0 00-.85-1.21l-3.613-.614a3.672 3.672 0 10-1.302 7.226l3.615.614a1.012 1.012 0 001.193-.831l.957-5.185z"
          fill="#FFF"
        />
        <path
          d="M61.97 63.626a1.048 1.048 0 00-.85-1.21l-3.613-.614a3.672 3.672 0 10-1.302 7.226l3.615.614a1.012 1.012 0 001.193-.831l.957-5.185z"
          stroke="#033A6D"
          strokeWidth="1.804"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

HandyPerson.defaultProps = {
  fill: colors.text,
  width: 165,
  height: 145,
};

export default memo(HandyPerson);
