import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import CheckboxGroupFields from '../../../../../components/CheckboxGroupFields';
import Input from '../../../../../components/Input';
import PARKING_TYPES from '../../../../../constants/lease_agreement/parking-types';
import composeValidators from '../../../../../validators/composeValidators';
import fieldRequiredStandard from '../../../../../validators/fieldRequired';
import fieldRequiredCheckboxStandard from '../../../../../validators/fieldRequiredCheckbox';
import isLengthBetweenWithLimits from '../../../../../validators/isLengthBetweenWithLimits';

import styles from './ParkingForm.module.scss';

const parkingTypesOptions = Object.keys(PARKING_TYPES).map((key) => {
  const parkingType = PARKING_TYPES[key];

  return {
    id: parkingType.id,
    label: parkingType.label,
    value: parkingType.value,
    hasAdditionalInput: parkingType.hasAdditionalInput,
  };
});

const additionalInputs = parkingTypesOptions.filter(
  (type) => type.hasAdditionalInput,
);

const noop = () => {};

const ParkingForm = ({ parkingTypes, standAloneWizard }) => {
  const fieldRequired = standAloneWizard ? noop : fieldRequiredStandard;
  const fieldRequiredCheckbox = standAloneWizard
    ? noop
    : fieldRequiredCheckboxStandard;

  return (
    <div className={styles.parkingForm}>
      <label className={styles.headerLabel}>
        What type of parking is available?
      </label>
      <div className={styles.parkingTypes}>
        <CheckboxGroupFields
          id="custom_state_data.parking_types"
          name="custom_state_data.parking_types"
          validate={fieldRequiredCheckbox}
          options={parkingTypesOptions}
          checkboxesClassName={styles.checkboxes}
        />
      </div>
      <div className={styles.additionalInputs}>
        {additionalInputs.map((additionalInput) => {
          if (!parkingTypes.includes(additionalInput.value)) {
            return;
          }
          const name = `custom_state_data.parking_${additionalInput.value.toLowerCase()}`;
          return (
            <div key={name}>
              <Field
                name={name}
                component={Input}
                type="text"
                label={additionalInput.label}
                className={styles.input}
                id={name}
                validate={fieldRequired}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.customRules}>
        <label className={styles.headerLabel}>Customize parking rules</label>
        <p className={styles.headerDescription}>
          You can edit or replace the default parking policy below. Consider
          including parking fees, rules, vehicle descriptions or a towing
          policy.{' '}
        </p>
        <Field
          as="textarea"
          name="custom_state_data.parking_rules"
          component={Input}
          type="text"
          className={styles.textArea}
          id="custom_state_data.parking_rules"
          validate={composeValidators(
            fieldRequired,
            isLengthBetweenWithLimits(0, 2000),
          )}
          help={(val = '') => (
            <span
              className={cx({
                [styles.helpTextRed]: val.length > 2000,
              })}
            >
              {val.length > 2000 &&
                `${val.length} / 2000 characters used | 2000 maximum`}
            </span>
          )}
          inputProps={{ 'data-qa': 'lease-agreements-parking-rules' }}
        />
      </div>
    </div>
  );
};

ParkingForm.propTypes = {
  input: PropTypes.object,
  standAloneWizard: PropTypes.bool,
};

export default ParkingForm;
