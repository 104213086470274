import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Badge.module.scss';

const Badge = ({ children, style, counter, className, badgeClassName }) => (
  <div className={cx(styles.container, className)} style={style}>
    {children}
    {counter ? (
      <div
        className={cx({
          [styles.position]: !!children,
        })}
      >
        <div
          className={cx(styles.badge, badgeClassName, {
            [styles.withIcon]: !!children,
          })}
        >
          {counter}
        </div>
      </div>
    ) : null}
  </div>
);

Badge.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  counter: PropTypes.number,
  className: PropTypes.string,
  badgeClassName: PropTypes.string,
};

export default Badge;
