import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Pencil2 = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    stroke={'currentColor'}
    strokeWidth={props.strokeWidth || 1.25}
    fillOpacity="0"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit="10"
  >
    <path d="M7.294 21.675l-6.466 1.517 1.517-6.465 15.6-15.602c.781-.781 2.049-.781 2.829 0l2.122 2.122c.78.781.78 2.047 0 2.829l-15.602 15.599zM22.191 6.784l-4.953-4.952M20.764 8.211l-4.94-4.965M7.546 21.424l-4.95-4.95" />
  </svg>
);

Pencil2.defaultProps = {
  width: 24,
  height: 24,
};

Pencil2.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(Pencil2);
