import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './BoxButton.module.scss';

const BoxButton = ({
  to,
  href,
  type,
  icon: Icon,
  iconProps: {
    className: iconClassName,
    iconWrapperClassName,
    ...otherIconProps
  },
  className,
  children,
  disabled,
  ...otherProps
}) => {
  let Element = 'button';
  const props = {};

  if (to && !disabled) {
    Element = Link;
    props.to = to;
  } else if (href && !disabled) {
    Element = 'a';
    props.href = href;
  } else {
    props.type = 'type';
  }

  return (
    <Element
      {...props}
      {...otherProps}
      className={cx(
        styles.button,
        {
          [styles.disabled]: disabled,
        },
        className,
      )}
      disabled={disabled}
    >
      {Icon && (
        <div className={cx(styles.iconWrapper, iconWrapperClassName)}>
          <Icon
            className={cx(styles.icon, iconClassName)}
            {...otherIconProps}
          />
        </div>
      )}
      {children}
    </Element>
  );
};

BoxButton.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
  iconProps: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

BoxButton.defaultProps = {
  type: 'button',
  iconProps: {},
};

export default BoxButton;
