import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import FacebookLogo from '../../icons/FacebookLogo';
import GoogleLogo from '../../icons/GoogleLogo';
import Button from '../Button';
import Spinner from '../Spinner';

import styles from './SocialButton.module.scss';

const label = {
  facebook: {
    signup: 'Continue with Facebook',
    login: 'Log in with Facebook',
  },
  google: {
    signup: 'Continue with Google',
    login: 'Log in with Google',
  },
};

const logo = {
  facebook: FacebookLogo,
  google: GoogleLogo,
};

const SocialButton = ({
  className,
  disabled,
  loading,
  type,
  socialNetwork,
  ...otherProps
}) => {
  const Logo = logo[socialNetwork];

  return (
    <Button
      secondary
      className={cx(styles.button, className)}
      disabled={disabled || loading}
      {...otherProps}
    >
      {loading ? (
        <Spinner secondary />
      ) : (
        <>
          <Logo style={{ marginRight: 5 }} />
          {label[socialNetwork][type]}
        </>
      )}
    </Button>
  );
};

SocialButton.defaultProps = {
  socialNetwork: 'facebook',
  type: 'signup',
};

SocialButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['signup', 'login']).isRequired,
  socialNetwork: PropTypes.oneOf(['facebook', 'google']).isRequired,
};

export default SocialButton;
