import React from 'react';
import PropTypes from 'prop-types';

import ErrorModal from '../ErrorModal';

import styles from './BankAccountAlreadyAddedModal.module.scss';

const BankAccountAlreadyAddedModal = ({ onClose }) => {
  return (
    <ErrorModal
      open={open}
      title="Bank Account Already Exists"
      description="The bank account you are attempting to add already exists."
      buttonLabel="Okay"
      onClose={onClose}
      onClick={onClose}
      iconWrapperClassName={styles.iconWrapper}
    />
  );
};

BankAccountAlreadyAddedModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default BankAccountAlreadyAddedModal;
