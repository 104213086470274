import React from 'react';

import { statesList } from '../../constants';

import SelectField from './SelectField';

const StateField = (props) => (
  <SelectField
    options={statesList.map(({ title, value }) => ({
      value,
      label: title,
      id: value,
    }))}
    {...props}
  />
);

export default StateField;
