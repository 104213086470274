import get from 'lodash.get';
import PropTypes from 'prop-types';

import { FEATURE_FLAGS } from '../../constants/feature_flags';
import { useUserProfile } from '../../core/TTgraphql';

const RenderIfLulaEligible = ({ children, listing }) => {
  const { user, loading, features = [] } = useUserProfile();

  const isLulaEnabled = features?.includes(FEATURE_FLAGS.LULA_MAINTENANCE);
  const isLulaEligible = user?.lula_eligible && isLulaEnabled;
  const hasMaintenancePlusSubscribed =
    user.maintenance_plus_subscription_subscribed;
  let isListingEligible = false;

  const isLulaExperimentActive = get(
    user,
    'maintenance_plus_active_experiment',
    false,
  );
  const maintenancePlusSettings =
    user?.maintenance_request_management_settings || false;
  const isEnrolledFreeLula =
    isLulaExperimentActive && !!maintenancePlusSettings;

  if (listing) {
    isListingEligible = listing.lula_eligible;
  }

  const showLulaPromo =
    isLulaEligible &&
    !hasMaintenancePlusSubscribed &&
    !isEnrolledFreeLula &&
    isListingEligible;

  if (!showLulaPromo || loading) {
    return null;
  }

  return children;
};

RenderIfLulaEligible.propTypes = {
  children: PropTypes.node,
  listing: PropTypes.object,
};

export default RenderIfLulaEligible;
