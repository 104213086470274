import React from 'react';
import PropTypes from 'prop-types';

import SectionInfoBlock from '../../../../../common/SectionInfoBlock';

const divisionTexts = {
  cookCounty: (
    <>
      Rent is due in full on the first day of each and every month. If rent is
      not received on or before the 2nd day of the month, you may charge your
      Tenant a late fee of $10 a month for rent under $1,000 or 5% of any rent
      amount over $1,000.00
    </>
  ),
  chicago: (
    <>
      Rent is due in full on the first day of each and every month. If rent is
      not received on or before the 2nd day of the month, you may charge your
      Tenant a late fee of $10 a month for rent under $500 plus 5% of any rent
      amount over $500.00. For example, if rent is $450, then the late fee would
      be $10; If rent is $700, late charge would be $20.
    </>
  ),
};

const LateFeeInfo = ({ className, leaseAgreementSelection }) => (
  <SectionInfoBlock className={className}>
    <>
      <strong>Late Fees:</strong>{' '}
      {divisionTexts[leaseAgreementSelection] || (
        <>
          Rent is due in full on the first day of each and every month. If rent
          is not received on or before the 5th day of the month, you may charge
          your Tenant a late fee of 5% of the unpaid rent amount.
        </>
      )}
    </>
  </SectionInfoBlock>
);

LateFeeInfo.propTypes = {
  className: PropTypes.string,
};

export default LateFeeInfo;
