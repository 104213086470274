import get from 'lodash.get';
import queryString from 'query-string';

// queryString.stringify will add extra `=` and that is ugly
const stringify = (params) => {
  return Object.keys(params)
    .filter((key) => params[key] != null)
    .map((key) => (params[key] ? `${key}=${params[key]}` : key))
    .join('&');
};

const queryState = (history, key) => {
  const search = get(history, 'location.search', '');
  const pathname = get(history, 'location.pathname', '');
  const params = queryString.parse(search);
  const state = key in params;
  const change = (newState) => {
    const newSearch = stringify({
      ...params,
      [key]: newState ? '' : null,
    });
    newSearch.replace(`${key}=`, key);
    history[newState ? 'push' : 'replace'](
      `${pathname}${newSearch ? `?${newSearch}` : ''}`,
    );
  };
  return [state, change];
};

export default queryState;
