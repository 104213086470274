import React from 'react';
import InputMask from 'react-input-mask';
import cx from 'classnames';
import PropTypes from 'prop-types';

import SecurityIcon from '../../icons/Security';
import getFieldError from '../../validators/get-field-error';
import Label from '../Label';
import PlainInputWithIcon from '../PlainInputWithIcon';

import styles from './TaxIDField.module.scss';

const TaxIDField = ({
  input,
  id,
  label,
  className,
  meta,
  disabled,
  hint,
  ...otherProps
}) => {
  const error = getFieldError(meta);
  return (
    <div
      className={cx(styles.container, className, {
        [styles.disabled]: disabled,
      })}
    >
      {label ? (
        <Label htmlFor={id} hint={hint}>
          {label}
        </Label>
      ) : null}
      <InputMask
        mask="99-9999999"
        maskChar="_"
        inputMode="tel"
        className={styles.mask}
        {...input}
        onChange={(e) => {
          e.preventDefault();
          input.onChange(e.target.value);
        }}
        value={input.value}
        disabled={disabled}
        {...otherProps}
      >
        {(passProps) => (
          <PlainInputWithIcon
            icon={SecurityIcon}
            invalid={!!error}
            id={id}
            type="text"
            className={styles.field}
            disabled={disabled}
            {...passProps}
          />
        )}
      </InputMask>
      {!!error && (
        <span id={`${id}_errormsg`} className={styles.error}>
          {error}
        </span>
      )}
    </div>
  );
};

TaxIDField.propTypes = {
  input: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.object,
  alwaysShowMask: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

TaxIDField.defaultProps = {
  alwaysShowMask: false,
  disabled: false,
};

export default TaxIDField;
