import React, { memo } from 'react';
import PropTypes from 'prop-types';

const CashBag = ({ color, ...props }) => (
  <svg viewBox="0 0 21 30" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M14.009 12.24a.99.99 0 0 1 .584.19c3.247 2.372 6.074 6.541 6.074 9.714 0 5.082-3.16 7.523-10 7.523s-10-2.441-10-7.523c0-3.172 2.826-7.341 6.072-9.714a.99.99 0 0 1 .584-.19Zm-.332 1.948H7.653l-.338.271c-2.445 2.016-4.516 5.16-4.672 7.418l-.01.267c0 3.885 2.188 5.574 8.034 5.574 5.845 0 8.032-1.689 8.032-5.574 0-2.271-2.139-5.589-4.681-7.685l-.341-.27Zm-3.076 1.028a.98.98 0 0 1 .974.842l.01.133v.393a2.763 2.763 0 0 1 1.803 2.583c0 .538-.44.974-.984.974a.98.98 0 0 1-.975-.842l-.009-.132a.816.816 0 0 0-.82-.812.816.816 0 0 0-.819.812c0 .449.367.812.82.812 1.538 0 2.787 1.236 2.787 2.76 0 1.182-.75 2.19-1.804 2.584v.393c0 .538-.44.974-.983.974a.98.98 0 0 1-.975-.842l-.009-.132v-.393a2.762 2.762 0 0 1-1.803-2.584c0-.538.44-.974.983-.974a.98.98 0 0 1 .975.842l.01.132c0 .449.366.812.819.812.452 0 .82-.363.82-.812a.816.816 0 0 0-.82-.812c-1.54 0-2.787-1.235-2.787-2.76 0-1.18.75-2.19 1.803-2.583v-.393c0-.538.44-.975.984-.975Zm4.273-5.357c.543 0 .984.436.984.974 0 .493-.37.9-.85.965l-.134.009H6.459a.979.979 0 0 1-.984-.974c0-.493.37-.901.85-.966l.134-.008h8.415ZM9.754.946l-.83 2.048-3.28-1.391c-.9-.382-1.749.63-1.206 1.436l.177.274a20.977 20.977 0 0 1 .964 1.704c.319.632.583 1.242.773 1.808.214.634.325 1.187.325 1.626 0 .538.44.975.984.975h6.01a.979.979 0 0 0 .984-.975c0-.439.112-.992.326-1.626.19-.566.454-1.176.773-1.808.25-.497.52-.977.79-1.422l.304-.486.047-.07.063-.107c.405-.785-.41-1.694-1.27-1.33l-3.282 1.392L11.58.946c-.33-.817-1.497-.817-1.827 0Zm.912 2.985.29.715.058.121a.989.989 0 0 0 1.242.413l1.553-.66-.215.467a14.06 14.06 0 0 0-.48 1.222l-.11.345c-.068.228-.126.45-.173.667l-.05.256h-4.23L8.5 7.22a9.438 9.438 0 0 0-.283-1.012l-.142-.401a14.98 14.98 0 0 0-.338-.82l-.218-.469 1.557.66a.987.987 0 0 0 1.3-.533l.289-.715Z"
    />
  </svg>
);

CashBag.defaultProps = {
  width: 21,
  height: 30,
  color: 'currentColor',
};

CashBag.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(CashBag);
