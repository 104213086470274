import React from 'react';

import isGreaterThan from '../../validators/isGreaterThan';
import isLessThanOrEqualTo from '../../validators/isLessThanOrEqualTo';
import Input from '../Input';

import CoreField from './CoreField';

export const AgeField = (props) => (
  <CoreField
    component={Input}
    type="number"
    pattern="[0-9]*"
    parse={(value) => (value ? value.replace(/[^0-9]/gi, '') : '')}
    validate={[isGreaterThan(0), isLessThanOrEqualTo(100)]}
    {...props}
  />
);

export default AgeField;
