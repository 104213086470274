import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

const UniversalMessagingContext = createContext();

const UniversalMessagingProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const toggleChat = useCallback(() => {
    if (!isOpen) {
      setPosition({ x: 0, y: 0 });
    }
    setIsOpen((prev) => !prev);
  }, []);

  const updatePosition = useCallback((newPosition) => {
    setPosition({
      x: newPosition.x,
      y: newPosition.y,
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      isOpen,
      position,
      toggleChat,
      updatePosition,
    }),
    [isOpen, position, toggleChat, updatePosition],
  );

  return (
    <UniversalMessagingContext.Provider value={contextValue}>
      {children}
    </UniversalMessagingContext.Provider>
  );
};

UniversalMessagingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUniversalMessagingContext = () =>
  useContext(UniversalMessagingContext);

export default UniversalMessagingProvider;
