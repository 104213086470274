export const FEATURE_FLAGS = {
  IS_BROKER: 'IS_BROKER',
  DISABLE_TRUSTED_DEVICE: 'DISABLE_TRUSTED_DEVICE',
  DISABLE_CHECK_INS: 'DISABLE_CHECK_INS',
  DISABLE_FAST_PAYOUTS: 'DISABLE_FAST_PAYOUTS',
  SHOW_TOOLBOX: 'SHOW_TOOLBOX',
  LOGIN_RECAPTCHA: 'LOGIN_RECAPTCHA',
  FORCE_LOGIN_RECAPTCHA: 'FORCE_LOGIN_RECAPTCHA',
  MESSAGING_V2: 'MESSAGING_V2',
  CALL_FORWARDING: 'CALL_FORWARDING',
  CUSTOM_PRESCREENER_QUESTIONS: 'CUSTOM_PRESCREENER_QUESTIONS',
  SURE_INSURANCE: 'SURE_INSURANCE',
  ENABLE_FORMS_EXPERIMENT: 'ENABLE_FORMS_EXPERIMENT',
  PAYMENT_ALERTS: 'PAYMENT_ALERTS',
  STRIPE_IDENTITY_VERIFICATION: 'STRIPE_IDENTITY_VERIFICATION',
  DISABLE_MOBILE_REVIEW: 'DISABLE_MOBILE_REVIEW',
  PHONE_MASKING: 'PHONE_MASKING',
  LULA_MAINTENANCE: 'LULA_MAINTENANCE',
};
