import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';
import ArrowLeft from '../../icons/ArrowLeft';
import ArrowRight from '../../icons/ArrowRight';
import IconButton from '../IconButton';

import styles from './SimpleSlider.module.scss';

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 300 : -300,
      opacity: 0,
    };
  },
  animate: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      position: 'absolute',
      zIndex: 0,
      x: direction < 0 ? 300 : -300,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const SimpleSlider = ({
  children,
  className,
  autoplay = false,
  speed = 3000,
  infinite = true,
}) => {
  const [[page, direction], setState] = useState([0, 0]);
  const childs = React.Children.toArray(children);
  const paginate = (direction) => {
    setState([page + direction, direction]);
  };
  const len = childs.length;
  const active = ((page % len) + len) % len;

  useEffect(() => {
    if (autoplay) {
      const interval = setInterval(() => {
        paginate(1);
      }, speed);
      return () => {
        clearInterval(interval);
      };
    }
  }, [page, autoplay]);

  // Conditionally handle arrow navigation based on the "infinite" prop
  const canNavigateLeft = infinite || active !== 0;
  const canNavigateRight = infinite || active !== len - 1;

  return (
    <div className={cx(styles.wrap, className)}>
      <div className={styles.container}>
        <div className={styles.slider}>
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="animate"
              exit="exit"
              transition={{
                x: { type: 'spring', stiffness: 450, damping: 30 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  if (canNavigateRight) {
                    paginate(1);
                  }
                } else if (swipe > swipeConfidenceThreshold) {
                  if (canNavigateLeft) {
                    paginate(-1);
                  }
                }
              }}
              className={styles.slide}
            >
              {childs[active]}
            </motion.div>
          </AnimatePresence>
        </div>

        <div className={styles.sliderNavigation}>
          <IconButton
            icon={ArrowLeft}
            iconProps={{
              color: !canNavigateLeft ? colors.greyLight : null,
            }}
            onClick={() => {
              if (canNavigateLeft) {
                paginate(-1);
              }
            }}
            className={cx(styles.arrow, styles.left)}
          />
          <div className={styles.dots}>
            {childs.map((c, index) => (
              <button
                className={cx(styles.dot, {
                  [styles.active]: active === index,
                })}
                key={index}
                onClick={() => {
                  if (active === index) {
                    return;
                  }
                  setState([index, active > index ? -1 : 1]);
                }}
              />
            ))}
          </div>
          <IconButton
            iconProps={{
              color: !canNavigateRight ? colors.greyLight : null,
            }}
            icon={ArrowRight}
            onClick={() => {
              if (canNavigateRight) {
                paginate(1);
              }
            }}
            className={cx(styles.arrow, styles.right)}
          />
        </div>
      </div>
    </div>
  );
};

SimpleSlider.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string,
  autoplay: PropTypes.bool,
  speed: PropTypes.number,
  infinite: PropTypes.bool,
};

export default SimpleSlider;
