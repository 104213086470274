import { useEffect } from 'react';

const defaultPageTitle = 'TurboTenant - The Easiest Landlord Software';

const Page = ({ title, children }) => {
  useEffect(() => {
    document.title = title || defaultPageTitle;
    return () => {
      document.title = defaultPageTitle;
    };
  }, [title]);

  return children;
};

export default Page;
