import React from 'react';
import PropTypes from 'prop-types';

import CheckmarkIcon from '../../../icons/CheckMarkFilled';
import getColor from '../utils/getColor';

const Checkmark = ({ color }) => (
  <CheckmarkIcon width={20} height={20} color={getColor(color)} />
);

Checkmark.propTypes = {
  color: PropTypes.string,
};

export default Checkmark;
