import React from 'react';
import PropTypes from 'prop-types';

import SectionInfoBlock from '../../../../../common/SectionInfoBlock';

const LateFeeInfoCO = ({ className }) => (
  <SectionInfoBlock className={className}>
    <>
      <strong>Late Fees:</strong> Rent is due in full on the first day of each
      and every month. If rent is not received on or before the 7th day of the
      month, you may charge your tenant a late fee of $50 or 5% of the balance
      due of unpaid rent amount, whichever is greater.
    </>
  </SectionInfoBlock>
);

LateFeeInfoCO.propTypes = {
  className: PropTypes.string,
};

export default LateFeeInfoCO;
