import './setup-dotenv';

import settings from './settings';

function checkSettings(obj, key) {
  if (process.env.NODE_ENV === 'test') {
    // No need to check
    return;
  }
  if (obj !== undefined) {
    if (typeof obj == 'string' || typeof obj == 'boolean') {
      return;
    }
    const keys = Object.keys(obj);
    if (keys.length > 0) {
      Object.keys(obj).forEach((key) => {
        checkSettings(obj[key], key);
      });
    }
    return;
  }
  throw new Error('Missing ENV property' + (key ? ': ' + key : ''));
}

if (process.env.NODE_ENV === 'test') {
  settings.db.databaseUrl = process.env.DB_TEST_URL;
}

export { checkSettings };

export default settings;
