import React from 'react';
import PropTypes from 'prop-types';

import SectionInfoBlock from '../../../../../common/SectionInfoBlock';

const LateFeeInfo = ({ className }) => (
  <SectionInfoBlock className={className}>
    <>
      <strong>Late Fees:</strong> Rent is due in full on the first day of each
      and every month. If rent is not received on or before the 5th day of the
      month, for rent $700 or less, Landlord may charge $12/day for late rent.
      For rent greater than $700, $20/day may be charged.
    </>
  </SectionInfoBlock>
);

LateFeeInfo.propTypes = {
  className: PropTypes.string,
};

export default LateFeeInfo;
