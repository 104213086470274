import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const ListingHouse = (props) => (
  <svg {...props} viewBox="0 0 28 23">
    <path
      d="m14.485.49 4.684 3.411v-.62c0-.41.312-.748.714-.797l.103-.007h3.265c.416 0 .76.307.81.704l.006.1v4.187L27 9.605c.633.461.302 1.451-.485 1.451h-2.45V21.39h2.45c.416 0 .759.307.81.704l.006.1c0 .41-.312.75-.714.799l-.102.006H1.484a.81.81 0 0 1-.816-.804c0-.41.312-.749.714-.798l.102-.006h2.449V11.054l-2.449.001c-.787 0-1.118-.99-.485-1.45L13.515.49a.826.826 0 0 1 .97 0Zm7.95 10.71H5.564v9.937h1.633V14.1c0-.634.366-1.147.816-1.147h3.265c.451 0 .816.513.816 1.147v7.035h10.34v-9.935Zm-11.972 4.047H8.831v5.35h1.632v-5.35Zm9.523-2.583c.45 0 .816.36.816.804v3.217a.81.81 0 0 1-.816.805h-5.442a.81.81 0 0 1-.816-.805v-3.217a.81.81 0 0 1 .816-.804h5.442Zm-.817 1.609H15.36v1.608h3.81v-1.608ZM14 2.138 3.962 9.448h20.076L14 2.138Zm8.434 1.948h-1.632V5.09l1.632 1.19V4.085Z"
      fill={props.color}
      fillRule="evenodd"
    />
  </svg>
);

ListingHouse.defaultProps = {
  width: 28,
  height: 23,
  color: colors.text,
};

ListingHouse.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ListingHouse);
