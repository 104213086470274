import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Drywall = ({ color, ...props }) => (
  <svg viewBox="0 0 20 20" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M19.373 0c.346 0 .627.286.627.64v18.72c0 .354-.28.64-.627.64H2.841a.633.633 0 0 1-.626-.64l-.001-4.082H.627a.631.631 0 0 1-.621-.552L0 14.64V10c0-.35.276-.635.62-.64l1.141-.014V6.102H.627a.631.631 0 0 1-.621-.552L0 5.464V.639C0 .286.28 0 .627 0h18.746Zm-8.918 15.278H3.468v3.443h6.987v-3.443Zm3.731 0h-2.478v3.443h7.038v-1.816a37.907 37.907 0 0 1-4.56-1.626Zm4.56-14L6.115 1.28C4.95 7.943 9.078 12.682 18.745 15.574V1.278ZM4.473 10.617H2.396l-1.142.014V14h3.219v-3.383Zm2.895 0H5.727V14h5.902c-1.748-1.002-3.169-2.13-4.261-3.382Zm-2.39-4.514-1.964-.001v3.236h3.38A10.382 10.382 0 0 1 4.98 6.104Zm-.135-4.824h-3.59v3.545l3.494.001c-.135-1.12-.103-2.303.096-3.546Z"
    />
  </svg>
);

Drywall.defaultProps = {
  width: 20,
  height: 20,
  color: '#042238',
};

Drywall.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Drywall);
