import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RadioGroup from '../../../../../../../components/RadioGroup';
import ExplosionIcon from '../../../../../../../icons/Explosion';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './MilitaryTestingDisclosureCA.module.scss';

const MilitaryTestingDisclosureCA = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Military Testing Disclosure" icon={ExplosionIcon} />
      <label className={styles.label}>
        Is the property located within 1 mile of a military testing site with an
        explosive risk?
      </label>
      <p className={styles.description}>
        California law requires you to disclose this.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.military_testing_site"
        name="custom_state_data.military_testing_site"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'military_testing_site_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'military_testing_site_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
    </div>
  );
};

MilitaryTestingDisclosureCA.propTypes = {
  className: PropTypes.string,
};

export default MilitaryTestingDisclosureCA;
