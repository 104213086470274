const NOT_ALLOWED_CHARACTERS = /[?$!;%|><="@`^{}[\]:\\&*]/;

export const validShareAbleUnit = (value) => {
  if (!value || (!NOT_ALLOWED_CHARACTERS.test(value) && value.length <= 100)) {
    return undefined;
  }

  return 'Invalid Unit';
};

export default validShareAbleUnit;
