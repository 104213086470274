import React, { memo } from 'react';
import PropTypes from 'prop-types';

const VerifiedBadge = ({ color, ...props }) => (
  <svg viewBox="0 0 14 16" {...props}>
    <path
      d="M7.006.5c.098 0 .19.043.251.117a3.814 3.814 0 0 0 2.95 1.38c.982 0 1.926-.36 3.037-1.116.213-.144.506.004.506.256v9.258l-.011.083-.032.095c-.217.61-.677 1.367-1.466 2.15-1.192 1.184-2.883 2.146-5.154 2.765a.33.33 0 0 1-.174 0c-1.81-.493-3.26-1.208-4.387-2.09-.803-.627-1.397-1.302-1.814-1.98-.255-.414-.394-.741-.45-.94l-.012-.083V1.137c0-.252.293-.4.506-.256 1.11.755 2.056 1.116 3.038 1.116a3.82 3.82 0 0 0 2.95-1.38.325.325 0 0 1 .25-.117Zm3.43 4.76a.656.656 0 0 0-.926.054L5.964 9.303 4.455 7.849l-.069-.057a.656.656 0 0 0-.843 1.002l2 1.928.07.06c.27.195.65.16.876-.096l4-4.5.055-.07a.656.656 0 0 0-.109-.856Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

VerifiedBadge.defaultProps = {
  width: 14,
  height: 16,
  color: '#04BF8D',
};

VerifiedBadge.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(VerifiedBadge);
