import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TownhouseColor = ({ ...props }) => (
  <svg {...props} viewBox="0 0 40 37">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#7FE3FF"
        d="M25.833 14.295c.92 0 1.667.754 1.667 1.682 0 .93-.746 1.682-1.667 1.682-.92 0-1.666-.753-1.666-1.682 0-.928.747-1.682 1.666-1.682ZM30 30.272v5.045h-3.333v-5.045zM32 9h3v5h-3zM8.167 11.632h-3v4l3-1zM13.333 29.443v5.907H10v-5.907zM13.333 18.81v3.454H10V18.81z"
      />
      <path
        fill="#042238"
        d="m18.884.21 11.949 10.61V8.409c0-.465.373-.841.834-.841H35c.46 0 .833.376.833.84v6.852l3.884 3.45c.346.306.38.838.075 1.186a.828.828 0 0 1-1.176.076L37.5 18.98v16.338h.834c.46 0 .834.377.834.841 0 .465-.373.841-.834.841H1.667a.837.837 0 0 1-.834-.84c0-.465.374-.842.834-.842H2.5V19.301l-1.233.758a.829.829 0 0 1-1.145-.28.845.845 0 0 1 .278-1.156l2.1-1.291v-.04l.067-.001 1.599-.982v-4.537c0-.464.374-.84.834-.84h3.333c.46 0 .834.376.834.84l-.001 1.466 10-6.143V2.701l-1.383-1.229a.846.846 0 0 1-.075-1.187.828.828 0 0 1 1.176-.075Zm1.949 3.971v31.137H25v-5.887c0-.429.318-.782.729-.834l.104-.007h5c.46 0 .834.377.834.841l-.001 5.887h4.167V17.501l-15-13.32Zm-1.667 4.883-15 9.214v17.04h4.167v-5.887c0-.429.318-.782.73-.834l.104-.007h5c.46 0 .833.377.833.841v5.887h4.166V9.064ZM30 30.272h-3.333v5.045H30v-5.045Zm-16.667 0H10v5.045h3.333v-5.045Zm.834-5.046c.46 0 .833.377.833.841 0 .465-.373.841-.833.841h-5a.837.837 0 0 1-.834-.84c0-.465.374-.842.834-.842Zm16.659 0a.84.84 0 1 1 0 1.682H25.84a.84.84 0 0 1 0-1.682Zm-16.66-7.568c.461 0 .834.377.834.841v4.205c0 .464-.373.84-.833.84h-5a.837.837 0 0 1-.834-.84v-4.205c0-.464.374-.84.834-.84Zm-.833 1.682H10v2.523h3.333V19.34Zm12.5-6.726c1.84 0 3.334 1.506 3.334 3.363 0 1.858-1.493 3.364-3.334 3.364-1.84 0-3.333-1.506-3.333-3.364 0-1.857 1.493-3.363 3.333-3.363Zm0 1.681c-.92 0-1.666.754-1.666 1.682 0 .93.746 1.682 1.666 1.682.92 0 1.667-.753 1.667-1.682 0-.928-.747-1.682-1.667-1.682ZM7.5 12.613H5.833v2.672L7.5 14.261v-1.648ZM34.167 9.25H32.5V12.3l1.666 1.479V9.25Z"
      />
    </g>
  </svg>
);

TownhouseColor.defaultProps = {
  width: 40,
  height: 37,
};

TownhouseColor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(TownhouseColor);
