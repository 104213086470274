import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import LoadingScreen from '../../../../../components/LoadingScreen';
import leaseRequestGQL from '../../../../../graphql/queries/leaseRequest.graphql';
import ListingProvider from '../../../../../providers/ListingProvider';
import AddCharges from '../AddCharges';

const ChargesWrapper = ({
  onNext,
  onSkipCharges,
  isEdit,
  leasesLoading,
  chargesListLoading,
  deleteLoading,
  onChargeCreate,
  onChargeDelete,
  onChargeEdit,
  monthlyCharges,
  oneTimeCharges,
  leaseId,
  isPremiumUser,
  bankAccountOptions,
  loadingUser,
}) => {
  const { data, loading: loadingLease } = useQuery(leaseRequestGQL, {
    variables: {
      id: leaseId,
    },
    skip: !leaseId,
    notifyOnNetworkStatusChange: true,
  });

  const lease = data?.node || {};

  const leaseHasTenants = !!lease?.renters?.length;

  const loading =
    leasesLoading ||
    chargesListLoading ||
    deleteLoading ||
    loadingLease ||
    loadingUser;

  if (!leaseId || loading) return <LoadingScreen loading />;

  return (
    <ListingProvider listing={lease?.listing}>
      <AddCharges
        onChargeCreate={onChargeCreate}
        onChargeDelete={onChargeDelete}
        onChargeEdit={onChargeEdit}
        monthlyCharges={monthlyCharges}
        oneTimeCharges={oneTimeCharges}
        lease={lease}
        onNext={onNext}
        onSkipCharges={onSkipCharges}
        submitLabel={isEdit ? 'Save' : 'Next'}
        leaseHasTenants={leaseHasTenants}
        isPremiumUser={isPremiumUser}
        bankAccountOptions={bankAccountOptions}
      />
    </ListingProvider>
  );
};

ChargesWrapper.propTypes = {
  leaseId: PropTypes.string,
  isPremiumUser: PropTypes.bool,
  bankAccountOptions: PropTypes.array,
  loadingUser: PropTypes.bool,
  isOnboardingFlow: PropTypes.bool,
};

export default withRouter(ChargesWrapper);
