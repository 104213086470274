import React from 'react';

import { P } from './EmailTypography';
import { Link } from './EmailTypography';

const RedfinFooter = () => (
  <P
    style={{
      textAlign: 'center',
      fontSize: '14px',
      marginBottom: 0,
    }}
  >
    Need a little help? Email our Colorado-based support staff at{' '}
    <Link href="https://support.turbotenant.com/" style={{ fontSize: 14 }}>
      support@turbotenant.com.
    </Link>
  </P>
);

export default RedfinFooter;
