import React from 'react';
import PropTypes from 'prop-types';

const InformationalIcon = (props) => (
  <svg fill="#042238" {...props} viewBox="0 0 20 18">
    <path
      d="M19.362 0c.352 0 .638.281.638.628v13.814a.633.633 0 0 1-.638.628c-4.684 0-8.724 1.246-8.724 2.302 0 .837-1.276.837-1.276 0 0-1.055-4.04-2.302-8.724-2.302A.633.633 0 0 1 0 14.442V.628C0 .28.286 0 .638 0 4.846 0 8.558.857 10 2.246 11.442.857 15.154 0 19.362 0ZM1.277 1.263v12.558c3.435.071 6.469.718 8.097 1.755V3.558h-.012c0-1.007-3.68-2.189-8.085-2.295Zm17.446 0c-.332.008-.66.023-.984.042v6.171c0 .539-.605.808-.959.428l-1.102-1.185-1.101 1.185c-.354.38-.96.11-.96-.428V1.912c-1.706.429-2.843 1.008-2.967 1.549v12.1c1.634-1.029 4.655-1.67 8.073-1.74V1.263Zm-1.32 10.04a.62.62 0 0 1 .655.585.624.624 0 0 1-.572.669c-2.06.142-3.882.499-5.16.998a.612.612 0 0 1-.796-.362.632.632 0 0 1 .355-.81c1.405-.55 3.343-.929 5.517-1.08Zm-14.88-.435c2.174.15 4.113.53 5.517 1.078a.632.632 0 0 1 .355.811.612.612 0 0 1-.796.362c-1.278-.499-3.099-.856-5.16-.998a.624.624 0 0 1-.573-.669.62.62 0 0 1 .656-.584Zm14.88-2.076a.62.62 0 0 1 .655.584.624.624 0 0 1-.572.67c-2.06.142-3.882.498-5.16.997a.612.612 0 0 1-.796-.362.632.632 0 0 1 .355-.81c1.405-.55 3.343-.929 5.517-1.079Zm-14.88-.436c2.174.15 4.113.53 5.517 1.079a.632.632 0 0 1 .355.81.612.612 0 0 1-.796.362c-1.278-.499-3.099-.855-5.16-.998a.624.624 0 0 1-.573-.668.62.62 0 0 1 .656-.585Zm0-2.511c2.174.15 4.113.53 5.517 1.078a.632.632 0 0 1 .355.811.612.612 0 0 1-.796.362c-1.278-.5-3.099-.856-5.16-.998a.624.624 0 0 1-.573-.67.62.62 0 0 1 .656-.583ZM14.74 1.668v4.35l.54-.58a.535.535 0 0 1 .795 0l.54.58v-4.62c-.662.07-1.29.16-1.875.27ZM2.522 3.333c2.175.15 4.114.53 5.518 1.079a.632.632 0 0 1 .355.81.612.612 0 0 1-.796.362c-1.278-.499-3.099-.856-5.16-.998a.624.624 0 0 1-.573-.669.62.62 0 0 1 .656-.584Z"
      fillRule="evenodd"
    />
  </svg>
);

InformationalIcon.defaultProps = {
  width: 20,
  height: 18,
};

InformationalIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default InformationalIcon;
