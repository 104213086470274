import { InMemoryCache } from '@apollo/client/cache';

// function toIdValue(idConfig, generated) {
//   if (generated === void 0) {
//     generated = false;
//   }
//   return {
//     ...({ type: 'id', generated: generated },
//     typeof idConfig === 'string'
//       ? { id: idConfig, typename: undefined }
//       : idConfig),
//   };
// }

export const apolloCache = new InMemoryCache({
  dataIdFromObject: (o) => {
    if (!o || !o.id) {
      return null;
    }
    return `${o.__typename || 'notype'}.${o.id || 'noid'}`;
  },
  cacheRedirects: {
    Query: {
      // node: (_, args) => toIdValue(args.id),
      node: (_, args) => ({
        type: 'id',
        id: args.id,
        generated: false,
      }),
    },
  },
  possibleTypes: {
    Node: [
      'RentalRequest',
      'Renter',
      'Lease',
      'Listing',
      'Owner',
      'SignatureRequest',
      'LeaseAgreement',
      'PaymentRequest',
      'RentPayment',
      'AutoPayRule',
      'PublicOwnerType',
      'Lead',
      'PaymentRequestRule',
      'OwnerProfile',
      'RenterProfile',
      'RenterInsuranceQuote',
      'ScheduledShowing',
    ],
    BasicUserProfile: ['OwnerProfile', 'RenterProfile'],
    UserProfile: ['OwnerProfile', 'RenterProfile'],
  },
  typePolicies: {
    PaymentsSummary: {
      keyFields: [],
    },
    RentPaymentsDashboardStats: {
      keyFields: [],
    },
  },
});
