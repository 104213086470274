import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Manufactured = (props) => (
  <svg {...props} viewBox="0 0 20 20">
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons/Property/Type/Manufactured/Dark"
        transform="translate(-2.000000, -2.000000)"
        fillRule="nonzero"
        fill={props.color}
      >
        <g id="Manufactured" transform="translate(2.000000, 2.000000)">
          <path
            d="M18.2978723,16.9708333 L18.2978723,17.3041667 C18.2978723,17.580309 18.0740147,17.8041667 17.7978723,17.8041667 L16.80681,17.8041667 L16.80681,16.9708333 L18.2978723,16.9708333 Z M13.0434392,16.9708333 L13.0434392,17.8041667 L6.14600368,17.8041667 L6.14600368,16.9708333 L13.0434392,16.9708333 Z M2.12765957,16.9708333 L2.29935893,16.9708333 L2.29935893,17.8041667 L1.77659574,17.8041667 C1.50045337,17.8041667 1.27659574,17.580309 1.27659574,17.3041667 L1.27659574,8.77061704 L18.2978723,2.19605564 L18.2978723,17.3875 L17.4468085,17.3875 L17.4468085,3.42061103 L2.12765957,9.33771629 L2.12765957,16.9708333 Z"
            id="Shape"
          ></path>
          <path
            d="M1.06382979,8.08748558 L18.5106383,1.33360961 L18.5106383,2.1055517 L17.2800658,2.58821054 L17.5965299,3.36178946 L19.0442726,2.79395148 C19.2357496,2.71884978 19.3617021,2.53415401 19.3617021,2.32847534 L19.3617021,0.837769951 C19.3617021,0.561627577 19.1378445,0.337769951 18.8617021,0.337769951 C18.7999687,0.337769951 18.7387691,0.349202172 18.6811987,0.371488356 L0.532262568,7.39716665 C0.339698696,7.47171051 0.212765957,7.65695943 0.212765957,7.86344825 L0.212765957,9.35369136 C0.212765957,9.62983373 0.436623583,9.85369136 0.712765957,9.85369136 C0.774803717,9.85369136 0.836299724,9.84214616 0.894113214,9.8196454 L2.14441195,9.33303466 L1.83005614,8.55863201 L1.06382979,8.85684392 L1.06382979,8.08748558 Z"
            id="Shape"
          ></path>
          <path
            d="M13.1136367,10.4113635 L15.7863639,10.4113635 L15.7863639,7.73863633 L13.1136367,7.73863633 L13.1136367,10.4113635 Z M16.5500002,7.4331818 L16.5500002,10.716818 C16.5500002,10.9698648 16.3491389,11.1749998 16.1013638,11.1749998 L12.7986367,11.1749998 C12.5508617,11.1749998 12.3500004,10.9698648 12.3500004,10.716818 L12.3500004,7.4331818 C12.3500004,7.18013498 12.5508617,6.975 12.7986367,6.975 L16.1013638,6.975 C16.3491389,6.975 16.5500002,7.18013498 16.5500002,7.4331818 Z"
            id="Rectangle-path"
          ></path>
          <path
            d="M7.44680851,9.475 L7.44680851,16.975 L10.8510638,16.975 L10.8510638,9.475 L7.44680851,9.475 Z M11.7021277,9.14166667 L11.7021277,17.8083333 L6.59574468,17.8083333 L6.59574468,9.14166667 C6.59574468,8.86552429 6.81960231,8.64166667 7.09574468,8.64166667 L11.2021277,8.64166667 C11.47827,8.64166667 11.7021277,8.86552429 11.7021277,9.14166667 Z"
            id="Rectangle-path"
          ></path>
          <path
            d="M18.0851064,16.5583333 L19.3705674,16.5583333 C19.600686,16.5583333 19.787234,16.3717853 19.787234,16.1416667 C19.787234,15.911548 19.600686,15.725 19.3705674,15.725 L18.0851064,15.725 L18.0851064,16.5583333 Z"
            id="Shape"
          ></path>
          <path
            d="M3.18297872,4.8875 L4.04255319,4.8875 L4.04255319,6.4875 L4.89361702,6.4875 L4.89361702,4.55416667 C4.89361702,4.27802429 4.6697594,4.05416667 4.39361702,4.05416667 L2.83191489,4.05416667 C2.55577252,4.05416667 2.33191489,4.27802429 2.33191489,4.55416667 L2.33191489,6.82083333 L3.18297872,6.82083333 L3.18297872,4.8875 Z"
            id="Shape"
          ></path>
          <path
            d="M4.25531915,19.8916667 C2.96273782,19.8916667 1.91489362,18.8656526 1.91489362,17.6 C1.91489362,16.3343474 2.96273782,15.3083333 4.25531915,15.3083333 C5.54790048,15.3083333 6.59574468,16.3343474 6.59574468,17.6 C6.59574468,18.8656526 5.54790048,19.8916667 4.25531915,19.8916667 Z M4.25531915,19.0583333 C5.0778709,19.0583333 5.74468085,18.4054153 5.74468085,17.6 C5.74468085,16.7945847 5.0778709,16.1416667 4.25531915,16.1416667 C3.43276739,16.1416667 2.76595745,16.7945847 2.76595745,17.6 C2.76595745,18.4054153 3.43276739,19.0583333 4.25531915,19.0583333 Z"
            id="Oval"
          ></path>
          <path
            d="M14.893617,19.8916667 C13.6010357,19.8916667 12.5531915,18.8656526 12.5531915,17.6 C12.5531915,16.3343474 13.6010357,15.3083333 14.893617,15.3083333 C16.1861984,15.3083333 17.2340426,16.3343474 17.2340426,17.6 C17.2340426,18.8656526 16.1861984,19.8916667 14.893617,19.8916667 Z M14.893617,19.0583333 C15.7161688,19.0583333 16.3829787,18.4054153 16.3829787,17.6 C16.3829787,16.7945847 15.7161688,16.1416667 14.893617,16.1416667 C14.0710653,16.1416667 13.4042553,16.7945847 13.4042553,17.6 C13.4042553,18.4054153 14.0710653,19.0583333 14.893617,19.0583333 Z"
            id="Oval"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

Manufactured.defaultProps = {
  width: 34,
  height: 44,
  color: colors.text,
};

Manufactured.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Manufactured);
