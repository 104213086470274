import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Close from '../../icons/Close';
import IconButton from '../IconButton';
import Modal from '../Modal';

import styles from './PreviewEmailModal.module.scss';

const PreviewEmailModal = ({ open, onClose, className, children }) => (
  <Modal
    open={open}
    className={cx(styles.container, className)}
    onClose={onClose}
    mobileFullScreen
  >
    <div className={styles.header}>
      <div>
        <h2 className={styles.title}>Email Preview</h2>
        <p className={styles.subtitle}>
          This is what the email we send will look like.
        </p>
      </div>
      <IconButton
        icon={Close}
        onClick={onClose}
        className={styles.closeMobile}
        iconProps={{ width: 19, height: 19, color: '#033A6D' }}
      />
    </div>
    {children}
  </Modal>
);
PreviewEmailModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.object,
};

export default PreviewEmailModal;
