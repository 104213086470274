import React from 'react';
// import AppleSignin from 'react-apple-signin-auth';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

// import AppleIcon from '../../icons/AppleIcon';
// import { useConfig } from '../../providers/ConfigProvider';
// import { segmentTracking } from '../../services/utilities';
import FaceBookLogin from '../FacebookLogin';
import GoogleLogin from '../GoogleLogin';
import { useErrorToast } from '../Toast';

import styles from './SocialLoginRetner.module.scss';

const SocialLoginRetner = ({
  onSuccess,
  // onAppleSignin,
  // appleLoading,
  className,
}) => {
  // const { RENTERS_URL } = useConfig();
  const showErrorMessage = useErrorToast();

  return (
    <div className={cx(styles.container, className)}>
      <GoogleLogin
        onSuccess={onSuccess}
        onFailure={() => {
          showErrorMessage('An error occurred while logging in with Google');
        }}
        type="login"
        className={styles.googleButton}
      />
      <FaceBookLogin
        className={styles.facebookButton}
        onSuccess={onSuccess}
        onFailure={() => {
          showErrorMessage('An error occurred while signing up with Facebook');
        }}
        type="login"
      />
      {/* NOTE: Make sure to update the social login modal in application flow when reintroducing this */}
      {/* <AppleSignin
        authOptions={{
          clientId: 'com.turbotenant.web',
          scope: 'email name',
          redirectURI: `${RENTERS_URL}redirect`,
          state: 'state',
          nonce: 'nonce',
          usePopup: true,
        }}
        uiType="dark"
        className="apple-auth-btn"
        noDefaultStyle={false}
        loading={appleLoading}
        buttonExtraChildren="Log in with Apple"
        onSuccess={onAppleSignin}
        onError={() => showErrorMessage('Error during Apple SSO')}
        skipScript={false}
        iconProp={{
          style: {
            marginTop: '10px',
            width: '380px',
            height: '40px',
            textAlign: 'center',
          },
        }}
        render={(props) => (
          <button
            {...props}
            className={styles.customAppleBtn}
            onClick={() => {
              // eslint-disable-next-line react/prop-types
              props.onClick();
              segmentTracking('log_in_with_apple clicked', {
                location: 'Renter Login',
              });
            }}
          >
            <AppleIcon />
            <span className={styles.appleLabel}>Log in with Apple</span>
          </button>
        )}
      /> */}
    </div>
  );
};

SocialLoginRetner.propTypes = {
  className: PropTypes.string,
  onSuccess: PropTypes.func,
  onAppleSignin: PropTypes.func,
  appleLoading: PropTypes.bool,
};

export default withRouter(SocialLoginRetner);
