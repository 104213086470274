import moment from 'moment';

const dateFormats = [
  'MM/DD/YYYY',
  'YYYY-MM-DD',
  'YYYY-MM-DDTHH:mm:ss.SSSZ',
  'MM/YY',
  'YYYYMMDD',
  'll',
];

function getFormattedDateLocalTZ(date, format = 'MM/DD/YYYY') {
  return (
    (date && moment(date, dateFormats, true).local().format(format)) ||
    undefined
  );
}

export default getFormattedDateLocalTZ;
