/**
 * NOTE: type number inputs still allow letter "e" for scientific notation.
 * This is an improved function to prevent it and other unwanted characters.
 * It now works with the Numeric Keypad as well.
 */
function preventNonNumericCharsOnInput(e) {
  // Allow: backspace, delete, tab, escape, enter
  if (
    [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
    // Allow: Ctrl+A, Command+A
    (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
    // Allow: home, end, left, right, down, up
    (e.keyCode >= 35 && e.keyCode <= 40)
  ) {
    // let it happen, don't do anything
    return;
  }

  // Ensure that it is a number and stop the keypress if not
  if (
    (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
    (e.keyCode < 96 || e.keyCode > 105)
  ) {
    e.preventDefault();
  }
}

export default preventNonNumericCharsOnInput;
