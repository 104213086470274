import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TriangleError = ({ color, halfFillColor, ...props }) => (
  <svg viewBox="0 0 40 40" {...props}>
    <g fill="#ff0000" fillRule="evenodd">
      <path fill={halfFillColor} d="M20 2.545v36.85H.606z" />
      <path
        d="M20.163.633c.24.064.387.102.59.45l18.565 37.091c.238.563-.104 1.179-.755 1.22H1.435a.866.866 0 0 1-.755-1.22l18.564-37.09c.275-.47.352-.525.919-.45Zm-.164 2.712L2.8 37.708h34.398L19.999 3.345Zm.086 27.621c.843.064 1.558.768 1.6 1.598.058 1.14-1.251 2.1-2.343 1.64-1.387-.587-1.41-3.292.743-3.238Zm.01-17.44a.877.877 0 0 1 .745.773c.166 4.318.003 8.643.003 12.965-.034.89-1.575 1.022-1.685.065-.166-4.32-.003-8.644-.003-12.966.02-.494.29-.887.94-.837Z"
        fill={color}
      />
    </g>
  </svg>
);

TriangleError.defaultProps = {
  width: 40,
  height: 40,
  color: '#042238',
  halfFillColor: '#7FE3FF',
};

TriangleError.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  halfFillColor: PropTypes.string,
  signAndBorderColor: PropTypes.string,
};

export default memo(TriangleError);
