import React from 'react';
import { has } from 'lodash';
import PropTypes from 'prop-types';

import Checkmark from './Checkmark';
import Numbered from './Numbered';

const mapper = {
  checkmark: Checkmark,
  numbered: Numbered,
};

const getPropsForType = (props, type) => {
  switch (type) {
    default:
      return {};
    case 'checkmark':
      return { color: props.color };
    case 'numbered':
      return {
        color: props.color,
        number: props.number,
        numericColor: props.numericColor,
      };
  }
};

const IconForType = ({ type, ...rest }) => {
  if (!type || !has(mapper, type)) {
    return null;
  }
  const Component = mapper[type];
  const props = getPropsForType(rest, type);

  return <Component {...props} />;
};

IconForType.propTypes = {
  type: PropTypes.oneOf(['checkmark', 'numbered']).isRequired,
  color: PropTypes.string,
  numericColor: PropTypes.string,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default IconForType;
