import React, { memo } from 'react';

import colors from '../constants/colors';

const RealEstateHouseDollarThick = (props) => (
  <svg viewBox="0 0 30 29" {...props} fill={props.color}>
    <path d="M4.823 13.44c.455 0 .83.336.89.772l.008.121v12.213H15.6c.454 0 .83.336.89.772l.008.122a.895.895 0 01-.777.885l-.121.008H4.823a.897.897 0 01-.89-.772l-.008-.121V14.333c0-.493.402-.893.898-.893zm17.96 1.191c3.803 0 6.884 3.066 6.884 6.851 0 3.784-3.082 6.851-6.885 6.851-3.802 0-6.884-3.067-6.884-6.85 0-3.786 3.081-6.852 6.884-6.852zm0 1.787c-2.812 0-5.09 2.266-5.09 5.064 0 2.797 2.279 5.064 5.09 5.064 2.81 0 5.088-2.267 5.088-5.064 0-2.798-2.277-5.064-5.089-5.064zm0 .416c.454 0 .83.337.89.773l.007.121v.21a2.277 2.277 0 011.388 2.093.895.895 0 01-.776.885l-.122.008-.092-.006.134.099c.478.382.799.952.849 1.598l.007.177c0 .94-.572 1.746-1.388 2.093v.209a.896.896 0 01-.898.894.897.897 0 01-.89-.773l-.008-.121v-.21a2.275 2.275 0 01-1.388-2.092c0-.452.338-.826.777-.885l.121-.008.095.006a2.27 2.27 0 01-.986-1.697l-.007-.177c0-.94.572-1.746 1.388-2.094v-.209c0-.494.402-.894.898-.894zm-.733 5.349l.055.063c.118.151.187.34.187.546a.489.489 0 10.49-.488c-.24 0-.472-.037-.69-.106l-.042-.015zm.725-2.64a.488.488 0 00.007.974c.24 0 .472.037.69.105l.04.014-.053-.06a.887.887 0 01-.179-.424l-.008-.121a.49.49 0 00-.483-.489h-.014zM15.497.482l.108.084 13.77 12.51a.89.89 0 01.057 1.263.901.901 0 01-1.164.14l-.105-.082L15 2.436 1.837 14.398a.901.901 0 01-1.172.033l-.097-.09a.89.89 0 01-.034-1.167l.092-.096L14.395.567a.902.902 0 011.102-.084zm1.299 5.808c.496 0 .898.4.898.893v3.575a.896.896 0 01-.898.893h-3.592a.896.896 0 01-.898-.893V7.184c0-.493.402-.893.898-.893zm-.898 1.787h-1.796v1.787h1.796V8.078zm8.082-6.553c.454 0 .83.336.89.772l.008.121v4.17a.896.896 0 01-.898.894.897.897 0 01-.89-.772l-.008-.121V3.312h-3.293a.897.897 0 01-.89-.772l-.008-.122c0-.452.338-.826.776-.885l.122-.008h4.19z" />
  </svg>
);

RealEstateHouseDollarThick.defaultProps = {
  color: colors.text,
  width: 30,
  height: 29,
};

export default memo(RealEstateHouseDollarThick);
