import { getConfig } from '../services/configService';

const getUserPremiumPrice = (user) => {
  if (user?.premium_price_signup) {
    return user.premium_price_signup;
  }

  const { PREMIUM_PLAN_PRICE_DEFAULT } = getConfig();
  return PREMIUM_PLAN_PRICE_DEFAULT;
};

export default getUserPremiumPrice;
