import React from 'react';
import PropTypes from 'prop-types';

const Description = ({ width, height, ...rest }) => (
  <svg viewBox="0 0 48 49" fill="none" width={width} height={height} {...rest}>
    <path d="M43 6.30347H5V16.3035H43V6.30347Z" fill="#7FE3FF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.1667 5.30347C43.6269 5.30347 44 5.67995 44 6.14438V16.2353C44 16.6997 43.6269 17.0762 43.1667 17.0762H4.83333C4.3731 17.0762 4 16.6997 4 16.2353V6.14438C4 5.67995 4.3731 5.30347 4.83333 5.30347H43.1667ZM42.3333 6.98528H5.66667V15.3944H42.3333V6.98528ZM41.5347 20.4398C41.9758 20.4398 42.3333 20.8163 42.3333 21.2807C42.3333 21.6936 42.0508 22.0369 41.6783 22.1081L41.5347 22.1216H4.79861C4.35755 22.1216 4 21.7452 4 21.2807C4 20.8679 4.28251 20.5246 4.65506 20.4534L4.79861 20.4398H41.5347ZM43.2 25.4853C43.6418 25.4853 44 25.8618 44 26.3262C44 26.739 43.717 27.0824 43.3438 27.1536L43.2 27.1671H4.8C4.35817 27.1671 4 26.7906 4 26.3262C4 25.9134 4.283 25.57 4.6562 25.4988L4.8 25.4853H43.2ZM41.5177 30.5307C41.9682 30.5307 42.3333 30.9072 42.3333 31.3716C42.3333 31.7845 42.0448 32.1278 41.6643 32.199L41.5177 32.2126H4.8156C4.36516 32.2126 4 31.8361 4 31.3716C4 30.9588 4.28852 30.6155 4.669 30.5443L4.8156 30.5307H41.5177ZM43.2 35.5762C43.6418 35.5762 44 35.9527 44 36.4171C44 36.8299 43.717 37.1733 43.3438 37.2445L43.2 37.258H4.8C4.35817 37.258 4 36.8815 4 36.4171C4 36.0043 4.283 35.6609 4.6562 35.5897L4.8 35.5762H43.2ZM31.5 40.6216C31.9602 40.6216 32.3333 40.9981 32.3333 41.4626C32.3333 41.8754 32.0385 42.2187 31.6498 42.2899L31.5 42.3035H4.83333C4.3731 42.3035 4 41.927 4 41.4626C4 41.0497 4.29479 40.7064 4.68354 40.6352L4.83333 40.6216H31.5Z"
      fill="#042238"
    />
  </svg>
);

Description.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Description;
