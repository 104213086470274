import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Messages = (props) => (
  <svg {...props} viewBox="0 0 28 24" fill={props.color}>
    <path d="M26.468 9.738c.478 0 .865.39.865.87v8.347c0 .48-.387.87-.865.87h-1.213v3.304c0 .75-.882 1.148-1.44.65l-4.43-3.954H14a.868.868 0 01-.866-.87v-8.347c0-.48.388-.87.866-.87zm-.866 1.74H14.866v6.608h4.848c.212 0 .417.078.575.22l3.235 2.887v-2.238c0-.48.387-.87.866-.87h1.212v-6.608zM22.312 0c.478 0 .865.39.865.87v7.347c0 .48-.387.87-.865.87a.868.868 0 01-.866-.87V1.739H2.398v11.825h2.415c.479 0 .866.39.866.87v2.379l2.888-2.985a.864.864 0 01.621-.264h1.094c.478 0 .865.39.865.87s-.387.87-.865.87h-.728l-4.12 4.257c-.541.56-1.486.175-1.486-.606v-3.652H1.532a.868.868 0 01-.865-.87V.87c0-.48.387-.87.865-.87z" />
  </svg>
);

Messages.defaultProps = {
  width: 28,
  height: 24,
  color: colors.greyLight,
};

Messages.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Messages);
