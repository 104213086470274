import React from 'react';
import { FormSpy } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import FlatButton from '../../../../../../../components/FlatButton';
import { Paragraph, Span } from '../../../../../../../components/Typography';
import Edit from '../../../../../../../icons/Edit';
import { LateFeeLimitTypeEnum, LateFeeUnitEnum } from '../constants';

import styles from '../ConfigureLateFeesModal.module.scss';

const dayOrDays = (days) => (+days === 1 ? 'day' : 'days');

function formatDollars(num) {
  if (isNaN(num)) {
    return num;
  }
  if (Number.isInteger(+num)) {
    return +num;
  } else {
    return (+num).toFixed(2);
  }
}

const Digest = ({
  data = {},
  insideModal = false,
  onEdit,
  hideEdit = false,
  hideTitle = false,
}) => {
  const {
    oneTimeFeeAmount,
    oneTimeFeeGracePeriod,
    oneTimeLateFeeUnit,
    dailyLateFeeAmount,
    dailyLateFeeGracePeriod,
    lateFeeLimitUnit,
    lateFeeLimitAmount,
    lateFeeLimitType,
    dailyFeesApplied,
    oneTimeFeeApplied,
    lateFeeLimitDays,
  } = data;

  const showOneTimeFee = oneTimeFeeApplied && !!oneTimeFeeAmount;
  const showDailyFees = dailyFeesApplied && !!dailyLateFeeAmount;

  const showDailyFeesTotal =
    dailyFeesApplied &&
    lateFeeLimitAmount &&
    lateFeeLimitType === LateFeeLimitTypeEnum.MAX_TOTAL_FEE_AMOUNT &&
    dailyLateFeeAmount > 0;

  const showStopAfterDays =
    dailyFeesApplied &&
    dailyLateFeeAmount &&
    lateFeeLimitType === LateFeeLimitTypeEnum.MAX_DAYS &&
    dailyLateFeeGracePeriod > 0;

  const showLateFeeDigestForOneTime = oneTimeFeeAmount && oneTimeFeeApplied;
  const showLateFeeDigestForDaily = dailyLateFeeAmount && dailyFeesApplied;

  if (!showLateFeeDigestForOneTime && !showLateFeeDigestForDaily) return null;

  return (
    <div
      className={cx(styles.lateFeePolicyContainer, {
        [styles.outsideModal]: !insideModal,
        [styles.noTopMargin]: hideTitle,
      })}
    >
      {insideModal ? (
        <Paragraph size="small" weight="semi" className={'mb-4 mt-0'}>
          LATE FEE POLICY
        </Paragraph>
      ) : (
        (!hideTitle || !hideEdit) && (
          <div className={styles.editHeader}>
            {!hideTitle && (
              <Paragraph size="large" weight="semi" className={'mb-4 mt-0'}>
                Late Fees
              </Paragraph>
            )}
            {!hideEdit && (
              <FlatButton
                icon={Edit}
                className={styles.editBtn}
                onClick={onEdit}
              >
                Edit
              </FlatButton>
            )}
          </div>
        )
      )}

      {showOneTimeFee && (
        <Paragraph className={'mb-4 mt-0'}>
          A one-time initial fee of{' '}
          <Span weight="bold">
            {oneTimeLateFeeUnit === LateFeeUnitEnum.PERCENTAGE
              ? `${oneTimeFeeAmount}% of monthly rent`
              : `$${formatDollars(oneTimeFeeAmount)}`}
          </Span>{' '}
          will be applied{' '}
          <Span weight="bold">
            {oneTimeFeeGracePeriod || 1} {dayOrDays(oneTimeFeeGracePeriod)}
          </Span>{' '}
          after the rent due date.
        </Paragraph>
      )}

      {showDailyFees && (
        <Paragraph className={'mb-4 mt-0'}>
          A daily late fee of{' '}
          <Span weight="bold">${formatDollars(dailyLateFeeAmount) || 0}</Span>{' '}
          will be applied starting{' '}
          <Span weight="bold">
            {dailyLateFeeGracePeriod || 1} {dayOrDays(dailyLateFeeGracePeriod)}
          </Span>{' '}
          after the rent due date.
        </Paragraph>
      )}

      {showDailyFeesTotal && (
        <Paragraph className={'mb-4 mt-0'}>
          Total late fees will not exceed{' '}
          <Span weight="bold">
            {lateFeeLimitUnit === LateFeeUnitEnum.PERCENTAGE
              ? `${lateFeeLimitAmount}% of monthly rent`
              : `$${formatDollars(lateFeeLimitAmount)}`}
          </Span>
          .
        </Paragraph>
      )}

      {showStopAfterDays && (
        <Paragraph>
          Total number of daily late fees applied will not exceed{' '}
          <Span weight="bold">
            {lateFeeLimitDays || 1} daily fee{lateFeeLimitDays > 1 ? 's' : ''}
          </Span>
          .
        </Paragraph>
      )}
    </div>
  );
};

const LateFeeDigest = ({ insideModal = false, data = {}, onEdit, ...rest }) => {
  return insideModal ? (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => <Digest data={values} insideModal />}
    </FormSpy>
  ) : (
    <Digest data={data} onEdit={onEdit} {...rest} />
  );
};

LateFeeDigest.propTypes = {
  insideModal: PropTypes.bool,
  data: PropTypes.shape({
    oneTimeFeeAmount: PropTypes.number,
    oneTimeFeeGracePeriod: PropTypes.number,
    oneTimeLateFeeUnit: PropTypes.oneOf(Object.values(LateFeeUnitEnum)),
    dailyLateFeeAmount: PropTypes.number,
    dailyLateFeeGracePeriod: PropTypes.number,
    lateFeeLimitUnit: PropTypes.oneOf(Object.values(LateFeeUnitEnum)),
    lateFeeLimitAmount: PropTypes.number,
    lateFeeLimitType: PropTypes.oneOf(Object.values(LateFeeLimitTypeEnum)),
  }),
  onEdit: PropTypes.func,
  hideTitle: PropTypes.bool,
  hideEdit: PropTypes.bool,
};

export default LateFeeDigest;
