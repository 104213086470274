export const isLessThanOrEqualTo =
  (n, errorMessage = null) =>
  (value) => {
    if (value && value > n) {
      return errorMessage || `Value should be less than or equal to ${n}`;
    }
    return undefined;
  };

export default isLessThanOrEqualTo;
