import PropTypes from 'prop-types';

import listingPropTypes from './listing';

const leasePropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  listing: listingPropTypes,
  renters: PropTypes.arrayOf(PropTypes.object),
  rent_amount: PropTypes.string,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  status: PropTypes.oneOf([
    'CURRENT',
    'UPCOMING',
    'PAST',
    'DRAFT',
    'ENDING_SOON',
  ]),
  total_balance_due: PropTypes.number,
  month_to_month: PropTypes.bool,
  end_action: PropTypes.oneOf(['CONTINUE_MONTHLY', 'TERMINATE']),
  payment_request_rules_count: PropTypes.number,
  lease_agreement: PropTypes.object,
  partial_payments: PropTypes.bool,
  autopay_enabled: PropTypes.bool,
  can_delete: PropTypes.bool,
});

export default leasePropTypes;
