import React, { useEffect } from 'react';
import { useLottie } from 'lottie-react';
import PropTypes from 'prop-types';

import isiOSDevice from '../../../../helpers/isIosDevice';
import IframeAnimation from '../../../Animations/iOSIframeAnimation';

import animationPathJson from './LulaWaitlistModalAnimation.animation.json';

const LulaWaitlistModalAnimation = ({ style = {}, onComplete }) => {
  const ANIMATION_DURATION = 4000; // 4 seconds
  const iOS = isiOSDevice();

  useEffect(() => {
    if (onComplete && iOS) {
      setTimeout(() => {
        onComplete();
      }, ANIMATION_DURATION);
    }
  }, []);

  if (iOS) {
    return (
      <IframeAnimation
        style={style}
        src="https://lottie.host/?file=d5b7cf6c-9cec-43c2-9e14-ee86502fd21b/S2gfM0ShQu.json"
      />
    );
  }

  const options = {
    path: animationPathJson,
    loop: false,
    autoplay: true,
    onComplete,
  };

  const LoaderData = useLottie(options, style);
  return LoaderData.View;
};

LulaWaitlistModalAnimation.propTypes = {
  style: PropTypes.object,
  onComplete: PropTypes.func,
};

export default LulaWaitlistModalAnimation;
