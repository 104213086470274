import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ApplicantIcon from '../../../icons/Applicant';
import Close from '../../../icons/Close';
import LeadsIcon from '../../../icons/Leads';
import ScreeningIcon from '../../../icons/ScreeningSlim';
import TenantsIcon from '../../../icons/Tenants';
import { useConfig } from '../../../providers/ConfigProvider';
import BoxButton from '../../BoxButton';
import IconButton from '../../IconButton';
import Modal from '../../Modal';

import styles from './AddRentersModal.module.scss';

const AddRentersModal = ({
  className,
  open,
  onClose,
  onAddLead,
  hasLeases,
}) => {
  const config = useConfig();

  return (
    <Modal
      containerClassName={styles.modalContainer}
      className={cx(styles.container, className)}
      open={open}
      onClose={onClose}
    >
      <div className={styles.header}>
        <h2 className={styles.title}>Add Renters</h2>
        <IconButton
          tabIndex="1"
          icon={Close}
          onClick={onClose}
          className={styles.close}
        />
      </div>
      <span className={styles.description}>
        Once you add a renter to TurboTenant, you can send them a message.
      </span>
      <div className={styles.buttonsContainer}>
        <BoxButton className={styles.btn} icon={LeadsIcon} onClick={onAddLead}>
          <span className={styles.btnContent}>
            <label className={styles.btnTitle}>Add a Lead</label>
            <span className={styles.btnDescription}>
              Keep track of all the renters interested in your property.
            </span>
          </span>
        </BoxButton>

        <BoxButton
          className={styles.btn}
          icon={ApplicantIcon}
          to={`${config.PRIVATE_BASE_PATH}invite-renter`}
        >
          <span className={styles.btnContent}>
            <label className={styles.btnTitle}>Invite to Apply</label>
            <span className={styles.btnDescription}>
              Already have someone in mind for a rental application?
            </span>
          </span>
        </BoxButton>

        <BoxButton
          className={styles.btn}
          icon={ScreeningIcon}
          to={`${config.PRIVATE_BASE_PATH}externalapplication/intro`}
        >
          <span className={styles.btnContent}>
            <label className={styles.btnTitle}>Screen a Tenant</label>
            <span className={styles.btnDescription}>
              Run a credit, criminal, and eviction check on a tenant.
            </span>
          </span>
        </BoxButton>

        <BoxButton
          className={styles.btn}
          icon={TenantsIcon}
          to={
            hasLeases
              ? `${config.PRIVATE_BASE_PATH}create-tenant`
              : `${config.PRIVATE_BASE_PATH}leases/add/manual-tenant`
          }
        >
          <span className={styles.btnContent}>
            <label className={styles.btnTitle}>Add a Tenant</label>
            <span className={styles.btnDescription}>
              Add a tenant currently living at your property.
            </span>
          </span>
        </BoxButton>
      </div>
    </Modal>
  );
};

AddRentersModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAddLead: PropTypes.func,
  hasLeases: PropTypes.bool,
};

export default AddRentersModal;
