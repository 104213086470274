import React, { memo } from 'react';

import colors from '../../../constants/colors';

const RadioChecked = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 0c5.5 0 10 4.5 10 10s-4.5 10-10 10S0 15.5 0 10 4.5 0 10 0zm4.8 6l-6.514 5.846L5.2 9.076 4 10.155 8.286 14 16 7.077 14.8 6z"
      fillRule="evenodd"
    />
  </svg>
);

RadioChecked.defaultProps = {
  fill: colors.primary,
};

export default memo(RadioChecked);
