import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import Card from '../Card';

import DefaultCard from './components/IncomeInsightCards/DefaultCard';
import IncomeInsightsWarningModal from './IncomeInsightsWarningModal';
import InsightsResult from './InsightsResult';

import styles from './IncomeInsightsCard.module.scss';

const IncomeInsightsCard = ({
  className,
  incomeInsights,
  isReportRequested,
  application,
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [isInsightsModalOpen, setIsInsightsModalOpen] = useState(false);

  const LS_INCOME_DISCLAIMER_KEY = `${application.id}_INCOME_DISCLAIMER_EXPIRES_AT`;

  const onSeeReport = useCallback(() => {
    setShowLoader(true);
    setIsInsightsModalOpen(false);
  }, [setShowLoader, setIsInsightsModalOpen]);

  const isApplicantDisclaimerExpired = () => {
    const disclaimerDisposalExpiresAt = localStorage.getItem(
      LS_INCOME_DISCLAIMER_KEY,
    );

    if (
      !disclaimerDisposalExpiresAt ||
      moment(disclaimerDisposalExpiresAt).isSameOrBefore(moment())
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!isApplicantDisclaimerExpired()) {
      setShowResults(true);
    }
  }, []);

  /**
   * We hide the disclaimer for 3 minutes for that application for the subsequent
   * clicks on "view insights"
   */
  const onOpenModal = useCallback(() => {
    if (isApplicantDisclaimerExpired()) {
      setIsInsightsModalOpen(true);
      localStorage.setItem(
        LS_INCOME_DISCLAIMER_KEY,
        moment().add(3, 'minutes'),
      );
    } else {
      setShowResults(true);
    }
  }, [setIsInsightsModalOpen]);

  const onAnimationComplete = useCallback(() => {
    setShowResults(true);
    setShowLoader(false);
  }, [setShowResults, setShowLoader]);

  return (
    <>
      {showResults && !showLoader && (
        <div className={styles.incomeTitle}>Income Insights</div>
      )}
      <Card className={cx(styles.container, className)}>
        {!isReportRequested && (
          <>
            {(showResults || showLoader) && (
              <InsightsResult
                onAnimationComplete={onAnimationComplete}
                showLoader={showLoader}
                incomeInsights={incomeInsights}
                application={application}
              />
            )}
            {!showLoader && !showResults && (
              <DefaultCard onOpenModal={onOpenModal} />
            )}
          </>
        )}
      </Card>
      <IncomeInsightsWarningModal
        open={isInsightsModalOpen}
        onAgree={onSeeReport}
      />
    </>
  );
};

IncomeInsightsCard.propTypes = {
  className: PropTypes.string,
  incomeInsightsEstimate: PropTypes.string,
  onRequestReport: PropTypes.func,
};

IncomeInsightsCard.defaultProps = {};

export default IncomeInsightsCard;
