import React, { memo } from 'react';
import PropTypes from 'prop-types';

const MilitaryPlane = ({ color, bgColor, ...props }) => (
  <svg viewBox="0 0 40 40" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="20" cy="20" r="20" fill={bgColor} />
      <path fill="none" d="M4 4h32v32H4z" />
      <path
        fill={color}
        fillRule="nonzero"
        d="M20.684 8.416c1.636.818 2.525 1.707 2.525 2.793v3.698l3.75 1.875a.784.784 0 0 1 .433.702v.678l5.992 4.994c.18.149.283.37.283.602v3.137c0 .403-.306.74-.707.78l-9.751.975v1.383a.784.784 0 0 1-1.135.701l-1.74-.87-1.741.87a.785.785 0 0 1-1.128-.594l-.007-.107-.001-1.383-9.75-.974a.784.784 0 0 1-.7-.672L7 26.895v-3.137c0-.233.103-.453.282-.602l5.992-4.995v-.677c0-.26.129-.5.34-.646l.094-.056 3.749-1.875V11.21c0-1.086.89-1.975 2.526-2.793.22-.11.48-.11.701 0Zm-.352 1.587-.05.029c-.87.487-1.256.907-1.256 1.177v17.554l.957-.478a.784.784 0 0 1 .588-.046l.113.046.957.48V11.208c0-.27-.387-.69-1.256-1.177l-.053-.029Zm2.877 6.657v10.414l8.889-.889v-2.059l-4.706-3.923v2.51a.784.784 0 0 1-1.568 0v-4.745l-2.615-1.308Zm-5.752 0-2.614 1.308v4.744a.784.784 0 0 1-1.568 0l-.001-2.509-4.705 3.923v2.06l8.888.888V16.66Zm2.876-5.19c.433 0 .785.352.785.785v2.091a.784.784 0 0 1-1.569 0v-2.091c0-.433.351-.784.784-.784Z"
      />
    </g>
  </svg>
);

MilitaryPlane.defaultProps = {
  width: 40,
  height: 40,
  color: '#042238',
  bgColor: '#E7EBF0',
};

MilitaryPlane.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  bgColor: PropTypes.string,
};

export default memo(MilitaryPlane);
