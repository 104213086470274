import React, { useState } from 'react';
import {
  array,
  bool,
  func,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';

import HeaderSectionComponent from '../../properties/manage_sections/HeaderSectionComponent';

import styles from './SwitchViewEdit.module.scss';

const SwitchViewEdit = ({
  id,
  application,
  title,
  viewComponent: View,
  editComponent: Edit,
  showPrintButton,
  location,
  hasIdentityVerification,
  onSave,
  partnersApplication,
  ...props
}) => {
  const [editMode, setEditMode] = useState(false);
  const [saved, setSaved] = useState(false);

  const handleSave = () => {
    document
      ?.getElementById(id)
      ?.dispatchEvent(new Event('submit', { cancelable: true }));
  };

  const handleOnSave = async (values) => {
    await onSave({ ...values, id: application.id });
    setEditMode(false);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    setSaved(false);
  };

  const {
    evicted_bkg_question_enabled,
    conviction_bkg_question_enabled,
    civil_action_bkg_question_enabled,
  } = application || {};

  if (
    id === 'selfReported' &&
    !evicted_bkg_question_enabled &&
    !conviction_bkg_question_enabled &&
    !civil_action_bkg_question_enabled
  ) {
    return null;
  }

  return (
    <div className={styles.container}>
      <HeaderSectionComponent
        saved={saved}
        title={title}
        editMode={editMode}
        onSaveClick={handleSave}
        onEditClick={handleEdit}
        onCancelClick={handleCancel}
        showPrintButton={showPrintButton}
        applicationId={application.id}
        location={location}
        hasIdentityVerification={hasIdentityVerification}
        partnersApplication={partnersApplication}
        id={id}
      >
        {editMode ? (
          <Edit
            {...props}
            id={id}
            application={application}
            onSubmit={handleOnSave}
          />
        ) : (
          <View
            {...props}
            id={id}
            partnersApplication={partnersApplication}
            application={application}
            contacts={
              id === 'generalDetails'
                ? application && application.contacts
                : null
            }
          />
        )}
      </HeaderSectionComponent>
    </div>
  );
};

SwitchViewEdit.propTypes = {
  loading: bool,
  history: object,
  onSave: func,
  application: object,
  refs: func,
  resetState: func,
  fields: array,
  id: oneOfType([number, string]),
  title: string,
  editableData: object,
  viewComponent: func,
  editComponent: func,
  location: object,
  showPrintButton: bool,
  partnersApplication: bool,
};

SwitchViewEdit.defaultProps = {
  onSave: Function.prototype,
};

export default SwitchViewEdit;
