import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import propTypes from 'prop-types';

import * as AuthService from '../../core/auth/authService';
import { useIsSmallScreen } from '../../hooks/useIsSmallScreen';
import * as localStorageService from '../../services/localStorageService';

import IOSPWAPlainPrompt from './IOSPWAPlainPrompt';
import isPWA from './isPWA';

const IOSPWAPrompt = ({ forced }) => {
  const isSmallScreen = useIsSmallScreen();

  const [isVisible, setVisibleState] = useState(
    !localStorageService.getItem('ios-prompt-hidden') || forced,
  );

  const hide = () => {
    localStorageService.setItem('ios-prompt-hidden', true) || forced;
    setVisibleState(false);
  };

  if (!forced && !AuthService.isLoggedIn()) {
    return null;
  }

  if (isSmallScreen) {
    return null;
  }

  if (!isVisible) {
    return null;
  }

  if (isPWA()) {
    return null;
  }

  return <IOSPWAPlainPrompt hide={hide} />;
};

IOSPWAPrompt.propTypes = {
  forced: propTypes.bool,
};

// We use withRouter here because there is
// no way to subscribe on AuthService.isLoggedIn()
// By using withRouter we make sure that this
// component will rerender on every router change
export default withRouter(IOSPWAPrompt);
