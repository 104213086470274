import React from 'react';
import PropTypes from 'prop-types';

import SectionInfoBlock from '../../../../../common/SectionInfoBlock';

const LateFeeInfoIN = ({ className }) => (
  <SectionInfoBlock className={className}>
    <strong>Late Fees:</strong> Rent is due in full on the first day of each and
    every month. If rent is not received on or before the 5th day of the month,
    you may charge your tenant a late fee of 5% unpaid rent amount.
  </SectionInfoBlock>
);

LateFeeInfoIN.propTypes = {
  className: PropTypes.string,
};

export default LateFeeInfoIN;
