import browserHistory from '../browserHistory';
import { TT_ACCOUNT_ID_HEADER } from '../providers/TTAccountProvider';

const ttAccountId =
  new URLSearchParams(browserHistory?.location?.search).get('ttAccountId') ||
  null;

export const TTfetch = async (input, init) => {
  if (init && init.headers && ttAccountId) {
    if (
      Object.keys(init.headers)
        .map((h) => h.toLowerCase())
        .includes('authorization')
    ) {
      // We only include `ttAccountId` when the request has an `authorization` header
      init.headers[TT_ACCOUNT_ID_HEADER] = ttAccountId;
    }
  }
  const response = await fetch(input, init);

  if (response.status === 503) {
    browserHistory.replace('/maintenance');
    throw { code: 503 };
  }
  if (response.status === 403) {
    throw { code: 403, response: await response.json() };
  }
  return response;
};
