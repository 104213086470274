import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const EnergyEfficient = (props) => (
  <svg viewBox="0 0 13 29" {...props}>
    <path
      fill={props.color}
      fillRule="nonzero"
      d="M11.552 12.16a.869.869 0 0 1-.593 1.076l-6.995 2.022.109.132c.32.418.519.932.556 1.52l.006.198-.001.289h2.897v-.289c0-1.744 1.393-2.897 3.187-2.897a.869.869 0 0 1 0 1.738c-.899 0-1.449.455-1.449 1.159v.289h1.449c.524 0 .929.461.861.981l-.333 2.521a5.323 5.323 0 0 1-4.295 3.959l.001.94a.869.869 0 0 1-1.738 0v-.94C3.15 24.516 1.44 22.97.903 20.8L.587 18.38a.869.869 0 0 1 .862-.981l1.447-.001v-.289c0-.704-.549-1.159-1.447-1.159-1.007 0-1.208-1.424-.242-1.703l9.27-2.68a.869.869 0 0 1 1.075.594Zm-1.824 6.976H2.435l.172 1.341a3.582 3.582 0 0 0 3.263 2.707l.213.007c1.645 0 3.077-1.118 3.459-2.615l.186-1.44Zm2.405-10.449a.869.869 0 0 1-.598 1.074L1.107 12.73a.869.869 0 0 1-.476-1.672l10.428-2.97a.869.869 0 0 1 1.074.598Zm0-3.476a.869.869 0 0 1-.598 1.074L1.107 9.255a.869.869 0 0 1-.476-1.672l10.428-2.97a.869.869 0 0 1 1.074.598ZM6.083 0c.48 0 .869.389.869.869v2.443c0 .405-.28.756-.674.847L1.644 5.227a.869.869 0 1 1-.39-1.693l3.96-.913V.869a.87.87 0 0 1 .751-.861L6.083 0Z"
    />
  </svg>
);

EnergyEfficient.defaultProps = {
  width: 13,
  height: 28,
  color: colors.text,
};

EnergyEfficient.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(EnergyEfficient);
