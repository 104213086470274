import React from 'react';
import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import BlankPageLayout from '../../../layout/BlankPageLayout';
import { RouteWithLayout } from '../../../providers/LayoutProvider';

import AcceptInviteWrapped from './AcceptInvite/AcceptInviteWrapped';

const UserRoutes = ({ match }) => {
  const { path } = match;

  return (
    <Switch>
      <RouteWithLayout
        path={`${path}/accept_invite/:new?`}
        component={AcceptInviteWrapped}
        layout={BlankPageLayout}
      />
    </Switch>
  );
};

UserRoutes.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default UserRoutes;
