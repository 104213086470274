import React from 'react';
import PropTypes from 'prop-types';

import AccountFind from '../../icons/AccountFind';
import SectionTitleWithIconAndAction from '../SectionTitleWithIconAndAction';

import styles from './PullingReportMessage.module.scss';

const PullingReportMessage = ({ title, titleDescription }) => (
  <div className={styles.container}>
    <SectionTitleWithIconAndAction
      className={styles.title}
      title={title}
      text={titleDescription}
      icon={AccountFind}
    />
    <div className={styles.descriptionContainer}>
      <span className={styles.description}>
        We are waiting for TransUnion, the credit bureau, to send the report. It
        may take a couple of minutes. Try refreshing the page if you still don’t
        see it.
      </span>
    </div>
  </div>
);

PullingReportMessage.propTypes = {
  title: PropTypes.string,
  titleDescription: PropTypes.string,
};

export default PullingReportMessage;
