import React from 'react';
import cx from 'classnames';
import kebabCase from 'lodash.kebabcase';
import PropTypes from 'prop-types';

import getFieldError from '../../validators/get-field-error';
import PlainRadioButton from '../PlainRadioButton';

import styles from './SegmentedController.module.scss';

const SegmentedController = ({
  className,
  options,
  input,
  radioProps,
  meta,
  id,
  disabled,
  canUnselect,
  containerClassName,
  itemClassName,
  withIcon,
  label,
  selectedValue: propsSelectedValue, // for the controlled variant
  onChange: propsOnChange,
  dataQa,
}) => {
  const selectedValue =
    propsSelectedValue == null ? input.value : propsSelectedValue;
  const onChange = propsOnChange || input.onChange;
  const error = getFieldError(meta);

  return (
    <div className={cx(styles.wrap, className)} data-qa={dataQa}>
      {label && <label className={styles.mainLabel}>{label}</label>}

      <div className={cx(styles.container, containerClassName)}>
        {options.map(({ value, label, ...restProps }) => (
          <label
            className={cx(styles.item, itemClassName, {
              [styles.checked]: selectedValue === value,
              [styles.disabled]: disabled,
            })}
            key={`segment-item-${value}`}
          >
            <PlainRadioButton
              checked={selectedValue === value}
              value={value}
              name={input.name}
              disabled={disabled}
              onClick={(e) => {
                if (e.target.value === selectedValue && canUnselect) {
                  onChange(null);
                }
              }}
              onChange={() => {
                onChange(value);
              }}
              data-qa={kebabCase(`segment-item-${id}-input-option-${value}`)}
              {...radioProps}
              {...restProps}
              className={cx(
                styles.radio,
                {
                  [styles.hidden]: !withIcon,
                },
                radioProps.className,
              )}
            />
            <span
              className={cx(styles.label, {
                [styles.disabled]: disabled,
              })}
            >
              {label}
            </span>
          </label>
        ))}
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

SegmentedController.propTypes = {
  className: PropTypes.string,
  dataQa: PropTypes.string,
  itemClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  radioProps: PropTypes.object,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  withIcon: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ).isRequired,
};

SegmentedController.defaultProps = {
  input: {
    value: '',
  },
  meta: {},
  radioProps: {},
  disabled: false,
  withIcon: false,
};

export default SegmentedController;
