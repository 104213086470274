import React from 'react';
import PropTypes from 'prop-types';

import config from '../../../config';

const styles = {
  logo: {
    maxWidth: '162px',
    maxHeight: '48px',
  },
};

const Logo = ({ logo, style }) => (
  <a href="http://www.turbotenant.com">
    <img
      src={logo}
      alt="TurboTenant"
      style={{
        ...styles.logo,
        ...style,
      }}
    />
  </a>
);

Logo.propTyps = {
  logo: PropTypes.string,
};

Logo.defaultProps = {
  logo: `${config.assetsBaseUrl}/ttlogo.png`,
  style: {},
};

export default Logo;
