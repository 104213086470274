import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Card from '../../../../components/Card';

import styles from './StepContainer.module.scss';

const generateQASelector = (title) =>
  title
    ?.toLowerCase()
    .replace(/\s/gi, '-')
    .replace(/[^a-zA-Z0-9-]/gi, '');

const StepContainer = ({
  narrow,
  title = '',
  description,
  children,
  additionalContainerTopContent,
  additionalContainerBottomContent,
  className,
  'data-qa': dataQa,
}) => {
  let qaSelector = dataQa;

  if (qaSelector == null) {
    qaSelector =
      typeof title === 'string'
        ? `step-title-${generateQASelector(title)}`
        : undefined;
  }

  return (
    <Card
      className={cx(styles.card, {
        [styles.narrow]: narrow,
        [className]: className != null,
      })}
    >
      {additionalContainerTopContent}
      {title != null && (
        <h1
          className={cx(styles.title, {
            [styles.hasDescription]: description != null,
          })}
          data-qa={qaSelector}
        >
          {title}
        </h1>
      )}
      {description != null && (
        <p className={styles.description}>{description}</p>
      )}
      {children}
      {additionalContainerBottomContent}
    </Card>
  );
};

StepContainer.propTypes = {
  'narrow': PropTypes.bool,
  'title': PropTypes.node,
  'description': PropTypes.node,
  'children': PropTypes.node,
  'additionalContainerTopContent': PropTypes.node,
  'additionalContainerBottomContent': PropTypes.node,
  'className': PropTypes.string,
  'data-qa': PropTypes.string,
};

export default StepContainer;
