const toCurrency = (number, showDecimals = true) => {
  if (!number) {
    return showDecimals ? '$0.00' : '$0';
  }
  if (
    typeof Intl == 'object' &&
    Intl &&
    typeof Intl.NumberFormat == 'function'
  ) {
    const hasDecimals = showDecimals || number - Math.floor(number) !== 0;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: hasDecimals ? 2 : 0,
    });
    return formatter.format(number);
  } else {
    return `$${number.toLocaleString()}`;
  }
};

export default toCurrency;
