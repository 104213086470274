import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import BugIcon from '../../../../../../../icons/Bug';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './BedbugReportingDisclosureAZ.module.scss';

const BedbugReportingDisclosureAZ = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Bedbug Disclosure" icon={BugIcon} />
      <label className={styles.label}>
        Is there a current or past infestation of bed bugs on the premises?
      </label>
      <p className={styles.description}>
        Arizona law requires that you disclose this along with any known
        information of any nearby infestation. Educational material on bed bugs
        must be attached to the lease and we will attach it for you.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.has_bed_bug"
        name="custom_state_data.has_bed_bug"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'has_bed_bug_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'has_bed_bug_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition when="custom_state_data.has_bed_bug" is={true}>
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.bed_bug_procedure"
          name="custom_state_data.bed_bug_procedure"
          label="Please provide information on the current or past infestation as well as any  nearby infestation or history of infestations which may place the property at risk."
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      </Condition>
    </div>
  );
};

BedbugReportingDisclosureAZ.propTypes = {
  className: PropTypes.string,
  formValues: PropTypes.object,
};

export default BedbugReportingDisclosureAZ;
