import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import ResourcesIcon from '../../../../../../../icons/resources';

import styles from '../../../BankInfo.module.scss';

const BankInfo = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.row}>
      <HelpfulInfoCard
        Icon={ResourcesIcon}
        className={styles.helpfulInfoCard}
        title="West Virginia Security Deposit Laws"
        color="iceCold"
      >
        Though they're included in the lease agreement, we've highlighted some
        rules you need to know:
        <HelpfulInfoList className={styles.helpfulInfoList}>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Landlord must return the security deposit within 60 days after the
            termination or expiration of this Agreement, unless Premises becomes
            occupied by another tenant then Landlord shall return within 45
            days.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            There is no state statutory maximum security deposit amount in West
            Virginia. Typically deposits are 1-2 times the monthly rent.
          </HelpfulInfoListItem>
          <div className={styles.learnMore}>
            <a
              href="https://support.turbotenant.com/en/articles/6145942-nebraska-lease-agreement"
              target="_blank noopener noreferrer"
            >
              Learn other security deposit laws
            </a>
          </div>
        </HelpfulInfoList>
      </HelpfulInfoCard>
    </div>
  </div>
);

BankInfo.propTypes = {
  formValues: PropTypes.object,
  className: PropTypes.string,
};

export default BankInfo;
