import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import Input from '../../../../../../../components/Input';
import ResourcesIcon from '../../../../../../../icons/resources';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredShortStandard from '../../../../../../../validators/fieldRequiredShort';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from '../../../BankInfo.module.scss';

const BankInfo = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequiredShort = conditionalValidation(
    fieldRequiredShortStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <HelpfulInfoCard
          collapsable
          Icon={ResourcesIcon}
          className={styles.helpfulInfoCard}
          title="Connecticut Security Deposit Laws"
          color="iceCold"
        >
          Though they're included in the lease agreement, we've highlighted some
          rules you need to know:
          <HelpfulInfoList className={styles.helpfulInfoList}>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The maximum security deposit you can collect is 2 times the
              monthly rent for tenants under the age of 62.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The maximum security deposit you can collect is 1 months rent for
              tenants age 62 and over.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The security deposit must be placed in a separate interest bearing
              account.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              Accrued interest shall be returned annually or given as credit for
              rent.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              You need to disclose the name and address of the bank where the
              security deposit is held.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The security deposit must be returned within 30 days of the lease
              ending or within 15 days after you receive written notice of
              Tenant’s forwarding address, whichever is later.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              You must include a written itemized statement of any lawful
              charges and deductions when you return the security deposit.
            </HelpfulInfoListItem>
            <div className={styles.learnMore}>
              <a
                href="https://support.turbotenant.com/en/articles/6198502-connecticut-lease-agreement"
                target="_blank noopener noreferrer"
              >
                Learn other security deposit laws
              </a>
            </div>
          </HelpfulInfoList>
        </HelpfulInfoCard>
        <Field
          label="Bank / Depository Name"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_name"
          name="deposit_bank_name"
          component={Input}
          validate={fieldRequiredShort}
        />
        <Field
          label="Bank / Depository Address"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_address"
          name="deposit_bank_address"
          component={Input}
          validate={fieldRequiredShort}
        />
      </div>
    </div>
  );
};

BankInfo.propTypes = {
  className: PropTypes.string,
};

export default BankInfo;
