import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const WaterThick = (props) => (
  <svg {...props} viewBox="0 0 24 26" fill={props.color}>
    <path d="M20.774 18.933a.906.906 0 011.567 0l.129.222.397.718.267.513c.084.164.162.324.236.48.406.856.63 1.487.63 1.988 0 1.37-1.094 2.48-2.442 2.48s-2.44-1.11-2.44-2.48c0-.5.223-1.132.629-1.988l.114-.236.12-.244c.174-.342.359-.686.544-1.017zm.784 2.4l-.061.123-.184.388c-.232.51-.368.904-.368 1.01 0 .344.274.623.613.623a.62.62 0 00.615-.623c0-.094-.108-.415-.295-.844l-.155-.34-.165-.338zM10.978 0a.92.92 0 01.913.928v1.098l4.625.018a.921.921 0 01.91.932.923.923 0 01-.793.916l-.124.008-4.618-.018.001.315a3.965 3.965 0 012.81 2.598l.058.191.047.187.452.025c4.827.341 8.55 2.699 8.704 7.716l.005.324v2.044a.92.92 0 01-.914.928h-4.026a.92.92 0 01-.913-.928v-2.044c0-.617-.492-1.117-1.1-1.117h-2.213l-.042.169c-.47 1.673-1.985 2.898-3.783 2.898H7.958c-1.797 0-3.313-1.225-3.782-2.898l-.044-.169H.914a.919.919 0 01-.906-.802L0 13.193v-5.11a.92.92 0 01.914-.928h3.218l.044-.169a3.968 3.968 0 012.868-2.789V3.9h-4.62a.92.92 0 01-.914-.928c0-.47.343-.858.79-.92l.123-.008h4.621V.928c0-.476.353-.868.808-.922L7.958 0zm3.933 9.036l-.001 3.229h2.106c1.558 0 2.832 1.238 2.921 2.798l.005.175v1.116h2.197l.001-1.116c0-4.05-3.038-5.965-7.23-6.202zm-3.934-3.092H7.958c-1.162 0-2.105.958-2.105 2.139v5.11c0 1.181.943 2.139 2.105 2.139h3.02c1.162 0 2.105-.958 2.105-2.139v-5.11l-.008-.185c-.092-1.094-.996-1.954-2.098-1.954zM4.025 9.01H1.827v3.255h2.198V9.01zm6.038-7.154H8.87v2.232h1.192V1.856z" />
  </svg>
);

WaterThick.defaultProps = {
  width: 24,
  height: 26,
  color: colors.text,
};

WaterThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(WaterThick);
