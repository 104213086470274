import React from 'react';
import { Field, Form } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { segmentTracking } from '../../services/utilities';
import composeValidators from '../../validators/composeValidators';
import fieldRequired from '../../validators/fieldRequired';
import validEmail from '../../validators/validEmail';
import validName from '../../validators/validName';
import validPasswordStrength from '../../validators/validPasswordStrength';
import Button from '../Button';
import EmbeddedSocialSignup from '../EmbeddedSocialSignup/EmbeddedSocialSignup';
import FinalFormError from '../FinalFormError';
import FlatButton from '../FlatButton';
import Input from '../Input';
import trimEmail from '../Input/trimEmail';
import PasswordInput from '../PasswordInput';
import { Link } from '../Typography';

import styles from './EmbeddedSignUpForm.module.scss';

const EmbeddedSignUpForm = ({
  handleSubmit,
  className,
  buttonLabel = 'Sign Up',
  segmentLocation,
}) => {
  return (
    <div className={cx(styles.container, className)}>
      <Form
        onSubmit={handleSubmit}
        subscription={{
          submitting: true,
          values: true,
          submitError: true,
        }}
      >
        {({ handleSubmit, submitting, values, submitError }) => (
          <form onSubmit={handleSubmit} className={cx(styles.form)}>
            <FinalFormError />
            <div className={styles.fullNameContainer}>
              <Field
                label="Your First Name"
                component={Input}
                name="first_name"
                id="first_name"
                className={styles.name}
                validate={composeValidators(fieldRequired, validName)}
              />
              <Field
                label="Your Last Name"
                component={Input}
                name="last_name"
                id="last_name"
                className={styles.name}
                validate={composeValidators(fieldRequired, validName)}
              />
            </div>
            <Field
              label="Your Email"
              component={Input}
              type="email"
              name="email"
              id="email"
              className={styles.email}
              parse={trimEmail}
              validate={composeValidators(validEmail, fieldRequired)}
            />
            {values.email && (
              <Field
                label="Create Password"
                component={PasswordInput}
                name="password"
                id="password"
                validate={composeValidators(
                  fieldRequired,
                  validPasswordStrength,
                )}
                className={styles.password}
                inputProps={{
                  autoComplete: 'new-password',
                }}
                passwordStrengthClassName={styles.noMargin}
                showPasswordStrengthChecker
              />
            )}
            {submitError === 'Email already in use.' && (
              <FlatButton
                className={styles.login}
                href="/auth/login"
                onClick={() => {
                  segmentTracking('login clicked', {
                    location: segmentLocation,
                  });
                }}
              >
                log in to turbotenant
              </FlatButton>
            )}
            <Button
              className={styles.submit}
              type="submit"
              loading={submitting}
            >
              {buttonLabel}
            </Button>
          </form>
        )}
      </Form>
      <div className={styles.or}>OR</div>
      <EmbeddedSocialSignup
        handleSignUp={handleSubmit}
        segmentLocation={segmentLocation}
      />
      <div className={styles.terms}>
        By clicking the button above you are agreeing to our{' '}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.turbotenant.com/terms-of-use/"
        >
          Terms of Use
        </Link>
        {' & '}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.turbotenant.com/privacy-policy/"
        >
          Privacy Policy
        </Link>
      </div>
    </div>
  );
};

EmbeddedSignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  segmentLocation: PropTypes.string,
};

export default EmbeddedSignUpForm;
