import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RadioIcon from './RadioIcon';

import styles from './PlainRadioButton.module.scss';

const PlainRadioButton = ({
  checked,
  value,
  id,
  disabled,
  className,
  style,
  ...otherProps
}) => (
  <label
    style={style}
    className={cx(styles.container, className, {
      [styles.disabled]: disabled,
    })}
  >
    <input
      type="radio"
      id={id}
      checked={checked}
      disabled={disabled}
      value={value}
      {...otherProps}
    />
    <RadioIcon className={cx(styles.icon, styles.checked)} checked />
    <RadioIcon className={cx(styles.icon, styles.notChecked)} />
  </label>
);

PlainRadioButton.propTypes = {
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  id: PropTypes.string,
  className: PropTypes.string,
};

export default PlainRadioButton;
