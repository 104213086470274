import { CometChat } from '@cometchat-pro/chat';

const initCometChat = ({ COMETCHAT_APP_ID, COMETCHAT_REGION }) => {
  const appSetting = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(COMETCHAT_REGION)
    .build();
  CometChat.init(COMETCHAT_APP_ID, appSetting).then(
    () => {
      console.log('Chat Initialization completed successfully');
    },
    (error) => {
      console.log('Chat Initialization failed with error:', error);
    },
  );
};

export default initCometChat;
