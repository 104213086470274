import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ArrowRight from '../../icons/streamline/ChevronRight';
import Card from '../Card';
import { H4, Paragraph } from '../Typography';

import styles from './OptionItemCard.module.scss';

const OptionItemCard = ({
  className,
  Icon,
  iconProps,
  onClick,
  title,
  description,
}) => {
  return (
    <Card className={cx(styles.container, className)} onClick={onClick}>
      <div className={styles.iconContainer}>
        {Icon && <Icon {...iconProps} />}
      </div>
      <div className={styles.textContainer}>
        <H4>{title}</H4>
        <Paragraph size="large">{description}</Paragraph>
      </div>
      <div className={styles.arrowContainer}>
        <ArrowRight />
      </div>
    </Card>
  );
};

OptionItemCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  Icon: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  iconProps: PropTypes.object,
};

export default OptionItemCard;
