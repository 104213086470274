import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';

import styles from './VoterRegistrationInformationDC.module.scss';

const VoterRegistrationInformationDC = ({ className }) => {
  return (
    <HelpfulInfoCard
      noIcon
      title="Washington, DC  Voter Registration Information"
      className={cx(styles.helpfulInfoCard, className)}
      color="iceCold"
    >
      District of Columbia requires that a link to voter registration and
      information packet must be presented to the tenant(s) Link:{' '}
      <a
        className={styles.link}
        href="https://dhcd.dc.gov/sites/default/files/dc/sites/dhcd/publication/attachments/Form"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://dhcd.dc.gov/sites/default/files/dc/sites/dhcd/publication/attachments/Form{' '}
      </a>
    </HelpfulInfoCard>
  );
};

VoterRegistrationInformationDC.propTypes = {
  className: PropTypes.string,
};

export default VoterRegistrationInformationDC;
