import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import PaintBrushIcon from '../../../../icons/PaintBrush';
import SectionTitle from '../../common/SectionTitle';

import styles from './SectionAdditionalProvisions.module.scss';

export const SectionAdditionalProvisionsLeadSection = ({ children }) => {
  return (
    <div className={cx(styles.column, styles.leadSection)}>
      <SectionTitle title="Lead-based Paint Disclosure" icon={PaintBrushIcon} />
      {children}
    </div>
  );
};

SectionAdditionalProvisionsLeadSection.propTypes = {
  children: PropTypes.node,
};
