import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const DollarCircle = (props) => (
  <svg {...props} viewBox="0 0 40 41">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 3.488c9.205 0 16.667 7.463 16.667 16.667 0 9.206-7.461 16.667-16.667 16.667-9.204 0-16.667-7.462-16.667-16.667 0-9.203 7.464-16.667 16.667-16.667Zm0 2.128c-8.028 0-14.54 6.51-14.54 14.539 0 8.03 6.51 14.539 14.54 14.539s14.539-6.508 14.539-14.54c0-8.028-6.51-14.538-14.54-14.538Zm-.078 4.255c.587 0 1.064.476 1.064 1.064v1.17a4.612 4.612 0 0 1 3.624 4.504 1.064 1.064 0 1 1-2.128 0c0-1.02-.616-1.897-1.496-2.279v4.867a4.612 4.612 0 0 1 0 9.008v1.17a1.064 1.064 0 0 1-2.128 0l-.002-1.207a4.611 4.611 0 0 1-3.466-4.467 1.064 1.064 0 0 1 2.128 0c0 .96.543 1.79 1.339 2.204v-4.83a4.611 4.611 0 0 1 0-8.933l.001-1.207c0-.588.476-1.064 1.064-1.064Zm1.064 11.551v4.558a2.482 2.482 0 0 0 0-4.558Zm-2.129-7.017a2.482 2.482 0 0 0 0 4.408v-4.408Z"
      fill={colors.text}
    />
  </svg>
);

DollarCircle.defaultProps = {
  color: colors.text,
};

DollarCircle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(DollarCircle);
