import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { howDidYouHear } from '../../../../constants';
import { useConfig } from '../../../../providers/ConfigProvider';
import { getBackgroundCheckTitle } from '../../../../services/utilities';

import styles from './OtherInfo.module.scss';

const ApplicantOtherInfo = ({ id, application, partnersApplication }) => {
  const { IS_RENTER } = useConfig();
  const getHowDidYouHearTitle = (name, otherDescription) => {
    if (!name && name !== 0) return '';
    const company = howDidYouHear.find((elem) => elem.value === name);
    if (company?.value === 'OTHER') {
      return otherDescription;
    }
    return company ? company.title : name.replace('_', ' ');
  };
  const {
    disability,
    disability_description,
    how_did_you_hear,
    how_did_you_hear_other,
    additional_comments,
  } = application;
  const DEFAULT_ANSWER = `${
    IS_RENTER ? 'You' : 'Renter'
  } did not provide this information`;

  return (
    <>
      {IS_RENTER ? (
        <p></p>
      ) : (
        <p className={styles.title}>
          {(application?.custom_application_question_1 != null ||
            application?.custom_application_question_2 != null ||
            application?.custom_application_question_3 != null ||
            application?.custom_application_question_4 != null) &&
            !partnersApplication && (
              <>
                A few additional questions, including{' '}
                <Link className={styles.link} to="/owners/settings/application">
                  any custom questions you may have added.
                </Link>
              </>
            )}
        </p>
      )}
      <article id={id} className={styles.container}>
        {[...Array(4).keys()]
          .map((index) => {
            const customQuestion =
              application[`custom_application_question_${index + 1}`];
            const answer =
              application[`custom_application_answer_${index + 1}`] ||
              DEFAULT_ANSWER;
            if (customQuestion) {
              return (
                <>
                  <div className={styles.question}>{customQuestion}</div>
                  <div className={styles.answer}>{answer}</div>
                </>
              );
            }
          })
          .filter((x) => !!x)}

        {application?.applying_as_tenant && (
          <>
            <div className={styles.question}>
              Do you have any special requests or requirements we should be
              aware of?
            </div>
            <div className={styles.answer}>
              <div className={cx({ [styles.yesTitle]: disability })}>
                {getBackgroundCheckTitle(disability)}
              </div>
              {disability && (
                <div>
                  <p className={styles.explainTitle}>Please Explain</p>
                  <span>{disability_description}</span>
                </div>
              )}
            </div>
            <div className={styles.question}>
              How did you hear about this property?
            </div>
            <div className={styles.answer}>
              {getHowDidYouHearTitle(how_did_you_hear, how_did_you_hear_other)}
            </div>
          </>
        )}
        <div className={styles.question}>Any other comments?</div>
        <div
          className={cx(styles.answer, {
            [styles.italic]: !additional_comments,
          })}
        >
          {additional_comments || 'No response given.'}
        </div>
      </article>
    </>
  );
};

ApplicantOtherInfo.propTypes = {
  id: PropTypes.string,
  styles: PropTypes.object,
  application: PropTypes.object,
  partnersApplication: PropTypes.bool,
};

export default ApplicantOtherInfo;
