import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';

import styles from './MailingInfo.module.scss';

const MailingInfo = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.row}>
      <HelpfulInfoCard
        noIcon
        noTitle
        className={styles.helpfulInfoCard}
        color="iceCold"
      >
        Delaware requires that a main office or other permanent address, in the
        County the property is located, must be listed in case your tenant(s)
        need to mail you a notice or pay their rent.
      </HelpfulInfoCard>
    </div>
  </div>
);

MailingInfo.propTypes = {
  className: PropTypes.string,
};

export default MailingInfo;
