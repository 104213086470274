import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const CloseCircle = (props) => (
  <svg {...props} viewBox="0 0 48 48" fill={props.color}>
    <path d="M24 .667C36.886.667 47.333 11.113 47.333 24c0 12.884-10.449 23.333-23.333 23.333C11.113 47.333.667 36.886.667 24 .667 11.112 11.112.667 24 .667zm0 2.029C12.232 2.696 2.696 12.232 2.696 24c0 11.765 9.538 21.304 21.304 21.304 11.764 0 21.304-9.54 21.304-21.304 0-11.766-9.539-21.304-21.304-21.304zm-8.032 11.863l.14.117 7.89 7.889 7.892-7.89a1.014 1.014 0 011.552 1.295l-.117.14L25.434 24l7.89 7.89a1.014 1.014 0 01-1.293 1.552l-.141-.117-7.891-7.89-7.89 7.889a1.014 1.014 0 01-1.552-1.294l.117-.14 7.89-7.89-7.89-7.89a1.014 1.014 0 011.294-1.551z" />
  </svg>
);

CloseCircle.defaultProps = {
  width: 48,
  height: 48,
  color: colors.text,
};

CloseCircle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(CloseCircle);
