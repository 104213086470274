import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const DollarCircledThick = (props) => (
  <svg {...props} viewBox="0 0 22 22" fill={props.color}>
    <path d="M11 .333C16.891.333 21.667 5.11 21.667 11c0 5.892-4.775 10.667-10.667 10.667C5.11 21.667.333 16.89.333 11 .333 5.11 5.11.333 11 .333zm0 1.905a8.763 8.763 0 100 17.524 8.763 8.763 0 000-17.524zm0 2.07c.526 0 .952.426.952.952v.518a3.162 3.162 0 012.208 3.014.952.952 0 01-1.904 0 1.25 1.25 0 00-.303-.818v2.22a3.162 3.162 0 010 6.028v.518a.952.952 0 11-1.905 0v-.518a3.161 3.161 0 01-2.208-3.014.952.952 0 011.904 0c0 .313.115.599.304.818v-2.22a3.161 3.161 0 010-6.028V5.26c0-.526.426-.953.952-.953zm.952 8.082v1.636a1.25 1.25 0 000-1.636zm-1.905-4.416a1.25 1.25 0 000 1.637z" />
  </svg>
);

DollarCircledThick.defaultProps = {
  width: 22,
  height: 22,
  color: colors.text,
};

DollarCircledThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(DollarCircledThick);
