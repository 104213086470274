import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useMutation, useQuery } from '@apollo/client';
import arrayMutators from 'final-form-arrays';
import { object } from 'prop-types';
import queryString from 'query-string';

import Button from '../../../components/Button';
import Divider from '../../../components/Divider';
import { FileField } from '../../../components/FinalFormFields';
import GrayFlatButton from '../../../components/GrayFlatButton';
import InlineAlert from '../../../components/InlineAlert/InlineAlert';
import { useErrorToast } from '../../../components/Toast';
import verifyConfirmationToken from '../../../core/services/tokenService';
import toGlobalId from '../../../helpers/to-global-id';
import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen';
import {
  ATTACHMENT_TYPE,
  uploadAttachmentsToS3,
} from '../../../services/cloudFilesService';
import StepContainer from '../components/StepContainer';
import StepSection from '../components/StepSection';
import rentalRequestQuery from '../graphql/rentalRequest.graphql';
import updateRentalRequest from '../graphql/updateRentalRequest.graphql';

import styles from './StandaloneUploadDocuments.module.scss';

const StandaloneUploadDocuments = ({ history, location }) => {
  const [listing, setListing] = useState(null);
  const [applicationId, setAppplicationId] = useState(null);
  const showErrorMessage = useErrorToast();
  const [updateRentalRequestMutation] = useMutation(updateRentalRequest);
  const { data } = useQuery(rentalRequestQuery, {
    variables: {
      id: toGlobalId(applicationId, 'RentalRequest'),
    },
    skip: !applicationId,
  });

  const handleLogIn = useCallback(
    () => history.replace('/auth/login'),
    [history],
  );

  const hasEsaAnimal = data?.node?.pets?.some((pet) => pet.esa_animal);
  const isSmallScreen = useIsSmallScreen();

  const onSubmit = useCallback(
    async (values) => {
      try {
        const files = [
          ...(values.photoID || []).map((file) => ({
            ...file,
            type: ATTACHMENT_TYPE.ID,
          })),
          ...(values.proofOfIncome || []).map((file) => ({
            ...file,
            type: ATTACHMENT_TYPE.PROOF_OF_INCOME,
          })),
          ...(values.other || []).map((file) => ({
            ...file,
            type: ATTACHMENT_TYPE.OTHER,
          })),
        ];

        const oldFiles = files?.filter(({ file }) => file == null);
        const newFiles = files?.filter(({ file }) => file != null);
        const uploaded = await uploadAttachmentsToS3(newFiles);

        await updateRentalRequestMutation({
          variables: {
            rentalRequest: {
              attachments: [...oldFiles, ...uploaded].map(
                ({ id, content, type }) => ({
                  id,
                  content,
                  type,
                }),
              ),
            },
          },
        });

        history.replace(
          `/application-documents-uploaded/${listing?.id}/success`,
        );
      } catch (e) {
        showErrorMessage('An error occurred while updating your application.');
        window.atatus &&
          window.atatus.notify(
            new Error('UploadDocuments Standalone flow' + (e && e.toString())),
          );
        throw e;
      }
    },
    [listing],
  );

  useEffect(() => {
    const { token } = queryString.parse(location.search);

    if (!token) handleLogIn();
    else {
      verifyConfirmationToken(`verify_auth?token=${token}`).then((res) => {
        if (!res) handleLogIn();
        else {
          setListing(res.listing);
          // res.currentId is actually rental request id
          setAppplicationId(res.currentId);
        }
      });
    }
  }, [location, verifyConfirmationToken]);

  return (
    <>
      <StepContainer
        title={'Upload your documents to finish your rental application'}
        data-qa={'stand-alone-upload-documents-step'}
        className={styles.container}
        description="Upload PDF, PNG, JPEG, and HEIC files up to a maximum size of 10MB."
      >
        <Form onSubmit={onSubmit} mutators={{ ...arrayMutators }}>
          {({ handleSubmit, submitting, valid }) => (
            <form onSubmit={handleSubmit}>
              <StepSection
                title={'Photo ID'}
                description="Must be government-issued e.g. driver’s license, passport, military
      ID, etc."
                className={styles.stepSection}
              >
                <FileField
                  multiple
                  name="photoID"
                  label="Upload Photo ID"
                  maxFileSize={10 * 1024 * 1024} // 10MB in bytes
                  button={GrayFlatButton}
                  accept="image/*,.pdf"
                />
                <Divider className={styles.divider} text="" />
              </StepSection>
              <StepSection
                title={'Proof of Income'}
                description="E.g. paystub, bank statement, social security, etc."
                className={styles.stepSection}
              >
                <FileField
                  multiple
                  name="proofOfIncome"
                  label="Upload Proof of Income"
                  maxFileSize={10 * 1024 * 1024} // 10MB in bytes
                  button={GrayFlatButton}
                  accept="image/*,.pdf"
                />
                <Divider className={styles.divider} text="" />
              </StepSection>
              <StepSection
                title={
                  <>
                    Other Docs{' '}
                    <span className={styles.optional}>(Optional)</span>
                  </>
                }
                description="Include any other documents requested by the landlord."
                className={styles.stepSection}
              >
                {hasEsaAnimal ? (
                  <InlineAlert
                    type="warning"
                    title="Please upload a verification document from a medical provider for you ESA/Service Animal"
                    hideIcon={isSmallScreen}
                    contentClassName={styles.esaAnimalWarningContent}
                    containerClassName={styles.esaAnimalWarningContainer}
                    titleFontWeight="bold"
                  />
                ) : null}
                <FileField
                  multiple
                  required={false}
                  name="other"
                  label="Upload Other Documents"
                  maxFileSize={10 * 1024 * 1024} // 10MB in bytes
                  button={GrayFlatButton}
                  accept="image/*,.pdf"
                />
                <Divider className={styles.divider} text="" />
              </StepSection>
              <Button
                loading={submitting}
                type="submit"
                disabled={submitting || !valid}
                data-qa="step-form-next-button"
                className={styles.nextButton}
              >
                Upload Documents
              </Button>
            </form>
          )}
        </Form>
      </StepContainer>
    </>
  );
};

StandaloneUploadDocuments.propTypes = {
  location: object.isRequired,
  history: object.isRequired,
};

export default StandaloneUploadDocuments;
