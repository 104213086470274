import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import RadioGroup from '../../../../components/RadioGroup';
import SectionTitleWithIcon from '../../../../components/SectionTitleWithIcon';
import DollarCircled from '../../../../icons/DollarCircled';
import fieldRequired from '../../../../validators/fieldRequired';

import styles from './WhoWillPay.module.scss';

const WhoWillPay = ({ hideTitle = false }) => {
  return (
    <div className={styles.whoWillPay}>
      {!hideTitle && (
        <SectionTitleWithIcon
          centered={false}
          title="Who will pay for the screening report?"
          icon={DollarCircled}
          iconClassNames={styles.dollarIcon}
          className={styles.sectionTitle}
        />
      )}
      <div>
        <Field
          component={RadioGroup}
          name="landlord_pays"
          id="landlord_pays"
          vertical={true}
          className={styles.whoPaysSelect}
          options={[
            {
              'label': `The renter will pay the fee`,
              'value': false,
              'data-qa': 'external-application-who-pays-applicant',
            },
            {
              'label': `I will pay the fee`,
              'value': true,
              'data-qa': 'external-application-who-pays-landlord',
            },
          ]}
          validate={fieldRequired}
        />
      </div>
    </div>
  );
};

WhoWillPay.propTypes = {
  hideTitle: PropTypes.bool,
};

export default WhoWillPay;
