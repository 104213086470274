export const hasApplicationInfoBeenFilled = (application) => {
  if (
    !!application &&
    (!application?.external ||
      application?.residences?.length > 1 ||
      application?.income_sources?.length ||
      application?.employments?.length ||
      application?.contacts?.length ||
      application?.vehicles?.length ||
      application?.attachments?.length ||
      application?.pets?.length ||
      application?.bank ||
      application?.evicted ||
      application?.conviction ||
      application?.civil_action ||
      application?.disability ||
      application?.additional_comments ||
      application?.custom_application_answer_1 ||
      application?.custom_application_answer_2 ||
      application?.custom_application_answer_3 ||
      application?.custom_application_answer_4 ||
      application?.how_did_you_hear)
  ) {
    return true;
  }

  return false;
};
