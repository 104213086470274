import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import compose from 'recompose/compose';

import { useConfig } from '../../../../providers/ConfigProvider';

import AddressContent from './AddressContent';

import styles from './Addresses.module.scss';

const ApplicantAddresses = ({
  history,
  location,
  id,
  application = {},
  isCoSignerAddress,
}) => {
  const { IS_RENTER } = useConfig();
  const { residences = [] } = application;
  const currentAddress = residences.find((address) => address.current);
  const residencesCurrentFirst = currentAddress
    ? [currentAddress, ...residences.filter((r) => !r.current)]
    : residences;

  const handleAction = useCallback(
    (residenceId) => {
      let searchParams = queryString.parse(location.search);

      searchParams = {
        ...searchParams,
        residenceId, // this way we render reference view in application tab instead of application
      };

      history.replace(
        `${location.pathname}?${queryString.stringify(searchParams)}`,
      );
    },
    [history, location],
  );

  return (
    <article id={id}>
      {IS_RENTER || !application?.applying_as_tenant ? (
        <p></p>
      ) : (
        <p className={styles.subTitle}>
          We asked this renter for at least 3 years of rental history.
        </p>
      )}
      {isCoSignerAddress && currentAddress != null ? (
        <AddressContent
          address={currentAddress}
          handleAction={handleAction}
          isCoSignerAddress
        />
      ) : (
        <>
          <div className={styles.address}>
            {currentAddress != null && (
              <AddressContent
                address={currentAddress}
                handleAction={handleAction}
              />
            )}
          </div>
          {application?.past_address_not_applicable ? (
            <AddressContent notApplicable />
          ) : (
            residencesCurrentFirst
              .filter(({ current }) => !current)
              .map((address, i) => (
                <div
                  className={styles.address}
                  key={`residence-key-${address.id || i}`}
                >
                  <AddressContent
                    address={address}
                    handleAction={handleAction}
                  />
                </div>
              ))
          )}
        </>
      )}
    </article>
  );
};

ApplicantAddresses.propTypes = {
  id: PropTypes.string,
  application: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default compose(withRouter)(ApplicantAddresses);
