import React from 'react';

import validPhone from '../../validators/validPhone';
import PhoneField from '../PhoneField';

import CoreField from './CoreField';

export const PhoneFinalField = ({ validate = [], ...props }) => (
  <CoreField
    component={PhoneField}
    validate={[
      validPhone,
      ...(Array.isArray(validate) ? validate : [validate]),
    ]}
    {...props}
  />
);

export default PhoneFinalField;
