import React from 'react';

import BoxButton from '../../../components/BoxButton';
import Applicant from '../../../icons/Applicant';
import Profile from '../../../icons/Profile';
import Property from '../../../icons/Property';
import { segmentTracking } from '../../../services/utilities';

import styles from './RenterSignupOptions.module.scss';

const RenterSignupOptions = () => (
  <div className={styles.container}>
    <h3 className={styles.title}>What are you looking to do?</h3>
    <BoxButton
      className={styles.item}
      icon={Property}
      onClick={() =>
        segmentTracking('find_property clicked', {
          location: 'Landlord Signup',
          properties: '[Renter]',
        })
      }
      href="https://renter.turbotenant.com/listingsearch"
      target="_blank"
    >
      Find a specific rental property
    </BoxButton>
    <BoxButton
      className={styles.item}
      icon={Applicant}
      onClick={() =>
        segmentTracking('rental_app clicked', {
          location: 'Landlord Signup',
          properties: '[Renter]',
        })
      }
      href="https://renter.turbotenant.com/listingsearch"
      target="_blank"
    >
      Fill out a rental application
    </BoxButton>
    <BoxButton
      className={styles.item}
      icon={Profile}
      onClick={() =>
        segmentTracking('renter_login clicked', {
          location: 'Landlord Signup',
          properties: '[Renter]',
        })
      }
      href="https://renter.turbotenant.com/auth/login"
      target="_blank"
    >
      Log in to my account
    </BoxButton>
  </div>
);

export default RenterSignupOptions;
