import React from 'react';
import { Field, Form } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { segmentTracking } from '../../../../services/utilities/segment';
import composeValidators from '../../../../validators/composeValidators';
import fieldRequired from '../../../../validators/fieldRequired';
import validEmail from '../../../../validators/validEmail';
import validName from '../../../../validators/validName';
import validPassword from '../../../../validators/validPassword';
import Button from '../../../Button';
import FinalFormError from '../../../FinalFormError';
import IconTooltip from '../../../IconTooltip/IconTooltip';
import Input from '../../../Input';
import trimEmail from '../../../Input/trimEmail';
import PasswordInput from '../../../PasswordInput';
import RadioGroup from '../../../RadioGroup';
import Stepper from '../../../Stepper';

import styles from './SignupForm.module.scss';

const SignupForm = ({ onSubmit, className }) => (
  <Form
    onSubmit={onSubmit}
    subscription={{
      submitting: true,
    }}
  >
    {({ handleSubmit, submitting }) => (
      <form
        onSubmit={handleSubmit}
        className={cx(styles.appSumoContainer, className)}
      >
        <FinalFormError focus />
        <div className={styles.row}>
          <Field
            label="First Name"
            component={Input}
            name="first_name"
            id="firstname"
            className={styles.name}
            validate={composeValidators(fieldRequired, validName)}
          />
          <Field
            label="Last Name"
            component={Input}
            name="last_name"
            id="lastname"
            className={styles.name}
            validate={composeValidators(fieldRequired, validName)}
          />
        </div>
        <div className={styles.row}>
          <Field
            label="Email"
            component={Input}
            type="email"
            name="email"
            id="email"
            className={styles.email}
            parse={trimEmail}
            validate={composeValidators(validEmail, fieldRequired)}
            inputProps={{
              autoComplete: 'username',
            }}
          />
        </div>
        <div className={styles.row}>
          <Field
            label="Password"
            component={PasswordInput}
            name="password"
            id="password"
            validate={composeValidators(fieldRequired, validPassword)}
            className={styles.password}
            inputProps={{
              autoComplete: 'new-password',
            }}
          />
        </div>
        <div className={styles.row}>
          <Field
            component={RadioGroup}
            id="landlord_signup_description"
            name="signupDescription"
            label="Which best describes you?"
            options={[
              {
                label: 'I manage my own rental(s)',
                value: 'MY_RENTALS',
              },
              {
                label: 'I manage rentals for others',
                value: 'OTHERS_RENTALS',
              },
              {
                label: 'I manage a mix of both',
                value: 'BOTH',
              },
              {
                label: "I don't manage any rentals yet",
                value: 'DONT_MANAGE_RENTALS',
              },
              {
                value: 'BROKER',
                label: (
                  <span className={styles.dwellingOption}>
                    I’m an apartment or rental broker
                    <IconTooltip
                      tip={
                        <span>
                          They serve as the go-between for property managers and
                          potential tenants. They do not actively manage
                          rentals.
                        </span>
                      }
                    />
                  </span>
                ),
              },
            ]}
            className={styles.landlordDescriptionContainer}
            radioRowClassName={styles.radioRowClassName}
            validate={fieldRequired}
          />
        </div>
        <div className={styles.row}>
          <Field
            label="How many properties do you own/manage?"
            component={Stepper}
            small
            name="properties"
            id="propertiesCount"
            className={styles.stepper}
            validate={fieldRequired}
          />
        </div>
        <div className={styles.row}>
          <Field
            label="AppSumo Code"
            component={Input}
            name="affiliateCode"
            id="affiliateCode"
            className={styles.affiliateCode}
            validate={fieldRequired}
          />
        </div>
        <Button
          className={styles.submit}
          type="submit"
          loading={submitting}
          onClick={() =>
            segmentTracking('sign_up clicked', { location: 'Landlord Signup' })
          }
          id="signup_submit"
        >
          Sign up
        </Button>
        <div className={cx(styles.row, styles.terms)}>
          By clicking the button above you are agreeing to our{' '}
          <a
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.turbotenant.com/terms-of-use/"
          >
            Terms of Use
          </a>
          {' & '}
          <a
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.turbotenant.com/privacy-policy/"
          >
            Privacy Policy
          </a>
        </div>
      </form>
    )}
  </Form>
);

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignupForm;
