import isString from 'lodash.isstring';

const trimWhiteSpace = (str) => {
  if (isString(str)) {
    return str.replace(/^\s+/, '').replace(/\s+$/, '');
  }
  return str;
};

const fieldRequired = (value) => {
  if (value == null || value?.length === 0 || trimWhiteSpace(value) === '') {
    return 'This value is required';
  }
  return undefined;
};

export default fieldRequired;
