import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const ScreeningSlim = (props) => (
  <svg {...props} viewBox="0 0 48 48">
    <path
      d="M19.139.667c10.203 0 18.472 8.27 18.472 18.472 0 4.752-1.794 9.085-4.742 12.358l14.18 14.177a.972.972 0 01-1.375 1.375l-14.18-14.178a18.404 18.404 0 01-12.355 4.74C8.936 37.611.667 29.341.667 19.14.667 8.936 8.937.667 19.139.667zm0 1.944C10.01 2.611 2.61 10.01 2.61 19.14c0 9.129 7.399 16.528 16.528 16.528 4.557 0 8.683-1.844 11.672-4.826l.013-.015a16.472 16.472 0 004.842-11.688c0-9.128-7.398-16.527-16.527-16.527zm-5.484 6.72c2.192-4.032 12.088-3.758 13.233.822.344 1.38.137 2.713-.427 4.146-.047.118-.102.253-.158.384.68.527.973 1.51.882 2.593-.092 1.087-.531 2.085-1.258 2.554-.085 1.675-.487 2.949-1.131 3.866-.26.37-.533.65-.796.854v2.683a86.035 86.035 0 014.303 1.903l-.836 1.755a83.883 83.883 0 00-4.8-2.1.972.972 0 01-.611-.902v-3.89c0-.413.261-.781.651-.917.06-.021.256-.16.497-.503.486-.692.796-1.8.796-3.44 0-.537.435-.973.972-.973-.034 0 .223-.433.275-1.054.053-.631-.08-.89-.213-.89a.972.972 0 01-.972-.972c0-.35.694-1.929.59-1.664.43-1.09.578-2.047.35-2.962-.727-2.907-9.054-2.907-9.781 0a.972.972 0 01-1.137.717c-.696-.142-.885-.088-.911-.036-.074.15-.042.591.145 1.214.082.273.188.567.32.901l.126.305.08.19.178.423c.197.475.256.644.256.912a.972.972 0 01-.972.972c-.173 0-.315.263-.267.898.047.615.302 1.046.267 1.046.536 0 .972.436.972.973 0 1.64.31 2.748.796 3.44.24.343.436.482.496.503.39.136.652.504.652.918v3.889a.972.972 0 01-.611.902 84.02 84.02 0 00-4.804 2.103l-.836-1.756a86.659 86.659 0 014.307-1.905V24.55a3.922 3.922 0 01-.796-.854c-.644-.917-1.046-2.192-1.131-3.866-.73-.471-1.168-1.475-1.25-2.562-.085-1.099.234-2.089.947-2.608-.087-.203-.17-.407-.215-.519-.153-.383-.277-.729-.378-1.064-.317-1.057-.38-1.914-.026-2.63.395-.804 1.174-1.162 2.226-1.116z"
      fillRule="evenodd"
    />
  </svg>
);

ScreeningSlim.defaultProps = {
  color: colors.text,
  width: 48,
  height: 48,
};

ScreeningSlim.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ScreeningSlim);
