import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { CometChat } from '@cometchat-pro/chat';

import { isLoggedIn } from '../core/auth/authService';
import injectTtAccountIdScript from '../helpers/injectTtAccountIdScript';
import updateAnchorTags from '../helpers/updateAnchorTags';

import { useConfig } from './ConfigProvider';

export const TT_ACCOUNT_ID_PARAM = 'ttAccountId';
export const TT_ACCOUNT_ID_HEADER = 'tt-account-id';

const TTAccountContext = React.createContext({});
const AccountSwitcherLoadingContext = React.createContext(false);

export const useTTAccountId = () => {
  const context = useContext(TTAccountContext);
  if (!context) {
    throw new Error('useTTAccountId must be used within the provider');
  }
  return context;
};

export const useAccountSwitcherLoading = () =>
  useContext(AccountSwitcherLoadingContext);

const TTAccountProvider = ({ children }) => {
  const history = useHistory();
  const { OWNERS_URL } = useConfig();

  const [ttAccountId, setTTAccountId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // This function is used to replace the path without changing the state
  const replacePathOnly = (path) =>
    history.replace(path, history.location.state);

  const apolloClient = useApolloClient();

  // TODO User access control
  // This provider could hold information on the user and not just the ID.
  // Maybe a "userQuery" that returns email, name and role capabilities?
  // That would allow us to both showsome information on the logged in user, and tailor the UI based on the user's role.

  useEffect(() => {
    if (ttAccountId) {
      injectTtAccountIdScript(ttAccountId, OWNERS_URL);
      updateAnchorTags(ttAccountId, OWNERS_URL);
    }
    const observer = new MutationObserver(() => {
      if (ttAccountId) {
        updateAnchorTags(ttAccountId, OWNERS_URL);
      }
    });
    observer.observe(document.body, { childList: true, subtree: true });

    const unlisten = history.listen((location) => {
      if (location.pathname.indexOf('/logout') !== -1) {
        return;
      }

      const newSearchParams = new URLSearchParams(location.search);
      const destinationAccountId = newSearchParams.get(TT_ACCOUNT_ID_PARAM);

      if (location.pathname.indexOf('/auth/') !== -1) {
        if (destinationAccountId) {
          newSearchParams.delete(TT_ACCOUNT_ID_PARAM);
          setTTAccountId(null);
          return replacePathOnly(
            `${location.pathname}?${newSearchParams.toString()}`,
          );
        }
        return;
      }

      if (ttAccountId && !destinationAccountId) {
        newSearchParams.set(TT_ACCOUNT_ID_PARAM, ttAccountId);
        return replacePathOnly(
          `${location.pathname}?${newSearchParams.toString()}`,
        );
      }

      if (
        ttAccountId &&
        destinationAccountId &&
        ttAccountId !== destinationAccountId
      ) {
        setTTAccountId(destinationAccountId);
        newSearchParams.set(TT_ACCOUNT_ID_PARAM, destinationAccountId);
        return replacePathOnly(
          `${location.pathname}?${newSearchParams.toString()}`,
        );
      }
    });
    return () => {
      observer.disconnect();
      unlisten();
    };
  }, [history, ttAccountId]);

  useEffect(() => {
    if (ttAccountId && location.pathname.indexOf('/logout') !== -1) {
      setTTAccountId(null);
      return;
    }
    const searchParams = new URLSearchParams(history?.location?.search);
    if (searchParams.get(TT_ACCOUNT_ID_PARAM) && !ttAccountId) {
      setTTAccountId(searchParams.get(TT_ACCOUNT_ID_PARAM));
      return;
    }

    if (
      ttAccountId &&
      !searchParams.get(TT_ACCOUNT_ID_PARAM) &&
      !location.pathname.indexOf('/select-account')
    ) {
      searchParams.set(TT_ACCOUNT_ID_PARAM, ttAccountId);
      replacePathOnly(`${location.pathname}?${searchParams.toString()}`);
      return;
    }

    if (ttAccountId && ttAccountId !== searchParams.get(TT_ACCOUNT_ID_PARAM)) {
      searchParams.set(TT_ACCOUNT_ID_PARAM, ttAccountId);
      CometChat.logout().then(
        () => {
          console.log('Messaging Logout completed successfully');
        },
        (error) => {
          console.log('Messaging Logout failed with exception:', { error });
        },
      );
      if (location.pathname.includes('/owners/dashboard')) {
        setIsLoading(true);
        history.replace(`/owners/dashboard?${searchParams.toString()}`);
        apolloClient
          .resetStore()
          .finally(() => {
            setIsLoading(false);
          })
          .catch(() => {
            // Do nothing since we're killing the requests
          });
      } else {
        if (isLoggedIn()) {
          window.location.href = `/owners/dashboard?${searchParams.toString()}`;
        }
      }

      return;
    }
  }, [history, ttAccountId, isLoggedIn]);

  return (
    <TTAccountContext.Provider
      value={{
        ttAccountId,
        setTTAccountId,
      }}
    >
      <AccountSwitcherLoadingContext.Provider value={isLoading}>
        {children}
      </AccountSwitcherLoadingContext.Provider>
    </TTAccountContext.Provider>
  );
};

export default TTAccountProvider;
