import React from 'react';
import cx from 'classnames';

import EditIcon from '../../../icons/Edit';
import FlatButton from '../../FlatButton';

import styles from './StepSection.module.scss';

const SectionTitle = ({
  title,
  onEditCLicked,
  editDataQA,
  style,
  icon: Icon,
  iconProps = {},
  showEdit,
}) => {
  const propsSectionIcon = { width: 21, height: 21, ...iconProps };

  return (
    <div className={styles.sectionWrapper} style={style}>
      <div className={styles.iconHeader}>
        <div className={styles.icon}>
          <Icon {...propsSectionIcon} />
        </div>
        <h4 className={styles.iconText}>{title}</h4>
      </div>
      {showEdit !== false && (
        <FlatButton
          icon={EditIcon}
          onClick={onEditCLicked}
          data-qa={editDataQA}
          iconProps={{ width: 21, height: 21 }}
        >
          Edit
        </FlatButton>
      )}
    </div>
  );
};

const SummarySection = ({
  title,
  step,
  icon,
  iconSettings = {},
  onEdit,
  show = true,
  showEdit = true,
  showContent = true,
  className,
  children,
}) =>
  show && (
    <div className={cx(styles.summarySection, className)}>
      <SectionTitle
        title={title}
        editDataQA={`edit-${step}`}
        style={{ marginTop: 0 }}
        icon={icon}
        iconProps={iconSettings}
        showEdit={showEdit}
        onEditCLicked={() => onEdit(step)}
      />
      {showContent && children}
    </div>
  );

export default SummarySection;
