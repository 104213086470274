import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Toolbox = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 25" {...rest} fill={color}>
    <path d="M1.9 12.897a.6.6 0 0 1 .6-.6h8.143a.6.6 0 0 1 0 1.2H2.5a.6.6 0 0 1-.6-.6ZM12.757 12.897a.6.6 0 0 1 .6-.6H21.5a.6.6 0 1 1 0 1.2h-8.143a.6.6 0 0 1-.6-.6Z" />
    <path d="M1.9 8.373a.6.6 0 0 1 .6-.6h19a.6.6 0 0 1 .6.6V21.04a.6.6 0 0 1-.6.6h-19a.6.6 0 0 1-.6-.6V8.373Zm1.2.6V20.44h17.8V8.973H3.1Z" />
    <path d="M10.043 11.992a.6.6 0 0 1 .6-.6h2.714a.6.6 0 0 1 .6.6v3.62a.6.6 0 0 1-.6.6h-2.714a.6.6 0 0 1-.6-.6v-3.62Zm1.2.6v2.42h1.514v-2.42h-1.514ZM12 5.354a3.018 3.018 0 0 0-3.02 3.02h-1.2A4.218 4.218 0 0 1 12 4.153c2.33 0 4.219 1.888 4.219 4.22h-1.2a3.018 3.018 0 0 0-3.02-3.02Z" />
  </svg>
);

Toolbox.defaultProps = {
  width: 24,
  height: 25,
  color: colors.primary,
};

Toolbox.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Toolbox);
