import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import Input from '../../../../../../../components/Input';
import ResourcesIcon from '../../../../../../../icons/resources';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredShortStandard from '../../../../../../../validators/fieldRequiredShort';
import SectionInfoBlock from '../../../../../common/SectionInfoBlock';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './BankInfo.module.scss';

const BankInfo = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequiredShort = conditionalValidation(
    fieldRequiredShortStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <SectionInfoBlock className={styles.infoBlock}>
          <strong>Michigan Law:</strong> Landlords are required to provide the
          location of the depository / bank where the security deposit is held.
        </SectionInfoBlock>
        <Field
          label="Bank / Depository Name"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_name"
          name="deposit_bank_name"
          component={Input}
          validate={fieldRequiredShort}
        />
        <Field
          label="Bank / Depository Address"
          className={styles.inputContainer}
          inputProps={{
            className: cx(styles.input),
          }}
          id="deposit_bank_address"
          name="deposit_bank_address"
          component={Input}
          validate={fieldRequiredShort}
        />
        <HelpfulInfoCard
          Icon={ResourcesIcon}
          className={styles.helpfulInfoList}
          title="Michigan Security Deposit Laws"
        >
          <HelpfulInfoList>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The maximum amount you can charge for a security deposit is 1.5
              times the monthly rent.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The security deposit must be placed in an FDIC insured bank
              account. Bank name and location to be indicated in the lease.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The tenant must send you a forwarding address within 4 days of
              vacating premises.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The security deposit must be returned to tenant by the 30th day
              after obtaining possession of the premises.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              If you are retaining any portion of the security deposit, you need
              to provide tenant with a written itemization of any lawful
              charges, only if tenant provided you with a forwarding address.
            </HelpfulInfoListItem>
            <HelpfulInfoListItem className={styles.helpfulItem}>
              The itemization and the security deposit balance needs to be
              mailed to tenant’s last known address via first-class mail.
            </HelpfulInfoListItem>
          </HelpfulInfoList>
        </HelpfulInfoCard>
      </div>
    </div>
  );
};

BankInfo.propTypes = {
  className: PropTypes.string,
};

export default BankInfo;
