import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Notes = ({ width, height, color, ...otherProps }) => (
  <svg
    width={width}
    height={height}
    {...otherProps}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.673 1.633a.612.612 0 01.083 1.22l-.083.005H1.224v15.918H15.51V9.184c0-.31.23-.566.53-.607l.082-.005c.31 0 .567.23.607.529l.006.083v10.204c0 .31-.23.566-.53.606l-.083.006H.612a.612.612 0 01-.606-.53L0 19.389V2.246c0-.31.23-.566.53-.607l.082-.006h13.06zM17.548.431c.602-.602 1.463-.548 2.016.004.553.553.607 1.414.006 2.016l-7.794 7.793a.612.612 0 01-.159.114L9.31 11.513c-.526.263-1.085-.296-.822-.822l1.155-2.31a.612.612 0 01.114-.158L16.39 1.59a.625.625 0 01.005-.005l.004-.006zm-.721 2.452l-6.135 6.134-.289.579.578-.29 6.135-6.134-.289-.289zm1.872-1.582c-.097-.097-.187-.102-.285-.004l-.721.721.288.288.723-.72c.08-.082.09-.158.035-.237z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

Notes.defaultProps = {
  width: 20,
  height: 20,
  color: colors.primary,
};

Notes.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Notes);
