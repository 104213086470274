import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const FireCircled = (props) => (
  <svg {...props} viewBox={props.viewBox || '0 0 28 28'} fill={props.color}>
    <path
      d="M14 .667C21.365.667 27.333 6.636 27.333 14S21.365 27.333 14 27.333.667 21.364.667 14 6.635.667 14 .667Zm0 2.065C7.776 2.732 2.732 7.776 2.732 14c0 6.224 5.044 11.268 11.268 11.268 6.224 0 11.268-5.044 11.268-11.268 0-6.224-5.044-11.268-11.268-11.268Zm-2.691 4.19a1.033 1.033 0 0 1 1.314-1.282c4.323 1.445 7.518 6.022 7.518 10.632a6.142 6.142 0 0 1-12.283 0c0-.512.12-1.062.334-1.656.164-.456.382-.93.64-1.411a15.76 15.76 0 0 1 .718-1.208l.202-.298a1.033 1.033 0 0 1 1.68-.003l.588.81.04-.197.028-.173.04-.354c.097-1.152-.094-2.374-.521-3.869Zm2.593 1.645.058.256c.227 1.104.312 2.116.226 3.132-.097 1.152-.423 2.213-1.014 3.16a1.033 1.033 0 0 1-1.642.147 12.18 12.18 0 0 1-.585-.737l-.278-.373-.168.324a8.83 8.83 0 0 0-.26.57l-.104.27c-.14.388-.211.717-.211.956a4.077 4.077 0 0 0 8.152 0c0-2.942-1.638-5.912-4.03-7.607l-.144-.098Z"
      fill={props.color}
      fillRule="evenodd"
    />
  </svg>
);

FireCircled.defaultProps = {
  width: 28,
  height: 28,
  color: colors.text,
};

FireCircled.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(FireCircled);
