import { useQuery } from '@apollo/client';

import getEntityById from '../../graphql/queries/entities/entity.graphql';

const useEntityQuery = (id, options = {}) => {
  const { data, ...rest } = useQuery(getEntityById, {
    variables: {
      id,
    },
    ...options,
  });

  return { entity: data?.entity, ...rest };
};

export default useEntityQuery;
