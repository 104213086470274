import React from 'react';
import PropTypes from 'prop-types';

import BackButton from '../../../components/BackButton';
import EmbeddedSignUpForm from '../../../components/EmbeddedSignUpForm';
import { H2, Paragraph } from '../../../components/Typography';
import MarketingLayout from '../../../layout/MarketingLayout';
import { segmentTracking } from '../../../services/utilities';
import PublicScreeningReportSidebar from '../PublicScreeningReportSidebar';

import styles from './SignUpStep.module.scss';

const SignUpStep = ({ onNext, segmentData = {} }) => {
  return (
    <MarketingLayout
      hideFooter
      side={
        <PublicScreeningReportSidebar segmentLocation="Create Account - Public Screening" />
      }
    >
      <div className={styles.container}>
        <BackButton className={styles.back} />
        <div className={styles.content}>
          <H2>Get updates on the screening</H2>
          <Paragraph>
            Create an account to view your screening reports, organize your
            leads, and explore TurboTenant.
          </Paragraph>
          <EmbeddedSignUpForm
            className={styles.signUpForm}
            handleSubmit={(data) => {
              // If the password field is present, this means is not from social signup
              if (data.password) {
                segmentTracking('continue clicked', {
                  location: 'Create Account - Public Screening',
                  ...segmentData,
                });
              }
              return onNext(data);
            }}
            buttonLabel="Continue"
            segmentLocation="Create Account - Public Screening"
          />
        </div>
      </div>
    </MarketingLayout>
  );
};

SignUpStep.propTypes = {
  onNext: PropTypes.func.isRequired,
  segmentData: PropTypes.object,
};

export default SignUpStep;
