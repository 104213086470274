import React, { memo } from 'react';
import PropTypes from 'prop-types';

const MarketingColor = (props) => (
  <svg {...props} viewBox="0 0 40 38" fillRule="evenodd">
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.color}
        d="M1.576 28.697h7.758v7.758H1.576zM16.121 28.697h7.758v7.758h-7.758zM30.667 28.697h7.758v7.758h-7.758z"
      />
      <path
        d="M32.121.576c.447 0 .808.358.808.8v6.41a.805.805 0 0 1-.808.8H20v9.613h13.738c.412 0 .752.306.802.7l.006.101v8.81l4.04.002c.447 0 .809.358.809.8v8.011a.805.805 0 0 1-.808.801h-8.08a.805.805 0 0 1-.809-.8v-8.011c0-.443.362-.801.808-.801l2.424-.001V19.8H20v8.01h4.041c.447 0 .808.36.808.802v8.01a.805.805 0 0 1-.808.801h-8.08a.805.805 0 0 1-.808-.8v-8.011c0-.443.361-.801.808-.801l2.423-.001V19.8H7.071v8.01h2.424c.446 0 .808.36.808.802v8.01a.805.805 0 0 1-.808.801h-8.08a.805.805 0 0 1-.809-.8v-8.011c0-.443.362-.801.808-.801l4.04-.001V19c0-.408.309-.745.707-.795l.102-.006h12.12V8.586H7.88a.805.805 0 0 1-.808-.8v-6.41c0-.442.361-.8.808-.8H32.12Zm-8.889 28.838h-6.464v6.408h6.464v-6.408Zm-14.545 0H2.222v6.408h6.465v-6.408Zm29.09 0h-6.464v6.408h6.465v-6.408ZM31.314 2.178H8.687v4.806h22.626V2.178Z"
        fill="#042238"
      />
    </g>
  </svg>
);

MarketingColor.defaultProps = {
  color: '#7FE3FF',
  width: 40,
  height: 38,
};

MarketingColor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(MarketingColor);
