import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import BackButton from '../../../../components/BackButton';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import LeaseDetailsCreateForm from '../../../../components/LeaseDetailsCreateForm';
import LoadingScreen from '../../../../components/LoadingScreen';
import SelectField from '../../../../components/SelectField';
import { useInfoToast } from '../../../../components/Toast';
import { useLeases } from '../../../../core/TTgraphql';
import { useConfig } from '../../../../providers/ConfigProvider';
import { segmentTracking } from '../../../../services/utilities/segment';

import styles from './RentalAddendumLeaseSelector.module.scss';

const RentalAddendumLeaseSelector = ({ location, history, match }) => {
  const [selectedLeaseId, setSelectedLeaseId] = useState();
  const [contentType, setContentType] = useState();

  const { PRIVATE_BASE_PATH } = useConfig();
  const showInfoToast = useInfoToast();
  const { leases, loading } = useLeases({}, 0);
  const { listingId } = match.params;

  /**
   * NOTE: Filter out past leases
   */
  let filteredLeases = [];

  if (!loading) {
    filteredLeases = leases.filter(
      ({ status, listing }) =>
        status !== 'PAST' && (!listingId || listingId === listing.id),
    );
  }

  /**
   * NOTE: Create a list of leases for dropdown lease selector
   * First item is always empty (unfefined) and last is always NEW
   */
  const leasesOptions = [
    {
      value: undefined,
      label: '',
    },
  ];
  filteredLeases.forEach(({ id: value, title: label }) =>
    leasesOptions.push({
      value,
      label,
    }),
  );
  leasesOptions.push({
    value: 'new',
    label: '+ Add New Lease',
  });

  /**
   * NOTE: This useEffect is used to preselect a lease
   * if it's the only available lease to select or to select 'Add New Lease'
   * if there are no leases.
   * Since our `leasesOptions` array is ALWAYS filled with
   * ```
   * const leasesOptions = [
   *  {
   *    value: undefined,
   *    label: '',
   *  },
   * ];
   * ```
   * and
   * ```
   * leasesOptions.push({
   *  value: 'new',
   *  label: '+ Add New Lease',
   * });
   * ```
   * that means that if there is only one lease to select,
   * `leasesOptions` array will have 3 items in it and
   * that's when we take the "middle" (index 1) element's value
   * and use it as default selected lease.
   *
   * Additionally if there are no leases `leasesOptions` array will
   * have 2 items and then we take the last index (index 1).
   */
  const selectLease = (leaseId) => {
    setSelectedLeaseId(leaseId);
    if (leaseId === 'new') {
      setContentType('NEW');
    } else {
      setContentType(undefined);
    }
  };

  useEffect(() => {
    if (leasesOptions.length === 2) {
      return selectLease('new');
    }
    if (leasesOptions.length === 3) {
      return selectLease(leasesOptions[1].value);
    }
    return selectLease('');
  }, [leases]);

  if (loading) {
    return <LoadingScreen loading />;
  }

  const handleLeaseSelect = (event) => {
    const value = event.target.value;
    setSelectedLeaseId(value);

    if (value === 'new') {
      return setContentType('NEW');
    } else {
      return setContentType(undefined);
    }
  };

  const onLeaseAdded = ({ leaseId }) => {
    showInfoToast('Lease Added!');
    setSelectedLeaseId(leaseId);
    history.push(
      `${PRIVATE_BASE_PATH}rental-addendum/${leaseId}?redirectTo=${encodeURIComponent(
        location.pathname,
      )}`,
    );
  };

  return (
    <div className={styles.container}>
      <BackButton
        className={styles.back}
        fallback={`${PRIVATE_BASE_PATH}dashboard`}
      />
      <Card className={styles.card}>
        <h2 className={styles.title}>Select Which Lease Needs an Addendum</h2>
        <SelectField
          id="lease"
          label="Lease"
          disabled={false}
          input={{
            value: selectedLeaseId,
            onChange: handleLeaseSelect,
          }}
          options={leasesOptions}
          className={cx({
            [styles.lease]: selectedLeaseId,
          })}
        />
        {contentType === 'NEW' && (
          <LeaseDetailsCreateForm
            defaultListingId={listingId}
            onSubmit={onLeaseAdded}
            hideCancel
            segmentLocation="Select Lease"
          />
        )}
        {selectedLeaseId && selectedLeaseId !== 'new' && (
          <Button
            className={styles.continue}
            to={`${PRIVATE_BASE_PATH}rental-addendum/${selectedLeaseId}?redirectTo=${encodeURIComponent(
              location.pathname,
            )}`}
            onClick={() =>
              segmentTracking('continue clicked', {
                location: 'Select Lease',
              })
            }
          >
            Continue
          </Button>
        )}
      </Card>
    </div>
  );
};

RentalAddendumLeaseSelector.propTypes = {
  location: PropTypes.object.isRequired,
};

export default RentalAddendumLeaseSelector;
