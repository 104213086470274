import React, { memo } from 'react';
import PropTypes from 'prop-types';

const PieChartColor = (props) => (
  <svg {...props} viewBox="0 0 40 40">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#7FE3FF"
        d="M23 1c5.548.881 9.215 2.215 11 4 1.785 1.785 3.452 5.785 5 12H23V1Z"
      />
      <path
        fill="#042238"
        d="M17.5 4.998a.84.84 0 0 1 .841.84l.001 15.82h15.82a.84.84 0 0 1 .833.736l.007.105C35.002 32.165 27.167 40 17.5 40S0 32.165 0 22.5C0 12.833 7.836 4.997 17.5 4.997Zm-.839 1.703-.112.006C8.255 7.199 1.68 14.082 1.68 22.499c0 8.738 7.082 15.82 15.82 15.82 8.418 0 15.3-6.574 15.792-14.868l.006-.112H17.5a.84.84 0 0 1-.834-.735l-.006-.105V6.701ZM22.5 0C32.164 0 40 7.836 40 17.5a.84.84 0 0 1-.84.841H22.5a.84.84 0 0 1-.841-.84V.84a.84.84 0 0 1 .84-.841Zm.84 1.703V16.66h14.958l-.03-.447c-.63-7.821-6.95-14.037-14.816-14.504l-.111-.006Z"
      />
    </g>
  </svg>
);

PieChartColor.defaultProps = {
  width: 40,
  height: 40,
};

PieChartColor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(PieChartColor);
