export const emailInUseValidator = (additional) => (value, allValues) => {
  let emailsToCheck = [
    additional
      ? allValues.landlord_email || allValues.landlords?.[0]?.email || ''
      : allValues.additional_landlord_email ||
        allValues.landlords?.[1]?.email ||
        '',
  ];

  if (allValues.tenants) {
    const rentersEmailsToCheck = allValues.tenants.reduce((acc, tenant) => {
      if (tenant.email) acc.push(tenant.email);
      return acc;
    }, []);
    emailsToCheck = [...emailsToCheck, ...rentersEmailsToCheck];
  }

  const emailIsUsed = emailsToCheck.some(
    (email) => email?.toLowerCase() === value?.toLowerCase(),
  );

  if (emailIsUsed) {
    return 'Email is already in use by another party in this lease agreement.';
  }

  return undefined;
};
