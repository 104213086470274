import React from 'react';
import { Field, Form } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import FinalFormError from '../../../../../../components/FinalFormError';
import HelpfulInfoCard from '../../../../../../components/HelpfulInfo';
import Input from '../../../../../../components/Input';
import PropertyAddressFields from '../../../../../../components/PropertyAddressFields';
import { useUserProfile } from '../../../../../../core/TTgraphql';
import TurboTenantSmallLogo from '../../../../../../icons/TurboTenantSmallLogo';
import fieldRequired from '../../../../../../validators/fieldRequired';
import validShareAbleAddress from '../../../../../../validators/validShareAbleAddress';
import validShareAbleCity from '../../../../../../validators/validShareAbleCity';
import validShareAbleUnit from '../../../../../../validators/validShareAbleUnit';

import styles from './PropertyDetailsEditOnboarding.module.scss';

const PropertyDetailsEditOnboarding = ({
  onSubmit,
  initialValues,
  isMultiUnitPropertyType,
  hasRooms,
}) => {
  const { response = {} } = useUserProfile({}, false);
  const { data = {} } = response;
  const { userData = {} } = data;
  const { basicProfile = {} } = userData;
  const { onboarding_data = {} } = basicProfile.data || {};
  const { properties } = onboarding_data;

  return (
    <Card className={styles.formContainer}>
      <h2 className={styles.title}>Edit your rental address</h2>
      <p className={styles.description}>You can edit this info later.</p>
      <Form
        onSubmit={onSubmit}
        subscription={{
          submitting: true,
          values: true,
        }}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <FinalFormError />
            <PropertyAddressFields
              className={cx(styles.propertyAddressFields, {
                [styles.extraSpacing]: !isMultiUnitPropertyType && !hasRooms,
              })}
              validators={{
                city: [validShareAbleCity],
                unit: [validShareAbleUnit],
                address: [validShareAbleAddress],
              }}
              showUnit={!isMultiUnitPropertyType}
            />
            {isMultiUnitPropertyType ? (
              <Field
                id={`unit`}
                name={`unit`}
                className={styles.field}
                component={Input}
                label="Unit"
                validate={fieldRequired}
                maxLength={50}
              />
            ) : null}
            {hasRooms ? (
              <Field
                id={`room_name`}
                name={`room_name`}
                className={styles.field}
                component={Input}
                label="Room Name"
                validate={fieldRequired}
                maxLength={50}
              />
            ) : null}
            {properties > 1 && (
              <HelpfulInfoCard
                className={styles.helpCard}
                title="What about my other rentals?"
                Icon={TurboTenantSmallLogo}
                iconProps={{
                  className: styles.helpCardIcon,
                }}
              >
                You can add charges for other rentals once the setup is
                complete. We'll focus on one rental for now.
              </HelpfulInfoCard>
            )}
            <Button
              className={styles.submit}
              type="submit"
              loading={submitting}
              disabled={submitting}
              id="add_lease_details_submit"
              data-qa="lease-details-basic-button-continue"
            >
              Save
            </Button>
          </form>
        )}
      </Form>
    </Card>
  );
};

PropertyDetailsEditOnboarding.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  isSinglePropertyType: PropTypes.bool,
  hasRooms: PropTypes.bool,
};

export default PropertyDetailsEditOnboarding;
