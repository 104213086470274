import React, { useEffect } from 'react';
import useWindowSize from '@rooks/use-window-size';
import PropTypes from 'prop-types';

import { smallScreenWidth } from '../../../constants/media-breakpoints';
import Close from '../../../icons/Close';
import DollarCircleBlue from '../../../icons/DollarCircleBlue';
import Button from '../../Button';
import Modal from '../../Modal';

import styles from './AreYouSureModal.module.scss';

const AreYouSureModal = ({
  showPremium,
  onClose,
  yourPrice,
  discountPrice,
  onLoad,
  open,
}) => {
  const { innerWidth } = useWindowSize();

  const isSmallScreen = innerWidth <= smallScreenWidth;

  useEffect(() => {
    if (open) onLoad();
  }, [open]);

  return (
    <Modal open={open} className={styles.container} onClose={onClose}>
      <Close className={styles.close} onClick={onClose} />
      <div className={styles.iconHolder}>
        <div>
          <DollarCircleBlue />
        </div>
      </div>
      <h3 className={styles.title}>
        Are you sure you don’t want to lower fees for tenants?{' '}
      </h3>{' '}
      <div className={styles.priceHolder}>
        <span className={styles.yourPrice}>${yourPrice}</span>
        <span className={styles.discountPrice}>${discountPrice}</span>
      </div>
      <div className={styles.buttonHolder}>
        <Button className={styles.cancel} secondary onClick={onClose}>
          {isSmallScreen ? 'cancel' : 'Not right now'}
        </Button>
        <Button className={styles.learnMore} onClick={showPremium}>
          {isSmallScreen ? 'continue' : 'Learn More'}
        </Button>
      </div>
    </Modal>
  );
};

AreYouSureModal.propTypes = {
  onClose: PropTypes.func,
  showPremium: PropTypes.func,
  yourPrice: PropTypes.node,
  discountPrice: PropTypes.node,
  open: PropTypes.bool,
  onLoad: PropTypes.func,
};

AreYouSureModal.defaultProps = {
  onLoad: () => {},
};

export default AreYouSureModal;
