import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import MilitaryPlaneIcon from '../../../../../../../icons/MilitaryPlane';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './MilitaryInstallationDisclosureVA.module.scss';

const MilitaryInstallationDisclosureVA = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle
        title="Military Installation Disclosure"
        icon={MilitaryPlaneIcon}
      />
      <label className={styles.label}>
        Is the property located in a military air installation zone?
      </label>
      <p className={styles.description}>
        Virginia law requires that you disclose this along with information on
        the noise zone or accident potential zone. Both are indicated on your
        localities' official zoning map.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.military_installation"
        name="custom_state_data.military_installation"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'military_installation_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'military_installation_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition when="custom_state_data.military_installation" is={true}>
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.military_installation_explanation"
          name="custom_state_data.military_installation_explanation"
          label="Please provide information on the noise zone or accident potential zone as indicated on your localities' official zoning map:"
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      </Condition>
    </div>
  );
};

MilitaryInstallationDisclosureVA.propTypes = {
  className: PropTypes.string,
};

export default MilitaryInstallationDisclosureVA;
