import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import getFieldError from '../../validators/get-field-error';
import Label from '../Label';

import SquareSimpleRadioButton from './SquareSimpleRadioButton';

import styles from './SquareSimpleRadioGroup.module.scss';

const SquareSimpleRadioGroup = ({
  className,
  containerClassName,
  options,
  input,
  disabled,
  radioProps,
  labelStyles,
  style,
  meta,
  label,
  id,
  onChange,
  submitOnClick,
}) => {
  const error = getFieldError(meta);
  return (
    <div className={cx(styles.wrap, className)} style={style}>
      {label && (
        <Label className={cx(styles.label, labelStyles)} htmlFor={id}>
          {label}
        </Label>
      )}
      <div className={cx(styles.container, containerClassName)}>
        {options.map(({ value, label, id }) => {
          return (
            <SquareSimpleRadioButton
              id={id}
              key={'radio-' + value}
              value={value}
              label={label}
              disabled={disabled}
              className={cx(styles.radioBox, radioProps.className)}
              onChange={(e) => {
                input.onChange(value);
                onChange && onChange(e);
              }}
              onClick={(e) => {
                if (e.target.value === input.value && submitOnClick) {
                  submitOnClick();
                }
              }}
              checked={input.value === value}
              name={input.name}
              {...radioProps}
            />
          );
        })}
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

SquareSimpleRadioGroup.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.node,
      className: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
  input: PropTypes.object,
  radioProps: PropTypes.object,
};

SquareSimpleRadioGroup.defaultProps = {
  radioProps: {},
  meta: {},
};

export default SquareSimpleRadioGroup;
