import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const DollarCircle = (props) => (
  <svg {...props} viewBox="0 0 40 41">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m31.841 21.956 2.88 2.995c.372.385.372 1.011 0 1.397l-8.643 8.986a.939.939 0 0 1-.409.25l-4.032 1.199c-.714.212-1.374-.477-1.17-1.22l1.153-4.193a.996.996 0 0 1 .241-.427l8.644-8.987a.92.92 0 0 1 1.337 0Zm-8.7 11.04-.385 1.402 1.316-.391-.93-1.01Zm1.654-29.508c.258 0 .505.11.683.304l5.015 5.371c.169.184.263.428.263.682l.001 10.424c0 .545-.423.986-.946.986-.523 0-.947-.441-.947-.986l-.001-8.897a.912.912 0 0 1-.325.06h-4.704c-.523 0-.946-.442-.946-.987V5.461H10.226v26.565h8.868c.522 0 .946.442.946.987 0 .545-.424.986-.946.986H9.28c-.523 0-.947-.441-.947-.986V4.475c0-.545.424-.987.947-.987h15.515Zm4.36 22.657L24.2 31.298l1.507 1.635 4.988-5.186-1.54-1.602Zm-10.73 1.02c.523 0 .946.441.946.986 0 .545-.423.986-.946.986h-5.226c-.523 0-.946-.441-.946-.986 0-.545.423-.987.946-.987h5.226Zm12.748-3.117-.678.704 1.54 1.601.678-.704-1.54-1.601Zm-12.748-.83c.523 0 .946.442.946.987 0 .545-.423.986-.946.986h-5.226c-.523 0-.946-.441-.946-.986 0-.545.423-.987.946-.987h5.226ZM23.98 17.3c.523 0 .947.442.947.987 0 .545-.424.986-.947.986H13.2c-.524 0-.947-.441-.947-.986 0-.545.423-.987.946-.987H23.98Zm0-3.946c.523 0 .947.442.947.987 0 .545-.424.986-.947.986H13.2c-.524 0-.947-.441-.947-.986 0-.545.423-.987.946-.987H23.98Zm-5.678-3.946c.522 0 .946.442.946.987 0 .545-.424.986-.947.986H13.2c-.523 0-.946-.441-.946-.986 0-.545.423-.987.946-.987h5.103Zm9.826.051L24.78 5.877v3.582h3.348Z"
      fill={colors.text}
    />
  </svg>
);

DollarCircle.defaultProps = {
  color: colors.text,
};

DollarCircle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(DollarCircle);
