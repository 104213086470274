import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TenantsThick = ({ color, ...props }) => (
  <svg viewBox="0 0 28 26" {...props} fill={color}>
    <path
      d="M4.86 12.375c.49 0 .895.36.96.828l.008.13v11.709c0 .53-.433.958-.968.958a.965.965 0 0 1-.959-.828l-.009-.13V13.333c0-.529.434-.958.968-.958Zm18.28 0c.49 0 .895.36.959.828l.009.13v11.709c0 .53-.434.958-.968.958a.965.965 0 0 1-.96-.828l-.008-.13V13.333c0-.529.433-.958.968-.958Zm-10.753 2.128c.535 0 .968.429.968.958v3.196c-.001 1.087-.289 1.924-.992 2.313l-.084.04v4.031a.961.961 0 0 1-.836.95l-.131.008H8.086a.963.963 0 0 1-.968-.958v-4.03l-.084-.04c-.657-.364-.95-1.118-.987-2.101l-.004-.215v-3.194c0-.529.433-.958.968-.958Zm8.602 0c.535 0 .968.429.968.958v3.196c-.001 1.087-.288 1.924-.991 2.313l-.085.04v4.031a.961.961 0 0 1-.836.95l-.131.008h-3.226a.963.963 0 0 1-.968-.958v-4.03l-.084-.04c-.657-.364-.95-1.118-.987-2.101l-.004-.215v-3.194c0-.529.433-.958.968-.958Zm-9.57 1.916H7.98l.002 2.513c.006.302.026.353.082.36l.154.01a.961.961 0 0 1 .837.95l-.001 3.83h1.29l.001-3.83c0-.485.364-.886.837-.95l.153-.01c.055-.007.076-.058.083-.36l.002-2.513Zm8.602 0h-3.44v2.388c.003.414.022.477.085.486l.153.01a.961.961 0 0 1 .837.95v3.83h1.29v-3.83c0-.486.364-.887.837-.95l.153-.01c.07-.01.085-.088.086-.638l-.001-2.236ZM9.699 9.182c1.425 0 2.58 1.144 2.58 2.555 0 1.41-1.155 2.554-2.58 2.554s-2.58-1.144-2.58-2.554 1.155-2.555 2.58-2.555Zm8.602 0c1.425 0 2.58 1.144 2.58 2.555 0 1.41-1.155 2.554-2.58 2.554s-2.58-1.144-2.58-2.554 1.155-2.555 2.58-2.555ZM14.536.827l.117.09 12.365 11.177a.951.951 0 0 1 .062 1.353.975.975 0 0 1-1.254.15l-.113-.088L14 2.922 2.287 13.509a.975.975 0 0 1-1.263.036l-.104-.098a.95.95 0 0 1-.037-1.25l.099-.103L13.347.917a.976.976 0 0 1 1.19-.09ZM9.7 11.098a.643.643 0 0 0-.645.639c0 .352.29.638.645.638a.643.643 0 0 0 .645-.638.643.643 0 0 0-.645-.639Zm8.602 0a.643.643 0 0 0-.645.639c0 .352.29.638.645.638a.643.643 0 0 0 .645-.638.643.643 0 0 0-.645-.639Z"
      fillRule="evenodd"
    />
  </svg>
);

TenantsThick.defaultProps = {
  width: 27,
  height: 25,
  color: 'currentColor',
};

TenantsThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(TenantsThick);
