import React from 'react';
import PropTypes from 'prop-types';

export const EmailQueryStringContext = React.createContext({});

const EmailQueryStringProvider = ({ children, ...props }) => (
  <EmailQueryStringContext.Provider value={props}>
    {children}
  </EmailQueryStringContext.Provider>
);

EmailQueryStringProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  // Device Login Token
  ownerEmail: PropTypes.string,
  // UTM
  campaign: PropTypes.string,
  source: PropTypes.string,
  medium: PropTypes.string,
  content: PropTypes.string,
};

EmailQueryStringProvider.defaultProps = {
  source: 'sendgrid',
  medium: 'email',
};

export default EmailQueryStringProvider;
