import React from 'react';
import cx from 'classnames';

import styles from './Rectangle.module.scss';

const Rectangle = ({
  className,
  slim,
  blank,
  animated,
  slider,
  tableHeader,
}) => (
  <div
    className={cx(className, styles.skeletonRectangle, {
      [styles.slim]: slim,
      [styles.blank]: blank,
      [styles.animated]: animated,
      [styles.slider]: slider,
      [styles.tableHeader]: tableHeader,
    })}
  />
);

export default Rectangle;
