import PropertyRolesEnum from './propertyRolesEnum';

function getRentalOptions(items) {
  return (
    items?.map((item) => ({
      label:
        item?.property_role === PropertyRolesEnum.UNIT
          ? item?.unit
          : item?.room_name,
      value: item?.id,
    })) || []
  );
}

export function getUnitRoomOptions(properties, property_id, unit_id) {
  const children =
    properties.find((p) => p.id === property_id)?.children_listings || [];

  const units = children.filter(
    (child) => child.property_role === PropertyRolesEnum.UNIT,
  );
  const topPropertyRooms = children.filter(
    (child) => child.property_role === PropertyRolesEnum.ROOM,
  );

  const selectedUnit = units.find((u) => u.id === unit_id);
  const unitRooms =
    selectedUnit?.children_listings?.filter(
      (child) => child.property_role === PropertyRolesEnum.ROOM,
    ) || [];

  const rooms = topPropertyRooms.length > 0 ? topPropertyRooms : unitRooms;
  const unitOptions = getRentalOptions(units);
  const roomOptions = getRentalOptions(rooms);

  return { unitOptions, roomOptions };
}
