import React from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import ChooseAccountCard from '../../../components/ChooseAccountCard';
import AccountAccessRoleEnum from '../../../constants/enums/AccountAccessRoleEnum';
import muaUserGQL from '../../../graphql/queries/multi_user_access/muaUser.graphql';
import { routes } from '../../../helpers/routes';
import TurboTenantLargeLogo from '../../../icons/TurboTenantLargeLogo';
import { useTTAccountId } from '../../../providers/TTAccountProvider';

import styles from './SelectAccount.module.scss';

const SelectAccount = ({ history }) => {
  const { numberOfAccounts } = queryString.parse(location.search);
  const { setTTAccountId } = useTTAccountId();
  const { data, loading: loadingUsers } = useQuery(muaUserGQL);
  const muaUser = data?.user || {};
  const usersAccounts = (muaUser?.users_accounts || [])
    .filter(
      (acc) =>
        acc.user_role === AccountAccessRoleEnum.MAIN || !!acc.confirmed_at,
    )
    .map((account) => account.owner);

  return (
    <div className={styles.container}>
      <TurboTenantLargeLogo className={styles.logo} />
      <ChooseAccountCard
        className={styles.card}
        user={{
          first_name: muaUser.first_name,
          last_name: muaUser.last_name,
          email: muaUser.email,
        }}
        users={usersAccounts}
        onSubmit={(userId) => {
          setTTAccountId(userId);
          // history.push({
          //   pathname: routes.dashboard,
          // });
        }}
        loadingUsers={loadingUsers}
        numberOfAccounts={numberOfAccounts ? parseInt(numberOfAccounts, 10) : 2}
        onLogout={() => {
          history.push({
            pathname: routes.logout,
          });
        }}
      />
    </div>
  );
};

SelectAccount.propTypes = {
  history: PropTypes.object,
};

export default SelectAccount;
