import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Spinner from '../Spinner';

import FacebookIcon from './Icon';

import styles from './FacebookButton.module.scss';

const label = {
  signup: 'Continue with Facebook',
  login: 'Log in with Facebook',
};

const FacebookButton = ({
  className,
  disabled,
  loading,
  type,
  ...otherProps
}) => (
  <button
    type="button"
    className={cx(
      styles.button,
      { [styles.disabled]: disabled || loading },
      className,
    )}
    disabled={disabled || loading}
    {...otherProps}
  >
    {loading ? (
      <Spinner />
    ) : (
      <React.Fragment>
        <FacebookIcon style={{ marginRight: 5 }} />
        {label[type]}
      </React.Fragment>
    )}
  </button>
);

FacebookButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['signup', 'login']).isRequired,
};

export default FacebookButton;
