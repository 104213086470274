import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Play2 = ({ color, ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C17.5222 2 22 6.47754 22 12C22 17.5225 17.5222 22 12 22C6.47671 22 2 17.523 2 12C2 6.47699 6.47671 2 12 2ZM9.375 16.9796L17.625 12.2165C17.7917 12.1203 17.7917 11.8797 17.625 11.7835L9.375 7.02035C9.20833 6.92413 9 7.04441 9 7.23686V16.7631C9 16.9556 9.20833 17.0759 9.375 16.9796Z"
      fill={color}
    />
  </svg>
);

Play2.defaultProps = {
  width: 24,
  height: 24,
  color: 'currentColor',
};

Play2.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default memo(Play2);
