import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Callout.module.scss';

const Callout = React.forwardRef(
  ({ type, children, className, style, ...restProps }, ref) => (
    <div
      className={cx(
        styles.container,
        {
          [styles[type]]: true,
        },
        className,
      )}
      style={style}
      ref={ref}
      {...restProps}
    >
      {children}
    </div>
  ),
);

Callout.displayName = 'Callout';

Callout.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['error', 'info', 'warning', 'success']).isRequired,
  onClose: PropTypes.func,
  borderWidth: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Callout;
