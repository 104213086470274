import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Photos = (props) => (
  <svg {...props} viewBox="0 0 24 24" fill={props.color}>
    <path
      d="M24 5.633v17.632c0 .372-.276.68-.635.728l-.1.007H5.633v-1.47H22.53V5.634H24zm-1.96-2.94v18.613c0 .372-.276.68-.634.728l-.1.007H2.694v-1.47H20.57V2.694h1.47zM19.348 0c.406 0 .735.329.735.735v18.612a.735.735 0 01-.735.735H.735A.735.735 0 010 19.347V.735C0 .329.329 0 .735 0zm-.735 16.163H1.469v2.45h17.143v-2.45zm0-14.694H1.47v13.224h2.768l2.725-4.54a.735.735 0 011.12-.17l.074.077 1.818 2.182 3.374-5.058a.735.735 0 011.254.05l.045.1 2.759 7.359h1.206V1.469zm-4.838 7.724l-3.122 4.684a.735.735 0 01-1.098.143l-.078-.08-1.787-2.145-1.739 2.898h9.887l-2.063-5.5zM6.122 2.94a2.204 2.204 0 110 4.408 2.204 2.204 0 010-4.408zm0 1.47a.735.735 0 10.002 1.47.735.735 0 00-.002-1.47z"
      fillRule="evenodd"
    />
  </svg>
);

Photos.defaultProps = {
  width: 24,
  height: 24,
  color: colors.text,
};

Photos.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Photos);
