import {
  greyLight,
  primary,
  textLight,
} from '../../../../../../constants/colors';

export const sectionStyle = (props) => {
  const containerHeightProp = props.containerHeight
    ? {
        height: `calc(100% - ${props.containerHeight})`,
      }
    : {
        height: 'calc(100% - 20px)',
      };

  return {
    width: '100%',
    ...containerHeightProp,
  };
};

export const sectionHeaderStyle = (props) => {
  return {
    margin: '0',
    width: '100%',
    fontSize: '12px',
    fontWeight: '500!important',
    lineHeight: '20px',
    color: `${props.theme.color.secondary}`,
    textTransform: 'uppercase',
  };
};

export const sectionContentStyle = () => {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 20px)',
  };
};

export const mediaBtnStyle = () => {
  return {
    borderRadius: '4px',
    backgroundColor: '#ffffff',
    width: '100%',
    padding: '4px',
    clear: 'both',
    border: `1px solid ${greyLight}`,
  };
};

export const buttonStyle = (state, type) => {
  const activeBtn =
    state.messagetype === type
      ? {
          '&::before': {
            display: 'none',
          },
          'color': '#fff',
          'border': '0.5px solid rgba(20,20,20,0.04)',
          'borderRadius': '4px',
          'backgroundColor': primary,
          'boxShadow':
            '0 3px 8px 0 rgba(20,20,20,0.12), 0 3px 1px 0 rgba(20,20,20,0.04)',
        }
      : {};

  return {
    'fontFamily': 'Open Sans',
    'fontSize': '14px',
    'fontWeight': 600,
    'letterSpacing': 0,
    'lineHeight': '21px',
    'display': 'inline-block',
    'width': '33.33%',
    'float': 'left',
    'padding': '4px',
    'position': 'relative',
    'textAlign': 'center',
    'cursor': 'pointer',
    ...activeBtn,
    '&:before': {
      '`content`': '',
      'position': 'absolute',
      'display': 'block',
      'width': '2px',
      'height': '16px',
      'backgroundColor': 'rgba(20, 20, 20, 0.12)',
      'right': '-2px',
      'top': '6px',
    },
    '&:last-of-type::before': {
      display: 'none',
    },
  };
};

export const mediaItemStyle = () => {
  return {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    gridTemplateColumns: '1fr 1fr',
    overflowY: 'auto',
    overflowX: 'hidden',
    fontSize: '14px',
    marginTop: '8px',
    gridGap: '8px',
    [`@media (min-width: 481px) and (max-width: 640px)`]: {
      gridTemplateRows: '1fr 1fr 1fr',
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [`@media (min-width: 641px) and (max-width: 768px)`]: {
      gridTemplateRows: '1fr 1fr 1fr 1fr',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    [`@media (min-width: 769px) and (max-width: 968px)`]: {
      gridTemplateRows: '1fr 1fr 1fr',
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [`@media (min-width: 969px) and (max-width: 1200px)`]: {
      gridTemplateRows: '1fr 1fr 1fr 1fr',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
  };
};

export const itemStyle = (state, props, img, context) => {
  let itemTypeStyle = {};
  let bgColor = `${props.theme.backgroundColor.lightGrey}`;

  if (state.messagetype === 'image') {
    itemTypeStyle = {
      'cursor': 'pointer',
      'height': '120px',
      'min-width': '120px',
      'backgroundColor': bgColor,
      '> img': {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    };
  } else if (state.messagetype === 'video') {
    itemTypeStyle = {
      'cursor': 'pointer',
      'position': 'relative',
      'height': '120px',
      'min-width': '120px',
      'backgroundColor': bgColor,
      '> video': {
        width: '100%',
        height: '100%',
        margin: 'auto',
        objectFit: 'cover',
      },
    };
  } else if (state.messagetype === 'file') {
    itemTypeStyle = {
      'backgroundColor': bgColor,
      '> a': {
        'maxWidth': '100%',
        'maxHeight': '100%',
        'height': '100%',
        'margin': 'auto',
        'display': 'flex',
        'flexDirection': 'column',
        'alignItems': 'center',
        'justifyContent': 'center',
        'padding': '32px 16px',
        '&:hover, &:visited': {
          color: `${props.theme.secondaryTextColor}`,
        },
        '> i': {
          width: '24px',
          height: '24px',
          display: 'inline-block',
          mask: `url(${img}) left center no-repeat`,
          backgroundColor: textLight,
          marginBottm: '4px',
        },
        '> span': {
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          wordBreak: 'break-all',
          color: textLight,
          fontFamily: 'Open Sans',
          fontSize: '14px',
          fontWeight: 600,
          letterSpacing: 0,
          lineHeight: '21px',
          textAlign: 'center',
          textTransform: 'uppercase',
        },
      },
    };
  }

  return {
    textAlign: 'center',
    borderRadius: '4px',
    overflow: 'hidden',
    ...itemTypeStyle,
  };
};

export const videoPlayIconStyle = () => {
  return {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '34px',
    height: '34px',
    position: 'absolute',
  };
};

export const emptyStateLabelStyle = () => {
  return {
    marginTop: '12px',
    color: textLight,
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: 0,
    lineHeight: '27px',
    textAlign: 'center',
  };
};
