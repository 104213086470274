import { createHmac } from 'crypto';

import config from '../../config';

const stringifyPayload = (payload) =>
  typeof payload === 'string'
    ? payload
    : Object.keys(payload)
        .sort()
        .map((key) => {
          if (typeof payload[key] === 'object') {
            return `${encodeURIComponent(key)}=${encodeURIComponent(
              stringifyPayload(payload[key]),
            )}`;
          }
          return `${encodeURIComponent(key)}=${encodeURIComponent(
            payload[key],
          )}`;
        })
        .join('&');

export default function calculateHmac(payload, secret, digest = 'hex') {
  return createHmac('sha256', secret || config.crypto.dbEncryptionKey)
    .update(stringifyPayload(payload), 'utf-8')
    .digest(digest);
}
