const WAITLIST_FEATURES = {
  ARMADILLO: 'ARMADILLO',
  LEASE_LOCK: 'LEASE_LOCK',
  SELL_PROPERTY: 'SELL_PROPERTY',
  LULA_MAINTENANCE: 'LULA_MAINTENANCE',
  OWNWELL: 'OWNWELL',
  NOVO: 'NOVO',
};

export default WAITLIST_FEATURES;
