function getListingFromArrayOfTreesById(properties, listingId) {
  for (let i = 0; i < properties.length; i++) {
    if (properties[i].id === listingId) {
      return properties[i];
    }
    if (properties[i].children_listings) {
      const result = getListingFromArrayOfTreesById(
        properties[i].children_listings,
        listingId,
      );
      if (result) {
        return result;
      }
    }
  }
  return null;
}

export default getListingFromArrayOfTreesById;
