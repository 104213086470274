import { getConfig } from '../../services/configService';
import { TTfetch } from '../TTfetch';

const checkEmailForPaidRR = (email, listingId) => {
  const { API_URL } = getConfig();
  return TTfetch(
    `${API_URL}/public/applicationExists?email=${encodeURIComponent(
      email,
    )}&listingId=${listingId}`,
    { method: 'GET' },
  )
    .then((res) => {
      if (res.status === 200) {
        return res.json().then((data) => data);
      }
      return false;
    })
    .catch(() => false);
};

export default checkEmailForPaidRR;
