import React from 'react';
import { useLottie } from 'lottie-react';

import IframeAnimation from '../../components/Animations/iOSIframeAnimation';
import isiOSDevice from '../../helpers/isIosDevice';

import animationPathJson from './spinner.animation.json';

const SpiningLoader = ({ style, options }) => {
  const iOS = isiOSDevice();

  if (iOS) {
    return (
      <IframeAnimation
        style={style}
        src="https://lottie.host/?file=43ab15c6-844d-4d07-a614-f6097aff4da2/XT4Hkqo8Xp.json"
      />
    );
  }

  const { View } = useLottie({
    path: animationPathJson,
    loop: true,
    autoplay: true,
    ...options,
  });

  return <div className={style}>{View}</div>;
};

export default SpiningLoader;
