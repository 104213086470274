import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import InfomationIcon from '../../icons/InformationCircleThick';
import Card from '../Card';

import styles from './HelpfulInfoCard.module.scss';

const COLLAPSABLE_REQUIRED_HEIGHT = 256;

const HelpfulInfoCard = ({
  className,
  title,
  noTitle,
  noIcon,
  Icon,
  iconProps: { className: iconClassName, ...otherIconProps },
  collapsable,
  children,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const [meetsRequiredHeight, setMeetsRequiredHeight] = useState(false);
  const cardRef = useRef(null);

  // If collapsable is enabled and the card height exceeds the required height, we'll collapse the card and display the Read More button
  const displayCollapseControls = collapsable && meetsRequiredHeight;

  useEffect(() => {
    if (
      collapsable &&
      cardRef?.current?.clientHeight > COLLAPSABLE_REQUIRED_HEIGHT
    ) {
      setMeetsRequiredHeight(true);
    }
  }, []);

  return (
    <Card
      className={cx(styles.helpCard, className, {
        [styles.collapsable]: displayCollapseControls,
        [styles.expanded]: collapsable && isExpanded,
      })}
      ref={cardRef}
    >
      {!noTitle && <h6>{title}</h6>}
      {!noIcon && (
        <Icon className={cx(styles.icon, iconClassName)} {...otherIconProps} />
      )}
      {children}
      {displayCollapseControls && (
        <button
          className={styles.readMore}
          onClick={(e) => {
            e.preventDefault();
            setExpanded(!isExpanded);
          }}
        >
          Read {isExpanded ? 'Less' : 'More'}
        </button>
      )}
    </Card>
  );
};

HelpfulInfoCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  noTitle: PropTypes.bool,
  noIcon: PropTypes.bool,
  Icon: PropTypes.object,
  iconProps: PropTypes.object,
  collapsable: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

HelpfulInfoCard.defaultProps = {
  title: 'Helpful info:',
  noTitle: false,
  noIcon: false,
  Icon: InfomationIcon,
  iconProps: {},
};

export default HelpfulInfoCard;
