import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import { SUB_DIVISION_NAMES_KEYS } from '../../../../../../../constants/lease_agreement/sub-division-names';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';

import styles from './SharedUtilitiesMD.module.scss';

const SharedUtilitiesMD = ({
  className,
  standaloneWizard,
  leaseAgreementSelection,
}) => {
  const {
    input: { value: sharedUtilities },
  } = useField('custom_state_data.shared_utilities');

  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standaloneWizard,
  );

  const CONTENT_DATA = {
    [SUB_DIVISION_NAMES_KEYS.montgomery]: {
      label: (
        <>
          For properties constructed before 1978 and not individually metered,
          are there any shared electric or gas utilities for your premises?
        </>
      ),
      description: (
        <>Montgomery County, MD law requires that you disclose this.</>
      ),
      text: (
        <>
          For any property constructed before 1978 and not individually metered,
          the Ratio Utility Billing System (RUBS) formula can be used to
          allocate gas and electric billings that are shared by tenants for the
          property. The specific formula used to calculate tenant's share of
          these expenses must be included along with all information required
          under the Public Utilities Article of the Maryland Code and applicable
          COMAR provisions. Please note that these costs are separate from the
          rent amount.
        </>
      ),
      textAreaLabel: (
        <>
          Please list all shared gas and electric utilities and how they are
          divided:
        </>
      ),
    },
    [SUB_DIVISION_NAMES_KEYS.baltimore]: {
      label: (
        <>Are there any shared water/wastewater utilities for the property?</>
      ),
      description: <>Baltimore City law requires that you disclose this.</>,
      text: (
        <>
          If any water/wastewater utility costs are shared by tenants for the
          property, the specific formula used to calculate tenant's share of the
          expense and the average monthly allocated costs of water and
          wastewater services in the preceding 12 mos. must be included. Please
          note that these costs are separate from the rent amount. Please list
          all shared water/wastewater utilities, how they are divided, and the
          preceding 12 mos. average monthly allocated cost:
        </>
      ),
      textAreaLabel: (
        <>
          Please list all shared water/wastewater utilities and how they are
          divided:
        </>
      ),
    },
  };

  const data = CONTENT_DATA[leaseAgreementSelection];

  return (
    <div className={cx(styles.container, className)}>
      <label className={styles.label}>{data.label}</label>
      <p className={styles.description}>{data.description}</p>
      <Field
        component={RadioGroup}
        id="custom_state_data.shared_utilities"
        name="custom_state_data.shared_utilities"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'custom_state_data.shared_utilities_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'custom_state_data.shared_utilities_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      {sharedUtilities && (
        <>
          <p className={styles.text}>{data.text}</p>
          <Field
            as="textarea"
            component={Input}
            id="custom_state_data.utilities_devided_as_follows"
            name="custom_state_data.utilities_devided_as_follows"
            label={data.textAreaLabel}
            className={styles.termsContainer}
            inputProps={{
              rows: '5',
              className: styles.termsField,
            }}
            maxLength={2000}
            validate={fieldRequired}
            help={(val = '') => `${val.length} / 2000 characters used`}
          />
        </>
      )}
    </div>
  );
};

SharedUtilitiesMD.propTypes = {
  className: PropTypes.string,
  standaloneWizard: PropTypes.bool,
  leaseAgreementSelection: PropTypes.string.isRequired,
};

export default SharedUtilitiesMD;
