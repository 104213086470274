import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import MaintenanceRequestStatusEnum, {
  LulaStatusesEnum,
} from '../../../../constants/enums/MaintenanceRequestStatusEnum';
import formatStatus from '../../../../helpers/formatStatus';
import { useConfig } from '../../../../providers/ConfigProvider';
import Tag from '../../../Tag';

import styles from './StatusTag.module.scss';

const StatusTag = ({
  className,
  type,
  sentToPartner,
  children,
  lastStatusUpdatedManually,
  ...props
}) => {
  const { IS_OWNER } = useConfig();
  const status = type.toLowerCase();

  // Here we check if user is renter and if the status matches one of LULA generated ones
  const isPartnerGreen =
    Object.values(LulaStatusesEnum).indexOf(status.toUpperCase()) > -1 &&
    !IS_OWNER;

  const userDependedColor = IS_OWNER ? 'red' : 'yellow';

  return (
    <Tag
      className={cx(className, styles.tag, styles[status], {
        [styles.green]: isPartnerGreen,
        [styles.manual]: lastStatusUpdatedManually,
        [styles.sentToPartner]: sentToPartner,
        [styles[userDependedColor]]:
          status ===
          MaintenanceRequestStatusEnum.QUOTE_NEEDS_REVIEW.toLowerCase(),
      })}
      uppercase={false}
      {...props}
    >
      {formatStatus(status)}
      {children}
    </Tag>
  );
};

StatusTag.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  lastStatusUpdatedManually: PropTypes.bool,
  sentToPartner: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(MaintenanceRequestStatusEnum)),
};

export default StatusTag;
