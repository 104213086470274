import get from 'lodash.get';

const getExperimentVariant = (user, experimentName, fallback = '') => {
  const activeExperiments = get(user, 'active_experiments', []);

  const experiment = (activeExperiments || []).find(
    (exp) => exp.name === experimentName,
  );
  if (!experiment) {
    return fallback;
  }
  return experiment.value;
};

export default getExperimentVariant;
