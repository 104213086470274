import React from 'react';

import Callout from './Callout';

export default {
  title: 'Commons/Callout',
  component: Callout,
};

const Template = (args) => <Callout {...args} />;

export const CalloutInfo = Template.bind({});
CalloutInfo.storyName = 'Callout Info';
CalloutInfo.args = {
  type: 'info',
  style: { padding: '16px' },
  children: <span>Your info was successfully saved</span>,
};

export const CalloutError = Template.bind({});
CalloutError.storyName = 'Callout Error';
CalloutError.args = {
  type: 'error',
  style: { padding: '16px' },
  children: <span>Something went wrong</span>,
};

export const CalloutWarning = Template.bind({});
CalloutWarning.storyName = 'Callout Warning';
CalloutWarning.args = {
  type: 'error',
  style: { padding: '16px' },
  children: <span>Something went wrong</span>,
};
