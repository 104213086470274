import React from 'react';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';

import styles from './NonRefundableFeeLawDC.module.scss';

const NonRefundableFeeLawDC = () => {
  return (
    <HelpfulInfoCard
      noIcon
      title="D.C.'s Non-Refundable Fee Law"
      className={styles.helpfulInfoCard}
      color="iceCold"
    >
      <p className={styles.description}>
        All non-refundable fees must be stated in the lease.
      </p>
    </HelpfulInfoCard>
  );
};

export default NonRefundableFeeLawDC;
