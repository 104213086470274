import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import PlainRadio from '../PlainRadioButton/PlainRadioButton';

import styles from './DayMonthPicker.module.scss';

const DayMonthPicker = ({ value, onChange }) => {
  const [selectedDay, setSelectedDay] = useState(value);

  const renderDays = (start = 1) => {
    const children = [];
    for (let i = start; i <= start + 6; i++) {
      children.push(
        <div
          key={i}
          className={cx(styles.day, { [styles.selected]: i == selectedDay })}
          onClick={() => {
            setSelectedDay(i);
            onChange && onChange(i);
          }}
          data-qa={`day-month-picker-${i}`}
        >
          {i}
        </div>,
      );
    }
    return children;
  };

  return (
    <div className={styles.column}>
      <div className={styles.title}>Select Day of Month</div>
      <div className={styles.row}>{renderDays()}</div>
      <div className={styles.row}>{renderDays(8)}</div>
      <div className={styles.row}>{renderDays(15)}</div>
      <div className={styles.row}>{renderDays(22)}</div>
      <div className={styles.divider} />
      <label style={{ display: 'flex', cursor: 'pointer' }}>
        <PlainRadio
          style={{ marginRight: 4 }}
          defaultChecked={selectedDay == 'Last Day'}
          onClick={() => {
            setSelectedDay(selectedDay == 'Last Day' ? null : 'Last Day');
            onChange && onChange('Last Day');
          }}
        />{' '}
        Last day of the month
      </label>
    </div>
  );
};

DayMonthPicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

export default DayMonthPicker;
