import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Logo from '../Logo';

import styles from './MarketingLayoutHeader.module.scss';

const logoContent = (hideLogoLink, onLogoClick, to) => {
  if (to) {
    return (
      <Link to={to} onClick={onLogoClick}>
        <Logo />
      </Link>
    );
  }
  if (hideLogoLink) {
    return <Logo onClick={onLogoClick} />;
  }
  return (
    <a
      href="https://www.turbotenant.com/"
      target="_blank"
      rel="noopener noreferrer"
      onClick={onLogoClick}
    >
      <Logo />
    </a>
  );
};

const MarketingLayoutHeader = ({
  action,
  className,
  onLogoClick,
  hideLogoLink,
  to,
  partnerLogo,
  LogoToReplace,
}) => (
  <div className={cx(styles.container, className)}>
    {LogoToReplace ? (
      <img src={LogoToReplace} width={163} height={36} />
    ) : (
      logoContent(hideLogoLink, onLogoClick, to)
    )}
    {action}
    {partnerLogo && <img src={partnerLogo} alt="Partner Logo" />}
  </div>
);

MarketingLayoutHeader.propTypes = {
  action: PropTypes.object,
  className: PropTypes.string,
  onLogoClick: PropTypes.func,
  hideLogoLink: PropTypes.bool,
  to: PropTypes.string,
  partnerLogo: PropTypes.node,
};

MarketingLayoutHeader.defaultProps = {
  hideLogoLink: false,
};

export default MarketingLayoutHeader;
