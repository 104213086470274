import React from 'react';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import browserHistory from '../browserHistory';
import { TTInitializer } from '../core/initialization';
import { useConfig } from '../providers/ConfigProvider';

import MainApplication from './pages/MainApplication';

import './main.scss';

const initializer = new TTInitializer();

const App = () => {
  const config = useConfig();
  const client = initializer.getApolloClient(config);

  return (
    <ApolloProvider client={client}>
      <Router history={browserHistory}>
        <MainApplication />
      </Router>
    </ApolloProvider>
  );
};

export default App;
