import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Form = (props) => (
  <svg {...props} viewBox="0 0 20 18" fill={props.color}>
    <path d="M16.939 0c.31 0 .566.226.606.519l.006.081v9.8h1.837c.304 0 .557.218.604.503L20 11v6.4c0 .331-.274.6-.612.6H.612A.606.606 0 010 17.4V11c0-.331.274-.6.612-.6h1.836l.001-8.2c0-.304.23-.555.53-.595L3.06 1.6h4.285v-1c0-.304.231-.555.53-.595L7.96 0h8.98zM5.714 11.6h-4.49v5.2h17.552v-5.2h-4.49v1c0 .304-.23.555-.53.595l-.083.005H6.327a.606.606 0 01-.613-.6v-1zm1.632-8.8H3.673v7.6h2.654c.304 0 .556.218.604.503l.008.097v1h.407V2.8zm8.98-1.6H8.572V12h4.49v-1c0-.304.23-.555.53-.595l.082-.005h2.653V1.2zm-1.02 7.6c.338 0 .612.269.612.6 0 .304-.23.555-.529.595l-.083.005H9.592a.606.606 0 01-.612-.6c0-.304.23-.555.529-.595l.083-.005h5.714zm0-1.6c.338 0 .612.269.612.6 0 .304-.23.555-.529.595l-.083.005H9.592a.606.606 0 01-.612-.6c0-.304.23-.555.529-.595l.083-.005h5.714zm0-1.6c.338 0 .612.269.612.6 0 .304-.23.555-.529.595l-.083.005H9.592a.606.606 0 01-.612-.6c0-.304.23-.555.529-.595l.083-.005h5.714zm0-1.6c.338 0 .612.269.612.6 0 .304-.23.555-.529.595l-.083.005H9.592a.606.606 0 01-.612-.6c0-.304.23-.555.529-.595L9.592 4h5.714zm0-1.6c.338 0 .612.269.612.6 0 .304-.23.555-.529.595l-.083.005h-4.082a.606.606 0 01-.612-.6c0-.304.23-.555.53-.595l.082-.005h4.082z" />
  </svg>
);

Form.defaultProps = {
  width: 20,
  height: 18,
  color: colors.text,
};

Form.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Form);
