import React from 'react';
import { useFormState } from 'react-final-form';
import PropTypes from 'prop-types';

import FormError from '../FormError';

const FinalFormError = ({ className, focus }) => {
  const { submitError, submitErrors, dirtySinceLastSubmit } = useFormState({
    subscription: {
      submitError: true,
      dirtySinceLastSubmit: true,
      submitErrors: true,
    },
  });
  if (dirtySinceLastSubmit) {
    return null;
  }
  let error = submitError;
  if (!error) {
    const errorFields = submitErrors ? Object.keys(submitErrors) : [];
    if (errorFields.length === 1) {
      error = submitErrors[errorFields[0]];
    } else if (errorFields.length > 1) {
      error = 'One or more fields have an error. Please check and try again.';
    }
  }

  return <FormError focus={focus} className={className} error={error} />;
};

FinalFormError.propTypes = {
  className: PropTypes.string,
  focus: PropTypes.bool,
};

export default FinalFormError;
