import React from 'react';
import { gql, useMutation } from '@apollo/client';

import Banner from '../../../assets/Leases-Leads@2x.png';
import Button from '../../../components/Button';
import SidebarMarketing from '../../../components/SidebarMarketing';
// Owner layout
import MarketingLayout from '../../../layout/MarketingLayout';
import { useConfig } from '../../../providers/ConfigProvider';

import academyBuildingImg from './assets/building-illustration.svg';
// Shared component
import ResetPassword from './ResetPassword';

import styles from './ResetPasswordContainer.module.scss';

const resetPasswordGQL = gql`
  mutation resetPasswordRequest(
    $accountType: UserType!
    $email: String!
    $isReset: Boolean!
    $redirectUrl: String
    $captchaToken: String
  ) {
    resetPasswordRequest(
      input: {
        accountType: $accountType
        email: $email
        isReset: $isReset
        redirectUrl: $redirectUrl
        captchaToken: $captchaToken
      }
    ) {
      ok
    }
  }
`;

const ResetPasswordContainer = ({ academy, ...props }) => {
  const [sendResetEmail, { loading }] = useMutation(resetPasswordGQL);
  const { IS_OWNER } = useConfig();

  // Owner layout
  if (IS_OWNER)
    return (
      <MarketingLayout
        action={
          <Button
            className={styles.signupButton}
            to={
              academy
                ? `/auth/academy/signup${props.history?.location?.search || ''}`
                : `/signup${props.history?.location?.search || ''}`
            }
            small={true}
            secondary={true}
          >
            SIGN UP
          </Button>
        }
        side={
          <>
            {academy ? (
              <SidebarMarketing
                className={styles.academySidebar}
                title="GET BACK TO UP YOUR LANDLORD GAME"
                subtitle="Where great landlords go to learn"
                graphic={academyBuildingImg}
              />
            ) : (
              <SidebarMarketing
                title="Manage The Rental Process"
                subtitle="Get Back to Finding & Managing Great Tenants"
                graphic={Banner}
              />
            )}
          </>
        }
      >
        <ResetPassword
          type={props.match.params.type}
          sendResetEmail={sendResetEmail}
          loading={loading}
          history={props.history}
          academy={academy}
        />
      </MarketingLayout>
    );

  // Renter uses default layout provided by parent components.
  return (
    <ResetPassword
      type={props.match.params.type}
      sendResetEmail={sendResetEmail}
      loading={loading}
      history={props.history}
      isRenter
    />
  );
};

export default ResetPasswordContainer;
