import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import TriangleError from '../../../icons/TriangleError';

import styles from './LulaUnpaidBanner.module.scss';

const LulaUnpaidBanner = ({
  className,
  children,
  bannerButton: BannerButton,
}) => {
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.iconWrap}>
        <TriangleError width={64} height={64} halfFillColor={'#BF2C2C'} />
      </div>
      <div className={styles.content}>
        <h4 className={styles.title}>
          Maintenance Plus Invoice Payment Failed
        </h4>
        {children}
      </div>
      <BannerButton />
    </div>
  );
};

LulaUnpaidBanner.propTypes = {
  className: PropTypes.string,
  segmentLocation: PropTypes.string,
};

LulaUnpaidBanner.defaultProps = {
  segmentLocation: 'Forms Pack Banner',
};

export default LulaUnpaidBanner;
