import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ScreeningOption = (props) => (
  <svg {...props} viewBox="0 0 24 24">
    <path
      fill={props.color}
      d="M9.56 0a9.56 9.56 0 019.562 9.56c0 2.36-.855 4.52-2.272 6.188l6.921 6.92a.78.78 0 01-1.016 1.179l-.087-.076-6.921-6.92a9.523 9.523 0 01-6.186 2.27A9.56 9.56 0 010 9.562 9.56 9.56 0 019.56 0zm0 1.561a8 8 0 00-7.999 8 8 8 0 008 8 8 8 0 008-8 8 8 0 00-8-8zm4.124 3.256l.049.164c.171.686.097 1.343-.14 2.029l-.089.235.035.04c.254.31.372.75.359 1.223l-.009.143c-.044.52-.227 1.014-.543 1.331l-.097.088c-.055.743-.234 1.337-.513 1.797l-.097.149c-.07.1-.144.19-.217.269l-.13.126v1.016l.132.054c.418.175.84.36 1.252.548l.609.284-.672 1.409a41.794 41.794 0 00-2.39-1.046.78.78 0 01-.484-.62l-.007-.105V12a.78.78 0 01.523-.737c-.025.009.022-.025.106-.144.207-.295.346-.793.346-1.558a.78.78 0 01.499-.728l.054-.018.012-.023a.91.91 0 00.049-.173l.013-.1.003-.069-.003-.083a.781.781 0 01-.59-.66l-.006-.098c0-.156.164-.586.26-.821l.07-.158.054-.145c.121-.359.168-.68.125-.978l-.029-.147c-.281-1.126-3.81-1.162-4.3-.11l-.039.11a.78.78 0 01-.913.575l-.107-.019.017.072.045.167c.025.085.056.176.093.274l.088.226.163.389.082.203c.052.14.068.225.068.362a.78.78 0 01-.589.757l-.032.006-.004.055.002.099c.008.099.03.195.06.269l.01.02.054.018a.781.781 0 01.492.63l.007.098c0 .765.139 1.263.346 1.558l.07.092c.037.045.053.058.036.052a.78.78 0 01.516.628L8.39 12v1.95a.78.78 0 01-.49.726c-.586.233-1.203.498-1.803.771l-.591.276-.67-1.41a43.892 43.892 0 011.867-.834l.126-.053v-1.014l-.018-.016a2.273 2.273 0 01-.22-.238l-.108-.142c-.298-.425-.5-.98-.585-1.675l-.026-.273-.06-.051c-.31-.289-.498-.74-.561-1.226l-.015-.147c-.04-.526.082-1.022.368-1.363l.047-.052-.028-.067c-.08-.2-.144-.381-.198-.558-.178-.597-.214-1.09.005-1.534.211-.43.584-.658 1.06-.712l.155-.012.084-.118c1.456-1.913 6.168-1.717 6.955.589z"
      fillRule="evenodd"
    />
  </svg>
);

ScreeningOption.defaultProps = {
  color: '#042238',
  width: 24,
  height: 24,
};

ScreeningOption.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ScreeningOption);
