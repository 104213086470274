import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';
import Feedback from '../../icons/Feedback';
import { segmentTracking } from '../../services/utilities';
import fieldRequired from '../../validators/fieldRequired';
import Button from '../Button';
import DottedHeader from '../DottedHeader';
import Input from '../Input';
import Modal from '../Modal';
import { H2, Paragraph } from '../Typography';

import styles from './SeeSomethingInaccurateModal.module.scss';

const SeeSomethingInaccurateModal = ({
  open,
  onClose,
  loading,
  openLocation,
  onSubmit,
}) => {
  useEffect(() => {
    segmentTracking('Something Inaccurate Modal Loaded', {
      location: openLocation,
    });
  }, []);

  return (
    <Modal
      onClose={onClose}
      open={open}
      showCloseIcon
      disableCloseIcon={loading}
      size="small"
      bannerClassName={styles.banner}
      autoFocus={false}
      banner={
        <DottedHeader
          className={styles.banner}
          icon={Feedback}
          iconProps={{
            className: styles.bannerIcon,
            color: colors.slimProgressBarFill,
          }}
          circleCenterClassName={styles.bannerCircle}
          patternProps={{ className: styles.bannerPattern }}
        />
      }
    >
      <Modal.Content noFooter>
        <H2 className={styles.title}>See something inaccurate?</H2>
        <Paragraph className={styles.desc}>
          Please describe the inaccuracies and any additional details to help us
          improve:
        </Paragraph>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Field
                  component={Input}
                  type="description"
                  name={`feedback`}
                  id="feedback"
                  as="textarea"
                  maxLength={1000}
                  help={(val = '') => `${val.length} / 1000 characters used`}
                  inputProps={{
                    rows: 2,
                  }}
                  className={styles.description}
                  validate={fieldRequired}
                />
                <div className={styles.btnWrapper}>
                  <Button
                    className={styles.sendBtn}
                    loading={loading}
                    type="submit"
                    disabled={loading}
                    id="submit-btn"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            );
          }}
        </Form>
      </Modal.Content>
    </Modal>
  );
};

SeeSomethingInaccurateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  openLocation: PropTypes.string,
};

export default SeeSomethingInaccurateModal;
