import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Toast from './Toast';

import styles from './ToastList.module.scss';

const ToastList = ({ toasts }) => (
  <div className={styles.list}>
    {toasts.map(({ centerScreen, message, type, timeout, id }) => (
      <Toast
        className={cx(styles.toast, { [styles.centerScreen]: centerScreen })}
        message={message}
        type={type}
        timeout={timeout}
        centerScreen={centerScreen}
        id={id}
        key={id}
      />
    ))}
  </div>
);

ToastList.propTypes = {
  toasts: PropTypes.array,
};

export default ToastList;
