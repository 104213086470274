import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import RealEstateHouseDollarThick from '../../../../../../../icons/RealEstateHouseDollarThick';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './RentConcessions.module.scss';

const RentConcessions = ({ className }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <div className={cx(styles.container, className)}>
      <SectionTitle title="Concessions" icon={RealEstateHouseDollarThick} />
      <label className={styles.label}>Are you making any concessions?</label>
      <p className={styles.description}>
        Under the Illinois Rent Concession Act, you must include in the lease
        agreement, the nature and amount of each concession granted. A
        concession is defined as any credit or rebate of rent, the right to
        occupy the leased property for an additional amount of time outside the
        lease terms, or any other valuable thing, right or privilege. Examples
        include: Free rent, reduced rent, waiving of fees, new appliances,
        renovations, repainting or pool access.
      </p>
      <Field
        component={RadioGroup}
        id="custom_state_data.rent_concessions"
        name="custom_state_data.rent_concessions"
        radiosContainerClassName={styles.radioButtonsContainer}
        options={[
          {
            id: 'rent_concessions_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'rent_concessions_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />
      <Condition when="custom_state_data.rent_concessions" is={true}>
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.nature_of_concessions"
          name="custom_state_data.nature_of_concessions"
          label="Please describe the nature of concessions. Include the type, term, amount and how they are calculated."
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
            className: styles.termsField,
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      </Condition>
    </div>
  );
};

RentConcessions.propTypes = {
  className: PropTypes.string,
};

export default RentConcessions;
