import React from 'react';
import cx from 'classnames';
import { array, object, oneOfType } from 'prop-types';

import LoadingScreen from '../../components/LoadingScreen';
import useBodyAndRootStyles from '../../hooks/useBodyAndRootStyles';

import styles from './DarkBluePageSimple.module.scss';

const BluePageSimple = ({ loading, children } = {}) => {
  useBodyAndRootStyles(`
    min-height: 100vh;
  `);

  return (
    <div className={cx(styles.container)}>
      <LoadingScreen loading={loading} className={styles.loadingScreen}>
        {children}
      </LoadingScreen>
    </div>
  );
};

BluePageSimple.propTypes = {
  children: oneOfType([object, array]),
};

export default BluePageSimple;
