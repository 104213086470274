import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../../../../constants/colors';
import Calculator from '../../../../../icons/streamline/Calculator';
import Card from '../../../../Card';
import Tag from '../../../../Tag';
import { H5, Paragraph } from '../../../../Typography';
import Benefit from '../Benefit';

import styles from './BenefitSection.module.scss';

const BenefitSection = ({ benefits, selectedBenefit, extraParams }) => {
  const isAllFeatures = selectedBenefit === 'all_features';

  const benefitsWithAccounting = useMemo(() => {
    const accountingExists = benefits.some(
      (benefit) => benefit.key === 'accounting',
    );
    if (accountingExists) return benefits;

    return [
      ...benefits,
      {
        key: 'accounting',
        title: 'Accounting',
      },
    ];
  }, [benefits]);

  return benefitsWithAccounting.map((benefit) => {
    const showAccounting =
      !isAllFeatures || (isAllFeatures && benefit.key === 'accounting');

    return (
      <div
        className={cx(
          styles.benefitsGroup,
          { [styles.all]: isAllFeatures },
          {
            [styles.show]: selectedBenefit === benefit.key || isAllFeatures,
          },
        )}
        key={benefit.key}
      >
        <div className={styles.proBenefit}>
          {isAllFeatures && <H5>{benefit.titleShort || benefit.title}</H5>}
          {benefit.items
            ?.filter((item) => item.plan !== 'premium')
            .map((item) => (
              <Benefit
                key={item.key}
                item={item}
                extraParams={extraParams}
                isProPlan={true}
              />
            ))}
        </div>

        <div className={styles.proBenefit}>
          {isAllFeatures && <H5>{benefit.titleShort || benefit.title}</H5>}
          {benefit.items
            ?.filter((item) => item.plan !== 'pro')
            .map((item) => (
              <Benefit key={item.key} item={item} extraParams={extraParams} />
            ))}
        </div>

        <div className={styles.premiumBenefit}>
          {isAllFeatures && <H5>{benefit.titleShort || benefit.title}</H5>}
          {benefit.items
            ?.filter((item) => item.plan !== 'pro')
            .map((item) => (
              <Benefit
                key={item.key}
                item={item}
                extraParams={extraParams}
                isPremiumPlus
              />
            ))}
          {showAccounting && (
            <>
              <Card className={styles.accounting}>
                <Tag accent className={styles.tag}>
                  $180 VALUE
                </Tag>
                <div className={styles.accountingHeader}>
                  <Calculator secondaryColor={colors.babyBlue} />
                  <H5>Rental Accounting</H5>
                </div>
                <Paragraph>
                  Bookkeeping built specifically for real estate investors
                </Paragraph>
              </Card>
            </>
          )}
        </div>
      </div>
    );
  });
};

BenefitSection.propTypes = {
  benefits: PropTypes.array.isRequired,
  selectedBenefit: PropTypes.string.isRequired,
  extraParams: PropTypes.object.isRequired,
};

export default BenefitSection;
