import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import getFormattedDateLocalTZ from '../../../helpers/format-date-local';
import toCurrency from '../../../helpers/toCurrency';
import NewWindow from '../../../icons/NewWindow';
import WarningFill from '../../../icons/WarningFill';
import Button from '../../Button';

import styles from './QuoteApprovalRequired.module.scss';

const QuoteApprovalRequired = ({
  expiresAt,
  quoteUrl,
  needApproval,
  nteAmount,
  atIndexPage = false,
}) => {
  return (
    <div
      className={cx(styles.container, {
        [styles.atIndexPage]: atIndexPage,
      })}
      onClick={() => !atIndexPage && window.open(quoteUrl, '_blank')}
    >
      <div className={styles.iconContainer}>
        <WarningFill />
      </div>
      <div className={styles.colRight}>
        <div className={styles.texts}>
          <h2 className={styles.title}>Approval Required to Continue</h2>
          <p className={styles.description}>
            A work order quote for {needApproval} may exceed your set amount.
            Your NTE <i>(Not to Exceed Amount)</i> is set to{' '}
            {toCurrency(nteAmount / 100, false)}.{' '}
            {!atIndexPage ? (
              <>This quote will expire {getFormattedDateLocalTZ(expiresAt)}.</>
            ) : (
              <>
                Click on the maintenance request for more details and to approve
                or reject the quote.
              </>
            )}
          </p>
        </div>
        {!atIndexPage && (
          <div>
            <Button
              icon={NewWindow}
              className={styles.viewDetailsBtn}
              iconProps={{
                className: styles.btnIcon,
              }}
              iconAfter
              onClick={(e) => {
                e.stopPropagation();
                !atIndexPage && window.open(quoteUrl, '_blank');
              }}
            >
              View Details
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

QuoteApprovalRequired.propTypes = {
  expiresAt: PropTypes.string,
  quoteUrl: PropTypes.string,
  needApproval: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  nteAmount: PropTypes.number.isRequired,
  atIndexPage: PropTypes.bool,
};

export default QuoteApprovalRequired;
