import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useMutation } from '@apollo/client';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Footer from '../../components/Footer';
import SelectField from '../../components/SelectField';
import { useSuccessToast } from '../../components/Toast';
import { Tooltip } from '../../components/Tooltip';
import UploadDropZoneAlt from '../../components/UploadDropZoneAlt';
import { statesList } from '../../constants';
import colors from '../../constants/colors';
import STATE_NAMES from '../../constants/lease_agreement/state-names';
import * as AuthService from '../../core/auth/authService';
import analyseNonTTLeaseAgreementMutationGQL from '../../graphql/mutations/lease_agreements/analyseNonTTLeaseAgreement.graphql';
import CheckMarkFilled from '../../icons/CheckMarkFilled';
import DocumentPartialFill from '../../icons/DocumentPartialFill';
import DownloadForms from '../../icons/DownloadForms';
import InformationCircle from '../../icons/InformationCircle';
import Security from '../../icons/Security';
import Sparkles from '../../icons/Sparkles';
import {
  getNonTTLeaseAgreementUploadUrl,
  uploadFileToS3,
} from '../../services/cloudFilesService';
import { segmentTracking } from '../../services/utilities';
import composeValidators from '../../validators/composeValidators';
import fieldRequiredShort from '../../validators/fieldRequiredShort';

import AuditLAErrorModal from './AuditLAErrorModal';
import AuditLAHeader from './AuditLAHeader';
import AuditUploadAnimation from './AuditUploadAnimation';

import styles from './LeaseAgreementAuditUpload.module.scss';

const MAX_FILE_SIZE = 20971520;

const ACCEPTED_FILE_TYPES = [
  'docx',
  'doc',
  'ppt',
  'pptx',
  'xls',
  'xlsx',
  'odt',
  'odp',
  'ods',
  'pdf',
  'txt',
];

const checkmarks = [
  'Recent state legislation changes',
  'Fair housing violations',
  'Required disclosures for your state',
  'Rent, deposit & late fee compliance',
  'General lease provisions',
  'Essential contractual terms',
];

const states = statesList
  .filter((state) => !['VI', 'GU', 'PR'].includes(state.value))
  .map((state) => ({
    label: STATE_NAMES[state.value],
    value: state.value,
  }));

const LeaseAgreementAuditUpload = ({ history }) => {
  const [analyseNonTTLeaseAgreementMutation] = useMutation(
    analyseNonTTLeaseAgreementMutationGQL,
  );
  const [formData, setFormData] = useState({});
  const [uploadError, setUploadError] = useState(null);
  const [fileAttached, setFileAttached] = useState(null);
  const [finalFormSubmit, setFinalFormSubmit] = useState(false);
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const showSuccessToast = useSuccessToast();

  useEffect(() => {
    segmentTracking('lease_audit started', {
      location: 'Lease Audit Upload Page',
    });
  }, []);

  useEffect(() => {
    if (
      formData.state &&
      fileAttached &&
      finalFormSubmit &&
      !submissionAttempted
    ) {
      finalFormSubmit();
    }
  }, [fileAttached, finalFormSubmit, submissionAttempted, formData]);

  const raiseError = (error) => {
    setUploadError(error);
    setFileUploading(false);
    setFileAttached(null);
    setSubmissionAttempted(false);
  };

  const onSubmit = async (formData) => {
    segmentTracking('document_upload started', {
      location: 'Lease Audit Upload Page',
    });

    if (!fileAttached) {
      raiseError('No file selected.');
      return;
    }
    setFileUploading(true);
    setSubmissionAttempted(true);

    let s3Params;

    try {
      s3Params = await getNonTTLeaseAgreementUploadUrl({
        fileName: fileAttached.name,
        state: formData.state,
      });
      await uploadFileToS3(s3Params.url, fileAttached);
    } catch (error) {
      raiseError(error.message || 'Error uploading file.');
      return;
    }

    const { key } = s3Params;

    try {
      const result = await analyseNonTTLeaseAgreementMutation({
        variables: {
          state: formData.state,
          uploadedFileKey: key,
        },
      });

      const { token } = result.data.analyseNonTTLeaseAgreement;
      const decodedToken = AuthService.decodeToken(token);
      showSuccessToast('Audit complete');
      history.push(
        `/lease-agreement-audit-ai/${btoa(
          `LeaseAgreementAIToolDocument:${decodedToken.id}`,
        )}?token=${token}`,
      );
    } catch (error) {
      raiseError(error.message || 'Error analysing file.');
      return;
    }

    setFileUploading(false);
  };

  function handleFileDrop({ droppedFiles, submitFunction }) {
    const files = droppedFiles || [];
    const file = files[0];
    if (!file) {
      raiseError('Unable to attach file. Make sure the extension is correct.');
    }
    if (file.size > MAX_FILE_SIZE) {
      raiseError(
        'The file you uploaded is too large. Maximum file size is 20mb.',
      );
      return;
    }
    const isAllowedFileType = ACCEPTED_FILE_TYPES.includes(
      file.name.split('.').pop().toLowerCase(),
    );
    if (isAllowedFileType) {
      setFileAttached(file);
      setFinalFormSubmit(submitFunction);
      return;
    }
    raiseError(`The file type for ${file.name} is not supported.`);
  }

  return (
    <div className={styles.container}>
      <AuditLAHeader step="upload" />
      <section className={styles.mainContent}>
        <div className={styles.mainContentInner}>
          <div className={styles.titleSection}>
            <h2>Free instant audit</h2>
            <h1 className={styles.title}>
              <Sparkles
                className={styles.sparkles}
                // This value enables gradient color on the icon
                color="url(#a)"
                sparkleTopClassName={styles.sparkleTop}
                sparkleBottomClassName={styles.sparkleBottom}
                sparkleBigClassName={styles.sparkleBig}
              />
              <span>Our legally trained AI</span> will analyze your lease
              agreement for:
            </h1>
          </div>
          <div className={styles.checkmarks}>
            {checkmarks.map((checkmark, index) => (
              <div key={index} className={styles.checkmark}>
                <CheckMarkFilled className={styles.checkmarkIcon} />
                <div className={styles.checkmarkText}>{checkmark}</div>
              </div>
            ))}
          </div>
          <Form onSubmit={onSubmit} initialValues={{}}>
            {({ handleSubmit, form }) => {
              const stateHasData = form.getState().values.state;
              setFormData(form.getState().values);

              return (
                <form
                  onSubmit={handleSubmit}
                  className={`${styles.form} ${
                    stateHasData ? styles.formWithState : ''
                  }`}
                >
                  <div className={styles.stateSelection}>
                    <Field
                      className={styles.statesSelect}
                      label="What state is the lease agreement for?"
                      options={states}
                      id="state"
                      validate={composeValidators(fieldRequiredShort)}
                      name={'state'}
                      component={SelectField}
                    />
                  </div>
                  <div className={styles.uploadSection}>
                    <div className={styles.uploadZone}>
                      {fileUploading && (
                        <div className={styles.animationWrapper}>
                          <AuditUploadAnimation style={{ width: '100%' }} />
                        </div>
                      )}
                      <UploadDropZoneAlt
                        attachedState={
                          fileAttached ? (
                            <div className={styles.attachedState}>
                              <div>
                                <DocumentPartialFill color={colors.link} />
                              </div>
                              <div className={styles.fileName}>
                                {fileAttached.name}
                              </div>
                            </div>
                          ) : (
                            false
                          )
                        }
                        id="lease_agreement_upload"
                        name="lease_agreement_upload"
                        label="Upload your lease agreement"
                        description={
                          <div className={styles.dropZoneDescription}>
                            <div className={styles.click}>
                              <div>Click or drag to upload</div>
                              <span data-tooltip-id="click-or-drag">
                                <InformationCircle
                                  width={12}
                                  height={12}
                                  color="white"
                                />
                              </span>
                              <Tooltip
                                id="click-or-drag"
                                className={styles.tooltipClick}
                              >
                                <p className={styles.tooltipText}>
                                  Files must be ≤20MB and one of these file
                                  types: {ACCEPTED_FILE_TYPES.join(', ')}
                                </p>
                              </Tooltip>
                            </div>
                            <div className={styles.infoSecure}>
                              <Security
                                color="white"
                                width={24}
                                height={24}
                                className={styles.lockIcon}
                              />
                              We keep your documents secure.
                            </div>
                          </div>
                        }
                        accept={ACCEPTED_FILE_TYPES.map(
                          (ext) => `.${ext}`,
                        ).join(',')}
                        uploadFiles={() => {}}
                        multiple={false}
                        icon={DownloadForms}
                        iconProps={{ width: 34, height: 34 }}
                        className={cx(styles.upload, {
                          [styles.uploading]: fileUploading,
                        })}
                        onFileDrop={(files) =>
                          handleFileDrop({
                            droppedFiles: files,
                            submitFunction: () => handleSubmit,
                          })
                        }
                      />
                    </div>

                    <div className={styles.disclosure}>
                      By uploading a document above, you are agreeing to our{' '}
                      <a
                        className={styles.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.turbotenant.com/terms-of-use/"
                      >
                        Terms of Use
                      </a>{' '}
                      &{' '}
                      <a
                        className={styles.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.turbotenant.com/privacy-policy/"
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
        <Footer className={styles.footer} />
      </section>
      {uploadError && (
        <AuditLAErrorModal
          onClose={() => setUploadError(null)}
          errorMessage={uploadError}
          onAction={() => history.push('/lease-agreement')}
          openLocation="Lease Audit Upload Page"
        />
      )}
    </div>
  );
};

LeaseAgreementAuditUpload.propTypes = {
  history: PropTypes.object.isRequired,
};

export default LeaseAgreementAuditUpload;
