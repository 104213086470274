import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FORM_ERROR } from 'final-form';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Checkbox from '../../../components/Checkbox';
import { loginCometChatUser } from '../../../components/CometChatWorkspace/utils';
import Divider from '../../../components/Divider';
import FinalFormError from '../../../components/FinalFormError';
import Input from '../../../components/Input';
import trimEmail from '../../../components/Input/trimEmail';
import PasswordInput from '../../../components/PasswordInput';
import SocialLoginRetner from '../../../components/SocialLoginRetner';
import { useErrorToast } from '../../../components/Toast';
import { login, requestLogin } from '../../../core/auth/authService';
import AppleSigninMutation from '../../../graphql/mutations/appleSignin.graphql';
import getDeviceSignature from '../../../helpers/get-device-signature';
import { useLoginRecaptcha } from '../../../helpers/login-recaptcha';
import authPage from '../../../hoc/auth-page';
import * as localStorageService from '../../../services/localStorageService';
import {
  getUrlToRedirectAfterLogin,
  segmentTracking,
  setUrlToRedirectAfterLogin,
} from '../../../services/utilities';
import composeValidators from '../../../validators/composeValidators';
import fieldRequired from '../../../validators/fieldRequired';
import validEmail from '../../../validators/validEmail';
import validPassword from '../../../validators/validPassword';

import AppleSigninModal from './AppleSigninModal';
import DownloadAppModal from './DownloadAppModal';

import styles from './RenterLoginCard.module.scss';

const RenterLoginCard = ({ history }) => {
  const [appleModalOpened, setAppleModal] = useState(false);
  const showErrorMessage = useErrorToast();
  const { getLoginCaptchaToken } = useLoginRecaptcha();

  const [appleSignin, { loading: appleSigninLoading }] =
    useMutation(AppleSigninMutation);

  const onSubmit = async (data) => {
    segmentTracking('login clicked', {
      location: 'Renter Login',
    });

    const captchaToken = await getLoginCaptchaToken();
    const { user, password, remember, token, service } = data;
    let response;
    try {
      response = await requestLogin({
        email: user,
        password,
        accountType: 2,
        service,
        token,
        captchaToken,
      });
    } catch (error) {
      const errorMessage =
        error.code === 403
          ? 'Your account has been suspended please email support@turbotenant.com'
          : 'The user name or password is incorrect. Please try again.';

      if (token) {
        return showErrorMessage(errorMessage);
      } else {
        return {
          [FORM_ERROR]: errorMessage,
        };
      }
    }
    const { info } = response || {};
    const hasUnreadMessages = response?.hasUnreadMessages || false;
    if (response.messaging_token && hasUnreadMessages) {
      loginCometChatUser(response.messaging_token);
    }

    if (remember) {
      localStorageService.setItem(
        'credentials',
        JSON.stringify({
          user,
          password: btoa(password),
        }),
      );
    }

    const url = getUrlToRedirectAfterLogin();

    if (url) {
      setUrlToRedirectAfterLogin('');
      return history.replace(url);
    }
    if (info && !info.application) {
      return history.replace('/renters/profile');
    }
    return history.replace('/');
  };

  const onAppleSignin = async (response) => {
    const identityToken = get(response, 'authorization.id_token', '');
    const deviceSignature = getDeviceSignature();

    try {
      const { data } = await appleSignin({
        variables: {
          identityToken,
          firstName: response?.user?.name?.firstName,
          lastName: response?.user?.name?.lastName,
          deviceSignature,
          isRenter: true,
        },
      });
      if (data?.signinAppleWeb?.isSignup) {
        return setAppleModal(true);
      }
      if (data?.signinAppleWeb?.messagingToken) {
        loginCometChatUser(data.signinAppleWeb.messagingToken);
      }
      login(data?.signinAppleWeb?.token);

      const url = getUrlToRedirectAfterLogin();

      if (url) {
        setUrlToRedirectAfterLogin('');
        return history.replace(url);
      }
      return history.replace('/');
    } catch (error) {
      const parsedError = get(error, `graphQLErrors[0]`, {});
      showErrorMessage(parsedError.message);
    }
  };

  return (
    <div className={styles.container}>
      <DownloadAppModal isRenter={true} />
      <Card className={styles.card}>
        <h2 className={styles.title}>Log in as a Renter</h2>
        <SocialLoginRetner
          onSuccess={onSubmit}
          onAppleSignin={onAppleSignin}
          appleLoading={appleSigninLoading}
          className={styles.socialLogin}
        />
        <Divider text="" />
        <Form
          onSubmit={onSubmit}
          subscription={{
            submitting: true,
          }}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <FinalFormError />
              <Field
                label="Email"
                component={Input}
                type="email"
                name="user"
                id="user"
                parse={trimEmail}
                validate={composeValidators(validEmail, fieldRequired)}
                inputProps={{
                  autoComplete: 'username',
                }}
              />
              <Field
                label="Password"
                component={PasswordInput}
                name="password"
                id="password"
                validate={composeValidators(fieldRequired, validPassword)}
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
              <div className={styles.row}>
                <Field
                  label="Remember Me"
                  component={Checkbox}
                  id="remember"
                  name="remember"
                  className={styles.rememberMe}
                />
              </div>
              <div className={styles.row}>
                <Link to="/auth/resetpassword/reset" className={styles.link}>
                  Forgot Password?
                </Link>
                <Link
                  to="/auth/resetpassword/setPassRenter"
                  className={styles.link}
                >
                  Didn't Set Your Password Yet?
                </Link>
              </div>
              <div className={styles.actions}>
                <Button
                  type="submit"
                  className={styles.action}
                  loading={submitting}
                  id="login_submit"
                >
                  Login
                </Button>
              </div>
            </form>
          )}
        </Form>
      </Card>
      {appleModalOpened && (
        <AppleSigninModal onClose={() => setAppleModal(false)} />
      )}
    </div>
  );
};

RenterLoginCard.propTypes = {
  history: PropTypes.object,
};

export default authPage(RenterLoginCard);
