import React from 'react';
import cx from 'classnames';

import FlatButton from '../FlatButton';

import styles from './GrayFlatButton.module.scss';

const GrayFlatButton = ({ className, label, children, ...props }) => (
  <FlatButton {...props} className={cx(styles.flatButton, className)}>
    {children != null ? children : label}
  </FlatButton>
);

export default GrayFlatButton;
