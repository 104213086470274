const getFieldError = (
  { touched, error, submitError, dirtySinceLastSubmit },
  skipTouchedCheck,
) => {
  if (skipTouchedCheck && error) {
    return error;
  }

  if (touched && error) {
    return error;
  }
  if (!dirtySinceLastSubmit && submitError) {
    return submitError;
  }
  return null;
};

export default getFieldError;
