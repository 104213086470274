import React, { useContext, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';

import LegacyLayout from '../layout/LegacyLayout';
import WithTracking from '../pages/WithTracking';

/**
 * What is the Layout context?
 *
 * Is an utility that allows us to easily change the application layout
 * depending on what part of the applciation we are right now.
 *
 * NOTICE:
 *
 * By default, it uses the LegacyLayout that is the legacy layout component used in the
 * application until now.
 *
 */

const initialState = {
  Layout: LegacyLayout,
  settings: {},
};

export const LayoutStateContext = React.createContext(initialState);
const LayoutChangeContext = React.createContext(() => {});

const LayoutProvider = ({ children }) => {
  const [layoutState, changeLayout] = useState({
    Layout: null,
    settings: {},
  });

  return (
    <LayoutStateContext.Provider value={layoutState}>
      <LayoutChangeContext.Provider value={changeLayout}>
        {children}
      </LayoutChangeContext.Provider>
    </LayoutStateContext.Provider>
  );
};

/**
 * What is Current Layout?
 *
 * <CurrentLayout /> is the component that renders the currently selected layout.
 *
 */

export const CurrentLayout = ({ children }) => {
  const { Layout, settings = {} } = useContext(LayoutStateContext);

  const LayoutComp = Layout || LegacyLayout;

  return <LayoutComp {...settings}>{children}</LayoutComp>;
};

export const useLayoutSettings = () => {
  const { settings = {} } = useContext(LayoutStateContext);

  return settings;
};

/**
 * How can the layout be changed?
 *
 * We have available three ways in which we can change the layout of the application:
 *
 * 1) <RouteWithLayout />, based on the react-router-dom <Route />, this component renders
 *    a route with an specific layout.
 *
 * 2) useLayoutSelector(), this hook can be used to dynamically modify the layout
 *    if needed.
 */

export const useLayoutSelector = () => {
  const changeLayout = useContext(LayoutChangeContext);

  return changeLayout;
};

export const RouteWithLayout = ({
  layout = LegacyLayout,
  layoutSettings,
  component,
  render,
  ...props
}) => {
  const selectLayout = useLayoutSelector();
  const currentSettings = useLayoutSettings();

  useEffect(() => {
    selectLayout({
      Layout: layout,
      settings: { ...(layoutSettings || {}), layoutId: layout.layoutId },
    });

    return () => {
      selectLayout({ Layout: null, settings: null });
    };
  }, [layout?.name, JSON.stringify(layoutSettings), JSON.stringify(props)]);

  return (
    <Route
      {...props}
      component={
        currentSettings?.layoutId === layout.layoutId ? component : null
      }
      render={currentSettings?.layoutId === layout.layoutId ? render : null}
    />
  );
};

/**
 *
 * @notice
 *
 * RouteWithLayoutV2 and ComponentWrapper were added to the codebase
 * because we needed to passe the route properties (match, location, history)
 * to the layout as well in order for the layout to change based on some params
 * and logic. The original RouteWithLayout component had some limitations
 * for accomplishing this, as well as a re-rendering issue.
 *
 * While reading about react-router-dom, I found that in the version 6.x
 * they added a new feature for handling layouts that I think it could be
 * a better approach than the custom code we wrote for handling layouts.
 * Maybe at some some point we could start using that feature instead of the
 * LayoutProvider.
 *
 * SEE: https://reactrouter.com/en/main/route/route#layout-routes
 *
 */
const ComponentWrapper = ({
  layout,
  layoutSettings,
  component: Component,
  ...props
}) => {
  const selectLayout = useLayoutSelector();
  const currentSettings = useLayoutSettings();

  useEffect(() => {
    selectLayout({
      Layout: layout,
      settings: {
        ...(layoutSettings || {}),
        ...(props || {}),
        layoutId: layout.layoutId,
      },
    });

    return () => {
      selectLayout({ Layout: null, settings: null });
    };
  }, [layout?.name, JSON.stringify(layoutSettings), JSON.stringify(props)]);

  return currentSettings?.layoutId === layout.layoutId ? (
    <Component {...props} />
  ) : null;
};

export const RouteWithLayoutV2 = ({
  layout = LegacyLayout,
  layoutSettings,
  component,
  ...props
}) => {
  return (
    <Route {...props}>
      {(props) => {
        return (
          <ComponentWrapper
            {...{ layout, layoutSettings, component, ...props }}
          />
        );
      }}
    </Route>
  );
};

export default WithTracking(LayoutProvider);
