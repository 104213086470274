import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import FlatButton from '../../../../components/FlatButton';
import { Tooltip } from '../../../../components/Tooltip';
import { maintenanceCategoriesObject } from '../../../../constants/maintenanceCategories';
import PREVIEWABLE_FILETYPES, {
  PREVIEW_PLACEHOLDER,
} from '../../../../constants/previewable_filetypes';
import getFormattedDateLocalTZ from '../../../../helpers/format-date-local';
import getFileTypeFromUrl from '../../../../helpers/getFileTypeFromUrl';
import EditIcon from '../../../../icons/Edit';
import Photos from '../../../../icons/Photos';
import { useConfig } from '../../../../providers/ConfigProvider';

import styles from './MaintenanceRequestCard.module.scss';

const MaintenanceRequestCard = ({
  requestedOn,
  preferredTime,
  category,
  description,
  photos,
  setPreviewedImage,
  className,
  isOwner = false,
  issueName,
  requestId,
  listingId,
  isRequester,
  sentToPartner,
}) => {
  const [showAllImages, setShowAllImages] = useState(false);
  const maintenanceRequestPhotos =
    photos?.filter((photo) => !photo.maintenance_request_activity_id) || [];
  const { PRIVATE_BASE_PATH, ASSET_BUCKET_URL } = useConfig();

  return (
    <div className={cx(styles.contentHolder, className)}>
      <div className={cx(styles.dataHolder)}>
        <div className={styles.detailsHeader}>
          <h3>Details</h3>

          {isOwner && (
            <div
              data-tooltip-id="tooltip-edit-maintenance"
              data-tooltip-hidden={isRequester && !sentToPartner}
            >
              <FlatButton
                id={`edit_maintenance_button`}
                to={`${PRIVATE_BASE_PATH}maintenance/${requestId}/${listingId}/edit`}
                icon={EditIcon}
                iconProps={{
                  className: styles.editIcon,
                }}
                className={styles.editButton}
                disabled={!isRequester || sentToPartner}
              >
                <span className={styles.mobileHidden}>EDIT</span>
              </FlatButton>
            </div>
          )}
          <Tooltip id="tooltip-edit-maintenance">
            {sentToPartner && isRequester ? (
              <>You can not edit the request once it's been sent to Lula</>
            ) : (
              <>
                Editing is not allowed for tenant created requests for
                traceability. Consider adding a note instead.
              </>
            )}
          </Tooltip>
        </div>
        <p>
          <b>Requested On:</b>
          {getFormattedDateLocalTZ(requestedOn, 'MMM DD, YYYY | hh:mm a')}
        </p>
        {!isOwner && requestId && (
          <p>
            <b>Maintenance ID:</b>
            {atob(requestId).split(':').pop()}
          </p>
        )}
        <p>
          <b>Preferred Time to Enter:</b>
          {preferredTime === 'COORDINATE'
            ? 'Coordinate a Time First'
            : 'Anytime'}
        </p>
        <p>
          <b>Category:</b>
          {maintenanceCategoriesObject[category] || 'N/A'}
        </p>
        {!isOwner && requestId && (
          <p>
            <b>Issue Title:</b>
            {issueName}
          </p>
        )}
        <p className={cx(styles.descriptionHeader)}>
          <b>Description:</b>
        </p>
        <p className={styles.description}>{description}</p>
        <div className={cx(styles.imagesHolder)}>
          {maintenanceRequestPhotos
            .slice(0, showAllImages ? photos.length : 5)
            .map((file, idx) => {
              const isPreviewable = PREVIEWABLE_FILETYPES.includes(
                getFileTypeFromUrl(file.url),
              );
              return (
                <div
                  key={file.id}
                  style={{
                    backgroundImage: `url('${
                      isPreviewable
                        ? file.url
                        : `${ASSET_BUCKET_URL}${PREVIEW_PLACEHOLDER}`
                    }')`,
                  }}
                  className={cx(styles.imageHolder)}
                  onClick={() => {
                    if (isPreviewable) {
                      setPreviewedImage(idx);
                      return;
                    }
                    window.open(file.url, '_blank');
                  }}
                ></div>
              );
            })}
          {maintenanceRequestPhotos.length > 5 && !showAllImages && (
            <div
              className={cx(styles.imageHolder, styles.viewMore)}
              onClick={() => setShowAllImages(true)}
            >
              <Photos width={24} height={24} className={styles.photoIcon} />
              <div>view more</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MaintenanceRequestCard.propTypes = {
  requestedOn: PropTypes.string.isRequired,
  preferredTime: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  photos: PropTypes.array.isRequired,
  setPreviewedImage: PropTypes.func.isRequired,
  className: PropTypes.string,
  isOwner: PropTypes.bool,
  requestId: PropTypes.string,
  issueName: PropTypes.string,
  listingId: PropTypes.string,
  isRequester: PropTypes.bool,
  sentToPartner: PropTypes.bool,
};

export default MaintenanceRequestCard;
