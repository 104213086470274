import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Help = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 25" {...rest} fill={color}>
    <path d="M12 3.249a8.9 8.9 0 1 0 0 17.8 8.9 8.9 0 0 0 0-17.8Zm-10.1 8.9c0-5.578 4.522-10.1 10.1-10.1 5.578 0 10.1 4.522 10.1 10.1 0 5.578-4.522 10.1-10.1 10.1-5.578 0-10.1-4.522-10.1-10.1Z" />
    <path d="M12 7.999c-1.102 0-1.991.888-1.991 1.99a.6.6 0 0 1-1.2 0 3.187 3.187 0 0 1 3.19-3.19 3.187 3.187 0 0 1 3.192 3.19 3.188 3.188 0 0 1-2.591 3.136v2.047a.6.6 0 0 1-1.2 0V12.58a.6.6 0 0 1 .6-.6c1.102 0 1.99-.889 1.99-1.991 0-1.103-.888-1.991-1.99-1.991Z" />
    <path d="M12.9 17.249a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0Z" />
  </svg>
);

Help.defaultProps = {
  width: 24,
  height: 25,
  color: colors.primary,
};

Help.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Help);
