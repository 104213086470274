import React, { memo } from 'react';

import colors from '../constants/colors';

const Referral = (props) => (
  <svg {...props} viewBox="0 0 48 48" fill={props.color}>
    <path
      d="M35.688 2.411c2.277 2.326 2.277 6.098 0 8.426-.308.314-.727.614-1.256.917-1.034.59-2.507 1.199-4.323 1.82H46.36c.537 0 .972.445.972.993v7.944a.983.983 0 01-.972.992h-.972V46.34a.983.983 0 01-.972.993H3.583a.983.983 0 01-.972-.993V23.503h-.972a.983.983 0 01-.972-.992v-7.944c0-.548.435-.993.972-.993h16.253l-.298-.103c-1.68-.585-3.05-1.16-4.026-1.717-.53-.303-.948-.603-1.256-.917-2.278-2.327-2.278-6.1 0-8.426a5.75 5.75 0 018.25 0c.31.317.607.749.906 1.296.59 1.08 1.196 2.627 1.817 4.534.246.754.485 1.538.715 2.334.23-.796.468-1.58.714-2.334.623-1.916 1.234-3.468 1.826-4.548.296-.54.59-.968.897-1.282a5.75 5.75 0 018.25 0zM16.222 23.503H4.556v21.845l11.666-.001V23.503zm13.61 0H18.556v21.844h11.278V23.503zm13.612 0H32.166v21.844h11.278V23.504zM16.222 15.56H2.612v5.958l13.61-.001V15.56zm13.61 0H18.556v5.957h11.278V15.56zm15.557 0H32.166v5.957h13.223V15.56zM28.812 3.816c-.161.164-.36.452-.576.847-.518.946-1.09 2.398-1.677 4.205a78.636 78.636 0 00-1.2 4.09l.157-.043a74.611 74.611 0 003.85-1.181c1.768-.6 3.19-1.184 4.117-1.713.386-.221.668-.423.83-.588 1.518-1.552 1.518-4.067 0-5.617a3.834 3.834 0 00-5.501 0zm-15.125 0a4.032 4.032 0 000 5.617c.161.165.444.367.83.588.927.53 2.349 1.112 4.117 1.713a74.611 74.611 0 004.006 1.224l-.043-.159a78.774 78.774 0 00-1.157-3.931c-.585-1.8-1.154-3.247-1.67-4.194-.22-.4-.42-.693-.582-.859a3.834 3.834 0 00-5.5 0z"
      fillRule="evenodd"
    />
  </svg>
);

Referral.defaultProps = {
  width: 48,
  height: 48,
  color: colors.text,
};

export default memo(Referral);
