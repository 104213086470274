import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import colors from '../../../constants/colors';
import PremiumBadge from '../../../icons/PremiumBadge';
import Referral from '../../../icons/Referral';
import Button from '../../Button';
import Skeleton from '../../Skeleton';

import styles from './MenuOfferTag.module.scss';

const MenuOfferTag = ({
  className,
  onClick,
  createdAt,
  userDataLoaded,
  isOwner,
  isPremiumUser,
}) => {
  const accountOlderThanThirtyDays =
    moment().diff(moment(createdAt), 'days') > 30;

  if (userDataLoaded && isOwner) {
    return (
      <Skeleton>
        <Skeleton.Button dark size={'sidebar'} />
      </Skeleton>
    );
  } else if (!isOwner) {
    return null;
  }

  let badgeText = 'Upgrade';
  let mobileSuffix = 'to Premium';
  let Icon = PremiumBadge;

  if (isPremiumUser) {
    badgeText = 'Give $25. Get $25.';
    mobileSuffix = '';
    Icon = Referral;
  }

  return (
    <Button
      className={cx(styles.container, className, {
        [styles.blue]:
          (accountOlderThanThirtyDays && !isPremiumUser) || isPremiumUser,
      })}
      onClick={onClick}
      dataQa="menu-offer-tag"
    >
      <Icon
        className={styles.icon}
        height={24}
        width={17}
        color={colors.white}
      />
      <span className={styles.label}>
        {badgeText} <span className={styles.mobileSuffix}>{mobileSuffix}</span>
      </span>
    </Button>
  );
};

MenuOfferTag.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  createdAt: PropTypes.string,
  userDataLoaded: PropTypes.bool,
  isOwner: PropTypes.bool,
  isPremiumUser: PropTypes.bool,
};

export default MenuOfferTag;
