import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Security = (props) => (
  <svg {...props} viewBox="0 0 15 20" fill={props.color}>
    <path d="M7.5 0c2.803 0 5.075 2.29 5.075 5.114v2.214l1.795.001c.316 0 .578.235.623.541l.007.094v11.4c0 .352-.282.636-.63.636H.63a.633.633 0 01-.63-.635v-11.4c0-.352.282-.636.63-.636l1.794-.001V5.114c0-2.752 2.158-4.996 4.861-5.11zm6.239 8.6H1.26v10.129h12.478V8.599zM7.5 11.4c.571 0 1.034.467 1.034 1.043 0 .336-.158.635-.403.825v2.025c0 .351-.283.635-.631.635a.632.632 0 01-.624-.541l-.006-.094-.001-2.025a1.044 1.044 0 01-.403-.825c0-.576.463-1.042 1.034-1.042zm0-10.13c-2.107 0-3.815 1.72-3.815 3.844v2.215h7.629V5.114c0-2.057-1.602-3.736-3.618-3.839z" />
  </svg>
);

Security.defaultProps = {
  width: 15,
  height: 20,
  color: colors.text,
};

Security.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Security);
