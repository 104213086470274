import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Truck = (props) => (
  <svg width="40" height="33" viewBox="0 0 40 33" {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#7FE3FF" d="M14 0h25v25H14z" />
      <path
        d="M39.167 0c.409 0 .749.292.82.677L40 .825v28.05a.828.828 0 0 1-.684.812l-.15.013H35.75c-.387 1.883-2.069 3.3-4.083 3.3-2.017 0-3.697-1.417-4.083-3.299H12.416C12.03 31.583 10.348 33 8.333 33c-2.016 0-3.697-1.417-4.083-3.299L.833 29.7a.831.831 0 0 1-.82-.677L0 28.875V19.8a.82.82 0 0 1 .159-.484l.085-.1 3.25-3.218 4.11-7.324a.833.833 0 0 1 .58-.41l.15-.014h5V.825c0-.405.294-.742.683-.812l.15-.013h25ZM8.333 26.4c-1.382 0-2.5 1.107-2.5 2.475 0 1.367 1.119 2.475 2.5 2.475 1.38 0 2.5-1.109 2.5-2.475 0-1.367-1.12-2.475-2.5-2.475Zm23.334 0c-1.383 0-2.5 1.107-2.5 2.475 0 1.367 1.118 2.475 2.5 2.475 1.38 0 2.5-1.109 2.5-2.475 0-1.367-1.12-2.475-2.5-2.475Zm-20 0c.364.48.625 1.04.75 1.65h15.166c.125-.61.385-1.17.75-1.65H14.166l-.013-.001-2.487.001Zm26.666 0H35c.364.48.625 1.04.75 1.649l2.583.001V26.4Zm-33.334 0H1.666v1.65H4.25c.125-.61.385-1.17.749-1.65Zm8.334-16.501-4.51.001-3.928 7-.14.183-3.088 3.057-.001 4.61h11.667V9.899ZM15 24.75h23.333V1.65H15v23.1Zm-4.167-13.2c.46 0 .834.37.834.825a.828.828 0 0 1-.684.812l-.15.013h-.318l-2.27 4.494a.837.837 0 0 1-.978.423l-.14-.054a.822.822 0 0 1-.427-.968l.055-.139 2.5-4.95a.832.832 0 0 1 .59-.442L10 11.55h.833Z"
        fill="#042238"
      />
    </g>
  </svg>
);

Truck.defaultProps = {
  width: 40,
  height: 33,
};

Truck.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(Truck);
