import React from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../components/Button';
import FlatButton from '../../components/FlatButton';
import openInNewTab from '../../helpers/openInNewTab';
import House from '../../icons/House';
import { useConfig } from '../../providers/ConfigProvider';

import styles from './ExtraHeaderComponent.module.scss';

const ExtraHeaderComponent = ({ history }) => {
  const { IS_RENTER } = useConfig();

  const pathname = history.location.pathname;

  if (pathname.indexOf('/auth/login') === 0 && IS_RENTER) {
    return (
      <div className={styles.headerTextWrapper}>
        <span className={styles.headerText}>
          Need to apply to a property on TurboTenant?
        </span>
        <Button
          className={styles.headerTextButton}
          onClick={() =>
            openInNewTab(
              'https://www.turbotenant.com/apply-to-a-turbotenant-property',
            )
          }
          secondary
          small
        >
          <span>
            Apply <span className={styles.hideMobile}> to a property</span>
          </span>
        </Button>
      </div>
    );
  }

  if (pathname.indexOf('/email-preferences') === 0 && !IS_RENTER) {
    return (
      <div className={styles.emailPrefContainer}>
        <Button href="/auth/login" secondary className={styles.btn}>
          <span>
            <span className={styles.hideMobile}>Landlord </span>Login
          </span>
        </Button>
      </div>
    );
  }

  if (pathname.indexOf('/properties') === 0) {
    return (
      <FlatButton
        className={styles.flatBtn}
        href="https://www.turbotenant.com/"
        icon={House}
        iconProps={{ className: cx(styles.houseIcon, styles.hideMobile) }}
      >
        List my property
      </FlatButton>
    );
  }

  return null;
};

ExtraHeaderComponent.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ExtraHeaderComponent);
