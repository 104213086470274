import React from 'react';
import cx from 'classnames';

import Line from '../common/Line';

import styles from './Zebra.module.scss';

const generateItems = (numberOfItems, itemClassName) => {
  const items = [];
  for (let i = 0; i < numberOfItems; i++) {
    items.push(
      <div key={i} className={cx(styles.skeletonZebraItem, itemClassName)}>
        <span className={styles.optionIcon} />
        <Line size={'zebra'} />
      </div>,
    );
  }
  return items;
};

const Zebra = ({ className, numberOfItems, itemClassName }) => (
  <div className={cx(styles.skeletonZebra, className)}>
    {generateItems(numberOfItems, itemClassName)}
  </div>
);

export default Zebra;
