import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../../constants/colors';
import CheckMarkFilled from '../../../icons/CheckMarkFilled';
import CloseCircle from '../../../icons/CloseCircle';
import ErrorCircleFilled from '../../../icons/ErrorCircleFilled';
import HelpfulInfoList from '../../HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../HelpfulInfo/HelpfulInfoListItem';

import styles from './PasswordStrengthChecker.module.scss';

const PasswordStrengthChecker = ({
  password = '',
  error = false,
  className,
}) => {
  const [requirementsMet, setRequirementsMet] = useState({
    length: 'init',
    lowercase: 'init',
    uppercase: 'init',
    number: 'init',
    specialChar: 'init',
  });

  const requirementMessages = {
    length: 'At least 8 characters long',
    lowercase: 'Includes upper & lowercase letters',
    number: 'Includes a number',
    specialChar: 'Includes a special character (!, @, #, %, etc.)',
  };

  const getStatus = (condition) =>
    condition ? 'met' : error ? 'error' : 'init';

  const hasLength = password.length >= 8;
  const hasLowercase = /[a-z]/.test(password);
  const hasUppercase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[^a-zA-Z0-9\s]/.test(password);

  const checkRequirements = () => {
    setRequirementsMet({
      length: getStatus(hasLength),
      lowercase: getStatus(hasLowercase && hasUppercase),
      number: getStatus(hasNumber),
      specialChar: getStatus(hasSpecialChar),
    });
  };

  useEffect(() => {
    checkRequirements();
  }, [password, error]);

  const getIconData = (status) => {
    switch (status) {
      case 'error':
        return { icon: ErrorCircleFilled, color: colors.statusWarning };
      case 'met':
        return { icon: CheckMarkFilled, color: colors.slimProgressBarFill };
      default:
        return { icon: CloseCircle, color: colors.darkGray };
    }
  };

  return (
    <HelpfulInfoList className={cx(styles.helpfulList, className)}>
      {Object.entries(requirementsMet).map(([requirement, status]) => {
        const iconData = getIconData(status);

        return (
          <HelpfulInfoListItem
            icon={iconData.icon}
            iconProps={{ width: 14, height: 14, color: iconData.color }}
            className={styles.helpfulItem}
            key={requirement}
          >
            {requirementMessages[requirement]}
          </HelpfulInfoListItem>
        );
      })}
    </HelpfulInfoList>
  );
};

PasswordStrengthChecker.propTypes = {
  password: PropTypes.string,
  error: PropTypes.bool,
  className: PropTypes.string,
};

export default PasswordStrengthChecker;
