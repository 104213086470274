import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ESign = ({ color, ...props }) => (
  <svg viewBox="0 0 54 54" {...props} fill={color}>
    <path
      d="M21.472 31.445c.614 0 1.112.498 1.112 1.111v4.445c0 .613-.498 1.11-1.112 1.11h-1.113v2.378c6.947 1.191 10.766 5.081 11.287 11.983a1.112 1.112 0 0 1-1.11 1.195H1.447c-.647 0-1.158-.55-1.11-1.195.524-6.934 4.23-10.798 11.123-11.983v-2.377h-1.113A1.112 1.112 0 0 1 9.234 37v-4.445c0-.613.498-1.11 1.112-1.11h11.126Zm-1.113 2.222h-8.9v2.222h1.112c.615 0 1.113.498 1.113 1.112v4.444c0 .558-.414 1.03-.968 1.101-6.072.794-9.254 3.531-10.03 8.899h26.61c-.79-5.328-4.073-8.098-10.194-8.899a1.112 1.112 0 0 1-.968-1.101V37c0-.614.498-1.112 1.113-1.112h1.112v-2.222ZM44.836 1.446V6.96l5.55-5.544a1.114 1.114 0 0 1 1.685-.13c2.45 2.45 2 6.926-.81 11.823a1.113 1.113 0 0 1-.965.558h-9.029l-2.226 2.222 8.02.001c.614 0 1.112.543 1.112 1.212 0 .67-.498 1.212-1.112 1.212H36.614l-2.024 2.02h9.133c1.017 0 1.501 1.25.749 1.934-5.507 4.998-12.894 6.807-16.506 4.683l-2.871 2.868a1.114 1.114 0 0 1-1.574 0 1.11 1.11 0 0 1 0-1.571l2.89-2.887c-2.142-3.783 1.093-11.651 7.281-17.827C36.736 4.488 40.41 1.844 43.23.45a1.112 1.112 0 0 1 1.607.996Zm-4.344 21.11h-8.127l-2.746 2.746c2.323.901 6.792-.03 10.873-2.745ZM42.61 3.325c-2.324 1.39-5.029 3.464-7.344 5.78-5.235 5.225-8.031 11.679-7.175 14.58l11.873-11.859c.033-.037.068-.073.106-.106l2.54-2.537V3.324Zm8.518.497-6.5 6.492c-.071.098-.157.184-.255.254l-.88.877 6.148.001c1.71-3.182 2.187-5.924 1.487-7.624Z"
      fillRule="evenodd"
    />
  </svg>
);

ESign.defaultProps = {
  width: 54,
  height: 48,
  color: 'currentColor',
};

ESign.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ESign);
