const MaintenanceActivityTypesEnum = {
  OPENED: 'OPENED', // to be deprecated
  IN_PROGRESS: 'IN_PROGRESS', // to be deprecated
  DISMISSED: 'DISMISSED', // to be deprecated
  RESOLVED: 'RESOLVED', // to be deprecated
  RE_OPENED: 'RE_OPENED',
  STATUS_UPDATE: 'STATUS_UPDATE',
  FEEDBACK: 'FEEDBACK',
  NOTE: 'NOTE',
  USER_EVENT: 'USER_EVENT',
  WORK_ORDER_SENT: 'WORK_ORDER_SENT',
  LULA_EVENT: 'LULA_EVENT',
  LULA_WORK_ORDER_SENT: 'LULA_WORK_ORDER_SENT',
  LULA_TROUBLESHOOTING: 'LULA_TROUBLESHOOTING',
  LULA_STATUS_UPDATE: 'LULA_STATUS_UPDATE',
  LULA_QUOTE_UPDATE: 'LULA_QUOTE_UPDATE',
  LULA_NOTE_EVENT: 'LULA_NOTE_EVENT',
};

export default MaintenanceActivityTypesEnum;
