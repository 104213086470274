import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RemoveIcon from '../../icons/Delete';
import getFieldError from '../../validators/get-field-error';
import FilesList from '../FilesList';

import styles from './FileUploadPreview.module.scss';

const FileUploadPreview = ({
  meta,
  id,
  input: { value: files, onChange },
  className,
  onRemove,
  withLinks,
  filter,
  actions,
  showRemovingInProgressIndicator,
  fileNameClassName,
}) => {
  const [removing, setRemoving] = useState(false);
  const error = meta && getFieldError(meta);

  if (!files || files.length === 0) {
    return null;
  }
  const filteredFiles = files.filter(filter);

  return (
    <div className={cx(styles.container, className)}>
      <FilesList
        files={filteredFiles}
        withLinks={withLinks}
        loading={removing}
        fileNameClassName={fileNameClassName}
        actions={[
          ...actions,
          {
            title: 'Remove',
            icon: RemoveIcon,
            handler: async (index) => {
              const file = filteredFiles[index];
              const originalIndex = files.findIndex((f) => f === file);
              onChange([
                ...files.slice(0, originalIndex),
                ...files.slice(originalIndex + 1),
              ]);
              if (onRemove) {
                showRemovingInProgressIndicator && setRemoving(true);
                try {
                  await onRemove(file);
                  showRemovingInProgressIndicator && setRemoving(false);
                } catch (e) {
                  showRemovingInProgressIndicator && setRemoving(false);
                  throw e;
                }
              }
            },
          },
        ]}
      />
      {error && (
        <span id={`${id}_errormsg`} className={styles.error}>
          {error}
        </span>
      )}
    </div>
  );
};

FileUploadPreview.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  id: PropTypes.string,
  className: PropTypes.string,
  onRemove: PropTypes.func,
  withLinks: PropTypes.bool,
  fileNameClassName: PropTypes.string,
  filter: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
      handler: PropTypes.func.isRequired,
    }),
  ),
  showRemovingInProgressIndicator: PropTypes.bool,
};

FileUploadPreview.defaultProps = {
  filter: () => true,
  actions: [],
};

export default FileUploadPreview;
