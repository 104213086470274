import React from 'react';
import PropTypes from 'prop-types';

import SectionInfoBlock from '../../../../../common/SectionInfoBlock';

const LateFeeInfo = ({ className }) => (
  <SectionInfoBlock className={className}>
    <>
      <strong>Late Fees:</strong> Rent is due in full on the Due Date. If Rent
      is not received on or before the tenth (10th) day of each month, a late
      fee of five percent (5%) of the unpaid Rent amount will be charged
    </>
  </SectionInfoBlock>
);

LateFeeInfo.propTypes = {
  className: PropTypes.string,
};

export default LateFeeInfo;
