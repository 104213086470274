import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './SquareSimpleRadioButton.module.scss';

const SquareSimpleRadioButton = ({
  checked,
  value,
  className,
  disabled,
  style,
  id,
  label,
  ...otherProps
}) => (
  <div className={cx(styles.wrap, className)} style={style}>
    <label
      style={style}
      className={cx(styles.container, className, {
        [styles.disabled]: disabled,
      })}
    >
      <input
        type="radio"
        id={id}
        className={styles.radio}
        checked={checked}
        disabled={disabled}
        value={value}
        {...otherProps}
      />
      <div className={styles.square}>{label} </div>
    </label>
  </div>
);

SquareSimpleRadioButton.propTypes = {
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
  name: PropTypes.string,

  className: PropTypes.string,

  style: PropTypes.object,
};

export default SquareSimpleRadioButton;
