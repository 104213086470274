import RentersInsuranceColor from '../icons/RentersInsuranceColor';
import Truck from '../icons/Truck';
import { getConfig } from '../services/configService';
import { segmentTracking } from '../services/utilities';

import openInNewTab from './openInNewTab';

const getMovingServices = (listing, { location, locationShort }) => {
  const { MOVERS_URL } = getConfig();

  const movingServices = [
    {
      icon: Truck,
      title: 'Book a Mover »',
      text: 'Local movers you can trust',
      onClick: () => {
        segmentTracking(`book_a_mover_${locationShort}_clicked`, {
          location: location,
        });
        openInNewTab(`${MOVERS_URL}`);
      },
    },
    {
      icon: RentersInsuranceColor,
      title: 'Renters Insurance »',
      text: "It's likely required to move-in",
      onClick: () => {
        segmentTracking(`renters_insurance_${locationShort}_clicked`, {
          location: location,
        });
        openInNewTab('https://turbotenant.sureapp.com/renters-insurance');
      },
    },
  ];

  return movingServices;
};

export default getMovingServices;
