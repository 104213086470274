import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const MinusCircle = (props) => (
  <svg {...props} viewBox="0 0 18 18" fill={props.color}>
    <path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM13 9C13 9.4 12.6 9.8 12.2 9.8L5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9L13 9Z" />
  </svg>
);

MinusCircle.defaultProps = {
  width: 18,
  height: 18,
  color: colors.primary,
};

MinusCircle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(MinusCircle);
