import React, { useState } from 'react';
import AppleSignInButton from 'react-apple-signin-auth';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Checkbox';
import Divider from '../../../../components/Divider';
import FacebookLogin from '../../../../components/FacebookLogin';
import FinalFormError from '../../../../components/FinalFormError';
import GoogleLogin from '../../../../components/GoogleLogin';
import Label from '../../../../components/Label';
import PasswordInput from '../../../../components/PasswordInput';
import { useErrorToast } from '../../../../components/Toast';
import { H2, Link, Paragraph } from '../../../../components/Typography';
import AppleIcon from '../../../../icons/AppleIcon';
import MarketingLayout from '../../../../layout/MarketingLayout';
import fieldRequired from '../../../../validators/fieldRequired';
import AcceptInviteSidebar from '../AcceptInviteSidebar';

import styles from './AcceptInvite.module.scss';

const AcceptInviteExistingUser = ({
  company,
  accountName,
  userName,
  email,
  handleSubmit,
  forgotToBackUrl,
  ownersUrl,
}) => {
  const showErrorToast = useErrorToast();
  const [tosAccepted, setTosAccepted] = useState(false);

  return (
    <MarketingLayout
      side={
        <AcceptInviteSidebar
          isNewUser={false}
          company={company}
          accountName={accountName}
          userName={userName}
        />
      }
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <H2 className={styles.title}>
            You've been invited to {company || accountName}'s Account
          </H2>
          <Paragraph size="large" weight="semi">
            Enter your existing password to get started.
          </Paragraph>
          <div className={styles.socialSignIn}>
            <GoogleLogin
              type="login"
              onSuccess={handleSubmit}
              onFailure={() =>
                showErrorToast('An error occurred while logging in with Google')
              }
              className={styles.socialBtn}
            />
            <FacebookLogin
              type="login"
              onSuccess={handleSubmit}
              onFailure={() => {
                showErrorToast(
                  'An error occurred while logging in with FaceBook',
                );
                window.atatus &&
                  window.atatus.notify({
                    message: 'An error occurred while logging in with FaceBook',
                  });
              }}
              className={styles.socialBtn}
            />

            <AppleSignInButton
              authOptions={{
                clientId: 'com.turbotenant.web',
                scope: 'email name',
                redirectURI: `${ownersUrl}redirect`,
                state: 'state',
                nonce: 'nonce',
                usePopup: true,
              }}
              uiType="dark"
              className="apple-auth-btn"
              noDefaultStyle={false}
              buttonExtraChildren="Log in with Apple"
              onSuccess={handleSubmit}
              onError={() => showErrorToast('Error during Apple SSO')}
              skipScript={false}
              iconProp={{
                style: {
                  marginTop: '10px',
                  width: '380px',
                  height: '40px',
                  textAlign: 'center',
                },
              }}
              render={(props) => (
                <button
                  {...props}
                  className={styles.customAppleBtn}
                  onClick={() => {
                    props.onClick();
                  }}
                >
                  <AppleIcon />
                  <span className={styles.appleLabel}>Log in with Apple</span>
                </button>
              )}
            />
          </div>
          <Form
            onSubmit={handleSubmit}
            subscription={{
              submitting: true,
            }}
          >
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit} className={styles.form}>
                <FinalFormError />
                <Divider text="" />
                <Label>Email</Label>
                <Paragraph className={styles.email}>{email}</Paragraph>
                <Field
                  label="Password"
                  component={PasswordInput}
                  name="password"
                  id="password"
                  hint={null}
                  validate={fieldRequired}
                  className={styles.password}
                />
                <div className={styles.row}>
                  <Field
                    label="Remember Me"
                    component={Checkbox}
                    id="remember"
                    name="remember"
                    className={styles.checkbox}
                  />

                  <Link
                    to={`/auth/resetpassword/reset${
                      forgotToBackUrl ? `?backToUrl=${forgotToBackUrl}` : ''
                    }`}
                    className={styles.link}
                  >
                    Forgot Password?
                  </Link>
                </div>
                <Divider text="" />
                <div className={styles.tosWrapper}>
                  <Checkbox
                    className={styles.tosCheckbox}
                    label={
                      <>
                        I agree to the{' '}
                        <Link
                          href="https://www.turbotenant.com/terms-of-use/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Terms of Use"
                        >
                          Terms of Use
                        </Link>{' '}
                        &{' '}
                        <Link
                          href="https://www.turbotenant.com/privacy-policy/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Privacy Policy"
                        >
                          Privacy Policy
                        </Link>
                        .
                      </>
                    }
                    input={{
                      onChange: (event) => {
                        const { checked } = event.currentTarget;
                        setTosAccepted(checked);
                      },
                    }}
                  />
                </div>
                <Button
                  type="submit"
                  className={styles.button}
                  loading={submitting}
                  disabled={submitting || !tosAccepted}
                  data-qa="accept-invite-submit"
                >
                  Accept Invite
                </Button>
              </form>
            )}
          </Form>
        </div>
      </div>
    </MarketingLayout>
  );
};

AcceptInviteExistingUser.propTypes = {
  company: PropTypes.string,
  accountName: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  forgotToBackUrl: PropTypes.string,
  ownersUrl: PropTypes.string.isRequired,
};

export default AcceptInviteExistingUser;
