import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Leases = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 25" {...rest} fill={color}>
    <path d="m18.105 13.382 1.728 1.796a.61.61 0 0 1 0 .838l-5.186 5.393a.564.564 0 0 1-.245.15l-2.42.718c-.428.128-.824-.285-.701-.732l.69-2.515a.598.598 0 0 1 .146-.257l5.186-5.391c.221-.23.58-.23.802 0Zm-5.22 6.624-.231.84.789-.234-.558-.606ZM13.877 2.3c.155 0 .303.066.41.182l3.009 3.223c.1.11.157.257.157.41l.001 6.253a.58.58 0 0 1-.567.592.58.58 0 0 1-.568-.592l-.001-5.338a.546.546 0 0 1-.195.036H13.3a.58.58 0 0 1-.567-.592v-2.99H5.136v15.939h5.32a.58.58 0 0 1 .568.592.58.58 0 0 1-.568.592H4.568A.58.58 0 0 1 4 20.016V2.893a.58.58 0 0 1 .568-.592h9.309Zm2.616 13.594-2.973 3.092.904.98 2.993-3.111-.924-.961Zm-6.438.611a.58.58 0 0 1 .568.592.58.58 0 0 1-.568.592H6.92a.58.58 0 0 1-.568-.592.58.58 0 0 1 .567-.592h3.136Zm7.649-1.87-.407.423.924.96.407-.422-.924-.96Zm-7.649-.497a.58.58 0 0 1 .568.592.58.58 0 0 1-.568.592H6.92a.58.58 0 0 1-.568-.592.58.58 0 0 1 .567-.592h3.136Zm3.333-3.552a.58.58 0 0 1 .568.592.58.58 0 0 1-.568.592H6.92a.58.58 0 0 1-.568-.592.58.58 0 0 1 .567-.592h6.47Zm0-2.367a.58.58 0 0 1 .568.592.58.58 0 0 1-.568.592H6.92a.58.58 0 0 1-.568-.592.58.58 0 0 1 .567-.592h6.47ZM9.981 5.852a.58.58 0 0 1 .568.592.58.58 0 0 1-.568.592H6.92a.58.58 0 0 1-.568-.592.58.58 0 0 1 .567-.592h3.062Zm5.896.03-2.009-2.148v2.15l2.009-.001Z" />
  </svg>
);

Leases.defaultProps = {
  width: 24,
  height: 25,
  color: colors.primary,
};

Leases.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Leases);
