import {
  primary,
  secondary,
  textLight,
  line,
} from '../../../../../../constants/colors';

export const navbarContainer = (isFromUniversalMessaging) => {
  return {
    width: '100%',
    zIndex: '1',
    borderBottom: isFromUniversalMessaging
      ? `1px solid ${line}`
      : `4px solid ${line}`,
  };
};

export const navbarStyle = () => {
  return {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    padding: '0 16px',
  };
};

export const tabContainerStyle = (isActive) => {
  return {
    'borderBottom': isActive ? `4px solid ${primary}` : 'none',
    'display': 'inline-block',
    'cursor': 'pointer',
    'whiteSpace': 'nowrap',
    'marginRight': '24px',
    'marginBottom': '-4px',
    'padding': '12px 0',
    'color': isActive ? primary : textLight,
    '&:hover': {
      color: secondary,
    },
  };
};

export const tabTextStyle = () => {
  return {
    paddingTop: '2px',
    fontSize: 16,
    lineHeight: 'initial',
    letterSpacing: 0.3,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  };
};

export const newMessageButtonStyle = () => {
  return {
    height: '34px',
    maxWidth: '120px',
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: '0.25px',
    lineHeight: '18px',
    textAlign: 'center',
  };
};

export const pencilStyle = () => {
  return {
    cursor: 'pointer',
  };
};
