export const isValidStripeAccountName = (value) => {
  const nameRegex =
    /^([A-Za-z\u0027\u005F-\u0060\u00B4\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s-]*)$/g;

  if (!value || nameRegex.test(value)) {
    return undefined;
  }

  return 'Please enter a valid name';
};

export default isValidStripeAccountName;
