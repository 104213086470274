import React, { useState } from 'react';
import { CardNumberElement } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import PaymentCardIcon from '../PaymentCardIcon';

import styles from './CardNumberElementWithIcon.module.scss';

const CardNumberElementWithIcon = ({ onChange, ...props }) => {
  const [brand, setBrand] = useState(null);
  return (
    <div className={styles.wrap}>
      <PaymentCardIcon
        brand={brand}
        className={styles.icon}
        width={22}
        height={17}
      />
      <CardNumberElement
        {...props}
        onChange={(e) => {
          setBrand(e ? e.brand : null);
          if (onChange) {
            onChange(e);
          }
        }}
      />
    </div>
  );
};

CardNumberElementWithIcon.propTypes = {
  onChange: PropTypes.func,
};

export default CardNumberElementWithIcon;
