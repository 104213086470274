import React, { memo } from 'react';
import PropTypes from 'prop-types';

const MoneyCreditCardMasterCard = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 23" {...props}>
    <g>
      <rect x="0.3" y="0.3" width="29.3" height="22" rx="4.4" fill="#243747" />
      <ellipse cx="11.8" cy="11.3" rx="5.1" ry="5.2" fill="#E61C24" />
      <ellipse cx="18.1" cy="11.3" rx="5.1" ry="5.2" fill="#F99F1B" />
      <path
        d="M15 7.3L15 7.3C13.8 8.3 13 9.8 13 11.3 13 12.9 13.8 14.4 15 15.4L15 15.4C16.2 14.4 16.9 12.9 16.9 11.3 16.9 9.8 16.2 8.3 15 7.3L15 7.3Z"
        fill="#F26622"
      />
    </g>
  </svg>
);

MoneyCreditCardMasterCard.defaultProps = {
  width: 31,
  height: 23,
};

MoneyCreditCardMasterCard.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(MoneyCreditCardMasterCard);
