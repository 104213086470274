import React from 'react';

import validEmail from '../../validators/validEmail';
import Input from '../Input';

import CoreField from './CoreField';

export const EmailField = ({ validate = [], ...props }) => (
  <CoreField
    component={Input}
    type="email"
    validate={[
      validEmail,
      ...(Array.isArray(validate) ? validate : [validate]),
    ]}
    {...props}
  />
);

export default EmailField;
