import React, { useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isLoggedIn, lightLogout } from '../../../core/auth/authService';

const PrivateRouteComponent = ({ component: Component, ...rest }) => {
  useEffect(() => {
    if (!isLoggedIn()) {
      lightLogout(true);
    }
  }, []);

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

PrivateRouteComponent.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default withRouter(PrivateRouteComponent);
