import React, { useCallback, useContext, useState } from 'react';

/*
*NOTE: Whith thi you can create keys that hold boolean values and are avaible across whole app.
* You modify values of keys, get current value and remove key from state with exsampe below 
set hooks for key you want with
use useToggleItem("myKey") to get function for set or modify value of key to Boolean value
getItemStatus("myKey") to get function for  getting  boolen from key
cleanItem("myKey")  to get function for cleaning object state from this value
*/

const ShowHideContext = React.createContext({
  toggleItem: () => console.error('Not in the ShowHideContext'),
  getItemStatus: () => console.error('Not in the ShowHideContext'),
  cleanItem: () => console.error('Not in the ShowHideContext'),
});

export const ShowHideProvider = ({ children }) => {
  const [items, setItems] = useState({});

  const toggleItem = useCallback((newItemId, show) => {
    const newStatus = (oldValue) => {
      if (show !== undefined) {
        return show;
      } else {
        return oldValue === undefined ? false : !oldValue;
      }
    };
    setItems((oldItems) => ({
      ...oldItems,
      [newItemId]: newStatus(oldItems[newItemId]),
    }));
  }, []);

  const getItemStatus = useCallback((itemId) => {
    return items[itemId] === undefined ? true : items[itemId];
  });

  const cleanItem = (itemId) => {
    setItems((oldItems) => {
      const { [itemId]: _exclude, ...rest } = oldItems;
      return rest;
    });
  };

  return (
    <ShowHideContext.Provider
      value={{
        toggleItem,
        getItemStatus,
        cleanItem,
      }}
    >
      {children}
    </ShowHideContext.Provider>
  );
};

export const useToggleItem = (itemId) => {
  const id = itemId;
  const { toggleItem } = useContext(ShowHideContext);
  return useCallback((show) => {
    return toggleItem(id, show);
  });
};

export const useGetItemStatus = (itemId) => {
  const id = itemId;
  const { getItemStatus } = useContext(ShowHideContext);
  return useCallback(() => {
    return getItemStatus(id);
  });
};

export const useCleanItem = (itemId) => {
  const id = itemId;
  const { cleanItem } = useContext(ShowHideContext);
  return () => {
    return cleanItem(id);
  };
};
