import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Download = (props) => (
  <svg {...props} viewBox="0 0 20 20" fill={props.color}>
    <path d="M19.362 14.653c.352 0 .638.286.638.639v1.628c0 1.7-1.38 3.08-3.08 3.08H3.08A3.08 3.08 0 010 16.92v-1.628a.638.638 0 111.277 0v1.628c0 .996.807 1.803 1.803 1.803h13.84c.996 0 1.803-.807 1.803-1.803v-1.628c0-.353.286-.639.639-.639zM10 0c.353 0 .638.286.638.638v13.019l4.868-4.867a.638.638 0 11.903.902L10.45 15.65l-.019.018a.642.642 0 01-.035.03l.054-.048a.642.642 0 01-.683.143.628.628 0 01-.22-.143L3.592 9.692a.638.638 0 01.903-.902l4.867 4.866V.638C9.362.286 9.648 0 10 0z" />
  </svg>
);

Download.defaultProps = {
  width: 20,
  height: 20,
  color: colors.primary,
};

Download.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Download);
