import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './NumberedList.module.scss';

const isArrayOfStringsOrElements = (list) => {
  if (Array.isArray(list)) {
    return list.every(
      (item) => typeof item === 'string' || React.isValidElement(item),
    );
  }
  return false;
};

const NumberedList = ({ list, className, variant, size, checked }) => {
  const listWithoutSubtitles = isArrayOfStringsOrElements(list);
  return (
    <ol
      className={cx(
        styles.numberedList,
        styles[variant],
        styles[size],
        className,
      )}
    >
      {list.map((item, index) => {
        return (
          item && (
            <li
              key={index}
              className={cx({
                [styles.checked]: checked && checked(index),
              })}
            >
              {listWithoutSubtitles ? (
                item
              ) : (
                <div className={styles.itemWithSubtitle}>
                  <span>{item.title}</span>
                  <span className={styles.subTitle}>{item.subTitle}</span>
                </div>
              )}
            </li>
          )
        );
      })}
    </ol>
  );
};

NumberedList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.object,
    ]),
  ),
  className: PropTypes.string,
  variant: PropTypes.oneOf(['normal', 'bold']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

NumberedList.defaultProps = {
  variant: 'normal',
  size: 'small',
};

export default NumberedList;
