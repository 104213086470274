import { graphql } from '@apollo/client/react/hoc';
import get from 'lodash.get';
import pathToRegexp from 'path-to-regexp';
import branch from 'recompose/branch';
import compose from 'recompose/compose';

import customLogoQuery from '../../../graphql/customLogo.graphql';

import HeaderComponent from './HeaderComponent';

const propertiesPathReg = pathToRegexp('/properties/:idOrSlug');
const propertiesLongPath = pathToRegexp('/p/:address/:idOrSlug');
const appRoutes = [
  '/applications/setpassword/:applicationId',
  '/applications/profile/:applicationId',
  '/applications/verify-identity-intro/:applicationId',
].map((route) => pathToRegexp(route));

const customLogo = graphql(customLogoQuery, {
  props: ({ ownProps, data: { loading, viewer } }) => {
    return {
      customLogo: get(viewer, 'customLogo.url', undefined),
      loading,
      ...ownProps,
    };
  },
  options: ({ pathname }) => {
    let id;
    if (propertiesLongPath.test(pathname)) {
      id = propertiesLongPath.exec(pathname)[2];
    } else if (propertiesPathReg.test(pathname)) {
      id = propertiesPathReg.exec(pathname)[1];
    } else if (appRoutes.some((reg) => reg.test(pathname))) {
      const route = appRoutes.filter((reg) => reg.test(pathname));
      id = route[0].exec(pathname)[1];
    }
    return {
      variables: {
        id,
      },
    };
  },
});

export default compose(
  branch(({ pathname }) => {
    return (
      propertiesLongPath.test(pathname) ||
      propertiesPathReg.test(pathname) ||
      appRoutes.some((reg) => reg.test(pathname))
    );
  }, customLogo),
)(HeaderComponent);
