import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Applicant = (props) => (
  <svg {...props} viewBox="0 0 38 48" fill={props.color}>
    <path d="M13.612.667c.442 0 .846.25 1.044.645l2.01 4.021H36.5c.644 0 1.167.523 1.167 1.167V45a2.333 2.333 0 01-2.334 2.333H2.667A2.333 2.333 0 01.333 45V1.833C.333 1.19.856.667 1.5.667h12.112zm-.577 2.1H2.433V45c0 .129.105.233.234.233h32.666a.233.233 0 00.234-.233V7.433H15.369l-2.334-4.666zM19 14.9c3.718 0 6.727 3.028 6.727 6.756a6.767 6.767 0 01-2.18 4.985c4.177 1.655 6.418 4.438 6.875 8.807l.108 1.03a1.167 1.167 0 01-1.16 1.289H8.63a1.167 1.167 0 01-1.16-1.288l.108-1.03c.456-4.373 2.696-7.154 6.875-8.808a6.757 6.757 0 01-2.18-4.98c0-3.735 3.004-6.761 6.727-6.761zm0 2.1c-2.56 0-4.627 2.083-4.627 4.66a4.66 4.66 0 002.034 3.86c1.066.73.84 2.37-.383 2.782-3.903 1.315-5.948 3.447-6.357 7.365h18.666c-.41-3.915-2.456-6.049-6.357-7.365-1.222-.413-1.448-2.05-.384-2.782a4.671 4.671 0 002.035-3.864C23.627 19.084 21.554 17 19 17z" />
  </svg>
);

Applicant.defaultProps = {
  width: 38,
  height: 48,
  color: colors.text,
};

Applicant.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Applicant);
