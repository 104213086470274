import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Print = (props) => (
  <svg {...props} viewBox="0 0 22 20" fill={props.color}>
    <path d="M19.53 5.333c1.361 0 2.47 1.099 2.47 2.445v5.333c0 1.346-1.109 2.445-2.47 2.445l-2.02-.001v3.778a.67.67 0 01-.673.667H5.163a.67.67 0 01-.673-.667l-.001-3.778h-2.02c-1.305 0-2.38-1.012-2.464-2.284L0 13.111V7.778c0-1.346 1.11-2.445 2.47-2.445zm-3.367 7.556H5.837v5.778h10.326v-5.778zm3.368-6.222H2.469c-.615 0-1.122.501-1.122 1.11v5.334c0 .61.507 1.111 1.122 1.111h2.02v-2a.67.67 0 01.674-.666h11.674a.67.67 0 01.673.666v2h2.02c.576 0 1.055-.436 1.116-.99l.007-.12V7.777c0-.61-.506-1.111-1.122-1.111zm-15.266.889c.621 0 1.123.496 1.123 1.11 0 .614-.502 1.112-1.123 1.112-.62 0-1.122-.498-1.122-1.111 0-.615.503-1.111 1.122-1.111zM14.143 0c.178 0 .35.07.476.195l2.694 2.667a.663.663 0 01.197.471v.89a.675.675 0 01-1.175.445l-2.641-.001A.67.67 0 0113.02 4V1.333H5.837v2.89a.67.67 0 01-.674.666.67.67 0 01-.673-.667V.667A.67.67 0 015.163 0zm1.741 3.333l-1.517-1.501v1.501h1.517z" />
  </svg>
);

Print.defaultProps = {
  width: 22,
  height: 20,
  color: colors.text,
};

Print.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Print);
