import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import getFieldError from '../../validators/get-field-error';
import Label from '../Label';
import PlainInput from '../PlainInput';

import styles from './Input.module.scss';

const Input = ({
  as,
  input,
  inputProps,
  label,
  hint,
  className,
  id,
  type,
  step,
  pattern,
  inputMode,
  meta,
  disabled,
  labelProps,
  inputRef,
  help,
  skipTouchedCheck,
  ...rest
}) => {
  const error = getFieldError(meta, skipTouchedCheck);
  let helpText;

  if (help) {
    helpText = typeof help === 'function' ? help(input.value) : help;
  }

  return (
    <div className={cx(styles.container, className)}>
      {label && (
        <Label htmlFor={id} {...labelProps} hint={hint}>
          {label}
        </Label>
      )}
      <PlainInput
        as={as}
        type={type}
        step={step}
        pattern={pattern}
        inputMode={inputMode}
        id={id}
        disabled={disabled}
        invalid={!!error}
        ref={inputRef}
        {...input}
        {...inputProps}
        {...rest}
        value={input.value == null ? '' : input.value}
        onWheel={(e) => {
          if (input.type === 'number') {
            e.currentTarget.blur();
          }
          const onWheel = input.onWheel || inputProps.onWheel;
          if (onWheel) {
            onWheel(e);
          }
        }}
      />
      {error && (
        <span id={`${id}_errormsg`} className={styles.error}>
          {error}
        </span>
      )}
      {!error && helpText && <span className={styles.help}>{helpText}</span>}
    </div>
  );
};

Input.propTypes = {
  as: PropTypes.string,
  input: PropTypes.object,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  meta: PropTypes.object,
  type: PropTypes.string,
  step: PropTypes.string,
  pattern: PropTypes.string,
  inputMode: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelProps: PropTypes.object,
};

Input.defaultProps = {
  inputProps: {},
  meta: {},
};

export default Input;
