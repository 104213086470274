import React, { useState } from 'react';
import cx from 'classnames';
import get from 'lodash.get';

import { text } from '../../../constants/colors';

import styles from './StripeBaseElement.module.scss';

const STRIPE_BASE_STYLES = {
  'fontSize': '16px',
  'color': '#042238',
  'iconColor': '#aab7c4',
  'fontFamily': "'Open Sans', sans-serif",
  'fontWeight': 400,
  '::placeholder': {
    color: '#aab7c4',
  },
};

const StripeBaseElement = ({
  component: StripeComponent,
  containerStyles,
  showError,
  label,
  baseStyles,
  id,
  onValid,
  ...props
}) => {
  const [error, setError] = useState(null);
  const [dirty, setDirty] = useState(false);

  function isValid(e) {
    return e.complete && !e.empty && !e.error;
  }

  return (
    <label className={cx(styles.container, containerStyles)}>
      <div className={styles.label}>{label}</div>
      <fieldset className={styles.fieldWrap}>
        <div className={styles.stipeField}>
          <StripeComponent
            id={id}
            options={{
              style: {
                base: {
                  ...STRIPE_BASE_STYLES,
                  ...baseStyles,
                },
                invalid: {
                  color: text,
                },
              },
            }}
            {...props}
            onChange={(e) => {
              const error = get(e, 'error.message');
              setError(error);
              onValid && onValid(e.elementType, isValid(e));
            }}
            onBlur={() => {
              setDirty(true);
            }}
          />
        </div>
      </fieldset>
      {error && dirty && showError ? <div>{error}</div> : null}
    </label>
  );
};

StripeBaseElement.defaultProps = {
  baseStyles: {},
};

export default StripeBaseElement;
