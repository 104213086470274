import React from 'react';
import { Field, Form } from 'react-final-form';

import IconTooltip from '../../../../../../components/IconTooltip';
import SquareSimpleRadioGroup from '../../../../../../components/SquareSimpleRadioGroup';

import styles from './SignupDescriptionStandAlone.module.scss';

const SignupDescriptionStandAlone = ({ onSubmit, initialValues }) => {
  return (
    <Form
      onSubmit={(...data) => {
        onSubmit(...data);
      }}
      initialValues={{ ...initialValues }}
      subscription={{
        submitting: true,
        values: true,
        form: true,
      }}
    >
      {({ handleSubmit, form }) => (
        <div className={styles.container}>
          <h2>What best describes you?</h2>
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Field
              component={SquareSimpleRadioGroup}
              onChange={() => {
                form.submit();
              }}
              submitOnClick={() => {
                form.submit();
              }}
              name="signupDescription"
              options={[
                {
                  label: 'I manage my own rental(s)',
                  value: 'MY_RENTALS',
                },
                {
                  label: 'I manage rentals for others',
                  value: 'OTHERS_RENTALS',
                },
                {
                  label: 'I manage a mix of both',
                  value: 'BOTH',
                },
                {
                  label: "I don't manage any rentals yet",
                  value: 'DONT_MANAGE_RENTALS',
                },
                {
                  value: 'BROKER',
                  label: (
                    <span className={styles.dwellingOption}>
                      I’m a rental broker
                      <IconTooltip
                        tip={
                          <span>
                            They serve as the go-between for property managers
                            and potential tenants. They do not actively manage
                            rentals.
                          </span>
                        }
                      />
                    </span>
                  ),
                },
              ]}
            />
          </form>
        </div>
      )}
    </Form>
  );
};

export default SignupDescriptionStandAlone;
