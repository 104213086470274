import React from 'react';
import cx from 'classnames';

import styles from './Banner.module.scss';

const Banner = ({ className, ...rest }) => (
  <div className={cx(styles.banner, className)} {...rest} />
);

export default Banner;
