import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Experiments } from '../../constants/experiments';
import getLandlordCountQueryGQL from '../../graphql/queries/general_insights/getLandlordCount.graphql';
import { useIsSmallScreen } from '../../hooks/useIsSmallScreen';
import PremiumBadge from '../../icons/PremiumBadge';
import { useConfig } from '../../providers/ConfigProvider';
import { segmentTracking } from '../../services/utilities';
import Modal from '../Modal';
import { MODAL_LOCATIONS } from '../PremiumSubscription/constants';
import SinglePaymentCard from '../PremiumSubscription/PremiumSubscriptionModalOdd/SinglePaymentCard';
import PremiumTestimonial from '../PremiumSubscription/PremiumTestimonial';

import { getModalDetails, getModalVersion } from './helpers';

import styles from './GBBPremiumModal.module.scss';

const GBBPremiumModal = ({
  onClose,
  variantToRender,
  openLocation,
  user,
  singlePaymentAction,
  singlePaymentType,
  hideButtons,
  isMobileApp,
  segmentProperties = {},
  wantsCosignerAgreement,
  ...props
}) => {
  const isSmallScreen = useIsSmallScreen();

  const hasReiHub = user?.reihub_subscription_subscribed;
  const ModalContent = getModalVersion(variantToRender, hasReiHub);

  const config = useConfig();
  const { data: landlordCountData, loading: loadingLandlordCount } = useQuery(
    getLandlordCountQueryGQL,
  );
  const landlordCount = landlordCountData?.general_insights?.landlordCount;

  const { title, subtitle, benefits, testimonial, defaultPricing } =
    getModalDetails(openLocation, user, config, variantToRender);

  const [currentSection, setCurrentSection] = useState(benefits?.[0]?.key);

  const isPremiumPlusAndNotHavingReiHub =
    (variantToRender === Experiments.GoodBetterBest.variants.D ||
      variantToRender === Experiments.GoodBetterBest.variants.E) &&
    !hasReiHub;

  const isVariantA = variantToRender === Experiments.GoodBetterBest.variants.A;

  return (
    <Modal
      open
      onClose={onClose}
      showCloseIcon
      className={cx(styles.modal, {
        [styles.tabVariant]:
          isVariantA ||
          ((Experiments.GoodBetterBest.variants.D ||
            Experiments.GoodBetterBest.variants.E) &&
            hasReiHub),
        [styles.tabVariantPremiumPlus]: isPremiumPlusAndNotHavingReiHub,
      })}
      disableOutsideClick
      fullScreen={isSmallScreen}
      id="GBBPremiumModal"
      closeIconClassName={styles.closeIcon}
    >
      <div className={styles.container}>
        <PremiumBadge className={styles.premiumBadge} />
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.subtitle}>{subtitle}</p>
        <ModalContent
          {...props}
          className={cx(styles.content, {
            [styles.noPaymentCard]: !singlePaymentType,
          })}
          user={user}
          allBenefits={benefits}
          benefits={benefits.find(({ key }) => key === currentSection)}
          sections={benefits.map(({ key, title, titleShort }) => ({
            key,
            title: titleShort || title,
          }))}
          onSectionClick={(key) => setCurrentSection(key)}
          defaultPricing={defaultPricing}
          segmentProperties={segmentProperties}
          displayMonthlyPrice={
            openLocation === MODAL_LOCATIONS.MARKETING ||
            props.displayMonthlyPrice
          }
        />
        {singlePaymentType && (
          <SinglePaymentCard
            className={cx(styles.singlePaymentCard, {
              [styles.singlePaymentInTabVariant]:
                isVariantA || isPremiumPlusAndNotHavingReiHub,
            })}
            openLocation={singlePaymentType}
            hideButtons={hideButtons}
            action={() => {
              segmentTracking('continue clicked', {
                ...segmentProperties,
              });
              singlePaymentAction();
            }}
            wantsCosignerAgreement={wantsCosignerAgreement}
          />
        )}
        <PremiumTestimonial
          showReviews
          className={styles.testimonial}
          paragraph={testimonial.paragraph}
          name={testimonial.name}
          landlordOf={testimonial.landlordOf}
          landlordCount={landlordCount}
          loading={loadingLandlordCount}
        />
      </div>
    </Modal>
  );
};

GBBPremiumModal.propTypes = {
  onClose: PropTypes.func,
  openLocation: PropTypes.string,
  variantToRender: PropTypes.string,
  user: PropTypes.object.isRequired,
  onGetPremium: PropTypes.func.isRequired,
  onGetPro: PropTypes.func.isRequired,
  singlePaymentAction: PropTypes.func,
  singlePaymentType: PropTypes.oneOf([
    MODAL_LOCATIONS.FORMS,
    MODAL_LOCATIONS.LEASE,
    MODAL_LOCATIONS.ADDENDUM,
    MODAL_LOCATIONS.E_SIGN,
  ]),
  hideButtons: PropTypes.bool,
  isMobileApp: PropTypes.bool,
  segmentProperties: PropTypes.object,
  displayMonthlyPrice: PropTypes.bool,
  wantsCosignerAgreement: PropTypes.bool,
};

export default GBBPremiumModal;
