import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Television = ({ color, ...props }) => (
  <svg viewBox="0 0 54 48" {...props} fill={color}>
    <path
      d="M49.25.138a4.42 4.42 0 0 1 4.045 2.85c.165.445.248.909.266 1.381.141 10.933.14 21.87 0 32.803a4.412 4.412 0 0 1-.306 1.481 4.422 4.422 0 0 1-2.597 2.487 4.659 4.659 0 0 1-1.409.262c-5.144.064-10.289.106-15.433.125.589 1.477 1.104 2.565 1.642 4.009 2.385.029 4.77.095 7.152.218.102.016.129.016.227.047a1.081 1.081 0 0 1 .211 1.973 1.213 1.213 0 0 1-.553.146l-7.776-.001-1.837.051c-4.541.09-9.087-.05-13.63-.05h-7.747a1.213 1.213 0 0 1-.553-.146 1.086 1.086 0 0 1-.458-1.382 1.116 1.116 0 0 1 .78-.62c.102-.021.129-.019.231-.024 2.322 0 4.644-.027 6.966-.062.538-1.517 1.153-2.604 1.734-4.158-5.152-.02-10.303-.062-15.454-.126a4.426 4.426 0 0 1-4.006-2.749 4.413 4.413 0 0 1-.306-1.481C.3 26.238.298 15.302.44 4.369c.019-.473.102-.937.268-1.382A4.423 4.423 0 0 1 4.75.138c14.832-.184 29.667-.184 44.498 0ZM22.22 41.534l-1.398 4.115c4.104-.069 8.21-.145 12.312-.132l-1.354-3.983c-3.187.008-6.374.008-9.56 0ZM4.872 2.309a2.217 2.217 0 0 0-2.086 1.426 2.201 2.201 0 0 0-.134.69c-.14 10.914-.42 21.84.002 32.748.016.235.062.464.15.684a2.213 2.213 0 0 0 1.946 1.372c14.823.553 29.678.552 44.5 0a2.211 2.211 0 0 0 1.945-1.372c.089-.22.136-.45.15-.684a423.948 423.948 0 0 0 0-32.805 2.2 2.2 0 0 0-.15-.683 2.214 2.214 0 0 0-1.945-1.373c-14.782-.551-29.585-.003-44.378-.003Zm22.241 31.496a2.214 2.214 0 0 1 1.595.787 2.168 2.168 0 0 1 .503 1.271 2.19 2.19 0 0 1-1.349 2.112 2.254 2.254 0 0 1-1.724 0 2.19 2.19 0 0 1-1.349-2.112 2.138 2.138 0 0 1 .503-1.271 2.216 2.216 0 0 1 1.595-.787l.068-.002h.091c.018 0 .039 0 .067.002ZM48.162 4.488a1.097 1.097 0 0 1 .941.818c.029.114.025.144.032.26v26.063a1.245 1.245 0 0 1-.072.386c-.111.288-.348.52-.642.63-.13.048-.256.062-.392.07H5.972a1.312 1.312 0 0 1-.393-.07 1.105 1.105 0 0 1-.642-.63 1.239 1.239 0 0 1-.072-.386V5.566c.008-.134.023-.258.072-.385a1.104 1.104 0 0 1 1.035-.701c14.063 0 28.152-.825 42.19.008Zm-1.24 2.164H7.078v23.891h39.844V6.652Z"
      fillRule="evenodd"
    />
  </svg>
);

Television.defaultProps = {
  width: 54,
  height: 48,
  color: 'currentColor',
};

Television.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Television);
