const roundToThousand = (val) => {
  if (!val) {
    return;
  }

  if (typeof val !== 'number') {
    throw new Error('This is not a number!');
  }

  const length = (val + '').replace('.', '').length; // for floats

  if (length < 4) {
    return val;
  }

  return Math.round(val / 1000) * 1000;
};

export default roundToThousand;
