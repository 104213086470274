import React, { memo } from 'react';
import PropTypes from 'prop-types';

const BusinessBank = ({ thick, ...props }) => {
  const thinIcon = (
    <svg viewBox={props.viewBox || '0 0 54 57'} {...props}>
      <path
        d="M52.553 54.333c.614 0 1.111.523 1.111 1.167s-.497 1.167-1.11 1.167H1.446c-.614 0-1.111-.523-1.111-1.167s.497-1.167 1.11-1.167Zm0-4.666c.614 0 1.111.522 1.111 1.166 0 .645-.497 1.167-1.11 1.167H1.446c-.614 0-1.111-.522-1.111-1.167 0-.644.497-1.166 1.11-1.166ZM14.78 26.333c.613 0 1.11.523 1.11 1.167s-.497 1.167-1.11 1.167h-1.112V45h1.112c.572 0 1.044.455 1.104 1.04l.007.127c0 .644-.498 1.166-1.111 1.166H3.669c-.614 0-1.111-.522-1.111-1.166 0-.645.497-1.167 1.11-1.167H4.78V28.667H3.67c-.573 0-1.045-.455-1.105-1.04l-.006-.127c0-.644.497-1.167 1.11-1.167Zm17.776 0c.614 0 1.111.523 1.111 1.167s-.497 1.167-1.11 1.167h-1.113V45h1.112c.573 0 1.044.455 1.105 1.04l.006.127c0 .644-.497 1.166-1.11 1.166H21.444c-.614 0-1.111-.522-1.111-1.166 0-.645.497-1.167 1.11-1.167h1.111V28.667h-1.11c-.573 0-1.044-.455-1.105-1.04l-.006-.127c0-.644.497-1.167 1.11-1.167Zm17.776 0c.614 0 1.111.523 1.111 1.167s-.497 1.167-1.11 1.167H49.22V45h1.111c.573 0 1.045.455 1.105 1.04l.006.127c0 .644-.497 1.166-1.11 1.166h-11.11c-.614 0-1.112-.522-1.112-1.166 0-.645.498-1.167 1.111-1.167h1.11V28.667h-1.11c-.572 0-1.044-.455-1.104-1.04l-.007-.127c0-.644.498-1.167 1.111-1.167Zm-3.333 2.334h-4.445V45h4.445V28.667Zm-17.777 0h-4.444V45h4.444V28.667Zm-17.776 0H7.001V45h4.444V28.667ZM27.755.916l25.484 21c.83.684.37 2.084-.686 2.084H1.447C.39 24-.07 22.597.763 21.914l25.622-21a1.07 1.07 0 0 1 1.37.002Zm-.688 2.399L4.676 21.667h44.662L27.067 3.315Z"
        fill={props.color}
        fillRule="evenodd"
      />
    </svg>
  );

  const thickIcon = (
    <svg viewBox={props.viewBox || '0 0 23 24'} {...props}>
      <path
        d="M21.9 22.473a.765.765 0 0 1 .113 1.519l-.114.008H.767a.765.765 0 0 1-.113-1.518l.113-.009H21.9Zm0-2.29a.765.765 0 0 1 .113 1.519l-.114.008H.767a.765.765 0 0 1-.113-1.519l.113-.008H21.9ZM6.28 10.534a.765.765 0 0 1 .113 1.519l-.113.008h-.154v5.801h.154a.765.765 0 0 1 .113 1.519l-.113.008H1.686a.765.765 0 0 1-.113-1.518l.113-.008.153-.001v-5.801h-.153a.765.765 0 0 1-.113-1.518l.113-.009H6.28Zm7.35 0a.765.765 0 0 1 .113 1.519l-.113.008h-.153v5.801h.153a.765.765 0 0 1 .113 1.519l-.113.008H9.036a.765.765 0 0 1-.113-1.518l.113-.008.153-.001v-5.801h-.153a.765.765 0 0 1-.113-1.518l.113-.009h4.594Zm7.35 0a.765.765 0 0 1 .114 1.519l-.113.008h-.154v5.801h.154a.765.765 0 0 1 .113 1.519l-.113.008h-4.594a.765.765 0 0 1-.113-1.518l.113-.008.152-.001v-5.801h-.152a.765.765 0 0 1-.113-1.518l.113-.009h4.594Zm-1.684 1.527h-1.225v5.801h1.225v-5.801Zm-7.351 0H10.72v5.801h1.225v-5.801Zm-7.35 0H3.37v5.801h1.225v-5.801Zm6.296-11.9a.767.767 0 0 1 .943.002l10.538 8.244c.572.448.255 1.364-.473 1.364H.767c-.728 0-1.045-.918-.47-1.365Zm.47 1.57L2.99 8.245h16.694L11.36 1.732Z"
        fill={props.color}
        fillRule="evenodd"
      />
    </svg>
  );

  return thick ? thickIcon : thinIcon;
};

BusinessBank.defaultProps = {
  thick: true,
  width: 23,
  height: 24,
  color: 'currentColor',
};

BusinessBank.propTypes = {
  thick: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(BusinessBank);
