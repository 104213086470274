import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Electricity = (props) => {
  if (props.variation === 'thick') {
    return (
      <svg {...props} viewBox={props.viewBox || '0 0 26 28'} fill={props.color}>
        <path
          d="M12.667 5.238c4.42 0 8.007 3.518 8.007 7.856 0 3.18-1.947 5.978-4.819 7.2l-.256.104-.167.06.001.636c0 .467-.327.86-.768.97l-.113.02-.138.01H10.92c-.526 0-.958-.39-1.014-.891l-.006-.11v-.634l-.166-.06c-2.824-1.09-4.813-3.677-5.051-6.695l-.017-.284-.007-.326c0-4.34 3.585-7.856 8.008-7.856Zm0 2c-3.297 0-5.97 2.621-5.97 5.856 0 2.684 1.86 5.002 4.477 5.664.413.104.711.447.758.855l.007.113v.368h1.455v-.368c0-.365.202-.693.513-.868l.107-.052.145-.048c2.616-.662 4.477-2.981 4.477-5.664 0-3.234-2.674-5.856-5.97-5.856Zm11.647 4.856c.563 0 1.02.448 1.02 1 0 .507-.384.925-.882.991l-.138.01h-2.33a1.01 1.01 0 0 1-1.018-1c0-.507.383-.925.88-.991l.139-.01h2.33Zm-20.965 0a1.01 1.01 0 0 1 1.019 1c0 .507-.384.925-.881.991l-.138.01h-2.33a1.01 1.01 0 0 1-1.019-1c0-.507.383-.925.88-.991l.14-.01h2.329Zm.863-8.692.114.096 2.472 2.425a.987.987 0 0 1 0 1.415 1.034 1.034 0 0 1-1.327.096l-.114-.096-2.472-2.425a.987.987 0 0 1 0-1.415 1.034 1.034 0 0 1 1.327-.096Zm18.234.096a.986.986 0 0 1 .099 1.302l-.1.113-2.47 2.425c-.399.39-1.044.39-1.442 0a.986.986 0 0 1-.099-1.302l.099-.113 2.472-2.425a1.033 1.033 0 0 1 1.44 0ZM12.666.667c.517 0 .943.376 1.01.864l.01.136v2.285c0 .552-.456 1-1.02 1-.515 0-.942-.376-1.01-.864l-.008-.136V1.667c0-.553.456-1 1.019-1Zm.583 24.666c.563 0 1.02.448 1.02 1 0 .507-.384.925-.882.991l-.138.01h-1.165a1.01 1.01 0 0 1-1.019-1c0-.507.384-.925.881-.991l.138-.01h1.165Zm1.165-2.62c.563 0 1.019.447 1.019 1 0 .506-.383.924-.88.99l-.14.01H10.92a1.01 1.01 0 0 1-1.02-1c0-.507.384-.925.881-.991l.139-.01h3.494Z"
          fillRule="evenodd"
        />
      </svg>
    );
  } else {
    return (
      <svg {...props} viewBox={props.viewBox || '0 0 22 22'}>
        <path
          d="M4.5 10.5C4.5 6.91 7.41 4 11 4s6.5 2.91 6.5 6.5c0 2.846-1.85 5.322-4.5 6.178v.822c0 .276-.224.5-.5.5h-3c-.276 0-.5-.224-.5-.5v-.822c-2.65-.855-4.5-3.33-4.5-6.178zM12 17v-.697c0-.228.154-.427.375-.484 2.41-.627 4.125-2.8 4.125-5.32C16.5 7.46 14.037 5 11 5c-3.038 0-5.5 2.462-5.5 5.5 0 2.52 1.714 4.697 4.125 5.32.22.056.375.255.375.483V17h2zm-.5 4h-1c-.276 0-.5.224-.5.5s.224.5.5.5h1c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm1-2h-3c-.276 0-.5.224-.5.5s.224.5.5.5h3c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zM10.5.5v2c0 .276.224.5.5.5s.5-.224.5-.5v-2c0-.276-.224-.5-.5-.5s-.5.224-.5.5zM21 10h-2c-.276 0-.5.224-.5.5s.224.5.5.5h2c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zM1 11h2c.276 0 .5-.224.5-.5S3.276 10 3 10H1c-.276 0-.5.224-.5.5s.224.5.5.5zm1.867-7.925L4.99 5.197c.194.196.51.196.706 0 .196-.195.196-.512 0-.707L3.574 2.368c-.195-.195-.512-.195-.707 0-.195.195-.195.512 0 .707zm15.557-.707L16.302 4.49c-.196.195-.196.512 0 .707.195.196.512.196.707 0l2.12-2.122c.192-.195.192-.512 0-.707-.198-.195-.51-.195-.71 0z"
          fill={props.color}
        />
      </svg>
    );
  }
};

Electricity.defaultProps = {
  width: 26,
  height: 28,
  color: colors.text,
  variation: 'thick',
};

Electricity.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  variation: PropTypes.string,
};

export default memo(Electricity);
