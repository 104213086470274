import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Forms = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 25" {...rest} fill={color}>
    <path d="M18.939 3.924c.31 0 .566.226.606.518l.006.082v9.8h1.837c.304 0 .556.217.604.502l.008.098v6.4c0 .331-.274.6-.612.6H2.612a.606.606 0 0 1-.612-.6v-6.4c0-.332.274-.6.612-.6h1.836l.001-8.2c0-.304.23-.555.53-.595l.082-.005h4.285v-1c0-.304.231-.555.53-.595l.083-.005h8.98Zm-11.225 11.6h-4.49v5.2h17.552v-5.2h-4.49v1c0 .304-.23.555-.53.594l-.082.006H8.326a.606.606 0 0 1-.613-.6v-1Zm1.632-8.8H5.673v7.6h2.654c.304 0 .556.217.604.502l.008.098v1h.407v-9.2Zm8.98-1.6h-7.755v10.8h4.49v-1c0-.304.23-.555.53-.595l.083-.005h2.652v-9.2Zm-1.02 7.6c.338 0 .612.268.612.6 0 .304-.23.555-.529.594l-.083.006h-5.714a.606.606 0 0 1-.612-.6c0-.304.23-.555.529-.595l.083-.005h5.714Zm0-1.6c.338 0 .612.268.612.6 0 .304-.23.555-.529.594l-.083.006h-5.714a.606.606 0 0 1-.612-.6c0-.304.23-.555.529-.595l.083-.005h5.714Zm0-1.6c.338 0 .612.268.612.6 0 .304-.23.555-.529.594l-.083.006h-5.714a.606.606 0 0 1-.612-.6c0-.304.23-.555.529-.595l.083-.005h5.714Zm0-1.6c.338 0 .612.268.612.6 0 .304-.23.555-.529.594l-.083.006h-5.714a.606.606 0 0 1-.612-.6c0-.304.23-.555.529-.595l.083-.005h5.714Zm0-1.6c.338 0 .612.268.612.6 0 .304-.23.555-.529.594l-.083.006h-4.082a.606.606 0 0 1-.612-.6c0-.304.23-.555.53-.595l.083-.005h4.081Z" />
  </svg>
);

Forms.defaultProps = {
  width: 24,
  height: 25,
  color: colors.primary,
};

Forms.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Forms);
