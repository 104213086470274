import React from 'react';
import PropTypes from 'prop-types';

import LulaBadge from '../../../../../icons/LulaBadge';

import styles from './LulaContactCard.module.scss';

const LulaContactCard = ({ isRenter }) => {
  let number = {
    raw: '+19133037781',
    formatted: '(913) 303-7781',
  };

  let supportLink =
    'https://support.turbotenant.com/en/articles/8660690-maintenance-plus-with-lula';

  if (isRenter) {
    number = {
      raw: '+19134449295',
      formatted: '(913) 444-9295',
    };

    supportLink =
      'https://support.turbotenant.com/en/articles/8702089-maintenance-requests-with-lula';
  }

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.iconContainer}>
          <LulaBadge />
        </div>
        <div className={styles.info}>
          <div className={styles.title}>Lula Contact</div>
          <div>
            <a href={`tel:${number.raw}`} className={styles.phone}>
              {number.formatted}
            </a>
          </div>
          {isRenter && (
            <div className={styles.learnMore}>
              <a target="_blank" href={supportLink} rel="noreferrer">
                Learn more about Lula
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

LulaContactCard.propTypes = {
  isRenter: PropTypes.bool,
};

export default LulaContactCard;
