import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const WarningFill = ({ width, height, color, secondaryColor, className }) => (
  <svg width={width} height={height} className={className} viewBox="0 0 40 40">
    <g fill="none" fillRule="evenodd">
      <path fill={color} d="M20 2v38H0z" />
      <path
        fill={secondaryColor}
        d="M20.168.028c.248.066.4.105.61.464l19.144 38.25c.246.58-.108 1.215-.779 1.258H.855a.893.893 0 0 1-.779-1.258L19.221.492c.283-.484.362-.54.947-.464Zm-.169 2.797L2.263 38.26h35.472L20 2.825Zm.089 28.484c.869.066 1.606.792 1.65 1.648.06 1.176-1.29 2.166-2.417 1.69-1.43-.604-1.454-3.394.767-3.338Zm.01-17.986c.412.064.72.384.769.797.17 4.454.002 8.914.002 13.37-.035.918-1.624 1.055-1.738.068-.17-4.454-.002-8.914-.002-13.371.02-.51.298-.915.97-.864Z"
      />
    </g>
  </svg>
);

WarningFill.defaultProps = {
  width: 40,
  height: 40,
  color: colors.statusWarning,
  secondaryColor: colors.text,
};

WarningFill.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default memo(WarningFill);
