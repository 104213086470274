import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import FlatButton from '../../../../../../components/FlatButton';
import { Column, Row } from '../../../../../../components/Grid';
import { parseLateFeeBackendData } from '../../../../../../components/MonthlyChargeForm/utils/lateFeeDataHelpers';
import { Paragraph } from '../../../../../../components/Typography';
import { getPaymentRequestRuleDueToNarrative } from '../../../../../../helpers/getPaymentRequestRuleDueToNarrative';
import DeleteIcon from '../../../../../../icons/Delete';
import EditIcon from '../../../../../../icons/Edit';
import { getRentPaymentCategoryLabel } from '../../../../../../services/utilities';
import LateFeeDigest from '../ConfigureLateFeeModal/components/LateFeeDigest';

import styles from './ChargeCollapsed.module.scss';

const ChargeCollapsed = ({ charge, onEdit, onDelete, className }) => {
  const { category, description, amount, late_fee_policy } = charge;

  const lateFeeDigestData = parseLateFeeBackendData(late_fee_policy);

  return (
    <div className={cx(styles.container, className)}>
      <Row>
        <Column span={11}>
          <Row>
            <Column span={10}>
              <span className={styles.category}>
                {getRentPaymentCategoryLabel(category)}
              </span>
              {description && (
                <span className={styles.description}>
                  &nbsp;-&nbsp;{description}
                </span>
              )}
            </Column>
          </Row>
        </Column>
        <Column span={1} className={styles.buttonColumn}>
          <FlatButton
            id={`edit_charge_button`}
            onClick={onEdit}
            icon={EditIcon}
            className={styles.button}
            iconProps={{ className: styles.trashIcon }}
          />
          <FlatButton
            id={`delete_charge_button`}
            onClick={onDelete}
            icon={DeleteIcon}
            iconProps={{ className: styles.trashIcon }}
            className={styles.button}
          />
        </Column>
      </Row>
      <Row>
        <Column span={2} className={styles.amountColumn}>
          <span className={styles.amount}>${amount}</span>
        </Column>
      </Row>
      <p className={styles.dueNarrative}>
        {getPaymentRequestRuleDueToNarrative(charge)}
      </p>

      {late_fee_policy?.policies?.length > 0 && (
        <>
          <Paragraph weight="bold">Late Fee Policy</Paragraph>
          <LateFeeDigest data={lateFeeDigestData} hideEdit hideTitle />
        </>
      )}
    </div>
  );
};

ChargeCollapsed.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  charge: PropTypes.shape({
    category: PropTypes.string,
    description: PropTypes.string,
    // We expect this to be already formatted which is why it's a string.
    // If we allow a number then something like 3.5 will show with only
    // one decimal.
    amount: PropTypes.string,
    late_fee_policy: PropTypes.shape({
      policies: PropTypes.arrayOf(
        PropTypes.shape({
          amount: PropTypes.number,
          grace_period_days: PropTypes.number,
          late_fee_policy_type: PropTypes.string,
          late_fee_unit: PropTypes.string,
        }),
      ),
      limit: PropTypes.shape({
        late_fee_limit_type: PropTypes.string,
        late_fee_limit_unit: PropTypes.string,
        late_fee_limit_value: PropTypes.number,
      }),
    }),
  }).isRequired,

  className: PropTypes.string,
};

export default ChargeCollapsed;
