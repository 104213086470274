import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Feedback = (props) => (
  <svg {...props}>
    <g id="Icon / Partial Fill / Feedback">
      <path
        id="Shape"
        d="M52.0079 33.3114L47.5616 37.7577L53.1195 43.3155L57.5657 38.8692L52.0079 33.3114Z"
        fill={props.pencilFill}
      />
      <path
        id="Shape_2"
        d="M6.43384 37.7575V6.63367H23.8854V37.7575L15.3264 46.65V37.7575H6.43384Z"
        fill="#7FE3FF"
      />
      <path
        id="Shape_3"
        d="M33.1118 57.7657L35.5598 49.9991L40.8928 55.5425L33.1118 57.7657Z"
        fill={props.pencilFill}
      />
      <g id="Shape_4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.33337 6.63364C5.33337 6.02595 5.826 5.53333 6.43369 5.53333H53.1194C53.7271 5.53333 54.2197 6.02595 54.2197 6.63364V25.5302C54.2197 26.1379 53.7271 26.6306 53.1194 26.6306C52.5117 26.6306 52.0191 26.1379 52.0191 25.5302V7.73396H7.53401V36.6571H15.3262C15.9339 36.6571 16.4265 37.1498 16.4265 37.7575V43.9936L23.4407 36.9794C23.647 36.7731 23.9269 36.6571 24.2187 36.6571H35.3344C35.9421 36.6571 36.4347 37.1498 36.4347 37.7575C36.4347 38.3652 35.9421 38.8578 35.3344 38.8578H24.6745L16.1043 47.428C15.7896 47.7427 15.3163 47.8369 14.9051 47.6665C14.494 47.4962 14.2259 47.095 14.2259 46.65V38.8578H6.43369C5.826 38.8578 5.33337 38.3652 5.33337 37.7575V6.63364Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2248 15.5261C13.2248 14.9184 13.7174 14.4258 14.3251 14.4258H45.2266C45.8343 14.4258 46.3269 14.9184 46.3269 15.5261C46.3269 16.1338 45.8343 16.6264 45.2266 16.6264H14.3251C13.7174 16.6264 13.2248 16.1338 13.2248 15.5261Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2248 22.1955C13.2248 21.5878 13.7174 21.0952 14.3251 21.0952H45.2266C45.8343 21.0952 46.3269 21.5878 46.3269 22.1955C46.3269 22.8032 45.8343 23.2958 45.2266 23.2958H14.3251C13.7174 23.2958 13.2248 22.8032 13.2248 22.1955Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2248 28.8649C13.2248 28.2572 13.7174 27.7645 14.3251 27.7645H34.3332C34.9409 27.7645 35.4336 28.2572 35.4336 28.8649C35.4336 29.4725 34.9409 29.9652 34.3332 29.9652H14.3251C13.7174 29.9652 13.2248 29.4725 13.2248 28.8649Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.0086 32.211C52.3004 32.211 52.5803 32.3269 52.7866 32.5332L58.3444 38.0911C58.7741 38.5208 58.7741 39.2174 58.3444 39.6472L41.671 56.3206C41.5387 56.4529 41.375 56.5492 41.1952 56.6006L33.4142 58.8237C33.03 58.9335 32.6165 58.8263 32.3339 58.5438C32.0514 58.2612 31.9442 57.8477 32.054 57.4634L34.2771 49.6825C34.3285 49.5027 34.4248 49.3389 34.557 49.2067L51.2305 32.5332C51.4369 32.3269 51.7167 32.211 52.0086 32.211ZM36.3147 50.5612L34.714 56.1636L40.3165 54.5629L56.0103 38.8691L52.0086 34.8674L36.3147 50.5612Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.3413 44.0934L46.7834 38.5355L48.3395 36.9794L53.8974 42.5373L52.3413 44.0934Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.557 49.2067C34.9867 48.777 35.683 48.7769 36.1127 49.2066L41.6705 54.7644C42.1002 55.1941 42.1007 55.8909 41.671 56.3206C41.2413 56.7503 40.5441 56.7502 40.1144 56.3205L34.5566 50.7627C34.1269 50.333 34.1273 49.6364 34.557 49.2067Z"
          fill="black"
        />
      </g>
    </g>
  </svg>
);

Feedback.defaultProps = {
  width: 64,
  height: 64,
  color: colors.info,
  pencilFill: colors.babyBlue,
};

Feedback.propTypes = {
  color: PropTypes.string,
  pencilFill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(Feedback);
