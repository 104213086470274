import React from 'react';

import HelpFill from '../../../../icons/HelpFill';

import {
  IncomeInsightsContent,
  IncomeInsightsDescription,
  IncomeInsightsIcon,
  IncomeInsightsTitle,
} from './Elements';

const NoDataInsightCard = ({ firstName }) => (
  <>
    <IncomeInsightsIcon noDataIcon>
      <HelpFill />
    </IncomeInsightsIcon>
    <IncomeInsightsContent>
      <IncomeInsightsTitle biggerTitle>
        Oh no! TransUnion doesn’t have enough data to accurately estimate{' '}
        {firstName}’s income.
      </IncomeInsightsTitle>
      <IncomeInsightsDescription>
        We recommend you check their pay stubs/bank statements and contact their
        employers to verify their income.
      </IncomeInsightsDescription>
    </IncomeInsightsContent>
  </>
);

export default NoDataInsightCard;
