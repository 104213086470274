import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './SectionPeopleOnTheLease.module.scss';

export const SectionPeopleOnTheLeaseLandlordMailingAddressSection = ({
  children,
}) => (
  <div className={cx(styles.section, styles.xSmallSpacing)}>
    <p className={styles.fatDescription}>LANDLORD MAILING ADDRESS</p>
    <p className={styles.description}>
      An address must be listed in case your tenant(s) need to mail you a
      notice.
    </p>
    {children}
  </div>
);

SectionPeopleOnTheLeaseLandlordMailingAddressSection.propTypes = {
  children: PropTypes.node,
};
